// Copyright 2019 Ernesto Giron E.
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//     http://www.apache.org/licenses/LICENSE-2.0
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.​

// ACTION TYPES //
export const types = {
  MODULE_TERAPIA_LOADED: "MODULE_TERAPIA_LOADED",
  MODULE_TERAPIA_FILTER_OPEN: "MODULE_TERAPIA_FILTER_OPEN",
  MODULE_TERAPIA_EDIT: "MODULE_TERAPIA_EDIT",
  TERAPIA_DATA_LOADED: "TERAPIA_DATA_LOADED",
  TERAPIA_RESETDATA_LOADED: "TERAPIA_RESETDATA_LOADED",
  TERAPIA_DATABYQUERY_LOADED: "TERAPIA_DATABYQUERY_LOADED",
  TERAPIA_STATS_LOADED: "TERAPIA_STATS_LOADED",
  TERAPIA_LIST_LOADED: "TERAPIA_LIST_LOADED",
  TERAPIA_LIST_FILTERED: "TERAPIA_LIST_FILTERED",
  TERAPIA_ISFILTER_SHOW: "TERAPIA_ISFILTER_SHOW",
  TERAPIA_SELECTED: "TERAPIA_SELECTED",
  TERAPIA_NOTA_SELECTED: "TERAPIA_NOTA_SELECTED",
  TERAPIA_DATA_SELECTED: "TERAPIA_DATA_SELECTED",
  TERAPIA_SELECTED_DATA_REQUEST: "TERAPIA_SELECTED_DATA_REQUEST",
  // TERAPIA_SELECTED_DATA_EDITED: "TERAPIA_SELECTED_DATA_EDITED",

  TERAPIA_DATA_UPDATED: "TERAPIA_DATA_UPDATED",
  TERAPIA_DATA_CREATED: "TERAPIA_DATA_CREATED",
  TERAPIA_DATA_DELETED: "TERAPIA_DATA_DELETED",
  
  TERAPIA_SERVER_MESSAGE: "TERAPIA_SERVER_MESSAGE"
};

// REDUCERS //
export const initialState = {
  loaded: false,
  isFilterShow: false,
  edit: false,
  openfilter: false,
  filtername: '',
  listTerapia: null,
  resultQueryTerapia: null,
  selected: null,
  selectedTerapia: null,
  datosSelTerapia: [],
  filter: [],
  query: null,
  mensaje: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.MODULE_TERAPIA_LOADED:
      const { loaded } = action.payload;
      return {
        ...state,
        loaded
      };
    case types.MODULE_TERAPIA_EDIT:
      const { edit } = action.payload;
      return {
        ...state,
        edit
      };
    case types.MODULE_TERAPIA_FILTER_OPEN:
      const { openfilter, filtername } = action.payload;
      return {
        ...state,
        openfilter, 
        filtername
      };
    case types.TERAPIA_LIST_LOADED:
      const { listTerapia } = action.payload;
      return {
        ...state,
        listTerapia
      };
    case types.TERAPIA_STATS_LOADED:
      const { resultQueryTerapia } = action.payload;
      return {
        ...state,
        resultQueryTerapia
      };
    case types.TERAPIA_LIST_FILTERED:
      const { filter } = action.payload;
      return {
        ...state,
        filter
      };
    case types.TERAPIA_ISFILTER_SHOW:
      const { isFilterShow } = action.payload;
      return {
        ...state,
        isFilterShow
      };
    case types.TERAPIA_SELECTED:
      const { selected } = action.payload;
      return {
        ...state,
        selected
      };
    // case types.TERAPIA_NOTA_SELECTED:
    //   return {
    //     ...state,
    //     selectedTerapia:action.payload.selectedTerapia
    //   };
    // case types.TERAPIA_DATA_SELECTED:
    //   const { datosSelTerapia, selectedTerapia } = action.payload;
    //   return {
    //     ...state,
    //     datosSelTerapia,
    //     selectedTerapia
    //   };
    case types.TERAPIA_SERVER_MESSAGE:
      const {mensaje} = action.payload;
      return {
        ...state,
        mensaje
      };
    default:
      return state;
  }
};

// ACTIONS //
export const moduleTerapiaLoaded = options => ({ type: types.MODULE_TERAPIA_LOADED, payload: options });
export const displayTerapiaFilter = options => ({ type: types.TERAPIA_ISFILTER_SHOW, payload: options });

export const editTerapia = options => ({ type: types.MODULE_TERAPIA_EDIT, payload: options });
export const changeFilterTerapia = options => ({ type: types.MODULE_TERAPIA_FILTER_OPEN, payload: options });

export const fetchTerapiasData = token => ({ 
  type: types.TERAPIA_DATA_LOADED, 
  payload: {
    token: token
  }
});
export const fetchTerapiasDatabyQuery = options => ({ 
  type: types.TERAPIA_DATABYQUERY_LOADED, 
  payload: options
});

export const resetTerapiasDatabyQuery = () => ({ 
  type: types.TERAPIA_RESETDATA_LOADED, 
  payload: {resultQueryTerapia: null}
});

export const updateTerapia = data => ({ 
  type: types.TERAPIA_DATA_UPDATED,  payload: data
});
export const insertTerapia = data => ({ 
  type: types.TERAPIA_DATA_CREATED,  payload: data
});
export const deleteTerapia = data => ({ 
  type: types.TERAPIA_DATA_DELETED,  payload: data
});

export const updateEditedTerapiaData = resultQueryTerapia => ({ 
  type: types.TERAPIA_STATS_LOADED, 
  payload: resultQueryTerapia
});

export const filterTerapiaData = filter => ({ 
  type: types.TERAPIA_LIST_FILTERED, 
  payload: {
    filter: filter
  }
});
export const resetfilterTerapiaData = () => ({ 
  type: types.TERAPIA_LIST_FILTERED, 
  payload: {filter: []}
});

export const selectTerapia = docId => ({ 
  type: types.TERAPIA_SELECTED, 
  payload: {
    selected: docId
  }
});


export const serverMensaje = mensaje => ({ 
  type: types.TERAPIA_SERVER_MESSAGE, 
  payload: {
    mensaje: mensaje
  }
});