import React, {useState, useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectBeneficiarioForPerfil } from '../../redux/reducers/beneficiarios';

import BenefHeader from './BenefHeader';
import BenefCitas from './BenefCitas';
import BenefCumplimiento from './BenefCumplimiento';
import BenefNotificaciones from './BenefNotificaciones';
import BenefPerfilBeneficiario from './BenefPerfilBeneficiario';
import BenefVestuarioTallaje from './BenefVestuarioTallaje';
import BenefLocalizacion from './BenefLocalizacion';
import BenefEncuestado from './BenefEncuestado';
import BenefVivienda from './BenefVivienda';
import BenefInfoEconomica from './BenefInfoEconomica';
import BenefSalud from './BenefSalud';
import BenefEducacion from './BenefEducacion';
import BenefRecreacion from './BenefRecreacion';
import BenefOrgSocial from './BenefOrgSocial';
// import BenefIntegrantesHogar from './BenefIntegrantesHogar';
import BenefFamiliares from './BenefFamiliares';
import BenefGenograma from './BenefGenograma';
import BenefFotos from './BenefFotos';
import BenefAudios from './BenefAudios';
import BenefVideos from './BenefVideos';
import BenefObservaciones from './BenefObservaciones';

import BenefTerapias from './BenefTerapias';
// import BenefAuxilios from './BenefAuxilios';

import styled from "styled-components";

import closecircleIcon from '../../assets/icons/closecircle_icon.png';


const BeneficiarioDetailsContainer = styled.div`
    width: 100%;
    height: 100vh;
    background: #fff;
`;

const CardBenefDetailsContainer = styled.div`
    // position: relative;
    // display: flex;
    // border: 1px solid #ccc;
    // padding: 0;
    // margin: 5px;
    // height: 100%;
    // flex-wrap: wrap;
    // width: auto;
`;

const CardBenefContainerClose = styled.div`
    position: absolute;
    width: 24px;
    height: 24px;
    right: 5px;
    top: 5px;
    font-size: 24px;
    cursor: pointer;
    z-index: 101;
    background: rgba(255, 255, 255, 0.1) url(${closecircleIcon}) no-repeat center/cover;
    opacity: 0.4;

    &:hover {
        opacity: 1;
    }
`;

const BeneficiarioDetails = props => {
    const config = useSelector(state => state.config);
    const users = useSelector(state => state.users);
    // const isAdmin = (users.user.tipo==='ADMINISTRADOR' && users.user.role==='A' && users.user.permisos==='A') ? true : false;
    // const canView = (users.user.areatrabajo==='TECNOLOGÍA' 
    //     || users.user.areatrabajo==='ADMINISTRATIVA' 
    //     || users.user.areatrabajo==='TRABAJO SOCIAL' 
    //     || users.user.areatrabajo==='COORDINACIÓN' 
    //     || users.user.areatrabajo==='PSICOLOGÍA' ) ? true : false;

    // const canView = ( users.user.areatrabajo==='TRABAJO SOCIAL' 
    //     || users.user.areatrabajo==='COORDINACIÓN' 
    //     || users.user.areatrabajo==='PSICOLOGÍA' ) ? true : false;
    
    const canView = ( users.user.role==='L' || users.user.role==='A' ) ? true : false;
    
    const datosSelBenef = useSelector(state => state.beneficiarios.datosSelBenef);
    const selectedProfile = useSelector(state => state.beneficiarios.selectedProfile);
    const [Beneficiario,setBeneficiario] = useState(null);
    const listBenef = useSelector(state => state.beneficiarios.listBenef);
    // const listStatsCaddie = useSelector(state => state.cumpli.listStatsCaddie);
    const dispatch = useDispatch();

    useEffect(() => {
        const getBeneficiarioByDocId = (docId) => {
            return listBenef.filter((benef, i) => benef.docId === docId);
        };

        if (selectedProfile && selectedProfile.docId!==''){
            setBeneficiario(getBeneficiarioByDocId(selectedProfile.docId)[0]);
        }
    }, [selectedProfile, listBenef, dispatch]);

    const onCloseBenefDetailsWin = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setBeneficiario(null);
        dispatch(selectBeneficiarioForPerfil({selectedProfile:null}));
    }

    if (Beneficiario){
        // let beneficiarios_raw = null; 
        let perfilbeneficiario = null;
        let predio = null;
        let encuestado = null;
        let vivienda = null;
        let infoeconomica = null;
        // let familiares = null;
        let integranteshogar = null;
        let aportantes = null;
        let ahorradores = null;
        let animales = null;
        let salud = null;
        let recreacion = null;
        let educacion =  null;
        let educacion_enhogar = null;
        let educacion_capacitacion = null;
        let educacion_habilidades = null;
        let orgsocial = null;
        let citas = null;
        let genograma = null;
        let fotos = null;
        let videos = null;
        let audios = null;
        let encuesta_observaciones = null;
        let notificaciones = null;
        let cumplimiento = null;
        let tallaje = null;
        let terapias = null;
        // let auxilios = null;

        if (datosSelBenef){
            if (datosSelBenef.length<=0) return (
                <></>
            );
            // console.log("datosSelBenef==>", datosSelBenef);
            // 0: "beneficiarios" -> Estos ya se tienen de antemano.
            // if ( datosSelBenef['beneficiarios'] && datosSelBenef['beneficiarios'].length>0){
            //     beneficiarios_raw = datosSelBenef['beneficiarios'][0];
            // }
            // 1: "predio"
            if ( datosSelBenef['predio'] && datosSelBenef['predio'].length>0){
                predio = datosSelBenef['predio'][0];
            }
            // 2: "perfilbeneficiario"
            if ( datosSelBenef['perfilbeneficiario'] && datosSelBenef['perfilbeneficiario'].length>0){
                perfilbeneficiario = datosSelBenef['perfilbeneficiario'][0];
            }
            // 3: "encuestado"
            if ( datosSelBenef['encuestado'] && datosSelBenef['encuestado'].length>0){
                encuestado = datosSelBenef['encuestado'][0];
            }
            // 4: "vivienda"
            if ( datosSelBenef['vivienda'] && datosSelBenef['vivienda'].length>0){ 
                vivienda = datosSelBenef['vivienda'][0]; 
            }
            // 5: "infoeconomica"
            if ( datosSelBenef['infoeconomica'] && datosSelBenef['infoeconomica'].length>0){ 
                infoeconomica = datosSelBenef['infoeconomica'][0];
            }
            // 6: "beneficiario_integranteshogar"
            // if ( datosSelBenef['beneficiario_integranteshogar'] && datosSelBenef['beneficiario_integranteshogar'].length>0){
                integranteshogar = datosSelBenef['beneficiario_integranteshogar'];
            // }
            // 6a: "beneficiario_aportantes"
            if ( datosSelBenef['beneficiario_aportantes'] && datosSelBenef['beneficiario_aportantes'].length>0){
                aportantes = datosSelBenef['beneficiario_aportantes'];
                infoeconomica['aportantes'] = aportantes;
            }
            // 6b: "beneficiario_ahorradores"
            if ( datosSelBenef['beneficiario_ahorradores'] && datosSelBenef['beneficiario_ahorradores'].length>0){
                ahorradores = datosSelBenef['beneficiario_ahorradores'];
                infoeconomica['ahorradores'] = ahorradores;
            }
            // 6c: "beneficiario_animales"
            if ( datosSelBenef['beneficiario_animales'] && datosSelBenef['beneficiario_animales'].length>0){
                animales = datosSelBenef['beneficiario_animales'];
                vivienda['animaleshogar'] = animales;
            }
            // 7: "salud"
            if ( datosSelBenef['salud'] && datosSelBenef['salud'].length>0){
                salud = datosSelBenef['salud'][0];
            }
            // 8: "recreacion"
            if ( datosSelBenef['recreacion'] && datosSelBenef['recreacion'].length>0){ 
                recreacion = datosSelBenef['recreacion'][0];
            }
            // 9: "educacion"
            if ( datosSelBenef['educacion'] && datosSelBenef['educacion'].length>0){ 
                educacion = datosSelBenef['educacion'][0];
            }
            // 9a: "educacion_enhogar"
            if ( datosSelBenef['educacion_enhogar'] && datosSelBenef['educacion_enhogar'].length>0){
                educacion_enhogar = datosSelBenef['educacion_enhogar'];
                educacion['educacionenhogar'] = educacion_enhogar;
            }
            // 9b: "educacion_capacitacion"
            if ( datosSelBenef['educacion_capacitacion'] && datosSelBenef['educacion_capacitacion'].length>0){
                educacion_capacitacion = datosSelBenef['educacion_capacitacion'];
                educacion['capacitacion'] = educacion_capacitacion;
            }
            // 9c: "educacion_habilidades"
            if ( datosSelBenef['educacion_habilidades'] && datosSelBenef['educacion_habilidades'].length>0){
                educacion_habilidades = datosSelBenef['educacion_habilidades'];
                educacion['habilidades'] = educacion_habilidades;
            }
            // 10: "orgsocial"
            if ( datosSelBenef['orgsocial'] && datosSelBenef['orgsocial'].length>0){
                orgsocial = datosSelBenef['orgsocial'][0];
            }
            // 11: "citas"
            if ( datosSelBenef['citas'] && datosSelBenef['citas'].length>0){
                citas = datosSelBenef['citas'][0];
            }
            // 12: "encuesta_observaciones"
            if ( datosSelBenef['encuesta_observaciones'] && datosSelBenef['encuesta_observaciones'].length>0){
                encuesta_observaciones = datosSelBenef['encuesta_observaciones'][0];
            }
            // 11: "genograma"
            if ( datosSelBenef['genograma']){
                genograma = datosSelBenef['genograma'];
            }
            // "grb_notificaciones"
            // if ( datosSelBenef['grb_notificaciones'] && datosSelBenef['grb_notificaciones'].length>0){
                notificaciones = datosSelBenef['grb_notificaciones']; // Array of Objects
            // }
            // grb_historicoreservas_golf
            if ( datosSelBenef['grb_historicoreservas_golf'] && datosSelBenef['grb_historicoreservas_golf'].length>0){
                cumplimiento = datosSelBenef['grb_historicoreservas_golf'];
            }
            // grb_vestuario_tallaje
            if ( datosSelBenef['grb_vestuario_tallaje'] && datosSelBenef['grb_vestuario_tallaje'].length>0){
                tallaje = datosSelBenef['grb_vestuario_tallaje'][0];
            }
            // grb_beneficiarios_terapias
            // if ( datosSelBenef['grb_beneficiarios_terapias'] && datosSelBenef['grb_beneficiarios_terapias'].length>0){
                terapias = datosSelBenef['grb_beneficiarios_terapias'];
            // }
            // grb_auxilios
            // if ( datosSelBenef['grb_auxilios'] && datosSelBenef['grb_auxilios'].length>0){
            //     auxilios = datosSelBenef['grb_auxilios'];
            // }
            


        return (
            <BeneficiarioDetailsContainer>
                <CardBenefContainerClose onClick={onCloseBenefDetailsWin} title="Cerrar Detalle Beneficiario"/>
                <CardBenefDetailsContainer>
                    <div className="cardBenefDetailsContainer-header-first">
                        <BenefHeader Beneficiario={Beneficiario} ECASE_URL={config.ECASE_URL}/>
                        <BenefCumplimiento cumplimiento={cumplimiento}/>
                    </div>
                    
                    {canView && terapias && <BenefTerapias terapias={terapias} beneficiarioId={Beneficiario.docId}/> }
                    {/* {canView && auxilios && <BenefAuxilios auxilios={auxilios}/> } */}
                    {canView && notificaciones && <BenefNotificaciones notificaciones={notificaciones} beneficiarioId={Beneficiario.docId} /> }
                    {canView && perfilbeneficiario && <BenefPerfilBeneficiario perfilbeneficiario={perfilbeneficiario} basicos={Beneficiario} /> }
                    {canView && tallaje && <BenefVestuarioTallaje tallaje={tallaje} basicos={Beneficiario} /> }
                    {canView && integranteshogar && <BenefFamiliares integranteshogar={integranteshogar} beneficiarioId={Beneficiario.docId}/>}
                    {/* {canView && integranteshogar && <BenefIntegrantesHogar integranteshogar={integranteshogar} beneficiarioId={Beneficiario.docId}/>} */}
                    {canView && predio && <BenefLocalizacion predio={predio} />}
                    {canView && encuestado && <BenefEncuestado encuestado={encuestado} />}
                    {canView && vivienda && <BenefVivienda vivienda={vivienda} />}
                    {canView && infoeconomica && <BenefInfoEconomica infoeconomica={infoeconomica} />}
                    {canView && salud && <BenefSalud salud={salud} />}
                    {canView && educacion && <BenefEducacion educacion={educacion} />}
                    {canView && recreacion && <BenefRecreacion recreacion={recreacion} />}
                    {canView && orgsocial && <BenefOrgSocial orgsocial={orgsocial} />}
                    {canView && genograma && <BenefGenograma genograma={config.ECASE_URL+genograma} />}
                    {canView && fotos && <BenefFotos fotos={fotos} />}
                    {canView && audios && <BenefAudios audios={audios} />}
                    {canView && videos && <BenefVideos videos={videos} />}
                    {canView && encuesta_observaciones && <BenefObservaciones obs={encuesta_observaciones} />}
                    {canView && citas && <BenefCitas citas={citas}/>}
                </CardBenefDetailsContainer>
            </BeneficiarioDetailsContainer>
            );

        } else { 
            return (
                <></>
            );
        }
    }
    return (
        <></>
    );
}

export default BeneficiarioDetails;

