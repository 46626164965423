import {capitalize} from '../../../../utils/utils';
import moment from 'moment';
import 'moment/locale/es-us';

class BenefObjectDataListStore {
  constructor(/*array*/ listEntrega){
    this.size = listEntrega.length;
    this._cache = [];
    this.data = listEntrega;
  }

  createRowObjectData(/*number*/ index) /*object*/ {
    const ECASE_URL = 'http://fundacionclubfarallones.org/investigacion/ECASE/beta/user_images/';
    return {
      id: index,
      foto: (this.data[index].foto!=='' ? ECASE_URL + this.data[index].foto : '../../../../assets/team/usuario.jpg'),
      beneficiarioId: this.data[index].beneficiarioId,
      docIdquienreclama: this.data[index].docIdquienreclama,
      nombrequienreclama: this.data[index].nombrequienreclama!=='' ? capitalize(this.data[index].nombrequienreclama, true) : '',
      numentrega: this.data[index].numentrega,
      convenio: this.data[index].convenio,
      almacen: this.data[index].almacen,
      tipoayuda: this.data[index].tipoayuda,
      fechainicio: moment(this.data[index].fechainicio).format("YYYY-MM-DD"), //this.data[index].fechainicio,
      fechavigencia: moment(this.data[index].fechavigencia).format("YYYY-MM-DD"),
      fechacaducidad: moment(this.data[index].fechacaducidad).format("YYYY-MM-DD"),
      cupo: this.data[index].cupo,
      cupoxperc: parseFloat(this.data[index].cupoxperc).toFixed(0),
      perc_afect: parseFloat(this.data[index].perc_afect).toFixed(0),
      perc_afect2: parseFloat(this.data[index].perc_afect2).toFixed(0),
      valorfinal: this.data[index].valorfinal,
      afectacion: this.data[index].afectacion,
      tipoafectacion: this.data[index].tipoafectacion,
      tipoafectacion2: this.data[index].tipoafectacion2,
      tipoafectacionescalado: this.data[index].tipoafectacionescalado,
      afectacionfinal: this.data[index].afectacionfinal,
      afectacionfinal2: this.data[index].afectacionfinal2,
      prestarservicio: parseInt(this.data[index].prestarservicio) === 1 ? 'SI' : 'NO',
      vlr_prestarservicio: this.data[index].vlr_prestarservicio,
      jefehogar: this.data[index].jefehogar,
      vlr_jefehogar: this.data[index].vlr_jefehogar,
      personasacargo: this.data[index].personasacargo,
      vlr_personasacargo: this.data[index].vlr_personasacargo,
      numhijos: this.data[index].numhijos,
      vlr_numhijos: this.data[index].vlr_numhijos,
      tienefmliaclub: parseInt(this.data[index].tienefmliaclub) === 1 ? 'SI' : 'NO', //this.data[index].tienefmliaclub,
      vlr_tienefmliaclub: this.data[index].vlr_tienefmliaclub,
      actividad: this.data[index].actividad,
      vlr_actividad: this.data[index].vlr_actividad,
      categoria: this.data[index].categoria,
      vlr_categoria: this.data[index].vlr_categoria,
      asistencia: parseFloat(this.data[index].asistencia).toFixed(2), //this.data[index].asistencia,
      vlr_asistencia: this.data[index].vlr_asistencia,
      edad: this.data[index].edad,
      vlr_edad: this.data[index].vlr_edad,
      edadclub: this.data[index].edadclub,
      vlr_edadclub: this.data[index].vlr_edadclub,
      comentarios: this.data[index].comentarios,
      
    };
  }

  getObjectAt(/*number*/ index) /*?object*/ {
    if (index < 0 || index > this.size){
      return undefined;
    }
    if (this._cache[index] === undefined) {
      this._cache[index] = this.createRowObjectData(index);
    }
    return this._cache[index];
  }

  /**
  * Populates the entire cache with data.
  * Use with Caution! Behaves slowly for large sizes
  * ex. 100,000 rows
  */
  getAll() {
    if (this._cache.length < this.size) {
      for (var i = 0; i < this.size; i++) {
        this.getObjectAt(i);
      }
    }
    return this._cache.slice();
  }

  getSize() {
    return this.size;
  }
}

export default BenefObjectDataListStore;
