import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { FixedSizeList as List } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import { fetchBeneficiarioPerfilDataxCumpli } from '../../redux/reducers/cumpli';
import {capitalize} from '../../utils/utils'; //formatMoney, 
// import moment from 'moment';
// import 'moment/locale/es-us';

import './caddiemaster_styles.css';
// Styled Components
// import styled from "styled-components";

export default function ListBeneficiariosWrapper({
  // Are there more items to load?
  // (This information comes from the most recent API request.)
  hasNextPage,

  // Are we currently loading a page of items?
  // (This may be an in-flight flag in your Redux store for example.)
  isNextPageLoading,

  // Array of items loaded so far.
  items,

  // Callback function responsible for loading the next page of items.
  loadNextPage
}) {
  // Load config 
  const config = useSelector(state => state.config);
  const users = useSelector(state => state.users);
  const dispatch = useDispatch();

  // If there are more items to be loaded then add an extra row to hold a loading indicator.
  const itemCount = hasNextPage ? items.length + 1 : items.length;

  // Only load 1 page of items at a time.
  // Pass an empty callback to InfiniteLoader in case it asks us to load more than once.
  const loadMoreItems = isNextPageLoading ? () => {} : loadNextPage;

  // Every row is loaded except for our loading indicator row.
  const isItemLoaded = index => !hasNextPage || index < items.length;

  const onClickBenefItem = (e, docId) => {
    e.preventDefault();
    e.stopPropagation();
    // e.nativeEvent.stopImmediatePropagation();
    // Obtenga todos los datos asociados al Beneficiario
    dispatch(fetchBeneficiarioPerfilDataxCumpli({
        token: users.user.claveAPI,
        docId: docId,
        req: "" //tabla de datos, deje en blanco para todas las relacionadas con el Beneficiario
    }));
    
    // dispatch(selectBeneficiarioForPerfil({selectedProfile:{docId:docId}}))
  }

  // Render an item or a loading indicator.
  const Item = ({ index, style }) => {
    let content;
    if (!isItemLoaded(index)) {
      content = ""; //"Cargando Beneficiario...";
    } else {
      if (items[index]){
        // const foto = items[index].foto==='' ? require('../../assets/team/usuario.jpg') : config.ECASE_URL +'user_images/'+ items[index].foto; 
        const foto = items[index].foto==='' ? process.env.PUBLIC_URL + '/imgs/team/usuario.jpg' : config.ECASE_URL +'user_images/'+ items[index].foto; 
        
        // const actualizacion = moment(items[index].actualizacion).format("YYYY-MM-DD HH:mm:ss");
        // const fullname = capitalize(items[index].nombres, true) + ' ' + capitalize(items[index].apellidos, true);
        
        content = <div className="cardBenefItemMaster" onClick={e => onClickBenefItem(e, items[index].docId)}>
                      <div className="cardBenefItemMaster-foto">
                        <img src={foto} alt=" "/>
                        <div className="cardBenefItemMaster-desc-caddiemaster">
                            <div className="cardBenefItemMaster-title">
                              <span className="cardBenefItemMaster-title-surname">{capitalize(items[index].apellidos, true)}</span>
                              <span className="cardBenefItemMaster-title-name">{capitalize(items[index].nombres, true)}</span>
                            </div>
                            <div className="cardBenefItemMaster-subtitle">{items[index].actividad}  <span className="cardBenefItemMaster-categoria">{items[index].categoria}</span></div>
                            
                            {/* <div className="cardBenefItemMaster-docId">C.C.: {items[index].docId}</div>
                            <div className="cardBenefItemMaster-docId">Cel.: {items[index].celular}</div> */}

                            {/* <div className="cardBenefItemMaster-benef-actualizacion">{actualizacion}</div> */}
                        </div>
                      </div>
                  </div>;
                            
      } else {
        content = <div></div>
      }
      
    }
    return <div style={style}>{content}</div>;
  };

  return (
    <InfiniteLoader 
      isItemLoaded={isItemLoaded}
      itemCount={itemCount}
      loadMoreItems={loadMoreItems}
    >
      {({ onItemsRendered, ref }) => (
        <List style={{minWidth:'390px'}}
          // className="cardBenefContainer"
          height={480}
          itemCount={itemCount}
          itemSize={45}
          onItemsRendered={onItemsRendered}
          ref={ref}
        >
          {Item}
        </List>
      )}
    </InfiniteLoader>
  );
}
