// // Copyright 2020 Ernesto Girón E.
// // Licensed under the Apache License, Version 2.0 (the "License");
// // you may not use this file except in compliance with the License.
// // You may obtain a copy of the License at
// //     http://www.apache.org/licenses/LICENSE-2.0
// // Unless required by applicable law or agreed to in writing, software
// // distributed under the License is distributed on an "AS IS" BASIS,
// // WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// // See the License for the specific language governing permissions and
// // limitations under the License.​

import API from '../utils/ecaseAPI';

/* 
 * Make a request for list of Beneficiarios
 * @return Array of Beneficiarios
 */
export async function getBeneficiariosDataxVestuarioTallaje(action) {
    let listBenefTallaje = null;
      try {
        await API('/tallaje/', { 
            method: 'POST',
            data: JSON.stringify({"api":action.token})
        }).then(response => response.data).then((data) => {
            listBenefTallaje = data.datos.map(d => {
              d.actualizacion = new Date(d.actualizacion);
              return d
            });
            listBenefTallaje = data.datos
        }).catch(function (error) {
            // handle error
            console.log(`😱 problemas obteniendo los datos de Beneficiarios del GRB.`);
            console.log(error);
        })
  
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
      return listBenefTallaje;
}

/* 
 * Make a request for Beneficiarios whole data
 * @return Array of Beneficiarios
 */
export async function getBeneficiarioPerfilDataxTallaje(action) {
    let datosBenef = null;
      try {
        await API('/beneficiarios/perfil', {
            method: 'POST',
            data: JSON.stringify({"api":action.token, "docId":action.docId, "req":action.req})
        }).then(response => response.data).then((data) => {
            datosBenef = data.datos
        }).catch(function (error) {
            console.log(`😱 problemas obteniendo los datos de Beneficiarios del GRB.`);
            console.log(error);
        })
  
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
      return datosBenef;
}


/* 
 * Registra nuevos datos de lista de tallaje 
 * @return 
 */
export async function crearVestuarioTallaje(action) {
    let resp = null;
    try {
        await API('/tallaje/insert', {
            method: 'POST',
            data: JSON.stringify({"api":action.token, "tallaje":action.tallaje})
        }).then(response => response.data).then((data) => {
            resp = data.mensaje;
        }).catch((error) => {
            resp = '😱 Error ('+error.response.status+'): Problema registrando los datos de tallaje en el servidor. ' + error.response.data.mensaje;
            console.log(`😱 problemas registrando la tabla de tallaje en GRB.`, error.response.data.mensaje);
            return resp;
        })
  
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
    return resp;
}
/* 
 * Actualiza los datos de lista de tallaje 
 * @return 
 */
export async function actualizarVestuarioTallaje(action) {
    let resp = null;
    try {
        await API('/tallaje/update', {
            method: 'POST',
            data: JSON.stringify({"api":action.token, "tallaje":action.tallaje})
        }).then(response => response.data).then((data) => {
            resp = data.mensaje;
        }).catch((error) => {
            resp = '😱 Error ('+error.response.status+'): Problema guardando los datos de tallaje en el servidor. ' + error.response.data.mensaje;
            console.log(`😱 problemas actualizando la tabla de tallaje en GRB.`, error.response.data.mensaje);
            return resp;
        })
        
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
    return resp;
}
/* 
 * Elimina datos de lista de tallaje 
 * @return 
 */
export async function eliminarVestuarioTallaje(action) {
    let resp = null;
    try {
        await API('/tallaje/delete', {
            method: 'POST',
            data: JSON.stringify({"api":action.token, "tallaje":action.tallaje})
        }).then(response => response.data).then((data) => {
            resp = data.mensaje;
        }).catch((error) => {
            resp = '😱 Error ('+error.response.status+'): Problema eliminando los datos de tallaje en el servidor. ' + error.response.data.mensaje;
            console.log(`😱 problemas eliminando la tabla de tallaje en GRB.`, error.response.data.mensaje);
            return resp;
        })
  
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
    return resp;
}