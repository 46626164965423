// Copyright 2019 Ernesto Giron E.
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//     http://www.apache.org/licenses/LICENSE-2.0
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.​

// ACTION TYPES //
export const types = {
  MODULE_BENEFICIARIOS_LOADED: "MODULE_BENEFICIARIOS_LOADED",
  MODULE_BENEFICIARIOS_FILTER_OPEN: "MODULE_BENEFICIARIOS_FILTER_OPEN",
  MODULE_BENEFICIARIOS_EDIT: "MODULE_BENEFICIARIOS_EDIT",
  BENEFICIARIOS_DATA_LOADED: "BENEFICIARIOS_DATA_LOADED",
  BENEFICIARIOS_LIST_LOADED: "BENEFICIARIOS_LIST_LOADED",
  BENEFICIARIOS_LIST_FILTERED: "BENEFICIARIOS_LIST_FILTERED",
  BENEFICIARIOS_ISFILTER_SHOW: "BENEFICIARIOS_ISFILTER_SHOW",
  BENEFICIARIO_SELECTED: "BENEFICIARIO_SELECTED",
  BENEFICIARIO_PROFILE_SELECTED: "BENEFICIARIO_PROFILE_SELECTED",
  BENEFICIARIO_DATA_SELECTED: "BENEFICIARIO_DATA_SELECTED",
  BENEFICIARIO_SELECTED_DATA_REQUEST: "BENEFICIARIO_SELECTED_DATA_REQUEST",
  // BENEFICIARIO_SELECTED_DATA_EDITED: "BENEFICIARIO_SELECTED_DATA_EDITED",
  BENEFICIARIO_SELECTED_BASICOS_UPDATED: "BENEFICIARIO_SELECTED_BASICOS_UPDATED",
  BENEFICIARIO_SELECTED_PERFIL_UPDATED: "BENEFICIARIO_SELECTED_PERFIL_UPDATED",
  BENEFICIARIO_SELECTED_CITA_UPDATED: "BENEFICIARIO_SELECTED_CITA_UPDATED",
  BENEFICIARIO_SELECTED_PREDIO_UPDATED: "BENEFICIARIO_SELECTED_PREDIO_UPDATED",
  BENEFICIARIO_SELECTED_ENCUESTADO_UPDATED: "BENEFICIARIO_SELECTED_ENCUESTADO_UPDATED",
  BENEFICIARIO_SELECTED_INTEGRANTESHOGAR_UPDATED: "BENEFICIARIO_SELECTED_INTEGRANTESHOGAR_UPDATED",
  BENEFICIARIO_SELECTED_INTEGRANTESHOGAR_CREATED: "BENEFICIARIO_SELECTED_INTEGRANTESHOGAR_CREATED",
  BENEFICIARIO_SELECTED_INTEGRANTESHOGAR_DELETED: "BENEFICIARIO_SELECTED_INTEGRANTESHOGAR_DELETED",
  BENEFICIARIO_SELECTED_VIVIENDA_UPDATED: "BENEFICIARIO_SELECTED_VIVIENDA_UPDATED",
  BENEFICIARIO_SELECTED_ANIMALES_UPDATED: "BENEFICIARIO_SELECTED_ANIMALES_UPDATED",
  BENEFICIARIO_SELECTED_ANIMALES_CREATED: "BENEFICIARIO_SELECTED_ANIMALES_CREATED",
  BENEFICIARIO_SELECTED_ANIMALES_DELETED: "BENEFICIARIO_SELECTED_ANIMALES_DELETED",
  BENEFICIARIO_SELECTED_INFOECONOMICA_UPDATED: "BENEFICIARIO_SELECTED_INFOECONOMICA_UPDATED",
  BENEFICIARIO_SELECTED_AHORRADORES_UPDATED: "BENEFICIARIO_SELECTED_AHORRADORES_UPDATED",
  BENEFICIARIO_SELECTED_AHORRADORES_CREATED: "BENEFICIARIO_SELECTED_AHORRADORES_CREATED",
  BENEFICIARIO_SELECTED_AHORRADORES_DELETED: "BENEFICIARIO_SELECTED_AHORRADORES_DELETED",
  BENEFICIARIO_SELECTED_APORTANTES_UPDATED: "BENEFICIARIO_SELECTED_APORTANTES_UPDATED",
  BENEFICIARIO_SELECTED_APORTANTES_CREATED: "BENEFICIARIO_SELECTED_APORTANTES_CREATED",
  BENEFICIARIO_SELECTED_APORTANTES_DELETED: "BENEFICIARIO_SELECTED_APORTANTES_DELETED",
  BENEFICIARIO_SELECTED_SALUD_UPDATED: "BENEFICIARIO_SELECTED_SALUD_UPDATED",
  BENEFICIARIO_SELECTED_EDUCACION_UPDATED: "BENEFICIARIO_SELECTED_EDUCACION_UPDATED",
  BENEFICIARIO_SELECTED_EDUCACIONENHOGAR_UPDATED: "BENEFICIARIO_SELECTED_EDUCACIONENHOGAR_UPDATED",
  BENEFICIARIO_SELECTED_EDUCACIONENHOGAR_CREATED: "BENEFICIARIO_SELECTED_EDUCACIONENHOGAR_CREATED",
  BENEFICIARIO_SELECTED_EDUCACIONENHOGAR_DELETED: "BENEFICIARIO_SELECTED_EDUCACIONENHOGAR_DELETED",
  BENEFICIARIO_SELECTED_CAPACITACION_UPDATED: "BENEFICIARIO_SELECTED_CAPACITACION_UPDATED",
  BENEFICIARIO_SELECTED_CAPACITACION_CREATED: "BENEFICIARIO_SELECTED_CAPACITACION_CREATED",
  BENEFICIARIO_SELECTED_CAPACITACION_DELETED: "BENEFICIARIO_SELECTED_CAPACITACION_DELETED",
  BENEFICIARIO_SELECTED_HABILIDADES_UPDATED: "BENEFICIARIO_SELECTED_HABILIDADES_UPDATED",
  BENEFICIARIO_SELECTED_HABILIDADES_CREATED: "BENEFICIARIO_SELECTED_HABILIDADES_CREATED", 
  BENEFICIARIO_SELECTED_HABILIDADES_DELETED: "BENEFICIARIO_SELECTED_HABILIDADES_DELETED",
  BENEFICIARIO_SELECTED_RECREACION_UPDATED: "BENEFICIARIO_SELECTED_RECREACION_UPDATED",
  BENEFICIARIO_SELECTED_ORGSOCIAL_UPDATED: "BENEFICIARIO_SELECTED_ORGSOCIAL_UPDATED",
  BENEFICIARIO_SELECTED_OBS_UPDATED: "BENEFICIARIO_SELECTED_OBS_UPDATED",

  BENEFICIARIO_SELECTED_VESTUARIOTALLAJE_UPDATED:"BENEFICIARIO_SELECTED_VESTUARIOTALLAJE_UPDATED",
  BENEFICIARIO_SELECTED_VESTUARIOTALLAJE_CREATED: "BENEFICIARIO_SELECTED_VESTUARIOTALLAJE_CREATED",
  BENEFICIARIO_SELECTED_VESTUARIOTALLAJE_DELETED: "BENEFICIARIO_SELECTED_VESTUARIOTALLAJE_DELETED",

  BENEFICIARIO_SERVER_MESSAGE: "BENEFICIARIO_SERVER_MESSAGE"
};

// REDUCERS //
export const initialState = {
  loaded: false,
  isFilterShow: false,
  edit: false,
  openfilter: false,
  filtername: '',
  listBenef: [],
  selected: null,
  selectedProfile: null,
  datosSelBenef: [],
  filter: [],
  mensaje: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.MODULE_BENEFICIARIOS_LOADED:
      const { loaded } = action.payload;
      return {
        ...state,
        loaded
      };
    case types.MODULE_BENEFICIARIOS_EDIT:
      const { edit } = action.payload;
      return {
        ...state,
        edit
      };
    case types.MODULE_BENEFICIARIOS_FILTER_OPEN:
      const { openfilter, filtername } = action.payload;
      return {
        ...state,
        openfilter, 
        filtername
      };
    case types.BENEFICIARIOS_LIST_LOADED:
      const { listBenef } = action.payload;
      return {
        ...state,
        listBenef
      };
    case types.BENEFICIARIOS_LIST_FILTERED:
      const { filter } = action.payload;
      return {
        ...state,
        filter
      };
    case types.BENEFICIARIOS_ISFILTER_SHOW:
      const { isFilterShow } = action.payload;
      return {
        ...state,
        isFilterShow
      };
    case types.BENEFICIARIO_SELECTED:
      const { selected } = action.payload;
      return {
        ...state,
        selected
      };
    case types.BENEFICIARIO_PROFILE_SELECTED:
      return {
        ...state,
        selectedProfile:action.payload.selectedProfile
      };
    case types.BENEFICIARIO_DATA_SELECTED:
      const { datosSelBenef, selectedProfile } = action.payload;
      return {
        ...state,
        datosSelBenef,
        selectedProfile
      };
    case types.BENEFICIARIO_SERVER_MESSAGE:
      const {mensaje} = action.payload;
      return {
        ...state,
        mensaje
      };
    default:
      return state;
  }
};

// ACTIONS //
export const moduleBENEFICIARIOSLoaded = options => ({ type: types.MODULE_BENEFICIARIOS_LOADED, payload: options });
export const displayBeneficiariosFilter = options => ({ type: types.BENEFICIARIOS_ISFILTER_SHOW, payload: options });

export const editBeneficiario = options => ({ type: types.MODULE_BENEFICIARIOS_EDIT, payload: options });
export const changeFilterBeneficiarios = options => ({ type: types.MODULE_BENEFICIARIOS_FILTER_OPEN, payload: options });
export const fetchBeneficiariosData = token => ({ 
  type: types.BENEFICIARIOS_DATA_LOADED, 
  payload: {
    token: token
  }
});

export const updateEditedBeneficiarioData = listBenef => ({ 
  type: types.BENEFICIARIOS_LIST_LOADED, 
  payload: listBenef
});

export const filterBeneficiariosData = filter => ({ 
  type: types.BENEFICIARIOS_LIST_FILTERED, 
  payload: {
    filter: filter
  }
});
export const resetfilterBeneficiariosData = () => ({ 
  type: types.BENEFICIARIOS_LIST_FILTERED, 
  payload: {filter: []}
});

export const selectBeneficiario = docId => ({ 
  type: types.BENEFICIARIO_SELECTED, 
  payload: {
    selected: docId
  }
});

export const selectBeneficiarioForPerfil = selectedProfile => ({ 
  type: types.BENEFICIARIO_PROFILE_SELECTED, 
  payload: selectedProfile
});
export const resetSelectedBeneficiarioForPerfil = () => ({ 
  type: types.BENEFICIARIO_PROFILE_SELECTED, 
  payload: {selectedProfile: null}
});

export const fetchBeneficiarioPerfilData = datosSelBenef => ({ 
  type: types.BENEFICIARIO_SELECTED_DATA_REQUEST, 
  payload: datosSelBenef
});

// export const updateEditedBeneficiarioPerfilData = datosSelBenef => ({ 
//   type: types.BENEFICIARIO_SELECTED_DATA_EDITED, 
//   payload: datosSelBenef
// });


export const updateBeneficiarioBasicos = data => ({ 
  type: types.BENEFICIARIO_SELECTED_BASICOS_UPDATED, 
  payload: data
});

export const updateBeneficiarioPerfil = data => ({ 
  type: types.BENEFICIARIO_SELECTED_PERFIL_UPDATED, 
  payload: data
});

export const updateBeneficiarioCita = data => ({ 
  type: types.BENEFICIARIO_SELECTED_CITA_UPDATED, 
  payload: data
});

export const updateBeneficiarioObs = data => ({ 
  type: types.BENEFICIARIO_SELECTED_OBS_UPDATED, 
  payload: data
});

export const updateBeneficiarioPredio = data => ({ 
  type: types.BENEFICIARIO_SELECTED_PREDIO_UPDATED, 
  payload: data
});

export const updateBeneficiarioEncuestado = data => ({ 
  type: types.BENEFICIARIO_SELECTED_ENCUESTADO_UPDATED, 
  payload: data
});

export const updateBeneficiarioIntegrantesHogar = data => ({ 
  type: types.BENEFICIARIO_SELECTED_INTEGRANTESHOGAR_UPDATED, 
  payload: data
});
export const insertBeneficiarioIntegrantesHogar = data => ({ 
  type: types.BENEFICIARIO_SELECTED_INTEGRANTESHOGAR_CREATED, 
  payload: data
});
export const deleteBeneficiarioIntegrantesHogar = data => ({ 
  type: types.BENEFICIARIO_SELECTED_INTEGRANTESHOGAR_DELETED, 
  payload: data
});

export const updateBeneficiarioVivienda = data => ({ 
  type: types.BENEFICIARIO_SELECTED_VIVIENDA_UPDATED, 
  payload: data
});

export const updateBeneficiarioAnimales = data => ({ 
  type: types.BENEFICIARIO_SELECTED_ANIMALES_UPDATED, 
  payload: data
});
export const insertBeneficiarioAnimales = data => ({ 
  type: types.BENEFICIARIO_SELECTED_ANIMALES_CREATED, 
  payload: data
});
export const deleteBeneficiarioAnimales = data => ({ 
  type: types.BENEFICIARIO_SELECTED_ANIMALES_DELETED, 
  payload: data
});

export const updateBeneficiarioInfoEconomica = data => ({ 
  type: types.BENEFICIARIO_SELECTED_INFOECONOMICA_UPDATED, 
  payload: data
});

export const updateBeneficiarioAhorradores = data => ({ 
  type: types.BENEFICIARIO_SELECTED_AHORRADORES_UPDATED, 
  payload: data
});
export const insertBeneficiarioAhorradores = data => ({ 
  type: types.BENEFICIARIO_SELECTED_AHORRADORES_CREATED, 
  payload: data
});
export const deleteBeneficiarioAhorradores = data => ({ 
  type: types.BENEFICIARIO_SELECTED_AHORRADORES_DELETED, 
  payload: data
});

export const updateBeneficiarioAportantes = data => ({ 
  type: types.BENEFICIARIO_SELECTED_APORTANTES_UPDATED, 
  payload: data
});
export const insertBeneficiarioAportantes = data => ({ 
  type: types.BENEFICIARIO_SELECTED_APORTANTES_CREATED, 
  payload: data
});
export const deleteBeneficiarioAportantes = data => ({ 
  type: types.BENEFICIARIO_SELECTED_APORTANTES_DELETED, 
  payload: data
});

export const updateBeneficiariosSalud = data => ({ 
  type: types.BENEFICIARIO_SELECTED_SALUD_UPDATED, 
  payload: data
});

export const updateBeneficiarioEducacion = data => ({ 
  type: types.BENEFICIARIO_SELECTED_EDUCACION_UPDATED, 
  payload: data
});

export const updateBeneficiarioEducacionenHogar = data => ({ 
  type: types.BENEFICIARIO_SELECTED_EDUCACIONENHOGAR_UPDATED, 
  payload: data
});
export const insertBeneficiarioEducacionenHogar = data => ({ 
  type: types.BENEFICIARIO_SELECTED_EDUCACIONENHOGAR_CREATED, 
  payload: data
});
export const deleteBeneficiarioEducacionenHogar = data => ({ 
  type: types.BENEFICIARIO_SELECTED_EDUCACIONENHOGAR_DELETED, 
  payload: data
});

export const updateBeneficiarioCapacitacion = data => ({ 
  type: types.BENEFICIARIO_SELECTED_CAPACITACION_UPDATED, 
  payload: data
});
export const insertBeneficiarioCapacitacion = data => ({ 
  type: types.BENEFICIARIO_SELECTED_CAPACITACION_CREATED, 
  payload: data
});
export const deleteBeneficiarioCapacitacion = data => ({ 
  type: types.BENEFICIARIO_SELECTED_CAPACITACION_DELETED, 
  payload: data
});

export const updateBeneficiarioHabilidades = data => ({ 
  type: types.BENEFICIARIO_SELECTED_HABILIDADES_UPDATED, 
  payload: data
});
export const insertBeneficiarioHabilidades = data => ({ 
  type: types.BENEFICIARIO_SELECTED_HABILIDADES_CREATED, 
  payload: data
});
export const deleteBeneficiarioHabilidades = data => ({ 
  type: types.BENEFICIARIO_SELECTED_HABILIDADES_DELETED, 
  payload: data
});

export const updateBeneficiarioRecreacion = data => ({ 
  type: types.BENEFICIARIO_SELECTED_RECREACION_UPDATED, 
  payload: data
});

export const updateBeneficiarioOrgSocial = data => ({ 
  type: types.BENEFICIARIO_SELECTED_ORGSOCIAL_UPDATED, 
  payload: data
});

// Tallaje
export const updateBeneficiariosVestuarioTallaje = data => ({ 
  type: types.BENEFICIARIO_SELECTED_VESTUARIOTALLAJE_UPDATED, 
  payload: data
});
export const insertBeneficiarioVestuarioTallaje = data => ({ 
  type: types.BENEFICIARIO_SELECTED_VESTUARIOTALLAJE_CREATED, 
  payload: data
});
export const deleteBeneficiarioVestuarioTallaje = data => ({ 
  type: types.BENEFICIARIO_SELECTED_VESTUARIOTALLAJE_DELETED, 
  payload: data
});



export const serverMensaje = mensaje => ({ 
  type: types.BENEFICIARIO_SERVER_MESSAGE, 
  payload: {
    mensaje: mensaje
  }
});