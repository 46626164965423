import React, {useState, useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";
import ContentEditable from 'react-contenteditable';
import { updateBeneficiarioPerfil, updateBeneficiarioBasicos, 
    updateEditedBeneficiarioData, 
    // updateEditedBeneficiarioPerfilData,
    serverMensaje 
} from '../../redux/reducers/beneficiarios';
import { 
    updateEditedBeneficiarioDataxCovidAyudas,
    updateEditedBeneficiarioDataxCovidAcomp 
} from '../../redux/reducers/covid19';

import {capitalize, highlightAll, disableNewlines, pastePlainText, 
    calculate_age, capitalizeFirstLetter
} from '../../utils/utils';
import Alert, {AlertMessage} from 'calcite-react/Alert';
import Button, { ButtonGroup } from 'calcite-react/Button';
import Switch from 'calcite-react/Switch';
import Accordion, {
    AccordionSection,
    AccordionTitle,
    AccordionContent
} from 'calcite-react/Accordion';
// import Radio from 'calcite-react/Radio';
import CopyToClipboard from 'calcite-react/CopyToClipboard'
import Select from 'calcite-react/Select';
import {MenuItem} from 'calcite-react/Menu';
// import Form, {
//     FormControl,
//     Fieldset,
// } from 'calcite-react/Form';
import DatePicker from 'calcite-react/DatePicker';
import moment from 'moment';
import 'moment/locale/es-us';

import {showToaster} from './BenefShowMesg';
import tiposEducacion from '../../data/tipoeducacion.json';
import estadosCiviles from '../../data/estadocivil.json';
import jornadasEscolares from '../../data/jornadaescolar.json';
import tipoDocs from '../../data/tipodoc.json';
import tipoGeneros from '../../data/generos.json';
import tipoOcupacion from '../../data/ocupaciones.json';
import tipoActividades from '../../data/actividades.json';
import tipoEstados from '../../data/estados.json';
import tipoCategorias from '../../data/categorias.json';
import tipoCategoriasSeguimiento from '../../data/categorias_seguimiento.json';
import tiposProgramas from '../../data/tipoprogramas.json';

import styled from "styled-components";

const BeneficiarioModulo = styled.div`
    height: auto;
    background: #fff;
    border: 0;
    padding: 0px;
    margin: 4px;
    overflow:hidden;
`;
const AccordionModulo = styled(Accordion)`
    padding:0;
`;
const AccordionTitleModule = styled(AccordionTitle)`
    background-color: #e6e6e6;
    color: #4d4d4d;
    font-size: 1.25em;
    font-weight: 700;
    // border-bottom: 1px solid #d9d9d9;
    // box-shadow: 0px 2px 6px #e1e1e1;
`;


const BenefPerfilBeneficiario = ({perfilbeneficiario, basicos} ) => {
    const dispatch = useDispatch();
    const mensaje = useSelector(state => state.beneficiarios.mensaje);
    const listBenef = useSelector(state => state.beneficiarios.listBenef);
    const listBenefAyudas = useSelector(state => state.covid19.listBenef);
    const listBenefAcomp = useSelector(state => state.covid19.listBenefAcomp);
    // const datosSelBenef = useSelector(state => state.beneficiarios.datosSelBenef);
    const users = useSelector(state => state.users);
    // const isEditable = users.user.tipo==='ADMINISTRADOR' ? true : false;
    const isEditable = (users.user.areatrabajo==='ADMINISTRATIVA' || users.user.areatrabajo==='TRABAJO SOCIAL' || users.user.areatrabajo==='COORDINACIÓN' || users.user.areatrabajo==='PSICOLOGÍA' ) ? true : false;
    
    // const [isUpdatedlistBenef, setIsupdatedlistBenef] = useState(false);
    const [activeSectionIndexes, setActiveSectionIndexes] = useState([]);
    const [estudiaActualmenteSelected, setEstudiaActualmenteSelected] = useState(parseInt(perfilbeneficiario.estudiaactualmente));
    const [generoSelectedValue, setGeneroSelectedValue] = useState(perfilbeneficiario.genero);
    const [jefehogarSelected, setJefehogarSelected] = useState(perfilbeneficiario.jefehogar);
    const [tipodocSelectedValue, setTipodocSelectedValue] = useState(perfilbeneficiario.tipodoc);
    const [estadocivilSelectedValue, setEstadoCivilSelectedValue] = useState(capitalizeFirstLetter(perfilbeneficiario.estadocivil));
    const [educacionSelectedValue, setEducacionSelectedValue] = useState(capitalize(perfilbeneficiario.educacion, true));
    const [jornadaSelectedValue, setJornadaSelectedValue] = useState(perfilbeneficiario.jornada==='' ? 'No Aplica' : capitalize(perfilbeneficiario.jornada, true));
    const [fechaNacimiento, setFechaNacimiento] = useState(moment(perfilbeneficiario.fechanacimiento.split(' ')[0],'YYYY-MM-DD'));
    // const [fechaNacimiento, setFechaNacimiento] = useState(moment(basicos.fechanacimiento.split(' ')[0],'YYYY-MM-DD')); // reemplazado con el mas actualizado
    const [fechanacimientoPickerFocused, setFechanacimientoPickerFocused] = useState(false);
    const [ocupacionSelectedValue, setOcupacionSelectedValue] = useState(capitalizeFirstLetter(perfilbeneficiario.ocupacion));
    const [categoriaseguimientoSelectedValue, setCategoriaseguimientoSelectedValue] = useState(perfilbeneficiario.categoriaseguimiento ? capitalize(perfilbeneficiario.categoriaseguimiento, true) : '');
    const [tienefmliaclubSelected, setTienefmliaclubSelected] = useState(parseInt(perfilbeneficiario.tienefmliaclub));
    const [programaprincipalSelectedValue, setProgramaprincipalSelectedValue] = useState(perfilbeneficiario.programaprincipal ? capitalize(perfilbeneficiario.programaprincipal, true) : '');
    const [error, setError] = useState(null);
    const onAccordionChange = (evt, index) => {
        activeSectionIndexes.includes(index)
        ? setActiveSectionIndexes(activeSectionIndexes.filter(
            item => index !== item,
          ))
        : setActiveSectionIndexes([...activeSectionIndexes, index]);
    }

    // Básicos
    // const [fechaIngreso, setFechaIngreso] = useState(moment(basicos.fecha.split(' ')[0],'YYYY-MM-DD'));
    const [fechaIngreso, setFechaIngreso] = useState(moment(basicos.fecha,'YYYY-MM-DD'));
    const [fechaIngresoPickerFocused, setFechaIngresoPickerFocused] = useState(false);
    const [estadoSelectedValue, setEstadoSelectedValue] = useState(basicos.estado);
    const [actividadSelectedValue, setActividadSelectedValue] = useState(basicos.actividad ? capitalize(basicos.actividad, true) : '');
    const [categoriaSelectedValue, setCategoriaSelectedValue] = useState(basicos.categoria);
    const [prestarservicioSelected, setPrestarservicioSelected] = useState(basicos.prestarservicio==='1' ? 1 : 0);

    const [Basicos] = useState({
        "id": basicos.id, //int
        "docId": basicos.docId, //32
        "nombres": basicos.nombres, //32
        "apellidos": basicos.apellidos, //32
        "email": basicos.email, //255
        "fecha": basicos.fecha, //0000-00-00 00:00:00
        "fechanacimiento": basicos.fechanacimiento, //0000-00-00 00:00:00
        "activo": basicos.activo==='1' ? true : false,
        "estado": basicos.estado, //
        "actividad": basicos.actividad, //
        "alias": basicos.alias, //
        "celular": basicos.celular, //
        "categoria": basicos.categoria, //
        "prestarservicio": basicos.prestarservicio==='1' ? 1 : 0, //
        "acuerdocorresp": basicos.acuerdocorresp==='1' ? true : false //
    });

    const [P] = useState({
        "id": perfilbeneficiario.id, //int
        "docId": perfilbeneficiario.docId, //15
        "tipodoc": perfilbeneficiario.tipodoc, //32
        "nombres": basicos.nombres, //32 // reemplazado con el mas actualizado
        "apellidos": basicos.apellidos, //32 // reemplazado con el mas actualizado
        "fechanacimiento": perfilbeneficiario.fechanacimiento, //0000-00-00 00:00:00 // reemplazado con el mas actualizado
        "lugarnacimiento": perfilbeneficiario.lugarnacimiento, //32
        "estadocivil": capitalizeFirstLetter(perfilbeneficiario.estadocivil), //20
        "genero": perfilbeneficiario.genero, //2
        "educacion": perfilbeneficiario.educacion, //32
        "nivelescolaridad": perfilbeneficiario.nivelescolaridad || '', //2
        "estudiaactualmente": perfilbeneficiario.estudiaactualmente, //true/false 1/0
        "jornada": perfilbeneficiario.jornada==='' ? 'No Aplica' : perfilbeneficiario.jornada, //20
        "email": basicos.email, //255 // reemplazado con el mas actualizado
        "jefehogar": perfilbeneficiario.jefehogar, //15
        "personasacargo": perfilbeneficiario.personasacargo, //32
        "numhijos": perfilbeneficiario.numhijos, //int
        "ocupacion": capitalizeFirstLetter(perfilbeneficiario.ocupacion), //60
        "otraocupacion": perfilbeneficiario.otraocupacion, //255
        "categoriaseguimiento": perfilbeneficiario.categoriaseguimiento, //32
        "tienefmliaclub": perfilbeneficiario.tienefmliaclub, //1
        "fmliaclub": perfilbeneficiario.fmliaclub, //32
        "referenciaclub": perfilbeneficiario.referenciaclub, //32
        // "asistencia": (parseFloat(perfilbeneficiario.asistencia) * 100.0).toString(), //float
        "programaprincipal": perfilbeneficiario.programaprincipal, //120
        "carrera": perfilbeneficiario.carrera, //120
        "institucion": perfilbeneficiario.institucion, //120
        "libretamilitar": perfilbeneficiario.libretamilitar==='1' ? true : false, //true/false 1/0
        "tiposangre": perfilbeneficiario.tiposangre, //32
        "modificadopor": perfilbeneficiario.modificadopor, //32
        "foto": perfilbeneficiario.foto, //200
        "comentarios": perfilbeneficiario.comentarios, //text
        "actualizacion": perfilbeneficiario.actualizacion, //0000-00-00 00:00:00
        "version": perfilbeneficiario.version,
    });

    const [itemsPerfilBeneficiario, setItemsPerfilBeneficiario] = useState([
        // Basicos tabla beneficiarios
        // {"key":"id1", "label": "Básicos Id", "value": Basicos.id },
        // {"key":"nombres1", "label": "Básicos nombres 1", "value": Basicos.nombres},
        // {"key":"apellidos1", "label": "Básicos apellidos 1", "value": Basicos.apellidos},
        // {"key":"email1", "label": "Básicos email 1", "value": Basicos.email},
        // {"key":"alias", "label": "Alias", "value": Basicos.alias},

        // {"key":"fechanacimiento1", "label": "Básicos Fecha de Nacimiento 1", "value": Basicos.fechanacimiento},
        {"key":"activo", "label": "Activo", "value": Basicos.activo},
        {"key":"prestarservicio", "label": "Puede prestar el Servicio?", "value": Basicos.prestarservicio},
        {"key":"estado", "label": "Estado", "value": Basicos.estado},
        {"key":"fecha", "label": "Fecha Ingreso", "value": Basicos.fecha},
        {"key":"actividad", "label": "Actividad dentro del Club", "value": Basicos.actividad ? capitalize(Basicos.actividad, true) : ''},
        {"key":"categoria", "label": "Categoría", "value": Basicos.categoria },
        
        // Tabla perfilbeneficiarios
        // {"key":"id", "label": "Identificador Único", "value": P['id']},
        {"key":"genero", "label": "Género", "value": capitalize(P['genero'], true)},
        {"key":"tiposangre", "label": "Tipo de sangre", "value": P['tiposangre']},
        {"key":"alias", "label": "Alias", "value": Basicos.alias}, // basicos
        {"key":"nombres", "label": "Nombres", "value": capitalize(P['nombres'], true)},
        {"key":"apellidos", "label": "Apellidos", "value": capitalize(P['apellidos'], true)},
        {"key":"tipodoc", "label": "Tipo de Documento de Identidad", "value": P['tipodoc']},
        {"key":"docId", "label": "Número Documento de Identidad", "value": P['docId']},
        {"key":"fechanacimiento", "label": "Fecha de Nacimiento", "value": P['fechanacimiento']}, //Basicos.fechanacimiento
        {"key":"lugarnacimiento", "label": "Lugar de Nacimiento", "value": capitalize(P['lugarnacimiento'], true)},
        {"key":"estadocivil", "label": "Estado Civil", "value": P['estadocivil']},
        {"key":"libretamilitar", "label": "Tiene libreta militar?", "value": P['libretamilitar']},
        {"key":"educacion", "label": "Educación", "value": P['educacion']}, //capitalize(P['educacion'], true)
        {"key":"nivelescolaridad", "label": "Nivel de Educación", "value": P['nivelescolaridad']},
        {"key":"estudiaactualmente", "label": "Estudia Actualmente", "value": P['estudiaactualmente']},
        {"key":"carrera", "label": "Carrera", "value": P['carrera']},
        {"key":"institucion", "label": "Institución educativa", "value": P['institucion']},
        {"key":"jornada", "label": "Jornada Escolar", "value": capitalize(P['jornada'], true)},
        {"key":"celular", "label": "Número de Celular", "value": Basicos.celular},
        {"key":"email", "label": "Correo Electrónico o E-mail", "value": P['email']},
        {"key":"jefehogar", "label": "Jefe de Hogar?", "value": P['jefehogar']},
        {"key":"personasacargo", "label": "Número de Personas a Cargo", "value": P['personasacargo']},
        {"key":"numhijos", "label": "Número de Hijos", "value": P['numhijos']},
        {"key":"ocupacion", "label": "Ocupación por fuera del Club", "value": P['ocupacion']},
        {"key":"otraocupacion", "label": "Otros Oficios", "value": capitalize(P['otraocupacion'], true)},
        {"key":"tienefmliaclub", "label": "Tiene familiares en el Club?", "value": P['tienefmliaclub']},
        {"key":"fmliaclub", "label": "Nombre Familia", "value": P['fmliaclub']},
        {"key":"referenciaclub", "label": "Referencia personal en el Club", "value": P['referenciaclub']},
        // {"key":"asistencia", "label": "Asistencia (%)", "value": P['asistencia']}
        {"key":"categoriaseguimiento", "label": "Categoría para seguimiento (COVID-19)", "value": P['categoriaseguimiento']},
        {"key":"programaprincipal", "label": "Programa principal en que se encuentra", "value": P['programaprincipal']},
        {"key":"acuerdocorresp", "label": "Firmó acuerdo de corresponsabilidad?", "value": Basicos.acuerdocorresp},

        // {"key":"foto", "label": "Foto", "value": P['foto']},
        // {"key":"comentarios", "label": "Comentarios en general", "value": P['comentarios']},
        // {"key":"actualizacion", "label": "Última actualización", "value": P['actualizacion']},
        // {"key":"version", "label": "Versión", "value": P['version']},
        // {"key":"modificadopor", "label": "modificadopor", "value": P['modificadopor']}
    ]);

    useEffect(() => {
        // Guardar los resultados al salir, puede ser contraproducente al fallar la conexión con el servidor u otro evento no deseado
        // se podrián perder los datos modificafos
        
        return () => {
            // if (isUpdated){
            //     console.log('Saving appointment', isUpdated);
            // }
            dispatch(serverMensaje({}));
        }
    }, [dispatch]);

    useEffect(() => {
        if (itemsPerfilBeneficiario){
            // console.log(itemsPerfilBeneficiario);
        }
        if (mensaje.perfil && mensaje.perfil!=='') {
            if (typeof mensaje.perfil === 'string' && mensaje.perfil.toLowerCase().includes('error')){
                showToaster('error', 'Ocurrió un fallo en el servidor', mensaje.perfil);
            } 
            // else {
            //     // showToaster('success',"PerfilBeneficiario/perfil del Beneficiario", mensaje.perfil); //"🧭 " + mensaje
            // }
        }
        if (mensaje.basicos && mensaje.basicos!=='') {
            if (typeof mensaje.basicos === 'string' && mensaje.basicos.toLowerCase().includes('error')){
                showToaster('error', 'Ocurrió un fallo en el servidor', mensaje.basicos);
            } 
            // else {
            //     // showToaster('success',"PerfilBeneficiario/basicos del Beneficiario", mensaje.basicos);
            // }
        }

    }, [mensaje.perfil, mensaje.basicos, itemsPerfilBeneficiario, dispatch]);

    const updateItemsPerfilBeneficiario = (f, v) => {
        const newitemsPerfilBeneficiario = itemsPerfilBeneficiario.map((itm, index) => {
            if (itm.key===f){
                itm.value = v;
            }
            return itm;
        });
        setItemsPerfilBeneficiario(newitemsPerfilBeneficiario);
    };

    const updateDataonServer = () =>{
        dispatch(serverMensaje({perfil:''}));
        // Algunos cambios manualmente, antes de enviar al servidor
        // P.asistencia =  (parseFloat(P.asistencia) / 100.0).toFixed(6).toString();
        P.libretamilitar =  P.libretamilitar===true ? '1' : '0';
        P.fecha =  moment(P.fecha).format("YYYY-MM-DD HH:mm:ss");
        P.fechanacimiento =  moment(P.fechanacimiento).format("YYYY-MM-DD HH:mm:ss");
        Basicos.fecha =  moment(Basicos.fecha).format("YYYY-MM-DD HH:mm:ss");
        Basicos.fechanacimiento =  moment(P.fechanacimiento).format("YYYY-MM-DD HH:mm:ss");
        dispatch(updateBeneficiarioPerfil( { token: users.user.claveAPI, perfil:P}));
        dispatch(updateBeneficiarioBasicos({ token: users.user.claveAPI, basicos:Basicos}));

        // Update array to display changes
        updateEditedDataBasicos(Basicos, P);
        
    }
    const updateBasicosonServer = () =>{
        dispatch(serverMensaje({basicos:''}));
        Basicos.fecha =  moment(Basicos.fecha).format("YYYY-MM-DD HH:mm:ss");
        Basicos.fechanacimiento =  moment(P.fechanacimiento).format("YYYY-MM-DD HH:mm:ss");
        dispatch(updateBeneficiarioBasicos({ token: users.user.claveAPI, basicos:Basicos}));
        
        // Update array to display changes
        updateEditedDataBasicos(Basicos, null);
    }

    const updateEditedDataBasicos = (basicos, perfil) =>{
        if (listBenef && listBenef.length>0)
            updateEditedDataBasicos_listBenefGral(basicos, perfil);
        if (listBenefAyudas && listBenefAyudas.length>0)
            updateEditedDataBasicos_listBenefAyudas(basicos, perfil);
        if (listBenefAcomp && listBenefAcomp.length>0)
            updateEditedDataBasicos_listBenefAcomp(basicos, perfil);
    }

    const updateEditedDataBasicos_listBenefGral = (basicos, perfil) =>{
        const newlistBenef = listBenef.map((itm, index) => {
            // if (itm.id===basicos.id){
            if (itm.docId===basicos.docId){
                itm.activo = (basicos.activo===true ? '1' : '0');
                itm.acuerdocorresp = (basicos.acuerdocorresp===true ? '1' : '0');
                itm.prestarservicio = basicos.prestarservicio; //(basicos.prestarservicio===1 ? '1' : '0');
                itm.estado = basicos.estado;
                itm.actividad = basicos.actividad;
                itm.categoria = basicos.categoria;
                itm.alias = basicos.alias;
                itm.fecha = basicos.fecha;
                itm.celular = (basicos.celular!==null || basicos.celular!=='') ? basicos.celular : '';
                itm.edadclub = calculate_age(moment(basicos.fecha).format("YYYY-MM-DD HH:mm:ss"));
                if (perfil){
                    itm.nombres = perfil.nombres;
                    itm.apellidos = perfil.apellidos;
                    itm.email = (perfil.email!==null || perfil.email!=='') ? perfil.email : '';
                    itm.fechanacimiento = perfil.fechanacimiento;
                    itm.edad = calculate_age(moment(perfil.fechanacimiento).format("YYYY-MM-DD HH:mm:ss"));
                }
            }
            return itm;
        });
        dispatch(updateEditedBeneficiarioData({listBenef: newlistBenef}));
    }
    const updateEditedDataBasicos_listBenefAcomp = (basicos, perfil) =>{
        const newlistBenefAcomp = listBenefAcomp.map((itm, index) => {
            // if (itm.id===basicos.id){
            if (itm.docId===basicos.docId){
                itm.activo = (basicos.activo===true ? '1' : '0');
                itm.acuerdocorresp = (basicos.acuerdocorresp===true ? '1' : '0');
                itm.prestarservicio = basicos.prestarservicio; //(basicos.prestarservicio===1 ? '1' : '0');
                itm.estado = basicos.estado;
                itm.actividad = basicos.actividad;
                itm.categoria = basicos.categoria;
                itm.alias = basicos.alias;
                itm.fecha = basicos.fecha;
                itm.celular = (basicos.celular!==null || basicos.celular!=='') ? basicos.celular : '';
                itm.edadclub = calculate_age(moment(basicos.fecha).format("YYYY-MM-DD HH:mm:ss"));
                if (perfil){
                    itm.nombres = perfil.nombres;
                    itm.apellidos = perfil.apellidos;
                    itm.email = (perfil.email!==null || perfil.email!=='') ? perfil.email : '';
                    itm.fechanacimiento = perfil.fechanacimiento;
                    itm.edad = calculate_age(moment(perfil.fechanacimiento).format("YYYY-MM-DD HH:mm:ss"));
                }
            }
            return itm;
        });
        dispatch(updateEditedBeneficiarioDataxCovidAcomp({listBenefAcomp: newlistBenefAcomp}));

    }
    const updateEditedDataBasicos_listBenefAyudas = (basicos, perfil) =>{
        const newlistBenefAyudas = listBenefAyudas.map((itm, index) => {
            // if (itm.id===basicos.id){
            if (itm.docId===basicos.docId){
                itm.activo = (basicos.activo===true ? '1' : '0');
                itm.acuerdocorresp = (basicos.acuerdocorresp===true ? '1' : '0');
                itm.prestarservicio = basicos.prestarservicio; //(basicos.prestarservicio===1 ? '1' : '0');
                itm.estado = basicos.estado;
                itm.actividad = basicos.actividad;
                itm.categoria = basicos.categoria;
                itm.alias = basicos.alias;
                itm.fecha = basicos.fecha;
                itm.celular = (basicos.celular!==null || basicos.celular!=='') ? basicos.celular : '';
                itm.edadclub = calculate_age(moment(basicos.fecha).format("YYYY-MM-DD HH:mm:ss"));
                if (perfil){
                    itm.nombres = perfil.nombres;
                    itm.apellidos = perfil.apellidos;
                    itm.email = (perfil.email!==null || perfil.email!=='') ? perfil.email : '';
                    itm.fechanacimiento = perfil.fechanacimiento;
                    itm.edad = calculate_age(moment(perfil.fechanacimiento).format("YYYY-MM-DD HH:mm:ss"));
                }
            }
            return itm;
        });
        dispatch(updateEditedBeneficiarioDataxCovidAyudas({listBenef: newlistBenefAyudas}));
        
    }

    const replaceSomeText = (s) =>{
        return s
            .replace(/&nbsp;/g, ' ')
            .replace(/<br>/g, '')
            .replace(/&amp;/g, '&')
            .replace(/&gt;/g, '>')
            .replace(/&lt;/g, '<').trim()
    }

    const validateInput = (f, v, final) => {
        const regex_onlynum = /^[0-9]*$/i; ///[0-9,]|\./;
        const regex_onlyfloat = /^[0-9,]|\./; // /^[0-9.]*$/i;
        const regex_nombres = /^['`´a-zA-ZÀ-ÿ\u00C0-\u017F\s]+$/;
        // const regex_email = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        let new_val = null;
        let err = null;
        switch (f) {
            // Básicos
            case 'activo': // Valida cuando el campo es de texto y no un componente Switch
                new_val = v;
                if (!regex_onlynum.test(v)) {
                    err = 'Dígite un solo número válido';
                    new_val = null;
                } else if(parseInt(v)>1){
                    err = 'Dígite un número válido';
                    new_val = null;
                }
                break;
            case 'acuerdocorresp': // Valida cuando el campo es de texto y no un componente Switch
                new_val = v;
                if (!regex_onlynum.test(v)) {
                    err = 'Dígite un solo número válido';
                    new_val = null;
                } else if(parseInt(v)>1){
                    err = 'Dígite un número válido';
                    new_val = null;
                }
                break;
            case 'estado':
                new_val = v.substr(0,32);
                if (final)
                    new_val = replaceSomeText(v).substr(0,32);
                break;
            case 'actividad':
                new_val = v.substr(0,60);
                if (final)
                    new_val = replaceSomeText(v).substr(0,60);
                break;
            case 'categoria':
                new_val = v.substr(0,50);
                if (final)
                    new_val = replaceSomeText(v).substr(0,50);
                break;
            case 'alias':
                new_val = v.substr(0,50);
                if (final)
                    new_val = replaceSomeText(v).substr(0,50);
                break;
            case 'fecha': 
                new_val = replaceSomeText(v).substr(0,32);
                if (final && new_val===''){
                    err = 'Dígite una fecha de ingreso válida';
                    new_val = null;
                }
                break;
            case 'celular':
                new_val = replaceSomeText(v).substr(0,15);
                if (!regex_onlynum.test(v)) {
                    err = 'Dígite solo números válidos de celular';
                    new_val = null;
                } else if(v.length>15){
                    err = 'Dígite un número de celular válido';
                    new_val = null;
                }
                if (new_val===null) new_val = '';
                break;
            case 'prestarservicio': //true,false
                new_val = replaceSomeText(v).substr(0,1);
                if (final && new_val===''){
                    err = 'Seleccione una opción válida en la prestación del servicio';
                    new_val = null;
                }
                break;
            // perfilbeneficiario
            case 'docId':
                // Campo no editable, tratado por aquí por el componente incluido en el campo del formulario
                // new_val = replaceSomeText(v).substr(0,15); //.toUpperCase();
                // if (!regex_onlynum.test(new_val)) {
                //     err = 'Dígite solo carácteres válidos';
                //     new_val = null;
                // } else if(new_val.length>15){
                //     err = 'Dígite un número válido';
                //     new_val = null;
                // }
                new_val=null;
                break;
            case 'tipodoc':
                new_val = v.substr(0,32);
                if (final)
                    new_val = replaceSomeText(v).substr(0,32);
                break;
            case 'nombres':
                new_val = replaceSomeText(v).substr(0,32); //.toUpperCase();
                if (!regex_nombres.test(new_val)) {
                    err = 'Dígite carácteres válidos';
                    new_val = null;
                }
                break;
            case 'apellidos':
                new_val = replaceSomeText(v).substr(0,32); //.toUpperCase();
                if (!regex_nombres.test(new_val)) {
                    err = 'Dígite carácteres válidos';
                    new_val = null;
                }
                break;
            case 'fechanacimiento': //0000-00-00 00:00:00
                new_val = v.substr(0,30);
                if (final && new_val===''){
                    new_val = replaceSomeText(v).substr(0,30);
                    err = 'Dígite una fecha de nacimiento válida';
                    new_val = null;
                }
                break;
            case 'lugarnacimiento':
                new_val = v.substr(0,32);
                if (final)
                    new_val = replaceSomeText(v).substr(0,32);
                break;
            case 'estadocivil':
                new_val = v.substr(0,20);
                if (final)
                    new_val = replaceSomeText(v).substr(0,20);
                break;
            case 'genero':
                new_val = replaceSomeText(v).substr(0,2);
                if (final && new_val===''){
                    err = 'Dígite un genero válido';
                    new_val = null;
                }
                break;
            case 'educacion':
                new_val = v.substr(0,32);
                if (final)
                    new_val = replaceSomeText(v).substr(0,32);
                break;
            case 'nivelescolaridad':
                new_val = replaceSomeText(v).substr(0,2);
                break;
            case 'estudiaactualmente': //true
                new_val = replaceSomeText(v).substr(0,2);
                break;
            case 'jornada':
                new_val = v.substr(0,20);
                if (final)
                    new_val = replaceSomeText(v).substr(0,20);
                break;
            case 'telefono':
                new_val = v;
                if (!regex_onlynum.test(v)) {
                    err = 'Dígite solo números válidos de teléfono';
                    new_val = null;
                } else if(v.length>15){
                    err = 'Dígite un número de teléfono fijo válido';
                    new_val = null;
                }
                if (new_val===null) new_val = '';
                break;
            case 'email':
                new_val = v.substr(0,255);
                if (final)
                    new_val = replaceSomeText(v).substr(0,255);
                break;
            case 'jefehogar':
                new_val = v.substr(0,15);
                if (final)
                    new_val = replaceSomeText(v).substr(0,15);
                break;
            case 'personasacargo':
                new_val = v.substr(0,32);
                if (final)
                    new_val = replaceSomeText(v).substr(0,32);
                break;
            case 'numhijos':
                new_val = v.substr(0,2);
                if (!regex_onlynum.test(v)) {
                    err = 'Dígite solo números válidos de cantidad de hijos';
                    new_val = null;
                } else if(v.length>15){
                    err = 'Dígite un número de hijos válido';
                    new_val = null;
                }
                // if (new_val===null) new_val = 0;
                if (final)
                    new_val = replaceSomeText(v).substr(0,2);
                break;
            case 'ocupacion':
                new_val = v.substr(0,60);
                if (final)
                    new_val = replaceSomeText(v).substr(0,60);
                break;
            case 'otraocupacion':
                new_val = v.substr(0,255);
                if (final)
                    new_val = replaceSomeText(v).substr(0,255);
                break;
            case 'categoriaseguimiento':
                new_val = v.substr(0,32);
                if (final)
                    new_val = replaceSomeText(v).substr(0,32);
                break;
            case 'tienefmliaclub':
                new_val = v;
                if (!regex_onlynum.test(v)) {
                    err = 'Dígite un solo número válido';
                    new_val = null;
                } else if(parseInt(v)>1){
                    err = 'Dígite un número válido';
                    new_val = null;
                }
                break;
            case 'fmliaclub':
                new_val = v.substr(0,32);
                if (final)
                    new_val = replaceSomeText(v).substr(0,32);
                break;
            case 'referenciaclub':
                new_val = v.substr(0,32);
                if (final)
                    new_val = replaceSomeText(v).substr(0,32);
                break;
            case 'foto':
                new_val = v.substr(0,200);
                if (final)
                    new_val = replaceSomeText(v).substr(0,200);
                break;
            case 'asistencia':
                new_val = v;
                if (!regex_onlyfloat.test(v)) {
                    err = 'Dígite un solo número válido';
                    new_val = null;
                } else if(parseFloat(v)>100.0){
                    err = 'Dígite un número válido';
                    new_val = null;
                }
                if (new_val===null) new_val = 0.0;
                // if (final)
                //     new_val = (parseFloat(new_val) / 100.0).toFixed(5).toString();
                break;
            case 'programaprincipal':
                new_val = v.substr(0,120);
                if (final)
                    new_val = replaceSomeText(v).substr(0,120);
                break;
            case 'carrera':
                new_val = v.substr(0,120);
                if (final)
                    new_val = replaceSomeText(v).substr(0,120);
                break;
            case 'institucion':
                new_val = v.substr(0,120);
                if (final)
                    new_val = replaceSomeText(v).substr(0,120);
                break;
            case 'libretamilitar':
                new_val = v;
                if (!regex_onlynum.test(v)) {
                    err = 'Dígite un solo número válido';
                    new_val = null;
                } else if(parseInt(v)>1){
                    err = 'Dígite un número válido';
                    new_val = null;
                }
                break;
            case 'tiposangre':
                new_val = v.substr(0,32);
                if (final)
                    new_val = replaceSomeText(v).substr(0,32);
                break;
            case 'modificadopor':
                new_val = v.substr(0,32);
                if (final)
                    new_val = replaceSomeText(v).substr(0,32);
                break;
            case 'comentarios':
                new_val = v.trim();
                if (final)
                    new_val = replaceSomeText(v); //.substr(0,255);
                break;
            default:
                break;
        }
        // P[f] = new_val;
        setError(err);
        return new_val
    }
    const handleChange = evt => {
        const field = evt.currentTarget.id.split('_')[1];
        const new_val = validateInput(field, evt.target.value, false);
        if(new_val){
            if (Object.keys(basicos).includes(field)){
                Basicos[field] = new_val;
            } else {
                P[field] = new_val
            }
        };
    };
    const handleBlur = evt => {
        const val = evt.currentTarget.innerHTML;
        const field = evt.target.id.split('_')[1];
        let new_val = validateInput(field, val, true);
        const regex_email = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        if (field==='email' && !regex_email.test(new_val)){
            setError('Correo eléctronico o email no válido');
            new_val = null;
        }
        if(new_val || new_val===''){
            if (Object.keys(Basicos).includes(field)){
                Basicos[field] = new_val;
                // updateBasicosonServer();
            }
            if (Object.keys(P).includes(field)){
                P[field] = new_val;
                // updateDataonServer();
            }
            updateItemsPerfilBeneficiario(field, new_val);
            updateDataonServer();
        };
    };

    // Básicos
    const onChangeActivo = (evt) => {
        Basicos.activo =  evt.target.checked; // ? '1' : '0';
        updateItemsPerfilBeneficiario('activo', P.activo);
        // updateDataonServer(); // Save to server inmediatively both tables
        updateBasicosonServer(); // Save to server inmediatively one table
    }
    const onChangeAcuerdoCorresp = (evt) => {
        Basicos.acuerdocorresp =  evt.target.checked; // ? '1' : '0';
        updateItemsPerfilBeneficiario('acuerdocorresp', P.acuerdocorresp);
        updateBasicosonServer(); // Save to server inmediatively one table
    }
    const handleEstadoSelectChange = (value, item) => {
        setEstadoSelectedValue(value);
        Basicos.estado = value;
        updateBasicosonServer();
    };
    const handleActividadSelectChange = (value, item) => {
        setActividadSelectedValue(value);
        Basicos.actividad = value;
        updateBasicosonServer();
    };
    const handleCategoriaSelectChange = (value, item) => {
        setCategoriaSelectedValue(value);
        Basicos.categoria = value;
        updateBasicosonServer();
    };

    const prestarservicioSelectButton = (selected) => {
        setPrestarservicioSelected(selected);
        Basicos.prestarservicio = selected === 1 ? '1' : '0';
        updateBasicosonServer();
    };
    

    const estudiaactualmenteSelectButton = (selected) => {
        setEstudiaActualmenteSelected(selected);
        P['estudiaactualmente'] = selected === 1 ? '1' : '0';
        updateDataonServer();
    };
    const handleGeneroSelectChange = (value, item) => {
        setGeneroSelectedValue(value);
        P['genero'] = value;
        updateDataonServer();
    };
    const jefehogarSelectButton = (selected) => {
        setJefehogarSelected(selected);
        P['jefehogar'] = selected;
        updateDataonServer();
    };
    const handleTipodocSelectChange = (value, item) => {
        setTipodocSelectedValue(value);
        P['tipodoc'] = value;
        updateDataonServer();
    };
    const handleEstadocivilSelectChange = (value, item) => {
        setEstadoCivilSelectedValue(value);
        P['estadocivil'] = value;
        updateDataonServer();
    };
    const handleEducacionSelectChange = (value, item) => {
        setEducacionSelectedValue(value);
        P['educacion'] = value;
        updateDataonServer();
    };
    const handleJornadaSelectChange = (value, item) => {
        setJornadaSelectedValue(value);
        P['jornada'] = value;
        updateDataonServer();
    };
    const handleOcupacionSelectChange = (value, item) => {
        setOcupacionSelectedValue(value);
        P['ocupacion'] = value;
        updateDataonServer();
    };
    const handleCategoriaseguimientoSelectChange = (value, item) => {
        const vlr = (typeof value === 'string') ? value : ''; 
        setCategoriaseguimientoSelectedValue(vlr);
        P['categoriaseguimiento'] = vlr;
        updateDataonServer();
    };
    // const handleCategoriaseguimientoSelectonBlur = (value) => {
    //     const vlr = (typeof value === 'string') ? value : ''; 
    //     setCategoriaseguimientoSelectedValue(vlr);
    //     P['categoriaseguimiento'] = vlr;
    //     updateDataonServer();
    // };

    const handleProgramaprincipalSelectChange = (value, item) => {
        const vlr = (typeof value === 'string') ? value : ''; 
        setProgramaprincipalSelectedValue(vlr);
        P['programaprincipal'] = vlr;
        updateDataonServer();
    };
    
    const tienefmliaclubSelectButton = (selected) => {
        setTienefmliaclubSelected(selected);
        P['tienefmliaclub'] = selected === 1 ? '1' : '0';
        updateDataonServer();
    };

    // DatePicker 
    const onFechaNacimientoChange = (date) =>{
        if (date.preventDefault) date.preventDefault()
        if (date){
            P.fechanacimiento = date.utc().format('YYYY-MM-DD HH:mm:ss');
            Basicos.fechanacimiento = date.utc().format('YYYY-MM-DD HH:mm:ss');
            // P.fechanacimiento =  moment(date.utc()).format("YYYY-MM-DD HH:mm:ss");
            setFechaNacimiento(date);
            updateDataonServer();
        }
        
    }
    const onFechaNacimientoFocusChange = ({ focused }) =>{
        setFechanacimientoPickerFocused(focused)
    }

    // DatePicker 2
    const onFechaIngresoChange = (date) =>{
        if (date.preventDefault) date.preventDefault()
        if (date){
            Basicos.fecha = date.utc().format('YYYY-MM-DD HH:mm:ss');
            // Basicos.fecha =  moment(date.utc()).format("YYYY-MM-DD HH:mm:ss");
            setFechaIngreso(date);
            updateBasicosonServer();
        }
        
    }
    const onFechaIngresoFocusChange = ({ focused }) =>{
        setFechaIngresoPickerFocused(focused)
    }

    const onChangeLibretamilitar = (evt) => {
        P.libretamilitar =  evt.target.checked; // ? '1' : '0';
        updateItemsPerfilBeneficiario('libretamilitar', P.libretamilitar);
        updateDataonServer(); // Save to server inmediatively both tables
    }

    return (
        <BeneficiarioModulo>
            <AccordionModulo
                onAccordionChange={onAccordionChange}
                activeSectionIndexes={activeSectionIndexes}
            >
                <AccordionSection>
                    <AccordionTitleModule>Perfil Beneficiario</AccordionTitleModule>
                    {activeSectionIndexes.length>0 &&  <AccordionContent style={{marginBottom: '10px'}}>
                    {error && <div style={{color: 'red', width: '100%'}}>
                            <Alert red showIcon style={{minWidth: '100%', marginBottom: '10px'}}
                                showCloseLabel 
                                onClose={() => setError(null)}>
                                <AlertMessage>
                                    Error:{' '}
                                    <strong>{error}</strong>
                                </AlertMessage>
                            </Alert>
                        </div> 
                    }
                    {/* ITEMS */}
                        {
                            itemsPerfilBeneficiario.map((itm, index) => {
                                return (<div key={index} className="itemContainer">
                                    <div className="itemContainer-attr" 
                                        style={{backgroundColor:(index % 2 === 0) ? '#f8f8f8': '#fff'}}
                                    >{itm.label}</div>
                                    <div className="itemContainer-value" 
                                        style={{backgroundColor:(index % 2 === 0) ? '#f8f8f8': '#fff'}}
                                    >
                                    {   itm.key==='activo' 
                                        ?
                                            <Switch 
                                                labelPosition={"before" || '' }
                                                style={{margin: '0 0 0.5rem 0'}}
                                                name={ 'activo_' + Basicos.id } 
                                                checked={ Basicos.activo} 
                                                disabled={!isEditable} 
                                                onChange={onChangeActivo} />
                                        :
                                        itm.key==='acuerdocorresp' 
                                        ?
                                            <Switch 
                                                labelPosition={"before" || '' }
                                                style={{margin: '0 0 0.5rem 0'}}
                                                name={ 'acuerdocorresp_' + Basicos.id } 
                                                checked={ Basicos.acuerdocorresp} 
                                                disabled={!isEditable} 
                                                onChange={onChangeAcuerdoCorresp} />
                                        :
                                        itm.key==='libretamilitar' 
                                        ?
                                            <Switch 
                                                labelPosition={"before" || '' }
                                                style={{margin: '0 0 0.5rem 0'}}
                                                name={ 'libretamilitar_' + index } 
                                                checked={ itm.value} 
                                                disabled={!isEditable} 
                                                onChange={onChangeLibretamilitar} />
                                        :
                                        itm.key==='prestarservicio'
                                        ?
                                        <ButtonGroup isToggle >
                                            <Button  clear={prestarservicioSelected !== 1} disabled={!isEditable}
                                                onClick={() => {  prestarservicioSelectButton(1) }}
                                            >Si</Button>
                                            <Button clear={prestarservicioSelected !== 0} disabled={!isEditable}
                                                onClick={() => { prestarservicioSelectButton(0) }}
                                            >No</Button>
                                        </ButtonGroup>
                                        :
                                        itm.key==='estado' 
                                        ?
                                            <Select filterable positionFixed 
                                                disabled={!isEditable} 
                                                onChange={handleEstadoSelectChange}
                                                selectedValue={estadoSelectedValue.trim() || ''}
                                            >
                                            {tipoEstados.tipo.map(e => {
                                                return (
                                                <MenuItem key={e.id} value={e.name}>
                                                    {e.name}
                                                </MenuItem>
                                                )
                                            })}
                                            </Select>
                                        :
                                        itm.key==='actividad' 
                                        ?
                                            <Select filterable positionFixed
                                                disabled={!isEditable} 
                                                onChange={handleActividadSelectChange}
                                                selectedValue={actividadSelectedValue.trim() || ''}
                                            >
                                            {tipoActividades.tipo.map(a => {
                                                return (
                                                <MenuItem key={a.id} value={a.name}>
                                                    {a.name}
                                                </MenuItem>
                                                )
                                            })}
                                            </Select>
                                        :
                                        itm.key==='categoria' 
                                        ?
                                            <Select filterable positionFixed
                                                disabled={!isEditable} 
                                                onChange={handleCategoriaSelectChange}
                                                selectedValue={categoriaSelectedValue.trim() || ''}
                                            >
                                            {tipoCategorias.tipo.map(c => {
                                                return (
                                                <MenuItem key={c.id} value={c.name}>
                                                    {c.name}
                                                </MenuItem>
                                                )
                                            })}
                                            </Select>
                                        :
                                        itm.key==='genero'
                                        ?
                                        <Select filterable positionFixed
                                                disabled={!isEditable} 
                                                onChange={handleGeneroSelectChange}
                                                selectedValue={generoSelectedValue.trim() || ''}
                                            >
                                            {tipoGeneros.tipo.map(e => {
                                                return (
                                                <MenuItem key={e.id} value={e.value}>
                                                    {e.name}
                                                </MenuItem>
                                                )
                                            })}
                                            </Select>
                                        :
                                        itm.key==='docId'
                                        ?
                                            // <div style={{ pointerEvents: 'none' }}>{itm.value}</div>
                                            <CopyToClipboard style={{maxWidth: '150px'}}
                                                tooltip={'Copiar número'} successTooltip={'Copiado!'}>
                                                {itm.value}
                                            </CopyToClipboard>
                                        : 
                                        itm.key==='estudiaactualmente'
                                        ?
                                        <ButtonGroup isToggle >
                                            <Button  clear={estudiaActualmenteSelected !== 1} disabled={!isEditable}
                                                onClick={() => {  estudiaactualmenteSelectButton(1) }}
                                            >Si</Button>
                                            <Button clear={estudiaActualmenteSelected !== 0} disabled={!isEditable}
                                                onClick={() => { estudiaactualmenteSelectButton(0) }}
                                            >No</Button>
                                        </ButtonGroup>
                                        :
                                        itm.key==='jefehogar'
                                        ?
                                        <ButtonGroup isToggle className="buttonGroupVertical" >
                                            <Button  clear={jefehogarSelected.toLowerCase() !== 'si'}
                                                disabled={!isEditable}
                                                onClick={() => {  jefehogarSelectButton('si') }}
                                            >Si</Button>
                                            <Button clear={jefehogarSelected.toLowerCase() !== 'compartido'}
                                                disabled={!isEditable}
                                                onClick={() => { jefehogarSelectButton('compartido') }}
                                            >Compartido</Button>
                                            <Button clear={jefehogarSelected.toLowerCase() !== 'no'}
                                                disabled={!isEditable}
                                                onClick={() => { jefehogarSelectButton('no') }}
                                            >No</Button>
                                        </ButtonGroup>
                                        :
                                        itm.key==='tipodoc' 
                                        ?
                                            <Select filterable positionFixed disabled={!isEditable} 
                                                onChange={handleTipodocSelectChange}
                                                selectedValue={tipodocSelectedValue.trim() || ''}
                                            >
                                            {tipoDocs.tipo.map(e => {
                                                return (
                                                <MenuItem key={e.id} value={e.name}>
                                                    {e.name}
                                                </MenuItem>
                                                )
                                            })}
                                            </Select>
                                        :
                                        itm.key==='estadocivil' 
                                        ?
                                            <Select filterable positionFixed disabled={!isEditable} 
                                                onChange={handleEstadocivilSelectChange}
                                                selectedValue={estadocivilSelectedValue.trim() || ''}
                                            >
                                            {estadosCiviles.estadocivil.map(e => {
                                                return (
                                                <MenuItem key={e.id} value={e.name}>
                                                    {e.name}
                                                </MenuItem>
                                                )
                                            })}
                                            </Select>
                                        :
                                        itm.key==='educacion' 
                                        ?
                                            <Select filterable positionFixed disabled={!isEditable} 
                                                onChange={handleEducacionSelectChange}
                                                selectedValue={educacionSelectedValue.trim() || ''}
                                            >
                                            {tiposEducacion.tipoeducacion.map(t => {
                                                return (
                                                <MenuItem key={t.id} value={t.name}>
                                                    {t.name}
                                                </MenuItem>
                                                )
                                            })}
                                            </Select>
                                        :
                                        itm.key==='jornada' 
                                        ?
                                            <Select filterable positionFixed disabled={!isEditable} 
                                                onChange={handleJornadaSelectChange}
                                                selectedValue={jornadaSelectedValue.trim() || ''}
                                            >
                                            {jornadasEscolares.jornada.map(j => {
                                                return (
                                                <MenuItem key={j.id} value={j.name}>
                                                    {j.name}
                                                </MenuItem>
                                                )
                                            })}
                                            </Select>
                                        :
                                        itm.key==='ocupacion' 
                                        ?
                                            <Select filterable positionFixed virtualized
                                                disabled={!isEditable} 
                                                virtualizedMenuWidth={200}
                                                onChange={handleOcupacionSelectChange}
                                                selectedValue={ocupacionSelectedValue.trim() || ''}
                                            >
                                            {tipoOcupacion.ocupaciones.map(o => {
                                                return (
                                                <MenuItem key={o.id} value={o.nombre}>
                                                    {o.nombre}
                                                </MenuItem>
                                                )
                                            })}
                                            </Select>
                                        :
                                        itm.key==='categoriaseguimiento' 
                                        ?
                                            <Select filterable positionFixed disabled={!isEditable} 
                                                onChange={handleCategoriaseguimientoSelectChange}
                                                selectedValue={categoriaseguimientoSelectedValue.trim() || ''}
                                                // onBlur={handleCategoriaseguimientoSelectonBlur}
                                            >
                                            {tipoCategoriasSeguimiento.tipo.map(c => {
                                                return (
                                                <MenuItem key={c.id} value={c.name}>
                                                    {c.name}
                                                </MenuItem>
                                                )
                                            })}
                                            </Select>
                                        :
                                        itm.key==='programaprincipal' 
                                        ?
                                            <Select filterable positionFixed disabled={!isEditable} 
                                                onChange={handleProgramaprincipalSelectChange}
                                                selectedValue={programaprincipalSelectedValue.trim() || ''}
                                                // onBlur={andleProgramaprincipalSelectonBlur}
                                            >
                                            {tiposProgramas.tipo.map(c => {
                                                return (
                                                <MenuItem key={c.id} value={c.name}>
                                                    {c.name}
                                                </MenuItem>
                                                )
                                            })}
                                            </Select>
                                        :
                                        itm.key==='tienefmliaclub'
                                        ?
                                        <ButtonGroup isToggle >
                                            <Button  clear={tienefmliaclubSelected !== 1} disabled={!isEditable}
                                                onClick={() => {  tienefmliaclubSelectButton(1) }}
                                            >Si</Button>
                                            <Button clear={tienefmliaclubSelected !== 0} disabled={!isEditable}
                                                onClick={() => { tienefmliaclubSelectButton(0) }}
                                            >No</Button>
                                        </ButtonGroup>
                                        :
                                        itm.key==='fecha' 
                                        ?
                                            <DatePicker 
                                                placeholder="Fecha Ingreso"
                                                id="fechaIngreso"
                                                date={fechaIngreso}
                                                focused={fechaIngresoPickerFocused}
                                                onDateChange={onFechaIngresoChange}
                                                onFocusChange={onFechaIngresoFocusChange}
                                                numberOfMonths={1}
                                                disabled={!isEditable}
                                                readOnly={true}
                                                hideInputIcon
                                                disableScroll={false}
                                                displayFormat={'YYYY-MM-DD'} // 'MMM DD YYYY'
                                                isOutsideRange={() => {}}
                                                monthYearSelectionMode="MONTH_YEAR"
                                                yearSelectDates={{"startYear" : 1940, "endYear" : new moment().year()}}
                                            />
                                        :itm.key==='fechanacimiento' 
                                        ?
                                            <DatePicker 
                                                placeholder="Fecha Nacimiento"
                                                id="fechaNacimiento"
                                                date={fechaNacimiento}
                                                focused={fechanacimientoPickerFocused}
                                                onDateChange={onFechaNacimientoChange}
                                                onFocusChange={onFechaNacimientoFocusChange}
                                                numberOfMonths={1}
                                                // enableOutsideDays={true}
                                                disabled={!isEditable}
                                                readOnly={true}
                                                hideInputIcon
                                                disableScroll={false}
                                                displayFormat={'YYYY-MM-DD'} // 'MMM DD YYYY'
                                                // renderMonthElement={renderMonthElement}
                                                isOutsideRange={() => {}}
                                                monthYearSelectionMode="MONTH_YEAR"
                                                // yearSelectDates={{"startYear" : new moment().subtract(new Date().getFullYear(), 100).year(), "endYear" : new moment().year()}}
                                                yearSelectDates={{"startYear" : 1900, "endYear" : new moment().year()}}
                                                
                                                // appendToBody={true}
                                            />
                                        :
                                            <ContentEditable
                                                id={'perfil_'+itm.key}
                                                html={itm.value || ''} // innerHTML of the editable div
                                                className='itemContainer-value-long'
                                                disabled={!isEditable}      // use true to disable editing
                                                onPaste={pastePlainText} // En caso se copie y pegue de otra pagina de internet
                                                onChange={handleChange} // handle innerHTML change
                                                onKeyPress={disableNewlines}  
                                                onFocus={highlightAll}
                                                onBlur={handleBlur}
                                                tagName='div' // Use a custom HTML tag (uses a div by default)
                                            />
                                    }

                                    </div>
                                </div>)
                            })
                        }

                        <div className="Observaciones-Comentarios">
                            <div className="benef-texto-observaciones-title">Comentarios en general:</div>
                            <div className="benef-texto-observaciones"> 
                                <ContentEditable
                                    id={'perfil_comentarios'}
                                    html={P.comentarios || ''} // innerHTML of the editable div
                                    disabled={!isEditable}        // use true to disable editing
                                    onPaste={pastePlainText}
                                    onChange={handleChange} // handle innerHTML change
                                    onFocus={highlightAll}
                                    onBlur={handleBlur}
                                    tagName='div' // Use a custom HTML tag (uses a div by default)
                                    style={{ "minHeight": "40px", "border": "1px dashed" }}
                                />
                            </div>
                        </div>

                    <div className="benef-texto-actualizacion">Última actualización: {P['actualizacion']} por <span>{P['modificadopor']}</span></div>

                    </AccordionContent>
                }
                </AccordionSection>
            </AccordionModulo>
        </BeneficiarioModulo>
    );
}

export default BenefPerfilBeneficiario;

