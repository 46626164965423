import React, {useState, useEffect} from "react";

import Accordion, {
    AccordionSection,
    AccordionTitle,
    AccordionContent
} from 'calcite-react/Accordion';

import styled from "styled-components";

const BeneficiarioModulo = styled.div`
    height: auto;
    background: #fff;
    border: 0;
    padding: 0px;
    margin: 4px;
    overflow:hidden;
    flex: 0 0 50%;
`;

const CumplimientoContainer = styled.div`
    padding: 0;
    margin: 0;
`;

const CumplimientoContainerHeader = styled.div`
    padding: 0;
    border: 1px solid #ccc;
    border-radius: 10px 10px 0px 0px;
    background-color: #c6c6c6;
    color: #fff;
`;

const TheadMeses1Sem = () => (
    <thead>
        <tr><td>1er Semestre</td><td>Ene</td><td>Feb</td><td>Mar</td><td>Abr</td><td>May</td><td>Jun</td></tr>
    </thead>
);

const TheadMeses2Sem = () => (
    <thead>
        <tr><td>2do Semestre</td><td>Jul</td><td>Ago</td><td>Sep</td><td>Oct</td><td>Nov</td><td>Dic</td></tr>
    </thead>
);

const BenefCumplimiento = ({cumplimiento}) => {
    const [minasistenciadias, ] = useState(16); //setMinasistenciadias
    const [yearSelected,] = useState(new Date().getFullYear()); //setYearSelected
    const [activeSectionIndexes, setActiveSectionIndexes] = useState([0]);
    const onAccordionChange = (evt, index) => {
        activeSectionIndexes.includes(index)
        ? setActiveSectionIndexes(activeSectionIndexes.filter(
            item => index !== item,
          ))
        : setActiveSectionIndexes([...activeSectionIndexes, index]);
    }

    // const [percTotal, setPercTotal] = useState(0);
    const [percTotalAsistencia, setPercTotalAsistencia] = useState(0);
    const [asistencia, ] = useState({ //setAsistencia
        1: {"dias": 0, "perc": 0},
        2: {"dias": 0, "perc": 0},
        3: {"dias": 0, "perc": 0},
        4: {"dias": 0, "perc": 0},
        5: {"dias": 0, "perc": 0},
        6: {"dias": 0, "perc": 0},
        7: {"dias": 0, "perc": 0},
        8: {"dias": 0, "perc": 0},
        9: {"dias": 0, "perc": 0},
        10: {"dias": 0, "perc": 0},
        11: {"dias": 0, "perc": 0},
        12: {"dias": 0, "perc": 0}
    });

    useEffect(() => {
        // const getAsistencias = (filter) => {
        //     let perctotalasistencia = 0;
        //     filter.forEach((itm, idx) => {
        //         const mes = parseInt(itm.Mes);
        //         const cantidad = parseInt(itm.Cantidad);
        //         let perc = (cantidad / minasistenciadias) * 100;
        //         perc = perc >= 100.0 ? 100 : perc;
        //         asistencia[mes].dias = cantidad;
        //         asistencia[mes].perc = perc.toFixed(1);
        //         perctotalasistencia = perctotalasistencia + perc;
        //     });
        //     // Obtener los porcentajes de los últimos 6 meses con datos
        //     if (cumplimiento.length>=6){
        //         const cumpli6months = cumplimiento.slice(Math.max(cumplimiento.length - 6, 1))
        //         // console.log(cumpli6months);
        //         perctotalasistencia = 0;
        //         cumpli6months.forEach((itm, idx) => {
        //             // const mes = parseInt(itm.Mes);
        //             const cantidad = parseInt(itm.Cantidad);
        //             let perc = (cantidad / minasistenciadias) * 100;
        //             perc = perc >= 100.0 ? 100 : perc;
        //             perctotalasistencia = perctotalasistencia + perc;
        //         });
        //         setPercTotalAsistencia((perctotalasistencia / 6).toFixed(2)); //Ultimos 6 meses
        //     } else {
        //         if (filter.length>0)
        //             setPercTotalAsistencia((perctotalasistencia / filter.length).toFixed(2)); //Todos los meses existentes en el filtro
        //         else
        //             setPercTotalAsistencia(0);
        //     }
        //     return perctotalasistencia;
        // }
        const getAsistencias = (filter) => {
            let perctotalasistencia = 0;
            filter.forEach((itm, idx) => {
                const mes = parseInt(itm.Mes);
                const cantidad = parseInt(itm.Cantidad);
                let perc = (cantidad / minasistenciadias) * 100;
                perc = perc >= 100.0 ? 100 : perc;
                asistencia[mes].dias = cantidad;
                asistencia[mes].perc = perc.toFixed(1);
                perctotalasistencia = perctotalasistencia + perc;
            });
            // Obtener los porcentajes de los últimos 6 meses con datos
            if (cumplimiento.length>=6){
                const cumpli6months = cumplimiento.slice(Math.max(cumplimiento.length - 6, 1))
                // console.log(cumpli6months);
                perctotalasistencia = 0;
                cumpli6months.forEach((itm, idx) => {
                    // const mes = parseInt(itm.Mes);
                    const cantidad = parseInt(itm.Cantidad);
                    let perc = (cantidad / minasistenciadias) * 100;
                    perc = perc >= 100.0 ? 100 : perc;
                    perctotalasistencia = perctotalasistencia + perc;
                });
                perctotalasistencia = (perctotalasistencia / cumpli6months.length);
                // setPercTotalAsistencia((perctotalasistencia / cumpli6months.length).toFixed(2)); //Ultimos 6 meses
            } else {
                if (filter.length>0){
                    perctotalasistencia = (perctotalasistencia / filter.length);
                    // setPercTotalAsistencia((perctotalasistencia / filter.length).toFixed(2)); //Todos los meses existentes en el filtro
                } else {
                    perctotalasistencia = 0;
                }
            }
            return perctotalasistencia;
        }
        
        if (cumplimiento && cumplimiento.length>0){
            const filter = cumplimiento.filter(i => parseInt(i.Año)===yearSelected);
            const perctotalasistencia = getAsistencias(filter);
            setPercTotalAsistencia(perctotalasistencia.toFixed(2));
            // setPercTotal(perctotalasistencia.toFixed(2));
        }

    }, [cumplimiento, minasistenciadias, asistencia, yearSelected]);
        
    return (
        <BeneficiarioModulo>
            <CumplimientoContainer>
                <CumplimientoContainerHeader>Cumplimiento Mensual</CumplimientoContainerHeader>
                <div className="cardBenefDetailsContainer-stats-tables">
                    <div className="cumplimiento-perc-total">Cumplimiento Total del <span className="cumplimiento-perc">{percTotalAsistencia}%</span></div>
                    
                    <Accordion iconPosition="start"
                        onAccordionChange={onAccordionChange}
                        activeSectionIndexes={activeSectionIndexes}
                    >
                    <AccordionSection>
                        <AccordionTitle>Asistencia</AccordionTitle>
                        <AccordionContent style={{padding:0}}>
                        <table>
                            <TheadMeses1Sem/>
                            <tbody>
                                <tr>
                                    <td>Días</td>
                                    <td style={{color: (asistencia[1].dias < minasistenciadias) ? 'red' : (asistencia[1].dias >= 20) ? 'green' : 'black'}}>{asistencia[1].dias}</td>
                                    <td style={{color: (asistencia[2].dias < minasistenciadias) ? 'red' : (asistencia[2].dias >= 20) ? 'green' : 'black'}}>{asistencia[2].dias}</td>
                                    <td style={{color: (asistencia[3].dias < minasistenciadias) ? 'red' : (asistencia[3].dias >= 20) ? 'green' : 'black'}}>{asistencia[3].dias}</td>
                                    <td style={{color: (asistencia[4].dias < minasistenciadias) ? 'red' : (asistencia[4].dias >= 20) ? 'green' : 'black'}}>{asistencia[4].dias}</td>
                                    <td style={{color: (asistencia[5].dias < minasistenciadias) ? 'red' : (asistencia[5].dias >= 20) ? 'green' : 'black'}}>{asistencia[5].dias}</td>
                                    <td style={{color: (asistencia[6].dias < minasistenciadias) ? 'red' : (asistencia[6].dias >= 20) ? 'green' : 'black'}}>{asistencia[6].dias}</td>
                                </tr>
                                <tr>
                                    <td>%</td>
                                    <td>{asistencia[1].perc}</td>
                                    <td>{asistencia[2].perc}</td>
                                    <td>{asistencia[3].perc}</td>
                                    <td>{asistencia[4].perc}</td>
                                    <td>{asistencia[5].perc}</td>
                                    <td>{asistencia[6].perc}</td>
                                </tr>
                            </tbody>
                        </table>
                        <table>
                            <TheadMeses2Sem/>
                            <tbody>
                                <tr>
                                    <td>Días</td>
                                    <td style={{color: (asistencia[7].dias < minasistenciadias) ? 'red' : (asistencia[7].dias >= 20) ? 'green' : 'black'}}>{asistencia[7].dias}</td>
                                    <td style={{color: (asistencia[8].dias < minasistenciadias) ? 'red' : (asistencia[8].dias >= 20) ? 'green' : 'black'}}>{asistencia[8].dias}</td>
                                    <td style={{color: (asistencia[9].dias < minasistenciadias) ? 'red' : (asistencia[9].dias >= 20) ? 'green' : 'black'}}>{asistencia[9].dias}</td>
                                    <td style={{color: (asistencia[10].dias < minasistenciadias) ? 'red' : (asistencia[10].dias >= 20) ? 'green' : 'black'}}>{asistencia[10].dias}</td>
                                    <td style={{color: (asistencia[11].dias < minasistenciadias) ? 'red' : (asistencia[11].dias >= 20) ? 'green' : 'black'}}>{asistencia[11].dias}</td>
                                    <td style={{color: (asistencia[12].dias < minasistenciadias) ? 'red' : (asistencia[12].dias >= 20) ? 'green' : 'black'}}>{asistencia[12].dias}</td>
                                </tr>
                                <tr>
                                    <td>%</td>
                                    <td>{asistencia[7].perc}</td>
                                    <td>{asistencia[8].perc}</td>
                                    <td>{asistencia[9].perc}</td>
                                    <td>{asistencia[10].perc}</td>
                                    <td>{asistencia[11].perc}</td>
                                    <td>{asistencia[12].perc}</td>
                                </tr>
                            </tbody>
                        </table>
                        </AccordionContent>
                    </AccordionSection>
                    
                </Accordion>
                </div>
            </CumplimientoContainer>
        </BeneficiarioModulo>
    );
    
}

export default BenefCumplimiento;

