// React
import React, {useState} from "react";
// Redux imports
import { useSelector, useDispatch } from "react-redux";
import { setProyecto } from "../../../redux/reducers/proyectos";

import ActionBar, {
  Action,
  ActionGroup,
  // BottomActionGroup
} from 'calcite-react/ActionBar';

// Styled Components
import styled from "styled-components";

const ActionBarLeft = styled.div`
  // float:left;
  // display: inline-flex;
  // position: relative;
  // width: auto;
  // height: 100%;
  // text-align: center;
  // z-index: 2;
  // left: 0px;
  overflow: hidden;
  // background-color: #4c4c4c;
  // color: #fff;
`;

const ItemMenuOptsIcon = styled.div`
  width: 16px;
  height: 16px;
  background: transpararent;
  font-size:0.95rem;
  font-weight:700;
  text-align: center;
`;

const MenuLeftVinculacionFormacion = props => {
    // const isCollapsed = useSelector(state => state.proyectos.collapsed);
    const [isCollapsed, setIsCollapsed] = useState(false);
    const activeAction = useSelector(state => state.proyectos.activeProyecto);
    const dispatch = useDispatch();

    const proyectosNames = {
      "":'Caracterización Social y Económica',
      "APOYOECONOMICO_FORMACION": "Apoyo económico",
      "ORIENTACION_VOCACIONAL": "Orientación vocacional",
      "ACOMPAÑAMIENTO_ACCEDER_EDUCACION": "Acompañamiento para acceder a la educación",
      "SEGUIMIENTO_PROCESO_FORMATIVO": "Seguimiento durante el proceso formativo",
      "CONFIGURACION": "Configuración del GRB", 
    };

    // useEffect(() => {
    //   dispatch(moduleLEFTPANELLoaded({activeModule:activeAction, headerTitle:proyectosNames[activeAction]}));
    // }, [dispatch]);

    const onToggleCollapse = (e) => {
      setIsCollapsed(!isCollapsed);
    };

    const turnOffModules = () => {
      // dispatch(moduleUSERSLoaded({loaded:false})); // Hide Users
      // dispatch(moduleBENEFICIARIOSLoaded({loaded:false}));
    }

    const actionSelected = (activeAction) => {
      dispatch(setProyecto({activeProyecto:activeAction, collapsed:isCollapsed, headerTitle:proyectosNames[activeAction]}));
      turnOffModules();
      switch (activeAction) {
        case 'APOYOECONOMICO_FORMACION':
          console.log('APOYOECONOMICO_FORMACION');
          break;
        case 'ORIENTACION_VOCACIONAL':
          console.log('ORIENTACION_VOCACIONAL');
          break;
        case 'ACOMPAÑAMIENTO_ACCEDER_EDUCACION':
          console.log('ACOMPAÑAMIENTO_ACCEDER_EDUCACION');
          break;
        case 'SEGUIMIENTO_PROCESO_FORMATIVO':
          console.log('SEGUIMIENTO_PROCESO_FORMATIVO');
          break;
        default:
          break;
      }
    };

    return (
      <ActionBarLeft>
      <ActionBar 
        // showCollapser={false}
        collapseLabel='Ocultar' 
        expandLabel='Expandir' 
        collapsed={isCollapsed}
        onToggleCollapse={onToggleCollapse}
      >
        <ActionGroup>
          <Action 
              onClick={() => actionSelected('APOYOECONOMICO_FORMACION')}
              active={activeAction === 'APOYOECONOMICO_FORMACION'}
              icon={<ItemMenuOptsIcon>AF</ItemMenuOptsIcon>}
            >
              Apoyo económico
          </Action>
          <Action
            onClick={() => actionSelected('ORIENTACION_VOCACIONAL')}
            active={activeAction === 'ORIENTACION_VOCACIONAL'}
            icon={<ItemMenuOptsIcon>OV</ItemMenuOptsIcon>}
          >
            Orientación vocacional
          </Action>
          <Action
            onClick={() => actionSelected('ACOMPAÑAMIENTO_ACCEDER_EDUCACION')}
            active={activeAction === 'ACOMPAÑAMIENTO_ACCEDER_EDUCACION'}
            icon={<ItemMenuOptsIcon>AE</ItemMenuOptsIcon>}
          >
            Acompañamiento para acceder a la educación
          </Action>
          <Action
            onClick={() => actionSelected('SEGUIMIENTO_PROCESO_FORMATIVO')}
            active={activeAction === 'SEGUIMIENTO_PROCESO_FORMATIVO'}
            icon={<ItemMenuOptsIcon>SF</ItemMenuOptsIcon>}
          >
            Seguimiento durante el proceso formativo
          </Action>
        
        </ActionGroup>
        {/* <BottomActionGroup>
        <Action icon={<GearIcon />}>Configuración</Action>
      </BottomActionGroup> */}
      </ActionBar>
      </ActionBarLeft>
    )

}

export default MenuLeftVinculacionFormacion;