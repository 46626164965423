// Copyright 2019 Ernesto Giron E.
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//     http://www.apache.org/licenses/LICENSE-2.0
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.​

// Guarda el Modulo dentro del programa seleccionado. Ejemplo: COVID-19

// ACTION TYPES //
export const types = {
  MODULO_LOADED: "MODULO_LOADED",
};

// REDUCERS //
export const initialState = {
  collapsed: false,
  activeModule: '',
  headerTitle:'Caracterización Social y Económica'
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.MODULO_LOADED:
      const { collapsed, activeModule, headerTitle } = action.payload;
      return {
        ...state,
        collapsed,
        activeModule,
        headerTitle
      };
    default:
      return state;
  }
};

// ACTIONS //
export const setModulo = options => ({ type: types.MODULO_LOADED, payload: options });
