// React
import React from "react";
// Redux imports
// import { useSelector } from "react-redux";
// Styled & Motion Components
import styled from "styled-components";
import SideNav from 'calcite-react/SideNav';
// 
import FilterBeneficiario from './filter_Beneficiario';
import FilterCovidAyudas from './filter_CovidAyudas';
import FilterCovidAcomp from './filter_CovidAcomp';

const GRBSideNavLeft = styled(SideNav)`
    background-color: #f8f8f8;
    color: #444;
    height: 100%;
    overflow: auto;

    /* Extra large devices (large laptops and desktops, 1200px and up) */
    @media only screen and (max-width: 1200px) {
        
    } 

    /* Large devices (laptops/desktops, 992px and up) */
    @media only screen and (max-width: 992px) {
        
    }

    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (max-width: 768px) {
      max-width: 240px;
    }

    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (max-width: 600px) {
      display:none;
    }

    /* Extra small devices (phones, 600px and down) */
    @media only screen and (max-width: 600px) {
      display:none;
    }

`;

const SideNavlLeftGRB = props => {
  // const isBeneficiariosLoaded = useSelector(state => state.beneficiarios.loaded);
  // const isFilterShow = useSelector(state => state.covid19.isFilterShow);
  return (
    <GRBSideNavLeft>
      <nav>
        {props.isFilterBeneficiariosShow && <FilterBeneficiario style={{ maxWidth: '320px' }}/>}
        {props.isFilterCovidAyudasShow && <FilterCovidAyudas style={{ maxWidth: '320px' }}/>}
        {props.isFilterCovidAcompShow && <FilterCovidAcomp style={{ maxWidth: '320px' }}/>}
      </nav>
    </GRBSideNavLeft>
  )

}

export default SideNavlLeftGRB;