// React
import React, {useState} from "react";

// Redux imports
import { useSelector, useDispatch } from "react-redux";
import { moduleNotificacionLoaded, updateEditedNotificacionData, 
  resetNotificacionesDatabyQuery } from "../redux/reducers/notas";

import { modulePrestamoLoaded, updateEditedPrestamoData, 
    resetPrestamosDatabyQuery } from "../redux/reducers/prestamos";
  
import { moduleAuxilioLoaded, updateEditedAuxilioData, 
  resetAuxiliosDatabyQuery } from "../redux/reducers/auxilios";
    
import { moduleUSERSLoaded, updateEditedUsersData } from "../redux/reducers/users";
import { moduleBENEFICIARIOSLoaded, 
  updateEditedBeneficiarioData, displayBeneficiariosFilter,
  resetSelectedBeneficiarioForPerfil, resetfilterBeneficiariosData
} from "../redux/reducers/beneficiarios";
import { setModulo } from "../redux/reducers/modulos";

import ActionBar, {
  Action,
  ActionGroup,
  BottomActionGroup
} from 'calcite-react/ActionBar';

// Styled Components
import styled from "styled-components";

// import BeneficiarioIcon from 'calcite-ui-icons-react/PersonIcon';
import GearIcon from 'calcite-ui-icons-react/GearIcon';
import TeamIcon from 'calcite-ui-icons-react/GroupIcon';
// import EducationIcon from 'calcite-ui-icons-react/EducationIcon';
// import HomeIcon from 'calcite-ui-icons-react/HomeIcon';
// import ViviendaIcon from 'calcite-ui-icons-react/LocatorIcon';
import WalkingIcon from 'calcite-ui-icons-react/WalkingIcon';
// import MedallaIcon from 'calcite-ui-icons-react/RibbonIcon';
import UsersIcon from 'calcite-ui-icons-react/UsersIcon';
import UsuarioIcon from 'calcite-ui-icons-react/UserIcon';
import NotificationIcon from 'calcite-ui-icons-react/BellIcon';

import prestamosIcon from '../assets/icons/prestamos_icon.png';
import auxilioIcon from '../assets/icons/auxilio_icon.png';
import golfIcon from '../assets/icons/golf-iron.png';
const GolfIcon = styled.div`
  width: 20px;
  height: 20px;
  background: rgba(255, 255, 255, 0.1) url(${golfIcon}) no-repeat center/cover;
`;
const AuxilioIcon = styled.div`
  width: 20px;
  height: 20px;
  background: rgba(255, 255, 255, 0.1) url(${auxilioIcon}) no-repeat center/cover;
`;
const PrestamosIcon = styled.div`
  width: 24px;
  height: 24px;
  background: rgba(255, 255, 255, 0.1) url(${prestamosIcon}) no-repeat center/cover;
`;

const ActionBarLeft = styled.div`
  // float:left;
  // display: inline-flex;
  // position: relative;
  // width: auto;
  // height: 100%;
  // text-align: center;
  // z-index: 2;
  // left: 0px;
  overflow: hidden;
  // background-color: #4c4c4c;
  // color: #fff;
`;

const PanelLeftGRB = props => {
    // const isUsersLoaded = useSelector(state => state.users.loaded);
    // const isCollapsed = useSelector(state => state.modulos.collapsed);
    const [isCollapsed, setIsCollapsed] = useState(false);
    const activeAction = useSelector(state => state.modulos.activeModule);
    const listUsers = useSelector(state => state.users.listUsers);
    const listBenef = useSelector(state => state.beneficiarios.listBenef);
    const resultQueryNotas = useSelector(state => state.notas.resultQueryNotas);
    const resultQueryPrestamos = useSelector(state => state.prestamos.resultQueryPrestamos);
    const resultQueryAuxilios = useSelector(state => state.auxilios.resultQueryAuxilios);
    const dispatch = useDispatch();

    const modulosNames = {
      "":'Caracterización Social y Económica',
      "BENEFICIARIOS": "Beneficiarios",
      "CADDIEMASTER": "Caddie Master",
      "EQUIPO": "Equipo de Trabajo",
      "NOTIFICACIONES": "Notificaciones Fundación",
      "ASPIRANTES": "Aspirantes",
      "INGRESOS": "Ingresos",
      "AUXILIOS": "Auxilios",
      "PRESTAMOS": "Prestamos",
      "TORNEOS": "Torneos", 
      "CONTRATISTAS": "Contratistas", 
      "JUNTA": "Junta Directiva", 
      "CONFIGURACION": "Configuración del GRB", 
    };

    // useEffect(() => {
    //   dispatch(setModulo({activeModule:activeAction, headerTitle:modulosNames[activeAction]}));
    // }, [dispatch]);

    const onToggleCollapse = (e) => {
      // dispatch(setModulo({collapsed:!isCollapsed}));
      setIsCollapsed(!isCollapsed);
    };

    const turnOffModules = () => {
      dispatch(moduleUSERSLoaded({loaded:false})); // Hide Users
      dispatch(moduleBENEFICIARIOSLoaded({loaded:false}));
      dispatch(moduleNotificacionLoaded({loaded:false}));
      dispatch(modulePrestamoLoaded({loaded:false}));
      dispatch(moduleAuxilioLoaded({loaded:false}));
    }

    const clearVariables = () => {
      dispatch(displayBeneficiariosFilter({isFilterShow:false}));
      dispatch(resetSelectedBeneficiarioForPerfil());
      dispatch(resetfilterBeneficiariosData());
      dispatch(resetNotificacionesDatabyQuery());
      dispatch(resetPrestamosDatabyQuery());
      dispatch(resetAuxiliosDatabyQuery()); 
    }

    const actionSelected = (activeAction) => {
      dispatch(setModulo({activeModule:activeAction, collapsed:isCollapsed, headerTitle:modulosNames[activeAction]}));
      turnOffModules();
      clearVariables();
      switch (activeAction) {
        case 'BENEFICIARIOS':
          // Mostrar Lista de Beneficiarios
          // dispatch(moduleBENEFICIARIOSLoaded({loaded:true}));
          if (listBenef && listBenef.length>0){
            dispatch(updateEditedBeneficiarioData({listBenef:[]}));
          }
          break;
        case 'CADDIEMASTER':
          // console.log('Caddie Master');
          break;
        case 'EQUIPO':
          // Mostrar Lista de Usuarios
          dispatch(moduleUSERSLoaded({loaded:true}));
          if (listUsers && listUsers.length>0){
            dispatch(updateEditedUsersData({listUsers:[]}));
          }
          break;
        case 'NOTIFICACIONES':
          // console.log('NOTIFICACIONES');
          // dispatch(moduleNotificacionLoaded({loaded:true}));
          if (resultQueryNotas!==null && resultQueryNotas.datos.length>0){
            dispatch(updateEditedNotificacionData({resultQueryNotas:null} ));
          }
          break;
        case 'AUXILIOS':
          // console.log('Auxilios');
          // dispatch(moduleAuxilioLoaded({loaded:true}));
          if (resultQueryAuxilios!==null && resultQueryAuxilios.datos.length>0){
            dispatch(updateEditedAuxilioData({resultQueryAuxilios:null} ));
          }
          break;
        case 'PRESTAMOS':
          // dispatch(modulePrestamoLoaded({loaded:true}));
          if (resultQueryPrestamos!==null && resultQueryPrestamos.datos.length>0){
            dispatch(updateEditedPrestamoData({resultQueryPrestamos:null} ));
          }
          break;
        default:
          break;
      }
    };

    return (
      <ActionBarLeft>
        <ActionBar
          collapseLabel='Ocultar' 
          expandLabel='Expandir' 
          collapsed={isCollapsed}
          onToggleCollapse={onToggleCollapse}
        >
          <ActionGroup>
          <Action
            onClick={() => actionSelected('BENEFICIARIOS')}
            active={activeAction === 'BENEFICIARIOS'}
            icon={<UsersIcon style={{color:'green'}} />}
          >
            Beneficiarios
          </Action>
          <Action
            onClick={() => actionSelected('CADDIEMASTER')}
            active={activeAction === 'CADDIEMASTER'}
            icon={<GolfIcon style={{color:'green'}} />}
          >
            Caddie Master
          </Action>
          </ActionGroup>
          <ActionGroup>
            <Action icon={<UsuarioIcon />}
              onClick={() => actionSelected('ASPIRANTES')}
              active={activeAction === 'ASPIRANTES'}
            >
              Aspirantes</Action>
            <Action
              onClick={() => actionSelected('INGRESOS')}
              active={activeAction === 'INGRESOS'}
              icon={<WalkingIcon />}
            >
              Ingresos
            </Action>
            {/* <Action
            onClick={() => actionSelected('FOTOS')}
            active={this.state.activeAction === 'FOTOS'}
            icon={<BasemapIcon />}
            >
              Fotos
            </Action> */}
          </ActionGroup>
          <ActionGroup>
            <Action
              onClick={() => actionSelected('EQUIPO')}
              active={activeAction === 'EQUIPO'}
              icon={<TeamIcon />}
              >Equipo</Action>
            {/* <Action icon={<PlusIcon />}>Junta</Action> */}

            <Action
              onClick={() => actionSelected('NOTIFICACIONES')}
              active={activeAction === 'NOTIFICACIONES'}
              icon={<NotificationIcon style={{color:'green'}}/>}
            >
              Notificaciones
            </Action>

          </ActionGroup>
          <ActionGroup>
            <Action
              onClick={() => actionSelected('AUXILIOS')}
              active={activeAction === 'AUXILIOS'}
              icon={<AuxilioIcon style={{color:'green'}}/>}
              >Auxilios</Action>

            <Action
              onClick={() => actionSelected('PRESTAMOS')}
              active={activeAction === 'PRESTAMOS'}
              icon={<PrestamosIcon style={{color:'green'}}/>}
            >
              Prestamos
            </Action>

          </ActionGroup>
          <BottomActionGroup>
          <Action icon={<GearIcon />}>Configuración</Action>
        </BottomActionGroup>
        </ActionBar>

      </ActionBarLeft>
    )

}

export default PanelLeftGRB;