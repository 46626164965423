// Copyright 2019 Ernesto Giron E.
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//     http://www.apache.org/licenses/LICENSE-2.0
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.​

// ACTION TYPES //
export const types = {
  USERS_LOGOUT: "USERS_LOGOUT",
  MODULE_USERS_LOADED: "MODULE_USERS_LOADED",
  MODULE_USERS_EDIT: "MODULE_USERS_EDIT",
  USERS_AUTH_SUCCESS: "USERS_AUTH_SUCCESS",
  USERS_AUTH_FAIL: "USERS_AUTH_FAIL",
  USERS_AUTH_START: "USERS_AUTH_START",
  USERS_DATA_REQUESTED: "USERS_DATA_REQUESTED",
  USERS_DATA_LOADED: "USERS_DATA_LOADED",
  USERS_LIST_LOADED: "USERS_LIST_LOADED",

  MODULE_USERS_UPDATED: "MODULE_USERS_UPDATED",
  MODULE_USERS_CREATED: "MODULE_USERS_CREATED",
  MODULE_USERS_DELETED: "MODULE_USERS_DELETED",

  MODULE_USERS_NOTIFICACION_DATABYQUERY_LOADED: "MODULE_USERS_NOTIFICACION_DATABYQUERY_LOADED",
  MODULE_USERS_NOTIFICACION_LOADED: "MODULE_USERS_NOTIFICACION_LOADED",
  MODULE_USERS_NOTIFICACION_RESETDATA: "MODULE_USERS_NOTIFICACION_RESETDATA",

  BENEFICIARIO_AUTH_START: "BENEFICIARIO_AUTH_START",
  BENEFICIARIO_AUTH_SUCCESS: "BENEFICIARIO_AUTH_SUCCESS",
  BENEFICIARIO_AUTH_FAIL: "BENEFICIARIO_AUTH_FAIL",

  MODULE_USERS_SERVER_MESSAGE: "MODULE_USERS_SERVER_MESSAGE"


};

// REDUCERS //
export const initialState = {
  loaded: false,
  user: null,
  listUsers: null,
  token: null,
  loggedIn: false,
  error: null,
  notificaciones: null,
  mensaje: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.USERS_AUTH_SUCCESS:
      const { user, token } = action.payload;
      return {
        user,
        token,
        loggedIn: true
      };
    case types.USERS_AUTH_FAIL:
      const { error } = action.payload;
      return {
        user: null,
        token: null,
        error,
        loggedIn: false
      };
    case types.MODULE_USERS_LOADED:
      const { loaded } = action.payload;
      return {
        ...state,
        loaded
      };
    case types.USERS_LIST_LOADED:
      const { listUsers } = action.payload;
      return {
        ...state,
        listUsers
      };
    case types.MODULE_USERS_EDIT:
      const { edit } = action.payload;
      return {
        ...state,
        edit
      };
    case types.USERS_LOGOUT:
      return {
        user: null,
        listUsers: null,
        notificaciones: null,
        token: null,
        error: null,
        loaded: false,
        loggedIn: false
      };
    case types.MODULE_USERS_NOTIFICACION_LOADED:
      const { notificaciones } = action.payload;
      return {
        ...state,
        notificaciones
      };
    case types.MODULE_USERS_SERVER_MESSAGE:
      const {mensaje} = action.payload;
      return {
        ...state,
        mensaje
      };
    default:
      return state;
  }
};

// ACTIONS //
export const moduleUSERSLoaded = options => ({ type: types.MODULE_USERS_LOADED, payload: options });

export const fetchUsersData = token => ({ 
  type: types.USERS_DATA_LOADED, 
  payload: {
    token: token
  }
});

export const resetUsers = () => ({  type: types.USERS_LIST_LOADED,  payload: {listUsers: null} });

export const updateEditedUsersData = listUsers => ({ 
  type: types.USERS_LIST_LOADED, 
  payload: listUsers
});

export const editUser = options => ({ type: types.MODULE_USERS_EDIT, payload: options });

export const startUserAuth = options => ({
  type: types.USERS_AUTH_START,
  payload: options
});

export const startBeneficiarioAuth = options => ({
  type: types.BENEFICIARIO_AUTH_START,
  payload: options
});

export const authLogout = () => ({
  type: types.USERS_LOGOUT
});

export const updateUserGRB = data => ({ 
  type: types.MODULE_USERS_UPDATED,  payload: data
});
export const insertUsersGRB = data => ({ 
  type: types.MODULE_USERS_CREATED,  payload: data
});
export const deleteUsersGRB = data => ({ 
  type: types.MODULE_USERS_DELETED,  payload: data
});

// Notifiaciones
export const fetchNotificacionesUserbyQuery = options => ({ 
  type: types.MODULE_USERS_NOTIFICACION_DATABYQUERY_LOADED, 
  payload: options
});
export const resetNotificaciones = () => ({ 
  type: types.MODULE_USERS_NOTIFICACION_RESETDATA, 
  payload: {notificaciones: null}
});

export const serverMensaje = mensaje => ({ 
  type: types.MODULE_USERS_SERVER_MESSAGE, 
  payload: {
    mensaje: mensaje
  }
});