import React, {useState, useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";
import ContentEditable from 'react-contenteditable';
import { updateBeneficiariosSalud, serverMensaje } from '../../redux/reducers/beneficiarios';
import {highlightAll, disableNewlines, pastePlainText } from '../../utils/utils';
import {showToaster} from './BenefShowMesg';
import Alert, {AlertMessage} from 'calcite-react/Alert'
import Button, { ButtonGroup } from 'calcite-react/Button';
import {MenuItem} from 'calcite-react/Menu';
import MultiSelect from 'calcite-react/MultiSelect';
import Accordion, {
    AccordionSection,
    AccordionTitle,
    AccordionContent
} from 'calcite-react/Accordion';

import styled from "styled-components";

import enfermedades from '../../data/salud_enfermedades.json';
import frecuencias from '../../data/frecuencia_object.json';

const BeneficiarioModulo = styled.div`
    height: auto;
    background: #fff;
    border: 0;
    padding: 0px;
    margin: 4px;
    overflow:hidden;
`;

const AccordionModulo = styled(Accordion)`
    padding:0;
`;
const AccordionTitleModule = styled(AccordionTitle)`
    background-color: #e6e6e6;
    color: #4d4d4d;
    font-size: 1.25em;
    font-weight: 700;
    // border-bottom: 1px solid #d9d9d9;
    // box-shadow: 0px 2px 6px #e1e1e1;
`;


const BenefSalud = ({salud}) => {
    const dispatch = useDispatch();
    const mensaje = useSelector(state => state.beneficiarios.mensaje);
    const users = useSelector(state => state.users);
    // const isEditable = users.user.tipo==='ADMINISTRADOR' ? true : false;
    // const isEditable = (users.user.tipo==='ADMINISTRADOR' && users.user.role==='A' && users.user.permisos==='A') ? true : false;
    const isEditable = (users.user.areatrabajo==='ADMINISTRATIVA' || users.user.areatrabajo==='TRABAJO SOCIAL' || users.user.areatrabajo==='PSICOLOGÍA' ) ? true : false;
    
    // const canView = (users.user.areatrabajo==='ADMINISTRATIVA' || users.user.areatrabajo==='TECNOLOGÍA' ) ? true : false;
    const [activeSectionIndexes, setActiveSectionIndexes] = useState([]);
    const [selectedFrecuenciaValues, setSelectedFrecuenciaValues] = useState(salud.frecuenciavisita); //.split('|').map(x=>+x)
    const [selectedEnfermedadesValues, setSelectedEnfermedadesValues] = useState(salud.enfermedades.split('|').map(x=>+x));
    const [accesoSelected, setAccesoSelected] = useState(parseInt(salud.acceso));
    const [satisfaccionservicioSelected, setSatisfaccionservicioSelected] = useState(parseInt(salud.satisfaccionservicio));
    const [discapacitadosSelected, setDiscapacitadosSelected] = useState(parseInt(salud.discapacitados));
    
    const [error, setError] = useState(null);
    const onAccordionChange = (evt, index) => {
        activeSectionIndexes.includes(index)
        ? setActiveSectionIndexes(activeSectionIndexes.filter(
            item => index !== item,
          ))
        : setActiveSectionIndexes([...activeSectionIndexes, index]);
    }
    
    const [S] = useState({
        "id": salud.id, //int
        "beneficiarioId": salud.beneficiarioId, //15
        "acceso": salud.acceso, //1
        "nombresalud": salud.nombresalud, //150
        "frecuenciavisita": salud.frecuenciavisita, //32
        "enfermedades": salud.enfermedades, //120
        "lugaratencion": salud.lugaratencion, //150
        "satisfaccionservicio": salud.satisfaccionservicio, //1
        "porquesatisfecho": salud.porquesatisfecho, //255
        "valorsaludmensual": salud.valorsaludmensual, //bigint
        "discapacitados": salud.discapacitados, //1
        "consumoalcohol": salud.consumoalcohol, //255
        "comentarios": salud.comentarios, //text
        "actualizacion": salud.actualizacion, //0000-00-00 00:00:00
        "version": salud.version,
    });

    const [itemsSalud, setItemsSalud] = useState([
        // {"key":"id", "label": "Identificador Único", "value": S['id']},
        // {"key":"beneficiarioId", "label": "Documento de Identificación", "value": S['beneficiarioId']},
        {"key":"acceso", "label": "La familia tienen acceso a servicio de salud?", "value": S['acceso']},
        {"key":"nombresalud", "label": "Cuál servicio de Salud?", "value": S['nombresalud']},
        {"key":"frecuenciavisita", "label": "Con qué frecuencia utiliza el servicio de salud o visita al médico?", "value": S['frecuenciavisita']},
        {"key":"enfermedades", "label": "Cuáles son las enfermedades más frecuentes del hogar?", "value": S['enfermedades']},
        {"key":"lugaratencion", "label": "En qué lugar son atendidos cuando requiren algún servicio de salud?", "value": S['lugaratencion']},
        {"key":"satisfaccionservicio", "label": "Usted y su familia se sienten satisfechos con el servicio de salud que tienen actualmente?", "value": S['satisfaccionservicio']},
        {"key":"porquesatisfecho", "label": "Por qué?", "value": S['porquesatisfecho']},
        {"key":"valorsaludmensual", "label": "Cuánto paga en salud mensualmente?", "value": S['valorsaludmensual']},
        {"key":"discapacitados", "label": "En la familia hay personas discapacitadas?", "value": S['discapacitados']},
        {"key":"consumoalcohol", "label": "Observación acerca del consumo de alcohol", "value": S['consumoalcohol']},
        // {"key":"comentarios", "label": "Comentarios en general de las condiciones de salud", "value": S['comentarios']},
        // {"key":"actualizacion", "label": "Última actualización", "value": S['actualizacion']},
        // {"key":"version", "label": "Versión", "value": S['version']}
    ]);

    useEffect(() => {
        // Guardar los resultados al salir, puede ser contraproducente al fallar la conexión con el servidor u otro evento no deseado
        // se podrián perder los datos modificafos
        
        return () => {
            // if (isUpdated){
            //     console.log('Saving appointment', isUpdated);
            // }
            dispatch(serverMensaje({}));
        }
    }, [dispatch]);

    useEffect(() => {
        // if (itemsSalud){
        //     // console.log(itemsSalud);
        // }
        if (mensaje.salud && mensaje.salud!=='') {
            if (typeof mensaje.salud === 'string' && mensaje.salud.toLowerCase().includes('error')){
                showToaster('error', 'Ocurrió un fallo en el servidor', mensaje.salud);
            } 
            // else {
            //     showToaster('success',"Salud del Beneficiario", mensaje.salud); //"🧭 " + mensaje
            // }
        }

    }, [mensaje.salud, dispatch]);

    const updateItemsSalud = (f, v) => {
        const newitemsSalud = itemsSalud.map((itm, index) => {
            if (itm.key===f){
                itm.value = v;
            }
            return itm;
        });
        setItemsSalud(newitemsSalud);
    };

    const updateDataonServer = () =>{
        dispatch(serverMensaje({salud:''}));
        dispatch(updateBeneficiariosSalud( { token: users.user.claveAPI, salud:S}));
        
    }

    const replaceSomeText = (s) =>{
        return s
            .replace(/&nbsp;/g, ' ')
            .replace(/<br>/g, '')
            .replace(/&amp;/g, '&')
            .replace(/&gt;/g, '>')
            .replace(/&lt;/g, '<').trim()
    }

    const validateInput = (f, v, final) => {
        const regex_onlynum = /[0-9,]|\./;
        // const regex_nombres = /#[a-zA-Z\xE1\xE9\xED\xF3\xFA\xC1\xC9\xCD\xD3\xDA\xF1\xD1]+#/i; // /^[a-zA-Z ]+$/;
        const regex_nombres = /^['`´a-zA-ZÀ-ÿ\u00C0-\u017F\s]+$/;
        // const regex_onlyletters = /#[a-zA-Z\xE1\xE9\xED\xF3\xFA\xC1\xC9\xCD\xD3\xDA\xF1\xD1]+#/i;
        const regex_gasto = /^[0-9]*(\.[0-9]+)?$/;
        let new_val = null;
        let err = null;
        switch (f) {
            case 'acceso':
                new_val = v; //replaceSomeText(v).substr(0,150);
                if (!regex_onlynum.test(new_val)) {
                    err = 'Dígite un tipo de acceso válido';
                    new_val = null;
                }
                break;
            case 'nombresalud':
                new_val = v.substr(0,150);
                if (final)
                    new_val = replaceSomeText(v).substr(0,150);
                if (!regex_nombres.test(new_val)) {
                    err = 'Dígite un nombre de Salud válido';
                    new_val = null;
                }
                break;
            case 'frecuenciavisita':
                new_val = v.substr(0,32);
                if (final)
                    new_val = replaceSomeText(v).substr(0,32);
                break;
            case 'enfermedades':
                new_val = v.substr(0,120);
                if (final)
                    new_val = replaceSomeText(v).substr(0,120);
                break;
            case 'lugaratencion':
                new_val = v.substr(0,150);
                if (final)
                    new_val = replaceSomeText(v).substr(0,150);
                break;
            case 'satisfaccionservicio':
                if (!regex_onlynum.test(v)) {
                    err = 'Dígite solo números válidos';
                    new_val = null;
                } else if(parseInt(v)>1){
                    err = 'Dígite un número válido';
                    new_val = null;
                }
                break;
            case 'porquesatisfecho':
                new_val = v.substr(0,255);
                if (final)
                    new_val = replaceSomeText(v).substr(0,255);
                break;
            case 'valorsaludmensual':
                new_val = replaceSomeText(v);
                if (!regex_gasto.test(new_val)) {
                    err = 'Dígite solo números válidos';
                    new_val = null;
                }
                break;
            case 'discapacitados':
                if (!regex_onlynum.test(v)) {
                    err = 'Dígite solo números válidos';
                    new_val = null;
                } else if(parseInt(v)>1){
                    err = 'Dígite un número válido';
                    new_val = null;
                }
                break;
            case 'consumoalcohol':
                new_val = v.substr(0,255);
                if (final)
                    new_val = replaceSomeText(v).substr(0,255);
                break;
            case 'comentarios':
                new_val = v.trim();
                if (final)
                    new_val = replaceSomeText(v);
                break;
            default:
                break;
        }
        setError(err);
        return new_val
    }
    const handleChange = evt => {
        const field = evt.currentTarget.id.split('_')[1];
        const new_val = validateInput(field, evt.target.value, false)
        if(new_val){
            S[field] = new_val
        };
    };
    const handleBlur = evt => {
        const val = evt.currentTarget.innerHTML;
        const field = evt.target.id.split('_')[1];
        const new_val = validateInput(field, val, true)
        if(new_val || new_val===''){
            S[field] = new_val;
            if (field!=='comentarios')
                updateItemsSalud(field, S[field]);
            updateDataonServer();
        };
        
    };

    const handleEnfermedadesMultiSelectChange = (values, items) => {
        let enf_str = "";
        if(values && values.length>0){
            values.map(v =>{
                return enf_str += v + '|';
            });
            enf_str = enf_str.slice(0,enf_str.length-1);
        }
        setSelectedEnfermedadesValues(values);
        S['enfermedades'] = enf_str;
        updateDataonServer();
    };
    const handleFrecuenciaMultiSelectChange = (values, items) => {
        let frecuencia_str = "";
        if(values && values.length>0){
            values.map(v =>{
                return frecuencia_str += v + ',';
            });
            frecuencia_str = frecuencia_str.slice(0,frecuencia_str.length-1);
        }
        setSelectedFrecuenciaValues(values);
        S['frecuenciavisita'] = frecuencia_str;
        updateDataonServer();
    };
    const renderEnfermedadesValue = (items) => {
        if (!items || items.length === 0) {
          return 'Seleccione una opción'
        } else if (items.length > 2) {
          // Template literals currently are not supported by MDX:
          // https://github.com/mdx-js/mdx/issues/218
          return items.length + ' enfermedades'
        } else {
          return items.map(item => item.props.children).join(', ')
        }
      }

    const accesoSelectButton = (selected) => {
        setAccesoSelected(selected);
        S['acceso'] = selected === 1 ? '1' : '0';
        updateDataonServer();
    }
    const satisfaccionservicioSelectButton = (selected) => {
        setSatisfaccionservicioSelected(selected);
        S['satisfaccionservicio'] = selected === 1 ? '1' : '0';
        updateDataonServer();
    }
    const discapacitadosSelectButton = (selected) => {
        setDiscapacitadosSelected(selected);
        S['discapacitados'] = selected === 1 ? '1' : '0';
        updateDataonServer();
    }


    return (
        <BeneficiarioModulo>
            <AccordionModulo
                onAccordionChange={onAccordionChange}
                activeSectionIndexes={activeSectionIndexes}
            >
                <AccordionSection>
                    <AccordionTitleModule>Salud</AccordionTitleModule>
                    {activeSectionIndexes.length>0 &&  <AccordionContent style={{marginBottom: '10px'}}>
                    {error && <div style={{color: 'red', width: '100%'}}>
                            <Alert red showIcon style={{minWidth: '100%', marginBottom: '10px'}}
                                showCloseLabel 
                                onClose={() => setError(null)}>
                                <AlertMessage>
                                    Error:{' '}
                                    <strong>{error}</strong>
                                </AlertMessage>
                            </Alert>
                        </div> 
                    }
                    {/* ITEMS */}
                        {
                            itemsSalud.map((itm, index) => {
                                // if (itm.value!==null && itm.value!=="" ){
                                    return (<div key={index} className="itemContainer">
                                        <div className="itemContainer-attr-long" 
                                            style={{backgroundColor:(index % 2 === 0) ? '#f8f8f8': '#fff'}}
                                        >{itm.label}</div>
                                        <div className="itemContainer-value" 
                                            style={{backgroundColor:(index % 2 === 0) ? '#f8f8f8': '#fff'}}
                                        >

                                        {   itm.key==='acceso'
                                            ?
                                            <ButtonGroup isToggle>
                                                <Button  clear={accesoSelected !== 1} disabled={!isEditable} 
                                                    onClick={() => {  accesoSelectButton(1) }}
                                                >Si</Button>
                                                <Button clear={accesoSelected !== 0} disabled={!isEditable} 
                                                    onClick={() => { accesoSelectButton(0) }}
                                                >No</Button>
                                            </ButtonGroup>
                                            :
                                            itm.key==='satisfaccionservicio'
                                            ?
                                            <ButtonGroup isToggle>
                                                <Button  clear={satisfaccionservicioSelected !== 1} disabled={!isEditable} 
                                                    onClick={() => { satisfaccionservicioSelectButton(1) }}
                                                >Si</Button>
                                                <Button clear={satisfaccionservicioSelected !== 0} disabled={!isEditable} 
                                                    onClick={() => { satisfaccionservicioSelectButton(0) }}
                                                >No</Button>
                                            </ButtonGroup>
                                            :
                                            itm.key==='discapacitados'
                                            ?
                                            <ButtonGroup isToggle>
                                                <Button  clear={discapacitadosSelected !== 1} disabled={!isEditable} 
                                                    onClick={() => {  discapacitadosSelectButton(1) }}
                                                >Si</Button>
                                                <Button clear={discapacitadosSelected !== 0} disabled={!isEditable} 
                                                    onClick={() => { discapacitadosSelectButton(0) }}
                                                >No</Button>
                                            </ButtonGroup>
                                            :
                                            itm.key==='enfermedades'
                                            ?
                                                <MultiSelect positionFixed disabled={!isEditable} 
                                                    renderValue={renderEnfermedadesValue}
                                                    onChange={handleEnfermedadesMultiSelectChange}
                                                    selectedValues={selectedEnfermedadesValues}
                                                    closeOnSelect={true}
                                                >
                                                    {enfermedades.enfermedades.map((f,i) => {
                                                        return <MenuItem key={f.id} value={f.id}>{f.nombre}</MenuItem>
                                                    })}
                                                </MultiSelect>
                                            :
                                            itm.key==='frecuenciavisita'
                                            ?
                                                <MultiSelect positionFixed disabled={!isEditable} 
                                                    onChange={handleFrecuenciaMultiSelectChange}
                                                    selectedValues={selectedFrecuenciaValues}
                                                    closeOnSelect={true}
                                                >
                                                    {frecuencias.frecuencias.map((f,i) => {
                                                        return <MenuItem key={f.id} value={f.name}>{f.name}</MenuItem>
                                                    })}
                                                </MultiSelect>
                                            :
                                            <ContentEditable
                                                id={'salud_'+itm.key}
                                                // innerRef={agendoEditable}
                                                html={itm.value || ''} // innerHTML of the editable div
                                                className='itemContainer-value-long'
                                                disabled={!isEditable}      // use true to disable editing
                                                onPaste={pastePlainText} // En caso se copie y pegue de otra pagina de internet
                                                onChange={handleChange} // handle innerHTML change
                                                onKeyPress={disableNewlines}  
                                                onFocus={highlightAll}
                                                onBlur={handleBlur}
                                                tagName='div' // Use a custom HTML tag (uses a div by default)
                                            />
                                        }
                                        </div>
                                    </div>)
                                // } 
                            })
                        }

                        <div className="Observaciones-Comentarios">
                            <div className="benef-texto-observaciones-title">Comentarios en general:</div>
                            <div className="benef-texto-observaciones"> 
                                <ContentEditable
                                    id={'salud_comentarios'}
                                    html={S.comentarios || ''} // innerHTML of the editable div
                                    disabled={!isEditable}        // use true to disable editing
                                    onPaste={pastePlainText}
                                    onChange={handleChange} // handle innerHTML change
                                    onFocus={highlightAll}
                                    onBlur={handleBlur}
                                    tagName='div' // Use a custom HTML tag (uses a div by default)
                                    style={{ "minHeight": "40px", "border": "1px dashed" }}
                                />
                            </div>
                        </div>

                        <div className="benef-texto-actualizacion">Última actualización: {S['actualizacion']}</div>

                    </AccordionContent>
                }
                </AccordionSection>
            </AccordionModulo>
        </BeneficiarioModulo>
    );
}

export default BenefSalud;

