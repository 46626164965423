// React
import React, {useState} from "react";

// Redux imports
import { useSelector, useDispatch } from "react-redux";
import { setModulo } from "../../redux/reducers/modulos";
// import { moduleLEFTPANELLoaded } from "../../redux/reducers/modulos";

// Styled Components
import styled from "styled-components";
import GearIcon from 'calcite-ui-icons-react/GearIcon';

import ActionBar, {
  Action,
  ActionGroup,
  BottomActionGroup
} from 'calcite-react/ActionBar';

import covid19Icon from '../../assets/icons/covid19_icon.png';
import saludIcon from '../../assets/icons/salud_icon_1.png';
import alimentacionIcon from '../../assets/icons/alimentacion_icon.png';
import deporteIcon from '../../assets/icons/deporte_icon.png';
// import formacionvidaIcon from '../../assets/icons/formacion_icon.png';
import fondoasissocialIcon from '../../assets/icons/fondoasissocial_icon.png';
import vestuarioIcon from '../../assets/icons/vestuario_icon.png';
import reconocimientoIcon from '../../assets/icons/reconocimiento_icon.png';
import segsocialIcon from '../../assets/icons/segsocial2_icon.png';

const ActionBarLeft = styled.div`
  // float:left;
  // display: inline-flex;
  // position: relative;
  // width: auto;
  // height: 100%;
  // text-align: center;
  // z-index: 2;
  // left: 0px;
  overflow: auto;
  // background-color: #4c4c4c;
  // color: #fff;
`;

// const ExtendedActionBar = styled(ActionBar)`
//   overflow: auto;
// `;


// const FormacionvidaIcon = styled.div`
//   width: 24px;
//   height: 24px;
//   background: rgba(255, 255, 255, 0.1) url(${formacionvidaIcon}) no-repeat center/cover;
// `;
const FondoasissocialIcon = styled.div`
  width: 24px;
  height: 24px;
  background: rgba(255, 255, 255, 0.1) url(${fondoasissocialIcon}) no-repeat center/cover;
`;
const VestuarioIcon = styled.div`
  width: 24px;
  height: 24px;
  background: rgba(255, 255, 255, 0.1) url(${vestuarioIcon}) no-repeat center/cover;
`;
const Covid19Icon = styled.div`
  width: 24px;
  height: 24px;
  background: rgba(255, 255, 255, 0.1) url(${covid19Icon}) no-repeat center/cover;
`;
const SaludIcon = styled.div`
  width: 24px;
  height: 24px;
  background: rgba(255, 255, 255, 0.1) url(${saludIcon}) no-repeat center/cover;
`;
const DeporteIcon = styled.div`
  width: 24px;
  height: 24px;
  background: rgba(255, 255, 255, 0.1) url(${deporteIcon}) no-repeat center/cover;
`;
const ReconocimientoIcon = styled.div`
  width: 24px;
  height: 24px;
  background: rgba(255, 255, 255, 0.1) url(${reconocimientoIcon}) no-repeat center/cover;
`;
const AlimentacionIcon = styled.div`
  width: 24px;
  height: 24px;
  background: rgba(255, 255, 255, 0.1) url(${alimentacionIcon}) no-repeat center/cover;
`;
const SegsocialIcon = styled.div`
  width: 24px;
  height: 24px;
  background: rgba(255, 255, 255, 0.1) url(${segsocialIcon}) no-repeat center/cover;
`;


const PanelLeftVivienda = props => {
    // const isCollapsed = useSelector(state => state.modulos.collapsed);
    const [isCollapsed, setIsCollapsed] = useState(false);
    const activeAction = useSelector(state => state.modulos.activeModule);
    const dispatch = useDispatch();
    
    const modulosNames = {
      "":"Bienestar",
      // "FORMACIONHABVIDA":"Formación en habilidades para la vida",
      "FONDOASISSOCIAL":"Fondo de asistencia social",
      "JORNADASSALUD": "Jornadas de salud",
      "DEPORTERECRECULTURA":"Deporte, recreación y cultura",
      "VESTUARIO":"Vestuario",
      "ALIMENTACION": "Alimentación",
      "SEGURIDADSOCIAL":"Seguridad Social",
      "RECONOCIMIENTOS":"Reconocimientos",
      "COVID19":"Plan de Solidaridad",
      "CONFIGURACION": "Configuración del GRB", 
    };

    const onToggleCollapse = (e) => {
      // dispatch(setModulo({collapsed:!isCollapsed, headerTitle:modulosNames[activeAction]}));
      setIsCollapsed(!isCollapsed);
    };

    const turnOffModules = () => {
      // dispatch(moduleUSERSLoaded({loaded:false})); // Hide Users
      // dispatch(moduleBENEFICIARIOSLoaded({loaded:false}));
    }

    const actionSelected = (activeAction) => {
      dispatch(setModulo({activeModule:activeAction, collapsed:isCollapsed, headerTitle:modulosNames[activeAction]}));
      turnOffModules();
      switch (activeAction) {
        // case 'FORMACIONHABVIDA':
        //   console.log('FORMACIONHABVIDA');
        //   // dispatch(moduleBENEFICIARIOSLoaded({loaded:true}));
        //   break;
        case 'FONDOASISSOCIAL':
          console.log('FONDOASISSOCIAL');
          break;
        case 'JORNADASSALUD':
          console.log('JORNADASSALUD');
          break;
        case 'DEPORTERECRECULTURA':
          console.log('DEPORTERECRECULTURA');
          break;
        case 'VESTUARIO':
          // console.log('VESTUARIO');
          break;
        case 'ALIMENTACION':
          console.log('ALIMENTACION');
          break;
        case 'SEGURIDADSOCIAL':
          console.log('SEGURIDADSOCIAL');
          break;
        case 'RECONOCIMIENTOS':
          console.log('RECONOCIMIENTOS');
          break;
        case 'COVID19':
          // console.log('COVID19');
          break;
        case 'CONFIGURACION':
          console.log('CONFIGURACION');
          break;
        default:
          break;
      }
    };

    return (
      <ActionBarLeft dir="ltr">
      <ActionBar 
        // layout='undefined'
        // expanded={true}
        collapseLabel='Ocultar' 
        expandLabel='Expandir' 
        collapsed={isCollapsed}
        onToggleCollapse={onToggleCollapse}
      >
        <ActionGroup>
        {/* <Action 
          onClick={() => actionSelected('FORMACIONHABVIDA')}
          active={activeAction === 'FORMACIONHABVIDA'}
          icon={<FormacionvidaIcon/>}
        >
          Formación Vida
        </Action> */}
          <Action
            onClick={() => actionSelected('FONDOASISSOCIAL')}
            active={activeAction === 'FONDOASISSOCIAL'}
            icon={<FondoasissocialIcon />}
          >
            Fondo A.S.
          </Action>
        <Action
          onClick={() => actionSelected('JORNADASSALUD')}
          active={activeAction === 'JORNADASSALUD'}
          icon={<SaludIcon />}
        >
          Jornadas Salud
        </Action>
        <Action
          onClick={() => actionSelected('DEPORTERECRECULTURA')}
          active={activeAction === 'DEPORTERECRECULTURA'}
          icon={<DeporteIcon />}
        >
          Deporte, Rec. y Cult.
        </Action>

        <Action
          onClick={() => actionSelected('VESTUARIO')}
          active={activeAction === 'VESTUARIO'}
          icon={<VestuarioIcon />}
        >
          Vestuario
        </Action>
        <Action
          onClick={() => actionSelected('ALIMENTACION')}
          active={activeAction === 'ALIMENTACION'}
          icon={<AlimentacionIcon />}
        >
          Alimentación
        </Action>
        <Action
          onClick={() => actionSelected('SEGURIDADSOCIAL')}
          active={activeAction === 'SEGURIDADSOCIAL'}
          icon={<SegsocialIcon />}
        >
          Seguridad Social
        </Action>
        <Action
          onClick={() => actionSelected('RECONOCIMIENTOS')}
          active={activeAction === 'RECONOCIMIENTOS'}
          icon={<ReconocimientoIcon />}
        >
          Reconocimientos
        </Action>
        </ActionGroup>
        <ActionGroup>
        <Action
          onClick={() => actionSelected('COVID19')}
          active={activeAction === 'COVID19'}
          icon={<Covid19Icon />}
        >
          Plan de Solidaridad
        </Action>
        
        </ActionGroup>

        <BottomActionGroup>
          <Action icon={<GearIcon />}>Configuración</Action>
        </BottomActionGroup>
      </ActionBar>
      </ActionBarLeft>
    )

}

export default PanelLeftVivienda;