// Copyright 2020 Ernesto Girón E.
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//     http://www.apache.org/licenses/LICENSE-2.0
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.​

import { call, put, takeLatest } from "redux-saga/effects";
import { types as configTypes } from "../reducers/notas";
import { 
  getListNotificaciones,
  getListNotificacionesbyQuery,
  actualizarNotificacion,
  crearNotificacion,
  eliminarNotificacion,
} from "../../utils/notas_req";

// WORKERS //
function* fetchNotificacionesData(action) {
  try {
    const listNotas = yield call(getListNotificaciones, action.payload.token);
    if (listNotas)
      yield put({
        type: configTypes.NOTIFICACION_LIST_LOADED,
        payload: {listNotas: listNotas}
      });
  } catch (e) {
    console.error("SAGA ERROR: notas_req/fetchNotificacionesData, ", e);
  }
}
function* fetchNotificacionesDatabyQuery(action) {
  try {
    const resultQueryNotas = yield call(getListNotificacionesbyQuery, action.payload);
    if (resultQueryNotas)
      yield put({
        type: configTypes.NOTIFICACION_STATS_LOADED,
        payload: {resultQueryNotas: resultQueryNotas}
      });
  } catch (e) {
    console.error("SAGA ERROR: notas_req/fetchNotificacionesData, ", e);
  }
}
function* updateNotificacion(action) {
  try {
    const resp = yield call(actualizarNotificacion, action.payload);
    if (resp && resp!=='')
      yield put({
        type: configTypes.NOTIFICACION_SERVER_MESSAGE,
        payload: {mensaje: {"notificaciones": resp}}
      });
  } catch (e) {
    console.error("SAGA ERROR: notas_req/updateNotificacion, ", e);
  }
}
function* insertNotificacion(action) {
  try {
    const resp = yield call(crearNotificacion, action.payload);
    if (resp && resp!=='')
      yield put({
        type: configTypes.NOTIFICACION_SERVER_MESSAGE,
        payload: {mensaje: {"notificaciones": resp}}
      });
  } catch (e) {
    console.error("SAGA ERROR: notas_req/insertNotificacion, ", e);
  }
}
function* deleteNotificacion(action) {
  try {
    const resp = yield call(eliminarNotificacion, action.payload);
    if (resp && resp!=='')
      yield put({
        type: configTypes.NOTIFICACION_SERVER_MESSAGE,
        payload: {mensaje: {"notificaciones": resp}}
      });
  } catch (e) {
    console.error("SAGA ERROR: notas_req/deleteNotificacion, ", e);
  }
}



// WATCHER //
export function* watchFetchNotasData() {
  yield takeLatest(configTypes.NOTIFICACION_DATA_LOADED, fetchNotificacionesData);
  yield takeLatest(configTypes.NOTIFICACION_DATABYQUERY_LOADED, fetchNotificacionesDatabyQuery);
  yield takeLatest(configTypes.NOTIFICACION_DATA_UPDATED, updateNotificacion);
  yield takeLatest(configTypes.NOTIFICACION_DATA_CREATED, insertNotificacion);
  yield takeLatest(configTypes.NOTIFICACION_DATA_DELETED, deleteNotificacion);

}
