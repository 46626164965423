import React, {useState, useEffect, useRef} from "react";
import { useSelector, useDispatch } from "react-redux";
import ContentEditable from 'react-contenteditable';
import { updateBeneficiarioObs, serverMensaje } from '../../redux/reducers/beneficiarios';
import {highlightAll, pastePlainText } from '../../utils/utils';
import Accordion, {
    AccordionSection,
    AccordionTitle,
    AccordionContent
} from 'calcite-react/Accordion';
import {showToaster} from './BenefShowMesg';

import styled from "styled-components";

const BeneficiarioModulo = styled.div`
    height: auto;
    background: #fff;
    border: 0;
    padding: 0px;
    margin: 4px;
    overflow:hidden;
`;

const AccordionModulo = styled(Accordion)`
    padding:0;
`;
const AccordionTitleModule = styled(AccordionTitle)`
    background-color: #e6e6e6;
    color: #4d4d4d;
    font-size: 1.25em;
    font-weight: 700;
    // border-bottom: 1px solid #d9d9d9;
    // box-shadow: 0px 2px 6px #e1e1e1;
`;


const BenefObservaciones = (obs) => {
    const dispatch = useDispatch();
    const mensaje = useSelector(state => state.beneficiarios.mensaje);
    const users = useSelector(state => state.users);
    // const isEditable = users.user.tipo==='ADMINISTRADOR' ? true : false;
    // const isEditable = (users.user.tipo==='ADMINISTRADOR' && users.user.role==='A' && users.user.permisos==='A') ? true : false;
    const isEditable = (users.user.areatrabajo==='ADMINISTRATIVA' || users.user.areatrabajo==='TRABAJO SOCIAL' || users.user.areatrabajo==='COORDINACIÓN' || users.user.areatrabajo==='PSICOLOGÍA' ) ? true : false;
    // const canView = (users.user.areatrabajo==='ADMINISTRATIVA' || users.user.areatrabajo==='TECNOLOGÍA' ) ? true : false;
    const [activeSectionIndexes, setActiveSectionIndexes] = useState([]);
    const observacionesEditable = useRef(); //

    const [O] = useState({
        "id": obs.obs.id,
        "beneficiarioId": obs.obs.beneficiarioId,
        "observacion": obs.obs.observacion,
        "actualizacion":obs.obs.actualizacion,
        "version": obs.obs.version
    });

    useEffect(() => {
        // Guardar los resultados al salir, puede ser contraproducente al fallar la conexión con el servidor u otro evento no deseado
        // se podrián perder los datos modificafos
        
        return () => {
            // if (isUpdated){
            //     console.log('Saving appointment', isUpdated);
            // }
            dispatch(serverMensaje({}));
        }
    }, [dispatch]);

    useEffect(() => {
        if (O){
            // console.log(O);
        }
        if (mensaje.observaciones && mensaje.observaciones!=='') {
            if (typeof mensaje.observaciones === 'string' && mensaje.observaciones.toLowerCase().includes('error')){
                showToaster('error', 'Ocurrió un fallo en el servidor', mensaje.observaciones);
            } 
            // else {
            //     showToaster('success',"Observaciones del Beneficiario", mensaje.observaciones); //"🧭 " + mensaje
            // }
        }

    }, [O, mensaje.observaciones, dispatch]);

    const updateDataonServer = () =>{
        dispatch(serverMensaje({observaciones:''}));
        dispatch(updateBeneficiarioObs( { token: users.user.claveAPI, obs:O}));
    }

    const onAccordionChange = (evt, index) => {
        activeSectionIndexes.includes(index)
        ? setActiveSectionIndexes(activeSectionIndexes.filter(
            item => index !== item,
          ))
        : setActiveSectionIndexes([...activeSectionIndexes, index]);
    }

    const handleChangeObservaciones = evt => {
        O.observacion = evt.target.value; //.substr(0,255);
    };
    const handleBlurObservaciones = (evt) => {
        // O.observacion = replaceSomeText(observacionesEditable.current.innerHTML) //.trim();.substr(0,255);
        O.observacion = observacionesEditable.current.innerHTML; // Aceptar todo carácter para luego crear las opciones del editor de texto
        updateDataonServer(); // Save to server inmediatively
    };
    
    return (
        <BeneficiarioModulo>
            <AccordionModulo
                onAccordionChange={onAccordionChange}
                activeSectionIndexes={activeSectionIndexes}
            >
                <AccordionSection>
                    <AccordionTitleModule>Observaciones Generales</AccordionTitleModule>
                    {activeSectionIndexes.length>0 &&  <AccordionContent style={{marginBottom: '10px'}}>
                        <h4>Información de interés que sobresalio durante la entrevista:</h4>
                        {/* <div className="benef-texto-observaciones">Observaciones: {O['observacion']}</div> */}
                        <div className="benef-texto-observaciones"> 
                            <ContentEditable
                                innerRef={observacionesEditable}
                                html={O.observacion || ''} // innerHTML of the editable div
                                // className='container-module-value'
                                disabled={!isEditable}        // use true to disable editing
                                onPaste={pastePlainText}
                                onChange={handleChangeObservaciones} // handle innerHTML change
                                onFocus={highlightAll}
                                onBlur={handleBlurObservaciones}
                                tagName='div' // Use a custom HTML tag (uses a div by default)
                                // style={{ "height": "40px", "border": "1px dashed" }}
                            />
                        </div>

                        {/* <div>Última actualización: {O['actualizacion']}</div> */}
                        {/* <div>Versión: {O['version']}</div> */}
                        <div className="benef-texto-actualizacion">Última actualización: {O['actualizacion']}</div>
                    </AccordionContent>
                }
                </AccordionSection>
            </AccordionModulo>
        </BeneficiarioModulo>
    );
}

    export default BenefObservaciones;

