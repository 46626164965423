// // Copyright 2020 Ernesto Girón E.
// // Licensed under the Apache License, Version 2.0 (the "License");
// // you may not use this file except in compliance with the License.
// // You may obtain a copy of the License at
// //     http://www.apache.org/licenses/LICENSE-2.0
// // Unless required by applicable law or agreed to in writing, software
// // distributed under the License is distributed on an "AS IS" BASIS,
// // WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// // See the License for the specific language governing permissions and
// // limitations under the License.​

import API from '../utils/ecaseAPI';
import * as Cookies from "js-cookie";

/**
 * restore a previously saved session
 */
export async function restoreSession(sessionId) {
    let authInfos = null;
  
    const serializedSession = Cookies.get(sessionId);
    const session = serializedSession; // && UserSession.deserialize(serializedSession);
  
    if (session) {
    //   const user = await session.getUser();
    //   const portal = await getPortal(null, {
    //     portal: session.portal,
    //     authentication: session
    //   });
    //   const token = session.token;
      // Editado por ahora
      console.log('restoreSession: Obteniendo datos de la session del usuario');
      const user = null;
      const token = null;
  
      authInfos = {
        user,
        token
      };
    }
  
    return authInfos;
}

/**
 * make sure the user is not logged in the next time they load the app
 */
export function signUserOut(sessionId) {
    // deleteUserSession(sessionId);
    console.log('signOut');
}

// save session & user for next time the user loads the app
export function saveUserSession(session, sessionId) {
    // get expiration from session
    // const expires = session.tokenExpires;
  
    // Cookies.set(sessionId, session.serialize(), {
    //   expires,
    //   sameSite: "strict"
    // });
    console.log('saveUserSession');
}
  
// delete a previously saved session
export function deleteUserSession(sessionId) {
  Cookies.remove(sessionId);
}

/**
 * Revisa si el usuario existe en la DB
 * @return Object - User profile
 */
export async function signUserIn(action) {
  let datosUsuario = null;
  try {
    await API('/users/login', {
        method: 'POST',
        data: JSON.stringify({"u":action.user.email,"p":action.user.password})
    }).then(response => response.data).then((data) => {
          // console.log("API response -->",data.usuario);
          const user = data.usuario;
          const token = data.usuario.claveAPI
          datosUsuario = { user,  token  };
        
    }).catch(function (error) {
        // handle error
        datosUsuario = {error: error.response.data.mensaje};
        console.log(`😱 problemas obteniendo validando los datos del Usuario del GRB.`);
    });

  } catch (e) {
      console.log(`😱 Axios request failed: ${e}`);
  }

  return datosUsuario
}

/**
 * Revisa si el beneficiario existe en la DB
 * @return Object - User profile
 */
export async function signBeneficiariosIn(action_payload) {
  let datosUsuario = null;
  try {
    await API('/beneficiarios/login', {
        method: 'POST',
        data: JSON.stringify({"u":action_payload.user.docId,"p":action_payload.user.password})
    }).then(response => response.data).then((data) => {
          // console.log("API response -->",data.usuario);
          // user: {
          //   tipo:values.tipo,
          //   nombre:data.usuario.nombres + " " + data.usuario.apellidos, 
          //   alias:data.usuario.alias,
          //   docId:data.usuario.docId,
          //   email:data.usuario.email, 
          //   claveAPI: data.usuario.claveAPI, 
          //   actividad:data.usuario.actividad, 
          //   foto:data.usuario.foto,
          //   activo:data.usuario.activo,
          //   estado:data.usuario.estado,
          //   actualizado:data.usuario.actualizacion,
          //   fecha:data.usuario.fecha,
          //   fechanacimiento:data.usuario.fechanacimiento,
          //   celular:data.usuario.celular
          // }

          const user = data.usuario;
          const token = data.usuario.claveAPI
          datosUsuario = { user,  token  };
        
    }).catch(function (error) {
        // handle error
        datosUsuario = {error: error.response.data.mensaje};
        console.log(`😱 problemas validando los datos del beneficiario del GRB.`);
    });

  } catch (e) {
      console.log(`😱 Axios request failed: ${e}`);
  }

  return datosUsuario
}

/********************************
// USERS
*********************************/

 /* 
 * Make a request for list of Users
 * @return Array of Users
 */
export async function getUsersData(action) {
  let listUsuario = null;
    try {
      // Load async Users data.
      await API('/users', {
          method: 'POST',
          data: JSON.stringify({"api":action.token})
      }).then(response => response.data).then((data) => {
          // console.log(data);
          listUsuario = data.datos
      }).catch(function (error) {
          // handle error
          console.log(`😱 problemas obteniendo los datos de usuarios del GRB.`);
          console.log(error);
      })

  } catch (e) {
      console.log(`😱 Axios request failed: ${e}`);
  }
    return listUsuario;
}

/* 
* Registra nuevos datos de usuario del GRB
* @return 
*/
export async function crearUsuarioGRB(action) {
  let resp = null;
  try {
      await API('/users/insert', {
          method: 'POST',
          data: JSON.stringify({"api":action.token, "usuario":action.usuario})
      }).then(response => response.data).then((data) => {
          resp = data.mensaje;
      }).catch((error) => {
          resp = '😱 Error ('+error.response.status+'): Problema registrando los datos de usuario en el servidor. ' + error.response.data.mensaje;
          console.log(`😱 problemas registrando la tabla de usuarios del GRB.`, error.response.data.mensaje);
          return resp;
      })

  } catch (e) {
      console.log(`😱 Axios request failed: ${e}`);
  }
  return resp;
}
/* 
* Actualiza los datos de usuario del GRB
* @return 
*/
export async function actualizarUsuarioGRB(action) {
  let resp = null;
  try {
      await API('/users/update', {
          method: 'POST',
          data: JSON.stringify({"api":action.token, "usuario":action.usuario})
      }).then(response => response.data).then((data) => {
          resp = data.mensaje;
      }).catch((error) => {
          resp = '😱 Error ('+error.response.status+'): Problema guardando los datos de usuario en el servidor. ' + error.response.data.mensaje;
          console.log(`😱 problemas actualizando la tabla de usuarios del GRB.`, error.response.data.mensaje);
          return resp;
      })
      
  } catch (e) {
      console.log(`😱 Axios request failed: ${e}`);
  }
  return resp;
}
/* 
* Elimina datos de usuario del GRB
* @return 
*/
export async function eliminarUsuarioGRB(action) {
  let resp = null;
  try {
      await API('/users/delete', {
          method: 'POST',
          data: JSON.stringify({"api":action.token, "usuario":action.usuario})
      }).then(response => response.data).then((data) => {
          resp = data.mensaje;
      }).catch((error) => {
          resp = '😱 Error ('+error.response.status+'): Problema eliminando los datos de usuario en el servidor. ' + error.response.data.mensaje;
          console.log(`😱 problemas eliminando la tabla de usuarios del GRB.`, error.response.data.mensaje);
          return resp;
      })

  } catch (e) {
      console.log(`😱 Axios request failed: ${e}`);
  }
  return resp;
}

// Notificaciones
/* 
 * Make a request for list of notifications
 * @return Array of Tabla de Lista de Notificaciones
 */
export async function getUsersNotificacionesbyQuery(action) {
    let notificaciones = null;
    try {
        await API('/notificaciones/query', { 
            method: 'POST',
            data: JSON.stringify({"api":action.token, "query":action.query})
        }).then(response => response.data).then((data) => {
            // console.log(data);
            if (data)
                notificaciones = data
        }).catch(function (error) {
            // handle error
            console.log(`😱 problemas obteniendo los datos de lista de notificaciones del GRB.`);
            console.log(error);
        })
  
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
    return notificaciones;
}