import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { filterBeneficiariosDataxCovidAcomp } from '../redux/reducers/covid19';
import {RemoveDuplicates, getDuplicates} from '../utils/utils';
import moment from 'moment';
import 'moment/locale/es-us';

import List, {
  ListItem,
  ListHeader,
  ListItemTitle,
  ListItemSubtitle
} from 'calcite-react/List';

// Styled & Motion Components
import styled from "styled-components";

// import covidbonos_Icon from '../assets/icons/bonos.png';
// import covidmercados_Icon from '../assets/icons/carrocompras.png';
// import benefactivos_Icon from '../assets/icons/benef_activos_green.png';
// import benefnoactivos_Icon from '../assets/icons/benef_noactivos_red.png';
import limpiarfiltro_Icon from '../assets/icons/filter_clear.png'; 

// Actividad
import caddieIcon from './beneficiario/imgs/caddie_icon_b.png';
import boleroIcon from './beneficiario/imgs/bolero_icon_b.png';
import boleadorIcon from './beneficiario/imgs/boleador_icon_b.png';
import monitorIcon from './beneficiario/imgs/monitor_icon_b.png';

import ChevronDownIcon from 'calcite-ui-icons-react/ChevronDownIcon';
import ChevronRightIcon from 'calcite-ui-icons-react/ChevronRightIcon';
// import UsersIcon from 'calcite-ui-icons-react/UsersIcon';
import UIcon from 'calcite-ui-icons-react/UserIcon';

// const AyudaBonosIcon = styled.div`
//   width: 16px;
//   height: 16px;
//   background: rgba(255, 255, 255, 0.1) url(${covidbonos_Icon}) no-repeat center/cover;
// `;
// const AyudaMercadoIcon = styled.div`
//   width: 16px;
//   height: 16px;
//   background: rgba(255, 255, 255, 0.1) url(${covidmercados_Icon}) no-repeat center/cover;
// `;

// const BenefActivosIcon = styled.div`
//   width: 16px;
//   height: 16px;
//   background: rgba(255, 255, 255, 0.1) url(${benefactivos_Icon}) no-repeat center/cover;
// `;
// const BenefNoActivosIcon = styled.div`
//   width: 16px;
//   height: 16px;
//   background: rgba(255, 255, 255, 0.1) url(${benefnoactivos_Icon}) no-repeat center/cover;
// `;
const CaddieIcon = styled.div`
  width: 20px;
  height: 20px;
  background: rgba(255, 255, 255, 0.1) url(${caddieIcon}) no-repeat center/cover;
  opacity: 0.7;
  // border-radius: 50%;
  // background-color: #249507;
`;
const BoleroIcon = styled.div`
  width: 20px;
  height: 20px;
  background: rgba(255, 255, 255, 0.1) url(${boleroIcon}) no-repeat center/cover;
  opacity: 0.7;
  // border-radius: 50%;
  // background-color: #249507;
`;
const BoleadorIcon = styled.div`
  width: 20px;
  height: 20px;
  background: rgba(255, 255, 255, 0.1) url(${boleadorIcon}) no-repeat center/cover;
  opacity: 0.7;
  // border-radius: 50%;
  // background-color: #249507;
`;
const MonitorIcon = styled.div`
  width: 20px;
  height: 20px;
  background: rgba(255, 255, 255, 0.1) url(${monitorIcon}) no-repeat center/cover;
  opacity: 0.7;
  // border-radius: 50%;
  // background-color: #249507;
`;
const UsuarioIcon = <UIcon style={{ width: '20px', height: '20px' }} />
const FiltroButton = styled.div`
  width: 16px;
  height: 16px;
  background: rgba(255, 255, 255, 0.1) url(${limpiarfiltro_Icon}) no-repeat center/cover;
  opacity: 0.4;

  &:hover {
    opacity: 1.0;
  }
`;
const FiltroIcon = <FiltroButton />

const FilterCovidAcomp = props => {
  const dispatch = useDispatch();
  const listBenefAcomp = useSelector(state => state.covid19.listBenefAcomp);
  const [listBenefDates, setListBenefDates] = useState([]);
  const filter = useSelector(state => state.covid19.filterAcomp);
  const [, setLastFilter] = useState([]);
  const [statsBeneficiario, setStatsBeneficiario] = useState({});

  const [openCategoriaSeguimiento, setOpenCategoriaSeguimiento] = useState(false);
  const [openJefeHogar, setOpenJefeHogar] = useState(false);
  const [openAcuerdoCorresp, setOpenAcuerdoCorresp] = useState(false);
  const [openPersonasaCargo, setOpenPersonasaCargo] = useState(false);
  const [openEstado, setOpenEstado] = useState(false);
  const [openActividad, setOpenActividad] = useState(false);
  const [openCategoria, setOpenCategoria] = useState(false);
  const [openActualizado, setOpenActualizado] = useState(false);
  const [openEdad, setOpenEdad] = useState(false);
  const [openEdadClub, setOpenEdadClub] = useState(false);
  
  let arrowLeftIconCategoriaSeguimiento = (openCategoriaSeguimiento) ? <ChevronDownIcon size={16} /> : <ChevronRightIcon size={16} />;
  let arrowLeftIconJefeHogar = (openJefeHogar) ? <ChevronDownIcon size={16} /> : <ChevronRightIcon size={16} />;
  let arrowLeftIconAcuerdoCorresp = (openAcuerdoCorresp) ? <ChevronDownIcon size={16} /> : <ChevronRightIcon size={16} />;
  let arrowLeftIconPersonasaCargo = (openPersonasaCargo) ? <ChevronDownIcon size={16} /> : <ChevronRightIcon size={16} />;
  let arrowLeftIconEstado = (openEstado) ? <ChevronDownIcon size={16} /> : <ChevronRightIcon size={16} />;
  let arrowLeftIconActividad =  (openActividad) ? <ChevronDownIcon size={16} /> : <ChevronRightIcon size={16} />;
  let arrowLeftIconCategoria =  (openCategoria) ? <ChevronDownIcon size={16} /> : <ChevronRightIcon size={16} />;
  let arrowLeftIconActualizado = (openActualizado) ? <ChevronDownIcon size={16} /> : <ChevronRightIcon size={16} />;
  let arrowLeftIconEdad = (openEdad) ? <ChevronDownIcon size={16} /> : <ChevronRightIcon size={16} />;
  let arrowLeftIconEdadClub = (openEdadClub) ? <ChevronDownIcon size={16} /> : <ChevronRightIcon size={16} />;
  
  // Stats
  useEffect(() => {
    if (listBenefAcomp && listBenefAcomp.length<=0){
      return
    }
    if (listBenefDates.length<=0){
      setListBenefDates([...listBenefAcomp].map(d => {
        // d.actualizacion = new Date(d.actualizacion);
        return d
      }));
    }

  }, [listBenefAcomp, listBenefDates]);
  // Stats
  useEffect(() => {
    if (listBenefDates && listBenefDates.length<=0){
      return
    }

    let datos = [];
    if (filter && filter.length>0){
        datos = [...filter];
    } else {
        datos = [...listBenefDates];

    }
    // General
    const total = datos.length;
    // const activos = datos.filter(b => b.activo==='1');
    // const noactivos = datos.filter(b => b.activo==='0');
    
    // Para manejar las estadisticas
    // const datos_unique = RemoveDuplicates(datos, 'docId');

    // Categorias Seguimiento (Covid-19) - tabla perfilbeneficiario
    const seg_empleado = datos.filter(b => b.categoriaseguimiento.toLowerCase()==='empleado');
    const seg_independiente = datos.filter(b => b.categoriaseguimiento.toLowerCase()==='independiente');
    const seg_disponible = datos.filter(b => b.categoriaseguimiento.toLowerCase()==='disponible');
    const seg_enproceso = datos.filter(b => b.categoriaseguimiento.toLowerCase()==='en proceso');
    const seg_dependiente = datos.filter(b => b.categoriaseguimiento.toLowerCase()==='dependiente');
    const seg_enacuerdo = datos.filter(b => b.categoriaseguimiento.toLowerCase()==='en acuerdo');
    
    // Jefe hogar
    const jefehogar = datos.filter(b => b.jefehogar.toLowerCase()==='si');
    const nojefehogar = datos.filter(b => b.jefehogar.toLowerCase()==='no');

    // En Acuerdo de corresponsabilidad
    const acuerdocorresp = datos.filter(b => b.acuerdocorresp==='1');
    const noacuerdocorresp = datos.filter(b => b.acuerdocorresp==='0');

    // Personas a Cargo
    const personasacargo_0 = datos.filter(b => b.personasacargo===0);
    const personasacargo_1 = datos.filter(b => b.personasacargo===1);
    const personasacargo_2 = datos.filter(b => b.personasacargo===2);
    const personasacargo_3 = datos.filter(b => b.personasacargo===3);
    const personasacargo_4 = datos.filter(b => b.personasacargo===4);
    const personasacargo_5 = datos.filter(b => b.personasacargo===5);
    const personasacargo_5plus = datos.filter(b => b.personasacargo>5);
    const personasacargo_6 = datos.filter(b => b.personasacargo===6);
    const personasacargo_7 = datos.filter(b => b.personasacargo===7);
    const personasacargo_8 = datos.filter(b => b.personasacargo===8);
    const personasacargo_9 = datos.filter(b => b.personasacargo===9);
    const personasacargo_10 = datos.filter(b => b.personasacargo>=10);

    // Estado
    const actualizado = datos.filter(b => b.estado.toLowerCase()==='actualizado');
    const pendiente = datos.filter(b => b.estado.toLowerCase()==='pendiente');
    const visitar = datos.filter(b => b.estado.toLowerCase()==='visitar');
    const revisar = datos.filter(b => b.estado.toLowerCase()==='revisar');
    const prueba = datos.filter(b => b.estado.toLowerCase()==='prueba');
    const retirado = datos.filter(b => b.estado.toLowerCase()==='retirado');
    const noapto = datos.filter(b => b.estado.toLowerCase()==='no apto');
    const suspendido = datos.filter(b => b.estado.toLowerCase()==='suspendido');
    // Actividad
    const caddie = datos.filter(b => b.actividad.toLowerCase()==='caddie');
    const bolero = datos.filter(b => b.actividad.toLowerCase()==='bolero');
    const boleador = datos.filter(b => b.actividad.toLowerCase()==='boleador');
    const monitor = datos.filter(b => b.actividad.toLowerCase()==='monitor');
    const entrenador = datos.filter(b => b.actividad.toLowerCase()==='entrenador');
    const externo = datos.filter(b => b.actividad.toLowerCase()==='externo');
    const empleadoclub = datos.filter(b => b.actividad.toLowerCase()==='empleado club');
    const empleadofundacion = datos.filter(b => b.actividad.toLowerCase()==='empleado fundación');
    const contratista = datos.filter(b => b.actividad.toLowerCase()==='contratista');
    const agenciadeempleo = datos.filter(b => b.actividad.toLowerCase()==='agencia de empleo');
    // Categoría
    const primera = datos.filter(b => b.categoria.toLowerCase()==='1');
    const segunda = datos.filter(b => b.categoria.toLowerCase()==='2');
    const tercera = datos.filter(b => b.categoria.toLowerCase()==='3');
    const pe1 = datos.filter(b => b.categoria.toLowerCase()==='pe1');
    const pe2 = datos.filter(b => b.categoria.toLowerCase()==='pe2');
    const pe3 = datos.filter(b => b.categoria.toLowerCase()==='pe3');
    const pp3 = datos.filter(b => b.categoria.toLowerCase()==='pp3');
    const serviciosgenerales = datos.filter(b => b.categoria.toLowerCase()==='servicios generales');
    const campogolf = datos.filter(b => b.categoria.toLowerCase()==='campo golf');
    const tiendagolf = datos.filter(b => b.categoria.toLowerCase()==='tienda golf');
    const lavadero = datos.filter(b => b.categoria.toLowerCase()==='lavadero');
    const cocina = datos.filter(b => b.categoria.toLowerCase()==='cocina');
    const masajes = datos.filter(b => b.categoria.toLowerCase()==='masajes');
    const cholados = datos.filter(b => b.categoria.toLowerCase()==='cholados');
    const peluqueria = datos.filter(b => b.categoria.toLowerCase()==='peluquería');

    // Edad
    const edadmenor = datos.filter(b => b.edad<18);
    const edad18 = datos.filter(b => b.edad>=18 && b.edad<21);
    const edad21 = datos.filter(b => b.edad>=21 && b.edad<26);
    const edad26 = datos.filter(b => b.edad>=26 && b.edad<31);
    const edad31 = datos.filter(b => b.edad>=31 && b.edad<36);
    const edad36 = datos.filter(b => b.edad>=36 && b.edad<41);
    const edad41 = datos.filter(b => b.edad>=41 && b.edad<45);
    const edad45 = datos.filter(b => b.edad>=45);

    // EdadClub
    const edadclub1 = datos.filter(b => b.edadclub<1);
    const edadclub2 = datos.filter(b => b.edadclub>=1 && b.edadclub<2);
    const edadclub3 = datos.filter(b => b.edadclub>=2 && b.edadclub<5);
    const edadclub4 = datos.filter(b => b.edadclub>=5 && b.edadclub<10);
    const edadclub5 = datos.filter(b => b.edadclub>=10 && b.edadclub<15);
    const edadclub6 = datos.filter(b => b.edadclub>=15 && b.edadclub<20);
    const edadclub7 = datos.filter(b => b.edadclub>=20 && b.edadclub<25);
    const edadclub8 = datos.filter(b => b.edadclub>=25 && b.edadclub<30);
    const edadclub9 = datos.filter(b => b.edadclub>=30 && b.edadclub<35);
    const edadclub10 = datos.filter(b => b.edadclub>=35 && b.edadclub<40);
    const edadclub11 = datos.filter(b => b.edadclub>=40 && b.edadclub<45);
    const edadclub12 = datos.filter(b => b.edadclub>=45 && b.edadclub<50);
    const edadclub13 = datos.filter(b => b.edadclub>=50);

    //  Fecha Actualizacion
    const d = moment(new Date(), "YYYY-MM-DD"); //.format("YYYY-MM-DD HH:mm:ss")
    const yesterday = moment(d).subtract(1 , 'day'); //d.setDate(d.getDate()-1);
    const l7days = moment(d).subtract(7 , 'day'); //d.setDate(d.getDate()-7);
    const l30days = moment(d).subtract(30 , 'day'); //d.setDate(d.getDate()-30);
    // 
    const hoy = datos.filter(b => moment(b.actualizacion).format("YYYY-MM-DD")===d.format("YYYY-MM-DD"));
    const ayer = datos.filter(b => moment(b.actualizacion).format("YYYY-MM-DD")===yesterday.format("YYYY-MM-DD"));
    const last7days = datos.filter(b => b.actualizacion>=l7days);
    const last30days = datos.filter(b => b.actualizacion>=l30days);
    // const rangofechas = datos.filter(b => b.actualizacion==='2020-06-07');

    setStatsBeneficiario({
        total:total,
        // activos:activos.length,
        // noactivos:noactivos.length,
        jefehogar: jefehogar.length,
        nojefehogar: nojefehogar.length,
        acuerdocorresp: acuerdocorresp.length,
        noacuerdocorresp: noacuerdocorresp.length,
        actualizado: actualizado.length,
        pendiente: pendiente.length,
        visitar: visitar.length,
        revisar: revisar.length,
        prueba: prueba.length,
        retirado: retirado.length,
        noapto: noapto.length,
        suspendido: suspendido.length,
        caddie: caddie.length,
        bolero: bolero.length,
        boleador: boleador.length,
        monitor: monitor.length,
        entrenador: entrenador.length,
        externo: externo.length,
        empleadoclub: empleadoclub.length,
        empleadofundacion: empleadofundacion.length,
        contratista: contratista.length,
        agenciadeempleo: agenciadeempleo.length,
        primera: primera.length,
        segunda: segunda.length,
        tercera: tercera.length,
        pe1: pe1.length,
        pe2: pe2.length,
        pe3: pe3.length,
        pp3: pp3.length,
        serviciosgenerales: serviciosgenerales.length,
        campogolf: campogolf.length,
        tiendagolf: tiendagolf.length,
        lavadero: lavadero.length,
        cocina: cocina.length,
        masajes: masajes.length,
        cholados: cholados.length,
        peluqueria: peluqueria.length,
        edadmenor: edadmenor.length,
        edad18: edad18.length,
        edad21: edad21.length,
        edad26: edad26.length,
        edad31: edad31.length,
        edad36: edad36.length,
        edad41: edad41.length,
        edad45: edad45.length,
        edadclub1: edadclub1.length,
        edadclub2: edadclub2.length,
        edadclub3: edadclub3.length,
        edadclub4: edadclub4.length,
        edadclub5: edadclub5.length,
        edadclub6: edadclub6.length,
        edadclub7: edadclub7.length,
        edadclub8: edadclub8.length,
        edadclub9: edadclub9.length,
        edadclub10: edadclub10.length,
        edadclub11: edadclub11.length,
        edadclub12: edadclub12.length,
        edadclub13: edadclub13.length,
        hoy: hoy.length,
        ayer: ayer.length,
        last7days: last7days.length,
        last30days: last30days.length,
        personasacargo_0:personasacargo_0.length,
        personasacargo_1:personasacargo_1.length,
        personasacargo_2:personasacargo_2.length,
        personasacargo_3:personasacargo_3.length,
        personasacargo_4:personasacargo_4.length,
        personasacargo_5:personasacargo_5.length,
        personasacargo_5plus:personasacargo_5plus.length,
        personasacargo_6:personasacargo_6.length,
        personasacargo_7:personasacargo_7.length,
        personasacargo_8:personasacargo_8.length,
        personasacargo_9:personasacargo_9.length,
        personasacargo_10:personasacargo_10.length,
        seg_empleado: seg_empleado.length,
        seg_independiente: seg_independiente.length,
        seg_disponible: seg_disponible.length,
        seg_enproceso: seg_enproceso.length,
        seg_dependiente: seg_dependiente.length,
        seg_enacuerdo: seg_enacuerdo.length
    });

  }, [filter, listBenefDates]);

  const toggleSublistCategoriaSeguimiento = () => { setOpenCategoriaSeguimiento(!openCategoriaSeguimiento); };
  const toggleSublistJefeHogar = () => { setOpenJefeHogar(!openJefeHogar); };
  const toggleSublistAcuerdoCorresp = () => { setOpenAcuerdoCorresp(!openAcuerdoCorresp); };
  const toggleSublistPersonasaCargo = () => { setOpenPersonasaCargo(!openPersonasaCargo); };
  const toggleSublistEstado = () => { setOpenEstado(!openEstado); };
  const toggleSublistActividad = () => { setOpenActividad(!openActividad); };
  const toggleSublistCategoria = () => { setOpenCategoria(!openCategoria); };
  const toggleSublistActualizado = () => { setOpenActualizado(!openActualizado); };
  const toggleSublistEdad = () => { setOpenEdad(!openEdad); };
  const toggleSublistEdadClub = () => { setOpenEdadClub(!openEdadClub); };

  const filtroGlobal = (filtro, arr) => {
    setLastFilter(arr);
    dispatch(filterBeneficiariosDataxCovidAcomp(arr));
  };
  const [listadeFiltros, setListadeFiltros] = useState([]);
  const [hasCategoriaSeguimientoFiltro, setHasCategoriaSeguimientoFiltro] = useState(false);
  const [hasJefeHogarFiltro, setHasJefeHogarFiltro] = useState(false);
  const [hasAcuerdoCorrespFiltro, setHasAcuerdoCorrespFiltro] = useState(false);
  const [hasPersonasaCargoFiltro, setHasPersonasaCargoFiltro] = useState(false);
  const [hasEstadoFiltro, setHasEstadoFiltro] = useState(false);
  const [hasActividadFiltro, setHasActividadFiltro] = useState(false);
  const [hasCategoriaFiltro, setHasCategoriaFiltro] = useState(false);
  const [hasEdadFiltro, setHasEdadFiltro] = useState(false);
  const [hasEdadClubFiltro, setHasEdadClubFiltro] = useState(false);
  const [hasActualizadoFiltro, setHasActualizadoFiltro] = useState(false);
  const filtersname = {
    "activos": "1",
    "noactivos": "0",
    "primera": "1",
    "segunda": "2",
    "tercera": "3",
    "jefehogar": "si",
    "nojefehogar": "no",
    "acuerdocorresp": "1",
    "noacuerdocorresp": "0",
  }
  
  // Procesa el filtro para Agregar, Remover o Limpiar todos las selecciones
  const procesarFiltro = (filtro, ultimoFiltro, isAddingFilter) => {
    const filtrosUnicos = [ 'jefehogar', 'nojefehogar', 'acuerdocorresp', 'noacuerdocorresp', 'hoy', 'ayer', 'last7days', 'last30days',
      'edadmenor', 'edad18', 'edad21', 'edad26', 'edad31', 'edad36', 'edad41', 'edad45', 'edadclub1', 'edadclub2', 
      'edadclub3', 'edadclub4', 'edadclub5', 'edadclub6', 'edadclub7', 'edadclub8', 'edadclub9', 
      'edadclub10', 'edadclub11', 'edadclub12', 'edadclub13', 'personasacargo_0', 'personasacargo_1', 'personasacargo_2', 'personasacargo_3', 
      'personasacargo_4', 'personasacargo_5', 'personasacargo_5plus', 'personasacargo_6', 'personasacargo_7', 'personasacargo_8', 'personasacargo_9', 'personasacargo_10'
    ];
    // const filtrosActivos = [ 'activos', 'noactivos' ];
    const filtrosCategoriaSeguimiento = [ 'empleado', 'independiente', 'disponible', 'en proceso', 'dependiente', 'en acuerdo' ];
    const filtrosJefeHogar = [ 'jefehogar', 'nojefehogar' ];
    const filtrosAcuerdoCorresp = [ 'acuerdocorresp', 'noacuerdocorresp' ];
    const filtrosPersonasaCargo = [ 'personasacargo_0', 'personasacargo_1', 'personasacargo_2', 'personasacargo_3', 'personasacargo_4', 'personasacargo_5', 'personasacargo_5plus',
      'personasacargo_6', 'personasacargo_7', 'personasacargo_8', 'personasacargo_9', 'personasacargo_10'
    ];
    const filtrosEdad = [ 'edadmenor', 'edad18', 'edad21', 'edad26', 'edad31', 'edad36', 'edad41', 'edad45' ]; 
    const filtrosEdadClub = [ 'edadclub1', 'edadclub2', 'edadclub3', 'edadclub4', 'edadclub5', 'edadclub6', 
      'edadclub7', 'edadclub8', 'edadclub9', 'edadclub10', 'edadclub11', 'edadclub12', 'edadclub13' 
    ]; 
    const filtrosActualizacion = [ 'hoy', 'ayer', 'last7days', 'last30days' ]; //'rangofechas'
    const filtrosEstado = ['actualizado', 'pendiente', 'visitar', 'revisar', 
      'prueba', 'retirado', 'no apto', 'suspendido' ];
    const filtrosActividad = ['caddie', 'bolero', 'boleador', 'monitor', 'entrenador', 
      'externo', 'empleado club', 'empleado fundación', 'contratista', 'agencia de empleo' ];
    const filtrosCategoria = ['primera','segunda','tercera','pe1','pe2','pe3','pp3','servicios generales',
      'campo golf','tienda golf','lavadero','cocina','masajes','cholados','peluquería',
    ];
    let filteredArray = [];
    // let hasActivoFilter = false;
    let hasCategoriaSeguimientoFilter = false;
    let hasJefeHogarFilter = false;
    let hasAcuerdoCorrespFilter = false;
    let hasPersonasaCargoFilter = false;
    let hasEstadoFilter = false;
    let hasActividadFilter = false;
    let hasCategoriaFilter = false;
    let hasEdadFilter = false;
    let hasEdadClubFilter = false;
    let hasActualizacionFilter = false;
    // let lastActivoFilterSelected = null;
    // let lastCategoriaSeguimientoFilterSelected = null;
    let lastJefeHogarFilterSelected = null;
    let lastAcuerdoCorrespFilterSelected = null;
    let lastPersonasaCargoFilterSelected = null;
    // let lastEstadoFilterSelected = null;
    // let lastActividadFilterSelected = null;
    // let lastCategoriaFilterSelected = null;
    let lastEdadFilterSelected = null;
    let lastEdadClubFilterSelected = null;
    let lastFechaFilterSelected = null;
    ultimoFiltro.forEach((uf, idx) => {
      // if (filtrosActivos.includes(uf.filtro)) {
      //   lastActivoFilterSelected = uf.filtro;
      //   hasActivoFilter = true;
      // }
      if (filtrosCategoriaSeguimiento.includes(uf.filtro)) {
        // lastCategoriaSeguimientoFilterSelected = uf.filtro;
        hasCategoriaSeguimientoFilter = true;
      }
      if (filtrosJefeHogar.includes(uf.filtro)) {
        lastJefeHogarFilterSelected = uf.filtro;
        hasJefeHogarFilter = true;
      }
      if (filtrosAcuerdoCorresp.includes(uf.filtro)) {
        lastAcuerdoCorrespFilterSelected = uf.filtro;
        hasAcuerdoCorrespFilter = true;
      }
      if (filtrosPersonasaCargo.includes(uf.filtro)) {
        lastPersonasaCargoFilterSelected = uf.filtro;
        hasPersonasaCargoFilter = true;
      }
      if (filtrosEdad.includes(uf.filtro)) {
        lastEdadFilterSelected = uf.filtro;
        hasEdadFilter = true;
      }
      if (filtrosEdadClub.includes(uf.filtro)) {
        lastEdadClubFilterSelected = uf.filtro;
        hasEdadClubFilter = true;
      }
      if (filtrosActualizacion.includes(uf.filtro)) {
        lastFechaFilterSelected = uf.filtro;
        hasActualizacionFilter = true;
      }
      if (filtrosEstado.includes(uf.filtro)) {
        // lastEstadoFilterSelected = uf.filtro;
        hasEstadoFilter = true;
      }
      if (filtrosActividad.includes(uf.filtro)) {
        // lastActividadFilterSelected = uf.filtro;
        hasActividadFilter = true;
      }
      if (filtrosCategoria.includes(uf.filtro)) {
        // lastCategoriaFilterSelected = uf.filtro;
        hasCategoriaFilter = true;
      }
    });

    // filteredArray = procesarFiltro(ultimoFiltro, true);
    // Remuevo todos los filtros de fecha y activo/noactivo seleccionados
    // Debido a que la selección por fechas no es multiple
    filtrosUnicos.forEach((f, idx) => {
      ultimoFiltro = [...ultimoFiltro].filter(itm => itm.filtro!==f)
    });
    setListadeFiltros(ultimoFiltro);
    
    // Concat Filter Estado, Actividad, Categoria, etc
    // Agregando los filtros seleccionados
    ultimoFiltro.forEach((uf, idx) => {
      let filtername = filtersname[uf.filtro] || uf.filtro;
      filteredArray = [...filteredArray].concat([...listBenefDates].filter(b => b[uf.field].toLowerCase()===filtername));
    });
    if (filteredArray.length<=0){
      if (isAddingFilter) filteredArray = [...listBenefDates]; //AddFilter
    } else {
      // Get Duplicates
      let duplicates = getDuplicates(filteredArray, 'id'); //docId
      if (duplicates.length>0) filteredArray = [...duplicates];
      // Remove duplicates
      // ES6+ // filteredArray = [...new Map(filteredArray.map(item => [item['docId'], item])).values()];
      // filteredArray = filteredArray.filter((set => f => !set.has(f.id) && set.add(f.id))(new Set));
      filteredArray = RemoveDuplicates(filteredArray, 'docId'); // Get Unique list of Beneficiarios
      // Sort
      // filteredArray.sort(((a, b) => a.docId < b.docId));
    }

    if (isAddingFilter){ //AddFilter
      // if (filtrosActivos.includes(filtro)) lastActivoFilterSelected = filtro;
      if (filtrosJefeHogar.includes(filtro)) lastJefeHogarFilterSelected = filtro;
      if (filtrosAcuerdoCorresp.includes(filtro)) lastAcuerdoCorrespFilterSelected = filtro;
      if (filtrosPersonasaCargo.includes(filtro)) lastPersonasaCargoFilterSelected = filtro;
      if (filtrosEdad.includes(filtro)) lastEdadFilterSelected = filtro;
      if (filtrosEdadClub.includes(filtro)) lastEdadClubFilterSelected = filtro;
      if (filtrosActualizacion.includes(filtro)) lastFechaFilterSelected = filtro;
    }

    if (hasCategoriaSeguimientoFilter){
      setHasCategoriaSeguimientoFiltro(true);
    } else {
      setHasCategoriaSeguimientoFiltro(false);
    }
    if (hasEstadoFilter) {
      setHasEstadoFiltro(true);
    } else {
      setHasEstadoFiltro(false);
    }
    if (hasActividadFilter) {
      setHasActividadFiltro(true);
    } else {
      setHasActividadFiltro(false);
    }
    if (hasCategoriaFilter) {
      setHasCategoriaFiltro(true);
    } else {
      setHasCategoriaFiltro(false);
    }

    // Último filtro selección única
    // if (hasActivoFilter){
    //   filteredArray = updateActivosFiltro(lastActivoFilterSelected, filteredArray, isAddingFilter);
    //   ultimoFiltro = [...ultimoFiltro].concat([{filtro:lastActivoFilterSelected, field:'activo'}]);
    // }
    if (hasJefeHogarFilter){
      setHasJefeHogarFiltro(true);
      filteredArray = updateJefeHogarFiltro(lastJefeHogarFilterSelected, filteredArray, isAddingFilter);
      ultimoFiltro = [...ultimoFiltro].concat([{filtro:lastJefeHogarFilterSelected, field:'jefehogar'}]);
    } else {
      setHasJefeHogarFiltro(false);
    }
    if (hasAcuerdoCorrespFilter){
      setHasAcuerdoCorrespFiltro(true);
      filteredArray = updateAcuerdoCorrespFiltro(lastAcuerdoCorrespFilterSelected, filteredArray, isAddingFilter);
      ultimoFiltro = [...ultimoFiltro].concat([{filtro:lastAcuerdoCorrespFilterSelected, field:'acuerdocorresp'}]);
    } else {
      setHasAcuerdoCorrespFiltro(false);
    }
    if (hasPersonasaCargoFilter){
      setHasPersonasaCargoFiltro(true);
      filteredArray = updatePersonasaCargoFiltro(lastPersonasaCargoFilterSelected, filteredArray, isAddingFilter);
      ultimoFiltro = [...ultimoFiltro].concat([{filtro:lastPersonasaCargoFilterSelected, field:'personasacargo'}]);
    } else {
      setHasPersonasaCargoFiltro(false);
    }
    if (hasEdadFilter){
      setHasEdadFiltro(true);
      filteredArray = updateEdadFiltro(lastEdadFilterSelected, filteredArray, isAddingFilter);
      ultimoFiltro = [...ultimoFiltro].concat([{filtro:lastEdadFilterSelected, field:'edad'}]);
    } else {
      setHasEdadFiltro(false);
    }
    if (hasEdadClubFilter){
      setHasEdadClubFiltro(true);
      filteredArray = updateEdadClubFiltro(lastEdadClubFilterSelected, filteredArray, isAddingFilter);
      ultimoFiltro = [...ultimoFiltro].concat([{filtro:lastEdadClubFilterSelected, field:'edadclub'}]);
    } else {
      setHasEdadClubFiltro(false);
    }
    if (hasActualizacionFilter){
      setHasActualizadoFiltro(true);
      filteredArray = updateActualizadoFiltro(lastFechaFilterSelected, filteredArray, isAddingFilter);
      ultimoFiltro = [...ultimoFiltro].concat([{filtro:lastFechaFilterSelected, field:'actualizacion'}]);
    } else {
      setHasActualizadoFiltro(false);
    }
    setListadeFiltros(ultimoFiltro);

    if (filteredArray.length<=0 && !isAddingFilter){
      filteredArray = [...listBenefDates];
    } 
    
    return filteredArray
  }

  // Filtros de Selección Única
  // const updateActivosFiltro = (lastActivoFilterSelected, filteredArray, isAddingFilter) => {
  //   if (isAddingFilter || filteredArray.length>0){
  //     if (lastActivoFilterSelected==='activos') filteredArray = [...filteredArray].filter(b => b['activo'].toLowerCase()==='1');
  //     if (lastActivoFilterSelected==='noactivos')  filteredArray = [...filteredArray].filter(b => b['activo'].toLowerCase()==='0');
  //   } else {
  //     if (lastActivoFilterSelected==='activos') filteredArray = [...listBenefDates].filter(b => b['activo'].toLowerCase()==='1');
  //     if (lastActivoFilterSelected==='noactivos')  filteredArray = [...listBenefDates].filter(b => b['activo'].toLowerCase()==='0');
  //   }

  //   return filteredArray
  // }
  
  // Filtros de Jefe Hogar
  const updateJefeHogarFiltro = (lastJefeHogarFilterSelected, filteredArray, isAddingFilter) => {
    if (isAddingFilter || filteredArray.length>0){
      if (lastJefeHogarFilterSelected==='jefehogar') filteredArray = [...filteredArray].filter(b => b['jefehogar'].toLowerCase()==='si');
      if (lastJefeHogarFilterSelected==='nojefehogar')  filteredArray = [...filteredArray].filter(b => b['jefehogar'].toLowerCase()==='no');
    } else {
      if (lastJefeHogarFilterSelected==='jefehogar') filteredArray = [...listBenefDates].filter(b => b['jefehogar'].toLowerCase()==='si');
      if (lastJefeHogarFilterSelected==='nojefehogar')  filteredArray = [...listBenefDates].filter(b => b['jefehogar'].toLowerCase()==='no');
    }

    return filteredArray
  }
  // Filtros Acuerdo de Corresponsabilidad
  const updateAcuerdoCorrespFiltro = (lastAcuerdoCorrespFilterSelected, filteredArray, isAddingFilter) => {
    if (isAddingFilter || filteredArray.length>0){
      if (lastAcuerdoCorrespFilterSelected==='acuerdocorresp') filteredArray = [...filteredArray].filter(b => b['acuerdocorresp'].toLowerCase()==='1');
      if (lastAcuerdoCorrespFilterSelected==='noacuerdocorresp')  filteredArray = [...filteredArray].filter(b => b['acuerdocorresp'].toLowerCase()==='0');
    } else {
      if (lastAcuerdoCorrespFilterSelected==='acuerdocorresp') filteredArray = [...listBenefDates].filter(b => b['acuerdocorresp'].toLowerCase()==='1');
      if (lastAcuerdoCorrespFilterSelected==='noacuerdocorresp')  filteredArray = [...listBenefDates].filter(b => b['acuerdocorresp'].toLowerCase()==='0');
    }

    return filteredArray
  }
  // Filtros de Personas a Cargo
  const updatePersonasaCargoFiltro = (lastPersonasaCargoFilterSelected, filteredArray, isAddingFilter) => {
    if (isAddingFilter || filteredArray.length>0){
      if (lastPersonasaCargoFilterSelected==='personasacargo_0') filteredArray = [...filteredArray].filter(b => b.personasacargo===0);
      if (lastPersonasaCargoFilterSelected==='personasacargo_1') filteredArray = [...filteredArray].filter(b => b.personasacargo===1);
      if (lastPersonasaCargoFilterSelected==='personasacargo_2') filteredArray = [...filteredArray].filter(b => b.personasacargo===2);
      if (lastPersonasaCargoFilterSelected==='personasacargo_3') filteredArray = [...filteredArray].filter(b => b.personasacargo===3);
      if (lastPersonasaCargoFilterSelected==='personasacargo_4') filteredArray = [...filteredArray].filter(b => b.personasacargo===4);
      if (lastPersonasaCargoFilterSelected==='personasacargo_5') filteredArray = [...filteredArray].filter(b => b.personasacargo===5);
      if (lastPersonasaCargoFilterSelected==='personasacargo_5plus') filteredArray = [...filteredArray].filter(b => b.personasacargo>5);
      if (lastPersonasaCargoFilterSelected==='personasacargo_6') filteredArray = [...filteredArray].filter(b => b.personasacargo===6);
      if (lastPersonasaCargoFilterSelected==='personasacargo_7') filteredArray = [...filteredArray].filter(b => b.personasacargo===7);
      if (lastPersonasaCargoFilterSelected==='personasacargo_8') filteredArray = [...filteredArray].filter(b => b.personasacargo===8);
      if (lastPersonasaCargoFilterSelected==='personasacargo_9') filteredArray = [...filteredArray].filter(b => b.personasacargo===9);
      if (lastPersonasaCargoFilterSelected==='personasacargo_10') filteredArray = [...filteredArray].filter(b => b.personasacargo>=10);
    } else {
      if (lastPersonasaCargoFilterSelected==='personasacargo_0') filteredArray = [...listBenefDates].filter(b => b.personasacargo===0);
      if (lastPersonasaCargoFilterSelected==='personasacargo_1') filteredArray = [...listBenefDates].filter(b => b.personasacargo===1);
      if (lastPersonasaCargoFilterSelected==='personasacargo_2') filteredArray = [...listBenefDates].filter(b => b.personasacargo===2);
      if (lastPersonasaCargoFilterSelected==='personasacargo_3') filteredArray = [...listBenefDates].filter(b => b.personasacargo===3);
      if (lastPersonasaCargoFilterSelected==='personasacargo_4') filteredArray = [...listBenefDates].filter(b => b.personasacargo===4);
      if (lastPersonasaCargoFilterSelected==='personasacargo_5') filteredArray = [...listBenefDates].filter(b => b.personasacargo===5);
      if (lastPersonasaCargoFilterSelected==='personasacargo_5plus') filteredArray = [...listBenefDates].filter(b => b.personasacargo>5);
      if (lastPersonasaCargoFilterSelected==='personasacargo_6') filteredArray = [...listBenefDates].filter(b => b.personasacargo===6);
      if (lastPersonasaCargoFilterSelected==='personasacargo_7') filteredArray = [...listBenefDates].filter(b => b.personasacargo===7);
      if (lastPersonasaCargoFilterSelected==='personasacargo_8') filteredArray = [...listBenefDates].filter(b => b.personasacargo===8);
      if (lastPersonasaCargoFilterSelected==='personasacargo_9') filteredArray = [...listBenefDates].filter(b => b.personasacargo===9);
      if (lastPersonasaCargoFilterSelected==='personasacargo_10') filteredArray = [...listBenefDates].filter(b => b.personasacargo>=10);
    }

    return filteredArray
  }
  const updateActualizadoFiltro = (lastFechaFilterSelected, filteredArray, isAddingFilter) => {
    const d = moment(new Date(), "YYYY-MM-DD"); //.format("YYYY-MM-DD HH:mm:ss")
    const yesterday = moment(d).subtract(1 , 'day'); //d.setDate(d.getDate()-1);
    const l7days = moment(d).subtract(7 , 'day'); //d.setDate(d.getDate()-7);
    const l30days = moment(d).subtract(30 , 'day'); //d.setDate(d.getDate()-30);
    
    if (isAddingFilter || filteredArray.length>0){
      if (lastFechaFilterSelected==='last30days') filteredArray = [...filteredArray].filter(b => b.actualizacion>=l30days);
      if (lastFechaFilterSelected==='last7days') filteredArray = [...filteredArray].filter(b => b.actualizacion>=l7days);
      if (lastFechaFilterSelected==='ayer') filteredArray = [...filteredArray].filter(b => moment(b.actualizacion).format("YYYY-MM-DD")===yesterday.format("YYYY-MM-DD"));
      if (lastFechaFilterSelected==='hoy') filteredArray = [...filteredArray].filter(b => moment(b.actualizacion).format("YYYY-MM-DD")===d.format("YYYY-MM-DD"));
    } else {
      if (lastFechaFilterSelected==='last30days') filteredArray = [...listBenefDates].filter(b => b.actualizacion>=l30days);
      if (lastFechaFilterSelected==='last7days') filteredArray = [...listBenefDates].filter(b => b.actualizacion>=l7days);
      if (lastFechaFilterSelected==='ayer') filteredArray = [...listBenefDates].filter(b => moment(b.actualizacion).format("YYYY-MM-DD")===yesterday.format("YYYY-MM-DD"));
      if (lastFechaFilterSelected==='hoy') filteredArray = [...listBenefDates].filter(b => moment(b.actualizacion).format("YYYY-MM-DD")===d.format("YYYY-MM-DD"));
    }
    return filteredArray
  }
  const updateEdadFiltro = (lastEdadFilterSelected, filteredArray, isAddingFilter) => {
    if (isAddingFilter || filteredArray.length>0){
      if (lastEdadFilterSelected==='edadmenor') filteredArray = [...filteredArray].filter(b => b.edad<18);
      if (lastEdadFilterSelected==='edad18') filteredArray = [...filteredArray].filter(b => b.edad>=18 && b.edad<21);
      if (lastEdadFilterSelected==='edad21') filteredArray = [...filteredArray].filter(b => b.edad>=21 && b.edad<26);
      if (lastEdadFilterSelected==='edad26') filteredArray = [...filteredArray].filter(b => b.edad>=26 && b.edad<31);
      if (lastEdadFilterSelected==='edad31') filteredArray = [...filteredArray].filter(b => b.edad>=31 && b.edad<36);
      if (lastEdadFilterSelected==='edad36') filteredArray = [...filteredArray].filter(b => b.edad>=36 && b.edad<41);
      if (lastEdadFilterSelected==='edad41') filteredArray = [...filteredArray].filter(b => b.edad>=41 && b.edad<45);
      if (lastEdadFilterSelected==='edad45') filteredArray = [...filteredArray].filter(b => b.edad>=45);
    } else {
      if (lastEdadFilterSelected==='edadmenor') filteredArray = [...listBenefDates].filter(b => b.edad<18);
      if (lastEdadFilterSelected==='edad18') filteredArray = [...listBenefDates].filter(b => b.edad>=18 && b.edad<21);
      if (lastEdadFilterSelected==='edad21') filteredArray = [...listBenefDates].filter(b => b.edad>=21 && b.edad<26);
      if (lastEdadFilterSelected==='edad26') filteredArray = [...listBenefDates].filter(b => b.edad>=26 && b.edad<31);
      if (lastEdadFilterSelected==='edad31') filteredArray = [...listBenefDates].filter(b => b.edad>=31 && b.edad<36);
      if (lastEdadFilterSelected==='edad36') filteredArray = [...listBenefDates].filter(b => b.edad>=36 && b.edad<41);
      if (lastEdadFilterSelected==='edad41') filteredArray = [...listBenefDates].filter(b => b.edad>=41 && b.edad<45);
      if (lastEdadFilterSelected==='edad45') filteredArray = [...listBenefDates].filter(b => b.edad>=45);
    }
    
    return filteredArray
  }
  const updateEdadClubFiltro = (lastEdadClubFilterSelected, filteredArray, isAddingFilter) => {
    if (isAddingFilter || filteredArray.length>0){
      if (lastEdadClubFilterSelected==='edadclub1') filteredArray = [...filteredArray].filter(b => b.edadclub<1);
      if (lastEdadClubFilterSelected==='edadclub2') filteredArray = [...filteredArray].filter(b => b.edadclub>=1 && b.edadclub<2);
      if (lastEdadClubFilterSelected==='edadclub3') filteredArray = [...filteredArray].filter(b => b.edadclub>=2 && b.edadclub<5);
      if (lastEdadClubFilterSelected==='edadclub4') filteredArray = [...filteredArray].filter(b => b.edadclub>=5 && b.edadclub<10);
      if (lastEdadClubFilterSelected==='edadclub5') filteredArray = [...filteredArray].filter(b => b.edadclub>=10 && b.edadclub<15);
      if (lastEdadClubFilterSelected==='edadclub6') filteredArray = [...filteredArray].filter(b => b.edadclub>=15 && b.edadclub<20);
      if (lastEdadClubFilterSelected==='edadclub7') filteredArray = [...filteredArray].filter(b => b.edadclub>=20 && b.edadclub<25);
      if (lastEdadClubFilterSelected==='edadclub8') filteredArray = [...filteredArray].filter(b => b.edadclub>=25 && b.edadclub<30);
      if (lastEdadClubFilterSelected==='edadclub9') filteredArray = [...filteredArray].filter(b => b.edadclub>=30 && b.edadclub<35);
      if (lastEdadClubFilterSelected==='edadclub10') filteredArray = [...filteredArray].filter(b => b.edadclub>=35 && b.edadclub<40);
      if (lastEdadClubFilterSelected==='edadclub11') filteredArray = [...filteredArray].filter(b => b.edadclub>=40 && b.edadclub<45);
      if (lastEdadClubFilterSelected==='edadclub12') filteredArray = [...filteredArray].filter(b => b.edadclub>=45 && b.edadclub<50);
      if (lastEdadClubFilterSelected==='edadclub13') filteredArray = [...filteredArray].filter(b => b.edadclub>=50);
    } else {
      if (lastEdadClubFilterSelected==='edadclub1') filteredArray = [...listBenefDates].filter(b => b.edadclub<1);
      if (lastEdadClubFilterSelected==='edadclub2') filteredArray = [...listBenefDates].filter(b => b.edadclub>=1 && b.edadclub<2);
      if (lastEdadClubFilterSelected==='edadclub3') filteredArray = [...listBenefDates].filter(b => b.edadclub>=2 && b.edadclub<5);
      if (lastEdadClubFilterSelected==='edadclub4') filteredArray = [...listBenefDates].filter(b => b.edadclub>=5 && b.edadclub<10);
      if (lastEdadClubFilterSelected==='edadclub5') filteredArray = [...listBenefDates].filter(b => b.edadclub>=10 && b.edadclub<15);
      if (lastEdadClubFilterSelected==='edadclub6') filteredArray = [...listBenefDates].filter(b => b.edadclub>=15 && b.edadclub<20);
      if (lastEdadClubFilterSelected==='edadclub7') filteredArray = [...listBenefDates].filter(b => b.edadclub>=20 && b.edadclub<25);
      if (lastEdadClubFilterSelected==='edadclub8') filteredArray = [...listBenefDates].filter(b => b.edadclub>=25 && b.edadclub<30);
      if (lastEdadClubFilterSelected==='edadclub9') filteredArray = [...listBenefDates].filter(b => b.edadclub>=30 && b.edadclub<35);
      if (lastEdadClubFilterSelected==='edadclub10') filteredArray = [...listBenefDates].filter(b => b.edadclub>=35 && b.edadclub<40);
      if (lastEdadClubFilterSelected==='edadclub11') filteredArray = [...listBenefDates].filter(b => b.edadclub>=40 && b.edadclub<45);
      if (lastEdadClubFilterSelected==='edadclub12') filteredArray = [...listBenefDates].filter(b => b.edadclub>=45 && b.edadclub<50);
      if (lastEdadClubFilterSelected==='edadclub13') filteredArray = [...listBenefDates].filter(b => b.edadclub>=50);
    }
    return filteredArray
  }

  // Agrega filtro a la lista de beneficiarios
  const addFilter = (filtro, field) => { 
    let filtername = filtersname[filtro] || filtro;
    let filteredArray = [];
    let ultimoFiltro = [...listadeFiltros];
    const isFiltered = ultimoFiltro.filter(lf => lf.filtro===filtro);
    // Actualizo la lista de filtros agregando solo nuevos y/o únicos
    if (isFiltered && isFiltered.length===0){
      ultimoFiltro = [...ultimoFiltro].concat([{filtro:filtro, field:field}]);
      setListadeFiltros(ultimoFiltro);
    }
      
    if (ultimoFiltro.length===1){
      if (field!=='actualizacion' && field!=='edad' && field!=='edadclub' 
        && field!=='personasacargo' ){
        filteredArray = listBenefDates.filter(b => b[field].toLowerCase()===filtername);
        
        if (field==='categoriaseguimiento') setHasCategoriaSeguimientoFiltro(true);
        if (field==='jefehogar') setHasJefeHogarFiltro(true);
        if (field==='acuerdocorresp') setHasAcuerdoCorrespFiltro(true);
        if (field==='estado') setHasEstadoFiltro(true);
        if (field==='actividad') setHasActividadFiltro(true);
        if (field==='categoria') setHasCategoriaFiltro(true);
        
      } else if (field==='personasacargo'){
        setHasPersonasaCargoFiltro(true);
        filteredArray = updatePersonasaCargoFiltro(filtro, filteredArray, false);
      } else if (field==='edad'){
        setHasEdadFiltro(true);
        filteredArray = updateEdadFiltro(filtro, filteredArray, false);
      } else if (field==='edadclub'){
        setHasEdadClubFiltro(true);
        filteredArray = updateEdadClubFiltro(filtro, filteredArray, false);
      } else if (field==='actualizacion') {
        setHasActualizadoFiltro(true);
        filteredArray = updateActualizadoFiltro(filtro, filteredArray, false);
      }
    } else {
      filteredArray = procesarFiltro(filtro, ultimoFiltro, true);
    }
    
    filtroGlobal(filtro, filteredArray);
  };

  const removeFilter = (filtro, field) => { 
    let filteredArray = [];
    let ultimoFiltro = [...listadeFiltros].filter(l => l.filtro!==filtro);
    setListadeFiltros(ultimoFiltro);
    
    if (ultimoFiltro.length<1){
      if (field==='categoriaseguimiento') setHasCategoriaSeguimientoFiltro(false);
      if (field==='jefehogar') setHasJefeHogarFiltro(false);
      if (field==='acuerdocorresp') setHasAcuerdoCorrespFiltro(false);
      if (field==='estado') setHasEstadoFiltro(false);
      if (field==='actividad') setHasActividadFiltro(false);
      if (field==='categoria') setHasCategoriaFiltro(false);
      if (field==='personasacargo') setHasPersonasaCargoFiltro(false);
      if (field==='edad') setHasEdadFiltro(false);
      if (field==='edadclub') setHasEdadClubFiltro(false);
      if (field==='actualizacion') setHasActualizadoFiltro(false);

      filteredArray = [...listBenefDates];
    } else {
      filteredArray = procesarFiltro(filtro, ultimoFiltro, false);
    }
    
    filtroGlobal(filtro, filteredArray);
  };

  const clearFilter = (filtros) => {
    let ultimoFiltro = [...listadeFiltros];
    // Remueve todos los filtros seleccionados
    filtros.forEach((f, idx) => {
      ultimoFiltro = [...ultimoFiltro].filter(itm => itm.filtro!==f)
    });

    let filteredArray = procesarFiltro(null, ultimoFiltro, false);
    
    filtroGlobal(null, filteredArray);
  }

  // Filtro por Activo / No Activos
  // const [selectedActivos, setSelectedActivos] = useState(false);
  // const [selectedNoActivos, setSelectedNoActivos] = useState(false);
  // const filterSublistTotal = (filtro) => { 
  //   if (filtro==='activos'){
  //     (!selectedActivos) ? addFilter(filtro, 'activo') : removeFilter(filtro, 'activo');
  //     setSelectedActivos(!selectedActivos);
  //     setSelectedNoActivos(false);
  //   }
  //   if (filtro==='noactivos'){
  //     (!selectedNoActivos) ? addFilter(filtro, 'activo') : removeFilter(filtro, 'activo');
  //     setSelectedNoActivos(!selectedNoActivos);
  //     setSelectedActivos(false);
  //   }
  // };

  
  // Filtro por Categoria Seguimiento
  const [selectedEmpleado, setSelectedEmpleado] = useState(false);
  const [selectedIndependiente, setSelectedIndependiente] = useState(false);
  const [selectedDisponible, setSelectedDisponible] = useState(false);
  const [selectedEnproceso, setSelectedEnproceso] = useState(false);
  const [selectedDependiente, setSelectedDependiente] = useState(false);
  const [selectedenAcuerdo, setSelectedenAcuerdo] = useState(false);
  const filterSublistCategoriaSeguimiento = (filtro) => { 
    if (filtro==='empleado'){
      (!selectedEmpleado) ? addFilter(filtro, 'categoriaseguimiento') : removeFilter(filtro, 'categoriaseguimiento');
      setSelectedEmpleado(!selectedEmpleado);
    }
    if (filtro==='independiente'){
      (!selectedIndependiente) ? addFilter(filtro, 'categoriaseguimiento') : removeFilter(filtro, 'categoriaseguimiento');
      setSelectedIndependiente(!selectedIndependiente);
    }
    if (filtro==='disponible'){
      (!selectedDisponible) ? addFilter(filtro, 'categoriaseguimiento') : removeFilter(filtro, 'categoriaseguimiento');
      setSelectedDisponible(!selectedDisponible);
    }
    if (filtro==='en proceso'){
      (!selectedEnproceso) ? addFilter(filtro, 'categoriaseguimiento') : removeFilter(filtro, 'categoriaseguimiento');
      setSelectedEnproceso(!selectedEnproceso);
    }
    if (filtro==='dependiente'){
      (!selectedDependiente) ? addFilter(filtro, 'categoriaseguimiento') : removeFilter(filtro, 'categoriaseguimiento');
      setSelectedDependiente(!selectedDependiente);
    }
    if (filtro==='en acuerdo'){
      (!selectedenAcuerdo) ? addFilter(filtro, 'categoriaseguimiento') : removeFilter(filtro, 'categoriaseguimiento');
      setSelectedenAcuerdo(!selectedenAcuerdo);
    }
    
  };

  // Filtro por Jefe Hogar
  const [selectedJefeHogar, setSelectedJefeHogar] = useState(false);
  const [selectedNoJefeHogar, setSelectedNoJefeHogar] = useState(false);
  const filterSublistJefeHogar = (filtro) => { 
    if (filtro==='jefehogar'){
      (!selectedJefeHogar) ? addFilter(filtro, 'jefehogar') : removeFilter(filtro, 'jefehogar');
      setSelectedJefeHogar(!selectedJefeHogar);
      setSelectedNoJefeHogar(false);
    }
    if (filtro==='nojefehogar'){
      (!selectedNoJefeHogar) ? addFilter(filtro, 'jefehogar') : removeFilter(filtro, 'jefehogar');
      setSelectedNoJefeHogar(!selectedNoJefeHogar);
      setSelectedJefeHogar(false);
    }
  };

  // Filtro Acuerdo corresponsabilidad
  const [selectedAcuerdoCorresp, setSelectedAcuerdoCorresp] = useState(false);
  const [selectedNoAcuerdoCorresp, setSelectedNoAcuerdoCorresp] = useState(false);
  const filterSublistAcuerdoCorresp = (filtro) => { 
    if (filtro==='acuerdocorresp'){
      (!selectedAcuerdoCorresp) ? addFilter(filtro, 'acuerdocorresp') : removeFilter(filtro, 'acuerdocorresp');
      setSelectedAcuerdoCorresp(!selectedAcuerdoCorresp);
      setSelectedNoAcuerdoCorresp(false);
    }
    if (filtro==='noacuerdocorresp'){
      (!selectedNoAcuerdoCorresp) ? addFilter(filtro, 'acuerdocorresp') : removeFilter(filtro, 'acuerdocorresp');
      setSelectedNoAcuerdoCorresp(!selectedNoAcuerdoCorresp);
      setSelectedAcuerdoCorresp(false);
    }
  };

  // Filtro Personas a Cargo
  const [selectedPersonasaCargo0, setSelectedPersonasaCargo0] = useState(false);
  const [selectedPersonasaCargo1, setSelectedPersonasaCargo1] = useState(false);
  const [selectedPersonasaCargo2, setSelectedPersonasaCargo2] = useState(false);
  const [selectedPersonasaCargo3, setSelectedPersonasaCargo3] = useState(false);
  const [selectedPersonasaCargo4, setSelectedPersonasaCargo4] = useState(false);
  const [selectedPersonasaCargo5, setSelectedPersonasaCargo5] = useState(false);
  const [selectedPersonasaCargo5plus, setSelectedPersonasaCargo5plus] = useState(false);
  const [selectedPersonasaCargo6, setSelectedPersonasaCargo6] = useState(false);
  const [selectedPersonasaCargo7, setSelectedPersonasaCargo7] = useState(false);
  const [selectedPersonasaCargo8, setSelectedPersonasaCargo8] = useState(false);
  const [selectedPersonasaCargo9, setSelectedPersonasaCargo9] = useState(false);
  const [selectedPersonasaCargo10, setSelectedPersonasaCargo10] = useState(false);
  const clearFilterSublistPersonasaCargo = () => {
    setSelectedPersonasaCargo0(false);
    setSelectedPersonasaCargo1(false);
    setSelectedPersonasaCargo2(false);
    setSelectedPersonasaCargo3(false);
    setSelectedPersonasaCargo4(false);
    setSelectedPersonasaCargo5(false);
    setSelectedPersonasaCargo5plus(false);
    setSelectedPersonasaCargo6(false);
    setSelectedPersonasaCargo7(false);
    setSelectedPersonasaCargo8(false);
    setSelectedPersonasaCargo9(false);
    setSelectedPersonasaCargo10(false);
  }
  const filterSublistPersonasaCargo = (filtro) => { 
    clearFilterSublistPersonasaCargo();
    if (filtro==='personasacargo_0'){
      (!selectedPersonasaCargo0) ? addFilter(filtro, 'personasacargo') : removeFilter(filtro, 'personasacargo');
      setSelectedPersonasaCargo0(!selectedPersonasaCargo0);
    }
    if (filtro==='personasacargo_1'){
      (!selectedPersonasaCargo1) ? addFilter(filtro, 'personasacargo') : removeFilter(filtro, 'personasacargo');
      setSelectedPersonasaCargo1(!selectedPersonasaCargo1);
    }
    if (filtro==='personasacargo_2'){
      (!selectedPersonasaCargo2) ? addFilter(filtro, 'personasacargo') : removeFilter(filtro, 'personasacargo');
      setSelectedPersonasaCargo2(!selectedPersonasaCargo2);
    }
    if (filtro==='personasacargo_3'){
      (!selectedPersonasaCargo3) ? addFilter(filtro, 'personasacargo') : removeFilter(filtro, 'personasacargo');
      setSelectedPersonasaCargo3(!selectedPersonasaCargo3);
    }
    if (filtro==='personasacargo_4'){
      (!selectedPersonasaCargo4) ? addFilter(filtro, 'personasacargo') : removeFilter(filtro, 'personasacargo');
      setSelectedPersonasaCargo4(!selectedPersonasaCargo4);
    }
    if (filtro==='personasacargo_5'){
      (!selectedPersonasaCargo5) ? addFilter(filtro, 'personasacargo') : removeFilter(filtro, 'personasacargo');
      setSelectedPersonasaCargo5(!selectedPersonasaCargo5);
    }
    if (filtro==='personasacargo_5plus'){
      (!selectedPersonasaCargo5plus) ? addFilter(filtro, 'personasacargo') : removeFilter(filtro, 'personasacargo');
      setSelectedPersonasaCargo5plus(!selectedPersonasaCargo5plus);
    }
    if (filtro==='personasacargo_6'){
      (!selectedPersonasaCargo6) ? addFilter(filtro, 'personasacargo') : removeFilter(filtro, 'personasacargo');
      setSelectedPersonasaCargo6(!selectedPersonasaCargo6);
    }
    if (filtro==='personasacargo_7'){
      (!selectedPersonasaCargo7) ? addFilter(filtro, 'personasacargo') : removeFilter(filtro, 'personasacargo');
      setSelectedPersonasaCargo7(!selectedPersonasaCargo7);
    }
    if (filtro==='personasacargo_8'){
      (!selectedPersonasaCargo8) ? addFilter(filtro, 'personasacargo') : removeFilter(filtro, 'personasacargo');
      setSelectedPersonasaCargo8(!selectedPersonasaCargo8);
    }
    if (filtro==='personasacargo_9'){
      (!selectedPersonasaCargo9) ? addFilter(filtro, 'personasacargo') : removeFilter(filtro, 'personasacargo');
      setSelectedPersonasaCargo9(!selectedPersonasaCargo9);
    }
    if (filtro==='personasacargo_10'){
      (!selectedPersonasaCargo10) ? addFilter(filtro, 'personasacargo') : removeFilter(filtro, 'personasacargo');
      setSelectedPersonasaCargo10(!selectedPersonasaCargo10);
    }
    
  };

  // Filtro por Estado
  const [selectedActualizado, setSelectedActualizado] = useState(false);
  const [selectedPendiente, setSelectedPendiente] = useState(false);
  const [selectedVisitar, setSelectedVisitar] = useState(false);
  const [selectedRevisar, setSelectedRevisar] = useState(false);
  const [selectedPrueba, setSelectedPrueba] = useState(false);
  const [selectedRetirado, setSelectedRetirado] = useState(false);
  const [selectedNoApto, setSelectedNoApto] = useState(false);
  const [selectedSuspendido, setSelectedSuspendido] = useState(false);
  const filterSublistEstado = (filtro) => { 
    if (filtro==='actualizado'){
      (!selectedActualizado) ? addFilter(filtro, 'estado') : removeFilter(filtro, 'estado');
      setSelectedActualizado(!selectedActualizado);
    }
    if (filtro==='pendiente'){
      (!selectedPendiente) ? addFilter(filtro, 'estado') : removeFilter(filtro, 'estado');
      setSelectedPendiente(!selectedPendiente);
    }
    if (filtro==='visitar'){
      (!selectedVisitar) ? addFilter(filtro, 'estado') : removeFilter(filtro, 'estado');
      setSelectedVisitar(!selectedVisitar);
    }
    if (filtro==='revisar'){
      (!selectedRevisar) ? addFilter(filtro, 'estado') : removeFilter(filtro, 'estado');
      setSelectedRevisar(!selectedRevisar);
    }
    if (filtro==='prueba'){
      (!selectedPrueba) ? addFilter(filtro, 'estado') : removeFilter(filtro, 'estado');
      setSelectedPrueba(!selectedPrueba);
    }
    if (filtro==='retirado'){
      (!selectedRetirado) ? addFilter(filtro, 'estado') : removeFilter(filtro, 'estado');
      setSelectedRetirado(!selectedRetirado);
    }
    if (filtro==='no apto'){
      (!selectedNoApto) ? addFilter(filtro, 'estado') : removeFilter(filtro, 'estado');
      setSelectedNoApto(!selectedNoApto);
    }
    if (filtro==='suspendido'){
      (!selectedSuspendido) ? addFilter(filtro, 'estado') : removeFilter(filtro, 'estado');
      setSelectedSuspendido(!selectedSuspendido);
    }
  };
  
  // Filtro por Actividad
  const [selectedCaddie, setSelectedCaddie] = useState(false);
  const [selectedBolero, setSelectedBolero] = useState(false);
  const [selectedBoleador, setSelectedBoleador] = useState(false);
  const [selectedMonitor, setSelectedMonitor] = useState(false);
  const [selectedEntrenador, setSelectedEntrenador] = useState(false);
  const [selectedExterno, setSelectedExterno] = useState(false);
  const [selectedEmpleadoClub, setSelectedEmpleadoClub] = useState(false);
  const [selectedEmpleadoFundacion, setSelectedEmpleadoFundacion] = useState(false);
  const [selectedContratista, setSelectedContratista] = useState(false);
  const [selectedAgencia, setSelectedAgencia] = useState(false);
  const filterSublistActividad = (filtro) => { 
    if (filtro==='caddie'){
      (!selectedCaddie) ? addFilter(filtro, 'actividad') : removeFilter(filtro, 'actividad');
      setSelectedCaddie(!selectedCaddie);
    }
    if (filtro==='bolero'){
      (!selectedBolero) ? addFilter(filtro, 'actividad') : removeFilter(filtro, 'actividad');
      setSelectedBolero(!selectedBolero);
    }
    if (filtro==='boleador'){
      (!selectedBoleador) ? addFilter(filtro, 'actividad') : removeFilter(filtro, 'actividad');
      setSelectedBoleador(!selectedBoleador);
    }
    if (filtro==='monitor'){
      (!selectedMonitor) ? addFilter(filtro, 'actividad') : removeFilter(filtro, 'actividad');
      setSelectedMonitor(!selectedMonitor);
    }
    if (filtro==='entrenador'){
      (!selectedEntrenador) ? addFilter(filtro, 'actividad') : removeFilter(filtro, 'actividad');
      setSelectedEntrenador(!selectedEntrenador);
    }
    if (filtro==='externo'){
      (!selectedExterno) ? addFilter(filtro, 'actividad') : removeFilter(filtro, 'actividad');
      setSelectedExterno(!selectedExterno);
    }
    if (filtro==='empleado club'){
      (!selectedEmpleadoClub) ? addFilter(filtro, 'actividad') : removeFilter(filtro, 'actividad');
      setSelectedEmpleadoClub(!selectedEmpleadoClub);
    }
    if (filtro==='empleado fundación'){
      (!selectedEmpleadoFundacion) ? addFilter(filtro, 'actividad') : removeFilter(filtro, 'actividad');
      setSelectedEmpleadoFundacion(!selectedEmpleadoFundacion);
    }
    if (filtro==='contratista'){
      (!selectedContratista) ? addFilter(filtro, 'actividad') : removeFilter(filtro, 'actividad');
      setSelectedContratista(!selectedContratista);
    }
    if (filtro==='agencia de empleo'){
      (!selectedAgencia) ? addFilter(filtro, 'actividad') : removeFilter(filtro, 'actividad');
      setSelectedAgencia(!selectedAgencia);
    }
  };

  // Filtro por Actividad
  const [selectedPrimera, setSelectedPrimera] = useState(false);
  const [selectedSegunda, setSelectedSegunda] = useState(false);
  const [selectedTercera, setSelectedTercera] = useState(false);
  const [selectedPE1, setSelectedPE1] = useState(false);
  const [selectedPE2, setSelectedPE2] = useState(false);
  const [selectedPE3, setSelectedPE3] = useState(false);
  const [selectedPP3, setSelectedPP3] = useState(false);
  const [selectedServiciosgenerales, setSelectedServiciosgenerales] = useState(false);
  const [selectedCampogolf, setSelectedCampogolf] = useState(false);
  const [selectedTiendagolf, setSelectedTiendagolf] = useState(false);
  const [selectedLavadero, setSelectedLavadero] = useState(false);
  const [selectedCocina, setSelectedCocina] = useState(false);
  const [selectedMasajes, setSelectedMasajes] = useState(false);
  const [selectedCholados, setSelectedCholados] = useState(false);
  const [selectedPeluqueria, setSelectedPeluqueria] = useState(false);
  const filterSublistCategoria = (filtro) => { 
    if (filtro==='primera'){
      (!selectedPrimera) ? addFilter(filtro, 'categoria') : removeFilter(filtro, 'categoria');
      setSelectedPrimera(!selectedPrimera);
    }
    if (filtro==='segunda'){
      (!selectedSegunda) ? addFilter(filtro, 'categoria') : removeFilter(filtro, 'categoria');
      setSelectedSegunda(!selectedSegunda);
    }
    if (filtro==='tercera'){
      (!selectedTercera) ? addFilter(filtro, 'categoria') : removeFilter(filtro, 'categoria');
      setSelectedTercera(!selectedTercera);
    }
    if (filtro==='pe1'){
      (!selectedPE1) ? addFilter(filtro, 'categoria') : removeFilter(filtro, 'categoria');
      setSelectedPE1(!selectedPE1);
    }
    if (filtro==='pe2'){
      (!selectedPE2) ? addFilter(filtro, 'categoria') : removeFilter(filtro, 'categoria');
      setSelectedPE2(!selectedPE2);
    }
    if (filtro==='pe3'){
      (!selectedPE3) ? addFilter(filtro, 'categoria') : removeFilter(filtro, 'categoria');
      setSelectedPE3(!selectedPE3);
    }
    if (filtro==='pp3'){
      (!selectedPP3) ? addFilter(filtro, 'categoria') : removeFilter(filtro, 'categoria');
      setSelectedPP3(!selectedPP3);
    }
    if (filtro==='servicios generales'){
      (!selectedServiciosgenerales) ? addFilter(filtro, 'categoria') : removeFilter(filtro, 'categoria');
      setSelectedServiciosgenerales(!selectedServiciosgenerales);
    }
    if (filtro==='campo golf'){
      (!selectedCampogolf) ? addFilter(filtro, 'categoria') : removeFilter(filtro, 'categoria');
      setSelectedCampogolf(!selectedCampogolf);
    }
    if (filtro==='tienda golf'){
      (!selectedTiendagolf) ? addFilter(filtro, 'categoria') : removeFilter(filtro, 'categoria');
      setSelectedTiendagolf(!selectedTiendagolf);
    }
    if (filtro==='lavadero'){
      (!selectedLavadero) ? addFilter(filtro, 'categoria') : removeFilter(filtro, 'categoria');
      setSelectedLavadero(!selectedLavadero);
    }
    if (filtro==='cocina'){
      (!selectedCocina) ? addFilter(filtro, 'categoria') : removeFilter(filtro, 'categoria');
      setSelectedCocina(!selectedCocina);
    }
    if (filtro==='masajes'){
      (!selectedMasajes) ? addFilter(filtro, 'categoria') : removeFilter(filtro, 'categoria');
      setSelectedMasajes(!selectedMasajes);
    }
    if (filtro==='cholados'){
      (!selectedCholados) ? addFilter(filtro, 'categoria') : removeFilter(filtro, 'categoria');
      setSelectedCholados(!selectedCholados);
    }
    if (filtro==='peluquería'){
      (!selectedPeluqueria) ? addFilter(filtro, 'categoria') : removeFilter(filtro, 'categoria');
      setSelectedPeluqueria(!selectedPeluqueria);
    }
  };

  // Filtro Actualizado
  const [selectedHoy, setSelectedHoy] = useState(false);
  const [selectedAyer, setSelectedAyer] = useState(false);
  const [selectedLast7days, setSelectedLast7days] = useState(false);
  const [selectedLast30days, setSelectedLast30days] = useState(false);
  const [selectedRangoFechas, setSelectedRangoFechas] = useState(false);
  const clearFilterSublistActualizado = () => {
    setSelectedHoy(false);
    setSelectedAyer(false);
    setSelectedLast7days(false);
    setSelectedLast30days(false);
    setSelectedRangoFechas(false);
  }
  const filterSublistActualizado = (filtro) => { 
    clearFilterSublistActualizado();
    if (filtro==='hoy'){
      (!selectedHoy) ? addFilter(filtro, 'actualizacion') : removeFilter(filtro, 'actualizacion');
      setSelectedHoy(!selectedHoy);
    }
    if (filtro==='ayer'){
      (!selectedAyer) ? addFilter(filtro, 'actualizacion') : removeFilter(filtro, 'actualizacion');
      setSelectedAyer(!selectedAyer);
    }
    if (filtro==='last7days'){
      (!selectedLast7days) ? addFilter(filtro, 'actualizacion') : removeFilter(filtro, 'actualizacion');
      setSelectedLast7days(!selectedLast7days);
    }
    if (filtro==='last30days'){
      (!selectedLast30days) ? addFilter(filtro, 'actualizacion') : removeFilter(filtro, 'actualizacion');
      setSelectedLast30days(!selectedLast30days);
    }
    if (filtro==='rangofechas'){
      // (!selectedRangoFechas) ? addFilter(filtro, 'actualizacion') : removeFilter(filtro, 'actualizacion');
      setSelectedRangoFechas(!selectedRangoFechas);
    }
    
  };

  // Filtro Edad
  const [selectedEdadmenor, setSelectedEdadmenor] = useState(false);
  const [selectedEdad18, setSelectedEdad18] = useState(false);
  const [selectedEdad21, setSelectedEdad21] = useState(false);
  const [selectedEdad26, setSelectedEdad26] = useState(false);
  const [selectedEdad31, setSelectedEdad31] = useState(false);
  const [selectedEdad36, setSelectedEdad36] = useState(false);
  const [selectedEdad41, setSelectedEdad41] = useState(false);
  const [selectedEdad45, setSelectedEdad45] = useState(false);
  const clearFilterSublistEdad = () => {
    setSelectedEdadmenor(false);
    setSelectedEdad18(false);
    setSelectedEdad21(false);
    setSelectedEdad26(false);
    setSelectedEdad31(false);
    setSelectedEdad36(false);
    setSelectedEdad41(false);
    setSelectedEdad45(false);
  }
  const filterSublistEdad = (filtro) => { 
    clearFilterSublistEdad();
    if (filtro==='edadmenor'){
      (!selectedEdadmenor) ? addFilter(filtro, 'edad') : removeFilter(filtro, 'edad');
      setSelectedEdadmenor(!selectedEdadmenor);
    }
    if (filtro==='edad18'){
      (!selectedEdad18) ? addFilter(filtro, 'edad') : removeFilter(filtro, 'edad');
      setSelectedEdad18(!selectedEdad18);
    }
    if (filtro==='edad21'){
      (!selectedEdad21) ? addFilter(filtro, 'edad') : removeFilter(filtro, 'edad');
      setSelectedEdad21(!selectedEdad21);
    }
    if (filtro==='edad26'){
      (!selectedEdad26) ? addFilter(filtro, 'edad') : removeFilter(filtro, 'edad');
      setSelectedEdad26(!selectedEdad26);
    }
    if (filtro==='edad31'){
      (!selectedEdad31) ? addFilter(filtro, 'edad') : removeFilter(filtro, 'edad');
      setSelectedEdad31(!selectedEdad31);
    }
    if (filtro==='edad36'){
      (!selectedEdad36) ? addFilter(filtro, 'edad') : removeFilter(filtro, 'edad');
      setSelectedEdad36(!selectedEdad36);
    }
    if (filtro==='edad41'){
      (!selectedEdad41) ? addFilter(filtro, 'edad') : removeFilter(filtro, 'edad');
      setSelectedEdad41(!selectedEdad41);
    }
    if (filtro==='edad45'){
      (!selectedEdad45) ? addFilter(filtro, 'edad') : removeFilter(filtro, 'edad');
      setSelectedEdad45(!selectedEdad45);
    }
    
  };

  // Filtro EdadClub
  const [selectedEdadClub1, setSelectedEdadClub1] = useState(false);
  const [selectedEdadClub2, setSelectedEdadClub2] = useState(false);
  const [selectedEdadClub3, setSelectedEdadClub3] = useState(false);
  const [selectedEdadClub4, setSelectedEdadClub4] = useState(false);
  const [selectedEdadClub5, setSelectedEdadClub5] = useState(false);
  const [selectedEdadClub6, setSelectedEdadClub6] = useState(false);
  const [selectedEdadClub7, setSelectedEdadClub7] = useState(false);
  const [selectedEdadClub8, setSelectedEdadClub8] = useState(false);
  const [selectedEdadClub9, setSelectedEdadClub9] = useState(false);
  const [selectedEdadClub10, setSelectedEdadClub10] = useState(false);
  const [selectedEdadClub11, setSelectedEdadClub11] = useState(false);
  const [selectedEdadClub12, setSelectedEdadClub12] = useState(false);
  const [selectedEdadClub13, setSelectedEdadClub13] = useState(false);
  const clearFilterSublistEdadClub = () => {
    setSelectedEdadClub1(false);
    setSelectedEdadClub2(false);
    setSelectedEdadClub3(false);
    setSelectedEdadClub4(false);
    setSelectedEdadClub5(false);
    setSelectedEdadClub6(false);
    setSelectedEdadClub7(false);
    setSelectedEdadClub8(false);
    setSelectedEdadClub9(false);
    setSelectedEdadClub10(false);
    setSelectedEdadClub11(false);
    setSelectedEdadClub12(false);
    setSelectedEdadClub13(false);
  }
  const filterSublistEdadClub = (filtro) => { 
    clearFilterSublistEdadClub();
    if (filtro==='edadclub1'){
      (!selectedEdadClub1) ? addFilter(filtro, 'edadclub') : removeFilter(filtro, 'edadclub');
      setSelectedEdadClub1(!selectedEdadClub1);
    }
    if (filtro==='edadclub2'){
      (!selectedEdadClub2) ? addFilter(filtro, 'edadclub') : removeFilter(filtro, 'edadclub');
      setSelectedEdadClub2(!selectedEdadClub2);
    }
    if (filtro==='edadclub3'){
      (!selectedEdadClub3) ? addFilter(filtro, 'edadclub') : removeFilter(filtro, 'edadclub');
      setSelectedEdadClub3(!selectedEdadClub3);
    }
    if (filtro==='edadclub4'){
      (!selectedEdadClub4) ? addFilter(filtro, 'edadclub') : removeFilter(filtro, 'edadclub');
      setSelectedEdadClub4(!selectedEdadClub4);
    }
    if (filtro==='edadclub5'){
      (!selectedEdadClub5) ? addFilter(filtro, 'edadclub') : removeFilter(filtro, 'edadclub');
      setSelectedEdadClub5(!selectedEdadClub5);
    }
    if (filtro==='edadclub6'){
      (!selectedEdadClub6) ? addFilter(filtro, 'edadclub') : removeFilter(filtro, 'edadclub');
      setSelectedEdadClub6(!selectedEdadClub6);
    }
    if (filtro==='edadclub7'){
      (!selectedEdadClub7) ? addFilter(filtro, 'edadclub') : removeFilter(filtro, 'edadclub');
      setSelectedEdadClub7(!selectedEdadClub7);
    }
    if (filtro==='edadclub8'){
      (!selectedEdadClub8) ? addFilter(filtro, 'edadclub') : removeFilter(filtro, 'edadclub');
      setSelectedEdadClub8(!selectedEdadClub8);
    }
    if (filtro==='edadclub9'){
      (!selectedEdadClub9) ? addFilter(filtro, 'edadclub') : removeFilter(filtro, 'edadclub');
      setSelectedEdadClub9(!selectedEdadClub9);
    }
    if (filtro==='edadclub10'){
      (!selectedEdadClub10) ? addFilter(filtro, 'edadclub') : removeFilter(filtro, 'edadclub');
      setSelectedEdadClub10(!selectedEdadClub10);
    }
    if (filtro==='edadclub11'){
      (!selectedEdadClub11) ? addFilter(filtro, 'edadclub') : removeFilter(filtro, 'edadclub');
      setSelectedEdadClub11(!selectedEdadClub11);
    }
    if (filtro==='edadclub12'){
      (!selectedEdadClub12) ? addFilter(filtro, 'edadclub') : removeFilter(filtro, 'edadclub');
      setSelectedEdadClub12(!selectedEdadClub12);
    }
    if (filtro==='edadclub13'){
      (!selectedEdadClub13) ? addFilter(filtro, 'edadclub') : removeFilter(filtro, 'edadclub');
      setSelectedEdadClub13(!selectedEdadClub13);
    }
  };

  // Lista de Beneficiarios Activos / No Activos
  // const ListItemActivo = ({statsBeneficiario}) => {
  //   if (statsBeneficiario)
  //     return (
  //       <>
  //         <ListItem leftNode={<UsersIcon />} rightNode={arrowLeftIconTotal}
  //           onClick={toggleSublistTotal}
  //         >
  //           <ListItemTitle style={{fontWeight:500, color:'#000'}}>Total</ListItemTitle>
  //           <ListItemSubtitle>{statsBeneficiario.total} registros</ListItemSubtitle>
  //         </ListItem>
  //         <List minimal nested open={openTotal}>
  //           <ListItem active={selectedActivos} onClick={() => { filterSublistTotal('activos') }}
  //             leftNode={<BenefActivosIcon />} rightNode={statsBeneficiario.activos}>
  //             <ListItemTitle style={{fontSize: '0.875rem'}}>Activos</ListItemTitle>
  //           </ListItem>
  //           <ListItem active={selectedNoActivos} onClick={() => { filterSublistTotal('noactivos') }}
  //             leftNode={<BenefNoActivosIcon />} rightNode={statsBeneficiario.noactivos}>
  //             <ListItemTitle style={{fontSize: '0.875rem'}}>No Activos</ListItemTitle>
  //           </ListItem>
  //         </List>
  //       </>
  //     );
  //   return ( <></>);
  // }

  const clearSublistCategoriaSeguimiento = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    const filtros = [ 'empleado', 'independiente', 'disponible', 'en proceso', 'dependiente', 'en acuerdo' ];
    setSelectedEmpleado(false);
    setSelectedIndependiente(false);
    setSelectedDisponible(false);
    setSelectedEnproceso(false);
    setSelectedDependiente(false);
    setSelectedenAcuerdo(false);
    clearFilter(filtros);
  }

  const clearSublistJefeHogar = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    const filtros = ['jefehogar', 'nojefehogar' ];
    setSelectedJefeHogar(false);
    setSelectedNoJefeHogar(false);
    clearFilter(filtros);
  }
  const clearSublistAcuerdoCorresp = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    const filtros = ['acuerdocorresp', 'noacuerdocorresp' ];
    setSelectedAcuerdoCorresp(false);
    setSelectedNoAcuerdoCorresp(false);
    clearFilter(filtros);
  }

  const clearSublistPersonasaCargo = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    const filtros = [ 'personasacargo_0', 'personasacargo_1', 'personasacargo_2', 'personasacargo_3', 'personasacargo_4', 'personasacargo_5', 'personasacargo_5plus',
      'personasacargo_6', 'personasacargo_7', 'personasacargo_8', 'personasacargo_9', 'personasacargo_10' ]; 
    setSelectedPersonasaCargo1(false);
    setSelectedPersonasaCargo0(false);
    setSelectedPersonasaCargo2(false);
    setSelectedPersonasaCargo3(false);
    setSelectedPersonasaCargo4(false);
    setSelectedPersonasaCargo5(false);
    setSelectedPersonasaCargo5plus(false);
    setSelectedPersonasaCargo6(false);
    setSelectedPersonasaCargo7(false);
    setSelectedPersonasaCargo8(false);
    setSelectedPersonasaCargo9(false);
    setSelectedPersonasaCargo10(false);
    clearFilter(filtros);
  }
  const clearSublistEstado = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    const filtros = ['actualizado', 'pendiente', 'visitar', 'revisar', 
      'prueba', 'retirado', 'no apto', 'suspendido' ];
    setSelectedActualizado(false);
    setSelectedPendiente(false);
    setSelectedVisitar(false);
    setSelectedRevisar(false);
    setSelectedPrueba(false);
    setSelectedRetirado(false);
    setSelectedNoApto(false);
    setSelectedSuspendido(false);
    clearFilter(filtros);
  }
  const clearSublistActividad = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    const filtros = ['caddie', 'bolero', 'boleador', 'monitor', 'entrenador', 
      'externo', 'empleado club', 'empleado fundación', 'contratista', 'agencia de empleo' ];
    setSelectedCaddie(false);
    setSelectedBolero(false);
    setSelectedBoleador(false);
    setSelectedMonitor(false);
    setSelectedEntrenador(false);
    setSelectedExterno(false);
    setSelectedEmpleadoClub(false);
    setSelectedEmpleadoFundacion(false);
    setSelectedContratista(false);
    setSelectedAgencia(false);
    clearFilter(filtros);
  }
  const clearSublistCategoria = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    const filtros = ['primera','segunda','tercera','pe1','pe2','pe3','pp3','servicios generales',
      'campo golf','tienda golf','lavadero','cocina','masajes','cholados','peluquería',
    ];
    setSelectedPrimera(false);
    setSelectedSegunda(false);
    setSelectedTercera(false);
    setSelectedPE1(false);
    setSelectedPE2(false);
    setSelectedPE3(false);
    setSelectedPP3(false);
    setSelectedServiciosgenerales(false);
    setSelectedCampogolf(false);
    setSelectedTiendagolf(false);
    setSelectedLavadero(false);
    setSelectedCocina(false);
    setSelectedMasajes(false);
    setSelectedCholados(false);
    setSelectedPeluqueria(false);
    clearFilter(filtros);
  }
  const clearSublistActualizado = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    const filtros = [ 'hoy', 'ayer', 'last7days', 'last30days' ]; //'rangofechas'
    setSelectedHoy(false);
    setSelectedAyer(false);
    setSelectedLast7days(false);
    setSelectedLast30days(false);
    setSelectedRangoFechas(false);
    clearFilter(filtros);
    // clearFilterSublistActualizado();
  }
  const clearSublistEdad = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    const filtros = [ 'edadmenor', 'edad18', 'edad21', 'edad26', 'edad31', 'edad36', 'edad41', 'edad45' ]; 
    setSelectedEdadmenor(false);
    setSelectedEdad18(false);
    setSelectedEdad21(false);
    setSelectedEdad26(false);
    setSelectedEdad31(false);
    setSelectedEdad36(false);
    setSelectedEdad41(false);
    setSelectedEdad45(false);
    clearFilter(filtros);
  }
  const clearSublistEdadClub = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    const filtros = [ 'edadclub1', 'edadclub2', 'edadclub3', 'edadclub4', 'edadclub5', 'edadclub6', 
    'edadclub7', 'edadclub8', 'edadclub9', 'edadclub10', 'edadclub11', 'edadclub12', 'edadclub13' ]; 
    setSelectedEdadClub1(false);
    setSelectedEdadClub2(false);
    setSelectedEdadClub3(false);
    setSelectedEdadClub4(false);
    setSelectedEdadClub5(false);
    setSelectedEdadClub6(false);
    setSelectedEdadClub7(false);
    setSelectedEdadClub8(false);
    setSelectedEdadClub9(false);
    setSelectedEdadClub10(false);
    setSelectedEdadClub11(false);
    setSelectedEdadClub12(false);
    setSelectedEdadClub13(false);
    clearFilter(filtros);
  }

  // Lista de Beneficiarios por Categoría de Seguimiento
  const ListItemCategoriaSeguimiento = ({statsBeneficiario}) => {
    if (statsBeneficiario)
      return (
        <>
          <ListItem 
            leftNode={arrowLeftIconCategoriaSeguimiento}
            rightNode={hasCategoriaSeguimientoFiltro && <div className={'bienestar-limpiar'} onClick={clearSublistCategoriaSeguimiento} >{FiltroIcon}</div>}
            onClick={toggleSublistCategoriaSeguimiento}>
            <ListItemTitle style={{fontWeight:500, color:'#000'}}>Cat. Seguimiento</ListItemTitle>
            <ListItemSubtitle>{statsBeneficiario.total} registros</ListItemSubtitle>
          </ListItem>
          <List minimal multiSelect nested open={openCategoriaSeguimiento}>
            <ListItem 
              active={selectedEmpleado} onClick={() => { filterSublistCategoriaSeguimiento('empleado') }}
              // leftNode={<span className="cardBenefItem-estado-circle cardBenefItem-categoriaseguimiento-empleado" />} 
              rightNode={statsBeneficiario.seg_empleado}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>Empleado</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedIndependiente} onClick={() => { filterSublistCategoriaSeguimiento('independiente') }}
              // leftNode={<span className="cardBenefItem-estado-circle cardBenefItem-estcategoriaseguimientodo-independiente" />} 
              rightNode={statsBeneficiario.seg_independiente}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>Independiente</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedDisponible} onClick={() => { filterSublistCategoriaSeguimiento('disponible') }}
              // leftNode={<span className="cardBenefItem-estado-circle cardBenefItem-categoriaseguimiento-disponible" />} 
              rightNode={statsBeneficiario.seg_disponible}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>Disponible</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedEnproceso} onClick={() => { filterSublistCategoriaSeguimiento('en proceso') }}
              // leftNode={<span className="cardBenefItem-estado-circle cardBenefItem-categoriaseguimiento-enproceso" />} 
              rightNode={statsBeneficiario.seg_enproceso}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>En Proceso</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedDependiente} onClick={() => { filterSublistCategoriaSeguimiento('dependiente') }}
              // leftNode={<span className="cardBenefItem-estado-circle cardBenefItem-categoriaseguimiento-dependiente" />} 
              rightNode={statsBeneficiario.seg_dependiente}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>Dependiente</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedenAcuerdo} onClick={() => { filterSublistCategoriaSeguimiento('en acuerdo') }}
              // leftNode={<span className="cardBenefItem-estado-circle cardBenefItem-categoriaseguimiento-enacuerdo" />} 
              rightNode={statsBeneficiario.seg_enacuerdo}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>En Acuerdo</ListItemTitle>
            </ListItem>
          </List>
        </>
      );
    
    return ( <></>);      
}

  // Lista de Jefe Hogar
  const ListItemJefeHogar = ({statsBeneficiario}) => {
    if (statsBeneficiario)
      return (
        <>
          <ListItem 
            leftNode={arrowLeftIconJefeHogar}
            rightNode={hasJefeHogarFiltro && <div className={'bienestar-limpiar'} onClick={clearSublistJefeHogar} >{FiltroIcon}</div>}
            onClick={toggleSublistJefeHogar}>
            <ListItemTitle style={{fontWeight:500, color:'#000'}}>Jefe Hogar</ListItemTitle>
          </ListItem>
          <List minimal nested open={openJefeHogar}>
            <ListItem active={selectedJefeHogar} onClick={() => { filterSublistJefeHogar('jefehogar') }}
              rightNode={statsBeneficiario.jefehogar}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>Si</ListItemTitle>
            </ListItem>
            <ListItem active={selectedNoJefeHogar} onClick={() => { filterSublistJefeHogar('nojefehogar') }}
              rightNode={statsBeneficiario.nojefehogar}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>No</ListItemTitle>
            </ListItem>
          </List>
        </>
      );
    return ( <></>);
  }

  // Acuerdo de Corresponsabilidad
  const ListItemAcuerdoCorresp = ({statsBeneficiario}) => {
    if (statsBeneficiario)
      return (
        <>
          <ListItem 
            leftNode={arrowLeftIconAcuerdoCorresp}
            rightNode={hasAcuerdoCorrespFiltro && <div className={'bienestar-limpiar'} onClick={clearSublistAcuerdoCorresp} >{FiltroIcon}</div>}
            onClick={toggleSublistAcuerdoCorresp}>
            <ListItemTitle style={{fontWeight:500, color:'#000'}}>Firmó Acuerdo</ListItemTitle>
          </ListItem>
          <List minimal nested open={openAcuerdoCorresp}>
            <ListItem active={selectedAcuerdoCorresp} onClick={() => { filterSublistAcuerdoCorresp('acuerdocorresp') }}
              rightNode={statsBeneficiario.acuerdocorresp}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>Si</ListItemTitle>
            </ListItem>
            <ListItem active={selectedNoAcuerdoCorresp} onClick={() => { filterSublistAcuerdoCorresp('noacuerdocorresp') }}
              rightNode={statsBeneficiario.noacuerdocorresp}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>No</ListItemTitle>
            </ListItem>
          </List>
        </>
      );
    return ( <></>);
  }

  // Lista de Beneficiarios Personas a Cargo
  const ListItemPersonasaCargo = ({statsBeneficiario}) => {
    if (statsBeneficiario)
      return (
        <>
          <ListItem 
              leftNode={arrowLeftIconPersonasaCargo}
              rightNode={hasPersonasaCargoFiltro && <div className={'filtro-bienestar-limpiar'} onClick={clearSublistPersonasaCargo} >{FiltroIcon}</div>}
              onClick={toggleSublistPersonasaCargo}>
              <ListItemTitle style={{fontWeight:500, color:'#000'}}>Personas a Cargo</ListItemTitle>
          </ListItem>
          <List minimal multiSelect nested open={openPersonasaCargo}>
          <ListItem 
              active={selectedPersonasaCargo0} onClick={() => { filterSublistPersonasaCargo('personasacargo_0') }}
              rightNode={statsBeneficiario.personasacargo_0}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>0 personas</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedPersonasaCargo1} onClick={() => { filterSublistPersonasaCargo('personasacargo_1') }}
              rightNode={statsBeneficiario.personasacargo_1}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>1 persona</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedPersonasaCargo2} onClick={() => { filterSublistPersonasaCargo('personasacargo_2') }}
              rightNode={statsBeneficiario.personasacargo_2}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>2 personas</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedPersonasaCargo3} onClick={() => { filterSublistPersonasaCargo('personasacargo_3') }}
              rightNode={statsBeneficiario.personasacargo_3}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>3 personas</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedPersonasaCargo4} onClick={() => { filterSublistPersonasaCargo('personasacargo_4') }}
              rightNode={statsBeneficiario.personasacargo_4}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>4 personas</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedPersonasaCargo5} onClick={() => { filterSublistPersonasaCargo('personasacargo_5') }}
              rightNode={statsBeneficiario.personasacargo_5}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>5 personas</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedPersonasaCargo5plus} onClick={() => { filterSublistPersonasaCargo('personasacargo_5plus') }}
              rightNode={statsBeneficiario.personasacargo_5plus}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>Más de 5 pers.</ListItemTitle>
            </ListItem>
            {/* <ListItem 
              active={selectedPersonasaCargo6} onClick={() => { filterSublistPersonasaCargo('personasacargo_6') }}
              rightNode={statsBeneficiario.personasacargo_6}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>6 personas</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedPersonasaCargo7} onClick={() => { filterSublistPersonasaCargo('personasacargo_7') }}
              rightNode={statsBeneficiario.personasacargo_7}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>7 personas</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedPersonasaCargo8} onClick={() => { filterSublistPersonasaCargo('personasacargo_8') }}
              rightNode={statsBeneficiario.personasacargo_8}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>8 personas</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedPersonasaCargo9} onClick={() => { filterSublistPersonasaCargo('personasacargo_9') }}
              rightNode={statsBeneficiario.personasacargo_9}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>9 personas</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedPersonasaCargo10} onClick={() => { filterSublistPersonasaCargo('personasacargo_10') }}
              rightNode={statsBeneficiario.personasacargo_10}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>Más de 10 pers.</ListItemTitle>
            </ListItem> */}
            
          </List>

        </>
      );
    
    return ( <></>);
       
  }

  // Lista de Beneficiarios por Estado
  const ListItemEstado = ({statsBeneficiario}) => {
      if (statsBeneficiario)
        return (
          <>
            <ListItem 
              leftNode={arrowLeftIconEstado}
              rightNode={hasEstadoFiltro && <div className={'bienestar-limpiar'} onClick={clearSublistEstado} >{FiltroIcon}</div>}
              onClick={toggleSublistEstado}>
              <ListItemTitle style={{fontWeight:500, color:'#000'}}>Estado</ListItemTitle>
            </ListItem>
            <List minimal multiSelect nested open={openEstado}>
              <ListItem 
                active={selectedActualizado} onClick={() => { filterSublistEstado('actualizado') }}
                leftNode={<span className="cardBenefItem-estado-circle cardBenefItem-estado-actualizado" />} 
                rightNode={statsBeneficiario.actualizado}>
                <ListItemTitle style={{fontSize: '0.875rem'}}>Actualizado</ListItemTitle>
              </ListItem>
              <ListItem 
                active={selectedPendiente} onClick={() => { filterSublistEstado('pendiente') }}
                leftNode={<span className="cardBenefItem-estado-circle cardBenefItem-estado-pendiente" />} 
                rightNode={statsBeneficiario.pendiente}>
                <ListItemTitle style={{fontSize: '0.875rem'}}>Pendiente</ListItemTitle>
              </ListItem>
              <ListItem 
                active={selectedVisitar} onClick={() => { filterSublistEstado('visitar') }}
                leftNode={<span className="cardBenefItem-estado-circle cardBenefItem-estado-visitar" />} 
                rightNode={statsBeneficiario.visitar}>
                <ListItemTitle style={{fontSize: '0.875rem'}}>Visitar</ListItemTitle>
              </ListItem>
              <ListItem 
                active={selectedRevisar} onClick={() => { filterSublistEstado('revisar') }}
                leftNode={<span className="cardBenefItem-estado-circle cardBenefItem-estado-revisar" />} 
                rightNode={statsBeneficiario.revisar}>
                <ListItemTitle style={{fontSize: '0.875rem'}}>Revisar</ListItemTitle>
              </ListItem>
              <ListItem 
                active={selectedPrueba} onClick={() => { filterSublistEstado('prueba') }}
                leftNode={<span className="cardBenefItem-estado-circle cardBenefItem-estado-prueba" />} 
                rightNode={statsBeneficiario.prueba}>
                <ListItemTitle style={{fontSize: '0.875rem'}}>Prueba</ListItemTitle>
              </ListItem>
              <ListItem 
                active={selectedRetirado} onClick={() => { filterSublistEstado('retirado') }}
                leftNode={<span className="cardBenefItem-estado-circle cardBenefItem-estado-retirado" />} 
                rightNode={statsBeneficiario.retirado}>
                <ListItemTitle style={{fontSize: '0.875rem'}}>Retirado</ListItemTitle>
              </ListItem>
              <ListItem 
                active={selectedNoApto} onClick={() => { filterSublistEstado('no apto') }}
                leftNode={<span className="cardBenefItem-estado-circle cardBenefItem-estado-noapto" />} 
                rightNode={statsBeneficiario.noapto}>
                <ListItemTitle style={{fontSize: '0.875rem'}}>No apto</ListItemTitle>
              </ListItem>
              <ListItem 
                active={selectedSuspendido} onClick={() => { filterSublistEstado('suspendido') }}
                leftNode={<span className="cardBenefItem-estado-circle cardBenefItem-estado-suspendido" />} 
                rightNode={statsBeneficiario.suspendido}>
                <ListItemTitle style={{fontSize: '0.875rem'}}>Suspendido</ListItemTitle>
              </ListItem>
            </List>
          </>
        );
      
      return ( <></>);      
  }

  // Lista de Beneficiarios por Actividad
  const ListItemActividad = ({statsBeneficiario}) => {
    if (statsBeneficiario)
      return (
        <>
          <ListItem 
              leftNode={arrowLeftIconActividad}
              rightNode={hasActividadFiltro && <div className={'bienestar-limpiar'} onClick={clearSublistActividad} >{FiltroIcon}</div>}
              onClick={toggleSublistActividad}>
              <ListItemTitle  style={{fontWeight:500, color:'#000'}}>Actividad</ListItemTitle>
          </ListItem>
          <List minimal multiSelect nested open={openActividad}>
            <ListItem 
              active={selectedCaddie} onClick={() => { filterSublistActividad('caddie') }}
              leftNode={<CaddieIcon />} rightNode={statsBeneficiario.caddie}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>Caddie</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedBolero} onClick={() => { filterSublistActividad('bolero') }}
              leftNode={<BoleroIcon />} rightNode={statsBeneficiario.bolero}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>Bolero</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedBoleador} onClick={() => { filterSublistActividad('boleador') }}
              leftNode={<BoleadorIcon />} rightNode={statsBeneficiario.boleador}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>Boleador</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedMonitor} onClick={() => { filterSublistActividad('monitor') }}
              leftNode={<MonitorIcon />} rightNode={statsBeneficiario.monitor}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>Monitor</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedEntrenador} onClick={() => { filterSublistActividad('entrenador') }}
              leftNode={UsuarioIcon} rightNode={statsBeneficiario.entrenador}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>Entrenador</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedExterno} onClick={() => { filterSublistActividad('externo') }}
              leftNode={UsuarioIcon} rightNode={statsBeneficiario.externo}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>Externo</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedEmpleadoClub} onClick={() => { filterSublistActividad('empleado club') }}
              leftNode={UsuarioIcon} rightNode={statsBeneficiario.empleadoclub}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>Emp. Club</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedEmpleadoFundacion} onClick={() => { filterSublistActividad('empleado fundación') }}
              leftNode={UsuarioIcon} rightNode={statsBeneficiario.empleadofundacion}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>Emp. Fundación</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedContratista} onClick={() => { filterSublistActividad('contratista') }}
              leftNode={UsuarioIcon} rightNode={statsBeneficiario.contratista}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>Contratista</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedAgencia} onClick={() => { filterSublistActividad('agencia de empleo') }}
              leftNode={UsuarioIcon} rightNode={statsBeneficiario.agenciadeempleo}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>Agencia</ListItemTitle>
            </ListItem>
          </List>

        </>
      );
    
    return ( <></>);
       
  }
  
  // Lista de Beneficiarios Categorias
  const ListItemCategoria = ({statsBeneficiario}) => {
    if (statsBeneficiario)
      return (
        <>
          <ListItem 
              leftNode={arrowLeftIconCategoria}
              rightNode={hasCategoriaFiltro && <div className={'filtro-bienestar-limpiar'} onClick={clearSublistCategoria} >{FiltroIcon}</div>}
              onClick={toggleSublistCategoria}>
              <ListItemTitle style={{fontWeight:500, color:'#000'}}>Categoría</ListItemTitle>
          </ListItem>
          <List minimal multiSelect nested open={openCategoria}>
            <ListItem 
              active={selectedPrimera} onClick={() => { filterSublistCategoria('primera') }}
              rightNode={statsBeneficiario.primera}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>Primera</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedSegunda} onClick={() => { filterSublistCategoria('segunda') }}
              rightNode={statsBeneficiario.segunda}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>Segunda</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedTercera} onClick={() => { filterSublistCategoria('tercera') }}
              rightNode={statsBeneficiario.tercera}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>Tercera</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedPE1} onClick={() => { filterSublistCategoria('pe1') }}
              rightNode={statsBeneficiario.pe1}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>PE1</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedPE2} onClick={() => { filterSublistCategoria('pe2') }}
              rightNode={statsBeneficiario.pe2}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>PE2</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedPE3} onClick={() => { filterSublistCategoria('pe3') }}
              rightNode={statsBeneficiario.pe3}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>PE3</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedPP3} onClick={() => { filterSublistCategoria('pp3') }}
              rightNode={statsBeneficiario.pp3}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>PP3</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedServiciosgenerales} onClick={() => { filterSublistCategoria('servicios generales') }}
              rightNode={statsBeneficiario.serviciosgenerales}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>Serv. Generales</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedCampogolf} onClick={() => { filterSublistCategoria('campo golf') }}
              rightNode={statsBeneficiario.campogolf}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>Campo golf</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedTiendagolf} onClick={() => { filterSublistCategoria('tienda golf') }}
              rightNode={statsBeneficiario.tiendagolf}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>Tienda golf</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedLavadero} onClick={() => { filterSublistCategoria('lavadero') }}
              rightNode={statsBeneficiario.lavadero}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>Lavadero</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedCocina} onClick={() => { filterSublistCategoria('cocina') }}
              rightNode={statsBeneficiario.cocina}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>Cocina</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedMasajes} onClick={() => { filterSublistCategoria('masajes') }}
              rightNode={statsBeneficiario.masajes}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>Masajes</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedCholados} onClick={() => { filterSublistCategoria('cholados') }}
              rightNode={statsBeneficiario.cholados}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>Cholados</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedPeluqueria} onClick={() => { filterSublistCategoria('peluquería') }}
              rightNode={statsBeneficiario.peluqueria}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>Peluquería</ListItemTitle>
            </ListItem>
          </List>

        </>
      );
    
    return ( <></>);
       
  }

  // Lista de Beneficiarios Fecha Modificación
  const ListItemFechaActualizado = ({statsBeneficiario}) => {
    if (statsBeneficiario)
      return (
        <>
          <ListItem 
              leftNode={arrowLeftIconActualizado}
              rightNode={hasActualizadoFiltro && <div className={'filtro-bienestar-limpiar'} onClick={clearSublistActualizado} >{FiltroIcon}</div>}
              onClick={toggleSublistActualizado}>
              <ListItemTitle style={{fontWeight:500, color:'#000'}}>Actualizado</ListItemTitle>
          </ListItem>
          <List minimal nested open={openActualizado}>
            <ListItem 
              active={selectedHoy} onClick={() => { filterSublistActualizado('hoy') }}
              rightNode={statsBeneficiario.hoy}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>Hoy</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedAyer} onClick={() => { filterSublistActualizado('ayer') }}
              rightNode={statsBeneficiario.ayer}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>Ayer</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedLast7days} onClick={() => { filterSublistActualizado('last7days') }}
              rightNode={statsBeneficiario.last7days}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>Últimos 7 días</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedLast30days} onClick={() => { filterSublistActualizado('last30days') }}
              rightNode={statsBeneficiario.last30days}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>Últimos 30 días</ListItemTitle>
            </ListItem>
            {/* <ListItem 
              active={selectedRangoFechas}
              // rightNode={statsBeneficiario.RangoFechas}
            >
              <ListItemTitle style={{fontSize: '0.875rem'}}>Rango fechas...</ListItemTitle>
            </ListItem> */}
            
          </List>

        </>
      );
    
    return ( <></>);
       
  }

  // Lista de Beneficiarios Edad
  const ListItemEdad = ({statsBeneficiario}) => {
    if (statsBeneficiario)
      return (
        <>
          <ListItem 
              leftNode={arrowLeftIconEdad}
              rightNode={hasEdadFiltro && <div className={'filtro-bienestar-limpiar'} onClick={clearSublistEdad} >{FiltroIcon}</div>}
              onClick={toggleSublistEdad}>
              <ListItemTitle style={{fontWeight:500, color:'#000'}}>Edad</ListItemTitle>
          </ListItem>
          <List minimal multiSelect nested open={openEdad}>
            <ListItem 
              active={selectedEdadmenor} onClick={() => { filterSublistEdad('edadmenor') }}
              rightNode={statsBeneficiario.edadmenor}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>Menor de 18 años</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedEdad18} onClick={() => { filterSublistEdad('edad18') }}
              rightNode={statsBeneficiario.edad18}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>18 - 20 años</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedEdad21} onClick={() => { filterSublistEdad('edad21') }}
              rightNode={statsBeneficiario.edad21}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>21 - 25 años</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedEdad26} onClick={() => { filterSublistEdad('edad26') }}
              rightNode={statsBeneficiario.edad26}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>26 - 30 años</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedEdad31} onClick={() => { filterSublistEdad('edad31') }}
              rightNode={statsBeneficiario.edad31}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>31 - 35 años</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedEdad36} onClick={() => { filterSublistEdad('edad36') }}
              rightNode={statsBeneficiario.edad36}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>36 - 40 años</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedEdad41} onClick={() => { filterSublistEdad('edad41') }}
              rightNode={statsBeneficiario.edad41}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>41 y 45 años</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedEdad45} onClick={() => { filterSublistEdad('edad45') }}
              rightNode={statsBeneficiario.edad45}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>Más de 45 años</ListItemTitle>
            </ListItem>
            
          </List>

        </>
      );
    
    return ( <></>);
       
  }

  // Lista de Beneficiarios Antiguedad - EdadClub con la FechaIngreso
  const ListItemEdadClub = ({statsBeneficiario}) => {
    if (statsBeneficiario)
      return (
        <>
          <ListItem 
              leftNode={arrowLeftIconEdadClub}
              rightNode={hasEdadClubFiltro && <div className={'filtro-bienestar-limpiar'} onClick={clearSublistEdadClub} >{FiltroIcon}</div>}
              onClick={toggleSublistEdadClub}>
              <ListItemTitle style={{fontWeight:500, color:'#000'}}>Años Club</ListItemTitle>
          </ListItem>
          <List minimal multiSelect nested open={openEdadClub}>
            <ListItem 
              active={selectedEdadClub1} onClick={() => { filterSublistEdadClub('edadclub1') }}
              rightNode={statsBeneficiario.edadclub1}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>Menos de 1 año</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedEdadClub2} onClick={() => { filterSublistEdadClub('edadclub2') }}
              rightNode={statsBeneficiario.edadclub2}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>1 - 2 años</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedEdadClub3} onClick={() => { filterSublistEdadClub('edadclub3') }}
              rightNode={statsBeneficiario.edadclub3}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>2 - 5 años</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedEdadClub4} onClick={() => { filterSublistEdadClub('edadclub4') }}
              rightNode={statsBeneficiario.edadclub4}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>5 - 10 años</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedEdadClub5} onClick={() => { filterSublistEdadClub('edadclub5') }}
              rightNode={statsBeneficiario.edadclub5}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>10 - 15 años</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedEdadClub6} onClick={() => { filterSublistEdadClub('edadclub6') }}
              rightNode={statsBeneficiario.edadclub6}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>15 y 20 años</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedEdadClub7} onClick={() => { filterSublistEdadClub('edadclub7') }}
              rightNode={statsBeneficiario.edadclub7}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>20 y 25 años</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedEdadClub8} onClick={() => { filterSublistEdadClub('edadclub8') }}
              rightNode={statsBeneficiario.edadclub8}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>25 y 30 años</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedEdadClub9} onClick={() => { filterSublistEdadClub('edadclub9') }}
              rightNode={statsBeneficiario.edadclub9}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>30 y 35 años</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedEdadClub10} onClick={() => { filterSublistEdadClub('edadclub10') }}
              rightNode={statsBeneficiario.edadclub10}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>35 y 40 años</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedEdadClub11} onClick={() => { filterSublistEdadClub('edadclub11') }}
              rightNode={statsBeneficiario.edadclub11}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>40 y 45 años</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedEdadClub12} onClick={() => { filterSublistEdadClub('edadclub12') }}
              rightNode={statsBeneficiario.edadclub12}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>45 y 50 años</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedEdadClub13} onClick={() => { filterSublistEdadClub('edadclub13') }}
              rightNode={statsBeneficiario.edadclub13}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>Más de 50 años</ListItemTitle>
            </ListItem>
            
          </List>

        </>
      );
    
    return ( <></>);
       
  }

  return (
      <div className="filtro-bienestar">
        <List minimal>
          <ListHeader>Filtro</ListHeader>
          {/* Mostrar los filtros seleccionados*/}
          {/* {listadeFiltros && listadeFiltros.length>0 && <ListItem>
            {listadeFiltros.map((lf, idx) => {
              return <div key={idx}>{lf.filtro}</div>
            })}
          </ListItem>}  */}
          {/* <ListItemActivo statsBeneficiario={statsBeneficiario}/> */}
          <ListItemCategoriaSeguimiento statsBeneficiario={statsBeneficiario}/>
          <ListItemAcuerdoCorresp statsBeneficiario={statsBeneficiario}/>
          <ListItemJefeHogar statsBeneficiario={statsBeneficiario}/>
          <ListItemPersonasaCargo statsBeneficiario={statsBeneficiario}/>
          <ListItemEstado statsBeneficiario={statsBeneficiario}/>
          <ListItemActividad statsBeneficiario={statsBeneficiario}/>
          <ListItemCategoria statsBeneficiario={statsBeneficiario}/>
          <ListItemEdad statsBeneficiario={statsBeneficiario}/>
          <ListItemEdadClub statsBeneficiario={statsBeneficiario}/>
          <ListItemFechaActualizado statsBeneficiario={statsBeneficiario}/>
          
        </List>

      </div>
    )

}

export default FilterCovidAcomp;