// Copyright 2020 Ernesto Girón E.
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//     http://www.apache.org/licenses/LICENSE-2.0
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.​

import { call, put, takeLatest } from "redux-saga/effects";
import { types as configTypes } from "../reducers/covid19";
import { getBeneficiariosDataxCovid, 
  getListEntregaDataxCovid,
  getBeneficiarioPerfilDataxCovid, 
  getBeneficiariosDataxCovidAcomp,
  getTablasCriteriosxCovid,
  getAyudasData,
  actualizarAyudasCovid,
  crearAyudasCovid,
  eliminarAyudasCovid,
  validarNuevaEntregaCovid,
  crearBatchListaEntregaCovid,
  actualizarListaEntregaCovid,
  crearListaEntregaCovid,
  eliminarListaEntregaCovid,
  limpiarListaEntregaCovid,
  actualizarCriteriosPrestaServicio,
  crearCriteriosPrestaServicio,
  eliminarCriteriosPrestaServicio,
  actualizarCriteriosFamiliaClub,
  crearCriteriosFamiliaClub,
  eliminarCriteriosFamiliaClub,
  actualizarCriteriosCabezaFamilia,
  crearCriteriosCabezaFamilia,
  eliminarCriteriosCabezaFamilia,
  actualizarCriteriosPersonasaCargo,
  crearCriteriosPersonasaCargo,
  eliminarCriteriosPersonasaCargo,
  actualizarCriteriosNumeroHijos,
  crearCriteriosNumeroHijos,
  eliminarCriteriosNumeroHijos,
  actualizarCriteriosAsistencia,
  crearCriteriosAsistencia,
  eliminarCriteriosAsistencia,
  actualizarCriteriosActividad,
  crearCriteriosActividad,
  eliminarCriteriosActividad,
  actualizarCriteriosCategoria,
  crearCriteriosCategoria,
  eliminarCriteriosCategoria,
  actualizarCriteriosEdad,
  crearCriteriosEdad,
  eliminarCriteriosEdad,
  actualizarCriteriosEdadClub,
  crearCriteriosEdadClub,
  eliminarCriteriosEdadClub,
  actualizarCriteriosCupo,
  crearCriteriosCupo,
  eliminarCriteriosCupo
  
} from "../../utils/covid_req";

// WORKERS //
function* fetchBeneficiariosDataxCovid(action) {
  try {
    // fetch listBeneficiarios
    const listBenef = yield call(getBeneficiariosDataxCovid, action.payload.token);
    // load users into Redux store
    yield put({
      type: configTypes.MODULE_COVID19_BENEFICIARIOS_LIST_LOADED,
      payload: {listBenef: listBenef}
    });
  } catch (e) {
    console.error("SAGA ERROR: covid_req/fetchBeneficiariosDataxCovid, ", e);
  }
}

function* fetchListEntregaDataxCovid(action) {
  try {
    const listEntrega = yield call(getListEntregaDataxCovid, action.payload.token);
    yield put({
      type: configTypes.MODULE_COVID19_ENTREGA_LIST_LOADED,
      payload: {listEntrega: listEntrega}
    });
  } catch (e) {
    console.error("SAGA ERROR: covid_req/fetchListEntregaDataxCovid, ", e);
  }
}


function* fetchBeneficiariosDataxCovidAcomp(action) {
  try {
    // fetch datos del Beneficiario seleccionado
    const listBenefAcomp = yield call(getBeneficiariosDataxCovidAcomp, action.payload.token);
    // load users into Redux store
    if (listBenefAcomp)
      yield put({
        type: configTypes.COVID19_ACOMP_BENEFICIARIOS_LIST_LOADED,
        payload: {listBenefAcomp: listBenefAcomp}
      });
  } catch (e) {
    console.error("SAGA ERROR: covid_req/fetchBeneficiariosDataxCovidAcomp, ", e);
  }
}

function* fetchBeneficiarioPerfilDataxCovid(action) {
  try {
    // fetch datos del Beneficiario seleccionado
    const datosSelBenef = yield call(getBeneficiarioPerfilDataxCovid, action.payload);
    // load users into Redux store
    if (datosSelBenef)
      yield put({
        type: configTypes.MODULE_COVID19_BENEFICIARIO_DATA_SELECTED,
        payload: {datosSelBenef: datosSelBenef, selectedProfile:{docId:datosSelBenef.beneficiarios[0].docId}}
      });
  } catch (e) {
    console.error("SAGA ERROR: covid_req/fetchBeneficiarioPerfilDataxCovid, ", e);
  }
}

function* fetchBeneficiarioPerfilDataxCovidAcomp(action) {
  try {
    // fetch datos del Beneficiario seleccionado
    const datosSelBenefAcomp = yield call(getBeneficiarioPerfilDataxCovid, action.payload);
    // load users into Redux store
    if (datosSelBenefAcomp)
      yield put({
        type: configTypes.COVID19_ACOMP_BENEFICIARIO_DATA_SELECTED,
        payload: {datosSelBenefAcomp: datosSelBenefAcomp, selectedProfileAcomp:{docId:datosSelBenefAcomp.beneficiarios[0].docId}}
      });
  } catch (e) {
    console.error("SAGA ERROR: covid_req/fetchBeneficiarioPerfilDataxCovidAcomp, ", e);
  }
}


function* fetchTablasCriteriosxCovid(action) {
  try {
    // fetch list Criterios para Ayudas
    const listCriterios = yield call(getTablasCriteriosxCovid, action.payload.token);
    yield put({
      type: configTypes.MODULE_COVID19_TABLASCRITERIOS_LIST_LOADED,
      payload: {listCriterios: listCriterios}
    });
  } catch (e) {
    console.error("SAGA ERROR: covid_req/fetchTablasCriteriosxCovid, ", e);
  }
}


function* fetchAyudasDataCovid(action) {
  try {
    // fetch listBeneficiarios con Ayudas
    const listAyudas = yield call(getAyudasData, action.payload.token);
    yield put({
      type: configTypes.MODULE_COVID19_AYUDAS_LIST_LOADED,
      payload: {listAyudas: listAyudas}
    });
  } catch (e) {
    console.error("SAGA ERROR: covid_req/fetchAyudasDataCovid, ", e);
  }
}
function* updateAyudasCovid(action) {
  try {
    const resp = yield call(actualizarAyudasCovid, action.payload);
    if (resp && resp!=='')
      yield put({
        type: configTypes.MODULE_COVID19_SERVER_MESSAGE,
        payload: {mensaje: {"covidayudas": resp}}
      });
  } catch (e) {
    console.error("SAGA ERROR: covid_req/updateAyudasCovid, ", e);
  }
}
function* insertAyudasCovid(action) {
  try {
    const resp = yield call(crearAyudasCovid, action.payload);
    if (resp && resp!=='')
      yield put({
        type: configTypes.MODULE_COVID19_SERVER_MESSAGE,
        payload: {mensaje: {"covidayudas": resp}}
      });
  } catch (e) {
    console.error("SAGA ERROR: covid_req/insertAyudasCovid, ", e);
  }
}
function* deleteAyudasCovid(action) {
  try {
    const resp = yield call(eliminarAyudasCovid, action.payload);
    if (resp && resp!=='')
      yield put({
        type: configTypes.MODULE_COVID19_SERVER_MESSAGE,
        payload: {mensaje: {"covidayudas": resp}}
      });
  } catch (e) {
    console.error("SAGA ERROR: covid_req/deleteAyudasCovid, ", e);
  }
}

// CRITERIOS PRESTA SERVICIO
function* updateCriteriosPrestaServicio(action) {
  try {
    const resp = yield call(actualizarCriteriosPrestaServicio, action.payload);
    if (resp && resp!=='')
      yield put({
        type: configTypes.MODULE_COVID19_SERVER_MESSAGE,
        payload: {mensaje: {"criteriosprestaservicio": resp}}
      });
  } catch (e) {
    console.error("SAGA ERROR: covid_req/updateCriteriosPrestaServicio, ", e);
  }
}
function* insertCriteriosPrestaServicio(action) {
  try {
    const resp = yield call(crearCriteriosPrestaServicio, action.payload);
    if (resp && resp!=='')
      yield put({
        type: configTypes.MODULE_COVID19_SERVER_MESSAGE,
        payload: {mensaje: {"criteriosprestaservicio": resp}}
      });
  } catch (e) {
    console.error("SAGA ERROR: covid_req/insertCriteriosPrestaServicio, ", e);
  }
}
function* deleteCriteriosPrestaServicio(action) {
  try {
    const resp = yield call(eliminarCriteriosPrestaServicio, action.payload);
    if (resp && resp!=='')
      yield put({
        type: configTypes.MODULE_COVID19_SERVER_MESSAGE,
        payload: {mensaje: {"criteriosprestaservicio": resp}}
      });
  } catch (e) {
    console.error("SAGA ERROR: covid_req/deleteCriteriosPrestaServicio, ", e);
  }
}

// CRITERIOS FAMILIARES EN EL CLUB
function* updateCriteriosFamiliaClub(action) {
  try {
    const resp = yield call(actualizarCriteriosFamiliaClub, action.payload);
    if (resp && resp!=='')
      yield put({
        type: configTypes.MODULE_COVID19_SERVER_MESSAGE,
        payload: {mensaje: {"criteriosfamiliaclub": resp}}
      });
  } catch (e) {
    console.error("SAGA ERROR: covid_req/updateCriteriosFamiliaClub, ", e);
  }
}
function* insertCriteriosFamiliaClub(action) {
  try {
    const resp = yield call(crearCriteriosFamiliaClub, action.payload);
    if (resp && resp!=='')
      yield put({
        type: configTypes.MODULE_COVID19_SERVER_MESSAGE,
        payload: {mensaje: {"criteriosfamiliaclub": resp}}
      });
  } catch (e) {
    console.error("SAGA ERROR: covid_req/insertCriteriosFamiliaClub, ", e);
  }
}
function* deleteCriteriosFamiliaClub(action) {
  try {
    const resp = yield call(eliminarCriteriosFamiliaClub, action.payload);
    if (resp && resp!=='')
      yield put({
        type: configTypes.MODULE_COVID19_SERVER_MESSAGE,
        payload: {mensaje: {"criteriosfamiliaclub": resp}}
      });
  } catch (e) {
    console.error("SAGA ERROR: covid_req/deleteCriteriosFamiliaClub, ", e);
  }
}

// CRITERIOS CABEZA FAMILIA
function* updateCriteriosCabezaFamilia(action) {
  try {
    const resp = yield call(actualizarCriteriosCabezaFamilia, action.payload);
    if (resp && resp!=='')
      yield put({
        type: configTypes.MODULE_COVID19_SERVER_MESSAGE,
        payload: {mensaje: {"criterioscabezafamilia": resp}}
      });
  } catch (e) {
    console.error("SAGA ERROR: covid_req/updateCriteriosCabezaFamilia, ", e);
  }
}
function* insertCriteriosCabezaFamilia(action) {
  try {
    const resp = yield call(crearCriteriosCabezaFamilia, action.payload);
    if (resp && resp!=='')
      yield put({
        type: configTypes.MODULE_COVID19_SERVER_MESSAGE,
        payload: {mensaje: {"criterioscabezafamilia": resp}}
      });
  } catch (e) {
    console.error("SAGA ERROR: covid_req/insertCriteriosCabezaFamilia, ", e);
  }
}
function* deleteCriteriosCabezaFamilia(action) {
  try {
    const resp = yield call(eliminarCriteriosCabezaFamilia, action.payload);
    if (resp && resp!=='')
      yield put({
        type: configTypes.MODULE_COVID19_SERVER_MESSAGE,
        payload: {mensaje: {"criterioscabezafamilia": resp}}
      });
  } catch (e) {
    console.error("SAGA ERROR: covid_req/deleteCriteriosCabezaFamilia, ", e);
  }
}

// CRITERIOS PERSONAS A CARGO
function* updateCriteriosPersonasaCargo(action) {
  try {
    const resp = yield call(actualizarCriteriosPersonasaCargo, action.payload);
    if (resp && resp!=='')
      yield put({
        type: configTypes.MODULE_COVID19_SERVER_MESSAGE,
        payload: {mensaje: {"criteriospersonasacargo": resp}}
      });
  } catch (e) {
    console.error("SAGA ERROR: covid_req/updateCriteriosPersonasaCargo, ", e);
  }
}
function* insertCriteriosPersonasaCargo(action) {
  try {
    const resp = yield call(crearCriteriosPersonasaCargo, action.payload);
    if (resp && resp!=='')
      yield put({
        type: configTypes.MODULE_COVID19_SERVER_MESSAGE,
        payload: {mensaje: {"criteriospersonasacargo": resp}}
      });
  } catch (e) {
    console.error("SAGA ERROR: covid_req/insertCriteriosPersonasaCargo, ", e);
  }
}
function* deleteCriteriosPersonasaCargo(action) {
  try {
    const resp = yield call(eliminarCriteriosPersonasaCargo, action.payload);
    if (resp && resp!=='')
      yield put({
        type: configTypes.MODULE_COVID19_SERVER_MESSAGE,
        payload: {mensaje: {"criteriospersonasacargo": resp}}
      });
  } catch (e) {
    console.error("SAGA ERROR: covid_req/deleteCriteriosPersonasaCargo, ", e);
  }
}

// CRITERIOS NUMERO DE HIJOS
function* updateCriteriosNumeroHijos(action) {
  try {
    const resp = yield call(actualizarCriteriosNumeroHijos, action.payload);
    if (resp && resp!=='')
      yield put({
        type: configTypes.MODULE_COVID19_SERVER_MESSAGE,
        payload: {mensaje: {"criteriosnumerohijos": resp}}
      });
  } catch (e) {
    console.error("SAGA ERROR: covid_req/updateCriteriosNumeroHijos, ", e);
  }
}
function* insertCriteriosNumeroHijos(action) {
  try {
    const resp = yield call(crearCriteriosNumeroHijos, action.payload);
    if (resp && resp!=='')
      yield put({
        type: configTypes.MODULE_COVID19_SERVER_MESSAGE,
        payload: {mensaje: {"criteriosnumerohijos": resp}}
      });
  } catch (e) {
    console.error("SAGA ERROR: covid_req/insertCriteriosNumeroHijos, ", e);
  }
}
function* deleteCriteriosNumeroHijos(action) {
  try {
    const resp = yield call(eliminarCriteriosNumeroHijos, action.payload);
    if (resp && resp!=='')
      yield put({
        type: configTypes.MODULE_COVID19_SERVER_MESSAGE,
        payload: {mensaje: {"criteriosnumerohijos": resp}}
      });
  } catch (e) {
    console.error("SAGA ERROR: covid_req/deleteCriteriosNumeroHijos, ", e);
  }
}


// CRITERIOS ASISTENCIA
function* updateCriteriosAsistencia(action) {
  try {
    const resp = yield call(actualizarCriteriosAsistencia, action.payload);
    if (resp && resp!=='')
      yield put({
        type: configTypes.MODULE_COVID19_SERVER_MESSAGE,
        payload: {mensaje: {"criteriosasistencia": resp}}
      });
  } catch (e) {
    console.error("SAGA ERROR: covid_req/updateCriteriosAsistencia, ", e);
  }
}
function* insertCriteriosAsistencia(action) {
  try {
    const resp = yield call(crearCriteriosAsistencia, action.payload);
    if (resp && resp!=='')
      yield put({
        type: configTypes.MODULE_COVID19_SERVER_MESSAGE,
        payload: {mensaje: {"criteriosasistencia": resp}}
      });
  } catch (e) {
    console.error("SAGA ERROR: covid_req/insertCriteriosAsistencia, ", e);
  }
}
function* deleteCriteriosAsistencia(action) {
  try {
    const resp = yield call(eliminarCriteriosAsistencia, action.payload);
    if (resp && resp!=='')
      yield put({
        type: configTypes.MODULE_COVID19_SERVER_MESSAGE,
        payload: {mensaje: {"criteriosasistencia": resp}}
      });
  } catch (e) {
    console.error("SAGA ERROR: covid_req/deleteCriteriosAsistencia, ", e);
  }
}

// CRITERIOS POR ACTIVIDAD
function* updateCriteriosActividad(action) {
  try {
    const resp = yield call(actualizarCriteriosActividad, action.payload);
    if (resp && resp!=='')
      yield put({
        type: configTypes.MODULE_COVID19_SERVER_MESSAGE,
        payload: {mensaje: {"criteriosactividad": resp}}
      });
  } catch (e) {
    console.error("SAGA ERROR: covid_req/updateCriteriosActividad, ", e);
  }
}
function* insertCriteriosActividad(action) {
  try {
    const resp = yield call(crearCriteriosActividad, action.payload);
    if (resp && resp!=='')
      yield put({
        type: configTypes.MODULE_COVID19_SERVER_MESSAGE,
        payload: {mensaje: {"criteriosactividad": resp}}
      });
  } catch (e) {
    console.error("SAGA ERROR: covid_req/insertCriteriosActividad, ", e);
  }
}
function* deleteCriteriosActividad(action) {
  try {
    const resp = yield call(eliminarCriteriosActividad, action.payload);
    if (resp && resp!=='')
      yield put({
        type: configTypes.MODULE_COVID19_SERVER_MESSAGE,
        payload: {mensaje: {"criteriosactividad": resp}}
      });
  } catch (e) {
    console.error("SAGA ERROR: covid_req/deleteCriteriosActividad, ", e);
  }
}

// CRITERIOS POR CATEGORIA
function* updateCriteriosCategoria(action) {
  try {
    const resp = yield call(actualizarCriteriosCategoria, action.payload);
    if (resp && resp!=='')
      yield put({
        type: configTypes.MODULE_COVID19_SERVER_MESSAGE,
        payload: {mensaje: {"criterioscategoria": resp}}
      });
  } catch (e) {
    console.error("SAGA ERROR: covid_req/updateCriteriosCategoria, ", e);
  }
}
function* insertCriteriosCategoria(action) {
  try {
    const resp = yield call(crearCriteriosCategoria, action.payload);
    if (resp && resp!=='')
      yield put({
        type: configTypes.MODULE_COVID19_SERVER_MESSAGE,
        payload: {mensaje: {"criterioscategoria": resp}}
      });
  } catch (e) {
    console.error("SAGA ERROR: covid_req/insertCriteriosCategoria, ", e);
  }
}
function* deleteCriteriosCategoria(action) {
  try {
    const resp = yield call(eliminarCriteriosCategoria, action.payload);
    if (resp && resp!=='')
      yield put({
        type: configTypes.MODULE_COVID19_SERVER_MESSAGE,
        payload: {mensaje: {"criterioscategoria": resp}}
      });
  } catch (e) {
    console.error("SAGA ERROR: covid_req/deleteCriteriosCategoria, ", e);
  }
}

// CRITERIOS POR EDAD
function* updateCriteriosEdad(action) {
  try {
    const resp = yield call(actualizarCriteriosEdad, action.payload);
    if (resp && resp!=='')
      yield put({
        type: configTypes.MODULE_COVID19_SERVER_MESSAGE,
        payload: {mensaje: {"criteriosedad": resp}}
      });
  } catch (e) {
    console.error("SAGA ERROR: covid_req/updateCriteriosEdad, ", e);
  }
}
function* insertCriteriosEdad(action) {
  try {
    const resp = yield call(crearCriteriosEdad, action.payload);
    if (resp && resp!=='')
      yield put({
        type: configTypes.MODULE_COVID19_SERVER_MESSAGE,
        payload: {mensaje: {"criteriosedad": resp}}
      });
  } catch (e) {
    console.error("SAGA ERROR: covid_req/insertCriteriosEdad, ", e);
  }
}
function* deleteCriteriosEdad(action) {
  try {
    const resp = yield call(eliminarCriteriosEdad, action.payload);
    if (resp && resp!=='')
      yield put({
        type: configTypes.MODULE_COVID19_SERVER_MESSAGE,
        payload: {mensaje: {"criteriosedad": resp}}
      });
  } catch (e) {
    console.error("SAGA ERROR: covid_req/deleteCriteriosEdad, ", e);
  }
}

// CRITERIOS POR TIEMPO EN EL CLUB
function* updateCriteriosEdadClub(action) {
  try {
    const resp = yield call(actualizarCriteriosEdadClub, action.payload);
    if (resp && resp!=='')
      yield put({
        type: configTypes.MODULE_COVID19_SERVER_MESSAGE,
        payload: {mensaje: {"criteriosedadclub": resp}}
      });
  } catch (e) {
    console.error("SAGA ERROR: covid_req/updateCriteriosEdadClub, ", e);
  }
}
function* insertCriteriosEdadClub(action) {
  try {
    const resp = yield call(crearCriteriosEdadClub, action.payload);
    if (resp && resp!=='')
      yield put({
        type: configTypes.MODULE_COVID19_SERVER_MESSAGE,
        payload: {mensaje: {"criteriosedadclub": resp}}
      });
  } catch (e) {
    console.error("SAGA ERROR: covid_req/insertCriteriosEdadClub, ", e);
  }
}
function* deleteCriteriosEdadClub(action) {
  try {
    const resp = yield call(eliminarCriteriosEdadClub, action.payload);
    if (resp && resp!=='')
      yield put({
        type: configTypes.MODULE_COVID19_SERVER_MESSAGE,
        payload: {mensaje: {"criteriosedadclub": resp}}
      });
  } catch (e) {
    console.error("SAGA ERROR: covid_req/deleteCriteriosEdadClub, ", e);
  }
}

// CRITERIOS TOPE O CUPO POR AFECTACION
function* updateCriteriosCupo(action) {
  try {
    const resp = yield call(actualizarCriteriosCupo, action.payload);
    if (resp && resp!=='')
      yield put({
        type: configTypes.MODULE_COVID19_SERVER_MESSAGE,
        payload: {mensaje: {"criterioscupo": resp}}
      });
  } catch (e) {
    console.error("SAGA ERROR: covid_req/updateCriteriosCupo, ", e);
  }
}
function* insertCriteriosCupo(action) {
  try {
    const resp = yield call(crearCriteriosCupo, action.payload);
    if (resp && resp!=='')
      yield put({
        type: configTypes.MODULE_COVID19_SERVER_MESSAGE,
        payload: {mensaje: {"criterioscupo": resp}}
      });
  } catch (e) {
    console.error("SAGA ERROR: covid_req/insertCriteriosCupo, ", e);
  }
}
function* deleteCriteriosCupo(action) {
  try {
    const resp = yield call(eliminarCriteriosCupo, action.payload);
    if (resp && resp!=='')
      yield put({
        type: configTypes.MODULE_COVID19_SERVER_MESSAGE,
        payload: {mensaje: {"criterioscupo": resp}}
      });
  } catch (e) {
    console.error("SAGA ERROR: covid_req/deleteCriteriosCupo, ", e);
  }
}


// LISTA DE ENTREGAS
function* updateListaEntregaCovid(action) {
  try {
    const resp = yield call(actualizarListaEntregaCovid, action.payload);
    if (resp && resp!=='')
      yield put({
        type: configTypes.MODULE_COVID19_SERVER_MESSAGE,
        payload: {mensaje: {"listaentregas": resp}}
      });
  } catch (e) {
    console.error("SAGA ERROR: covid_req/updateListaEntregaCovid, ", e);
  }
}
function* insertListaEntregaCovid(action) {
  try {
    const resp = yield call(crearListaEntregaCovid, action.payload);
    if (resp && resp!=='')
      yield put({
        type: configTypes.MODULE_COVID19_SERVER_MESSAGE,
        payload: {mensaje: {"listaentregas": resp}}
      });
  } catch (e) {
    console.error("SAGA ERROR: covid_req/insertListaEntregaCovid, ", e);
  }
}
function* deleteListaEntregaCovid(action) {
  try {
    const resp = yield call(eliminarListaEntregaCovid, action.payload);
    if (resp && resp!=='')
      yield put({
        type: configTypes.MODULE_COVID19_SERVER_MESSAGE,
        payload: {mensaje: {"listaentregas": resp}}
      });
  } catch (e) {
    console.error("SAGA ERROR: covid_req/deleteListaEntregaCovid, ", e);
  }
}
function* cleanListaEntregaCovid(action) {
  try {
    const resp = yield call(limpiarListaEntregaCovid, action.payload);
    if (resp && resp!=='')
      yield put({
        type: configTypes.MODULE_COVID19_SERVER_MESSAGE,
        payload: {mensaje: {"listaentregas": resp}}
      });
  } catch (e) {
    console.error("SAGA ERROR: covid_req/cleanListaEntregaCovid, ", e);
  }
}

function* insertBatchListaEntregaCovid(action) {
  try {
    const resp = yield call(crearBatchListaEntregaCovid, action.payload);
    if (resp && resp!=='')
      yield put({
        type: configTypes.MODULE_COVID19_SERVER_MESSAGE,
        payload: {mensaje: {"listaentregas": resp}}
      });
  } catch (e) {
    console.error("SAGA ERROR: covid_req/insertBatchListaEntregaCovid, ", e);
  }
}

function* validarListEntrega(action) {
  try {
    const resp = yield call(validarNuevaEntregaCovid, action.payload);
    if (resp && resp!=='')
      yield put({
        type: configTypes.MODULE_COVID19_SERVER_MESSAGE,
        payload: {mensaje: {"listaentregas": resp}}
      });
  } catch (e) {
    console.error("SAGA ERROR: covid_req/validarListEntrega, ", e);
  }
}



// WATCHER //
export function* watchFetchBeneficiariosCovidData() {
  yield takeLatest(configTypes.MODULE_COVID19_BENEFDATA_LOADED, fetchBeneficiariosDataxCovid);
  yield takeLatest(configTypes.MODULE_COVID19_LISTAENTREGASDATA_LOADED, fetchListEntregaDataxCovid);

  yield takeLatest(configTypes.COVID19_ACOMP_BENEFDATA_LOADED, fetchBeneficiariosDataxCovidAcomp);
  yield takeLatest(configTypes.MODULE_COVID19_BENEFICIARIO_SELECTED_DATA_REQUEST, fetchBeneficiarioPerfilDataxCovid);
  yield takeLatest(configTypes.COVID19_ACOMP_BENEFICIARIO_SELECTED_DATA_REQUEST, fetchBeneficiarioPerfilDataxCovidAcomp);
  yield takeLatest(configTypes.MODULE_COVID19_AYUDAS_DATA_LOADED, fetchAyudasDataCovid);
  yield takeLatest(configTypes.MODULE_COVID19_TABLASCRITERIOS_LOADED, fetchTablasCriteriosxCovid);
  yield takeLatest(configTypes.COVID19_SELECTED_AYUDAS_UPDATED, updateAyudasCovid);
  yield takeLatest(configTypes.COVID19_SELECTED_AYUDAS_CREATED, insertAyudasCovid);
  yield takeLatest(configTypes.COVID19_SELECTED_AYUDAS_DELETED, deleteAyudasCovid);

  yield takeLatest(configTypes.COVID19_LISTENTREGA_BATCH, insertBatchListaEntregaCovid);
  yield takeLatest(configTypes.COVID19_LISTENTREGA_UPDATED, updateListaEntregaCovid);
  yield takeLatest(configTypes.COVID19_LISTENTREGA_CREATED, insertListaEntregaCovid);
  yield takeLatest(configTypes.COVID19_LISTENTREGA_DELETED, deleteListaEntregaCovid);
  yield takeLatest(configTypes.COVID19_LISTENTREGA_CLEAN, cleanListaEntregaCovid);
  yield takeLatest(configTypes.COVID19_LISTENTREGA_VALIDAR, validarListEntrega);
  
  yield takeLatest(configTypes.COVID19_CRITERIOS_PRESTASERVICIO_UPDATED, updateCriteriosPrestaServicio);
  yield takeLatest(configTypes.COVID19_CRITERIOS_PRESTASERVICIO_CREATED, insertCriteriosPrestaServicio);
  yield takeLatest(configTypes.COVID19_CRITERIOS_PRESTASERVICIO_DELETED, deleteCriteriosPrestaServicio);

  yield takeLatest(configTypes.COVID19_CRITERIOS_FAMILIACLUB_UPDATED, updateCriteriosFamiliaClub);
  yield takeLatest(configTypes.COVID19_CRITERIOS_FAMILIACLUB_CREATED, insertCriteriosFamiliaClub);
  yield takeLatest(configTypes.COVID19_CRITERIOS_FAMILIACLUB_DELETED, deleteCriteriosFamiliaClub);

  yield takeLatest(configTypes.COVID19_CRITERIOS_CABEZAHOGAR_UPDATED, updateCriteriosCabezaFamilia);
  yield takeLatest(configTypes.COVID19_CRITERIOS_CABEZAHOGAR_CREATED, insertCriteriosCabezaFamilia);
  yield takeLatest(configTypes.COVID19_CRITERIOS_CABEZAHOGAR_DELETED, deleteCriteriosCabezaFamilia);
  
  yield takeLatest(configTypes.COVID19_CRITERIOS_PERSONASACARGO_UPDATED, updateCriteriosPersonasaCargo);
  yield takeLatest(configTypes.COVID19_CRITERIOS_PERSONASACARGO_CREATED, insertCriteriosPersonasaCargo);
  yield takeLatest(configTypes.COVID19_CRITERIOS_PERSONASACARGO_DELETED, deleteCriteriosPersonasaCargo);
  
  yield takeLatest(configTypes.COVID19_CRITERIOS_NUMEROHIJOS_UPDATED, updateCriteriosNumeroHijos);
  yield takeLatest(configTypes.COVID19_CRITERIOS_NUMEROHIJOS_CREATED, insertCriteriosNumeroHijos);
  yield takeLatest(configTypes.COVID19_CRITERIOS_NUMEROHIJOS_DELETED, deleteCriteriosNumeroHijos);
  
  yield takeLatest(configTypes.COVID19_CRITERIOS_ASISTENCIA_UPDATED, updateCriteriosAsistencia);
  yield takeLatest(configTypes.COVID19_CRITERIOS_ASISTENCIA_CREATED, insertCriteriosAsistencia);
  yield takeLatest(configTypes.COVID19_CRITERIOS_ASISTENCIA_DELETED, deleteCriteriosAsistencia);

  yield takeLatest(configTypes.COVID19_CRITERIOS_ACTIVIDAD_UPDATED, updateCriteriosActividad);
  yield takeLatest(configTypes.COVID19_CRITERIOS_ACTIVIDAD_CREATED, insertCriteriosActividad);
  yield takeLatest(configTypes.COVID19_CRITERIOS_ACTIVIDAD_DELETED, deleteCriteriosActividad);

  yield takeLatest(configTypes.COVID19_CRITERIOS_CATEGORIA_UPDATED, updateCriteriosCategoria);
  yield takeLatest(configTypes.COVID19_CRITERIOS_CATEGORIA_CREATED, insertCriteriosCategoria);
  yield takeLatest(configTypes.COVID19_CRITERIOS_CATEGORIA_DELETED, deleteCriteriosCategoria);

  yield takeLatest(configTypes.COVID19_CRITERIOS_EDAD_UPDATED, updateCriteriosEdad);
  yield takeLatest(configTypes.COVID19_CRITERIOS_EDAD_CREATED, insertCriteriosEdad);
  yield takeLatest(configTypes.COVID19_CRITERIOS_EDAD_DELETED, deleteCriteriosEdad);

  yield takeLatest(configTypes.COVID19_CRITERIOS_EDADCLUB_UPDATED, updateCriteriosEdadClub);
  yield takeLatest(configTypes.COVID19_CRITERIOS_EDADCLUB_CREATED, insertCriteriosEdadClub);
  yield takeLatest(configTypes.COVID19_CRITERIOS_EDADCLUB_DELETED, deleteCriteriosEdadClub);

  yield takeLatest(configTypes.COVID19_CRITERIOS_CUPO_UPDATED, updateCriteriosCupo);
  yield takeLatest(configTypes.COVID19_CRITERIOS_CUPO_CREATED, insertCriteriosCupo);
  yield takeLatest(configTypes.COVID19_CRITERIOS_CUPO_DELETED, deleteCriteriosCupo);


}
