// React
import React, { Fragment,useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { fetchBeneficiariosDataxVestuarioTallaje, 
    displayVestuarioTallajeFilter, 
} from '../../../redux/reducers/vestuario';

// load lazy data
import DisplayListBeneficiarios from './displayListBeneficiariosTallaje';
import TallajeDetails from './TallajeDetails';

import ListButtonIcon from 'calcite-ui-icons-react/ListButtonIcon';
import FilterIcon from 'calcite-ui-icons-react/FilterIcon';
import Loader from 'calcite-react/Loader';
import Search from 'calcite-react/Search';

import Tabs, {
    TabNav,
    TabTitle,
    TabContents,
    TabSection
} from 'calcite-react/Tabs';

// Styled & Motion Components
import styled from "styled-components";

const SearchBeneficiario = styled.div`
    position: relative;
    float: right;
    // margin-right: 51px;
    top: -4px;
    height:1.9rem;

    /* Extra large devices (large laptops and desktops, 1200px and up) */
    @media only screen and (max-width: 1200px) {
        
    } 

    /* Large devices (laptops/desktops, 992px and up) */
    @media only screen and (max-width: 992px) {
        
    }

    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (max-width: 768px) {
        max-width: 150px;
    }

    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (max-width: 600px) {
        max-width: 150px;
    }

    /* Extra small devices (phones, 600px and down) */
    @media only screen and (max-width: 600px) {
        max-width: 150px;
    }

`;

const BeneficiarioDetalleContainer = styled.div`
    z-index:100;
    display: flex;
    position: absolute;
    left: 0px;
    top: 0px;
    width:100%;
    height:100%;
    padding:0px;
    margin:0px;
    background-color: #ffffffa3;
    //padding-right: 50px;
`;

const FiltroButton = styled.div`
    // float: left;
    position: relative;
    margin-bottom: 2px;
    top: 0px;
    background: #fff;
    width: 41px;
    height: 40px;
    border: 1px solid #ccc;
    cursor: pointer;

    &:hover{
        background: #f8f8f8;
    }

    /* Extra large devices (large laptops and desktops, 1200px and up) */
    @media only screen and (max-width: 1200px) {
        
    } 

    /* Large devices (laptops/desktops, 992px and up) */
    @media only screen and (max-width: 992px) {
        
    }

    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (max-width: 768px) {
        
    }

    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (max-width: 600px) {
        max-width: 150px;
    }

    /* Extra small devices (phones, 400px and down) */
    @media only screen and (max-width: 400px) {
        
    }

`;
const FiltroIcon = <FilterIcon style={{ width: '20px', height: '20px', marginTop: '10px', color:'green' }} />

const ModuleVestuarioTallaje = props => {
    const users = useSelector(state => state.users);
    const selectedProfile = useSelector(state => state.vestuario.selectedProfileTallaje);
    const listBenefTallaje = useSelector(state => state.vestuario.listBenefTallaje);
    const filter = useSelector(state => state.vestuario.filterTallaje);
    const isFilterTallajeShow = useSelector(state => state.vestuario.isFilterTallajeShow);
    const [filtroBeneficiario,setFiltroBeneficiario] = useState([]);
    const [isFiltered,setIsFiltered] = useState(false);
    const dispatch = useDispatch();
    const [activeTabIndex,setTabIndex] = useState(0);
    
    const [inputValue,setInputValue] = useState('');
    const [selectedItem,setSelectedItem] = useState('');
    const [searchItems,setSearchItems] = useState([]);
    // const dataSourceConfig = { label: 'label', value: 'id', }
    // const capitalize = (str, lower = false) => (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, match => match.toUpperCase());
    
    const onTabChange = (index) => {
        setTabIndex(index);
    }

    useEffect(() => {
        if (!listBenefTallaje || listBenefTallaje.length<=0){
            dispatch(fetchBeneficiariosDataxVestuarioTallaje({token: users.user.claveAPI}));
            return
        }
        return () => {
            // executed when unmount
            // setIsFiltered(false);
            // setFiltroBeneficiario([]);
            // dispatch(displayVestuarioTallajeFilter({isFilterShow:false}));
            // dispatch(resetDataVestuarioTallaje());
            // dispatch(resetSelectBeneficiarioForPerfilxVestuarioTallaje());
        }
        
    }, [users.user.claveAPI, listBenefTallaje, dispatch]);

    useEffect(() => {
        let datos = [];
        if (filter && filter.length>0){
            setIsFiltered(true);
            datos = [...filter];
            setFiltroBeneficiario(filter);
        } else {
            if (listBenefTallaje) datos = [...listBenefTallaje];
            if (!isFiltered)
                setFiltroBeneficiario(listBenefTallaje);
        }
        if (datos.length>0){
            let cantidad = datos.length;
            let items_0 = datos.map((b, idx) => {
                return (
                {
                    "id": idx + "-" + b.docId,
                    "label": b.apellidos.trim() +' '+ b.nombres.trim()
                }
                );
            })
            let items_1 = datos.map((b, idx) => {
                return (
                {
                    "id": (cantidad+idx+1) + "-" + b.docId,
                    "label": b.docId.trim(),
                }
                );
            })
            let items_2 = datos.map((b, idx) => {
                return (
                    {
                        "id": (cantidad*2+idx+1) + "-" + b.docId,
                        "label": b.alias.trim()
                    }
                );
            })
            items_0 = items_0.filter(itm => (itm.id!==undefined || itm.label!==undefined));
            items_1 = items_1.filter(itm => (itm.id!==undefined || itm.label!==undefined));
            items_2 = items_2.filter(itm => (itm.id!==undefined || itm.label!==undefined));
            const items = [...items_0.concat(items_1, items_2)]; //items_0.filter(itm => itm!==undefined);
            setSearchItems(items);
        }
        
        
    }, [listBenefTallaje, filter, isFiltered]);

    // Search 
    const searchChanged = (e) => {
        setSelectedItem(e)
    }
    const clearSearch = () => {
        setInputValue('');
        setSelectedItem('');
        setIsFiltered(false);
        setFiltroBeneficiario(listBenefTallaje);
    }

    const getBeneficiarioByDocId = (docId) => {
        if (docId && docId!==null && docId!==''){
            setIsFiltered(true);
            setFiltroBeneficiario(listBenefTallaje.filter(b => b.docId === docId));
        } 
    };
    
    const onUserAction = (inputValue, selectedItemVal) => {
        setInputValue(inputValue);
        setSelectedItem(selectedItemVal);
        if (selectedItemVal && selectedItemVal.id){
            const doc = selectedItemVal['id'].split('-')[1];
            getBeneficiarioByDocId(doc)
        }
    }

    const onClickToggleFilter = () => {
        dispatch(displayVestuarioTallajeFilter({isFilterTallajeShow:!isFilterTallajeShow}));
    }
    
    if (listBenefTallaje && listBenefTallaje.length>0)
    return (
        <Fragment>
            {filtroBeneficiario.length<0 && <Loader color="#009937" text="Cargando Tallaje..."/>}
             {selectedProfile && 
                <BeneficiarioDetalleContainer>
                    <TallajeDetails />
                </BeneficiarioDetalleContainer>
            }

            <FiltroButton onClick={onClickToggleFilter}>
                {FiltroIcon}
            </FiltroButton>

            <SearchBeneficiario>
                <Search
                    virtualized
                    virtualizedMenuWidth={250}
                    placeholder='Buscar...' 
                    inputValue={inputValue}
                    selectedItem={selectedItem}
                    items={searchItems}
                    dataSourceConfig={{ label: 'label', value: 'id' }}
                    minimal
                    appendToBody
                    onChange={searchChanged}
                    onUserAction={onUserAction}
                    onRequestClear={clearSearch}
                />
            </SearchBeneficiario>
            
            <Tabs
                onTabChange={onTabChange}
                activeTabIndex={activeTabIndex}
            >
                <TabNav>
                <TabTitle><ListButtonIcon size={16} color={'green'} /></TabTitle>
                </TabNav>
                <TabContents>
                <TabSection>
                    {filtroBeneficiario.length>0 && 
                        <DisplayListBeneficiarios isFiltered={isFiltered} listBenefTallaje={filtroBeneficiario} />
                    }
                </TabSection>
                </TabContents>
            </Tabs>

        </Fragment>
    );
    return (<></>)
}
  
export default ModuleVestuarioTallaje;