import React, {useState, useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";
import ContentEditable from 'react-contenteditable';
import { updateBeneficiarioPredio, serverMensaje } from '../../redux/reducers/beneficiarios';
import {capitalize, highlightAll, disableNewlines, pastePlainText } from '../../utils/utils';
import Alert, {AlertMessage} from 'calcite-react/Alert'
import Accordion, {
    AccordionSection,
    AccordionTitle,
    AccordionContent
} from 'calcite-react/Accordion';
import Select from 'calcite-react/Select';
import {MenuItem} from 'calcite-react/Menu';

import Geocode from "react-geocode";
import Map from '../Map';
import StreetView from '../StreetView';
import {showToaster} from './BenefShowMesg';
import tiposPredio from '../../data/tipospredio.json';
import Departamentos from '../../data/dptos.json';
import Ciudades from '../../data/ciudades.json';
import Barrios from '../../data/barrios.json';

import styled from "styled-components";

const BeneficiarioModulo = styled.div`
    height: auto;
    background: #fff;
    border: 0;
    padding: 0px;
    margin: 4px;
    overflow:hidden;
`;

const AccordionModulo = styled(Accordion)`
    padding:0;
`;
const AccordionTitleModule = styled(AccordionTitle)`
    background-color: #e6e6e6;
    color: #4d4d4d;
    font-size: 1.25em;
    font-weight: 700;
    // border-bottom: 1px solid #d9d9d9;
    // box-shadow: 0px 2px 6px #e1e1e1;
`;

const MapContainer = styled.div`
    height: 100%;
    width: 100%;
    background: #fff;
    border: 0;
    padding: 0px;
    margin: 4px;
    overflow:hidden;
`;

const BenefLocalizacion = ({predio}) => {
    const dispatch = useDispatch();
    const config = useSelector(state => state.config);
    Geocode.setApiKey(config.gmap.key);
    Geocode.setLanguage("es");
    Geocode.setRegion("co");
    const mensaje = useSelector(state => state.beneficiarios.mensaje);
    const users = useSelector(state => state.users);
    // const isEditable = users.user.tipo==='ADMINISTRADOR' ? true : false;
    // const isEditable = (users.user.tipo==='ADMINISTRADOR' && users.user.role==='A' && users.user.permisos==='A') ? true : false;
    const isEditable = (users.user.areatrabajo==='ADMINISTRATIVA' || users.user.areatrabajo==='TRABAJO SOCIAL' || users.user.areatrabajo==='COORDINACIÓN' || users.user.areatrabajo==='PSICOLOGÍA' ) ? true : false;
    // const canView = (users.user.areatrabajo==='ADMINISTRATIVA' || users.user.areatrabajo==='TECNOLOGÍA' ) ? true : false;
    
    const [activeSectionIndexes, setActiveSectionIndexes] = useState([]);
    const [tipopredioSelectedValue, setTipopredioSelectedValue] = useState(capitalize(predio.tipopredio, true));
    const [dptoSelectedValue, setDptoSelectedValue] = useState('Valle del Cauca');
    const [ciudadSelectedValue, setCiudadSelectedValue] = useState(capitalize(predio.ciudad, true));
    const [barrioSelectedValue, setBarrioSelectedValue] = useState(capitalize(predio.barrio, true));
    

    // const [positionChanged, setMapPosition] = useState(false);
    const [homeMapPosition, sethomeMapPosition] = useState({
        lat: parseFloat(predio.lat) || undefined, 
        lng: parseFloat(predio.lng) || undefined,
    });
    // const [homePanoPosition, sethomePanoPosition] = useState({
    const [homePanoPosition] = useState({
        lat: parseFloat(predio.lat) || undefined, 
        lng: parseFloat(predio.lng) || undefined,
    });

    
    // const [latUpdated, setLatUpdated] = useState(false);
    // const [latforPano, setPanoLatitud] = useState(parseFloat(predio.lat) || undefined);
    // const [lngforPano, setPanoLongitud] = useState(parseFloat(predio.lng) || undefined);
    const [headingforPano, setPanoramaHeading] = useState(parseFloat(predio.heading) || undefined);
    const [pitchforPano, setPanoramaPitch] = useState(parseFloat(predio.pitch) || undefined);
    const [zoomforPano, setPanoramaZoom] = useState(parseInt(predio.zoom) || 0);

    // const [mapLoaded, setDireccionChanged] = useState(true);
    // const [mapChanged, setMapChanged] = useState(true);
    const [listCiudades, setListCiudades] = useState(null);
    const [listBarrios, setListBarrios] = useState(null);
    const [error, setError] = useState(null);
    const onAccordionChange = (evt, index) => {
        activeSectionIndexes.includes(index)
        ? setActiveSectionIndexes(activeSectionIndexes.filter(
            item => index !== item,
          ))
        : setActiveSectionIndexes([...activeSectionIndexes, index]);
    }
    const [P] = useState({
        "id": predio.id, //int
        "docId": predio.docId, //15
        "tipopredio": predio.tipopredio || '', //10
        "ciudad": predio.ciudad || '', //32
        "direccion": predio.direccion || '', //90
        "telefono": predio.telefono, //int
        "celular": predio.celular, //int
        "barrio": predio.barrio || '', //32
        "comuna": predio.comuna, //int
        "corregimiento": predio.corregimiento || '', //32
        "vereda": predio.vereda || '', //32
        "descripcion": predio.descripcion || '', //255
        "lat": (predio.lat), //float(10,6)
        "lng": predio.lng, //float(10,6)
        "heading": predio.heading, //float(10,6)
        "pitch": predio.pitch, //float(10,6)
        "zoom": predio.zoom, //int
        "actualizacion": predio.actualizacion, //0000-00-00 00:00:00
        "version": predio.version,
        "estrato": predio.estrato //int
        
    });

    const [itemsPredio, setItemsPredio] = useState([
        // {"key":"id", "label": "Identificador Único", "value": P['id']},
        // {"key":"docId", "label": "Documento de Identificación", "value": P['docId']},
        {"key":"tipopredio", "label": "Tipo de predio", "value": capitalize(P['tipopredio'], true)},
        {"key":"corregimiento", "label": "Corregimiento", "value": capitalize(P['corregimiento'],true)},
        {"key":"vereda", "label": "Vereda", "value": capitalize(P['vereda'],true)},
        {"key":"dpto", "label": "Departamento", "value": dptoSelectedValue},
        {"key":"ciudad", "label": "Ciudad", "value": capitalize(P['ciudad'], true)},
        {"key":"barrio", "label": "Barrio", "value": capitalize(P['barrio'],true)},
        {"key":"estrato", "label": "Estrato", "value": P['estrato']},
        {"key":"comuna", "label": "Comuna", "value": P['comuna']},
        {"key":"direccion", "label": "Dirección", "value": capitalize(P['direccion'], true)},
        {"key":"telefono", "label": "Teléfono fijo:", "value": P['telefono']},
        {"key":"celular", "label": "Celular", "value": P['celular']},
        {"key":"descripcion", "label": "Descripción", "value": P['descripcion']},
        {"key":"lat", "label": "Latitud", "value": P['lat']},
        {"key":"lng", "label": "Longitud", "value": P['lng']},
        {"key":"heading", "label": "Heading", "value": P['heading']},
        {"key":"pitch", "label": "Pitch", "value": P['pitch']},
        {"key":"zoom", "label": "Zoom", "value": P['zoom']},
        // {"key":"actualizacion", "label": "Última actualización", "value": P['actualizacion']},
        // {"key":"version", "label": "Versión", "value": P['version']}
    ]);

    useEffect(() => {
        // Guardar los resultados al salir, puede ser contraproducente al fallar la conexión con el servidor u otro evento no deseado
        // se podrián perder los datos modificafos

        // Update Combobox Ciudades
        if (dptoSelectedValue && listCiudades===null){
            const lc = Ciudades.ciudad.filter(c => c.departamento.toLowerCase() === dptoSelectedValue.toLowerCase() );
            setListCiudades(lc);
        }
        if (ciudadSelectedValue && listBarrios===null){
            const lb = Barrios.barrio.filter(b => b.ciudad.toLowerCase()===ciudadSelectedValue.toLowerCase() );
            setListBarrios(lb);
        }
        if (tipopredioSelectedValue){
            if (tipopredioSelectedValue.toLowerCase()==='rural'){
                document.getElementById('predioItem_corregimiento').style.display = 'flex';
                document.getElementById('predioItem_vereda').style.display = 'flex';
                document.getElementById('predioItem_comuna').style.display = 'none';
            } else {
                document.getElementById('predioItem_corregimiento').style.display = 'none';
                document.getElementById('predioItem_vereda').style.display = 'none';
                document.getElementById('predioItem_comuna').style.display = 'flex';
            }
           
        }
        
        return () => {
            // if (isUpdated){
            //     console.log('Saving appointment', isUpdated);
            // }
            dispatch(serverMensaje({}));
        }
    }, [config.gmap.key, dptoSelectedValue, ciudadSelectedValue, 
        listCiudades, listBarrios, tipopredioSelectedValue, dispatch]);

    useEffect(() => {
       
        if (itemsPredio){
            // console.log(itemsPredio);
        }
        if (mensaje.predio && mensaje.predio!=='') {
            if (typeof mensaje.predio === 'string' && mensaje.predio.toLowerCase().includes('error')){
                showToaster('error', 'Ocurrió un fallo en el servidor', mensaje.predio);
            } 
            // else {
            //     showToaster('success',"Localización del Beneficiario", mensaje.predio); //"🧭 " + mensaje
            // }
        }

    }, [mensaje.predio, itemsPredio, dispatch]);

    const updateItemsPredio = (f, v) => {
        const newitemsPredio = itemsPredio.map((itm, index) => {
            if (itm.key===f){
                itm.value = v;
            }
            return itm;
        });
        setItemsPredio(newitemsPredio);
    };

    const updateDataonServer = () =>{
        dispatch(serverMensaje({predio:''}));
        dispatch(updateBeneficiarioPredio( { token: users.user.claveAPI, predio:P}));
    }

    const replaceSomeText = (s) =>{
        return s
            .replace(/&nbsp;/g, '')
            .replace(/<br>/g, '')
            .replace(/&amp;/g, '&')
            .replace(/&gt;/g, '>')
            .replace(/&lt;/g, '<').trim()
    }

    const validateInput = (f, v, final) => {
        const regex_onlynum = /[0-9,]|\./;
        // const regex_nombres = /#[a-zA-Z\xE1\xE9\xED\xF3\xFA\xC1\xC9\xCD\xD3\xDA\xF1\xD1]+#/i; // /^[a-zA-Z ]+$/;
        const regex_nombres = /^['`´a-zA-ZÀ-ÿ\u00C0-\u017F\s]+$/;
        let new_val = null;
        let err = null;
        switch (f) {
            case 'tipopredio':
                new_val = replaceSomeText(v).substr(0,10); //.toUpperCase();
                if (!regex_nombres.test(new_val)) {
                    err = 'Dígite un tipo de predio válido';
                    new_val = null;
                }
                break;
            case 'corregimiento':
                new_val = v.substr(0,32);
                if (final)
                    new_val = replaceSomeText(v).substr(0,32);
                break;
            case 'vereda':
                new_val = v.substr(0,32);
                if (final)
                    new_val = replaceSomeText(v).substr(0,32);
                break;
            case 'ciudad':
                new_val = v.substr(0,32);
                if (final){
                    new_val = replaceSomeText(v).substr(0,32);
                    if(new_val==='' || new_val.length<3){
                        err = 'Dígite o seleccione una ciudad válida';
                        new_val = null;
                    }
                }
                break;
            case 'barrio':
                new_val = v.substr(0,32);
                if (final){
                    new_val = replaceSomeText(v).substr(0,32);
                    if(new_val==='' || new_val.length<3){
                        err = 'Dígite o seleccione un barrio válido';
                        new_val = null;
                    }
                }
                break;
            case 'estrato':
                // validateNum(evt); // No funcionó por el componente
                new_val = v;
                if (!regex_onlynum.test(v)) {
                    err = 'Dígite solo números de estrato válidos';
                    new_val = null;
                } else if(parseInt(v)>10){
                    err = 'Dígite un número válido';
                    new_val = null;
                }
                break;
            case 'comuna':
                new_val = v;
                if (!regex_onlynum.test(v)) {
                    err = 'Dígite solo números de comuna válidos';
                    new_val = null;
                } else if(parseInt(v)>100){
                    err = 'Dígite un número válido';
                    new_val = null;
                }
                break;
            case 'direccion':
                new_val = v.substr(0,90);
                if (final){
                    new_val = replaceSomeText(v).substr(0,90);
                    if(new_val==='' || new_val.length<4){
                        err = 'Dígite una dirección válida';
                        new_val = null;
                    }
                }

                break;
            case 'telefono':
                new_val = v;
                if (!regex_onlynum.test(v)) {
                    err = 'Dígite solo números válidos de teléfono';
                    new_val = null;
                } else if(v.length>15){
                    err = 'Dígite un número de teléfono fijo válido';
                    new_val = null;
                }
                break;
            case 'celular':
                new_val = v;
                if (!regex_onlynum.test(v)) {
                    err = 'Dígite solo números válidos de celular';
                    new_val = null;
                } else if(v.length>15){
                    err = 'Dígite un número de celular válido';
                    new_val = null;
                }
                break;
            case 'descripcion':
                new_val = v.substr(0,255);
                if (final)
                    new_val = replaceSomeText(v).substr(0,255);
                break;
            case 'lat':
                new_val = v;
                if (!regex_onlynum.test(v)) {
                    err = 'Dígite solo números';
                    new_val = null;
                }
                // if (final){
                //     console.log('Actualizando latitud...', new_val);
                //     // setLatUpdated(true);
                // }
                break;
            case 'lng':
                new_val = v;
                if (!regex_onlynum.test(v)) {
                    err = 'Dígite solo números';
                    new_val = null;
                }
                break;
            case 'heading':
                new_val = v;
                if (!regex_onlynum.test(v)) {
                    err = 'Dígite solo números';
                    new_val = null;
                }
                break;
            case 'pitch':
                new_val = v;
                if (!regex_onlynum.test(v)) {
                    err = 'Dígite solo números';
                    new_val = null;
                }
                break;
            case 'zoom':
                new_val = v;
                if (!regex_onlynum.test(v)) {
                    err = 'Dígite solo números';
                    new_val = null;
                }
                break;
            default:
                break;
        }
        // P[f] = new_val;
        setError(err);
        return new_val
    }
    const handleChange = evt => {
        // console.log(evt.currentTarget.id, evt.target.value);
        const field = evt.currentTarget.id.split('_')[1];
        const new_val = validateInput(field, evt.target.value, false);
        if(new_val){
            P[field] = new_val
        };
    };
    const handleBlur = evt => {
        const val = evt.currentTarget.innerHTML;
        const field = evt.target.id.split('_')[1];
        const new_val = validateInput(field, val, true);
        if(new_val || new_val===''){
            P[field] = new_val
            updateItemsPredio(field, P[field]);
            updateDataonServer();

            // if (field==='lat'){
            //     sethomeMapPosition({lat: parseFloat(new_val), lng: homeMapPosition.lng});
            //     // setLatUpdated(true);
            // } else {
            //     // setLatUpdated(false);
            // }
            
        };
        
    };

    const handleTipopredioSelectChange = (value, item) => {
        P['tipopredio'] = value;
        updateDataonServer();
        setTipopredioSelectedValue(value);
        updateItemsPredio('tipopredio', value);
        if (value.toLowerCase()==='rural'){
            document.getElementById('predioItem_corregimiento').style.display = 'flex';
            document.getElementById('predioItem_vereda').style.display = 'flex';
            document.getElementById('predioItem_comuna').style.display = 'none';
        } else {
            document.getElementById('predioItem_corregimiento').style.display = 'none';
            document.getElementById('predioItem_vereda').style.display = 'none';
            document.getElementById('predioItem_comuna').style.display = 'flex';
        }

    };
    const handleDptoSelectChange = (value, item) => {
        // console.log(value);
        // P['dpto'] = value;
        const lc = Ciudades.ciudad.filter(c => c.departamento.toLowerCase()===value.toLowerCase());
        // console.log(lc);
        setCiudadSelectedValue('');
        setBarrioSelectedValue('');
        setListCiudades(lc);
        setDptoSelectedValue(value);
    };
    const handleCiudadSelectChange = (value, item) => {
        const new_pos = getLatLngfromAdress(value);
        if (new_pos){
            updatePosition(new_pos);
        }
        setCiudadSelectedValue(value);
        P['ciudad'] = value;
        updateDataonServer();
        const lb = Barrios.barrio.filter(b => b.ciudad.toLowerCase()===value.toLowerCase());
        setBarrioSelectedValue('');
        setListBarrios(lb);
        
        // if (new_pos){
        //     updatePosition(new_pos);
        //     P['ciudad'] = value;
        //     updateDataonServer();
        // }
        
    };
    const handleBarrioSelectChange = (value, item) => {
        setBarrioSelectedValue(value);
        P['barrio'] = value;
        updateDataonServer();

    };

    // 
    const getLatLngfromAdress = (address) => {
        Geocode.fromAddress(address).then(
            response => {
                console.log(response.results[0]);
                const { lat, lng } = response.results[0].geometry.location;
                console.log("getLatLngfromAdress",lat, lng);
                return {lat:lat, lng:lng}
            }, error => {
                console.error(error);
            }
        );
    }

    const updatePosition = (pos) => {
        console.log('actualizando.... updatePosition', pos);
        if (pos){
            P['lat'] = pos.lat.toFixed(6);
            P['lng'] = pos.lat.toFixed(6);
            updateItemsPredio('lat', pos.lat.toFixed(6));
            updateItemsPredio('lng', pos.lng.toFixed(6));
            // sethomePanoPosition(pos);
            sethomeMapPosition(pos);
            updateDataonServer();
        } else {
            console.log('Nothing to update');
        }
        
    }
    
    /**
	 * When the marker is dragged you get the lat and long using the functions available from event object.
	 * Use geocode to get the address, city, area and state from the lat and lng positions.
	 * And then set those values in the state.
	 *
	 * @param event
	 */
	const onMarkerDragEnd = ( event ) => {
		let newLat = event.latLng.lat(),
            newLng = event.latLng.lng();
        updatePosition({lat: newLat, lng: newLng});
    };
    
    const onPanoChanged = (pos) => {
        // console.log('Panorama cambió', pos.lat, pos.lng);
        P['lat'] = pos.lat.toFixed(6);
        P['lng'] = pos.lng.toFixed(6);
        updateItemsPredio('lat', pos.lat.toFixed(6));
        updateItemsPredio('lng', pos.lng.toFixed(6));
        // sethomeMapPosition({lat: pos.lat, lng: pos.lng});
    }

    const onPanoPovChange = (pov) => {
        // console.log('pov cambió', pov);
        setPanoramaHeading(pov.heading);
        setPanoramaPitch(pov.pitch);
        setPanoramaZoom(pov.zoom);

        P['heading'] = String(pov.heading);
        P['pitch'] = String(pov.pitch);
        P['zoom'] = String(pov.zoom);

        updateItemsPredio('heading', String(pov.heading));
        updateItemsPredio('pitch', String(pov.pitch));
        updateItemsPredio('zoom', String(pov.zoom));
    }
    
    // STATIC MAP
    // const map_url = config.gmap.URLStatic + 'center='+ P.lat +','+ P.lng +
    // '&markers='+ config.gmap.markers + P.lat +','+ P.lng +
    // '&zoom='+P.zoom+
    // '&size='+config.gmap.size+
    // '&maptype='+maptype+
    // '&key='+ config.gmap.key;

    return (
        <BeneficiarioModulo>
            <AccordionModulo
                onAccordionChange={onAccordionChange}
                activeSectionIndexes={activeSectionIndexes}
            >
                <AccordionSection>
                    <AccordionTitleModule>Localización</AccordionTitleModule>
                    <AccordionContent style={{marginBottom: '10px'}}>
                    {error && <div style={{color: 'red', width: '100%'}}>
                            <Alert red showIcon style={{minWidth: '100%', marginBottom: '10px'}}
                                showCloseLabel 
                                onClose={() => setError(null)}>
                                <AlertMessage>
                                    Error:{' '}
                                    <strong>{error}</strong>
                                </AlertMessage>
                            </Alert>
                        </div> 
                    }
                    {/* ITEMS */}
                        {
                            itemsPredio.map((itm, index) => {
                                // if (itm.value!==null && itm.value!=="" ){
                                    return (<div id={'predioItem_'+itm.key} key={index} className="itemContainer">
                                        <div className="itemContainer-attr" 
                                            style={{backgroundColor:(index % 2 === 0) ? '#f8f8f8': '#fff'}}
                                        >{itm.label}</div>
                                        <div className="itemContainer-value" 
                                            style={{backgroundColor:(index % 2 === 0) ? '#f8f8f8': '#fff'}}
                                        >

                                        { itm.key==='tipopredio' 
                                            ?
                                                <Select positionFixed disabled={!isEditable} 
                                                    onChange={handleTipopredioSelectChange}
                                                    selectedValue={tipopredioSelectedValue.trim()}
                                                >
                                                {tiposPredio.tipo.map(tp => {
                                                    return (
                                                    <MenuItem key={tp.id} value={tp.name}>
                                                        {tp.name}
                                                    </MenuItem>
                                                    )
                                                })}
                                                </Select>
                                            :
                                            itm.key==='dpto' 
                                            ?
                                                <Select filterable positionFixed disabled={!isEditable} 
                                                    onChange={handleDptoSelectChange}
                                                    selectedValue={dptoSelectedValue.trim()}
                                                >
                                                {Departamentos.dpto.map(d => {
                                                    return (
                                                    <MenuItem key={d.codDpto} value={d.departamento}>
                                                        {d.departamento}
                                                    </MenuItem>
                                                    )
                                                })}
                                                </Select>
                                            :
                                            itm.key==='ciudad' 
                                            ?
                                            listCiudades && listCiudades.length>0 ?
                                                <Select filterable positionFixed virtualized
                                                    disabled={!isEditable} 
                                                    onChange={handleCiudadSelectChange}
                                                    selectedValue={ciudadSelectedValue.trim() || ''}
                                                >
                                                {listCiudades.map(c => {
                                                            return (
                                                        <MenuItem key={c.id} value={c.municipio}>
                                                            {c.municipio}
                                                        </MenuItem>
                                                        )
                                                    
                                                })}
                                                </Select>
                                                :
                                                <ContentEditable
                                                    id={'predio_'+itm.key}
                                                    html={itm.value || ''} // innerHTML of the editable div
                                                    className='itemContainer-value-long'
                                                    disabled={!isEditable}      // use true to disable editing
                                                    onPaste={pastePlainText} // En caso se copie y pegue de otra pagina de internet
                                                    onChange={handleChange} // handle innerHTML change
                                                    onKeyPress={disableNewlines}  
                                                    onFocus={highlightAll}
                                                    onBlur={handleBlur}
                                                    tagName='div' // Use a custom HTML tag (uses a div by default)
                                                />
                                            :
                                            itm.key==='barrio' 
                                            ?
                                            listBarrios && listBarrios.length>0 ? 
                                                <Select filterable positionFixed virtualized
                                                    disabled={!isEditable} 
                                                    onChange={handleBarrioSelectChange}
                                                    selectedValue={barrioSelectedValue.trim()}
                                                >
                                                {listBarrios.map(b => {
                                                        return (
                                                        <MenuItem key={b.id} value={b.barrio}>
                                                            {b.barrio}
                                                        </MenuItem>
                                                        )
                                                })}
                                                </Select>
                                                :
                                                    <ContentEditable
                                                        id={'predio_'+itm.key}
                                                        html={itm.value || ''} // innerHTML of the editable div
                                                        className='itemContainer-value-long'
                                                        disabled={!isEditable}      // use true to disable editing
                                                        onPaste={pastePlainText} // En caso se copie y pegue de otra pagina de internet
                                                        onChange={handleChange} // handle innerHTML change
                                                        onKeyPress={disableNewlines}  
                                                        onFocus={highlightAll}
                                                        onBlur={handleBlur}
                                                        tagName='div' // Use a custom HTML tag (uses a div by default)
                                                    />
                                            :
                                            <ContentEditable
                                                id={'predio_'+itm.key}
                                                // innerRef={agendoEditable}
                                                html={itm.value || ''} // innerHTML of the editable div
                                                className='itemContainer-value-long'
                                                disabled={!isEditable}      // use true to disable editing
                                                onPaste={pastePlainText} // En caso se copie y pegue de otra pagina de internet
                                                onChange={handleChange} // handle innerHTML change
                                                onKeyPress={disableNewlines}  
                                                onFocus={highlightAll}
                                                onBlur={handleBlur}
                                                tagName='div' // Use a custom HTML tag (uses a div by default)
                                            />
                                        }

                                        </div>
                                    </div>)
                                // } 
                            })
                        }

                        {/* {map_position && <BenefMapa position={map_position} />} */}

                        {activeSectionIndexes.length>0 && <MapContainer>
                            {/* <img src={map_url} alt="Mapa" /> */}

                            <Map configMap={config.gmap} isMarkerShown
                                tipoPredio={P.tipopredio}
                                ciudad={P.ciudad}
                                direccion={P.direccion}
                                mapTypeId={P.tipopredio.toLowerCase()==='urbano' ? 'roadmap' : 'satellite'}
                                zoom={P.tipopredio.toLowerCase()==='urbano' ? 17 : 15}
                                markerPosition={homeMapPosition } //homeMapPosition {lat:parseFloat(P.lat), lng:parseFloat(P.lng)} 
                                onMarkerDragEnd= {onMarkerDragEnd}
                            />

                            <StreetView 
                                configMap={config.gmap}
                                visible={true}
                                zoom={P.tipopredio.toLowerCase()==='urbano' ? 17 : 13}
                                center={ homePanoPosition }
                                pov={{
                                    heading: headingforPano, 
                                    pitch: pitchforPano, 
                                    zoom: zoomforPano || 0
                                }}
                                onPanoramaChange = {onPanoChanged}
                                onPanoramaPovChange = {onPanoPovChange}
                            />
                        </MapContainer>}
                        <div className="benef-texto-actualizacion">Última actualización: {P['actualizacion']}</div>
                    
                    </AccordionContent>
                </AccordionSection>
            </AccordionModulo>
        </BeneficiarioModulo>
    );
}

export default BenefLocalizacion;

