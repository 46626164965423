// React
import React, {useState}from "react";

// Redux imports
import { useSelector, useDispatch } from "react-redux";
import { setModulo } from "../../redux/reducers/modulos";

// Styled Components
import styled from "styled-components";
import GearIcon from 'calcite-ui-icons-react/GearIcon';
import viviendaCompra_Icon from '../../assets/icons/vivienda_compra.png';
import mejoramiento_Icon from '../../assets/icons/vivienda_mejoramiento.png';

import ActionBar, {
  Action,
  ActionGroup,
  BottomActionGroup
} from 'calcite-react/ActionBar';



const CompraIcon = styled.div`
  width: 24px;
  height: 24px;
  background: rgba(255, 255, 255, 0.1) url(${viviendaCompra_Icon}) no-repeat center/cover;
  
`;

const MejoramientoIcon = styled.div`
  width: 24px;
  height: 24px;
  background: rgba(255, 255, 255, 0.1) url(${mejoramiento_Icon}) no-repeat center/cover;
  
`;

const PanelLeftVivienda = props => {
    // const isCollapsed = useSelector(state => state.modulos.collapsed);
    const [isCollapsed, setIsCollapsed] = useState(false);
    const activeAction = useSelector(state => state.modulos.activeModule);
    const dispatch = useDispatch();

    const proyectosNames = {
      "":'Vivienda',
      "COMPRA": "Compra de vivienda",
      "MEJORAMIENTO": "Mejoramiento de vivienda",
      "CONFIGURACION": "Configuración del GRB", 
    };

    const onToggleCollapse = (e) => {
      // dispatch(setModulo({collapsed:!isCollapsed}));
      setIsCollapsed(!isCollapsed);
    };

    const turnOffModules = () => {
      // dispatch(moduleUSERSLoaded({loaded:false})); // Hide Users
      // dispatch(moduleBENEFICIARIOSLoaded({loaded:false}));
    }

    const actionSelected = (activeAction) => {
      dispatch(setModulo({activeModule:activeAction, collapsed:isCollapsed, headerTitle:proyectosNames[activeAction]}));
      turnOffModules();
      switch (activeAction) {
        case 'COMPRA':
          console.log('COMPRA');
          // dispatch(moduleBENEFICIARIOSLoaded({loaded:true}));
          break;
        case 'MEJORAMIENTO':
          console.log('MEJORAMIENTO');
          break;
        default:
          break;
      }
    };

    return (
      <ActionBar
        collapseLabel='Ocultar' 
        expandLabel='Expandir' 
        collapsed={isCollapsed}
        onToggleCollapse={onToggleCollapse}
      >
        <ActionGroup>
        <Action
          onClick={() => actionSelected('COMPRA')}
          active={activeAction === 'COMPRA'}
          icon={<CompraIcon/>}
        >
          Compra
        </Action>
          <Action
            onClick={() => actionSelected('MEJORAMIENTO')}
            active={activeAction === 'MEJORAMIENTO'}
            icon={<MejoramientoIcon />}
          >
            Mejoramiento
          </Action>
        </ActionGroup>
        <BottomActionGroup>
          <Action icon={<GearIcon />}>Configuración</Action>
        </BottomActionGroup>
      </ActionBar>
    )

}

export default PanelLeftVivienda;