// Copyright 2020 Ernesto Girón E.
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//     http://www.apache.org/licenses/LICENSE-2.0
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.​

import { call, put, takeLatest } from "redux-saga/effects";
import { types as configTypes } from "../reducers/beneficiarios";
import { getBeneficiariosData, 
  getBeneficiarioPerfilData, 
  actualizarBeneficiarioDatosBasicos,
  actualizarBeneficiarioPerfil,
  actualizarBeneficiarioCita,
  actualizarBeneficiarioPredio,
  actualizarBeneficiarioEncuestado,
  actualizarBeneficiarioIntegrantesHogar,
  crearBeneficiarioIntegrantesHogar,
  eliminarBeneficiarioIntegrantesHogar,
  actualizarBeneficiarioVivienda,
  actualizarBeneficiarioAnimales,
  crearBeneficiarioAnimales,
  eliminarBeneficiarioAnimales,
  actualizarBeneficiarioInfoEconomica,
  actualizarBeneficiarioAportantes,
  crearBeneficiarioAportantes,
  eliminarBeneficiarioAportantes,
  actualizarBeneficiarioAhorradores,
  crearBeneficiarioAhorradores,
  eliminarBeneficiarioAhorradores,
  actualizarBeneficiarioSalud,
  actualizarBeneficiarioEducacion,
  actualizarBeneficiarioEducacionenHogar,
  crearBeneficiarioEducacionenHogar,
  eliminarBeneficiarioEducacionenHogar,
  actualizarBeneficiarioCapacitacion,
  crearBeneficiarioCapacitacion,
  eliminarBeneficiarioCapacitacion,
  actualizarBeneficiarioHabilidades,
  crearBeneficiarioHabilidades,
  eliminarBeneficiarioHabilidades,
  actualizarBeneficiarioRecreacion,
  actualizarBeneficiarioOrgSocial,
  actualizarBeneficiarioObs,
  eliminarBeneficiarioVestuarioTallaje,
  crearBeneficiarioVestuarioTallaje,
  actualizarBeneficiarioVestuarioTallaje
} from "../../utils/benef_req";

// WORKERS //
function* fetchBeneficiariosData(action) {
  try {
    // fetch listBeneficiarios
    const listBenef = yield call(getBeneficiariosData, action.payload.token);
    // load users into Redux store
    yield put({
      type: configTypes.BENEFICIARIOS_LIST_LOADED,
      payload: {listBenef: listBenef}
    });
  } catch (e) {
    console.error("SAGA ERROR: users_req/fetchBeneficiariosData, ", e);
  }
}


function* fetchBeneficiarioPerfilData(action) {
  try {
    // fetch datos del Beneficiario seleccionado
    const datosSelBenef = yield call(getBeneficiarioPerfilData, action.payload);
    // load users into Redux store
    if (datosSelBenef)
      yield put({
        type: configTypes.BENEFICIARIO_DATA_SELECTED,
        payload: {datosSelBenef: datosSelBenef, selectedProfile:{docId:datosSelBenef.beneficiarios[0].docId}}
      });
  } catch (e) {
    console.error("SAGA ERROR: benef_req/fetchBeneficiarioPerfilData, ", e);
  }
}

function* updateBeneficiarioBasicos(action) {
  try {
    const resp = yield call(actualizarBeneficiarioDatosBasicos, action.payload);
    if (resp && resp!=='')
      yield put({
        type: configTypes.BENEFICIARIO_SERVER_MESSAGE,
        payload: {mensaje: {"basicos": resp}}
      });
  } catch (e) {
    console.error("SAGA ERROR: benef_req/updateBeneficiarioBasicos, ", e);
  }
}

function* updateBeneficiarioPerfil(action) {
  try {
    const resp = yield call(actualizarBeneficiarioPerfil, action.payload);
    if (resp && resp!=='')
      yield put({
        type: configTypes.BENEFICIARIO_SERVER_MESSAGE,
        payload: {mensaje: {"perfil": resp}}
      });
  } catch (e) {
    console.error("SAGA ERROR: benef_req/updateBeneficiarioPerfil, ", e);
  }
}

function* updateBeneficiarioCita(action) {
  try {
    const resp = yield call(actualizarBeneficiarioCita, action.payload);
    if (resp && resp!=='')
      yield put({
        type: configTypes.BENEFICIARIO_SERVER_MESSAGE,
        payload: {mensaje: {"cita": resp}}
      });
  } catch (e) {
    console.error("SAGA ERROR: benef_req/updateBeneficiarioCita, ", e);
  }
}

function* updateBeneficiarioObs(action) {
  try {
    const resp = yield call(actualizarBeneficiarioObs, action.payload);
    if (resp && resp!=='')
      yield put({
        type: configTypes.BENEFICIARIO_SERVER_MESSAGE,
        payload: {mensaje: {"observaciones": resp}}
      });
  } catch (e) {
    console.error("SAGA ERROR: benef_req/updateBeneficiarioObs, ", e);
  }
}

function* updateBeneficiarioPredio(action) {
  try {
    const resp = yield call(actualizarBeneficiarioPredio, action.payload);
    if (resp && resp!=='')
      yield put({
        type: configTypes.BENEFICIARIO_SERVER_MESSAGE,
        payload: {mensaje: {"predio": resp}}
      });
  } catch (e) {
    console.error("SAGA ERROR: benef_req/updateBeneficiarioPredio, ", e);
  }
}


function* updateBeneficiarioEncuestado(action) {
  try {
    const resp = yield call(actualizarBeneficiarioEncuestado, action.payload);
    if (resp && resp!=='')
      yield put({
        type: configTypes.BENEFICIARIO_SERVER_MESSAGE,
        payload: {mensaje: {"encuestado": resp}}
      });
  } catch (e) {
    console.error("SAGA ERROR: benef_req/updateBeneficiarioEncuestado, ", e);
  }
}


function* updateBeneficiarioVivienda(action) {
  try {
    const resp = yield call(actualizarBeneficiarioVivienda, action.payload);
    if (resp && resp!=='')
      yield put({
        type: configTypes.BENEFICIARIO_SERVER_MESSAGE,
        payload: {mensaje: {"vivienda": resp}}
      });
  } catch (e) {
    console.error("SAGA ERROR: benef_req/updateBeneficiarioVivienda, ", e);
  }
}

function* updateBeneficiarioInfoEconomica(action) {
  try {
    const resp = yield call(actualizarBeneficiarioInfoEconomica, action.payload);
    if (resp && resp!=='')
      yield put({
        type: configTypes.BENEFICIARIO_SERVER_MESSAGE,
        payload: {mensaje: {"infoeconomica": resp}}
      });
  } catch (e) {
    console.error("SAGA ERROR: benef_req/updateBeneficiarioInfoEconomica, ", e);
  }
}

function* updateBeneficiarioSalud(action) {
  try {
    const resp = yield call(actualizarBeneficiarioSalud, action.payload);
    if (resp && resp!=='')
      yield put({
        type: configTypes.BENEFICIARIO_SERVER_MESSAGE,
        payload: {mensaje: {"salud": resp}}
      });
  } catch (e) {
    console.error("SAGA ERROR: benef_req/updateBeneficiarioSalud, ", e);
  }
}


function* updateBeneficiarioEducacion(action) {
  try {
    const resp = yield call(actualizarBeneficiarioEducacion, action.payload);
    if (resp && resp!=='')
      yield put({
        type: configTypes.BENEFICIARIO_SERVER_MESSAGE,
        payload: {mensaje: {"educacion": resp}}
      });
  } catch (e) {
    console.error("SAGA ERROR: benef_req/updateBeneficiarioEducacion, ", e);
  }
}

function* updateBeneficiarioRecreacion(action) {
  try {
    const resp = yield call(actualizarBeneficiarioRecreacion, action.payload);
    if (resp && resp!=='')
      yield put({
        type: configTypes.BENEFICIARIO_SERVER_MESSAGE,
        payload: {mensaje: {"recreacion": resp}}
      });
  } catch (e) {
    console.error("SAGA ERROR: benef_req/updateBeneficiarioRecreacion, ", e);
  }
}

function* updateBeneficiarioOrgSocial(action) {
  try {
    const resp = yield call(actualizarBeneficiarioOrgSocial, action.payload);
    if (resp && resp!=='')
      yield put({
        type: configTypes.BENEFICIARIO_SERVER_MESSAGE,
        payload: {mensaje: {"orgsocial": resp}}
      });
  } catch (e) {
    console.error("SAGA ERROR: benef_req/updateBeneficiarioOrgSocial, ", e);
  }
}


function* updateBeneficiarioAhorradores(action) {
  try {
    const resp = yield call(actualizarBeneficiarioAhorradores, action.payload);
    if (resp && resp!=='')
      yield put({
        type: configTypes.BENEFICIARIO_SERVER_MESSAGE,
        payload: {mensaje: {"ahorradores": resp}}
      });
  } catch (e) {
    console.error("SAGA ERROR: benef_req/updateBeneficiarioAhorradores, ", e);
  }
}
function* insertBeneficiarioAhorradores(action) {
  try {
    const resp = yield call(crearBeneficiarioAhorradores, action.payload);
    if (resp && resp!=='')
      yield put({
        type: configTypes.BENEFICIARIO_SERVER_MESSAGE,
        payload: {mensaje: {"ahorradores": resp}}
      });
  } catch (e) {
    console.error("SAGA ERROR: benef_req/insertBeneficiarioAhorradores, ", e);
  }
}
function* deleteBeneficiarioAhorradores(action) {
  try {
    const resp = yield call(eliminarBeneficiarioAhorradores, action.payload);
    if (resp && resp!=='')
      yield put({
        type: configTypes.BENEFICIARIO_SERVER_MESSAGE,
        payload: {mensaje: {"ahorradores": resp}}
      });
  } catch (e) {
    console.error("SAGA ERROR: benef_req/deleteBeneficiarioAhorradores, ", e);
  }
}

function* updateBeneficiarioAportantes(action) {
  try {
    const resp = yield call(actualizarBeneficiarioAportantes, action.payload);
    if (resp && resp!=='')
      yield put({
        type: configTypes.BENEFICIARIO_SERVER_MESSAGE,
        payload: {mensaje: {"aportantes": resp}}
      });
  } catch (e) {
    console.error("SAGA ERROR: benef_req/updateBeneficiarioAportantes, ", e);
  }
}
function* insertBeneficiarioAportantes(action) {
  try {
    const resp = yield call(crearBeneficiarioAportantes, action.payload);
    if (resp && resp!=='')
      yield put({
        type: configTypes.BENEFICIARIO_SERVER_MESSAGE,
        payload: {mensaje: {"aportantes": resp}}
      });
  } catch (e) {
    console.error("SAGA ERROR: benef_req/insertBeneficiarioAportantes, ", e);
  }
}
function* deleteBeneficiarioAportantes(action) {
  try {
    const resp = yield call(eliminarBeneficiarioAportantes, action.payload);
    if (resp && resp!=='')
      yield put({
        type: configTypes.BENEFICIARIO_SERVER_MESSAGE,
        payload: {mensaje: {"aportantes": resp}}
      });
  } catch (e) {
    console.error("SAGA ERROR: benef_req/deleteBeneficiarioAportantes, ", e);
  }
}

function* updateBeneficiarioAnimales(action) {
  try {
    const resp = yield call(actualizarBeneficiarioAnimales, action.payload);
    if (resp && resp!=='')
      yield put({
        type: configTypes.BENEFICIARIO_SERVER_MESSAGE,
        payload: {mensaje: {"animales": resp}}
      });
  } catch (e) {
    console.error("SAGA ERROR: benef_req/updateBeneficiarioAnimales, ", e);
  }
}

function* insertBeneficiarioAnimales(action) {
  try {
    const resp = yield call(crearBeneficiarioAnimales, action.payload);
    if (resp && resp!=='')
      yield put({
        type: configTypes.BENEFICIARIO_SERVER_MESSAGE,
        payload: {mensaje: {"animales": resp}}
      });
  } catch (e) {
    console.error("SAGA ERROR: benef_req/insertBeneficiarioAnimales, ", e);
  }
}

function* deleteBeneficiarioAnimales(action) {
  try {
    const resp = yield call(eliminarBeneficiarioAnimales, action.payload);
    if (resp && resp!=='')
      yield put({
        type: configTypes.BENEFICIARIO_SERVER_MESSAGE,
        payload: {mensaje: {"animales": resp}}
      });
  } catch (e) {
    console.error("SAGA ERROR: benef_req/deleteBeneficiarioAnimales, ", e);
  }
}

function* updateBeneficiarioIntegrantesHogar(action) {
  try {
    const resp = yield call(actualizarBeneficiarioIntegrantesHogar, action.payload);
    if (resp && resp!=='')
      yield put({
        type: configTypes.BENEFICIARIO_SERVER_MESSAGE,
        payload: {mensaje: {"integranteshogar": resp}}
      });
  } catch (e) {
    console.error("SAGA ERROR: benef_req/updateBeneficiarioIntegrantesHogar, ", e);
  }
}

function* insertBeneficiarioIntegrantesHogar(action) {
  try {
    const resp = yield call(crearBeneficiarioIntegrantesHogar, action.payload);
    if (resp && resp!=='')
      yield put({
        type: configTypes.BENEFICIARIO_SERVER_MESSAGE,
        payload: {mensaje: {"integranteshogar": resp}}
      });
  } catch (e) {
    console.error("SAGA ERROR: benef_req/insertBeneficiarioIntegrantesHogar, ", e);
  }
}

function* deleteBeneficiarioIntegrantesHogar(action) {
  try {
    const resp = yield call(eliminarBeneficiarioIntegrantesHogar, action.payload);
    if (resp && resp!=='')
      yield put({
        type: configTypes.BENEFICIARIO_SERVER_MESSAGE,
        payload: {mensaje: {"integranteshogar": resp}}
      });
  } catch (e) {
    console.error("SAGA ERROR: benef_req/deleteBeneficiarioIntegrantesHogar, ", e);
  }
}

function* updateBeneficiarioEducacionenHogar(action) {
  try {
    const resp = yield call(actualizarBeneficiarioEducacionenHogar, action.payload);
    if (resp && resp!=='')
      yield put({
        type: configTypes.BENEFICIARIO_SERVER_MESSAGE,
        payload: {mensaje: {"educacionenhogar": resp}}
      });
  } catch (e) {
    console.error("SAGA ERROR: benef_req/updateBeneficiarioEducacionenHogar, ", e);
  }
}
function* insertBeneficiarioEducacionenHogar(action) {
  try {
    const resp = yield call(crearBeneficiarioEducacionenHogar, action.payload);
    if (resp && resp!=='')
      yield put({
        type: configTypes.BENEFICIARIO_SERVER_MESSAGE,
        payload: {mensaje: {"educacionenhogar": resp}}
      });
  } catch (e) {
    console.error("SAGA ERROR: benef_req/insertBeneficiarioEducacionenHogar, ", e);
  }
}
function* deleteBeneficiarioEducacionenHogar(action) {
  try {
    const resp = yield call(eliminarBeneficiarioEducacionenHogar, action.payload);
    if (resp && resp!=='')
      yield put({
        type: configTypes.BENEFICIARIO_SERVER_MESSAGE,
        payload: {mensaje: {"educacionenhogar": resp}}
      });
  } catch (e) {
    console.error("SAGA ERROR: benef_req/deleteBeneficiarioEducacionenHogar, ", e);
  }
}



function* updateBeneficiarioCapacitacion(action) {
  try {
    const resp = yield call(actualizarBeneficiarioCapacitacion, action.payload);
    if (resp && resp!=='')
      yield put({
        type: configTypes.BENEFICIARIO_SERVER_MESSAGE,
        payload: {mensaje: {"capacitacion": resp}}
      });
  } catch (e) {
    console.error("SAGA ERROR: benef_req/updateBeneficiarioCapacitacion, ", e);
  }
}
function* insertBeneficiarioCapacitacion(action) {
  try {
    const resp = yield call(crearBeneficiarioCapacitacion, action.payload);
    if (resp && resp!=='')
      yield put({
        type: configTypes.BENEFICIARIO_SERVER_MESSAGE,
        payload: {mensaje: {"capacitacion": resp}}
      });
  } catch (e) {
    console.error("SAGA ERROR: benef_req/insertBeneficiarioCapacitacion, ", e);
  }
}
function* deleteBeneficiarioCapacitacion(action) {
  try {
    const resp = yield call(eliminarBeneficiarioCapacitacion, action.payload);
    if (resp && resp!=='')
      yield put({
        type: configTypes.BENEFICIARIO_SERVER_MESSAGE,
        payload: {mensaje: {"capacitacion": resp}}
      });
  } catch (e) {
    console.error("SAGA ERROR: benef_req/deleteBeneficiarioCapacitacion, ", e);
  }
}

function* updateBeneficiarioHabilidades(action) {
  try {
    const resp = yield call(actualizarBeneficiarioHabilidades, action.payload);
    if (resp && resp!=='')
      yield put({
        type: configTypes.BENEFICIARIO_SERVER_MESSAGE,
        payload: {mensaje: {"habilidades": resp}}
      });
  } catch (e) {
    console.error("SAGA ERROR: benef_req/updateBeneficiarioHabilidades, ", e);
  }
}
function* insertBeneficiarioHabilidades(action) {
  try {
    const resp = yield call(crearBeneficiarioHabilidades, action.payload);
    if (resp && resp!=='')
      yield put({
        type: configTypes.BENEFICIARIO_SERVER_MESSAGE,
        payload: {mensaje: {"habilidades": resp}}
      });
  } catch (e) {
    console.error("SAGA ERROR: benef_req/insertBeneficiarioHabilidades, ", e);
  }
}
function* deleteBeneficiarioHabilidades(action) {
  try {
    const resp = yield call(eliminarBeneficiarioHabilidades, action.payload);
    if (resp && resp!=='')
      yield put({
        type: configTypes.BENEFICIARIO_SERVER_MESSAGE,
        payload: {mensaje: {"habilidades": resp}}
      });
  } catch (e) {
    console.error("SAGA ERROR: benef_req/deleteBeneficiarioHabilidades, ", e);
  }
}

// Tallaje
function* updateBeneficiarioVestuarioTallaje(action) {
  try {
    const resp = yield call(actualizarBeneficiarioVestuarioTallaje, action.payload);
    if (resp && resp!=='')
      yield put({
        type: configTypes.BENEFICIARIO_SERVER_MESSAGE,
        payload: {mensaje: {"tallaje": resp}}
      });
  } catch (e) {
    console.error("SAGA ERROR: benef_req/updateBeneficiarioVestuarioTallaje, ", e);
  }
}
function* insertBeneficiarioVestuarioTallaje(action) {
  try {
    const resp = yield call(crearBeneficiarioVestuarioTallaje, action.payload);
    if (resp && resp!=='')
      yield put({
        type: configTypes.BENEFICIARIO_SERVER_MESSAGE,
        payload: {mensaje: {"tallaje": resp}}
      });
  } catch (e) {
    console.error("SAGA ERROR: benef_req/insertBeneficiarioVestuarioTallaje, ", e);
  }
}
function* deleteBeneficiarioVestuarioTallaje(action) {
  try {
    const resp = yield call(eliminarBeneficiarioVestuarioTallaje, action.payload);
    if (resp && resp!=='')
      yield put({
        type: configTypes.BENEFICIARIO_SERVER_MESSAGE,
        payload: {mensaje: {"tallaje": resp}}
      });
  } catch (e) {
    console.error("SAGA ERROR: benef_req/deleteBeneficiarioVestuarioTallaje, ", e);
  }
}


// WATCHER //
export function* watchFetchBeneficiariosData() {
  yield takeLatest(configTypes.BENEFICIARIOS_DATA_LOADED, fetchBeneficiariosData);
  yield takeLatest(configTypes.BENEFICIARIO_SELECTED_DATA_REQUEST, fetchBeneficiarioPerfilData);
  yield takeLatest(configTypes.BENEFICIARIO_SELECTED_PERFIL_UPDATED, updateBeneficiarioPerfil);
  yield takeLatest(configTypes.BENEFICIARIO_SELECTED_BASICOS_UPDATED, updateBeneficiarioBasicos);
  yield takeLatest(configTypes.BENEFICIARIO_SELECTED_CITA_UPDATED, updateBeneficiarioCita);
  yield takeLatest(configTypes.BENEFICIARIO_SELECTED_PREDIO_UPDATED, updateBeneficiarioPredio);
  yield takeLatest(configTypes.BENEFICIARIO_SELECTED_ENCUESTADO_UPDATED, updateBeneficiarioEncuestado);
  yield takeLatest(configTypes.BENEFICIARIO_SELECTED_INTEGRANTESHOGAR_UPDATED, updateBeneficiarioIntegrantesHogar);
  yield takeLatest(configTypes.BENEFICIARIO_SELECTED_INTEGRANTESHOGAR_CREATED, insertBeneficiarioIntegrantesHogar);
  yield takeLatest(configTypes.BENEFICIARIO_SELECTED_INTEGRANTESHOGAR_DELETED, deleteBeneficiarioIntegrantesHogar);
  yield takeLatest(configTypes.BENEFICIARIO_SELECTED_VIVIENDA_UPDATED, updateBeneficiarioVivienda);
  yield takeLatest(configTypes.BENEFICIARIO_SELECTED_ANIMALES_UPDATED, updateBeneficiarioAnimales);
  yield takeLatest(configTypes.BENEFICIARIO_SELECTED_ANIMALES_CREATED, insertBeneficiarioAnimales);
  yield takeLatest(configTypes.BENEFICIARIO_SELECTED_ANIMALES_DELETED, deleteBeneficiarioAnimales);
  yield takeLatest(configTypes.BENEFICIARIO_SELECTED_INFOECONOMICA_UPDATED, updateBeneficiarioInfoEconomica);
  yield takeLatest(configTypes.BENEFICIARIO_SELECTED_AHORRADORES_UPDATED, updateBeneficiarioAhorradores);
  yield takeLatest(configTypes.BENEFICIARIO_SELECTED_AHORRADORES_CREATED, insertBeneficiarioAhorradores);
  yield takeLatest(configTypes.BENEFICIARIO_SELECTED_AHORRADORES_DELETED, deleteBeneficiarioAhorradores);
  yield takeLatest(configTypes.BENEFICIARIO_SELECTED_APORTANTES_UPDATED, updateBeneficiarioAportantes);
  yield takeLatest(configTypes.BENEFICIARIO_SELECTED_APORTANTES_CREATED, insertBeneficiarioAportantes);
  yield takeLatest(configTypes.BENEFICIARIO_SELECTED_APORTANTES_DELETED, deleteBeneficiarioAportantes);
  yield takeLatest(configTypes.BENEFICIARIO_SELECTED_SALUD_UPDATED, updateBeneficiarioSalud);
  yield takeLatest(configTypes.BENEFICIARIO_SELECTED_EDUCACION_UPDATED, updateBeneficiarioEducacion);
  yield takeLatest(configTypes.BENEFICIARIO_SELECTED_EDUCACIONENHOGAR_UPDATED, updateBeneficiarioEducacionenHogar);
  yield takeLatest(configTypes.BENEFICIARIO_SELECTED_EDUCACIONENHOGAR_CREATED, insertBeneficiarioEducacionenHogar);
  yield takeLatest(configTypes.BENEFICIARIO_SELECTED_EDUCACIONENHOGAR_DELETED, deleteBeneficiarioEducacionenHogar);
  yield takeLatest(configTypes.BENEFICIARIO_SELECTED_CAPACITACION_UPDATED, updateBeneficiarioCapacitacion);
  yield takeLatest(configTypes.BENEFICIARIO_SELECTED_CAPACITACION_CREATED, insertBeneficiarioCapacitacion);
  yield takeLatest(configTypes.BENEFICIARIO_SELECTED_CAPACITACION_DELETED, deleteBeneficiarioCapacitacion);
  yield takeLatest(configTypes.BENEFICIARIO_SELECTED_HABILIDADES_UPDATED, updateBeneficiarioHabilidades);
  yield takeLatest(configTypes.BENEFICIARIO_SELECTED_HABILIDADES_CREATED, insertBeneficiarioHabilidades);
  yield takeLatest(configTypes.BENEFICIARIO_SELECTED_HABILIDADES_DELETED, deleteBeneficiarioHabilidades);
  yield takeLatest(configTypes.BENEFICIARIO_SELECTED_RECREACION_UPDATED, updateBeneficiarioRecreacion);
  yield takeLatest(configTypes.BENEFICIARIO_SELECTED_ORGSOCIAL_UPDATED, updateBeneficiarioOrgSocial);
  yield takeLatest(configTypes.BENEFICIARIO_SELECTED_OBS_UPDATED, updateBeneficiarioObs);
  
  yield takeLatest(configTypes.BENEFICIARIO_SELECTED_VESTUARIOTALLAJE_UPDATED, updateBeneficiarioVestuarioTallaje);
  yield takeLatest(configTypes.BENEFICIARIO_SELECTED_VESTUARIOTALLAJE_CREATED, insertBeneficiarioVestuarioTallaje);
  yield takeLatest(configTypes.BENEFICIARIO_SELECTED_VESTUARIOTALLAJE_DELETED, deleteBeneficiarioVestuarioTallaje);
  
  
  

}
