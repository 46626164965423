// Copyright 2020 Ernesto Giron E.
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//     http://www.apache.org/licenses/LICENSE-2.0
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.​

// React imports
import React from "react";
import { Redirect} from "react-router-dom";
// Redux imports
import { useSelector } from "react-redux";

// Component imports
import HeaderGRB from './ui_header';
import FooterGRB from './ui_footer';
import SubNavGRB from './ui_subNav';
// import EditBeneficiarioGRB from './EditBeneficiario';

// Styled Components
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  overflow: hidden;
`;

const GRBWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 0;
  overflow: hidden;
  background-color: #fff;
  text-align: left;
`;

const GRBPanelLeft = styled.div`
  float:left;
  display: inline-flex;
  position: relative;
  width: auto;
  height: 100%;
  text-align: center;
  z-index: 2;
  left: 0px;
  // overflow: hidden auto;
  // background-color: #4c4c4c;
  color: #fff;
`;

const GRBPanelCentral = styled.div`
  display: block;
  position: relative;
  height: 100%;
  text-align: center;
  z-index: 0;
  background-color: #f8f8f8;
  left: auto;
  overflow: hidden auto;
  padding-right: 55px;
`;

// Component definition
const MainBeneficiario = props => {
  // redux store state
  const loggedIn = useSelector(state => state.auth.loggedIn);
  // const isEditBeneficiario = useSelector(state => state.beneficiarios.edit);
  
  if (loggedIn) {
    return (
      <Container>
        <HeaderGRB>
        </HeaderGRB>
        <SubNavGRB/>
        <GRBWrapper>
          <GRBPanelLeft>
          </GRBPanelLeft>
          <GRBPanelCentral>
            <div>Bienvenido Beneficiario</div>
            {/* {isEditBeneficiario && <EditBeneficiarioGRB/>} */}
          </GRBPanelCentral>
        </GRBWrapper>
        <FooterGRB/>
      </Container>


    );
  } else {
    return (
      <>
          <Redirect to="/" />
      </>
      );
  }
};


export default MainBeneficiario;
