// // Copyright 2020 Ernesto Girón E.
// // Licensed under the Apache License, Version 2.0 (the "License");
// // you may not use this file except in compliance with the License.
// // You may obtain a copy of the License at
// //     http://www.apache.org/licenses/LICENSE-2.0
// // Unless required by applicable law or agreed to in writing, software
// // distributed under the License is distributed on an "AS IS" BASIS,
// // WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// // See the License for the specific language governing permissions and
// // limitations under the License.​

import API from '../utils/ecaseAPI';
import {calculate_age} from '../utils/utils';

/********************************
// BENEFICIARIOS
*********************************/

 /* 
 * Make a request for list of Beneficiarios
 * @return Array of Beneficiarios
 */
export async function getBeneficiariosData(action) {
  let listBeneficiarios = null;
    try {
      await API('/beneficiarios', {
          method: 'POST',
          data: JSON.stringify({"api":action.token})
      }).then(response => response.data).then((data) => {
          // console.log(data);
          listBeneficiarios = data.datos.map(d => {
            d.edad = calculate_age(d.fechanacimiento);
            d.edadclub = calculate_age(d.fecha);
            d.fecha = new Date(d.fecha);
            d.fechanacimiento = new Date(d.fechanacimiento);
            d.actualizacion = new Date(d.actualizacion);
            return d
          });
        //   listBeneficiarios = data.datos
      }).catch(function (error) {
          // handle error
          console.log(`😱 problemas obteniendo los datos de Beneficiarios del GRB.`);
          console.log(error);
      })

  } catch (e) {
      console.log(`😱 Axios request failed: ${e}`);
  }
    return listBeneficiarios;
}

/* 
 * Make a request for Beneficiarios whole data
 * @return Array of Beneficiarios
 */
export async function getBeneficiarioPerfilData(action) {
    let datosBenef = null;
      try {
        await API('/beneficiarios/perfil', {
            method: 'POST',
            data: JSON.stringify({"api":action.token, "docId":action.docId, "req":action.req})
        }).then(response => response.data).then((data) => {
            datosBenef = data.datos
        }).catch(function (error) {
            console.log(`😱 problemas obteniendo los datos de Beneficiarios del GRB.`);
            console.log(error);
        })
  
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
      return datosBenef;
}

/* 
 * Actualiza el estado y otros datos basicos del Beneficiario
 * @return Array of Beneficiarios
 */
export async function actualizarBeneficiarioDatosBasicos(action) {
    let resp = null;
    try {
        await API('/beneficiarios/actualizarbasicos', {
            method: 'POST',
            data: JSON.stringify({"api":action.token, "basicos":action.basicos})
        }).then(response => response.data).then((data) => {
            resp = data.mensaje;
        }).catch((error) => {
            resp = '😱 Error ('+error.response.status+'): Problema guardando los datos básicos del beneficiario en el servidor.' + error.response.data.mensaje;
            console.log(`😱 problemas actualizando los datos básicos del Beneficiario en GRB.`, error.response.data.mensaje);
            return resp;
        })
  
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
    return resp;
}

/* 
 * Actualiza los datos de Perfil del Beneficiario
 * Solo tiene relación con la tabla perfilbeneficiarios
 * @return 
 */
export async function actualizarBeneficiarioPerfil(action) {
    let resp = null;
    try {
        await API('/perfilbeneficiario/update', {
            method: 'POST',
            data: JSON.stringify({"api":action.token, "perfil":action.perfil})
        }).then(response => response.data).then((data) => {
            resp = data.mensaje;
        }).catch((error) => {
            resp = '😱 Error ('+error.response.status+'): Problema guardando los datos de Perfil en el servidor.' + error.response.data.mensaje;
            console.log(`😱 problemas actualizando la tabla de perfilbeneficiario del Beneficiario en GRB.`, error.response.data.mensaje);
            return resp;
        })
  
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
    return resp;
}

/* 
 * Actualiza los datos de la Cita del Beneficiario
 * @return 
 */
export async function actualizarBeneficiarioCita(action) {
    let resp = null;
    try {
        await API('/citas/update', {
            method: 'POST',
            data: JSON.stringify({"api":action.token, "cita":action.cita})
        }).then(response => response.data).then((data) => {
            resp = data.mensaje;
        }).catch((error) => {
            resp = '😱 Error ('+error.response.status+'): Problema guardando la cita en el servidor.' + error.response.data.mensaje;
            console.log(`😱 problemas actualizando la cita del Beneficiario en GRB.`, error.response.data.mensaje);
            return resp;
        })
  
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
    return resp;
}

/* 
 * Actualiza los datos de la Observaciones del Beneficiario
 * @return 
 */
export async function actualizarBeneficiarioObs(action) {
    let resp = null;
    try {
        await API('/observaciones/update', {
            method: 'POST',
            data: JSON.stringify({"api":action.token, "obs":action.obs})
        }).then(response => response.data).then((data) => {
            resp = data.mensaje;
        }).catch((error) => {
            resp = '😱 Error ('+error.response.status+'): Problema guardando la observación en el servidor.' + error.response.data.mensaje;
            console.log(`😱 problemas actualizando la Obervación del Beneficiario en GRB.`, error.response.data.mensaje);
            return resp;
        })
  
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
    return resp;
}

/* 
 * Actualiza los datos de Localización del Beneficiario
 * @return 
 */
export async function actualizarBeneficiarioPredio(action) {
    let resp = null;
    try {
        await API('/predio/update', {
            method: 'POST',
            data: JSON.stringify({"api":action.token, "predio":action.predio})
        }).then(response => response.data).then((data) => {
            resp = data.mensaje;
        }).catch((error) => {
            resp = '😱 Error ('+error.response.status+'): Problema guardando la localización en el servidor.' + error.response.data.mensaje;
            console.log(`😱 problemas actualizando la localización del Beneficiario en GRB.`, error.response.data.mensaje);
            return resp;
        })
  
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
    return resp;
}



/* 
 * Actualiza los datos de Enceustado/Acudiente del Beneficiario
 * @return 
 */
export async function actualizarBeneficiarioEncuestado(action) {
    let resp = null;
    try {
        await API('/encuestado/update', {
            method: 'POST',
            data: JSON.stringify({"api":action.token, "encuestado":action.encuestado})
        }).then(response => response.data).then((data) => {
            resp = data.mensaje;
        }).catch((error) => {
            resp = '😱 Error ('+error.response.status+'): Problema guardando los datos de encuestado en el servidor.' + error.response.data.mensaje;
            console.log(`😱 problemas actualizando la tabla de encuestado del Beneficiario en GRB.`, error.response.data.mensaje);
            return resp;
        })
  
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
    return resp;
}

/* 
 * Actualiza los datos de Vivienda del Beneficiario
 * @return 
 */
export async function actualizarBeneficiarioVivienda(action) {
    let resp = null;
    try {
        await API('/vivienda/update', {
            method: 'POST',
            data: JSON.stringify({"api":action.token, "vivienda":action.vivienda})
        }).then(response => response.data).then((data) => {
            resp = data.mensaje;
        }).catch((error) => {
            resp = '😱 Error ('+error.response.status+'): Problema guardando los datos de vivienda en el servidor.' + error.response.data.mensaje;
            console.log(`😱 problemas actualizando la tabla de vivienda del Beneficiario en GRB.`, error.response.data.mensaje);
            return resp;
        })
  
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
    return resp;
}

/* 
 * Actualiza los datos de InfoEconomica del Beneficiario
 * @return 
 */
export async function actualizarBeneficiarioInfoEconomica(action) {
    let resp = null;
    try {
        await API('/infoeconomica/update', {
            method: 'POST',
            data: JSON.stringify({"api":action.token, "infoeconomica":action.infoeconomica})
        }).then(response => response.data).then((data) => {
            resp = data.mensaje;
        }).catch((error) => {
            resp = '😱 Error ('+error.response.status+'): Problema guardando los datos de InfoEconomica en el servidor.' + error.response.data.mensaje;
            console.log(`😱 problemas actualizando la tabla de InfoEconomica del Beneficiario en GRB.`, error.response.data.mensaje);
            return resp;
        })
  
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
    return resp;
}

/* 
 * Actualiza los datos de Salud del Beneficiario
 * @return 
 */
export async function actualizarBeneficiarioSalud(action) {
    let resp = null;
    try {
        await API('/salud/update', {
            method: 'POST',
            data: JSON.stringify({"api":action.token, "salud":action.salud})
        }).then(response => response.data).then((data) => {
            resp = data.mensaje;
        }).catch((error) => {
            resp = '😱 Error ('+error.response.status+'): Problema guardando los datos de salud en el servidor.' + error.response.data.mensaje;
            console.log(`😱 problemas actualizando la tabla de salud del Beneficiario en GRB.`, error.response.data.mensaje);
            return resp;
        })
  
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
    return resp;
}

/* 
 * Actualiza los datos de Educacion del Beneficiario
 * @return 
 */
export async function actualizarBeneficiarioEducacion(action) {
    let resp = null;
    try {
        await API('/educacion/update', {
            method: 'POST',
            data: JSON.stringify({"api":action.token, "edu":action.edu})
        }).then(response => response.data).then((data) => {
            resp = data.mensaje;
        }).catch((error) => {
            resp = '😱 Error ('+error.response.status+'): Problema guardando los datos de Educación en el servidor.' + error.response.data.mensaje;
            console.log(`😱 problemas actualizando la tabla de Educación del Beneficiario en GRB.`, error.response.data.mensaje);
            return resp;
        })
  
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
    return resp;
}

/* 
 * Actualiza los datos de Recreación del Beneficiario
 * @return 
 */
export async function actualizarBeneficiarioRecreacion(action) {
    let resp = null;
    try {
        await API('/recreacion/update', {
            method: 'POST',
            data: JSON.stringify({"api":action.token, "rec":action.rec})
        }).then(response => response.data).then((data) => {
            resp = data.mensaje;
        }).catch((error) => {
            resp = '😱 Error ('+error.response.status+'): Problema guardando los datos de recreación en el servidor. ' + error.response.data.mensaje;
            console.log(`😱 problemas actualizando la tabla de recreación del Beneficiario en GRB.`, error.response.data.mensaje);
            return resp;
        })
  
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
    return resp;
}

/* 
 * Actualiza los datos de Organización Social del Beneficiario
 * @return 
 */
export async function actualizarBeneficiarioOrgSocial(action) {
    let resp = null;
    try {
        await API('/orgsocial/update', {
            method: 'POST',
            data: JSON.stringify({"api":action.token, "orgsoc":action.orgsoc})
        }).then(response => response.data).then((data) => {
            resp = data.mensaje;
        }).catch((error) => {
            resp = '😱 Error ('+error.response.status+'): Problema guardando los datos de Organización Social en el servidor.' + error.response.data.mensaje;
            console.log(`😱 problemas actualizando la tabla de Organización Social del Beneficiario en GRB.`, error.response.data.mensaje);
            return resp;
        })
  
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
    return resp;
}

/* 
 * Actualiza los datos de Ahorradores del Beneficiario
 * @return 
 */
export async function actualizarBeneficiarioAhorradores(action) {
    let resp = null;
    try {
        await API('/ahorradores/update', {
            method: 'POST',
            data: JSON.stringify({"api":action.token, "ahorradores":action.ahorradores})
        }).then(response => response.data).then((data) => {
            resp = data.mensaje;
        }).catch((error) => {
            resp = '😱 Error ('+error.response.status+'): Problema guardando los datos de Ahorradores en el servidor. ' + error.response.data.mensaje;
            console.log(`😱 problemas actualizando la tabla de ahorradores del Beneficiario en GRB.`, error.response.data.mensaje);
            return resp;
        })
  
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
    return resp;
}
/* 
 * Registra nuevos datos de Ahorradores del Beneficiario
 * @return 
 */
export async function crearBeneficiarioAhorradores(action) {
    let resp = null;
    try {
        await API('/ahorradores/insert', {
            method: 'POST',
            data: JSON.stringify({"api":action.token, "ahorradores":action.ahorradores})
        }).then(response => response.data).then((data) => {
            resp = data.mensaje;
        }).catch((error) => {
            resp = '😱 Error ('+error.response.status+'): Problema registrando los datos de Ahorradores en el servidor. ' + error.response.data.mensaje;
            console.log(`😱 problemas registrando la tabla de ahorradores del Beneficiario en GRB.`, error.response.data.mensaje);
            return resp;
        })
  
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
    return resp;
}
/* 
 * Actualiza los datos de Ahorradores del Beneficiario
 * @return 
 */
export async function eliminarBeneficiarioAhorradores(action) {
    let resp = null;
    try {
        await API('/ahorradores/delete', {
            method: 'POST',
            data: JSON.stringify({"api":action.token, "ahorradores":action.ahorradores})
        }).then(response => response.data).then((data) => {
            resp = data.mensaje;
        }).catch((error) => {
            resp = '😱 Error ('+error.response.status+'): Problema eliminando los datos de Ahorradores en el servidor. ' + error.response.data.mensaje;
            console.log(`😱 problemas eliminando la tabla de ahorradores del Beneficiario en GRB.`, error.response.data.mensaje);
            return resp;
        })
  
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
    return resp;
}


/* 
 * Actualiza los datos de Aportantes del Beneficiario
 * @return 
 */
export async function actualizarBeneficiarioAportantes(action) {
    let resp = null;
    try {
        await API('/aportantes/update', {
            method: 'POST',
            data: JSON.stringify({"api":action.token, "aportantes":action.aportantes})
        }).then(response => response.data).then((data) => {
            resp = data.mensaje;
        }).catch((error) => {
            resp = '😱 Error ('+error.response.status+'): Problema guardando los datos de Aportantes en el servidor. ' + error.response.data.mensaje;
            console.log(`😱 problemas actualizando la tabla de aportantes del Beneficiario en GRB.`, error.response.data.mensaje);
            return resp;
        })
  
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
    return resp;
}
/* 
 * Crea los datos de aportantes del Beneficiario
 * @return 
 */
export async function crearBeneficiarioAportantes(action) {
    let resp = null;
    try {
        await API('/aportantes/insert', {
            method: 'POST',
            data: JSON.stringify({"api":action.token, "aportantes":action.aportantes})
        }).then(response => response.data).then((data) => {
            resp = data.mensaje;
        }).catch((error) => {
            resp = '😱 Error ('+error.response.status+'): Problema registrando los datos del aportante en el servidor. ' + error.response.data.mensaje;
            console.log(`😱 problemas registrando la tabla de aportantes del Beneficiario en GRB.`, error.response.data.mensaje);
            return resp;
        })
  
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
    return resp;
}
/* 
 * Elimina los datos de aportantes del Beneficiario
 * @return 
 */
export async function eliminarBeneficiarioAportantes(action) {
    let resp = null;
    try {
        await API('/aportantes/delete', {
            method: 'POST',
            data: JSON.stringify({"api":action.token, "aportantes":action.aportantes})
        }).then(response => response.data).then((data) => {
            resp = data.mensaje;
        }).catch((error) => {
            resp = '😱 Error ('+error.response.status+'): Problema eliminando el dato del aportante en el servidor. ' + error.response.data.mensaje;
            console.log(`😱 problemas eliminando datos en la tabla de aportantes del Beneficiario en GRB.`, error.response.data.mensaje);
            return resp;
        })
  
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
    return resp;
}


/* 
 * Actualiza los datos de Animales del Beneficiario
 * @return 
 */
export async function actualizarBeneficiarioAnimales(action) {
    let resp = null;
    try {
        await API('/animales/update', {
            method: 'POST',
            data: JSON.stringify({"api":action.token, "animales":action.animales})
        }).then(response => response.data).then((data) => {
            resp = data.mensaje;
        }).catch((error) => {
            resp = '😱 Error ('+error.response.status+'): Problema guardando los datos de animales en el servidor. ' + error.response.data.mensaje;
            console.log(`😱 problemas actualizando la tabla de animales del Beneficiario en GRB.`, error.response.data.mensaje);
            return resp;
        })
  
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
    return resp;
}

/* 
 * Crea los datos de Animales del Beneficiario
 * @return 
 */
export async function crearBeneficiarioAnimales(action) {
    let resp = null;
    try {
        await API('/animales/insert', {
            method: 'POST',
            data: JSON.stringify({"api":action.token, "animales":action.animales})
        }).then(response => response.data).then((data) => {
            resp = data.mensaje;
        }).catch((error) => {
            resp = '😱 Error ('+error.response.status+'): Problema registrando los datos de animales en el servidor. ' + error.response.data.mensaje;
            console.log(`😱 problemas registrando la tabla de animales del Beneficiario en GRB.`, error.response.data.mensaje);
            return resp;
        })
  
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
    return resp;
}
/* 
 * Elimina los datos de Animales del Beneficiario
 * @return 
 */
export async function eliminarBeneficiarioAnimales(action) {
    let resp = null;
    try {
        await API('/animales/delete', {
            method: 'POST',
            data: JSON.stringify({"api":action.token, "animales":action.animales})
        }).then(response => response.data).then((data) => {
            resp = data.mensaje;
        }).catch((error) => {
            resp = '😱 Error ('+error.response.status+'): Problema eliminando el dato del animal en el servidor. ' + error.response.data.mensaje;
            console.log(`😱 problemas eliminando datos en la tabla de animales del Beneficiario en GRB.`, error.response.data.mensaje);
            return resp;
        })
  
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
    return resp;
}

/* 
 * Actualiza los datos de Integrantes Hogar del Beneficiario
 * @return 
 */
export async function actualizarBeneficiarioIntegrantesHogar(action) {
    let resp = null;
    try {
        await API('/integranteshogar/update', {
            method: 'POST',
            data: JSON.stringify({"api":action.token, "integranteshogar":action.integranteshogar})
        }).then(response => response.data).then((data) => {
            resp = data.mensaje;
        }).catch((error) => {
            resp = '😱 Error ('+error.response.status+'): Problema guardando los datos de Integrantes del Hogar en el servidor. ' + error.response.data.mensaje;
            console.log(`😱 problemas actualizando la tabla de IntegrantesHogar del Beneficiario en GRB.`, error.response.data.mensaje);
            return resp;
        })
  
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
    return resp;
}
/* 
 * Crea los datos de Integrantes Hogar del Beneficiario
 * @return 
 */
export async function crearBeneficiarioIntegrantesHogar(action) {
    let resp = null;
    try {
        await API('/integranteshogar/insert', {
            method: 'POST',
            data: JSON.stringify({"api":action.token, "integranteshogar":action.integranteshogar})
        }).then(response => response.data).then((data) => {
            resp = data.mensaje;
        }).catch((error) => {
            resp = '😱 Error ('+error.response.status+'): Problema registrando los datos de Integrantes del Hogar en el servidor. ' + error.response.data.mensaje;
            console.log(`😱 problemas registrando la tabla de IntegrantesHogar del Beneficiario en GRB.`, error.response.data.mensaje);
            return resp;
        })
  
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
    return resp;
}
/* 
 * Elimina los datos de Integrantes Hogar del Beneficiario
 * @return 
 */
export async function eliminarBeneficiarioIntegrantesHogar(action) {
    let resp = null;
    try {
        await API('/integranteshogar/delete', {
            method: 'POST',
            data: JSON.stringify({"api":action.token, "integranteshogar":action.integranteshogar})
        }).then(response => response.data).then((data) => {
            // console.log(data.mensaje);
            resp = data.mensaje;
        }).catch((error) => {
            // console.log(error.response.data.mensaje);
            resp = '😱 Error ('+error.response.status+'): Problema eliminando el Integrante del Hogar en el servidor. ' + error.response.data.mensaje;
            console.log(`😱 problemas eliminando datos en la tabla de IntegrantesHogar del Beneficiario en GRB.`, error.response.data.mensaje);
            return resp;
        })
  
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
    return resp;
}

/* 
 * Actualiza los datos de Educación en el Hogar del Beneficiario
 * @return 
 */
export async function actualizarBeneficiarioEducacionenHogar(action) {
    let resp = null;
    try {
        await API('/educacionenhogar/update', {
            method: 'POST',
            data: JSON.stringify({"api":action.token, "eduhogar":action.eduhogar})
        }).then(response => response.data).then((data) => {
            resp = data.mensaje;
        }).catch((error) => {
            resp = '😱 Error ('+error.response.status+'): Problema guardando los datos de Educación en el Hogar en el servidor. ' + error.response.data.mensaje;
            console.log(`😱 problemas actualizando la tabla de EducacionenHogar del Beneficiario en GRB.`, error.response.data.mensaje);
            return resp;
        })
  
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
    return resp;
}
/* 
 * Registra datos de Educación en el Hogar del Beneficiario
 * @return 
 */
export async function crearBeneficiarioEducacionenHogar(action) {
    let resp = null;
    try {
        await API('/educacionenhogar/insert', {
            method: 'POST',
            data: JSON.stringify({"api":action.token, "eduhogar":action.eduhogar})
        }).then(response => response.data).then((data) => {
            resp = data.mensaje;
        }).catch((error) => {
            resp = '😱 Error ('+error.response.status+'): Problema creando los datos de Educación en el Hogar en el servidor. ' + error.response.data.mensaje;
            console.log(`😱 problemas creando la tabla de EducacionenHogar del Beneficiario en GRB.`, error.response.data.mensaje);
            return resp;
        })
  
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
    return resp;
}
/* 
 * Eliminar datos de Educación en el Hogar del Beneficiario
 * @return 
 */
export async function eliminarBeneficiarioEducacionenHogar(action) {
    let resp = null;
    try {
        await API('/educacionenhogar/delete', {
            method: 'POST',
            data: JSON.stringify({"api":action.token, "eduhogar":action.eduhogar})
        }).then(response => response.data).then((data) => {
            resp = data.mensaje;
        }).catch((error) => {
            // console.log(error.response.data.mensaje);
            resp = '😱 Error ('+error.response.status+'): Problema eliminando los datos de Educación en el Hogar en el servidor. ' + error.response.data.mensaje;
            console.log(`😱 problemas eliminando la tabla de EducacionenHogar del Beneficiario en GRB.`, error.response.data.mensaje);
            return resp;
        })
  
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
    return resp;
}

/* 
 * Actualiza los datos de Capacitacion del Beneficiario
 * @return 
 */
export async function actualizarBeneficiarioCapacitacion(action) {
    let resp = null;
    try {
        await API('/capacitacion/update', {
            method: 'POST',
            data: JSON.stringify({"api":action.token, "capacitacion":action.capacitacion})
        }).then(response => response.data).then((data) => {
            // console.log(data.mensaje);
            resp = data.mensaje;
        }).catch((error) => {
            // console.log(error.response.data.mensaje);
            resp = '😱 Error ('+error.response.status+'): Problema guardando los datos de Capacitacion en el servidor. ' + error.response.data.mensaje;
            console.log(`😱 problemas actualizando la tabla de Capacitacion del Beneficiario en GRB.`, error.response.data.mensaje);
            return resp;
        })
  
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
    return resp;
}
/* 
 * Crear los datos de Capacitacion del Beneficiario
 * @return 
 */
export async function crearBeneficiarioCapacitacion(action) {
    let resp = null;
    try {
        await API('/capacitacion/insert', {
            method: 'POST',
            data: JSON.stringify({"api":action.token, "capacitacion":action.capacitacion})
        }).then(response => response.data).then((data) => {
            resp = data.mensaje;
        }).catch((error) => {
            resp = '😱 Error ('+error.response.status+'): Problema creando los datos de Capacitacion en el servidor. ' + error.response.data.mensaje;
            console.log(`😱 problemas creando la tabla de Capacitacion del Beneficiario en GRB.`, error.response.data.mensaje);
            return resp;
        })
  
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
    return resp;
}
/* 
 * Eliminar los datos de Capacitacion del Beneficiario
 * @return 
 */
export async function eliminarBeneficiarioCapacitacion(action) {
    let resp = null;
    try {
        await API('/capacitacion/delete', {
            method: 'POST',
            data: JSON.stringify({"api":action.token, "capacitacion":action.capacitacion})
        }).then(response => response.data).then((data) => {
            resp = data.mensaje;
        }).catch((error) => {
            resp = '😱 Error ('+error.response.status+'): Problema eliminando los datos de Capacitacion en el servidor. ' + error.response.data.mensaje;
            console.log(`😱 problemas eliminando la tabla de Capacitacion del Beneficiario en GRB.`, error.response.data.mensaje);
            return resp;
        })
  
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
    return resp;
}

/* 
 * Actualiza los datos de Habilidades del Beneficiario
 * @return 
 */
export async function actualizarBeneficiarioHabilidades(action) {
    let resp = null;
    try {
        await API('/habilidades/update', {
            method: 'POST',
            data: JSON.stringify({"api":action.token, "habilidades":action.habilidades})
        }).then(response => response.data).then((data) => {
            // console.log(data.mensaje);
            resp = data.mensaje;
        }).catch((error) => {
            // console.log(error.response.data.mensaje);
            resp = '😱 Error ('+error.response.status+'): Problema guardando los datos de Habilidades en el servidor. ' + error.response.data.mensaje;
            console.log(`😱 problemas actualizando la tabla de Habilidades del Beneficiario en GRB.`, error.response.data.mensaje);
            return resp;
        })
  
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
    return resp;
}
/* 
 * Crea los datos de Habilidades del Beneficiario
 * @return 
 */
export async function crearBeneficiarioHabilidades(action) {
    let resp = null;
    try {
        await API('/habilidades/insert', {
            method: 'POST',
            data: JSON.stringify({"api":action.token, "habilidades":action.habilidades})
        }).then(response => response.data).then((data) => {
            resp = data.mensaje;
        }).catch((error) => {
            resp = '😱 Error ('+error.response.status+'): Problema creando los datos de Habilidades en el servidor. ' + error.response.data.mensaje;
            console.log(`😱 problemas creando los datos de la tabla de Habilidades del Beneficiario en GRB.`, error.response.data.mensaje);
            return resp;
        })
  
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
    return resp;
}
/* 
 * Eliminar los datos de Habilidades del Beneficiario
 * @return 
 */
export async function eliminarBeneficiarioHabilidades(action) {
    let resp = null;
    try {
        await API('/habilidades/delete', {
            method: 'POST',
            data: JSON.stringify({"api":action.token, "habilidades":action.habilidades})
        }).then(response => response.data).then((data) => {
            // console.log(data.mensaje);
            resp = data.mensaje;
        }).catch((error) => {
            // console.log(error.response.data.mensaje);
            resp = '😱 Error ('+error.response.status+'): Problema eliminando los datos de Habilidades en el servidor. ' + error.response.data.mensaje;
            console.log(`😱 problemas eliminando los datos de la tabla de Habilidades del Beneficiario en GRB.`, error.response.data.mensaje);
            return resp;
        })
  
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
    return resp;
}

/* 
 * Actualiza los datos de Vestuario Tallaje del Beneficiario
 * @return 
 */
export async function actualizarBeneficiarioVestuarioTallaje(action) {
    let resp = null;
    try {
        await API('/tallaje/update', {
            method: 'POST',
            data: JSON.stringify({"api":action.token, "tallaje":action.tallaje})
        }).then(response => response.data).then((data) => {
            // console.log(data.mensaje);
            resp = data.mensaje;
        }).catch((error) => {
            // console.log(error.response.data.mensaje);
            resp = '😱 Error ('+error.response.status+'): Problema guardando los datos de tallaje en el servidor. ' + error.response.data.mensaje;
            console.log(`😱 problemas actualizando la tabla de tallaje del Beneficiario en GRB.`, error.response.data.mensaje);
            return resp;
        })
  
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
    return resp;
}
/* 
 * Crea los datos de tallaje del Beneficiario
 * @return 
 */
export async function crearBeneficiarioVestuarioTallaje(action) {
    let resp = null;
    try {
        await API('/tallaje/insert', {
            method: 'POST',
            data: JSON.stringify({"api":action.token, "tallaje":action.tallaje})
        }).then(response => response.data).then((data) => {
            resp = data.mensaje;
        }).catch((error) => {
            resp = '😱 Error ('+error.response.status+'): Problema creando los datos de tallaje en el servidor. ' + error.response.data.mensaje;
            console.log(`😱 problemas creando los datos de la tabla de tallaje del Beneficiario en GRB.`, error.response.data.mensaje);
            return resp;
        })
  
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
    return resp;
}
/* 
 * Eliminar los datos de tallaje del Beneficiario
 * @return 
 */
export async function eliminarBeneficiarioVestuarioTallaje(action) {
    let resp = null;
    try {
        await API('/tallaje/delete', {
            method: 'POST',
            data: JSON.stringify({"api":action.token, "tallaje":action.tallaje})
        }).then(response => response.data).then((data) => {
            // console.log(data.mensaje);
            resp = data.mensaje;
        }).catch((error) => {
            // console.log(error.response.data.mensaje);
            resp = '😱 Error ('+error.response.status+'): Problema eliminando los datos de tallaje en el servidor. ' + error.response.data.mensaje;
            console.log(`😱 problemas eliminando los datos de la tabla de tallaje del Beneficiario en GRB.`, error.response.data.mensaje);
            return resp;
        })
  
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
    return resp;
}

