import React, {useState, useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectBeneficiarioForPerfilxCovidAcomp } from '../../../redux/reducers/covid19';

// import BenefCovidAyudas from '../../beneficiario/BenefCovidAyudas';
import BenefHeader from '../../beneficiario/BenefHeader';
import BenefCitas from '../../beneficiario/BenefCitas';
import BenefCumplimiento from '../../beneficiario/BenefCumplimiento';
import BenefNotificaciones from '../../beneficiario/BenefNotificaciones';
import BenefPerfilBeneficiario from '../../beneficiario/BenefPerfilBeneficiario';
import BenefLocalizacion from '../../beneficiario/BenefLocalizacion';
import BenefEncuestado from '../../beneficiario/BenefEncuestado';
import BenefVivienda from '../../beneficiario/BenefVivienda';
import BenefInfoEconomica from '../../beneficiario/BenefInfoEconomica';
import BenefSalud from '../../beneficiario/BenefSalud';
import BenefEducacion from '../../beneficiario/BenefEducacion';
import BenefRecreacion from '../../beneficiario/BenefRecreacion';
import BenefOrgSocial from '../../beneficiario/BenefOrgSocial';
// import BenefIntegrantesHogar from '../../beneficiario/BenefIntegrantesHogar';
import BenefFamiliares from '../../beneficiario/BenefFamiliares';
import BenefGenograma from '../../beneficiario/BenefGenograma';
import BenefFotos from '../../beneficiario/BenefFotos';
import BenefAudios from '../../beneficiario/BenefAudios';
import BenefVideos from '../../beneficiario/BenefVideos';
import BenefObservaciones from '../../beneficiario/BenefObservaciones';

import styled from "styled-components";

import closecircleIcon from '../../../assets/icons/closecircle_icon.png';


const BeneficiarioDetailsContainer = styled.div`
    // width: calc(100% - 45px); // Cuando hay actionbar
    width: 100%;
    height: 100vh;
    background: #fff;
    // padding-right:45px; // Cuando hay actionbar
    padding-right:5px;
`;

const CardBenefDetailsContainer = styled.div`
    // position: relative;
    // display: flex;
    // border: 1px solid #ccc;
    // padding: 0;
    // margin: 5px;
    // height: 100%;
    // flex-wrap: wrap;
    // width: auto;
`;

const CardBenefContainerClose = styled.div`
    position: absolute;
    width: 24px;
    height: 24px;
    // right: 60px; // Cuando hay actionbar
    right: 5px;
    top: 5px;
    font-size: 24px;
    cursor: pointer;
    z-index: 101;
    background: rgba(255, 255, 255, 0.1) url(${closecircleIcon}) no-repeat center/cover;
    opacity: 0.4;

    &:hover {
        opacity: 1;
    }
`;

const BeneficiarioDetails = props => {
    const users = useSelector(state => state.users);
    const canView = (users.user.areatrabajo==='ADMINISTRATIVA' || users.user.areatrabajo==='COORDINACIÓN' 
            || users.user.areatrabajo==='TRABAJO SOCIAL' || users.user.areatrabajo==='DIRECCIÓN' ) ? true : false;

    const config = useSelector(state => state.config);
    const datosSelBenefAcomp = useSelector(state => state.covid19.datosSelBenefAcomp);
    const selectedProfile = useSelector(state => state.covid19.selectedProfileAcomp);
    const [Beneficiario,setBeneficiario] = useState(null);
    const listBenefAcomp = useSelector(state => state.covid19.listBenefAcomp);
    // const listStatsCaddie = useSelector(state => state.cumpli.listStatsCaddie);
    const dispatch = useDispatch();

    useEffect(() => {
        const getBeneficiarioByDocId = (docId) => {
            return listBenefAcomp.filter((benef, i) => benef.docId === docId);
        };

        if (selectedProfile && selectedProfile.docId!==''){
            setBeneficiario(getBeneficiarioByDocId(selectedProfile.docId)[0]);
        }
    }, [selectedProfile, listBenefAcomp, dispatch]);

    const onCloseBenefDetailsWin = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setBeneficiario(null);
        dispatch(selectBeneficiarioForPerfilxCovidAcomp({selectedProfileAcomp:null}));
    }

    if (Beneficiario){
        let beneficiarios_raw = null; 
        // let covidayudas = null;
        let perfilbeneficiario = null;
        let predio = null;
        let encuestado = null;
        let vivienda = null;
        let infoeconomica = null;
        let integranteshogar = null;
        let aportantes = null;
        let ahorradores = null;
        let animales = null;
        let salud = null;
        let recreacion = null;
        let educacion =  null;
        let educacion_enhogar = null;
        let educacion_capacitacion = null;
        let educacion_habilidades = null;
        let orgsocial = null;
        let citas = null;
        let genograma = null;
        let fotos = null;
        let videos = null;
        let audios = null;
        let encuesta_observaciones = null;
        let notificaciones = null;
        let cumplimiento = null;

        if (datosSelBenefAcomp){
            if (datosSelBenefAcomp.length<=0) return (
                <></>
            );
            // console.log("datosSelBenefAcomp==>", datosSelBenefAcomp);
            // 0: "beneficiarios" -> Estos ya se tienen de antemano.
            if ( datosSelBenefAcomp['beneficiarios'] && datosSelBenefAcomp['beneficiarios'].length>0){
                beneficiarios_raw = datosSelBenefAcomp['beneficiarios'][0];
            }
            // 1: "predio"
            if ( datosSelBenefAcomp['predio'] && datosSelBenefAcomp['predio'].length>0){
                predio = datosSelBenefAcomp['predio'][0];
            }
            // 2: "perfilbeneficiario"
            if ( datosSelBenefAcomp['perfilbeneficiario'] && datosSelBenefAcomp['perfilbeneficiario'].length>0){
                perfilbeneficiario = datosSelBenefAcomp['perfilbeneficiario'][0];
            }
            // 3: "encuestado"
            if ( datosSelBenefAcomp['encuestado'] && datosSelBenefAcomp['encuestado'].length>0){
                encuestado = datosSelBenefAcomp['encuestado'][0];
            }
            // 4: "vivienda"
            if ( datosSelBenefAcomp['vivienda'] && datosSelBenefAcomp['vivienda'].length>0){ 
                vivienda = datosSelBenefAcomp['vivienda'][0]; 
            }
            // 5: "infoeconomica"
            if ( datosSelBenefAcomp['infoeconomica'] && datosSelBenefAcomp['infoeconomica'].length>0){ 
                infoeconomica = datosSelBenefAcomp['infoeconomica'][0];
            }
            // 6: "beneficiario_integranteshogar"
            if ( datosSelBenefAcomp['beneficiario_integranteshogar'] && datosSelBenefAcomp['beneficiario_integranteshogar'].length>0){
                integranteshogar = datosSelBenefAcomp['beneficiario_integranteshogar'];
            }
            // 6a: "beneficiario_aportantes"
            if ( datosSelBenefAcomp['beneficiario_aportantes'] && datosSelBenefAcomp['beneficiario_aportantes'].length>0){
                aportantes = datosSelBenefAcomp['beneficiario_aportantes'];
                infoeconomica['aportantes'] = aportantes;
            }
            // 6b: "beneficiario_ahorradores"
            if ( datosSelBenefAcomp['beneficiario_ahorradores'] && datosSelBenefAcomp['beneficiario_ahorradores'].length>0){
                ahorradores = datosSelBenefAcomp['beneficiario_ahorradores'];
                infoeconomica['ahorradores'] = ahorradores;
            }
            // 6c: "beneficiario_animales"
            if ( datosSelBenefAcomp['beneficiario_animales'] && datosSelBenefAcomp['beneficiario_animales'].length>0){
                animales = datosSelBenefAcomp['beneficiario_animales'];
                vivienda['animaleshogar'] = animales;
            }
            // 7: "salud"
            if ( datosSelBenefAcomp['salud'] && datosSelBenefAcomp['salud'].length>0){
                salud = datosSelBenefAcomp['salud'][0];
            }
            // 8: "recreacion"
            if ( datosSelBenefAcomp['recreacion'] && datosSelBenefAcomp['recreacion'].length>0){ 
                recreacion = datosSelBenefAcomp['recreacion'][0];
            }
            // 9: "educacion"
            if ( datosSelBenefAcomp['educacion'] && datosSelBenefAcomp['educacion'].length>0){ 
                educacion = datosSelBenefAcomp['educacion'][0];
            }
            // 9a: "educacion_enhogar"
            if ( datosSelBenefAcomp['educacion_enhogar'] && datosSelBenefAcomp['educacion_enhogar'].length>0){
                educacion_enhogar = datosSelBenefAcomp['educacion_enhogar'];
                educacion['educacionenhogar'] = educacion_enhogar;
            }
            // 9b: "educacion_capacitacion"
            if ( datosSelBenefAcomp['educacion_capacitacion'] && datosSelBenefAcomp['educacion_capacitacion'].length>0){
                educacion_capacitacion = datosSelBenefAcomp['educacion_capacitacion'];
                educacion['capacitacion'] = educacion_capacitacion;
            }
            // 9c: "educacion_habilidades"
            if ( datosSelBenefAcomp['educacion_habilidades'] && datosSelBenefAcomp['educacion_habilidades'].length>0){
                educacion_habilidades = datosSelBenefAcomp['educacion_habilidades'];
                educacion['habilidades'] = educacion_habilidades;
            }
            // 10: "orgsocial"
            if ( datosSelBenefAcomp['orgsocial'] && datosSelBenefAcomp['orgsocial'].length>0){
                orgsocial = datosSelBenefAcomp['orgsocial'][0];
            }
            // 11: "citas"
            if ( datosSelBenefAcomp['citas'] && datosSelBenefAcomp['citas'].length>0){
                citas = datosSelBenefAcomp['citas'][0];
            }
            // 12: "encuesta_observaciones"
            if ( datosSelBenefAcomp['encuesta_observaciones'] && datosSelBenefAcomp['encuesta_observaciones'].length>0){
                encuesta_observaciones = datosSelBenefAcomp['encuesta_observaciones'][0];
            }
            // 11: "genograma"
            if ( datosSelBenefAcomp['genograma']){
                genograma = datosSelBenefAcomp['genograma'];
            }
            // Covid Ayudas - Bonos/Mercados/Otros
            // if (datosSelBenefAcomp['grb_covid19_ayudas']){
            //     covidayudas = datosSelBenefAcomp['grb_covid19_ayudas'];
            // }
             // "grb_notificaciones"
             if ( datosSelBenefAcomp['grb_notificaciones'] && datosSelBenefAcomp['grb_notificaciones'].length>0){
                notificaciones = datosSelBenefAcomp['grb_notificaciones']; // Array of Objects
            }
            // grb_historicoreservas_golf
            if ( datosSelBenefAcomp['grb_historicoreservas_golf'] && datosSelBenefAcomp['grb_historicoreservas_golf'].length>0){
                cumplimiento = datosSelBenefAcomp['grb_historicoreservas_golf'];
            }

        return (
            <BeneficiarioDetailsContainer>
                <CardBenefContainerClose onClick={onCloseBenefDetailsWin} title="Cerrar Detalle Beneficiario"/>
                <CardBenefDetailsContainer>
                    <div className="cardBenefDetailsContainer-header-first">
                        <BenefHeader Beneficiario={beneficiarios_raw} ECASE_URL={config.ECASE_URL}/>
                        <BenefCumplimiento cumplimiento={cumplimiento} docId={Beneficiario.docId}/>
                    </div>
                    {/* {canView && covidayudas && <BenefCovidAyudas covidayudas={covidayudas} />} */}
                    {canView && notificaciones && <BenefNotificaciones notificaciones={notificaciones} basicos={beneficiarios_raw} /> }
                    {canView && perfilbeneficiario && <BenefPerfilBeneficiario perfilbeneficiario={perfilbeneficiario} basicos={beneficiarios_raw} /> }
                    {canView && integranteshogar && <BenefFamiliares integranteshogar={integranteshogar} beneficiarioId={Beneficiario.docId}/>}
                    {/* {canView && integranteshogar && <BenefIntegrantesHogar integranteshogar={integranteshogar} beneficiarioId={beneficiarios_raw.docId}/>} */}
                    {canView && predio && <BenefLocalizacion predio={predio} />}
                    {canView && encuestado && <BenefEncuestado encuestado={encuestado} />}
                    {canView && vivienda && <BenefVivienda vivienda={vivienda} />}
                    {canView && infoeconomica && <BenefInfoEconomica infoeconomica={infoeconomica} />}
                    {canView && salud && <BenefSalud salud={salud} />}
                    {canView && educacion && <BenefEducacion educacion={educacion} />}
                    {canView && recreacion && <BenefRecreacion recreacion={recreacion} />}
                    {canView && orgsocial && <BenefOrgSocial orgsocial={orgsocial} />}
                    {canView && genograma && <BenefGenograma genograma={config.ECASE_URL+genograma} />}
                    {canView && fotos && <BenefFotos fotos={fotos} />}
                    {canView && audios && <BenefAudios audios={audios} />}
                    {canView && videos && <BenefVideos videos={videos} />}
                    {canView && encuesta_observaciones && <BenefObservaciones obs={encuesta_observaciones} />}
                    {canView && citas && <BenefCitas citas={citas}/>}
                </CardBenefDetailsContainer>
            </BeneficiarioDetailsContainer>
            );

        } else { 
            return (
                <></>
            );
        }
    }
    return (
        <></>
    );
}

export default BeneficiarioDetails;

