// // Copyright 2020 Ernesto Girón E.
// // Licensed under the Apache License, Version 2.0 (the "License");
// // you may not use this file except in compliance with the License.
// // You may obtain a copy of the License at
// //     http://www.apache.org/licenses/LICENSE-2.0
// // Unless required by applicable law or agreed to in writing, software
// // distributed under the License is distributed on an "AS IS" BASIS,
// // WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// // See the License for the specific language governing permissions and
// // limitations under the License.​

import API from '../utils/ecaseAPI';

/* 
 * Make a request for list of notifications
 * @return Array of Tabla de Lista de Notificaciones
 */
export async function getListNotificaciones(action) {
    let listNotificaciones = null;
      try {
        await API('/notificaciones/', { 
            method: 'POST',
            data: JSON.stringify({"api":action.token})
        }).then(response => response.data).then((data) => {
            listNotificaciones = data.datos
        }).catch(function (error) {
            // handle error
            console.log(`😱 problemas obteniendo los datos de lista de notificaciones del GRB.`);
            console.log(error);
        })
  
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
      return listNotificaciones;
}

/* 
 * Make a request for list of notifications
 * @return Array of Tabla de Lista de Notificaciones
 */
export async function getListNotificacionesbyQuery(action) {
    let resultQueryNotas = null;
    try {
        await API('/notificaciones/query', { 
            method: 'POST',
            data: JSON.stringify({"api":action.token, "query":action.query})
        }).then(response => response.data).then((data) => {
            // console.log(data);
            if (data)
                resultQueryNotas = data
        }).catch(function (error) {
            // handle error
            console.log(`😱 problemas obteniendo los datos de lista de notificaciones del GRB.`);
            console.log(error);
        })
  
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
    return resultQueryNotas;
}

/* 
 * Registra nuevos datos de lista de notificaciones 
 * @return 
 */
export async function crearNotificacion(action) {
    let resp = null;
    try {
        await API('/notificaciones/insert', {
            method: 'POST',
            data: JSON.stringify({"api":action.token, "notificaciones":action.notificaciones})
        }).then(response => response.data).then((data) => {
            resp = data.mensaje;
        }).catch((error) => {
            resp = '😱 Error ('+error.response.status+'): Problema registrando los datos de notificaciones en el servidor. ' + error.response.data.mensaje;
            console.log(`😱 problemas registrando la tabla de notificaciones en GRB.`, error.response.data.mensaje);
            return resp;
        })
  
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
    return resp;
}
/* 
 * Actualiza los datos de lista de notificaciones 
 * @return 
 */
export async function actualizarNotificacion(action) {
    let resp = null;
    try {
        await API('/notificaciones/update', {
            method: 'POST',
            data: JSON.stringify({"api":action.token, "notificaciones":action.notificaciones})
        }).then(response => response.data).then((data) => {
            resp = data.mensaje;
        }).catch((error) => {
            resp = '😱 Error ('+error.response.status+'): Problema guardando los datos de notificaciones en el servidor. ' + error.response.data.mensaje;
            console.log(`😱 problemas actualizando la tabla de notificaciones en GRB.`, error.response.data.mensaje);
            return resp;
        })
        
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
    return resp;
}
/* 
 * Elimina datos de lista de notificaciones 
 * @return 
 */
export async function eliminarNotificacion(action) {
    let resp = null;
    try {
        await API('/notificaciones/delete', {
            method: 'POST',
            data: JSON.stringify({"api":action.token, "notificaciones":action.notificaciones})
        }).then(response => response.data).then((data) => {
            resp = data.mensaje;
        }).catch((error) => {
            resp = '😱 Error ('+error.response.status+'): Problema eliminando los datos de notificaciones en el servidor. ' + error.response.data.mensaje;
            console.log(`😱 problemas eliminando la tabla de notificaciones en GRB.`, error.response.data.mensaje);
            return resp;
        })
  
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
    return resp;
}