import React, {useState, useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";
import ContentEditable from 'react-contenteditable';
import { updateBeneficiarioInfoEconomica, serverMensaje } from '../../redux/reducers/beneficiarios';
import {highlightAll, disableNewlines, pastePlainText } from '../../utils/utils';
import {showToaster} from './BenefShowMesg';
import Alert, {AlertMessage} from 'calcite-react/Alert';
import Button, { ButtonGroup } from 'calcite-react/Button';
import Radio from 'calcite-react/Radio';
// import {MenuItem} from 'calcite-react/Menu';
// import MultiSelect from 'calcite-react/MultiSelect';
import Accordion, {
    AccordionSection,
    AccordionTitle,
    AccordionContent
} from 'calcite-react/Accordion';
import Form, {
    FormControl,
    Fieldset,
} from 'calcite-react/Form';

import styled from "styled-components";

import Aportantes from './BenefAportantes';
import Ahorradores from './BenefAhorradores';

// import enfermedades from '../../data/salud_enfermedades.json';

const BeneficiarioModulo = styled.div`
    height: auto;
    background: #fff;
    border: 0;
    padding: 0px;
    margin: 4px;
    overflow:hidden;
`;
const AccordionModulo = styled(Accordion)`
    padding:0;
`;
const AccordionTitleModule = styled(AccordionTitle)`
    background-color: #e6e6e6;
    color: #4d4d4d;
    font-size: 1.25em;
    font-weight: 700;
    // border-bottom: 1px solid #d9d9d9;
    // box-shadow: 0px 2px 6px #e1e1e1;
`;


const BenefInfoEconomica = ({infoeconomica}) => {
    const dispatch = useDispatch();
    const mensaje = useSelector(state => state.beneficiarios.mensaje);
    const users = useSelector(state => state.users);
    // const isEditable = users.user.tipo==='ADMINISTRADOR' ? true : false;
    // const isEditable = (users.user.tipo==='ADMINISTRADOR' && users.user.role==='A' && users.user.permisos==='A') ? true : false;
    const isEditable = (users.user.areatrabajo==='ADMINISTRATIVA' || users.user.areatrabajo==='TRABAJO SOCIAL' || users.user.areatrabajo==='COORDINACIÓN' || users.user.areatrabajo==='PSICOLOGÍA' ) ? true : false;
    // const canView = (users.user.areatrabajo==='ADMINISTRATIVA' || users.user.areatrabajo==='TECNOLOGÍA' ) ? true : false;
    
    const [activeSectionIndexes, setActiveSectionIndexes] = useState([]);
    const [tieneaportantesSelected, setTieneaportantesSelected] = useState(parseInt(infoeconomica.tieneaportantes));
    const [ahorranSelected, setAhorranSelected] = useState(parseInt(infoeconomica.ahorran));
    const [creditosbancosSelected, setCreditosbancosSelected] = useState(parseInt(infoeconomica.creditosbancos));
    const [creditosprestamistasSelected, setCreditosprestamistasSelected] = useState(parseInt(infoeconomica.creditosprestamistas));
    const [miembrodeudaSelected, setMiembrodeudaSelected] = useState(parseInt(infoeconomica.miembrodeuda));
    const [formapagoSelected, setFormapagoSelected] = useState(infoeconomica.formapago);
    
    // const [selectedFrecuenciaValues, setSelectedFrecuenciaValues] = useState(infoeconomica.frecuenciavisita); //.split('|').map(x=>+x)
    // const [selectedEnfermedadesValues, setSelectedEnfermedadesValues] = useState(infoeconomica.enfermedades.split('|').map(x=>+x));
    // const [satisfaccionservicioSelected, setSatisfaccionservicioSelected] = useState(parseInt(infoeconomica.satisfaccionservicio));
    // const [discapacitadosSelected, setDiscapacitadosSelected] = useState(parseInt(infoeconomica.discapacitados));
    
    const [error, setError] = useState(null);
    const onAccordionChange = (evt, index) => {
        activeSectionIndexes.includes(index)
        ? setActiveSectionIndexes(activeSectionIndexes.filter(
            item => index !== item,
          ))
        : setActiveSectionIndexes([...activeSectionIndexes, index]);
    }
    
    const [pagototalservicio] = useState(
        parseInt(infoeconomica.pagoenergia || 0) + 
        parseInt(infoeconomica.pagoacueducto || 0) + 
        parseInt(infoeconomica.pagoaseo || 0) + 
        parseInt(infoeconomica.pagogas || 0) + 
        parseInt(infoeconomica.pagotelefonia || 0) + 
        parseInt(infoeconomica.pagointernet || 0) + 
        parseInt(infoeconomica.pagocelular || 0) +
        parseInt(infoeconomica.pagocable || 0) + 
        parseInt(infoeconomica.pagootroservicio || 0)
    );

    const [IE] = useState({
        "id": infoeconomica.id, //int
        "beneficiarioId": infoeconomica.beneficiarioId, //15
        "totalingresos": infoeconomica.totalingresos,
        "tieneaportantes": infoeconomica.tieneaportantes,
        "gastosemanal": infoeconomica.gastosemanal,
        "dondecompra": infoeconomica.dondecompra,
        "formapago": infoeconomica.formapago,
        "pagodiferido": infoeconomica.pagodiferido,
        "gastotransporte": infoeconomica.gastotransporte,
        "transportebeneficiario": infoeconomica.transportebeneficiario,
        "transportefamilia": infoeconomica.transportefamilia,
        "pagoenergia": infoeconomica.pagoenergia,
        "pagoacueducto": infoeconomica.pagoacueducto,
        "pagoaseo": infoeconomica.pagoaseo,
        "pagogas": infoeconomica.pagogas,
        "pagotelefonia": infoeconomica.pagotelefonia,
        "pagointernet": infoeconomica.pagointernet,
        "pagocelular": infoeconomica.pagocelular,
        "pagocable": infoeconomica.pagocable,
        "pagootroservicio": infoeconomica.pagootroservicio,
        "ahorran": infoeconomica.ahorran,
        "creditosbancos": infoeconomica.creditosbancos,
        "montocreditosbancos": infoeconomica.montocreditosbancos,
        "creditosprestamistas": infoeconomica.creditosprestamistas,
        "montocreditosprestamistas": infoeconomica.montocreditosprestamistas,
        "destinocreditos": infoeconomica.destinocreditos,
        "miembrodeuda": infoeconomica.miembrodeuda,
        "miembrodeudaquien": infoeconomica.miembrodeudaquien,
        "montomiembrodeuda": infoeconomica.montomiembrodeuda,
        "comomejoraingresos": infoeconomica.comomejoraingresos,
        "necesidades": infoeconomica.necesidades,
        "pagototalservicio": String(pagototalservicio),
        "comentarios": infoeconomica.comentarios, //text
        "actualizacion": infoeconomica.actualizacion, //0000-00-00 00:00:00
        "version": infoeconomica.version
    });

    

    const [itemsInfoEconomica, setItemsInfoEconomica] = useState([
        // {"key":"id", "label": "Identificador Único", "value": IE['id']},
        // {"key":"beneficiarioId", "label": "Documento de Identificación", "value": IE['beneficiarioId']},
        {"key":"totalingresos", "label": "Total de los ingresos mensuales en el hogar", "value": IE['totalingresos']},
        {"key":"tieneaportantes", "label": "Aportantes en relación con el beneficiario?", "value": IE['tieneaportantes']},
        {"key":"gastosemanal", "label": "Cuánto gasta semanalmente en alimentación?", "value": IE['gastosemanal']},
        {"key":"dondecompra", "label": "Dónde compra los alimentos?", "value": IE['dondecompra']},
        {"key":"formapago", "label": "Forma de pago?", "value": IE['formapago']},
        {"key":"pagodiferido", "label": "Si es un crédito a cuánto tiempo difiere usualmente", "value": IE['pagodiferido']},
        {"key":"gastotransporte", "label": "Cuánto gasta la familia semanalmente en transporte?", "value": IE['gastotransporte']},
        {"key":"transportebeneficiario", "label": "Qué medio de transporte utiliza el beneficiario para desplazarse?", "value": IE['transportebeneficiario']},
        {"key":"transportefamilia", "label": "Qué medio de transporte utiliza la familia para desplazarse?", "value": IE['transportefamilia']},
        {"key":"pagoenergia", "label": "Energía", "value": IE['pagoenergia']},
        {"key":"pagoacueducto", "label": "Acueducto", "value": IE['pagoacueducto']},
        {"key":"pagoaseo", "label": "Aseo", "value": IE['pagoaseo']},
        {"key":"pagogas", "label": "Gas", "value": IE['pagogas']},
        {"key":"pagotelefonia", "label": "Telefonía", "value": IE['pagotelefonia']},
        {"key":"pagointernet", "label": "Internet", "value": IE['pagointernet']},
        {"key":"pagocelular", "label": "Celular", "value": IE['pagocelular']},
        {"key":"pagocable", "label": "TV Cable", "value": IE['pagocable']},
        {"key":"pagootroservicio", "label": "Otro", "value": IE['pagootroservicio']},

        {"key":"pagototalservicio", "label": "Total pago mensual servicios públicos?", "value": IE['pagototalservicio'] },

        {"key":"ahorran", "label": "Ahorradores en la familia", "value": IE['ahorran']},
        {"key":"creditosbancos", "label": "Tiene créditos con entidades financieras?", "value": IE['creditosbancos']},
        {"key":"montocreditosbancos", "label": "Monto con entidades financieras", "value": IE['montocreditosbancos']},
        {"key":"creditosprestamistas", "label": "Tiene créditos con prestamistas?", "value": IE['creditosprestamistas']},
        {"key":"montocreditosprestamistas", "label": "Monto con prestamistas", "value": IE['montocreditosprestamistas']},
        {"key":"destinocreditos", "label": "Destino de los créditos (en qué invirtió el dinero)   ", "value": IE['destinocreditos']},
        {"key":"miembrodeuda", "label": "Algún miembro del hogar tiene deuda(s) (fuera de la deuda por la vivienda, si es que tiene)", "value": IE['miembrodeuda']},
        {"key":"miembrodeudaquien", "label": "Quién? (en ralación con el beneficiario)", "value": IE['miembrodeudaquien']},
        {"key":"montomiembrodeuda", "label": "Cuál es el monto de la(s) deuda(s)?", "value": IE['montomiembrodeuda']},
        {"key":"comomejoraingresos", "label": "Cómo cree usted que podría mejorar el nivel de ingresos familiar?", "value": IE['comomejoraingresos']},
        {"key":"necesidades", "label": "Qué necesidades familiares no alcanza a satisfacer con el nivel de ingresos actual?", "value": IE['necesidades']},
        // {"key":"comentarios", "label": "Comentarios en general información económica", "value": IE['comentarios']},
        // {"key":"actualizacion", "label": "Última actualización", "value": IE['actualizacion']},
        // {"key":"version", "label": "Versión", "value": IE['version']}
    ]);

    useEffect(() => {
        // Guardar los resultados al salir, puede ser contraproducente al fallar la conexión con el servidor u otro evento no deseado
        // se podrián perder los datos modificafos

        return () => {
            // if (isUpdated){
            //     console.log('Saving appointment', isUpdated);
            // }
            dispatch(serverMensaje({}));
        }
    }, [dispatch]);

    useEffect(() => {
        // if (itemsInfoEconomica){
        //     // console.log(itemsInfoEconomica);
        // }
        if (mensaje.infoeconomica && mensaje.infoeconomica!=='') {
            if (typeof mensaje.infoeconomica === 'string' && mensaje.infoeconomica.toLowerCase().includes('error')){
                showToaster('error', 'Ocurrió un fallo en el servidor', mensaje.infoeconomica);
            } 
            // else {
            //     showToaster('success',"Información Económica del Beneficiario", mensaje.infoeconomica); //"🧭 " + mensaje
            // }
        }

    }, [mensaje.infoeconomica, dispatch]);

    const updateItemsInfoEconomica = (f, v) => {
        const newitemsInfoEconomica = itemsInfoEconomica.map((itm, index) => {
            if (itm.key===f){
                itm.value = v;
            }
            return itm;
        });
        setItemsInfoEconomica(newitemsInfoEconomica);
    };

    const updateDataonServer = () =>{
        dispatch(serverMensaje({infoeconomica:''}));
        dispatch(updateBeneficiarioInfoEconomica( { token: users.user.claveAPI, infoeconomica:IE}));
        
    }

    const replaceSomeText = (s) =>{
        return s
            .replace(/&nbsp;/g, ' ')
            .replace(/<br>/g, '')
            .replace(/&amp;/g, '&')
            .replace(/&gt;/g, '>')
            .replace(/&lt;/g, '<').trim()
    }

    const validateInput = (f, v, final) => {
        const regex_onlynum = /^[0-9]*$/i; ///[0-9,]|\./;
        // const regex_nombres = /^['`´a-zA-ZÀ-ÿ\u00C0-\u017F\s]+$/;
        // const regex_email = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        let new_val = null;
        let err = null;
        switch (f) {
            case 'totalingresos':
                new_val = replaceSomeText(v);
                if (!regex_onlynum.test(new_val)) {
                    err = 'Dígite solo números válidos';
                    new_val = null;
                }
                if (new_val===null) new_val = '';
                break;
            case 'tieneaportantes':
                new_val = replaceSomeText(v); //1
                if (!regex_onlynum.test(new_val)) {
                    err = 'Dígite un solo número válido';
                    new_val = null;
                } else if(parseInt(new_val)>1){
                    err = 'Dígite un número válido';
                    new_val = null;
                }
                break;
            case 'gastosemanal':
                new_val = replaceSomeText(v);
                if (!regex_onlynum.test(new_val)) {
                    err = 'Dígite solo números válidos';
                    new_val = null;
                }
                if (new_val===null) new_val = '';
                break;
            case 'dondecompra':
                new_val = v.substr(0,120);
                if (final)
                    new_val = replaceSomeText(v).substr(0,120);
                break;
            case 'formapago':
                new_val = validateInput.substr(0,32);
                if (final)
                    new_val = replaceSomeText(v).substr(0,32);
                break;
            case 'pagodiferido':
                new_val = v.substr(0,60);
                if (final)
                    new_val = replaceSomeText(v).substr(0,60);
                break;
            case 'gastotransporte':
                new_val = replaceSomeText(v);
                if (!regex_onlynum.test(new_val)) {
                    err = 'Dígite solo números válidos';
                    new_val = null;
                }
                if (new_val===null) new_val = '';
                break;
            case 'transportebeneficiario':
                new_val = v.substr(0,60);
                if (final)
                    new_val = replaceSomeText(v).substr(0,60);
                break;
            case 'transportefamilia':
                new_val = v.substr(0,60);
                if (final)
                    new_val = replaceSomeText(v).substr(0,60);
                break;
            case 'pagoenergia':
                new_val = replaceSomeText(v);
                if (!regex_onlynum.test(new_val)) {
                    err = 'Dígite solo números válidos';
                    new_val = null;
                }
                if (new_val===null) new_val = '';
                break;
            case 'pagoacueducto':
                new_val = replaceSomeText(v);
                if (!regex_onlynum.test(new_val)) {
                    err = 'Dígite solo números válidos';
                    new_val = null;
                }
                if (new_val===null) new_val = '';
                break;
            case 'pagoaseo':
                new_val = replaceSomeText(v);
                if (!regex_onlynum.test(new_val)) {
                    err = 'Dígite solo números válidos';
                    new_val = null;
                }
                if (new_val===null) new_val = '';
                break;
            case 'pagogas':
                new_val = replaceSomeText(v);
                if (!regex_onlynum.test(new_val)) {
                    err = 'Dígite solo números válidos';
                    new_val = null;
                }
                if (new_val===null) new_val = '';
                break;
            case 'pagotelefonia':
                new_val = replaceSomeText(v);
                if (!regex_onlynum.test(new_val)) {
                    err = 'Dígite solo números válidos';
                    new_val = null;
                }
                if (new_val===null) new_val = '';
                break;
            case 'pagointernet':
                new_val = replaceSomeText(v);
                if (!regex_onlynum.test(new_val)) {
                    err = 'Dígite solo números válidos';
                    new_val = null;
                }
                if (new_val===null) new_val = '';
                break;
            case 'pagocelular':
                new_val = replaceSomeText(v);
                if (!regex_onlynum.test(new_val)) {
                    err = 'Dígite solo números válidos';
                    new_val = null;
                }
                if (new_val===null) new_val = '';
                break;
            case 'pagocable':
                new_val = replaceSomeText(v);
                if (!regex_onlynum.test(new_val)) {
                    err = 'Dígite solo números válidos';
                    new_val = null;
                }
                if (new_val===null) new_val = '';
                break;
            case 'pagootroservicio':
                new_val = replaceSomeText(v);
                if (!regex_onlynum.test(new_val)) {
                    err = 'Dígite solo números válidos';
                    new_val = null;
                }
                if (new_val===null) new_val = '';
                break;

            case 'ahorran':
                new_val = replaceSomeText(v); //1
                if (!regex_onlynum.test(new_val)) {
                    err = 'Dígite un solo número válido';
                    new_val = null;
                } else if(parseInt(new_val)>1){
                    err = 'Dígite un número válido';
                    new_val = null;
                }
                break;
            case 'creditosbancos':
                new_val = replaceSomeText(v); //1
                if (!regex_onlynum.test(new_val)) {
                    err = 'Dígite un solo número válido';
                    new_val = null;
                } else if(parseInt(new_val)>1){
                    err = 'Dígite un número válido';
                    new_val = null;
                }
                break;
            case 'montocreditosbancos':
                new_val = replaceSomeText(v);
                if (!regex_onlynum.test(new_val)) {
                    err = 'Dígite solo números válidos';
                    new_val = null;
                }
                if (new_val===null) new_val = '';
                break;
            case 'creditosprestamistas':
                new_val = replaceSomeText(v); //1
                if (!regex_onlynum.test(new_val)) {
                    err = 'Dígite un solo número válido';
                    new_val = null;
                } else if(parseInt(new_val)>1){
                    err = 'Dígite un número válido';
                    new_val = null;
                }
                break;
            case 'montocreditosprestamistas':
                new_val = replaceSomeText(v);
                if (!regex_onlynum.test(new_val)) {
                    err = 'Dígite solo números válidos';
                    new_val = null;
                }
                if (new_val===null) new_val = '';
                break;
            case 'destinocreditos':
                new_val = v.substr(0,255);
                if (final)
                    new_val = replaceSomeText(v).substr(0,255);
                break;
            case 'miembrodeuda':
                new_val = replaceSomeText(v); //1
                if (!regex_onlynum.test(new_val)) {
                    err = 'Dígite un solo número válido';
                    new_val = null;
                } else if(parseInt(new_val)>1){
                    err = 'Dígite un número válido';
                    new_val = null;
                }
                break;
            case 'miembrodeudaquien':
                new_val = v.substr(0,32);
                if (final)
                    new_val = replaceSomeText(v).substr(0,32);
                break;
            case 'montomiembrodeuda':
                new_val = replaceSomeText(v);
                if (!regex_onlynum.test(new_val)) {
                    err = 'Dígite solo números válidos';
                    new_val = null;
                }
                if (new_val===null) new_val = '';
                break;
            case 'comomejoraingresos':
                new_val = v.substr(0,255);
                if (final)
                    new_val = replaceSomeText(v).substr(0,255);
                break;
            case 'necesidades':
                new_val = v.substr(0,255);
                if (final)
                    new_val = replaceSomeText(v).substr(0,255);
                break;
            case 'comentarios':
                new_val = v.trim(); //.substr(0,255);
                if (final)
                    new_val = replaceSomeText(v); //.substr(0,255);
                break;
            default:
                break;
        }
        setError(err);
        return new_val
    }
    const handleChange = evt => {
        const field = evt.currentTarget.id.split('_')[1];
        const new_val = validateInput(field, evt.target.value, false)
        if(new_val){
            IE[field] = new_val;
        };

    };
    const handleBlur = evt => {
        const val = evt.currentTarget.innerHTML;
        const field = evt.target.id.split('_')[1];
        const new_val = validateInput(field, val, true)
        if(new_val || new_val===''){
            IE['pagototalservicio'] = String(
                parseInt(IE.pagoenergia || 0) + 
                parseInt(IE.pagoacueducto || 0) + 
                parseInt(IE.pagoaseo || 0) + 
                parseInt(IE.pagogas || 0) + 
                parseInt(IE.pagotelefonia || 0) + 
                parseInt(IE.pagointernet || 0) + 
                parseInt(IE.pagocelular || 0) +
                parseInt(IE.pagocable || 0) + 
                parseInt(IE.pagootroservicio || 0));
            updateItemsInfoEconomica('pagototalservicio', IE['pagototalservicio']);

            IE[field] = new_val;
            if (field!=='comentarios')
                updateItemsInfoEconomica(field, IE[field]);

            updateDataonServer();
        };
        
    };

    const tieneaportantesSelectButton = (selected) => {
        setTieneaportantesSelected(selected);
        IE['tieneaportantes'] = selected === 1 ? '1' : '0';
        updateDataonServer();
    }
    const ahorranSelectButton = (selected) => {
        setAhorranSelected(selected);
        IE['ahorran'] = selected === 1 ? '1' : '0';
        updateDataonServer();
    }
    const creditosbancosSelectButton = (selected) => {
        setCreditosbancosSelected(selected);
        IE['creditosbancos'] = selected === 1 ? '1' : '0';
        updateDataonServer();
    }
    const creditosprestamistasSelectButton = (selected) => {
        setCreditosprestamistasSelected(selected);
        IE['creditosprestamistas'] = selected === 1 ? '1' : '0';
        updateDataonServer();
    }
    const miembrodeudaSelectButton = (selected) => {
        setMiembrodeudaSelected(selected);
        IE['miembrodeuda'] = selected === 1 ? '1' : '0';
        updateDataonServer();
    }
    
    const formapagoSelectRadio  = (selected) => {
        setFormapagoSelected(selected);
        IE['formapago'] = selected;
        updateDataonServer();
    }

    return (
        <BeneficiarioModulo>
            <AccordionModulo
                onAccordionChange={onAccordionChange}
                activeSectionIndexes={activeSectionIndexes}
            >
                <AccordionSection>
                    <AccordionTitleModule>Información Económica</AccordionTitleModule>
                    {activeSectionIndexes.length>0 &&  <AccordionContent style={{marginBottom: '10px'}}>
                    {error && <div style={{color: 'red', width: '100%'}}>
                            <Alert red showIcon style={{minWidth: '100%', marginBottom: '10px'}}
                                showCloseLabel 
                                onClose={() => setError(null)}>
                                <AlertMessage>
                                    Error:{' '}
                                    <strong>{error}</strong>
                                </AlertMessage>
                            </Alert>
                        </div> 
                    }
                    {/* ITEMS */}
                    {
                        itemsInfoEconomica.map((itm, index) => {
                            return (<div key={index} className="itemContainer">
                                <div className="itemContainer-attr-long" 
                                    style={{backgroundColor:(index % 2 === 0) ? '#f8f8f8': '#fff'}}
                                >{itm.label}</div>
                                <div className="itemContainer-value" 
                                    style={{backgroundColor:(index % 2 === 0) ? '#f8f8f8': '#fff'}}
                                >

                                {   
                                    itm.key==='tieneaportantes'
                                    ?
                                    <ButtonGroup isToggle>
                                        <Button  clear={tieneaportantesSelected !== 1} disabled={!isEditable} 
                                            onClick={() => {  tieneaportantesSelectButton(1) }}
                                        >Si</Button>
                                        <Button clear={tieneaportantesSelected !== 0} disabled={!isEditable} 
                                            onClick={() => { tieneaportantesSelectButton(0) }}
                                        >No</Button>
                                    </ButtonGroup>
                                    :

                                    itm.key==='ahorran'
                                    ?
                                    <ButtonGroup isToggle>
                                        <Button  clear={ahorranSelected !== 1} disabled={!isEditable} 
                                            onClick={() => { ahorranSelectButton(1) }}
                                        >Si</Button>
                                        <Button clear={ahorranSelected !== 0} disabled={!isEditable} 
                                            onClick={() => { ahorranSelectButton(0) }}
                                        >No</Button>
                                    </ButtonGroup>
                                    :
                                    itm.key==='creditosbancos'
                                    ?
                                    <ButtonGroup isToggle>
                                        <Button  clear={creditosbancosSelected !== 1} disabled={!isEditable} 
                                            onClick={() => {  creditosbancosSelectButton(1) }}
                                        >Si</Button>
                                        <Button clear={creditosbancosSelected !== 0} disabled={!isEditable} 
                                            onClick={() => { creditosbancosSelectButton(0) }}
                                        >No</Button>
                                    </ButtonGroup>
                                    :
                                    itm.key==='creditosprestamistas'
                                    ?
                                    <ButtonGroup isToggle>
                                        <Button  clear={creditosprestamistasSelected !== 1} disabled={!isEditable} 
                                            onClick={() => {  creditosprestamistasSelectButton(1) }}
                                        >Si</Button>
                                        <Button clear={creditosprestamistasSelected !== 0} disabled={!isEditable} 
                                            onClick={() => { creditosprestamistasSelectButton(0) }}
                                        >No</Button>
                                    </ButtonGroup>
                                    :
                                    itm.key==='miembrodeuda'
                                    ?
                                    <ButtonGroup isToggle>
                                        <Button  clear={miembrodeudaSelected !== 1} disabled={!isEditable} 
                                            onClick={() => {  miembrodeudaSelectButton(1) }}
                                        >Si</Button>
                                        <Button clear={miembrodeudaSelected !== 0} disabled={!isEditable} 
                                            onClick={() => { miembrodeudaSelectButton(0) }}
                                        >No</Button>
                                    </ButtonGroup>
                                    :
                                    // itm.key==='enfermedades'
                                    // ?
                                    //     <MultiSelect
                                    //         renderValue={renderEnfermedadesValue}
                                    //         onChange={handleEnfermedadesMultiSelectChange}
                                    //         selectedValues={selectedEnfermedadesValues}
                                    //         closeOnSelect={true}
                                    //     >
                                    //         {enfermedades.enfermedades.map((f,i) => {
                                    //             return <MenuItem key={f.id} value={f.id}>{f.nombre}</MenuItem>
                                    //         })}
                                    //     </MultiSelect>
                                    // :
                                    itm.key==='formapago' 
                                    ?
                                        <Form>
                                            <FormControl>
                                            <Fieldset name="formapagoRadioGroup">
                                                <Radio name='Contado' disabled={!isEditable} 
                                                    checked={formapagoSelected.toLowerCase()==='contado' ? true : false}
                                                    onChange={() => { formapagoSelectRadio('Contado') }}
                                                >Contado</Radio>
                                                <Radio name='Crédito' disabled={!isEditable} 
                                                    // defaultChecked={formapagoSelected==='Crédito' ? true : false}
                                                    checked={formapagoSelected.toLowerCase()==='crédito' ? true : false}
                                                    onChange={() => { formapagoSelectRadio('Crédito') }}
                                                >Crédito</Radio>
                                                <Radio name='Ambas' disabled={!isEditable} 
                                                    checked={formapagoSelected.toLowerCase()==='ambas' ? true : false}
                                                    onChange={() => { formapagoSelectRadio('Ambas') }}
                                                >Ambas</Radio>
                                            </Fieldset>
                                            </FormControl>
                                        </Form>
                                    :
                                    <ContentEditable
                                        id={'infoeconomica_'+itm.key}
                                        // innerRef={agendoEditable}
                                        html={itm.value || ''} // innerHTML of the editable div
                                        className='itemContainer-value-long'
                                        disabled={!isEditable}      // use true to disable editing
                                        onPaste={pastePlainText} // En caso se copie y pegue de otra pagina de internet
                                        onChange={handleChange} // handle innerHTML change
                                        onKeyPress={disableNewlines}  
                                        onFocus={highlightAll}
                                        onBlur={handleBlur}
                                        tagName='div' // Use a custom HTML tag (uses a div by default)
                                    />
                                }
                                </div>
                            </div>)
                        })
                    }
                    {/* Tabla Aportantes al Hogar */}
                    { IE['tieneaportantes'] === '1' && infoeconomica.aportantes && <div className="itemContainer-table">
                            <div className="tablas-Beneficiario-detalles">
                                <div className="benef-texto-observaciones-title">Aportantes al Hogar</div>
                                <Aportantes aportantes={infoeconomica.aportantes} beneficiarioId={infoeconomica.beneficiarioId}/>
                            </div>
                        </div>
                    }

                    {/* Tabla Ahorradores al Hogar */}
                    {IE['ahorran'] === '1' && infoeconomica.ahorradores && <div className="itemContainer-table">
                            <div className="tablas-Beneficiario-detalles">
                                <div className="benef-texto-observaciones-title">Ahorradores en el Hogar</div>
                                <Ahorradores ahorradores={infoeconomica.ahorradores} beneficiarioId={infoeconomica.beneficiarioId}/>
                            </div>
                        </div>
                    }

                    <div className="Observaciones-Comentarios">
                        <div className="benef-texto-observaciones-title">Comentarios en general información económica:</div>
                        <div className="benef-texto-observaciones"> 
                            <ContentEditable
                                id={'infoeconomica_comentarios'}
                                html={IE.comentarios || ''} // innerHTML of the editable div
                                disabled={!isEditable}        // use true to disable editing
                                onPaste={pastePlainText}
                                onChange={handleChange} // handle innerHTML change
                                onFocus={highlightAll}
                                onBlur={handleBlur}
                                tagName='div' // Use a custom HTML tag (uses a div by default)
                                style={{ "minHeight": "40px", "border": "1px dashed" }}
                            />
                        </div>
                    </div>

                        <div className="benef-texto-actualizacion">Última actualización: {IE['actualizacion']}</div>

                    </AccordionContent>
                }
                </AccordionSection>
            </AccordionModulo>
        </BeneficiarioModulo>
    );
}

export default BenefInfoEconomica;

