// React
import React, { Fragment,useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ContentEditable from 'react-contenteditable';
import { EditorState,  } from 'draft-js'; //convertToRaw, ContentState
import { Editor } from 'react-draft-wysiwyg';
// import draftToHtml from 'draftjs-to-html';
// import htmlToDraft from 'html-to-draftjs';

import {capitalize, formatMoney,
    // getFirstWords, removeHTMLTags,
    highlightAll, 
    disableNewlines, 
    pastePlainText
} from '../../utils/utils';
import Dimensions from 'react-dimensions';
import Pagination from '../Pagination';
// import DatePicker from 'calcite-react/DatePicker';
import moment from 'moment';
import 'moment/locale/es-us';

import {showMsgToaster} from '../ShowMesg';
import {ToastContainer} from 'calcite-react/Toaster'; //notify
import Alert, {AlertMessage} from 'calcite-react/Alert';
import Select from 'calcite-react/Select';
// import MultiSelect from 'calcite-react/MultiSelect';
import {MenuItem} from 'calcite-react/Menu';
import Loader from 'calcite-react/Loader';
// import Search from 'calcite-react/Search';
import Button from 'calcite-react/Button';
import TextField from 'calcite-react/TextField';
import {
    // ListItem,
    // ListItemTitle,
    // ListItemSubtitle
} from 'calcite-react/List';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import '../../styles/css/font-awesome.min.css';
import './auxilios_styles.css';

import { serverMensaje, moduleAuxilioLoaded,
    // fetchAuxiliosData,
    fetchAuxiliosDatabyQuery,
    updateAuxilio,
    insertAuxilio,
    deleteAuxilio,
    // displayAuxilioFilter,

} from '../../redux/reducers/auxilios';

import {fetchUsersData} from '../../redux/reducers/users';
import { fetchBeneficiariosData } from '../../redux/reducers/beneficiarios';

// import estadoAuxilios from '../../data/estadonotificacion.json';
// import areadeTrabajoAuxilios from '../../data/areasnotificacion.json';
// import tipodeAuxilio from '../../data/tipodenotificacion.json';
// import prioridadAuxilio from '../../data/prioridadnotificacion.json';


// Styled & Motion Components
import styled from "styled-components";

// import UserIcon from 'calcite-ui-icons-react/UserIcon';
import ResetIcon from 'calcite-ui-icons-react/ResetIcon';
import SaveIcon from 'calcite-ui-icons-react/SaveIcon';
import PlusIcon from 'calcite-ui-icons-react/SpeechBubblePlusIcon';
import TrashIcon from 'calcite-ui-icons-react/TrashIcon';
import FilterIcon from 'calcite-ui-icons-react/FilterIcon';
// import CalendarIcon from 'calcite-ui-icons-react/CalendarIcon';
import SearchIcon from 'calcite-ui-icons-react/SearchIcon';
import MobileIcon from 'calcite-ui-icons-react/MobileIcon';

const EditAuxilio = styled.div`
    width: 100%;
    // height: 100vh; // calc(100% - 10px);
    position: relative;
    background-color: #fff;
    z-index: 2;
`;
const AgregarAuxilio = styled.div`
    width: 100%;
    // height: 100vh; // calc(100% - 10px);
    position: relative;
    background-color: #fff;
    z-index: 2;
`;


const ModuleAuxilios = props => {
    const dispatch = useDispatch();
    const config = useSelector(state => state.config);
    const users = useSelector(state => state.users);
    const listUsers = useSelector(state => state.users.listUsers);
    const listBenef = useSelector(state => state.beneficiarios.listBenef);
    const isEditable = (users.user.areatrabajo==='ADMINISTRATIVA' || users.user.areatrabajo==='COORDINACIÓN' || users.user.areatrabajo==='DIRECCIÓN' ) ? true : false;
    // const [isEditable,setIsEditable] = useState(true); //users.user.docId===auxilioSelected.creadapor ? true : false;
    const resultQueryAuxilios = useSelector(state => state.auxilios.resultQueryAuxilios);
    const [searchText,setSearchText] = useState('');
    const [isDisplayEditAuxilio, setIsDisplayEditAuxilio] = useState(false);
    const [isDisplayAgregarAuxilio, setIsDisplayAgregarAuxilio] = useState(false);

    const mensaje = useSelector(state => state.auxilios.mensaje);
    const [error, setError] = useState(null);
    const [errorenAgregar, setErrorenAgregar] = useState(null);
    // const [fechaPlazo, setFechaPlazo] = useState(moment(new Date(),'YYYY-MM-DD'));
    // const [fechaPlazoPickerFocused, setFechaPlazoPickerFocused] = useState(false);
    // const minDate = moment('2017-05-14'); //moment('2020-07-15'); //moment().subtract(2, 'months').startOf('month');
    // const maxDate= moment().add(15, 'months'); //moment().add(2, 'months').endOf('month');

    const [query,] = useState({
        "page": "1",
        "item_per_page": "10",
        "searchText":"",
        "id":"",
        "beneficiarioId":"",
        "familiarId":"",
        "auxilioparafamiliar":"",
        "programa":"",
        "subprograma":"",
        "plan":"",
        "tipoauxilio":"",
        "tipoanterior":"",
        "claseauxilio":"",
        "descripcion":"",
        "monto":"",
        "fechaaprobado":"",
        "observaciones":"",
        "fecharegistro":"",
        "comprobante":"",
        "estadoaux":"",
        "entidaddesalud":"",
        "colegiouniversidad":"",
        "promedio":"",
        "periodo":"",
        "educacion":"",
        "nivelescolaridad":"",
        "vlrmatriculaaprobada":"",
        "vlrmensualidadaprobada":"",
        "porcentcumpli":"",
        "vlrcancelado":"",
        "fechauso":"",
        "creadapor":"",
        "modificadopor":"",
        "actualizacion":"",
        "version":""
    });
    // setAuxilioSelected
    const [auxilioSelected, ] = useState({
        "id": '-1', //int
        "beneficiarioId":"",
        "familiarId":"",
        "auxilioparafamiliar":"",
        "programa":"",
        "subprograma":"",
        "plan":"",
        "tipoauxilio":"",
        "tipoanterior":"",
        "claseauxilio":"",
        "descripcion":"",
        "monto":"",
        "fechaaprobado":"",
        "observaciones":"",
        "fecharegistro":"",
        "comprobante":"",
        "estadoaux":"",
        "entidaddesalud":"",
        "colegiouniversidad":"",
        "promedio":"",
        "periodo":"",
        "educacion":"",
        "nivelescolaridad":"",
        "vlrmatriculaaprobada":"",
        "vlrmensualidadaprobada":"",
        "porcentcumpli":"",
        "vlrcancelado":"",
        "fechauso":"",
        "creadapor":"",
        "modificadopor":"",
        "actualizacion":"",
        "version":""
    });

    const [itemsAuxilio, setItemsAuxilio] = useState([
        // {"key":"id", "label": "Id", "value": auxilioSelected['id']},
        // {"key":"beneficiarioId", "label": "beneficiarioId", "value": auxilioSelected['beneficiarioId']},
        {"key":"familiarId", "label": "familiarId", "value": auxilioSelected['familiarId']},
        {"key":"auxilioparafamiliar", "label": "auxilioparafamiliar", "value": auxilioSelected['auxilioparafamiliar']},
        {"key":"programa", "label": "programa", "value": auxilioSelected['programa']},
        {"key":"subprograma", "label": "subprograma", "value": auxilioSelected['subprograma']},
        {"key":"plan", "label": "plan", "value": auxilioSelected['plan']},
        {"key":"tipoauxilio", "label": "tipoauxilio", "value": auxilioSelected['tipoauxilio']},
        {"key":"tipoanterior", "label": "tipoanterior", "value": auxilioSelected['tipoanterior']},
        {"key":"claseauxilio", "label": "claseauxilio", "value": auxilioSelected['claseauxilio']},
        {"key":"descripcion", "label": "descripcion", "value": auxilioSelected['descripcion']},
        {"key":"monto", "label": "monto", "value": auxilioSelected['monto']},
        {"key":"fechaaprobado", "label": "fechaaprobado", "value": auxilioSelected['fechaaprobado']},
        {"key":"fecharegistro", "label": "fecharegistro", "value": auxilioSelected['fecharegistro']},
        {"key":"comprobante", "label": "comprobante", "value": auxilioSelected['comprobante']},
        {"key":"estadoaux", "label": "estadoaux", "value": auxilioSelected['estadoaux']},
        {"key":"entidaddesalud", "label": "entidaddesalud", "value": auxilioSelected['entidaddesalud']},
        {"key":"colegiouniversidad", "label": "colegiouniversidad", "value": auxilioSelected['colegiouniversidad']},
        {"key":"promedio", "label": "promedio", "value": auxilioSelected['promedio']},
        {"key":"periodo", "label": "periodo", "value": auxilioSelected['periodo']},
        {"key":"educacion", "label": "educacion", "value": auxilioSelected['educacion']},
        {"key":"nivelescolaridad", "label": "nivelescolaridad", "value": auxilioSelected['nivelescolaridad']},
        {"key":"vlrmatriculaaprobada", "label": "vlrmatriculaaprobada", "value": auxilioSelected['vlrmatriculaaprobada']},
        {"key":"vlrmensualidadaprobada", "label": "vlrmensualidadaprobada", "value": auxilioSelected['vlrmensualidadaprobada']},
        {"key":"porcentcumpli", "label": "porcentcumpli", "value": auxilioSelected['porcentcumpli']},
        {"key":"vlrcancelado", "label": "vlrcancelado", "value": auxilioSelected['vlrcancelado']},
        {"key":"fechauso", "label": "fechauso", "value": auxilioSelected['fechauso']},
        {"key":"observaciones", "label": "observaciones", "value": auxilioSelected['observaciones']},
        // {"key":"fecharegistro", "label": "Fecha creación", "value": auxilioSelected['fecharegistro']},
        // {"key":"creadapor", "label": "Auxilio creada por", "value": auxilioSelected['creadapor']},
        // {"key":"modificadopor", "label": "Modificado por", "value": auxilioSelected['modificadopor']},
        // {"key":"comentarios", "label": "Observaciones", "value": auxilioSelected['comentarios']},
        // {"key":"actualizacion", "label": "Actualizacion", "value": auxilioSelected['actualizacion']},
        // {"key":"version", "label": "Version", "value": auxilioSelected['version']},
    ]);

    // let showPages = 1; let totalPages = 1;
    const [showPages,] = useState(3);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [numItemsperPage,] = useState(10);
    const onPageNumberChange = (pageNumber) => {
        query['page'] = parseInt(pageNumber)+1;
        dispatch(fetchAuxiliosDatabyQuery({token: users.user.claveAPI, query: query} ));
        setCurrentPage(pageNumber);
    }
    
    useEffect(() => {
        if (!listUsers || listUsers.length<0){
            dispatch(fetchUsersData({token: users.user.claveAPI} ));
            return
        }
        if (!listBenef || listBenef.length<=0){
            dispatch(fetchBeneficiariosData({token: users.user.claveAPI}));
            return
        }
        if (resultQueryAuxilios===null){
            dispatch(fetchAuxiliosDatabyQuery({token: users.user.claveAPI, query: query} ));
            return
        }

        return () => {
            // executed when unmount
            // console.log('Saliendo del Modulo Beneficiarios');
        }
        
    }, [users.user.claveAPI, listUsers, listBenef, resultQueryAuxilios, query, dispatch]);

    useEffect(() => {
        if (resultQueryAuxilios===null){
            return
        }
        if (!listUsers || listUsers.length<0){
            return
        }
        if (!listBenef || listBenef.length<0){
            return
        }
        if (resultQueryAuxilios.datos.length<=0){
            console.log('No hay registros para mostrar...');
        }
        // Update or visualize the module
        dispatch(moduleAuxilioLoaded({loaded:true}));
        // console.log(resultQueryAuxilios);
        // Update totalPages
        if (resultQueryAuxilios.stats.count)
            setTotalPages(Math.ceil(resultQueryAuxilios.stats.count / numItemsperPage));


        if (mensaje.auxilios && mensaje.auxilios!=='') {

            const updateListaAuxilios = () => {
                setCurrentPage(0);
                query['page'] = '1'; // Es necesario enviarla para que encuentre algo
                query['estadonota'] = '';
                dispatch(fetchAuxiliosDatabyQuery({token: users.user.claveAPI, query: query} ));
            }

            if (typeof mensaje.auxilios === 'string' && mensaje.auxilios.toLowerCase().includes('error')){
                showMsgToaster('error', 'Ocurrió un fallo en el servidor', mensaje.auxilios);
                mensaje.auxilios='';
            } 
            else {
                showMsgToaster('success',"Auxilios", mensaje.auxilios.split('.')[0]);
                mensaje.auxilios='';
                dispatch(serverMensaje({auxilios:''}));
                updateListaAuxilios();
            }
            
        }
        
    }, [resultQueryAuxilios, listUsers, listBenef, numItemsperPage, query, users.user.claveAPI, 
        mensaje.auxilios, dispatch]);

    // Save on Server
    const updateDataonServer = (action, auxilio) => {
        // console.log(auxilio);
        switch (action) {
            case 'insert':
                auxilio['creadapor'] = users.user.docId;
                dispatch(insertAuxilio( { token: users.user.claveAPI, auxilios:auxilio}));
                break;
            case 'remove':
                dispatch(deleteAuxilio( { token: users.user.claveAPI, auxilios:auxilio}));
                break;
            case 'update':
                // Actualizar solo el registro necesario, para no enviar toda la tabla
                dispatch(updateAuxilio( { token: users.user.claveAPI, auxilios:auxilio}));
                break;
            default: // update
                console.log('Acción no disponible');
                break;
        }
    }

    const updateItemsAuxilio = (f, v) => {
        const newitemsAuxilio = itemsAuxilio.map((itm, index) => {
            if (itm.key===f){
                itm.value = v;
            }
            return itm;
        });
        setItemsAuxilio(newitemsAuxilio);
    };

    // const getUserbyDocId = (docId) =>{
    //     let u = null;
    //     if (listUsers && listUsers.length>0)
    //         u = listUsers.filter(itm => itm.docId===docId);
    //     return u;
    // }
    
    // const showAuxilio = (idx) =>{
    //     document.getElementById('auxilio_'+idx).style.display = document.getElementById('auxilio_'+idx).style.display==='block' ? '-webkit-box' : 'block';
    // }
    // const showObservaciones = (idx) =>{
    //     document.getElementById('observaciones_'+idx).style.display = document.getElementById('observaciones_'+idx).style.display==='block' ? '-webkit-box' : 'block';
    // }

    // Buscar
    const buscarenAuxilio = (str)  => {
        // console.log('texto a buscar:', searchText);
        setCurrentPage(0);
        query['page'] = '1'; // Es necesario enviarla para que encuentre algo
        query['descripcion'] = str || searchText.trim() || '';
        // query['estadonota'] = ''; // removido por el momento
        dispatch(fetchAuxiliosDatabyQuery({token: users.user.claveAPI, query: query} ));
    }

    // Toolbar
    const onBtnFiltrarAuxilio= (evt) =>{
        evt.preventDefault();
        evt.stopPropagation();
        setCurrentPage(0);
        query['page'] = '1'; // Es necesario enviarla para que encuentre algo
        query['descripcion'] = searchText.trim() || '';
        query['estadonota'] = ''; // removido por el momento
        toggleFilterTool(evt);
    }
    const handleSearchKeyPress = (evt) => {
        const keyCode = evt.keyCode || evt.which
        if (keyCode === 13 || evt.key === 'Enter') {
            evt.returnValue = false
            if (evt.preventDefault) evt.preventDefault();
            // Search
            buscarenAuxilio();
        }
    }
    const onSearchChange = (value) => {
        setSearchText(value);
    }
    const onSearchRequestClear = () => {
        setSearchText('');
        setCurrentPage(0);
        query['page'] = '1';
        query['descripcion'] = '';
        dispatch(fetchAuxiliosDatabyQuery({token: users.user.claveAPI, query: query} ));
        // buscarenAuxilio(); // No refresca la lista
    }
    const buscarTexto = (evt)  => {
        evt.preventDefault();
        evt.stopPropagation();
        // console.log('texto a buscar:', searchText);
        buscarenAuxilio(searchText.trim());
    }
    const toggleBuscarTool = (evt)  => {
        evt.preventDefault();
        evt.stopPropagation();
        document.getElementById('buscar-tool-mobile').style.display = document.getElementById('buscar-tool-mobile').style.display==='inline-flex' ? 'none' : 'inline-flex';
    }
    
    const onBtnAgregarAuxilio = (evt) =>{
        evt.preventDefault();
        evt.stopPropagation();
        // console.log(users);
        // const newitemsAuxilio = itemsAuxilio.map((itm, index) => {
        //     itm.value = '';
        //     if (itm.key==='id') itm.value = "-1";
        //     if (itm.key==='estadonota') itm.value = "Pendiente";
        //     if (itm.key==='tiponota') itm.value = "Informativa";
        //     if (itm.key==='areanota') itm.value = "Fundación";
        //     if (itm.key==='prioridad') itm.value = "Baja";
        //     if (itm.key==='fecharegistro') itm.value = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
        //     if (itm.key==='fechaplazo') itm.value = moment().add(15, 'days').format("YYYY-MM-DD HH:mm:ss");
        //     if (itm.key==='creadapor') itm.value = users.user.nombre; //users.user.docId;
        //     if (itm.key==='modificadopor') getFirstWords(users.user.nombre.toUpperCase());
        //     if (itm.key==='actualizacion') itm.value = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
        //     if (itm.key==='version') itm.value = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
            
        //     return itm;
        // });
        // let nuevoRegistro = {
        //     "id": '-1', //int
        //     "tiponota":"Informativa",
        //     "auxilio":"",
        //     "areanota":"Fundación",
        //     "relacioncon":"",
        //     "responsables":"",
        //     "prioridad":"Baja",
        //     "etiquetas":"",
        //     "estadonota":"Pendiente",
        //     "comentarios":"",
        //     "fecharegistro":moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
        //     "fechaplazo":moment().add(15, 'days').format("YYYY-MM-DD HH:mm:ss"),
        //     "creadapor": users.user.docId,
        //     "actualizacion":moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
        //     "version":moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
        //     "modificadopor": getFirstWords(users.user.nombre.toUpperCase())
        // };
        // setItemsAuxilio(newitemsAuxilio);
        // setAuxilioSelected(nuevoRegistro);
        // // Update UI
        // setEditorAuxilioState(EditorState.createEmpty());
        // setEditorObservacionState(EditorState.createEmpty());
        // setTiponotaSelectedValue(nuevoRegistro.tiponota);
        // setPrioridadSelectedValue(nuevoRegistro.prioridad);
        // setAreanotaSelectedValue(nuevoRegistro.areanota);
        // setEstadonotaSelectedValue(nuevoRegistro.estadonota);
        // setInputBeneficiarioValue(nuevoRegistro.relacioncon);
        // setSelectedResponsablesValues(nuevoRegistro.responsables);
        // // setSelectedResponsablesValues(nuevoRegistro.responsables.split(',').map(x=>+x));
        // setIsDisplayAgregarAuxilio(true);
    }
    const onBtnEditAuxilio = (evt, id, index) =>{
        evt.preventDefault();
        evt.stopPropagation();
        if (resultQueryAuxilios && resultQueryAuxilios.datos.length>0){
            const newitemsAuxilio = resultQueryAuxilios.datos.filter((itm, index) => { return itm.id===id});
            // console.log(newitemsAuxilio);
            if (newitemsAuxilio){
                // const itemsnote = itemsAuxilio.map((itm, index) => {
                //     itm.value = newitemsAuxilio[0][itm.key];
                //     if (itm.key==='relacioncon' && newitemsAuxilio[0].benefnombres!==null) itm.value = capitalize(newitemsAuxilio[0].benefnombres, true) +' '+capitalize(newitemsAuxilio[0].benefapellidos,true);
                //     if (itm.key==='modificadopor') itm.value = getFirstWords(users.user.nombre.toUpperCase());
                //     // if (itm.key==='fecharegistro') itm.value = N[id][itm.key].split(' ')[1];
                //     return itm;
                // });
                // setItemsAuxilio(itemsnote);
                // setAuxilioSelected(newitemsAuxilio[0]);
                // // Update UI
                // // Agregar datos del observación al editor
                // // const html = '<div>'+newitemsAuxilio[0].comentarios+'</div>';
                // // const comentariosContentBlock = htmlToDraft('<div>'+newitemsAuxilio[0].comentarios+'</div>');
                // const comentariosContentBlock = htmlToDraft(newitemsAuxilio[0].comentarios);
                // if (comentariosContentBlock) {
                //     const comentariosContentState = ContentState.createFromBlockArray(comentariosContentBlock.contentBlocks);
                //     const comentariosEditorState = EditorState.createWithContent(comentariosContentState);
                //     setEditorObservacionState(comentariosEditorState);
                // }
                // // const notaContentBlock = htmlToDraft('<div>'+newitemsAuxilio[0].auxilio+'</div>');
                // const notaContentBlock = htmlToDraft(newitemsAuxilio[0].auxilio);
                // if (notaContentBlock) {
                //     const notaContentState = ContentState.createFromBlockArray(notaContentBlock.contentBlocks);
                //     const notaEditorState = EditorState.createWithContent(notaContentState);
                //     setEditorAuxilioState(notaEditorState);
                // }
                // setTiponotaSelectedValue(newitemsAuxilio[0].tiponota);
                // setPrioridadSelectedValue(newitemsAuxilio[0].prioridad);
                // setAreanotaSelectedValue(newitemsAuxilio[0].areanota);
                // setEstadonotaSelectedValue(newitemsAuxilio[0].estadonota);
                // setFechaPlazo(moment(newitemsAuxilio[0].fechaplazo, 'YYYY-MM-DD'));
                // // setInputBeneficiarioValue(newitemsAuxilio[0].relacioncon); 
                // if (newitemsAuxilio[0].benefnombres!==null)
                //     setInputBeneficiarioValue(capitalize(newitemsAuxilio[0].benefnombres, true) +' '+capitalize(newitemsAuxilio[0].benefapellidos,true));
                // setSelectedResponsablesValues(newitemsAuxilio[0].responsables); //.split(',').map(x=>+x));

                // // setIsEditable(users.user.docId===newitemsAuxilio[0].creadapor ? true : false);
                // setIsEditable(users.user.areatrabajo==='ADMINISTRATIVA' || users.user.docId===newitemsAuxilio[0].creadapor ? true : false);
                // setIsDisplayEditAuxilio(true);
            }
        }
        
    }
    
    
    const saveAuxilio = () => {
        setIsDisplayAgregarAuxilio(false);
        setIsDisplayEditAuxilio(false);
        // const htmlAuxilio = draftToHtml(convertToRaw(editorAuxilioState.getCurrentContent()));
        // const htmlObservaciones = draftToHtml(convertToRaw(editorObservacionState.getCurrentContent()));
        // // console.log(html);
        // auxilioSelected['auxilio'] = htmlAuxilio;
        // auxilioSelected['comentarios'] = htmlObservaciones;
        // auxilioSelected['modificadopor'] = getFirstWords(users.user.nombre.toUpperCase());
        // if (auxilioSelected['relacioncon']==='' || auxilioSelected['relacioncon']===undefined || notaSelected['relacioncon']===null)
        //     auxilioSelected['relacioncon']='FUNDACIÓN';
        // if (isDisplayEditAuxilio===true) updateDataonServer('update', auxilioSelected);
        // if (isDisplayAgregarAuxilio===true) updateDataonServer('insert', auxilioSelected);
    }
    const cancelAuxilio = (evt) => {
        if (isDisplayEditAuxilio===true) setIsDisplayEditAuxilio(false);
        if (isDisplayAgregarAuxilio===true) setIsDisplayAgregarAuxilio(false);
    }
    const eliminarAuxilio = (evt, id) =>{
        evt.preventDefault();
        evt.stopPropagation();
        updateDataonServer('remove', auxilioSelected);
        setIsDisplayEditAuxilio(false);
    }


    const replaceSomeText = (s) =>{
        return s
            .replace(/&nbsp;/g, ' ')
            .replace(/<br>/g, '')
            .replace(/<\/br>/g, '')
            .replace(/<\/div>/g, '')
            .replace(/<div>/g, '')
            .replace(/<b>/g, '')
            .replace(/<strong>/g, '')
            .replace(/<p>/g, '')
            .replace(/<\/b>/g, '')
            .replace(/<\/strong>/g, '')
            .replace(/<\/p>/g, '')
            .replace(/&amp;/g, '&')
            .replace(/&gt;/g, '>')
            .replace(/&lt;/g, '<').trim()
    }
    const validateInput = (f, v, final) => {
        const regex_onlynum = /[0-9,]|\./;
        // const regex_nombres = /^['`´a-zA-ZÀ-ÿ\u00C0-\u017F\s]+$/;
        let new_val = null;
        let err = null;
        switch (f) {
            case 'id':
                new_val = replaceSomeText(v);
                if (!regex_onlynum.test(new_val)) {
                    err = 'Dígite solo números válidos';
                    new_val = null;
                }
                if (new_val===null) new_val = '';
                break;
            case 'fecharegistro': //0000-00-00 00:00:00
                new_val = replaceSomeText(v).substr(0,30);
                if (final && new_val===''){
                    new_val = replaceSomeText(v).substr(0,30);
                    err = 'Dígite una fecha de creación válida';
                    new_val = null;
                }
                break;
            case 'fechaplazo': //0000-00-00 00:00:00
                new_val = replaceSomeText(v).substr(0,30);
                if (final && new_val===''){
                    new_val = replaceSomeText(v).substr(0,30);
                    err = 'Dígite una fecha límite válida';
                    new_val = null;
                }
                break;
            case 'tiponota':
                new_val = v.substr(0,32);
                if (final)
                    new_val = replaceSomeText(v).substr(0,32);
                break;
            case 'estadonota':
                new_val = v.substr(0,32);
                if (final)
                    new_val = replaceSomeText(v).substr(0,32);
                break;
            case 'areanota':
                new_val = v.substr(0,32);
                if (final)
                    new_val = replaceSomeText(v).substr(0,32);
                break;
            case 'relacioncon':
                new_val = v.substr(0,20);
                if (final)
                    new_val = replaceSomeText(v).substr(0,20);
                break;
            case 'responsables':
                new_val = v.substr(0,500);
                if (final)
                    new_val = v.substr(0,600).replace(/<br>/g, ' ').trim();
                break;
            case 'etiquetas':
                new_val = v.substr(0,500);
                if (final)
                    new_val = v.substr(0,600).replace(/<br>/g, ' ').trim();
                break;
            case 'prioridad':
                new_val = v.substr(0,20);
                if (final)
                    new_val = replaceSomeText(v).substr(0,20);
                break;
            case 'celular':
                new_val = replaceSomeText(v).substr(0,15);
                if (!regex_onlynum.test(v)) {
                    err = 'Dígite solo números válidos de celular';
                    new_val = null;
                } else if(v.length>15){
                    err = 'Dígite un número de celular válido';
                    new_val = null;
                }
                if (new_val===null) new_val = '';
                break;
            
            // case 'leida':
            //     new_val = replaceSomeText(v).substr(0,1); //1
            //     if (!regex_onlynum.test(new_val)) {
            //         err = 'Dígite solo números válidos';
            //         new_val = null;
            //     } else if(parseInt(new_val)>1){
            //         err = 'Dígite un número válido';
            //         new_val = null;
            //     }
            //     break;
            
            case 'creadapor':
                new_val = v.substr(0,32);
                if (final)
                    new_val = replaceSomeText(v).substr(0,32);
                break;
            case 'modificadopor':
                new_val = v.substr(0,32);
                if (final)
                    new_val = replaceSomeText(v).substr(0,32);
                break;
            case 'auxilio':
                new_val = v;
                // if (final)
                //     new_val = v; //replaceSomeText(v);
                break;
            case 'comentarios':
                new_val = v;
                // if (final)
                //     new_val = v; //replaceSomeText(v);
                break;
            case 'actualizacion': //0000-00-00 00:00:00
                new_val = v.substr(0,30);
                if (final && new_val===''){
                    new_val = replaceSomeText(v).substr(0,30);
                    err = 'Dígite una fecha de actualización válida';
                    new_val = null;
                }
                break;
            case 'version': //0000-00-00 00:00:00
                new_val = v.substr(0,30);
                if (final && new_val===''){
                    new_val = replaceSomeText(v).substr(0,30);
                    err = 'Dígite una fecha de version válida';
                    new_val = null;
                }
                break;
            
            default:
                break;
        }

        if (isDisplayEditAuxilio) setError(err);
        if (isDisplayAgregarAuxilio) setErrorenAgregar(err);
        return new_val
    }
    const handleChange = evt => {
        const field = evt.currentTarget.id.split('_')[1];
        const new_val = validateInput(field, evt.target.value, false);
        if(new_val){
            auxilioSelected[field] = new_val
        };
    };
    const handleBlur = evt => {
        const val = evt.currentTarget.innerHTML; //evt.currentTarget.innerText; //
        const field = evt.target.id.split('_')[1];
        let new_val = validateInput(field, val, true);
        if(new_val || new_val===''){
            if (Object.keys(auxilioSelected).includes(field)){
                auxilioSelected[field] = new_val;
            }
            updateItemsAuxilio(field, new_val);
        };
    };

    // const [tiponotaSelectedValue, setTiponotaSelectedValue] = useState('');
    // const handleTipoAuxilioSelectChange = (value, item) => {
    //     setTiponotaSelectedValue(value);
    //     auxilioSelected['tiponota'] = value;
    //     updateItemsAuxilio('tiponota', value);
    // };
    // const handleTipoAuxilioonKeyDown  = (evt) => {
    //     const keyCode = evt.keyCode || evt.which
    //     if (keyCode === 8 || keyCode === 46) {
    //         setTiponotaSelectedValue('');
    //         auxilioSelected['tiponota'] = '';
    //         updateItemsAuxilio('tiponota', '');
    //         evt.returnValue = false
    //         if (evt.preventDefault) evt.preventDefault()
    //     }
    // }

    // const [prioridadSelectedValue, setPrioridadSelectedValue] = useState('');
    // const handlePrioridadSelectChange = (value, item) => {
    //     setPrioridadSelectedValue(value);
    //     auxilioSelected['prioridad'] = value;
    //     updateItemsAuxilio('prioridad', value);
    // };
    // const handlePrioridadonKeyDown  = (evt) => {
    //     const keyCode = evt.keyCode || evt.which
    //     if (keyCode === 8 || keyCode === 46) {
    //         setPrioridadSelectedValue('');
    //         auxilioSelected['prioridad'] = '';
    //         updateItemsAuxilio('prioridad', '');
    //         evt.returnValue = false
    //         if (evt.preventDefault) evt.preventDefault()
    //     }
    // }

    // const [areanotaSelectedValue, setAreanotaSelectedValue] = useState('');
    // const handleAreanotaSelectChange = (value, item) => {
    //     setAreanotaSelectedValue(value);
    //     auxilioSelected['areanota'] = value;
    //     updateItemsAuxilio('areanota', value);
    // };
    // const handleAreanotaonKeyDown  = (evt) => {
    //     const keyCode = evt.keyCode || evt.which
    //     if (keyCode === 8 || keyCode === 46) {
    //         setAreanotaSelectedValue('');
    //         auxilioSelected['areanota'] = '';
    //         updateItemsAuxilio('areanota', '');
    //         evt.returnValue = false
    //         if (evt.preventDefault) evt.preventDefault()
    //     }
    // }

    // const [estadonotaSelectedValue, setEstadonotaSelectedValue] = useState('');
    // const handleEstadonotaSelectChange = (value, item) => {
    //     setEstadonotaSelectedValue(value);
    //     auxilioSelected['estadonota'] = value;
    //     updateItemsAuxilio('estadonota', value);
    // };
    // const handleEstadonotaonKeyDown  = (evt) => {
    //     const keyCode = evt.keyCode || evt.which
    //     if (keyCode === 8 || keyCode === 46) {
    //         setEstadonotaSelectedValue('');
    //         auxilioSelected['estadonota'] = '';
    //         updateItemsAuxilio('estadonota', '');
    //         evt.returnValue = false
    //         if (evt.preventDefault) evt.preventDefault()
    //     }
    // }

    // DatePicker
    // const onFechaPlazoChange = (date) =>{
    //     if (date.preventDefault) date.preventDefault()
    //     if (date){
    //         auxilioSelected.fechaplazo = date.utc().format('YYYY-MM-DD HH:mm:ss');
    //         setFechaPlazo(date);
    //         updateItemsAuxilio('fechaplazo', auxilioSelected.fechaplazo);
    //     }
    // }
    // const onFechaPlazoFocusChange = ({ focused }) =>{
    //     setFechaPlazoPickerFocused(focused)
    // }

    // Select Beneficiario
    // const [selectedBeneficiarioItem, setSelectedBeneficiarioItem] = useState('');
    // const [inputBeneficiarioValue, setInputBeneficiarioValue] = useState('');
    // const searchBeneficiarioChanged = (e) => {
    //     setSelectedBeneficiarioItem(e);
    //     setInputBeneficiarioValue(e);
    //     auxilioSelected['relacioncon'] = e.value;
    //     updateItemsAuxilio('relacioncon', e.value);
    // }
    // const clearBeneficiarioSearch = () => {
    //     setInputBeneficiarioValue('');
    //     setSelectedBeneficiarioItem('');
    //     auxilioSelected['relacioncon'] = '';
    //     updateItemsAuxilio('relacioncon', '');
    // }
    // const onBeneficiarioUserAction = (inputValue, selectedItemVal) => {
    //     setInputBeneficiarioValue(inputValue);
    //     setSelectedBeneficiarioItem(selectedItemVal);
    // }

    // Editor
    const [editorAuxilioState, setEditorAuxilioState] = useState( () => EditorState.createEmpty() );
    const [editorObservacionState, setEditorObservacionState] = useState( () => EditorState.createEmpty() );
    const onAuxilioEditorStateChange = (eState) => {
        setEditorAuxilioState(eState);
    };
    const onObservacionEditorStateChange = (eState) => {
        setEditorObservacionState(eState);
    };
    
    // Filtro
    const toggleFilterTool = (evt)  => {
        evt.preventDefault();
        evt.stopPropagation();
        document.getElementById('filtro-tool-mobile').style.display = document.getElementById('filtro-tool-mobile').style.display==='block' ? 'none' : 'block';
    }
    const clearFilters = (evt) => {
        evt.preventDefault();
        evt.stopPropagation();
        // setEstadonotaFilterSelectedValue('');
        // query['estadonota'] = '';
        // setPrioridadFilterSelectedValue('');
        // query['prioridad'] = '';
        // setAreanotaFilterSelectedValue('');
        // query['areanota'] = '';
        // setTipoAuxilioFilterSelectedValue('');
        // query['tiponota'] = '';
        // setCreadaporFilterSelectedValue('');
        // query['creadapor'] = '';
        // setRelacionconFilterSelectedValue('');
        // // setRelacionconFilterSelectedValue(undefined);
        // document.getElementById('auxilios-fundacion-selectRelacionCon').defaultValue='';
        // document.getElementById('auxilios-fundacion-selectRelacionCon').value='';
        // query['relacioncon'] = '';

        // dispatch(fetchAuxiliosDatabyQuery({token: users.user.claveAPI, query: query} ));

        // setTimeout(() => {
        //     // console.log('Limpiando de nuevo');
        //     document.getElementById('auxilios-fundacion-selectRelacionCon').defaultValue='';
        //     document.getElementById('auxilios-fundacion-selectRelacionCon').value='';
        //     setRelacionconFilterSelectedValue('');
        // }, 1300);

    };
    const makeFilter = (q) => {
        q['page'] = '1';
        q['auxilio'] = searchText.trim() || '';
        dispatch(fetchAuxiliosDatabyQuery({token: users.user.claveAPI, query: q} ));
    }
    // Filtro por Estado
    // const [estadonotaFilterSelectedValue, setEstadonotaFilterSelectedValue] = useState('');
    // const handleEstadonotaFilterSelectChange = (value, item) => {
    //     setEstadonotaFilterSelectedValue(value);
    //     query['estadonota'] = value;
    //     makeFilter(query);
    // };
    // const handleEstadonotaFilteronKeyDown  = (evt) => {
    //     const keyCode = evt.keyCode || evt.which
    //     if (keyCode === 8 || keyCode === 46) {
    //         setEstadonotaFilterSelectedValue('');
    //         query['estadonota'] = '';
    //         makeFilter(query);
    //         evt.returnValue = false
    //         if (evt.preventDefault) evt.preventDefault()
    //     }
    // }
    // // Filtro por Prioridad
    // const [prioridadFilterSelectedValue, setPrioridadFilterSelectedValue] = useState('');
    // const handlePrioridadFilterSelectChange = (value, item) => {
    //     setPrioridadFilterSelectedValue(value);
    //     query['prioridad'] = value;
    //     makeFilter(query);
    // };
    // const handlePrioridadFilteronKeyDown  = (evt) => {
    //     const keyCode = evt.keyCode || evt.which
    //     if (keyCode === 8 || keyCode === 46) {
    //         setPrioridadFilterSelectedValue('');
    //         query['prioridad'] = '';
    //         makeFilter(query);
    //         evt.returnValue = false
    //         if (evt.preventDefault) evt.preventDefault()
    //     }
    // }
    // // Filtro por Area de Trabajo
    // const [areanotaFilterSelectedValue, setAreanotaFilterSelectedValue] = useState('');
    // const handleAreanotaFilterSelectChange = (value, item) => {
    //     setAreanotaFilterSelectedValue(value);
    //     query['areanota'] = value;
    //     makeFilter(query);
    // };
    // const handleAreanotaFilteronKeyDown  = (evt) => {
    //     const keyCode = evt.keyCode || evt.which
    //     if (keyCode === 8 || keyCode === 46) {
    //         setAreanotaFilterSelectedValue('');
    //         query['areanota'] = '';
    //         makeFilter(query);
    //         evt.returnValue = false
    //         if (evt.preventDefault) evt.preventDefault()
    //     }
    // }
    // // Filtro por Tipo Auxilio
    // const [tiponotaFilterSelectedValue, setTipoAuxilioFilterSelectedValue] = useState('');
    // const handleTipoAuxilioFilterSelectChange = (value, item) => {
    //     setTipoAuxilioFilterSelectedValue(value);
    //     query['tiponota'] = value;
    //     makeFilter(query);
    // };
    // const handleTipoAuxilioFilteronKeyDown  = (evt) => {
    //     const keyCode = evt.keyCode || evt.which
    //     if (keyCode === 8 || keyCode === 46) {
    //         setTipoAuxilioFilterSelectedValue('');
    //         query['tiponota'] = '';
    //         makeFilter(query);
    //         evt.returnValue = false
    //         if (evt.preventDefault) evt.preventDefault()
    //     }
    // }
    // Filtro por Creada por Usuario
    const [creadaporFilterSelectedValue, setCreadaporFilterSelectedValue] = useState('');
    const handleCreadaporFilterSelectChange = (value, item) => {
        // console.log(value, item.props.children);
        setCreadaporFilterSelectedValue(item.props.children);
        query['creadapor'] = value;
        makeFilter(query);
    };
    const handleCreadaporFilteronKeyDown  = (evt) => {
        const keyCode = evt.keyCode || evt.which
        if (keyCode === 8 || keyCode === 46) {
            setCreadaporFilterSelectedValue('');
            query['creadapor'] = '';
            makeFilter(query);
            evt.returnValue = false
            if (evt.preventDefault) evt.preventDefault()
        }
    }

    if (resultQueryAuxilios && listUsers ) 
    return (
        <Fragment>
            <ToastContainer />
            {isDisplayAgregarAuxilio && <AgregarAuxilio>
                    <div className="auxilio-editform-title">Crear Nueva Auxilio</div>
                    <div className="auxilio-editform-editar">
                        {errorenAgregar && <div style={{color: 'red', width: '100%'}}>
                                <Alert red showIcon style={{minWidth: '100%', marginBottom: '10px'}}
                                    showCloseLabel 
                                    onClose={() => setErrorenAgregar(null)}>
                                    <AlertMessage>
                                        Error:{' '}
                                        <strong>{errorenAgregar}</strong>
                                    </AlertMessage>
                                </Alert>
                            </div> 
                        }
                        {/* ITEMS */}
                        
                        { itemsAuxilio && 
                            itemsAuxilio.map((itm, index) => {
                                return (<div key={index} className="itemContainer">
                                    <div className="itemContainer-attr" 
                                        style={{backgroundColor:(index % 2 === 0) ? '#f8f8f8': '#fff'}}
                                    >{itm.label}</div>
                                    <div className="itemContainer-value" 
                                        style={{backgroundColor:(index % 2 === 0) ? '#f8f8f8': '#fff'}}
                                    >
                                    {   
                                        (itm.key==='id' || itm.key==='fecharegistro' || itm.key==='creadapor')
                                        ?
										<ContentEditable
                                            id={'auxilio_'+itm.key}
                                            style={{color:'#ccc'}}
											html={itm.value || ''} // innerHTML of the editable div
											className='itemContainer-value-long'
											disabled={true}      // use true to disable editing
											tagName='div' // Use a custom HTML tag (uses a div by default)
										/>
                                        :
                                        // itm.key==='tiponota' 
                                        // ?
                                        //     <Select filterable positionFixed 
                                        //         // disabled={!isEditable} 
                                        //         placeholder={'Seleccione...'}
                                        //         onChange={handleTipoAuxilioSelectChange}
                                        //         onKeyDown={handleTipoAuxilioonKeyDown}
                                        //         value={tiponotaSelectedValue || ''}
                                        //         selectedValue={tiponotaSelectedValue || ''}
                                        //     >
                                        //     {tipodeAuxilio.tipo.map(e => {
                                        //         return (
                                        //         <MenuItem key={e.id} value={e.name}>
                                        //             {e.name}
                                        //         </MenuItem>
                                        //         )
                                        //     })}
                                        //     </Select>
                                        // :
                                        // itm.key==='prioridad' 
                                        // ?
                                        //     <Select filterable positionFixed 
                                        //         // disabled={!isEditable} 
                                        //         placeholder={'Seleccione...'}
                                        //         onChange={handlePrioridadSelectChange}
                                        //         onKeyDown={handlePrioridadonKeyDown}
                                        //         value={prioridadSelectedValue || ''}
                                        //         selectedValue={prioridadSelectedValue || ''}
                                        //     >
                                        //     {prioridadAuxilio.tipo.map(e => {
                                        //         return (
                                        //         <MenuItem key={e.id} value={e.name}>
                                        //             {e.name}
                                        //         </MenuItem>
                                        //         )
                                        //     })}
                                        //     </Select>
                                        // :
                                        // itm.key==='areanota' 
                                        // ?
                                        //     <Select filterable positionFixed 
                                        //         // disabled={!isEditable} 
                                        //         placeholder={'Seleccione...'}
                                        //         onChange={handleAreanotaSelectChange}
                                        //         onKeyDown={handleAreanotaonKeyDown}
                                        //         value={areanotaSelectedValue || ''}
                                        //         selectedValue={areanotaSelectedValue || ''}
                                        //     >
                                        //     {areadeTrabajoAuxilios.tipo.map(e => {
                                        //         return (
                                        //         <MenuItem key={e.id} value={e.name}>
                                        //             {e.name}
                                        //         </MenuItem>
                                        //         )
                                        //     })}
                                        //     </Select>
                                        // :
                                        // itm.key==='estadonota' 
                                        // ?
                                        //     <Select filterable positionFixed 
                                        //         // disabled={!isEditable} 
                                        //         placeholder={'Seleccione...'}
                                        //         onChange={handleEstadonotaSelectChange}
                                        //         onKeyDown={handleEstadonotaonKeyDown}
                                        //         value={estadonotaSelectedValue || ''}
                                        //         selectedValue={estadonotaSelectedValue || ''}
                                        //     >
                                        //     {estadoAuxilios.tipo.map(e => {
                                        //         return (
                                        //         <MenuItem key={e.id} value={e.name}>
                                        //             {e.name}
                                        //         </MenuItem>
                                        //         )
                                        //     })}
                                        //     </Select>
                                        // :
                                        // itm.key==='fechaplazo' 
                                        // ?
                                        //     <DatePicker 
                                        //         placeholder="Fecha límite"
                                        //         id="fechaplazo"
                                        //         // style={{backgroundColor:(index % 2 === 0) ? '#f8f8f8 !important': '#fff !important'}}
                                        //         date={fechaPlazo}
                                        //         focused={fechaPlazoPickerFocused}
                                        //         onDateChange={onFechaPlazoChange}
                                        //         onFocusChange={onFechaPlazoFocusChange}
                                        //         numberOfMonths={1}
                                        //         // disabled={!isEditable}
                                        //         readOnly={true}
                                        //         hideInputIcon
                                        //         disableScroll={false}
                                        //         displayFormat={'YYYY-MM-DD'} // 'MMM DD YYYY'
                                        //         // isOutsideRange={() => {}}
                                        //         isOutsideRange={fechaPlazo => fechaPlazo.isBefore(minDate) || fechaPlazo.isAfter(maxDate)}
                                        //         monthYearSelectionMode="MONTH_YEAR"
                                        //         yearSelectDates={{"startYear" : 2020, "endYear" : new moment().year() + 1}}
                                        //         // displayFormat={moment.localeData().longDateFormat('L')}
                                        //         // appendToBody={true}
                                        //         // withPortal={true}
                                        //     />
                                        // :
                                        // itm.key==='relacioncon'
                                        // ?
                                        // <Search positionFixed className="val-search-benef-input"
                                        //     virtualized
                                        //     virtualizedMenuWidth={250}
                                        //     virtualizedRowHeight={75}
                                        //     placeholder={'Buscar Beneficiario...'}
                                        //     searchIcon={false}
                                        //     inputValue={inputBeneficiarioValue || itm.value}
                                        //     selectedItem={selectedBeneficiarioItem}
                                        //     onChange={searchBeneficiarioChanged}
                                        //     onUserAction={onBeneficiarioUserAction}
                                        //     onRequestClear={clearBeneficiarioSearch}
                                        //     >
                                        //     {listBenef.map((item,ix) => {
                                        //         const fotoSelBenef = (item.foto==='' || item.foto===null) ? process.env.PUBLIC_URL + '/imgs/team/usuario.jpg' : config.ECASE_URL +'user_images/'+ item.foto; 
                                        //         return ( 
                                        //         <ListItem
                                        //             key={item.docId}
                                        //             value={item.docId}
                                        //             label={capitalize(item.nombres +' '+ item.apellidos, true)}
                                        //             // leftNode={<UserIcon size={32} />}
                                        //             leftNode={fotoSelBenef ? <img src={fotoSelBenef} width={32} alt=" " /> : <UserIcon size={32} />}
                                        //         >
                                        //             <ListItemTitle>{capitalize(item.nombres +' '+ item.apellidos, true)}</ListItemTitle>
                                        //             <ListItemSubtitle>{item.actividad +' '+ item.categoria}</ListItemSubtitle>
                                        //         </ListItem>
                                        //         )
                                        //     })}
                                        // </Search>
                                        // :
                                        
                                        <ContentEditable
											id={'auxilio_'+itm.key}
											html={itm.value || ''} // innerHTML of the editable div
											className='itemContainer-value-long'
											// disabled={!isEditable}      // use true to disable editing
											onPaste={pastePlainText} // En caso se copie y pegue de otra pagina de internet
											onChange={handleChange} // handle innerHTML change
											onKeyPress={disableNewlines}  
											onFocus={highlightAll}
											onBlur={handleBlur}
											tagName='div' // Use a custom HTML tag (uses a div by default)
										/>
                                    }

                                    </div>
                                </div>)
                            })
                        }

                        <div className="auxilio-observaciones-comentarios">
                            <div className="auxilio-texto-observaciones-title">Descripción:</div>
                            <div className="auxilio-texto-observaciones"> 
                                <Editor 
                                    placeholder={'Escriba la descripción...'}
                                    editorState={editorAuxilioState}
                                    editorClassName="auxilio-comntario-editor"
                                    localization={{ locale: 'es', }}
                                    onEditorStateChange={onAuxilioEditorStateChange}
                                />
                            </div>
                        </div>
                        
                        <div className="auxilio-observaciones-comentarios">
                            <div className="auxilio-texto-observaciones-title">Observación:</div>
                            <div className="auxilio-texto-observaciones"> 
                                <Editor 
                                    placeholder={'Escriba un observación...'}
                                    editorState={editorObservacionState}
                                    // wrapperClassName="wrapper-class"
                                    // toolbarClassName="toolbar-class"
                                    editorClassName="auxilio-comntario-editor"
                                    localization={{ locale: 'es', }}
                                    onEditorStateChange={onObservacionEditorStateChange}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="auxilio-edit-botones">
                        <div className="auxilio-edit-botones-btn"> 
                            <Button iconPosition="before" onClick={saveAuxilio} icon={<SaveIcon size={16} />}
                            >Guardar</Button>
                        </div>
                        <div className="auxilio-edit-botones-btn"> 
                            <Button iconPosition="before" onClick={(evt) => cancelAuxilio(auxilioSelected.id)} icon={<ResetIcon size={16} />}
                            >Cancelar</Button>
                        </div>
                    </div>
                </AgregarAuxilio>
            }
            {/* EDIT NOTE */}
            {isDisplayEditAuxilio && <EditAuxilio>
                    <div className="auxilio-editform-title">Editar Auxilio</div>
                    <div className="auxilio-editform-editar">
                        {error && <div style={{color: 'red', width: '100%'}}>
                                <Alert red showIcon style={{minWidth: '100%', marginBottom: '10px'}}
                                    showCloseLabel 
                                    onClose={() => setError(null)}>
                                    <AlertMessage>
                                        Error:{' '}
                                        <strong>{error}</strong>
                                    </AlertMessage>
                                </Alert>
                            </div> 
                        }
                        {/* ITEMS */}
                        { itemsAuxilio && 
                            itemsAuxilio.map((itm, index) => {
                                return (<div key={index} className="itemContainer">
                                    <div className="itemContainer-attr" 
                                        style={{backgroundColor:(index % 2 === 0) ? '#f8f8f8': '#fff'}}
                                    >{itm.label}</div>
                                    <div className="itemContainer-value" 
                                        style={{backgroundColor:(index % 2 === 0) ? '#f8f8f8': '#fff'}}
                                    >
                                    {   
                                        (itm.key==='id' || itm.key==='fecharegistro' || itm.key==='creadapor')
                                        ?
										<ContentEditable
                                            id={'auxilio_'+itm.key}
                                            style={{color:'#ccc'}}
											html={itm.value || ''} // innerHTML of the editable div
											className='itemContainer-value-long'
											disabled={true}      // use true to disable editing
											tagName='div' // Use a custom HTML tag (uses a div by default)
										/>
                                        :
                                        // itm.key==='tiponota' 
                                        // ?
                                        //     <Select filterable positionFixed 
                                        //         disabled={!isEditable} 
                                        //         placeholder={'Seleccione...'}
                                        //         onChange={handleTipoAuxilioSelectChange}
                                        //         onKeyDown={handleTipoAuxilioonKeyDown}
                                        //         value={tiponotaSelectedValue || ''}
                                        //         selectedValue={tiponotaSelectedValue || ''}
                                        //     >
                                        //     {tipodeAuxilio.tipo.map(e => {
                                        //         return (
                                        //         <MenuItem key={e.id} value={e.name}>
                                        //             {e.name}
                                        //         </MenuItem>
                                        //         )
                                        //     })}
                                        //     </Select>
                                        // :
                                        // itm.key==='prioridad' 
                                        // ?
                                        //     <Select filterable positionFixed 
                                        //         disabled={!isEditable} 
                                        //         placeholder={'Seleccione...'}
                                        //         onChange={handlePrioridadSelectChange}
                                        //         onKeyDown={handlePrioridadonKeyDown}
                                        //         value={prioridadSelectedValue || ''}
                                        //         selectedValue={prioridadSelectedValue || ''}
                                        //     >
                                        //     {prioridadAuxilio.tipo.map(e => {
                                        //         return (
                                        //         <MenuItem key={e.id} value={e.name}>
                                        //             {e.name}
                                        //         </MenuItem>
                                        //         )
                                        //     })}
                                        //     </Select>
                                        // :
                                        // itm.key==='areanota' 
                                        // ?
                                        //     <Select filterable positionFixed 
                                        //         disabled={!isEditable} 
                                        //         placeholder={'Seleccione...'}
                                        //         onChange={handleAreanotaSelectChange}
                                        //         onKeyDown={handleAreanotaonKeyDown}
                                        //         value={areanotaSelectedValue || ''}
                                        //         selectedValue={areanotaSelectedValue || ''}
                                        //     >
                                        //     {areadeTrabajoAuxilios.tipo.map(e => {
                                        //         return (
                                        //         <MenuItem key={e.id} value={e.name}>
                                        //             {e.name}
                                        //         </MenuItem>
                                        //         )
                                        //     })}
                                        //     </Select>
                                        // :
                                        // itm.key==='estadonota' 
                                        // ?
                                        //     <Select filterable positionFixed 
                                        //         disabled={!isEditable} 
                                        //         placeholder={'Seleccione...'}
                                        //         onChange={handleEstadonotaSelectChange}
                                        //         onKeyDown={handleEstadonotaonKeyDown}
                                        //         value={estadonotaSelectedValue || ''}
                                        //         selectedValue={estadonotaSelectedValue || ''}
                                        //     >
                                        //     {estadoAuxilios.tipo.map(e => {
                                        //         return (
                                        //         <MenuItem key={e.id} value={e.name}>
                                        //             {e.name}
                                        //         </MenuItem>
                                        //         )
                                        //     })}
                                        //     </Select>
                                        // :
                                        // itm.key==='fechaplazo' 
                                        // ?
                                        //     <DatePicker 
                                        //         placeholder="Fecha límite"
                                        //         id="fechaplazo"
                                        //         // style={{backgroundColor:(index % 2 === 0) ? '#f8f8f8 !important': '#fff !important'}}
                                        //         date={fechaPlazo}
                                        //         focused={fechaPlazoPickerFocused}
                                        //         onDateChange={onFechaPlazoChange}
                                        //         onFocusChange={onFechaPlazoFocusChange}
                                        //         numberOfMonths={1}
                                        //         disabled={!isEditable}
                                        //         readOnly={true}
                                        //         hideInputIcon
                                        //         disableScroll={false}
                                        //         displayFormat={'YYYY-MM-DD'} // 'MMM DD YYYY'
                                        //         // isOutsideRange={() => {}}
                                        //         isOutsideRange={fechaPlazo => fechaPlazo.isBefore(minDate) || fechaPlazo.isAfter(maxDate)}
                                        //         monthYearSelectionMode="MONTH_YEAR"
                                        //         yearSelectDates={{"startYear" : 2000, "endYear" : new moment().year() + 1}}
                                        //         // displayFormat={moment.localeData().longDateFormat('L')}
                                        //         // appendToBody={true}
                                        //         // withPortal={true}
                                        //     />
                                        // :
                                        // itm.key==='relacioncon'
                                        // ?
                                        // <Search positionFixed className="val-search-benef-input"
                                        //     disabled={!isEditable} 
                                        //     virtualized
                                        //     virtualizedMenuWidth={250}
                                        //     virtualizedRowHeight={75}
                                        //     placeholder={'Buscar Beneficiario...'}
                                        //     searchIcon={false}
                                        //     inputValue={inputBeneficiarioValue || itm.value}
                                        //     selectedItem={selectedBeneficiarioItem}
                                        //     onChange={searchBeneficiarioChanged}
                                        //     onUserAction={onBeneficiarioUserAction}
                                        //     onRequestClear={clearBeneficiarioSearch}
                                        //     >
                                        //     {listBenef.map((item,ix) => {
                                        //         const fotoSelBenef = (item.foto==='' || item.foto===null) ? process.env.PUBLIC_URL + '/imgs/team/usuario.jpg' : config.ECASE_URL +'user_images/'+ item.foto; 
                                        //         return ( 
                                        //         <ListItem
                                        //             key={item.docId}
                                        //             value={item.docId}
                                        //             label={capitalize(item.nombres +' '+ item.apellidos, true)}
                                        //             // leftNode={<UserIcon size={32} />}
                                        //             leftNode={fotoSelBenef ? <img src={fotoSelBenef} width={32} alt=" " /> : <UserIcon size={32} />}
                                        //         >
                                        //             <ListItemTitle>{capitalize(item.nombres +' '+ item.apellidos, true)}</ListItemTitle>
                                        //             <ListItemSubtitle>{item.actividad +' '+ item.categoria}</ListItemSubtitle>
                                        //         </ListItem>
                                        //         )
                                        //     })}
                                        // </Search>
                                        // :

                                        <ContentEditable
											id={'auxilio_'+itm.key}
											html={itm.value || ''} // innerHTML of the editable div
											className='itemContainer-value-long'
											disabled={!isEditable}      // use true to disable editing
											onPaste={pastePlainText} // En caso se copie y pegue de otra pagina de internet
											onChange={handleChange} // handle innerHTML change
											onKeyPress={disableNewlines}  
											onFocus={highlightAll}
											onBlur={handleBlur}
											tagName='div' // Use a custom HTML tag (uses a div by default)
										/>
                                    }

                                    </div>
                                </div>)
                            })
                        }

                        <div className="auxilio-observaciones-comentarios">
                            <div className="auxilio-texto-observaciones-title">Descripción:</div>
                            <div className="auxilio-texto-observaciones"> 
                            { isEditable ?
                                <Editor placeholder={'Escriba la descripción...'}
                                    editorState={editorAuxilioState}
                                    editorClassName="auxilio-comntario-editor"
                                    localization={{ locale: 'es', }}
                                    onEditorStateChange={onAuxilioEditorStateChange}
                                />
                                :
                                    <ContentEditable
                                        id={'notaedit_nota'}
                                        html={auxilioSelected.auxilio || ''} // innerHTML of the editable div
                                        // disabled={users.user.docId===auxilioSelected.creadapor ? false : true}        // use true to disable editing
                                        disabled={true} 
                                        onPaste={pastePlainText}
                                        onChange={handleChange} // handle innerHTML change
                                        onFocus={highlightAll}
                                        onBlur={handleBlur}
                                        tagName='div' // Use a custom HTML tag (uses a div by default)
                                        style={{ "minHeight": "100px", "border": "1px dashed", backgroundColor:'#fdfdca', padding:'0px 8px' }}
                                    />
                                }
                            </div>
                        </div>
                        
                        <div className="auxilio-observaciones-comentarios">
                            <div className="auxilio-texto-observaciones-title">Observación:</div>
                            <div className="auxilio-texto-observaciones"> 
                                {/* {isEditable ? */}
                                    <Editor placeholder={'Escriba un observación...'}
                                        editorState={editorObservacionState}
                                        editorClassName="auxilio-comntario-editor"
                                        toolbar={{
                                            inline: { inDropdown: true },
                                            list: { inDropdown: true },
                                            textAlign: { inDropdown: true },
                                            link: { inDropdown: true },
                                            embedded: { inDropdown: true },
                                            emoji: { inDropdown: true },
                                            image: { inDropdown: true },
                                            remove: { inDropdown: true },
                                            history: { inDropdown: true },
                                        }}
                                        localization={{ locale: 'es', }}
                                        onEditorStateChange={onObservacionEditorStateChange}
                                    />
                            </div>
                        </div>

                    </div>
                    <div className="auxilio-edit-botones">
                        <div className="auxilio-edit-botones-btn"> 
                            <Button iconPosition="before" onClick={saveAuxilio} icon={<SaveIcon size={16} />}
                            >Guardar</Button>
                        </div>
                        {isEditable && <div className="auxilio-edit-botones-btn"> 
                            <Button iconPosition="before" onClick={(evt) => eliminarAuxilio(evt, auxilioSelected.id)} icon={<TrashIcon size={16} />}
                            >Eliminar</Button>
                        </div>}
                        {/* <div className="auxilio-edit-botones-btn"> 
                            <Button iconPosition="before" onClick={(evt) => cancelAuxilio(auxilioSelected.id)} icon={<ResetIcon size={16} />}
                            >Cancelar</Button>
                        </div> */}
                    </div>
                </EditAuxilio>
            }

            {resultQueryAuxilios && resultQueryAuxilios.datos.length<0 && <Loader color="#009937" text="Cargando Auxilios..."/>}


            {!isDisplayEditAuxilio && !isDisplayAgregarAuxilio && 
                <div className='auxilios-fundacion-lista'>
                    <div className="auxilios-fundacion-toolbar-container">
                        <div className="auxilios-fundacion-toolbar">
                            <div className="auxilios-fundacion-toolbar-left">
                                <div className="auxilios-fundacion-toolbarLeft-tools">
                                    <div className="auxilios-fundacion-toolbarLeft-btn">
                                        <Button inline small iconPosition="before" 
                                            onClick={onBtnAgregarAuxilio}
                                            icon={<PlusIcon size={16} />}
                                        >
                                            Agregar
                                        </Button>
                                    </div>
                                    <div className="auxilios-fundacion-toolbarLeft-btn">
                                        <Button inline small iconPosition="before" 
                                            onClick={onBtnFiltrarAuxilio}
                                            icon={<FilterIcon size={16} />}
                                        >
                                            Filtrar
                                        </Button>
                                    </div>
                                </div>
                            </div>
                            <div className="auxilios-fundacion-toolbar-right">
                                <div className="auxilios-fundacion-toolbarRight-tools">
                                    {/* <div className="auxilios-fundacion-toolbarLeft-btn">
                                        <Button inline small iconPosition="before" 
                                            onClick={onBtnAgregarAuxilio}
                                            icon={<CalendarIcon size={16} />}
                                        >
                                            Fecha
                                        </Button>
                                    </div> */}
                                    <div className="auxilios-fundacion-toolbarLeft-btn">
                                        <div className="auxilios-fundacion-toolbarLeft-btn-Search">
                                            <TextField style={{display:'inline-flex'}}
                                                value={searchText}
                                                onChange={e => onSearchChange(e.target.value)}
                                                onRequestClear={onSearchRequestClear}
                                                // onBlur={(e) => handleSearchBlur(e.target.value)}
                                                onKeyPress={handleSearchKeyPress}
                                                search
                                                placeholder="Buscar..."
                                            />
                                            <div className="auxilios-fundacion-toolbarLeft-btnBuscar"
                                                onClick={buscarTexto}
                                                >Buscar</div>
                                        </div>
                                        <div className="auxilios-fundacion-toolbarLeft-btn-SearchSmall">
                                            <Button inline small iconPosition="before" 
                                                onClick={toggleBuscarTool}
                                                icon={<SearchIcon size={16} />}
                                            >
                                                Buscar
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Espacio para el Filtro y opciones para Mobiles */}
                    <div className="auxilios-fundacion-options-container">
                        <div id='buscar-tool-mobile' className="auxilios-fundacion-toolbarLeft-btn-Search-mobile">
                            <TextField style={{display:'inline-flex'}}
                                value={searchText}
                                onChange={e => onSearchChange(e.target.value)}
                                onRequestClear={onSearchRequestClear}
                                onKeyPress={handleSearchKeyPress}
                                search
                                placeholder="Buscar..."
                            />
                            <div className="auxilios-fundacion-toolbarLeft-btnBuscar"
                                onClick={buscarTexto}
                                >Buscar</div>
                        </div>
                        
                        {/* FILTROS */}
                        <div id='filtro-tool-mobile' className="auxilios-fundacion-lista-filtroMobile">
                            <div className="auxilios-fundacion-lista-filtro">
                                <div className="auxilios-fundacion-lista-optfiltros">
                                    {/* <div className="auxilios-fundacion-lista-optfiltros-toolContainer">
                                         <div className="auxilios-fundacion-lista-optfiltros-tool">
                                            <div className="auxilios-fundacion-lista-optfiltros-toolname">
                                                Estado:
                                            </div>
                                            <Select filterable positionFixed style={{width:'120px'}}
                                                // disabled={!isEditable} 
                                                placeholder={'Estado...'}
                                                onChange={handleEstadonotaFilterSelectChange}
                                                onKeyDown={handleEstadonotaFilteronKeyDown}
                                                value={estadonotaFilterSelectedValue || ''}
                                                selectedValue={estadonotaFilterSelectedValue || ''}
                                            >
                                            {estadoAuxilios.tipo.map(e => {
                                                return (
                                                <MenuItem key={e.id} value={e.name}>
                                                    {e.name}
                                                </MenuItem>
                                                )
                                            })}
                                            </Select>
                                        </div>
                                    </div>
                                    <div className="auxilios-fundacion-lista-optfiltros-toolContainer">
                                        <div className="auxilios-fundacion-lista-optfiltros-tool">
                                            <div className="auxilios-fundacion-lista-optfiltros-toolname">
                                                Prioridad:
                                            </div>
                                            <Select filterable positionFixed style={{width:'120px'}}
                                                // disabled={!isEditable} 
                                                placeholder={'Prioridad...'}
                                                onChange={handlePrioridadFilterSelectChange}
                                                onKeyDown={handlePrioridadFilteronKeyDown}
                                                value={prioridadFilterSelectedValue || ''}
                                                selectedValue={prioridadFilterSelectedValue || ''}
                                            >
                                            {prioridadAuxilio.tipo.map(e => {
                                                return (
                                                <MenuItem key={e.id} value={e.name}>
                                                    {e.name}
                                                </MenuItem>
                                                )
                                            })}
                                            </Select>
                                        </div>
                                    </div>
                                    <div className="auxilios-fundacion-lista-optfiltros-toolContainer">
                                        <div className="auxilios-fundacion-lista-optfiltros-tool">
                                            <div className="auxilios-fundacion-lista-optfiltros-toolname">
                                                Area de Trabajo:
                                            </div>
                                            <Select filterable positionFixed 
                                                // disabled={!isEditable} 
                                                placeholder={'Area...'}
                                                onChange={handleAreanotaFilterSelectChange}
                                                onKeyDown={handleAreanotaFilteronKeyDown}
                                                value={areanotaFilterSelectedValue || ''}
                                                selectedValue={areanotaFilterSelectedValue || ''}
                                            >
                                            {areadeTrabajoAuxilios.tipo.map(e => {
                                                return (
                                                <MenuItem key={e.id} value={e.name}>
                                                    {e.name}
                                                </MenuItem>
                                                )
                                            })}
                                            </Select>
                                        </div>
                                    </div>
                                    <div className="auxilios-fundacion-lista-optfiltros-toolContainer">
                                        <div className="auxilios-fundacion-lista-optfiltros-tool">
                                            <div className="auxilios-fundacion-lista-optfiltros-toolname">
                                                Tipo de Auxilio:
                                            </div>
                                            <Select filterable positionFixed 
                                                // disabled={!isEditable} 
                                                placeholder={'Tipo Auxilio...'}
                                                onChange={handleTipoAuxilioFilterSelectChange}
                                                onKeyDown={handleTipoAuxilioFilteronKeyDown}
                                                value={tiponotaFilterSelectedValue || ''}
                                                selectedValue={tiponotaFilterSelectedValue || ''}
                                            >
                                            {tipodeAuxilio.tipo.map(e => {
                                                return (
                                                <MenuItem key={e.id} value={e.name}>
                                                    {e.name}
                                                </MenuItem>
                                                )
                                            })}
                                            </Select>
                                        </div>
                                    </div> */}
                                    <div className="auxilios-fundacion-lista-optfiltros-toolContainer">
                                        <div className="auxilios-fundacion-lista-optfiltros-tool">
                                            <div className="auxilios-fundacion-lista-optfiltros-toolname">
                                                Creada por:
                                            </div>
                                            <Select filterable positionFixed 
                                                // disabled={!isEditable} 
                                                placeholder={'Creada por...'}
                                                onChange={handleCreadaporFilterSelectChange}
                                                onKeyDown={handleCreadaporFilteronKeyDown}
                                                value={creadaporFilterSelectedValue || ''}
                                                selectedValue={creadaporFilterSelectedValue || ''}
                                            >
                                            {listUsers.map(e => {
                                                return (
                                                <MenuItem key={e.id} value={e.docId}>
                                                    {capitalize(e.nombre, true)}
                                                </MenuItem>
                                                )
                                            })}
                                            </Select>
                                        </div>
                                    </div>
                                    

                                </div>
                            </div>
                            <div className="auxilio-edit-botones-btnRemoveFilter"> 
                                <Button iconPosition="before" onClick={clearFilters} icon={<ResetIcon size={16} />}
                                >Quitar Filtros</Button>
                            </div>
                        </div>
                        
                    </div>


                    {/* <h2>Cantidad de Registros: {resultQueryAuxilios.stats.count || 0}</h2> */}
                    { resultQueryAuxilios && resultQueryAuxilios.datos.length>0 ? 
                        <div className='prestamos-fundacion-tablaContainer'>
                        <div className='prestamos-fundacion-tabla'>
                        { resultQueryAuxilios.datos.map((itm, index) => {
                            // const numprestamo = index;
                            // const foto_creador = itm.foto==='' ? process.env.PUBLIC_URL + '/imgs/team/usuario.jpg' : config.ECASE_URL +'user_images/'+ Beneficiario.foto; 
                            // const foto_creador = (itm.foto==='' || itm.foto===null) ? process.env.PUBLIC_URL + '/imgs/team/usuario.jpg' : process.env.PUBLIC_URL + '/imgs/team/'+ itm.foto; 
                            let fotobenef = (itm.fotobenef==='' || itm.fotobenef===null) ? process.env.PUBLIC_URL + '/imgs/team/usuario.jpg' : config.ECASE_URL +'user_images/'+ itm.fotobenef;
                            if (itm.beneficiarioId==='' || itm.beneficiarioId==='FUNDACIÓN'){
                                fotobenef = process.env.PUBLIC_URL + '/imgs/team/fundacion.png';
                            }
                            const benefnombre = (itm.benefnombres==='' || itm.benefnombres===null) ? '' : capitalize(itm.benefnombres,true);
                            const benefapellidos = (itm.benefapellidos==='' || itm.benefapellidos===null) ? '' : capitalize(itm.benefapellidos,true);
                            // const fullName = (itm.nombre==='' || itm.nombre===null) ? '' : capitalize(itm.nombre, true);
                            // const nombre = fullName ? fullName.split(' ')[0] +' '+ fullName.split(' ')[1] : '';
                            // const iniciales = getFirstWords(fullName);
                            // const fecharegistro = itm.fecharegistro ? moment(itm.fecharegistro, 'YYYY-MM-DD').format('YYYY-MMM-DD').split('-') : '';
                            const fecharegistro  = itm.fecharegistro ? moment(itm.fecharegistro, 'YYYY-MM-DD').format('MMMM DD, YYYY') : '';
                            const fechaaprobado  = itm.fechaaprobado ? moment(itm.fechaaprobado, 'YYYY-MM-DD').format('MMMM DD, YYYY') : '';
                            // const fechadesembolso  = itm.fechadesembolso ? moment(itm.fechadesembolso, 'YYYY-MM-DD').format('MMM DD, YYYY') : '';
                            return (<div key={index}  className="prestamos-fundacion-lista-row" style={{backgroundColor:(index % 2 === 0) ? '#fff': '#fff'}}
                                    onClick={(evt) => onBtnEditAuxilio(evt, itm.id, index)} >
                                
                                {/* <div className="prestamos-fundacion-lista-cell">{itm.id}</div> */}
                                <div className="prestamos-fundacion-header">
                                    <div className="prestamos-fundacion-lista-header-left">
                                        <div className="prestamos-fundacion-componente-column">
                                            <div className="prestamos-fundacion-componente-row">
                                                <div className="prestamos-fundacion-componente-beneficiario">
                                                    {itm.activo==='0' && <div className="prestamos-beneficiario-activo-noactivo">No activo</div>}
                                                    {itm.activo==='1' && <div className="prestamos-beneficiario-activo-activo">Activo</div>}
                                                    <div className="prestamos-fundacion-lista-relacioncon-foto"><img src={fotobenef} alt=' ' /></div>
                                                    <div className="prestamos-fundacion-lista-relacioncon-docId">{itm.beneficiarioId}</div>
                                                </div>
                                                <div className="prestamos-fundacion-componente-column">
                                                    {itm.estado && itm.estado.toLowerCase()==='revisar' && <div className="prestamos-beneficiario-estado prestamos-beneficiario-estado-revisar">{itm.estado}</div>}
                                                    {itm.estado && itm.estado.toLowerCase()==='visitar' && <div className="prestamos-beneficiario-estado prestamos-beneficiario-estado-visitar">{itm.estado}</div>}
                                                    {itm.estado && itm.estado.toLowerCase()==='retirado' && <div className="prestamos-beneficiario-estado prestamos-beneficiario-estado-retirado">{itm.estado}</div>}
                                                    {itm.estado && itm.estado.toLowerCase()==='fallecido' && <div className="prestamos-beneficiario-estado prestamos-beneficiario-estado-fallecido">{itm.estado}</div>}
                                                    {itm.estado && itm.estado.toLowerCase()==='noapto' && <div className="prestamos-beneficiario-estado prestamos-beneficiario-estado-noapto">{itm.estado}</div>}
                                                    {itm.estado && itm.estado.toLowerCase()==='pendiente' && <div className="prestamos-beneficiario-estado prestamos-beneficiario-estado-pendiente">{itm.estado}</div>}
                                                    {itm.estado && itm.estado.toLowerCase()==='prueba' && <div className="prestamos-beneficiario-estado prestamos-beneficiario-estado-prueba">{itm.estado}</div>}
                                                    {itm.estado && itm.estado.toLowerCase()==='suspendido' && <div className="prestamos-beneficiario-estado prestamos-beneficiario-estado-suspendido">{itm.estado}</div>}
                                                    {itm.estado && itm.estado.toLowerCase()==='actualizado' && <div className="prestamos-beneficiario-estado prestamos-beneficiario-estado-actualizado">{itm.estado}</div>}

                                                    <div className="prestamos-fundacion-lista-relacioncon-nombre multi-line-ellipsis"><strong>{benefnombre}</strong> {benefapellidos}</div>
                                                    <div className="prestamos-beneficiario-actividad one-line-ellipsis">{itm.actividad}</div>
                                                    {itm.celular!=='' && itm.celular!==undefined && itm.celular!==null && 
                                                        <div className="prestamos-beneficiario-celular">
                                                            <MobileIcon size={16} style={{marginRight:'4px'}}/>{ itm.celular } 
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            
                                            {/* <div className="prestamos-fundacion-componente-column">
                                                <div className="prestamos-beneficiario-prestarservicio">{itm.prestarservicio}</div>
                                            </div> */}
                                        </div>
                                    </div>

                                    <div className="prestamos-fundacion-lista-header-center">
                                        <div className="prestamos-fundacion-componente-column">
                                            <div className="prestamos-fundacion-componente-row">
                                                <div className="prestamos-fundacion-lista-programa">{itm.programa ? itm.programa.toUpperCase() : ''} - </div>
                                                <div className="prestamos-fundacion-lista-tipoprestamo">{itm.subprograma!=='' ? capitalize(itm.subprograma, true) : ''} - </div>
                                                <div className="prestamos-fundacion-lista-tipoprestamo">{itm.plan!=='' ? capitalize(itm.plan, true) : ''} - </div>
                                            </div>
                                            <div className="prestamos-fundacion-componente-row">
                                                <div className="prestamos-fundacion-lista-tipoprestamo">{itm.tipoauxilio!=='' ? capitalize(itm.tipoauxilio, true) : ''} - </div>
                                                <div className="prestamos-fundacion-lista-claseprestamo">{itm.claseauxilio!=='' ? capitalize(itm.claseauxilio, true) : ''}</div>
                                            </div>
                                            {itm.descripcion!=='' && <div className="prestamos-fundacion-lista-descripcion multi-line-ellipsis">{itm.descripcion.toLowerCase()}</div>}

                                            <div className="prestamos-fundacion-componente-column">
                                                <div className="prestamos-fundacion-lista-fechaaprobado">Aprobado: {capitalize(fechaaprobado,true)}</div>
                                                <div className="prestamos-fundacion-lista-fechaaprobado">Periodo: {itm.periodo}</div>
                                                {/* {itm.familiar && <div className="prestamos-fundacion-lista-fiador">Familiar: <span>{itm.familiar}</span></div>} */}
                                                {itm.familiarId && <div className="prestamos-fundacion-lista-fiadorId">{itm.familiarId}</div>}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="prestamos-fundacion-lista-header-center">
                                        <div className="prestamos-fundacion-componente-column">
                                            {itm.entidaddesalud && <div className="prestamos-fundacion-lista-entidaddesalud">{itm.entidaddesalud}</div>}
                                            <div className="prestamos-fundacion-lista-diacorte">% Cumplim.: <span>{itm.porcentcumpli}</span></div>
                                            {/* <div className="prestamos-fundacion-lista-diacorte">Educacion: <span>{itm.educacion}</span></div> */}
                                            {/* <div className="prestamos-fundacion-lista-diacorte">Institución: <span>{itm.colegiouniversidad}</span></div> */}
                                            <div className="prestamos-fundacion-lista-diacorte">Nivel Escolaridad: <span>{itm.nivelescolaridad}</span></div>
                                            <div className="prestamos-fundacion-lista-diacorte">Promedio: <span>{itm.promedio}</span></div>
                                        </div>
                                    </div>

                                    <div className="prestamos-fundacion-lista-header-center">
                                        <div className="prestamos-fundacion-componente-column">
                                            {itm.entidaddesalud && <div className="prestamos-fundacion-lista-entidaddesalud">{itm.entidaddesalud}</div>}
                                            <div className="prestamos-fundacion-lista-diacorte">Educacion: <span>{itm.educacion}</span></div>
                                            <div className="prestamos-fundacion-lista-diacorte">Institución: <span>{itm.colegiouniversidad}</span></div>
                                            <div className="prestamos-fundacion-lista-vlrcuota">Valor Matricula: <span>$ {formatMoney(itm.vlrmatriculaaprobada)}</span></div>
                                            <div className="prestamos-fundacion-lista-vlrcuota">Valor Mensualidad: <span>$ {formatMoney(itm.vlrmensualidadaprobada)}</span></div>
                                            <div className="prestamos-fundacion-lista-vlrcuota">Valor Cancelado: <span>$ {formatMoney(itm.vlrcancelado)}</span></div>
                                        </div>
                                    </div>

                                    {/* <div className="prestamos-fundacion-lista-header-center">
                                        <div className="prestamos-fundacion-componente-column">
                                            <div className="prestamos-fundacion-componente-row">
                                                <div className="prestamos-fundacion-lista-plazo">
                                                    <div className="prestamos-fundacion-lista-plazoTitle">Plazo</div>
                                                    <div className="prestamos-fundacion-lista-plazoValue">{itm.plazo} meses</div>
                                                </div>
                                                <div className="prestamos-fundacion-lista-tasapactada">
                                                    <div className="prestamos-fundacion-lista-tasapactadaTitle">Tasa</div>
                                                    <div className="prestamos-fundacion-lista-tasapactadaValue">{parseFloat(itm.tasapactada).toFixed(2)} %</div>
                                                </div>
                                                <div className="prestamos-fundacion-lista-cuotaspendientes">
                                                    <div className="prestamos-fundacion-lista-cuotaspendientesTitle">Cuotas Pend.</div>
                                                    <div className="prestamos-fundacion-lista-cuotaspendientesValue">{itm.cuotaspendientes}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}

                                    <div className="prestamos-fundacion-lista-header-right">
                                        <div className="prestamos-fundacion-componente-column">
                                            <div className="prestamos-fundacion-componente-column">
                                                <div className="prestamos-fundacion-lista-comprobdesembolso">Comprobante <span>{itm.comprobante}</span></div>
                                                <div className="prestamos-fundacion-lista-fechadesembolso">{capitalize(fecharegistro,true)}</div>
                                                <div className="prestamos-fundacion-lista-monto">$ {formatMoney(itm.monto)}</div>
                                                
                                                { itm.estadoaux && itm.estadoaux.toLowerCase()==='activo' && <div className="prestamos-fundacion-lista-estadoaux estadoaux-activo">{itm.estadoaux.toUpperCase()}</div>}
                                                { itm.estadoaux && itm.estadoaux.toLowerCase()==='pendiente' && <div className="prestamos-fundacion-lista-estadoaux estadoaux-pendiente">{itm.estadoaux.toUpperCase()}</div>}
                                                { itm.estadoaux && itm.estadoaux.toLowerCase()==='finalizado' && <div className="prestamos-fundacion-lista-estadoaux estadoaux-finalizado">{itm.estadoaux.toUpperCase()}</div>}
                                                { itm.estadoaux && itm.estadoaux.toLowerCase()==='mora' && <div className="prestamos-fundacion-lista-estadoaux estadoaux-mora">{itm.estadoaux.toUpperCase()}</div>}
                                                { itm.estadoaux && itm.estadoaux.toLowerCase()==='anulado' && <div className="prestamos-fundacion-lista-estadoaux estadoaux-anulado">{itm.estadoaux.toUpperCase()}</div>}
                                                
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                {/* <div className="prestamos-fundacion-lista-footer">
                                    <div className="prestamos-fundacion-lista-comprobdesembolso">{itm.comprobante}</div>
                                    <div className="prestamos-fundacion-lista-actualizacion">Última actualización: {itm.actualizacion} por <span>{itm.modificadopor}</span></div>
                                </div> */}
                                <div className="prestamos-fundacion-lista-actualizacion"><span>{itm.modificadopor} - {itm.actualizacion}</span></div>

                            </div>)
                            })
                        }
                        </div>
                        
                    </div>
                :
                    <div>No hay registros</div>
                }
                </div>

            }
            
            {/* Pagenation */}
            {!isDisplayEditAuxilio && !isDisplayAgregarAuxilio &&  resultQueryAuxilios && resultQueryAuxilios.datos.length>0 && 
                <div className="auxilios-fundacion-tabla-footerContainer">
                    <div className="auxilios-fundacion-tabla-footer">
                        <div className="auxilios-fundacion-cantidad">{resultQueryAuxilios.stats.count || 0} {resultQueryAuxilios.stats.count>1 ? 'auxilios' : 'auxilio'}</div>
                        <div className="auxilios-fundacion-pagina">Página {currentPage+1} de {totalPages}</div>
                    </div>
                    
                    <Pagination
                        className="pagination pagination-auxilios"
                        totalPages={totalPages}
                        currentPage={currentPage}
                        showPages={showPages}
                        onChangePage={onPageNumberChange}
                    />
                </div>
            }
            

        </Fragment>
    );
    return (<></>)
}
  
class TableAuxilios extends React.Component {
    constructor(props) {
      super(props);
      this.state = {};
    }
    
    render() {
    //   var {dataList} = this.state;
      const {containerHeight, containerWidth} = this.props; //{height, width, ...props}
      return (
        <div style={{ width:containerWidth, height:containerHeight }}>
            <ModuleAuxilios />
        </div>
      );
    }
}

// See react-dimensions for the best way to configure
// https://github.com/digidem/react-dimensions
export default Dimensions({
    elementResize: true,
  
    getHeight: function(element) {
      // return window.innerHeight - 300;
    //   console.log(element.parentElement.clientHeight);
      let heightOffset = 50;
      let heightParent = element.clientHeight;
      return heightParent - heightOffset;
    },
    getWidth: function(element) {
      // var widthOffset = window.innerWidth < 680 ? 52 : 412;
      // return window.innerWidth - widthOffset;
      let widthOffset = 5;
      return element.clientWidth - widthOffset;
    }
})(TableAuxilios);