import React, {Fragment, useState, useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";

import {highlightAll, disableNewlines, pastePlainText, formatMoney } from '../../utils/utils'; //capitalize
import ContentEditable from 'react-contenteditable';
import {showMsgToaster} from '../ShowMesg';
import {ToastContainer} from 'calcite-react/Toaster'; //notify
import Alert, {AlertMessage} from 'calcite-react/Alert';
import Button from 'calcite-react/Button';
import Table, {
    TableHeader,
    TableHeaderRow,
    TableHeaderCell,
    TableBody,
    TableRow,
    TableCell
} from 'calcite-react/Table';
import Select from 'calcite-react/Select';
import {MenuItem} from 'calcite-react/Menu';
import * as XLSX from 'xlsx';
// import DatePicker from 'calcite-react/DatePicker';
import moment from 'moment';
import 'moment/locale/es-us';

import { serverMensaje,
    fetchHistoPrestamosDatabyQuery, 
    // resetHistoPrestamosDatabyQuery,
    // updateEditedHistoPrestamoData,
    updateHistoPrestamo,
    insertHistoPrestamo,
    deleteHistoPrestamo,
} from '../../redux/reducers/prestamos';

import medioAbonoPrestamo from '../../data/medioabonoprestamo.json';

import styled from "styled-components";

import PlusIcon from 'calcite-ui-icons-react/PlusIcon';
import SaveIcon from 'calcite-ui-icons-react/SaveIcon';
import TrashIcon from 'calcite-ui-icons-react/TrashIcon';
import DownloadIcon from 'calcite-ui-icons-react/DownloadIcon';
import CalculatorIcon from 'calcite-ui-icons-react/CalculatorIcon';

const ProyeccionContainer = styled.div`
    height: auto;
    background: #fff;
    border: 0;
    padding: 0px;
    margin: 4px;
    overflow:hidden;
`;


const HistoricoPrestamos = ({prestamoSelected}) => {
    const dispatch = useDispatch();
    const users = useSelector(state => state.users);
    const isEditable = (users.user.areatrabajo==='ADMINISTRATIVA' || users.user.areatrabajo==='COORDINACIÓN' || users.user.areatrabajo==='DIRECCIÓN' ) ? true : false;
    // const canView = (users.user.areatrabajo==='ADMINISTRATIVA' || users.user.areatrabajo==='TECNOLOGÍA' ) ? true : false;
    const resultQueryHistoPrestamos = useSelector(state => state.prestamos.resultQueryHistoPrestamos);
    const mensaje = useSelector(state => state.prestamos.mensaje);
    const [error, setError] = useState(null);
    const [P, updateAbono] = useState([]); //Object.assign({}, prestamos); // Arreglos no Objects
    const [isBtnAddEnable, setIsBtnAddEnable] = useState(false);
    const [vlrUltimaCuota, setVlrUltimaCuota] = useState(0);

    const [query,] = useState({
        "page": "1",
        "item_per_page": "60",
        "searchText":"",
        "id":"",
        "idprestamo":prestamoSelected.id || '',
        "beneficiarioId":prestamoSelected.beneficiarioId || '',
        "monto":"",
        "tasapactada":"",
        "numcuota":"",
        "vlrcuota":"",
        "vlrabono":"",
        "fechaabono":"",
        "medioabono":"",
        "comprobante":"",
        "vlrinteres":"",
        "vlrcapital":"",
        "vlrsaldocapital":"",
        "creadapor":"", //users.user.docId
        "actualizacion":"",
        "version":"",
        "modificadopor": ""
    });

    useEffect(() => {
        if (resultQueryHistoPrestamos===null) { 
            dispatch(fetchHistoPrestamosDatabyQuery({token: users.user.claveAPI, query: query} ));
            return
        }
        
        return () => {
            // executed when unmount
            // console.log('Saliendo del Modulo Historico de Prestamos');
            // dispatch(updateEditedHistoPrestamoData({resultQueryHistoPrestamos:null}));
        }
        
    }, [users.user.claveAPI, query, resultQueryHistoPrestamos, dispatch]);

    useEffect(() => {
        if (resultQueryHistoPrestamos===null){
            return
        }
        // if (resultQueryHistoPrestamos.datos.length<=0){
        //     console.log('No hay registros para mostrar...');
        // }
        // // Update 
        if (resultQueryHistoPrestamos!==null && P.length<=0){
            // console.log(resultQueryHistoPrestamos);
            if (resultQueryHistoPrestamos.datos.length<=0){
                const newId = -1;
                const nuevo_abono = {
                    "id": String(newId),
                    "idprestamo": prestamoSelected.id,
                    "beneficiarioId":prestamoSelected.beneficiarioId,
                    "monto":prestamoSelected.monto,
                    "tasapactada":prestamoSelected.tasapactada,
                    "numcuota": (resultQueryHistoPrestamos.datos.length + 1).toString() || '',
                    "vlrcuota": prestamoSelected.vlrcuota,
                    "vlrabono": "",
                    "fechaabono": moment(new Date(), 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'),
                    "medioabono": "",
                    "comprobante": "",
                    "vlrinteres": "",
                    "vlrcapital": "",
                    "vlrsaldocapital": "",
                    "creadopor":"",
                    "modificadopor":"",
                }
                updateAbono([...P].concat(nuevo_abono) );
                setIsBtnAddEnable(true);
            } else {
                updateAbono(resultQueryHistoPrestamos.datos);
            }
        }
        // // Update totalPages
        // if (resultQueryPrestamos.stats.count)
        //     setTotalPages(Math.ceil(resultQueryPrestamos.stats.count / numItemsperPage));

        if (mensaje.histoprestamos && mensaje.histoprestamos!=='') {

            const insertAbono = (id, f, v) => {
                if (P){
                    updateAbono(P.map((itm, index) => {
                        if (itm.id===id){
                            itm[f] = v;
                        }
                        return itm;
                    }));
                    mensaje.histoprestamos='';
                }
            };

            // const updateListaPrestamos = () => {
            //     query['page'] = '1'; // Es necesario enviarla para que encuentre algo
            //     dispatch(fetchHistoPrestamosDatabyQuery({token: users.user.claveAPI, query: query} ));
            // }

            if (typeof mensaje.histoprestamos === 'string' && mensaje.histoprestamos.toLowerCase().includes('error')){
                showMsgToaster('error', 'Ocurrió un fallo en el servidor', mensaje.histoprestamos);
                mensaje.histoprestamos='';
            } 
            else {
                // showMsgToaster('success',"Histórico de Prestamos", mensaje.histoprestamos.split('.')[0]);
                mensaje.histoprestamos='';
                dispatch(serverMensaje({histoprestamos:''}));
                // Update Id for edition
                // Cambiar el id por el que devuelve el servidor o transacción de MySQL
                insertAbono('-1', 'id', String(mensaje.histoprestamos.split('.')[1]));
                // updateListaPrestamos();
            }
            
        }
        
    }, [resultQueryHistoPrestamos, query, users.user.claveAPI, P, prestamoSelected, updateAbono, 
        mensaje.histoprestamos, dispatch]);

    const updateAbonos = (id, f, v) => {
        if (P){
            updateAbono(P.map((itm, index) => {
                if (itm.id===id){
                    itm[f] = v;
                }
                return itm;
            }));
        }
    };

    const updateDataonServer = (id, action, newItem) =>{
        dispatch(serverMensaje({histoprestamos:''}));
        switch (action) {
            case 'insert':
                if (parseInt(id)< 0 && newItem){
                    // console.log('insertando nuevo registro: ', newItem);
                    setIsBtnAddEnable(true);
                }
                break;
            case 'save':
                if (id){
                    const reg = P.filter(itm => itm.id===id);
                    if (reg && reg.length>0){
                        dispatch(insertHistoPrestamo( { token: users.user.claveAPI, prestamos:reg[0]}));
                        setIsBtnAddEnable(false);
                    }
                }
                break;
            case 'remove':
                if (id){
                    const reg = P.filter(itm => itm.id===id);
                    if (reg && reg.length>0){
                        dispatch(deleteHistoPrestamo( { token: users.user.claveAPI, prestamos:reg[0]}));
                    }
                }
                break;
            default: // update
                // Actualizar solo el registro necesario, para no enviar toda la tabla
                if (id && parseInt(id) > 0){
                    const reg = P.filter(itm => itm.id===id);
                    if (reg && reg.length>0){
                        dispatch(updateHistoPrestamo( { token: users.user.claveAPI, prestamos:reg[0]}));
                    }
                }
                break;
        }
    }

    const replaceSomeText = (s) =>{
        return s
            .replace(/&nbsp;/g, ' ')
            .replace(/<br>/g, '')
            .replace(/&amp;/g, '&')
            .replace(/&gt;/g, '>')
            .replace(/&lt;/g, '<').trim()
    }

    const validateInput = (f, v, final) => {
        const regex_onlynum = /^[0-9]*$/i; ///[0-9,]|\./;
        // const regex_onlynum = /[0-9,]|\./;
        const regex_gasto = /^-?\d*\.?\d*$/;
        let new_val = null;
        let err = null;
        switch (f) {
            case 'id':
                new_val = replaceSomeText(v);
                if (!regex_onlynum.test(new_val)) {
                    err = 'Dígite solo números válidos';
                    new_val = null;
                }
                if (new_val===null) new_val = '';
                break;
            case 'beneficiarioId':
                new_val = v.substr(0,15);
                if (final)
                    new_val = replaceSomeText(v).substr(0,15);
                break;
            case 'numcuota':
                new_val = replaceSomeText(v).substr(0,3);
                if (!regex_onlynum.test(v)) {
                    err = 'Dígite solo números válidos de plazo';
                    new_val = null;
                } else if(v.length>15){
                    err = 'Dígite un número de plazo válido';
                    new_val = null;
                }
                if (new_val===null) new_val = '';
                break;
            case 'medioabono':
                new_val = v.substr(0,32);
                if (final)
                    new_val = replaceSomeText(v).substr(0,32);
                break;
            case 'comprobante':
                new_val = v.substr(0,32);
                if (final)
                    new_val = replaceSomeText(v).substr(0,32);
                break;
            case 'fechaabono': //0000-00-00 00:00:00
                new_val = replaceSomeText(v).substr(0,30);
                if (final && new_val===''){
                    new_val = replaceSomeText(v).substr(0,30);
                    err = 'Dígite una fecha de abono válida';
                    new_val = null;
                }
                break;
            case 'vlrabono':
                new_val = replaceSomeText(v);
                if (!regex_gasto.test(new_val)) {
                    err = 'Dígite solo números válidos';
                    new_val = null;
                }
                break;
            case 'vlrinteres':
                new_val = replaceSomeText(v);
                if (!regex_gasto.test(new_val)) {
                    err = 'Dígite solo números válidos';
                    new_val = null;
                }
                break;
            case 'vlrcapital':
                new_val = replaceSomeText(v);
                if (!regex_gasto.test(new_val)) {
                    err = 'Dígite solo números válidos';
                    new_val = null;
                }
                break;
            case 'vlrsaldocapital':
                new_val = replaceSomeText(v);
                if (!regex_gasto.test(new_val)) {
                    err = 'Dígite solo números válidos';
                    new_val = null;
                }
                break;
            default:
                break;
        }
        setError(err);
        return new_val
    }

    const handleChange = evt => {
        const [, field, id] = evt.currentTarget.id.split('_');
        const new_val = validateInput(field, evt.target.value, false);
        if(new_val){
            updateAbonos(id,field, new_val );
        };

    };
    const handleBlur = evt => {
        const val = evt.currentTarget.innerHTML;
        const [,field, id] = evt.target.id.split('_');
        const new_val = validateInput(field, val, true)
        if(new_val || new_val===''){
            // updateAbonos(id,field, new_val );
            // Update Valores del prestamo (Interes, Capital y Saldo a Capital)
            if (field==='vlrabono'){
                const new_valores = calculatePrestamo(id, new_val);
                if (new_valores){
                    if (new_valores.interes) updateAbonos(id,'vlrinteres', new_valores.interes.toFixed(2).toString() );
                    if (new_valores.capital) updateAbonos(id,'vlrcapital', new_valores.capital.toFixed(2).toString() );
                    if (new_valores.saldocapital) updateAbonos(id,'vlrsaldocapital', new_valores.saldocapital.toFixed(2).toString() );
                }
            }
            updateAbonos(id,field, new_val );
            updateDataonServer(id);
        };
    };

    // const getSaldoAnterior = (idCredito) => {
    //     let saldocapital = 0;
    //     P.forEach((itm) => {
    //         if (itm.id===idCredito){
    //             // console.log(itm);
    //             saldocapital = itm['vlrsaldocapital'];
    //         }
    //     });
    //     return saldocapital;
    // };

    const calculatePrestamo = (id, vlrabono) => {
        const tasaInteresEA = parseFloat(prestamoSelected.tasapactada) / 100.0; //
        const monto = parseFloat(prestamoSelected.monto);
        const ultimoPago = [...P][P.length-2]; // Si es la primer cuota, se utiliza el monto completo
        // console.log("ultimoPago",ultimoPago);
        // console.log("Saldo Anterior: ",getSaldoAnterior(ultimoPago.id));
        const vlrcuota = parseFloat(prestamoSelected.vlrcuota);
        let saldocapitalAnterior = (ultimoPago!==null && ultimoPago!==undefined) ? parseFloat(ultimoPago.vlrsaldocapital) : monto;
        // console.log("saldocapitalAnterior =>", saldocapitalAnterior);
        // Interés
        let interes = saldocapitalAnterior * tasaInteresEA / 12;
        let capital = vlrabono - interes;
        let saldocapital = saldocapitalAnterior - capital;

        // Valor próxima cuota
        let vlrProxCuota = vlrcuota;
        if (saldocapital < vlrcuota){
            // console.log('Cuota menor a pagar este mes');
            vlrProxCuota = (saldocapital <= 0) ? 0.0 : (saldocapital + (saldocapital * tasaInteresEA / 12));
            setVlrUltimaCuota(vlrProxCuota);
        } 

        return {"interes":interes, "capital":capital, "saldocapital":saldocapital, "vlrProxCuota":vlrProxCuota}
    };

    const onBtnExportaraExcel = (evt) => {
        evt.preventDefault();
        evt.stopPropagation();
        // Remover algunos campos no necesarios
        let data2excel = [];
        P.forEach((itm, ix) => {
            data2excel.push({
                // id: itm.id, 
                // "ID PRESTAMO": itm.idprestamo || '',
                "DOCID BENEFICIARIO": itm.beneficiarioId || '',
                // "BENEFICIARIO": capitalize(prestamoSelected.benefnombres +' '+ prestamoSelected.benefapellidos, true) || '',
                "MONTO SOLICITADO": itm.monto || '',
                "TASA PACTADA": itm.tasapactada || '',
                "PLAZO": itm.numcuota || '',
                "CUOTA MES": itm.vlrcuota || '',
                "ABONO": itm.vlrabono || '',
                "FECHA DE ABONO": itm.fechaabono || '',
                "MEDIO ABONO": itm.medioabono || '',
                "COMPROBANTE No.": itm.comprobante || '',
                "INTERÉS": itm.vlrinteres || '',
                "CAPITAL": itm.vlrcapital || '',
                "SALDA CAPITAL": itm.vlrsaldocapital || ''
                // "creadapor":"", //users.user.docId
                // "actualizacion":"",
                // "version":"",
                // "modificadopor": ""
                
            });
        });
        /* make the worksheet */
        var ws = XLSX.utils.json_to_sheet(data2excel);
        /* add to workbook */
        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Prestamo");
        /* generate an XLSX file */
        XLSX.writeFile(wb, "Prestamo_"+P[0].beneficiarioId+"_"+moment(new Date(), 'YYYY-MM-DD').format("YYYY-MM-DD")+".xlsx");

    }

    const onClickAddAbono = (evt) => {
        evt.preventDefault();
        evt.stopPropagation();

        const newId = -1;
        const nuevo_abono = {
            "id": String(newId),
            "idprestamo": prestamoSelected.id,
            "beneficiarioId":prestamoSelected.beneficiarioId,
            "monto":prestamoSelected.monto,
            "tasapactada":prestamoSelected.tasapactada,
            "numcuota": (resultQueryHistoPrestamos.datos.length + 1).toString() || '',
            "vlrcuota": prestamoSelected.vlrcuota,
            "vlrabono": "",
            "fechaabono": moment(new Date(), 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'),
            "medioabono": "",
            "comprobante": "",
            "vlrinteres": "",
            "vlrcapital": "",
            "vlrsaldocapital": "",
            "creadopor":"",
            "modificadopor":"",
        }
        updateAbono([...P].concat(nuevo_abono) );
        updateDataonServer(newId, 'insert', nuevo_abono);
    }
    const onClickSaveAbonos = (id) => {
        updateDataonServer(id, 'save');
    }
    const onClickRemoveAbonos = (id) => {
        updateAbono([...P].filter(itm => itm.id!==id));
        updateDataonServer(id, 'remove');
    }

    // const [medioabonoSelectedValue, setMedioAbonoSelectedValue] = useState('');
    const handleMedioAbonoSelectChange = (value, id) => {
        // setMedioAbonoSelectedValue(value);
        updateAbonos(id,'medioabono', value );
        updateDataonServer(id);
    };
    const handleMedioAbonoonKeyDown = (evt, id) => {
        const keyCode = evt.keyCode || evt.which
        if (keyCode === 8 || keyCode === 46) {
            // setMedioAbonoSelectedValue('');
            updateAbonos(id,'medioabono', '' );
            updateDataonServer(id);
            evt.returnValue = false
            if (evt.preventDefault) evt.preventDefault()
        }
    }

    const simularPrestamo = (evt) => {
        evt.preventDefault();
        evt.stopPropagation();
        let err = null;
        // console.log(prestamoSelected);
        const monto = parseFloat(prestamoSelected.monto);
        const tasa = parseFloat(prestamoSelected.tasapactada);
        const plazo = parseInt(prestamoSelected.plazo);
        const vlrmes = parseFloat(prestamoSelected.vlrcuota);

        if (!plazo || plazo==='NaN' || plazo===undefined){
            // err = 'Dígite un número de plazo o valor de cuota mensual en la parte superior del formulario';
            if (!vlrmes || vlrmes==='NaN' || vlrmes===undefined) {
                err = 'Dígite un valor de cuota mensual o número de plazo en la parte superior del formulario';
            }
        } 
        if (!vlrmes || vlrmes==='NaN' || vlrmes===undefined) {
            if (!plazo || plazo==='NaN' || plazo===undefined){
                err = 'Dígite un valor de cuota mensual o número de plazo en la parte superior del formulario';
            }
        }
        if (!tasa || tasa==='NaN' || tasa===undefined){
            err = 'Dígite un valor de tasa pactada en la parte superior del formulario';
        }
        if (!monto || monto==='NaN' || monto===undefined){
            err = 'Dígite un valor de prestamo en la parte superior del formulario';
        }

        if (err){ 
            setError(err);

        } else {
            SimuladorPrestamo(monto, tasa, plazo, vlrmes);
        }
        
    }

    const SimuladorPrestamo = (monto, tasa, plazo, vlrmes) => {
        if (plazo!==null && plazo!==0){
            console.log('Simulador utilizando cuota variable');
            // console.log(monto / plazo);
            vlrmes = monto / plazo; 
        } else if (vlrmes!==null && vlrmes!==0){
            console.log('Simulador utilizando cuota fija');
            // console.log(Math.ceil(monto / vlrmes)); 
            plazo = Math.ceil(monto / vlrmes); 
            prestamoSelected.plazo = plazo;
        }

        // Cálculo por medio del plazo
        let proyeccionAbonos = [];
        let saldocapitalAnterior = monto;
        let tasaInteresEA = parseFloat(tasa) / 100.0; //
        for (let i = 0; i < plazo; i++) {
            // Interés
            const interes = saldocapitalAnterior * tasaInteresEA / 12;
            const capital = vlrmes - interes;
            const saldocapital = saldocapitalAnterior - capital;
            
            const abono = {
                "id": i,
                "idprestamo": prestamoSelected.id || -9999,
                "beneficiarioId":prestamoSelected.beneficiarioId || -9999,
                "monto": monto.toFixed(2).toString(),
                "tasapactada":tasaInteresEA.toFixed(2).toString(),
                "numcuota": (i+1).toString(),
                "vlrcuota": vlrmes.toFixed(2).toString(),
                "vlrabono": vlrmes.toFixed(2).toString(),
                "fechaabono": moment().add((i+1)*30, 'days').format("YYYY-MM-DD HH:mm:ss"),
                "medioabono": "",
                "comprobante": "",
                "vlrinteres": interes.toFixed(2).toString(),
                "vlrcapital": capital.toFixed(2).toString(),
                "vlrsaldocapital": saldocapital.toFixed(2).toString(),
                "creadopor": users.user.docId,
                "modificadopor":"GRB",
            };
            proyeccionAbonos.push(abono);
            saldocapitalAnterior = saldocapital;
            
        }

        // updateAbono([...P].concat(proyeccionAbonos) );
        updateAbono(proyeccionAbonos);
        setIsBtnAddEnable(false);
        // console.log(monto, plazo, vlrmes);
    }

    return (
        <Fragment>
            <ToastContainer />
            <ProyeccionContainer>
                {/* <div>{prestamoSelected.id}</div> */}
                <div className='prestamos-historico-tablaContainer'>
                    <div className='prestamos-historico-tabla'>
                    <div className='prestamos-historico-tablaHeader'>
                        <div>Plan de Abonos</div>
                        <div className='prestamos-historico-tablaToolBar'>
                            <Button extraSmall inline iconPosition="before" 
                                disabled={!isEditable || isBtnAddEnable} 
                                id={'abonos_agregar'}
                                onClick={onClickAddAbono}
                                icon={<PlusIcon size={16} />}
                            >Abono</Button>

                            <Button extraSmall inline iconPosition="before" 
                                disabled={!isEditable || isBtnAddEnable} 
                                id={'abonos_exportar'}
                                onClick={onBtnExportaraExcel}
                                icon={<DownloadIcon size={16} />}
                            >Exportar</Button>
                            <div className='prestamos-historico-tablaToolBar-right'>
                                <Button extraSmall inline iconPosition="before" 
                                    // disabled={!isEditable || isBtnAddEnable} 
                                    // disabled={P && P.length<=1 ? false : true} 
                                    id={'abonos_simular'}
                                    onClick={simularPrestamo}
                                    icon={<CalculatorIcon size={16} />}
                                 >Simulador</Button>
                            </div>
                            
                        </div>
                    </div>
                    { error && <div style={{color: 'red', width: '100%'}}>
                        <Alert red showIcon style={{minWidth: '100%', marginBottom: '10px'}}
                            showCloseLabel 
                            onClose={() => setError(null)}>
                            <AlertMessage>
                                Error:{' '}
                                <strong>{error}</strong>
                            </AlertMessage>
                        </Alert>
                        </div>
                    }

                { P && P.length>0 ? 
                    <Table striped>
                    <TableHeader>
                        <TableHeaderRow>
                            <TableHeaderCell style={{textAlign:'center', fontWeight:600, fontSize: '0.85em'}}>Plazo</TableHeaderCell>
                            <TableHeaderCell style={{textAlign:'center', fontWeight:600, fontSize: '0.85em'}}>Cuota Mes</TableHeaderCell>
                            <TableHeaderCell style={{textAlign:'center', fontWeight:600, fontSize: '0.85em'}}>Fecha de Abono</TableHeaderCell>
                            <TableHeaderCell style={{textAlign:'center', fontWeight:600, fontSize: '0.85em'}}>Medio de Abono</TableHeaderCell>
                            <TableHeaderCell style={{textAlign:'center', fontWeight:600, fontSize: '0.85em'}}>Comprobante No.</TableHeaderCell>
                            <TableHeaderCell style={{textAlign:'center', fontWeight:600, fontSize: '0.85em'}}>Interés</TableHeaderCell>
                            <TableHeaderCell style={{textAlign:'center', fontWeight:600, fontSize: '0.85em'}}>Capital</TableHeaderCell>
                            <TableHeaderCell style={{textAlign:'center', fontWeight:600, fontSize: '0.85em'}}>Saldo Capital</TableHeaderCell>
                            <TableHeaderCell style={{textAlign:'center', fontWeight:600, fontSize: '0.85em'}}>Acción</TableHeaderCell>
                        </TableHeaderRow>
                    </TableHeader>
                    <TableBody>
                    {
                        P.map((ah, index) => (
                            <TableRow key={index}>
                                <TableCell style={{minWidth:'40px', padding:'2px 4px'}}>
                                    <ContentEditable
                                        id={'abonos_numcuota_'+ah.id}
                                        html={ah.numcuota || ''} // innerHTML of the editable div
                                        className='itemContainer-table-prestamos-value'
                                        disabled={!isEditable}      // use true to disable editing
                                        onPaste={pastePlainText} // En caso se copie y pegue de otra pagina de internet
                                        onChange={handleChange} // handle innerHTML change
                                        onKeyPress={disableNewlines}  
                                        onFocus={highlightAll}
                                        onBlur={handleBlur}
                                        tagName='div' // Use a custom HTML tag (uses a div by default)
                                    />
                                </TableCell>
                                <TableCell style={{minWidth:'100px', padding:'2px 4px'}}>
                                    <ContentEditable
                                        id={'abonos_vlrabono_'+ah.id}
                                        html={ah.vlrabono || ''} // innerHTML of the editable div
                                        className={(parseInt(ah.vlrabono) < parseInt(ah.vlrcuota)) ? 'itemContainer-table-prestamos-valueAbono-mora' : 'itemContainer-table-prestamos-valueAbono'}
                                        disabled={!isEditable}      // use true to disable editing
                                        onPaste={pastePlainText} // En caso se copie y pegue de otra pagina de internet
                                        onChange={handleChange} // handle innerHTML change
                                        onKeyPress={disableNewlines}  
                                        onFocus={highlightAll}
                                        onBlur={handleBlur}
                                        tagName='div' // Use a custom HTML tag (uses a div by default)
                                    />
                                </TableCell>
                                <TableCell style={{minWidth:'120px', padding:'2px 4px'}}>
                                    <ContentEditable
                                        id={'abonos_fechaabono_'+ah.id}
                                        html={ah.fechaabono || ''} // innerHTML of the editable div
                                        className='itemContainer-table-prestamos-value'
                                        disabled={!isEditable}      // use true to disable editing
                                        onPaste={pastePlainText} // En caso se copie y pegue de otra pagina de internet
                                        onChange={handleChange} // handle innerHTML change
                                        onKeyPress={disableNewlines}  
                                        onFocus={highlightAll}
                                        onBlur={handleBlur}
                                        tagName='div' // Use a custom HTML tag (uses a div by default)
                                    />
                                </TableCell>
                                <TableCell style={{minWidth:'40px', padding:'2px 4px'}}>
                                    {/* <ContentEditable
                                        id={'abonos_medioabono_'+ah.id}
                                        html={ah.medioabono || ''} // innerHTML of the editable div
                                        className='itemContainer-table-prestamos-value'
                                        disabled={!isEditable}      // use true to disable editing
                                        onPaste={pastePlainText} // En caso se copie y pegue de otra pagina de internet
                                        onChange={handleChange} // handle innerHTML change
                                        onKeyPress={disableNewlines}  
                                        onFocus={highlightAll}
                                        onBlur={handleBlur}
                                        tagName='div' // Use a custom HTML tag (uses a div by default)
                                    /> */}
                                    <Select minimal filterable positionFixed 
                                        disabled={!isEditable} 
                                        placeholder={'Seleccione...'}
                                        id={'abonos_medioabono_'+ah.id}
                                        className={'prestamos-itemContainer-table-selects'}
                                        onChange={(value, item) => handleMedioAbonoSelectChange(value, ah.id)}
                                        onKeyDown={(evt) => handleMedioAbonoonKeyDown(evt, ah.id)}
                                        // value={medioabonoSelectedValue || ''}
                                        selectedValue={ah.medioabono.trim() || ''}
                                    >
                                    {medioAbonoPrestamo.tipo.map(e => {
                                        return (
                                        <MenuItem key={e.id} value={e.name}>
                                            {e.name}
                                        </MenuItem>
                                        )
                                    })}
                                    </Select>

                                </TableCell>
                                <TableCell style={{minWidth:'80px', padding:'2px 4px'}}>
                                    <ContentEditable
                                        id={'abonos_comprobante_'+ah.id}
                                        html={ah.comprobante || ''} // innerHTML of the editable div
                                        className='itemContainer-table-prestamos-value'
                                        disabled={!isEditable}      // use true to disable editing
                                        onPaste={pastePlainText} // En caso se copie y pegue de otra pagina de internet
                                        onChange={handleChange} // handle innerHTML change
                                        onKeyPress={disableNewlines}  
                                        onFocus={highlightAll}
                                        onBlur={handleBlur}
                                        tagName='div' // Use a custom HTML tag (uses a div by default)
                                    />
                                </TableCell>
                                <TableCell style={{minWidth:'80px', padding:'2px 4px'}}>
                                    <ContentEditable
                                        id={'abonos_vlrinteres_'+ah.id}
                                        html={ah.vlrinteres || ''} // innerHTML of the editable div
                                        className='itemContainer-table-prestamos-value'
                                        disabled={!isEditable}      // use true to disable editing
                                        onPaste={pastePlainText} // En caso se copie y pegue de otra pagina de internet
                                        onChange={handleChange} // handle innerHTML change
                                        onKeyPress={disableNewlines}  
                                        onFocus={highlightAll}
                                        onBlur={handleBlur}
                                        tagName='div' // Use a custom HTML tag (uses a div by default)
                                    />
                                </TableCell>
                                <TableCell style={{minWidth:'80px', padding:'2px 4px'}}>
                                    <ContentEditable
                                        id={'abonos_vlrcapital_'+ah.id}
                                        html={ah.vlrcapital || ''} // innerHTML of the editable div
                                        className='itemContainer-table-prestamos-value'
                                        disabled={!isEditable}      // use true to disable editing
                                        onPaste={pastePlainText} // En caso se copie y pegue de otra pagina de internet
                                        onChange={handleChange} // handle innerHTML change
                                        onKeyPress={disableNewlines}  
                                        onFocus={highlightAll}
                                        onBlur={handleBlur}
                                        tagName='div' // Use a custom HTML tag (uses a div by default)
                                    />
                                </TableCell>
                                <TableCell style={{minWidth:'90px', padding:'2px 4px', textAlign:'right'}}>
                                    <ContentEditable
                                        id={'abonos_vlrsaldocapital_'+ah.id}
                                        html={ah.vlrsaldocapital || ''} // innerHTML of the editable div
                                        className='itemContainer-table-prestamos-value'
                                        disabled={!isEditable}      // use true to disable editing
                                        onPaste={pastePlainText} // En caso se copie y pegue de otra pagina de internet
                                        onChange={handleChange} // handle innerHTML change
                                        onKeyPress={disableNewlines}  
                                        onFocus={highlightAll}
                                        onBlur={handleBlur}
                                        tagName='div' // Use a custom HTML tag (uses a div by default)
                                    />
                                </TableCell>
                                <TableCell style={{maxWidth:'140px', padding:'2px 4px', textAlign:'center'}}>
                                    {
                                        (parseInt(ah.id)< 0)
                                        ?
                                        <Button extraSmall clear iconPosition="before"
                                            disabled={!isEditable} 
                                            id={'abonos_guardar_'+ah.id}
                                            onClick={(evt) => onClickSaveAbonos(ah.id)}
                                            icon={<SaveIcon size={16} />}
                                        >
                                            Guardar
                                        </Button>
                                        :
                                        <Button extraSmall clear iconPosition="before"
                                            disabled={!isEditable} 
                                            id={'abonos_eliminar_'+ah.id}
                                            onClick={(evt) => onClickRemoveAbonos(ah.id)}
                                            icon={<TrashIcon size={16} />}
                                        >
                                            Eliminar
                                        </Button>
                                    }
                                </TableCell>
                            </TableRow>
                        ))
                    }
                    </TableBody>
                </Table>
                :
                <div className='prestamos-historico-tablaContent'>No hay histórico de abonos</div> 
                }

                {vlrUltimaCuota!==0 && <div className='prestamos-historico-tablaContent-ultimacuota'>Valor de la última cuota: $ {formatMoney(vlrUltimaCuota)} </div>}
                </div>
            </div>
            </ProyeccionContainer>
        </Fragment>
    );
}

export default HistoricoPrestamos;

