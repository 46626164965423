// Copyright 2019 Ernesto Giron E.
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//     http://www.apache.org/licenses/LICENSE-2.0
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.​

// ACTION TYPES //
export const types = {
  MODULE_PRESTAMO_LOADED: "MODULE_PRESTAMO_LOADED",
  MODULE_PRESTAMO_FILTER_OPEN: "MODULE_PRESTAMO_FILTER_OPEN",
  MODULE_PRESTAMO_EDIT: "MODULE_PRESTAMO_EDIT",
  PRESTAMO_DATA_LOADED: "PRESTAMO_DATA_LOADED",
  PRESTAMO_RESETDATA_LOADED: "PRESTAMO_RESETDATA_LOADED",
  PRESTAMO_DATABYQUERY_LOADED: "PRESTAMO_DATABYQUERY_LOADED",
  PRESTAMO_STATS_LOADED: "PRESTAMO_STATS_LOADED",
  PRESTAMO_LIST_LOADED: "PRESTAMO_LIST_LOADED",
  PRESTAMO_LIST_FILTERED: "PRESTAMO_LIST_FILTERED",
  PRESTAMO_ISFILTER_SHOW: "PRESTAMO_ISFILTER_SHOW",
  PRESTAMO_SELECTED: "PRESTAMO_SELECTED",
  PRESTAMO_NOTA_SELECTED: "PRESTAMO_NOTA_SELECTED",
  PRESTAMO_DATA_SELECTED: "PRESTAMO_DATA_SELECTED",
  PRESTAMO_SELECTED_DATA_REQUEST: "PRESTAMO_SELECTED_DATA_REQUEST",
  // PRESTAMO_SELECTED_DATA_EDITED: "PRESTAMO_SELECTED_DATA_EDITED",

  PRESTAMO_DATA_UPDATED: "PRESTAMO_DATA_UPDATED",
  PRESTAMO_DATA_CREATED: "PRESTAMO_DATA_CREATED",
  PRESTAMO_DATA_DELETED: "PRESTAMO_DATA_DELETED",

  PRESTAMO_HISTODATABYQUERY_LOADED: "PRESTAMO_HISTODATABYQUERY_LOADED",
  PRESTAMO_HISTODATA_UPDATED: "PRESTAMO_HISTODATA_UPDATED",
  PRESTAMO_HISTODATA_CREATED: "PRESTAMO_HISTODATA_CREATED",
  PRESTAMO_HISTODATA_DELETED: "PRESTAMO_HISTODATA_DELETED",
  PRESTAMO_HISTO_RESETDATA_LOADED: "PRESTAMO_HISTO_RESETDATA_LOADED",
  PRESTAMO_HISTOSTATS_LOADED: "PRESTAMO_HISTOSTATS_LOADED",
  
  PRESTAMO_SERVER_MESSAGE: "PRESTAMO_SERVER_MESSAGE"
};

// REDUCERS //
export const initialState = {
  loaded: false,
  isFilterShow: false,
  edit: false,
  openfilter: false,
  filtername: '',
  listPrestamos: null,
  resultQueryPrestamos: null,
  resultQueryHistoPrestamos: null,
  selected: null,
  selectedPrestamo: null,
  datosSelPrestamo: [],
  filter: [],
  query: null,
  mensaje: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.MODULE_PRESTAMO_LOADED:
      const { loaded } = action.payload;
      return {
        ...state,
        loaded
      };
    case types.MODULE_PRESTAMO_EDIT:
      const { edit } = action.payload;
      return {
        ...state,
        edit
      };
    case types.MODULE_PRESTAMO_FILTER_OPEN:
      const { openfilter, filtername } = action.payload;
      return {
        ...state,
        openfilter, 
        filtername
      };
    case types.PRESTAMO_LIST_LOADED:
      const { listPrestamos } = action.payload;
      return {
        ...state,
        listPrestamos
      };
    case types.PRESTAMO_STATS_LOADED:
      const { resultQueryPrestamos } = action.payload;
      return {
        ...state,
        resultQueryPrestamos
      };
    case types.PRESTAMO_LIST_FILTERED:
      const { filter } = action.payload;
      return {
        ...state,
        filter
      };
    case types.PRESTAMO_ISFILTER_SHOW:
      const { isFilterShow } = action.payload;
      return {
        ...state,
        isFilterShow
      };
    case types.PRESTAMO_SELECTED:
      const { selected } = action.payload;
      return {
        ...state,
        selected
      };
    // case types.PRESTAMO_NOTA_SELECTED:
    //   return {
    //     ...state,
    //     selectedPrestamo:action.payload.selectedPrestamo
    //   };
    // case types.PRESTAMO_DATA_SELECTED:
    //   const { datosSelPrestamo, selectedPrestamo } = action.payload;
    //   return {
    //     ...state,
    //     datosSelPrestamo,
    //     selectedPrestamo
    //   };
    case types.PRESTAMO_HISTOSTATS_LOADED:
      const { resultQueryHistoPrestamos } = action.payload;
      return {
        ...state,
        resultQueryHistoPrestamos
      };
    case types.PRESTAMO_SERVER_MESSAGE:
      const {mensaje} = action.payload;
      return {
        ...state,
        mensaje
      };
    default:
      return state;
  }
};

// ACTIONS //
export const modulePrestamoLoaded = options => ({ type: types.MODULE_PRESTAMO_LOADED, payload: options });
export const displayPrestamoFilter = options => ({ type: types.PRESTAMO_ISFILTER_SHOW, payload: options });

export const editPrestamo = options => ({ type: types.MODULE_PRESTAMO_EDIT, payload: options });
export const changeFilterPrestamo = options => ({ type: types.MODULE_PRESTAMO_FILTER_OPEN, payload: options });

export const fetchPrestamosData = token => ({ 
  type: types.PRESTAMO_DATA_LOADED, 
  payload: {
    token: token
  }
});
export const fetchPrestamosDatabyQuery = options => ({ 
  type: types.PRESTAMO_DATABYQUERY_LOADED, 
  payload: options
});

export const resetPrestamosDatabyQuery = () => ({ 
  type: types.PRESTAMO_RESETDATA_LOADED, 
  payload: {resultQueryPrestamos: null}
});

export const updatePrestamo = data => ({ 
  type: types.PRESTAMO_DATA_UPDATED,  payload: data
});
export const insertPrestamo = data => ({ 
  type: types.PRESTAMO_DATA_CREATED,  payload: data
});
export const deletePrestamo = data => ({ 
  type: types.PRESTAMO_DATA_DELETED,  payload: data
});

export const updateEditedPrestamoData = resultQueryPrestamos => ({ 
  type: types.PRESTAMO_STATS_LOADED, 
  payload: resultQueryPrestamos
});

export const filterPrestamoData = filter => ({ 
  type: types.PRESTAMO_LIST_FILTERED, 
  payload: {
    filter: filter
  }
});
export const resetfilterPrestamoData = () => ({ 
  type: types.PRESTAMO_LIST_FILTERED, 
  payload: {filter: []}
});

export const selectPrestamo = docId => ({ 
  type: types.PRESTAMO_SELECTED, 
  payload: {
    selected: docId
  }
});

// Histórico
export const fetchHistoPrestamosDatabyQuery = options => ({ 
  type: types.PRESTAMO_HISTODATABYQUERY_LOADED, 
  payload: options
});
export const updateHistoPrestamo = data => ({ 
  type: types.PRESTAMO_HISTODATA_UPDATED,  payload: data
});
export const insertHistoPrestamo = data => ({ 
  type: types.PRESTAMO_HISTODATA_CREATED,  payload: data
});
export const deleteHistoPrestamo = data => ({ 
  type: types.PRESTAMO_HISTODATA_DELETED,  payload: data
});
export const resetHistoPrestamosDatabyQuery = () => ({ 
  type: types.PRESTAMO_HISTO_RESETDATA_LOADED, 
  payload: {resultQueryHistoPrestamos: null}
});
export const updateEditedHistoPrestamoData = resultQueryHistoPrestamos => ({ 
  type: types.PRESTAMO_HISTOSTATS_LOADED, 
  payload: resultQueryHistoPrestamos
});


export const serverMensaje = mensaje => ({ 
  type: types.PRESTAMO_SERVER_MESSAGE, 
  payload: {
    mensaje: mensaje
  }
});