// Copyright 2020 Ernesto Girón E.
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//     http://www.apache.org/licenses/LICENSE-2.0
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.​

import { call, put, takeLatest } from "redux-saga/effects";
import { types as configTypes } from "../reducers/terapias";
import { 
  getListTerapias,
  getListTerapiasbyQuery,
  actualizarTerapia,
  crearTerapia,
  eliminarTerapia,
} from "../../utils/terapias_req";

// WORKERS //
function* fetchTerapiasData(action) {
  try {
    const listTerapia = yield call(getListTerapias, action.payload.token);
    if (listTerapia)
      yield put({
        type: configTypes.TERAPIA_LIST_LOADED,
        payload: {listTerapia: listTerapia}
      });
  } catch (e) {
    console.error("SAGA ERROR: terapias_req/fetchTerapiasData, ", e);
  }
}
function* fetchTerapiasDatabyQuery(action) {
  try {
    const resultQueryTerapia = yield call(getListTerapiasbyQuery, action.payload);
    if (resultQueryTerapia)
      yield put({
        type: configTypes.TERAPIA_STATS_LOADED,
        payload: {resultQueryTerapia: resultQueryTerapia}
      });
  } catch (e) {
    console.error("SAGA ERROR: terapias_req/fetchTerapiasData, ", e);
  }
}
function* updateTerapia(action) {
  try {
    const resp = yield call(actualizarTerapia, action.payload);
    if (resp && resp!=='')
      yield put({
        type: configTypes.TERAPIA_SERVER_MESSAGE,
        payload: {mensaje: {"terapias": resp}}
      });
  } catch (e) {
    console.error("SAGA ERROR: terapias_req/updateTerapia, ", e);
  }
}
function* insertTerapia(action) {
  try {
    const resp = yield call(crearTerapia, action.payload);
    if (resp && resp!=='')
      yield put({
        type: configTypes.TERAPIA_SERVER_MESSAGE,
        payload: {mensaje: {"terapias": resp}}
      });
  } catch (e) {
    console.error("SAGA ERROR: terapias_req/insertTerapia, ", e);
  }
}
function* deleteTerapia(action) {
  try {
    const resp = yield call(eliminarTerapia, action.payload);
    if (resp && resp!=='')
      yield put({
        type: configTypes.TERAPIA_SERVER_MESSAGE,
        payload: {mensaje: {"terapias": resp}}
      });
  } catch (e) {
    console.error("SAGA ERROR: terapias_req/deleteTerapia, ", e);
  }
}



// WATCHER //
export function* watchFetchTerapiaData() {
  yield takeLatest(configTypes.TERAPIA_DATA_LOADED, fetchTerapiasData);
  yield takeLatest(configTypes.TERAPIA_DATABYQUERY_LOADED, fetchTerapiasDatabyQuery);
  yield takeLatest(configTypes.TERAPIA_DATA_UPDATED, updateTerapia);
  yield takeLatest(configTypes.TERAPIA_DATA_CREATED, insertTerapia);
  yield takeLatest(configTypes.TERAPIA_DATA_DELETED, deleteTerapia);

}
