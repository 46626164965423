import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { FixedSizeList as List } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import { fetchBeneficiarioPerfilDataxCovid } from '../../../redux/reducers/covid19';
import {formatMoney, capitalize} from '../../../utils/utils';
import moment from 'moment';
import 'moment/locale/es-us';

import '../bienestar_styles.css';
// Styled Components
import styled from "styled-components";

import caddieIcon from '../../beneficiario/imgs/caddie_icon_w.png';
import boleroIcon from '../../beneficiario/imgs/bolero_icon_w.png';
import boleadorIcon from '../../beneficiario/imgs/boleador_icon_w.png';
import monitorIcon from '../../beneficiario/imgs/monitor_icon_w.png';

import dineroIcon from '../../../assets/icons/covidayuda_dinero.png';
// import mercadoIcon from '../../../assets/icons/carrocompras.png';

const DineroIcon = styled.div`
  width: 20px;
  height: 20px;
  background: rgba(255, 255, 255, 0.1) url(${dineroIcon}) no-repeat center/cover;
  opacity: 0.4;
  margin-right: 5px;

  &:hover{
    opacity: 0.9;
  }
`;
// const MercadoIcon = styled.div`
//   width: 20px;
//   height: 20px;
//   background: rgba(255, 255, 255, 0.1) url(${mercadoIcon}) no-repeat center/cover;
//   opacity: 0.4;
//   margin-right: 5px;

//   &:hover{
//     opacity: 0.9;
//   }
// `;
const CaddieIcon = styled.div`
  width: 24px;
  height: 24px;
  background: rgba(255, 255, 255, 0.1) url(${caddieIcon}) no-repeat center/cover;
  border-radius: 50%;
  background-color: #249507;
`;
const BoleroIcon = styled.div`
  width: 24px;
  height: 24px;
  background: rgba(255, 255, 255, 0.1) url(${boleroIcon}) no-repeat center/cover;
  border-radius: 50%;
  background-color: #249507;
`;
const BoleadorIcon = styled.div`
  width: 24px;
  height: 24px;
  background: rgba(255, 255, 255, 0.1) url(${boleadorIcon}) no-repeat center/cover;
  border-radius: 50%;
  background-color: #249507;
`;
const MonitorIcon = styled.div`
  width: 24px;
  height: 24px;
  background: rgba(255, 255, 255, 0.1) url(${monitorIcon}) no-repeat center/cover;
  border-radius: 50%;
  background-color: #249507;
`;

export default function ListBeneficiariosWrapper({
  // Are there more items to load?
  // (This information comes from the most recent API request.)
  hasNextPage,

  // Are we currently loading a page of items?
  // (This may be an in-flight flag in your Redux store for example.)
  isNextPageLoading,

  // Array of items loaded so far.
  items,

  // Callback function responsible for loading the next page of items.
  loadNextPage
}) {
  // Load config 
  const config = useSelector(state => state.config);
  const users = useSelector(state => state.users);
  const dispatch = useDispatch();

  // If there are more items to be loaded then add an extra row to hold a loading indicator.
  const itemCount = hasNextPage ? items.length + 1 : items.length;

  // Only load 1 page of items at a time.
  // Pass an empty callback to InfiniteLoader in case it asks us to load more than once.
  const loadMoreItems = isNextPageLoading ? () => {} : loadNextPage;

  // Every row is loaded except for our loading indicator row.
  const isItemLoaded = index => !hasNextPage || index < items.length;

  const onClickBenefItem = (e, docId) => {
    e.preventDefault();
    e.stopPropagation();
    // e.nativeEvent.stopImmediatePropagation();
    // Obtenga todos los datos asociados al Beneficiario
    dispatch(fetchBeneficiarioPerfilDataxCovid({
        token: users.user.claveAPI,
        docId: docId,
        req: "" //tabla de datos, deje en blanco para todas las relacionadas con el Beneficiario
    }));
    
    // dispatch(selectBeneficiarioForPerfil({selectedProfile:{docId:docId}}))
  }

  // Render an item or a loading indicator.
  const Item = ({ index, style }) => {
    let content;
    if (!isItemLoaded(index)) {
      content = "Cargando Beneficiario...";
    } else {
      if (items[index]){
        // const actualizacion = moment(items[index].actualizacion).format("YYYY-MM-DD HH:mm:ss");
        const fecharedencion = moment(items[index].fecharedencion).format("YYYY-MM-DD HH:mm:ss");
        const fechacaducidad = items[index].fechacaducidad ? items[index].fechacaducidad.replace(' 00:00:00', '') : ''; //moment(items[index].fechacaducidad).format("YYYY-MM-DD HH:mm:ss");
        // const foto = items[index].foto==='' ? require('../../../assets/team/usuario.jpg') : config.ECASE_URL +'user_images/'+ items[index].foto; 
        const foto = items[index].foto==='' ? process.env.PUBLIC_URL + '/imgs/team/usuario.jpg' : config.ECASE_URL +'user_images/'+ items[index].foto; 
        // const fullname = capitalize(items[index].nombres, true) + ' ' + capitalize(items[index].apellidos, true);
        const bono = items[index].tipoayuda.toLowerCase()==='bono' ? require('../../../assets/icons/bonos.png') : '';
        const mercado = items[index].tipoayuda.toLowerCase()==='mercado' ? require('../../../assets/icons/carrocompras.png') : '';
        const familia = parseInt(items[index].personasacargo)>0 ? require('../../../assets/icons/familia_nina.png') : '';
        const nojefehogar = items[index].jefehogar.toLowerCase()==='no' ? require('../../../assets/icons/nojefehogar.png') : '';
        const fmliaclub = items[index].tienefmliaclub!=='0' ? require('../../../assets/icons/familiares.png') : '';
        const estadoayuda = items[index].estadoayuda.toLowerCase();

        const estado = items[index].estado.toLowerCase();
        let estadoCircle = "cardBenefItem-estado-circle";
        let estadoClase = "cardBenefItem-avatar";
        switch (estado) {
          case 'suspendido':
            estadoClase = "cardBenefItem-avatar-suspendido";
            estadoCircle = "cardBenefItem-estado-circle cardBenefItem-estado-suspendido";
            break;
          case 'retirado':
            estadoClase = "cardBenefItem-avatar-retirado";
            estadoCircle = "cardBenefItem-estado-circle cardBenefItem-estado-retirado";
            break;
          case 'no apto':
            estadoClase = "cardBenefItem-avatar-noapto";
            estadoCircle = "cardBenefItem-estado-circle cardBenefItem-estado-noapto";
            break;
          case 'pendiente':
            estadoCircle = "cardBenefItem-estado-circle cardBenefItem-estado-pendiente";
            break;
          case 'prueba':
            estadoCircle = "cardBenefItem-estado-circle cardBenefItem-estado-prueba";
            break;
          case 'visitar':
            estadoCircle = "cardBenefItem-estado-circle cardBenefItem-estado-visitar";
            break;
          case 'revisar':
            estadoCircle = "cardBenefItem-estado-circle cardBenefItem-estado-revisar";
            break;
          case 'actualizado':
            // estadoClase = "cardBenefItem-avatar-actualizado";
            estadoCircle = "cardBenefItem-estado-circle cardBenefItem-estado-actualizado";
            break;
          default:
            break;
        }
        
        content = <div className="cardBenefItemBienestar" onClick={e => onClickBenefItem(e, items[index].docId)}>
                      <div className={estadoClase}>
                          <div className="cardBenefItem-foto">
                              <img src={foto} alt=" "/>
                          </div>
                          <div className="cardBenefItem-icon">
                             { items[index].actividad.toLowerCase()==='caddie' && <CaddieIcon/>}
                             { items[index].actividad.toLowerCase()==='bolero' && <BoleroIcon/>}
                             { items[index].actividad.toLowerCase()==='boleador' && <BoleadorIcon/>}
                             { items[index].actividad.toLowerCase()==='monitor' && <MonitorIcon/>}
                          </div>
                          <div className="cardBenefItem-estado">
                              <ul>
                                  { estado ==='suspendido' &&  <li className={estadoCircle} title='Suspendido'></li> }
                                  { estado ==='retirado' &&  <li className={estadoCircle} title='Retirado'></li> }
                                  { estado ==='no apto' &&  <li className={estadoCircle} title='No Apto'></li> }
                                  { estado ==='pendiente' &&  <li className={estadoCircle} title='Pendiente'></li> }
                                  { estado ==='prueba' &&  <li className={estadoCircle} title='Prueba'></li> }
                                  { estado ==='visitar' &&  <li className={estadoCircle} title='Visitar'></li> }
                                  { estado ==='revisar' &&  <li className={estadoCircle} title='Revisar'></li> }
                                  { estado ==='actualizado' &&  <li className={estadoCircle} title='Actualizado'></li> }
                                  {items[index].activo==='1' 
                                    ? <li className="cardBenefItem-estado-circle cardBenefItem-estado-activo" title='Activo'></li>
                                    : <li className="cardBenefItem-estado-circle cardBenefItem-estado-noactivo" title='No Activo'></li>
                                  }
                              </ul>
                          </div>
                      </div>
                      <div className="cardBenefItem-desc-bienestar">
                          {/* <div className="cardBenefItem-title">{fullname}</div> */}
                          <div className="cardBenefItem-title">
                            <span className="cardBenefItem-title-surname">{capitalize(items[index].apellidos, true)}</span>
                            <span className="cardBenefItem-title-name">{capitalize(items[index].nombres, true)}</span>
                          </div>
                          <div className="cardBenefItem-subtitle">{items[index].actividad}  <span className="cardBenefItem-categoria">{items[index].categoria}</span></div>
                          { items[index].docId!==items[index].docIdquienreclama
                            ? <div className="cardBenefItem-autorizacion">Autoriza a: {capitalize(items[index].nombrequienreclama, true)}</div>
                            : <div className="cardBenefItem-autorizacion">&nbsp;</div>
                          }
                          {bono!=="" && <div className="cardBenefItem-bono"><img src={bono} alt="Bono" title="Bono" width="18px" height="auto" />
                            <div className="cardBenefItem-numentrega" title="Entrega No.">{items[index].numentrega}</div>
                            </div>
                          }
                          {mercado!=="" && <div className="cardBenefItem-mercado"><img src={mercado} alt="Mercado" title="Mercado" width="18px" height="auto" />
                            <div className="cardBenefItem-numentrega" title={`Entrega No. ${items[index].numentrega}`}>{items[index].numentrega}</div>
                            </div>
                          }
                          { items[index].jefehogar.toLowerCase()==='no' && parseInt(items[index].personasacargo)>0
                            ?
                            <div className="cardBenefItem-nojefehogar"><img src={nojefehogar} alt="NO" title="No Jefe Hogar" width="20px" height="auto" />
                              <div className="cardBenefItem-numentrega" title="Personas a Cargo">{items[index].personasacargo}</div>
                            </div>
                            :
                            familia!=="" && <div className="cardBenefItem-familia"><img src={familia} alt="Familia" title="Jefe Hogar" width="20px" height="auto" />
                              <div className="cardBenefItem-numentrega" title="Personas a Cargo">{items[index].personasacargo}</div>
                            </div>
                          }
                          {fmliaclub!=="" && <div className="cardBenefItem-fmliaclub"><img src={fmliaclub} alt="Familia Club" title="Familiares Club" width="20px" height="auto" />
                            </div>
                          }
                          <div className="cardBenefItem-valor"><DineroIcon title='Valor Ayuda'/> {formatMoney(items[index].valor, 0)}</div>

                          {estadoayuda==="redimido" && <div className="cardBenefItem-estadoayuda cardBenefItem-estadoayuda-redimido">{items[index].estadoayuda}</div> }
                          {estadoayuda==="caducado" && <div className="cardBenefItem-estadoayuda cardBenefItem-estadoayuda-caducado">{items[index].estadoayuda}</div> }
                          
                          {estadoayuda==="redimido" 
                          ?
                            fecharedencion!=="" && <div className="cardBenefItem-fecharedencion">{fecharedencion}</div>
                          :
                            estadoayuda==="caducado" ?
                            fechacaducidad!=="" && <div className="cardBenefItem-fechacaducidad">{fechacaducidad}</div> 
                            : <></>
                          }
                          
                          {/* <div className="cardBenefItem-numentrega">{items[index].tipoayuda} - Entrega {items[index].numentrega}</div> */}
                          {/* {items[index].jefehogar && <div className="cardBenefItem-docId">Cabeza familia: {capitalize(items[index].jefehogar, true)}</div>}
                          {items[index].personasacargo && <div className="cardBenefItem-docId"># Pers. a cargo: {items[index].personasacargo}</div>} */}
                          {/* {items[index].tienefmliaclub && <div className="cardBenefItem-docId">Familiares Club: {items[index].tienefmliaclub==="0" ? "No" : "Si"}</div>} */}
                          {/* <div className="cardBenefItem-fecha">{items[index].fecha.split(' ')[0]}</div> */}
                          {/* <div className="cardBenefItem-fecha">{actualizacion}</div> */}
                      </div>
                  </div>;
                            
      } else {
        content = <div></div>
      }
      
    }
    return <div style={style}>{content}</div>;
  };

  return (
    <InfiniteLoader 
      isItemLoaded={isItemLoaded}
      itemCount={itemCount}
      loadMoreItems={loadMoreItems}
    >
      {({ onItemsRendered, ref }) => (
        <List style={{minWidth:'390px'}}
          // className="cardBenefContainer"
          height={480}
          itemCount={itemCount}
          itemSize={90}
          onItemsRendered={onItemsRendered}
          ref={ref}
        >
          {Item}
        </List>
      )}
    </InfiniteLoader>
  );
}
