import React, {Fragment} from "react";
import { toast } from 'react-toastify';
import {ToastMessage, ToastTitle, notify} from 'calcite-react/Toaster';
import { StyledCloseButton } from 'calcite-react/Button';
import XIcon from 'calcite-ui-icons-react/XIcon';

const CloseButton = ({ closeToast }) => (
    <StyledCloseButton iconButton type="button" icon={<XIcon size={16} />} onClick={closeToast} />
);
toast.configure({ closeButton: <CloseButton />, });

export const showMsgToaster = (tipo, title, mesg) =>{
    switch (tipo) {
        case 'success':
            notify(
                  <ToastMessage>
                    {mesg}
                  </ToastMessage>,
                {
                  type: 'success',
                  showIcon: true,
                //   autoClose: true,
                  autoClose:1500,
                  showProgress: true,
                  position:"bottom-right"
                },
            );
            break;
        case 'error':
            notify(
                <Fragment>
                  <ToastTitle>{title}</ToastTitle>
                  <ToastMessage>
                    {mesg}
                  </ToastMessage>
                </Fragment>,
                {
                  type: 'error',
                  showIcon: true,
                  autoClose: false,
                //   autoClose:2500,
                  showProgress: true,
                  position:"bottom-right"
                },
            );
            break;
        case 'warning':
            notify({mesg}, {
                type: 'warning',
                showIcon: true,
                autoClose: true,
                position:"bottom-right"
            });
            break;
        case 'info':
            notify(
                <Fragment>
                    <ToastTitle>{title}</ToastTitle>
                    <ToastMessage>{mesg}</ToastMessage>
                </Fragment>,{
                    position:"bottom-right"
                }
            );
            break;
        default:
            break;
    }
};



