import React, {useState, useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";
import ContentEditable from 'react-contenteditable';
// import { updateBeneficiarioCovidAyudas, serverMensaje } from '../../redux/reducers/beneficiarios';
import { updateAyudasCovid, serverMensaje } from '../../redux/reducers/covid19';
import {capitalize, highlightAll, disableNewlines, pastePlainText } from '../../utils/utils';
import Alert, {AlertMessage} from 'calcite-react/Alert';
import {showToaster} from './BenefShowMesg';
import Pagination from '../Pagination';
import '../../styles/css/font-awesome.min.css';

// import Switch from 'calcite-react/Switch';
import Accordion, {
    AccordionSection,
    AccordionTitle,
    AccordionContent
} from 'calcite-react/Accordion';
import Select from 'calcite-react/Select';
import {MenuItem} from 'calcite-react/Menu';
import DatePicker from 'calcite-react/DatePicker';
import moment from 'moment';
import 'moment/locale/es-us';
import styled from "styled-components";

import tipoCasoAyuda from '../../data/tipocasoayuda.json';
import tipoAfectacion from '../../data/tipoafectacion.json';
import tipoEstadoAyuda from '../../data/estadoayuda.json';
import tipoAyuda from '../../data/tipoayuda.json';


const BeneficiarioModulo = styled.div`
    height: auto;
    background: #fff;
    border: 0;
    padding: 0px;
    margin: 4px;
    overflow:hidden;
`;
const AccordionModulo = styled(Accordion)`
    padding:0;
`;
const AccordionTitleModule = styled(AccordionTitle)`
    background-color: #e6e6e6;
    color: #4d4d4d;
    font-size: 1.25em;
    font-weight: 700;
    // border-bottom: 1px solid #d9d9d9;
    // box-shadow: 0px 2px 6px #e1e1e1;
`;

const BenefCovidAyudas = ({covidayudas}) => {
    const dispatch = useDispatch();
    const mensaje = useSelector(state => state.beneficiarios.mensaje);
    const users = useSelector(state => state.users);
    // const isEditable = users.user.tipo==='ADMINISTRADOR' ? true : false;
    const isEditable = (users.user.areatrabajo==='ADMINISTRATIVA' || users.user.areatrabajo==='TRABAJO SOCIAL' || users.user.areatrabajo==='COORDINACIÓN' ) ? true : false;
    const [activeSectionIndexes, setActiveSectionIndexes] = useState([]);
    const [error, setError] = useState(null);
    const onAccordionChange = (evt, index) => {
        activeSectionIndexes.includes(index)
        ? setActiveSectionIndexes(activeSectionIndexes.filter(
            item => index !== item,
          ))
        : setActiveSectionIndexes([...activeSectionIndexes, index]);
    }
    
    const showPages = 1;
    const [currentPage, setCurrentPage] = useState(covidayudas.length-1);
    const totalPages = covidayudas.length;
    const onPageNumberChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        updateDatosEntregaCovidAyudas(covidayudas[pageNumber]);
    }

    const [estadoayudaSelectedValue, setEstadoayudaSelectedValue] = useState(covidayudas[currentPage].estadoayuda || '');
    const [tipoayudaSelectedValue, setTipoayudaSelectedValue] = useState(covidayudas[currentPage].tipoayuda || '');
    const [afectacionSelectedValue, setAfectacionSelectedValue] = useState(covidayudas[currentPage].afectacion || '');
    const [tipocasoSelectedValue, setTipocasoSelectedValue] = useState(covidayudas[currentPage].tipocaso || '');

    const [CA] = useState({
        "id": covidayudas[currentPage].id,
        "beneficiarioId": covidayudas[currentPage].beneficiarioId,
        "docIdquienreclama": covidayudas[currentPage].docIdquienreclama,
        "nombrequienreclama": covidayudas[currentPage].nombrequienreclama ? capitalize(covidayudas[currentPage].nombrequienreclama,true) : '',
        "numentrega": covidayudas[currentPage].numentrega,
        "serial": covidayudas[currentPage].serial,
        "convenio": covidayudas[currentPage].convenio,
        "tipoayuda": covidayudas[currentPage].tipoayuda ? capitalize(covidayudas[currentPage].tipoayuda,true) : '',
        "cupo": covidayudas[currentPage].cupo,
        "valor": covidayudas[currentPage].valor,
        "estadoayuda": covidayudas[currentPage].estadoayuda || '',
        "afectacion": covidayudas[currentPage].afectacion || '',
        "fechainicio": covidayudas[currentPage].fechainicio ? covidayudas[currentPage].fechainicio.split(' ')[0] : null,
        "fechavigencia": covidayudas[currentPage].fechavigencia || null,
        "fechacaducidad": covidayudas[currentPage].fechacaducidad || null,
        "fecharedencion": covidayudas[currentPage].fecharedencion || null,
        "fechaanulacion": covidayudas[currentPage].fechaanulacion || null,
        "transaccion": covidayudas[currentPage].transaccion || '',
        "nut": covidayudas[currentPage].nut || '',
        "terminal": covidayudas[currentPage].terminal || '',
        "descripcion": covidayudas[currentPage].descripcion || '',
        // "entregada": covidayudas[currentPage].entregada==='1' ? true : false, 
        "almacen": covidayudas[currentPage].almacen || '',
        "tipocaso": covidayudas[currentPage].tipocaso || '',
        "comentarios": covidayudas[currentPage].comentarios || '',
        "actualizacion":covidayudas[currentPage].actualizacion || '',
        "version": covidayudas[currentPage].version || ''
    });

    const [itemsCovidAyudas, setItemsCovidAyudas] = useState([
        // {"key":"id", "label": "Identificador Único", "value": CA['id']},
        {"key":"beneficiarioId", "label": "Documento Beneficiario", "value": CA['beneficiarioId']},
        {"key":"docIdquienreclama", "label": "Documento de quién reclama la ayuda", "value": CA['docIdquienreclama']},
        {"key":"nombrequienreclama", "label": "Nombre de quién reclama la ayuda", "value": CA['nombrequienreclama']},
        {"key":"numentrega", "label": "Número de entrega", "value": CA['numentrega']},
        {"key":"serial", "label": "Número Serial", "value": CA['serial']},
        {"key":"convenio", "label": "No. Convenio", "value": CA['convenio']},
        {"key":"tipoayuda", "label": "Tipo de ayuda", "value": CA['tipoayuda']},
        {"key":"cupo", "label": "Cupo o Tope", "value": CA['cupo']},
        {"key":"valor", "label": "Valor de la Ayuda", "value": CA['valor']},
        {"key":"estadoayuda", "label": "Estado de la ayuda", "value": CA['estadoayuda']},
        {"key":"afectacion", "label": "Afectación", "value": CA['afectacion']},
        {"key":"fechainicio", "label": "Fecha de Inicio", "value": CA['fechainicio']},
        {"key":"fechavigencia", "label": "Fecha de Vigencia", "value": CA['fechavigencia']},
        {"key":"fechacaducidad", "label": "Fecha de Caducidad", "value": CA['fechacaducidad']},
        {"key":"fecharedencion", "label": "Fecha de Redención", "value": CA['fecharedencion']},
        {"key":"fechaanulacion", "label": "Fecha de Anulación", "value": CA['fechaanulacion']},
        {"key":"transaccion", "label": "Código de Transacción", "value": CA['transaccion']},
        {"key":"nut", "label": "NUT", "value": CA['nut']},
        {"key":"terminal", "label": "Terminal", "value": CA['terminal']},
        {"key":"descripcion", "label": "Descripción de la Ayuda", "value": CA['descripcion']},
        // {"key":"entregada", "label": "Ayuda Entregada", "value": CA['entregada']},
        {"key":"almacen", "label": "Almacén", "value": CA['almacen']},
        {"key":"tipocaso", "label": "Tipo de Caso", "value": CA['tipocaso']},
        // {"key":"comentarios", "label": "comentarios", "value": CA['comentarios']},
        // {"key":"actualizacion", "label": "Última actualización", "value": CA['actualizacion']},
        // {"key":"version", "label": "Versión", "value": CA['version']}
    ]);

    const [fechaInicio, setFechaInicio] = useState(CA.fechainicio ? moment(CA.fechainicio.split(' ')[0],'YYYY-MM-DD') : null);
    const [fechaInicioPickerFocused, setFechaInicioPickerFocused] = useState(false);
    const [fechaVigencia, setFechaVigencia] = useState(CA.fechavigencia ? moment(CA.fechavigencia.split(' ')[0],'YYYY-MM-DD') : null);
    const [fechaVigenciaPickerFocused, setFechaVigenciaPickerFocused] = useState(false);
    const [fechaCaducidad, setFechaCaducidad] = useState(CA.fechacaducidad ? moment(CA.fechacaducidad.split(' ')[0],'YYYY-MM-DD'): null);
    const [fechaCaducidadPickerFocused, setFechaCaducidadPickerFocused] = useState(false);
    const [fechaRedencion, setFechaRedencion] = useState(CA.fecharedencion ? moment(CA.fecharedencion.split(' ')[0],'YYYY-MM-DD'): null);
    const [fechaRedencionPickerFocused, setFechaRedencionPickerFocused] = useState(false);
    const [fechaAnulacion, setFechaAnulacion] = useState(CA.fechaanulacion ? moment(CA.fechaanulacion.split(' ')[0],'YYYY-MM-DD') : null);
    const [fechaAnulacionPickerFocused, setFechaAnulacionPickerFocused] = useState(false);
    
    useEffect(() => {
        // Guardar los resultados al salir, puede ser contraproducente al fallar la conexión con el servidor u otro evento no deseado
        // se podrián perder los datos modificafos
        
        return () => {
            // if (isUpdated){
            //     console.log('Saving appointment', isUpdated);
            // }
            dispatch(serverMensaje({}));
        }
    }, [dispatch]);

    useEffect(() => {
        if (itemsCovidAyudas){
            // console.log(itemsCovidAyudas);
        }
        if (mensaje.covidayudas && mensaje.covidayudas!=='') {
            if (typeof mensaje.covidayudas === 'string' && mensaje.covidayudas.toLowerCase().includes('error')){
                showToaster('error', 'Ocurrió un fallo en el servidor', mensaje.covidayudas);
            } 
            // else {
            //     showToaster('success',"CovidAyudas del Beneficiario", mensaje.covidayudas); //"🧭 " + mensaje
            // }
        }

    }, [mensaje.covidayudas, itemsCovidAyudas, dispatch]);

    const updateDatosEntregaCovidAyudas = (current_covidayudas) => {
        const newitemsCovidAyudas = itemsCovidAyudas.map((itm, index) => {
            itm.value = current_covidayudas[itm.key];
            CA[itm.key] = current_covidayudas[itm.key];
            return itm;
        });
        CA['id'] = current_covidayudas['id'];
        CA['nombrequienreclama'] = current_covidayudas.nombrequienreclama ? capitalize(current_covidayudas.nombrequienreclama,true) : '';
        CA['tipoayuda'] = current_covidayudas.tipoayuda ? capitalize(current_covidayudas.tipoayuda,true) : '';
        CA['comentarios'] = current_covidayudas.comentarios; 
        // CA['entregada'] = current_covidayudas.entregada==='1' ? true : false;
        setTipoayudaSelectedValue(CA['tipoayuda']);
        setEstadoayudaSelectedValue(CA['estadoayuda']);
        setAfectacionSelectedValue(CA['afectacion']);
        setTipocasoSelectedValue(CA['tipocaso']);
        // Fechas
        setFechaInicio(CA.fechainicio ? moment(CA.fechainicio.split(' ')[0],'YYYY-MM-DD') : null);
        setFechaVigencia(CA.fechavigencia ? moment(CA.fechavigencia.split(' ')[0],'YYYY-MM-DD') : null);
        setFechaCaducidad(CA.fechacaducidad ? moment(CA.fechacaducidad.split(' ')[0],'YYYY-MM-DD'): null);
        setFechaRedencion(CA.fecharedencion ? moment(CA.fecharedencion.split(' ')[0],'YYYY-MM-DD'): null);
        setFechaAnulacion(CA.fechaanulacion ? moment(CA.fechaanulacion.split(' ')[0],'YYYY-MM-DD') : null);
    
        setItemsCovidAyudas(newitemsCovidAyudas);
    };

    const updateItemsCovidAyudas = (f, v) => {
        const newitemsCovidAyudas = itemsCovidAyudas.map((itm, index) => {
            if (itm.key===f){
                itm.value = v;
            }
            return itm;
        });
        setItemsCovidAyudas(newitemsCovidAyudas);
    };

    const updateCovidAyudassonServer = () =>{
        dispatch(serverMensaje({covidayudas:''}));
        // console.log(CA);
        dispatch(updateAyudasCovid( { token: users.user.claveAPI, covidayudas:CA}));
    }

    const replaceSomeText = (s) =>{
        return s
            .replace(/&nbsp;/g, ' ')
            .replace(/<br>/g, '')
            .replace(/&amp;/g, '&')
            .replace(/&gt;/g, '>')
            .replace(/&lt;/g, '<').trim()
    }

    const validateInput = (f, v, final) => {
        const regex_onlynum = /^[0-9]*$/i; ///[0-9,]|\./;
        const regex_nombres = /^['`´a-zA-ZÀ-ÿ\u00C0-\u017F\s]+$/;
        let new_val = null;
        let err = null;
        switch (f) {
            case 'beneficiarioId':
                new_val = v.substr(0,15);
                if (final)
                    new_val = replaceSomeText(v).substr(0,15);
                break;
            case 'docIdquienreclama':
                new_val = v.substr(0,15);
                if (final)
                    new_val = replaceSomeText(v).substr(0,15);
                break;
            case 'numentrega': // Valida cuando el campo es de texto y no un componente Switch
                new_val = v;
                if (!regex_onlynum.test(v)) {
                    err = 'Dígite un solo número válido';
                    new_val = null;
                } else if(parseInt(v)>100){
                    err = 'Dígite un número válido';
                    new_val = null;
                }
                break;
            case 'serial':
                new_val = v.substr(0,32);
                if (final)
                    new_val = replaceSomeText(v).substr(0,32).toUpperCase();
                break;
            case 'convenio':
                new_val = v.substr(0,32);
                if (final)
                    new_val = replaceSomeText(v).substr(0,32).toUpperCase();
                break;
            case 'tipoayuda':
                new_val = v.substr(0,32);
                if (final)
                    new_val = capitalize(replaceSomeText(v).substr(0,32),true);
                break;
            case 'cupo':
                new_val = replaceSomeText(v);
                if (!regex_onlynum.test(new_val)) {
                    err = 'Dígite solo números válidos';
                    new_val = null;
                }
                if (new_val===null) new_val = '';
                break;
            case 'valor':
                new_val = replaceSomeText(v);
                if (!regex_onlynum.test(new_val)) {
                    err = 'Dígite solo números válidos';
                    new_val = null;
                }
                if (new_val===null) new_val = '';
                break;
            case 'nombrequienreclama':
                    new_val = capitalize(replaceSomeText(v), true).substr(0,60);
                    if (!regex_nombres.test(new_val)) {
                        err = 'Dígite un nombre y apellidos válido';
                        new_val = null;
                    }
                    break;
            case 'estadoayuda':
                new_val = v.substr(0,32);
                if (final)
                    new_val = replaceSomeText(v).substr(0,32);
                break;
            case 'fechainicio': //0000-00-00 00:00:00
                new_val = v.substr(0,30);
                if (final && new_val===''){
                    new_val = replaceSomeText(v).substr(0,30);
                    err = 'Dígite una fecha inicial válida';
                    new_val = null;
                }
                break;
            case 'fechavigencia': //0000-00-00 00:00:00
                new_val = v.substr(0,30);
                if (final && new_val===''){
                    new_val = replaceSomeText(v).substr(0,30);
                    err = 'Dígite una fecha vigencia válida';
                    new_val = null;
                }
                break;
            case 'fechacaducidad': //0000-00-00 00:00:00
                new_val = v.substr(0,30);
                if (final && new_val===''){
                    new_val = replaceSomeText(v).substr(0,30);
                    err = 'Dígite una fecha caducidad válida';
                    new_val = null;
                }
                break;
            case 'fecharedencion': //0000-00-00 00:00:00
                new_val = v.substr(0,30);
                if (final && new_val===''){
                    new_val = replaceSomeText(v).substr(0,30);
                    err = 'Dígite una fecha redención válida';
                    new_val = null;
                }
                break;
            case 'fechaanulacion': //0000-00-00 00:00:00
                new_val = v.substr(0,30);
                if (final && new_val===''){
                    new_val = replaceSomeText(v).substr(0,30);
                    err = 'Dígite una fecha anulación válida';
                    new_val = null;
                }
                break;
            case 'afectacion':
                new_val = v.substr(0,32);
                if (final)
                    new_val = replaceSomeText(v).substr(0,32);
                break;
            case 'transaccion':
                new_val = v.substr(0,32);
                if (final)
                    new_val = replaceSomeText(v).substr(0,32);
                break;
            case 'nut':
                new_val = v.substr(0,32);
                if (final)
                    new_val = replaceSomeText(v).substr(0,32);
                break;
            case 'terminal':
                new_val = v.substr(0,32);
                if (final)
                    new_val = replaceSomeText(v).substr(0,32);
                break;
            case 'entregada':
                new_val = v;
                if (!regex_onlynum.test(v)) {
                    err = 'Dígite un solo número válido';
                    new_val = null;
                } else if(parseInt(v)>1){
                    err = 'Dígite un número válido';
                    new_val = null;
                }
                break;
            case 'almacen':
                new_val = v.substr(0,120);
                if (final)
                    new_val = replaceSomeText(v).substr(0,120);
                break;
            case 'tipocaso':
                new_val = v.substr(0,32);
                if (final)
                    new_val = replaceSomeText(v).substr(0,32);
                break;
            case 'descripcion':
                new_val = v.substr(0,255);
                if (final)
                    new_val = replaceSomeText(v).substr(0,255);
                break;
            case 'comentarios':
                new_val = v.trim();
                if (final)
                    new_val = replaceSomeText(v); //.substr(0,255);
                break;
            default:
                break;
        }
        setError(err);
        return new_val
    }
    const handleChange = evt => {
        // console.log(evt.currentTarget.id, evt.target.value);
        const field = evt.currentTarget.id.split('_')[1];
        const new_val = validateInput(field, evt.target.value, false)
        if(new_val){
            CA[field] = new_val
        };
    };
    const handleBlur = evt => {
        const val = evt.currentTarget.innerHTML;
        const field = evt.target.id.split('_')[1];
        // console.log(field, val);
        const new_val = validateInput(field, val, true)
        if(new_val || new_val===''){
            CA[field] = new_val;
            updateItemsCovidAyudas(field, CA[field]);
            updateCovidAyudassonServer(); // Save to server inmediatively
        };
        
    };

    // const onChangeEntregada = (evt) => {
    //     CA.entregada =  evt.target.checked; // ? '1' : '0';
    //     updateItemsCovidAyudas('entregada', CA.entregada);
    //     updateCovidAyudassonServer(); // Save to server inmediatively
    // }

    const handleEstadoAyudaSelectChange = (value, item) => {
        setEstadoayudaSelectedValue(value);
        CA['estadoayuda'] = value;
        updateCovidAyudassonServer();
    };
    // const handleCategoriaseguimientoSelectonBlur = (evt) => {
    //     evt.preventDefault();
    //     evt.stopPropagation();
    //     // evt.currentTarget.setValue(null);
    //     // const vlr = (typeof value === 'string') ? value : ''; 
    //     // setEstadoayudaSelectedValue(vlr);
    //     // setEstadoayudaSelectedItem(vlr==='' ? null : vlr);
    //     // CA['estadoayuda'] = vlr;
    //     // updateCovidAyudassonServer();
    // };
    const handleCategoriaseguimientoonKeyDown  = (evt) => {
        evt.preventDefault();
        evt.stopPropagation();
        const keyCode = evt.keyCode || evt.which
        if (keyCode === 8 || keyCode === 46) {
            setEstadoayudaSelectedValue('');
            CA['estadoayuda'] = '';
            updateCovidAyudassonServer();
            evt.returnValue = false
            if (evt.preventDefault) evt.preventDefault()
        }
    }
    
    const handleTipoAyudaSelectChange = (value, item) => {
        setTipoayudaSelectedValue(value);
        CA['tipoayuda'] = value;
        updateCovidAyudassonServer();
    };
    const handleTipoAyudaonKeyDown  = (evt) => {
        const keyCode = evt.keyCode || evt.which
        if (keyCode === 8 || keyCode === 46) {
            setTipoayudaSelectedValue('');
            CA['tipoayuda'] = '';
            updateCovidAyudassonServer();
            evt.returnValue = false
            if (evt.preventDefault) evt.preventDefault()
        }
    }

    const handleAfectacionSelectChange = (value, item) => {
        setAfectacionSelectedValue(value);
        CA['afectacion'] = value;
        updateCovidAyudassonServer();
    };
    const handleAfectaciononKeyDown  = (evt) => {
        const keyCode = evt.keyCode || evt.which
        if (keyCode === 8 || keyCode === 46) {
            setAfectacionSelectedValue('');
            CA['afectacion'] = '';
            updateCovidAyudassonServer();
            evt.returnValue = false
            if (evt.preventDefault) evt.preventDefault()
        }
    }

    const handleTipocasoSelectChange = (value, item) => {
        setTipocasoSelectedValue(value);
        CA['tipocaso'] = value;
        updateCovidAyudassonServer();
    };
    const handleTipocasoonKeyDown  = (evt) => {
        const keyCode = evt.keyCode || evt.which
        if (keyCode === 8 || keyCode === 46) {
            setTipocasoSelectedValue('');
            CA['tipocaso'] = '';
            updateCovidAyudassonServer();
            evt.returnValue = false
            if (evt.preventDefault) evt.preventDefault()
        }
    }
    
    // DatePicker
    const onFechaInicioChange = (date) =>{
        if (date.preventDefault) date.preventDefault()
        if (date){
            // console.log("onFechaCovidAyudasChange formatted==>",date.format('YYYY-MM-DD'));
            CA.fechainicio = date.utc().format('YYYY-MM-DD HH:mm:ss');
            setFechaInicio(date);
            updateCovidAyudassonServer();
        }
    }
    const onFechaInicioFocusChange = ({ focused }) =>{
        setFechaInicioPickerFocused(focused)
    }
    // DatePicker 
    const onFechaVigenciaChange = (date) =>{
        if (date.preventDefault) date.preventDefault()
        if (date){
            CA.fechavigencia = date.utc().format('YYYY-MM-DD HH:mm:ss');
            // CA.fechavigencia =  moment(date.utc()).format("YYYY-MM-DD HH:mm:ss");
            setFechaVigencia(date);
            updateCovidAyudassonServer();
        }
    }
    const onFechaVigenciaFocusChange = ({ focused }) =>{
        setFechaVigenciaPickerFocused(focused)
    }

    // DatePicker 
    const onFechaCaducidadChange = (date) =>{
        if (date.preventDefault) date.preventDefault()
        if (date){
            CA.fechacaducidad = date.utc().format('YYYY-MM-DD HH:mm:ss');
            // CA.fechacaducidad =  moment(date.utc()).format("YYYY-MM-DD HH:mm:ss");
            setFechaCaducidad(date);
            updateCovidAyudassonServer();
        }
    }
    const onFechaCaducidadFocusChange = ({ focused }) =>{
        setFechaCaducidadPickerFocused(focused)
    }
    // DatePicker 
    const onFechaRedencionChange = (date) =>{
        if (date.preventDefault) date.preventDefault()
        if (date){
            CA.fecharedencion = date.utc().format('YYYY-MM-DD HH:mm:ss');
            // CA.fecharedencion =  moment(date.utc()).format("YYYY-MM-DD HH:mm:ss");
            setFechaRedencion(date);
            updateCovidAyudassonServer();
        }
    }
    const onFechaRedencionFocusChange = ({ focused }) =>{
        setFechaRedencionPickerFocused(focused)
    }
    // DatePicker 
    const onFechaAnulacionChange = (date) =>{
        if (date.preventDefault) date.preventDefault()
        if (date){
            CA.fechaanulacion = date.utc().format('YYYY-MM-DD HH:mm:ss');
            // CA.fechaanulacion =  moment(date.utc()).format("YYYY-MM-DD HH:mm:ss");
            setFechaAnulacion(date);
            updateCovidAyudassonServer();
        }
    }
    const onFechaAnulacionFocusChange = ({ focused }) =>{
        setFechaAnulacionPickerFocused(focused)
    }


    return (
        <BeneficiarioModulo>
            <AccordionModulo
                onAccordionChange={onAccordionChange}
                activeSectionIndexes={activeSectionIndexes}
            >
                <AccordionSection>
                    <AccordionTitleModule>Ayudas Covid-19</AccordionTitleModule>
                    {activeSectionIndexes.length>0 &&  <AccordionContent style={{marginBottom: '10px'}}>
                        {error && <div style={{color: 'red', width: '100%'}}>
                                <Alert red showIcon style={{minWidth: '100%', marginBottom: '10px'}}
                                    showCloseLabel 
                                    onClose={() => setError(null)}>
                                    <AlertMessage>
                                        Error:{' '}
                                        <strong>{error}</strong>
                                    </AlertMessage>
                                </Alert>
                            </div> 
                        }
                        {/* Pagenation */}
                        <Pagination
                            className="pagination pagination-bienestar"
                            totalPages={totalPages}
                            currentPage={currentPage}
                            showPages={showPages}
                            onChangePage={onPageNumberChange}
                        />

                        {/* ITEMS */}
                        <div className="itemContainer-covid">
                        {   
                            itemsCovidAyudas.map((itm, index) => {
                                // if (itm.value!==null && itm.value!=="" ){
                                    return (<div key={index} className="itemContainer">
                                        <div className="itemContainer-attr" 
                                            style={{backgroundColor:(index % 2 === 0) ? '#f8f8f8': '#fff'}}
                                        >{itm.label}</div>
                                        <div className="itemContainer-value" 
                                            style={{backgroundColor:(index % 2 === 0) ? '#f8f8f8': '#fff'}}
                                        >
                                            {/* {itm.value} */}
                                            { 
                                            itm.key==='estadoayuda' 
                                            ?
                                                <Select filterable positionFixed disabled={!isEditable} 
                                                    placeholder={'Seleccione...'}
                                                    onChange={handleEstadoAyudaSelectChange}
                                                    onKeyDown={handleCategoriaseguimientoonKeyDown}
                                                    // onBlur={handleCategoriaseguimientoSelectonBlur}
                                                    // onChange={(e, val) => console.log(e, val)}
                                                    value={estadoayudaSelectedValue || ''}
                                                    selectedValue={estadoayudaSelectedValue || ''}
                                                >
                                                {tipoEstadoAyuda.tipo.map(e => {
                                                    return (
                                                    <MenuItem key={e.id} value={e.name}>
                                                        {e.name}
                                                    </MenuItem>
                                                    )
                                                })}
                                                </Select>
                                            :
                                            itm.key==='tipoayuda' 
                                            ?
                                                <Select filterable positionFixed disabled={!isEditable} 
                                                    placeholder={'Seleccione...'}
                                                    onChange={handleTipoAyudaSelectChange}
                                                    onKeyDown={handleTipoAyudaonKeyDown}
                                                    value={tipoayudaSelectedValue || ''}
                                                    selectedValue={tipoayudaSelectedValue || ''}
                                                >
                                                {tipoAyuda.tipo.map(e => {
                                                    return (
                                                    <MenuItem key={e.id} value={e.name}>
                                                        {e.name}
                                                    </MenuItem>
                                                    )
                                                })}
                                                </Select>
                                            :
                                            itm.key==='afectacion' 
                                            ?
                                                <Select filterable positionFixed disabled={!isEditable} 
                                                    placeholder={'Seleccione...'}
                                                    onChange={handleAfectacionSelectChange}
                                                    onKeyDown={handleAfectaciononKeyDown}
                                                    value={afectacionSelectedValue || ''}
                                                    selectedValue={afectacionSelectedValue || ''}
                                                >
                                                {tipoAfectacion.tipo.map(e => {
                                                    return (
                                                    <MenuItem key={e.id} value={e.name}>
                                                        {e.name}
                                                    </MenuItem>
                                                    )
                                                })}
                                                </Select>
                                            :
                                            itm.key==='tipocaso' 
                                            ?
                                                <Select filterable positionFixed disabled={!isEditable} 
                                                    placeholder={'Seleccione...'}
                                                    onChange={handleTipocasoSelectChange}
                                                    onKeyDown={handleTipocasoonKeyDown}
                                                    value={tipocasoSelectedValue || ''}
                                                    selectedValue={tipocasoSelectedValue || ''}
                                                >
                                                {tipoCasoAyuda.tipo.map(e => {
                                                    return (
                                                    <MenuItem key={e.id} value={e.name}>
                                                        {e.name}
                                                    </MenuItem>
                                                    )
                                                })}
                                                </Select>
                                            :
                                            // itm.key==='entregada' 
                                            // ?
                                            //     <Switch 
                                            //         labelPosition={"before" || '' }
                                            //         style={{margin: '0 0 0.5rem 0'}}
                                            //         name={ 'entregada_' + CA.id } 
                                            //         checked={ CA.entregada || false} 
                                            //         disabled={!isEditable} 
                                            //         onChange={onChangeEntregada} />
                                            // :

                                            itm.key==='fechainicio' 
                                            ?
                                                <DatePicker 
                                                    placeholder="Fecha Inicial"
                                                    id="fechaInicio"
                                                    // style={{backgroundColor:(index % 2 === 0) ? '#f8f8f8 !important': '#fff !important'}}
                                                    date={fechaInicio}
                                                    focused={fechaInicioPickerFocused}
                                                    onDateChange={onFechaInicioChange}
                                                    onFocusChange={onFechaInicioFocusChange}
                                                    numberOfMonths={1}
                                                    disabled={!isEditable}
                                                    readOnly={true}
                                                    hideInputIcon
                                                    disableScroll={false}
                                                    displayFormat={'YYYY-MM-DD'} // 'MMM DD YYYY'
                                                    isOutsideRange={() => {}}
                                                    monthYearSelectionMode="MONTH_YEAR"
                                                    yearSelectDates={{"startYear" : 2020, "endYear" : new moment().year() + 1}}
                                                    // displayFormat={moment.localeData().longDateFormat('L')}
                                                    // appendToBody={true}
                                                    // withPortal={true}
                                                />
                                            :
                                            itm.key==='fechavigencia' 
                                            ?
                                                <DatePicker 
                                                    placeholder="Fecha Vigencia"
                                                    id="fechaVigencia"
                                                    date={fechaVigencia}
                                                    focused={fechaVigenciaPickerFocused}
                                                    onDateChange={onFechaVigenciaChange}
                                                    onFocusChange={onFechaVigenciaFocusChange}
                                                    numberOfMonths={1}
                                                    disabled={!isEditable}
                                                    readOnly={true}
                                                    hideInputIcon
                                                    disableScroll={false}
                                                    displayFormat={'YYYY-MM-DD'} // 'MMM DD YYYY'
                                                    isOutsideRange={() => {}}
                                                    monthYearSelectionMode="MONTH_YEAR"
                                                    yearSelectDates={{"startYear" : 2020, "endYear" : new moment().year() + 1}}
                                                    // appendToBody={true}
                                                />
                                            :
                                            itm.key==='fechacaducidad' 
                                            ?
                                                <DatePicker 
                                                    placeholder="Fecha Caducidad"
                                                    id="fechaCaducidad"
                                                    date={fechaCaducidad}
                                                    focused={fechaCaducidadPickerFocused}
                                                    onDateChange={onFechaCaducidadChange}
                                                    onFocusChange={onFechaCaducidadFocusChange}
                                                    numberOfMonths={1}
                                                    disabled={!isEditable}
                                                    readOnly={true}
                                                    hideInputIcon
                                                    disableScroll={false}
                                                    displayFormat={'YYYY-MM-DD'} // 'MMM DD YYYY'
                                                    isOutsideRange={() => {}}
                                                    monthYearSelectionMode="MONTH_YEAR"
                                                    yearSelectDates={{"startYear" : 2020, "endYear" : new moment().year() + 1}}
                                                    // appendToBody={true}
                                                />
                                            :
                                            itm.key==='fecharedencion'
                                            ?
                                                <DatePicker 
                                                    placeholder="Fecha Redención"
                                                    id="fechaRedencion"
                                                    date={fechaRedencion}
                                                    focused={fechaRedencionPickerFocused}
                                                    onDateChange={onFechaRedencionChange}
                                                    onFocusChange={onFechaRedencionFocusChange}
                                                    numberOfMonths={1}
                                                    disabled={!isEditable}
                                                    readOnly={true}
                                                    hideInputIcon
                                                    disableScroll={false}
                                                    displayFormat={'YYYY-MM-DD'} // 'MMM DD YYYY'
                                                    isOutsideRange={() => {}}
                                                    monthYearSelectionMode="MONTH_YEAR"
                                                    yearSelectDates={{"startYear" : 2020, "endYear" : new moment().year() + 1}}
                                                    // appendToBody={true}
                                                />
                                            :
                                            itm.key==='fechaanulacion'
                                            ?
                                                <DatePicker 
                                                    placeholder="Fecha Anulación"
                                                    id="fechaAnulacion"
                                                    date={fechaAnulacion}
                                                    focused={fechaAnulacionPickerFocused}
                                                    onDateChange={onFechaAnulacionChange}
                                                    onFocusChange={onFechaAnulacionFocusChange}
                                                    numberOfMonths={1}
                                                    disabled={!isEditable}
                                                    readOnly={true}
                                                    hideInputIcon
                                                    disableScroll={false}
                                                    displayFormat={'YYYY-MM-DD'} // 'MMM DD YYYY'
                                                    isOutsideRange={() => {}}
                                                    monthYearSelectionMode="MONTH_YEAR"
                                                    yearSelectDates={{"startYear" : 2020, "endYear" : new moment().year() + 1}}
                                                    // appendToBody={true}
                                                />
                                            :
                                            (itm.key==='id' || itm.key==='beneficiarioId')
                                            ?
                                                <ContentEditable
                                                    id={'covidayudas_'+itm.key}
                                                    html={itm.value || ''} // innerHTML of the editable div
                                                    className='itemContainer-value-long'
                                                    disabled={true}      // use true to disable editing
                                                    // onPaste={pastePlainText} // En caso se copie y pegue de otra pagina de internet
                                                    // onChange={handleChange} // handle innerHTML change
                                                    // onKeyPress={disableNewlines}  
                                                    // onFocus={highlightAll}
                                                    // onBlur={handleBlur}
                                                    tagName='div' // Use a custom HTML tag (uses a div by default)
                                                />
                                            :
                                                <ContentEditable
                                                    id={'covidayudas_'+itm.key}
                                                    html={itm.value || ''} // innerHTML of the editable div
                                                    className='itemContainer-value-long'
                                                    disabled={!isEditable}      // use true to disable editing
                                                    onPaste={pastePlainText} // En caso se copie y pegue de otra pagina de internet
                                                    onChange={handleChange} // handle innerHTML change
                                                    onKeyPress={disableNewlines}  
                                                    onFocus={highlightAll}
                                                    onBlur={handleBlur}
                                                    tagName='div' // Use a custom HTML tag (uses a div by default)
                                                />
                                            
                                            }

                                        </div>
                                    </div>)
                                // } 
                            })
                        }
                        </div>

                        <div className="Observaciones-Comentarios">
                            <div className="benef-texto-observaciones-title">Comentarios en general:</div>
                            <div className="benef-texto-observaciones"> 
                                <ContentEditable
                                    id={'covidayudas_comentarios'}
                                    html={CA.comentarios || ''} // innerHTML of the editable div
                                    disabled={!isEditable}        // use true to disable editing
                                    onPaste={pastePlainText}
                                    onChange={handleChange} // handle innerHTML change
                                    onFocus={highlightAll}
                                    onBlur={handleBlur}
                                    tagName='div' // Use a custom HTML tag (uses a div by default)
                                    style={{ "minHeight": "40px", "border": "1px dashed" }}
                                />
                            </div>
                        </div>

                        <div className="benef-texto-actualizacion">Última actualización: {CA['actualizacion']}</div>

                    </AccordionContent>
                }
                </AccordionSection>
            </AccordionModulo>
        </BeneficiarioModulo>
    );
    
}

export default BenefCovidAyudas;

