import React, {useState, useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateBeneficiarioIntegrantesHogar, insertBeneficiarioIntegrantesHogar,
    deleteBeneficiarioIntegrantesHogar,
    serverMensaje 
} from '../../redux/reducers/beneficiarios';
import {highlightAll, disableNewlines, pastePlainText, 
    capitalize, getFirstWords,calculate_age 
} from '../../utils/utils';
import {showToaster} from './BenefShowMesg';
import ContentEditable from 'react-contenteditable';
import Switch from 'calcite-react/Switch';
import Alert, {AlertMessage} from 'calcite-react/Alert';
import Accordion, {
    AccordionSection,
    AccordionTitle,
    AccordionContent
} from 'calcite-react/Accordion';
import Table, {
    TableHeader,
    TableHeaderRow,
    TableHeaderCell,
    TableBody,
    TableRow,
    TableCell
} from 'calcite-react/Table';
import Select from 'calcite-react/Select';
import {MenuItem} from 'calcite-react/Menu';
import Button from 'calcite-react/Button';
import DatePicker from 'calcite-react/DatePicker';
import moment from 'moment';
import 'moment/locale/es-us';

import SaveIcon from 'calcite-ui-icons-react/SaveIcon';
import TrashIcon from 'calcite-ui-icons-react/TrashIcon';
// import XIcon from 'calcite-ui-icons-react/XCircleIcon';
import UserPlusIcon from 'calcite-ui-icons-react/UserPlusIcon';

import tipoDocs from '../../data/tipodoc.json';
import tipoParentescos from '../../data/parentesco.json';
import tipoEstadoCivil from '../../data/estadocivil.json';
import tipoEducacion from '../../data/tipoeducacion.json';

import styled from "styled-components";

const BeneficiarioModulo = styled.div`
    height: auto;
    background: #fff;
    border: 0;
    padding: 0px;
    margin: 4px;
    overflow:hidden;
`;
const AccordionModulo = styled(Accordion)`
    padding:0;
`;
const AccordionTitleModule = styled(AccordionTitle)`
    background-color: #e6e6e6;
    color: #4d4d4d;
    font-size: 1.25em;
    font-weight: 700;
    // border-bottom: 1px solid #d9d9d9;
    // box-shadow: 0px 2px 6px #e1e1e1;
`;

const BenefFamiliares = ({integranteshogar, beneficiarioId}) => {
    const dispatch = useDispatch();
    const mensaje = useSelector(state => state.beneficiarios.mensaje);
    const users = useSelector(state => state.users);
    // const isEditable = users.user.tipo==='ADMINISTRADOR' ? true : false;
    // const isEditable = (users.user.tipo==='ADMINISTRADOR' && users.user.role==='A' && users.user.permisos==='A') ? true : false;
    const isEditable = (users.user.areatrabajo==='ADMINISTRATIVA' || users.user.areatrabajo==='TRABAJO SOCIAL' || users.user.areatrabajo==='COORDINACIÓN' || users.user.areatrabajo==='PSICOLOGÍA' ) ? true : false;
    // const canView = (users.user.areatrabajo==='ADMINISTRATIVA' || users.user.areatrabajo==='TECNOLOGÍA' ) ? true : false;
    
    const [isBtnAddEnable, setIsBtnAddEnable] = useState(false);
    const [error, setError] = useState(null);
    const [IH, updateIH] = useState([...integranteshogar]); //Object.assign({}, integranteshogar); // Arreglos no Objects
    
    const [focusedfechasnacimiento, setFocusedfechasnacimiento] = useState({});

    const [activeSectionIndexes, setActiveSectionIndexes] = useState([]);
    const onAccordionChange = (evt, index) => {
        activeSectionIndexes.includes(index)
        ? setActiveSectionIndexes(activeSectionIndexes.filter(
            item => index !== item,
          ))
        : setActiveSectionIndexes([...activeSectionIndexes, index]);
    }

    useEffect(() => {
        // 
        return () => {
            dispatch(serverMensaje({}));
        }
    }, [dispatch]);

    useEffect(() => {

        if (mensaje.integranteshogar && mensaje.integranteshogar!=='') {
            const insertIntegrantesHogar = (id, f, v) => {
                if (IH){
                    updateIH(IH.map((itm, index) => {
                        if (itm.id===id){
                            itm[f] = v;
                        }
                        return itm;
                    }));
                    mensaje.integranteshogar='';
                    // dispatch(serverMensaje({integranteshogar:''}));
                }
            };

            if (typeof mensaje.integranteshogar === 'string' && mensaje.integranteshogar.toLowerCase().includes('error')){
                showToaster('error', 'Ocurrió un fallo en el servidor', mensaje.integranteshogar);
            } 
            else {
                showToaster('success',"Familiares del Beneficiario", mensaje.integranteshogar.split('.')[0]);
                // Update Id for edition
                // Cambiar el id por el que devuelve el servidor o transacción de MySQL
                insertIntegrantesHogar('-1', 'id', String(mensaje.integranteshogar.split('.')[1]));
            }
        }

    }, [mensaje.integranteshogar, updateIH, IH, dispatch]);
    
    const updateIntegrantesHogar = (id, f, v) => {
        if (IH){
            updateIH(IH.map((itm, index) => {
                if (itm.id===id){
                    itm[f] = v;
                }
                itm.modificadopor = getFirstWords(users.user.nombre) || '';

                return itm;
            }));
        }
    };

    const updateFocusedDatePickers = (focused, id) =>{
        let focusefechas = {
            '-1': false, // Agregamos el del nuevo registro
        }
        integranteshogar.forEach((itm, idx) => {
            focusefechas[itm.id] = false;
            if (itm.id ===id)
                focusefechas[itm.id] = focused; //true
        });
        if (String(id)==='-1'){
            focusefechas[String(id)] = focused;
        }
        
        setFocusedfechasnacimiento(focusefechas);
    } 

    const updateDataonServer = (id, action, newItem) =>{
        dispatch(serverMensaje({integranteshogar:''}));
        switch (action) {
            case 'insert':
                if (parseInt(id)< 0 && newItem){
                    // console.log('insertando nuevo registro: ', newItem);
                    setIsBtnAddEnable(true);
                }
                break;
            case 'save':
                if (id){
                    const reg = IH.filter(itm => itm.id===id);
                    if (reg && reg.length>0){
                        dispatch(insertBeneficiarioIntegrantesHogar( { "token": users.user.claveAPI, "integranteshogar":reg[0]}));
                        setIsBtnAddEnable(false);
                    }
                }
                break;
            case 'remove':
                if (id){
                    const reg = IH.filter(itm => itm.id===id);
                    if (reg && reg.length>0){
                        dispatch(deleteBeneficiarioIntegrantesHogar( { token: users.user.claveAPI, integranteshogar:reg[0]}));
                    }
                }
                break;
            default: // update
                // Actualizar solo el registro necesario, para no enviar toda la tabla
                if (id && parseInt(id) > 0){
                    const reg = IH.filter(itm => itm.id===id);
                    if (reg && reg.length>0){
                        dispatch(updateBeneficiarioIntegrantesHogar( { token: users.user.claveAPI, integranteshogar:reg[0]}));
                    }
                }
                break;
        }
        
        
    }

    const replaceSomeText = (s) =>{
        return s
            .replace(/&nbsp;/g, ' ')
            .replace(/<br>/g, '')
            .replace(/&amp;/g, '&')
            .replace(/&gt;/g, '>')
            .replace(/&lt;/g, '<').trim()
    }

    const validateInput = (f, v, final) => {
        const regex_onlynum = /^[0-9]*$/i; ///[0-9,]|\./;
        // const regex_nombres = /^['`´a-zA-ZÀ-ÿ\u00C0-\u017F\s]+$/;
        let new_val = null;
        let err = null;
        switch (f) {
            // case 'beneficiarioId':
            //     new_val = replaceSomeText(v);
            //     if (!regex_onlynum.test(new_val)) {
            //         err = 'Dígite solo números válidos';
            //         new_val = null;
            //     }
            //     if (new_val===null) new_val = '';
            //     break;
            case 'tipodoc':
                new_val = v.substr(0,32);
                if (final)
                    new_val = replaceSomeText(v).substr(0,120);
                break;
            case 'docId':
                new_val = replaceSomeText(v);
                if (!regex_onlynum.test(new_val)) {
                    err = 'Dígite solo números válidos';
                    new_val = null;
                }
                if (new_val===null) new_val = '';
                break;
            case 'nombre':
                new_val = v.substr(0,120);
                if (final)
                    new_val = replaceSomeText(v).substr(0,120);
                break;
            case 'apellidos':
                new_val = v.substr(0,32);
                if (final)
                    new_val = replaceSomeText(v).substr(0,120);
                break;
            case 'parentesco':
                new_val = v.substr(0,32);
                if (final)
                    new_val = replaceSomeText(v).substr(0,32);
                break;
            case 'estadocivil':
                new_val = v.substr(0,32);
                if (final)
                    new_val = replaceSomeText(v).substr(0,32);
                break;
            case 'niveleducativo':
                new_val = v.substr(0,120);
                if (final)
                    new_val = replaceSomeText(v).substr(0,120);
                break;
            case 'nivel':
                new_val = replaceSomeText(v);
                if (!regex_onlynum.test(new_val)) {
                    err = 'Dígite solo números válidos';
                    new_val = null;
                }
                if (new_val===null) new_val = '';
                break;
            case 'fechanacimiento': //0000-00-00 00:00:00
                new_val = v.substr(0,30);
                if (final && new_val===''){
                    new_val = replaceSomeText(v).substr(0,30);
                    err = 'Dígite una fecha de nacimiento válida';
                    new_val = null;
                }
                break;
            case 'edad':
                new_val = replaceSomeText(v);
                if (!regex_onlynum.test(new_val)) {
                    err = 'Dígite solo números válidos';
                    new_val = null;
                }
                if (new_val===null) new_val = '';
                break;
            case 'telefono':
                new_val = replaceSomeText(v);
                if (!regex_onlynum.test(new_val)) {
                    err = 'Dígite solo números válidos';
                    new_val = null;
                }
                if (new_val===null) new_val = '';
                break;
            case 'celular':
                new_val = replaceSomeText(v);
                if (!regex_onlynum.test(new_val)) {
                    err = 'Dígite solo números válidos';
                    new_val = null;
                }
                if (new_val===null) new_val = '';
                break;
            case 'direccion':
                new_val = v.substr(0,120);
                if (final)
                    new_val = replaceSomeText(v).substr(0,120);
                break;
            case 'viveconbeneficiario': // Valida cuando el campo es de texto y no un componente Switch
                new_val = v;
                if (!regex_onlynum.test(v)) {
                    err = 'Dígite un solo número válido';
                    new_val = null;
                } else if(parseInt(v)>1){
                    err = 'Dígite un número válido';
                    new_val = null;
                }
                break;
            case 'modificadopor':
                new_val = v.substr(0,32);
                if (final)
                    new_val = replaceSomeText(v).substr(0,2);
                break;
            case 'comentarios':
                new_val = replaceSomeText(v); //.substr(0,255);
                break;
            default:
                break;
        }
        setError(err);
        return new_val
    }

    const handleTipodocSelectChange = (value, id) => {
        updateIntegrantesHogar(id,'tipodoc', value );
        updateDataonServer(id);
    };

    const handleParentescoSelectChange = (value, id) => {
        updateIntegrantesHogar(id,'parentesco', value );
        updateDataonServer(id);
    };

    const handleEstadocivilSelectChange = (value, id) => {
        updateIntegrantesHogar(id,'estadocivil', value );
        updateDataonServer(id);
    };
    const handleNiveleducativoSelectChange = (value, id) => {
        updateIntegrantesHogar(id,'niveleducativo', value );
        updateDataonServer(id);
    };
    
    const handleChange = evt => {
        const [, field, id] = evt.currentTarget.id.split('_');
        const new_val = validateInput(field, evt.target.value, false);
        if(new_val){
            updateIntegrantesHogar(id,field, new_val );
        };

    };
    const handleBlur = evt => {
        const val = evt.currentTarget.innerHTML;
        const [,field, id] = evt.target.id.split('_');
        const new_val = validateInput(field, val, true)
        if(new_val || new_val===''){
            updateIntegrantesHogar(id,field, new_val );
            updateDataonServer(id);
        };
    };

    const onClickAddIntegranteHogar = () => {
        const newId = -1;
        const nuevo_integrante = {
            "id": String(newId),
            "beneficiarioId": beneficiarioId,
            "nombre": "",
            "parentesco": "",
            "edad": "0",
            "estadocivil": "",
            "niveleducativo": "",
            "tipodoc" : "",
            "docId" : "",
            "apellidos" : "",
            "fechanacimiento" : new moment('1900-01-01','YYYY-MM-DD').format('YYYY-MM-DD'),
            "nivel" : "",
            "viveconbeneficiario" : "1",
            "direccion" : "",
            "telefono" : "",
            "celular" : "", 
            "modificadopor" : getFirstWords(users.user.nombre) || "", 
            // "comentarios" : "", comentarios;
            // "actualizacion" : "", actualizacion;
            // "version": "2020-06-03 10:03:03"
        }
        updateIH([...IH].concat(nuevo_integrante) );
        updateDataonServer(newId, 'insert', nuevo_integrante);
    }
    const onClickSaveIntegranteHogar = (id) => {
        updateDataonServer(id, 'save');
    }
    const onClickRemoveIntegranteHogar = (id) => {
        updateIH([...IH].filter(itm => itm.id!==id));
        updateDataonServer(id, 'remove');
    }
    const onChangeViveconbeneficiario = (evt, id) => {
        const value =  (evt.target.checked===true) ? '1' : '0';
        updateIntegrantesHogar(id,'viveconbeneficiario', value );
        updateDataonServer(id);
    }

    // DatePicker 
    const onFechaNacimientoChange = (date, id) =>{
        if (date.preventDefault) date.preventDefault()
        if (date){
            const value = date.utc().format('YYYY-MM-DD HH:mm:ss');
            // const value = new moment(date,'YYYY-MM-DD');
            updateIntegrantesHogar(id,'fechanacimiento', value );
            // const newage = calculate_age(new moment(date,'YYYY-MM-DD').format('YYYY-MM-DD'));
            const newage = calculate_age(value);
            updateIntegrantesHogar(id,'edad', newage.toString() );
            updateDataonServer(id);
        }
    }
    // openDatepicker = () => this._calendar.setOpen(true);
    const onFechaNacimientoFocusChange = ({focused}, id) =>{
        // console.log("focused, id", focused, id);
        updateFocusedDatePickers(focused, id);
    }

    return (

        <BeneficiarioModulo>
            <AccordionModulo
                onAccordionChange={onAccordionChange}
                activeSectionIndexes={activeSectionIndexes}
            >
                <AccordionSection>
                    <AccordionTitleModule>Familiares</AccordionTitleModule>
                    {activeSectionIndexes.length>0 &&  <AccordionContent style={{marginBottom: '10px'}}>
                        {error && <div style={{color: 'red', width: '100%'}}>
                                <Alert red showIcon style={{minWidth: '100%', marginBottom: '10px'}}
                                    showCloseLabel 
                                    onClose={() => setError(null)}>
                                    <AlertMessage>
                                        Error:{' '}
                                        <strong>{error}</strong>
                                    </AlertMessage>
                                </Alert>
                            </div> 
                        }
                    <div className="itemContainer-table-integranteshogar">
                        <div className="itemContainer-table-integranteshogar-nota"><b>Nota:</b> La información podría estar duplicada mientras se unifican los registros con ECASE</div>
                        <Table blue striped>
                        <TableHeader>
                            <TableHeaderRow>
                                <TableHeaderCell style={{textAlign:'center'}}>Tipo</TableHeaderCell>
                                <TableHeaderCell style={{textAlign:'center'}}>Documento</TableHeaderCell>
                                <TableHeaderCell style={{textAlign:'center'}}>Nombres</TableHeaderCell>
                                <TableHeaderCell style={{textAlign:'center'}}>Apellidos</TableHeaderCell>
                                <TableHeaderCell style={{textAlign:'center'}}>Parentesco</TableHeaderCell>
                                <TableHeaderCell style={{textAlign:'center'}}>Fecha Nacimiento</TableHeaderCell>
                                <TableHeaderCell style={{textAlign:'center'}}>Edad</TableHeaderCell>
                                <TableHeaderCell style={{textAlign:'center'}}>Estado Civil</TableHeaderCell>
                                <TableHeaderCell style={{textAlign:'center'}}>Educación</TableHeaderCell>
                                <TableHeaderCell style={{textAlign:'center'}}>Nivel</TableHeaderCell>
                                <TableHeaderCell style={{textAlign:'center'}}>Viven juntos</TableHeaderCell>
                                <TableHeaderCell style={{textAlign:'center'}}>Dirección</TableHeaderCell>
                                <TableHeaderCell style={{textAlign:'center'}}>Teléfono</TableHeaderCell>
                                <TableHeaderCell style={{textAlign:'center'}}>Celular</TableHeaderCell>
                                <TableHeaderCell style={{textAlign:'center'}}>Acción</TableHeaderCell>
                            </TableHeaderRow>
                        </TableHeader>
                        <TableBody>
                            {
                                IH.map((ih, index) => (
                                    <TableRow key={ih.id}>
                                        <TableCell style={{minWidth:'80px'}}>
                                            <Select filterable positionFixed minimal
                                                disabled={!isEditable} 
                                                id={'integranteshogar_tipodoc_'+ih.id}
                                                className={'itemContainer-table-selects-tipodoc'}
                                                onChange={(value, item) => handleTipodocSelectChange(value, ih.id)}
                                                selectedValue={ih.tipodoc.trim()}
                                                // style={{backgroundColor: 'transparent'}}
                                            >
                                            {tipoDocs.tipo.map(p => {
                                                return (
                                                <MenuItem key={p.id} value={p.name}>
                                                    {p.name}
                                                </MenuItem>
                                                )
                                            })}
                                            </Select>
                                        </TableCell>
                                        <TableCell style={{minWidth:'100px'}}>
                                            <ContentEditable
                                                id={'integranteshogar_docId_'+ih.id}
                                                html={ih.docId || ''} // innerHTML of the editable div
                                                className='itemContainer-value-table'
                                                disabled={!isEditable}      // use true to disable editing
                                                onPaste={pastePlainText} // En caso se copie y pegue de otra pagina de internet
                                                onChange={handleChange} // handle innerHTML change
                                                onKeyPress={disableNewlines}  
                                                onFocus={highlightAll}
                                                onBlur={handleBlur}
                                                tagName='div' // Use a custom HTML tag (uses a div by default)
                                            />
                                        </TableCell>
                                        <TableCell style={{minWidth:'140px'}}>
                                            <ContentEditable
                                                id={'integranteshogar_nombre_'+ih.id}
                                                html={capitalize(ih.nombre,true) || ''} // innerHTML of the editable div
                                                className='itemContainer-value-table'
                                                disabled={!isEditable}      // use true to disable editing
                                                onPaste={pastePlainText} // En caso se copie y pegue de otra pagina de internet
                                                onChange={handleChange} // handle innerHTML change
                                                onKeyPress={disableNewlines}  
                                                onFocus={highlightAll}
                                                onBlur={handleBlur}
                                                tagName='div' // Use a custom HTML tag (uses a div by default)
                                            />
                                        </TableCell>
                                        <TableCell style={{minWidth:'140px'}}>
                                            <ContentEditable
                                                id={'integranteshogar_apellidos_'+ih.id}
                                                html={capitalize(ih.apellidos,true) || ''} // innerHTML of the editable div
                                                className='itemContainer-value-table'
                                                disabled={!isEditable}      // use true to disable editing
                                                onPaste={pastePlainText} // En caso se copie y pegue de otra pagina de internet
                                                onChange={handleChange} // handle innerHTML change
                                                onKeyPress={disableNewlines}  
                                                onFocus={highlightAll}
                                                onBlur={handleBlur}
                                                tagName='div' // Use a custom HTML tag (uses a div by default)
                                            />
                                        </TableCell>

                                        <TableCell style={{maxWidth:'120px'}}>
                                            <Select filterable positionFixed minimal
                                                disabled={!isEditable} 
                                                id={'integranteshogar_parentesco_'+ih.id}
                                                className={'itemContainer-table-selects'}
                                                onChange={(value, item) => handleParentescoSelectChange(value, ih.id)}
                                                selectedValue={ih.parentesco.trim()}
                                                // style={{backgroundColor: 'transparent'}}
                                            >
                                            {tipoParentescos.tipo.map(p => {
                                                return (
                                                <MenuItem key={p.id} value={p.name}>
                                                    {p.name}
                                                </MenuItem>
                                                )
                                            })}
                                            </Select>
                                        </TableCell>

                                        <TableCell style={{minWidth:'100px'}}>
                                            <DatePicker 
                                                key={ih.id}
                                                placeholder="Fecha Nacimiento"
                                                id={'integranteshogar_fechanacimiento_'+ih.id}
                                                // date={ typeof ih.fechanacimiento==='string' ? new moment(ih.fechanacimiento) : ih.fechanacimiento}
                                                // date={ih.fechanacimiento || null}
                                                date={ih.fechanacimiento===null ? new moment('1900-01-01','YYYY-MM-DD') : new moment(ih.fechanacimiento,'YYYY-MM-DD')}
                                                focused={focusedfechasnacimiento[ih.id]}
                                                onDateChange={(date) => onFechaNacimientoChange(date, ih.id)}
                                                onFocusChange={(focused) => onFechaNacimientoFocusChange(focused, ih.id)}
                                                numberOfMonths={1}
                                                // enableOutsideDays={true}
                                                disabled={!isEditable}
                                                readOnly={true}
                                                hideInputIcon
                                                disableScroll={false}
                                                displayFormat={'YYYY-MM-DD'} // 'MMM DD YYYY'
                                                // renderMonthElement={renderMonthElement}
                                                isOutsideRange={() => {}}
                                                monthYearSelectionMode="MONTH_YEAR"
                                                // yearSelectDates={{"startYear" : new moment().subtract(new Date().getFullYear(), 100).year(), "endYear" : new moment().year()}}
                                                yearSelectDates={{"startYear" : 1900, "endYear" : new moment().year()}}
                                                // appendToBody={true}
                                            />
                                        </TableCell>

                                        <TableCell style={{minWidth:'70px'}}>
                                            <ContentEditable
                                                id={'integranteshogar_edad_'+ih.id}
                                                html={ih.edad || ''} // innerHTML of the editable div
                                                className='itemContainer-value-table'
                                                disabled={!isEditable}      // use true to disable editing
                                                onPaste={pastePlainText} // En caso se copie y pegue de otra pagina de internet
                                                onChange={handleChange} // handle innerHTML change
                                                onKeyPress={disableNewlines}  
                                                onFocus={highlightAll}
                                                onBlur={handleBlur}
                                                tagName='div' // Use a custom HTML tag (uses a div by default)
                                            />
                                        </TableCell>
                                        <TableCell style={{maxWidth:'120px'}}>
                                            <Select filterable positionFixed minimal 
                                                disabled={!isEditable} 
                                                id={'integranteshogar_estadocivil_'+ih.id}
                                                className={'itemContainer-table-selects'}
                                                onChange={(value, item) => handleEstadocivilSelectChange(value, ih.id)}
                                                selectedValue={ih.estadocivil.trim()}
                                                // style={{backgroundColor: 'transparent'}}
                                            >
                                            {tipoEstadoCivil.estadocivil.map(f => {
                                                return (
                                                <MenuItem key={f.id} value={f.name}>
                                                    {f.name}
                                                </MenuItem>
                                                )
                                            })}
                                            </Select>
                                        </TableCell>
                                        <TableCell style={{maxWidth:'120px'}}>
                                            <Select filterable positionFixed minimal 
                                                disabled={!isEditable} 
                                                id={'integranteshogar_niveleducativo_'+ih.id}
                                                className={'itemContainer-table-selects'}
                                                onChange={(value, item) => handleNiveleducativoSelectChange(value, ih.id)}
                                                selectedValue={ih.niveleducativo.trim()}
                                                // style={{backgroundColor: 'transparent'}}
                                            >
                                            {tipoEducacion.tipoeducacion.map(f => {
                                                return (
                                                <MenuItem key={f.id} value={f.name}>
                                                    {f.name}
                                                </MenuItem>
                                                )
                                            })}
                                            </Select>
                                        </TableCell>
                                        <TableCell style={{minWidth:'60px'}}>
                                            <ContentEditable
                                                id={'integranteshogar_nivel_'+ih.id}
                                                html={ih.nivel || ''} // innerHTML of the editable div
                                                className='itemContainer-value-table'
                                                disabled={!isEditable}      // use true to disable editing
                                                onPaste={pastePlainText} // En caso se copie y pegue de otra pagina de internet
                                                onChange={handleChange} // handle innerHTML change
                                                onKeyPress={disableNewlines}  
                                                onFocus={highlightAll}
                                                onBlur={handleBlur}
                                                tagName='div' // Use a custom HTML tag (uses a div by default)
                                            />
                                        </TableCell>
                                        <TableCell style={{minWidth:'80px'}}>
                                            <Switch 
                                                labelPosition={"before" || '' }
                                                style={{margin: '0 0 0.5rem 0'}}
                                                name={ 'integranteshogar_viveconbeneficiario_' + ih.id } 
                                                checked={ ih.viveconbeneficiario==='1' ? true : false} 
                                                disabled={!isEditable} 
                                                onChange={(evt) => onChangeViveconbeneficiario(evt, ih.id)}
                                                />
                                        </TableCell>
                                        <TableCell style={{minWidth:'140px'}}>
                                            <ContentEditable
                                                id={'integranteshogar_direccion_'+ih.id}
                                                html={ih.direccion || ''} // innerHTML of the editable div
                                                className='itemContainer-value-table'
                                                disabled={!isEditable}      // use true to disable editing
                                                onPaste={pastePlainText} // En caso se copie y pegue de otra pagina de internet
                                                onChange={handleChange} // handle innerHTML change
                                                onKeyPress={disableNewlines}  
                                                onFocus={highlightAll}
                                                onBlur={handleBlur}
                                                tagName='div' // Use a custom HTML tag (uses a div by default)
                                            />
                                        </TableCell>
                                        <TableCell style={{minWidth:'100px'}}>
                                            <ContentEditable
                                                id={'integranteshogar_telefono_'+ih.id}
                                                html={ih.telefono || ''} // innerHTML of the editable div
                                                className='itemContainer-value-table'
                                                disabled={!isEditable}      // use true to disable editing
                                                onPaste={pastePlainText} // En caso se copie y pegue de otra pagina de internet
                                                onChange={handleChange} // handle innerHTML change
                                                onKeyPress={disableNewlines}  
                                                onFocus={highlightAll}
                                                onBlur={handleBlur}
                                                tagName='div' // Use a custom HTML tag (uses a div by default)
                                            />
                                        </TableCell>
                                        <TableCell style={{minWidth:'100px'}}>
                                            <ContentEditable
                                                id={'integranteshogar_celular_'+ih.id}
                                                html={ih.celular || ''} // innerHTML of the editable div
                                                className='itemContainer-value-table'
                                                disabled={!isEditable}      // use true to disable editing
                                                onPaste={pastePlainText} // En caso se copie y pegue de otra pagina de internet
                                                onChange={handleChange} // handle innerHTML change
                                                onKeyPress={disableNewlines}  
                                                onFocus={highlightAll}
                                                onBlur={handleBlur}
                                                tagName='div' // Use a custom HTML tag (uses a div by default)
                                            />
                                        </TableCell>
                                        <TableCell style={{maxWidth:'140px'}}>
                                            {
                                                (parseInt(ih.id)< 0)
                                                ?
                                                <Button extraSmall clear iconPosition="before"
                                                    disabled={!isEditable} 
                                                    id={'integranteshogar_guardar_'+ih.id}
                                                    onClick={(evt) => onClickSaveIntegranteHogar(ih.id)}
                                                    icon={<SaveIcon size={16} />}
                                                >
                                                    Guardar
                                                </Button>
                                                :
                                                <Button extraSmall clear iconPosition="before"
                                                    disabled={!isEditable} 
                                                    id={'integranteshogar_eliminar_'+ih.id}
                                                    onClick={(evt) => onClickRemoveIntegranteHogar(ih.id)}
                                                    icon={<TrashIcon size={16} />}
                                                >
                                                    Eliminar
                                                </Button>
                                            }
                                        </TableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                        </Table>
                    </div>
                    <div className="">
                        <Button iconPosition="before" 
                            disabled={!isEditable || isBtnAddEnable} 
                            id={'integranteshogar_agregar'}
                            onClick={onClickAddIntegranteHogar}
                            icon={<UserPlusIcon size={16} />}
                        >
                            Agregar nuevo integrante
                        </Button>
                    </div>

                    {/* <div className="benef-texto-actualizacion">Última actualización: {IH[0]['actualizacion']}</div> */}

                </AccordionContent>
                
                }
                </AccordionSection>

            </AccordionModulo>
        </BeneficiarioModulo>
    );
}
   
export default BenefFamiliares;

