
import React, {Fragment} from 'react';
import BenefObjectDataListStore from './helpers/BenefObjectDataListStore';
import { DateCell, ImageCell,  TextCell } from './helpers/cells'; //LinkCell,
import { Table, Column, Cell } from 'fixed-data-table-2';
import Dimensions from 'react-dimensions';
// import { CSVLink } from "react-csv";
import * as XLSX from 'xlsx';

import { 
  setCovidListEntrega, 
  insertBatchListaEntregaCovid } from '../../../redux/reducers/covid19';

import '../fixed-data-table.css';

// Styled Components
// import styled from "styled-components";

import Button from 'calcite-react/Button';

import SaveIcon from 'calcite-ui-icons-react/SaveIcon';
import DownloadIcon from 'calcite-ui-icons-react/DownloadIcon';
// import exportarExcelIcon from '../../../assets/icons/excel_icon_white.png';

// const ExportarExcelIcon = styled.div`
//   width: 20px;
//   height: 20px;
//   background: rgba(255, 255, 255, 0.1) url(${exportarExcelIcon}) no-repeat center/cover;
// `;

var SortTypes = {
  ASC: 'ASC',
  DESC: 'DESC',
};

function reverseSortDirection(sortDir) {
  return sortDir === SortTypes.DESC ? SortTypes.ASC : SortTypes.DESC;
}

class SortHeaderCell extends React.Component {
  constructor(props) {
    super();

    this._onSortChange = this._onSortChange.bind(this);
  }

  render() {
    var {onSortChange, sortDir, children, ...props} = this.props;
    return (
      <Cell {...props}>
        <a href='/#' onClick={this._onSortChange}>
          {children} {sortDir ? (sortDir === SortTypes.DESC ? '↓' : '↑') : ''}
        </a>
      </Cell>
    );
  }

  _onSortChange(e) {
    e.preventDefault();

    if (this.props.onSortChange) {
      this.props.onSortChange(
        this.props.columnKey,
        this.props.sortDir ?
          reverseSortDirection(this.props.sortDir) :
          SortTypes.DESC
      );
    }
  }
}

class ActionCell extends React.Component {
  constructor(props) {
    super(props);

    this._onActionClick = this._onActionClick.bind(this);
  }

  render() {
    var {onActionClick, rowIndex, children, ...props} = this.props;
    return (
      <Cell {...props}>
        <a className="table-beneficiarios-entrega-actioncell" href='/#' onClick={this._onActionClick} >
          Eliminar
        </a>
      </Cell>
    );
  }

  _onActionClick(e) {
    e.preventDefault();

    if (this.props.onActionClick) {
      // console.log('Removiendo Beneficiario de la Ayuda');
      this.props.onActionClick(
        this.props.rowIndex
      );
    }
  }
}

class DataListWrapper {
  constructor(indexMap, data) {
    this._indexMap = indexMap;
    this._data = data;
  }

  getSize() {
    return this._indexMap.length;
  }

  getObjectAt(index) {
    return this._data.getObjectAt(
      this._indexMap[index],
    );
  }
}

class TableBeneficiariosEntregaSortColumns extends React.Component {
  
  constructor(props) {
    super(props);
    
    this._dataList = new BenefObjectDataListStore(props.listEntrega);
    this._dispatch = props.udispatch;
    this._token = props.token;

    this._headerstoExcel = [
      { label: "No.", key: "id" },
      { label: "Beneficiario documento", key: "beneficiarioId" },
      { label: "DocId. quien reclama" ,  key: "docIdquienreclama" },
      { label: "Nombre quien reclama" ,  key: "nombrequienreclama" },
      { label: "Número de Entrega" ,  key: "numentrega" },
      { label: "Convenio" ,  key: "convenio" },
      { label: "Almacen" ,  key: "almacen" },
      { label: "Tipo de Ayuda" ,  key: "tipoayuda" },
      { label: "Fecha Inicio" ,  key: "fechainicio" },
      { label: "Fecha Vigencia" ,  key: "fechavigencia" },
      { label: "Fecha Caducidad" ,  key: "fechacaducidad" },
      { label: "Cupo anterior" ,  key: "cupo" },
      { label: "Cupo ponderado (3 criterios)" ,  key: "cupoxperc" },
      { label: "% Afectación (3 Criterios)" ,  key: "perc_afect" },
      { label: "% Afectación (10 Criterios)" ,  key: "perc_afect2" },
      { label: "Valor final" ,  key: "valorfinal" },
      { label: "Afectación anterior" ,  key: "afectacion" },
      { label: "Afectación (3 criterios)" ,  key: "afectacionfinal" },
      { label: "Afectación (10 criterios)" ,  key: "afectacionfinal2" },
      { label: "Tipo Afectación (3 criterios)" ,  key: "tipoafectacion" },
      { label: "tipoafectacion2" ,  key: "tipoafectacion2" },
      { label: "Tipo Afectación (10 criterios)" ,  key: "tipoafectacionescalado" },
      { label: "Prestando Servicio" ,  key: "prestarservicio" },
      { label: "Prestando Servicio (peso)" ,  key: "vlr_prestarservicio" },
      { label: "Jefe de hogar" ,  key: "jefehogar" },
      { label: "Jefe de hogar (peso)" ,  key: "vlr_jefehogar" },
      { label: "Personas a cargo" ,  key: "personasacargo" },
      { label: "Personas a cargo (peso)" ,  key: "vlr_personasacargo" },
      { label: "Número de hijos" ,  key: "numhijos" },
      { label: "Número de hijos (peso)" ,  key: "vlr_numhijos" },
      { label: "Familia en club" ,  key: "tienefmliaclub" },
      { label: "Familia en club (peso)" ,  key: "vlr_tienefmliaclub" },
      { label: "Actividad" ,  key: "actividad" },
      { label: "Actividad (peso)" ,  key: "vlr_actividad" },
      { label: "Categoria" ,  key: "categoria" },
      { label: "Categoria (peso)" ,  key: "vlr_categoria" },
      { label: "Asistencia" ,  key: "asistencia" },
      { label: "Asistencia (peso)" ,  key: "vlr_asistencia" },
      { label: "Edad" ,  key: "edad" },
      { label: "Edad (peso)" ,  key: "vlr_edad" },
      { label: "Tiempo en Club" ,  key: "edadclub" },
      { label: "Tiempo en Club (peso)" ,  key: "vlr_edadclub" },
      { label: "Foto", key: "foto" },
      { label: "Comentarios" ,  key: "comentarios" },
      { label: "Actualización" ,  key: "actualizacion" },
      { label: "Versión" ,  key: "version" }

    ];

    this._defaultSortIndexes = [];
    var size = this._dataList.getSize();
    for (var index = 0; index < size; index++) {
      this._defaultSortIndexes.push(index);
    }

    this.state = {
      sortedDataList: this._dataList,
      colSortDirs: {},
      headerstoExcel: this._headerstoExcel,
    };

    this._onSortChange = this._onSortChange.bind(this);
    this._onActionClick = this._onActionClick.bind(this);
    this._onClickSaveLista = this._onClickSaveLista.bind(this);
    this._onClickExporttoExcel = this._onClickExporttoExcel.bind(this);
  }

  _onSortChange(columnKey, sortDir) {
    var sortIndexes = this._defaultSortIndexes.slice();
    sortIndexes.sort((indexA, indexB) => {
      var valueA = this._dataList.getObjectAt(indexA)[columnKey];
      var valueB = this._dataList.getObjectAt(indexB)[columnKey];
      var sortVal = 0;
      if (valueA > valueB) {
        sortVal = 1;
      }
      if (valueA < valueB) {
        sortVal = -1;
      }
      if (sortVal !== 0 && sortDir === SortTypes.DESC) {
        sortVal = sortVal * -1;
      }

      return sortVal;
    });

    this.setState({
      sortedDataList: new DataListWrapper(sortIndexes, this._dataList),
      colSortDirs: {
        [columnKey]: sortDir,
      },
    });
  }

  _onActionClick(rowIndex) {
    // var itm = this._dataList.getObjectAt(rowIndex); // Esta fila no es 
    var itm2 = this.state.sortedDataList.getObjectAt(rowIndex);
    // console.log('Eliminando...', rowIndex, itm, itm2);
    var newDataList = [...this._dataList.data].filter(row => row.beneficiarioId!==itm2.beneficiarioId);
    this._dataList = new BenefObjectDataListStore(newDataList);
    // this._dataList._cache = [];
    this._defaultSortIndexes = [];
    for (var index = 0; index < this._dataList.getSize(); index++) {
      this._defaultSortIndexes.push(index);
    }

    this.setState({
      // sortedDataList: new DataListWrapper(this._defaultSortIndexes, this._dataList),
      sortedDataList: this._dataList,
      // colSortDirs: {},
    });

    if (this.state.colSortDirs && this.state.colSortDirs!=={}){
      var columnKey = Object.keys(this.state.colSortDirs)[0];
      var sortDir = this.state.colSortDirs[columnKey];
      this._onSortChange(columnKey, sortDir)
    }

    // Update listEntrega
    if(this._dataList.data)
      this._dispatch(setCovidListEntrega({listEntrega:this._dataList.data}));

  }

  _onClickSaveLista(e){
    e.preventDefault();
    if (this.state.sortedDataList.data){
      this._dispatch(setCovidListEntrega({listEntrega:this.state.sortedDataList.data}));
      this._dispatch(insertBatchListaEntregaCovid({"token":this._token, "listaentregas":this.state.sortedDataList.data}));
    } else if (this.state.sortedDataList._data){ // Datos Ordenados
      if (this.state.sortedDataList._data.data){
        this._dispatch(setCovidListEntrega({listEntrega:this.state.sortedDataList._data.data}));
        this._dispatch(insertBatchListaEntregaCovid({"token":this._token, "listaentregas":this.state.sortedDataList._data.data}));
      }
    }
    
  }

  _onClickExporttoExcel(e){
    e.preventDefault();
    // console.log('Exportando lista completa a excel');

    const data=(this.state.sortedDataList.data || this.state.sortedDataList._data.data) || [];
    let data2excel = [];
    data.forEach((itm, ix) => {
        data2excel.push({
          "No.": itm.id,
          "Beneficiario documento": itm.beneficiarioId,
          "DocId. quien reclama": itm.docIdquienreclama,
          "Nombre quien reclama": itm.nombrequienreclama,
          "Número de Entrega": itm.numentrega,
          "Convenio": itm.convenio,
          "Almacen": itm.almacen,
          "Tipo de Ayuda": itm.tipoayuda,
          "Fecha Inicio": itm.fechainicio,
          "Fecha Vigencia": itm.fechavigencia,
          "Fecha Caducidad": itm.fechacaducidad,
          "Cupo anterior": itm.cupo,
          "Cupo ponderado (3 criterios)": itm.cupoxperc,
          "% Afectación (3 Criterios)": itm.perc_afect,
          "% Afectación (10 Criterios)": itm.perc_afect2,
          "Valor final": itm.valorfinal,
          "Afectación anterior": itm.afectacion,
          "Afectación (3 criterios)": itm.afectacionfinal,
          "Afectación (10 criterios)": itm.afectacionfinal2,
          "Tipo Afectación (3 criterios)": itm.tipoafectacion,
          "tipoafectacion2": itm.tipoafectacion2,
          "Tipo Afectación (10 criterios)": itm.tipoafectacionescalado,
          "Prestando Servicio": parseInt(itm.prestarservicio)===1 ? 'SI' : 'NO',
          "Prestando Servicio (peso)": itm.vlr_prestarservicio,
          "Jefe de hogar": itm.jefehogar,
          "Jefe de hogar (peso)": itm.vlr_jefehogar,
          "Personas a cargo": itm.personasacargo,
          "Personas a cargo (peso)": itm.vlr_personasacargo,
          "Número de hijos": itm.numhijos,
          "Número de hijos (peso)": itm.vlr_numhijos,
          "Familia en club": parseInt(itm.tienefmliaclub)===1 ? 'SI' : 'NO', //itm.tienefmliaclub,
          "Familia en club (peso)": itm.vlr_tienefmliaclub,
          "Actividad": itm.actividad,
          "Actividad (peso)": itm.vlr_actividad,
          "Categoria": itm.categoria,
          "Categoria (peso)": itm.vlr_categoria,
          "Asistencia": itm.asistencia,
          "Asistencia (peso)": itm.vlr_asistencia,
          "Edad": itm.edad,
          "Edad (peso)": itm.vlr_edad,
          "Tiempo en Club": itm.edadclub,
          "Tiempo en Club (peso)": itm.vlr_edadclub,
          "Foto": itm.foto,
          "Comentarios": itm.comentarios,
          "Actualización": itm.actualizacion,
          "Versión": itm.version
        });
    });

    // Exportar en formato Excel .xlsx
    var ws = XLSX.utils.json_to_sheet(data2excel); //make the worksheet
    var wb = XLSX.utils.book_new(); //add to workbook
    XLSX.utils.book_append_sheet(wb, ws, "ListaEntrega");
    /* generate an XLSX file */
    const fname = "ListaEntregaCompleta_Afectacion-"+ new Date().toLocaleString().split(',')[0].replace(',','').replace(/\//g,'-') +".xlsx";
    XLSX.writeFile(wb, fname);
    
  }

  render() {
    var {sortedDataList, colSortDirs} = this.state;
    const {containerHeight, containerWidth} = this.props; //{height, width, ...props}
    return (
      <Fragment>
      <Table
        rowHeight={50}
        headerHeight={60}
        rowsCount={sortedDataList.getSize()}
        width={containerWidth}
        height={containerHeight}
        {...this.props}>
        <Column
          columnKey="foto"
          cell={<ImageCell data={sortedDataList} />}
          fixed={true}
          width={50}
        />
        <Column columnKey="beneficiarioId" 
          header={
            <SortHeaderCell onSortChange={this._onSortChange} sortDir={colSortDirs.beneficiarioId}>
              Beneficiario Id.
            </SortHeaderCell>
          }
          cell={<TextCell data={sortedDataList} />} width={150} 
        />
        <Column columnKey="nombrequienreclama" 
          header={
            <SortHeaderCell onSortChange={this._onSortChange} sortDir={colSortDirs.nombrequienreclama}>
              Nombre
            </SortHeaderCell>
          }
          cell={<TextCell data={sortedDataList} />} fixed={true} width={250} 
        />
        <Column columnKey="docIdquienreclama" 
          header={
            <SortHeaderCell onSortChange={this._onSortChange} sortDir={colSortDirs.docIdquienreclama}>
              DocId Reclama
            </SortHeaderCell>
          }
          cell={<TextCell data={sortedDataList} />} width={150}
        />
        <Column columnKey="numentrega" header={<Cell>No. Entrega</Cell>} cell={<TextCell data={sortedDataList} />} width={120} />
        <Column columnKey="convenio" header={<Cell>No. Convenio</Cell>} cell={<TextCell data={sortedDataList} />} width={130}/>
        <Column columnKey="almacen" 
          header={
            <SortHeaderCell onSortChange={this._onSortChange} sortDir={colSortDirs.almacen}>
              Almacen
            </SortHeaderCell>
          }
          cell={<TextCell data={sortedDataList} />} width={200} 
        />
        <Column columnKey="tipoayuda" header={<Cell>Tipo Ayuda</Cell>} cell={<TextCell data={sortedDataList} />} width={120}/>
        {/* <Column columnKey="tipoayuda" 
          header={
            <SortHeaderCell onSortChange={this._onSortChange} sortDir={colSortDirs.tipoayuda}>
              Tipo Ayuda
            </SortHeaderCell>
          }
          cell={<TextCell data={sortedDataList} />} width={120} 
        /> */}
        <Column columnKey="fechainicio" header={<Cell>Fecha Inicio</Cell>} cell={<DateCell data={sortedDataList} />} width={150}/>
        <Column columnKey="fechavigencia" header={<Cell>Fecha Vigencia</Cell>} cell={<DateCell data={sortedDataList} />} width={150}/>
        <Column columnKey="fechacaducidad" header={<Cell>Fecha Caducidad</Cell>} cell={<DateCell data={sortedDataList} />} width={150}/>
        <Column columnKey="perc_afect" 
          header={
            <SortHeaderCell onSortChange={this._onSortChange} sortDir={colSortDirs.perc_afect}>
              % Afectación (3 Criterios)
            </SortHeaderCell>
          }
          cell={<TextCell data={sortedDataList} />} width={150} 
        />
        <Column columnKey="perc_afect2" 
          header={
            <SortHeaderCell onSortChange={this._onSortChange} sortDir={colSortDirs.perc_afect2}>
              % Afectación (10 Criterios)
            </SortHeaderCell>
          }
          cell={<TextCell data={sortedDataList} />} width={150} 
        />
        <Column columnKey="cupo" 
          header={
            <SortHeaderCell onSortChange={this._onSortChange} sortDir={colSortDirs.cupo}>
              Cupo anterior
            </SortHeaderCell>
          }
          cell={<TextCell data={sortedDataList} />} fixedRight={true} width={100}
        />
        <Column columnKey="cupoxperc" 
          header={
            <SortHeaderCell onSortChange={this._onSortChange} sortDir={colSortDirs.cupoxperc}>
              Cupo ponderado (3 criterios)
            </SortHeaderCell>
          }
          cell={<TextCell data={sortedDataList} />} width={150} 
        />
        <Column columnKey="valorfinal" 
          header={
            <SortHeaderCell onSortChange={this._onSortChange} sortDir={colSortDirs.valorfinal}>
              Valor Final
            </SortHeaderCell>
          }
          cell={<TextCell data={sortedDataList} />} width={100}
        />
        <Column columnKey="afectacion" 
          header={
            <SortHeaderCell onSortChange={this._onSortChange} sortDir={colSortDirs.afectacion}>
              Afectación anterior
            </SortHeaderCell>
          }
          cell={<TextCell data={sortedDataList} />} width={100}
        />
        <Column columnKey="tipoafectacion" 
          header={
            <SortHeaderCell onSortChange={this._onSortChange} sortDir={colSortDirs.tipoafectacion}>
              Tipo Afectación
            </SortHeaderCell>
          }
          cell={<TextCell data={sortedDataList} />} width={100}
        />
        {/* <Column columnKey="tipoafectacion2" 
          header={
            <SortHeaderCell onSortChange={this._onSortChange} sortDir={colSortDirs.tipoafectacion2}>
              Tipo Afectación 2
            </SortHeaderCell>
          }
          cell={<TextCell data={sortedDataList} />} width={120}
        /> */}
        <Column columnKey="tipoafectacionescalado" 
          header={
            <SortHeaderCell onSortChange={this._onSortChange} sortDir={colSortDirs.tipoafectacionescalado}>
              Tipo Afectación (10 criterios)
            </SortHeaderCell>
          }
          cell={<TextCell data={sortedDataList} />} width={150}
        />
        <Column columnKey="afectacionfinal" 
          header={
            <SortHeaderCell onSortChange={this._onSortChange} sortDir={colSortDirs.afectacionfinal}>
              Afectación (3 criterios)
            </SortHeaderCell>
          }
          cell={<TextCell data={sortedDataList} />} width={120}
        />
        <Column columnKey="afectacionfinal2" 
          header={
            <SortHeaderCell onSortChange={this._onSortChange} sortDir={colSortDirs.afectacionfinal2}>
              Afectación (10 criterios)
            </SortHeaderCell>
          }
          cell={<TextCell data={sortedDataList} />} width={120}
        />
        <Column columnKey="prestarservicio" 
          header={
            <SortHeaderCell onSortChange={this._onSortChange} sortDir={colSortDirs.prestarservicio}>
              Prestando Servicio
            </SortHeaderCell>
          }
          cell={<TextCell data={sortedDataList} />} width={120}
        />
        <Column columnKey="vlr_prestarservicio" 
          header={
            <SortHeaderCell onSortChange={this._onSortChange} sortDir={colSortDirs.vlr_prestarservicio}>
              Prestando Servicio (peso)
            </SortHeaderCell>
          }
          cell={<TextCell data={sortedDataList} />} width={120}
        />
        <Column columnKey="jefehogar" 
          header={
            <SortHeaderCell onSortChange={this._onSortChange} sortDir={colSortDirs.jefehogar}>
              Jefe Hogar
            </SortHeaderCell>
          }
          cell={<TextCell data={sortedDataList} />} width={120}
        />
        <Column columnKey="vlr_jefehogar" 
          header={
            <SortHeaderCell onSortChange={this._onSortChange} sortDir={colSortDirs.vlr_jefehogar}>
              Jefe Hogar (peso)
            </SortHeaderCell>
          }
          cell={<TextCell data={sortedDataList} />} width={120}
        />
        <Column columnKey="personasacargo" 
          header={
            <SortHeaderCell onSortChange={this._onSortChange} sortDir={colSortDirs.personasacargo}>
              Peronas a Cargo
            </SortHeaderCell>
          }
          cell={<TextCell data={sortedDataList} />} width={100} 
        />
        <Column columnKey="vlr_personasacargo" 
          header={
            <SortHeaderCell onSortChange={this._onSortChange} sortDir={colSortDirs.vlr_personasacargo}>
              Peronas a Cargo (peso)
            </SortHeaderCell>
          }
          cell={<TextCell data={sortedDataList} />} width={120}
        />
        <Column columnKey="numhijos" 
          header={
            <SortHeaderCell onSortChange={this._onSortChange} sortDir={colSortDirs.numhijos}>
              No. Hijos
            </SortHeaderCell>
          }
          cell={<TextCell data={sortedDataList} />} width={100}
        />
        <Column columnKey="vlr_numhijos" 
          header={
            <SortHeaderCell onSortChange={this._onSortChange} sortDir={colSortDirs.vlr_numhijos}>
              No. Hijos (peso)
            </SortHeaderCell>
          }
          cell={<TextCell data={sortedDataList} />} width={100}
        />
        <Column columnKey="tienefmliaclub" 
          header={
            <SortHeaderCell onSortChange={this._onSortChange} sortDir={colSortDirs.tienefmliaclub}>
              Familia en Club
            </SortHeaderCell>
          }
          cell={<TextCell data={sortedDataList} />} width={100}
        />
        <Column columnKey="vlr_tienefmliaclub" 
          header={
            <SortHeaderCell onSortChange={this._onSortChange} sortDir={colSortDirs.vlr_tienefmliaclub}>
              Familia en Club (peso)
            </SortHeaderCell>
          }
          cell={<TextCell data={sortedDataList} />} width={120}
        />
        <Column columnKey="actividad" 
          header={
            <SortHeaderCell onSortChange={this._onSortChange} sortDir={colSortDirs.actividad}>
              Actividad
            </SortHeaderCell>
          }
          cell={<TextCell data={sortedDataList} />} width={150}
        />
        <Column columnKey="vlr_actividad" 
          header={
            <SortHeaderCell onSortChange={this._onSortChange} sortDir={colSortDirs.vlr_actividad}>
              Actividad (peso)
            </SortHeaderCell>
          }
          cell={<TextCell data={sortedDataList} />} width={100}
        />
        <Column columnKey="categoria" 
          header={
            <SortHeaderCell onSortChange={this._onSortChange} sortDir={colSortDirs.categoria}>
              Categoría
            </SortHeaderCell>
          }
          cell={<TextCell data={sortedDataList} />} width={150}
        />
        <Column columnKey="vlr_categoria" 
          header={
            <SortHeaderCell onSortChange={this._onSortChange} sortDir={colSortDirs.vlr_categoria}>
              Categoría (peso)
            </SortHeaderCell>
          }
          cell={<TextCell data={sortedDataList} />} width={100}
        />
        <Column columnKey="asistencia" 
          header={
            <SortHeaderCell onSortChange={this._onSortChange} sortDir={colSortDirs.asistencia}>
              % Asistencia
            </SortHeaderCell>
          }
          cell={<TextCell data={sortedDataList} />} width={100}
        />
        <Column columnKey="vlr_asistencia" 
          header={
            <SortHeaderCell onSortChange={this._onSortChange} sortDir={colSortDirs.vlr_asistencia}>
              Asistencia (peso)
            </SortHeaderCell>
          }
          cell={<TextCell data={sortedDataList} />} width={100}
        />
        <Column columnKey="edad" 
          header={
            <SortHeaderCell onSortChange={this._onSortChange} sortDir={colSortDirs.edad}>
              Edad
            </SortHeaderCell>
          }
          cell={<TextCell data={sortedDataList} />} width={100}
        />
        <Column columnKey="vlr_edad" 
          header={
            <SortHeaderCell onSortChange={this._onSortChange} sortDir={colSortDirs.vlr_edad}>
              Edad (peso)
            </SortHeaderCell>
          }
          cell={<TextCell data={sortedDataList} />} width={80}
        />
        <Column columnKey="edadclub" 
          header={
            <SortHeaderCell onSortChange={this._onSortChange} sortDir={colSortDirs.edadclub}>
              Edad en el Club
            </SortHeaderCell>
          }
          cell={<TextCell data={sortedDataList} />} width={100}
        />
        <Column columnKey="vlr_edadclub" 
          header={
            <SortHeaderCell onSortChange={this._onSortChange} sortDir={colSortDirs.vlr_edadclub}>
              Edad Club (peso)
            </SortHeaderCell>
          }
          cell={<TextCell data={sortedDataList} />} width={100}
        />

        <Column columnKey="comentarios" header={<Cell>Comentarios</Cell>} cell={<TextCell data={sortedDataList} />} width={400}/>
        
        {/* <Column header={<Cell>Acción</Cell>} cell={'Eliminar'} fixedRight={true} width={100}/> */}
        <Column 
          header={<Cell>Acción</Cell>} 
          cell={<ActionCell  onActionClick={this._onActionClick} />} 
          fixedRight={true} width={100}
        />

      </Table>
      
      <div className="table-beneficiarios-entrega-actions">
        {/* <Button extraSmall clear 
            iconPosition="before"
            icon={<DownloadIcon size={16} />}
        >
          <CSVLink 
            data={(this.state.sortedDataList.data || this.state.sortedDataList._data.data) || []}
            headers={this.state.headerstoExcel}
            filename={"listaEntregaCompleta-"+ new Date().toLocaleString().split(',')[0].replace(',','').replace(/\//g,'-') +".csv"}
            className="table-beneficiarios-entrega-actions-downloadBtn"
          >Descargar Lista Completa</CSVLink>
        </Button> */}

        <Button extraSmall clear 
            iconPosition="before"
            onClick={this._onClickExporttoExcel}
            icon={<DownloadIcon size={16} />}
        >
          Descargar Lista Completa
        </Button>
        
        <Button extraSmall  
            iconPosition="before"
            onClick={this._onClickSaveLista}
            icon={<SaveIcon size={16} />}
        >
          Guardar Lista
        </Button>
      </div>

      </Fragment>
    );
  }
}

// See react-dimensions for the best way to configure
// https://github.com/digidem/react-dimensions
export default Dimensions({
  elementResize: true,

  getHeight: function(element) {
    // return window.innerHeight - 300;
    let heightOffset = 144;
    let heightParent = element.parentElement.parentElement.parentElement.parentElement.clientHeight;
    return heightParent - heightOffset;
  },
  getWidth: function(element) {
    // var widthOffset = window.innerWidth < 680 ? 52 : 412;
    // return window.innerWidth - widthOffset;
    let widthOffset = 52;
    return element.clientWidth - widthOffset;
  }
})(TableBeneficiariosEntregaSortColumns);

