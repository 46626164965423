import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { FixedSizeList as List } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import { fetchBeneficiarioPerfilDataxVestuarioTallaje } from '../../../redux/reducers/vestuario';

import {capitalize} from '../../../utils/utils';
import moment from 'moment';
import 'moment/locale/es-us';

import '../bienestar_styles.css';
// Styled Components
import styled from "styled-components";

import caddieIcon from '../../beneficiario/imgs/caddie_icon_w.png';
import boleroIcon from '../../beneficiario/imgs/bolero_icon_w.png';
import boleadorIcon from '../../beneficiario/imgs/boleador_icon_w.png';
import monitorIcon from '../../beneficiario/imgs/monitor_icon_w.png';

// Vestuario
import camisetaIcon from '../../../assets/icons/camiseta_icon.png';
import pantalonIcon from '../../../assets/icons/pantalon_icon.png';
import pantalonetaIcon from '../../../assets/icons/pantaloneta_icon.png';
import calzadoIcon from '../../../assets/icons/calzado_icon.png';
// import gorraIcon from '../../../assets/icons/gorra_icon.png';

const CamisetaIcon = styled.div`
  width: 24px;
  height: 24px;
  background: rgba(255, 255, 255, 0.1) url(${camisetaIcon}) no-repeat center/cover;
`;
const PantalonIcon = styled.div`
  width: 24px;
  height: 24px;
  background: rgba(255, 255, 255, 0.1) url(${pantalonIcon}) no-repeat center/cover;
`;
const PantalonetaIcon = styled.div`
  width: 24px;
  height: 24px;
  background: rgba(255, 255, 255, 0.1) url(${pantalonetaIcon}) no-repeat center/cover;
`;
const CalzadoIcon = styled.div`
  width: 24px;
  height: 24px;
  background: rgba(255, 255, 255, 0.1) url(${calzadoIcon}) no-repeat center/cover;
`;
// const GorraIcon = styled.div`
//   width: 24px;
//   height: 24px;
//   background: rgba(255, 255, 255, 0.1) url(${gorraIcon}) no-repeat center/cover;
// `;

const CaddieIcon = styled.div`
  width: 24px;
  height: 24px;
  background: rgba(255, 255, 255, 0.1) url(${caddieIcon}) no-repeat center/cover;
  border-radius: 50%;
  background-color: #249507;
`;
const BoleroIcon = styled.div`
  width: 24px;
  height: 24px;
  background: rgba(255, 255, 255, 0.1) url(${boleroIcon}) no-repeat center/cover;
  border-radius: 50%;
  background-color: #249507;
`;
const BoleadorIcon = styled.div`
  width: 24px;
  height: 24px;
  background: rgba(255, 255, 255, 0.1) url(${boleadorIcon}) no-repeat center/cover;
  border-radius: 50%;
  background-color: #249507;
`;
const MonitorIcon = styled.div`
  width: 24px;
  height: 24px;
  background: rgba(255, 255, 255, 0.1) url(${monitorIcon}) no-repeat center/cover;
  border-radius: 50%;
  background-color: #249507;
`;

export default function ListBeneficiariosWrapperTallaje({
  // Are there more items to load?
  // (This information comes from the most recent API request.)
  hasNextPage,

  // Are we currently loading a page of items?
  // (This may be an in-flight flag in your Redux store for example.)
  isNextPageLoading,

  // Array of items loaded so far.
  items,

  // Callback function responsible for loading the next page of items.
  loadNextPage
}) {
  // Load config 
  const config = useSelector(state => state.config);
  const users = useSelector(state => state.users);
  const dispatch = useDispatch();

  // If there are more items to be loaded then add an extra row to hold a loading indicator.
  const itemCount = hasNextPage ? items.length + 1 : items.length;

  // Only load 1 page of items at a time.
  // Pass an empty callback to InfiniteLoader in case it asks us to load more than once.
  const loadMoreItems = isNextPageLoading ? () => {} : loadNextPage;

  // Every row is loaded except for our loading indicator row.
  const isItemLoaded = index => !hasNextPage || index < items.length;

  const onClickBenefItem = (e, docId) => {
    e.preventDefault();
    e.stopPropagation();
    // e.nativeEvent.stopImmediatePropagation();
    // Obtenga todos los datos asociados al Beneficiario
    dispatch(fetchBeneficiarioPerfilDataxVestuarioTallaje({
        token: users.user.claveAPI,
        docId: docId,
        req: "" //tabla de datos, deje en blanco para todas las relacionadas con el Beneficiario
    }));
    
    // dispatch(selectBeneficiarioForPerfil({selectedProfile:{docId:docId}}))
  }

  // Render an item or a loading indicator.
  const Item = ({ index, style }) => {
    let content;
    if (!isItemLoaded(index)) {
      content = "Cargando Beneficiario...";
    } else {
      if (items[index]){
        // const foto = items[index].foto==='' ? require('../../../assets/team/usuario.jpg') : config.ECASE_URL +'user_images/'+ items[index].foto; 
        const foto = items[index].foto==='' ? process.env.PUBLIC_URL + '/imgs/team/usuario.jpg' : config.ECASE_URL +'user_images/'+ items[index].foto; 
        const actualizacion = moment(items[index].actualizacion).format("YYYY-MM-DD HH:mm:ss");
        // const fullname = capitalize(items[index].nombres, true) + ' ' + capitalize(items[index].apellidos, true);
        // const camiseta = items[index].camiseta.toLowerCase()==='M' ? require('../../../assets/icons/camiseta_icon.png') : '';
        
        const estado = items[index].estado.toLowerCase();
        let estadoCircle = "cardBenefItem-estado-circle";
        let estadoClase = "cardBenefItem-avatar";
        switch (estado) {
          case 'suspendido':
            estadoClase = "cardBenefItem-avatar-suspendido";
            estadoCircle = "cardBenefItem-estado-circle cardBenefItem-estado-suspendido";
            break;
          case 'retirado':
            estadoClase = "cardBenefItem-avatar-retirado";
            estadoCircle = "cardBenefItem-estado-circle cardBenefItem-estado-retirado";
            break;
          case 'no apto':
            estadoClase = "cardBenefItem-avatar-noapto";
            estadoCircle = "cardBenefItem-estado-circle cardBenefItem-estado-noapto";
            break;
          case 'pendiente':
            estadoCircle = "cardBenefItem-estado-circle cardBenefItem-estado-pendiente";
            break;
          case 'prueba':
            estadoCircle = "cardBenefItem-estado-circle cardBenefItem-estado-prueba";
            break;
          case 'visitar':
            estadoCircle = "cardBenefItem-estado-circle cardBenefItem-estado-visitar";
            break;
          case 'revisar':
            estadoCircle = "cardBenefItem-estado-circle cardBenefItem-estado-revisar";
            break;
          case 'actualizado':
            // estadoClase = "cardBenefItem-avatar-actualizado";
            estadoCircle = "cardBenefItem-estado-circle cardBenefItem-estado-actualizado";
            break;
          default:
            break;
        }
        
        content = <div className="cardBenefItemBienestar" onClick={e => onClickBenefItem(e, items[index].docId)}>
                      <div className={estadoClase}>
                          <div className="cardBenefItem-foto">
                              <img src={foto} alt=" "/>
                          </div>
                          <div className="cardBenefItem-icon">
                             { items[index].actividad.toLowerCase()==='caddie' && <CaddieIcon/>}
                             { items[index].actividad.toLowerCase()==='bolero' && <BoleroIcon/>}
                             { items[index].actividad.toLowerCase()==='boleador' && <BoleadorIcon/>}
                             { items[index].actividad.toLowerCase()==='monitor' && <MonitorIcon/>}
                          </div>
                          <div className="cardBenefItem-estado">
                              <ul>
                                  { estado ==='suspendido' &&  <li className={estadoCircle} title='Suspendido'></li> }
                                  { estado ==='retirado' &&  <li className={estadoCircle} title='Retirado'></li> }
                                  { estado ==='no apto' &&  <li className={estadoCircle} title='No Apto'></li> }
                                  { estado ==='pendiente' &&  <li className={estadoCircle} title='Pendiente'></li> }
                                  { estado ==='prueba' &&  <li className={estadoCircle} title='Prueba'></li> }
                                  { estado ==='visitar' &&  <li className={estadoCircle} title='Visitar'></li> }
                                  { estado ==='revisar' &&  <li className={estadoCircle} title='Revisar'></li> }
                                  { estado ==='actualizado' &&  <li className={estadoCircle} title='Actualizado'></li> }
                                  {items[index].activo==='1' 
                                    ? <li className="cardBenefItem-estado-circle cardBenefItem-estado-activo" title='Activo'></li>
                                    : <li className="cardBenefItem-estado-circle cardBenefItem-estado-noactivo" title='No Activo'></li>
                                  }
                              </ul>
                          </div>
                      </div>
                      <div className="cardBenefItem-desc-bienestar">
                          {/* <div className="cardBenefItem-title">{fullname}</div> */}
                          <div className="cardBenefItem-title">
                            <span className="cardBenefItem-title-surname">{capitalize(items[index].apellidos, true)}</span>
                            <span className="cardBenefItem-title-name">{capitalize(items[index].nombres, true)}</span>
                          </div>
                          <div className="cardBenefItem-subtitle">{items[index].actividad}  <span className="cardBenefItem-categoria">{items[index].categoria}</span></div>
                          
                          {/* {categoriaseguimiento==="empleado" && <div className="cardBenefItem-categoriaseguimiento cardBenefItem-categoriaseguimiento-empleado">{capitalize(items[index].categoriaseguimiento, true)}</div> }
                          {categoriaseguimiento==="independiente" && <div className="cardBenefItem-categoriaseguimiento cardBenefItem-categoriaseguimiento-independiente">{capitalize(items[index].categoriaseguimiento, true)}</div> }
                          {categoriaseguimiento==="disponible" && <div className="cardBenefItem-categoriaseguimiento cardBenefItem-categoriaseguimiento-disponible">{capitalize(items[index].categoriaseguimiento, true)}</div> }
                          {categoriaseguimiento==="en proceso" && <div className="cardBenefItem-categoriaseguimiento cardBenefItem-categoriaseguimiento-enproceso">{capitalize(items[index].categoriaseguimiento, true)}</div> }
                          {categoriaseguimiento==="dependiente" && <div className="cardBenefItem-categoriaseguimiento cardBenefItem-categoriaseguimiento-dependiente">{capitalize(items[index].categoriaseguimiento, true)}</div> }
                           */}
                          <div className="cardBenefItem-vestuarioContainer">
                            <div className="cardBenefItem-camiseta">
                              <CamisetaIcon title="Camiseta" width="20px" height="auto" />
                              <div className="cardBenefItem-talla" title="Camiseta Talla">{items[index].camiseta}</div>
                            </div>
                            <div className="cardBenefItem-pantalon">
                              <PantalonIcon title="Pantalón" width="20px" height="auto" />
                              <div className="cardBenefItem-talla" title="Pantalón Talla">{items[index].pantalon}</div>
                            </div>
                            <div className="cardBenefItem-pantaloneta">
                              <PantalonetaIcon title="Pantaloneta" width="20px" height="auto" />
                              <div className="cardBenefItem-talla" title="Pantaloneta Talla">{items[index].pantaloneta}</div>
                            </div>
                            <div className="cardBenefItem-calzado">
                              <CalzadoIcon title="Calzado" width="20px" height="auto" />
                              <div className="cardBenefItem-talla" title="Calzado Talla">{items[index].calzado}</div>
                            </div>
                            {/* <div className="cardBenefItem-gorra">
                              <GorraIcon title="Gorra" width="20px" height="auto" />
                              <div className="cardBenefItem-talla" title="Gorra Talla">{items[index].gorra}</div>
                            </div> */}
                            {/* <div className="cardBenefItem-otro">
                              <PantalonIcon title="Otro" width="20px" height="auto" />
                              <div className="cardBenefItem-talla" title="Otro Talla">{items[index].otro}</div>
                            </div> */}
                         
                          </div>
                          
                          

                        <div className="cardBenefItem-acompactualizacion">{actualizacion} por {items[index].modificadopor}</div>
                      </div>
                  </div>;
                            
      } else {
        content = <div></div>
      }
      
    }
    return <div style={style}>{content}</div>;
  };

  return (
    <InfiniteLoader 
      isItemLoaded={isItemLoaded}
      itemCount={itemCount}
      loadMoreItems={loadMoreItems}
    >
      {({ onItemsRendered, ref }) => (
        <List style={{minWidth:'390px'}}
          // className="cardBenefContainer"
          height={480}
          itemCount={itemCount}
          itemSize={90}
          onItemsRendered={onItemsRendered}
          ref={ref}
        >
          {Item}
        </List>
      )}
    </InfiniteLoader>
  );
}
