import React, {useState, useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";
import ContentEditable from 'react-contenteditable';
import { updateBeneficiarioOrgSocial, serverMensaje } from '../../redux/reducers/beneficiarios';
import {highlightAll, disableNewlines, pastePlainText } from '../../utils/utils';
import Alert, {AlertMessage} from 'calcite-react/Alert'
import Accordion, {
    AccordionSection,
    AccordionTitle,
    AccordionContent
} from 'calcite-react/Accordion';
import {showToaster} from './BenefShowMesg';

import Button, { ButtonGroup } from 'calcite-react/Button';
import Radio from 'calcite-react/Radio';
import Form, {
    FormControl,
    Fieldset,
  } from 'calcite-react/Form';

import styled from "styled-components";

const BeneficiarioModulo = styled.div`
    height: auto;
    background: #fff;
    border: 0;
    padding: 0px;
    margin: 4px;
    overflow:hidden;
`;

const AccordionModulo = styled(Accordion)`
    padding:0;
`;
const AccordionTitleModule = styled(AccordionTitle)`
    background-color: #e6e6e6;
    color: #4d4d4d;
    font-size: 1.25em;
    font-weight: 700;
    // border-bottom: 1px solid #d9d9d9;
    // box-shadow: 0px 2px 6px #e1e1e1;
`;


const BenefOrgSocial = ({orgsocial}) => {
    const dispatch = useDispatch();
    const mensaje = useSelector(state => state.beneficiarios.mensaje);
    const users = useSelector(state => state.users);
    // const isEditable = users.user.tipo==='ADMINISTRADOR' ? true : false;
    // const isEditable = (users.user.tipo==='ADMINISTRADOR' && users.user.role==='A' && users.user.permisos==='A') ? true : false;
    const isEditable = (users.user.areatrabajo==='ADMINISTRATIVA' || users.user.areatrabajo==='TRABAJO SOCIAL' || users.user.areatrabajo==='COORDINACIÓN' || users.user.areatrabajo==='PSICOLOGÍA' ) ? true : false;
    // const canView = (users.user.areatrabajo==='ADMINISTRATIVA' || users.user.areatrabajo==='TECNOLOGÍA' ) ? true : false;
    const [activeSectionIndexes, setActiveSectionIndexes] = useState([]);
    const [perteneceorgsocSelected, setPerteneceorgsocSelected] = useState(parseInt(orgsocial.perteneceorgsoc));
    const [participancomunidadSelected, setParticipancomunidadSelected] = useState(parseInt(orgsocial.participancomunidad));
    const [disposicionparticipacionSelected, setDisposicionparticipacion] = useState(parseInt(orgsocial.disposicionparticipacion));
    const [califrelcomunidadSelected, setCalifrelcomunidadSelected] = useState(orgsocial.califrelcomunidad);
    
    const [error, setError] = useState(null);
    const onAccordionChange = (evt, index) => {
        activeSectionIndexes.includes(index)
        ? setActiveSectionIndexes(activeSectionIndexes.filter(
            item => index !== item,
          ))
        : setActiveSectionIndexes([...activeSectionIndexes, index]);
    }

    const [OS] = useState({
        "id": orgsocial.id, //int
        "beneficiarioId": orgsocial.beneficiarioId, //15
        "perteneceorgsoc": orgsocial.perteneceorgsoc || '', //1
        "quienes": orgsocial.quienes || '', //255
        "cuales": orgsocial.cuales || '', // 255
        "califrelcomunidad": orgsocial.califrelcomunidad, // 32
        "porquecalificion": orgsocial.porquecalificion, //255
        "participancomunidad": orgsocial.participancomunidad, //1
        "cualesparticipan": orgsocial.cualesparticipan, //255
        "disposicionparticipacion": orgsocial.disposicionparticipacion, //1
        "porquedisposicion": orgsocial.porquedisposicion, //255
        "observaciones": orgsocial.observaciones, //text
        "comentarios": orgsocial.comentarios, //text
        "actualizacion": orgsocial.actualizacion, //0000-00-00 00:00:00
        "version": orgsocial.version,
    });

    const [itemsOrgSocial, setItemsOrgSocial] = useState([
        // {"key":"id", "label": "Identificador Único", "value": OS['id']},
        // {"key":"beneficiarioId", "label": "Documento de Identificación", "value": OS['beneficiarioId']},
        {"key":"perteneceorgsoc", "label": "Algún miembro de la familia pertenece a una organización social o comunitaria?", "value": OS['perteneceorgsoc']},
        {"key":"quienes", "label": "Quién(es)?", "value": OS['quienes']},
        {"key":"cuales", "label": "Cuál(es)?", "value": OS['cuales']},
        {"key":"califrelcomunidad", "label": "Cómo califica usted la relación que tiene con los demás miembros de la comunidad (vecinos)?", "value": OS['califrelcomunidad']},
        {"key":"porquecalificion", "label": "Por qué?", "value": OS['porquecalificion']},
        {"key":"participancomunidad", "label": "Usted y su familia participan de actividades de tipo comunitario?", "value": OS['participancomunidad']},
        {"key":"cualesparticipan", "label": "Quién(es) participan", "value": OS['cualesparticipan']},
        {"key":"disposicionparticipacion", "label": "Estaría dispuesto usted y su familia a participar en organismos de tipo comunitario?", "value": OS['disposicionparticipacion']},
        {"key":"porquedisposicion", "label": "Por qué?", "value": OS['porquedisposicion']},
        // {"key":"comentarios", "label": "Comentarios en general", "value": OS['comentarios']},
        // {"key":"observaciones", "label": "Observaciones", "value": OS['observaciones']},
        // {"key":"actualizacion", "label": "Última actualización", "value": OS['actualizacion']},
        // {"key":"version", "label": "Versión", "value": OS['version']}
    ]);

    useEffect(() => {
        // Guardar los resultados al salir, puede ser contraproducente al fallar la conexión con el servidor u otro evento no deseado
        // se podrián perder los datos modificafos
        
        return () => {
            // if (isUpdated){
            //     console.log('Saving appointment', isUpdated);
            // }
            dispatch(serverMensaje({}));
        }
    }, [dispatch]);

    useEffect(() => {
        if (itemsOrgSocial){
            // console.log(itemsOrgSocial);
        }
        if (mensaje.orgsocial!=='' && mensaje.orgsocial) {
            if (typeof mensaje.orgsocial === 'string' && mensaje.orgsocial.toLowerCase().includes('error')){
                showToaster('error', 'Ocurrió un fallo en el servidor', mensaje.orgsocial);
            } 
            // else {
            //     showToaster('success',"Org. Social del Beneficiario", mensaje.orgsocial); //"🧭 " + mensaje
            // }
        }

    }, [mensaje.orgsocial, itemsOrgSocial, dispatch]);

    const updateItemsOrgSocial = (f, v) => {
        const newitemsOrgSocial = itemsOrgSocial.map((itm, index) => {
            if (itm.key===f){
                itm.value = v;
            }
            return itm;
        });
        setItemsOrgSocial(newitemsOrgSocial);
    };

    const updateDataonServer = () =>{
        dispatch(serverMensaje({orgsocial:''}));
        dispatch(updateBeneficiarioOrgSocial( { token: users.user.claveAPI, orgsoc:OS}));
    }

    const replaceSomeText = (s) =>{
        return s
            .replace(/&nbsp;/g, '')
            .replace(/<br>/g, '')
            .replace(/&amp;/g, '&')
            .replace(/&gt;/g, '>')
            .replace(/&lt;/g, '<').trim()
    }

    const validateInput = (f, v, final) => {
        // const regex_onlynum = /[0-9,]|\./;
        // const regex_nombres = /^[a-zA-Z ]+$/;
        // const regex_onlyletters = /#[a-zA-Z\xE1\xE9\xED\xF3\xFA\xC1\xC9\xCD\xD3\xDA\xF1\xD1]+#/i;
        // const regex_nombres = /^['`´a-zA-ZÀ-ÿ\u00C0-\u017F\s]+$/;
        // const regex_gasto = /^[0-9]*(\.[0-9]+)?$/;
        let new_val = null;
        let err = null;
        switch (f) {
            case 'perteneceorgsoc':
                new_val = replaceSomeText(v); //1
                break;
            case 'quienes':
                new_val = v.substr(0,255);
                if (final)
                    new_val = replaceSomeText(v).substr(0,255);
                break;
            case 'cuales':
                new_val = v.substr(0,255);
                if (final)
                    new_val = replaceSomeText(v).substr(0,255);
                break;
            case 'califrelcomunidad':
                new_val = v.substr(0,32);
                if (final)
                    new_val = replaceSomeText(v).substr(0,32);
                break;
            case 'porquecalificion':
                new_val = v.substr(0,255);
                if (final)
                    new_val = replaceSomeText(v).substr(0,255);
                break;
            case 'participancomunidad':
                new_val = v.trim(); //1
                break;
            case 'cualesparticipan':
                new_val = v.substr(0,255);
                if (final)
                    new_val = replaceSomeText(v).substr(0,255);
                break;
            case 'disposicionparticipacion':
                new_val = v.trim(); //1
                break;
            case 'porquedisposicion':
                new_val = v.substr(0,255);
                if (final)
                    new_val = replaceSomeText(v).substr(0,255);
                break;
            case 'observaciones':
                new_val = v.trim(); //text
                if (final)
                    new_val = replaceSomeText(v); //.substr(0,255);
                break;
            case 'comentarios':
                new_val = v.trim(); //text
                if (final)
                    new_val = replaceSomeText(v); //.substr(0,255);
                break;
            default:
                break;
        }
        setError(err);
        return new_val
    }
    const handleChange = evt => {
        const field = evt.currentTarget.id.split('_')[1];
        const new_val = validateInput(field, evt.target.value, false)
        if(new_val){
            OS[field] = new_val
        };
    };
    const handleBlur = evt => {
        const val = evt.currentTarget.innerHTML;
        const field = evt.target.id.split('_')[1];
        const new_val = validateInput(field, val, true)
        if(new_val || new_val===''){
            OS[field] = new_val;
            if (field!=='comentarios' || field!=='observaciones')
                updateItemsOrgSocial(field, OS[field]);
            updateDataonServer();
        };
    };

    const perteneceorgsocSelectButton = (selected) => {
        setPerteneceorgsocSelected(selected);
        OS['perteneceorgsoc'] = selected === 1 ? '1' : '0';
        updateDataonServer();
    }
    const participancomunidadSelectButton = (selected) => {
        setParticipancomunidadSelected(selected);
        OS['participancomunidad'] = selected === 1 ? '1' : '0';
        updateDataonServer();
    }
    const disposicionparticipacionSelectButton = (selected) => {
        setDisposicionparticipacion(selected);
        OS['disposicionparticipacion'] = selected === 1 ? '1' : '0';
        updateDataonServer();
    }

    // 
    const califrelcomunidadSelectRadio  = (selected) => {
        setCalifrelcomunidadSelected(selected);
        OS['califrelcomunidad'] = selected;
        updateDataonServer();
    }

    return (
        <BeneficiarioModulo>
            {/* <ToastContainer position="bottom-right" /> */}
            <AccordionModulo
                onAccordionChange={onAccordionChange}
                activeSectionIndexes={activeSectionIndexes}
            >
                <AccordionSection>
                    <AccordionTitleModule>Organización Social</AccordionTitleModule>
                    {activeSectionIndexes.length>0 &&  <AccordionContent style={{marginBottom: '10px'}}>
                    {error && <div style={{color: 'red', width: '100%'}}>
                            <Alert red showIcon style={{minWidth: '100%', marginBottom: '10px'}}
                                showCloseLabel 
                                onClose={() => setError(null)}>
                                <AlertMessage>
                                    Error:{' '}
                                    <strong>{error}</strong>
                                </AlertMessage>
                            </Alert>
                        </div> 
                    }
                    {/* ITEMS */}
                        {
                            itemsOrgSocial.map((itm, index) => {
                                // if (itm.value!==null && itm.value!=="" ){
                                    return (<div key={index} className="itemContainer">
                                        <div className="itemContainer-attr-long" 
                                            style={{backgroundColor:(index % 2 === 0) ? '#f8f8f8': '#fff'}}
                                        >{itm.label}</div>
                                        <div className="itemContainer-value" 
                                            style={{backgroundColor:(index % 2 === 0) ? '#f8f8f8': '#fff'}}
                                        >
                                            {/* {itm.value} */}

                                            {itm.key==='perteneceorgsoc' 
                                                ?
                                                    <ButtonGroup isToggle>
                                                        <Button  clear={perteneceorgsocSelected !== 1} disabled={!isEditable} 
                                                            onClick={() => {  perteneceorgsocSelectButton(1) }}
                                                        >Si</Button>
                                                        <Button clear={perteneceorgsocSelected !== 0} disabled={!isEditable} 
                                                            onClick={() => { perteneceorgsocSelectButton(0) }}
                                                        >No</Button>
                                                    </ButtonGroup>
                                                :
                                                itm.key==='participancomunidad' 
                                                ?
                                                    <ButtonGroup isToggle>
                                                        <Button  clear={participancomunidadSelected !== 1} disabled={!isEditable} 
                                                            onClick={() => {  participancomunidadSelectButton(1) }}
                                                        >Si</Button>
                                                        <Button clear={participancomunidadSelected !== 0} disabled={!isEditable} 
                                                            onClick={() => { participancomunidadSelectButton(0) }}
                                                        >No</Button>
                                                    </ButtonGroup>
                                                :
                                                itm.key==='disposicionparticipacion' 
                                                ?
                                                    <ButtonGroup isToggle>
                                                        <Button  clear={disposicionparticipacionSelected !== 1} disabled={!isEditable} 
                                                            onClick={() => {  disposicionparticipacionSelectButton(1) }}
                                                        >Si</Button>
                                                        <Button clear={disposicionparticipacionSelected !== 0} disabled={!isEditable} 
                                                            onClick={() => { disposicionparticipacionSelectButton(0) }}
                                                        >No</Button>
                                                    </ButtonGroup>
                                                :
                                                itm.key==='califrelcomunidad' 
                                                ?
                                                    <Form>
                                                        <FormControl>
                                                        <Fieldset name="califrelcomunidadRadioGroup">
                                                            {/* <Legend>Relación:</Legend> */}
                                                            <Radio name='Excelente' disabled={!isEditable} 
                                                                checked={califrelcomunidadSelected==='Excelente' ? true : false}
                                                                onChange={() => { califrelcomunidadSelectRadio('Excelente') }}
                                                            >Excelente</Radio>
                                                            <Radio name='Buena' disabled={!isEditable} 
                                                                // defaultChecked={califrelcomunidadSelected==='Buena' ? true : false}
                                                                checked={califrelcomunidadSelected==='Buena' ? true : false}
                                                                onChange={() => { califrelcomunidadSelectRadio('Buena') }}
                                                            >Buena</Radio>
                                                            <Radio name='Regular' disabled={!isEditable} 
                                                                checked={califrelcomunidadSelected==='Regular' ? true : false}
                                                                onChange={() => { califrelcomunidadSelectRadio('Regular') }}
                                                            >Regular</Radio>
                                                            <Radio name='Mala' disabled={!isEditable} 
                                                                checked={califrelcomunidadSelected==='Mala' ? true : false}
                                                                onChange={() => { califrelcomunidadSelectRadio('Mala') }}
                                                            >Mala</Radio>
                                                        </Fieldset>
                                                        </FormControl>
                                                    </Form>
                                                :
                                                    <ContentEditable
                                                        id={'orgsocial_'+itm.key}
                                                        // innerRef={agendoEditable}
                                                        html={itm.value || ''} // innerHTML of the editable div
                                                        className='itemContainer-value-long'
                                                        disabled={!isEditable}      // use true to disable editing
                                                        onPaste={pastePlainText} // En caso se copie y pegue de otra pagina de internet
                                                        onChange={handleChange} // handle innerHTML change
                                                        onKeyPress={disableNewlines}  
                                                        onFocus={highlightAll}
                                                        onBlur={handleBlur}
                                                        tagName='div' // Use a custom HTML tag (uses a div by default)
                                                    />
                                             }

                                        </div>
                                    </div>)
                                // } 
                            })
                        }

                        <div className="Observaciones-Comentarios">
                            <div className="benef-texto-observaciones-title">Comentarios en general:</div>
                            <div className="benef-texto-observaciones"> 
                                <ContentEditable
                                    id={'orgsocial_comentarios'}
                                    html={OS.comentarios || ''} // innerHTML of the editable div
                                    disabled={!isEditable}        // use true to disable editing
                                    onPaste={pastePlainText}
                                    onChange={handleChange} // handle innerHTML change
                                    onFocus={highlightAll}
                                    onBlur={handleBlur}
                                    tagName='div' // Use a custom HTML tag (uses a div by default)
                                    style={{ "minHeight": "40px", "border": "1px dashed" }}
                                />
                            </div>
                        </div>
                        <div className="Observaciones-Comentarios">
                            <div className="benef-texto-observaciones-title">Observaciones:</div>
                            <div className="benef-texto-observaciones"> 
                                <ContentEditable
                                    id={'orgsocial_observaciones'}
                                    html={OS.observaciones || ''} // innerHTML of the editable div
                                    disabled={!isEditable}        // use true to disable editing
                                    onPaste={pastePlainText}
                                    onChange={handleChange} // handle innerHTML change
                                    onFocus={highlightAll}
                                    onBlur={handleBlur}
                                    tagName='div' // Use a custom HTML tag (uses a div by default)
                                    style={{ "minHeight": "40px", "border": "1px dashed" }}
                                />
                            </div>
                        </div>

                        <div className="benef-texto-actualizacion">Última actualización: {OS['actualizacion']}</div>

                    </AccordionContent>
                }
                </AccordionSection>
            </AccordionModulo>
        </BeneficiarioModulo>
    );
}

export default BenefOrgSocial;

