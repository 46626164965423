import React, {useState, useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";
import ContentEditable from 'react-contenteditable';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import {capitalize, getFirstWords, removeHTMLTags,
    highlightAll, 
    disableNewlines, 
    pastePlainText
} from '../../utils/utils';
import {fetchUsersData} from '../../redux/reducers/users';
import { fetchBeneficiariosData } from '../../redux/reducers/beneficiarios';
import { serverMensaje,
    // fetchNotificacionesDatabyQuery,
    updateNotificacion,
    insertNotificacion,
    deleteNotificacion,
} from '../../redux/reducers/notas';

import DatePicker from 'calcite-react/DatePicker';
import moment from 'moment';
import 'moment/locale/es-us';

import Accordion, {
    AccordionSection,
    AccordionTitle,
    AccordionContent
} from 'calcite-react/Accordion';
import {showMsgToaster} from '../ShowMesg';
import {ToastContainer} from 'calcite-react/Toaster'; //notify
import Alert, {AlertMessage} from 'calcite-react/Alert';
import Select from 'calcite-react/Select';
import MultiSelect from 'calcite-react/MultiSelect';
import {MenuItem} from 'calcite-react/Menu';
// import Loader from 'calcite-react/Loader';
// import Search from 'calcite-react/Search';
import Button from 'calcite-react/Button';
// import TextField from 'calcite-react/TextField';
import {
    ListItem,
    // ListHeader,
    ListItemTitle,
    ListItemSubtitle
} from 'calcite-react/List';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import '../../styles/css/font-awesome.min.css';
import '../notificaciones/notas_styles.css';

import estadoNotas from '../../data/estadonotificacion.json';
import areadeTrabajoNotas from '../../data/areasnotificacion.json';
import tipodeNota from '../../data/tipodenotificacion.json';
import prioridadNota from '../../data/prioridadnotificacion.json';


import styled from "styled-components";

import UserIcon from 'calcite-ui-icons-react/UserIcon';
import ResetIcon from 'calcite-ui-icons-react/ResetIcon';
import SaveIcon from 'calcite-ui-icons-react/SaveIcon';
import PlusIcon from 'calcite-ui-icons-react/SpeechBubblePlusIcon';
import TrashIcon from 'calcite-ui-icons-react/TrashIcon';
// import FilterIcon from 'calcite-ui-icons-react/FilterIcon';
// import CalendarIcon from 'calcite-ui-icons-react/CalendarIcon';
// import SearchIcon from 'calcite-ui-icons-react/SearchIcon';

import whatsappIcon from '../../assets/icons/whatsapp_white.png';
const WhatsappIcon = styled.div`
  width: 16px;
  height: 16px;
  position: relative;
  display: inline-flex;
  vertical-align: bottom;
//   opacity: 0.4;
  margin-right: 10px;
  background: rgba(255, 255, 255, 0.1) url(${whatsappIcon}) no-repeat center/cover;
`;

const EditNota = styled.div`
    width: 100%;
    // height: 100vh; // calc(100% - 10px);
    position: relative;
    background-color: #fff;
    z-index: 2;
`;
const AgregarNota = styled.div`
    width: 100%;
    // height: 100vh; // calc(100% - 10px);
    position: relative;
    background-color: #fff;
    z-index: 2;
`;

const NotasModulo = styled.div`
    height: auto;
    background: #fff;
    border: 0;
    padding: 0px;
    margin: 4px;
    overflow:hidden;
`;

const AccordionModulo = styled(Accordion)`
    padding:0;
`;
const AccordionTitleModule = styled(AccordionTitle)`
    background-color: #e6e6e6;
    color: #4d4d4d;
    font-size: 1.25em;
    font-weight: 700;
    // border-bottom: 1px solid #d9d9d9;
    // box-shadow: 0px 2px 6px #e1e1e1;
`;


const BenefNotificaciones = ({notificaciones, beneficiarioId}) => {
    const dispatch = useDispatch();
    const config = useSelector(state => state.config);
    const users = useSelector(state => state.users);
    const listUsers = useSelector(state => state.users.listUsers);
    const listBenef = useSelector(state => state.beneficiarios.listBenef);
    // const isEditable = (users.user.areatrabajo==='ADMINISTRATIVA' || users.user.areatrabajo==='COORDINACIÓN' || users.user.areatrabajo==='TECNOLOGÍA' ) ? true : false;
    const [isEditable,setIsEditable] = useState(true); // || users.user.docId===notaSelected.creadapor ? true : false);
    const [isDisplayEditNota, setIsDisplayEditNota] = useState(false);
    const [isDisplayAgregarNota, setIsDisplayAgregarNota] = useState(false);

    const mensaje = useSelector(state => state.notas.mensaje);
    const [error, setError] = useState(null);
    const [errorenAgregar, setErrorenAgregar] = useState(null);
    const [fechaPlazo, setFechaPlazo] = useState(moment(new Date(),'YYYY-MM-DD'));
    const [fechaPlazoPickerFocused, setFechaPlazoPickerFocused] = useState(false);
    const minDate = moment('2017-05-14'); //moment('2020-07-15'); //moment().subtract(2, 'months').startOf('month');
    const maxDate= moment().add(15, 'months'); //moment().add(2, 'months').endOf('month');

    // const [query,] = useState({
    //     "page": "1",
    //     "item_per_page": "100",
    //     "searchText":"",
    //     "id":"",
    //     "tiponota":"",
    //     "nota":"",
    //     "areanota":"", //"TRABAJO SOCIAL",
    //     "relacioncon":(notificaciones && notificaciones.length>0 ? notificaciones[0].relacioncon : ''),
    //     "responsables":"",
    //     "prioridad":"",
    //     "etiquetas":"",
    //     "estadonota":"",
    //     "comentarios":"",
    //     "fechacreacion":"",
    //     "fechaplazo":"",
    //     "creadapor":"",
    //     "actualizacion":"",
    //     "version":"",
    //     "modificadopor":""
    // });
    const [notaSelected, setNotaSelected] = useState({
        "id": '-1', //int
        "tiponota":"",
        "nota":"",
        "areanota":"",
        "relacioncon":(notificaciones && notificaciones.length>0 ? notificaciones[0].relacioncon : ''),
        "responsables":"",
        "prioridad":"",
        "etiquetas":"",
        "estadonota":"",
        "comentarios":"",
        "fechacreacion":"",
        "fechaplazo":"",
        "creadapor":"",
        "actualizacion":"",
        "version":"",
        "modificadopor":""
    });

    const [itemsNota, setItemsNota] = useState([
        // {"key":"id", "label": "Id", "value": notaSelected['id']},
        {"key":"estadonota", "label": "Estado", "value": notaSelected['estadonota']},
        {"key":"areanota", "label": "Area de Trabajo", "value": notaSelected['areanota']},
        {"key":"tiponota", "label": "Tipo de Nota", "value": notaSelected['tiponota']},
        // {"key":"relacioncon", "label": "Nota en relacion con", "value": notaSelected['relacioncon']}, // No necesario
        {"key":"prioridad", "label": "Prioridad", "value": notaSelected['prioridad']},
        // {"key":"nota", "label": "Notificación u Observación", "value": notaSelected['nota']},
        {"key":"responsables", "label": "Responsables", "value": notaSelected['responsables']},
        {"key":"fechaplazo", "label": "Fecha plazo", "value": notaSelected['fechaplazo']},
        {"key":"etiquetas", "label": "Etiquetas o palabras clave (separadas por coma)", "value": notaSelected['etiquetas']},
        // {"key":"fechacreacion", "label": "Fecha creación", "value": notaSelected['fechacreacion']},
        // {"key":"creadapor", "label": "Nota creada por", "value": notaSelected['creadapor']},
        // {"key":"modificadopor", "label": "Modificado por", "value": notaSelected['modificadopor']},
        // {"key":"comentarios", "label": "Comentarios", "value": notaSelected['comentarios']},
        // {"key":"actualizacion", "label": "Actualizacion", "value": notaSelected['actualizacion']},
        // {"key":"version", "label": "Version", "value": notaSelected['version']},
    ]);

    useEffect(() => {
        if (!listUsers || listUsers.length<0){
            dispatch(fetchUsersData({token: users.user.claveAPI} ));
        }
        if (!listBenef || listBenef.length<=0){
            dispatch(fetchBeneficiariosData({token: users.user.claveAPI}));
        }

        return () => {
            // executed when unmount
            // console.log('Saliendo del Modulo Beneficiarios');
        }
        
    }, [users.user.claveAPI, listUsers, listBenef, dispatch]);

    useEffect(() => {
        if (!listUsers || listUsers.length<0){
            return
        }
        if (!listBenef || listBenef.length<0){
            return
        }
        // if (notificaciones.length<=0){
        //     console.log('No hay registros para mostrar...');
        // }
        if (mensaje.notificaciones && mensaje.notificaciones!=='') {
            const updateListaNotas = () => {
                // query['page'] = '1'; // Es necesario enviarla para que encuentre algo
                if (notificaciones && notificaciones.length>0) {
                    // query['relacioncon'] = notificaciones[0].relacioncon;
                    // dispatch(fetchNotificacionesDatabyQuery({token: users.user.claveAPI, query: query} ));
                }
            }

            if (typeof mensaje.notificaciones === 'string' && mensaje.notificaciones.toLowerCase().includes('error')){
                showMsgToaster('error', 'Ocurrió un fallo en el servidor', mensaje.notificaciones);
                mensaje.notificaciones='';
            } 
            else {
                showMsgToaster('success',"Notificaciones", mensaje.notificaciones.split('.')[0]);
                mensaje.notificaciones='';
                dispatch(serverMensaje({notificaciones:''}));
                updateListaNotas();
            }
            
        }
        
    }, [listUsers, listBenef, users.user.claveAPI, mensaje.notificaciones, notificaciones, dispatch]);
    // Save on Server
    const updateDataonServer = (action, nota) => {
        // console.log(nota);
        switch (action) {
            case 'insert':
                nota['creadapor'] = users.user.docId;
                dispatch(insertNotificacion( { token: users.user.claveAPI, notificaciones:nota}));
                break;
            case 'remove':
                dispatch(deleteNotificacion( { token: users.user.claveAPI, notificaciones:nota}));
                break;
            case 'update':
                // Actualizar solo el registro necesario, para no enviar toda la tabla
                dispatch(updateNotificacion( { token: users.user.claveAPI, notificaciones:nota}));
                break;
            default: // update
                console.log('Acción no disponible');
                break;
        }
    }

    const updateItemsNota = (f, v) => {
        const newitemsNota = itemsNota.map((itm, index) => {
            if (itm.key===f){
                itm.value = v;
            }
            return itm;
        });
        setItemsNota(newitemsNota);
    };

    const [activeSectionIndexes, setActiveSectionIndexes] = useState([]);
    const onAccordionChange = (evt, index) => {
        activeSectionIndexes.includes(index)
        ? setActiveSectionIndexes(activeSectionIndexes.filter(
            item => index !== item,
          ))
        : setActiveSectionIndexes([...activeSectionIndexes, index]);
    }

    const getUserbyDocId = (docId) =>{
        let u = null;
        if (listUsers && listUsers.length>0)
            u = listUsers.filter(itm => itm.docId===docId);
        return u;
    }
    // Obtiene los Ids de los Responsables de la tarea y devuelve los atributos guardados en la tabla Users
    const getDatabyResponsableId = (r) =>{
        const responsables = r.split(',');
        let r_final = [];
        if (responsables && responsables.length>0){
            responsables.forEach(docId => {
                const u = getUserbyDocId(docId);
                if (u && u.length>=1)
                    r_final.push({"nombre": capitalize(u[0].nombre, true), "foto":u[0].foto});
            });
        }
        return r_final
    }

    const showNota = (idx) =>{
        document.getElementById('nota_'+idx).style.display = document.getElementById('nota_'+idx).style.display==='block' ? '-webkit-box' : 'block';
    }
    const showComentarios = (idx) =>{
        document.getElementById('comment_'+idx).style.display = document.getElementById('comment_'+idx).style.display==='block' ? '-webkit-box' : 'block';
    }

    const onBtnAgregarNota = (evt) =>{
        evt.preventDefault();
        evt.stopPropagation();
        // console.log(users);
        const newitemsNota = itemsNota.map((itm, index) => {
            itm.value = '';
            if (itm.key==='id') itm.value = "-1";
            if (itm.key==='relacioncon') itm.value = beneficiarioId; //(notificaciones && notificaciones.length>0) ? notificaciones[0].relacioncon : ''; //capitalize(newitemsNota[0].benefnombres, true) +' '+capitalize(newitemsNota[0].benefapellidos,true);
            if (itm.key==='estadonota') itm.value = "Pendiente";
            if (itm.key==='tiponota') itm.value = "Informativa";
            if (itm.key==='areanota') itm.value = "Fundación";
            if (itm.key==='prioridad') itm.value = "Baja";
            if (itm.key==='fechacreacion') itm.value = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
            if (itm.key==='fechaplazo') itm.value = moment().add(15, 'days').format("YYYY-MM-DD HH:mm:ss");
            if (itm.key==='creadapor') itm.value = users.user.nombre; //users.user.docId;
            if (itm.key==='modificadopor') getFirstWords(users.user.nombre.toUpperCase());
            if (itm.key==='actualizacion') itm.value = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
            if (itm.key==='version') itm.value = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
            
            return itm;
        });
        let nuevoRegistro = {
            "id": '-1', //int
            "tiponota":"Informativa",
            "nota":"",
            "areanota":"Fundación",
            "relacioncon":beneficiarioId, //(notificaciones && notificaciones.length>0) ? notificaciones[0].relacioncon : '',
            "responsables":"",
            "prioridad":"Baja",
            "etiquetas":"",
            "estadonota":"Pendiente",
            "comentarios":"",
            "fechacreacion":moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
            "fechaplazo":moment().add(15, 'days').format("YYYY-MM-DD HH:mm:ss"),
            "creadapor": users.user.docId,
            "actualizacion":moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
            "version":moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
            "modificadopor": getFirstWords(users.user.nombre.toUpperCase())
        };
        setItemsNota(newitemsNota);
        setNotaSelected(nuevoRegistro);
        // Update UI
        setEditorNotaState(EditorState.createEmpty());
        setEditorComentarioState(EditorState.createEmpty());
        setTiponotaSelectedValue(nuevoRegistro.tiponota);
        setPrioridadSelectedValue(nuevoRegistro.prioridad);
        setAreanotaSelectedValue(nuevoRegistro.areanota);
        setEstadonotaSelectedValue(nuevoRegistro.estadonota);
        // setInputBeneficiarioValue(nuevoRegistro.relacioncon);
        setSelectedResponsablesValues(nuevoRegistro.responsables);
        // setSelectedResponsablesValues(nuevoRegistro.responsables.split(',').map(x=>+x));
        setIsDisplayAgregarNota(true);
    }

    const onBtnEditNota = (evt, id, index) =>{
        evt.preventDefault();
        evt.stopPropagation();
        // console.log('onBtnEditNota', id, index);
        if (notificaciones && notificaciones.length>0){
            const newitemsNota = notificaciones.filter((itm, index) => { return itm.id===id});
            // console.log(newitemsNota);
            if (newitemsNota){
                const itemsnote = itemsNota.map((itm, index) => {
                    itm.value = newitemsNota[0][itm.key];
                    // if (itm.key==='relacioncon') itm.value = capitalize(newitemsNota[0].benefnombres, true) +' '+capitalize(newitemsNota[0].benefapellidos,true);
                    if (itm.key==='modificadopor') itm.value = getFirstWords(users.user.nombre.toUpperCase());
                    return itm;
                });
                setItemsNota(itemsnote);
                setNotaSelected(newitemsNota[0]);
                // Update UI
                // Agregar datos del comentario al editor
                // const html = '<div>'+newitemsNota[0].comentarios+'</div>';
                // const comentariosContentBlock = htmlToDraft('<div>'+newitemsNota[0].comentarios+'</div>');
                const comentariosContentBlock = htmlToDraft(newitemsNota[0].comentarios);
                if (comentariosContentBlock) {
                    const comentariosContentState = ContentState.createFromBlockArray(comentariosContentBlock.contentBlocks);
                    const comentariosEditorState = EditorState.createWithContent(comentariosContentState);
                    setEditorComentarioState(comentariosEditorState);
                }
                // const notaContentBlock = htmlToDraft('<div>'+newitemsNota[0].nota+'</div>');
                const notaContentBlock = htmlToDraft(newitemsNota[0].nota);
                if (notaContentBlock) {
                    const notaContentState = ContentState.createFromBlockArray(notaContentBlock.contentBlocks);
                    const notaEditorState = EditorState.createWithContent(notaContentState);
                    setEditorNotaState(notaEditorState);
                }
                setTiponotaSelectedValue(newitemsNota[0].tiponota);
                setPrioridadSelectedValue(newitemsNota[0].prioridad);
                setAreanotaSelectedValue(newitemsNota[0].areanota);
                setEstadonotaSelectedValue(newitemsNota[0].estadonota);
                setFechaPlazo(moment(newitemsNota[0].fechaplazo, 'YYYY-MM-DD'));
                // setInputBeneficiarioValue(newitemsNota[0].relacioncon); 
                // setInputBeneficiarioValue(capitalize(newitemsNota[0].benefnombres, true) +' '+capitalize(newitemsNota[0].benefapellidos,true));
                setSelectedResponsablesValues(newitemsNota[0].responsables); //.split(',').map(x=>+x));

                // setIsEditable(users.user.docId===newitemsNota[0].creadapor ? true : false);
                setIsEditable(users.user.areatrabajo==='ADMINISTRATIVA' || users.user.docId===newitemsNota[0].creadapor ? true : false);
                setIsDisplayEditNota(true);
            }
        }
        
    }
    
    const enviarNota = () => {
        // console.log(notaSelected);
        setIsDisplayAgregarNota(false);
        setIsDisplayEditNota(false);
        const htmlNota = draftToHtml(convertToRaw(editorNotaState.getCurrentContent()));
        const htmlComentarios = draftToHtml(convertToRaw(editorComentarioState.getCurrentContent()));
        notaSelected['nota'] = htmlNota;
        notaSelected['comentarios'] = htmlComentarios;
        notaSelected['modificadopor'] = getFirstWords(users.user.nombre.toUpperCase());
        if (isDisplayEditNota===true) updateDataonServer('update', notaSelected);
        if (isDisplayAgregarNota===true) updateDataonServer('insert', notaSelected);
        // TODO: Enviar notificación al correo
        // window.open('mailto:your@email.here.com');
        // Enviar datos al Whatsapp
        // por políticas de Whatsapp, no se puede enviar un mensaje masivamente por código, ni a un grupo o lista de distribucción
        // Es necesario enviarlo al mismo que crea la lista para que lo reenvie luego al grupo
        // Obtener el numero de telefono del que crea la lista
        const benef = listBenef.filter(b=>b.docId===notaSelected.relacioncon)[0];
        const fotobenef = (benef.foto==='' || benef.foto===null) ? config.ECASE_URL + 'user_images/usuario.jpg' : config.ECASE_URL +'user_images/'+ benef.foto;
        // Enviarse a uno mismo o al primero de la lista
        let celular = users.user.celular;
        // let nombre = users.user.nombre;
        // Enviar mensaje al primero en la lista de responsables
        const responsable_nrouno = listUsers.filter(b=>b.docId===notaSelected.responsables.split(',')[0].trim())[0];
        celular = responsable_nrouno.celular || celular;
        // nombre = responsable_nrouno.nombre || nombre;
        if (benef.foto!=='' && benef.foto!==null){
            window.open('https://api.whatsapp.com/send?phone=57'+ celular + '&text=*_Nota automática del GRB_*: %0D%0A %0D%0A '+removeHTMLTags(notaSelected.nota)+' %0D%0A Relacionada con *'+benef.nombres.toUpperCase()+' '+benef.apellidos.toUpperCase()+'* ('+benef.docId+'). '+encodeURIComponent(fotobenef));
        } else {
            window.open('https://api.whatsapp.com/send?phone=57'+ celular + '&text=*_Nota automática del GRB_*: %0D%0A %0D%0A '+removeHTMLTags(notaSelected.nota)+' %0D%0A Relacionada con *'+benef.nombres.toUpperCase()+' '+benef.apellidos.toUpperCase()+'* ('+benef.docId+').');
        }
        
    }
    const saveNota = () => {
        setIsDisplayAgregarNota(false);
        setIsDisplayEditNota(false);
        const htmlNota = draftToHtml(convertToRaw(editorNotaState.getCurrentContent()));
        const htmlComentarios = draftToHtml(convertToRaw(editorComentarioState.getCurrentContent()));
        // console.log(html);
        notaSelected['nota'] = htmlNota;
        notaSelected['comentarios'] = htmlComentarios;
        notaSelected['modificadopor'] = getFirstWords(users.user.nombre.toUpperCase());
        if (isDisplayEditNota===true) updateDataonServer('update', notaSelected);
        if (isDisplayAgregarNota===true) updateDataonServer('insert', notaSelected);
    }
    const cancelNota = (evt) => {
        if (isDisplayEditNota===true) setIsDisplayEditNota(false);
        if (isDisplayAgregarNota===true) setIsDisplayAgregarNota(false);
    }
    const eliminarNota = (evt, id) =>{
        evt.preventDefault();
        evt.stopPropagation();
        updateDataonServer('remove', notaSelected);
        setIsDisplayEditNota(false);
        // if (notificaciones && notificaciones.length>0){
        //     const newitemsNota = notificaciones.filter((itm, index) => { return itm.id===id});
        //     if (newitemsNota){
        //         setNotaSelected(newitemsNota[0]);
        //         updateDataonServer('remove', newitemsNota[0]);
        //         setIsDisplayEditNota(false);
        //     }
        // }
    }


    const replaceSomeText = (s) =>{
        return s
            .replace(/&nbsp;/g, ' ')
            .replace(/<br>/g, '')
            .replace(/<\/br>/g, '')
            .replace(/<\/div>/g, '')
            .replace(/<div>/g, '')
            .replace(/<b>/g, '')
            .replace(/<strong>/g, '')
            .replace(/<p>/g, '')
            .replace(/<\/b>/g, '')
            .replace(/<\/strong>/g, '')
            .replace(/<\/p>/g, '')
            .replace(/&amp;/g, '&')
            .replace(/&gt;/g, '>')
            .replace(/&lt;/g, '<').trim()
    }
    const validateInput = (f, v, final) => {
        const regex_onlynum = /[0-9,]|\./;
        // const regex_nombres = /^['`´a-zA-ZÀ-ÿ\u00C0-\u017F\s]+$/;
        let new_val = null;
        let err = null;
        switch (f) {
            case 'id':
                new_val = replaceSomeText(v);
                if (!regex_onlynum.test(new_val)) {
                    err = 'Dígite solo números válidos';
                    new_val = null;
                }
                if (new_val===null) new_val = '';
                break;
            case 'fechacreacion': //0000-00-00 00:00:00
                new_val = replaceSomeText(v).substr(0,30);
                if (final && new_val===''){
                    new_val = replaceSomeText(v).substr(0,30);
                    err = 'Dígite una fecha de creación válida';
                    new_val = null;
                }
                break;
            case 'fechaplazo': //0000-00-00 00:00:00
                new_val = replaceSomeText(v).substr(0,30);
                if (final && new_val===''){
                    new_val = replaceSomeText(v).substr(0,30);
                    err = 'Dígite una fecha límite válida';
                    new_val = null;
                }
                break;
            case 'tiponota':
                new_val = v.substr(0,32);
                if (final)
                    new_val = replaceSomeText(v).substr(0,32);
                break;
            case 'estadonota':
                new_val = v.substr(0,32);
                if (final)
                    new_val = replaceSomeText(v).substr(0,32);
                break;
            case 'areanota':
                new_val = v.substr(0,32);
                if (final)
                    new_val = replaceSomeText(v).substr(0,32);
                break;
            case 'relacioncon':
                new_val = v.substr(0,20);
                if (final)
                    new_val = replaceSomeText(v).substr(0,20);
                break;
            case 'responsables':
                new_val = v.substr(0,500);
                if (final)
                    new_val = v.substr(0,600).replace(/<br>/g, ' ').trim();
                break;
            case 'etiquetas':
                new_val = v.substr(0,500);
                if (final)
                    new_val = v.substr(0,600).replace(/<br>/g, ' ').trim();
                break;
            case 'prioridad':
                new_val = v.substr(0,20);
                if (final)
                    new_val = replaceSomeText(v).substr(0,20);
                break;
            case 'celular':
                new_val = replaceSomeText(v).substr(0,15);
                if (!regex_onlynum.test(v)) {
                    err = 'Dígite solo números válidos de celular';
                    new_val = null;
                } else if(v.length>15){
                    err = 'Dígite un número de celular válido';
                    new_val = null;
                }
                if (new_val===null) new_val = '';
                break;
            case 'creadapor':
                new_val = v.substr(0,32);
                if (final)
                    new_val = replaceSomeText(v).substr(0,32);
                break;
            case 'modificadopor':
                new_val = v.substr(0,32);
                if (final)
                    new_val = replaceSomeText(v).substr(0,32);
                break;
            case 'nota':
                new_val = v;
                // if (final)
                //     new_val = v; //replaceSomeText(v);
                break;
            case 'comentarios':
                new_val = v;
                // if (final)
                //     new_val = v; //replaceSomeText(v);
                break;
            case 'actualizacion': //0000-00-00 00:00:00
                new_val = v.substr(0,30);
                if (final && new_val===''){
                    new_val = replaceSomeText(v).substr(0,30);
                    err = 'Dígite una fecha de actualización válida';
                    new_val = null;
                }
                break;
            case 'version': //0000-00-00 00:00:00
                new_val = v.substr(0,30);
                if (final && new_val===''){
                    new_val = replaceSomeText(v).substr(0,30);
                    err = 'Dígite una fecha de version válida';
                    new_val = null;
                }
                break;
            
            default:
                break;
        }

        if (isDisplayEditNota) setError(err);
        if (isDisplayAgregarNota) setErrorenAgregar(err);
        return new_val
    }
    const handleChange = evt => {
        const field = evt.currentTarget.id.split('_')[1];
        const new_val = validateInput(field, evt.target.value, false);
        if(new_val){
            notaSelected[field] = new_val
        };
    };
    const handleBlur = evt => {
        const val = evt.currentTarget.innerHTML; //evt.currentTarget.innerText; //
        const field = evt.target.id.split('_')[1];
        let new_val = validateInput(field, val, true);
        if(new_val || new_val===''){
            if (Object.keys(notaSelected).includes(field)){
                notaSelected[field] = new_val;
            }
            updateItemsNota(field, new_val);
        };
    };

    const [tiponotaSelectedValue, setTiponotaSelectedValue] = useState('');
    const handleTipoNotaSelectChange = (value, item) => {
        setTiponotaSelectedValue(value);
        notaSelected['tiponota'] = value;
        updateItemsNota('tiponota', value);
    };
    const handleTipoNotaonKeyDown  = (evt) => {
        const keyCode = evt.keyCode || evt.which
        if (keyCode === 8 || keyCode === 46) {
            setTiponotaSelectedValue('');
            notaSelected['tiponota'] = '';
            updateItemsNota('tiponota', '');
            evt.returnValue = false
            if (evt.preventDefault) evt.preventDefault()
        }
    }

    const [prioridadSelectedValue, setPrioridadSelectedValue] = useState('');
    const handlePrioridadSelectChange = (value, item) => {
        setPrioridadSelectedValue(value);
        notaSelected['prioridad'] = value;
        updateItemsNota('prioridad', value);
    };
    const handlePrioridadonKeyDown  = (evt) => {
        const keyCode = evt.keyCode || evt.which
        if (keyCode === 8 || keyCode === 46) {
            setPrioridadSelectedValue('');
            notaSelected['prioridad'] = '';
            updateItemsNota('prioridad', '');
            evt.returnValue = false
            if (evt.preventDefault) evt.preventDefault()
        }
    }

    const [areanotaSelectedValue, setAreanotaSelectedValue] = useState('');
    const handleAreanotaSelectChange = (value, item) => {
        setAreanotaSelectedValue(value);
        notaSelected['areanota'] = value;
        updateItemsNota('areanota', value);
    };
    const handleAreanotaonKeyDown  = (evt) => {
        const keyCode = evt.keyCode || evt.which
        if (keyCode === 8 || keyCode === 46) {
            setAreanotaSelectedValue('');
            notaSelected['areanota'] = '';
            updateItemsNota('areanota', '');
            evt.returnValue = false
            if (evt.preventDefault) evt.preventDefault()
        }
    }

    const [estadonotaSelectedValue, setEstadonotaSelectedValue] = useState('');
    const handleEstadonotaSelectChange = (value, item) => {
        setEstadonotaSelectedValue(value);
        notaSelected['estadonota'] = value;
        updateItemsNota('estadonota', value);
    };
    const handleEstadonotaonKeyDown  = (evt) => {
        const keyCode = evt.keyCode || evt.which
        if (keyCode === 8 || keyCode === 46) {
            setEstadonotaSelectedValue('');
            notaSelected['estadonota'] = '';
            updateItemsNota('estadonota', '');
            evt.returnValue = false
            if (evt.preventDefault) evt.preventDefault()
        }
    }

    // DatePicker
    const onFechaPlazoChange = (date) =>{
        if (date.preventDefault) date.preventDefault()
        if (date){
            notaSelected.fechaplazo = date.utc().format('YYYY-MM-DD HH:mm:ss');
            setFechaPlazo(date);
            updateItemsNota('fechaplazo', notaSelected.fechaplazo);
        }
    }
    const onFechaPlazoFocusChange = ({ focused }) =>{
        setFechaPlazoPickerFocused(focused)
    }

    // Select Beneficiario
    // const [selectedBeneficiarioItem, setSelectedBeneficiarioItem] = useState('');
    // const [inputBeneficiarioValue, setInputBeneficiarioValue] = useState('');
    // const searchBeneficiarioChanged = (e) => {
    //     setSelectedBeneficiarioItem(e);
    //     setInputBeneficiarioValue(e);
    //     notaSelected['relacioncon'] = e.value;
    //     updateItemsNota('relacioncon', e.value);
    // }
    // const clearBeneficiarioSearch = () => {
    //     setInputBeneficiarioValue('');
    //     setSelectedBeneficiarioItem('');
    //     notaSelected['relacioncon'] = '';
    //     updateItemsNota('relacioncon', '');
    // }
    // const onBeneficiarioUserAction = (inputValue, selectedItemVal) => {
    //     setInputBeneficiarioValue(inputValue);
    //     setSelectedBeneficiarioItem(selectedItemVal);
    // }

    // Select Responsables (Users)
    const [selectedResponsablesValues, setSelectedResponsablesValues] = useState(); //notaSelected.responsables.split(',').map(x=>+x)
    const handleResponsablesMultiSelectChange = (values, items) => {
        let resp_str = "";
        if(values && values.length>0){
            resp_str = values.join(',');
        }
        setSelectedResponsablesValues(values);
        notaSelected['responsables'] = resp_str;
        updateItemsNota('responsables', resp_str);
    };
    
    // Editor
    const [editorNotaState, setEditorNotaState] = useState( () => EditorState.createEmpty() );
    const [editorComentarioState, setEditorComentarioState] = useState( () => EditorState.createEmpty() );
    const onNotaEditorStateChange = (eState) => {
        setEditorNotaState(eState);
    };
    const onComentarioEditorStateChange = (eState) => {
        setEditorComentarioState(eState);
    };
        
    return (
        <NotasModulo>
            <ToastContainer />
            <AccordionModulo
                onAccordionChange={onAccordionChange}
                activeSectionIndexes={activeSectionIndexes}
            >
                <AccordionSection>
                    <AccordionTitleModule>Notificaciones</AccordionTitleModule>
                    {activeSectionIndexes.length>0 &&  <AccordionContent style={{marginBottom: '10px'}}>
                            { isDisplayAgregarNota && <AgregarNota>
                                    <div className="nota-editform-title">Crear Nueva Nota</div>
                                    <div className="nota-editform-editar">
                                        {errorenAgregar && <div style={{color: 'red', width: '100%'}}>
                                                <Alert red showIcon style={{minWidth: '100%', marginBottom: '10px'}}
                                                    showCloseLabel 
                                                    onClose={() => setErrorenAgregar(null)}>
                                                    <AlertMessage>
                                                        Error:{' '}
                                                        <strong>{errorenAgregar}</strong>
                                                    </AlertMessage>
                                                </Alert>
                                            </div> 
                                        }
                                        {/* ITEMS */}
                                        
                                        { itemsNota && 
                                            itemsNota.map((itm, index) => {
                                                return (<div key={index} className="itemContainer">
                                                    <div className="itemContainer-attr" 
                                                        style={{backgroundColor:(index % 2 === 0) ? '#f8f8f8': '#fff'}}
                                                    >{itm.label}</div>
                                                    <div className="itemContainer-value" 
                                                        style={{backgroundColor:(index % 2 === 0) ? '#f8f8f8': '#fff'}}
                                                    >
                                                    {   
                                                        (itm.key==='id' || itm.key==='fechacreacion' || itm.key==='creadapor')
                                                        ?
                                                        <ContentEditable
                                                            id={'nota_'+itm.key}
                                                            style={{color:'#ccc'}}
                                                            html={itm.value || ''} // innerHTML of the editable div
                                                            className='itemContainer-value-long'
                                                            disabled={true}      // use true to disable editing
                                                            tagName='div' // Use a custom HTML tag (uses a div by default)
                                                        />
                                                        :
                                                        itm.key==='tiponota' 
                                                        ?
                                                            <Select filterable positionFixed 
                                                                // disabled={!isEditable} 
                                                                placeholder={'Seleccione...'}
                                                                onChange={handleTipoNotaSelectChange}
                                                                onKeyDown={handleTipoNotaonKeyDown}
                                                                value={tiponotaSelectedValue || ''}
                                                                selectedValue={tiponotaSelectedValue || ''}
                                                            >
                                                            {tipodeNota.tipo.map(e => {
                                                                return (
                                                                <MenuItem key={e.id} value={e.name}>
                                                                    {e.name}
                                                                </MenuItem>
                                                                )
                                                            })}
                                                            </Select>
                                                        :
                                                        itm.key==='prioridad' 
                                                        ?
                                                            <Select filterable positionFixed 
                                                                // disabled={!isEditable} 
                                                                placeholder={'Seleccione...'}
                                                                onChange={handlePrioridadSelectChange}
                                                                onKeyDown={handlePrioridadonKeyDown}
                                                                value={prioridadSelectedValue || ''}
                                                                selectedValue={prioridadSelectedValue || ''}
                                                            >
                                                            {prioridadNota.tipo.map(e => {
                                                                return (
                                                                <MenuItem key={e.id} value={e.name}>
                                                                    {e.name}
                                                                </MenuItem>
                                                                )
                                                            })}
                                                            </Select>
                                                        :
                                                        itm.key==='areanota' 
                                                        ?
                                                            <Select filterable positionFixed 
                                                                // disabled={!isEditable} 
                                                                placeholder={'Seleccione...'}
                                                                onChange={handleAreanotaSelectChange}
                                                                onKeyDown={handleAreanotaonKeyDown}
                                                                value={areanotaSelectedValue || ''}
                                                                selectedValue={areanotaSelectedValue || ''}
                                                            >
                                                            {areadeTrabajoNotas.tipo.map(e => {
                                                                return (
                                                                <MenuItem key={e.id} value={e.name}>
                                                                    {e.name}
                                                                </MenuItem>
                                                                )
                                                            })}
                                                            </Select>
                                                        :
                                                        itm.key==='estadonota' 
                                                        ?
                                                            <Select filterable positionFixed 
                                                                // disabled={!isEditable} 
                                                                placeholder={'Seleccione...'}
                                                                onChange={handleEstadonotaSelectChange}
                                                                onKeyDown={handleEstadonotaonKeyDown}
                                                                value={estadonotaSelectedValue || ''}
                                                                selectedValue={estadonotaSelectedValue || ''}
                                                            >
                                                            {estadoNotas.tipo.map(e => {
                                                                return (
                                                                <MenuItem key={e.id} value={e.name}>
                                                                    {e.name}
                                                                </MenuItem>
                                                                )
                                                            })}
                                                            </Select>
                                                        :
                                                        itm.key==='fechaplazo' 
                                                        ?
                                                            <DatePicker 
                                                                placeholder="Fecha límite"
                                                                id="fechaplazo"
                                                                // style={{backgroundColor:(index % 2 === 0) ? '#f8f8f8 !important': '#fff !important'}}
                                                                date={fechaPlazo}
                                                                focused={fechaPlazoPickerFocused}
                                                                onDateChange={onFechaPlazoChange}
                                                                onFocusChange={onFechaPlazoFocusChange}
                                                                numberOfMonths={1}
                                                                // disabled={!isEditable}
                                                                readOnly={true}
                                                                hideInputIcon
                                                                disableScroll={false}
                                                                displayFormat={'YYYY-MM-DD'} // 'MMM DD YYYY'
                                                                // isOutsideRange={() => {}}
                                                                isOutsideRange={fechaPlazo => fechaPlazo.isBefore(minDate) || fechaPlazo.isAfter(maxDate)}
                                                                monthYearSelectionMode="MONTH_YEAR"
                                                                yearSelectDates={{"startYear" : 2020, "endYear" : new moment().year() + 1}}
                                                                // displayFormat={moment.localeData().longDateFormat('L')}
                                                                // appendToBody={true}
                                                                // withPortal={true}
                                                            />
                                                        :
                                                        // itm.key==='relacioncon'
                                                        // ?
                                                        // <Search positionFixed className="val-search-benef-input"
                                                        //     virtualized
                                                        //     virtualizedMenuWidth={250}
                                                        //     virtualizedRowHeight={75}
                                                        //     placeholder={'Buscar Beneficiario...'}
                                                        //     searchIcon={false}
                                                        //     inputValue={inputBeneficiarioValue || itm.value}
                                                        //     selectedItem={selectedBeneficiarioItem}
                                                        //     onChange={searchBeneficiarioChanged}
                                                        //     onUserAction={onBeneficiarioUserAction}
                                                        //     onRequestClear={clearBeneficiarioSearch}
                                                        //     >
                                                        //     {listBenef.map((item,ix) => {
                                                        //         const fotoSelBenef = (item.foto==='' || item.foto===null) ? process.env.PUBLIC_URL + '/imgs/team/usuario.jpg' : config.ECASE_URL +'user_images/'+ item.foto; 
                                                        //         return ( 
                                                        //         <ListItem
                                                        //             key={item.docId}
                                                        //             value={item.docId}
                                                        //             label={capitalize(item.nombres +' '+ item.apellidos, true)}
                                                        //             // leftNode={<UserIcon size={32} />}
                                                        //             leftNode={fotoSelBenef ? <img src={fotoSelBenef} width={32} alt=" " /> : <UserIcon size={32} />}
                                                        //         >
                                                        //             <ListItemTitle>{capitalize(item.nombres +' '+ item.apellidos, true)}</ListItemTitle>
                                                        //             <ListItemSubtitle>{item.actividad +' '+ item.categoria}</ListItemSubtitle>
                                                        //         </ListItem>
                                                        //         )
                                                        //     })}
                                                        // </Search>
                                                        // :
                                                        itm.key==='responsables'
                                                        ?
                                                        <MultiSelect positionFixed 
                                                            // disabled={!isEditable} 
                                                            // renderValue={renderResponsablesValue}
                                                            onChange={handleResponsablesMultiSelectChange}
                                                            selectedValues={selectedResponsablesValues || []}
                                                            closeOnSelect={true}
                                                        >
                                                            {listUsers.map((f,i) => {
                                                                const fotoUser = (f.foto==='' ||f.foto===null) ? process.env.PUBLIC_URL + '/imgs/team/usuario.jpg' : process.env.PUBLIC_URL +'/imgs/team/'+ f.foto; 
                                                                return ( f.activo==='1' &&
                                                                    <ListItem
                                                                        key={f.id}
                                                                        value={f.docId}
                                                                        label={f.usuario.toLowerCase()}
                                                                        leftNode={fotoUser ? <img src={fotoUser} width={32} alt=" " /> : <UserIcon size={32} />}
                                                                    >
                                                                        <ListItemTitle>{capitalize(f.nombre, true)}</ListItemTitle>
                                                                        <ListItemSubtitle>{f.areatrabajo}</ListItemSubtitle>
                                                                    </ListItem>
                                                                // return <MenuItem key={f.id} value={f.docId}>{capitalize(f.nombre, true)}</MenuItem>
                                                                )
                                                            })}
                                                        </MultiSelect>
                                                        :
                                                        <ContentEditable
                                                            id={'nota_'+itm.key}
                                                            html={itm.value || ''} // innerHTML of the editable div
                                                            className='itemContainer-value-long'
                                                            // disabled={!isEditable}      // use true to disable editing
                                                            onPaste={pastePlainText} // En caso se copie y pegue de otra pagina de internet
                                                            onChange={handleChange} // handle innerHTML change
                                                            onKeyPress={disableNewlines}  
                                                            onFocus={highlightAll}
                                                            onBlur={handleBlur}
                                                            tagName='div' // Use a custom HTML tag (uses a div by default)
                                                        />
                                                    }

                                                    </div>
                                                </div>)
                                            })
                                        }

                                        <div className="nota-observaciones-comentarios">
                                            <div className="nota-texto-observaciones-title">Notificación u observación:</div>
                                            <div className="nota-texto-observaciones"> 
                                                <Editor 
                                                    placeholder={'Escriba la notificación...'}
                                                    editorState={editorNotaState}
                                                    // wrapperClassName="wrapper-class"
                                                    editorClassName="nota-comntario-editor"
                                                    // toolbarClassName="toolbar-class"
                                                    localization={{ locale: 'es', }}
                                                    onEditorStateChange={onNotaEditorStateChange}
                                                />
                                                
                                            </div>
                                        </div>
                                        
                                        <div className="nota-observaciones-comentarios">
                                            <div className="nota-texto-observaciones-title">Comentario:</div>
                                            <div className="nota-texto-observaciones"> 
                                                <Editor 
                                                    placeholder={'Escriba un comentario...'}
                                                    editorState={editorComentarioState}
                                                    // wrapperClassName="wrapper-class"
                                                    // toolbarClassName="toolbar-class"
                                                    editorClassName="nota-comntario-editor"
                                                    localization={{ locale: 'es', }}
                                                    onEditorStateChange={onComentarioEditorStateChange}
                                                />
                                                
                                            </div>
                                        </div>

                                    </div>

                                    <div className="nota-edit-botones">
                                        <div className="nota-edit-botones-btn"> 
                                            <Button iconPosition="before" onClick={saveNota} icon={<SaveIcon size={16} />}
                                            >Guardar</Button>
                                        </div>
                                        <div className="nota-edit-botones-btn"> 
                                            <Button iconPosition="before" onClick={enviarNota} icon={<WhatsappIcon size={16} />}
                                            >Enviar</Button>
                                        </div>
                                        <div className="nota-edit-botones-btn"> 
                                            <Button iconPosition="before" onClick={(evt) => cancelNota(notaSelected.id)} icon={<ResetIcon size={16} />}
                                            >Cancelar</Button>
                                        </div>
                                    </div>
                                </AgregarNota>
                            }
                            {/* EDIT NOTE */}
                            {isDisplayEditNota && <EditNota>
                                    <div className="nota-editform-title">Editar Nota</div>
                                    <div className="nota-editform-editar">
                                        {error && <div style={{color: 'red', width: '100%'}}>
                                                <Alert red showIcon style={{minWidth: '100%', marginBottom: '10px'}}
                                                    showCloseLabel 
                                                    onClose={() => setError(null)}>
                                                    <AlertMessage>
                                                        Error:{' '}
                                                        <strong>{error}</strong>
                                                    </AlertMessage>
                                                </Alert>
                                            </div> 
                                        }
                                        {/* ITEMS */}
                                        { itemsNota && 
                                            itemsNota.map((itm, index) => {
                                                return (<div key={index} className="itemContainer">
                                                    <div className="itemContainer-attr" 
                                                        style={{backgroundColor:(index % 2 === 0) ? '#f8f8f8': '#fff'}}
                                                    >{itm.label}</div>
                                                    <div className="itemContainer-value" 
                                                        style={{backgroundColor:(index % 2 === 0) ? '#f8f8f8': '#fff'}}
                                                    >
                                                    {   
                                                        (itm.key==='id' || itm.key==='fechacreacion' || itm.key==='creadapor')
                                                        ?
                                                        <ContentEditable
                                                            id={'nota_'+itm.key}
                                                            style={{color:'#ccc'}}
                                                            html={itm.value || ''} // innerHTML of the editable div
                                                            className='itemContainer-value-long'
                                                            disabled={true}      // use true to disable editing
                                                            tagName='div' // Use a custom HTML tag (uses a div by default)
                                                        />
                                                        :
                                                        itm.key==='tiponota' 
                                                        ?
                                                            <Select filterable positionFixed 
                                                                disabled={!isEditable} 
                                                                placeholder={'Seleccione...'}
                                                                onChange={handleTipoNotaSelectChange}
                                                                onKeyDown={handleTipoNotaonKeyDown}
                                                                value={tiponotaSelectedValue || ''}
                                                                selectedValue={tiponotaSelectedValue || ''}
                                                            >
                                                            {tipodeNota.tipo.map(e => {
                                                                return (
                                                                <MenuItem key={e.id} value={e.name}>
                                                                    {e.name}
                                                                </MenuItem>
                                                                )
                                                            })}
                                                            </Select>
                                                        :
                                                        itm.key==='prioridad' 
                                                        ?
                                                            <Select filterable positionFixed 
                                                                disabled={!isEditable} 
                                                                placeholder={'Seleccione...'}
                                                                onChange={handlePrioridadSelectChange}
                                                                onKeyDown={handlePrioridadonKeyDown}
                                                                value={prioridadSelectedValue || ''}
                                                                selectedValue={prioridadSelectedValue || ''}
                                                            >
                                                            {prioridadNota.tipo.map(e => {
                                                                return (
                                                                <MenuItem key={e.id} value={e.name}>
                                                                    {e.name}
                                                                </MenuItem>
                                                                )
                                                            })}
                                                            </Select>
                                                        :
                                                        itm.key==='areanota' 
                                                        ?
                                                            <Select filterable positionFixed 
                                                                disabled={!isEditable} 
                                                                placeholder={'Seleccione...'}
                                                                onChange={handleAreanotaSelectChange}
                                                                onKeyDown={handleAreanotaonKeyDown}
                                                                value={areanotaSelectedValue || ''}
                                                                selectedValue={areanotaSelectedValue || ''}
                                                            >
                                                            {areadeTrabajoNotas.tipo.map(e => {
                                                                return (
                                                                <MenuItem key={e.id} value={e.name}>
                                                                    {e.name}
                                                                </MenuItem>
                                                                )
                                                            })}
                                                            </Select>
                                                        :
                                                        itm.key==='estadonota' 
                                                        ?
                                                            <Select filterable positionFixed 
                                                                disabled={!isEditable} 
                                                                placeholder={'Seleccione...'}
                                                                onChange={handleEstadonotaSelectChange}
                                                                onKeyDown={handleEstadonotaonKeyDown}
                                                                value={estadonotaSelectedValue || ''}
                                                                selectedValue={estadonotaSelectedValue || ''}
                                                            >
                                                            {estadoNotas.tipo.map(e => {
                                                                return (
                                                                <MenuItem key={e.id} value={e.name}>
                                                                    {e.name}
                                                                </MenuItem>
                                                                )
                                                            })}
                                                            </Select>
                                                        :
                                                        itm.key==='fechaplazo' 
                                                        ?
                                                            <DatePicker 
                                                                placeholder="Fecha límite"
                                                                id="fechaplazo"
                                                                // style={{backgroundColor:(index % 2 === 0) ? '#f8f8f8 !important': '#fff !important'}}
                                                                date={fechaPlazo}
                                                                focused={fechaPlazoPickerFocused}
                                                                onDateChange={onFechaPlazoChange}
                                                                onFocusChange={onFechaPlazoFocusChange}
                                                                numberOfMonths={1}
                                                                disabled={!isEditable}
                                                                readOnly={true}
                                                                hideInputIcon
                                                                disableScroll={false}
                                                                displayFormat={'YYYY-MM-DD'} // 'MMM DD YYYY'
                                                                // isOutsideRange={() => {}}
                                                                isOutsideRange={fechaPlazo => fechaPlazo.isBefore(minDate) || fechaPlazo.isAfter(maxDate)}
                                                                monthYearSelectionMode="MONTH_YEAR"
                                                                yearSelectDates={{"startYear" : 2000, "endYear" : new moment().year() + 1}}
                                                                // displayFormat={moment.localeData().longDateFormat('L')}
                                                                // appendToBody={true}
                                                                // withPortal={true}
                                                            />
                                                        :
                                                        // itm.key==='relacioncon'
                                                        // ?
                                                        // <Search positionFixed className="val-search-benef-input"
                                                        //     disabled={!isEditable} 
                                                        //     virtualized
                                                        //     virtualizedMenuWidth={250}
                                                        //     virtualizedRowHeight={75}
                                                        //     placeholder={'Buscar Beneficiario...'}
                                                        //     searchIcon={false}
                                                        //     inputValue={inputBeneficiarioValue || itm.value}
                                                        //     selectedItem={selectedBeneficiarioItem}
                                                        //     onChange={searchBeneficiarioChanged}
                                                        //     onUserAction={onBeneficiarioUserAction}
                                                        //     onRequestClear={clearBeneficiarioSearch}
                                                        //     >
                                                        //     {listBenef.map((item,ix) => {
                                                        //         const fotoSelBenef = (item.foto==='' || item.foto===null) ? process.env.PUBLIC_URL + '/imgs/team/usuario.jpg' : config.ECASE_URL +'user_images/'+ item.foto; 
                                                        //         return ( 
                                                        //         <ListItem
                                                        //             key={item.docId}
                                                        //             value={item.docId}
                                                        //             label={capitalize(item.nombres +' '+ item.apellidos, true)}
                                                        //             // leftNode={<UserIcon size={32} />}
                                                        //             leftNode={fotoSelBenef ? <img src={fotoSelBenef} width={32} alt=" " /> : <UserIcon size={32} />}
                                                        //         >
                                                        //             <ListItemTitle>{capitalize(item.nombres +' '+ item.apellidos, true)}</ListItemTitle>
                                                        //             <ListItemSubtitle>{item.actividad +' '+ item.categoria}</ListItemSubtitle>
                                                        //         </ListItem>
                                                        //         )
                                                        //     })}
                                                        // </Search>
                                                        // :
                                                        itm.key==='responsables'
                                                        ?
                                                        <MultiSelect positionFixed 
                                                            disabled={!isEditable} 
                                                            // renderValue={renderResponsablesValue}
                                                            onChange={handleResponsablesMultiSelectChange}
                                                            selectedValues={selectedResponsablesValues || []}
                                                            closeOnSelect={true}
                                                        >
                                                            {listUsers.map((f,i) => {
                                                                const fotoUser = (f.foto==='' ||f.foto===null) ? process.env.PUBLIC_URL + '/imgs/team/usuario.jpg' : process.env.PUBLIC_URL +'/imgs/team/'+ f.foto; 
                                                                return ( f.activo==='1' &&
                                                                    <ListItem
                                                                        key={f.id}
                                                                        value={f.docId}
                                                                        label={f.usuario.toLowerCase()}
                                                                        leftNode={fotoUser ? <img src={fotoUser} width={32} alt=" " /> : <UserIcon size={32} />}
                                                                    >
                                                                        <ListItemTitle>{capitalize(f.nombre, true)}</ListItemTitle>
                                                                        <ListItemSubtitle>{f.areatrabajo}</ListItemSubtitle>
                                                                    </ListItem>
                                                                // return <MenuItem key={f.id} value={f.docId}>{capitalize(f.nombre, true)}</MenuItem>
                                                                )
                                                            })}
                                                        </MultiSelect>
                                                        :
                                                        <ContentEditable
                                                            id={'nota_'+itm.key}
                                                            html={itm.value || ''} // innerHTML of the editable div
                                                            className='itemContainer-value-long'
                                                            disabled={!isEditable}      // use true to disable editing
                                                            onPaste={pastePlainText} // En caso se copie y pegue de otra pagina de internet
                                                            onChange={handleChange} // handle innerHTML change
                                                            onKeyPress={disableNewlines}  
                                                            onFocus={highlightAll}
                                                            onBlur={handleBlur}
                                                            tagName='div' // Use a custom HTML tag (uses a div by default)
                                                        />
                                                    }

                                                    </div>
                                                </div>)
                                            })
                                        }

                                        <div className="nota-observaciones-comentarios">
                                            <div className="nota-texto-observaciones-title">Notificación u observación:</div>
                                            <div className="nota-texto-observaciones"> 
                                            { isEditable ?
                                                <Editor placeholder={'Escriba la notificación...'}
                                                    editorState={editorNotaState}
                                                    // wrapperClassName="wrapper-class"
                                                    editorClassName="nota-comntario-editor"
                                                    // toolbarClassName="toolbar-class"
                                                    localization={{ locale: 'es', }}
                                                    onEditorStateChange={onNotaEditorStateChange}
                                                    
                                                />
                                                :
                                                <ContentEditable
                                                    id={'notaedit_nota'}
                                                    // innerRef={nota}
                                                    // html={nota.current}
                                                    html={notaSelected.nota || ''} // innerHTML of the editable div
                                                    // disabled={users.user.docId===notaSelected.creadapor ? false : true}        // use true to disable editing
                                                    disabled={true} 
                                                    onPaste={pastePlainText}
                                                    onChange={handleChange} // handle innerHTML change
                                                    onFocus={highlightAll}
                                                    onBlur={handleBlur}
                                                    tagName='div' // Use a custom HTML tag (uses a div by default)
                                                    style={{ "minHeight": "100px", "border": "1px dashed", backgroundColor:'#fdfdca', padding:'0px 8px' }}
                                                />
                                            }
                                                
                                            </div>
                                        </div>
                                        
                                        <div className="nota-observaciones-comentarios">
                                            <div className="nota-texto-observaciones-title">Comentario:</div>
                                            <div className="nota-texto-observaciones"> 
                                                <Editor disabled={!isEditable} placeholder={'Escriba un comentario...'}
                                                    // toolbarHidden
                                                    // toolbarOnFocus
                                                    editorState={editorComentarioState}
                                                    // wrapperClassName="wrapper-class"
                                                    editorClassName="nota-comntario-editor"
                                                    // toolbarClassName="toolbar-class"
                                                    toolbar={{
                                                        inline: { inDropdown: true },
                                                        list: { inDropdown: true },
                                                        textAlign: { inDropdown: true },
                                                        link: { inDropdown: true },
                                                        embedded: { inDropdown: true },
                                                        emoji: { inDropdown: true },
                                                        image: { inDropdown: true },
                                                        remove: { inDropdown: true },
                                                        history: { inDropdown: true },
                                                    }}
                                                    localization={{ locale: 'es', }}
                                                    onEditorStateChange={onComentarioEditorStateChange}
                                                />
                                                
                                            </div>
                                        </div>

                                    </div>
                                    <div className="nota-edit-botones">
                                        <div className="nota-edit-botones-btn"> 
                                            <Button iconPosition="before" onClick={saveNota} icon={<SaveIcon size={16} />}
                                            >Guardar</Button>
                                        </div>
                                        {isEditable && <div className="nota-edit-botones-btn"> 
                                            <Button iconPosition="before" onClick={(evt) => eliminarNota(evt, notaSelected.id)} icon={<TrashIcon size={16} />}
                                            >Eliminar</Button>
                                        </div>}
                                        
                                    </div>
                                </EditNota>
                            }
                            {   !isDisplayEditNota && !isDisplayAgregarNota && <div>
                                    <div className="notas-fundacion-toolbar">
                                    <div className="notas-fundacion-toolbar-left">
                                        <div className="notas-fundacion-toolbarLeft-tools">
                                            <div className="notas-fundacion-toolbarLeft-btn">
                                                <Button iconPosition="before" 
                                                    onClick={onBtnAgregarNota}
                                                    icon={<PlusIcon size={16} />}
                                                >
                                                    Nueva Notificación
                                                </Button>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                                { notificaciones && notificaciones.length>0 ?
                                <div className='notas-fundacion-tablaContainer'>
                                    <div className='notas-fundacion-tabla'>
                                    { notificaciones.map((itm, index) => {
                                        // const foto_creador = itm.foto==='' ? process.env.PUBLIC_URL + '/imgs/team/usuario.jpg' : config.ECASE_URL +'user_images/'+ Beneficiario.foto; 
                                        const foto_creador = (itm.foto==='' || itm.foto===null) ? process.env.PUBLIC_URL + '/imgs/team/usuario.jpg' : process.env.PUBLIC_URL + '/imgs/team/'+ itm.foto; 
                                        // const fotobenef = (itm.fotobenef==='' || itm.fotobenef===null) ? process.env.PUBLIC_URL + '/imgs/team/usuario.jpg' : config.ECASE_URL +'user_images/'+ itm.fotobenef;
                                        // const benefnombre = (itm.benefnombres==='' || itm.benefnombres===null) ? '' : capitalize(itm.benefnombres,true);
                                        // const benefapellidos = (itm.benefapellidos==='' || itm.benefapellidos===null) ? '' : capitalize(itm.benefapellidos,true);
                                        const fullName = (itm.nombre==='' || itm.nombre===null) ? '' : capitalize(itm.nombre, true);
                                        const nombre = fullName ? fullName.split(' ')[0] +' '+ fullName.split(' ')[1] : '';
                                        // const iniciales = getFirstWords(fullName);
                                        const responsables = itm.responsables ? getDatabyResponsableId(itm.responsables) : '';
                                        const fechacreacion = itm.fechacreacion ? moment(itm.fechacreacion, 'YYYY-MM-DD').format('YYYY-MMM-DD').split('-') : '';
                                        return (<div key={index}  className="notas-fundacion-lista-row" style={{backgroundColor:(index % 2 === 0) ? '#fff': '#fff'}}>
                                            
                                            {/* <div className="notas-fundacion-lista-cell">{itm.id}</div> */}
                                            <div className="notas-fundacion-header" onClick={(evt) => onBtnEditNota(evt, itm.id, index)}>
                                                <div className="notas-fundacion-lista-cell-inline">
                                                    <div className="notas-fundacion-lista-cell-inline">
                                                        <div className="notas-fundacion-lista-fechacreacion">
                                                            <div className="notas-fundacion-lista-fechacreacion-m">{fechacreacion[1].toUpperCase()}</div>
                                                            <div className="notas-fundacion-lista-fechacreacion-d">{fechacreacion[2]}</div>
                                                            <div className="notas-fundacion-lista-fechacreacion-y">{fechacreacion[0]}</div>
                                                        </div>
                                                    </div>

                                                    <div className="notas-fundacion-lista-cell-inline">
                                                        <div className="notas-fundacion-lista-creadorContainer">
                                                            <div className="notas-fundacion-lista-tiponotificacion">
                                                                <div className="notas-fundacion-lista-areanota">{capitalize(itm.areanota, true)}</div>
                                                                <div className="notas-fundacion-lista-tiponota">{itm.tiponota}</div>
                                                                <div className="notas-fundacion-lista-prioridad">
                                                                    {itm.prioridad.toLowerCase()==='alta' && <div className="notas-fundacion-lista-prioridad notas-fundacion-lista-prioridad-alta">{itm.prioridad}</div>}
                                                                    {itm.prioridad.toLowerCase()==='media' && <div className="notas-fundacion-lista-prioridad notas-fundacion-lista-prioridad-media">{itm.prioridad}</div>}
                                                                    {itm.prioridad.toLowerCase()==='baja' && <div className="notas-fundacion-lista-prioridad notas-fundacion-lista-prioridad-baja">{itm.prioridad}</div>}
                                                                </div>
                                                            </div>
                                                            
                                                            <div className="notas-fundacion-lista-creador">
                                                                <div className="notas-fundacion-lista-creadapor-foto"><img src={foto_creador} alt=' ' /></div>
                                                                <div className="notas-fundacion-lista-creadapor">{nombre}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="notas-fundacion-lista-header-right">
                                                    <div className="notas-fundacion-lista-dirigidoa">
                                                        {/* <div className="notas-fundacion-lista-relacioncon-nombre"><strong>{benefnombre}</strong> {benefapellidos}</div>
                                                        <div className="notas-fundacion-lista-relacioncon-foto"><img src={fotobenef} alt=' ' /></div> */}
                                                    </div>
                                                    {/* <div className="notas-fundacion-lista-relacioncon">{itm.relacioncon}</div> */}
                                                    { itm.estadonota.toLowerCase()==='finalizada' && <div className="notas-fundacion-lista-estadonota estadonota-finalizada">{itm.estadonota}</div>}
                                                    { itm.estadonota.toLowerCase()==='pendiente' && <div className="notas-fundacion-lista-estadonota estadonota-pendiente">{itm.estadonota}</div>}
                                                    { itm.estadonota.toLowerCase()==='cancelada' && <div className="notas-fundacion-lista-estadonota estadonota-cancelada">{itm.estadonota}</div>}
                                                    { itm.estadonota.toLowerCase()==='aceptada' && <div className="notas-fundacion-lista-estadonota estadonota-aceptada">{itm.estadonota}</div>}
                                                    { itm.estadonota.toLowerCase()==='revisada' && <div className="notas-fundacion-lista-estadonota estadonota-revisada">{itm.estadonota}</div>}
                                                    { itm.estadonota.toLowerCase()==='caducada' && <div className="notas-fundacion-lista-estadonota estadonota-caducada">{itm.estadonota}</div>}
                                                </div>
                                            </div>
                                            
                                            <div className="notas-fundacion-lista-cell">
                                                <div id={'nota_'+index} className="notas-fundacion-lista-cell-nota multi-line-ellipsis" aria-haspopup="true"
                                                    onClick={() => showNota(index)} >
                                                        <ContentEditable
                                                            id={'notificacion_'+index}
                                                            html={itm.nota || ''} // innerHTML of the editable div
                                                            disabled={true}        // use true to disable editing
                                                            tagName='div' // Use a custom HTML tag (uses a div by default)
                                                            // style={{ "minHeight": "40px", "border": "1px dashed", padding:'0px 8px'  }}
                                                        />
                                                    </div>
                                            </div>
                                            
                                            <div className="notas-fundacion-lista-notafooter">
                                                <div className="notas-fundacion-lista-responsables">
                                                    {responsables && <div className="notas-fundacion-lista-responsables-avatars">
                                                        { responsables.map((r,idx) =>{
                                                            const iniciales = getFirstWords(r.nombre.toUpperCase());
                                                            const fotoresponsable = (r.foto==='' || r.foto===null) ? process.env.PUBLIC_URL + '/imgs/team/usuario.jpg' : process.env.PUBLIC_URL + '/imgs/team/'+ r.foto; 
                                                            return (<div key={idx} title={r.nombre} className="notas-fundacion-lista-resp-avatar">
                                                                    <div className="notas-fundacion-lista-responsables-foto"><img src={fotoresponsable} alt=' '/></div>
                                                                    <div className="notas-fundacion-lista-responsables-iniciales">{iniciales}</div>
                                                                </div>
                                                            )
                                                        })
                                                        }
                                                    </div>}
                                                </div>
                                                <div className="notas-fundacion-lista-fechaplazo">Plazo: {itm.fechaplazo.split(' ')[0]}</div>
                                            </div>
                                            {itm.comentarios!=='' && <div className="notas-fundacion-lista-cell">
                                                <div className="notas-fundacion-lista-comentarios">
                                                    <div>Comentarios:</div>
                                                    <div id={'comment_'+index} className="multi-line-ellipsis" onClick={() => showComentarios(index)}>
                                                        {/* {itm.comentarios} */}
                                                        <ContentEditable
                                                            id={'notificacion_'+index}
                                                            html={itm.comentarios || ''} // innerHTML of the editable div
                                                            disabled={true}        // use true to disable editing
                                                            tagName='div' // Use a custom HTML tag (uses a div by default)
                                                            // style={{ "minHeight": "40px", "border": "1px dashed", padding:'0px 8px'  }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            }
                                            {itm.etiquetas!=='' && <div className="notas-fundacion-lista-cell">  
                                                <div className="notas-fundacion-lista-etiquetas">
                                                    {itm.etiquetas.split(',').map((e, idx) =>{
                                                        return (
                                                            <div key={idx} className="notas-fundacion-lista-etiquetas-item">{e}</div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                            }
                                            <div className="notas-fundacion-lista-footer">
                                                <div className="notas-fundacion-lista-actualizacion">Última actualización: {itm.actualizacion} por <span>{itm.modificadopor}</span></div>
                                            </div>
                                            
                                        </div>)
                                        })
                                    }
                                    </div>
                                </div>
                            :
                                <div>No hay registros</div>
                            }
                            </div>
                            }
                        </AccordionContent>
                    }
                </AccordionSection>
            </AccordionModulo>
        </NotasModulo>
    );
    }

export default BenefNotificaciones;

