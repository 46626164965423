import React, {useState, useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";
import ContentEditable from 'react-contenteditable';
import { updateBeneficiariosVestuarioTallaje, serverMensaje } from '../../redux/reducers/beneficiarios';

import {highlightAll, disableNewlines, pastePlainText } from '../../utils/utils';
import {showToaster} from './BenefShowMesg';
import Alert, {AlertMessage} from 'calcite-react/Alert'

import Accordion, {
    AccordionSection,
    AccordionTitle,
    AccordionContent
} from 'calcite-react/Accordion';

import styled from "styled-components";

const BeneficiarioModulo = styled.div`
    height: auto;
    background: #fff;
    border: 0;
    padding: 0px;
    margin: 4px;
    overflow:hidden;
`;

const AccordionModulo = styled(Accordion)`
    padding:0;
`;
const AccordionTitleModule = styled(AccordionTitle)`
    background-color: #e6e6e6;
    color: #4d4d4d;
    font-size: 1.25em;
    font-weight: 700;
    // border-bottom: 1px solid #d9d9d9;
    // box-shadow: 0px 2px 6px #e1e1e1;
`;


const BenefVestuarioTallaje = ({tallaje}) => {
    const dispatch = useDispatch();
    const mensaje = useSelector(state => state.beneficiarios.mensaje);
    const users = useSelector(state => state.users);
    // const isEditable = users.user.tipo==='ADMINISTRADOR' ? true : false;
    const isEditable = (users.user.areatrabajo==='ADMINISTRATIVA' || users.user.areatrabajo==='TRABAJO SOCIAL' || users.user.areatrabajo==='COORDINACIÓN' || users.user.areatrabajo==='PSICOLOGÍA' ) ? true : false;
    const [activeSectionIndexes, setActiveSectionIndexes] = useState([]);

    const [error, setError] = useState(null);
    const onAccordionChange = (evt, index) => {
        activeSectionIndexes.includes(index)
        ? setActiveSectionIndexes(activeSectionIndexes.filter(
            item => index !== item,
          ))
        : setActiveSectionIndexes([...activeSectionIndexes, index]);
    }
    
    const [T] = useState({
        "id": tallaje.id, //int
        "beneficiarioId": tallaje.beneficiarioId, //15
        "camiseta": tallaje.camiseta, //
        "pantalon": tallaje.pantalon, //
        "pantaloneta": tallaje.pantaloneta, //
        "calzado": tallaje.calzado, //
        "gorra": tallaje.gorra, //
        "otro": tallaje.otro, //
        "descripcion": tallaje.descripcion, //
        "comentarios": tallaje.comentarios, //
        "actualizacion": tallaje.actualizacion, //
        "version": tallaje.version, //
        "modificadopor": tallaje.modificadopor //
    });

    const [itemsTallaje, setItemsTallaje] = useState([
        // {"key":"id", "label": "Identificador Único", "value": T['id']},
        // {"key":"beneficiarioId", "label": "Documento de Identificación", "value": T['beneficiarioId']},
        {"key":"camiseta", "label": "Talla de Camiseta", "value": T['camiseta']},
        {"key":"pantalon", "label": "Talla de Pantalón", "value": T['pantalon']},
        {"key":"pantaloneta", "label": "Talla de Pantaloneta", "value": T['pantaloneta']},
        {"key":"calzado", "label": "Talla de Calzado", "value": T['calzado']},
        // {"key":"gorra", "label": "Gorra", "value": T['gorra']},
        {"key":"otro", "label": "Otro", "value": T['otro']},
        {"key":"descripcion", "label": "Descripción breve de otro", "value": T['descripcion']},
        // {"key":"comentarios", "label": "comentarios", "value": T['comentarios']},
        // {"key":"actualizacion", "label": "actualizacion", "value": T['actualizacion']},
        // {"key":"version", "label": "version", "value": T['version']},
        // {"key":"modificadopor", "label": "modificadopor", "value": T['modificadopor']},
        // {"key":"comentarios", "label": "Comentarios en general del tallaje", "value": T['comentarios']},
        // {"key":"actualizacion", "label": "Última actualización", "value": T['actualizacion']},
        // {"key":"version", "label": "Versión", "value": T['version']}
    ]);

    useEffect(() => {
        // Guardar los resultados al salir, puede ser contraproducente al fallar la conexión con el servidor u otro evento no deseado
        // se podrián perder los datos modificados
        
        return () => {
            // if (isUpdated){
            //     console.log('Saving appointment', isUpdated);
            // }
            dispatch(serverMensaje({}));
        }
    }, [dispatch]);

    useEffect(() => {
        // if (itemsTallaje){
        //     // console.log(itemsTallaje);
        // }
        if (mensaje.tallaje && mensaje.tallaje!=='') {
            if (typeof mensaje.tallaje === 'string' && mensaje.tallaje.toLowerCase().includes('error')){
                showToaster('error', 'Ocurrió un fallo en el servidor', mensaje.tallaje);
            } 
            // else {
            //     showToaster('success',"Tallaje del Beneficiario", mensaje.tallaje); //"🧭 " + mensaje
            // }
            
        }

    }, [mensaje.tallaje, dispatch]);

    const updateItemsTallaje = (f, v) => {
        const newitemsTallaje = itemsTallaje.map((itm, index) => {
            if (itm.key===f){
                itm.value = v;
            }
            return itm;
        });
        setItemsTallaje(newitemsTallaje);
    };

    const updateDataonServer = () =>{
        dispatch(serverMensaje({tallaje:''}));
        dispatch(updateBeneficiariosVestuarioTallaje( { token: users.user.claveAPI, tallaje:T}));
    }

    const replaceSomeText = (s) =>{
        return s
            .replace(/&nbsp;/g, ' ')
            .replace(/<br>/g, '')
            .replace(/&amp;/g, '&')
            .replace(/&gt;/g, '>')
            .replace(/&lt;/g, '<').trim()
    }

    const validateInput = (f, v, final) => {
        const regex_onlynum = /[0-9,]|\./;
        // const regex_nombres = /^['`´a-zA-ZÀ-ÿ\u00C0-\u017F\s]+$/;
        // const regex_onlyletters = /#[a-zA-Z\xE1\xE9\xED\xF3\xFA\xC1\xC9\xCD\xD3\xDA\xF1\xD1]+#/i;
        let new_val = null;
        let err = null;
        switch (f) {
            case 'id':
                new_val = v; //replaceSomeText(v).substr(0,150);
                if (!regex_onlynum.test(new_val)) {
                    err = 'Dígite un id válido';
                    new_val = null;
                }
                break;
            case 'beneficiarioId':
                new_val = v.substr(0,15);
                if (final)
                    new_val = replaceSomeText(v).substr(0,15);
                if (!regex_onlynum.test(new_val)) {
                    err = 'Dígite un identificador válido';
                    new_val = null;
                }
                break;
            case 'camiseta':
                new_val = v.substr(0,32);
                if (final)
                    new_val = replaceSomeText(v).substr(0,32);
                break;
            case 'pantalon':
                new_val = v.substr(0,32);
                if (final)
                    new_val = replaceSomeText(v).substr(0,32);
                break;
            case 'pantaloneta':
                new_val = v.substr(0,32);
                if (final)
                    new_val = replaceSomeText(v).substr(0,32);
                break;
            case 'calzado':
                new_val = v.substr(0,32);
                if (final)
                    new_val = replaceSomeText(v).substr(0,32);
                break;
            case 'gorra':
                new_val = v.substr(0,32);
                if (final)
                    new_val = replaceSomeText(v).substr(0,32);
                break;
            case 'otro':
                new_val = v.substr(0,60);
                if (final)
                    new_val = replaceSomeText(v).substr(0,60);
                break;
            case 'descripcion':
                new_val = v.substr(0,255);
                if (final)
                    new_val = replaceSomeText(v).substr(0,255);
                break;
            case 'actualizacion': //0000-00-00 00:00:00
                new_val = v.substr(0,30);
                if (final && new_val===''){
                    new_val = replaceSomeText(v).substr(0,30);
                    err = 'Dígite una fecha de nacimiento válida';
                    new_val = null;
                }
                break;
            case 'version': //0000-00-00 00:00:00
                new_val = v.substr(0,30);
                if (final && new_val===''){
                    new_val = replaceSomeText(v).substr(0,30);
                    err = 'Dígite una fecha de nacimiento válida';
                    new_val = null;
                }
                break;
            case 'modificadopor':
                new_val = v.substr(0,32);
                if (final)
                    new_val = replaceSomeText(v).substr(0,32);
                break;
            case 'comentarios':
                new_val = v.trim();
                if (final)
                    new_val = replaceSomeText(v);
                break;
            default:
                break;
        }
        setError(err);
        return new_val
    }
    const handleChange = evt => {
        const field = evt.currentTarget.id.split('_')[1];
        const new_val = validateInput(field, evt.target.value, false)
        if(new_val){
            T[field] = new_val
        };
    };
    const handleBlur = evt => {
        const val = evt.currentTarget.innerHTML;
        const field = evt.target.id.split('_')[1];
        const new_val = validateInput(field, val, true)
        if(new_val || new_val===''){
            T[field] = new_val;
            // if (field!=='comentarios')
            updateItemsTallaje(field, T[field]);
            updateDataonServer();
        };
        
    };

    return (
        <BeneficiarioModulo>
            <AccordionModulo
                onAccordionChange={onAccordionChange}
                activeSectionIndexes={activeSectionIndexes}
            >
                <AccordionSection>
                    <AccordionTitleModule>Tallaje</AccordionTitleModule>
                    {activeSectionIndexes.length>0 &&  <AccordionContent style={{marginBottom: '10px'}}>
                    {error && <div style={{color: 'red', width: '100%'}}>
                            <Alert red showIcon style={{minWidth: '100%', marginBottom: '10px'}}
                                showCloseLabel 
                                onClose={() => setError(null)}>
                                <AlertMessage>
                                    Error:{' '}
                                    <strong>{error}</strong>
                                </AlertMessage>
                            </Alert>
                        </div> 
                    }
                    {/* ITEMS */}
                        {
                            itemsTallaje.map((itm, index) => {
                                // if (itm.value!==null && itm.value!=="" ){
                                    return (<div key={index} className="itemContainer">
                                        <div className="itemContainer-attr" 
                                            style={{backgroundColor:(index % 2 === 0) ? '#f8f8f8': '#fff'}}
                                        >{itm.label}</div>
                                        <div className="itemContainer-value" 
                                            style={{backgroundColor:(index % 2 === 0) ? '#f8f8f8': '#fff'}}
                                        >

                                        {   
                                            <ContentEditable
                                                id={'tallaje_'+itm.key}
                                                html={itm.value || ''} // innerHTML of the editable div
                                                className='itemContainer-value-long'
                                                disabled={!isEditable}      // use true to disable editing
                                                onPaste={pastePlainText} // En caso se copie y pegue de otra pagina de internet
                                                onChange={handleChange} // handle innerHTML change
                                                onKeyPress={disableNewlines}  
                                                onFocus={highlightAll}
                                                onBlur={handleBlur}
                                                tagName='div' // Use a custom HTML tag (uses a div by default)
                                            />
                                        }
                                        </div>
                                    </div>)
                                // } 
                            })
                        }

                        <div className="Observaciones-Comentarios">
                            <div className="benef-texto-observaciones-title">Comentarios en general:</div>
                            <div className="benef-texto-observaciones"> 
                                <ContentEditable
                                    id={'tallaje_comentarios'}
                                    html={T.comentarios || ''} // innerHTML of the editable div
                                    disabled={!isEditable}        // use true to disable editing
                                    onPaste={pastePlainText}
                                    onChange={handleChange} // handle innerHTML change
                                    onFocus={highlightAll}
                                    onBlur={handleBlur}
                                    tagName='div' // Use a custom HTML tag (uses a div by default)
                                    style={{ "minHeight": "40px", "border": "1px dashed" }}
                                />
                            </div>
                        </div>

                    <div className="benef-texto-actualizacion">Última actualización: {T['actualizacion']} por {T['modificadopor']}</div>

                    </AccordionContent>
                }
                </AccordionSection>
            </AccordionModulo>
        </BeneficiarioModulo>
    );
}

export default BenefVestuarioTallaje;

