import React, {useState, useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchTablasCriteriosxCovid, 
    setCovidListEntrega, 
    displayCovidListEntrega } from '../../../redux/reducers/covid19';

import {calcularAfectacion } from '../../../utils/utils';

import Alert, {AlertMessage} from 'calcite-react/Alert';
import TextField from 'calcite-react/TextField';
import Button, { ButtonGroup } from 'calcite-react/Button';
// import Form, {
//     FormControl,
//     FormControlLabel,
//     FormHelperText,
//     Fieldset,
//     Legend
//   } from 'calcite-react/Form';
import Table, {
    // TableHeader,
    // TableHeaderRow,
    // TableHeaderCell,
    TableBody,
    TableRow,
    TableCell
} from 'calcite-react/Table';
import Select from 'calcite-react/Select';
import { MenuItem } from 'calcite-react/Menu';
import DatePicker from 'calcite-react/DatePicker';
import moment from 'moment';
import 'moment/locale/es-us';
// import '../../react_dates_overrides.css';

import PlusIcon from 'calcite-ui-icons-react/PlusIcon';

import CriterioPrestaServicio from './CriterioPrestaServicio';
import CriterioFamiliaClub from './CriterioFamiliaClub';
import CriterioCabezaFamilia from './CriterioCabezaFamilia';
import CriterioPersonasaCargo from './CriterioPersonasaCargo';
import CriterioNumeroHijos from './CriterioNumeroHijos';
import CriterioAsistencia from './CriterioAsistencia';
import CriterioEdad from './CriterioEdad';
import CriterioEdadClub from './CriterioEdadClub';
import CriterioActividad from './CriterioActividad';
import CriterioCategoria from './CriterioCategoria';
import CriterioCupo from './CriterioCupo';

import styled from "styled-components";

import tipoAyuda from '../../../data/tipoayuda.json';
// import closecircleIcon from '../../../assets/icons/closecircle_icon.png';


const ListaEntregaContainer = styled.div`
    // width: calc(100% - 45px);
    width: 100%;
    height: 100vh;
    background: #fff;
    padding: 10px;
`;

const ListaEntregaCriteriosContainer = styled.div`
    // display: flex;
`;
const ListaEntregaOpcionesContainer = styled.div`
    // display: flex;
    // border: 1px solid #ccc;
    padding: 0;
    // margin: 5px;
    // flex-wrap: wrap;
    // width: auto;
`;

// const CardBenefContainerClose = styled.div`
//     position: absolute;
//     width: 24px;
//     height: 24px;
//     right: 60px;
//     top: 5px;
//     font-size: 24px;
//     cursor: pointer;
//     z-index: 101;
//     background: rgba(255, 255, 255, 0.1) url(${closecircleIcon}) no-repeat center/cover;
//     opacity: 0.4;

//     &:hover {
//         opacity: 1;
//     }
// `;

const ListaEntrega = ({listAyudas, listfilter}) => {
    // const config = useSelector(state => state.config);
    const users = useSelector(state => state.users);
    // const isEditable = users.user.tipo==='ADMINISTRADOR' ? true : false;
    const isEditable = (users.user.areatrabajo==='ADMINISTRATIVA' || users.user.areatrabajo==='COORDINACIÓN' 
        || users.user.areatrabajo==='TECNOLOGÍA' || users.user.areatrabajo==='DIRECCIÓN') ? true : false;
    // const canView = (users.user.areatrabajo==='ADMINISTRATIVA' || users.user.areatrabajo==='TECNOLOGÍA' ) ? true : false;
    
    // const listEntrega = useSelector(state => state.covid19.listEntrega);
    // const isListEntrega = useSelector(state => state.covid19.isListEntrega);
    // const listBenef = useSelector(state => state.covid19.listBenef);
    const listCriterios = useSelector(state => state.covid19.listCriterios);
    const [error, setError] = useState(null);
    const dispatch = useDispatch();
    // Calcula el número de la última entrega
    let last_numentrega = parseInt(listAyudas[0].numentrega);
    listAyudas.forEach((itm) => {
        if (parseInt(itm.numentrega) > last_numentrega) {
            last_numentrega = parseInt(itm.numentrega);
        }
    });

    const [datosEntrega,] = useState({
        'numentrega': (last_numentrega+1) || '',
        'numconvenio': '801',
        'tipoayuda': 'Bono',
        'fechainicio': moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
        'fechavigencia': null,
        'fechacaducidad': null,
        'caducados': 0,
        'datosfiltrados': 0
    });

    const [numentrega, setNumentrega] = useState((last_numentrega+1) || '');
    const [numconvenio, setNumconvenio] = useState('801');
    const [tipoayudaSelectedValue, setTipoayudaSelectedValue] = useState('Bono');
    const [caducadosSelected, setCaducadosSelected] = useState(0);
    const [datosfiltradosSelected, setDatosFiltradosSelected] = useState(0);
    // Fechas
    const [fechaInicio, setFechaInicio] = useState(moment(new Date(),'YYYY-MM-DD'));
    const [fechaInicioPickerFocused, setFechaInicioPickerFocused] = useState(false);
    const [fechaVigencia, setFechaVigencia] = useState(null);
    const [fechaVigenciaPickerFocused, setFechaVigenciaPickerFocused] = useState(false);
    const [fechaCaducidad, setFechaCaducidad] = useState(null);
    const [fechaCaducidadPickerFocused, setFechaCaducidadPickerFocused] = useState(false);
    
    useEffect(() => {
        if (!listCriterios || listCriterios.length<=0)
            dispatch(fetchTablasCriteriosxCovid({token: users.user.claveAPI}));

    }, [users.user.claveAPI, listCriterios, dispatch]);

    const onCreateListEntrega = () => {
        if (datosEntrega){
            let errormsg = '';
            if (datosEntrega.fechacaducidad==='' || datosEntrega.fechacaducidad===null){
                errormsg = 'Seleccione una fecha de caducidad válida';
            }
            if (datosEntrega.fechavigencia==='' || datosEntrega.fechavigencia===null){
                errormsg = 'Seleccione una fecha de vigencia válida';
            }
            if (datosEntrega.fechainicio==='' || datosEntrega.fechainicio===null){
                errormsg = 'Seleccione una fecha de inicio válida';
            }
            if (datosEntrega.tipoayuda==='' || datosEntrega.tipoayuda===null){
                errormsg = 'Dígite un tipo de ayuda válido';
            }
            if (datosEntrega.numconvenio==='' || datosEntrega.numconvenio===null){
                errormsg = 'Dígite un número de convenio válido';
            }
            if (datosEntrega.numentrega==='' || datosEntrega.numentrega===null){ 
                errormsg = 'Dígite un número de entrega válido';
            }
            // if (datosEntrega.caducados==='' || datosEntrega.caducados===null){ 
            //     errormsg = 'Seleccione opción de caducados válida';
            // }
            // if (datosEntrega.datosfiltrados==='' || datosEntrega.datosfiltrados===null){ 
            //     errormsg = 'Seleccione opción de datos filtrados válida';
            // }
            if (errormsg!==''){
                setError(errormsg);
                return
            }

            if (listAyudas && listAyudas.length>0){
                let newListaEntrega = [];
                if (datosEntrega.datosfiltrados===1 && listfilter.length>0){
                    newListaEntrega = [...listfilter]; // Clone datos Lista filtrada
                } else {
                    newListaEntrega = [...listAyudas].filter(b => parseInt(b.numentrega) === (parseInt(datosEntrega.numentrega) - 1)); // Clone ultima Lista
                    // No incluir los caducados pasados
                    if (datosEntrega.caducados===0){
                        newListaEntrega = [...newListaEntrega].filter(b => b.estadoayuda.toLowerCase() !=='caducado');
                    }
                }
                // Ajustar Valores
                // TODO: Esto causa problemas... no se aún porque reemplaza o cambia los datos originales
                // creando conflicto temporal en el filtro
                newListaEntrega = [...newListaEntrega].map((item,idx) => {
                    // Es necesario clonar los objetos por que pasen por referencia, 
                    // a pesar de que se clonó todo el arreglo en líneas arriba
                    let itm = Object.assign({}, item); 
                    itm.serial = datosEntrega.numentrega + datosEntrega.fechainicio.split(' ')[0].replace(/-/g,'') + (idx + 1);
                    itm.numentrega = datosEntrega.numentrega;
                    itm.convenio = datosEntrega.numconvenio;
                    itm.tipoayuda = datosEntrega.tipoayuda;
                    itm.fechainicio = datosEntrega.fechainicio.split(' ')[0];
                    itm.fechavigencia = datosEntrega.fechavigencia.split(' ')[0];
                    itm.fechacaducidad = datosEntrega.fechacaducidad.split(' ')[0];
                    const clase_afectacion = calcularAfectacion({
                        'prestarservicio': parseInt(itm.prestarservicio)===1 ? 'SI' : 'NO',
                        'tienefmliaclub': parseInt(itm.tienefmliaclub)===1 ? 'SI' : 'NO',
                        'jefehogar': itm.jefehogar,
                        'personasacargo': itm.personasacargo,
                        'numhijos': itm.numhijos,
                        'edad': itm.edad,
                        'edadclub': itm.edadclub,
                        'asistencia': itm.asistencia,
                        'actividad': itm.actividad,
                        'categoria': itm.categoria,
                        'cupo': itm.cupo,
                    }, listCriterios);

                    itm['tipoafectacion'] = clase_afectacion.tipoafectacion;
                    itm['tipoafectacion2'] = clase_afectacion.tipoafectacion2;
                    itm['tipoafectacionescalado'] = clase_afectacion.tipoafectacionescalado;
                    itm['afectacionfinal'] = clase_afectacion.afectacionfinal;
                    itm['afectacionfinal2'] = clase_afectacion.afectacionfinal2;
                    itm['valorfinal'] = clase_afectacion.valorfinal;
                    itm['perc_afect'] = clase_afectacion.perc_afect;
                    itm['perc_afect2'] = clase_afectacion.perc_afect2;
                    itm['cupoxperc'] = clase_afectacion.cupoxperc;

                    itm['vlr_prestarservicio'] = clase_afectacion.vlr_prestarservicio;
                    itm['vlr_jefehogar'] = clase_afectacion.vlr_jefehogar;
                    itm['vlr_personasacargo'] = clase_afectacion.vlr_personasacargo;
                    itm['vlr_numhijos'] = clase_afectacion.vlr_numhijos;
                    itm['vlr_tienefmliaclub'] = clase_afectacion.vlr_tienefmliaclub;
                    itm['vlr_actividad'] = clase_afectacion.vlr_actividad;
                    itm['vlr_categoria'] = clase_afectacion.vlr_categoria;
                    itm.asistencia = Math.ceil(itm.asistencia*100);
                    itm['vlr_asistencia'] = clase_afectacion.vlr_asistencia;
                    itm['vlr_edad'] = clase_afectacion.vlr_edad;
                    itm['vlr_edadclub'] = clase_afectacion.vlr_edadclub;

                    return itm;
                });
                
                dispatch(setCovidListEntrega({listEntrega:newListaEntrega}));
                dispatch(displayCovidListEntrega({isListEntrega:false}));
            }
            
        } else {
            setError('No hay datos de entrega');
        }
    }

    const handleNumentregaChange = (evt) => {
        setNumentrega(evt.target.value);
        datosEntrega['numentrega'] = evt.target.value;
    };
    const handleNumconvenioChange= (evt) => {
        setNumconvenio(evt.target.value);
        datosEntrega['numconvenio'] = evt.target.value;
    };

    const handleTipoAyudaSelectChange = (value) => {
        setTipoayudaSelectedValue(value);
        datosEntrega['tipoayuda'] = value;
    };
    const handleTipoAyudaonKeyDown  = (evt) => {
        const keyCode = evt.keyCode || evt.which
        if (keyCode === 8 || keyCode === 46) {
            setTipoayudaSelectedValue('');
            datosEntrega['tipoayuda'] = '';
            evt.returnValue = false
            if (evt.preventDefault) evt.preventDefault()
        }
    }

    // DatePicker
    const onFechaInicioChange = (date) =>{
        if (date.preventDefault) date.preventDefault()
        if (date){
            const fechainicio = date.utc().format('YYYY-MM-DD HH:mm:ss');
            datosEntrega['fechainicio'] = fechainicio;
            setFechaInicio(date);
            // updateCovidAyudassonServer();
        }
    }
    const onFechaInicioFocusChange = ({ focused }) => {
        setFechaInicioPickerFocused(focused)
    }
    // DatePicker 
    const onFechaVigenciaChange = (date) => {
        if (date.preventDefault) date.preventDefault()
        if (date){
            const fechavigencia = date.utc().format('YYYY-MM-DD HH:mm:ss');
            // CA.fechavigencia =  moment(date.utc()).format("YYYY-MM-DD HH:mm:ss");
            datosEntrega['fechavigencia'] = fechavigencia;
            datosEntrega['fechacaducidad'] = fechavigencia; // Usualmente son las mismas
            setFechaVigencia(date);
            setFechaCaducidad(date); // Usualmente son las mismas
        }
    }
    const onFechaVigenciaFocusChange = ({ focused }) =>{
        setFechaVigenciaPickerFocused(focused)
    }
    // DatePicker 
    const onFechaCaducidadChange = (date) =>{
        if (date.preventDefault) date.preventDefault()
        if (date){
            const fechacaducidad = date.utc().format('YYYY-MM-DD HH:mm:ss');
            datosEntrega['fechacaducidad'] = fechacaducidad;
            setFechaCaducidad(date);
        }
    }
    const onFechaCaducidadFocusChange = ({ focused }) =>{
        setFechaCaducidadPickerFocused(focused)
    }

    // Caducados anteriores
    const caducadosSelectButton = (selected) => {
        setCaducadosSelected(selected);
        datosEntrega['caducados'] = selected; // === 1 ? '1' : '0';
    }
    // Datos Filtrados
    const datosfiltradosSelectButton = (selected) => {
        setDatosFiltradosSelected(selected);
        datosEntrega['datosfiltrados'] = selected; // === 1 ? '1' : '0';
    }

    if (listCriterios){
        if (listCriterios.length<=0) return (
            <></>
        );
        
        let criterios_prestarservicio = null;
        let criterios_tienefmliaclub = null;
        let criterios_cabezahogar = null;
        let criterios_actividad = null;
        let criterios_asistencia = null;
        let criterios_categoria = null;
        let criterios_edad = null;
        let criterios_edadclub = null;
        let criterios_numhijos = null;
        let criterios_personacargo = null;
        let criterios_cupo = null;
        
        if ( listCriterios['grb_criterios_prestarservicio'] && listCriterios['grb_criterios_prestarservicio'].length>0){
            criterios_prestarservicio = listCriterios['grb_criterios_prestarservicio'];
        }
        if ( listCriterios['grb_criterios_tienefmliaclub'] && listCriterios['grb_criterios_tienefmliaclub'].length>0){
            criterios_tienefmliaclub = listCriterios['grb_criterios_tienefmliaclub'];
        }
        if ( listCriterios['grb_criterios_cabezahogar'] && listCriterios['grb_criterios_cabezahogar'].length>0){
            criterios_cabezahogar = listCriterios['grb_criterios_cabezahogar'];
        }
        if ( listCriterios['grb_criterios_personacargo'] && listCriterios['grb_criterios_personacargo'].length>0){
            criterios_personacargo = listCriterios['grb_criterios_personacargo'];
        }
        if ( listCriterios['grb_criterios_numhijos'] && listCriterios['grb_criterios_numhijos'].length>0){
            criterios_numhijos = listCriterios['grb_criterios_numhijos'];
        }
        if ( listCriterios['grb_criterios_asistencia'] && listCriterios['grb_criterios_asistencia'].length>0){
            criterios_asistencia = listCriterios['grb_criterios_asistencia'];
        }
        if ( listCriterios['grb_criterios_actividad'] && listCriterios['grb_criterios_actividad'].length>0){
            criterios_actividad = listCriterios['grb_criterios_actividad'];
        }
        if ( listCriterios['grb_criterios_categoria'] && listCriterios['grb_criterios_categoria'].length>0){
            criterios_categoria = listCriterios['grb_criterios_categoria'];
        }
        if ( listCriterios['grb_criterios_edad'] && listCriterios['grb_criterios_edad'].length>0){
            criterios_edad = listCriterios['grb_criterios_edad'];
        }
        if ( listCriterios['grb_criterios_edadclub'] && listCriterios['grb_criterios_edadclub'].length>0){
            criterios_edadclub = listCriterios['grb_criterios_edadclub'];
        }
        if ( listCriterios['grb_criterios_cupo'] && listCriterios['grb_criterios_cupo'].length>0){
            criterios_cupo = listCriterios['grb_criterios_cupo'];
        }

        
        return (
            <ListaEntregaContainer>
                <div className="lista-criterios-covid-title">Nueva Lista de Entrega</div>
                <ListaEntregaOpcionesContainer>
                    { error && <div style={{color: 'red', width: '100%'}}>
                        <Alert red showIcon style={{minWidth: '100%', marginBottom: '10px'}}
                            showCloseLabel 
                            onClose={() => setError(null)}>
                            <AlertMessage>
                                Error:{' '}
                                <strong>{error}</strong>
                            </AlertMessage>
                        </Alert>
                        </div>
                    }
                    <Table striped className="lista-criterios-table-opciones">
                    <TableBody>
                        <TableRow>
                            <TableCell>Número Entrega</TableCell>
                            <TableCell>
                                <TextField minimal type="number" id="numentrega" placeholder="Número Entrega" 
                                    min={1} 
                                    defaultValue = {numentrega}
                                    onChange = {handleNumentregaChange}
                                    disabled={!isEditable} 
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Número Convenio</TableCell>
                            <TableCell>
                                <TextField minimal id="numconvenio" placeholder="Número Convenio" 
                                    defaultValue={numconvenio}
                                    onChange = {handleNumconvenioChange}
                                    disabled={!isEditable} 
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Tipo Ayuda</TableCell>
                            <TableCell>
                                <Select filterable positionFixed
                                    disabled={!isEditable} 
                                    placeholder={'Seleccione tipo ayuda'}
                                    onChange={handleTipoAyudaSelectChange}
                                    onKeyDown={handleTipoAyudaonKeyDown}
                                    value={tipoayudaSelectedValue.trim() || ''}
                                    selectedValue={tipoayudaSelectedValue.trim() || ''}
                                >
                                    {tipoAyuda.tipo.map(e => {
                                        return (
                                        <MenuItem key={e.id} value={e.name}>
                                            {e.name}
                                        </MenuItem>
                                        )
                                    })}
                                </Select>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Fecha Inicial</TableCell>
                            <TableCell>
                                <DatePicker
                                    placeholder="Fecha Inicial"
                                    id="fechaInicio"
                                    // style={{backgroundColor:'transparent !important', minWidth:'100px'}}
                                    date={fechaInicio}
                                    focused={fechaInicioPickerFocused}
                                    onDateChange={onFechaInicioChange}
                                    onFocusChange={onFechaInicioFocusChange}
                                    numberOfMonths={1}
                                    disabled={!isEditable}
                                    readOnly={true}
                                    hideInputIcon
                                    disableScroll={false}
                                    displayFormat={'YYYY-MM-DD'} // 'MMM DD YYYY'
                                    isOutsideRange={() => {}}
                                    monthYearSelectionMode="MONTH_YEAR"
                                    yearSelectDates={{"startYear" : 2020, "endYear" : new moment().year() + 1}}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Fecha de Vigencia</TableCell>
                            <TableCell>
                                <DatePicker 
                                    placeholder="Fecha Vigencia"
                                    id="fechaVigencia"
                                    date={fechaVigencia}
                                    focused={fechaVigenciaPickerFocused}
                                    onDateChange={onFechaVigenciaChange}
                                    onFocusChange={onFechaVigenciaFocusChange}
                                    numberOfMonths={1}
                                    disabled={!isEditable}
                                    readOnly={true}
                                    hideInputIcon
                                    disableScroll={false}
                                    displayFormat={'YYYY-MM-DD'} // 'MMM DD YYYY'
                                    isOutsideRange={() => {}}
                                    monthYearSelectionMode="MONTH_YEAR"
                                    yearSelectDates={{"startYear" : 2020, "endYear" : new moment().year() + 1}}
                                    // appendToBody={true}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Fecha de Caducidad</TableCell>
                            <TableCell>
                                <DatePicker
                                    placeholder="Fecha Caducidad"
                                    id="fechaCaducidad"
                                    date={fechaCaducidad}
                                    focused={fechaCaducidadPickerFocused}
                                    onDateChange={onFechaCaducidadChange}
                                    onFocusChange={onFechaCaducidadFocusChange}
                                    numberOfMonths={1}
                                    disabled={!isEditable}
                                    readOnly={true}
                                    hideInputIcon
                                    disableScroll={false}
                                    displayFormat={'YYYY-MM-DD'} // 'MMM DD YYYY'
                                    isOutsideRange={() => {}}
                                    monthYearSelectionMode="MONTH_YEAR"
                                    yearSelectDates={{"startYear" : 2020, "endYear" : new moment().year() + 1}}
                                    // appendToBody={true}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Incluir Caducados?</TableCell>
                            <TableCell>
                                <ButtonGroup isToggle>
                                    <Button  clear={caducadosSelected !== 1} disabled={!isEditable} 
                                        onClick={() => {  caducadosSelectButton(1) }}
                                    >Si</Button>
                                    <Button clear={caducadosSelected !== 0} disabled={!isEditable} 
                                        onClick={() => { caducadosSelectButton(0) }}
                                    >No</Button>
                                </ButtonGroup>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Utilizar datos del Filtro</TableCell>
                            <TableCell>
                                <ButtonGroup isToggle>
                                    <Button  clear={datosfiltradosSelected !== 1} disabled={!isEditable} 
                                        onClick={() => {  datosfiltradosSelectButton(1) }}
                                    >Si</Button>
                                    <Button clear={datosfiltradosSelected !== 0} disabled={!isEditable} 
                                        onClick={() => { datosfiltradosSelectButton(0) }}
                                    >No</Button>
                                </ButtonGroup>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                    </Table>

                    <div className="itemContainer-addbtn-criterios">
                        <Button iconPosition="before" 
                            disabled={!isEditable} 
                            id={'criterios_agregar_lista'}
                            onClick={onCreateListEntrega}
                            icon={<PlusIcon size={16} />}
                        >
                            Crear lista
                        </Button>
                    </div>
                </ListaEntregaOpcionesContainer>

                <div className="tabla-criterios-covid-header">Tablas de Criterios</div>
                <ListaEntregaCriteriosContainer>
                    <div className="tabla-criterios-covid">
                        <div className="tabla-criterios-covid-title">Cupo por Afectación</div>
                        <CriterioCupo criterios={criterios_cupo}/>
                    </div>
                    <div className="tabla-criterios-covid">
                        <div className="tabla-criterios-covid-title">Presta Servicio</div>
                        <CriterioPrestaServicio criterios={criterios_prestarservicio}/>
                    </div>
                    <div className="tabla-criterios-covid">
                        <div className="tabla-criterios-covid-title">Familiares en el Club</div>
                        <CriterioFamiliaClub criterios={criterios_tienefmliaclub}/>
                    </div>
                    <div className="tabla-criterios-covid">
                        <div className="tabla-criterios-covid-title">Cabeza de Familia</div>
                        <CriterioCabezaFamilia criterios={criterios_cabezahogar}/>
                    </div>
                    <div className="tabla-criterios-covid">
                        <div className="tabla-criterios-covid-title">Número de Hijos</div>
                        <CriterioNumeroHijos criterios={criterios_numhijos}/>
                    </div>
                    <div className="tabla-criterios-covid">
                        <div className="tabla-criterios-covid-title">Asistencia</div>
                        <CriterioAsistencia criterios={criterios_asistencia}/>
                    </div>
                    <div className="tabla-criterios-covid">
                        <div className="tabla-criterios-covid-title">Personas a Cargo</div>
                        <CriterioPersonasaCargo criterios={criterios_personacargo}/>
                    </div>
                    <div className="tabla-criterios-covid">
                        <div className="tabla-criterios-covid-title">Edad</div>
                        <CriterioEdad criterios={criterios_edad}/>
                    </div>
                    <div className="tabla-criterios-covid">
                        <div className="tabla-criterios-covid-title">Tiempo Club</div>
                        <CriterioEdadClub criterios={criterios_edadclub}/>
                    </div>
                    <div className="tabla-criterios-covid">
                        <div className="tabla-criterios-covid-title">Actividad</div>
                        <CriterioActividad criterios={criterios_actividad}/>
                    </div>
                    <div className="tabla-criterios-covid">
                        <div className="tabla-criterios-covid-title">Categoría</div>
                        <CriterioCategoria criterios={criterios_categoria}/>
                    </div>
                    
                </ListaEntregaCriteriosContainer>
            </ListaEntregaContainer>
        );

    } else { 
        return (
            <></>
        );
    }
    

}

export default ListaEntrega;

