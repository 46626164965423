// React
import React, {Fragment,useState, useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";
import ContentEditable from 'react-contenteditable';
import {capitalize, highlightAll, 
    disableNewlines, 
    pastePlainText
} from '../../utils/utils';
import { fetchUsersData,
    // updateUserGRB,
    // insertUsersGRB,
    // deleteUsersGRB,
    // serverMensaje
} from '../../redux/reducers/users';

import './users-style.css';

// import {showMsgToaster} from '../ShowMesg';
import {ToastContainer} from 'calcite-react/Toaster'; //notify
import Alert, {AlertMessage} from 'calcite-react/Alert';
// import Switch from 'calcite-react/Switch';

// Styled & Motion Components
// import styled from "styled-components";

import Button from 'calcite-react/Button';

import SaveIcon from 'calcite-ui-icons-react/SaveIcon';
// import TrashIcon from 'calcite-ui-icons-react/TrashIcon';
import UserPlusIcon from 'calcite-ui-icons-react/UserPlusIcon';
import EditUserIcon from 'calcite-ui-icons-react/PencilIcon';

const ModuleUsersGRB = props => {
    const dispatch = useDispatch();
    const users = useSelector(state => state.users);
    const isEditable = (users.user.tipo==='ADMINISTRADOR' && users.user.role==='A' && users.user.permisos==='A') ? true : false;
    const canView = (users.user.areatrabajo==='ADMINISTRATIVA' || users.user.areatrabajo==='TECNOLOGÍA' ) ? true : false;
    
    const listUsers = useSelector(state => state.users.listUsers);
    // const mensaje = useSelector(state => state.users.mensaje);
    const [error, setError] = useState(null);
    const [errorenAgregar, setErrorenAgregar] = useState(null);
    const [isAddUserDisplay, setIsAddUserDisplay] = useState(false);
    const [isEditUserDisplay, setIsEditUserDisplay] = useState(false);
    
    const [userValues,] = useState( //setUserValues
        {
            "id":"",
            "nombre":"",
            "usuario":"",
            "passwd": "",
            "email":"",
            "regIP": "",
            "activo":"",
            "estado":"",
            "tipo":"",
            "nota": "",
            "foto":"",
            "claveAPI":"",
            "areatrabajo":"",
            "role": "",
            "permisos": "",
            "creado": "",
            "actualizado":"",
            "version":""
        }
    );
    const [itemsUser, setItemsUser] = useState([
        {"key":"id", "label": "Id", "value": userValues['id']},
        {"key":"nombre", "label": "Nombres y Apellidos completos", "value": userValues['nombre']},
        {"key":"usuario", "label": "Usuario", "value": userValues['usuario']},
        {"key":"passwd", "label": "Contraseña", "value": userValues['passwd']},
        {"key":"email", "label": "Correo Electrónico", "value": userValues['email']},
        {"key":"regIP", "label": "Registro IP", "value": userValues['regIP']},
        {"key":"activo", "label": "Activo", "value": userValues['activo']},
        {"key":"estado", "label": "Estado", "value": userValues['estado']},
        {"key":"tipo", "label": "Tipo de Usuario", "value": userValues['tipo']},
        {"key":"foto", "label": "Foto perfil", "value": userValues['foto']},
        {"key":"claveAPI", "label": "Clave para API", "value": userValues['claveAPI']},
        {"key":"areatrabajo", "label": "Area de Trabajo", "value": userValues['areatrabajo']},
        {"key":"role", "label": "Role", "value": userValues['role']},
        {"key":"permisos", "label": "Permisos", "value": userValues['permisos']},
        {"key":"creado", "label": "Fecha creado", "value": userValues['creado']},
        {"key":"nota", "label": "Nota breve", "value": userValues['nota']},
        // {"key":"actualizado", "label": "actualizado", "value": userValues['actualizado']},
        // {"key":"version", "label": "Version", "value": userValues['version']},
    ]);
    
    useEffect(() => {
        if (listUsers===null || listUsers===undefined || listUsers.length<=0){
            dispatch(fetchUsersData({token: users.user.claveAPI}));
        }

    }, [users.user.claveAPI, listUsers, dispatch]);

    useEffect(() => {
        if (listUsers===null || listUsers===undefined || listUsers.length<=0)
            return
        
        // if (listUsers!==null){
        //     console.log(listUsers);
        // }

        // if (mensaje.usuarios && mensaje.usuarios!=='') {

        //     if (typeof mensaje.usuarios === 'string' && mensaje.usuarios.toLowerCase().includes('error')){
        //         showMsgToaster('error', 'Ocurrió un fallo en el servidor', mensaje.usuarios);
        //         mensaje.usuarios='';
        //     } 
        //     else {
        //         // showMsgToaster('success', 'Usuarios', 'Lista de Usuario guardada con exito!');
        //         showMsgToaster('success',"Usuarios", mensaje.usuarios.split('.')[0]);
        //         // Update Id for edition
        //         // Cambiar el id por el que devuelve el servidor o transacción de MySQL
        //         // if (isDisplayAgregarUsuario) insertUsuario('-1', 'id', String(mensaje.usuarios.split('.')[1]));
        //         // mensaje.usuarios='';
        //         // dispatch(serverMensaje({usuarios:''}));
        //         // // Si los datos del Beneficiario fueron actualizados es necesario cargarlos de nuevo una vez 
        //         // // guardados en el servidor
        //         // dispatch(resetBeneficiarioGolf({listGolf: null})); // Necesario para reflejar los cambios
        //         // updateListaUsuariosxFecha(fechaUsuario);
        //     }
            
        // }

    }, [listUsers, dispatch]);

    // const updateDataonServer = (action, usuario) => {
    //     console.log(usuario);
    //     switch (action) {
    //         case 'insert':
    //             // dispatch(insertListaUsuariosHistoricoGolf( { token: users.user.claveAPI, usuario:usuario}));
    //             break;
    //         case 'remove':
    //             // dispatch(deleteListaUsuariosHistoricoGolf( { token: users.user.claveAPI, usuario:usuario}));
    //             break;
    //         case 'update':
    //             // Actualizar solo el registro necesario, para no enviar toda la tabla
    //             // dispatch(updateListaUsuariosHistoricoGolf( { token: users.user.claveAPI, usuario:usuario}));
    //             break;
    //         default: // update
    //             console.log('Acción no disponible');
    //             break;
    //     }
        
    // }

    const updateItemsUser = (f, v) => {
        const newitemsUser = itemsUser.map((itm, index) => {
            if (itm.key===f){
                itm.value = v;
            }
            return itm;
        });
        setItemsUser(newitemsUser);
    };

    // const updateUsuarios = (idx, f, v) => {
    //     if (R){
    //         updateUsuario(R.map((itm, ix) => {
    //             if (ix===idx){
    //                 itm[f] = v;
    //             }
    //             return itm;
    //         }));
    //     }
    // };

    const replaceSomeText = (s) =>{
        return s
            .replace(/&nbsp;/g, ' ')
            .replace(/<br>/g, '')
            .replace(/&amp;/g, '&')
            .replace(/&gt;/g, '>')
            .replace(/&lt;/g, '<').trim()
    }
    const validateInput = (f, v, final) => {
        // const regex_onlynum = /^[0-9]*$/i; ///[0-9,]|\./;
        const regex_onlynum = /[0-9,]|\./;
        // const regex_onlyfloat = /^[0-9,]|\./; // /^[0-9.]*$/i;
        const regex_nombres = /^['`´a-zA-ZÀ-ÿ\u00C0-\u017F\s]+$/;
        let new_val = null;
        let err = null;
        switch (f) {
            case 'id':
                // new_val = replaceSomeText(v);
                // if (!regex_onlynum.test(new_val)) {
                //     err = 'Dígite solo números válidos';
                //     new_val = null;
                // }
                // if (new_val===null) new_val = '';
                new_val = null;
                break;
            case 'regIP':
                new_val = null;
                break;
            case 'nombre':
                new_val = replaceSomeText(v).substr(0,32);
                if (!regex_nombres.test(new_val)) {
                    err = 'Dígite carácteres válidos';
                    new_val = null;
                }
                break;
            case 'usuario':
                new_val = replaceSomeText(v).substr(0,32);
                if (!regex_nombres.test(new_val)) {
                    err = 'Dígite carácteres válidos';
                    new_val = null;
                }
                break;
            case 'passwd':
                new_val = v.substr(0,32);
                if (final)
                    new_val = replaceSomeText(v).substr(0,32);
                break;
            case 'email':
                new_val = v.substr(0,32);
                if (final)
                    new_val = replaceSomeText(v).substr(0,32);
                break;
            case 'activo':
                new_val = replaceSomeText(v).substr(0,1); //1
                if (!regex_onlynum.test(new_val)) {
                    err = 'Dígite solo números válidos';
                    new_val = null;
                } else if(parseInt(new_val)>1){
                    err = 'Dígite un número válido';
                    new_val = null;
                }
                break;
            case 'tipo':
                new_val = v.substr(0,32);
                if (final)
                    new_val = replaceSomeText(v).substr(0,32);
                break;
            case 'estado':
                new_val = v.substr(0,32);
                if (final)
                    new_val = replaceSomeText(v).substr(0,32);
                break;
            case 'foto':
                new_val = v.substr(0,200);
                if (final)
                    new_val = replaceSomeText(v).substr(0,200);
                break;
            case 'claveAPI':
                new_val = v.substr(0,64);
                if (final)
                    new_val = replaceSomeText(v).substr(0,64);
                break;
            case 'areatrabajo':
                new_val = v.substr(0,32);
                if (final)
                    new_val = replaceSomeText(v).substr(0,32);
                break;
            case 'role':
                new_val = v.substr(0,32);
                if (final)
                    new_val = replaceSomeText(v).substr(0,32);
                break;
            case 'permisos':
                new_val = v.substr(0,32);
                if (final)
                    new_val = replaceSomeText(v).substr(0,32);
                break;
            case 'nota':
                new_val = replaceSomeText(v).substr(0,255);
                break;
            case 'creado': //0000-00-00 00:00:00
                // new_val = replaceSomeText(v).substr(0,30);
                // if (final && new_val===''){
                //     new_val = replaceSomeText(v).substr(0,30);
                //     err = 'Dígite una fecha de creado válida';
                //     new_val = null;
                // }
                new_val = null;
                break;
            case 'actualizado': //0000-00-00 00:00:00
                new_val = v.substr(0,30);
                if (final && new_val===''){
                    new_val = replaceSomeText(v).substr(0,30);
                    err = 'Dígite una fecha de actualización válida';
                    new_val = null;
                }
                break;
            case 'version': //0000-00-00 00:00:00
                new_val = v.substr(0,30);
                if (final && new_val===''){
                    new_val = replaceSomeText(v).substr(0,30);
                    err = 'Dígite una fecha de version válida';
                    new_val = null;
                }
                break;
            
            default:
                break;
        }

        if (isEditUserDisplay) setError(err);
        if (isAddUserDisplay) setErrorenAgregar(err);
        return new_val
    }
    const handleChange = evt => {
        const field = evt.currentTarget.id.split('_')[1];
        const new_val = validateInput(field, evt.target.value, false);
        if(new_val){
            userValues[field] = new_val
        };
    };
    const handleBlur = evt => {
        const val = evt.currentTarget.innerHTML;
        const field = evt.target.id.split('_')[1];
        let new_val = validateInput(field, val, true);
        // const regex_email = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        // if (field==='email' && !regex_email.test(new_val)){
        //     setError('Correo eléctronico o email no válido');
        //     new_val = null;
        // }
        if(new_val || new_val===''){
            // if (field==='fechareserva') {
            //     new_val = moment(fechaUser).format("YYYY-MM-DD HH:mm:ss").split(' ')[0] + ' '+ new_val;
            //     userValues[field] = new_val;
            //     updateItemsUser(field, new_val.split(' ')[1]);
            // } else {
                if (Object.keys(userValues).includes(field)){
                    userValues[field] = new_val;
                }
                updateItemsUser(field, new_val);
            // }
        };
    };

    const onClickAddUser = () =>{
        console.log('Agregar usuario');
        setIsAddUserDisplay(true);
    }
    const onClickEditUser = (id) =>{
        const selectedUser = [...listUsers].filter(u => u.id===id);
        if (selectedUser && selectedUser.length>0){
            const newitemsUser = itemsUser.map((itm, index) => {
                itm.value = selectedUser[0][itm.key];
                return itm;
            });
            setItemsUser(newitemsUser);
            setIsEditUserDisplay(true);
        }
    }
    const onClickSaveUser = () =>{
        setIsAddUserDisplay(false);
    }
    const onClickUpdateUser = () =>{
        setIsEditUserDisplay(false);
    }


if (listUsers)
    return (
        <Fragment>
            <ToastContainer />
            { isAddUserDisplay && 
                <div className="users-additional-container">
                    <div className="users-editform-title">Crear Nuevo Usuario</div>
                    <div className="users-addform">
                        { errorenAgregar && <div style={{color: 'red', width: '100%'}}>
                                <Alert red showIcon style={{minWidth: '100%', marginBottom: '10px'}}
                                    showCloseLabel 
                                    onClose={() => setErrorenAgregar(null)}>
                                    <AlertMessage>
                                        Error:{' '}
                                        <strong>{errorenAgregar}</strong>
                                    </AlertMessage>
                                </Alert>
                            </div> 
                        }
                        {/* ITEMS */}
                        {
                            itemsUser.map((itm, index) => {
                                return (<div key={index} className="itemContainer">
                                    <div className="itemContainer-attr" 
                                        style={{backgroundColor:(index % 2 === 0) ? '#f8f8f8': '#fff'}}
                                    >{itm.label}</div>
                                    <div className="itemContainer-value" 
                                        style={{backgroundColor:(index % 2 === 0) ? '#f8f8f8': '#fff'}}
                                    >
                                    {   
                                        (itm.key==='id' || itm.key==='creado')
                                        ?
										<ContentEditable
                                            id={'adduser_'+itm.key}
                                            style={{color:'#ccc'}}
											html={itm.value || ''} // innerHTML of the editable div
											className='itemContainer-value-long'
											disabled={true}      // use true to disable editing
											tagName='div' // Use a custom HTML tag (uses a div by default)
										/>
                                        :
                                        <ContentEditable
											id={'adduser_'+itm.key}
											html={itm.value || ''} // innerHTML of the editable div
											className='itemContainer-value-long'
											// disabled={!isEditable}      // use true to disable editing
											onPaste={pastePlainText} // En caso se copie y pegue de otra pagina de internet
											onChange={handleChange} // handle innerHTML change
											onKeyPress={disableNewlines}  
											onFocus={highlightAll}
											onBlur={handleBlur}
											tagName='div' // Use a custom HTML tag (uses a div by default)
										/>
                                    }

                                    </div>
                                </div>)
                            })
                        }

                    </div>
                    <Button iconPosition="before" 
                        disabled={!isEditable} 
                        id={'user_newUser'}
                        onClick={onClickSaveUser}
                        icon={<SaveIcon size={16} />}
                    >
                        Guardar Cambios
                    </Button>
                </div>
            }

            { isEditUserDisplay && 
                <div className="users-additional-container">
                    <div className="users-editform-title">Editar Usuario</div>
                    <div className="users-addform">
                        {error && <div style={{color: 'red', width: '100%'}}>
                                <Alert red showIcon style={{minWidth: '100%', marginBottom: '10px'}}
                                    showCloseLabel 
                                    onClose={() => setError(null)}>
                                    <AlertMessage>
                                        Error:{' '}
                                        <strong>{error}</strong>
                                    </AlertMessage>
                                </Alert>
                            </div> 
                        }
                        {/* ITEMS */}
                        {
                            itemsUser.map((itm, index) => {
                                return (<div key={index} className="itemContainer">
                                    <div className="itemContainer-attr" 
                                        style={{backgroundColor:(index % 2 === 0) ? '#f8f8f8': '#fff'}}
                                    >{itm.label}</div>
                                    <div className="itemContainer-value" 
                                        style={{backgroundColor:(index % 2 === 0) ? '#f8f8f8': '#fff'}}
                                    >
                                    {   
                                        (itm.key==='id' || itm.key==='creado')
                                        ?
										<ContentEditable
                                            id={'edituser_'+itm.key}
                                            style={{color:'#ccc'}}
											html={itm.value || ''} // innerHTML of the editable div
											className='itemContainer-value-long'
											disabled={true}      // use true to disable editing
											tagName='div' // Use a custom HTML tag (uses a div by default)
										/>
                                        :
                                        <ContentEditable
											id={'edituser_'+itm.key}
											html={itm.value || ''} // innerHTML of the editable div
											className='itemContainer-value-long'
											// disabled={!isEditable}      // use true to disable editing
											onPaste={pastePlainText} // En caso se copie y pegue de otra pagina de internet
											onChange={handleChange} // handle innerHTML change
											onKeyPress={disableNewlines}  
											onFocus={highlightAll}
											onBlur={handleBlur}
											tagName='div' // Use a custom HTML tag (uses a div by default)
										/>
                                    }

                                    </div>
                                </div>)
                            })
                        }
                    </div>
                    <Button iconPosition="before" 
                        disabled={!isEditable} 
                        id={'user_newUser'}
                        onClick={onClickUpdateUser}
                        icon={<SaveIcon size={16} />}
                    >
                        Guardar Cambios
                    </Button>
                </div>
            }
            
            { !isAddUserDisplay && !isEditUserDisplay && 
                <div>
                    {canView && <div className="users-toolbar-container">
                        <div className="users-list-btnAgregar">
                            <Button inline small iconPosition="before" 
                                disabled={!isEditable} 
                                id={'user_agregar'}
                                onClick={onClickAddUser}
                                icon={<UserPlusIcon size={16} />}
                            >
                                Nuevo Usuario
                            </Button>
                        </div>
                    </div>}
                    <div className="users-container">
                        <div className="users-container-list">
                            {
                                listUsers.map((U) => {
                                    // const foto = U.foto==='' ? require('../../assets/team/usuario.jpg') : require('../../assets/team/' + U.foto);
                                    return <div className="users-list" key={U.id}>
                                        <div className="users-list-foto">
                                            {/* <img src={foto} alt=" " /> */}
                                            <img src={process.env.PUBLIC_URL + '/imgs/team/' + U.foto} alt=" " />
                                            <div className="users-list-areatrabajo">{U.areatrabajo}</div>
                                        </div>
                                        <div className="users-list-desc">
                                            <div className="users-list-nombre">{capitalize(U.nombre, true)}</div>
                                        </div>
                                        {canView && <div className="users-list-btnEditar">
                                            <Button fullWidth iconPosition="before" 
                                                disabled={!isEditable} 
                                                id={'user_editar'}
                                                onClick={() => onClickEditUser(U.id)}
                                                icon={<EditUserIcon size={16} />}
                                            >
                                                Editar
                                            </Button>
                                        </div>}
                                    </div>

                                })
                            }
                        </div>
                    </div>
                </div>
            }

            

        </Fragment>
    );

return (<></>)
}
  
export default ModuleUsersGRB;