// React
import React, { Fragment,useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchBeneficiariosData, displayBeneficiariosFilter, moduleBENEFICIARIOSLoaded,
    // resetSelectedBeneficiarioForPerfil, resetfilterBeneficiariosData,
    // selectBeneficiario 
} from '../redux/reducers/beneficiarios';

import * as XLSX from 'xlsx';
import moment from 'moment';
import 'moment/locale/es-us';

import Button from 'calcite-react/Button';
// load lazy data
import DisplayListBeneficiarios from './beneficiario/displayListBeneficiarios';
import BenefDetailsContainer from './beneficiario/BeneficiarioDetails';

import ListButtonIcon from 'calcite-ui-icons-react/ListButtonIcon';
import FilterIcon from 'calcite-ui-icons-react/FilterIcon';
import DownloadIcon from 'calcite-ui-icons-react/DownloadIcon';

import Search from 'calcite-react/Search';

import Tabs, {
    TabNav,
    TabTitle,
    TabContents,
    TabSection
} from 'calcite-react/Tabs';

// Styled & Motion Components
import styled from "styled-components";

const FiltroButton = styled.div`
    // float: left;
    position: relative;
    margin-bottom: 2px;
    margin-right: 2px;
    top: 0px;
    background: #fff;
    width: 41px;
    height: 40px;
    border: 1px solid #ccc;
    cursor: pointer;

    &:hover{
        background: #f8f8f8;
    }

    /* Extra large devices (large laptops and desktops, 1200px and up) */
    @media only screen and (max-width: 1200px) {
        
    } 

    /* Large devices (laptops/desktops, 992px and up) */
    @media only screen and (max-width: 992px) {
        
    }

    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (max-width: 768px) {
        
    }

    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (max-width: 600px) {
        max-width: 150px;
    }

    /* Extra small devices (phones, 400px and down) */
    @media only screen and (max-width: 400px) {
        
    }

`;
const FiltroIcon = <FilterIcon style={{ width: '20px', height: '20px', marginTop: '10px', color:'green' }} />

const ToolBarOptions = styled.div`
    position: relative;
    margin-bottom: 2px;
    top: 0px;
    background: #fff;
    width: 100%;
    height: 40px;
    border: 1px solid #ccc;
    cursor: pointer;
    text-align: left;

    &:hover{
        // background: #f8f8f8;
    }

    /* Extra large devices (large laptops and desktops, 1200px and up) */
    @media only screen and (max-width: 1200px) {
        
    } 

    /* Large devices (laptops/desktops, 992px and up) */
    @media only screen and (max-width: 992px) {
        
    }

    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (max-width: 768px) {
        
    }

    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (max-width: 600px) {
        display:none;
    }

    /* Extra small devices (phones, 400px and down) */
    @media only screen and (max-width: 400px) {
        display:none;
    }

`;

const SearchBeneficiario = styled.div`
    position: relative;
    float: right;
    margin-right: 4px;
    top: -4px;
    height:1.9rem;

    /* Extra large devices (large laptops and desktops, 1200px and up) */
    @media only screen and (max-width: 1200px) {
        
    } 

    /* Large devices (laptops/desktops, 992px and up) */
    @media only screen and (max-width: 992px) {
        
    }

    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (max-width: 768px) {
        max-width: 150px;
    }

    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (max-width: 600px) {
        max-width: 150px;
    }

    /* Extra small devices (phones, 600px and down) */
    @media only screen and (max-width: 600px) {
        max-width: 150px;
    }

`;

const BeneficiarioDetalleContainer = styled.div`
    z-index:100;
    display: flex;
    position: absolute;
    left: 0px;
    top: 0px;
    width:100%;
    height:100%;
    padding:0px;
    margin:0px;
    background-color: #ffffffa3;
`;

const ModuleBeneficiariosGRB = props => {
    const users = useSelector(state => state.users);
    const isAdmin = (users.user.tipo==='ADMINISTRADOR' && users.user.role==='A' && users.user.permisos==='A') ? true : false;
    const selectedProfile = useSelector(state => state.beneficiarios.selectedProfile);
    const listBenef = useSelector(state => state.beneficiarios.listBenef);
    const filter = useSelector(state => state.beneficiarios.filter);
    const isFilterShow = useSelector(state => state.beneficiarios.isFilterShow);
    const [filtroBeneficiario,setFiltroBeneficiario] = useState([]);
    const [isFiltered,setIsFiltered] = useState(false);
    const dispatch = useDispatch();
    const [activeTabIndex,setTabIndex] = useState(0);
    
    const [inputValue,setInputValue] = useState('');
    const [selectedItem,setSelectedItem] = useState('');
    const [searchItems,setSearchItems] = useState([]);
    // const dataSourceConfig = { label: 'label', value: 'id', }
    // const capitalize = (str, lower = false) => (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, match => match.toUpperCase());
    
    const onTabChange = (index) => {
        setTabIndex(index);
    }

    useEffect(() => {
        if (!listBenef || listBenef.length<=0)
            dispatch(fetchBeneficiariosData({token: users.user.claveAPI}));

        return () => {
            // executed when unmount
            // console.log('Saliendo del Modulo Beneficiarios');
            setIsFiltered(false);
            setFiltroBeneficiario([]);
            // dispatch(displayBeneficiariosFilter({isFilterShow:false}));
            // dispatch(resetSelectedBeneficiarioForPerfil());
            // dispatch(resetfilterBeneficiariosData());
            
        }
    }, [users.user.claveAPI, listBenef, dispatch]);

    useEffect(() => {
        let datos = [];
        if (filter && filter.length>0){
            setIsFiltered(true);
            datos = [...filter];
            setFiltroBeneficiario(filter);
        } else {
            datos = [...listBenef];
            if (!isFiltered)
                setFiltroBeneficiario(listBenef);
        }
        
        let cantidad = datos.length;
        let items_0 = datos.map((b, idx) => {
            return (
            {
                "id": idx + "-" + b.docId,
                "label": b.apellidos.trim() +' '+ b.nombres.trim(),
            }
            );
        })
        let items_1 = datos.map((b, idx) => {
            return (
            {
                "id": (cantidad+idx+1) + "-" + b.docId,
                "label": b.docId.trim(),
            }
            );
        })
        let items_2 = datos.map((b, idx) => {
            return (
                {
                    "id": (cantidad*2+idx+1) + "-" + b.docId,
                    "label": b.alias.trim()
                }
            );
        })
        items_0 = items_0.filter(itm => (itm.id!==undefined || itm.label!==undefined));
        items_1 = items_1.filter(itm => (itm.id!==undefined || itm.label!==undefined));
        items_2 = items_2.filter(itm => (itm.id!==undefined || itm.label!==undefined));
        const items = [...items_0.concat(items_1, items_2)]; //items_0.filter(itm => itm!==undefined);
        setSearchItems(items);

        if (listBenef && listBenef.length>0)
            dispatch(moduleBENEFICIARIOSLoaded({loaded:true}));
        
    }, [listBenef, filter, isFiltered, dispatch]);

    // Search 
    const searchChanged = (e) => {
        setSelectedItem(e)
    }
    const clearSearch = () => {
        setInputValue('');
        setSelectedItem('');
        setIsFiltered(false);
        setFiltroBeneficiario(listBenef);
    }

    const getBeneficiarioByDocId = (docId) => {
        if (docId && docId!==null && docId!==''){
            setIsFiltered(true);
            setFiltroBeneficiario(listBenef.filter(b => b.docId === docId));
        } 
    };
    
    const onUserAction = (inputValue, selectedItemVal) => {
        setInputValue(inputValue);
        setSelectedItem(selectedItemVal);
        if (selectedItemVal && selectedItemVal.id){
            const doc = selectedItemVal['id'].split('-')[1];
            getBeneficiarioByDocId(doc)
        }
    }

    const onClickToggleFilter = () => {
        dispatch(displayBeneficiariosFilter({isFilterShow:!isFilterShow}));
    }

    const onBtnExportaraExcel = (evt) =>{
        evt.preventDefault();
        evt.stopPropagation();
        // Remover algunos campos no necesarios o sencibles
        let data2excel = [];
        console.log(filtroBeneficiario);
        filtroBeneficiario.forEach((itm, ix) => {
            data2excel.push({
                "No. ECASE": itm.id,
                "NOMBRES": itm.nombres,
                "APELLIDOS": itm.apellidos,
                "ALIAS": itm.alias,
                // "passwd": itm.passwd,
                "E-MAIL": itm.email,
                "DOC. IDENTIDAD": itm.docId,
                "FECHA NACIMIENTO": itm.fechanacimiento,
                "EDAD": itm.edad,
                "FECHA INGRESO": itm.fecha,
                "No AÑOS CLUB": itm.edadclub,
                "ACTIVO": itm.activo==='1' ? 'SI' : 'NO',
                "ESTADO": itm.estado, 
                "ACTIVIDAD": itm.actividad,
                "CATEGORIA": itm.categoria,
                // "FOTO": itm.foto,
                // "claveAPI": itm.claveAPI,
                "CELULAR": itm.celular,
                "PRESTANDO EL SERVICIO": itm.prestarservicio==='1' ? 'SI' : 'NO',
                "SANCIONADO": itm.sancionbenef==='1' ? 'SI' : 'NO',
                "ACTUALIZACION": itm.actualizacion
                // "VERSION": itm.version
            });
        });
        /* make the worksheet */
        var ws = XLSX.utils.json_to_sheet(data2excel);
        /* add to workbook */
        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Beneficiarios");
        /* generate an XLSX file */
        XLSX.writeFile(wb, "Beneficiarios_"+moment().format("YYYY-MM-DD")+".xlsx");

    }
      
    if (listBenef.length>0)
    return (
        <Fragment>
            {selectedProfile && 
                <BeneficiarioDetalleContainer>
                    <BenefDetailsContainer />
                </BeneficiarioDetalleContainer>
            }

            <div className="benef-toptoolbar-container">
                    <FiltroButton onClick={onClickToggleFilter}>
                        {FiltroIcon}
                    </FiltroButton>
                    {isAdmin && <ToolBarOptions>
                        {/* <div className="benef-toptoolbar-opts-exportar"></div> */}
                        <Button transparent small iconPosition="before" 
                            style={{top:'2px',color:'#009937'}}
                            onClick={onBtnExportaraExcel}
                            icon={<DownloadIcon size={16} />}
                        >
                            Exportar a Excel
                        </Button>
                        
                    </ToolBarOptions>
                    }
                </div>

            <SearchBeneficiario>
                <Search
                    virtualized
                    virtualizedMenuWidth={250}
                    placeholder='Buscar...' 
                    inputValue={inputValue}
                    selectedItem={selectedItem}
                    items={searchItems}
                    dataSourceConfig={{ label: 'label', value: 'id' }}
                    minimal
                    appendToBody
                    onChange={searchChanged}
                    onUserAction={onUserAction}
                    onRequestClear={clearSearch}
                />
            </SearchBeneficiario>
            
            <Tabs
                onTabChange={onTabChange}
                activeTabIndex={activeTabIndex}
            >
                <TabNav>
                    <TabTitle><ListButtonIcon size={16} color={'green'} /></TabTitle>
                </TabNav>
                <TabContents>
                <TabSection>
                    {filtroBeneficiario.length>0 && 
                        <DisplayListBeneficiarios 
                            isFiltered={isFiltered}
                            listBenef={filtroBeneficiario} />}
                </TabSection>
                </TabContents>
            </Tabs>

        </Fragment>
    );
    return (<></>)
}
  
export default ModuleBeneficiariosGRB;