import React from "react";
// import { compose } from "recompose"; // withProps, lifecycle, withState, withHandlers, withState
import {
    withGoogleMap,
    GoogleMap,
    // StreetViewPanorama,
    // OverlayView,
    Marker,
    withScriptjs,
    // DirectionsRenderer
  } from "react-google-maps";
// import Geocode from "react-geocode";

const HomeIcon = 'home.png';

// GEOCODE
// const initGeocoder = (mapkey) =>{
//     // set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
//     Geocode.setApiKey(mapkey);
//     // set response language. Defaults to english.
//     Geocode.setLanguage("es");
//     // set response region. Its optional.
//     // A Geocoding request with region=co (Colombia) will return the Colombian city.
//     Geocode.setRegion("co");
//     // Enable or disable logs. Its optional.
//     // Geocode.enableDebug(); 
// }

// Map
// Get address from latidude & longitude.
// const getAdress = (lat, lng) => {
//     Geocode.fromLatLng(lat, lng).then(
//         response => {
//             const address = response.results[0].formatted_address;
//             console.log(address);
//             // if (address) return address;
//         }, error => {
//             console.error(error);
//         }
//     );
// }
// Get latidude & longitude from address.
// const getLatLngfromAdress = (address) => {
//     Geocode.fromAddress(address).then(
//         response => {
//             console.log(response.results[0]);
//             const { lat, lng } = response.results[0].geometry.location;
//             console.log("getLatLngfromAdress",lat, lng);
//             return {lat:lat, lng:lng}
//         }, error => {
//             console.error(error);
//         }
//     );
// }

export default class Map extends React.Component { //React.PureComponent
    constructor(props) {
        super(props);
        this.state = {
            // isMarkerShown: props.isMarkerShown,
            // configMap: props.configMap,
            tipoPredio: props.tipoPredio,
            ciudad: props.ciudad,
            // direccion: props.direccion,
            // mapTypeId: props.mapTypeId || props.tipoPredio.toLowerCase()==='urbano' ? 'roadmap' : 'satellite',
            zoom:props.zoom,
            // // defaultCenter: props.defaultCenter,
            markerPosition: props.markerPosition,
            // onMarkerDragEnd: props.onMarkerDragEnd,
        }

        // this.onMarkerDragEnd = props.onMarkerDragEnd;
        // this.delayedShowMarker = this.delayedShowMarker.bind(this);
        // this.handleMarkerClick = this.handleMarkerClick.bind(this);

    }
    
    componentWillUnmount(){
        console.log('desmontando el mapa...');
    }
    // componentDidMount() {
    //     //   this.delayedShowMarker()
    //     console.log('Marker position', this.state.markerPosition);
    // }
    
    componentWillReceiveProps(nextProps) {
        // console.log('Esto se ejecutará primero', nextProps.tipoPredio);
        // this.setState({
        //     tipoPredio: nextProps.tipoPredio,
        // })

        // if (nextProps.ciudad.toLowerCase() !== this.state.ciudad.toLowerCase()) {
        //     console.log('nextProps componentWillReceiveProps', nextProps.ciudad);
        //     this.setState({
        //         markerPosition: getLatLngfromAdress(nextProps.ciudad + ', Colombia'),
        //         zoom: 12,
        //     });
        // }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.tipoPredio.toLowerCase() !== this.state.tipoPredio.toLowerCase()) {
            console.log('tipo de predio cambió', this.props.tipoPredio !== this.state.tipoPredio);
            return true
        }
        if (nextState.ciudad.toLowerCase() !== this.state.ciudad.toLowerCase()) {
            console.log('ciudad cambió');
            return true
        }
        // if (this.props.direccion.toLowerCase() !== this.state.direccion.toLowerCase()) {
        //     console.log('dirección cambió');
        //     return true
        // }
        // if (this.props.markerPosition !== this.state.markerPosition) {
        //     return true
        // }
        // if (this.props.markerPosition !== this.state.markerPosition) {
        // console.log(this.props.markerPosition.lat);
        // console.log(this.props.markerPosition.lng);
        // console.log(nextProps.markerPosition.lat);
        // console.log(nextProps.markerPosition.lng);
        // console.log(this.state.markerPosition.lat);
        // console.log(this.state.markerPosition.lng);
        // console.log(nextState.markerPosition.lat);
        // console.log(nextState.markerPosition.lng);
        // console.log(parseFloat(this.props.markerPosition.lat).toFixed(6));
        // console.log(parseFloat(this.props.markerPosition.lng).toFixed(6));
        // console.log(parseFloat(this.state.markerPosition.lat).toFixed(6));
        // console.log(parseFloat(this.state.markerPosition.lng).toFixed(6));
        // const latnext = (Math.round(parseFloat(nextState.markerPosition.lat)*1000000)/1000000);
        // const lngnext = (Math.round(parseFloat(nextState.markerPosition.lng)*1000000)/1000000);
        // const latprev = (Math.round(parseFloat(this.state.markerPosition.lat)*1000000)/1000000);
        // const lngprev = (Math.round(parseFloat(this.state.markerPosition.lng)*1000000)/1000000);
        // if (latnext!==latprev){
        //     console.log('Latitudes dif: ', latnext, latprev);
        //     return true;
        // }
        // if (lngnext!==lngprev){
        //     console.log('Longitudes dif: ', lngnext, lngprev);
        // }

        // if ((parseFloat(this.props.markerPosition.lat).toFixed(6) !== parseFloat(this.state.markerPosition.lat).toFixed(6)) ||
        //    (parseFloat(this.props.markerPosition.lng).toFixed(6) !== parseFloat(this.state.markerPosition.lng).toFixed(6)) )
        // {
        //     // console.log('shouldComponentUpdate nextProps, nextState', nextProps, nextState);
        //     console.log('Lat',parseFloat(this.props.markerPosition.lat).toFixed(6), nextState.markerPosition.lat);
        //     console.log('Lng',parseFloat(this.props.markerPosition.lng).toFixed(6), nextState.markerPosition.lng);
        //     return true
        // } 
        return false
    }

    // componentDidUpdate(prevProps) {
        
    //     // Typical usage (don't forget to compare props):
    //     if (this.props.ciudad.toLowerCase() !== prevProps.ciudad.toLowerCase()) {
    //         console.log('prevProps componentDidUpdate', prevProps.ciudad);
    //         // this.setState({
    //         //     markerPosition: getLatLngfromAdress(this.props.ciudad + ', Colombia'),
    //         //     zoom: 12,
    //         // });
    //     }
    // }

  
    // delayedShowMarker = () => {
    //   setTimeout(() => {
    //     this.setState({ isMarkerShown: true })
    //   }, 3000)
    // }
  
    // handleMarkerClick = () => {
    //   this.setState({ isMarkerShown: false })
    //   this.delayedShowMarker()
    // }

    // updatePosition = (pos) => {
    //     console.log(pos);
    // }

    handleMarkerDragEnd = (evt) => {
        let newLat = evt.latLng.lat(),
		    newLng = evt.latLng.lng();
        console.log("handleMarkerDragEnd",newLat, newLng );
    };

    render() {
        // initGeocoder(this.props.configMap.key);

        const MapWithAMarker = withScriptjs(withGoogleMap(p =>
            <GoogleMap mapTypeId={p.mapTypeId} defaultZoom={p.zoom} defaultCenter={p.markerPosition} 
                defaultOptions={{scrollwheel: false, streetViewControl:false, fullscreenControl:false}}
            >
               {p.isMarkerShown && (
                    <Marker position={p.markerPosition} icon={HomeIcon}
                        draggable={true}
                        onDragEnd={ p.onMarkerDragEnd }
                        // onClick={ p.onMarkerClick }
                    />
                )}
            </GoogleMap>
        ));

        return (
            <MapWithAMarker
                googleMapURL={this.props.configMap.mapURL}
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: `400px` }} />}
                mapElement={<div style={{ height: `100%` }} />}
                isMarkerShown={this.props.isMarkerShown}
                mapTypeId={this.props.mapTypeId}
                zoom={this.props.zoom} 
                markerPosition={ this.props.markerPosition }

                // Events
                // onMarkerClick={this.handleMarkerClick}
                // onMarkerDragEnd={ this.handleMarkerDragEnd }
                onMarkerDragEnd = {this.props.onMarkerDragEnd}
                updatePosition = {this.props.updatePosition}
                
            />
        )
    }
}


