// // Copyright 2020 Ernesto Girón E.
// // Licensed under the Apache License, Version 2.0 (the "License");
// // you may not use this file except in compliance with the License.
// // You may obtain a copy of the License at
// //     http://www.apache.org/licenses/LICENSE-2.0
// // Unless required by applicable law or agreed to in writing, software
// // distributed under the License is distributed on an "AS IS" BASIS,
// // WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// // See the License for the specific language governing permissions and
// // limitations under the License.​

import API from './ecaseAPI';
import {calculate_age} from '../utils/utils';
// import * as Cookies from "js-cookie";

/* 
* Make a request for list of Beneficiarios
* @return Array of Beneficiarios
*/
export async function getBeneficiariosGolf(action) {
  let listGolf = null;
    try {
      await API('/beneficiarios/golf', {
          method: 'POST',
          data: JSON.stringify({"api":action.token})
      }).then(response => response.data).then((data) => {
          listGolf = data.datos.map(d => {
            d.edad = calculate_age(d.fechanacimiento);
            d.edadclub = calculate_age(d.fecha);
            d.fecha = new Date(d.fecha);
            d.fechanacimiento = new Date(d.fechanacimiento);
            d.actualizacion = new Date(d.actualizacion);
            return d
          });
      }).catch(function (error) {
          // handle error
          console.log(`😱 problemas obteniendo los datos de Beneficiarios del GRB.`);
          console.log(error);
      })
  } catch (e) {
      console.log(`😱 Axios request failed: ${e}`);
  }
  return listGolf;
}

export async function getBeneficiariosTenis(action) {
  let listTenis = null;
    try {
      await API('/beneficiarios/tenis', {
          method: 'POST',
          data: JSON.stringify({"api":action.token})
      }).then(response => response.data).then((data) => {
          // console.log(data);
          listTenis = data.datos.map(d => {
            d.edad = calculate_age(d.fechanacimiento);
            d.edadclub = calculate_age(d.fecha);
            d.fecha = new Date(d.fecha);
            d.fechanacimiento = new Date(d.fechanacimiento);
            d.actualizacion = new Date(d.actualizacion);
            return d
          });
      }).catch(function (error) {
          // handle error
          console.log(`😱 problemas obteniendo los datos de Beneficiarios del GRB.`);
          console.log(error);
      })
  } catch (e) {
      console.log(`😱 Axios request failed: ${e}`);
  }
  return listTenis;
}

/* 
 * Make a request for Beneficiarios whole data
 * @return Array of Beneficiarios
 */
export async function getBeneficiarioPerfilDataxCumpli(action) {
  let datosBenef = null;
    try {
      await API('/beneficiarios/perfil', {
          method: 'POST',
          data: JSON.stringify({"api":action.token, "docId":action.docId, "req":action.req})
      }).then(response => response.data).then((data) => {
          datosBenef = data.datos
      }).catch(function (error) {
          console.log(`😱 problemas obteniendo los datos de Beneficiarios del GRB.`);
          console.log(error);
      })

  } catch (e) {
      console.log(`😱 Axios request failed: ${e}`);
  }
    return datosBenef;
}

/* 
* Make a request for list of Socios
* @return Array of Socios
*/
export async function getSociosGolf(action) {
  let listGolf = null;
    try {
      await API('/socios', {
          method: 'POST',
          data: JSON.stringify({"api":action.token})
      }).then(response => response.data).then((data) => {
          // listGolf = data.datos.map(d => {
          //   // d.fechanacimiento = new Date(d.fechanacimiento);
          //   d.actualizacion = new Date(d.actualizacion);
          //   return d
          // });
          listGolf = data.datos;
      }).catch(function (error) {
          // handle error
          console.log(`😱 problemas obteniendo los datos de Socios del GRB.`);
          console.log(error);
      })
  } catch (e) {
      console.log(`😱 Axios request failed: ${e}`);
  }
  return listGolf;
}


/*****************
* RESERVAS GOLF
******************/ 

/* 
 * Make a request for list of reservations
 * @return Array of Tabla de Lista de Reservas de Golf
 */
export async function getListReservasGolf(action) {
  let listReservas = null;
    try {
      await API('/reservasgolf/', { 
          method: 'POST',
          data: JSON.stringify({"api":action.token})
      }).then(response => response.data).then((data) => {
          listReservas = data.datos
      }).catch(function (error) {
          // handle error
          console.log(`😱 problemas obteniendo los datos de lista de reservas del GRB.`);
          console.log(error);
      })

  } catch (e) {
      console.log(`😱 Axios request failed: ${e}`);
  }
    return listReservas;
}

/* 
* Registra nuevos datos de lista de reservas de golf
* @return 
*/
export async function crearListaReservasGolf(action) {
  let resp = null;
  try {
      await API('/reservasgolf/insert', {
          method: 'POST',
          data: JSON.stringify({"api":action.token, "reservas":action.reservas})
      }).then(response => response.data).then((data) => {
          resp = data.mensaje;
      }).catch((error) => {
          resp = '😱 Error ('+error.response.status+'): Problema registrando los datos de reservas en el servidor. ' + error.response.data.mensaje;
          console.log(`😱 problemas registrando la tabla de reservas del Beneficiario en GRB.`, error.response.data.mensaje);
          return resp;
      })

  } catch (e) {
      console.log(`😱 Axios request failed: ${e}`);
  }
  return resp;
}
/* 
* Actualiza los datos de lista de reservas de golf
* @return 
*/
export async function actualizarListaReservasGolf(action) {
  let resp = null;
  try {
      await API('/reservasgolf/update', {
          method: 'POST',
          data: JSON.stringify({"api":action.token, "reservas":action.reservas})
      }).then(response => response.data).then((data) => {
          resp = data.mensaje;
      }).catch((error) => {
          resp = '😱 Error ('+error.response.status+'): Problema guardando los datos de reservas en el servidor. ' + error.response.data.mensaje;
          console.log(`😱 problemas actualizando la tabla de reservas del Beneficiario en GRB.`, error.response.data.mensaje);
          return resp;
      })
      
  } catch (e) {
      console.log(`😱 Axios request failed: ${e}`);
  }
  return resp;
}
/* 
* Elimina datos de lista de reservas de golf
* @return 
*/
export async function eliminarListaReservasGolf(action) {
  let resp = null;
  try {
      await API('/reservasgolf/delete', {
          method: 'POST',
          data: JSON.stringify({"api":action.token, "reservas":action.reservas})
      }).then(response => response.data).then((data) => {
          resp = data.mensaje;
      }).catch((error) => {
          resp = '😱 Error ('+error.response.status+'): Problema eliminando los datos de reservas en el servidor. ' + error.response.data.mensaje;
          console.log(`😱 problemas eliminando la tabla de reservas del Beneficiario en GRB.`, error.response.data.mensaje);
          return resp;
      })

  } catch (e) {
      console.log(`😱 Axios request failed: ${e}`);
  }
  return resp;
}
/* 
* Registra datos en batch de lista de reservas de golf
* @return 
*/
export async function crearBatchListaReservasGolf(action) {
  let resp = null;
  try {
      await API('/reservasgolf/create', {
          method: 'POST',
          data: JSON.stringify({"api":action.token, "listareservas":action.listareservas})
      }).then(response => response.data).then((data) => {
          resp = data.mensaje;
          // console.log(resp);
      }).catch((error) => {
          resp = '😱 Error ('+error.response.status+'): Problema ejecutando el batch la lista de reservas en el servidor. ' + error.response.data.mensaje;
          console.log(`😱 problemas batch la lista de reservas en GRB.`, error.response.data.mensaje);
          return resp;
      })

  } catch (e) {
      console.log(`😱 Axios request failed: ${e}`);
  }
  return resp;
}


/*****************
* RESERVAS HISTORICO GOLF
******************/ 

/* 
 * Make a request for list of reservations
 * @return Array of Tabla de Lista de Reservas Historico de Golf
 */
export async function getListReservasHistoricoGolf(action) {
  let listReservasHistorico = null;
    try {
      await API('/reservashistoricogolf/', { 
          method: 'POST',
          data: JSON.stringify({"api":action.token})
      }).then(response => response.data).then((data) => {
          listReservasHistorico = data.datos
      }).catch(function (error) {
          // handle error
          console.log(`😱 problemas obteniendo los datos de lista de reservas del GRB.`);
          console.log(error);
      })

  } catch (e) {
      console.log(`😱 Axios request failed: ${e}`);
  }
    return listReservasHistorico;
}

/* 
* Obetiene datos de lista de reservas de golf por fecha
* @return 
*/
export async function getListReservasHistoricoGolfxFecha(action) {
  let resp = null;
  try {
      await API('/reservashistoricogolf/date', {
          method: 'POST',
          data: JSON.stringify({"api":action.token, "fechareserva":action.fechareserva})
      }).then(response => response.data).then((data) => {
          resp = data.datos;
      }).catch((error) => {
          resp = '😱 Error ('+error.response.status+'): Problema obteniendo los datos de reservas para la fecha indicada en el servidor. ' + error.response.data.mensaje;
          console.log(`😱 problemas obteniendo la tabla de histórico de reservas del Socio y Beneficiario en GRB.`, error.response.data.mensaje);
          return resp;
      })

  } catch (e) {
      console.log(`😱 Axios request failed: ${e}`);
  }
  return resp;
}

/* 
* Registra nuevos datos de lista de reservas de golf
* @return 
*/
export async function crearListaReservasHistoricoGolf(action) {
  let resp = null;
  try {
      await API('/reservashistoricogolf/insert', {
          method: 'POST',
          data: JSON.stringify({"api":action.token, "reservas":action.reservas})
      }).then(response => response.data).then((data) => {
          resp = data.mensaje;
      }).catch((error) => {
          resp = '😱 Error ('+error.response.status+'): Problema registrando los datos de reservas en el servidor. ' + error.response.data.mensaje;
          console.log(`😱 problemas registrando la tabla de histórico de reservas del Beneficiario en GRB.`, error.response.data.mensaje);
          return resp;
      })

  } catch (e) {
      console.log(`😱 Axios request failed: ${e}`);
  }
  return resp;
}
/* 
* Actualiza los datos de lista de reservas de golf
* @return 
*/
export async function actualizarListaReservasHistoricoGolf(action) {
  let resp = null;
  try {
      await API('/reservashistoricogolf/update', {
          method: 'POST',
          data: JSON.stringify({"api":action.token, "reservas":action.reservas})
      }).then(response => response.data).then((data) => {
          resp = data.mensaje;
      }).catch((error) => {
          resp = '😱 Error ('+error.response.status+'): Problema guardando los datos de reservas en el servidor. ' + error.response.data.mensaje;
          console.log(`😱 problemas actualizando la tabla de histórico de reservas del Beneficiario en GRB.`, error.response.data.mensaje);
          return resp;
      })
      
  } catch (e) {
      console.log(`😱 Axios request failed: ${e}`);
  }
  return resp;
}
/* 
* Elimina datos de lista de reservas de golf
* @return 
*/
export async function eliminarListaReservasHistoricoGolf(action) {
  let resp = null;
  try {
      await API('/reservashistoricogolf/delete', {
          method: 'POST',
          data: JSON.stringify({"api":action.token, "reservas":action.reservas})
      }).then(response => response.data).then((data) => {
          resp = data.mensaje;
      }).catch((error) => {
          resp = '😱 Error ('+error.response.status+'): Problema eliminando los datos de reservas en el servidor. ' + error.response.data.mensaje;
          console.log(`😱 problemas eliminando la tabla de histórico de reservas del Beneficiario en GRB.`, error.response.data.mensaje);
          return resp;
      })

  } catch (e) {
      console.log(`😱 Axios request failed: ${e}`);
  }
  return resp;
}
/* 
* Registra datos en batch de lista de reservas de golf
* @return 
*/
export async function crearBatchListaReservasHistoricoGolf(action) {
  let resp = null;
  try {
      await API('/reservashistoricogolf/create', {
          method: 'POST',
          data: JSON.stringify({"api":action.token, "listareservas":action.listareservas})
      }).then(response => response.data).then((data) => {
          resp = data.mensaje;
          // console.log(resp);
      }).catch((error) => {
          resp = '😱 Error ('+error.response.status+'): Problema ejecutando el batch la lista de reservas en el servidor. ' + error.response.data.mensaje;
          console.log(`😱 problemas batch la lista de reservas en GRB.`, error.response.data.mensaje);
          return resp;
      })

  } catch (e) {
      console.log(`😱 Axios request failed: ${e}`);
  }
  return resp;
}


// TABLAS CRITERIOS TARIFAS SERVICIO
/* 
 * Actualiza los datos de criterios de las Tarifas del Caddie
 * @return 
 */



/* 
 * Make a request for list of reservations
 * @return Array of Tabla de Lista de Reservas Historico de Golf
 */
export async function getTarifasCaddie(action) {
    let listTarifasCaddie = null;
    try {
        await API('/tarifascaddie/', { 
            method: 'POST',
            data: JSON.stringify({"api":action.token})
        }).then(response => response.data).then((data) => {
            listTarifasCaddie = data.datos
        }).catch(function (error) {
            // handle error
            console.log(`😱 problemas obteniendo los datos de las tarifas del caddie en GRB.`);
            console.log(error);
        })
  
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
    return listTarifasCaddie;
}

export async function actualizarTarifasCaddie(action) {
    let resp = null;
    try {
        await API('/tarifascaddie/update', {
            method: 'POST',
            data: JSON.stringify({"api":action.token, "tarifas":action.tarifas})
        }).then(response => response.data).then((data) => {
            resp = data.mensaje;
        }).catch((error) => {
            resp = '😱 Error ('+error.response.status+'): Problema guardando los datos de tarifascaddie en el servidor. ' + error.response.data.mensaje;
            console.log(`😱 problemas actualizando la tabla de tarifascaddie del Beneficiario en GRB.`, error.response.data.mensaje);
            return resp;
        })
  
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
    return resp;
}
/* 
 * Registra nuevos datos de criterios de las Tarifas del Caddie
 * @return 
 */
export async function crearTarifasCaddie(action) {
    let resp = null;
    try {
        await API('/tarifascaddie/insert', {
            method: 'POST',
            data: JSON.stringify({"api":action.token, "tarifas":action.tarifas})
        }).then(response => response.data).then((data) => {
            resp = data.mensaje;
        }).catch((error) => {
            resp = '😱 Error ('+error.response.status+'): Problema registrando los datos de tarifascaddie en el servidor. ' + error.response.data.mensaje;
            console.log(`😱 problemas registrando la tabla de tarifascaddie del Beneficiario en GRB.`, error.response.data.mensaje);
            return resp;
        })
  
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
    return resp;
}
/* 
 * Actualiza los datos de criterios de las Tarifas del Caddie
 * @return 
 */
export async function eliminarTarifasCaddie(action) {
    let resp = null;
    try {
        await API('/tarifascaddie/delete', {
            method: 'POST',
            data: JSON.stringify({"api":action.token, "tarifas":action.tarifas})
        }).then(response => response.data).then((data) => {
            resp = data.mensaje;
        }).catch((error) => {
            resp = '😱 Error ('+error.response.status+'): Problema eliminando los datos de tarifascaddie en el servidor. ' + error.response.data.mensaje;
            console.log(`😱 problemas eliminando la tabla de tarifascaddie del Beneficiario en GRB.`, error.response.data.mensaje);
            return resp;
        })
  
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
    return resp;
}

// Stats
/* 
* Obetiene datos de estadisticas de golf
* @return 
*/
export async function obtenerEstadisticasCaddie(action) {
    let resp = null;
    try {
        await API('/reservashistoricogolf/caddie', {
            method: 'POST',
            data: JSON.stringify({"api":action.token, "caddie":action.caddie})
        }).then(response => response.data).then((data) => {
            // console.log(data);
            resp = data.datos;
        }).catch((error) => {
            resp = '😱 Error ('+error.response.status+'): Problema obteniendo los datos del caddie en el servidor. ' + error.response.data.mensaje;
            console.log(`😱 problemas obteniendo la tabla de histórico del Beneficiario en GRB.`, error.response.data.mensaje);
            return resp;
        })
  
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
    return resp;
  }