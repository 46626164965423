// Copyright 2020 Ernesto Girón E.
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//     http://www.apache.org/licenses/LICENSE-2.0
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.​

import { call, put, takeLatest } from "redux-saga/effects";
import { types as configTypes } from "../reducers/users";
import { 
  signBeneficiariosIn, 
  signUserIn, signUserOut, 
  getUsersData,
  crearUsuarioGRB,
  actualizarUsuarioGRB,
  eliminarUsuarioGRB,
  getUsersNotificacionesbyQuery
} from "../../utils/users_req";

// WORKERS //
function* startUserAuth(action) {
  try {
    // first try to restore the session from a saved cookie if it exists
    let authInfos = null; //yield call(restoreSession, action.payload.sessionId);

    // if there isn't a session cookie then we can start a new session
    if (!authInfos) {
      authInfos = yield call(signUserIn, action.payload);
    }
    // check for a response and finish by sending the authentication info to the Redux store
    if (!authInfos.error) {
      yield put({
        type: configTypes.USERS_AUTH_SUCCESS,
        payload: authInfos
      });
    } else {
      // error catching if we need it
      yield put({ type: configTypes.USERS_AUTH_FAIL, payload: authInfos });
    }
  } catch (e) {
    yield put({ type: configTypes.USERS_AUTH_FAIL });
    console.error("SAGA ERROR: _users/startUserAuth, ", e);
  }
}

function* startBeneficiarioAuth(action) {
  try {
    // first try to restore the session from a saved cookie if it exists
    let authInfos = null; //yield call(restoreSession, action.payload.sessionId);
    
    // if there isn't a session cookie then we can start a new session
    if (!authInfos) {
      authInfos = yield call(signBeneficiariosIn, action.payload);
    }
    
    // check for a response and finish by sending the authentication info to the Redux store
    if (!authInfos.error) {
      yield put({
        type: configTypes.USERS_AUTH_SUCCESS,
        payload: authInfos
      });
    } else {
      // error catching if we need it
      yield put({ type: configTypes.USERS_AUTH_FAIL, payload: authInfos });
    }
  } catch (e) {
    yield put({ type: configTypes.USERS_AUTH_FAIL });
    console.error("SAGA ERROR: _users/startBeneficiarioAuth, ", e);
  }
}

function* authLogout(action) {
  try {
    yield call(signUserOut, action.payload);

    // window.location.reload();
  } catch (e) {
    console.error("SAGA ERROR: _users/authLogout, ", e);
  }
}

function* fetchUsersData(action) {
  try {
    // fetch users
    const listUsers = yield call(getUsersData, action.payload.token);
    // load users into Redux store
    yield put({
      type: configTypes.USERS_LIST_LOADED,
      payload: {listUsers: listUsers}
    });
  } catch (e) {
    console.error("SAGA ERROR: _users/fetchUsersData, ", e);
  }
}

function* updateUserGRB(action) {
  try {
    const resp = yield call(actualizarUsuarioGRB, action.payload);
    if (resp && resp!=='')
      yield put({
        type: configTypes.MODULE_USERS_SERVER_MESSAGE,
        payload: {mensaje: {"usuarios": resp}}
      });
  } catch (e) {
    console.error("SAGA ERROR: _users/updateUserGRB, ", e);
  }
}
function* insertUsersGRB(action) {
  try {
    const resp = yield call(crearUsuarioGRB, action.payload);
    if (resp && resp!=='')
      yield put({
        type: configTypes.MODULE_USERS_SERVER_MESSAGE,
        payload: {mensaje: {"usuarios": resp}}
      });
  } catch (e) {
    console.error("SAGA ERROR: _users/insertUsersGRB, ", e);
  }
}
function* deleteUsersGRB(action) {
  try {
    const resp = yield call(eliminarUsuarioGRB, action.payload);
    if (resp && resp!=='')
      yield put({
        type: configTypes.MODULE_USERS_SERVER_MESSAGE,
        payload: {mensaje: {"usuarios": resp}}
      });
  } catch (e) {
    console.error("SAGA ERROR: _users/deleteUsersGRB, ", e);
  }
}

// Notificaciones
function* fetchNotificacionesUserbyQuery(action) {
  try {
    const notificaciones = yield call(getUsersNotificacionesbyQuery, action.payload);
    if (notificaciones)
      yield put({
        type: configTypes.MODULE_USERS_NOTIFICACION_LOADED,
        payload: {notificaciones: notificaciones}
      });
  } catch (e) {
    console.error("SAGA ERROR: _users/fetchNotificacionesUserbyQuery, ", e);
  }
}

// WATCHER //
export function* watchFetchUsersData() {
  yield takeLatest(configTypes.USERS_DATA_LOADED, fetchUsersData);
  yield takeLatest(configTypes.USERS_AUTH_START, startUserAuth);
  yield takeLatest(configTypes.BENEFICIARIO_AUTH_START, startBeneficiarioAuth);
  yield takeLatest(configTypes.USERS_LOGOUT, authLogout);

  yield takeLatest(configTypes.MODULE_USERS_UPDATED, updateUserGRB);
  yield takeLatest(configTypes.MODULE_USERS_CREATED, insertUsersGRB);
  yield takeLatest(configTypes.MODULE_USERS_DELETED, deleteUsersGRB);

  
  yield takeLatest(configTypes.MODULE_USERS_NOTIFICACION_DATABYQUERY_LOADED, fetchNotificacionesUserbyQuery);

}
