// React
import React, { Fragment,useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ContentEditable from 'react-contenteditable';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import {capitalize, 
    // RemoveDuplicates,
    getFirstWords, formatMoney,
    //removeHTMLTags,
    highlightAll, 
    disableNewlines, 
    pastePlainText
} from '../../utils/utils'; 
import Dimensions from 'react-dimensions';
import Pagination from '../Pagination';
import DatePicker from 'calcite-react/DatePicker';
import moment from 'moment';
import 'moment/locale/es-us';

import {showMsgToaster} from '../ShowMesg';
import {ToastContainer} from 'calcite-react/Toaster'; //notify
import Alert, {AlertMessage} from 'calcite-react/Alert';
import Select from 'calcite-react/Select';
// import MultiSelect from 'calcite-react/MultiSelect';
import Popover from 'calcite-react/Popover';
import Menu, {MenuItem} from 'calcite-react/Menu'; //MenuTitle, 
import Loader from 'calcite-react/Loader';
import Search from 'calcite-react/Search';
import Button from 'calcite-react/Button';
import TextField from 'calcite-react/TextField';
import {
    ListItem,
    ListItemTitle,
    ListItemSubtitle
} from 'calcite-react/List';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import '../../styles/css/font-awesome.min.css';
import './prestamos_styles.css';

import { serverMensaje, modulePrestamoLoaded,
    // fetchPrestamosData,
    fetchPrestamosDatabyQuery, updateEditedHistoPrestamoData,
    updatePrestamo,
    insertPrestamo,
    deletePrestamo,
    // displayPrestamoFilter,
} from '../../redux/reducers/prestamos';

import { fetchUsersData } from '../../redux/reducers/users';
import { fetchBeneficiariosData } from '../../redux/reducers/beneficiarios';
import HistoricoPrestamos from './HistoricoPrestamos';

import tipodePrestamo from '../../data/tipodeprestamo.json';
import estadoPrestamos from '../../data/estadoprestamo.json';


// Styled & Motion Components
import styled from "styled-components";

import UserIcon from 'calcite-ui-icons-react/UserIcon';
import ResetIcon from 'calcite-ui-icons-react/ResetIcon';
import SaveIcon from 'calcite-ui-icons-react/SaveIcon';
import PlusIcon from 'calcite-ui-icons-react/SpeechBubblePlusIcon';
import TrashIcon from 'calcite-ui-icons-react/TrashIcon';
import FilterIcon from 'calcite-ui-icons-react/FilterIcon';
// import CalendarIcon from 'calcite-ui-icons-react/CalendarIcon';
import SearchIcon from 'calcite-ui-icons-react/SearchIcon';
import MobileIcon from 'calcite-ui-icons-react/MobileIcon';
import smartphoneIcon from '../../assets/icons/smartphone_icon.png';
import whatsappIcon from '../../assets/icons/whatsapp.png';

const WhatsappIcon = styled.div`
  width: 20px;
  height: 20px;
  position: relative;
  display: inline-flex;
  vertical-align: bottom;
  opacity: 0.4;
  margin-right: 10px;
  background: rgba(255, 255, 255, 0.1) url(${whatsappIcon}) no-repeat center/cover;
`;
const SmartphoneIcon = styled.div`
  width: 20px;
  height: 20px;
  position: relative;
  display: inline-flex;
  vertical-align: bottom;
  opacity: 0.4;
  margin-right: 10px;
  background: rgba(255, 255, 255, 0.1) url(${smartphoneIcon}) no-repeat center/cover;
`;

const EditPrestamo = styled.div`
    width: 100%;
    // height: 100vh; // calc(100% - 10px);
    position: relative;
    background-color: #fff;
    z-index: 2;
`;
const AgregarPrestamo = styled.div`
    width: 100%;
    // height: 100vh; // calc(100% - 10px);
    position: relative;
    background-color: #fff;
    z-index: 2;
`;


const ModulePrestamos = props => {
    const dispatch = useDispatch();
    const config = useSelector(state => state.config);
    const users = useSelector(state => state.users);
    const listUsers = useSelector(state => state.users.listUsers);
    const listBenef = useSelector(state => state.beneficiarios.listBenef);
    const isEditable = (users.user.areatrabajo==='ADMINISTRATIVA' || users.user.areatrabajo==='COORDINACIÓN' || users.user.areatrabajo==='DIRECCIÓN' ) ? true : false;
    // const [isEditable,setIsEditable] = useState(true); //users.user.docId===prestamoSelected.creadapor ? true : false;
    const resultQueryPrestamos = useSelector(state => state.prestamos.resultQueryPrestamos);
    // const [listComprobantes, setListComprobantes] = useState([]);
    const [searchText,setSearchText] = useState('');
    const [searchComprobanteText, setComprobanteSearchText] = useState('');
    const [isDisplayEditPrestamo, setIsDisplayEditPrestamo] = useState(false);
    const [isDisplayAgregarPrestamo, setIsDisplayAgregarPrestamo] = useState(false);

    const mensaje = useSelector(state => state.prestamos.mensaje);
    const [error, setError] = useState(null);
    const [errorenAgregar, setErrorenAgregar] = useState(null);
    const [fechaRegistro, setFechaRegistro] = useState(moment(new Date(),'YYYY-MM-DD'));
    const [fechaRegistroPickerFocused, setFechaRegistroPickerFocused] = useState(false);
    const [fechaAprobado, setFechaAprobado] = useState(moment(new Date(),'YYYY-MM-DD'));
    const [fechaAprobadoPickerFocused, setFechaAprobadoPickerFocused] = useState(false);
    const [fechaDesembolso, setFechaDesembolso] = useState(moment(new Date(),'YYYY-MM-DD'));
    const [fechaDesembolsoPickerFocused, setFechaDesembolsoPickerFocused] = useState(false);
    const minDate = moment('2017-05-14'); //moment('2020-07-15'); //moment().subtract(2, 'months').startOf('month');
    const maxDate= moment().add(15, 'months'); //moment().add(2, 'months').endOf('month');

    const [query,] = useState({
        "page": "1",
        "item_per_page": "10",
        "searchText":"",
        "id":"",
        "beneficiarioId":"",
        "fiadorId":"",
        "fiador":"",
        "tipoprestamo":"",
        "claseprestamo":"",
        "descripcion":"",
        "monto":"",
        "fechaaprobado":"",
        "fechadesembolso":"",
        "comprobdesembolso":"",
        "plazo":"",
        "tasapactada":"",
        "vlrcuota":"",
        "cuotaspendientes":"",
        "diacorte":"",
        "estadoprestamo":"",
        "observaciones":"",
        "creadapor":"",
        "fecharegistro":"",
        "actualizacion":"",
        "version":"",
        "modificadopor":""
    });

    const [prestamoSelected, setPrestamoSelected] = useState({
        "id": '-1', //int
        "beneficiarioId":"",
        "fiadorId":"",
        "fiador":"",
        "tipoprestamo":"",
        "claseprestamo":"",
        "descripcion":"",
        "monto":"",
        "fechaaprobado":"",
        "fechadesembolso":"",
        "comprobdesembolso":"",
        "plazo":"",
        "tasapactada":"",
        "vlrcuota":"",
        "cuotaspendientes":"",
        "diacorte":"",
        "estadoprestamo":"",
        "observaciones":"",
        "creadapor":"",
        "fecharegistro":"",
        "actualizacion":"",
        "version":"",
        "modificadopor":""
    });

    const [itemsPrestamo, setItemsPrestamo] = useState([
        // {"key":"id", "label": "Id", "value": prestamoSelected['id']},
        {"key":"beneficiarioId", "label": "Número de documento del Beneficiario", "value": prestamoSelected['beneficiarioId']},
        {"key":"fiadorId", "label": "Número de documento del Fiador", "value": prestamoSelected['fiadorId']},
        {"key":"fiador", "label": "Nombre completo Fiador", "value": prestamoSelected['fiador']},
        {"key":"tipoprestamo", "label": "Tipo de préstamo", "value": prestamoSelected['tipoprestamo']},
        {"key":"claseprestamo", "label": "Clase de préstamo", "value": prestamoSelected['claseprestamo']},
        {"key":"descripcion", "label": "Breve descripción", "value": prestamoSelected['descripcion']},
        {"key":"monto", "label": "Valor del Préstamo o Monto", "value": prestamoSelected['monto']},
        {"key":"fechaaprobado", "label": "Fecha Aprobado", "value": prestamoSelected['fechaaprobado']},
        {"key":"fechadesembolso", "label": "Fecha Desembolso", "value": prestamoSelected['fechadesembolso']},
        {"key":"comprobdesembolso", "label": "Número de Comprobante desembolso", "value": prestamoSelected['comprobdesembolso']},
        {"key":"plazo", "label": "Plazo en meses", "value": prestamoSelected['plazo']},
        {"key":"tasapactada", "label": "Tasa pactada (E.A.) en %", "value": prestamoSelected['tasapactada']},
        {"key":"vlrcuota", "label": "Valor cuota pactada", "value": prestamoSelected['vlrcuota']},
        {"key":"cuotaspendientes", "label": "Cuotas pendientes por pagar", "value": prestamoSelected['cuotaspendientes']},
        {"key":"diacorte", "label": "Día de corte para fecha de pago", "value": prestamoSelected['diacorte']},
        {"key":"estadoprestamo", "label": "Estado del préstamo", "value": prestamoSelected['estadoprestamo']},
        // {"key":"observaciones", "label": "Observaciones", "value": prestamoSelected['observaciones']},
        // {"key":"fecharegistro", "label": "Fecha creación", "value": prestamoSelected['fecharegistro']},
        // {"key":"creadapor", "label": "Préstamo creada por", "value": prestamoSelected['creadapor']},
        // {"key":"modificadopor", "label": "Modificado por", "value": prestamoSelected['modificadopor']},
        // {"key":"actualizacion", "label": "Actualizacion", "value": prestamoSelected['actualizacion']},
        // {"key":"version", "label": "Version", "value": prestamoSelected['version']},
    ]);

    // let showPages = 1; let totalPages = 1;
    const [showPages,] = useState(3);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [numItemsperPage,] = useState(10);
    const onPageNumberChange = (pageNumber) => {
        query['page'] = parseInt(pageNumber)+1;
        dispatch(fetchPrestamosDatabyQuery({token: users.user.claveAPI, query: query} ));
        setCurrentPage(pageNumber);
    }
    
    useEffect(() => {
        if (!listUsers || listUsers.length<0){
            dispatch(fetchUsersData({token: users.user.claveAPI} ));
        }
        if (!listBenef || listBenef.length<=0){
            dispatch(fetchBeneficiariosData({token: users.user.claveAPI}));
        }
        if (resultQueryPrestamos===null){
            dispatch(fetchPrestamosDatabyQuery({token: users.user.claveAPI, query: query} ));
            return
        }

        return () => {
            // executed when unmount
            // console.log('Saliendo del Modulo Prestamos');
        }
        
    }, [users.user.claveAPI, listUsers, listBenef, resultQueryPrestamos, query, dispatch]);

    useEffect(() => {
        if (resultQueryPrestamos===null){
            return
        }
        if (!listUsers || listUsers.length<0){
            return
        }
        if (!listBenef || listBenef.length<0){
            return
        }
        if (resultQueryPrestamos.datos.length<=0){
            console.log('No hay registros para mostrar...');
        }
        // Update 
        dispatch(modulePrestamoLoaded({loaded:true}));
        if (resultQueryPrestamos && resultQueryPrestamos.datos.length > 0){
            // console.log(resultQueryPrestamos);
            // let comprobantes = [];
            let openIds = {}; //[...listReservas].map((itm,idx) => {return false});
            resultQueryPrestamos.datos.forEach((itm,idx) => {
                openIds[idx] = false;
                // comprobantes.push(
                //     { "id": idx, "factura": itm.comprobdesembolso }
                // );
            });
            setCelularOpenPopoverIds(openIds);

            // Remover Facturas Duplicadas
            // comprobantes = RemoveDuplicates(comprobantes, 'factura');
            // setListComprobantes(comprobantes);
        }
        // Update totalPages
        if (resultQueryPrestamos.stats.count)
            setTotalPages(Math.ceil(resultQueryPrestamos.stats.count / numItemsperPage));


        if (mensaje.prestamos && mensaje.prestamos!=='') {

            const updateListaPrestamos = () => {
                setCurrentPage(0);
                query['page'] = '1'; // Es necesario enviarla para que encuentre algo
                query['estadoprestamo'] = '';
                dispatch(fetchPrestamosDatabyQuery({token: users.user.claveAPI, query: query} ));
            }

            if (typeof mensaje.prestamos === 'string' && mensaje.prestamos.toLowerCase().includes('error')){
                showMsgToaster('error', 'Ocurrió un fallo en el servidor', mensaje.prestamos);
                mensaje.prestamos='';
            } 
            else {
                showMsgToaster('success',"Prestamos", mensaje.prestamos.split('.')[0]);
                mensaje.prestamos='';
                dispatch(serverMensaje({prestamos:''}));
                updateListaPrestamos();
            }
            
        }
        
    }, [resultQueryPrestamos, listUsers, listBenef, numItemsperPage, query, users.user.claveAPI, 
        mensaje.prestamos, dispatch]);

    // Save on Server
    const updateDataonServer = (action, prestamo) => {
        // console.log(prestamo);
        switch (action) {
            case 'insert':
                prestamo['creadapor'] = users.user.docId;
                dispatch(insertPrestamo( { token: users.user.claveAPI, prestamos:prestamo}));
                break;
            case 'remove':
                dispatch(deletePrestamo( { token: users.user.claveAPI, prestamos:prestamo}));
                break;
            case 'update':
                // Actualizar solo el registro necesario, para no enviar toda la tabla
                dispatch(updatePrestamo( { token: users.user.claveAPI, prestamos:prestamo}));
                break;
            default: // update
                console.log('Acción no disponible');
                break;
        }
    }

    const updateItemsPrestamo = (f, v) => {
        const newitemsPrestamo = itemsPrestamo.map((itm, index) => {
            if (itm.key===f){
                itm.value = v;
            }
            return itm;
        });
        setItemsPrestamo(newitemsPrestamo);
    };

    // const getUserbyDocId = (docId) =>{
    //     let u = null;
    //     if (listUsers && listUsers.length>0)
    //         u = listUsers.filter(itm => itm.docId===docId);
    //     return u;
    // }
    
    // const showPrestamo = (idx) =>{
    //     document.getElementById('prestamo_'+idx).style.display = document.getElementById('prestamo_'+idx).style.display==='block' ? '-webkit-box' : 'block';
    // }
    // const showObservaciones = (idx) =>{
    //     document.getElementById('observaciones_'+idx).style.display = document.getElementById('observaciones_'+idx).style.display==='block' ? '-webkit-box' : 'block';
    // }

    // Buscar
    const buscarenPrestamo = (str)  => {
        // console.log('texto a buscar:', searchText);
        setCurrentPage(0);
        query['page'] = '1'; // Es necesario enviarla para que encuentre algo
        query['descripcion'] = str || searchText.trim() || '';
        dispatch(fetchPrestamosDatabyQuery({token: users.user.claveAPI, query: query} ));
    }

    // Toolbar
    const onBtnFiltrarPrestamo= (evt) =>{
        evt.preventDefault();
        evt.stopPropagation();
        setCurrentPage(0);
        query['page'] = '1'; // Es necesario enviarla para que encuentre algo
        query['descripcion'] = searchText.trim() || '';
        toggleFilterTool(evt);
    }
    const handleSearchKeyPress = (evt) => {
        const keyCode = evt.keyCode || evt.which
        if (keyCode === 13 || evt.key === 'Enter') {
            evt.returnValue = false
            if (evt.preventDefault) evt.preventDefault();
            // Search
            buscarenPrestamo();
        }
    }
    const onSearchChange = (value) => {
        setSearchText(value);
    }
    const onSearchRequestClear = () => {
        setSearchText('');
        setCurrentPage(0);
        query['page'] = '1';
        query['descripcion'] = '';
        dispatch(fetchPrestamosDatabyQuery({token: users.user.claveAPI, query: query} ));
        // buscarenPrestamo(); // No refresca la lista
    }
    const buscarTexto = (evt)  => {
        evt.preventDefault();
        evt.stopPropagation();
        // console.log('texto a buscar:', searchText);
        buscarenPrestamo(searchText.trim());
    }
    const toggleBuscarTool = (evt)  => {
        evt.preventDefault();
        evt.stopPropagation();
        document.getElementById('buscar-tool-mobile').style.display = document.getElementById('buscar-tool-mobile').style.display==='inline-flex' ? 'none' : 'inline-flex';
    }
    
    const onBtnAgregarPrestamo = (evt) =>{
        evt.preventDefault();
        evt.stopPropagation();
        // console.log(users);
        const newitemsPrestamo = itemsPrestamo.map((itm, index) => {
            itm.value = '';
            if (itm.key==='id') itm.value = "-1";
            if (itm.key==='estadoprestamo') itm.value = "Pendiente";
            // if (itm.key==='tipoprestamo') itm.value = "";
            // if (itm.key==='claseprestamo') itm.value = "";
            if (itm.key==='fecharegistro') itm.value = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
            // if (itm.key==='fechadesembolso') itm.value = moment().add(5, 'days').format("YYYY-MM-DD HH:mm:ss");
            if (itm.key==='creadapor') itm.value = users.user.nombre; //users.user.docId;
            if (itm.key==='modificadopor') getFirstWords(users.user.nombre.toUpperCase());
            if (itm.key==='actualizacion') itm.value = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
            if (itm.key==='version') itm.value = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
            
            return itm;
        });
        let nuevoRegistro = {
            "id": '-1', //int
            "beneficiarioId":"",
            "fiadorId":"",
            "fiador":"",
            "tipoprestamo":"",
            "claseprestamo":"",
            "descripcion":"",
            "monto":"0.00",
            "fechaaprobado":null,
            "fechadesembolso":null,
            "comprobdesembolso":"",
            "plazo":"0",
            "tasapactada":"0.00",
            "vlrcuota":"0",
            "cuotaspendientes":"0",
            "diacorte":"15",
            "estadoprestamo":"Pendiente",
            "observaciones":"",
            "creadapor": users.user.docId,
            "fecharegistro": moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
            "actualizacion": moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
            "version": moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
            "modificadopor": getFirstWords(users.user.nombre.toUpperCase())
        };
        setItemsPrestamo(newitemsPrestamo);
        setPrestamoSelected(nuevoRegistro);
        // // Update UI
        // setEditorDescripcionState(EditorState.createEmpty());
        setEditorObservacionState(EditorState.createEmpty());
        setTipoprestamoSelectedValue(nuevoRegistro.tipoprestamo);
        // setPrioridadSelectedValue(nuevoRegistro.prioridad);
        // setAreaprestamoSelectedValue(nuevoRegistro.areanota);
        setEstadoprestamoSelectedValue(nuevoRegistro.estadoprestamo);
        setFechaAprobado(nuevoRegistro.fechaaprobado);
        setFechaDesembolso(nuevoRegistro.fechadesembolso);
        // setInputBeneficiarioValue(nuevoRegistro.beneficiarioId);
        setIsDisplayAgregarPrestamo(true);
    }
    const onBtnEditPrestamo = (evt, id, index) =>{
        evt.preventDefault();
        evt.stopPropagation();
        
        if (resultQueryPrestamos && resultQueryPrestamos.datos.length>0){
            const newitemsPrestamo = resultQueryPrestamos.datos.filter((itm, index) => { return itm.id===id});
            // console.log(newitemsPrestamo);
            if (newitemsPrestamo){
                const itemprestamo = itemsPrestamo.map((itm, index) => {
                    itm.value = newitemsPrestamo[0][itm.key];
                    // if (itm.key==='beneficiarioId' && newitemsPrestamo[0].benefnombres!==null) itm.value = capitalize(newitemsPrestamo[0].benefnombres, true) +' '+capitalize(newitemsPrestamo[0].benefapellidos,true);
                    if (itm.key==='modificadopor') itm.value = getFirstWords(users.user.nombre.toUpperCase());
                    return itm;
                });
                setItemsPrestamo(itemprestamo);
                setPrestamoSelected(newitemsPrestamo[0]);
                // // Update UI
                // // Agregar datos del observación al editor
                // const descripcionContentBlock = htmlToDraft(newitemsPrestamo[0].descripcion);
                // if (descripcionContentBlock) {
                //     const descripcionContentState = ContentState.createFromBlockArray(descripcionContentBlock.contentBlocks);
                //     const descripcionEditorState = EditorState.createWithContent(descripcionContentState);
                //     setEditorDescripcionState(descripcionEditorState);
                // }
                const observacionesContentBlock = htmlToDraft(newitemsPrestamo[0].observaciones);
                if (observacionesContentBlock) {
                    const observacionesContentState = ContentState.createFromBlockArray(observacionesContentBlock.contentBlocks);
                    const observacionesEditorState = EditorState.createWithContent(observacionesContentState);
                    setEditorObservacionState(observacionesEditorState);
                }
                setTipoprestamoSelectedValue(newitemsPrestamo[0].tipoprestamo);
                // setPrioridadSelectedValue(newitemsPrestamo[0].prioridad);
                // setAreaprestamoSelectedValue(newitemsPrestamo[0].areanota);
                setEstadoprestamoSelectedValue(newitemsPrestamo[0].estadoprestamo);
                setFechaRegistro(moment(newitemsPrestamo[0].fecharegistro, 'YYYY-MM-DD'));
                setFechaAprobado(moment(newitemsPrestamo[0].fechaaprobado, 'YYYY-MM-DD'));
                setFechaDesembolso(moment(newitemsPrestamo[0].fechadesembolso, 'YYYY-MM-DD'));
                // // setInputBeneficiarioValue(newitemsPrestamo[0].beneficiarioId); 
                // if (newitemsPrestamo[0].benefnombres!==null)
                //     setInputBeneficiarioValue(capitalize(newitemsPrestamo[0].benefnombres, true) +' '+capitalize(newitemsPrestamo[0].benefapellidos,true));

                // // setIsEditable(users.user.docId===newitemsPrestamo[0].creadapor ? true : false);
                // setIsEditable(users.user.areatrabajo==='ADMINISTRATIVA' || users.user.docId===newitemsPrestamo[0].creadapor ? true : false);
                setIsDisplayEditPrestamo(true);
            }
        }
        
    }
    
    
    const savePrestamo = () => {
        setIsDisplayAgregarPrestamo(false);
        setIsDisplayEditPrestamo(false);
        dispatch(updateEditedHistoPrestamoData({resultQueryHistoPrestamos:null}));
        // const htmlDescripcion = draftToHtml(convertToRaw(editorDescripcionState.getCurrentContent()));
        const htmlObservaciones = draftToHtml(convertToRaw(editorObservacionState.getCurrentContent()));
        // // console.log(html);
        // prestamoSelected['descripcion'] = htmlDescripcion;
        prestamoSelected['observaciones'] = htmlObservaciones;
        prestamoSelected['modificadopor'] = getFirstWords(users.user.nombre.toUpperCase());
        if (prestamoSelected['beneficiarioId']==='' || prestamoSelected['beneficiarioId']===undefined || prestamoSelected['beneficiarioId']===null)
            prestamoSelected['beneficiarioId']='FUNDACIÓN';
        if (isDisplayEditPrestamo===true) updateDataonServer('update', prestamoSelected);
        if (isDisplayAgregarPrestamo===true) updateDataonServer('insert', prestamoSelected);
    }
    const cancelPrestamo = (evt) => {
        if (isDisplayEditPrestamo===true) setIsDisplayEditPrestamo(false);
        if (isDisplayAgregarPrestamo===true) setIsDisplayAgregarPrestamo(false);
    }
    const eliminarPrestamo = (evt, id) =>{
        evt.preventDefault();
        evt.stopPropagation();
        updateDataonServer('remove', prestamoSelected);
        setIsDisplayEditPrestamo(false);
    }


    const replaceSomeText = (s) =>{
        return s
            .replace(/&nbsp;/g, ' ')
            .replace(/<br>/g, '')
            .replace(/<\/br>/g, '')
            .replace(/<\/div>/g, '')
            .replace(/<div>/g, '')
            .replace(/<b>/g, '')
            .replace(/<strong>/g, '')
            .replace(/<p>/g, '')
            .replace(/<\/b>/g, '')
            .replace(/<\/strong>/g, '')
            .replace(/<\/p>/g, '')
            .replace(/&amp;/g, '&')
            .replace(/&gt;/g, '>')
            .replace(/&lt;/g, '<').trim()
    }
    const validateInput = (f, v, final) => {
        const regex_onlynum = /[0-9,]|\./;
        // const regex_gasto = /^[0-9]*(\.[0-9]+)?$/;
        const regex_gasto = /^-?\d*\.?\d*$/;
        // const regex_nombres = /^['`´a-zA-ZÀ-ÿ\u00C0-\u017F\s]+$/;
        let new_val = null;
        let err = null;
        switch (f) {
            case 'id':
                new_val = replaceSomeText(v);
                if (!regex_onlynum.test(new_val)) {
                    err = 'Dígite solo números válidos';
                    new_val = null;
                }
                if (new_val===null) new_val = '';
                break;
            case 'beneficiarioId':
                new_val = v.substr(0,15);
                if (final)
                    new_val = replaceSomeText(v).substr(0,15);
                break;
            // case 'familiarId':
            //     new_val = v.substr(0,15);
            //     if (final)
            //         new_val = replaceSomeText(v).substr(0,15);
            //     break;
            case 'fiadorId':
                new_val = v.substr(0,15);
                if (final)
                    new_val = replaceSomeText(v).substr(0,15);
                break;
            case 'fecharegistro': //0000-00-00 00:00:00
                new_val = replaceSomeText(v).substr(0,30);
                if (final && new_val===''){
                    new_val = replaceSomeText(v).substr(0,30);
                    err = 'Dígite una fecha de registro  válida';
                    new_val = null;
                }
                break;
            case 'fechadesembolso': //0000-00-00 00:00:00
                new_val = replaceSomeText(v).substr(0,30);
                if (final && new_val===''){
                    new_val = replaceSomeText(v).substr(0,30);
                    err = 'Dígite una fecha de desembolso válida';
                    new_val = null;
                }
                break;
            case 'fechaaprobado': //0000-00-00 00:00:00
                new_val = replaceSomeText(v).substr(0,30);
                if (final && new_val===''){
                    new_val = replaceSomeText(v).substr(0,30);
                    err = 'Dígite una fecha de aprobado válida';
                    new_val = null;
                }
                break;
            case 'fiador':
                new_val = v.substr(0,120);
                if (final)
                    new_val = replaceSomeText(v).substr(0,120);
                break;
            // case 'auxilioparafamiliar':
            //     new_val = replaceSomeText(v).substr(0,1); //1
            //     if (!regex_onlynum.test(new_val)) {
            //         err = 'Dígite solo números válidos';
            //         new_val = null;
            //     } else if(parseInt(new_val)>1){
            //         err = 'Dígite un número válido';
            //         new_val = null;
            //     }
            //     break;
            case 'comprobdesembolso':
                new_val = v.substr(0,32);
                if (final)
                    new_val = replaceSomeText(v).substr(0,32);
                break;
            case 'estadoprestamo':
                new_val = v.substr(0,1);
                if (final)
                    new_val = replaceSomeText(v).substr(0,1);
                break;
            case 'plazo':
                new_val = replaceSomeText(v).substr(0,3);
                if (!regex_onlynum.test(v)) {
                    err = 'Dígite solo números válidos de plazo';
                    new_val = null;
                } else if(v.length>15){
                    err = 'Dígite un número de plazo válido';
                    new_val = null;
                }
                if (new_val===null) new_val = '';
                break;
            case 'diacorte':
                new_val = replaceSomeText(v).substr(0,2);
                if (!regex_onlynum.test(v)) {
                    err = 'Dígite solo números válidos de dia de corte';
                    new_val = null;
                } else if(v.length>2){
                    err = 'Dígite un número de dia de corte válido';
                    new_val = null;
                }
                if (new_val===null) new_val = '';
                break;
            case 'cuotaspendientes':
                new_val = replaceSomeText(v).substr(0,3);
                if (!regex_onlynum.test(v)) {
                    err = 'Dígite solo números válidos de cuotas pendientes';
                    new_val = null;
                } else if(v.length>15){
                    err = 'Dígite un número de cuotas pendientes válido';
                    new_val = null;
                }
                if (new_val===null) new_val = '';
                break;
            case 'monto':
                new_val = replaceSomeText(v);
                if (!regex_gasto.test(new_val)) {
                    err = 'Dígite solo números válidos';
                    new_val = null;
                }
                break;
            case 'tasapactada':
                new_val = replaceSomeText(v);
                if (!regex_gasto.test(new_val)) {
                    err = 'Dígite solo números válidos';
                    new_val = null;
                }
                break;
            case 'vlrcuota':
                new_val = replaceSomeText(v);
                if (!regex_gasto.test(new_val)) {
                    err = 'Dígite solo números válidos';
                    new_val = null;
                }
                break;
            case 'tipoprestamo':
                new_val = v.substr(0,60);
                if (final)
                    new_val = replaceSomeText(v).substr(0,60);
                break;
            case 'claseprestamo':
                new_val = v.substr(0,120);
                if (final)
                    new_val = replaceSomeText(v).substr(0,120);
                break;
            case 'creadapor':
                new_val = v.substr(0,32);
                if (final)
                    new_val = replaceSomeText(v).substr(0,32);
                break;
            case 'modificadopor':
                new_val = v.substr(0,32);
                if (final)
                    new_val = replaceSomeText(v).substr(0,32);
                break;
            case 'descripcion':
                new_val = v.substr(0,255);
                // if (final)
                //     new_val = v; //replaceSomeText(v);
                break;
            case 'observaciones':
                new_val = v;
                // if (final)
                //     new_val = v; //replaceSomeText(v);
                break;
            case 'actualizacion': //0000-00-00 00:00:00
                new_val = v.substr(0,30);
                if (final && new_val===''){
                    new_val = replaceSomeText(v).substr(0,30);
                    err = 'Dígite una fecha de actualización válida';
                    new_val = null;
                }
                break;
            case 'version': //0000-00-00 00:00:00
                new_val = v.substr(0,30);
                if (final && new_val===''){
                    new_val = replaceSomeText(v).substr(0,30);
                    err = 'Dígite una fecha de version válida';
                    new_val = null;
                }
                break;
            
            default:
                break;
        }

        if (isDisplayEditPrestamo) setError(err);
        if (isDisplayAgregarPrestamo) setErrorenAgregar(err);
        return new_val
    }
    const handleChange = evt => {
        const field = evt.currentTarget.id.split('_')[1];
        const new_val = validateInput(field, evt.target.value, false);
        if(new_val){
            prestamoSelected[field] = new_val
        };
    };
    const handleBlur = evt => {
        const val = evt.currentTarget.innerHTML; //evt.currentTarget.innerText; //
        const field = evt.target.id.split('_')[1];
        let new_val = validateInput(field, val, true);
        if(new_val || new_val===''){
            if (Object.keys(prestamoSelected).includes(field)){
                prestamoSelected[field] = new_val;
            }
            updateItemsPrestamo(field, new_val);
        };
    };

    const [tipoprestamoSelectedValue, setTipoprestamoSelectedValue] = useState('');
    const handleTipoPrestamoSelectChange = (value, item) => {
        setTipoprestamoSelectedValue(value);
        prestamoSelected['tipoprestamo'] = value;
        updateItemsPrestamo('tipoprestamo', value);
    };
    const handleTipoPrestamoonKeyDown  = (evt) => {
        const keyCode = evt.keyCode || evt.which
        if (keyCode === 8 || keyCode === 46) {
            setTipoprestamoSelectedValue('');
            prestamoSelected['tipoprestamo'] = '';
            updateItemsPrestamo('tipoprestamo', '');
            evt.returnValue = false
            if (evt.preventDefault) evt.preventDefault()
        }
    }

    // const [prioridadSelectedValue, setPrioridadSelectedValue] = useState('');
    // const handlePrioridadSelectChange = (value, item) => {
    //     setPrioridadSelectedValue(value);
    //     prestamoSelected['prioridad'] = value;
    //     updateItemsPrestamo('prioridad', value);
    // };
    // const handlePrioridadonKeyDown  = (evt) => {
    //     const keyCode = evt.keyCode || evt.which
    //     if (keyCode === 8 || keyCode === 46) {
    //         setPrioridadSelectedValue('');
    //         prestamoSelected['prioridad'] = '';
    //         updateItemsPrestamo('prioridad', '');
    //         evt.returnValue = false
    //         if (evt.preventDefault) evt.preventDefault()
    //     }
    // }

    // const [areaprestamoSelectedValue, setAreaprestamoSelectedValue] = useState('');
    // const handleAreanotaSelectChange = (value, item) => {
    //     setAreaprestamoSelectedValue(value);
    //     prestamoSelected['areanota'] = value;
    //     updateItemsPrestamo('areanota', value);
    // };
    // const handleAreanotaonKeyDown  = (evt) => {
    //     const keyCode = evt.keyCode || evt.which
    //     if (keyCode === 8 || keyCode === 46) {
    //         setAreaprestamoSelectedValue('');
    //         prestamoSelected['areanota'] = '';
    //         updateItemsPrestamo('areanota', '');
    //         evt.returnValue = false
    //         if (evt.preventDefault) evt.preventDefault()
    //     }
    // }

    const [estadoprestamoSelectedValue, setEstadoprestamoSelectedValue] = useState('');
    const handleEstadoprestamoSelectChange = (value, item) => {
        setEstadoprestamoSelectedValue(value);
        prestamoSelected['estadoprestamo'] = value;
        updateItemsPrestamo('estadoprestamo', value);
    };
    const handleEstadoprestamoonKeyDown  = (evt) => {
        const keyCode = evt.keyCode || evt.which
        if (keyCode === 8 || keyCode === 46) {
            setEstadoprestamoSelectedValue('');
            prestamoSelected['estadoprestamo'] = '';
            updateItemsPrestamo('estadoprestamo', '');
            evt.returnValue = false
            if (evt.preventDefault) evt.preventDefault()
        }
    }

    // DatePicker
    const onFechaRegistroChange = (date) =>{
        if (date.preventDefault) date.preventDefault()
        if (date){
            prestamoSelected.fecharegistro = date.utc().format('YYYY-MM-DD HH:mm:ss');
            setFechaRegistro(date);
            updateItemsPrestamo('fecharegistro', prestamoSelected.fecharegistro);
        }
    }
    const onFechaRegistroFocusChange = ({ focused }) =>{
        setFechaRegistroPickerFocused(focused)
    }
    // DatePicker
    const onFechaAprobadoChange = (date) =>{
        if (date.preventDefault) date.preventDefault()
        if (date){
            prestamoSelected.fechaaprobado = date.utc().format('YYYY-MM-DD HH:mm:ss');
            setFechaAprobado(date);
            updateItemsPrestamo('fechaaprobado', prestamoSelected.fechaaprobado);
        }
    }
    const onFechaAprobadoFocusChange = ({ focused }) =>{
        setFechaAprobadoPickerFocused(focused)
    }
    // DatePicker
    const onFechaDesembolsoChange = (date) =>{
        if (date.preventDefault) date.preventDefault()
        if (date){
            prestamoSelected.fechadesembolso = date.utc().format('YYYY-MM-DD HH:mm:ss');
            setFechaDesembolso(date);
            updateItemsPrestamo('fechadesembolso', prestamoSelected.fechadesembolso);
        }
    }
    const onFechaDesembolsoFocusChange = ({ focused }) =>{
        setFechaDesembolsoPickerFocused(focused)
    }

    // Select Beneficiario
    const [selectedBeneficiarioItem, setSelectedBeneficiarioItem] = useState('');
    const [inputBeneficiarioValue, setInputBeneficiarioValue] = useState('');
    const searchBeneficiarioChanged = (e) => {
        setSelectedBeneficiarioItem(e);
        setInputBeneficiarioValue(e);
        prestamoSelected['beneficiarioId'] = e.value;
        updateItemsPrestamo('beneficiarioId', e.value);
    }
    const clearBeneficiarioSearch = () => {
        setInputBeneficiarioValue('');
        setSelectedBeneficiarioItem('');
        prestamoSelected['beneficiarioId'] = '';
        updateItemsPrestamo('beneficiarioId', '');
    }
    const onBeneficiarioUserAction = (inputValue, selectedItemVal) => {
        setInputBeneficiarioValue(inputValue);
        setSelectedBeneficiarioItem(selectedItemVal);
    }

    // Editor
    // const [editorDescripcionState, setEditorDescripcionState] = useState( () => EditorState.createEmpty() );
    const [editorObservacionState, setEditorObservacionState] = useState( () => EditorState.createEmpty() );
    // const onDescripcionEditorStateChange = (eState) => {
    //     setEditorDescripcionState(eState);
    // };
    const onObservacionEditorStateChange = (eState) => {
        setEditorObservacionState(eState);
    };
    
    // Filtro
    const toggleFilterTool = (evt)  => {
        evt.preventDefault();
        evt.stopPropagation();
        document.getElementById('filtro-tool-mobile').style.display = document.getElementById('filtro-tool-mobile').style.display==='block' ? 'none' : 'block';
    }
    const clearFilters = (evt) => {
        evt.preventDefault();
        evt.stopPropagation();
        setEstadoprestamoFilterSelectedValue('');
        query['estadoprestamo'] = '';
        setComprobanteSearchText('');
        query['comprobdesembolso'] = '';
        // setPrioridadFilterSelectedValue('');
        // query['prioridad'] = '';
        // setAreanotaFilterSelectedValue('');
        // query['areanota'] = '';
        setTipoPrestamoFilterSelectedValue('');
        query['tipoprestamo'] = '';
        setCreadaporFilterSelectedValue('');
        query['creadapor'] = '';
        setRelacionconFilterSelectedValue('');
        // setRelacionconFilterSelectedValue(undefined);
        document.getElementById('prestamos-fundacion-selectRelacionCon').defaultValue='';
        document.getElementById('prestamos-fundacion-selectRelacionCon').value='';
        query['beneficiarioId'] = '';
        dispatch(fetchPrestamosDatabyQuery({token: users.user.claveAPI, query: query} ));

        setTimeout(() => {
            // console.log('Limpiando de nuevo');
            document.getElementById('prestamos-fundacion-selectRelacionCon').defaultValue='';
            document.getElementById('prestamos-fundacion-selectRelacionCon').value='';
            setRelacionconFilterSelectedValue('');
        }, 1500);

    };
    const makeFilter = (q) => {
        q['page'] = '1';
        q['prestamo'] = searchText.trim() || '';
        dispatch(fetchPrestamosDatabyQuery({token: users.user.claveAPI, query: q} ));
    }
    // Filtro por Estado
    const [estadoprestamoFilterSelectedValue, setEstadoprestamoFilterSelectedValue] = useState('');
    const handleEstadoprestamoFilterSelectChange = (value, item) => {
        setEstadoprestamoFilterSelectedValue(value);
        query['estadoprestamo'] = value;
        makeFilter(query);
    };
    const handleEstadoprestamoFilteronKeyDown  = (evt) => {
        const keyCode = evt.keyCode || evt.which
        if (keyCode === 8 || keyCode === 46) {
            setEstadoprestamoFilterSelectedValue('');
            query['estadoprestamo'] = '';
            makeFilter(query);
            evt.returnValue = false
            if (evt.preventDefault) evt.preventDefault()
        }
    }
    // // Filtro por Prioridad
    // const [prioridadFilterSelectedValue, setPrioridadFilterSelectedValue] = useState('');
    // const handlePrioridadFilterSelectChange = (value, item) => {
    //     setPrioridadFilterSelectedValue(value);
    //     query['prioridad'] = value;
    //     makeFilter(query);
    // };
    // const handlePrioridadFilteronKeyDown  = (evt) => {
    //     const keyCode = evt.keyCode || evt.which
    //     if (keyCode === 8 || keyCode === 46) {
    //         setPrioridadFilterSelectedValue('');
    //         query['prioridad'] = '';
    //         makeFilter(query);
    //         evt.returnValue = false
    //         if (evt.preventDefault) evt.preventDefault()
    //     }
    // }
    // // Filtro por Area de Trabajo
    // const [areanotaFilterSelectedValue, setAreanotaFilterSelectedValue] = useState('');
    // const handleAreanotaFilterSelectChange = (value, item) => {
    //     setAreanotaFilterSelectedValue(value);
    //     query['areanota'] = value;
    //     makeFilter(query);
    // };
    // const handleAreanotaFilteronKeyDown  = (evt) => {
    //     const keyCode = evt.keyCode || evt.which
    //     if (keyCode === 8 || keyCode === 46) {
    //         setAreanotaFilterSelectedValue('');
    //         query['areanota'] = '';
    //         makeFilter(query);
    //         evt.returnValue = false
    //         if (evt.preventDefault) evt.preventDefault()
    //     }
    // }
    // Filtro por Tipo Préstamo
    const [tipoprestamoFilterSelectedValue, setTipoPrestamoFilterSelectedValue] = useState('');
    const handleTipoPrestamoFilterSelectChange = (value, item) => {
        setTipoPrestamoFilterSelectedValue(value);
        query['tipoprestamo'] = value;
        makeFilter(query);
    };
    const handleTipoPrestamoFilteronKeyDown  = (evt) => {
        const keyCode = evt.keyCode || evt.which
        if (keyCode === 8 || keyCode === 46) {
            setTipoPrestamoFilterSelectedValue('');
            query['tipoprestamo'] = '';
            makeFilter(query);
            evt.returnValue = false
            if (evt.preventDefault) evt.preventDefault()
        }
    }
    // Filtro por Creada por Usuario
    const [creadaporFilterSelectedValue, setCreadaporFilterSelectedValue] = useState('');
    const handleCreadaporFilterSelectChange = (value, item) => {
        setCreadaporFilterSelectedValue(item.props.children);
        query['creadapor'] = value;
        makeFilter(query);
    };
    const handleCreadaporFilteronKeyDown  = (evt) => {
        const keyCode = evt.keyCode || evt.which
        if (keyCode === 8 || keyCode === 46) {
            setCreadaporFilterSelectedValue('');
            query['creadapor'] = '';
            makeFilter(query);
            evt.returnValue = false
            if (evt.preventDefault) evt.preventDefault()
        }
    }
    // Filtro por Comprobante
    // const [comprobanteFilterSelectedValue, setComprobanteFilterSelectedValue] = useState('');
    // const handleComprobanteFilterSelectChange = (value, item) => {
    //     setComprobanteFilterSelectedValue(item.props.children);
    //     query['comprobdesembolso'] = value;
    //     makeFilter(query);
    // };
    // const handleComprobanteFilteronKeyDown  = (evt) => {
    //     const keyCode = evt.keyCode || evt.which
    //     if (keyCode === 8 || keyCode === 46) {
    //         setComprobanteFilterSelectedValue('');
    //         query['comprobdesembolso'] = '';
    //         makeFilter(query);
    //         evt.returnValue = false
    //         if (evt.preventDefault) evt.preventDefault()
    //     }
    // }
    const handleComprobanteSearchKeyPress = (evt) => {
        const keyCode = evt.keyCode || evt.which
        if (keyCode === 13 || evt.key === 'Enter') {
            evt.returnValue = false
            if (evt.preventDefault) evt.preventDefault();
            // Search
            query['comprobdesembolso'] = searchComprobanteText.trim();
            buscarenPrestamo();
        }
    }
    // const buscarComprobanteTexto = (evt)  => {
    //     evt.preventDefault();
    //     evt.stopPropagation();
    //     // console.log('texto a buscar:', searchComprobanteText);
    //     buscarenPrestamo(searchComprobanteText.trim());
    // }
    
    const handleComprobanteSearchBlur = (value) => {
        // setComprobanteSearchText(value);
        query['comprobdesembolso'] = value;
        buscarenPrestamo();
    }
    const onComprobanteSearchChange = (value) => {
        setComprobanteSearchText(value);
    }
    const onComprobanteSearchRequestClear = () => {
        setComprobanteSearchText('');
        query['page'] = '1';
        query['descripcion'] = '';
        query['comprobdesembolso'] = '';
        dispatch(fetchPrestamosDatabyQuery({token: users.user.claveAPI, query: query} ));
        // buscarenPrestamo(); // No refresca la lista
    }

    // Filtro por Relacionado con
    const [relacionconFilterSelectedValue, setRelacionconFilterSelectedValue] = useState('');
    const handleRelacionconFilterSelectChange = (value, item) => {
        setRelacionconFilterSelectedValue(item.props.children);
        query['beneficiarioId'] = value;
        makeFilter(query);
    };
    const handleRelacionconFilteronBlur = (evt) => {
        const value = evt.target.value;
        // console.log(document.getElementById('prestamos-fundacion-selectRelacionCon'));
        if (value===null || value===undefined || value===''){
            // console.log(relacionconFilterSelectedValue);
            // setRelacionconFilterSelectedValue('');
            document.getElementById('prestamos-fundacion-selectRelacionCon').defaultValue='';
            document.getElementById('prestamos-fundacion-selectRelacionCon').value='';
            query['beneficiarioId'] = '';
            makeFilter(query);
            evt.returnValue = false
            if (evt.preventDefault) evt.preventDefault()
            if (evt.stopPropagation) evt.stopPropagation()
            
        }
         
    }

    const [celularOpenPopoverIds, setCelularOpenPopoverIds] = useState({});
    const toggleCelularPopover = (evt, idx) =>{
        evt.preventDefault();
        evt.stopPropagation();
        let openIds = Object.assign({}, celularOpenPopoverIds);
        openIds[idx] = celularOpenPopoverIds[idx]===false ? true : false;
        setCelularOpenPopoverIds(openIds);
    }
    const celularclosePopover = (evt, idx) =>{
        evt.preventDefault();
        evt.stopPropagation();
        let openIds = Object.assign({}, celularOpenPopoverIds);
        openIds[idx] = false;
        setCelularOpenPopoverIds(openIds);
    }
    const onClickCelularMenuPopover = (evt, action, celular, benefnombre) =>{
        evt.preventDefault();
        evt.stopPropagation();
        if (action==='call'){
            window.open('tel:+57'+ celular);
        } else if (action==='chat'){
            // window.open('https://api.whatsapp.com/send?phone=57'+ celular + '&text=*_Nota automática del GRB_*: %0D%0A %0D%0A '+replaceSomeText(removeHTMLTags(notaSelected.nota))+' %0D%0A Relacionada con *'+benef.nombres.toUpperCase()+' '+benef.apellidos.toUpperCase()+'* ('+benef.docId+').');
            window.open('https://api.whatsapp.com/send?phone=57'+ celular + '&text=*_Nota automática del GRB_*: %0D%0A %0D%0A Hola *'+ benefnombre +'*, la _Fundación Club Farallones_ le recuerda su obligación con... %0D%0A');
        }
    }

    if (resultQueryPrestamos && listUsers ) 
    return (
        <Fragment>
            <ToastContainer />
            {isDisplayAgregarPrestamo && <AgregarPrestamo>
                    <div className="prestamo-editform-title">Crear Nuevo Préstamo</div>
                    <div className="prestamo-editform-editar">
                        {errorenAgregar && <div style={{color: 'red', width: '100%'}}>
                                <Alert red showIcon style={{minWidth: '100%', marginBottom: '10px'}}
                                    showCloseLabel 
                                    onClose={() => setErrorenAgregar(null)}>
                                    <AlertMessage>
                                        Error:{' '}
                                        <strong>{errorenAgregar}</strong>
                                    </AlertMessage>
                                </Alert>
                            </div> 
                        }
                        {/* ITEMS */}
                        
                        { itemsPrestamo && 
                            itemsPrestamo.map((itm, index) => {
                                return (<div key={index} className="itemContainer">
                                    <div className="itemContainer-attr" 
                                        style={{backgroundColor:(index % 2 === 0) ? '#f8f8f8': '#fff'}}
                                    >{itm.label}</div>
                                    <div className="itemContainer-value" 
                                        style={{backgroundColor:(index % 2 === 0) ? '#f8f8f8': '#fff'}}
                                    >
                                    {   
                                        (itm.key==='id' || itm.key==='fecharegistro' || itm.key==='creadapor')
                                        ?
										<ContentEditable
                                            id={'prestamo_'+itm.key}
                                            style={{color:'#ccc'}}
											html={itm.value || ''} // innerHTML of the editable div
											className='itemContainer-value-long'
											disabled={true}      // use true to disable editing
											tagName='div' // Use a custom HTML tag (uses a div by default)
										/>
                                        :
                                        itm.key==='tipoprestamo' 
                                        ?
                                            <Select filterable positionFixed 
                                                // disabled={!isEditable} 
                                                placeholder={'Seleccione...'}
                                                onChange={handleTipoPrestamoSelectChange}
                                                onKeyDown={handleTipoPrestamoonKeyDown}
                                                value={tipoprestamoSelectedValue || ''}
                                                selectedValue={tipoprestamoSelectedValue || ''}
                                            >
                                            {tipodePrestamo.tipo.map(e => {
                                                return (
                                                <MenuItem key={e.id} value={e.name}>
                                                    {e.name}
                                                </MenuItem>
                                                )
                                            })}
                                            </Select>
                                        :
                                        // itm.key==='prioridad' 
                                        // ?
                                        //     <Select filterable positionFixed 
                                        //         // disabled={!isEditable} 
                                        //         placeholder={'Seleccione...'}
                                        //         onChange={handlePrioridadSelectChange}
                                        //         onKeyDown={handlePrioridadonKeyDown}
                                        //         value={prioridadSelectedValue || ''}
                                        //         selectedValue={prioridadSelectedValue || ''}
                                        //     >
                                        //     {prioridadPrestamo.tipo.map(e => {
                                        //         return (
                                        //         <MenuItem key={e.id} value={e.name}>
                                        //             {e.name}
                                        //         </MenuItem>
                                        //         )
                                        //     })}
                                        //     </Select>
                                        // :
                                        // itm.key==='areanota' 
                                        // ?
                                        //     <Select filterable positionFixed 
                                        //         // disabled={!isEditable} 
                                        //         placeholder={'Seleccione...'}
                                        //         onChange={handleAreanotaSelectChange}
                                        //         onKeyDown={handleAreanotaonKeyDown}
                                        //         value={areaprestamoSelectedValue || ''}
                                        //         selectedValue={areaprestamoSelectedValue || ''}
                                        //     >
                                        //     {areadeTrabajoPrestamos.tipo.map(e => {
                                        //         return (
                                        //         <MenuItem key={e.id} value={e.name}>
                                        //             {e.name}
                                        //         </MenuItem>
                                        //         )
                                        //     })}
                                        //     </Select>
                                        // :
                                        itm.key==='estadoprestamo' 
                                        ?
                                            <Select filterable positionFixed 
                                                // disabled={!isEditable} 
                                                placeholder={'Seleccione...'}
                                                onChange={handleEstadoprestamoSelectChange}
                                                onKeyDown={handleEstadoprestamoonKeyDown}
                                                value={estadoprestamoSelectedValue || ''}
                                                selectedValue={estadoprestamoSelectedValue || ''}
                                            >
                                            {estadoPrestamos.tipo.map(e => {
                                                return (
                                                <MenuItem key={e.id} value={e.name}>
                                                    {e.name}
                                                </MenuItem>
                                                )
                                            })}
                                            </Select>
                                        :
                                        itm.key==='fecharegistro' 
                                        ?
                                            <DatePicker 
                                                placeholder="Fecha Registro"
                                                id="fecharegistro"
                                                // style={{backgroundColor:(index % 2 === 0) ? '#f8f8f8 !important': '#fff !important'}}
                                                date={fechaRegistro}
                                                focused={fechaRegistroPickerFocused}
                                                onDateChange={onFechaRegistroChange}
                                                onFocusChange={onFechaRegistroFocusChange}
                                                numberOfMonths={1}
                                                // disabled={!isEditable}
                                                readOnly={true}
                                                hideInputIcon
                                                disableScroll={false}
                                                displayFormat={'YYYY-MM-DD'} // 'MMM DD YYYY'
                                                // isOutsideRange={() => {}}
                                                isOutsideRange={fechaRegistro => fechaRegistro.isBefore(minDate) || fechaRegistro.isAfter(maxDate)}
                                                monthYearSelectionMode="MONTH_YEAR"
                                                yearSelectDates={{"startYear" : 2000, "endYear" : new moment().year() + 1}}
                                                // displayFormat={moment.localeData().longDateFormat('L')}
                                                // appendToBody={true}
                                                // withPortal={true}
                                            />
                                            :
                                            itm.key==='fechaaprobado' 
                                            ?
                                            <DatePicker 
                                                placeholder="Fecha aprobado"
                                                id="fechaaprobado"
                                                date={fechaAprobado}
                                                focused={fechaAprobadoPickerFocused}
                                                onDateChange={onFechaAprobadoChange}
                                                onFocusChange={onFechaAprobadoFocusChange}
                                                numberOfMonths={1}
                                                disabled={!isEditable}
                                                readOnly={true}
                                                hideInputIcon
                                                disableScroll={false}
                                                displayFormat={'YYYY-MM-DD'} // 'MMM DD YYYY'
                                                // isOutsideRange={() => {}}
                                                isOutsideRange={fechaAprobado => fechaAprobado.isBefore(minDate) || fechaAprobado.isAfter(maxDate)}
                                                monthYearSelectionMode="MONTH_YEAR"
                                                yearSelectDates={{"startYear" : 2000, "endYear" : new moment().year() + 1}}
                                                // displayFormat={moment.localeData().longDateFormat('L')}
                                                // appendToBody={true}
                                                // withPortal={true}
                                            />
                                            :
                                            itm.key==='fechadesembolso' 
                                            ?
                                                <DatePicker 
                                                    placeholder="Fecha desembolso"
                                                    id="fechadesembolso"
                                                    date={fechaDesembolso}
                                                    focused={fechaDesembolsoPickerFocused}
                                                    onDateChange={onFechaDesembolsoChange}
                                                    onFocusChange={onFechaDesembolsoFocusChange}
                                                    numberOfMonths={1}
                                                    disabled={!isEditable}
                                                    readOnly={true}
                                                    hideInputIcon
                                                    disableScroll={false}
                                                    displayFormat={'YYYY-MM-DD'} // 'MMM DD YYYY'
                                                    // isOutsideRange={() => {}}
                                                    isOutsideRange={fechaDesembolso => fechaDesembolso.isBefore(minDate) || fechaDesembolso.isAfter(maxDate)}
                                                    monthYearSelectionMode="MONTH_YEAR"
                                                    yearSelectDates={{"startYear" : 2000, "endYear" : new moment().year() + 1}}
                                                    // displayFormat={moment.localeData().longDateFormat('L')}
                                                    // appendToBody={true}
                                                    // withPortal={true}
                                                />
                                            :
                                            itm.key==='beneficiarioId'
                                            ?
                                            <Search positionFixed className="val-search-benef-input"
                                                virtualized
                                                virtualizedMenuWidth={250}
                                                virtualizedRowHeight={75}
                                                placeholder={'Buscar Beneficiario...'}
                                                searchIcon={false}
                                                inputValue={inputBeneficiarioValue || itm.value}
                                                selectedItem={selectedBeneficiarioItem}
                                                onChange={searchBeneficiarioChanged}
                                                onUserAction={onBeneficiarioUserAction}
                                                onRequestClear={clearBeneficiarioSearch}
                                                >
                                                {listBenef.map((item,ix) => {
                                                    const fotoSelBenef = (item.foto==='' || item.foto===null) ? process.env.PUBLIC_URL + '/imgs/team/usuario.jpg' : config.ECASE_URL +'user_images/'+ item.foto; 
                                                    return ( 
                                                    <ListItem
                                                        key={item.docId}
                                                        value={item.docId}
                                                        label={capitalize(item.nombres +' '+ item.apellidos, true)}
                                                        // leftNode={<UserIcon size={32} />}
                                                        leftNode={fotoSelBenef ? <img src={fotoSelBenef} width={32} alt=" " /> : <UserIcon size={32} />}
                                                    >
                                                        <ListItemTitle>{capitalize(item.nombres +' '+ item.apellidos, true)}</ListItemTitle>
                                                        <ListItemSubtitle>{item.actividad +' '+ item.categoria}</ListItemSubtitle>
                                                    </ListItem>
                                                    )
                                                })}
                                            </Search>
                                            :
                                            <ContentEditable
                                                id={'prestamo_'+itm.key}
                                                html={itm.value || ''} // innerHTML of the editable div
                                                className='itemContainer-value-long'
                                                // disabled={!isEditable}      // use true to disable editing
                                                onPaste={pastePlainText} // En caso se copie y pegue de otra pagina de internet
                                                onChange={handleChange} // handle innerHTML change
                                                onKeyPress={disableNewlines}  
                                                onFocus={highlightAll}
                                                onBlur={handleBlur}
                                                tagName='div' // Use a custom HTML tag (uses a div by default)
                                            />
                                    }

                                    </div>
                                </div>)
                            })
                        }

                        {/* <div className="prestamo-observaciones-comentarios">
                            <div className="prestamo-texto-observaciones-title">Descripción:</div>
                            <div className="prestamo-texto-observaciones"> 
                                <Editor 
                                    placeholder={'Escriba la descripción...'}
                                    editorState={editorDescripcionState}
                                    editorClassName="prestamo-comntario-editor"
                                    localization={{ locale: 'es', }}
                                    onEditorStateChange={onDescripcionEditorStateChange}
                                />
                            </div>
                        </div> */}
                        
                        <div className="prestamo-observaciones-comentarios">
                            <div className="prestamo-texto-observaciones-title">Observación:</div>
                            <div className="prestamo-texto-observaciones"> 
                                <Editor 
                                    placeholder={'Escriba un observación...'}
                                    editorState={editorObservacionState}
                                    editorClassName="prestamo-comntario-editor"
                                    localization={{ locale: 'es', }}
                                    onEditorStateChange={onObservacionEditorStateChange}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="prestamo-edit-historico">
                        <HistoricoPrestamos prestamoSelected={prestamoSelected} />
                    </div>

                    <div className="prestamo-edit-botones">
                        <div className="prestamo-edit-botones-btn"> 
                            <Button iconPosition="before" onClick={savePrestamo} icon={<SaveIcon size={16} />}
                            >Guardar</Button>
                        </div>
                        <div className="prestamo-edit-botones-btn"> 
                            <Button iconPosition="before" onClick={(evt) => cancelPrestamo(prestamoSelected.id)} icon={<ResetIcon size={16} />}
                            >Cancelar</Button>
                        </div>
                    </div>
                </AgregarPrestamo>
            }
            {/* EDIT NOTE */}
            {isDisplayEditPrestamo && <EditPrestamo>
                    <div className="prestamo-editform-title">Editar Préstamo</div>
                    <div className="prestamo-editform-editar">
                        {error && <div style={{color: 'red', width: '100%'}}>
                                <Alert red showIcon style={{minWidth: '100%', marginBottom: '10px'}}
                                    showCloseLabel 
                                    onClose={() => setError(null)}>
                                    <AlertMessage>
                                        Error:{' '}
                                        <strong>{error}</strong>
                                    </AlertMessage>
                                </Alert>
                            </div> 
                        }
                        {/* ITEMS */}
                        { itemsPrestamo && 
                            itemsPrestamo.map((itm, index) => {
                                return (<div key={index} className="itemContainer">
                                    <div className="itemContainer-attr" 
                                        style={{backgroundColor:(index % 2 === 0) ? '#f8f8f8': '#fff'}}
                                    >{itm.label}</div>
                                    <div className="itemContainer-value" 
                                        style={{backgroundColor:(index % 2 === 0) ? '#f8f8f8': '#fff'}}
                                    >
                                    {   
                                        (itm.key==='id' || itm.key==='beneficiarioId' || itm.key==='fecharegistro' || itm.key==='creadapor')
                                        ?
										<ContentEditable
                                            id={'prestamo_'+itm.key}
                                            style={{color:'#ccc'}}
											html={itm.value || ''} // innerHTML of the editable div
											className='itemContainer-value-long'
											disabled={true}      // use true to disable editing
											tagName='div' // Use a custom HTML tag (uses a div by default)
										/>
                                        :
                                        itm.key==='tipoprestamo' 
                                        ?
                                            <Select filterable positionFixed 
                                                disabled={!isEditable} 
                                                placeholder={'Seleccione...'}
                                                onChange={handleTipoPrestamoSelectChange}
                                                onKeyDown={handleTipoPrestamoonKeyDown}
                                                value={tipoprestamoSelectedValue || ''}
                                                selectedValue={tipoprestamoSelectedValue || ''}
                                            >
                                            {tipodePrestamo.tipo.map(e => {
                                                return (
                                                <MenuItem key={e.id} value={e.name}>
                                                    {e.name}
                                                </MenuItem>
                                                )
                                            })}
                                            </Select>
                                        :
                                        // itm.key==='prioridad' 
                                        // ?
                                        //     <Select filterable positionFixed 
                                        //         disabled={!isEditable} 
                                        //         placeholder={'Seleccione...'}
                                        //         onChange={handlePrioridadSelectChange}
                                        //         onKeyDown={handlePrioridadonKeyDown}
                                        //         value={prioridadSelectedValue || ''}
                                        //         selectedValue={prioridadSelectedValue || ''}
                                        //     >
                                        //     {prioridadPrestamo.tipo.map(e => {
                                        //         return (
                                        //         <MenuItem key={e.id} value={e.name}>
                                        //             {e.name}
                                        //         </MenuItem>
                                        //         )
                                        //     })}
                                        //     </Select>
                                        // :
                                        // itm.key==='areanota' 
                                        // ?
                                        //     <Select filterable positionFixed 
                                        //         disabled={!isEditable} 
                                        //         placeholder={'Seleccione...'}
                                        //         onChange={handleAreanotaSelectChange}
                                        //         onKeyDown={handleAreanotaonKeyDown}
                                        //         value={areaprestamoSelectedValue || ''}
                                        //         selectedValue={areaprestamoSelectedValue || ''}
                                        //     >
                                        //     {areadeTrabajoPrestamos.tipo.map(e => {
                                        //         return (
                                        //         <MenuItem key={e.id} value={e.name}>
                                        //             {e.name}
                                        //         </MenuItem>
                                        //         )
                                        //     })}
                                        //     </Select>
                                        // :
                                        itm.key==='estadoprestamo' 
                                        ?
                                            <Select filterable positionFixed 
                                                disabled={!isEditable} 
                                                placeholder={'Seleccione...'}
                                                onChange={handleEstadoprestamoSelectChange}
                                                onKeyDown={handleEstadoprestamoonKeyDown}
                                                value={estadoprestamoSelectedValue || ''}
                                                selectedValue={estadoprestamoSelectedValue || ''}
                                            >
                                            {estadoPrestamos.tipo.map(e => {
                                                return (
                                                <MenuItem key={e.id} value={e.name}>
                                                    {e.name}
                                                </MenuItem>
                                                )
                                            })}
                                            </Select>
                                        :
                                        itm.key==='fecharegistro' 
                                        ?
                                            <DatePicker 
                                                placeholder="Fecha registro"
                                                id="fecharegistro"
                                                // style={{backgroundColor:(index % 2 === 0) ? '#f8f8f8 !important': '#fff !important'}}
                                                date={fechaRegistro}
                                                focused={fechaRegistroPickerFocused}
                                                onDateChange={onFechaRegistroChange}
                                                onFocusChange={onFechaRegistroFocusChange}
                                                numberOfMonths={1}
                                                disabled={!isEditable}
                                                readOnly={true}
                                                hideInputIcon
                                                disableScroll={false}
                                                displayFormat={'YYYY-MM-DD'} // 'MMM DD YYYY'
                                                // isOutsideRange={() => {}}
                                                isOutsideRange={fechaRegistro => fechaRegistro.isBefore(minDate) || fechaRegistro.isAfter(maxDate)}
                                                monthYearSelectionMode="MONTH_YEAR"
                                                yearSelectDates={{"startYear" : 2000, "endYear" : new moment().year() + 1}}
                                                // displayFormat={moment.localeData().longDateFormat('L')}
                                                // appendToBody={true}
                                                // withPortal={true}
                                            />
                                        :
                                        itm.key==='fechaaprobado' 
                                        ?
                                            <DatePicker 
                                                placeholder="Fecha aprobado"
                                                id="fechaaprobado"
                                                date={fechaAprobado}
                                                focused={fechaAprobadoPickerFocused}
                                                onDateChange={onFechaAprobadoChange}
                                                onFocusChange={onFechaAprobadoFocusChange}
                                                numberOfMonths={1}
                                                disabled={!isEditable}
                                                readOnly={true}
                                                hideInputIcon
                                                disableScroll={false}
                                                displayFormat={'YYYY-MM-DD'} // 'MMM DD YYYY'
                                                // isOutsideRange={() => {}}
                                                isOutsideRange={fechaAprobado => fechaAprobado.isBefore(minDate) || fechaAprobado.isAfter(maxDate)}
                                                monthYearSelectionMode="MONTH_YEAR"
                                                yearSelectDates={{"startYear" : 2000, "endYear" : new moment().year() + 1}}
                                                // displayFormat={moment.localeData().longDateFormat('L')}
                                                // appendToBody={true}
                                                // withPortal={true}
                                            />
                                            :
                                            itm.key==='fechadesembolso' 
                                            ?
                                                <DatePicker 
                                                    placeholder="Fecha desembolso"
                                                    id="fechadesembolso"
                                                    date={fechaDesembolso}
                                                    focused={fechaDesembolsoPickerFocused}
                                                    onDateChange={onFechaDesembolsoChange}
                                                    onFocusChange={onFechaDesembolsoFocusChange}
                                                    numberOfMonths={1}
                                                    disabled={!isEditable}
                                                    readOnly={true}
                                                    hideInputIcon
                                                    disableScroll={false}
                                                    displayFormat={'YYYY-MM-DD'} // 'MMM DD YYYY'
                                                    // isOutsideRange={() => {}}
                                                    isOutsideRange={fechaDesembolso => fechaDesembolso.isBefore(minDate) || fechaDesembolso.isAfter(maxDate)}
                                                    monthYearSelectionMode="MONTH_YEAR"
                                                    yearSelectDates={{"startYear" : 2000, "endYear" : new moment().year() + 1}}
                                                    // displayFormat={moment.localeData().longDateFormat('L')}
                                                    // appendToBody={true}
                                                    // withPortal={true}
                                                />
                                            :
                                        itm.key==='beneficiarioId'
                                        ?
                                        <Search positionFixed className="val-search-benef-input"
                                            disabled={!isEditable} 
                                            virtualized
                                            virtualizedMenuWidth={250}
                                            virtualizedRowHeight={75}
                                            placeholder={'Buscar Beneficiario...'}
                                            searchIcon={false}
                                            inputValue={inputBeneficiarioValue || itm.value}
                                            selectedItem={selectedBeneficiarioItem}
                                            onChange={searchBeneficiarioChanged}
                                            onUserAction={onBeneficiarioUserAction}
                                            onRequestClear={clearBeneficiarioSearch}
                                            >
                                            {listBenef.map((item,ix) => {
                                                const fotoSelBenef = (item.foto==='' || item.foto===null) ? process.env.PUBLIC_URL + '/imgs/team/usuario.jpg' : config.ECASE_URL +'user_images/'+ item.foto; 
                                                return ( 
                                                <ListItem
                                                    key={item.docId}
                                                    value={item.docId}
                                                    label={capitalize(item.nombres +' '+ item.apellidos, true)}
                                                    // leftNode={<UserIcon size={32} />}
                                                    leftNode={fotoSelBenef ? <img src={fotoSelBenef} width={32} alt=" " /> : <UserIcon size={32} />}
                                                >
                                                    <ListItemTitle>{capitalize(item.nombres +' '+ item.apellidos, true)}</ListItemTitle>
                                                    <ListItemSubtitle>{item.actividad +' '+ item.categoria}</ListItemSubtitle>
                                                </ListItem>
                                                )
                                            })}
                                        </Search>
                                        :

                                        <ContentEditable
											id={'prestamo_'+itm.key}
											html={itm.value || ''} // innerHTML of the editable div
											className='itemContainer-value-long'
											disabled={!isEditable}      // use true to disable editing
											onPaste={pastePlainText} // En caso se copie y pegue de otra pagina de internet
											onChange={handleChange} // handle innerHTML change
											onKeyPress={disableNewlines}  
											onFocus={highlightAll}
											onBlur={handleBlur}
											tagName='div' // Use a custom HTML tag (uses a div by default)
										/>
                                    }

                                    </div>
                                </div>)
                            })
                        }

                        {/* <div className="prestamo-observaciones-comentarios">
                            <div className="prestamo-texto-observaciones-title">Descripción:</div>
                            <div className="prestamo-texto-observaciones"> 
                            { isEditable ?
                                <Editor placeholder={'Escriba la descripción...'}
                                    editorState={editorDescripcionState}
                                    editorClassName="prestamo-comntario-editor"
                                    localization={{ locale: 'es', }}
                                    onEditorStateChange={onDescripcionEditorStateChange}
                                />
                                :
                                    <ContentEditable
                                        id={'notaedit_nota'}
                                        html={prestamoSelected.prestamo || ''} // innerHTML of the editable div
                                        // disabled={users.user.docId===prestamoSelected.creadapor ? false : true}        // use true to disable editing
                                        disabled={true} 
                                        onPaste={pastePlainText}
                                        onChange={handleChange} // handle innerHTML change
                                        onFocus={highlightAll}
                                        onBlur={handleBlur}
                                        tagName='div' // Use a custom HTML tag (uses a div by default)
                                        style={{ "minHeight": "100px", "border": "1px dashed", backgroundColor:'#fdfdca', padding:'0px 8px' }}
                                    />
                                }
                            </div>
                        </div> */}
                        
                        <div className="prestamo-observaciones-comentarios">
                            <div className="prestamo-texto-observaciones-title">Observación:</div>
                            <div className="prestamo-texto-observaciones"> 
                                {/* {isEditable ? */}
                                    <Editor placeholder={'Escriba un observación...'}
                                        editorState={editorObservacionState}
                                        editorClassName="prestamo-comntario-editor"
                                        toolbar={{
                                            inline: { inDropdown: true },
                                            list: { inDropdown: true },
                                            textAlign: { inDropdown: true },
                                            link: { inDropdown: true },
                                            embedded: { inDropdown: true },
                                            emoji: { inDropdown: true },
                                            image: { inDropdown: true },
                                            remove: { inDropdown: true },
                                            history: { inDropdown: true },
                                        }}
                                        localization={{ locale: 'es', }}
                                        onEditorStateChange={onObservacionEditorStateChange}
                                    />
                            </div>
                        </div>

                    </div>
                    
                    <div className="prestamo-edit-historico">
                        <HistoricoPrestamos prestamoSelected={prestamoSelected} />
                    </div>

                    <div className="prestamo-edit-botones">
                        <div className="prestamo-edit-botones-btn"> 
                            <Button iconPosition="before" onClick={savePrestamo} icon={<SaveIcon size={16} />}
                            >Guardar</Button>
                        </div>
                        {isEditable && <div className="prestamo-edit-botones-btn"> 
                            <Button iconPosition="before" onClick={(evt) => eliminarPrestamo(evt, prestamoSelected.id)} icon={<TrashIcon size={16} />}
                            >Eliminar</Button>
                        </div>}
                    </div>
                </EditPrestamo>
            }

            {resultQueryPrestamos && resultQueryPrestamos.datos.length<0 && <Loader color="#009937" text="Cargando Prestamos..."/>}


            {!isDisplayEditPrestamo && !isDisplayAgregarPrestamo && 
                <div className='prestamos-fundacion-lista'>
                    <div className="prestamos-fundacion-toolbar-container">
                        <div className="prestamos-fundacion-toolbar">
                            <div className="prestamos-fundacion-toolbar-left">
                                <div className="prestamos-fundacion-toolbarLeft-tools">
                                    <div className="prestamos-fundacion-toolbarLeft-btn">
                                        <Button inline small iconPosition="before" 
                                            onClick={onBtnAgregarPrestamo}
                                            icon={<PlusIcon size={16} />}
                                        >
                                            Agregar
                                        </Button>
                                    </div>
                                    <div className="prestamos-fundacion-toolbarLeft-btn">
                                        <Button inline small iconPosition="before" 
                                            onClick={onBtnFiltrarPrestamo}
                                            icon={<FilterIcon size={16} />}
                                        >
                                            Filtrar
                                        </Button>
                                    </div>
                                </div>
                            </div>
                            <div className="prestamos-fundacion-toolbar-right">
                                <div className="prestamos-fundacion-toolbarRight-tools">
                                    {/* <div className="prestamos-fundacion-toolbarLeft-btn">
                                        <Button inline small iconPosition="before" 
                                            onClick={onBtnAgregarPrestamo}
                                            icon={<CalendarIcon size={16} />}
                                        >
                                            Fecha
                                        </Button>
                                    </div> */}
                                    <div className="prestamos-fundacion-toolbarLeft-btn">
                                        <div className="prestamos-fundacion-toolbarLeft-btn-Search">
                                            <TextField style={{display:'inline-flex'}}
                                                value={searchText}
                                                onChange={e => onSearchChange(e.target.value)}
                                                onRequestClear={onSearchRequestClear}
                                                // onBlur={(e) => handleSearchBlur(e.target.value)}
                                                onKeyPress={handleSearchKeyPress}
                                                search
                                                placeholder="Buscar..."
                                            />
                                            <div className="prestamos-fundacion-toolbarLeft-btnBuscar"
                                                onClick={buscarTexto}
                                                >Buscar</div>
                                        </div>
                                        <div className="prestamos-fundacion-toolbarLeft-btn-SearchSmall">
                                            <Button inline small iconPosition="before" 
                                                onClick={toggleBuscarTool}
                                                icon={<SearchIcon size={16} />}
                                            >
                                                Buscar
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Espacio para el Filtro y opciones para Mobiles */}
                    <div className="prestamos-fundacion-options-container">
                        <div id='buscar-tool-mobile' className="prestamos-fundacion-toolbarLeft-btn-Search-mobile">
                            <TextField style={{display:'inline-flex'}}
                                value={searchText}
                                onChange={e => onSearchChange(e.target.value)}
                                onRequestClear={onSearchRequestClear}
                                onKeyPress={handleSearchKeyPress}
                                search
                                placeholder="Buscar..."
                            />
                            <div className="prestamos-fundacion-toolbarLeft-btnBuscar"
                                onClick={buscarTexto}
                                >Buscar</div>
                        </div>
                        
                        {/* FILTROS */}
                        <div id='filtro-tool-mobile' className="prestamos-fundacion-lista-filtroMobile">
                            <div className="prestamos-fundacion-lista-filtro">
                                <div className="prestamos-fundacion-lista-optfiltros">
                                    <div className="prestamos-fundacion-lista-optfiltros-toolContainer">
                                         <div className="prestamos-fundacion-lista-optfiltros-tool">
                                            <div className="prestamos-fundacion-lista-optfiltros-toolname">
                                                Estado del préstamo:
                                            </div>
                                            <Select filterable positionFixed style={{width:'120px'}}
                                                // disabled={!isEditable} 
                                                placeholder={'Estado préstamo...'}
                                                onChange={handleEstadoprestamoFilterSelectChange}
                                                onKeyDown={handleEstadoprestamoFilteronKeyDown}
                                                value={estadoprestamoFilterSelectedValue || ''}
                                                selectedValue={estadoprestamoFilterSelectedValue || ''}
                                            >
                                            {estadoPrestamos.tipo.map(e => {
                                                return (
                                                <MenuItem key={e.id} value={e.name}>
                                                    {e.name}
                                                </MenuItem>
                                                )
                                            })}
                                            </Select>
                                        </div>
                                    </div>
                                   {/*  <div className="prestamos-fundacion-lista-optfiltros-toolContainer">
                                        <div className="prestamos-fundacion-lista-optfiltros-tool">
                                            <div className="prestamos-fundacion-lista-optfiltros-toolname">
                                                Prioridad:
                                            </div>
                                            <Select filterable positionFixed style={{width:'120px'}}
                                                // disabled={!isEditable} 
                                                placeholder={'Prioridad...'}
                                                onChange={handlePrioridadFilterSelectChange}
                                                onKeyDown={handlePrioridadFilteronKeyDown}
                                                value={prioridadFilterSelectedValue || ''}
                                                selectedValue={prioridadFilterSelectedValue || ''}
                                            >
                                            {prioridadPrestamo.tipo.map(e => {
                                                return (
                                                <MenuItem key={e.id} value={e.name}>
                                                    {e.name}
                                                </MenuItem>
                                                )
                                            })}
                                            </Select>
                                        </div>
                                    </div>
                                    <div className="prestamos-fundacion-lista-optfiltros-toolContainer">
                                        <div className="prestamos-fundacion-lista-optfiltros-tool">
                                            <div className="prestamos-fundacion-lista-optfiltros-toolname">
                                                Area de Trabajo:
                                            </div>
                                            <Select filterable positionFixed 
                                                // disabled={!isEditable} 
                                                placeholder={'Area...'}
                                                onChange={handleAreanotaFilterSelectChange}
                                                onKeyDown={handleAreanotaFilteronKeyDown}
                                                value={areanotaFilterSelectedValue || ''}
                                                selectedValue={areanotaFilterSelectedValue || ''}
                                            >
                                            {areadeTrabajoPrestamos.tipo.map(e => {
                                                return (
                                                <MenuItem key={e.id} value={e.name}>
                                                    {e.name}
                                                </MenuItem>
                                                )
                                            })}
                                            </Select>
                                        </div>
                                    </div>*/}
                                    <div className="prestamos-fundacion-lista-optfiltros-toolContainer">
                                        <div className="prestamos-fundacion-lista-optfiltros-tool">
                                            <div className="prestamos-fundacion-lista-optfiltros-toolname">
                                                Tipo de Préstamo:
                                            </div>
                                            <Select filterable positionFixed 
                                                // disabled={!isEditable} 
                                                placeholder={'Tipo Préstamo...'}
                                                onChange={handleTipoPrestamoFilterSelectChange}
                                                onKeyDown={handleTipoPrestamoFilteronKeyDown}
                                                value={tipoprestamoFilterSelectedValue || ''}
                                                selectedValue={tipoprestamoFilterSelectedValue || ''}
                                            >
                                            {tipodePrestamo.tipo.map(e => {
                                                return (
                                                <MenuItem key={e.id} value={e.name}>
                                                    {e.name}
                                                </MenuItem>
                                                )
                                            })}
                                            </Select>
                                        </div>
                                    </div> 

                                    <div className="prestamos-fundacion-lista-optfiltros-toolContainer">
                                        <div className="prestamos-fundacion-lista-optfiltros-tool">
                                            <div className="prestamos-fundacion-lista-optfiltros-toolname">
                                                Beneficiario:
                                            </div>
                                                <Select id='prestamos-fundacion-selectRelacionCon' filterable positionFixed 
                                                    virtualized
                                                    virtualizedMenuWidth={200}
                                                    virtualizedRowHeight={40}
                                                    // disabled={!isEditable} 
                                                    placeholder={'Beneficiario...'}
                                                    onChange={handleRelacionconFilterSelectChange}
                                                    onBlur={handleRelacionconFilteronBlur}
                                                    selectedValue={relacionconFilterSelectedValue || ''}
                                                >
                                                {listBenef.map(e => {
                                                    return (
                                                    <MenuItem key={e.id} value={e.docId}>
                                                        {capitalize(e.nombres+' '+e.apellidos, true)}
                                                    </MenuItem>
                                                    )
                                                })}
                                                </Select>
                                        </div>
                                    </div>

                                    <div className="prestamos-fundacion-lista-optfiltros-toolContainer">
                                        <div className="prestamos-fundacion-lista-optfiltros-tool">
                                            <div className="prestamos-fundacion-lista-optfiltros-toolname">
                                                Creado por:
                                            </div>
                                            <Select filterable positionFixed 
                                                // disabled={!isEditable} 
                                                placeholder={'Creado por...'}
                                                onChange={handleCreadaporFilterSelectChange}
                                                onKeyDown={handleCreadaporFilteronKeyDown}
                                                value={creadaporFilterSelectedValue || ''}
                                                selectedValue={creadaporFilterSelectedValue || ''}
                                            >
                                            {listUsers.map(e => {
                                                return (
                                                <MenuItem key={e.id} value={e.docId}>
                                                    {capitalize(e.nombre, true)}
                                                </MenuItem>
                                                )
                                            })}
                                            </Select>
                                        </div>
                                    </div>

                                    <div className="prestamos-fundacion-lista-optfiltros-toolContainer">
                                        <div className="prestamos-fundacion-lista-optfiltros-tool">
                                            <div className="prestamos-fundacion-lista-optfiltros-toolname">
                                                Comprobante:
                                            </div>
                                            <TextField style={{display:'inline-flex'}}
                                                value={searchComprobanteText}
                                                onChange={e => onComprobanteSearchChange(e.target.value)}
                                                onRequestClear={onComprobanteSearchRequestClear}
                                                onBlur={(e) => handleComprobanteSearchBlur(e.target.value)}
                                                onKeyPress={handleComprobanteSearchKeyPress}
                                                // search
                                                placeholder="Comprobante..."
                                            />
                                            {/* <Select filterable positionFixed style={{width:'120px'}}
                                                virtualized
                                                virtualizedMenuWidth={200}
                                                virtualizedRowHeight={40}
                                                // disabled={!isEditable} 
                                                placeholder={'Comprobante...'}
                                                onChange={handleComprobanteFilterSelectChange}
                                                onKeyDown={handleComprobanteFilteronKeyDown}
                                                // onBlur={handleComprobanteFilteronBlur}
                                                value={comprobanteFilterSelectedValue || ''}
                                                selectedValue={comprobanteFilterSelectedValue || ''}
                                            >
                                            {listComprobantes.map(e => {
                                                return (
                                                <MenuItem key={e.id} value={e.factura}>
                                                    {e.factura}
                                                </MenuItem>
                                                )
                                            })}
                                            </Select> */}
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="prestamo-edit-botones-btnRemoveFilter"> 
                                <Button iconPosition="before" onClick={clearFilters} icon={<ResetIcon size={16} />}
                                >Quitar Filtros</Button>
                            </div>
                        </div>
                        
                    </div>

                    { resultQueryPrestamos && resultQueryPrestamos.datos.length>0 ? 
                        <div className='prestamos-fundacion-tablaContainer'>
                            <div className='prestamos-fundacion-tabla'>
                            { resultQueryPrestamos.datos.map((itm, index) => {
                                const numprestamo = index;
                                // const foto_creador = itm.foto==='' ? process.env.PUBLIC_URL + '/imgs/team/usuario.jpg' : config.ECASE_URL +'user_images/'+ Beneficiario.foto; 
                                // const foto_creador = (itm.foto==='' || itm.foto===null) ? process.env.PUBLIC_URL + '/imgs/team/usuario.jpg' : process.env.PUBLIC_URL + '/imgs/team/'+ itm.foto; 
                                let fotobenef = (itm.fotobenef==='' || itm.fotobenef===null) ? process.env.PUBLIC_URL + '/imgs/team/usuario.jpg' : config.ECASE_URL +'user_images/'+ itm.fotobenef;
                                if (itm.beneficiarioId==='' || itm.beneficiarioId==='FUNDACIÓN'){
                                    fotobenef = process.env.PUBLIC_URL + '/imgs/team/fundacion.png';
                                }
                                const benefnombre = (itm.benefnombres==='' || itm.benefnombres===null) ? '' : capitalize(itm.benefnombres,true);
                                const benefapellidos = (itm.benefapellidos==='' || itm.benefapellidos===null) ? '' : capitalize(itm.benefapellidos,true);
                                // const fullName = (itm.nombre==='' || itm.nombre===null) ? '' : capitalize(itm.nombre, true);
                                // const nombre = fullName ? fullName.split(' ')[0] +' '+ fullName.split(' ')[1] : '';
                                // const iniciales = getFirstWords(fullName);
                                // const fecharegistro = itm.fecharegistro ? moment(itm.fecharegistro, 'YYYY-MM-DD').format('YYYY-MMM-DD').split('-') : '';
                                const fechaaprobado  = itm.fechaaprobado ? moment(itm.fechaaprobado, 'YYYY-MM-DD').format('MMMM DD, YYYY') : '';
                                const fechadesembolso  = itm.fechadesembolso ? moment(itm.fechadesembolso, 'YYYY-MM-DD').format('MMM DD, YYYY') : '';
                                return (<div key={index}  className="prestamos-fundacion-lista-row" style={{backgroundColor:(index % 2 === 0) ? '#fff': '#fff'}}
                                        onClick={(evt) => onBtnEditPrestamo(evt, itm.id, index)} >
                                    
                                    {/* <div className="prestamos-fundacion-lista-cell">{itm.id}</div> */}
                                    <div className="prestamos-fundacion-header">
                                        <div className="prestamos-fundacion-lista-header-left">
                                            <div className="prestamos-fundacion-componente-column">
                                                <div className="prestamos-fundacion-componente-row">
                                                    <div className="prestamos-fundacion-componente-beneficiario">
                                                        {itm.activo==='0' && <div className="prestamos-beneficiario-activo-noactivo">No activo</div>}
                                                        {itm.activo==='1' && <div className="prestamos-beneficiario-activo-activo">Activo</div>}
                                                        <div className="prestamos-fundacion-lista-relacioncon-foto"><img src={fotobenef} alt=' ' /></div>
                                                        <div className="prestamos-fundacion-lista-relacioncon-docId">{itm.beneficiarioId}</div>
                                                    </div>
                                                    <div className="prestamos-fundacion-componente-column">
                                                        {itm.estado && itm.estado.toLowerCase()==='revisar' && <div className="prestamos-beneficiario-estado prestamos-beneficiario-estado-revisar">{itm.estado}</div>}
                                                        {itm.estado && itm.estado.toLowerCase()==='visitar' && <div className="prestamos-beneficiario-estado prestamos-beneficiario-estado-visitar">{itm.estado}</div>}
                                                        {itm.estado && itm.estado.toLowerCase()==='retirado' && <div className="prestamos-beneficiario-estado prestamos-beneficiario-estado-retirado">{itm.estado}</div>}
                                                        {itm.estado && itm.estado.toLowerCase()==='fallecido' && <div className="prestamos-beneficiario-estado prestamos-beneficiario-estado-fallecido">{itm.estado}</div>}
                                                        {itm.estado && itm.estado.toLowerCase()==='noapto' && <div className="prestamos-beneficiario-estado prestamos-beneficiario-estado-noapto">{itm.estado}</div>}
                                                        {itm.estado && itm.estado.toLowerCase()==='pendiente' && <div className="prestamos-beneficiario-estado prestamos-beneficiario-estado-pendiente">{itm.estado}</div>}
                                                        {itm.estado && itm.estado.toLowerCase()==='prueba' && <div className="prestamos-beneficiario-estado prestamos-beneficiario-estado-prueba">{itm.estado}</div>}
                                                        {itm.estado && itm.estado.toLowerCase()==='suspendido' && <div className="prestamos-beneficiario-estado prestamos-beneficiario-estado-suspendido">{itm.estado}</div>}
                                                        {itm.estado && itm.estado.toLowerCase()==='actualizado' && <div className="prestamos-beneficiario-estado prestamos-beneficiario-estado-actualizado">{itm.estado}</div>}

                                                        <div className="prestamos-fundacion-lista-relacioncon-nombre multi-line-ellipsis"><strong>{benefnombre}</strong> {benefapellidos}</div>
                                                        <div className="prestamos-beneficiario-actividad one-line-ellipsis">{itm.actividad}</div>
                                                        {itm.celular!=='' && 
                                                            <div className="prestamos-beneficiario-celular">
                                                                {/* Cel.: {itm.celular} */}
                                                                <MobileIcon size={16} style={{marginRight:'4px'}}/>
                                                                { itm.celular ? 
                                                                    <Popover
                                                                        targetEl={<Button transparent extraSmall style={{padding:'0px',top:'-3px',color:'#a2a2a2'}}
                                                                                onClick={(evt) => toggleCelularPopover(evt,numprestamo)}>{itm.celular}</Button>}
                                                                            open={celularOpenPopoverIds[numprestamo]}
                                                                            onRequestClose={(evt) => celularclosePopover(evt, numprestamo)}
                                                                            closeOnSelect
                                                                            positionFixed
                                                                            // appendToBody
                                                                        >
                                                                        <Menu style={{ maxWidth: '150px' }}>
                                                                            {/* <MenuTitle>Contactar Caddie</MenuTitle> */}
                                                                            <MenuItem onClick={(evt) => onClickCelularMenuPopover(evt, 'call', itm.celular, benefnombre)}
                                                                                // href={"tel:+57" + itm.celular} target="_new"
                                                                                >
                                                                                <SmartphoneIcon/>Llamar al {itm.celular}
                                                                            </MenuItem>
                                                                            <MenuItem onClick={(evt) => onClickCelularMenuPopover(evt, 'chat', itm.celular, benefnombre)}
                                                                                // href={"https://api.whatsapp.com/send?phone=57" + itm.celular + "&text=Hola *"+ benefnombre +"*, la _Fundación Club Farallones_ le recuerda su obligación con..."}  target="_new"
                                                                                >
                                                                                <WhatsappIcon/> Escribir mensaje
                                                                            </MenuItem>
                                                                            {/* <MenuTitle>Más opciones</MenuTitle>
                                                                            <MenuItem>Correo electrónico</MenuItem>
                                                                            <MenuItem>Llamar al Fiador</MenuItem> */}
                                                                        </Menu>
                                                                    </Popover>
                                                                    : ''
                                                                } 

                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                                
                                                {/* <div className="prestamos-fundacion-componente-column">
                                                    <div className="prestamos-beneficiario-prestarservicio">{itm.prestarservicio}</div>
                                                </div> */}
                                            </div>
                                        </div>

                                        <div className="prestamos-fundacion-lista-header-center">
                                            <div className="prestamos-fundacion-componente-column">
                                                <div className="prestamos-fundacion-componente-row">
                                                    <div className="prestamos-fundacion-lista-tipoprestamo">{capitalize(itm.tipoprestamo, true)} - </div>
                                                    <div className="prestamos-fundacion-lista-claseprestamo">{itm.claseprestamo}</div>
                                                </div>
                                                {itm.descripcion!=='' && <div className="prestamos-fundacion-lista-descripcion multi-line-ellipsis">{itm.descripcion.toLowerCase()}</div>}

                                                <div className="prestamos-fundacion-componente-column">
                                                    <div className="prestamos-fundacion-lista-fechaaprobado">Aprobado: {capitalize(fechaaprobado,true)}</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="prestamos-fundacion-lista-header-center">
                                            <div className="prestamos-fundacion-componente-column">
                                                {itm.fiador!=='' && <div className="prestamos-fundacion-lista-fiador">Fiador: <span>{itm.fiador}</span></div>}
                                                {itm.fiadorId!=='' && <div className="prestamos-fundacion-lista-fiadorId">{itm.fiadorId}</div>}
                                                <div className="prestamos-fundacion-lista-vlrcuota">Valor Cuota: <span>$ {formatMoney(itm.vlrcuota)}</span></div>
                                                <div className="prestamos-fundacion-lista-diacorte">Día de corte: <span>{itm.diacorte}</span></div>
                                            </div>
                                        </div>

                                        <div className="prestamos-fundacion-lista-header-center">
                                            <div className="prestamos-fundacion-componente-column">
                                                <div className="prestamos-fundacion-componente-row">
                                                    <div className="prestamos-fundacion-lista-plazo">
                                                        <div className="prestamos-fundacion-lista-plazoTitle">Plazo</div>
                                                        <div className="prestamos-fundacion-lista-plazoValue">{itm.plazo} meses</div>
                                                    </div>
                                                    <div className="prestamos-fundacion-lista-tasapactada">
                                                        <div className="prestamos-fundacion-lista-tasapactadaTitle">Tasa</div>
                                                        <div className="prestamos-fundacion-lista-tasapactadaValue">{parseFloat(itm.tasapactada).toFixed(2)} %</div>
                                                    </div>
                                                    <div className="prestamos-fundacion-lista-cuotaspendientes">
                                                        <div className="prestamos-fundacion-lista-cuotaspendientesTitle">Cuotas Pend.</div>
                                                        <div className="prestamos-fundacion-lista-cuotaspendientesValue">{itm.cuotaspendientes}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="prestamos-fundacion-lista-header-right">
                                            <div className="prestamos-fundacion-componente-column">
                                                <div className="prestamos-fundacion-componente-column">
                                                    <div className="prestamos-fundacion-lista-comprobdesembolso">Comprobante <span>{itm.comprobdesembolso}</span></div>
                                                    <div className="prestamos-fundacion-lista-fechadesembolso">{capitalize(fechadesembolso,true)}</div>
                                                    <div className="prestamos-fundacion-lista-monto">$ {formatMoney(itm.monto)}</div>
                                                    
                                                    { itm.estadoprestamo && itm.estadoprestamo.toLowerCase()==='activo' && <div className="prestamos-fundacion-lista-estadoprestamo estadoprestamo-activo">{itm.estadoprestamo.toUpperCase()}</div>}
                                                    { itm.estadoprestamo && itm.estadoprestamo.toLowerCase()==='pendiente' && <div className="prestamos-fundacion-lista-estadoprestamo estadoprestamo-pendiente">{itm.estadoprestamo.toUpperCase()}</div>}
                                                    { itm.estadoprestamo && itm.estadoprestamo.toLowerCase()==='finalizado' && <div className="prestamos-fundacion-lista-estadoprestamo estadoprestamo-finalizado">{itm.estadoprestamo.toUpperCase()}</div>}
                                                    { itm.estadoprestamo && itm.estadoprestamo.toLowerCase()==='mora' && <div className="prestamos-fundacion-lista-estadoprestamo estadoprestamo-mora">{itm.estadoprestamo.toUpperCase()}</div>}
                                                    { itm.estadoprestamo && itm.estadoprestamo.toLowerCase()==='anulado' && <div className="prestamos-fundacion-lista-estadoprestamo estadoprestamo-anulado">{itm.estadoprestamo.toUpperCase()}</div>}
                                                    
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    {/* <div className="prestamos-fundacion-lista-footer">
                                        <div className="prestamos-fundacion-lista-comprobdesembolso">{itm.comprobdesembolso}</div>
                                        <div className="prestamos-fundacion-lista-actualizacion">Última actualización: {itm.actualizacion} por <span>{itm.modificadopor}</span></div>
                                    </div> */}
                                    <div className="prestamos-fundacion-lista-actualizacion"><span>{itm.modificadopor} - {itm.actualizacion}</span></div>

                                </div>)
                                })
                            }
                            </div>
                            
                        </div>
                    :
                        <div>No hay registros</div>
                    }
                   
                </div>

            }
            
            {/* Pagenation */}
            {!isDisplayEditPrestamo && !isDisplayAgregarPrestamo &&  resultQueryPrestamos && resultQueryPrestamos.datos.length>0 && 
                <div className="prestamos-fundacion-tabla-footerContainer">
                    <div className="prestamos-fundacion-tabla-footer">
                        <div className="prestamos-fundacion-cantidad">{resultQueryPrestamos.stats.count || 0} {resultQueryPrestamos.stats.count>1 ? 'prestamos' : 'prestamo'}</div>
                        <div className="prestamos-fundacion-pagina">Página {currentPage+1} de {totalPages}</div>
                    </div>
                    
                    <Pagination
                        className="pagination pagination-prestamos"
                        totalPages={totalPages}
                        currentPage={currentPage}
                        showPages={showPages}
                        onChangePage={onPageNumberChange}
                    />
                </div>
            }
            

        </Fragment>
    );
    return (<></>)
}
  
class TablePrestamos extends React.Component {
    constructor(props) {
      super(props);
      this.state = {};
    }
    
    render() {
    //   var {dataList} = this.state;
      const {containerHeight, containerWidth} = this.props; //{height, width, ...props}
      return (
        <div style={{ width:containerWidth, height:containerHeight }}>
            <ModulePrestamos />
        </div>
      );
    }
}

// See react-dimensions for the best way to configure
// https://github.com/digidem/react-dimensions
export default Dimensions({
    elementResize: true,
  
    getHeight: function(element) {
      // return window.innerHeight - 300;
    //   console.log(element.parentElement.clientHeight);
      let heightOffset = 50;
      let heightParent = element.clientHeight;
      return heightParent - heightOffset;
    },
    getWidth: function(element) {
      // var widthOffset = window.innerWidth < 680 ? 52 : 412;
      // return window.innerWidth - widthOffset;
      let widthOffset = 5;
      return element.clientWidth - widthOffset;
    }
})(TablePrestamos);