import React, {useState, useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";
import ContentEditable from 'react-contenteditable';
import { updateBeneficiarioCita, serverMensaje } from '../../redux/reducers/beneficiarios';
import {capitalize, highlightAll, disableNewlines, pastePlainText } from '../../utils/utils';
import Alert, {AlertMessage} from 'calcite-react/Alert';
import {showToaster} from './BenefShowMesg';
import Switch from 'calcite-react/Switch';
import Accordion, {
    AccordionSection,
    AccordionTitle,
    AccordionContent
} from 'calcite-react/Accordion';
import DatePicker from 'calcite-react/DatePicker';
import moment from 'moment';
import 'moment/locale/es-us';
import styled from "styled-components";

const BeneficiarioModulo = styled.div`
    height: auto;
    background: #fff;
    border: 0;
    padding: 0px;
    margin: 4px;
    overflow:hidden;
`;

const AccordionModulo = styled(Accordion)`
    padding:0;
`;
const AccordionTitleModule = styled(AccordionTitle)`
    background-color: #e6e6e6;
    color: #4d4d4d;
    font-size: 1.25em;
    font-weight: 700;
    // border-bottom: 1px solid #d9d9d9;
    // box-shadow: 0px 2px 6px #e1e1e1;
`;


const BenefCitas = ({citas}) => {
    const dispatch = useDispatch();
    const mensaje = useSelector(state => state.beneficiarios.mensaje);
    const users = useSelector(state => state.users);
    // const isEditable = users.user.tipo==='ADMINISTRADOR' ? true : false;
    const isEditable = (users.user.areatrabajo==='ADMINISTRATIVA' || users.user.areatrabajo==='TRABAJO SOCIAL' || users.user.areatrabajo==='PSICOLOGÍA' ) ? true : false;
    const [activeSectionIndexes, setActiveSectionIndexes] = useState([]);
    const [error, setError] = useState(null);
    const onAccordionChange = (evt, index) => {
        activeSectionIndexes.includes(index)
        ? setActiveSectionIndexes(activeSectionIndexes.filter(
            item => index !== item,
          ))
        : setActiveSectionIndexes([...activeSectionIndexes, index]);
    }
    const [C] = useState({
        "id": citas.id,
        "beneficiarioId": citas.beneficiarioId,
        "fecha": citas.fecha.split(' ')[0] || '',
        "horario": citas.horario || '',
        "encuestador": capitalize(citas.encuestador,true) || '',
        "agendo": capitalize(citas.agendo, true) || '',
        "visitado": citas.visitado==='1' ? true : false,
        "observaciones": citas.observaciones || '',
        "actualizacion":citas.actualizacion || '',
        "version": citas.version || ''
    });

    const [itemsCita, setItemsCita] = useState([
        // {"key":"id", "label": "Identificador Único", "value": C['id']},
        // {"key":"docId", "label": "Documento de Identificación", "value": C['beneficiarioId']},
        {"key":"fecha", "label": "Fecha de Visita", "value": C['fecha']},
        {"key":"horario", "label": "Hora Visita", "value": C['horario']},
        {"key":"encuestador", "label": "Encuestador", "value": C['encuestador']},
        {"key":"agendo", "label": "Agendó", "value": C['agendo']},
        {"key":"visitado", "label": "Visitado", "value": C['visitado']},
        {"key":"observaciones", "label": "Observaciones", "value": C['observaciones']},
        // {"key":"actualizacion", "label": "Última actualización", "value": C['actualizacion']},
        // {"key":"version", "label": "Versión", "value": C['version']}
    ]);

    // let citasUpdated = Object.assign({}, C);
    // const [isUpdated, setIsUpdated] = useState(false);
    const [fechaCita, setFechaCita] = useState(moment(C.fecha.split(' ')[0],'YYYY-MM-DD')); //C.fecha.split(' ')[0].replace('-','/'));
    const [fechaCitaPickerFocused, setFechaCitaPickerFocused] = useState(false);
   
    useEffect(() => {
        // Guardar los resultados al salir, puede ser contraproducente al fallar la conexión con el servidor u otro evento no deseado
        // se podrián perder los datos modificafos
        
        return () => {
            // if (isUpdated){
            //     console.log('Saving appointment', isUpdated);
            // }
            dispatch(serverMensaje({}));
        }
    }, [dispatch]);

    useEffect(() => {
        if (itemsCita){
            // console.log(itemsCita);
        }
        if (mensaje.cita && mensaje.cita!=='') {
            if (typeof mensaje.cita === 'string' && mensaje.cita.toLowerCase().includes('error')){
                showToaster('error', 'Ocurrió un fallo en el servidor', mensaje.cita);
            } 
            // else {
            //     showToaster('success',"Cita del Beneficiario", mensaje.cita); //"🧭 " + mensaje
            // }
        }

    }, [mensaje.cita, itemsCita, dispatch]);

    const updateItemsCita = (f, v) => {
        const newitemsCita = itemsCita.map((itm, index) => {
            if (itm.key===f){
                // console.log(itm.key, f, itm.value, v);
                itm.value = v;
            }
            return itm;
        });
        setItemsCita(newitemsCita);
    };

    const updateCitasonServer = () =>{
        dispatch(serverMensaje({cita:''}));
        dispatch(updateBeneficiarioCita( { token: users.user.claveAPI, cita:C}));
    }

    const replaceSomeText = (s) =>{
        return s
            .replace(/&nbsp;/g, '')
            .replace(/<br>/g, '')
            .replace(/&amp;/g, '&')
            .replace(/&gt;/g, '>')
            .replace(/&lt;/g, '<').trim()
    }

    const validateInput = (f, v, final) => {
        const regex_onlynum = /[0-9,]|\./;
        // const regex_nombres = /#[a-zA-Z\xE1\xE9\xED\xF3\xFA\xC1\xC9\xCD\xD3\xDA\xF1\xD1]+#/i; // /^[a-zA-Z ]+$/;
        const regex_nombres = /^['`´a-zA-ZÀ-ÿ\u00C0-\u017F\s]+$/;
        let new_val = null;
        let err = null;
        switch (f) {
            case 'fecha':
                new_val = replaceSomeText(v).substr(0,10);
                break;
            case 'horario':
                new_val = v.substr(0,8).toUpperCase();
                if (final)
                    new_val = replaceSomeText(v).substr(0,8).toUpperCase();
                break;
            case 'encuestador':
                    new_val = capitalize(replaceSomeText(v), true).substr(0,64);
                    if (!regex_nombres.test(new_val)) {
                        err = 'Dígite un nombre válido';
                        new_val = null;
                    }
                    break;
            case 'agendo':
                new_val = capitalize(replaceSomeText(v), true).substr(0,64);
                if (!regex_nombres.test(new_val)) {
                    err = 'Dígite un nombre válido';
                    new_val = null;
                }
                break;
            case 'visitado': // Valida cuando el campo es de texto y no un componente Switch
                new_val = v;
                if (!regex_onlynum.test(v)) {
                    err = 'Dígite un solo número válido';
                    new_val = null;
                } else if(parseInt(v)>1){
                    err = 'Dígite un número válido';
                    new_val = null;
                }
                break;
            case 'observaciones':
                new_val = v.substr(0,255);
                if (final)
                    new_val = replaceSomeText(v).substr(0,255);
                break;
            default:
                break;
        }
        setError(err);
        return new_val
    }
    const handleChange = evt => {
        // console.log(evt.currentTarget.id, evt.target.value);
        const field = evt.currentTarget.id.split('_')[1];
        const new_val = validateInput(field, evt.target.value, false)
        if(new_val){
            C[field] = new_val
        };
    };
    const handleBlur = evt => {
        const val = evt.currentTarget.innerHTML;
        const field = evt.target.id.split('_')[1];
        // console.log(field, val);
        const new_val = validateInput(field, val, true)
        if(new_val || new_val===''){
            C[field] = new_val;
            updateItemsCita(field, C[field]);
            updateCitasonServer(); // Save to server inmediatively
        };
        
    };

    const onChangeVisitado = (evt) => {
        C.visitado =  evt.target.checked; // ? '1' : '0';
        updateItemsCita('visitado', C.visitado);
        updateCitasonServer(); // Save to server inmediatively
    }

    // DatePicker
    const onFechaCitaChange = (date) =>{
        if (date.preventDefault) date.preventDefault()
        if (date){
            // console.log("onFechaCitaChange formatted==>",date.format('YYYY-MM-DD'));
            C.fecha = date.utc().format('YYYY-MM-DD HH:mm:ss');
            setFechaCita(date);
            updateCitasonServer();
        }
        
    }
    const onFechaCitaFocusChange = ({ focused }) =>{
        setFechaCitaPickerFocused(focused)
    }

    return (
        <BeneficiarioModulo>
            <AccordionModulo
                onAccordionChange={onAccordionChange}
                activeSectionIndexes={activeSectionIndexes}
            >
                <AccordionSection>
                    <AccordionTitleModule>Encuestas / Cita</AccordionTitleModule>
                    {activeSectionIndexes.length>0 &&  <AccordionContent style={{marginBottom: '10px'}}>
                        {error && <div style={{color: 'red', width: '100%'}}>
                                <Alert red showIcon style={{minWidth: '100%', marginBottom: '10px'}}
                                    showCloseLabel 
                                    onClose={() => setError(null)}>
                                    <AlertMessage>
                                        Error:{' '}
                                        <strong>{error}</strong>
                                    </AlertMessage>
                                </Alert>
                            </div> 
                        }
                        {/* ITEMS */}
                        {
                            itemsCita.map((itm, index) => {
                                // if (itm.value!==null && itm.value!=="" ){
                                    return (<div key={index} className="itemContainer">
                                        <div className="itemContainer-attr" 
                                            style={{backgroundColor:(index % 2 === 0) ? '#f8f8f8': '#fff'}}
                                        >{itm.label}</div>
                                        <div className="itemContainer-value" 
                                            style={{backgroundColor:(index % 2 === 0) ? '#f8f8f8': '#fff'}}
                                        >
                                            {/* {itm.value} */}
                                            { itm.key==='visitado' 
                                                ?
                                                    <Switch 
                                                        labelPosition={"before" || '' }
                                                        style={{margin: '0 0 0.5rem 0'}}
                                                        name={ 'visitado_' + C.id } 
                                                        checked={ C.visitado || false} 
                                                        disabled={!isEditable} 
                                                        onChange={onChangeVisitado} />
                                                :

                                                itm.key==='fecha' 
                                                ?
                                                    <DatePicker 
                                                        placeholder="Fecha"
                                                        id="fechaCita"
                                                        // style={{backgroundColor:(index % 2 === 0) ? '#f8f8f8 !important': '#fff !important'}}
                                                        date={fechaCita}
                                                        focused={fechaCitaPickerFocused}
                                                        onDateChange={onFechaCitaChange}
                                                        onFocusChange={onFechaCitaFocusChange}
                                                        numberOfMonths={1}
                                                        disabled={!isEditable}
                                                        readOnly={true}
                                                        hideInputIcon
                                                        disableScroll={false}
                                                        displayFormat={'YYYY-MM-DD'} // 'MMM DD YYYY'
                                                        isOutsideRange={() => {}}
                                                        monthYearSelectionMode="MONTH_YEAR"
                                                        // displayFormat={moment.localeData().longDateFormat('L')}
                                                        // appendToBody={true}
                                                        // withPortal={true}
                                                    />
                                                :
                                                    <ContentEditable
                                                        id={'cita_'+itm.key}
                                                        html={itm.value || ''} // innerHTML of the editable div
                                                        className='itemContainer-value-long'
                                                        disabled={!isEditable}      // use true to disable editing
                                                        onPaste={pastePlainText} // En caso se copie y pegue de otra pagina de internet
                                                        onChange={handleChange} // handle innerHTML change
                                                        onKeyPress={disableNewlines}  
                                                        onFocus={highlightAll}
                                                        onBlur={handleBlur}
                                                        tagName='div' // Use a custom HTML tag (uses a div by default)
                                                    />
                                                
                                            }

                                        </div>
                                    </div>)
                                // } 
                            })
                        }

                        <div className="benef-texto-actualizacion">Última actualización: {C['actualizacion']}</div>

                    </AccordionContent>
                }
                </AccordionSection>
            </AccordionModulo>
        </BeneficiarioModulo>
    );
    
}

export default BenefCitas;

