import React, {useState, useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";
import ContentEditable from 'react-contenteditable';
import { updateBeneficiarioEncuestado, serverMensaje } from '../../redux/reducers/beneficiarios';
import {capitalize, highlightAll, disableNewlines, pastePlainText, capitalizeFirstLetter } from '../../utils/utils';
import Alert, {AlertMessage} from 'calcite-react/Alert';
import Button, { ButtonGroup } from 'calcite-react/Button';
import Accordion, {
    AccordionSection,
    AccordionTitle,
    AccordionContent
} from 'calcite-react/Accordion';
// import Radio from 'calcite-react/Radio';
import Select from 'calcite-react/Select';
import {MenuItem} from 'calcite-react/Menu';
// import Form, {
//     FormControl,
//     Fieldset,
// } from 'calcite-react/Form';
import DatePicker from 'calcite-react/DatePicker';
import moment from 'moment';
import 'moment/locale/es-us';

import {showToaster} from './BenefShowMesg';
import tiposEducacion from '../../data/tipoeducacion.json';
import estadosCiviles from '../../data/estadocivil.json';
import jornadasEscolares from '../../data/jornadaescolar.json';
import tipoParentescos from '../../data/parentesco.json';
import tipoDocs from '../../data/tipodoc.json';
import tipoGeneros from '../../data/generos.json';
import tipoOcupacion from '../../data/ocupaciones.json';

import styled from "styled-components";

const BeneficiarioModulo = styled.div`
    height: auto;
    background: #fff;
    border: 0;
    padding: 0px;
    margin: 4px;
    overflow:hidden;
`;

const AccordionModulo = styled(Accordion)`
    padding:0;
`;
const AccordionTitleModule = styled(AccordionTitle)`
    background-color: #e6e6e6;
    color: #4d4d4d;
    font-size: 1.25em;
    font-weight: 700;
    // border-bottom: 1px solid #d9d9d9;
    // box-shadow: 0px 2px 6px #e1e1e1;
`;


const BenefEncuestado = ({encuestado}) => {
    const dispatch = useDispatch();
    const mensaje = useSelector(state => state.beneficiarios.mensaje);
    const users = useSelector(state => state.users);
    // const isEditable = users.user.tipo==='ADMINISTRADOR' ? true : false;
    // const isEditable = (users.user.tipo==='ADMINISTRADOR' && users.user.role==='A' && users.user.permisos==='A') ? true : false;
    const isEditable = (users.user.areatrabajo==='ADMINISTRATIVA' || users.user.areatrabajo==='TRABAJO SOCIAL' || users.user.areatrabajo==='COORDINACIÓN' || users.user.areatrabajo==='PSICOLOGÍA' ) ? true : false;
    // const canView = (users.user.areatrabajo==='ADMINISTRATIVA' || users.user.areatrabajo==='TECNOLOGÍA' ) ? true : false;
    
    const [activeSectionIndexes, setActiveSectionIndexes] = useState([]);
    const [estudiaActualmenteSelected, setEstudiaActualmenteSelected] = useState(parseInt(encuestado.estudiaactualmente));
    // const [generoSelected, setGeneroSelected] = useState(encuestado.genero);
    const [generoSelectedValue, setGeneroSelectedValue] = useState(encuestado.genero);
    const [jefehogarSelected, setJefehogarSelected] = useState(encuestado.jefehogar);
    // const [tipodocSelected, setTipodocSelected] = useState(encuestado.tipodoc);
    const [tipodocSelectedValue, setTipodocSelectedValue] = useState(encuestado.tipodoc);
    const [estadocivilSelectedValue, setEstadoCivilSelectedValue] = useState(capitalizeFirstLetter(encuestado.estadocivil));
    const [educacionSelectedValue, setEducacionSelectedValue] = useState(capitalize(encuestado.educacion, true));
    const [parentescoSelectedValue, setParentescoSelectedValue] = useState(capitalize(encuestado.parentesco, true));
    const [jornadaSelectedValue, setJornadaSelectedValue] = useState(encuestado.jornada==='' ? 'No Aplica' : capitalize(encuestado.jornada, true));
    const [fechaNacimiento, setFechaNacimiento] = useState(moment(encuestado.fechanacimiento.split(' ')[0],'YYYY-MM-DD'));
    const [fechanacimientoPickerFocused, setFechanacimientoPickerFocused] = useState(false);
    const [ocupacionSelectedValue, setOcupacionSelectedValue] = useState(capitalizeFirstLetter(encuestado.ocupacion));
    
   
    const [error, setError] = useState(null);
    const onAccordionChange = (evt, index) => {
        activeSectionIndexes.includes(index)
        ? setActiveSectionIndexes(activeSectionIndexes.filter(
            item => index !== item,
          ))
        : setActiveSectionIndexes([...activeSectionIndexes, index]);
    }

    const [E] = useState({
        "id": encuestado.id, //int
        "beneficiarioId": encuestado.beneficiarioId, //15
        "parentesco": encuestado.parentesco, //32
        "docId": encuestado.docId, //15
        "tipodoc": encuestado.tipodoc, //32
        "nombres": encuestado.nombres, //32
        "apellidos": encuestado.apellidos, //32
        "fechanacimiento": encuestado.fechanacimiento, //0000-00-00 00:00:00
        "lugarnacimiento": encuestado.lugarnacimiento, //32
        "estadocivil": capitalizeFirstLetter(encuestado.estadocivil), //20
        "genero": encuestado.genero, //2
        "educacion": encuestado.educacion, //32
        "nivelescolaridad": encuestado.nivelescolaridad || '', //2
        "estudiaactualmente": encuestado.estudiaactualmente, //true/false 1/0
        "jornada": encuestado.jornada==='' ? 'No Aplica' : encuestado.jornada, //20
        "telefono": encuestado.telefono, //bigint
        "email": encuestado.email, //255
        "jefehogar": encuestado.jefehogar, //15
        "personasacargo": encuestado.personasacargo, //32
        "ocupacion": capitalizeFirstLetter(encuestado.ocupacion), //60
        "otraocupacion": encuestado.otraocupacion, //120
        "comentarios": encuestado.comentarios, //text
        "actualizacion": encuestado.actualizacion, //0000-00-00 00:00:00
        "version": encuestado.version,
    });

    const [itemsEncuestado, setItemsEncuestado] = useState([
        // {"key":"id", "label": "Identificador Único", "value": E['id']},
        // {"key":"beneficiarioId", "label": "beneficiarioId", "value": E['beneficiarioId']},
        {"key":"parentesco", "label": "Parentesco con el beneficiario", "value": capitalize(E['parentesco'], true)},
        {"key":"genero", "label": "Género", "value": capitalize(E['genero'], true)},
        {"key":"nombres", "label": "Nombres", "value": capitalize(E['nombres'], true)},
        {"key":"apellidos", "label": "Apellidos", "value": capitalize(E['apellidos'], true)},
        {"key":"docId", "label": "Número Documento de Identidad", "value": E['docId']},
        {"key":"tipodoc", "label": "Tipo de Documento de Identidad", "value": E['tipodoc']},
        {"key":"fechanacimiento", "label": "Fecha de Nacimiento", "value": E['fechanacimiento']},
        {"key":"lugarnacimiento", "label": "Lugar de Nacimiento", "value": capitalize(E['lugarnacimiento'], true)},
        {"key":"estadocivil", "label": "Estado Civil", "value": E['estadocivil']},
        {"key":"educacion", "label": "Educación", "value": capitalize(E['educacion'], true)},
        {"key":"nivelescolaridad", "label": "Nivel de Educación", "value": E['nivelescolaridad']},
        {"key":"estudiaactualmente", "label": "Estudia Actualmente", "value": E['estudiaactualmente']},
        {"key":"jornada", "label": "Jornada Escolar", "value": capitalize(E['jornada'], true)},
        {"key":"telefono", "label": "Número de Teléfono", "value": E['telefono']},
        {"key":"email", "label": "Correo Electrónico o E-mail", "value": E['email']},
        {"key":"jefehogar", "label": "Jefe de Hogar?", "value": E['jefehogar']},
        {"key":"personasacargo", "label": "Número de Personas a Cargo", "value": E['personasacargo']},
        {"key":"ocupacion", "label": "Ocupación", "value": E['ocupacion']},
        {"key":"otraocupacion", "label": "Otros Oficios", "value": capitalize(E['otraocupacion'], true)},
        {"key":"comentarios", "label": "Comentarios en general", "value": E['comentarios']},
        // {"key":"actualizacion", "label": "Última actualización", "value": E['actualizacion']},
        // {"key":"version", "label": "Versión", "value": E['version']}
    ]);

    useEffect(() => {
        // Guardar los resultados al salir, puede ser contraproducente al fallar la conexión con el servidor u otro evento no deseado
        // se podrián perder los datos modificafos
        
        return () => {
            // if (isUpdated){
            //     console.log('Saving appointment', isUpdated);
            // }
            dispatch(serverMensaje({}));
        }
    }, [dispatch]);

    useEffect(() => {
        if (itemsEncuestado){
            // console.log(itemsEncuestado);
        }
        if (mensaje.encuestado && mensaje.encuestado!=='') {
            if (typeof mensaje.encuestado === 'string' && mensaje.encuestado.toLowerCase().includes('error')){
                showToaster('error', 'Ocurrió un fallo en el servidor', mensaje.encuestado);
            } 
            // else {
            //     showToaster('success',"Encuestado/Acudiente del Beneficiario", mensaje.encuestado); //"🧭 " + mensaje
            // }
        }

    }, [mensaje.encuestado, itemsEncuestado, dispatch]);

    const updateItemsEncuestado = (f, v) => {
        const newitemsEncuestado = itemsEncuestado.map((itm, index) => {
            if (itm.key===f){
                itm.value = v;
            }
            return itm;
        });
        setItemsEncuestado(newitemsEncuestado);
    };

    const updateDataonServer = () =>{
        dispatch(serverMensaje({encuestado:''}));
        dispatch(updateBeneficiarioEncuestado( { token: users.user.claveAPI, encuestado:E}));
    }

    const replaceSomeText = (s) =>{
        return s
            .replace(/&nbsp;/g, '')
            .replace(/<br>/g, '')
            .replace(/&amp;/g, '&')
            .replace(/&gt;/g, '>')
            .replace(/&lt;/g, '<').trim()
    }

    const validateInput = (f, v, final) => {
        const regex_onlynum = /^[0-9]*$/i; ///[0-9,]|\./;
        // const regex_nombres = /#[a-zA-Z \xE1\xE9\xED\xF3\xFA\xC1\xC9\xCD\xD3\xDA\xF1\xD1]+#/i; // /^[a-zA-Z ]+$/;
        const regex_nombres = /^['`´a-zA-ZÀ-ÿ\u00C0-\u017F\s]+$/;
        // const regex_email = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        let new_val = null;
        let err = null;
        switch (f) {
            case 'parentesco':
                new_val = replaceSomeText(v).substr(0,32); //.toUpperCase();
                if (!regex_nombres.test(new_val)) {
                    err = 'Dígite un tipo de parentesco válido';
                    new_val = null;
                }
                break;
            case 'docId':
                new_val = replaceSomeText(v).substr(0,15); //.toUpperCase();
                if (!regex_onlynum.test(new_val)) {
                    err = 'Dígite solo carácteres válidos';
                    new_val = null;
                } else if(new_val.length>15){
                    err = 'Dígite un número válido';
                    new_val = null;
                }
                break;
            case 'tipodoc':
                new_val = replaceSomeText(v).substr(0,32);
                break;
            case 'nombres':
                new_val = replaceSomeText(v).substr(0,32); //.toUpperCase();
                if (!regex_nombres.test(new_val)) {
                    err = 'Dígite carácteres válidos';
                    new_val = null;
                }
                break;
            case 'apellidos':
                new_val = replaceSomeText(v).substr(0,32); //.toUpperCase();
                if (!regex_nombres.test(new_val)) {
                    err = 'Dígite carácteres válidos';
                    new_val = null;
                }
                break;
            case 'fechanacimiento': //0000-00-00 00:00:00
                new_val = replaceSomeText(v).substr(0,32);
                if (final && new_val===''){
                    err = 'Dígite una fecha de nacimiento válida';
                    new_val = null;
                }
                break;
            case 'lugarnacimiento':
                new_val = replaceSomeText(v).substr(0,32);
                break;
            case 'estadocivil':
                new_val = replaceSomeText(v).substr(0,20);
                break;
            case 'genero':
                new_val = replaceSomeText(v).substr(0,2);
                if (final && new_val===''){
                    err = 'Dígite un genero válido';
                    new_val = null;
                }
                break;
            case 'educacion':
                new_val = replaceSomeText(v).substr(0,32);
                break;
            case 'nivelescolaridad':
                new_val = replaceSomeText(v).substr(0,2);
                break;
            case 'estudiaactualmente': //true
                new_val = replaceSomeText(v).substr(0,2);
                break;
            case 'jornada':
                new_val = replaceSomeText(v).substr(0,20);
                break;
            case 'telefono':
                new_val = v;
                if (!regex_onlynum.test(v)) {
                    err = 'Dígite solo números válidos de teléfono';
                    new_val = null;
                } else if(v.length>15){
                    err = 'Dígite un número de teléfono fijo válido';
                    new_val = null;
                }
                break;
            case 'email':
                new_val = v.substr(0,255);
                break;
            case 'jefehogar':
                new_val = replaceSomeText(v).substr(0,15);
                break;
            case 'personasacargo':
                new_val = replaceSomeText(v).substr(0,32);
                break;
            case 'ocupacion':
                new_val = replaceSomeText(v).substr(0,60);
                break;
            case 'otraocupacion':
                new_val = replaceSomeText(v).substr(0,120);
                break;
            case 'comentarios':
                new_val = replaceSomeText(v).substr(0,255);
                break;
            default:
                break;
        }
        // E[f] = new_val;
        setError(err);
        return new_val
    }
    const handleChange = evt => {
        const field = evt.currentTarget.id.split('_')[1];
        const new_val = validateInput(field, evt.target.value, false)
        if(new_val){
            E[field] = new_val
        };
    };
    const handleBlur = evt => {
        const val = evt.currentTarget.innerHTML;
        const field = evt.target.id.split('_')[1];
        let new_val = validateInput(field, val, true);
        const regex_email = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        if (field==='email' && !regex_email.test(new_val)){
            setError('Correo eléctronico o email no válido');
            new_val = null;
        }
        if(new_val || new_val===''){
            E[field] = new_val
            updateItemsEncuestado(field, E[field]);
            updateDataonServer();
        };
    };

    const estudiaactualmenteSelectButton = (selected) => {
        setEstudiaActualmenteSelected(selected);
        E['estudiaactualmente'] = selected === 1 ? '1' : '0';
        updateDataonServer();
    };
    // const generoSelectButton = (selected) => {
    //     setGeneroSelected(selected);
    //     E['genero'] = selected;
    //     updateDataonServer();
    // };
    // const generoSelectRadio = (selected) => {
    //     setGeneroSelected(selected);
    //     E['genero'] = selected;
    //     updateDataonServer();
    // };
    const handleGeneroSelectChange = (value, item) => {
        setGeneroSelectedValue(value);
        E['genero'] = value;
        updateDataonServer();
    };

    const jefehogarSelectButton = (selected) => {
        setJefehogarSelected(selected);
        E['jefehogar'] = selected;
        updateDataonServer();
    };
    // const tipodocSelectRadio  = (selected) => {
    //     setTipodocSelected(selected);
    //     E['tipodoc'] = selected;
    //     updateDataonServer();
    // }
    const handleTipodocSelectChange = (value, item) => {
        setTipodocSelectedValue(value);
        E['tipodoc'] = value;
        updateDataonServer();
    };
    

    const handleEstadocivilSelectChange = (value, item) => {
        setEstadoCivilSelectedValue(value);
        E['estadocivil'] = value;
        updateDataonServer();
    };
    const handleParentescoSelectChange = (value, item) => {
        setParentescoSelectedValue(value);
        E['parentesco'] = value;
        updateDataonServer();
    };
    const handleEducacionSelectChange = (value, item) => {
        setEducacionSelectedValue(value);
        E['educacion'] = value;
        updateDataonServer();
    };
    const handleJornadaSelectChange = (value, item) => {
        setJornadaSelectedValue(value);
        E['jornada'] = value;
        updateDataonServer();
    };
    const handleOcupacionSelectChange = (value, item) => {
        setOcupacionSelectedValue(value);
        E['ocupacion'] = value;
        updateDataonServer();
    };
    

    // DatePicker
    const onFechaNacimientoChange = (date) =>{
        if (date.preventDefault) date.preventDefault()
        if (date){
            E.fechanacimiento = date.utc().format('YYYY-MM-DD HH:mm:ss');
            setFechaNacimiento(date);
            updateDataonServer();
        }
        
    }

    // const returnYears = () => {
    //     let years = []
    //     for(let i = moment().year() - 100; i <= moment().year(); i++) {
    //         years.push(<option value={i}>{i}</option>);
    //     }
    //     return years;
    // };
    // const renderMonthElement = ({ month, onMonthSelect, onYearSelect }) => {
    //     return (<div style={{ display: 'flex', justifyContent: 'center' }}>
    //         <div>
    //             <select
    //                 value={month.month()}
    //                 onChange={(e) => onMonthSelect(month, e.target.value)}
    //             >
    //                 {moment.months().map((label, value) => (
    //                     <option value={value}>{label}</option>
    //                 ))}
    //             </select>
    //         </div>
    //         <div>
    //             <select value={month.year()} onChange={(e) => onYearSelect(month, e.target.value)}>
    //                 {returnYears()}
    //             </select>
    //         </div>
    //     </div>)
    // }
    const onFechaNacimientoFocusChange = ({ focused }) =>{
        setFechanacimientoPickerFocused(focused)
    }

    return (
        <BeneficiarioModulo>
            <AccordionModulo
                onAccordionChange={onAccordionChange}
                activeSectionIndexes={activeSectionIndexes}
            >
                <AccordionSection>
                    <AccordionTitleModule>Encuestado / Acudiente</AccordionTitleModule>
                    {activeSectionIndexes.length>0 &&  <AccordionContent style={{marginBottom: '10px'}}>
                    {error && <div style={{color: 'red', width: '100%'}}>
                            <Alert red showIcon style={{minWidth: '100%', marginBottom: '10px'}}
                                showCloseLabel 
                                onClose={() => setError(null)}>
                                <AlertMessage>
                                    Error:{' '}
                                    <strong>{error}</strong>
                                </AlertMessage>
                            </Alert>
                        </div> 
                    }
                    {/* ITEMS */}
                        {
                            itemsEncuestado.map((itm, index) => {
                                // if (itm.value!==null && itm.value!=="" ){
                                    return (<div key={index} className="itemContainer">
                                        <div className="itemContainer-attr" 
                                            style={{backgroundColor:(index % 2 === 0) ? '#f8f8f8': '#fff'}}
                                        >{itm.label}</div>
                                        <div className="itemContainer-value" 
                                            style={{backgroundColor:(index % 2 === 0) ? '#f8f8f8': '#fff'}}
                                        >
                                        {   itm.key==='genero'
                                            ?
                                            <Select filterable positionFixed disabled={!isEditable} 
                                                    onChange={handleGeneroSelectChange}
                                                    selectedValue={generoSelectedValue.trim()}
                                                >
                                                {tipoGeneros.tipo.map(e => {
                                                    return (
                                                    <MenuItem key={e.id} value={e.value}>
                                                        {e.name}
                                                    </MenuItem>
                                                    )
                                                })}
                                                </Select>
                                            :
                                            itm.key==='estudiaactualmente'
                                            ?
                                            <ButtonGroup isToggle>
                                                <Button  clear={estudiaActualmenteSelected !== 1} disabled={!isEditable} 
                                                    onClick={() => {  estudiaactualmenteSelectButton(1) }}
                                                >Si</Button>
                                                <Button clear={estudiaActualmenteSelected !== 0} disabled={!isEditable} 
                                                    onClick={() => { estudiaactualmenteSelectButton(0) }}
                                                >No</Button>
                                            </ButtonGroup>
                                            :
                                            itm.key==='jefehogar'
                                            ?
                                            <ButtonGroup isToggle className="buttonGroupVertical">
                                                <Button  clear={jefehogarSelected.toLowerCase() !== 'si'}
                                                    disabled={!isEditable} 
                                                    onClick={() => {  jefehogarSelectButton('si') }}
                                                >Si</Button>
                                                <Button clear={jefehogarSelected.toLowerCase() !== 'compartido'}
                                                    disabled={!isEditable} 
                                                    onClick={() => { jefehogarSelectButton('compartido') }}
                                                >Compartido</Button>
                                                <Button clear={jefehogarSelected.toLowerCase() !== 'no'}
                                                    disabled={!isEditable} 
                                                    onClick={() => { jefehogarSelectButton('no') }}
                                                >No</Button>
                                            </ButtonGroup>
                                            :
                                            itm.key==='tipodoc' 
                                            ?
                                                <Select filterable positionFixed disabled={!isEditable} 
                                                    onChange={handleTipodocSelectChange}
                                                    selectedValue={tipodocSelectedValue.trim()}
                                                >
                                                {tipoDocs.tipo.map(e => {
                                                    return (
                                                    <MenuItem key={e.id} value={e.name}>
                                                        {e.name}
                                                    </MenuItem>
                                                    )
                                                })}
                                                </Select>
                                            :
                                            itm.key==='estadocivil' 
                                            ?
                                                <Select filterable positionFixed disabled={!isEditable} 
                                                    onChange={handleEstadocivilSelectChange}
                                                    selectedValue={estadocivilSelectedValue.trim()}
                                                >
                                                {estadosCiviles.estadocivil.map(e => {
                                                    return (
                                                    <MenuItem key={e.id} value={e.name}>
                                                        {e.name}
                                                    </MenuItem>
                                                    )
                                                })}
                                                </Select>
                                            :
                                            itm.key==='parentesco' 
                                            ?
                                                <Select filterable positionFixed disabled={!isEditable} 
                                                    onChange={handleParentescoSelectChange}
                                                    selectedValue={parentescoSelectedValue.trim()}
                                                >
                                                {tipoParentescos.tipo.map(p => {
                                                    return (
                                                    <MenuItem key={p.id} value={p.name}>
                                                        {p.name}
                                                    </MenuItem>
                                                    )
                                                })}
                                                </Select>
                                            :
                                            itm.key==='educacion' 
                                            ?
                                                <Select filterable positionFixed disabled={!isEditable} 
                                                    onChange={handleEducacionSelectChange}
                                                    selectedValue={educacionSelectedValue.trim()}
                                                >
                                                {tiposEducacion.tipoeducacion.map(t => {
                                                    return (
                                                    <MenuItem key={t.id} value={t.name}>
                                                        {t.name}
                                                    </MenuItem>
                                                    )
                                                })}
                                                </Select>
                                            :
                                            itm.key==='jornada' 
                                            ?
                                                <Select filterable positionFixed disabled={!isEditable} 
                                                    onChange={handleJornadaSelectChange}
                                                    selectedValue={jornadaSelectedValue.trim()}
                                                >
                                                {jornadasEscolares.jornada.map(j => {
                                                    return (
                                                    <MenuItem key={j.id} value={j.name}>
                                                        {j.name}
                                                    </MenuItem>
                                                    )
                                                })}
                                                </Select>
                                            :
                                            itm.key==='ocupacion' 
                                            ?
                                                <Select filterable positionFixed virtualized
                                                    disabled={!isEditable} 
                                                    virtualizedMenuWidth={200}
                                                    onChange={handleOcupacionSelectChange}
                                                    selectedValue={ocupacionSelectedValue.trim()}
                                                >
                                                {tipoOcupacion.ocupaciones.map(o => {
                                                    return (
                                                    <MenuItem key={o.id} value={o.nombre}>
                                                        {o.nombre}
                                                    </MenuItem>
                                                    )
                                                })}
                                                </Select>
                                            :itm.key==='fechanacimiento' 
                                            ?
                                                <DatePicker 
                                                    placeholder="Fecha Nacimiento"
                                                    id="fechaNacimiento"
                                                    date={fechaNacimiento}
                                                    focused={fechanacimientoPickerFocused}
                                                    onDateChange={onFechaNacimientoChange}
                                                    onFocusChange={onFechaNacimientoFocusChange}
                                                    numberOfMonths={1}
                                                    // enableOutsideDays={true}
                                                    disabled={!isEditable}
                                                    readOnly={true}
                                                    hideInputIcon
                                                    disableScroll={false}
                                                    displayFormat={'YYYY-MM-DD'} // 'MMM DD YYYY'
                                                    // renderMonthElement={renderMonthElement}
                                                    isOutsideRange={() => {}}
                                                    monthYearSelectionMode="MONTH_YEAR"
                                                    // yearSelectDates={{"startYear" : new moment().subtract(new Date().getFullYear(), 100).year(), "endYear" : new moment().year()}}
                                                    yearSelectDates={{"startYear" : 1900, "endYear" : new moment().year()}}
                                                    
                                                    // appendToBody={true}
                                                />
                                            :
                                                <ContentEditable
                                                    id={'encuestado_'+itm.key}
                                                    html={itm.value || ''} // innerHTML of the editable div
                                                    className='itemContainer-value-long'
                                                    disabled={!isEditable}      // use true to disable editing
                                                    onPaste={pastePlainText} // En caso se copie y pegue de otra pagina de internet
                                                    onChange={handleChange} // handle innerHTML change
                                                    onKeyPress={disableNewlines}  
                                                    onFocus={highlightAll}
                                                    onBlur={handleBlur}
                                                    tagName='div' // Use a custom HTML tag (uses a div by default)
                                                />
                                        }

                                        </div>
                                    </div>)
                                // } 
                            })
                        }

                        <div className="benef-texto-actualizacion">Última actualización: {E['actualizacion']}</div>

                    </AccordionContent>
                }
                </AccordionSection>
            </AccordionModulo>
        </BeneficiarioModulo>
    );
}

export default BenefEncuestado;

