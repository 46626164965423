// Copyright 2020 Ernesto Girón E.
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//     http://www.apache.org/licenses/LICENSE-2.0
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.​

import { call, put, takeLatest } from "redux-saga/effects";
import { types as configTypes } from "../reducers/cumpli";
import { 
  getBeneficiariosGolf,
  getBeneficiariosTenis,
  getSociosGolf,
  getBeneficiarioPerfilDataxCumpli,
  getListReservasGolf,
  actualizarListaReservasGolf,
  crearListaReservasGolf,
  eliminarListaReservasGolf,
  crearBatchListaReservasGolf,
  getListReservasHistoricoGolf,
  getListReservasHistoricoGolfxFecha,
  crearListaReservasHistoricoGolf,
  actualizarListaReservasHistoricoGolf,
  eliminarListaReservasHistoricoGolf,
  crearBatchListaReservasHistoricoGolf,
  getTarifasCaddie,
  actualizarTarifasCaddie,
  crearTarifasCaddie,
  eliminarTarifasCaddie,
  obtenerEstadisticasCaddie
} from "../../utils/cumpli_req";

// WORKERS //
function* fetchBenefGolfData(action) {
  try {
    const listGolf = yield call(getBeneficiariosGolf, action.payload.token);
    yield put({
      type: configTypes.CUMPLIMIENTO_BENEFGOLF_LIST_LOADED,
      payload: {listGolf: listGolf}
    });
  } catch (e) {
    console.error("SAGA ERROR: _cumpli/fetchBenefGolfData, ", e);
  }
}

function* fetchBenefTenisData(action) {
  try {
    const listTenis = yield call(getBeneficiariosTenis, action.payload.token);
    yield put({
      type: configTypes.CUMPLIMIENTO_BENEFTENIS_LIST_LOADED,
      payload: {listTenis: listTenis}
    });
  } catch (e) {
    console.error("SAGA ERROR: _cumpli/fetchBenefTenisData, ", e);
  }
}

function* fetchBeneficiarioPerfilDataxCumpli(action) {
  try {
    const datosSelBenef = yield call(getBeneficiarioPerfilDataxCumpli, action.payload);
    if (datosSelBenef)
      yield put({
        type: configTypes.MODULE_CUMPLIMIENTO_BENEFICIARIO_DATA_SELECTED,
        payload: {datosSelBenef: datosSelBenef, selectedProfile:{docId:datosSelBenef.beneficiarios[0].docId}}
      });
  } catch (e) {
    console.error("SAGA ERROR: _cumpli/fetchBeneficiarioPerfilDataxCumpli, ", e);
  }
}


function* fetchBenefSociosData(action) {
  try {
    const listSocios = yield call(getSociosGolf, action.payload.token);
    yield put({
      type: configTypes.CUMPLIMIENTO_SOCIOSGOLF_LIST_LOADED,
      payload: {listSocios: listSocios}
    });
  } catch (e) {
    console.error("SAGA ERROR: _cumpli/fetchBenefSociosData, ", e);
  }
}


// LISTA DE RESERVAS GOLF
function* fetchListReservasGolfData(action) {
  try {
    const listReservas = yield call(getListReservasGolf, action.payload.token);
    yield put({
      type: configTypes.CUMPLIMIENTO_RESERVAS_LIST_LOADED,
      payload: {listReservas: listReservas}
    });
  } catch (e) {
    console.error("SAGA ERROR: _cumpli/fetchListReservasGolfData, ", e);
  }
}
function* updateListaReservasGolf(action) {
  try {
    const resp = yield call(actualizarListaReservasGolf, action.payload);
    if (resp && resp!=='')
      yield put({
        type: configTypes.MODULE_CUMPLIMIENTO_SERVER_MESSAGE,
        payload: {mensaje: {"listareservas": resp}}
      });
  } catch (e) {
    console.error("SAGA ERROR: _cumpli/updateListaReservasGolf, ", e);
  }
}
function* insertListaReservasGolf(action) {
  try {
    const resp = yield call(crearListaReservasGolf, action.payload);
    if (resp && resp!=='')
      yield put({
        type: configTypes.MODULE_CUMPLIMIENTO_SERVER_MESSAGE,
        payload: {mensaje: {"listareservas": resp}}
      });
  } catch (e) {
    console.error("SAGA ERROR: _cumpli/insertListaReservasGolf, ", e);
  }
}
function* deleteListaReservasGolf(action) {
  try {
    const resp = yield call(eliminarListaReservasGolf, action.payload);
    if (resp && resp!=='')
      yield put({
        type: configTypes.MODULE_CUMPLIMIENTO_SERVER_MESSAGE,
        payload: {mensaje: {"listareservas": resp}}
      });
  } catch (e) {
    console.error("SAGA ERROR: _cumpli/deleteListaReservasGolf, ", e);
  }
}
function* insertBatchListaReservasGolf(action) {
  try {
    const resp = yield call(crearBatchListaReservasGolf, action.payload);
    if (resp && resp!=='')
      yield put({
        type: configTypes.MODULE_CUMPLIMIENTO_SERVER_MESSAGE,
        payload: {mensaje: {"listareservas": resp}}
      });
  } catch (e) {
    console.error("SAGA ERROR: _cumpli/insertBatchListaReservasGolf, ", e);
  }
}


// LISTA DE HISTORICO RESERVAS GOLF
function* fetchListReservasHistoricoGolfData(action) {
  try {
    const listReservas = yield call(getListReservasHistoricoGolf, action.payload);
    yield put({
      type: configTypes.CUMPLIMIENTO_RESERVAS_LIST_LOADED,
      payload: {listReservas: listReservas}
    });
  } catch (e) {
    console.error("SAGA ERROR: _cumpli/fetchListReservasHistoricoGolfData, ", e);
  }
}
function* fetchListReservasHistoricoGolfDataxFecha(action) {
  try {
    const listReservas = yield call(getListReservasHistoricoGolfxFecha, action.payload);
    yield put({
      type: configTypes.CUMPLIMIENTO_RESERVAS_LIST_LOADED,
      payload: {listReservas: listReservas}
    });
  } catch (e) {
    console.error("SAGA ERROR: _cumpli/fetchListReservasHistoricoGolfDataxFecha, ", e);
  }
}

function* updateListaReservasHistoricoGolf(action) {
  try {
    const resp = yield call(actualizarListaReservasHistoricoGolf, action.payload);
    if (resp && resp!=='')
      yield put({
        type: configTypes.MODULE_CUMPLIMIENTO_SERVER_MESSAGE,
        payload: {mensaje: {"listareservas": resp}}
      });
  } catch (e) {
    console.error("SAGA ERROR: _cumpli/updateListaReservasHistoricoGolf, ", e);
  }
}
function* insertListaReservasHistoricoGolf(action) {
  try {
    const resp = yield call(crearListaReservasHistoricoGolf, action.payload);
    if (resp && resp!=='')
      yield put({
        type: configTypes.MODULE_CUMPLIMIENTO_SERVER_MESSAGE,
        payload: {mensaje: {"listareservas": resp}}
      });
  } catch (e) {
    console.error("SAGA ERROR: _cumpli/insertListaReservasHistoricoGolf, ", e);
  }
}
function* deleteListaReservasHistoricoGolf(action) {
  try {
    const resp = yield call(eliminarListaReservasHistoricoGolf, action.payload);
    if (resp && resp!=='')
      yield put({
        type: configTypes.MODULE_CUMPLIMIENTO_SERVER_MESSAGE,
        payload: {mensaje: {"listareservas": resp}}
      });
  } catch (e) {
    console.error("SAGA ERROR: _cumpli/deleteListaReservasHistoricoGolf, ", e);
  }
}
function* insertBatchListaReservasHistoricoGolf(action) {
  try {
    const resp = yield call(crearBatchListaReservasHistoricoGolf, action.payload);
    if (resp && resp!=='')
      yield put({
        type: configTypes.MODULE_CUMPLIMIENTO_SERVER_MESSAGE,
        payload: {mensaje: {"listareservas": resp}}
      });
  } catch (e) {
    console.error("SAGA ERROR: _cumpli/insertBatchListaReservasHistoricoGolf, ", e);
  }
}


// LISTA DE TARIFAS CADDIES
function* fetchTarifasCaddieData(action) {
  try {
    const listTarifasCaddie = yield call(getTarifasCaddie, action.payload.token);
    yield put({
      type: configTypes.CUMPLIMIENTO_TARIFASCADDIE_LIST_LOADED,
      payload: {listTarifasCaddie: listTarifasCaddie}
    });
  } catch (e) {
    console.error("SAGA ERROR: _cumpli/fetchTarifasCaddieData, ", e);
  }
}
function* updateTarifasCaddie(action) {
  try {
    const resp = yield call(actualizarTarifasCaddie, action.payload);
    if (resp && resp!=='')
      yield put({
        type: configTypes.MODULE_CUMPLIMIENTO_SERVER_MESSAGE,
        payload: {mensaje: {"tarifascaddie": resp}}
      });
  } catch (e) {
    console.error("SAGA ERROR: _cumpli/updateTarifasCaddie, ", e);
  }
}
function* insertTarifasCaddie(action) {
  try {
    const resp = yield call(crearTarifasCaddie, action.payload);
    if (resp && resp!=='')
      yield put({
        type: configTypes.MODULE_CUMPLIMIENTO_SERVER_MESSAGE,
        payload: {mensaje: {"tarifascaddie": resp}}
      });
  } catch (e) {
    console.error("SAGA ERROR: _cumpli/insertTarifasCaddie, ", e);
  }
}
function* deleteTarifasCaddie(action) {
  try {
    const resp = yield call(eliminarTarifasCaddie, action.payload);
    if (resp && resp!=='')
      yield put({
        type: configTypes.MODULE_CUMPLIMIENTO_SERVER_MESSAGE,
        payload: {mensaje: {"tarifascaddie": resp}}
      });
  } catch (e) {
    console.error("SAGA ERROR: _cumpli/deleteTarifasCaddie, ", e);
  }
}

// LISTA DE ESTADISTICAS CADDIES
function* getStatsxCaddie(action) {
  try {
    const listStatsCaddie = yield call(obtenerEstadisticasCaddie, action.payload.token);
    yield put({
      type: configTypes.CUMPLIMIENTO_STATSCADDIE_LIST_LOADED,
      payload: {listStatsCaddie: listStatsCaddie}
    });
  } catch (e) {
    console.error("SAGA ERROR: _cumpli/getStatsxCaddie, ", e);
  }
}

// WATCHER //
export function* watchFetchCumplimientoData() {
  yield takeLatest(configTypes.CUMPLIMIENTO_BENEFGOLF_DATA_LOADED, fetchBenefGolfData);
  yield takeLatest(configTypes.CUMPLIMIENTO_BENEFTENIS_DATA_LOADED, fetchBenefTenisData);
  yield takeLatest(configTypes.CUMPLIMIENTO_SOCIOSGOLF_DATA_LOADED, fetchBenefSociosData);
  
  yield takeLatest(configTypes.MODULE_CUMPLIMIENTO_TARIFASCADDIE_LOADED, fetchTarifasCaddieData);
  yield takeLatest(configTypes.MODULE_CUMPLIMIENTO_TARIFASCADDIE_UPDATED, updateTarifasCaddie);
  yield takeLatest(configTypes.MODULE_CUMPLIMIENTO_TARIFASCADDIE_CREATED, insertTarifasCaddie);
  yield takeLatest(configTypes.MODULE_CUMPLIMIENTO_TARIFASCADDIE_DELETED, deleteTarifasCaddie);

  yield takeLatest(configTypes.MODULE_CUMPLIMIENTO_LISTRESERVASGOLF_LOADED, fetchListReservasGolfData);
  yield takeLatest(configTypes.MODULE_CUMPLIMIENTO_LISTRESERVASGOLF_BATCH, insertBatchListaReservasGolf);
  yield takeLatest(configTypes.MODULE_CUMPLIMIENTO_LISTRESERVASGOLF_UPDATED, updateListaReservasGolf);
  yield takeLatest(configTypes.MODULE_CUMPLIMIENTO_LISTRESERVASGOLF_CREATED, insertListaReservasGolf);
  yield takeLatest(configTypes.MODULE_CUMPLIMIENTO_LISTRESERVASGOLF_DELETED, deleteListaReservasGolf);

  yield takeLatest(configTypes.MODULE_CUMPLIMIENTO_LISTRESERVASHISTORICOGOLFXFECHA_LOADED, fetchListReservasHistoricoGolfDataxFecha);
  yield takeLatest(configTypes.MODULE_CUMPLIMIENTO_LISTRESERVASHISTORICOGOLF_LOADED, fetchListReservasHistoricoGolfData);
  yield takeLatest(configTypes.MODULE_CUMPLIMIENTO_LISTRESERVASHISTORICOGOLF_BATCH, insertBatchListaReservasHistoricoGolf);
  yield takeLatest(configTypes.MODULE_CUMPLIMIENTO_LISTRESERVASHISTORICOGOLF_UPDATED, updateListaReservasHistoricoGolf);
  yield takeLatest(configTypes.MODULE_CUMPLIMIENTO_LISTRESERVASHISTORICOGOLF_CREATED, insertListaReservasHistoricoGolf);
  yield takeLatest(configTypes.MODULE_CUMPLIMIENTO_LISTRESERVASHISTORICOGOLF_DELETED, deleteListaReservasHistoricoGolf);

  
  yield takeLatest(configTypes.MODULE_CUMPLIMIENTO_STATSCADDIE_LOADED, getStatsxCaddie);
  

  yield takeLatest(configTypes.MODULE_CUMPLIMIENTO_BENEFICIARIO_SELECTED_DATA_REQUEST, fetchBeneficiarioPerfilDataxCumpli);
  
  
}
