import React, {useState, useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";
import ContentEditable from 'react-contenteditable';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import {capitalize, getFirstWords,
    highlightAll, 
    disableNewlines, 
    pastePlainText
} from '../../utils/utils';
import {fetchUsersData} from '../../redux/reducers/users';
import { fetchBeneficiariosData } from '../../redux/reducers/beneficiarios';
import { serverMensaje,
    updateTerapia,
    insertTerapia,
    deleteTerapia,
} from '../../redux/reducers/terapias';

import TextField from 'calcite-react/TextField';
import DatePicker from 'calcite-react/DatePicker';
import moment from 'moment';
import 'moment/locale/es-us';

// import Switch from 'calcite-react/Switch';
import Button, { ButtonGroup } from 'calcite-react/Button';
import Accordion, {
    AccordionSection,
    AccordionTitle,
    AccordionContent
} from 'calcite-react/Accordion';
import {showMsgToaster} from '../ShowMesg';
import {ToastContainer} from 'calcite-react/Toaster'; //notify
import Alert, {AlertMessage} from 'calcite-react/Alert';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import '../../styles/css/font-awesome.min.css';

// import prioridadTerapia from '../../data/prioridadnotificacion.json';

import styled from "styled-components";

// import UserIcon from 'calcite-ui-icons-react/UserIcon';
import ResetIcon from 'calcite-ui-icons-react/ResetIcon';
import SaveIcon from 'calcite-ui-icons-react/SaveIcon';
import PlusIcon from 'calcite-ui-icons-react/SpeechBubblePlusIcon';
import TrashIcon from 'calcite-ui-icons-react/TrashIcon';
// import FilterIcon from 'calcite-ui-icons-react/FilterIcon';
// import CalendarIcon from 'calcite-ui-icons-react/CalendarIcon';
// import SearchIcon from 'calcite-ui-icons-react/SearchIcon';

// import whatsappIcon from '../../assets/icons/whatsapp_white.png';
// const WhatsappIcon = styled.div`
//   width: 16px;
//   height: 16px;
//   position: relative;
//   display: inline-flex;
//   vertical-align: bottom;
// //   opacity: 0.4;
//   margin-right: 10px;
//   background: rgba(255, 255, 255, 0.1) url(${whatsappIcon}) no-repeat center/cover;
// `;

const EditTerapia = styled.div`
    width: 100%;
    // height: 100vh; // calc(100% - 10px);
    position: relative;
    background-color: #fff;
    z-index: 2;
`;
const AgregarTerapia = styled.div`
    width: 100%;
    // height: 100vh; // calc(100% - 10px);
    position: relative;
    background-color: #fff;
    z-index: 2;
`;

const TerapiasModulo = styled.div`
    height: auto;
    background: #fff;
    border: 0;
    padding: 0px;
    margin: 4px;
    overflow:hidden;
`;

const AccordionModulo = styled(Accordion)`
    padding:0;
`;
const AccordionTitleModule = styled(AccordionTitle)`
    background-color: #e6e6e6;
    color: #4d4d4d;
    font-size: 1.25em;
    font-weight: 700;
    // border-bottom: 1px solid #d9d9d9;
    // box-shadow: 0px 2px 6px #e1e1e1;
`;


const BenefTerapias = ({terapias, beneficiarioId}) => {
    const dispatch = useDispatch();
    // const config = useSelector(state => state.config);
    const users = useSelector(state => state.users);
    const listUsers = useSelector(state => state.users.listUsers);
    const listBenef = useSelector(state => state.beneficiarios.listBenef);
    // const isEditable = (users.user.areatrabajo==='ADMINISTRATIVA' || users.user.areatrabajo==='COORDINACIÓN' || users.user.areatrabajo==='TECNOLOGÍA' ) ? true : false;
    const isEditable = (users.user.areatrabajo==='ADMINISTRATIVA' || users.user.areatrabajo==='TRABAJO SOCIAL' || users.user.areatrabajo==='PSICOLOGÍA' ) ? true : false;
    // const [isEditable,setIsEditable] = useState(true); // || users.user.docId===terapiaSelected.creadapor ? true : false);
    const [isDisplayEditTerapia, setIsDisplayEditTerapia] = useState(false);
    const [isDisplayAgregarTerapia, setIsDisplayAgregarTerapia] = useState(false);

    const mensaje = useSelector(state => state.terapias.mensaje);
    const [error, setError] = useState(null);
    const [errorenAgregar, setErrorenAgregar] = useState(null);
    const [asistioSelected, setAsistioSelected] = useState(0);
    const [fechaSesion, setFechaSesion] = useState(moment(new Date(),'YYYY-MM-DD'));
    const [fechaSesionPickerFocused, setFechaSesionPickerFocused] = useState(false);
    const [fechaProxSesion, setFechaProxSesion] = useState(null);
    const [fechaProxSesionPickerFocused, setFechaProxSesionPickerFocused] = useState(false);
    const minDate = moment().subtract(1, 'months').startOf('month');
    const maxDate= moment().add(12, 'months');

    const [terapiaSelected, setTerapiaSelected] = useState({
        "id": '-1', //int
        "beneficiarioId":beneficiarioId,
        "fecha":null,
        "horainicio":null,
        "horafin":null,
        "temasabordados":"",
        "resumen":"",
        "observaciones":"",
        "tareasasignadas":"",
        "fechaproxsesion":null,
        "horaproxsesion":null,
        "asistio":"",
        "creadapor":"",
        "actualizacion":"",
        "version":"",
        "modificadopor":""
    });

    const [itemsTerapia, setItemsTerapia] = useState([
        // {"key":"id", "label": "Id", "value": terapiaSelected['id']},
        // {"key":"beneficiarioId", "label": "beneficiarioId", "value": terapiaSelected['beneficiarioId']},
        
        // {"key":"fecha", "label": "Fecha de Sesión", "value": terapiaSelected['fecha']},
        // {"key":"horainicio", "label": "Hora inicial", "value": terapiaSelected['horainicio']},
        // {"key":"horafin", "label": "Hora final", "value": terapiaSelected['horafin']},

        // {"key":"temasabordados", "label": "Temas abordados", "value": terapiaSelected['temasabordados']},
        // {"key":"resumen", "label": "Resumen", "value": terapiaSelected['resumen']},
        // {"key":"observaciones", "label": "Observaciones", "value": terapiaSelected['observaciones']},
        // {"key":"tareasasignadas", "label": "Tareas asignadas", "value": terapiaSelected['tareasasignadas']},
        {"key":"fechaproxsesion", "label": "Fecha próxima sesión", "value": terapiaSelected['fechaproxsesion']},
        {"key":"horaproxsesion", "label": "Hora próxima sesión", "value": terapiaSelected['horaproxsesion']},
        {"key":"asistio", "label": "Asistió?", "value": terapiaSelected['asistio']},
        // {"key":"creadapor", "label": "Terapia creada por", "value": terapiaSelected['creadapor']},
        // {"key":"modificadopor", "label": "Modificado por", "value": terapiaSelected['modificadopor']},
        // {"key":"comentarios", "label": "Comentarios", "value": terapiaSelected['comentarios']},
        // {"key":"actualizacion", "label": "Actualizacion", "value": terapiaSelected['actualizacion']},
        // {"key":"version", "label": "Version", "value": terapiaSelected['version']},
    ]);

    useEffect(() => {
        if (!listUsers || listUsers.length<0){
            dispatch(fetchUsersData({token: users.user.claveAPI} ));
        }
        if (!listBenef || listBenef.length<=0){
            dispatch(fetchBeneficiariosData({token: users.user.claveAPI}));
        }

        return () => {
            // executed when unmount
            // console.log('Saliendo del Modulo Beneficiarios');
        }
        
    }, [users.user.claveAPI, listUsers, listBenef, dispatch]);

    useEffect(() => {
        if (!listUsers || listUsers.length<0){
            return
        }
        if (!listBenef || listBenef.length<0){
            return
        }
        // if (terapias.length<=0){
        //     console.log('No hay registros para mostrar...');
        // }
        if (mensaje.terapias && mensaje.terapias!=='') {
            const updateListaTerapias = () => {
                // query['page'] = '1'; // Es necesario enviarla para que encuentre algo
                if (terapias && terapias.length>0) {
                    // query['relacioncon'] = terapias[0].relacioncon;
                    // dispatch(fetchTerapiaesDatabyQuery({token: users.user.claveAPI, query: query} ));
                }
            }

            if (typeof mensaje.terapias === 'string' && mensaje.terapias.toLowerCase().includes('error')){
                showMsgToaster('error', 'Ocurrió un fallo en el servidor', mensaje.terapias);
                mensaje.terapias='';
            } 
            else {
                showMsgToaster('success',"Terapias", mensaje.terapias.split('.')[0]);
                mensaje.terapias='';
                dispatch(serverMensaje({terapias:''}));
                updateListaTerapias();
            }
            
        }
        
    }, [listUsers, listBenef, users.user.claveAPI, mensaje.terapias, terapias, dispatch]);
    // Save on Server
    const updateDataonServer = (action, terapia) => {
        // console.log(terapia);
        switch (action) {
            case 'insert':
                terapia['creadapor'] = users.user.docId;
                dispatch(insertTerapia( { token: users.user.claveAPI, terapias:terapia}));
                break;
            case 'remove':
                dispatch(deleteTerapia( { token: users.user.claveAPI, terapias:terapia}));
                break;
            case 'update':
                // Actualizar solo el registro necesario, para no enviar toda la tabla
                dispatch(updateTerapia( { token: users.user.claveAPI, terapias:terapia}));
                break;
            default: // update
                console.log('Acción no disponible');
                break;
        }
    }

    const updateItemsTerapia = (f, v) => {
        const newitemsTerapia = itemsTerapia.map((itm, index) => {
            if (itm.key===f){
                itm.value = v;
            }
            return itm;
        });
        setItemsTerapia(newitemsTerapia);
    };

    const [activeSectionIndexes, setActiveSectionIndexes] = useState([]);
    const onAccordionChange = (evt, index) => {
        activeSectionIndexes.includes(index)
        ? setActiveSectionIndexes(activeSectionIndexes.filter(
            item => index !== item,
          ))
        : setActiveSectionIndexes([...activeSectionIndexes, index]);
    }

    // const getUserbyDocId = (docId) =>{
    //     let u = null;
    //     if (listUsers && listUsers.length>0)
    //         u = listUsers.filter(itm => itm.docId===docId);
    //     return u;
    // }

    const showTemasAbordados = (idx) =>{
        document.getElementById('div_temasabordados_'+idx).style.display = document.getElementById('div_temasabordados_'+idx).style.display==='block' ? '-webkit-box' : 'block';
    }
    const showObservaciones = (idx) =>{
        document.getElementById('div_observaciones_'+idx).style.display = document.getElementById('div_observaciones_'+idx).style.display==='block' ? '-webkit-box' : 'block';
    }
    const showResumen = (idx) =>{
        document.getElementById('div_resumen_'+idx).style.display = document.getElementById('div_resumen_'+idx).style.display==='block' ? '-webkit-box' : 'block';
    }
    const showTareasAsignadas = (idx) =>{
        document.getElementById('div_tareasasignadas_'+idx).style.display = document.getElementById('div_tareasasignadas_'+idx).style.display==='block' ? '-webkit-box' : 'block';
    }


    const onBtnAgregarTerapia = (evt) =>{
        evt.preventDefault();
        evt.stopPropagation();
        // console.log(users);
        const newitemsTerapia = itemsTerapia.map((itm, index) => {
            itm.value = '';
            if (itm.key==='id') itm.value = "-1";
            if (itm.key==='beneficiarioId') itm.value = beneficiarioId;
            if (itm.key==='asistio') itm.value = "0";
            if (itm.key==='fecha') itm.value = moment(new Date()).format("YYYY-MM-DD");
            if (itm.key==='horainicio') itm.value =  moment(new Date(), 'hh:mm:ss').format('h:mm A');
            // if (itm.key==='horafin') itm.value =  moment(new Date(), 'hh:mm:ss').format('h:mm A');
            // if (itm.key==='fechaproxsesion') itm.value = moment().add(15, 'days').format("YYYY-MM-DD HH:mm:ss");
            if (itm.key==='creadapor') itm.value = users.user.docId;
            if (itm.key==='modificadopor') getFirstWords(users.user.nombre.toUpperCase());
            if (itm.key==='actualizacion') itm.value = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
            if (itm.key==='version') itm.value = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
            
            return itm;
        });
        let nuevoRegistro = {
            "id": '-1', //int
            "beneficiarioId":beneficiarioId,
            "fecha":moment(new Date()).format("YYYY-MM-DD"),
            "horainicio":moment(new Date(), 'YYYY-MM-DD HH:mm:ss').format('HH:mm'),
            "horafin":null,
            "temasabordados":"",
            "resumen":"",
            "observaciones":"",
            "tareasasignadas":"",
            "fechaproxsesion":null, //moment().add(15, 'days').format("YYYY-MM-DD"),
            "horaproxsesion":null,
            "asistio":"0",
            "creadapor": users.user.docId,
            "actualizacion":moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
            "version":moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
            "modificadopor": getFirstWords(users.user.nombre.toUpperCase())
        };
        setItemsTerapia(newitemsTerapia);
        setTerapiaSelected(nuevoRegistro);
        // // Update UI
        setEditorTemasAbordadosState(EditorState.createEmpty());
        setEditorResumenState(EditorState.createEmpty());
        setEditorTareasAsignadasState(EditorState.createEmpty());
        setEditorObservacionesState(EditorState.createEmpty());
        // setPrioridadSelectedValue(nuevoRegistro.prioridad);
        setAsistioSelected(parseInt(nuevoRegistro.asistio));
        setIsDisplayAgregarTerapia(true);
    }

    const onBtnEditTerapia = (evt, id, index) =>{
        evt.preventDefault();
        evt.stopPropagation();
        // console.log('onBtnEditTerapia', id, index);
        if (terapias && terapias.length>0){
            const newitemsTerapia = terapias.filter((itm, index) => { return itm.id===id});
            if (newitemsTerapia){
                newitemsTerapia[0]['horainicio'] = (newitemsTerapia[0]['horainicio']==='00:00:00') ? '' : moment(newitemsTerapia[0]['horainicio'], 'HH:mm:ss').format('HH:mm');
                newitemsTerapia[0]['horafin'] = (newitemsTerapia[0]['horafin']==='00:00:00') ? '' : moment(newitemsTerapia[0]['horafin'], 'HH:mm:ss').format('HH:mm');
                newitemsTerapia[0]['horaproxsesion'] = (newitemsTerapia[0]['horaproxsesion']==='00:00:00') ? '' : moment(newitemsTerapia[0]['horaproxsesion'], 'HH:mm:ss').format('HH:mm');
                const itemsnote = itemsTerapia.map((itm, index) => {
                    itm.value = newitemsTerapia[0][itm.key];
                    if (itm.key==='modificadopor') itm.value = getFirstWords(users.user.nombre.toUpperCase());
                    // if (itm.key==='horainicio') itm.value = (newitemsTerapia[0]['horainicio']==='00:00:00') ? '' : moment(newitemsTerapia[0]['horainicio'], 'HH:mm:ss').format('HH:mm');
                    // if (itm.key==='horafin') itm.value = (newitemsTerapia[0]['horafin']==='00:00:00') ? '' : moment(newitemsTerapia[0]['horafin'], 'HH:mm:ss').format('HH:mm');
                    // if (itm.key==='horaproxsesion') itm.value = (newitemsTerapia[0]['horaproxsesion']==='00:00:00') ? '' : moment(newitemsTerapia[0]['horaproxsesion'], 'HH:mm:ss').format('HH:mm');
                    return itm;
                });
                setItemsTerapia(itemsnote);
                setTerapiaSelected(newitemsTerapia[0]);
                // Update UI
                // Agregar datos del temasabordados al editor
                const temasabordadosContentBlock = htmlToDraft(newitemsTerapia[0].temasabordados);
                if (temasabordadosContentBlock) {
                    const temasabordadosContentState = ContentState.createFromBlockArray(temasabordadosContentBlock.contentBlocks);
                    const temasabordadosEditorState = EditorState.createWithContent(temasabordadosContentState);
                    setEditorTemasAbordadosState(temasabordadosEditorState);
                }
                // Agregar datos del resumen al editor
                const resumenContentBlock = htmlToDraft(newitemsTerapia[0].resumen);
                if (resumenContentBlock) {
                    const resumenContentState = ContentState.createFromBlockArray(resumenContentBlock.contentBlocks);
                    const resumenEditorState = EditorState.createWithContent(resumenContentState);
                    setEditorResumenState(resumenEditorState);
                }
                 // Agregar datos del tareasasignadas al editor
                 const tareasasignadasContentBlock = htmlToDraft(newitemsTerapia[0].tareasasignadas);
                 if (tareasasignadasContentBlock) {
                     const tareasasignadasContentState = ContentState.createFromBlockArray(tareasasignadasContentBlock.contentBlocks);
                     const tareasasignadasEditorState = EditorState.createWithContent(tareasasignadasContentState);
                     setEditorTareasAsignadasState(tareasasignadasEditorState);
                 }
                // Agregar datos del observaciones al editor
                const observacionesContentBlock = htmlToDraft(newitemsTerapia[0].observaciones);
                if (observacionesContentBlock) {
                    const observacionesContentState = ContentState.createFromBlockArray(observacionesContentBlock.contentBlocks);
                    const observacionesEditorState = EditorState.createWithContent(observacionesContentState);
                    setEditorObservacionesState(observacionesEditorState);
                }
        //         setPrioridadSelectedValue(newitemsTerapia[0].prioridad);
                setAsistioSelected(parseInt(newitemsTerapia[0].asistio));
                setFechaSesion(moment(newitemsTerapia[0].fecha, 'YYYY-MM-DD'));
                setFechaProxSesion((newitemsTerapia[0].fechaproxsesion!=='' && newitemsTerapia[0].fechaproxsesion!==null) ? moment(newitemsTerapia[0].fechaproxsesion, 'YYYY-MM-DD') : null);
                // setInputBeneficiarioValue(capitalize(newitemsTerapia[0].benefnombres, true) +' '+capitalize(newitemsTerapia[0].benefapellidos,true));

                // setIsEditable(users.user.areatrabajo==='ADMINISTRATIVA' || users.user.docId===newitemsTerapia[0].creadapor ? true : false);
                setIsDisplayEditTerapia(true);
            }
        }
        
    }
    
    
    const saveTerapia = () => {
        let err = null;
        const htmlTemasAbordados = draftToHtml(convertToRaw(editorTemasAbordadosState.getCurrentContent()));
        const htmlResumen = draftToHtml(convertToRaw(editorResumenState.getCurrentContent()));
        const htmlTareasAsignadas = draftToHtml(convertToRaw(editorTareasAsignadasState.getCurrentContent()));
        const htmlObservaciones = draftToHtml(convertToRaw(editorObservacionesState.getCurrentContent()));
        // // console.log(html);
        terapiaSelected['temasabordados'] = htmlTemasAbordados;
        terapiaSelected['resumen'] = htmlResumen;
        terapiaSelected['tareasasignadas'] = htmlTareasAsignadas;
        terapiaSelected['observaciones'] = htmlObservaciones;
        terapiaSelected['modificadopor'] = getFirstWords(users.user.nombre.toUpperCase());

        if (terapiaSelected['temasabordados']==='<p></p>\n') terapiaSelected['temasabordados']='';
        if (terapiaSelected['resumen']==='<p></p>\n') terapiaSelected['resumen']='';
        if (terapiaSelected['tareasasignadas']==='<p></p>\n') terapiaSelected['tareasasignadas']='';
        if (terapiaSelected['observaciones']==='<p></p>\n') terapiaSelected['observaciones']='';
        if (terapiaSelected['horainicio']==='00:00:00') terapiaSelected['horainicio']='';
        if (terapiaSelected['horafin']==='00:00:00') terapiaSelected['horafin']='';
        if (terapiaSelected['horaproxsesion']==='00:00:00') terapiaSelected['horaproxsesion']='';

        // Validar datos 
        if (terapiaSelected['temasabordados']==='' || terapiaSelected['temasabordados']==='<p></p>\n'){
            err = 'Dígite algún tema a abordar';
        }
        if (terapiaSelected['resumen']==='' || terapiaSelected['resumen']==='<p></p>\n'){
            err = 'Dígite algún resumen';
        }
        // if (terapiaSelected['fecha']===''){
        //     err = 'Dígite una fecha de sesión válida';
        // }
        if (terapiaSelected['horainicio']===''){
            err = 'Dígite una hora inicial válida';
        }

        terapiaSelected['horainicio'] = (terapiaSelected['horainicio']!=='' && terapiaSelected['horainicio']!==null) ? moment(terapiaSelected['horainicio'],'h:mm A').format('HH:mm:ss') : '';
        terapiaSelected['horafin'] = (terapiaSelected['horafin']!=='' && terapiaSelected['horafin']!==null) ? moment(terapiaSelected['horafin'],'h:mm A').format('HH:mm:ss') : '';
        terapiaSelected['horaproxsesion'] = (terapiaSelected['horaproxsesion']!=='' && terapiaSelected['horaproxsesion']!==null) ? moment(terapiaSelected['horaproxsesion'],'h:mm A').format('HH:mm:ss') : '';

        if (err){
            if (isDisplayEditTerapia) setError(err);
            if (isDisplayAgregarTerapia) setErrorenAgregar(err);
        } else {
            if (isDisplayEditTerapia===true) updateDataonServer('update', terapiaSelected);
            if (isDisplayAgregarTerapia===true) updateDataonServer('insert', terapiaSelected);
            setIsDisplayAgregarTerapia(false);
            setIsDisplayEditTerapia(false);
        }
        
    }
    const cancelTerapia = (evt) => {
        if (isDisplayEditTerapia===true) setIsDisplayEditTerapia(false);
        if (isDisplayAgregarTerapia===true) setIsDisplayAgregarTerapia(false);
    }
    const eliminarTerapia = (evt, id) =>{
        evt.preventDefault();
        evt.stopPropagation();
        updateDataonServer('remove', terapiaSelected);
        setIsDisplayEditTerapia(false);
       
    }


    const replaceSomeText = (s) =>{
        return s
            .replace(/&nbsp;/g, ' ')
            .replace(/<br>/g, '')
            .replace(/<\/br>/g, '')
            .replace(/<\/div>/g, '')
            .replace(/<div>/g, '')
            .replace(/<b>/g, '')
            .replace(/<strong>/g, '')
            .replace(/<p>/g, '')
            .replace(/<\/b>/g, '')
            .replace(/<\/strong>/g, '')
            .replace(/<\/p>/g, '')
            .replace(/&amp;/g, '&')
            .replace(/&gt;/g, '>')
            .replace(/&lt;/g, '<').trim()
    }
    const validateInput = (f, v, final) => {
        const regex_onlynum = /[0-9,]|\./;
        // const regex_nombres = /^['`´a-zA-ZÀ-ÿ\u00C0-\u017F\s]+$/;
        let new_val = null;
        let err = null;
        switch (f) {
            case 'id':
                new_val = replaceSomeText(v);
                if (!regex_onlynum.test(new_val)) {
                    err = 'Dígite solo números válidos';
                    new_val = null;
                }
                if (new_val===null) new_val = '';
                break;
            case 'fecha': //0000-00-00 00:00:00
                new_val = replaceSomeText(v).substr(0,30);
                if (final && new_val===''){
                    new_val = replaceSomeText(v).substr(0,30);
                    err = 'Dígite una fecha de sesión válida';
                    new_val = null;
                }
                break;
            case 'fechaproxsesion': //0000-00-00 00:00:00
                new_val = replaceSomeText(v).substr(0,30);
                if (final && new_val===''){
                    new_val = replaceSomeText(v).substr(0,30);
                    err = 'Dígite una fecha válida';
                    new_val = null;
                }
                break;
            case 'horainicio':
                new_val = v.substr(0,32);
                if (final)
                    new_val = replaceSomeText(v).substr(0,32);
                break;
            case 'horafin':
                new_val = v.substr(0,32);
                if (final)
                    new_val = replaceSomeText(v).substr(0,32);
                break;
            case 'horaproxsesion':
                new_val = v.substr(0,32);
                if (final)
                    new_val = replaceSomeText(v).substr(0,32);
                break;
            case 'prioridad':
                new_val = v.substr(0,20);
                if (final)
                    new_val = replaceSomeText(v).substr(0,20);
                break;
            case 'creadapor':
                new_val = v.substr(0,32);
                if (final)
                    new_val = replaceSomeText(v).substr(0,32);
                break;
            case 'modificadopor':
                new_val = v.substr(0,32);
                if (final)
                    new_val = replaceSomeText(v).substr(0,32);
                break;
            case 'temasabordados':
                new_val = v;
                break;
            case 'resumen':
                new_val = v;
                break;
            case 'observaciones':
                new_val = v;
                break;
            case 'tareasasignadas':
                new_val = v;
                break;
            case 'asistio': // Valida cuando el campo es de texto y no un componente Switch
                new_val = v;
                if (!regex_onlynum.test(v)) {
                    err = 'Dígite un solo número válido';
                    new_val = null;
                } else if(parseInt(v)>1){
                    err = 'Dígite un número válido';
                    new_val = null;
                }
                break;
            case 'actualizacion': //0000-00-00 00:00:00
                new_val = v.substr(0,30);
                if (final && new_val===''){
                    new_val = replaceSomeText(v).substr(0,30);
                    err = 'Dígite una fecha de actualización válida';
                    new_val = null;
                }
                break;
            case 'version': //0000-00-00 00:00:00
                new_val = v.substr(0,30);
                if (final && new_val===''){
                    new_val = replaceSomeText(v).substr(0,30);
                    err = 'Dígite una fecha de version válida';
                    new_val = null;
                }
                break;
            
            default:
                break;
        }

        if (isDisplayEditTerapia) setError(err);
        if (isDisplayAgregarTerapia) setErrorenAgregar(err);
        return new_val
    }
    const handleChange = evt => {
        const field = evt.currentTarget.id.split('_')[1];
        const new_val = validateInput(field, evt.target.value, false);
        if(new_val){
            terapiaSelected[field] = new_val
        };
    };
    const handleBlur = evt => {
        const val = evt.currentTarget.innerHTML; //evt.currentTarget.innerText; //
        const field = evt.target.id.split('_')[1];
        let new_val = validateInput(field, val, true);
        if(new_val || new_val===''){
            if (Object.keys(terapiaSelected).includes(field)){
                terapiaSelected[field] = new_val;
            }
            updateItemsTerapia(field, new_val);
        };
    };

    // const onChangeAsistio = (evt) => {
    //     terapiaSelected.asistio =  evt.target.checked; // ? '1' : '0';
    //     updateItemsTerapia('asistio', terapiaSelected.asistio);
    // }
    const asistioSelectButton = (selected) => {
        setAsistioSelected(selected);
        terapiaSelected['asistio'] = selected === 1 ? '1' : '0';
    }

    // DatePicker
    const onFechaSesionChange = (date) =>{
        if (date.preventDefault) date.preventDefault()
        if (date){
            terapiaSelected.fecha = date.utc().format('YYYY-MM-DD'); // HH:mm:ss
            setFechaSesion(date);
            updateItemsTerapia('fecha', terapiaSelected.fecha);
        }
    }
    const onFechaSesionFocusChange = ({ focused }) =>{
        setFechaSesionPickerFocused(focused)
    }
    const onFechaProxSesionChange = (date) =>{
        if (date.preventDefault) date.preventDefault()
        if (date){
            terapiaSelected.fechaproxsesion = date.utc().format('YYYY-MM-DD'); // HH:mm:ss
            setFechaProxSesion(date);
            updateItemsTerapia('fechaproxsesion', terapiaSelected.fechaproxsesion);
        }
    }
    const onFechaProxSesionFocusChange = ({ focused }) =>{
        setFechaProxSesionPickerFocused(focused)
    }

    // Editor
    // temasabordados, resumen, observaciones, tareasasignadas
    const [editorTemasAbordadosState, setEditorTemasAbordadosState] = useState( () => EditorState.createEmpty() );
    const [editorResumenState, setEditorResumenState] = useState( () => EditorState.createEmpty() );
    const [editorObservacionesState, setEditorObservacionesState] = useState( () => EditorState.createEmpty() );
    const [editorTareasAsignadasState, setEditorTareasAsignadasState] = useState( () => EditorState.createEmpty() );
    const onTemasAbordadosEditorStateChange = (eState) => {
        setEditorTemasAbordadosState(eState);
    };
    const onResumenEditorStateChange = (eState) => {
        setEditorResumenState(eState);
    };
    const onObservacionesEditorStateChange = (eState) => {
        setEditorObservacionesState(eState);
    };
    const onTareasAsignadasEditorStateChange = (eState) => {
        setEditorTareasAsignadasState(eState);
    };

    const handleHorainicioChange = (evt) => {
        // console.log(evt.target.value);
        terapiaSelected.horainicio = moment(evt.target.value, 'HH:mm').format('HH:mm:ss');
        updateItemsTerapia('horainicio', terapiaSelected.horainicio);
    }
    const handleHorafinChange = (evt) => {
        // console.log(evt.target.value);
        terapiaSelected.horafin = moment(evt.target.value, 'HH:mm').format('HH:mm:ss');
        updateItemsTerapia('horafin', terapiaSelected.horafin);
    }
    const handleHoraproxsesionChange = (evt) => {
        // console.log(evt.target.value);
        terapiaSelected.horaproxsesion = moment(evt.target.value, 'HH:mm').format('HH:mm:ss');
        updateItemsTerapia('v', terapiaSelected.horaproxsesion);
    }
        
    return (
        <TerapiasModulo>
            <ToastContainer />
            <AccordionModulo
                onAccordionChange={onAccordionChange}
                activeSectionIndexes={activeSectionIndexes}
            >
                <AccordionSection>
                    <AccordionTitleModule>Terapias</AccordionTitleModule>
                    {activeSectionIndexes.length>0 &&  <AccordionContent style={{marginBottom: '10px'}}>
                            { isDisplayAgregarTerapia && <AgregarTerapia>
                                    <div className="terapia-editform-title">Crear Nueva Terapia</div>
                                    <div className="terapia-editform-editar">
                                        {errorenAgregar && <div style={{color: 'red', width: '100%'}}>
                                                <Alert red showIcon style={{minWidth: '100%', marginBottom: '10px'}}
                                                    showCloseLabel 
                                                    onClose={() => setErrorenAgregar(null)}>
                                                    <AlertMessage>
                                                        Error:{' '}
                                                        <strong>{errorenAgregar}</strong>
                                                    </AlertMessage>
                                                </Alert>
                                            </div> 
                                        }
                                        {/* ITEMS */}
                                        <div className="terapia-editform-items-top">
                                            <div className="terapia-editform-items">
                                                <div className="terapia-editform-itemsTitle">Fecha Sesión:</div>
                                                <DatePicker 
                                                    placeholder="Fecha Sesión"
                                                    id="fecha"
                                                    date={fechaSesion}
                                                    focused={fechaSesionPickerFocused}
                                                    onDateChange={onFechaSesionChange}
                                                    onFocusChange={onFechaSesionFocusChange}
                                                    numberOfMonths={1}
                                                    disabled={!isEditable}
                                                    readOnly={true}
                                                    hideInputIcon
                                                    disableScroll={false}
                                                    displayFormat={'YYYY-MM-DD'} // 'MMM DD YYYY'
                                                    // isOutsideRange={() => {}}
                                                    isOutsideRange={fechaSesion => fechaSesion.isBefore(minDate) || fechaSesion.isAfter(maxDate)}
                                                    monthYearSelectionMode="MONTH_YEAR"
                                                    yearSelectDates={{"startYear" : 2000, "endYear" : new moment().year() + 1}}
                                                    // displayFormat={moment.localeData().longDateFormat('L')}
                                                    // appendToBody={true}
                                                    // withPortal={true}
                                                />
                                            </div>
                                            <div className="terapia-editform-items">
                                                <div className="terapia-editform-itemsTitle">Hora Inicial:</div>
                                                <TextField disabled={!isEditable} 
                                                    id={'terapia_horainicio'}
                                                    label="Hora Inicial"
                                                    type="time"
                                                    defaultValue={terapiaSelected.horainicio || ''} 
                                                    className="terapia-timePicker-horainicio"
                                                    onChange={handleHorainicioChange}
                                                />
                                            </div>
                                            <div className="terapia-editform-items">
                                                <div className="terapia-editform-itemsTitle">Hora Final:</div>
                                                <TextField disabled={!isEditable} 
                                                    id={'terapia_horafin'}
                                                    label="Hora Final"
                                                    type="time"
                                                    defaultValue={terapiaSelected.horafin || ''} 
                                                    className="terapia-timePicker-horafin"
                                                    onChange={handleHorafinChange}
                                                />
                                            </div>
                                        </div>
                                        
                                        
                                        <div className="terapia-observaciones-comentarios">
                                            <div className="terapia-texto-observaciones-title">Temas abordados:</div>
                                            <div className="terapia-texto-observaciones"> 
                                                <Editor placeholder={'Escriba temas a abordar...'}
                                                    editorState={editorTemasAbordadosState}
                                                    editorClassName="terapia-comntario-editor"
                                                    toolbar={{
                                                        inline: { inDropdown: true },
                                                        list: { inDropdown: true },
                                                        textAlign: { inDropdown: true },
                                                        link: { inDropdown: true },
                                                        embedded: { inDropdown: true },
                                                        emoji: { inDropdown: true },
                                                        image: { inDropdown: true },
                                                        remove: { inDropdown: true },
                                                        history: { inDropdown: true },
                                                    }}
                                                    localization={{ locale: 'es', }}
                                                    onEditorStateChange={onTemasAbordadosEditorStateChange}
                                                /> 
                                            </div>
                                        </div>
                                        <div className="terapia-observaciones-comentarios">
                                            <div className="terapia-texto-observaciones-title">Resumen:</div>
                                            <div className="terapia-texto-observaciones"> 
                                                <Editor placeholder={'Escriba un comentario...'}
                                                    editorState={editorResumenState}
                                                    editorClassName="terapia-comntario-editor"
                                                    toolbar={{
                                                        inline: { inDropdown: true },
                                                        list: { inDropdown: true },
                                                        textAlign: { inDropdown: true },
                                                        link: { inDropdown: true },
                                                        embedded: { inDropdown: true },
                                                        emoji: { inDropdown: true },
                                                        image: { inDropdown: true },
                                                        remove: { inDropdown: true },
                                                        history: { inDropdown: true },
                                                    }}
                                                    localization={{ locale: 'es', }}
                                                    onEditorStateChange={onResumenEditorStateChange}
                                                />
                                                
                                            </div>
                                        </div>

                                        <div className="terapia-observaciones-comentarios">
                                            <div className="terapia-texto-observaciones-title">Tareas asignadas:</div>
                                            <div className="terapia-texto-observaciones"> 
                                                <Editor placeholder={'Escriba una tarea...'}
                                                    editorState={editorTareasAsignadasState}
                                                    editorClassName="terapia-comntario-editor"
                                                    toolbar={{
                                                        inline: { inDropdown: true },
                                                        list: { inDropdown: true },
                                                        textAlign: { inDropdown: true },
                                                        link: { inDropdown: true },
                                                        embedded: { inDropdown: true },
                                                        emoji: { inDropdown: true },
                                                        image: { inDropdown: true },
                                                        remove: { inDropdown: true },
                                                        history: { inDropdown: true },
                                                    }}
                                                    localization={{ locale: 'es', }}
                                                    onEditorStateChange={onTareasAsignadasEditorStateChange}
                                                />
                                                
                                            </div>
                                        </div>

                                        <div className="terapia-observaciones-comentarios">
                                            <div className="terapia-texto-observaciones-title">Observaciones:</div>
                                            <div className="terapia-texto-observaciones"> 
                                                <Editor placeholder={'Escriba observaciones...'}
                                                    editorState={editorObservacionesState}
                                                    editorClassName="terapia-comntario-editor"
                                                    toolbar={{
                                                        inline: { inDropdown: true },
                                                        list: { inDropdown: true },
                                                        textAlign: { inDropdown: true },
                                                        link: { inDropdown: true },
                                                        embedded: { inDropdown: true },
                                                        emoji: { inDropdown: true },
                                                        image: { inDropdown: true },
                                                        remove: { inDropdown: true },
                                                        history: { inDropdown: true },
                                                    }}
                                                    localization={{ locale: 'es', }}
                                                    onEditorStateChange={onObservacionesEditorStateChange}
                                                />
                                                
                                            </div>
                                        </div>

                                    </div>

                                    { itemsTerapia && 
                                            itemsTerapia.map((itm, index) => {
                                                return (<div key={index} className="itemContainer">
                                                    <div className="itemContainer-attr" 
                                                        style={{backgroundColor:(index % 2 === 0) ? '#f8f8f8': '#fff'}}
                                                    >{itm.label}</div>
                                                    <div className="itemContainer-value" 
                                                        style={{backgroundColor:(index % 2 === 0) ? '#f8f8f8': '#fff'}}
                                                    >
                                                    {   
                                                        (itm.key==='id' || itm.key==='beneficiarioId' || itm.key==='creadapor')
                                                        ?
                                                        <ContentEditable
                                                            id={'terapia_'+itm.key}
                                                            style={{color:'#ccc'}}
                                                            html={itm.value || ''} // innerHTML of the editable div
                                                            className='itemContainer-value-long'
                                                            disabled={true}      // use true to disable editing
                                                            tagName='div' // Use a custom HTML tag (uses a div by default)
                                                        />
                                                        :
                                                        // itm.key==='horainicio'
                                                        // ?
                                                        // <TextField
                                                        //     id="horainicio"
                                                        //     label="Hora Inicial"
                                                        //     type="time"
                                                        //     defaultValue={itm.value}
                                                        //     className="terapia-timePicker-horainicio"
                                                        //     onChange={handleHorainicioChange}
                                                        //     // InputLabelProps={{
                                                        //     //     shrink: true,
                                                        //     // }}
                                                        //     // inputProps={{
                                                        //     //     step: 300, // 5 min
                                                        //     // }}
                                                        // />
                                                        // :
                                                        // itm.key==='horafin'
                                                        // ?
                                                        // <TextField
                                                        //     id="horafin"
                                                        //     label="Hora Final"
                                                        //     type="time"
                                                        //     defaultValue={itm.value}
                                                        //     className="terapia-timePicker-horafin"
                                                        //     onChange={handleHorafinChange}
                                                        // />
                                                        // :
                                                        itm.key==='horaproxsesion'
                                                        ?
                                                        <TextField
                                                            id="horaproxsesion"
                                                            label="Hora próxima sesión"
                                                            type="time"
                                                            defaultValue={itm.value}
                                                            className="terapia-timePicker-horaproxsesion"
                                                            onChange={handleHoraproxsesionChange}
                                                        />
                                                        :
                                                        itm.key==='asistio' 
                                                        ?
                                                            // <Switch 
                                                            //     labelPosition={"before" || '' }
                                                            //     style={{margin: '0 0 0.5rem 0'}}
                                                            //     name={ 'asistio_' + itm.key } 
                                                            //     checked={ itm.asistio || false} 
                                                            //     disabled={!isEditable} 
                                                            //     onChange={onChangeAsistio} />
                                                            <ButtonGroup isToggle>
                                                                <Button  clear={asistioSelected !== 1}
                                                                    onClick={() => {  asistioSelectButton(1) }}
                                                                >Si</Button>
                                                                <Button clear={asistioSelected !== 0} 
                                                                    onClick={() => { asistioSelectButton(0) }}
                                                                >No</Button>
                                                            </ButtonGroup>
                                                        :
                                                        // itm.key==='fecha' 
                                                        // ?
                                                        //     <DatePicker 
                                                        //         placeholder="Fecha Sesión"
                                                        //         id="fecha"
                                                        //         // style={{backgroundColor:(index % 2 === 0) ? '#f8f8f8 !important': '#fff !important'}}
                                                        //         date={fechaSesion}
                                                        //         focused={fechaSesionPickerFocused}
                                                        //         onDateChange={onFechaSesionChange}
                                                        //         onFocusChange={onFechaSesionFocusChange}
                                                        //         numberOfMonths={1}
                                                        //         disabled={!isEditable}
                                                        //         readOnly={true}
                                                        //         hideInputIcon
                                                        //         disableScroll={false}
                                                        //         displayFormat={'YYYY-MM-DD'} // 'MMM DD YYYY'
                                                        //         // isOutsideRange={() => {}}
                                                        //         isOutsideRange={fechaSesion => fechaSesion.isBefore(moment()) || fechaSesion.isAfter(maxDate)}
                                                        //         monthYearSelectionMode="MONTH_YEAR"
                                                        //         yearSelectDates={{"startYear" : 2000, "endYear" : new moment().year() + 1}}
                                                        //         // displayFormat={moment.localeData().longDateFormat('L')}
                                                        //         // appendToBody={true}
                                                        //         // withPortal={true}
                                                        //     />
                                                        // :
                                                        itm.key==='fechaproxsesion' 
                                                        ?
                                                            <DatePicker 
                                                                placeholder="Fecha Prox. Sesión"
                                                                id="fechaproxsesion"
                                                                // style={{backgroundColor:(index % 2 === 0) ? '#f8f8f8 !important': '#fff !important'}}
                                                                date={fechaProxSesion}
                                                                focused={fechaProxSesionPickerFocused}
                                                                onDateChange={onFechaProxSesionChange}
                                                                onFocusChange={onFechaProxSesionFocusChange}
                                                                numberOfMonths={1}
                                                                disabled={!isEditable}
                                                                readOnly={true}
                                                                hideInputIcon
                                                                disableScroll={false}
                                                                displayFormat={'YYYY-MM-DD'} // 'MMM DD YYYY'
                                                                // isOutsideRange={() => {}}
                                                                isOutsideRange={fechaProxSesion => fechaProxSesion.isBefore(minDate) || fechaProxSesion.isAfter(maxDate)}
                                                                monthYearSelectionMode="MONTH_YEAR"
                                                                yearSelectDates={{"startYear" : 2020, "endYear" : new moment().year() + 1}}
                                                                // displayFormat={moment.localeData().longDateFormat('L')}
                                                                // appendToBody={true}
                                                                // withPortal={true}
                                                            />
                                                        :
                                                        <ContentEditable
                                                            id={'terapia_'+itm.key}
                                                            html={itm.value || ''} // innerHTML of the editable div
                                                            className='itemContainer-value-long'
                                                            // disabled={!isEditable}      // use true to disable editing
                                                            onPaste={pastePlainText} // En caso se copie y pegue de otra pagina de internet
                                                            onChange={handleChange} // handle innerHTML change
                                                            onKeyPress={disableNewlines}  
                                                            onFocus={highlightAll}
                                                            onBlur={handleBlur}
                                                            tagName='div' // Use a custom HTML tag (uses a div by default)
                                                        />
                                                    }

                                                    </div>
                                                </div>)
                                            })
                                        }

                                    <div className="terapia-edit-botones">
                                        <div className="terapia-edit-botones-btn"> 
                                            <Button iconPosition="before" onClick={saveTerapia} icon={<SaveIcon size={16} />}
                                            >Guardar</Button>
                                        </div>
                                        <div className="terapia-edit-botones-btn"> 
                                            <Button iconPosition="before" onClick={(evt) => cancelTerapia(terapiaSelected.id)} icon={<ResetIcon size={16} />}
                                            >Cancelar</Button>
                                        </div>
                                    </div>
                                </AgregarTerapia>
                            }
                            {/* EDIT NOTE */}
                            {isDisplayEditTerapia && <EditTerapia>
                                <div className="terapia-editform-title">Editar Terapia</div>
                                <div className="terapia-editform-editar">
                                    {error && <div style={{color: 'red', width: '100%'}}>
                                            <Alert red showIcon style={{minWidth: '100%', marginBottom: '10px'}}
                                                showCloseLabel 
                                                onClose={() => setError(null)}>
                                                <AlertMessage>
                                                    Error:{' '}
                                                    <strong>{error}</strong>
                                                </AlertMessage>
                                            </Alert>
                                        </div> 
                                    }
                                    {/* ITEMS */}
                                    <div className="terapia-editform-items-top">
                                        <div className="terapia-editform-items">
                                            <div className="terapia-editform-itemsTitle">Fecha Sesión:</div>
                                            <DatePicker 
                                                placeholder="Fecha Sesión"
                                                id="fecha"
                                                date={fechaSesion}
                                                focused={fechaSesionPickerFocused}
                                                onDateChange={onFechaSesionChange}
                                                onFocusChange={onFechaSesionFocusChange}
                                                numberOfMonths={1}
                                                disabled={!isEditable}
                                                readOnly={true}
                                                hideInputIcon
                                                disableScroll={false}
                                                displayFormat={'YYYY-MM-DD'} // 'MMM DD YYYY'
                                                // isOutsideRange={() => {}}
                                                isOutsideRange={fechaSesion => fechaSesion.isBefore(minDate) || fechaSesion.isAfter(maxDate)}
                                                monthYearSelectionMode="MONTH_YEAR"
                                                yearSelectDates={{"startYear" : 2000, "endYear" : new moment().year() + 1}}
                                                // displayFormat={moment.localeData().longDateFormat('L')}
                                                // appendToBody={true}
                                                // withPortal={true}
                                            />
                                        </div>
                                        <div className="terapia-editform-items">
                                            <div className="terapia-editform-itemsTitle">Hora Inicial:</div>
                                            <TextField disabled={!isEditable} 
                                                id={'terapia_horainicio'}
                                                label="Hora Inicial"
                                                type="time"
                                                defaultValue={terapiaSelected.horainicio || ''} 
                                                className="terapia-timePicker-horainicio"
                                                onChange={handleHorainicioChange}
                                            />
                                        </div>
                                        <div className="terapia-editform-items">
                                            <div className="terapia-editform-itemsTitle">Hora Final:</div>
                                            <TextField disabled={!isEditable} 
                                                id={'terapia_horafin'}
                                                label="Hora Final"
                                                type="time"
                                                defaultValue={terapiaSelected.horafin || ''} 
                                                className="terapia-timePicker-horafin"
                                                onChange={handleHorafinChange}
                                            />
                                        </div>
                                    </div>
                                    
                                    <div className="terapia-observaciones-comentarios">
                                        <div className="terapia-texto-observaciones-title">Temas abordados:</div>
                                        <div className="terapia-texto-observaciones"> 
                                        { isEditable ?
                                            <Editor placeholder={'Escriba temas a abordar...'}
                                                editorState={editorTemasAbordadosState}
                                                editorClassName="terapia-comntario-editor"
                                                toolbar={{
                                                    inline: { inDropdown: true },
                                                    list: { inDropdown: true },
                                                    textAlign: { inDropdown: true },
                                                    link: { inDropdown: true },
                                                    embedded: { inDropdown: true },
                                                    emoji: { inDropdown: true },
                                                    image: { inDropdown: true },
                                                    remove: { inDropdown: true },
                                                    history: { inDropdown: true },
                                                }}
                                                localization={{ locale: 'es', }}
                                                onEditorStateChange={onTemasAbordadosEditorStateChange}
                                            /> 
                                            :
                                            <ContentEditable
                                                id={'terapia_temasabordados'}
                                                html={terapiaSelected.temasabordados || ''} // innerHTML of the editable div
                                                disabled={true} 
                                                onPaste={pastePlainText}
                                                onChange={handleChange} // handle innerHTML change
                                                onFocus={highlightAll}
                                                onBlur={handleBlur}
                                                tagName='div' // Use a custom HTML tag (uses a div by default)
                                                style={{ "minHeight": "100px", "border": "1px dashed", backgroundColor:'#fdfdca', padding:'0px 8px' }}
                                            />
                                        }
                                            
                                        </div>
                                    </div>
                                    
                                    <div className="terapia-observaciones-comentarios">
                                        <div className="terapia-texto-observaciones-title">Resumen:</div>
                                        <div className="terapia-texto-observaciones"> 
                                        { isEditable ?
                                            <Editor disabled={!isEditable} placeholder={'Escriba un comentario...'}
                                                editorState={editorResumenState}
                                                editorClassName="terapia-comntario-editor"
                                                toolbar={{
                                                    inline: { inDropdown: true },
                                                    list: { inDropdown: true },
                                                    textAlign: { inDropdown: true },
                                                    link: { inDropdown: true },
                                                    embedded: { inDropdown: true },
                                                    emoji: { inDropdown: true },
                                                    image: { inDropdown: true },
                                                    remove: { inDropdown: true },
                                                    history: { inDropdown: true },
                                                }}
                                                localization={{ locale: 'es', }}
                                                onEditorStateChange={onResumenEditorStateChange}
                                            />
                                            :
                                            <ContentEditable
                                                id={'terapia_temasabordados'}
                                                html={terapiaSelected.temasabordados || ''} // innerHTML of the editable div
                                                disabled={true} 
                                                onPaste={pastePlainText}
                                                onChange={handleChange} // handle innerHTML change
                                                onFocus={highlightAll}
                                                onBlur={handleBlur}
                                                tagName='div' // Use a custom HTML tag (uses a div by default)
                                                style={{ "minHeight": "100px", "border": "1px dashed", backgroundColor:'#fdfdca', padding:'0px 8px' }}
                                            />
                                        }
                                            
                                        </div>
                                    </div>

                                    <div className="terapia-observaciones-comentarios">
                                        <div className="terapia-texto-observaciones-title">Tareas asignadas:</div>
                                        <div className="terapia-texto-observaciones"> 
                                        { isEditable ?
                                            <Editor disabled={!isEditable} placeholder={'Escriba una tarea...'}
                                                editorState={editorTareasAsignadasState}
                                                editorClassName="terapia-comntario-editor"
                                                toolbar={{
                                                    inline: { inDropdown: true },
                                                    list: { inDropdown: true },
                                                    textAlign: { inDropdown: true },
                                                    link: { inDropdown: true },
                                                    embedded: { inDropdown: true },
                                                    emoji: { inDropdown: true },
                                                    image: { inDropdown: true },
                                                    remove: { inDropdown: true },
                                                    history: { inDropdown: true },
                                                }}
                                                localization={{ locale: 'es', }}
                                                onEditorStateChange={onTareasAsignadasEditorStateChange}
                                            />
                                            :
                                            <ContentEditable
                                                id={'terapia_temasabordados'}
                                                html={terapiaSelected.temasabordados || ''} // innerHTML of the editable div
                                                disabled={true} 
                                                onPaste={pastePlainText}
                                                onChange={handleChange} // handle innerHTML change
                                                onFocus={highlightAll}
                                                onBlur={handleBlur}
                                                tagName='div' // Use a custom HTML tag (uses a div by default)
                                                style={{ "minHeight": "100px", "border": "1px dashed", backgroundColor:'#fdfdca', padding:'0px 8px' }}
                                            />
                                        }
                                            
                                        </div>
                                    </div>

                                    <div className="terapia-observaciones-comentarios">
                                        <div className="terapia-texto-observaciones-title">Observaciones:</div>
                                        <div className="terapia-texto-observaciones"> 
                                        { isEditable ?
                                            <Editor disabled={!isEditable} placeholder={'Escriba observaciones...'}
                                                editorState={editorObservacionesState}
                                                editorClassName="terapia-comntario-editor"
                                                toolbar={{
                                                    inline: { inDropdown: true },
                                                    list: { inDropdown: true },
                                                    textAlign: { inDropdown: true },
                                                    link: { inDropdown: true },
                                                    embedded: { inDropdown: true },
                                                    emoji: { inDropdown: true },
                                                    image: { inDropdown: true },
                                                    remove: { inDropdown: true },
                                                    history: { inDropdown: true },
                                                }}
                                                localization={{ locale: 'es', }}
                                                onEditorStateChange={onObservacionesEditorStateChange}
                                            />
                                            :
                                            <ContentEditable
                                                id={'terapia_temasabordados'}
                                                html={terapiaSelected.temasabordados || ''} // innerHTML of the editable div
                                                disabled={true} 
                                                onPaste={pastePlainText}
                                                onChange={handleChange} // handle innerHTML change
                                                onFocus={highlightAll}
                                                onBlur={handleBlur}
                                                tagName='div' // Use a custom HTML tag (uses a div by default)
                                                style={{ "minHeight": "100px", "border": "1px dashed", backgroundColor:'#fdfdca', padding:'0px 8px' }}
                                            />
                                        }
                                            
                                        </div>
                                    </div>

                                </div>

                                { itemsTerapia && 
                                        itemsTerapia.map((itm, index) => {
                                            return (<div key={index} className="itemContainer">
                                                <div className="itemContainer-attr" 
                                                    style={{backgroundColor:(index % 2 === 0) ? '#f8f8f8': '#fff'}}
                                                >{itm.label}</div>
                                                <div className="itemContainer-value" 
                                                    style={{backgroundColor:(index % 2 === 0) ? '#f8f8f8': '#fff'}}
                                                >
                                                {   
                                                    (itm.key==='id' || itm.key==='beneficiarioId' || itm.key==='creadapor')
                                                    ?
                                                    <ContentEditable
                                                        id={'terapia_'+itm.key}
                                                        style={{color:'#ccc'}}
                                                        html={itm.value || ''} // innerHTML of the editable div
                                                        className='itemContainer-value-long'
                                                        disabled={true}      // use true to disable editing
                                                        tagName='div' // Use a custom HTML tag (uses a div by default)
                                                    />
                                                    :
                                                    // itm.key==='horainicio'
                                                    // ?
                                                    // <TextField disabled={!isEditable} 
                                                    //     id="horainicio"
                                                    //     label="Hora Inicial"
                                                    //     type="time"
                                                    //     defaultValue={itm.value}
                                                    //     // defaultProps={ {type:"time"}}
                                                    //     className="terapia-timePicker-horainicio"
                                                    //     onChange={handleHorainicioChange}
                                                    // />
                                                    // :
                                                    // itm.key==='horafin'
                                                    // ?
                                                    // <TextField disabled={!isEditable} 
                                                    //     id="horafin"
                                                    //     label="Hora Final"
                                                    //     type="time"
                                                    //     defaultValue={itm.value}
                                                    //     className="terapia-timePicker-horafin"
                                                    //     onChange={handleHorafinChange}
                                                    // />
                                                    // :
                                                    itm.key==='horaproxsesion'
                                                    ?
                                                    <TextField disabled={!isEditable} 
                                                        id="horaproxsesion"
                                                        label="Hora próxima sesión"
                                                        type="time"
                                                        defaultValue={itm.value}
                                                        className="terapia-timePicker-horaproxsesion"
                                                        onChange={handleHoraproxsesionChange}
                                                    />
                                                    :
                                                    itm.key==='asistio' 
                                                    ?
                                                        // <Switch 
                                                        //     labelPosition={"before" || '' }
                                                        //     style={{margin: '0 0 0.5rem 0'}}
                                                        //     name={ 'asistio_' + itm.key } 
                                                        //     checked={ itm.asistio || false} 
                                                        //     disabled={!isEditable} 
                                                        //     onChange={onChangeAsistio} />
                                                        <ButtonGroup isToggle>
                                                            <Button  clear={asistioSelected !== 1} disabled={!isEditable} 
                                                                onClick={() => {  asistioSelectButton(1) }}
                                                            >Si</Button>
                                                            <Button clear={asistioSelected !== 0} disabled={!isEditable} 
                                                                onClick={() => { asistioSelectButton(0) }}
                                                            >No</Button>
                                                        </ButtonGroup>
                                                    :
                                                    // itm.key==='prioridad' 
                                                    // ?
                                                    //     <Select filterable positionFixed 
                                                    //         disabled={!isEditable} 
                                                    //         placeholder={'Seleccione...'}
                                                    //         onChange={handlePrioridadSelectChange}
                                                    //         onKeyDown={handlePrioridadonKeyDown}
                                                    //         value={prioridadSelectedValue || ''}
                                                    //         selectedValue={prioridadSelectedValue || ''}
                                                    //     >
                                                    //     {prioridadTerapia.tipo.map(e => {
                                                    //         return (
                                                    //         <MenuItem key={e.id} value={e.name}>
                                                    //             {e.name}
                                                    //         </MenuItem>
                                                    //         )
                                                    //     })}
                                                    //     </Select>
                                                    // :
                                                    
                                                    // itm.key==='fecha' 
                                                    // ?
                                                    //     <DatePicker 
                                                    //         placeholder="Fecha Sesión"
                                                    //         id="fecha"
                                                    //         // style={{backgroundColor:(index % 2 === 0) ? '#f8f8f8 !important': '#fff !important'}}
                                                    //         date={fechaSesion}
                                                    //         focused={fechaSesionPickerFocused}
                                                    //         onDateChange={onFechaSesionChange}
                                                    //         onFocusChange={onFechaSesionFocusChange}
                                                    //         numberOfMonths={1}
                                                    //         disabled={!isEditable}
                                                    //         readOnly={true}
                                                    //         hideInputIcon
                                                    //         disableScroll={false}
                                                    //         displayFormat={'YYYY-MM-DD'} // 'MMM DD YYYY'
                                                    //         // isOutsideRange={() => {}}
                                                    //         isOutsideRange={fechaSesion => fechaSesion.isBefore(minDate) || fechaSesion.isAfter(maxDate)}
                                                    //         monthYearSelectionMode="MONTH_YEAR"
                                                    //         yearSelectDates={{"startYear" : 2000, "endYear" : new moment().year() + 1}}
                                                    //         // displayFormat={moment.localeData().longDateFormat('L')}
                                                    //         // appendToBody={true}
                                                    //         // withPortal={true}
                                                    //     />
                                                    // :
                                                    itm.key==='fechaproxsesion' 
                                                    ?
                                                        <DatePicker 
                                                            placeholder="Fecha Prox. Sesión"
                                                            id="fechaproxsesion"
                                                            // style={{backgroundColor:(index % 2 === 0) ? '#f8f8f8 !important': '#fff !important'}}
                                                            date={fechaProxSesion}
                                                            focused={fechaProxSesionPickerFocused}
                                                            onDateChange={onFechaProxSesionChange}
                                                            onFocusChange={onFechaProxSesionFocusChange}
                                                            numberOfMonths={1}
                                                            disabled={!isEditable}
                                                            readOnly={true}
                                                            hideInputIcon
                                                            disableScroll={false}
                                                            displayFormat={'YYYY-MM-DD'} // 'MMM DD YYYY'
                                                            // isOutsideRange={() => {}}
                                                            isOutsideRange={fechaProxSesion => fechaProxSesion.isBefore(minDate) || fechaProxSesion.isAfter(maxDate)}
                                                            monthYearSelectionMode="MONTH_YEAR"
                                                            yearSelectDates={{"startYear" : 2020, "endYear" : new moment().year() + 1}}
                                                            // displayFormat={moment.localeData().longDateFormat('L')}
                                                            // appendToBody={true}
                                                            // withPortal={true}
                                                        />
                                                    :
                                                    <ContentEditable
                                                        id={'terapia_'+itm.key}
                                                        html={itm.value || ''} // innerHTML of the editable div
                                                        className='itemContainer-value-long'
                                                        disabled={!isEditable}      // use true to disable editing
                                                        onPaste={pastePlainText} // En caso se copie y pegue de otra pagina de internet
                                                        onChange={handleChange} // handle innerHTML change
                                                        onKeyPress={disableNewlines}  
                                                        onFocus={highlightAll}
                                                        onBlur={handleBlur}
                                                        tagName='div' // Use a custom HTML tag (uses a div by default)
                                                    />
                                                }

                                                </div>
                                            </div>)
                                        })
                                    }
                                <div className="terapia-edit-botones">
                                    <div className="terapia-edit-botones-btn"> 
                                        <Button iconPosition="before" onClick={saveTerapia} icon={<SaveIcon size={16} />}
                                        >Guardar</Button>
                                    </div>
                                    {isEditable && <div className="terapia-edit-botones-btn"> 
                                        <Button iconPosition="before" onClick={(evt) => eliminarTerapia(evt, terapiaSelected.id)} icon={<TrashIcon size={16} />}
                                        >Eliminar</Button>
                                    </div>}
                                    
                                </div>
                            </EditTerapia>
                            }

                            {/* LISTA DE TERAPIAS */}

                            {   !isDisplayEditTerapia && !isDisplayAgregarTerapia && <div>
                                    <div className="terapias-fundacion-toolbar">
                                    <div className="terapias-fundacion-toolbar-left">
                                        <div className="terapias-fundacion-toolbarLeft-tools">
                                            <div className="terapias-fundacion-toolbarLeft-btn">
                                                <Button iconPosition="before" disabled={!isEditable}
                                                    onClick={onBtnAgregarTerapia}
                                                    icon={<PlusIcon size={16} />}
                                                >
                                                    Nueva Terapia
                                                </Button>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                                { terapias && terapias.length>0 ?
                                <div className='terapias-fundacion-tablaContainer'>
                                    <div className='terapias-fundacion-tabla'>
                                    { terapias.map((itm, index) => {
                                        const foto_creador = (itm.foto==='' || itm.foto===null) ? process.env.PUBLIC_URL + '/imgs/team/usuario.jpg' : process.env.PUBLIC_URL + '/imgs/team/'+ itm.foto; 
                                        const fullName = (itm.nombre==='' || itm.nombre===null) ? '' : capitalize(itm.nombre, true);
                                        const nombre = fullName ? fullName.split(' ')[0] +' '+ fullName.split(' ')[1] : '';
                                        // const iniciales = getFirstWords(fullName);
                                        const fechacreacion = itm.fecha ? moment(itm.fecha, 'YYYY-MM-DD').format('YYYY-MMM-DD').split('-') : '';
                                        const horainicial = itm.horainicio ? moment(itm.horainicio, 'hh:mm:ss').format('h:mm A') : '';
                                        const horafinal = itm.horafin ? moment(itm.horafin, 'hh:mm:ss').format('h:mm A') : '';
                                        const horaproxsesion = itm.horaproxsesion ? moment(itm.horaproxsesion, 'hh:mm:ss').format('h:mm a') : '';
                                        const fechaproxsesion = itm.fechaproxsesion ? moment(itm.fechaproxsesion, 'YYYY-MM-DD').format('dddd DD, MMMM YYYY') : '';

                                        return (<div key={index}  className="terapias-fundacion-lista-row" style={{backgroundColor:(index % 2 === 0) ? '#fff': '#fff'}}>
                                            
                                            {/* <div className="terapias-fundacion-lista-cell">{itm.id}</div> */}
                                            <div className="terapias-fundacion-header" onClick={(evt) => onBtnEditTerapia(evt, itm.id, index)}>
                                                <div className="terapias-fundacion-lista-cell-inline">
                                                    <div className="terapias-fundacion-lista-cell-inline">
                                                        <div className="terapias-fundacion-lista-fechacreacion">
                                                            <div className="terapias-fundacion-lista-fechacreacion-m">{fechacreacion[1].toUpperCase()}</div>
                                                            <div className="terapias-fundacion-lista-fechacreacion-d">{fechacreacion[2]}</div>
                                                            <div className="terapias-fundacion-lista-fechacreacion-y">{fechacreacion[0]}</div>
                                                        </div>
                                                    </div>

                                                    <div className="terapias-fundacion-lista-cell-inline">
                                                        <div className="terapias-fundacion-lista-creadorContainer">
                                                            <div className="terapias-fundacion-lista-tipoterapia">
                                                                <div className="terapias-fundacion-lista-creador">
                                                                    <div className="terapias-fundacion-lista-creadapor-foto"><img src={foto_creador} alt=' ' /></div>
                                                                    <div className="terapias-fundacion-lista-creadapor">{nombre}</div>
                                                                </div>
                                                                <div className="terapias-fundacion-lista-horainicio">Hora Inicial: {horainicial}</div>
                                                                <div className="terapias-fundacion-lista-horafin">Hora Final: {horafinal}</div>
                                                                {/* <div className="terapias-fundacion-lista-prioridad">
                                                                    {itm.prioridad.toLowerCase()==='alta' && <div className="terapias-fundacion-lista-prioridad terapias-fundacion-lista-prioridad-alta">{itm.prioridad}</div>}
                                                                    {itm.prioridad.toLowerCase()==='media' && <div className="terapias-fundacion-lista-prioridad terapias-fundacion-lista-prioridad-media">{itm.prioridad}</div>}
                                                                    {itm.prioridad.toLowerCase()==='baja' && <div className="terapias-fundacion-lista-prioridad terapias-fundacion-lista-prioridad-baja">{itm.prioridad}</div>}
                                                                </div> */}
                                                            </div>
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="terapias-fundacion-lista-header-right">
                                                    {itm.asistio==='1' && <div className="terapias-fundacion-lista-estadoterapia estadoterapia-asistio">Asistió</div>}
                                                    {itm.asistio==='0' && <div className="terapias-fundacion-lista-estadoterapia estadoterapia-noasistio">No Asistió</div>}
                                                    {fechaproxsesion!=='' && <div className="terapias-fundacion-lista-proxsesion">Próxima Sesión</div>}
                                                    {fechaproxsesion!=='' && <div className="terapias-fundacion-lista-fechaproxsesion">{capitalize(fechaproxsesion, true)}</div>}
                                                    {fechaproxsesion!=='' && <div className="terapias-fundacion-lista-horaproxsesion">Hora: {horaproxsesion}</div>}
                                                </div>
                                            </div>
                                            
                                            <div className="terapias-fundacion-lista-cell">
                                                <div className="terapias-fundacion-lista-cell-titlenota">Temas abordados</div>
                                                <div id={'div_temasabordados_'+index} className="terapias-fundacion-lista-cell-nota one-line-ellipsis" aria-haspopup="true"
                                                    onClick={() => showTemasAbordados(index)} >
                                                        <ContentEditable
                                                            id={'temasabordados_'+index}
                                                            html={itm.temasabordados || ''} // innerHTML of the editable div
                                                            disabled={true}        // use true to disable editing
                                                            tagName='div' // Use a custom HTML tag (uses a div by default)
                                                            // style={{ "minHeight": "40px", "border": "1px dashed", padding:'0px 8px'  }}
                                                        />
                                                    </div>
                                            </div>                                            
                                            {itm.resumen!=='' && <div className="terapias-fundacion-lista-cell">
                                                <div className="terapias-fundacion-lista-cell-titlenota">Resumen</div>
                                                    <div id={'div_resumen_'+index} className="terapias-fundacion-lista-cell-nota terapias-fundacion-lista-resumen one-line-ellipsis" onClick={() => showResumen(index)}>
                                                        {/* {itm.resumen} */}
                                                        <ContentEditable
                                                            id={'resumen_'+index}
                                                            html={itm.resumen || ''} // innerHTML of the editable div
                                                            disabled={true}        // use true to disable editing
                                                            tagName='div' // Use a custom HTML tag (uses a div by default)
                                                            // style={{ "minHeight": "40px", "border": "1px dashed", padding:'0px 8px'  }}
                                                        />
                                                    </div>
                                            </div>
                                            }
                                            {itm.observaciones!=='' && <div className="terapias-fundacion-lista-cell">
                                                <div className="terapias-fundacion-lista-cell-titlenota">Observaciones</div>
                                                <div id={'div_observaciones_'+index} className="terapias-fundacion-lista-cell-nota terapias-fundacion-lista-obs one-line-ellipsis" aria-haspopup="true"
                                                    onClick={() => showObservaciones(index)} >
                                                        <ContentEditable
                                                            id={'observaciones_'+index}
                                                            html={itm.observaciones || ''} // innerHTML of the editable div
                                                            disabled={true}        // use true to disable editing
                                                            tagName='div' // Use a custom HTML tag (uses a div by default)
                                                            // style={{ "minHeight": "40px", "border": "1px dashed", padding:'0px 8px'  }}
                                                        />
                                                    </div>
                                            </div>}

                                            
                                            {itm.tareasasignadas!=='' && <div className="terapias-fundacion-lista-cell">
                                                <div className="terapias-fundacion-lista-cell-titlenota">Tareas asignadas</div>
                                                <div id={'div_tareasasignadas_'+index} className="terapias-fundacion-lista-cell-nota terapias-fundacion-lista-obs one-line-ellipsis" aria-haspopup="true"
                                                    onClick={() => showTareasAsignadas(index)} >
                                                        <ContentEditable
                                                            id={'tareasasignadas_'+index}
                                                            html={itm.tareasasignadas || ''} // innerHTML of the editable div
                                                            disabled={true}        // use true to disable editing
                                                            tagName='div' // Use a custom HTML tag (uses a div by default)
                                                            // style={{ "minHeight": "40px", "border": "1px dashed", padding:'0px 8px'  }}
                                                        />
                                                    </div>
                                            </div>}
                                            
                                            <div className="terapias-fundacion-lista-footer">
                                                <div className="terapias-fundacion-lista-actualizacion">Última actualización: {itm.actualizacion} por <span>{itm.modificadopor}</span></div>
                                            </div>
                                            
                                        </div>)
                                        })
                                    }
                                    </div>
                                </div>
                            :
                                <div>No hay registros</div>
                            }
                            </div>
                            }
                        </AccordionContent>
                    }
                </AccordionSection>
            </AccordionModulo>
        </TerapiasModulo>
    );
    }

export default BenefTerapias;

