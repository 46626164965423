// React
import React, { Fragment,useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import CountUp from 'react-countup'; //, { useCountUp }
import { fetchBeneficiariosDataxCovid, fetchTablasCriteriosxCovid, fetchListEntregaDataxCovid,
    displayCovidFilter, displayAddBenefListEntrega, setCovidListEntrega, 
    moduleCOVID19Loaded, 
    // resetfilterBeneficiariosData, resetSelectBeneficiarioForPerfilxCovid
} from '../../../redux/reducers/covid19';
import { fetchBeneficiariosData } from '../../../redux/reducers/beneficiarios';
import {calcularAfectacion, capitalize, highlightAll, 
    disableNewlines, 
    pastePlainText
} from '../../../utils/utils'; //RemoveDuplicates, getDuplicates
import moment from 'moment';
import 'moment/locale/es-us';
import ContentEditable from 'react-contenteditable';
import Alert, {AlertMessage} from 'calcite-react/Alert';
// load lazy data
import DisplayListBeneficiarios from './displayListBeneficiarios';
import BenefDetailsContainer from './BeneficiarioDetails';
import ListaEntrega from './ListaEntrega';
import TableBeneficiariosEntregaSortColumns from './TableBeneficiariosEntregaSortColumns';

import ListButtonIcon from 'calcite-ui-icons-react/ListButtonIcon';
import FilterIcon from 'calcite-ui-icons-react/FilterIcon';
import SaveIcon from 'calcite-ui-icons-react/SaveIcon';
import CheckIcon from 'calcite-ui-icons-react/CheckIcon';

import Button from 'calcite-react/Button';
import Search from 'calcite-react/Search';
import DatePicker from 'calcite-react/DatePicker';

import Tabs, {
    TabNav,
    TabTitle,
    TabContents,
    TabSection
} from 'calcite-react/Tabs';

// Styled & Motion Components
import styled from "styled-components";

import costoIcon from '../../../assets/icons/costos.png';
import covidbonos_Icon from '../../../assets/icons/bonos_white.png';
import peopleIcon from '../../../assets/icons/people_white.png';
import covidmercados_Icon from '../../../assets/icons/carrocompras_white.png';

const SearchBeneficiario = styled.div`
    position: relative;
    float: right;
    margin-right: 51px;
    top: -4px;
    height:1.9rem;

    /* Extra large devices (large laptops and desktops, 1200px and up) */
    @media only screen and (max-width: 1200px) {
        
    } 

    /* Large devices (laptops/desktops, 992px and up) */
    @media only screen and (max-width: 992px) {
        
    }

    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (max-width: 768px) {
        max-width: 150px;
    }

    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (max-width: 600px) {
        max-width: 150px;
    }

    /* Extra small devices (phones, 600px and down) */
    @media only screen and (max-width: 600px) {
        max-width: 150px;
    }

`;

const BeneficiarioDetalleContainer = styled.div`
    z-index:101;
    display: flex;
    position: absolute;
    left: 0px;
    top: 0px;
    // width: calc(100% - 52px);
    width:100%;
    height:100%;
    padding:0px;
    margin:0px;
    background-color: #fff;
    //padding-right: 50px;
`;

const ListaEntregaContainer = styled.div`
    z-index:100;
    display: flex;
    position: absolute;
    left: 0px;
    top: 0px;
    width: calc(100% - 52px);
    height:100%;
    padding:0px;
    margin:0px;
    background-color: #fff;
    //padding-right: 50px;
`;

const FiltroButton = styled.div`
    // position: relative;
    // margin-bottom: 2px;
    // top: 0px;
    background: #fff;
    width: 40px;
    height: 40px;
    // border: 1px solid #ccc;
    cursor: pointer;
    display:inline-flex;

    &:hover{
        background: #c7eaff;
    }

    /* Extra large devices (large laptops and desktops, 1200px and up) */
    @media only screen and (max-width: 1200px) {
        
    } 

    /* Large devices (laptops/desktops, 992px and up) */
    @media only screen and (max-width: 992px) {
        
    }

    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (max-width: 768px) {
        
    }

    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (max-width: 600px) {

    }

    /* Extra small devices (phones, 400px and down) */
    @media only screen and (max-width: 400px) {
        
    }

`;
const FiltroIcon = <FilterIcon style={{ width: '24px', height: '24px', marginLeft: '8px', marginTop: '10px', color:'green' }} />

const CostosButton = styled.div`
    // position: relative;
    // margin-bottom: 2px;
    // top: 0px;
    background: #fff;
    width: 40px;
    height: 40px;
    // border: 1px solid #ccc;
    cursor: pointer;
    display:inline-flex;

    &:hover{
        background: #c7eaff;
    }

    /* Extra large devices (large laptops and desktops, 1200px and up) */
    @media only screen and (max-width: 1200px) {
        
    } 

    /* Large devices (laptops/desktops, 992px and up) */
    @media only screen and (max-width: 992px) {
        
    }

    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (max-width: 768px) {
        
    }

    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (max-width: 600px) {

    }

    /* Extra small devices (phones, 400px and down) */
    @media only screen and (max-width: 400px) {
        
    }

`;
const CostosIcon = styled.div`
  width: 24px;
  height: 24px;
  margin-left: 8px;
  margin-top: 10px;
  background: rgba(255, 255, 255, 0.1) url(${costoIcon}) no-repeat center/cover;
`;

const AyudasTopToolBar = styled.div`
    position: relative;
    margin-bottom: 2px;
    top: 0px;
    background: #fff;
    // width: calc(100% - 52px);
    width: auto;
    height: 40px;
    border: 1px solid #ccc;
    text-align: left;

    /* Extra large devices (large laptops and desktops, 1200px and up) */
    @media only screen and (max-width: 1200px) {
        
    } 

    /* Large devices (laptops/desktops, 992px and up) */
    @media only screen and (max-width: 992px) {
        
    }

    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (max-width: 768px) {
        
    }

    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (max-width: 600px) {

    }

    /* Extra small devices (phones, 400px and down) */
    @media only screen and (max-width: 400px) {
        
    }

`;

const CostosContainer = styled.div`
    position: relative;
    // margin-bottom: 2px;
    top: 0px;
    color: #fff;
    background: #009937;
    // width: calc(100% - 52px);
    width: 100%;
    min-height: 40px;
    border: 1px solid #ccc;
    text-align: center;
    // padding:5px;

    /* Extra large devices (large laptops and desktops, 1200px and up) */
    @media only screen and (max-width: 1200px) {
        
    } 

    /* Large devices (laptops/desktops, 992px and up) */
    @media only screen and (max-width: 992px) {
        
    }

    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (max-width: 768px) {
        
    }

    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (max-width: 600px) {

    }

    /* Extra small devices (phones, 400px and down) */
    @media only screen and (max-width: 400px) {
        
    }

`;

const AyudaBonosIcon = styled.div`
  width: 26px;
  height: 26px;
  background: transparent url(${covidbonos_Icon}) no-repeat center/cover;
  margin-right: 5px;
  background-size: 72%;
`;
const AyudaMercadoIcon = styled.div`
  width: 26px;
  height: 26px;
  background: transparent url(${covidmercados_Icon}) no-repeat center/cover;
  margin-right: 5px;
  background-size: 90%;
  opacity: 0.8;
`;
const PeopleIcon = styled.div`
  width: 26px;
  height: 26px;
  background: transparent url(${peopleIcon}) no-repeat center/cover;
  margin-right: 5px;
  background-size: 90%;
//   opacity: 0.8;
`;

const ModuleCovid19Ayudas = props => {
    const users = useSelector(state => state.users);
    const isEditable = (users.user.areatrabajo==='ADMINISTRATIVA' || users.user.areatrabajo==='TECNOLOGÍA' 
        || users.user.areatrabajo==='COORDINACIÓN' || users.user.areatrabajo==='DIRECCIÓN') ? true : false;
    const selectedProfile = useSelector(state => state.covid19.selectedProfile);
    const listCriterios = useSelector(state => state.covid19.listCriterios);
    const listEntrega = useSelector(state => state.covid19.listEntrega);
    const isListEntrega = useSelector(state => state.covid19.isListEntrega);
    const listBenef = useSelector(state => state.covid19.listBenef);
    const filter = useSelector(state => state.covid19.filter);
    const isFilterShow = useSelector(state => state.covid19.isFilterShow);
    const isAddBenefaListEntrega = useSelector(state => state.covid19.isAddBenefaListEntrega);
    const ListBeneficiarios = useSelector(state => state.beneficiarios.listBenef);
    
    // const costoTotalAyudas = useSelector(state => state.covid19.costoTotalAyudas);
    const [filtroBeneficiario,setFiltroBeneficiario] = useState([]);
    const [isFiltered,setIsFiltered] = useState(false);
    const dispatch = useDispatch();
    const [activeTabIndex,setTabIndex] = useState(0);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    // Buscar dentro de entregas
    const [inputValue,setInputValue] = useState('');
    const [selectedItem,setSelectedItem] = useState('');
    const [searchItems,setSearchItems] = useState([]);
    // Buscar en todos los beneficiarios
    const [inputValueAddBeneficiario,setInputValueAddBeneficiario] = useState('');
    const [selectedItemAddBeneficiario,setSelectedItemAddBeneficiario] = useState('');
    const [searchItemsAddBeneficiario,setSearchItemsAddBeneficiario] = useState([]);

    const [isCostosShow,setIsCostosShow] = useState(false);
    const [costoTotalAyudas,setCostoTotalAyudas] = useState(0);
    const [costoTotalBonos,setCostoTotalBonos] = useState(0);
    const [costoTotalMercados,setCostoTotalMercados] = useState(0);
    const [costoTotalAfectAlta,setCostoTotalAfectAlta] = useState(0);
    const [costoTotalAfectMedia,setCostoTotalAfectMedia] = useState(0);
    const [costoTotalAfectBaja,setCostoTotalAfectBaja] = useState(0);
    const [cantidadBonos,setCantidadBonos] = useState(0);
    const [cantidadMercados,setCantidadMercados] = useState(0);
    const [cantidadAfectAlta,setCantidadAfectAlta] = useState(0);
    const [cantidadAfectMedia,setCantidadAfectMedia] = useState(0);
    const [cantidadAfectBaja,setCantidadAfectBaja] = useState(0);

    const [newAddBeneficiario,setNewAddBeneficiario] = useState(null);
    // const [newAddBeneficiario, setNewAddBeneficiario] = useState({
    //     "id": '',
    //     "beneficiarioId": '',
    //     "docIdquienreclama": '',
    //     "nombrequienreclama": '',
    //     "numentrega": '',
    //     "serial": '',
    //     "convenio": '',
    //     "tipoayuda": '',
    //     "cupo": '',
    //     "valor": '',
    //     "estadoayuda": '',
    //     "afectacion": '',
    //     "fechainicio": null,
    //     "fechavigencia": null,
    //     "fechacaducidad": null,
    //     "fecharedencion": null,
    //     "fechaanulacion": null,
    //     "transaccion": '',
    //     "nut": '',
    //     "terminal": '',
    //     "descripcion": '',
    //     // "entregada": entregada==='1' ? true : false, 
    //     "almacen": '',
    //     "tipocaso": '',
    //     "comentarios": '',
    //     "actualizacion": '',
    //     "version": ''
    // });
                                    
    const [itemsAddBeneftoEntrega, setItemsAddBeneftoEntrega] = useState([
        // {"key":"id", "label": "Identificador Único", "value": newAddBeneficiario['id']},
        {"key":"beneficiarioId", "label": "Documento Beneficiario", "value": newAddBeneficiario ? newAddBeneficiario['beneficiarioId'] : ''},
        {"key":"docIdquienreclama", "label": "Documento de quién reclama la ayuda", "value": newAddBeneficiario ? newAddBeneficiario['docIdquienreclama'] : ''},
        {"key":"nombrequienreclama", "label": "Nombre de quién reclama la ayuda", "value": newAddBeneficiario ? newAddBeneficiario['nombrequienreclama'] : ''},
        {"key":"numentrega", "label": "Número de entrega", "value": newAddBeneficiario ? newAddBeneficiario['numentrega'] : ''},
        // {"key":"serial", "label": "Número Serial", "value": newAddBeneficiario ? newAddBeneficiario['serial'] : ''},
        {"key":"convenio", "label": "No. Convenio", "value": newAddBeneficiario ? newAddBeneficiario['convenio'] : ''},
        // {"key":"tipoayuda", "label": "Tipo de ayuda", "value": newAddBeneficiario ? newAddBeneficiario['tipoayuda'] : ''},
        {"key":"cupo", "label": "Cupo o Tope", "value": newAddBeneficiario ? newAddBeneficiario['cupo'] : ''},
        {"key":"valor", "label": "Valor de la Ayuda", "value": newAddBeneficiario ? newAddBeneficiario['valor'] : ''},
        // {"key":"estadoayuda", "label": "Estado de la ayuda", "value": newAddBeneficiario ? newAddBeneficiario['estadoayuda'] : ''},
        // {"key":"afectacion", "label": "Afectación", "value": newAddBeneficiario ?  newAddBeneficiario['afectacion'] : ''},
        {"key":"fechainicio", "label": "Fecha de Inicio", "value": newAddBeneficiario ?  newAddBeneficiario['fechainicio'] : ''},
        {"key":"fechavigencia", "label": "Fecha de Vigencia", "value": newAddBeneficiario ?  newAddBeneficiario['fechavigencia'] : ''},
        {"key":"fechacaducidad", "label": "Fecha de Caducidad", "value": newAddBeneficiario ?  newAddBeneficiario['fechacaducidad'] : ''},
        // {"key":"fecharedencion", "label": "Fecha de Redención", "value": newAddBeneficiario ?  newAddBeneficiario['fecharedencion'] : ''},
        // {"key":"fechaanulacion", "label": "Fecha de Anulación", "value": newAddBeneficiario ?  newAddBeneficiario['fechaanulacion'] : ''},
        // {"key":"transaccion", "label": "Código de Transacción", "value": newAddBeneficiario ?  newAddBeneficiario['transaccion'] : ''},
        // {"key":"nut", "label": "NUT", "value": newAddBeneficiario ?  newAddBeneficiario['nut'] : ''},
        // {"key":"terminal", "label": "Terminal", "value": newAddBeneficiario ?  newAddBeneficiario['terminal'] : ''},
        // {"key":"descripcion", "label": "Descripción de la Ayuda", "value": newAddBeneficiario ?  newAddBeneficiario['descripcion'] : ''},
        // {"key":"entregada", "label": "Ayuda Entregada", "value": newAddBeneficiario ?  newAddBeneficiario['entregada'] : ''},
        // {"key":"almacen", "label": "Almacén", "value": newAddBeneficiario ?  newAddBeneficiario['almacen'] : ''},
        // {"key":"tipocaso", "label": "Tipo de Caso", "value": newAddBeneficiario ?  newAddBeneficiario['tipocaso'] : ''},
        {"key":"comentarios", "label": "Comentarios", "value": newAddBeneficiario ?  newAddBeneficiario['comentarios'] : ''},
        // {"key":"actualizacion", "label": "Última actualización", "value": newAddBeneficiario ?  newAddBeneficiario['actualizacion'] : ''},
        // {"key":"version", "label": "Versión", "value": newAddBeneficiario ?  newAddBeneficiario['version']: ''}
    ]);

    const [fechaInicio, setFechaInicio] = useState((newAddBeneficiario!==null && newAddBeneficiario.fechainicio!=='') ? moment(newAddBeneficiario.fechainicio,'YYYY-MM-DD') :  moment(new Date(),'YYYY-MM-DD'));
    const [fechaInicioPickerFocused, setFechaInicioPickerFocused] = useState(false);
    const [fechaVigencia, setFechaVigencia] = useState(newAddBeneficiario!==null && newAddBeneficiario.fechavigencia!=='' ? moment(newAddBeneficiario.fechavigencia,'YYYY-MM-DD') : null);
    const [fechaVigenciaPickerFocused, setFechaVigenciaPickerFocused] = useState(false);
    const [fechaCaducidad, setFechaCaducidad] = useState(newAddBeneficiario!==null && newAddBeneficiario.fechacaducidad!=='' ? moment(newAddBeneficiario.fechacaducidad,'YYYY-MM-DD'): null);
    const [fechaCaducidadPickerFocused, setFechaCaducidadPickerFocused] = useState(false);
    
    const onTabChange = (index) => {
        setTabIndex(index);

        if (index===1 && (!listEntrega || listEntrega.length<=0))
            dispatch(fetchListEntregaDataxCovid({token: users.user.claveAPI}));
    }

    useEffect(() => {
        if (!ListBeneficiarios || ListBeneficiarios.length<=0)
            dispatch(fetchBeneficiariosData({token: users.user.claveAPI}));

        if (!listBenef || listBenef.length<=0){
            dispatch(fetchBeneficiariosDataxCovid({token: users.user.claveAPI}));
            return
        }
        if (!listCriterios || listCriterios.length<=0){
            dispatch(fetchTablasCriteriosxCovid({token: users.user.claveAPI}));
            return
        }
        if (listEntrega===null){
            dispatch(fetchListEntregaDataxCovid({token: users.user.claveAPI}));
            return
        }

        return () => {
            // executed when unmount
            // console.log('Saliendo del Modulo Covid-Ayudas');
            // setIsFiltered(false);
            // setFiltroBeneficiario([]);
            // dispatch(displayCovidFilter({isFilterShow:false}));
            // dispatch(resetfilterBeneficiariosData());
            // dispatch(resetSelectBeneficiarioForPerfilxCovid());
        }
        
    }, [users.user.claveAPI, listBenef, listCriterios, listEntrega, ListBeneficiarios, dispatch]);

    useEffect(() => {

        const getListadeBusquedaAyudas = (data) => {
            // Extraer los datos de Busqueda
            // Problema con casos especiales donde docId es nulo
            const datos = [...data].filter(itm => itm.docId!==null);
            let cantidad = datos.length;
            let items_0 = datos.map((b, idx) => {
                return (
                {
                    "id": idx + "-" + b.docId,
                    // "label": b.apellidos.trim() +' '+ b.nombres.trim(),
                    "label": b.nombrequienreclama.trim()
                }
                );
            })
            let items_1 = datos.map((b, idx) => {
                return (
                {
                    "id": (cantidad+idx+1) + "-" + b.docId, //docIdquienreclama
                    "label": b.docId.trim(),
                }
                );
            })
            let items_2 = datos.map((b, idx) => {
                return (
                    {
                        "id": (cantidad*2+idx+1) + "-" + b.docId,
                        "label": b.alias.trim()
                    }
                );
            })
            items_0 = items_0.filter(itm => (itm.id!==undefined || itm.label!==undefined));
            items_1 = items_1.filter(itm => (itm.id!==undefined || itm.label!==undefined));
            items_2 = items_2.filter(itm => (itm.id!==undefined || itm.label!==undefined));
            let items = [...items_0.concat(items_1, items_2)]; //items_0.filter(itm => itm!==undefined);
            // TODO: Verificar el por que degrada la app, colocandola lenta
            // // Get Duplicates
            // let duplicates = getDuplicates(items, 'id');
            // if (duplicates.length>0) items = [...duplicates];
            // const unique_items = RemoveDuplicates(items, 'id'); // Get Unique list of Beneficiarios

            return items
        }

        const getListadeBusquedaCompleta = (data) => {
            // Extraer los datos de Busqueda
            // Problema con casos especiales donde docId es nulo
            const datos = [...data].filter(itm => itm.docId!==null);
            let cantidad = datos.length;
            let items_0 = datos.map((b, idx) => {
                return (
                {
                    "id": idx + "-" + b.docId,
                    "label": b.apellidos.trim() +' '+ b.nombres.trim(),
                }
                );
            })
            let items_1 = datos.map((b, idx) => {
                return (
                {
                    "id": (cantidad+idx+1) + "-" + b.docId,
                    "label": b.docId.trim(),
                }
                );
            })
            let items_2 = datos.map((b, idx) => {
                return (
                    {
                        "id": (cantidad*2+idx+1) + "-" + b.docId,
                        "label": b.alias.trim()
                    }
                );
            })
            items_0 = items_0.filter(itm => (itm.id!==undefined || itm.label!==undefined));
            items_1 = items_1.filter(itm => (itm.id!==undefined || itm.label!==undefined));
            items_2 = items_2.filter(itm => (itm.id!==undefined || itm.label!==undefined));
            let items = [...items_0.concat(items_1, items_2)]; //items_0.filter(itm => itm!==undefined);
            
            return items
        }

        let datos = [];
        if (filter && filter.length>0){
            setIsFiltered(true);
            datos = [...filter];
            setFiltroBeneficiario(filter);
        } else {
            if (listBenef) datos = [...listBenef];
            if (!isFiltered)
                setFiltroBeneficiario(listBenef);
        }
        if (datos.length>0){
            
            // let items = getListadeBusqueda(datos);
            setSearchItems(getListadeBusquedaAyudas(datos));
            // Asignar a busqueda de cualqueir beneficiario
            setSearchItemsAddBeneficiario(getListadeBusquedaCompleta(ListBeneficiarios));

            // Cantidad de Beneficiarios y Ayudas
            // Tipo Ayuda
            const cant_bonos = datos.filter(b => b.tipoayuda.toLowerCase()==='bono').length;
            const cant_mercados = datos.filter(b => b.tipoayuda.toLowerCase()==='mercado').length;
            // Estado Ayuda
            // const cant_redimido = datos.filter(b => b.estadoayuda.toLowerCase()==='redimido').length;
            // const cant_caducado = datos.filter(b => b.estadoayuda.toLowerCase()==='caducado').length;
            // Afectación
            const cant_afectacion_alta = datos.filter(b => b.afectacion.toLowerCase()==='alta').length;
            const cant_afectacion_media = datos.filter(b => b.afectacion.toLowerCase()==='media').length;
            const cant_afectacion_baja = datos.filter(b => b.afectacion.toLowerCase()==='baja').length;
            // const cant_afectacion_noaplica = datos.filter(b => b.afectacion.toLowerCase()==='no aplica').length;
            setCantidadBonos(cant_bonos);
            setCantidadMercados(cant_mercados);

            setCantidadAfectAlta(cant_afectacion_alta);
            setCantidadAfectMedia(cant_afectacion_media);
            setCantidadAfectBaja(cant_afectacion_baja);

            // Extraer los costos totales.
            let costosTotales = 0;
            let costoBonos = 0;
            let costoMercados = 0;
            let costoAfectAlta = 0;
            let costoAfectMedia = 0;
            let costoAfectBaja = 0;
            datos.forEach((reg, idx) => {
                costosTotales = costosTotales + reg.valor;
                if (reg.tipoayuda.toLowerCase()==='bono') costoBonos = costoBonos + reg.valor;
                if (reg.tipoayuda.toLowerCase()==='mercado') costoMercados = costoMercados + reg.valor;
                if (reg.afectacion.toLowerCase()==='alta') costoAfectAlta = costoAfectAlta + reg.valor;
                if (reg.afectacion.toLowerCase()==='media') costoAfectMedia = costoAfectMedia + reg.valor;
                if (reg.afectacion.toLowerCase()==='baja') costoAfectBaja = costoAfectBaja + reg.valor;
            });
            setCostoTotalBonos(costoBonos);
            setCostoTotalMercados(costoMercados);
            setCostoTotalAfectAlta(costoAfectAlta);
            setCostoTotalAfectMedia(costoAfectMedia);
            setCostoTotalAfectBaja(costoAfectBaja);
            setCostoTotalAyudas(costosTotales);
        }
        
        if (listBenef && listBenef.length>0)
            dispatch(moduleCOVID19Loaded({loaded:true}));


    }, [listBenef, isListEntrega, listEntrega, ListBeneficiarios, filter, isFiltered, dispatch]);

    // Search 
    const searchChanged = (e) => {
        setSelectedItem(e);
        if (e && e.id){
            const doc = e['id'].split('-')[1];
            getBeneficiarioByDocId(doc)
        }
    }
    const clearSearch = () => {
        setInputValue('');
        setSelectedItem('');
        setIsFiltered(false);
        setFiltroBeneficiario(listBenef);
        // dispatch(filterBeneficiariosDataxCovid(listBenef));
    }
    const getBeneficiarioByDocId = (docId) => {
        if (docId && docId!==null && docId!==''){
            setIsFiltered(true);
            setFiltroBeneficiario(listBenef.filter(b => b.docId === docId));
            // dispatch(filterBeneficiariosDataxCovid(listBenef.filter(b => b.docId === docId)));
        } 
    };
    const onUserAction = (inputValue, selectedItemVal) => {
        setInputValue(inputValue);
        setSelectedItem(selectedItemVal);
    }

    // Add beneficiario
    const searchChangedAddBeneficiario = (e) => {
        setSelectedItemAddBeneficiario(e);
        if (e && e.id){
            const doc = e['id'].split('-')[1];
            setupnewBeneftoListEntrega(doc)
        }
    }
    const clearSearchAddBeneficiario = () => {
        setInputValueAddBeneficiario('');
        setSelectedItemAddBeneficiario('');
        setNewAddBeneficiario(null);
        setSuccess(null);
        updateItemsparaEntrega({
            "id": '',
            "beneficiarioId": '',
            "docIdquienreclama": '',
            "nombrequienreclama": '',
            "numentrega": '',
            "serial": '',
            "convenio": '',
            "tipoayuda": '',
            "cupo": '',
            "valor": '',
            "estadoayuda": '',
            "afectacion": '',
            "fechainicio": null,
            "fechavigencia": null,
            "fechacaducidad": null,
            "fecharedencion": null,
            "fechaanulacion": null,
            "transaccion": '',
            "nut": '',
            "terminal": '',
            "descripcion": '',
            // "entregada": entregada==='1' ? true : false, 
            "almacen": '',
            "tipocaso": '',
            "comentarios": '',
            "actualizacion": '',
            "version": ''
        });
    }
    const onUserActionAddBeneficiario = (inputValue, selectedItemVal) => {
        setInputValueAddBeneficiario(inputValue);
        setSelectedItemAddBeneficiario(selectedItemVal);
    }

    const onClickToggleFilter = () => {
        dispatch(displayCovidFilter({isFilterShow:!isFilterShow}));
    }

    const onClickToggleCostos = () => {
        setIsCostosShow(!isCostosShow);
    }

    const setupnewBeneftoListEntrega = (docId) => {
        if (docId && docId!==null && docId!==''){
            let beneftoadd = [...listBenef].filter(b => b.docId === docId);
            if (beneftoadd && beneftoadd.length===0 && ListBeneficiarios && ListBeneficiarios.length>0)
                beneftoadd = [...ListBeneficiarios].filter(b => b.docId === docId); // todos los Beneficiarios

            let benefRef = null;
            let benefTar = null;
            if (listEntrega && listEntrega.length>0){
                benefRef = [...listEntrega][0]; //Tomamos el primer beneficiario de la lista para extraer los params grles...
                // let itm = Object.assign({}, item); 
            }
            if (beneftoadd && beneftoadd.length>0){
                // console.log(beneftoadd[0]);
                benefTar = Object.assign({}, beneftoadd[0]);  //[...beneftoadd][0];
                benefTar['numentrega'] = (benefRef && benefRef['numentrega']) ? benefRef['numentrega'] : '';
                benefTar['convenio'] = (benefRef && benefRef['convenio']) ? benefRef['convenio'] : '801';
                benefTar['tipoayuda'] = (benefRef && benefRef['tipoayuda']) ? benefRef['tipoayuda'] : 'Bono';
                benefTar['fechainicio'] = (benefRef && benefRef['fechainicio']) ? benefRef['fechainicio'] : moment(new Date(), 'YYYY-MM-DD');
                benefTar['fechavigencia'] = (benefRef && benefRef['fechavigencia']) ? benefRef['fechavigencia'] : '';
                benefTar['fechacaducidad'] = (benefRef && benefRef['fechacaducidad']) ? benefRef['fechacaducidad'] : '';
                // benefTar['serial'] = (benefRef && benefRef['fechainicio']) ? benefRef['fechainicio'].split(' ')[0].replace(/-/g,'') + (listEntrega.length + 1) : '';
                // Corregir asistencia, cuando el valor en la DB es NULL por error.
                benefTar['asistencia'] = (benefTar.asistencia===undefined || benefTar.asistencia===null || benefTar.asistencia==='') ? '0.000' : benefTar.asistencia;
                // Estos campo son del perfil por tanto no existen undefined
                // benefTar['jefehogar'] = benefTar.jefehogar;
                // benefTar['personasacargo'] = benefTar.personasacargo;
                // benefTar['numhijos'] = benefTar.numhijos;

                const clase_afectacion = calcularAfectacion({
                    'prestarservicio': parseInt(benefTar.prestarservicio)===1 ? 'SI' : 'NO',
                    'tienefmliaclub': parseInt(benefTar.tienefmliaclub)===1 ? 'SI' : 'NO',
                    'jefehogar': benefTar.jefehogar,
                    'personasacargo': benefTar.personasacargo,
                    'numhijos': benefTar.numhijos,
                    'edad': benefTar.edad,
                    'edadclub': benefTar.edadclub,
                    'asistencia': benefTar.asistencia,
                    'actividad': benefTar.actividad,
                    'categoria': benefTar.categoria,
                    'cupo': benefTar.cupo,
                }, listCriterios);

                benefTar['tipoafectacion'] = clase_afectacion.tipoafectacion;
                benefTar['tipoafectacion2'] = clase_afectacion.tipoafectacion2;
                benefTar['tipoafectacionescalado'] = clase_afectacion.tipoafectacionescalado;
                benefTar['afectacionfinal'] = clase_afectacion.afectacionfinal;
                benefTar['afectacionfinal2'] = clase_afectacion.afectacionfinal2;
                benefTar['valor'] = clase_afectacion.valorfinal;
                benefTar['valorfinal'] = clase_afectacion.valorfinal;
                benefTar['perc_afect'] = clase_afectacion.perc_afect;
                benefTar['perc_afect2'] = clase_afectacion.perc_afect2;
                // benefTar['cupo'] = clase_afectacion.valorfinal;
                benefTar['cupoxperc'] = clase_afectacion.cupoxperc;

                benefTar['vlr_prestarservicio'] = clase_afectacion.vlr_prestarservicio;
                benefTar['vlr_jefehogar'] = clase_afectacion.vlr_jefehogar;
                benefTar['vlr_personasacargo'] = clase_afectacion.vlr_personasacargo;
                benefTar['vlr_numhijos'] = clase_afectacion.vlr_numhijos;
                benefTar['vlr_tienefmliaclub'] = clase_afectacion.vlr_tienefmliaclub;
                benefTar['vlr_actividad'] = clase_afectacion.vlr_actividad;
                benefTar['vlr_categoria'] = clase_afectacion.vlr_categoria;
                benefTar.asistencia = Math.ceil(benefTar.asistencia*100);
                benefTar['vlr_asistencia'] = clase_afectacion.vlr_asistencia;
                benefTar['vlr_edad'] = clase_afectacion.vlr_edad;
                benefTar['vlr_edadclub'] = clase_afectacion.vlr_edadclub;

                // Limpiar algunos
                benefTar['beneficiarioId'] = benefTar.docId; //(benefTar['beneficiarioId']===null || benefTar['beneficiarioId']==='') ? benefTar.docId : '';
                benefTar['docIdquienreclama'] = benefTar.docId; //(benefTar['docIdquienreclama']===null || benefTar['docIdquienreclama']==='') ? benefTar.docId : '';
                benefTar['nombrequienreclama'] = benefTar.nombres +' '+benefTar.apellidos; //(benefTar['nombrequienreclama']===null || benefTar['nombrequienreclama']==='') ? benefTar.nombres +' '+benefTar.apellidos : '';

                benefTar['estadoayuda'] = "Programada";
                benefTar['tipoayuda'] = "Bono";
                benefTar['fecharedencion'] = null;
                benefTar['fechaanulacion'] = null;
                benefTar['transaccion'] = '';
                benefTar['nut'] = '';
                benefTar['terminal'] = '';
                benefTar['descripcion'] = '';
                benefTar['entregada'] = '0';
                benefTar['almacen'] = '';
                benefTar['tipocaso'] = '';
                benefTar['comentarios'] = '';
                benefTar['actualizacion'] = '2020-03-20T14:00:00.000Z';
                benefTar['version'] = '2020-03-20T14:00:00.000Z';

                // console.log("benefTar===>", benefTar);
                // const newlistEntrega = [...listEntrega].concat([benefTar]);
                // dispatch(setCovidListEntrega({listEntrega:newlistEntrega}));
                setNewAddBeneficiario(benefTar);
                updateItemsparaEntrega(benefTar);
                
            }
        }

    }
    const onClickAddBeneftoListEntrega = () => {
        // console.log(newAddBeneficiario);
        let errormsg = '';
        if (newAddBeneficiario.beneficiarioId==='' || newAddBeneficiario.beneficiarioId===null){
            errormsg = 'Dígite un identificador válido';
        }
        if (newAddBeneficiario.docIdquienreclama==='' || newAddBeneficiario.docIdquienreclama===null){
            errormsg = 'Dígite un identificador válido';
        }
        if (newAddBeneficiario.nombrequienreclama==='' || newAddBeneficiario.nombrequienreclama===null){
            errormsg = 'Dígite un nombre válido de quién reclama';
        }
        if (newAddBeneficiario.fechacaducidad==='' || newAddBeneficiario.fechacaducidad===null){
            errormsg = 'Seleccione una fecha de caducidad válida';
        }
        if (newAddBeneficiario.fechavigencia==='' || newAddBeneficiario.fechavigencia===null){
            errormsg = 'Seleccione una fecha de vigencia válida';
        }
        if (newAddBeneficiario.fechainicio==='' || newAddBeneficiario.fechainicio===null){
            errormsg = 'Seleccione una fecha de inicio válida';
        }
        // if (newAddBeneficiario.tipoayuda==='' || newAddBeneficiario.tipoayuda===null){
        //     errormsg = 'Dígite un tipo de ayuda válido';
        // }
        if (newAddBeneficiario.numconvenio==='' || newAddBeneficiario.numconvenio===null){
            errormsg = 'Dígite un número de convenio válido';
        }
        if (newAddBeneficiario.numentrega==='0' || newAddBeneficiario.numentrega==='' || newAddBeneficiario.numentrega===null){ 
            errormsg = 'Dígite un número de entrega válido';
        }

        if (newAddBeneficiario.cupo===0 || newAddBeneficiario.cupo==='' || newAddBeneficiario.cupo===null){ 
            errormsg = 'Dígite un número de cupo válido';
        }
        if (newAddBeneficiario.valor===0 || newAddBeneficiario.valor==='' || newAddBeneficiario.valor===null){ 
            errormsg = 'Dígite un número de valor de ayuda válido';
        }
        if (errormsg!==''){
            setError(errormsg);
            return
        }
        
        if (newAddBeneficiario!==null && newAddBeneficiario.beneficiarioId!==''){
            // Dejar los valores escritos por el Usuario
            newAddBeneficiario.cupoxperc = newAddBeneficiario.cupo;
            newAddBeneficiario.valorfinal = newAddBeneficiario.valor;
            newAddBeneficiario.comentarios = newAddBeneficiario.comentarios==='' ? 'Caso agregado y modificado manualmente' : newAddBeneficiario.comentarios +'. Caso agregado y modificado manualmente por '+users.user.nombre;
            // Convertir fechas antes de enviar
            newAddBeneficiario.fechainicio = moment(newAddBeneficiario.fechainicio).format("YYYY-MM-DD");
            newAddBeneficiario.fechavigencia = moment(newAddBeneficiario.fechavigencia).format("YYYY-MM-DD");
            newAddBeneficiario.fechacaducidad = moment(newAddBeneficiario.fechacaducidad).format("YYYY-MM-DD");
            
            newAddBeneficiario.serial = newAddBeneficiario.numentrega + newAddBeneficiario.fechainicio.split(' ')[0].replace(/-/g,'') + (listEntrega.length + 1);
            const newlistEntrega = [...listEntrega].concat([newAddBeneficiario]);
            dispatch(setCovidListEntrega({listEntrega:newlistEntrega}));

            setSuccess('Usuario agregado con exito!');
        }
    } 
    const onClickAceptarAddBenef = () => {
        dispatch(displayAddBenefListEntrega({isAddBenefaListEntrega:!isAddBenefaListEntrega}));
        clearSearchAddBeneficiario();
    }


    const replaceSomeText = (s) =>{
        return s
            .replace(/&nbsp;/g, ' ')
            .replace(/<br>/g, '')
            .replace(/&amp;/g, '&')
            .replace(/&gt;/g, '>')
            .replace(/&lt;/g, '<').trim()
    }

    const validateInput = (f, v, final) => {
        const regex_onlynum = /^[0-9]*$/i; ///[0-9,]|\./;
        // const regex_nombres = /^['`´a-zA-ZÀ-ÿ\u00C0-\u017F\s]+$/;
        let new_val = null;
        let err = null;
        switch (f) {
            case 'beneficiarioId':
                new_val = replaceSomeText(v).substr(0,15);
                if (!regex_onlynum.test(new_val)) {
                    err = 'Dígite solo números válidos';
                    new_val = null;
                }
                if (new_val===null) new_val = '';
                break;
            case 'docIdquienreclama':
                new_val = replaceSomeText(v).substr(0,15);
                if (!regex_onlynum.test(new_val)) {
                    err = 'Dígite solo números válidos';
                    new_val = null;
                }
                if (new_val===null) new_val = '';
                break;
            case 'numentrega': // Valida cuando el campo es de texto y no un componente Switch
                new_val = v;
                if (!regex_onlynum.test(v)) {
                    err = 'Dígite un solo número válido';
                    new_val = null;
                } else if(parseInt(v)>100){
                    err = 'Dígite un número válido';
                    new_val = null;
                }
                break;
            case 'serial':
                new_val = v.substr(0,32);
                if (final)
                    new_val = replaceSomeText(v).substr(0,32).toUpperCase();
                break;
            case 'convenio':
                new_val = v.substr(0,32);
                if (final)
                    new_val = replaceSomeText(v).substr(0,32).toUpperCase();
                break;
            case 'tipoayuda':
                new_val = v.substr(0,32);
                if (final)
                    new_val = capitalize(replaceSomeText(v).substr(0,32),true);
                break;
            case 'cupo':
                new_val = replaceSomeText(v);
                if (!regex_onlynum.test(new_val)) {
                    err = 'Dígite solo números válidos';
                    new_val = null;
                }
                if (new_val===null) new_val = '';
                break;
            case 'valor':
                new_val = replaceSomeText(v);
                if (!regex_onlynum.test(new_val)) {
                    err = 'Dígite solo números válidos';
                    new_val = null;
                }
                if (new_val===null) new_val = '';
                break;
            case 'nombrequienreclama':
                    new_val = v;
                    // if (!regex_nombres.test(new_val)) {
                    //     err = 'Dígite nombres y apellidos válido';
                    //     new_val = null;
                    // }
                    if (final)
                        new_val = capitalize(replaceSomeText(v).substr(0,60),true);
                    break;
            case 'estadoayuda':
                new_val = v.substr(0,32);
                if (final)
                    new_val = replaceSomeText(v).substr(0,32);
                break;
            case 'fechainicio': //0000-00-00 00:00:00
                new_val = v.substr(0,30);
                if (final && new_val===''){
                    new_val = replaceSomeText(v).substr(0,30);
                    err = 'Dígite una fecha inicial válida';
                    new_val = null;
                }
                break;
            case 'fechavigencia': //0000-00-00 00:00:00
                new_val = v.substr(0,30);
                if (final && new_val===''){
                    new_val = replaceSomeText(v).substr(0,30);
                    err = 'Dígite una fecha vigencia válida';
                    new_val = null;
                }
                break;
            case 'fechacaducidad': //0000-00-00 00:00:00
                new_val = v.substr(0,30);
                if (final && new_val===''){
                    new_val = replaceSomeText(v).substr(0,30);
                    err = 'Dígite una fecha caducidad válida';
                    new_val = null;
                }
                break;
            case 'fecharedencion': //0000-00-00 00:00:00
                new_val = v.substr(0,30);
                if (final && new_val===''){
                    new_val = replaceSomeText(v).substr(0,30);
                    err = 'Dígite una fecha redención válida';
                    new_val = null;
                }
                break;
            case 'fechaanulacion': //0000-00-00 00:00:00
                new_val = v.substr(0,30);
                if (final && new_val===''){
                    new_val = replaceSomeText(v).substr(0,30);
                    err = 'Dígite una fecha anulación válida';
                    new_val = null;
                }
                break;
            case 'afectacion':
                new_val = v.substr(0,32);
                if (final)
                    new_val = replaceSomeText(v).substr(0,32);
                break;
            case 'transaccion':
                new_val = v.substr(0,32);
                if (final)
                    new_val = replaceSomeText(v).substr(0,32);
                break;
            case 'nut':
                new_val = v.substr(0,32);
                if (final)
                    new_val = replaceSomeText(v).substr(0,32);
                break;
            case 'terminal':
                new_val = v.substr(0,32);
                if (final)
                    new_val = replaceSomeText(v).substr(0,32);
                break;
            case 'entregada':
                new_val = v;
                if (!regex_onlynum.test(v)) {
                    err = 'Dígite un solo número válido';
                    new_val = null;
                } else if(parseInt(v)>1){
                    err = 'Dígite un número válido';
                    new_val = null;
                }
                break;
            case 'almacen':
                new_val = v.substr(0,120);
                if (final)
                    new_val = replaceSomeText(v).substr(0,120);
                break;
            case 'tipocaso':
                new_val = v.substr(0,32);
                if (final)
                    new_val = replaceSomeText(v).substr(0,32);
                break;
            case 'descripcion':
                new_val = v.substr(0,255);
                if (final)
                    new_val = replaceSomeText(v).substr(0,255);
                break;
            case 'comentarios':
                new_val = v.trim();
                if (final)
                    new_val = replaceSomeText(v); //.substr(0,255);
                break;
            default:
                break;
        }
        if (err) setError(err);
        return new_val
    }
    const handleChange = evt => {
        // console.log(evt.currentTarget.id, evt.target.value);
        const field = evt.currentTarget.id.split('_')[1];
        const new_val = validateInput(field, evt.target.value, false)
        if(new_val){
            newAddBeneficiario[field] = new_val;
            // updateItemsCovidAyudas(field, new_val);
        };
    };
    const handleBlur = evt => {
        const val = evt.currentTarget.innerHTML;
        const field = evt.target.id.split('_')[1];
        // console.log(field, val);
        const new_val = validateInput(field, val, true)
        if(new_val || new_val===''){
            newAddBeneficiario[field] = new_val.toString();
            updateItemsCovidAyudas(field, new_val.toString());
        };
        
    };
    const updateItemsCovidAyudas = (f, v) => {
        const newitemsCovidAyudas = itemsAddBeneftoEntrega.map((itm, index) => {
            if (itm.key===f){
                itm.value = v;
            }
            return itm;
        });
        setItemsAddBeneftoEntrega(newitemsCovidAyudas);
    };
    const updateItemsparaEntrega = (benefTar) => {
        // const modifiedAddBeneficiario = Object.assign({}, newAddBeneficiario);
        const newitemsCovidAyudas = itemsAddBeneftoEntrega.map((itm, index) => {
            itm.value = benefTar[itm.key];
            return itm;
        });
        setItemsAddBeneftoEntrega(newitemsCovidAyudas);
    };

    // DatePicker
    const onFechaInicioChange = (date) =>{
        if (date.preventDefault) date.preventDefault()
        if (date){
            // console.log("onFechaInicioChange formatted==>",date.format('YYYY-MM-DD'));
            // newAddBeneficiario.fechainicio = date.utc().format('YYYY-MM-DD HH:mm:ss');
            newAddBeneficiario.fechainicio = moment(date.format('YYYY-MM-DD')).format("YYYY-MM-DD HH:mm:ss");
            updateItemsCovidAyudas('fechainicio', newAddBeneficiario.fechainicio);
            setFechaInicio(date);
        }
    }
    const onFechaInicioFocusChange = ({ focused }) =>{
        setFechaInicioPickerFocused(focused)
    }
    // DatePicker 
    const onFechaVigenciaChange = (date) =>{
        if (date.preventDefault) date.preventDefault()
        if (date){
            newAddBeneficiario.fechavigencia = moment(date.format('YYYY-MM-DD')).format("YYYY-MM-DD HH:mm:ss"); //date.utc().format('YYYY-MM-DD HH:mm:ss');
            newAddBeneficiario.fechacaducidad = moment(date.format('YYYY-MM-DD')).format("YYYY-MM-DD HH:mm:ss");
            setFechaVigencia(date);
            setFechaCaducidad(date);
            updateItemsCovidAyudas('fechavigencia', newAddBeneficiario.fechavigencia);
            updateItemsCovidAyudas('fechacaducidad', newAddBeneficiario.fechavigencia);
        }
    }
    const onFechaVigenciaFocusChange = ({ focused }) =>{
        setFechaVigenciaPickerFocused(focused)
    }

    // DatePicker 
    const onFechaCaducidadChange = (date) =>{
        if (date.preventDefault) date.preventDefault()
        if (date){
            newAddBeneficiario.fechacaducidad = moment(date.format('YYYY-MM-DD')).format("YYYY-MM-DD HH:mm:ss"); //date.utc().format('YYYY-MM-DD HH:mm:ss');
            setFechaCaducidad(date);
            updateItemsCovidAyudas('fechacaducidad', newAddBeneficiario.fechacaducidad);
        }
    }
    const onFechaCaducidadFocusChange = ({ focused }) =>{
        setFechaCaducidadPickerFocused(focused)
    }

    // const { countUp } = useCountUp({ end: costoTotalAyudas });

    if (listBenef && listBenef.length>0){
        
    
        return (
            <Fragment>
                {selectedProfile && <div style={{paddingRight:'50px'}}>
                        <BeneficiarioDetalleContainer>
                            <BenefDetailsContainer />
                        </BeneficiarioDetalleContainer>
                    </div>
                }
                {isListEntrega && <div style={{paddingRight:'50px'}}>
                        <ListaEntregaContainer>
                            <ListaEntrega listAyudas={listBenef} listfilter={filtroBeneficiario} />
                        </ListaEntregaContainer>
                    </div>
                }
                <div style={{paddingRight:'50px'}}>
                    <AyudasTopToolBar>
                        <FiltroButton onClick={onClickToggleFilter}>
                            {FiltroIcon}
                        </FiltroButton>
                        <CostosButton >
                            <CostosIcon onClick={onClickToggleCostos}/>
                        </CostosButton>
                    </AyudasTopToolBar>
                    
                    {isCostosShow && <CostosContainer>
                        <div>
                            <div className='covidAyudas-costosContainer-costoxTipoAyuda'>
                                <div>
                                    <div className='covidAyudas-costosContainer-costoValor'>
                                        {/* $ {formatMoney(costoTotalBonos, 0)} */}
                                        <CountUp end={costoTotalBonos} duration={0.5} prefix="$ " separator="," decimals={0} decimal="." />
                                    </div>
                                    <div className='covidAyudas-costosContainer-costoCantidad'><AyudaBonosIcon/> <CountUp end={cantidadBonos} duration={0.75} separator=","/></div>
                                    <div className='covidAyudas-costosContainer-costoTitle'>Bonos</div>
                                </div>
                                <div>
                                    <div className='covidAyudas-costosContainer-costoValor'>
                                        {/* $ {formatMoney(costoTotalMercados, 0)} */}
                                        <CountUp end={costoTotalMercados} duration={0.5} prefix="$ " separator="," decimals={0} decimal="." />
                                    </div>
                                    <div className='covidAyudas-costosContainer-costoCantidad'><AyudaMercadoIcon/> <CountUp end={cantidadMercados} duration={0.75} separator=","/></div>
                                    <div className='covidAyudas-costosContainer-costoTitle'>Mercados</div>
                                </div>
                            </div>
                            <div className='covidAyudas-costosContainer-costoxAfectacion'>
                                <div>
                                    <div className='covidAyudas-costosContainer-costoValor'>
                                        {/* $ {formatMoney(costoTotalAfectAlta, 0)} */}
                                        <CountUp end={costoTotalAfectAlta} duration={0.5} prefix="$ " separator="," decimals={0} decimal="." />
                                    </div>
                                    <div className='covidAyudas-costosContainer-costoCantidad'><PeopleIcon/> <CountUp end={cantidadAfectAlta} duration={0.75} separator=","/></div>
                                    <div className='covidAyudas-costosContainer-costoTitle'>Alta</div>
                                </div>
                                <div>
                                    <div className='covidAyudas-costosContainer-costoValor'>
                                        {/* $ {formatMoney(costoTotalAfectMedia, 0)} */}
                                        <CountUp end={costoTotalAfectMedia} duration={0.5} prefix="$ " separator="," decimals={0} decimal="." />
                                    </div>
                                    <div className='covidAyudas-costosContainer-costoCantidad'><PeopleIcon/> <CountUp end={cantidadAfectMedia} duration={0.75} separator=","/></div>
                                    <div className='covidAyudas-costosContainer-costoTitle'>Media</div>
                                </div>
                                <div>
                                    <div className='covidAyudas-costosContainer-costoValor'>
                                        {/* $ {formatMoney(costoTotalAfectBaja, 0)} */}
                                        <CountUp end={costoTotalAfectBaja} duration={0.5} prefix="$ " separator="," decimals={0} decimal="." />
                                    </div>
                                    <div className='covidAyudas-costosContainer-costoCantidad'><PeopleIcon/> <CountUp end={cantidadAfectBaja} duration={0.75} separator=","/></div>
                                    <div className='covidAyudas-costosContainer-costoTitle'>Baja</div>
                                </div>
                            </div>
                        </div>
                        
                        {/* <div className='covidAyudas-costosContainer-costoTotal'>$ {formatMoney(costoTotalAyudas, 0)}</div> */}
                        <div className='covidAyudas-costosContainer-costoTotal'>
                            <CountUp end={costoTotalAyudas} duration={0.75} prefix="$ " separator="," decimals={0} decimal="." />
                        </div>
                    </CostosContainer>}
                </div>
                
                {activeTabIndex===0 && <SearchBeneficiario>
                    <Search
                        virtualized
                        virtualizedMenuWidth={250}
                        placeholder='Buscar...' 
                        inputValue={inputValue}
                        selectedItem={selectedItem}
                        items={searchItems}
                        dataSourceConfig={{ label: 'label', value: 'id' }}
                        minimal
                        appendToBody
                        onChange={searchChanged}
                        onUserAction={onUserAction}
                        onRequestClear={clearSearch}
                    />
                </SearchBeneficiario>}
                
                <Tabs style={{background:'#fff'}}
                    onTabChange={onTabChange}
                    activeTabIndex={activeTabIndex}
                >
                    <TabNav>
                        <TabTitle><ListButtonIcon size={16} color={'green'} /></TabTitle>
                        <TabTitle /* disabled={(listEntrega && listEntrega.length>0) ? true : false} */
                        >Lista Entrega</TabTitle>
                    </TabNav>
                    <TabContents>
                    <TabSection>
                        {filtroBeneficiario && filtroBeneficiario.length>0 && 
                            <div style={{paddingRight:'50px'}}>
                                <DisplayListBeneficiarios isFiltered={isFiltered} listBenef={filtroBeneficiario} />
                            </div>
                        }
                    </TabSection>
                    <TabSection>
                    { !isListEntrega && isAddBenefaListEntrega ?
                        <div style={{paddingRight:'50px'}}>
                            <div style={{fontSize:'12pt', fontWeight:'600'}}>Agregar Beneficiario <br/>a lista de Entrega</div>
                            <div>
                                <Search
                                    virtualized
                                    virtualizedMenuWidth={250}
                                    placeholder='Buscar...' 
                                    inputValue={inputValueAddBeneficiario}
                                    selectedItem={selectedItemAddBeneficiario}
                                    items={searchItemsAddBeneficiario}
                                    dataSourceConfig={{ label: 'label', value: 'id' }}
                                    minimal
                                    appendToBody
                                    onChange={searchChangedAddBeneficiario}
                                    onUserAction={onUserActionAddBeneficiario}
                                    onRequestClear={clearSearchAddBeneficiario}
                                />
                            </div>
                            <div>
                                {error && <div style={{color: 'red', width: '100%'}}>
                                        <Alert red showIcon style={{minWidth: '100%', marginBottom: '10px'}}
                                            showCloseLabel 
                                            onClose={() => setError(null)}>
                                            <AlertMessage>
                                                Error:{' '}
                                                <strong>{error}</strong>
                                            </AlertMessage>
                                        </Alert>
                                    </div> 
                                }
                                {success && <div style={{color: 'green', width: '100%'}}>
                                        <Alert green showIcon style={{minWidth: '100%', marginBottom: '10px'}}
                                            showCloseLabel 
                                            onClose={() => clearSearchAddBeneficiario()}>
                                            <AlertMessage>
                                                {/* Info:{' '} */}
                                                <strong>{success}</strong>
                                            </AlertMessage>
                                        </Alert>
                                    </div> 
                                }
                            </div>
                                {/* ITEMS */}
                                <div className="itemContainer-covid">
                                {  newAddBeneficiario && itemsAddBeneftoEntrega &&
                                    itemsAddBeneftoEntrega.map((itm, index) => {
                                            return (<div key={index} className="itemContainer">
                                                <div className="itemContainer-attr" 
                                                    style={{backgroundColor:(index % 2 === 0) ? '#f8f8f8': '#fff'}}
                                                >{itm.label}</div>
                                                <div className="itemContainer-value" 
                                                    style={{backgroundColor:(index % 2 === 0) ? '#f8f8f8': '#fff'}}
                                                >
                                                    {/* {itm.value} */}
                                                    { 
                                                    // itm.key==='estadoayuda' 
                                                    // ?
                                                    //     <Select filterable positionFixed disabled={!isEditable} 
                                                    //         placeholder={'Seleccione...'}
                                                    //         onChange={handleEstadoAyudaSelectChange}
                                                    //         onKeyDown={handleCategoriaseguimientoonKeyDown}
                                                    //         // onBlur={handleCategoriaseguimientoSelectonBlur}
                                                    //         // onChange={(e, val) => console.log(e, val)}
                                                    //         value={estadoayudaSelectedValue || ''}
                                                    //         selectedValue={estadoayudaSelectedValue || ''}
                                                    //     >
                                                    //     {tipoEstadoAyuda.tipo.map(e => {
                                                    //         return (
                                                    //         <MenuItem key={e.id} value={e.name}>
                                                    //             {e.name}
                                                    //         </MenuItem>
                                                    //         )
                                                    //     })}
                                                    //     </Select>
                                                    // :
                                                    // itm.key==='tipoayuda' 
                                                    // ?
                                                    //     <Select filterable positionFixed disabled={!isEditable} 
                                                    //         placeholder={'Seleccione...'}
                                                    //         onChange={handleTipoAyudaSelectChange}
                                                    //         onKeyDown={handleTipoAyudaonKeyDown}
                                                    //         value={tipoayudaSelectedValue || ''}
                                                    //         selectedValue={tipoayudaSelectedValue || ''}
                                                    //     >
                                                    //     {tipoAyuda.tipo.map(e => {
                                                    //         return (
                                                    //         <MenuItem key={e.id} value={e.name}>
                                                    //             {e.name}
                                                    //         </MenuItem>
                                                    //         )
                                                    //     })}
                                                    //     </Select>
                                                    // :
                                                    // itm.key==='afectacion' 
                                                    // ?
                                                    //     <Select filterable positionFixed disabled={!isEditable} 
                                                    //         placeholder={'Seleccione...'}
                                                    //         onChange={handleAfectacionSelectChange}
                                                    //         onKeyDown={handleAfectaciononKeyDown}
                                                    //         value={afectacionSelectedValue || ''}
                                                    //         selectedValue={afectacionSelectedValue || ''}
                                                    //     >
                                                    //     {tipoAfectacion.tipo.map(e => {
                                                    //         return (
                                                    //         <MenuItem key={e.id} value={e.name}>
                                                    //             {e.name}
                                                    //         </MenuItem>
                                                    //         )
                                                    //     })}
                                                    //     </Select>
                                                    // :
                                                    // itm.key==='tipocaso' 
                                                    // ?
                                                    //     <Select filterable positionFixed disabled={!isEditable} 
                                                    //         placeholder={'Seleccione...'}
                                                    //         onChange={handleTipocasoSelectChange}
                                                    //         onKeyDown={handleTipocasoonKeyDown}
                                                    //         value={tipocasoSelectedValue || ''}
                                                    //         selectedValue={tipocasoSelectedValue || ''}
                                                    //     >
                                                    //     {tipoCasoAyuda.tipo.map(e => {
                                                    //         return (
                                                    //         <MenuItem key={e.id} value={e.name}>
                                                    //             {e.name}
                                                    //         </MenuItem>
                                                    //         )
                                                    //     })}
                                                    //     </Select>
                                                    // :
                                                    // itm.key==='entregada' 
                                                    // ?
                                                    //     <Switch 
                                                    //         labelPosition={"before" || '' }
                                                    //         style={{margin: '0 0 0.5rem 0'}}
                                                    //         name={ 'entregada_' + newAddBeneficiario.id } 
                                                    //         checked={ newAddBeneficiario.entregada || false} 
                                                    //         disabled={!isEditable} 
                                                    //         onChange={onChangeEntregada} />
                                                    // :

                                                    itm.key==='fechainicio' 
                                                    ?
                                                        <DatePicker 
                                                            placeholder="Fecha Inicial"
                                                            id="fechaInicio"
                                                            // style={{backgroundColor:(index % 2 === 0) ? '#f8f8f8 !important': '#fff !important'}}
                                                            date={fechaInicio}
                                                            focused={fechaInicioPickerFocused}
                                                            onDateChange={onFechaInicioChange}
                                                            onFocusChange={onFechaInicioFocusChange}
                                                            numberOfMonths={1}
                                                            disabled={!isEditable}
                                                            readOnly={true}
                                                            hideInputIcon
                                                            disableScroll={false}
                                                            displayFormat={'YYYY-MM-DD'} // 'MMM DD YYYY'
                                                            isOutsideRange={() => {}}
                                                            monthYearSelectionMode="MONTH_YEAR"
                                                            yearSelectDates={{"startYear" : 2020, "endYear" : new moment().year() + 1}}
                                                            // displayFormat={moment.localeData().longDateFormat('L')}
                                                            // appendToBody={true}
                                                            // withPortal={true}
                                                        />
                                                    :
                                                    itm.key==='fechavigencia' 
                                                    ?
                                                        <DatePicker 
                                                            placeholder="Fecha Vigencia"
                                                            id="fechaVigencia"
                                                            date={fechaVigencia}
                                                            focused={fechaVigenciaPickerFocused}
                                                            onDateChange={onFechaVigenciaChange}
                                                            onFocusChange={onFechaVigenciaFocusChange}
                                                            numberOfMonths={1}
                                                            disabled={!isEditable}
                                                            readOnly={true}
                                                            hideInputIcon
                                                            disableScroll={false}
                                                            displayFormat={'YYYY-MM-DD'} // 'MMM DD YYYY'
                                                            isOutsideRange={() => {}}
                                                            monthYearSelectionMode="MONTH_YEAR"
                                                            yearSelectDates={{"startYear" : 2020, "endYear" : new moment().year() + 1}}
                                                            // appendToBody={true}
                                                        />
                                                    :
                                                    itm.key==='fechacaducidad' 
                                                    ?
                                                        <DatePicker 
                                                            placeholder="Fecha Caducidad"
                                                            id="fechaCaducidad"
                                                            date={fechaCaducidad}
                                                            focused={fechaCaducidadPickerFocused}
                                                            onDateChange={onFechaCaducidadChange}
                                                            onFocusChange={onFechaCaducidadFocusChange}
                                                            numberOfMonths={1}
                                                            disabled={!isEditable}
                                                            readOnly={true}
                                                            hideInputIcon
                                                            disableScroll={false}
                                                            displayFormat={'YYYY-MM-DD'} // 'MMM DD YYYY'
                                                            isOutsideRange={() => {}}
                                                            monthYearSelectionMode="MONTH_YEAR"
                                                            yearSelectDates={{"startYear" : 2020, "endYear" : new moment().year() + 1}}
                                                            // appendToBody={true}
                                                        />
                                                    :
                                                    // itm.key==='fecharedencion'
                                                    // ?
                                                    //     <DatePicker 
                                                    //         placeholder="Fecha Redención"
                                                    //         id="fechaRedencion"
                                                    //         date={fechaRedencion}
                                                    //         focused={fechaRedencionPickerFocused}
                                                    //         onDateChange={onFechaRedencionChange}
                                                    //         onFocusChange={onFechaRedencionFocusChange}
                                                    //         numberOfMonths={1}
                                                    //         disabled={!isEditable}
                                                    //         readOnly={true}
                                                    //         hideInputIcon
                                                    //         disableScroll={false}
                                                    //         displayFormat={'YYYY-MM-DD'} // 'MMM DD YYYY'
                                                    //         isOutsideRange={() => {}}
                                                    //         monthYearSelectionMode="MONTH_YEAR"
                                                    //         yearSelectDates={{"startYear" : 2020, "endYear" : new moment().year() + 1}}
                                                    //         // appendToBody={true}
                                                    //     />
                                                    // :
                                                    // itm.key==='fechaanulacion'
                                                    // ?
                                                    //     <DatePicker 
                                                    //         placeholder="Fecha Anulación"
                                                    //         id="fechaAnulacion"
                                                    //         date={fechaAnulacion}
                                                    //         focused={fechaAnulacionPickerFocused}
                                                    //         onDateChange={onFechaAnulacionChange}
                                                    //         onFocusChange={onFechaAnulacionFocusChange}
                                                    //         numberOfMonths={1}
                                                    //         disabled={!isEditable}
                                                    //         readOnly={true}
                                                    //         hideInputIcon
                                                    //         disableScroll={false}
                                                    //         displayFormat={'YYYY-MM-DD'} // 'MMM DD YYYY'
                                                    //         isOutsideRange={() => {}}
                                                    //         monthYearSelectionMode="MONTH_YEAR"
                                                    //         yearSelectDates={{"startYear" : 2020, "endYear" : new moment().year() + 1}}
                                                    //         // appendToBody={true}
                                                    //     />
                                                    // :
                                                        <ContentEditable
                                                            id={'covidayudas_'+itm.key}
                                                            html={(itm.value && itm.value!==null) ? itm.value.toString() : ''} // innerHTML of the editable div
                                                            className='itemContainer-value-long'
                                                            disabled={!isEditable}      // use true to disable editing
                                                            onPaste={pastePlainText} // En caso se copie y pegue de otra pagina de internet
                                                            onChange={handleChange} // handle innerHTML change
                                                            onKeyPress={disableNewlines}  
                                                            onFocus={highlightAll}
                                                            onBlur={handleBlur}
                                                            tagName='div' // Use a custom HTML tag (uses a div by default)
                                                        />
                                                    
                                                    }

                                                </div>
                                            </div>)
                                        // } 
                                    })
                                }
                                </div>

                            
                            <div style={{textAlign:'center'}}>
                                <Button iconPosition="before" style={{marginRight:'10px'}} 
                                    onClick={onClickAddBeneftoListEntrega}
                                    icon={<SaveIcon size={16} />}
                                    dissabled={newAddBeneficiario!==null ? false : true}
                                >
                                    Agregar
                                </Button>
                                <Button iconPosition="before" 
                                    onClick={onClickAceptarAddBenef}
                                    icon={<CheckIcon size={16} />}
                                >
                                    Aceptar
                                </Button>
                            </div>
                        </div>
                    :
                    !isListEntrega && listEntrega && listEntrega.length>=0 && 
                        <TableBeneficiariosEntregaSortColumns listEntrega={listEntrega} token={users.user.claveAPI} udispatch={dispatch} />
                    }
                    </TabSection>
                    </TabContents>
                </Tabs>

            </Fragment>
        );
    }
    return (<></>)
}
  
export default ModuleCovid19Ayudas;