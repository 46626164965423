// // Copyright 2020 Ernesto Girón E.
// // Licensed under the Apache License, Version 2.0 (the "License");
// // you may not use this file except in compliance with the License.
// // You may obtain a copy of the License at
// //     http://www.apache.org/licenses/LICENSE-2.0
// // Unless required by applicable law or agreed to in writing, software
// // distributed under the License is distributed on an "AS IS" BASIS,
// // WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// // See the License for the specific language governing permissions and
// // limitations under the License.​

import API from '../utils/ecaseAPI';

/* 
 * Make a request for list of loans
 * @return Array of Tabla de Lista de Prestamos
 */
export async function getListPrestamos(action) {
    let listPrestamos = null;
      try {
        await API('/prestamos/', { 
            method: 'POST',
            data: JSON.stringify({"api":action.token})
        }).then(response => response.data).then((data) => {
            listPrestamos = data.datos
        }).catch(function (error) {
            // handle error
            console.log(`😱 problemas obteniendo los datos de lista de prestamos del GRB.`);
            console.log(error);
        })
  
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
      return listPrestamos;
}

/* 
 * Make a request for list of loans
 * @return Array of Tabla de Lista de Prestamos
 */
export async function getListPrestamosbyQuery(action) {
    let resultQueryPrestamos = null;
    try {
        await API('/prestamos/query', { 
            method: 'POST',
            data: JSON.stringify({"api":action.token, "query":action.query})
        }).then(response => response.data).then((data) => {
            // console.log(data);
            if (data)
                resultQueryPrestamos = data
        }).catch(function (error) {
            // handle error
            console.log(`😱 problemas obteniendo los datos de lista de prestamos del GRB.`);
            console.log(error);
        })
  
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
    return resultQueryPrestamos;
}

/* 
 * Registra nuevos datos de lista de prestamos 
 * @return 
 */
export async function crearPrestamo(action) {
    let resp = null;
    try {
        await API('/prestamos/insert', {
            method: 'POST',
            data: JSON.stringify({"api":action.token, "prestamos":action.prestamos})
        }).then(response => response.data).then((data) => {
            resp = data.mensaje;
        }).catch((error) => {
            resp = '😱 Error ('+error.response.status+'): Problema registrando los datos de prestamos en el servidor. ' + error.response.data.mensaje;
            console.log(`😱 problemas registrando la tabla de prestamos en GRB.`, error.response.data.mensaje);
            return resp;
        })
  
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
    return resp;
}
/* 
 * Actualiza los datos de lista de prestamos 
 * @return 
 */
export async function actualizarPrestamo(action) {
    let resp = null;
    try {
        await API('/prestamos/update', {
            method: 'POST',
            data: JSON.stringify({"api":action.token, "prestamos":action.prestamos})
        }).then(response => response.data).then((data) => {
            resp = data.mensaje;
        }).catch((error) => {
            resp = '😱 Error ('+error.response.status+'): Problema guardando los datos de prestamos en el servidor. ' + error.response.data.mensaje;
            console.log(`😱 problemas actualizando la tabla de prestamos en GRB.`, error.response.data.mensaje);
            return resp;
        })
        
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
    return resp;
}
/* 
 * Elimina datos de lista de prestamos 
 * @return 
 */
export async function eliminarPrestamo(action) {
    let resp = null;
    try {
        await API('/prestamos/delete', {
            method: 'POST',
            data: JSON.stringify({"api":action.token, "prestamos":action.prestamos})
        }).then(response => response.data).then((data) => {
            resp = data.mensaje;
        }).catch((error) => {
            resp = '😱 Error ('+error.response.status+'): Problema eliminando los datos de prestamos en el servidor. ' + error.response.data.mensaje;
            console.log(`😱 problemas eliminando la tabla de prestamos en GRB.`, error.response.data.mensaje);
            return resp;
        })
  
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
    return resp;
}

// HISTORICO
/* 
 * Make a request for list of loans historic
 * @return Array of Table de Histórico de Prestamos
 */
export async function getListHistoPrestamosbyQuery(action) {
    let resultQueryHistoPrestamos = null;
    try {
        await API('/prestamoshistorico/query', { 
            method: 'POST',
            data: JSON.stringify({"api":action.token, "query":action.query})
        }).then(response => response.data).then((data) => {
            // console.log(data);
            if (data)
                resultQueryHistoPrestamos = data
        }).catch(function (error) {
            // handle error
            console.log(`😱 problemas obteniendo los datos del histórico de prestamos del GRB.`);
            console.log(error);
        })
  
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
    return resultQueryHistoPrestamos;
}

/* 
 * Registra nuevos datos de lista histórico de prestamos 
 * @return 
 */
export async function crearHistoPrestamo(action) {
    let resp = null;
    try {
        await API('/prestamoshistorico/insert', {
            method: 'POST',
            data: JSON.stringify({"api":action.token, "prestamos":action.prestamos})
        }).then(response => response.data).then((data) => {
            resp = data.mensaje;
        }).catch((error) => {
            resp = '😱 Error ('+error.response.status+'): Problema registrando los datos de histórico de prestamos en el servidor. ' + error.response.data.mensaje;
            console.log(`😱 problemas registrando la tabla de histórico de prestamos en GRB.`, error.response.data.mensaje);
            return resp;
        })
  
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
    return resp;
}
/* 
 * Actualiza los datos de lista histórico de prestamos 
 * @return 
 */
export async function actualizarHistoPrestamo(action) {
    let resp = null;
    try {
        await API('/prestamoshistorico/update', {
            method: 'POST',
            data: JSON.stringify({"api":action.token, "prestamos":action.prestamos})
        }).then(response => response.data).then((data) => {
            resp = data.mensaje;
        }).catch((error) => {
            resp = '😱 Error ('+error.response.status+'): Problema guardando los datos de histórico de prestamos en el servidor. ' + error.response.data.mensaje;
            console.log(`😱 problemas actualizando la tabla de histórico de prestamos en GRB.`, error.response.data.mensaje);
            return resp;
        })
        
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
    return resp;
}
/* 
 * Elimina datos de lista histórico de prestamos 
 * @return 
 */
export async function eliminarHistoPrestamo(action) {
    let resp = null;
    try {
        await API('/prestamoshistorico/delete', {
            method: 'POST',
            data: JSON.stringify({"api":action.token, "prestamos":action.prestamos})
        }).then(response => response.data).then((data) => {
            resp = data.mensaje;
        }).catch((error) => {
            resp = '😱 Error ('+error.response.status+'): Problema eliminando los datos de histórico de prestamos en el servidor. ' + error.response.data.mensaje;
            console.log(`😱 problemas eliminando la tabla de histórico de prestamos en GRB.`, error.response.data.mensaje);
            return resp;
        })
  
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
    return resp;
}