import React, {useState, useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateBeneficiarioHabilidades, 
    insertBeneficiarioHabilidades,
    deleteBeneficiarioHabilidades,
    serverMensaje } from '../../redux/reducers/beneficiarios';
import {highlightAll, disableNewlines, pastePlainText } from '../../utils/utils';
import {showToaster} from './BenefShowMesg';
import ContentEditable from 'react-contenteditable';
import Alert, {AlertMessage} from 'calcite-react/Alert';
import Table, {
    TableHeader,
    TableHeaderRow,
    TableHeaderCell,
    TableBody,
    TableRow,
    TableCell
} from 'calcite-react/Table';
import Select from 'calcite-react/Select';
import {MenuItem} from 'calcite-react/Menu';
import Button from 'calcite-react/Button';

import SaveIcon from 'calcite-ui-icons-react/SaveIcon';
import TrashIcon from 'calcite-ui-icons-react/TrashIcon';
import UserPlusIcon from 'calcite-ui-icons-react/UserPlusIcon';

import tipoParentescos from '../../data/parentesco.json';

const BenefHabilidades = ({habilidades, beneficiarioId}) => {
    const dispatch = useDispatch();
    const mensaje = useSelector(state => state.beneficiarios.mensaje);
    const users = useSelector(state => state.users);
    // const isEditable = users.user.tipo==='ADMINISTRADOR' ? true : false;
    // const isEditable = (users.user.tipo==='ADMINISTRADOR' && users.user.role==='A' && users.user.permisos==='A') ? true : false;
    const isEditable = (users.user.areatrabajo==='ADMINISTRATIVA' || users.user.areatrabajo==='TRABAJO SOCIAL' || users.user.areatrabajo==='COORDINACIÓN' || users.user.areatrabajo==='PSICOLOGÍA' ) ? true : false;
    // const canView = (users.user.areatrabajo==='ADMINISTRATIVA' || users.user.areatrabajo==='TECNOLOGÍA' ) ? true : false;
    
    const [isBtnAddEnable, setIsBtnAddEnable] = useState(false);
    const [error, setError] = useState(null);
    const [H, updateH] = useState([...habilidades]); //Object.assign({}, habilidades); // Arreglos no Objects

    useEffect(() => {

        if (mensaje.habilidades && mensaje.habilidades!=='') {
            const insertHabilidades = (id, f, v) => {
                if (H){
                    updateH(H.map((itm, index) => {
                        if (itm.id===id){
                            itm[f] = v;
                        }
                        return itm;
                    }));
                    mensaje.habilidades='';
                    // dispatch(serverMensaje({habilidades:''}));
                }
            };

            if (typeof mensaje.habilidades === 'string' && mensaje.habilidades.toLowerCase().includes('error')){
                showToaster('error', 'Ocurrió un fallo en el servidor', mensaje.habilidades);
            } 
            else {
                showToaster('success',"Educacion en el Hogar del Beneficiario", mensaje.habilidades.split('.')[0]);
                // Update Id for edition
                // Cambiar el id por el que devuelve el servidor o transacción de MySQL
                insertHabilidades('-1', 'id', String(mensaje.habilidades.split('.')[1]));
            }
        }

    }, [mensaje.habilidades, updateH, H, dispatch]);
    
    const updateHabilidades = (id, f, v) => {
        if (H){
            updateH(H.map((itm, index) => {
                if (itm.id===id){
                    itm[f] = v;
                }
                return itm;
            }));
        }
    };

    const updateDataonServer = (id, action, newItem) =>{
        dispatch(serverMensaje({habilidades:''}));
        switch (action) {
            case 'insert':
                if (parseInt(id)< 0 && newItem){
                    // console.log('insertando nuevo registro: ', newItem);
                    setIsBtnAddEnable(true);
                }
                break;
            case 'save':
                if (id){
                    const reg = H.filter(itm => itm.id===id);
                    if (reg && reg.length>0){
                        dispatch(insertBeneficiarioHabilidades( { token: users.user.claveAPI, habilidades:reg[0]}));
                        setIsBtnAddEnable(false);
                    }
                }
                break;
            case 'remove':
                if (id){
                    const reg = H.filter(itm => itm.id===id);
                    if (reg && reg.length>0){
                        dispatch(deleteBeneficiarioHabilidades( { token: users.user.claveAPI, habilidades:reg[0]}));
                    }
                }
                break;
            default: // update
                // Actualizar solo el registro necesario, para no enviar toda la tabla
                if (id && parseInt(id) > 0){
                    const reg = H.filter(itm => itm.id===id);
                    if (reg && reg.length>0){
                        dispatch(updateBeneficiarioHabilidades( { token: users.user.claveAPI, habilidades:reg[0]}));
                    }
                }
                break;
        }
    }

    const replaceSomeText = (s) =>{
        return s
            .replace(/&nbsp;/g, ' ')
            .replace(/<br>/g, '')
            .replace(/&amp;/g, '&')
            .replace(/&gt;/g, '>')
            .replace(/&lt;/g, '<').trim()
    }

    const validateInput = (f, v, final) => {
        // const regex_onlynum = /^[0-9]*$/i; ///[0-9,]|\./;
        // const regex_nombres = /^['`´a-zA-ZÀ-ÿ\u00C0-\u017F\s]+$/;
        let new_val = null;
        let err = null;
        switch (f) {
            case 'nombre':
                new_val = v.substr(0,120);
                if (final)
                    new_val = replaceSomeText(v).substr(0,120);
                break;
            case 'parentesco':
                new_val = v.substr(0,32);
                if (final)
                    new_val = replaceSomeText(v).substr(0,32);
                break;
            case 'habilidad':
                new_val = v.substr(0,255);
                if (final)
                    new_val = replaceSomeText(v).substr(0,255);
                break;
            // case 'anio':
            //     new_val = replaceSomeText(v);
            //     if (!regex_onlynum.test(new_val)) {
            //         err = 'Dígite solo números válidos';
            //         new_val = null;
            //     }
            //     if (new_val===null) new_val = '';
            //     break;
            // case 'comentarios':
            //     new_val = replaceSomeText(v); //.substr(0,255);
            //     break;
            default:
                break;
        }
        setError(err);
        return new_val
    }

    const handleParentescoSelectChange = (value, id) => {
        // H['parentesco'] = value;
        updateHabilidades(id,'parentesco', value );
        updateDataonServer(id);
    };
    
    const handleChange = evt => {
        const [, field, id] = evt.currentTarget.id.split('_');
        const new_val = validateInput(field, evt.target.value, false);
        if(new_val){
            updateHabilidades(id,field, new_val );
            // console.log("tabla, field, id", tabla, field, id, new_val);
        };

    };
    const handleBlur = evt => {
        const val = evt.currentTarget.innerHTML;
        const [,field, id] = evt.target.id.split('_');
        const new_val = validateInput(field, val, true)
        if(new_val || new_val===''){
            // console.log("tabla, field, id", tabla, field, id, val);
            updateHabilidades(id,field, new_val );
            updateDataonServer(id);
        };
    };

    const onClickAddHabilidades = () => {
        const newId = -1;
        const nueva_habilidad = {
            "id": String(newId),
            "beneficiarioId": beneficiarioId,
            "nombre": "",
            "parentesco": "",
            "habilidad": "",
            // "version": "2020-06-03 10:03:03"
        }
        updateH([...H].concat(nueva_habilidad) );
        updateDataonServer(newId, 'insert', nueva_habilidad);
    }
    const onClickSaveHabilidades = (id) => {
        updateDataonServer(id, 'save');
    }
    const onClickRemoveHabilidades = (id) => {
        updateH([...H].filter(itm => itm.id!==id));
        updateDataonServer(id, 'remove');
    }

    return (
        <div>
            { error && <div style={{color: 'red', width: '100%'}}>
                <Alert red showIcon style={{minWidth: '100%', marginBottom: '10px'}}
                    showCloseLabel 
                    onClose={() => setError(null)}>
                    <AlertMessage>
                        Error:{' '}
                        <strong>{error}</strong>
                    </AlertMessage>
                </Alert>
                </div>
            }
        <div className="itemContainer-table-habilidades">
        <Table striped>
            <TableHeader>
                <TableHeaderRow>
                    <TableHeaderCell style={{textAlign:'center'}}>Nombre</TableHeaderCell>
                    <TableHeaderCell style={{textAlign:'center'}}>Parentesco</TableHeaderCell>
                    <TableHeaderCell style={{textAlign:'center'}}>Qué sabe hacer?</TableHeaderCell>
                    <TableHeaderCell style={{textAlign:'center'}}>Acción</TableHeaderCell>
                </TableHeaderRow>
            </TableHeader>
            <TableBody>
                {
                    H.map((hh, index) => (
                        <TableRow key={hh.id}>
                            <TableCell style={{minWidth:'80px'}}>
                                <ContentEditable
                                    id={'habilidades_nombre_'+hh.id}
                                    html={hh.nombre || ''} // innerHTML of the editable div
                                    className='itemContainer-value-table'
                                    disabled={!isEditable}      // use true to disable editing
                                    onPaste={pastePlainText} // En caso se copie y pegue de otra pagina de internet
                                    onChange={handleChange} // handle innerHTML change
                                    onKeyPress={disableNewlines}  
                                    onFocus={highlightAll}
                                    onBlur={handleBlur}
                                    tagName='div' // Use a custom HTML tag (uses a div by default)
                                />
                            </TableCell>
                            <TableCell style={{maxWidth:'120px'}}>
                                <Select filterable positionFixed disabled={!isEditable} 
                                    id={'habilidades_parentesco_'+hh.id}
                                    className={'itemContainer-table-selects'}
                                    onChange={(value, item) => handleParentescoSelectChange(value, hh.id)}
                                    selectedValue={hh.parentesco.trim()}
                                >
                                {tipoParentescos.tipo.map(p => {
                                    return (
                                    <MenuItem key={p.id} value={p.name}>
                                        {p.name}
                                    </MenuItem>
                                    )
                                })}
                                </Select>
                            </TableCell>
                            <TableCell style={{minWidth:'40px'}}>
                                <ContentEditable
                                    id={'habilidades_habilidad_'+hh.id}
                                    html={hh.habilidad || ''} // innerHTML of the editable div
                                    className='itemContainer-value-table'
                                    disabled={!isEditable}      // use true to disable editing
                                    onPaste={pastePlainText} // En caso se copie y pegue de otra pagina de internet
                                    onChange={handleChange} // handle innerHTML change
                                    onKeyPress={disableNewlines}  
                                    onFocus={highlightAll}
                                    onBlur={handleBlur}
                                    tagName='div' // Use a custom HTML tag (uses a div by default)
                                />
                            </TableCell>
                            <TableCell style={{maxWidth:'140px'}}>
                                {
                                    (parseInt(hh.id)< 0)
                                    ?
                                    <Button extraSmall clear iconPosition="before"
                                        disabled={!isEditable} 
                                        id={'habilidades_guardar_'+hh.id}
                                        // style={{margin: '0 0 0.5rem 0'}}
                                        onClick={(evt) => onClickSaveHabilidades(hh.id)}
                                        icon={<SaveIcon size={16} />}
                                    >
                                        Guardar
                                    </Button>
                                    :
                                    <Button extraSmall clear iconPosition="before"
                                        disabled={!isEditable} 
                                        id={'habilidades_eliminar_'+hh.id}
                                        onClick={(evt) => onClickRemoveHabilidades(hh.id)}
                                        icon={<TrashIcon size={16} />}
                                    >
                                        Eliminar
                                    </Button>
                                }
                            </TableCell>
                        </TableRow>
                    ))
                }
            </TableBody>
        </Table>
        </div>

            <div className="">
                <Button iconPosition="before" 
                    disabled={!isEditable || isBtnAddEnable} 
                    id={'habilidades_agregar'}
                    onClick={onClickAddHabilidades}
                    icon={<UserPlusIcon size={16} />}
                >
                    Agregar nuevo familiar
                </Button>
            </div>

        </div> 
    );
}
   
export default BenefHabilidades;

