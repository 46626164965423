// React
import React, {useState} from "react";
// Redux imports
import { useSelector, useDispatch } from "react-redux";
import { setProyecto } from "../../../redux/reducers/proyectos";

// import BooleanIcon from 'calcite-ui-icons-react/BooleanIcon';

import ActionBar, {
  Action,
  ActionGroup,
  // BottomActionGroup
} from 'calcite-react/ActionBar';

// Styled Components
import styled from "styled-components";

const ActionBarLeft = styled.div`
  // float:left;
  // display: inline-flex;
  // position: relative;
  // width: auto;
  // height: 100%;
  // text-align: center;
  // z-index: 2;
  // left: 0px;
  overflow: hidden;
  // background-color: #4c4c4c;
  // color: #fff;
`;

const ItemMenuOptsIcon = styled.div`
  width: 16px;
  height: 16px;
  background: transpararent;
  font-size:0.95rem;
  font-weight:700;
  text-align: center;
`;

const MenuLeftDeporteRecreCultura = props => {
    // const isCollapsed = useSelector(state => state.proyectos.collapsed);
    const [isCollapsed, setIsCollapsed] = useState(false);
    const activeAction = useSelector(state => state.proyectos.activeProyecto);
    const dispatch = useDispatch();

    const proyectosNames = {
      "":'Caracterización Social y Económica',
      "PASEO_AREA_GOLF": "Paseo área golf",
      "PASEO_AREA_TENIS": "Paseo área tenis",
      "ALMUERZO_AREA_GOLF": "Almuerzo área golf",
      "ALMUERZO_AREA_TENIS": "Almuerzo área tenis",
      "ACTIVIDAD_HIJOS_BENEFICIARIOS": "Actividad hijos beneficiarios",
      "INTEGRACION_DE_AREAS": "Integración de áreas",
      "CONFIGURACION": "Configuración del GRB", 

    };

    // useEffect(() => {
    //   dispatch(moduleLEFTPANELLoaded({activeModule:activeAction, headerTitle:proyectosNames[activeAction]}));
    // }, [dispatch]);

    const onToggleCollapse = (e) => {
      setIsCollapsed(!isCollapsed);
    };

    const turnOffModules = () => {
      // dispatch(moduleUSERSLoaded({loaded:false})); // Hide Users
      // dispatch(moduleBENEFICIARIOSLoaded({loaded:false}));
    }

    const actionSelected = (activeAction) => {
      dispatch(setProyecto({activeProyecto:activeAction, collapsed:isCollapsed, headerTitle:proyectosNames[activeAction]}));
      turnOffModules();
      switch (activeAction) {
        case 'PASEO_AREA_GOLF':
          console.log('PASEO_AREA_GOLF');
          break;
        case 'PASEO_AREA_TENIS':
          console.log('PASEO_AREA_TENIS');
          break;
        case 'ALMUERZO_AREA_GOLF':
          console.log('ALMUERZO_AREA_GOLF');
          break;
        case 'ALMUERZO_AREA_TENIS':
          console.log('ALMUERZO_AREA_TENIS');
          break;
        case 'ACTIVIDAD_HIJOS_BENEFICIARIOS':
          console.log('ACTIVIDAD_HIJOS_BENEFICIARIOS');
          break;
        case 'INTEGRACION_DE_AREAS':
          console.log('INTEGRACION_DE_AREAS');
          break;
        default:
          break;
      }
    };

    

    return (
      <ActionBarLeft>
      <ActionBar 
        // showCollapser={false}
        collapseLabel='Ocultar' 
        expandLabel='Expandir' 
        collapsed={isCollapsed}
        onToggleCollapse={onToggleCollapse}
      >
        <ActionGroup>
          <Action
            onClick={() => actionSelected('PASEO_AREA_GOLF')}
            active={activeAction === 'PASEO_AREA_GOLF'}
            icon={<ItemMenuOptsIcon>PG</ItemMenuOptsIcon>}
          >
            Paseo área golf
          </Action>
          <Action 
              onClick={() => actionSelected('PASEO_AREA_TENIS')}
              active={activeAction === 'PASEO_AREA_TENIS'}
              icon={<ItemMenuOptsIcon>PT</ItemMenuOptsIcon>}
            >
              Paseo área tenis
          </Action>
          </ActionGroup>
          <ActionGroup>
          <Action
            onClick={() => actionSelected('ALMUERZO_AREA_GOLF')}
            active={activeAction === 'ALMUERZO_AREA_GOLF'}
            icon={<ItemMenuOptsIcon>AG</ItemMenuOptsIcon>}
            >
              Almuerzo área golf
          </Action>
          <Action
            onClick={() => actionSelected('ALMUERZO_AREA_TENIS')}
            active={activeAction === 'ALMUERZO_AREA_TENIS'}
            icon={<ItemMenuOptsIcon>AT</ItemMenuOptsIcon>}
          >
            Almuerzo área tenis
          </Action>
          </ActionGroup>
          <ActionGroup>
          <Action
            onClick={() => actionSelected('INTEGRACION_DE_AREAS')}
            active={activeAction === 'INTEGRACION_DE_AREAS'}
            icon={<ItemMenuOptsIcon>IA</ItemMenuOptsIcon>}
          >
            Integración de áreas
          </Action>
          <Action
            onClick={() => actionSelected('ACTIVIDAD_HIJOS_BENEFICIARIOS')}
            active={activeAction === 'ACTIVIDAD_HIJOS_BENEFICIARIOS'}
            icon={<ItemMenuOptsIcon>AH</ItemMenuOptsIcon>}
            >
              Actividad hijos beneficiarios
          </Action>
        </ActionGroup>
        {/* <BottomActionGroup>
        <Action icon={<GearIcon />}>Configuración</Action>
      </BottomActionGroup> */}
      </ActionBar>
      </ActionBarLeft>
    )

}

export default MenuLeftDeporteRecreCultura;