import React, {useState, useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectBeneficiarioForPerfilxCumpli } from '../../redux/reducers/cumpli';

// import BenefCovidAyudas from '../beneficiario/BenefCovidAyudas';
import BenefHeader from '../beneficiario/BenefHeader';
// import BenefCitas from '../beneficiario/BenefCitas';
import BenefCumplimiento from './BenefCumplimiento';
// import BenefPerfilBeneficiario from '../beneficiario/BenefPerfilBeneficiario';
// import BenefLocalizacion from '../beneficiario/BenefLocalizacion';
// import BenefEncuestado from '../beneficiario/BenefEncuestado';
// import BenefVivienda from '../beneficiario/BenefVivienda';
// import BenefInfoEconomica from '../beneficiario/BenefInfoEconomica';
// import BenefSalud from '../beneficiario/BenefSalud';
// import BenefEducacion from '../beneficiario/BenefEducacion';
// import BenefRecreacion from '../beneficiario/BenefRecreacion';
// import BenefOrgSocial from '../beneficiario/BenefOrgSocial';
// import BenefIntegrantesHogar from '../beneficiario/BenefIntegrantesHogar';
// import BenefGenograma from '../beneficiario/BenefGenograma';
// import BenefFotos from '../beneficiario/BenefFotos';
// import BenefAudios from '../beneficiario/BenefAudios';
// import BenefVideos from '../beneficiario/BenefVideos';
// import BenefObservaciones from '../beneficiario/BenefObservaciones';

import styled from "styled-components";

import closecircleIcon from '../../assets/icons/closecircle_icon.png';


const BeneficiarioDetailsContainer = styled.div`
    // width: calc(100% - 45px); // Cuando hay actionbar
    width: 100%;
    height: 100vh;
    background: #fff;
    // padding-right:45px; // Cuando hay actionbar
    padding-right:5px;
`;

const CardBenefDetailsContainer = styled.div`
    // position: relative;
    // display: flex;
    // border: 1px solid #ccc;
    // padding: 0;
    // margin: 5px;
    // height: 100%;
    // flex-wrap: wrap;
    // width: auto;
`;

const CardBenefContainerClose = styled.div`
    position: absolute;
    width: 24px;
    height: 24px;
    // right: 60px; // Cuando hay actionbar
    right: 5px;
    top: 5px;
    font-size: 24px;
    cursor: pointer;
    z-index: 101;
    background: rgba(255, 255, 255, 0.1) url(${closecircleIcon}) no-repeat center/cover;
    opacity: 0.4;

    &:hover {
        opacity: 1;
    }
`;

const BeneficiarioDetails = props => {
    const config = useSelector(state => state.config);
    const datosSelBenef = useSelector(state => state.cumpli.datosSelBenef);
    const selectedProfile = useSelector(state => state.cumpli.selectedProfile);
    const [Beneficiario,setBeneficiario] = useState(null);
    const listBenef = useSelector(state => state.cumpli.listGolf);
    // const listStatsCaddie = useSelector(state => state.cumpli.listStatsCaddie);
    const dispatch = useDispatch();

    useEffect(() => {
        const getBeneficiarioByDocId = (docId) => {
            return listBenef.filter((benef, i) => benef.docId === docId);
        };

        if (selectedProfile && selectedProfile.docId!==''){
            setBeneficiario(getBeneficiarioByDocId(selectedProfile.docId)[0]);
        }
    }, [selectedProfile, listBenef, dispatch]);

    const onCloseBenefDetailsWin = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setBeneficiario(null);
        dispatch(selectBeneficiarioForPerfilxCumpli({selectedProfile:null}));
    }

    if (Beneficiario){
        // let beneficiarios_raw = null; 
        let cumplimiento = null;
        // let perfilbeneficiario = null;
        // let predio = null;
        // let encuesta_observaciones = null;

        if (datosSelBenef){
            if (datosSelBenef.length<=0) return (
                <></>
            );
            // console.log("datosSelBenef==>", datosSelBenef);
            // 0: "beneficiarios" -> Estos ya se tienen de antemano.
            // if ( datosSelBenef['beneficiarios'] && datosSelBenef['beneficiarios'].length>0){
            //     beneficiarios_raw = datosSelBenef['beneficiarios'][0];
            // }
            // 1: "predio"
            // if ( datosSelBenef['predio'] && datosSelBenef['predio'].length>0){
            //     predio = datosSelBenef['predio'][0];
            // }
            // // 2: "perfilbeneficiario"
            // if ( datosSelBenef['perfilbeneficiario'] && datosSelBenef['perfilbeneficiario'].length>0){
            //     perfilbeneficiario = datosSelBenef['perfilbeneficiario'][0];
            // }
           
            // // 12: "encuesta_observaciones"
            // if ( datosSelBenef['encuesta_observaciones'] && datosSelBenef['encuesta_observaciones'].length>0){
            //     encuesta_observaciones = datosSelBenef['encuesta_observaciones'][0];
            // }
            
            // grb_historicoreservas_golf
            if ( datosSelBenef['grb_historicoreservas_golf'] && datosSelBenef['grb_historicoreservas_golf'].length>0){
                cumplimiento = datosSelBenef['grb_historicoreservas_golf'];
            }

        return (
            <BeneficiarioDetailsContainer>
                <CardBenefContainerClose onClick={onCloseBenefDetailsWin} title="Cerrar Detalle Beneficiario"/>
                <CardBenefDetailsContainer>
                    <div className="cardBenefDetailsContainer-header-first">
                        <BenefHeader Beneficiario={Beneficiario} ECASE_URL={config.ECASE_URL}/>
                        <BenefCumplimiento cumplimiento={cumplimiento} docId={Beneficiario.docId}/>
                    </div>
                    {/* {covidayudas && <BenefCovidAyudas covidayudas={covidayudas} />} */}
                    {/* {citas && <BenefCitas citas={citas}/>}
                    {perfilbeneficiario && <BenefPerfilBeneficiario perfilbeneficiario={perfilbeneficiario} basicos={Beneficiario} /> }
                    {integranteshogar && <BenefIntegrantesHogar integranteshogar={integranteshogar} beneficiarioId={Beneficiario.docId}/>}
                    {predio && <BenefLocalizacion predio={predio} />}
                    {encuestado && <BenefEncuestado encuestado={encuestado} />}
                    {vivienda && <BenefVivienda vivienda={vivienda} />}
                    {infoeconomica && <BenefInfoEconomica infoeconomica={infoeconomica} />}
                    {salud && <BenefSalud salud={salud} />}
                    {educacion && <BenefEducacion educacion={educacion} />}
                    {recreacion && <BenefRecreacion recreacion={recreacion} />}
                    {orgsocial && <BenefOrgSocial orgsocial={orgsocial} />}
                    {genograma && <BenefGenograma genograma={config.ECASE_URL+genograma} />}
                    {fotos && <BenefFotos fotos={fotos} />}
                    {audios && <BenefAudios audios={audios} />}
                    {videos && <BenefVideos videos={videos} />}
                    {encuesta_observaciones && <BenefObservaciones obs={encuesta_observaciones} />} */}
                
                </CardBenefDetailsContainer>
            </BeneficiarioDetailsContainer>
            );

        } else { 
            return (
                <></>
            );
        }
    }
    return (
        <></>
    );
}

export default BeneficiarioDetails;

