// Copyright 2020 Ernesto Giron E.
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//     http://www.apache.org/licenses/LICENSE-2.0
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.​

// React imports
import React from "react";
import { Redirect} from "react-router-dom";
// Redux imports
import { useSelector } from "react-redux";

import background from "../assets/logo-solo.png";

// Component imports
import Loader from 'calcite-react/Loader';
import HeaderGRB from './ui_header';
import FooterGRB from './ui_footer';
import SubNav from './caddiemaster/SubNav';
import StarterPanelLeft from './caddiemaster/PanelLeft';
import ModuleReservas from './caddiemaster/ModuleReservas';
// import ModuleAsignacion from './caddiemaster/ModuleAsignacion';
import ModuleValidacion from './caddiemaster/ModuleValidacion';
import ModuleBeneficiarios from './caddiemaster/ModuleBeneficiarios';
// import ModuleReportes from './caddiemaster/ModuleReportes';
import ModuleTarifasCaddie from './caddiemaster/ModuleTarifasCaddie';

// Styled Components
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  overflow: hidden;
`;

const GRBWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 0;
  overflow: hidden;
  background-color: #fff;
  text-align: left;
`;

const GRBPanelLeft = styled.div`
  float:left;
  display: inline-flex;
  position: relative;
  width: auto;
  height: 100%;
  text-align: center;
  z-index: 2;
  left: 0px;
  // overflow: hidden auto;
  // background-color: #4c4c4c;
  color: #fff;
`;

const GRBPanelCentral = styled.div`
  display: block;
  position: relative;
  height: 100%;
  text-align: center;
  z-index: 0;
  background-color: #f8f8f8;
  left: auto;
  overflow: hidden auto;
  // padding-right: 55px;
`;

const GRBPanelCentralBground = styled.div`
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  z-index: -1;
  left: auto;
  background: rgba(255, 255, 255, 0.1) url(${background}) no-repeat center;
  opacity:0.2;  
`;

// Component definition
const MainCaddieMaster = props => {
  // redux store state
  const loggedIn = useSelector(state => state.auth.loggedIn);
  // const activeProgram = useSelector(state => state.programas.activeProgram);
  // const activeModule = 'CADDIEMASTER'; //useSelector(state => state.modulos.activeModule);
  const activeProyecto = useSelector(state => state.proyectos.activeProyecto);

  const isCaddiesLoaded = useSelector(state => state.cumpli.benefloaded);
  // const isReservasLoaded = useSelector(state => state.reservas.loaded);
  
  if (loggedIn) {
    return (
      <Container>
        <HeaderGRB/>
        <SubNav/>
        <GRBWrapper>
          <GRBPanelLeft>
            <StarterPanelLeft/>
          </GRBPanelLeft>
          <GRBPanelCentral>
            <GRBPanelCentralBground/>

            {activeProyecto==='RESERVAS' && <ModuleReservas/>}
            {/* {activeProyecto==='ASIGNACION' && <ModuleAsignacion/>} */}
            {activeProyecto==='VALIDACION' && <ModuleValidacion/>}

            {!isCaddiesLoaded && activeProyecto==='CADDIES' && <Loader color="#009937" text="Cargando Beneficiarios..."/>}
            {activeProyecto==='CADDIES' && <ModuleBeneficiarios/>}
            {/* {activeProyecto==='REPORTES' && <ModuleReportes/>} */}
            {activeProyecto==='TARIFASCADDIES' && <ModuleTarifasCaddie/>}

          </GRBPanelCentral>
        </GRBWrapper>
        <FooterGRB/>
      </Container>


    );
  } else {
    return (
      <>
          <Redirect to="/" />
      </>
      );
  }
};


export default MainCaddieMaster;
