import React, {useState, useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateBeneficiarioAportantes, 
    insertBeneficiarioAportantes,
    deleteBeneficiarioAportantes,
    serverMensaje } from '../../redux/reducers/beneficiarios';
import {highlightAll, disableNewlines, pastePlainText } from '../../utils/utils';
import {showToaster} from './BenefShowMesg';
import ContentEditable from 'react-contenteditable';
import Alert, {AlertMessage} from 'calcite-react/Alert';
import Table, {
    TableHeader,
    TableHeaderRow,
    TableHeaderCell,
    TableBody,
    TableRow,
    TableCell
} from 'calcite-react/Table';
import Select from 'calcite-react/Select';
import {MenuItem} from 'calcite-react/Menu';
import Button from 'calcite-react/Button';

import SaveIcon from 'calcite-ui-icons-react/SaveIcon';
import TrashIcon from 'calcite-ui-icons-react/TrashIcon';
import UserPlusIcon from 'calcite-ui-icons-react/UserPlusIcon';

import tipoParentescos from '../../data/parentesco.json';

const BenefAportantes = ({aportantes, beneficiarioId}) => {
    const dispatch = useDispatch();
    const mensaje = useSelector(state => state.beneficiarios.mensaje);
    const users = useSelector(state => state.users);
    // const isEditable = users.user.tipo==='ADMINISTRADOR' ? true : false;
    const isEditable = (users.user.areatrabajo==='ADMINISTRATIVA' || users.user.areatrabajo==='TRABAJO SOCIAL' || users.user.areatrabajo==='COORDINACIÓN' || users.user.areatrabajo==='PSICOLOGÍA' ) ? true : false;
    const [isBtnAddEnable, setIsBtnAddEnable] = useState(false);
    const [error, setError] = useState(null);
    const [A, updateA] = useState([...aportantes]); //Object.assign({}, aportantes); // Arreglos no Objects

    useEffect(() => {

        if (mensaje.aportantes && mensaje.aportantes!=='') {
            const insertAportantes = (id, f, v) => {
                if (A){
                    updateA(A.map((itm, index) => {
                        if (itm.id===id){
                            itm[f] = v;
                        }
                        return itm;
                    }));
                    mensaje.aportantes='';
                    // dispatch(serverMensaje({aportantes:''}));
                }
            };

            if (typeof mensaje.aportantes === 'string' && mensaje.aportantes.toLowerCase().includes('error')){
                showToaster('error', 'Ocurrió un fallo en el servidor', mensaje.aportantes);
            } 
            else {
                showToaster('success',"Aportantes del Beneficiario", mensaje.aportantes.split('.')[0]);
                // Update Id for edition
                // Cambiar el id por el que devuelve el servidor o transacción de MySQL
                insertAportantes('-1', 'id', String(mensaje.aportantes.split('.')[1]));
            }
        }

    }, [mensaje.aportantes, updateA, A, dispatch]);
    
    const updateAportantes = (id, f, v) => {
        if (A){
            updateA(A.map((itm, index) => {
                if (itm.id===id){
                    itm[f] = v;
                }
                return itm;
            }));
        }
    };

    const updateDataonServer = (id, action, newItem) =>{
        dispatch(serverMensaje({aportantes:''}));
        switch (action) {
            case 'insert':
                if (parseInt(id)< 0 && newItem){
                    // console.log('insertando nuevo registro: ', newItem);
                    setIsBtnAddEnable(true);
                }
                break;
            case 'save':
                if (id){
                    const reg = A.filter(itm => itm.id===id);
                    if (reg && reg.length>0){
                        dispatch(insertBeneficiarioAportantes( { token: users.user.claveAPI, aportantes:reg[0]}));
                        setIsBtnAddEnable(false);
                    }
                }
                break;
            case 'remove':
                if (id){
                    const reg = A.filter(itm => itm.id===id);
                    if (reg && reg.length>0){
                        dispatch(deleteBeneficiarioAportantes( { token: users.user.claveAPI, aportantes:reg[0]}));
                    }
                }
                break;
            default: // update
                // Actualizar solo el registro necesario, para no enviar toda la tabla
                if (id && parseInt(id) > 0){
                    const reg = A.filter(itm => itm.id===id);
                    if (reg && reg.length>0){
                        dispatch(updateBeneficiarioAportantes( { token: users.user.claveAPI, aportantes:reg[0]}));
                    }
                }
                break;
        }
    }

    const replaceSomeText = (s) =>{
        return s
            .replace(/&nbsp;/g, ' ')
            .replace(/<br>/g, '')
            .replace(/&amp;/g, '&')
            .replace(/&gt;/g, '>')
            .replace(/&lt;/g, '<').trim()
    }

    const validateInput = (f, v, final) => {
        const regex_onlynum = /^[0-9]*$/i; ///[0-9,]|\./;
        // const regex_nombres = /^['`´a-zA-ZÀ-ÿ\u00C0-\u017F\s]+$/;
        let new_val = null;
        let err = null;
        switch (f) {
            case 'nombre':
                new_val = v.substr(0,120);
                if (final)
                    new_val = replaceSomeText(v).substr(0,120);
                break;
            case 'parentesco':
                new_val = v.substr(0,32);
                if (final)
                    new_val = replaceSomeText(v).substr(0,32);
                break;
            case 'actividad':
                new_val = v.substr(0,120);
                if (final)
                    new_val = replaceSomeText(v).substr(0,120);
                break;
            case 'mesada':
                new_val = replaceSomeText(v);
                if (!regex_onlynum.test(new_val)) {
                    err = 'Dígite solo números válidos';
                    new_val = null;
                }
                if (new_val===null) new_val = '';
                break;
            // case 'comentarios':
            //     new_val = replaceSomeText(v); //.substr(0,255);
            //     break;
            default:
                break;
        }
        setError(err);
        return new_val
    }

    const handleParentescoSelectChange = (value, id) => {
        A['parentesco'] = value;
        updateAportantes(id,'parentesco', value );
        updateDataonServer(id);
    };

    // const handleFrecuenciaSelectChange = (value, id) => {
    //     A['actividad'] = value;
    //     updateAportantes(id,'actividad', value );
    //     updateDataonServer(id);
    // };

    const handleChange = evt => {
        const [, field, id] = evt.currentTarget.id.split('_');
        const new_val = validateInput(field, evt.target.value, false);
        if(new_val){
            updateAportantes(id,field, new_val );
            // console.log("tabla, field, id", tabla, field, id, new_val);
        };

    };
    const handleBlur = evt => {
        const val = evt.currentTarget.innerHTML;
        const [,field, id] = evt.target.id.split('_');
        const new_val = validateInput(field, val, true)
        if(new_val || new_val===''){
            // console.log("tabla, field, id", tabla, field, id, val);
            updateAportantes(id,field, new_val );
            updateDataonServer(id);
        };
    };

    const onClickAddAportantes = () => {
        const newId = -1;
        const nuevo_aportante = {
            "id": String(newId),
            "beneficiarioId": beneficiarioId,
            "nombre": "",
            "parentesco": "",
            "mesada": "0",
            "actividad": "",
            // "version": "2020-06-03 10:03:03"
        }
        updateA([...A].concat(nuevo_aportante) );
        updateDataonServer(newId, 'insert', nuevo_aportante);
    }
    const onClickSaveAportantes = (id) => {
        updateDataonServer(id, 'save');
    }
    const onClickRemoveAportantes = (id) => {
        updateA([...A].filter(itm => itm.id!==id));
        updateDataonServer(id, 'remove');
    }

    return (
        <div>
            { error && <div style={{color: 'red', width: '100%'}}>
                <Alert red showIcon style={{minWidth: '100%', marginBottom: '10px'}}
                    showCloseLabel 
                    onClose={() => setError(null)}>
                    <AlertMessage>
                        Error:{' '}
                        <strong>{error}</strong>
                    </AlertMessage>
                </Alert>
                </div>
            }
            <div className="itemContainer-table-aportantes">
            <Table striped>
                <TableHeader>
                    <TableHeaderRow>
                        <TableHeaderCell style={{textAlign:'center'}}>Parentesco</TableHeaderCell>
                        <TableHeaderCell style={{textAlign:'center'}}>Valor</TableHeaderCell>
                        <TableHeaderCell style={{textAlign:'center'}}>Actividad</TableHeaderCell>
                        <TableHeaderCell style={{textAlign:'center'}}>Acción</TableHeaderCell>
                    </TableHeaderRow>
                </TableHeader>
                <TableBody>
                    {
                        A.map((ah, index) => (
                            <TableRow key={ah.id}>
                                <TableCell style={{maxWidth:'120px'}}>
                                    <Select filterable positionFixed disabled={!isEditable} 
                                        id={'aportantes_parentesco_'+ah.id}
                                        className={'itemContainer-table-selects'}
                                        onChange={(value, item) => handleParentescoSelectChange(value, ah.id)}
                                        selectedValue={ah.parentesco.trim()}
                                    >
                                    {tipoParentescos.tipo.map(p => {
                                        return (
                                        <MenuItem key={p.id} value={p.name}>
                                            {p.name}
                                        </MenuItem>
                                        )
                                    })}
                                    </Select>
                                </TableCell>
                                <TableCell style={{minWidth:'80px'}}>
                                    <ContentEditable
                                        id={'aportantes_mesada_'+ah.id}
                                        html={ah.mesada || ''} // innerHTML of the editable div
                                        // style={{textAlign:'center'}}
                                        className='itemContainer-value-table'
                                        disabled={!isEditable}      // use true to disable editing
                                        onPaste={pastePlainText} // En caso se copie y pegue de otra pagina de internet
                                        onChange={handleChange} // handle innerHTML change
                                        onKeyPress={disableNewlines}  
                                        onFocus={highlightAll}
                                        onBlur={handleBlur}
                                        tagName='div' // Use a custom HTML tag (uses a div by default)
                                    />
                                </TableCell>
                                <TableCell style={{minWidth:'140px'}}>
                                    <ContentEditable
                                        id={'aportantes_actividad_'+ah.id}
                                        html={ah.actividad || ''} // innerHTML of the editable div
                                        // style={{textAlign:'center'}}
                                        className='itemContainer-value-table'
                                        disabled={!isEditable}      // use true to disable editing
                                        onPaste={pastePlainText} // En caso se copie y pegue de otra pagina de internet
                                        onChange={handleChange} // handle innerHTML change
                                        onKeyPress={disableNewlines}  
                                        onFocus={highlightAll}
                                        onBlur={handleBlur}
                                        tagName='div' // Use a custom HTML tag (uses a div by default)
                                    />
                                </TableCell>
                                <TableCell style={{maxWidth:'140px'}}>
                                    {
                                        (parseInt(ah.id)< 0)
                                        ?
                                        <Button extraSmall clear iconPosition="before"
                                            disabled={!isEditable} 
                                            id={'aportantes_guardar_'+ah.id}
                                            onClick={(evt) => onClickSaveAportantes(ah.id)}
                                            icon={<SaveIcon size={16} />}
                                        >
                                            Guardar
                                        </Button>
                                        :
                                        <Button extraSmall clear iconPosition="before"
                                            disabled={!isEditable} 
                                            id={'aportantes_eliminar_'+ah.id}
                                            onClick={(evt) => onClickRemoveAportantes(ah.id)}
                                            icon={<TrashIcon size={16} />}
                                        >
                                            Eliminar
                                        </Button>
                                    }
                                </TableCell>
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>
            </div>

            <div className="">
                <Button iconPosition="before" 
                    disabled={!isEditable || isBtnAddEnable} 
                    id={'aportantes_agregar'}
                    onClick={onClickAddAportantes}
                    icon={<UserPlusIcon size={16} />}
                >
                    Agregar nuevo aportante
                </Button>
            </div>

        </div> 
    );
}
   
export default BenefAportantes;

