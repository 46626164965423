// // Copyright 2020 Ernesto Girón E.
// // Licensed under the Apache License, Version 2.0 (the "License");
// // you may not use this file except in compliance with the License.
// // You may obtain a copy of the License at
// //     http://www.apache.org/licenses/LICENSE-2.0
// // Unless required by applicable law or agreed to in writing, software
// // distributed under the License is distributed on an "AS IS" BASIS,
// // WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// // See the License for the specific language governing permissions and
// // limitations under the License.​

import API from '../utils/ecaseAPI';

/* 
 * Make a request for list of terapias
 * @return Array of Tabla de Lista de Terapias
 */
export async function getListTerapias(action) {
    let listTerapias = null;
      try {
        await API('/terapias/', { 
            method: 'POST',
            data: JSON.stringify({"api":action.token})
        }).then(response => response.data).then((data) => {
            listTerapias = data.datos
        }).catch(function (error) {
            // handle error
            console.log(`😱 problemas obteniendo los datos de lista de terapias del GRB.`);
            console.log(error);
        })
  
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
      return listTerapias;
}

/* 
 * Make a request for list of terapias
 * @return Array of Tabla de Lista de Terapias
 */
export async function getListTerapiasbyQuery(action) {
    let resultQueryNotas = null;
    try {
        await API('/terapias/query', { 
            method: 'POST',
            data: JSON.stringify({"api":action.token, "query":action.query})
        }).then(response => response.data).then((data) => {
            // console.log(data);
            if (data)
                resultQueryNotas = data
        }).catch(function (error) {
            // handle error
            console.log(`😱 problemas obteniendo los datos de lista de terapias del GRB.`);
            console.log(error);
        })
  
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
    return resultQueryNotas;
}

/* 
 * Registra nuevos datos de lista de terapias 
 * @return 
 */
export async function crearTerapia(action) {
    let resp = null;
    try {
        await API('/terapias/insert', {
            method: 'POST',
            data: JSON.stringify({"api":action.token, "terapias":action.terapias})
        }).then(response => response.data).then((data) => {
            resp = data.mensaje;
        }).catch((error) => {
            resp = '😱 Error ('+error.response.status+'): Problema registrando los datos de terapias en el servidor. ' + error.response.data.mensaje;
            console.log(`😱 problemas registrando la tabla de terapias en GRB.`, error.response.data.mensaje);
            return resp;
        })
  
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
    return resp;
}
/* 
 * Actualiza los datos de lista de terapias 
 * @return 
 */
export async function actualizarTerapia(action) {
    let resp = null;
    try {
        await API('/terapias/update', {
            method: 'POST',
            data: JSON.stringify({"api":action.token, "terapias":action.terapias})
        }).then(response => response.data).then((data) => {
            resp = data.mensaje;
        }).catch((error) => {
            resp = '😱 Error ('+error.response.status+'): Problema guardando los datos de terapias en el servidor. ' + error.response.data.mensaje;
            console.log(`😱 problemas actualizando la tabla de terapias en GRB.`, error.response.data.mensaje);
            return resp;
        })
        
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
    return resp;
}
/* 
 * Elimina datos de lista de terapias 
 * @return 
 */
export async function eliminarTerapia(action) {
    let resp = null;
    try {
        await API('/terapias/delete', {
            method: 'POST',
            data: JSON.stringify({"api":action.token, "terapias":action.terapias})
        }).then(response => response.data).then((data) => {
            resp = data.mensaje;
        }).catch((error) => {
            resp = '😱 Error ('+error.response.status+'): Problema eliminando los datos de terapias en el servidor. ' + error.response.data.mensaje;
            console.log(`😱 problemas eliminando la tabla de terapias en GRB.`, error.response.data.mensaje);
            return resp;
        })
  
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
    return resp;
}