import React from "react";
import {capitalize, numberWithCommas, calculate_age, formatCelular} from '../../utils/utils';
import moment from 'moment';
import 'moment/locale/es-us';

import Label from 'calcite-react/Label';
import styled from "styled-components";

import caddieIcon from './imgs/caddie_icon_w.png';
import boleroIcon from './imgs/bolero_icon_w.png';
import boleadorIcon from './imgs/boleador_icon_w.png';
import monitorIcon from './imgs/monitor_icon_w.png';
import docIdIcon from '../../assets/icons/docId_Icon_24dp.png';
import cakeIcon from '../../assets/icons/cake_icon.png';
import calendarIcon from '../../assets/icons/calendar_icon.png';
import smartphoneIcon from '../../assets/icons/smartphone_icon.png';
import whatsappIcon from '../../assets/icons/whatsapp.png';
             
const DocIdIcon = styled.div`
  width: 20px;
  height: 20px;
  position: relative;
  display: inline-flex;
  vertical-align: bottom;
  opacity: 0.4;
  margin-right: 10px;
  background: rgba(255, 255, 255, 0.1) url(${docIdIcon}) no-repeat center/cover;
  background-size: 90% auto;
}
`;
const CakeIcon = styled.div`
  width: 20px;
  height: 20px;
  position: relative;
  display: inline-flex;
  vertical-align: bottom;
  opacity: 0.4;
  margin-right: 10px;
  background: rgba(255, 255, 255, 0.1) url(${cakeIcon}) no-repeat center/cover;
`;
const CalendarIcon = styled.div`
  width: 20px;
  height: 20px;
  position: relative;
  display: inline-flex;
  vertical-align: bottom;
  opacity: 0.4;
  margin-right: 10px;
  background: rgba(255, 255, 255, 0.1) url(${calendarIcon}) no-repeat center/cover;
  background-size: 80% auto;
}
`;
const SmartphoneIcon = styled.div`
  width: 20px;
  height: 20px;
  position: relative;
  display: inline-flex;
  vertical-align: bottom;
  opacity: 0.4;
  margin-right: 10px;
  background: rgba(255, 255, 255, 0.1) url(${smartphoneIcon}) no-repeat center/cover;
`;

const WhatsappIcon = styled.div`
  width: 20px;
  height: 20px;
  position: relative;
  display: inline-flex;
  vertical-align: bottom;
  opacity: 0.4;
  margin-right: 10px;
  background: rgba(255, 255, 255, 0.1) url(${whatsappIcon}) no-repeat center/cover;
`;

const CaddieIcon = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.1) url(${caddieIcon}) no-repeat center/cover;
`;
const BoleroIcon = styled.div`
    width: 100%;
    height: 100%;
  background: rgba(255, 255, 255, 0.1) url(${boleroIcon}) no-repeat center/cover;
`;
const BoleadorIcon = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.1) url(${boleadorIcon}) no-repeat center/cover;
`;
const MonitorIcon = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.1) url(${monitorIcon}) no-repeat center/cover;
`;

const BeneficiarioHeaderContainer = styled.div`
    background: #fff;
    border: 1px solid #ccc;
    padding: 0px;
    margin: 4px;
    flex: 0 0 50%;
    display: inline-table;
    min-width: 300px;
    max-height: 220px;

    /* Extra large devices (large laptops and desktops, 1200px and up) */
    @media only screen and (max-width: 1200px) {
        display: block;
        flex: unset;
    } 

    /* Large devices (laptops/desktops, 992px and up) */
    @media only screen and (max-width: 992px) {
        display: block;
        flex: unset;
    }

    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (max-width: 768px) {
        display: block;
        flex: unset;
    }

    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (max-width: 600px) {
       
    }

    /* Extra small devices (phones, 600px and down) */
    @media only screen and (max-width: 600px) {
        
    }
`;

// const BenefHeader = props => {
    class BenefHeader extends React.Component {
        constructor(props) {
            super(props);
            this.state = {

            }

        }

        render() {
            const { Beneficiario, ECASE_URL } = this.props;
            // const fechanacimiento = Beneficiario.fechanacimiento.toLocaleString('es-CO'); //.split(' ')[0];
            const fechanacimiento = moment(Beneficiario.fechanacimiento).format("YYYY-MM-DD HH:mm:ss");
            // const fechaingreso = Beneficiario.fecha.toLocaleString('es-CO'); //.split(' ')[0];
            const fechaingreso = moment(Beneficiario.fecha).format("YYYY-MM-DD HH:mm:ss");
            const antigueda = calculate_age(fechaingreso);
            // const actualizacion = Beneficiario.actualizacion.toLocaleString('es-CO'); //Beneficiario.actualizacion.split(' ')[0];
            const actualizacion = moment(Beneficiario.actualizacion).format("YYYY-MM-DD HH:mm:ss");
            
            const numaños = calculate_age(fechanacimiento);
            const celular = Beneficiario.celular ? formatCelular(Beneficiario.celular) : '';
            // const foto = Beneficiario.foto==='' ? require('../../assets/team/usuario.jpg') : ECASE_URL +'user_images/'+ Beneficiario.foto; 
            const foto = Beneficiario.foto==='' ? process.env.PUBLIC_URL + '/imgs/team/usuario.jpg' : ECASE_URL +'user_images/'+ Beneficiario.foto; 
            
            let iconEstadoClase = "cardBenefDetailsContainer-headerAvatar-icon";
            let avatarEstadoClase = 'cardBenefDetailsContainer-headerAvatar-estado2';
            switch (Beneficiario.estado.toLowerCase()) {
                case 'suspendido':
                    iconEstadoClase = "cardBenefDetailsContainer-headerAvatar-icon cardBenefDetailsContainer-headerAvatar-icon-suspendido";
                    avatarEstadoClase = 'cardBenefDetailsContainer-headerAvatar-estado2 cardBenefDetailsContainer-headerAvatar-estado2-suspendido';
                    break;
                case 'retirado':
                    iconEstadoClase = "cardBenefDetailsContainer-headerAvatar-icon cardBenefDetailsContainer-headerAvatar-icon-retirado";
                    avatarEstadoClase = 'cardBenefDetailsContainer-headerAvatar-estado2 cardBenefDetailsContainer-headerAvatar-estado2-retirado';
                    break;
                case 'fallecido':
                    iconEstadoClase = "cardBenefDetailsContainer-headerAvatar-icon cardBenefDetailsContainer-headerAvatar-icon-fallecido";
                    avatarEstadoClase = 'cardBenefDetailsContainer-headerAvatar-estado2 cardBenefDetailsContainer-headerAvatar-estado2-fallecido';
                    break;
                case 'no apto':
                    iconEstadoClase = "cardBenefDetailsContainer-headerAvatar-icon cardBenefDetailsContainer-headerAvatar-icon-noapto";
                    avatarEstadoClase = 'cardBenefDetailsContainer-headerAvatar-estado2 cardBenefDetailsContainer-headerAvatar-estado2-noapto';
                    break;
                case 'pendiente':
                    iconEstadoClase = "cardBenefDetailsContainer-headerAvatar-icon cardBenefDetailsContainer-headerAvatar-icon-pendiente";
                    avatarEstadoClase = 'cardBenefDetailsContainer-headerAvatar-estado2 cardBenefDetailsContainer-headerAvatar-estado2-pendiente';
                    break;
                case 'prueba':
                    iconEstadoClase = "cardBenefDetailsContainer-headerAvatar-icon cardBenefDetailsContainer-headerAvatar-icon-prueba";
                    avatarEstadoClase = 'cardBenefDetailsContainer-headerAvatar-estado2 cardBenefDetailsContainer-headerAvatar-estado2-prueba';
                    break;
                case 'visitar':
                    iconEstadoClase = "cardBenefDetailsContainer-headerAvatar-icon cardBenefDetailsContainer-headerAvatar-icon-visitar";
                    avatarEstadoClase = 'cardBenefDetailsContainer-headerAvatar-estado2 cardBenefDetailsContainer-headerAvatar-estado2-visitar';
                    break;
                case 'revisar':
                    iconEstadoClase = "cardBenefDetailsContainer-headerAvatar-icon cardBenefDetailsContainer-headerAvatar-icon-revisar";
                    avatarEstadoClase = 'cardBenefDetailsContainer-headerAvatar-estado2 cardBenefDetailsContainer-headerAvatar-estado2-revisar';
                    break;
                case 'actualizado':
                    iconEstadoClase = "cardBenefDetailsContainer-headerAvatar-icon cardBenefDetailsContainer-headerAvatar-icon-actualizado";
                    avatarEstadoClase = 'cardBenefDetailsContainer-headerAvatar-estado2 cardBenefDetailsContainer-headerAvatar-estado2-actualizado';
                    break;
                default:
                    break;
            }


            return (
                <BeneficiarioHeaderContainer>
                    <div className="cardBenefDetailsContainer-header">
                        <div className="cardBenefDetailsContainer-headerAvatar">
                            <div className="cardBenefDetailsContainer-headerAvatar-foto">
                                <div className="cardBenefDetailsContainer-headerAvatar-estado">
                                    { Beneficiario.activo==='1' && <Label green>Activo</Label>}
                                    { Beneficiario.activo==='0' && <Label red>No Activo</Label>}
                                </div>
                                <div className={avatarEstadoClase}>{Beneficiario.estado}</div>
                                <img src={foto} alt="Foto"/>
                            </div>
                            <div className="cardBenefDetailsContainer-headerAvatar-foto-triangle"></div>
                            <div className={iconEstadoClase}>
                            { Beneficiario.actividad.toLowerCase()==='caddie' && <CaddieIcon/>}
                            { Beneficiario.actividad.toLowerCase()==='bolero' && <BoleroIcon/>}
                            { Beneficiario.actividad.toLowerCase()==='boleador' && <BoleadorIcon/>}
                            { Beneficiario.actividad.toLowerCase()==='monitor' && <MonitorIcon/>}
                            </div>
                            <div className="cardBenefDetailsContainer-headerAvatar-idECASE">{Beneficiario.id}</div>
                        </div>

                        <div className="cardBenefDetailsContainer-header-desc">
                            <div className="cardBenefDetailsContainer-header-desc-Nombres">{capitalize(Beneficiario.nombres, true)}</div>
                            <div className="cardBenefDetailsContainer-header-desc-Apellidos">{capitalize(Beneficiario.apellidos, true)}</div>
                            <div className="cardBenefDetailsContainer-header-desc-Actividad">{Beneficiario.actividad.toUpperCase()}
                                {Beneficiario.categoria!=='' && <span> - {Beneficiario.categoria}</span>}
                            </div>
                            <div className="cardBenefDetailsContainer-header-desc-texto"><DocIdIcon title='Documento de Identidad'/><span className="cardBenefDetailsContainer-attr">{numberWithCommas(Beneficiario.docId)}</span></div>
                            <div className="cardBenefDetailsContainer-header-edad">
                                <div className="cardBenefDetailsContainer-header-desc-edad"><CakeIcon title='Fecha de Nacimiento'/><span className="cardBenefDetailsContainer-fecha"><span>{numaños}</span> años</span></div>
                                <div className="cardBenefDetailsContainer-header-desc-edadclub"><CalendarIcon title='Fecha de Ingreso o antigüedad'/><span className="cardBenefDetailsContainer-attr">{antigueda} años</span></div>
                            </div>
                            {/* <div className="cardBenefDetailsContainer-header-desc-texto"><SmartphoneIcon title='Número de Celular'/><span className="cardBenefDetailsContainer-attr"><a href={"https://wa.me/+57" + Beneficiario.celular} target="_new">{celular}</a></span></div> */}
                            <div className="cardBenefDetailsContainer-header-desc-texto"><SmartphoneIcon title='Número de Celular'/><span className="cardBenefDetailsContainer-attr"><a href={"tel:+57" + Beneficiario.celular} target="_new">{celular}</a></span></div>
                            <div className="cardBenefDetailsContainer-header-desc-texto"><WhatsappIcon title='Whatsapp'/><span className="cardBenefDetailsContainer-attr"><a href={"https://api.whatsapp.com/send?phone=57" + Beneficiario.celular + "&text=Hola "+ Beneficiario.nombres +", soy de la Fundación Club Farallones..."}  target="_new">{celular}</a></span></div>
                            <div className="cardBenefDetailsContainer-header-desc-texto-version">Actualizado a: {actualizacion}</div>
                        </div>

                    </div>
                </BeneficiarioHeaderContainer>
            );
        }
    }
//     return <BenefHeader />
// }
export default BenefHeader;

