// React
import React, { Fragment,useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ContentEditable from 'react-contenteditable';
// import sanitizeHtml from "sanitize-html";
// import { EditorState, convertToRaw } from 'draft-js';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import {capitalize, getFirstWords, removeHTMLTags,
    highlightAll, 
    disableNewlines, 
    pastePlainText
} from '../../utils/utils';
import Dimensions from 'react-dimensions';
import Pagination from '../Pagination';
import DatePicker from 'calcite-react/DatePicker';
import moment from 'moment';
import 'moment/locale/es-us';

import {showMsgToaster} from '../ShowMesg';
import {ToastContainer} from 'calcite-react/Toaster'; //notify
import Alert, {AlertMessage} from 'calcite-react/Alert';
import Select from 'calcite-react/Select';
import MultiSelect from 'calcite-react/MultiSelect';
import {MenuItem} from 'calcite-react/Menu';
import Loader from 'calcite-react/Loader';
import Search from 'calcite-react/Search';
import Button from 'calcite-react/Button';
import TextField from 'calcite-react/TextField';
import {
    ListItem,
    // ListHeader,
    ListItemTitle,
    ListItemSubtitle
} from 'calcite-react/List';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import '../../styles/css/font-awesome.min.css';
import './notas_styles.css';

import { serverMensaje, moduleNotificacionLoaded,
    // fetchNotificacionesData,
    fetchNotificacionesDatabyQuery,
    updateNotificacion,
    insertNotificacion,
    deleteNotificacion,
    // displayNotificacionFilter,

} from '../../redux/reducers/notas';
import {fetchUsersData} from '../../redux/reducers/users';
import { fetchBeneficiariosData } from '../../redux/reducers/beneficiarios';

import estadoNotas from '../../data/estadonotificacion.json';
import areadeTrabajoNotas from '../../data/areasnotificacion.json';
import tipodeNota from '../../data/tipodenotificacion.json';
import prioridadNota from '../../data/prioridadnotificacion.json';


// Styled & Motion Components
import styled from "styled-components";

import UserIcon from 'calcite-ui-icons-react/UserIcon';
import ResetIcon from 'calcite-ui-icons-react/ResetIcon';
import SaveIcon from 'calcite-ui-icons-react/SaveIcon';
import PlusIcon from 'calcite-ui-icons-react/SpeechBubblePlusIcon';
import TrashIcon from 'calcite-ui-icons-react/TrashIcon';
import FilterIcon from 'calcite-ui-icons-react/FilterIcon';
// import CalendarIcon from 'calcite-ui-icons-react/CalendarIcon';
import SearchIcon from 'calcite-ui-icons-react/SearchIcon';

import whatsappIcon from '../../assets/icons/whatsapp_white.png';
const WhatsappIcon = styled.div`
  width: 16px;
  height: 16px;
  position: relative;
  display: inline-flex;
  vertical-align: bottom;
//   opacity: 0.4;
  margin-right: 10px;
  background: rgba(255, 255, 255, 0.1) url(${whatsappIcon}) no-repeat center/cover;
`;

const EditNota = styled.div`
    width: 100%;
    // height: 100vh; // calc(100% - 10px);
    position: relative;
    background-color: #fff;
    z-index: 2;
`;
const AgregarNota = styled.div`
    width: 100%;
    // height: 100vh; // calc(100% - 10px);
    position: relative;
    background-color: #fff;
    z-index: 2;
`;


const ModuleNotificaciones = props => {
    const dispatch = useDispatch();
    const config = useSelector(state => state.config);
    const users = useSelector(state => state.users);
    const listUsers = useSelector(state => state.users.listUsers);
    const listBenef = useSelector(state => state.beneficiarios.listBenef);
    // const isEditable = (users.user.areatrabajo==='ADMINISTRATIVA' || users.user.areatrabajo==='COORDINACIÓN' || users.user.areatrabajo==='TECNOLOGÍA' ) ? true : false;
    const [isEditable,setIsEditable] = useState(true); //users.user.docId===notaSelected.creadapor ? true : false;
    const resultQueryNotas = useSelector(state => state.notas.resultQueryNotas);
    const [searchText,setSearchText] = useState('');
    const [isDisplayEditNota, setIsDisplayEditNota] = useState(false);
    const [isDisplayAgregarNota, setIsDisplayAgregarNota] = useState(false);

    const mensaje = useSelector(state => state.notas.mensaje);
    const [error, setError] = useState(null);
    const [errorenAgregar, setErrorenAgregar] = useState(null);
    const [fechaPlazo, setFechaPlazo] = useState(moment(new Date(),'YYYY-MM-DD'));
    const [fechaPlazoPickerFocused, setFechaPlazoPickerFocused] = useState(false);
    const minDate = moment('2017-05-14'); //moment('2020-07-15'); //moment().subtract(2, 'months').startOf('month');
    const maxDate= moment().add(15, 'months'); //moment().add(2, 'months').endOf('month');
    // Necesario para que funcione el HTML en las notas
    // const nota = useRef('');
    // const comentario = useRef('');
    // const sanitizeConf = {
    //     // allowedTags: ["b", "i", "em", "strong", "a", "p", "h1", "br", "div"],
    //     allowedTags: [ 'h3', 'h4', 'h5', 'h6', 'blockquote', 'p', 'a', 'ul', 'ol',
    //                     'nl', 'li', 'b', 'i', 'strong', 'em', 'strike', 'code', 'hr', 'br', 'div',
    //                     'table', 'thead', 'caption', 'tbody', 'tr', 'th', 'td', 'pre' ],
    //     allowedAttributes: { a: ["href"] }
    // };

    // const sanitize = (html) => {
    //     return sanitizeHtml(html, sanitizeConf);
    // };

    const [query,] = useState({
        "page": "1",
        "item_per_page": "10",
        "searchText":"",
        "id":"",
        "tiponota":"",
        "nota":"",
        "areanota":"", //"TRABAJO SOCIAL",
        "relacioncon":"",
        "responsables":"",
        "prioridad":"",
        "etiquetas":"",
        "estadonota":"Pendiente",
        "comentarios":"",
        "fechacreacion":"",
        "fechaplazo":"",
        "creadapor":"",
        "actualizacion":"",
        "version":"",
        "modificadopor":""
    });

    const [notaSelected, setNotaSelected] = useState({
        "id": '-1', //int
        "tiponota":"",
        "nota":"",
        "areanota":"",
        "relacioncon":"",
        "responsables":"",
        "prioridad":"",
        "etiquetas":"",
        "estadonota":"",
        "comentarios":"",
        "fechacreacion":"",
        "fechaplazo":"",
        "creadapor":"",
        "actualizacion":"",
        "version":"",
        "modificadopor":""
    });

    const [itemsNota, setItemsNota] = useState([
        // {"key":"id", "label": "Id", "value": notaSelected['id']},
        {"key":"estadonota", "label": "Estado", "value": notaSelected['estadonota']},
        {"key":"areanota", "label": "Area de Trabajo", "value": notaSelected['areanota']},
        {"key":"tiponota", "label": "Tipo de Nota", "value": notaSelected['tiponota']},
        {"key":"relacioncon", "label": "Nota en relacion con", "value": notaSelected['relacioncon']},
        {"key":"prioridad", "label": "Prioridad", "value": notaSelected['prioridad']},
        // {"key":"nota", "label": "Notificación u Observación", "value": notaSelected['nota']},
        {"key":"responsables", "label": "Responsables", "value": notaSelected['responsables']},
        {"key":"fechaplazo", "label": "Fecha plazo", "value": notaSelected['fechaplazo']},
        {"key":"etiquetas", "label": "Etiquetas o palabras clave (separadas por coma)", "value": notaSelected['etiquetas']},
        // {"key":"fechacreacion", "label": "Fecha creación", "value": notaSelected['fechacreacion']},
        // {"key":"creadapor", "label": "Nota creada por", "value": notaSelected['creadapor']},
        // {"key":"modificadopor", "label": "Modificado por", "value": notaSelected['modificadopor']},
        // {"key":"comentarios", "label": "Comentarios", "value": notaSelected['comentarios']},
        // {"key":"actualizacion", "label": "Actualizacion", "value": notaSelected['actualizacion']},
        // {"key":"version", "label": "Version", "value": notaSelected['version']},
    ]);

    // let showPages = 1; let totalPages = 1;
    const [showPages,] = useState(3);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [numItemsperPage,] = useState(10);
    const onPageNumberChange = (pageNumber) => {
        query['page'] = parseInt(pageNumber)+1;
        dispatch(fetchNotificacionesDatabyQuery({token: users.user.claveAPI, query: query} ));
        setCurrentPage(pageNumber);
    }
    
    useEffect(() => {
        if (!listUsers || listUsers.length<0){
            dispatch(fetchUsersData({token: users.user.claveAPI} ));
        }
        if (!listBenef || listBenef.length<=0){
            dispatch(fetchBeneficiariosData({token: users.user.claveAPI}));
        }
        if (resultQueryNotas===null){
            dispatch(fetchNotificacionesDatabyQuery({token: users.user.claveAPI, query: query} ));
            return
        }

        return () => {
            // executed when unmount
            // console.log('Saliendo del Modulo Beneficiarios');
        }
        
    }, [users.user.claveAPI, listUsers, listBenef, resultQueryNotas, query, dispatch]);

    useEffect(() => {
        if (resultQueryNotas===null){
            return
        }
        if (!listUsers || listUsers.length<0){
            return
        }
        if (!listBenef || listBenef.length<0){
            return
        }
        if (resultQueryNotas.datos.length<=0){
            console.log('No hay registros para mostrar...');
        }
        // Update
        dispatch(moduleNotificacionLoaded({loaded:true}));

        // console.log(resultQueryNotas);
        // Update totalPages
        if (resultQueryNotas.stats.count)
            setTotalPages(Math.ceil(resultQueryNotas.stats.count / numItemsperPage));


        if (mensaje.notificaciones && mensaje.notificaciones!=='') {

            const updateListaNotas = () => {
                setCurrentPage(0);
                query['page'] = '1'; // Es necesario enviarla para que encuentre algo
                query['estadonota'] = '';
                dispatch(fetchNotificacionesDatabyQuery({token: users.user.claveAPI, query: query} ));
            }

            if (typeof mensaje.notificaciones === 'string' && mensaje.notificaciones.toLowerCase().includes('error')){
                showMsgToaster('error', 'Ocurrió un fallo en el servidor', mensaje.notificaciones);
                mensaje.notificaciones='';
            } 
            else {
                showMsgToaster('success',"Notificaciones", mensaje.notificaciones.split('.')[0]);
                mensaje.notificaciones='';
                dispatch(serverMensaje({notificaciones:''}));
                updateListaNotas();
            }
            
        }
        
    }, [resultQueryNotas, listUsers, listBenef, numItemsperPage, query, users.user.claveAPI, 
        mensaje.notificaciones, dispatch]);

    // Save on Server
    const updateDataonServer = (action, nota) => {
        // console.log(nota);
        switch (action) {
            case 'insert':
                nota['creadapor'] = users.user.docId;
                dispatch(insertNotificacion( { token: users.user.claveAPI, notificaciones:nota}));
                break;
            case 'remove':
                dispatch(deleteNotificacion( { token: users.user.claveAPI, notificaciones:nota}));
                break;
            case 'update':
                // Actualizar solo el registro necesario, para no enviar toda la tabla
                dispatch(updateNotificacion( { token: users.user.claveAPI, notificaciones:nota}));
                break;
            default: // update
                console.log('Acción no disponible');
                break;
        }
    }

    const updateItemsNota = (f, v) => {
        const newitemsNota = itemsNota.map((itm, index) => {
            if (itm.key===f){
                itm.value = v;
            }
            return itm;
        });
        setItemsNota(newitemsNota);
    };

    const getUserbyDocId = (docId) =>{
        let u = null;
        if (listUsers && listUsers.length>0)
            u = listUsers.filter(itm => itm.docId===docId);
        return u;
    }
    // Obtiene los Ids de los Responsables de la tarea y devuelve los atributos guardados en la tabla Users
    const getDatabyResponsableId = (r) =>{
        const responsables = r.split(',');
        let r_final = [];
        if (responsables && responsables.length>0){
            responsables.forEach(docId => {
                const u = getUserbyDocId(docId);
                if (u && u.length>=1)
                    r_final.push({"nombre": capitalize(u[0].nombre, true), "foto":u[0].foto});
            });
        }
        return r_final
    }

    const showNota = (idx) =>{
        document.getElementById('nota_'+idx).style.display = document.getElementById('nota_'+idx).style.display==='block' ? '-webkit-box' : 'block';
    }
    const showComentarios = (idx) =>{
        document.getElementById('comment_'+idx).style.display = document.getElementById('comment_'+idx).style.display==='block' ? '-webkit-box' : 'block';
    }

    // Buscar
    const buscarenNota = (str)  => {
        // console.log('texto a buscar:', searchText);
        // query['searchText'] = searchText.trim();
        // query['prioridad'] = searchText.trim();
        // query['etiquetas'] = searchText.trim();
        setCurrentPage(0);
        query['page'] = '1'; // Es necesario enviarla para que encuentre algo
        query['nota'] = str || searchText.trim() || '';
        query['estadonota'] = ''; // removido por el momento
        dispatch(fetchNotificacionesDatabyQuery({token: users.user.claveAPI, query: query} ));
    }

    // Toolbar
    const onBtnFiltrarNota= (evt) =>{
        evt.preventDefault();
        evt.stopPropagation();
        setCurrentPage(0);
        query['page'] = '1'; // Es necesario enviarla para que encuentre algo
        query['nota'] = searchText.trim() || '';
        query['estadonota'] = ''; // removido por el momento
        toggleFilterTool(evt);
    }
    const handleSearchKeyPress = (evt) => {
        const keyCode = evt.keyCode || evt.which
        if (keyCode === 13 || evt.key === 'Enter') {
            evt.returnValue = false
            if (evt.preventDefault) evt.preventDefault();
            // Search
            buscarenNota();
        }
    }
    const onSearchChange = (value) => {
        setSearchText(value);
    }
    const onSearchRequestClear = () => {
        setSearchText('');
        setCurrentPage(0);
        query['page'] = '1';
        query['nota'] = '';
        dispatch(fetchNotificacionesDatabyQuery({token: users.user.claveAPI, query: query} ));
        // buscarenNota(); // No refresca la lista
    }
    const buscarTexto = (evt)  => {
        evt.preventDefault();
        evt.stopPropagation();
        // console.log('texto a buscar:', searchText);
        buscarenNota(searchText.trim());
    }
    const toggleBuscarTool = (evt)  => {
        evt.preventDefault();
        evt.stopPropagation();
        document.getElementById('buscar-tool-mobile').style.display = document.getElementById('buscar-tool-mobile').style.display==='inline-flex' ? 'none' : 'inline-flex';
    }
    
    const onBtnAgregarNota = (evt) =>{
        evt.preventDefault();
        evt.stopPropagation();
        // console.log(users);
        const newitemsNota = itemsNota.map((itm, index) => {
            itm.value = '';
            if (itm.key==='id') itm.value = "-1";
            if (itm.key==='estadonota') itm.value = "Pendiente";
            if (itm.key==='tiponota') itm.value = "Informativa";
            if (itm.key==='areanota') itm.value = "Fundación";
            if (itm.key==='prioridad') itm.value = "Baja";
            if (itm.key==='fechacreacion') itm.value = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
            if (itm.key==='fechaplazo') itm.value = moment().add(15, 'days').format("YYYY-MM-DD HH:mm:ss");
            if (itm.key==='creadapor') itm.value = users.user.nombre; //users.user.docId;
            if (itm.key==='modificadopor') getFirstWords(users.user.nombre.toUpperCase());
            if (itm.key==='actualizacion') itm.value = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
            if (itm.key==='version') itm.value = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
            
            return itm;
        });
        let nuevoRegistro = {
            "id": '-1', //int
            "tiponota":"Informativa",
            "nota":"",
            "areanota":"Fundación",
            "relacioncon":"",
            "responsables":"",
            "prioridad":"Baja",
            "etiquetas":"",
            "estadonota":"Pendiente",
            "comentarios":"",
            "fechacreacion":moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
            "fechaplazo":moment().add(15, 'days').format("YYYY-MM-DD HH:mm:ss"),
            "creadapor": users.user.docId,
            "actualizacion":moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
            "version":moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
            "modificadopor": getFirstWords(users.user.nombre.toUpperCase())
        };
        setItemsNota(newitemsNota);
        setNotaSelected(nuevoRegistro);
        // Update UI
        setEditorNotaState(EditorState.createEmpty());
        setEditorComentarioState(EditorState.createEmpty());
        setTiponotaSelectedValue(nuevoRegistro.tiponota);
        setPrioridadSelectedValue(nuevoRegistro.prioridad);
        setAreanotaSelectedValue(nuevoRegistro.areanota);
        setEstadonotaSelectedValue(nuevoRegistro.estadonota);
        setInputBeneficiarioValue(nuevoRegistro.relacioncon);
        setSelectedResponsablesValues(nuevoRegistro.responsables);
        // setSelectedResponsablesValues(nuevoRegistro.responsables.split(',').map(x=>+x));
        setIsDisplayAgregarNota(true);
    }
    const onBtnEditNota = (evt, id, index) =>{
        evt.preventDefault();
        evt.stopPropagation();
        if (resultQueryNotas && resultQueryNotas.datos.length>0){
            const newitemsNota = resultQueryNotas.datos.filter((itm, index) => { return itm.id===id});
            // console.log(newitemsNota);
            if (newitemsNota){
                const itemsnote = itemsNota.map((itm, index) => {
                    itm.value = newitemsNota[0][itm.key];
                    if (itm.key==='relacioncon' && newitemsNota[0].benefnombres!==null) itm.value = capitalize(newitemsNota[0].benefnombres, true) +' '+capitalize(newitemsNota[0].benefapellidos,true);
                    if (itm.key==='modificadopor') itm.value = getFirstWords(users.user.nombre.toUpperCase());
                    // if (itm.key==='fechacreacion') itm.value = N[id][itm.key].split(' ')[1];
                    return itm;
                });
                setItemsNota(itemsnote);
                setNotaSelected(newitemsNota[0]);
                // Update UI
                // Agregar datos del comentario al editor
                // const html = '<div>'+newitemsNota[0].comentarios+'</div>';
                // const comentariosContentBlock = htmlToDraft('<div>'+newitemsNota[0].comentarios+'</div>');
                const comentariosContentBlock = htmlToDraft(newitemsNota[0].comentarios);
                if (comentariosContentBlock) {
                    const comentariosContentState = ContentState.createFromBlockArray(comentariosContentBlock.contentBlocks);
                    const comentariosEditorState = EditorState.createWithContent(comentariosContentState);
                    setEditorComentarioState(comentariosEditorState);
                }
                // const notaContentBlock = htmlToDraft('<div>'+newitemsNota[0].nota+'</div>');
                const notaContentBlock = htmlToDraft(newitemsNota[0].nota);
                if (notaContentBlock) {
                    const notaContentState = ContentState.createFromBlockArray(notaContentBlock.contentBlocks);
                    const notaEditorState = EditorState.createWithContent(notaContentState);
                    setEditorNotaState(notaEditorState);
                }
                setTiponotaSelectedValue(newitemsNota[0].tiponota);
                setPrioridadSelectedValue(newitemsNota[0].prioridad);
                setAreanotaSelectedValue(newitemsNota[0].areanota);
                setEstadonotaSelectedValue(newitemsNota[0].estadonota);
                setFechaPlazo(moment(newitemsNota[0].fechaplazo, 'YYYY-MM-DD'));
                // setInputBeneficiarioValue(newitemsNota[0].relacioncon); 
                if (newitemsNota[0].benefnombres!==null)
                    setInputBeneficiarioValue(capitalize(newitemsNota[0].benefnombres, true) +' '+capitalize(newitemsNota[0].benefapellidos,true));
                setSelectedResponsablesValues(newitemsNota[0].responsables); //.split(',').map(x=>+x));

                // setIsEditable(users.user.docId===newitemsNota[0].creadapor ? true : false);
                setIsEditable(users.user.areatrabajo==='ADMINISTRATIVA' || users.user.docId===newitemsNota[0].creadapor ? true : false);
                setIsDisplayEditNota(true);
            }
        }
        
    }
    
    const enviarNota = () => {
        // console.log(notaSelected);
        setIsDisplayAgregarNota(false);
        setIsDisplayEditNota(false);
        const htmlNota = draftToHtml(convertToRaw(editorNotaState.getCurrentContent()));
        const htmlComentarios = draftToHtml(convertToRaw(editorComentarioState.getCurrentContent()));
        notaSelected['nota'] = htmlNota;
        notaSelected['comentarios'] = htmlComentarios;
        notaSelected['modificadopor'] = getFirstWords(users.user.nombre.toUpperCase());
        if (notaSelected['relacioncon']==='' || notaSelected['relacioncon']===undefined || notaSelected['relacioncon']===null)
            notaSelected['relacioncon']='FUNDACIÓN';
        if (isDisplayEditNota===true) updateDataonServer('update', notaSelected);
        if (isDisplayAgregarNota===true) updateDataonServer('insert', notaSelected);
        // TODO: Enviar notificación al correo
        // window.open('mailto:your@email.here.com');
        // Enviar datos al Whatsapp
        // por políticas de Whatsapp, no se puede enviar un mensaje masivamente por código, ni a un grupo o lista de distribucción
        // Es necesario enviarlo al mismo que crea la lista para que lo reenvie luego al grupo
        // Obtener el numero de telefono del que crea la lista
        // Enviarse a uno mismo o al primero de la lista
        let celular = users.user.celular;
        let nombre = capitalize(users.user.nombre,true);
        const benef = listBenef.filter(b=>b.docId===notaSelected.relacioncon)[0];
        if (benef){
            const fotobenef = (benef.foto==='' || benef.foto===null) ? config.ECASE_URL + 'user_images/usuario.jpg' : config.ECASE_URL +'user_images/'+ benef.foto;
            // Enviar mensaje al primero en la lista de responsables
            const responsable_nrouno = listUsers.filter(b=>b.docId===notaSelected.responsables.split(',')[0].trim())[0];
            celular = responsable_nrouno.celular || celular;
            // nombre = responsable_nrouno.nombre || nombre;
            if (benef.foto!=='' && benef.foto!==null){
                window.open('https://api.whatsapp.com/send?phone=57'+ celular + '&text=*_Nota automática del GRB_*: %0D%0A %0D%0A '+replaceSomeText(removeHTMLTags(notaSelected.nota))+' %0D%0A Relacionada con *'+benef.nombres.toUpperCase()+' '+benef.apellidos.toUpperCase()+'* ('+benef.docId+'). '+encodeURIComponent(fotobenef));
            } else {
                window.open('https://api.whatsapp.com/send?phone=57'+ celular + '&text=*_Nota automática del GRB_*: %0D%0A %0D%0A '+replaceSomeText(removeHTMLTags(notaSelected.nota))+' %0D%0A Relacionada con *'+benef.nombres.toUpperCase()+' '+benef.apellidos.toUpperCase()+'* ('+benef.docId+').');
            }
        } else {
            window.open('https://api.whatsapp.com/send?phone=57'+ celular + '&text=*_Nota automática del GRB_*: %0D%0A %0D%0A '+replaceSomeText(removeHTMLTags(notaSelected.nota))+' %0D%0A creada por *' +nombre+ '*');
        }
        
    }
    const saveNota = () => {
        setIsDisplayAgregarNota(false);
        setIsDisplayEditNota(false);
        const htmlNota = draftToHtml(convertToRaw(editorNotaState.getCurrentContent()));
        const htmlComentarios = draftToHtml(convertToRaw(editorComentarioState.getCurrentContent()));
        // console.log(html);
        notaSelected['nota'] = htmlNota;
        notaSelected['comentarios'] = htmlComentarios;
        notaSelected['modificadopor'] = getFirstWords(users.user.nombre.toUpperCase());
        if (notaSelected['relacioncon']==='' || notaSelected['relacioncon']===undefined || notaSelected['relacioncon']===null)
            notaSelected['relacioncon']='FUNDACIÓN';
        if (isDisplayEditNota===true) updateDataonServer('update', notaSelected);
        if (isDisplayAgregarNota===true) updateDataonServer('insert', notaSelected);
    }
    const cancelNota = (evt) => {
        if (isDisplayEditNota===true) setIsDisplayEditNota(false);
        if (isDisplayAgregarNota===true) setIsDisplayAgregarNota(false);
    }
    const eliminarNota = (evt, id) =>{
        evt.preventDefault();
        evt.stopPropagation();
        updateDataonServer('remove', notaSelected);
        setIsDisplayEditNota(false);
        // if (resultQueryNotas && resultQueryNotas.datos.length>0){
        //     const newitemsNota = resultQueryNotas.datos.filter((itm, index) => { return itm.id===id});
        //     if (newitemsNota){
        //         setNotaSelected(newitemsNota[0]);
        //         updateDataonServer('remove', newitemsNota[0]);
        //         setIsDisplayEditNota(false);
        //     }
        // }
    }


    const replaceSomeText = (s) =>{
        return s
            .replace(/&nbsp;/g, ' ')
            .replace(/<br>/g, '')
            .replace(/<\/br>/g, '')
            .replace(/<\/div>/g, '')
            .replace(/<div>/g, '')
            .replace(/<b>/g, '')
            .replace(/<strong>/g, '')
            .replace(/<p>/g, '')
            .replace(/<\/b>/g, '')
            .replace(/<\/strong>/g, '')
            .replace(/<\/p>/g, '')
            .replace(/&amp;/g, '&')
            .replace(/&gt;/g, '>')
            .replace(/&lt;/g, '<').trim()
    }
    const validateInput = (f, v, final) => {
        const regex_onlynum = /[0-9,]|\./;
        // const regex_nombres = /^['`´a-zA-ZÀ-ÿ\u00C0-\u017F\s]+$/;
        let new_val = null;
        let err = null;
        switch (f) {
            case 'id':
                new_val = replaceSomeText(v);
                if (!regex_onlynum.test(new_val)) {
                    err = 'Dígite solo números válidos';
                    new_val = null;
                }
                if (new_val===null) new_val = '';
                break;
            case 'fechacreacion': //0000-00-00 00:00:00
                new_val = replaceSomeText(v).substr(0,30);
                if (final && new_val===''){
                    new_val = replaceSomeText(v).substr(0,30);
                    err = 'Dígite una fecha de creación válida';
                    new_val = null;
                }
                break;
            case 'fechaplazo': //0000-00-00 00:00:00
                new_val = replaceSomeText(v).substr(0,30);
                if (final && new_val===''){
                    new_val = replaceSomeText(v).substr(0,30);
                    err = 'Dígite una fecha límite válida';
                    new_val = null;
                }
                break;
            case 'tiponota':
                new_val = v.substr(0,32);
                if (final)
                    new_val = replaceSomeText(v).substr(0,32);
                break;
            case 'estadonota':
                new_val = v.substr(0,32);
                if (final)
                    new_val = replaceSomeText(v).substr(0,32);
                break;
            case 'areanota':
                new_val = v.substr(0,32);
                if (final)
                    new_val = replaceSomeText(v).substr(0,32);
                break;
            case 'relacioncon':
                new_val = v.substr(0,20);
                if (final)
                    new_val = replaceSomeText(v).substr(0,20);
                break;
            case 'responsables':
                new_val = v.substr(0,500);
                if (final)
                    new_val = v.substr(0,600).replace(/<br>/g, ' ').trim();
                break;
            case 'etiquetas':
                new_val = v.substr(0,500);
                if (final)
                    new_val = v.substr(0,600).replace(/<br>/g, ' ').trim();
                break;
            case 'prioridad':
                new_val = v.substr(0,20);
                if (final)
                    new_val = replaceSomeText(v).substr(0,20);
                break;
            case 'celular':
                new_val = replaceSomeText(v).substr(0,15);
                if (!regex_onlynum.test(v)) {
                    err = 'Dígite solo números válidos de celular';
                    new_val = null;
                } else if(v.length>15){
                    err = 'Dígite un número de celular válido';
                    new_val = null;
                }
                if (new_val===null) new_val = '';
                break;
            
            // case 'leida':
            //     new_val = replaceSomeText(v).substr(0,1); //1
            //     if (!regex_onlynum.test(new_val)) {
            //         err = 'Dígite solo números válidos';
            //         new_val = null;
            //     } else if(parseInt(new_val)>1){
            //         err = 'Dígite un número válido';
            //         new_val = null;
            //     }
            //     break;
            
            case 'creadapor':
                new_val = v.substr(0,32);
                if (final)
                    new_val = replaceSomeText(v).substr(0,32);
                break;
            case 'modificadopor':
                new_val = v.substr(0,32);
                if (final)
                    new_val = replaceSomeText(v).substr(0,32);
                break;
            case 'nota':
                new_val = v;
                // if (final)
                //     new_val = v; //replaceSomeText(v);
                break;
            case 'comentarios':
                new_val = v;
                // if (final)
                //     new_val = v; //replaceSomeText(v);
                break;
            case 'actualizacion': //0000-00-00 00:00:00
                new_val = v.substr(0,30);
                if (final && new_val===''){
                    new_val = replaceSomeText(v).substr(0,30);
                    err = 'Dígite una fecha de actualización válida';
                    new_val = null;
                }
                break;
            case 'version': //0000-00-00 00:00:00
                new_val = v.substr(0,30);
                if (final && new_val===''){
                    new_val = replaceSomeText(v).substr(0,30);
                    err = 'Dígite una fecha de version válida';
                    new_val = null;
                }
                break;
            
            default:
                break;
        }

        if (isDisplayEditNota) setError(err);
        if (isDisplayAgregarNota) setErrorenAgregar(err);
        return new_val
    }
    const handleChange = evt => {
        const field = evt.currentTarget.id.split('_')[1];
        const new_val = validateInput(field, evt.target.value, false);
        if(new_val){
            notaSelected[field] = new_val
        };
    };
    const handleBlur = evt => {
        const val = evt.currentTarget.innerHTML; //evt.currentTarget.innerText; //
        const field = evt.target.id.split('_')[1];
        let new_val = validateInput(field, val, true);
        if(new_val || new_val===''){
            if (Object.keys(notaSelected).includes(field)){
                notaSelected[field] = new_val;
                
            }
            updateItemsNota(field, new_val);
        };
    };

    const [tiponotaSelectedValue, setTiponotaSelectedValue] = useState('');
    const handleTipoNotaSelectChange = (value, item) => {
        setTiponotaSelectedValue(value);
        notaSelected['tiponota'] = value;
        updateItemsNota('tiponota', value);
    };
    const handleTipoNotaonKeyDown  = (evt) => {
        const keyCode = evt.keyCode || evt.which
        if (keyCode === 8 || keyCode === 46) {
            setTiponotaSelectedValue('');
            notaSelected['tiponota'] = '';
            updateItemsNota('tiponota', '');
            evt.returnValue = false
            if (evt.preventDefault) evt.preventDefault()
        }
    }

    const [prioridadSelectedValue, setPrioridadSelectedValue] = useState('');
    const handlePrioridadSelectChange = (value, item) => {
        setPrioridadSelectedValue(value);
        notaSelected['prioridad'] = value;
        updateItemsNota('prioridad', value);
    };
    const handlePrioridadonKeyDown  = (evt) => {
        const keyCode = evt.keyCode || evt.which
        if (keyCode === 8 || keyCode === 46) {
            setPrioridadSelectedValue('');
            notaSelected['prioridad'] = '';
            updateItemsNota('prioridad', '');
            evt.returnValue = false
            if (evt.preventDefault) evt.preventDefault()
        }
    }

    const [areanotaSelectedValue, setAreanotaSelectedValue] = useState('');
    const handleAreanotaSelectChange = (value, item) => {
        setAreanotaSelectedValue(value);
        notaSelected['areanota'] = value;
        updateItemsNota('areanota', value);
    };
    const handleAreanotaonKeyDown  = (evt) => {
        const keyCode = evt.keyCode || evt.which
        if (keyCode === 8 || keyCode === 46) {
            setAreanotaSelectedValue('');
            notaSelected['areanota'] = '';
            updateItemsNota('areanota', '');
            evt.returnValue = false
            if (evt.preventDefault) evt.preventDefault()
        }
    }

    const [estadonotaSelectedValue, setEstadonotaSelectedValue] = useState('');
    const handleEstadonotaSelectChange = (value, item) => {
        setEstadonotaSelectedValue(value);
        notaSelected['estadonota'] = value;
        updateItemsNota('estadonota', value);
    };
    const handleEstadonotaonKeyDown  = (evt) => {
        const keyCode = evt.keyCode || evt.which
        if (keyCode === 8 || keyCode === 46) {
            setEstadonotaSelectedValue('');
            notaSelected['estadonota'] = '';
            updateItemsNota('estadonota', '');
            evt.returnValue = false
            if (evt.preventDefault) evt.preventDefault()
        }
    }

    // DatePicker
    const onFechaPlazoChange = (date) =>{
        if (date.preventDefault) date.preventDefault()
        if (date){
            notaSelected.fechaplazo = date.utc().format('YYYY-MM-DD HH:mm:ss');
            setFechaPlazo(date);
            updateItemsNota('fechaplazo', notaSelected.fechaplazo);
        }
    }
    const onFechaPlazoFocusChange = ({ focused }) =>{
        setFechaPlazoPickerFocused(focused)
    }

    // Select Beneficiario
    const [selectedBeneficiarioItem, setSelectedBeneficiarioItem] = useState('');
    const [inputBeneficiarioValue, setInputBeneficiarioValue] = useState('');
    const searchBeneficiarioChanged = (e) => {
        setSelectedBeneficiarioItem(e);
        setInputBeneficiarioValue(e);
        notaSelected['relacioncon'] = e.value;
        updateItemsNota('relacioncon', e.value);
    }
    const clearBeneficiarioSearch = () => {
        setInputBeneficiarioValue('');
        setSelectedBeneficiarioItem('');
        notaSelected['relacioncon'] = '';
        updateItemsNota('relacioncon', '');
    }
    const onBeneficiarioUserAction = (inputValue, selectedItemVal) => {
        setInputBeneficiarioValue(inputValue);
        setSelectedBeneficiarioItem(selectedItemVal);
    }

    // Select Responsables (Users)
    const [selectedResponsablesValues, setSelectedResponsablesValues] = useState(); //notaSelected.responsables.split(',').map(x=>+x)
    const handleResponsablesMultiSelectChange = (values, items) => {
        let resp_str = "";
        if(values && values.length>0){
            resp_str = values.join(',');
        }
        setSelectedResponsablesValues(values);
        notaSelected['responsables'] = resp_str;
        updateItemsNota('responsables', resp_str);
        // updateDataonServer();
    };
    // const renderResponsablesValue = (items) => {
    //     if (!items || items.length === 0) {
    //       return 'Seleccione una opción'
    //     } else if (items.length > 2) {
    //       // Template literals currently are not supported by MDX:
    //       // https://github.com/mdx-js/mdx/issues/218
    //       return items.length + ' responsables'
    //     } else {
    //       return items.map(item => item.props.children).join(',')
    //     }
    // }

    // Editor
    
    const [editorNotaState, setEditorNotaState] = useState( () => EditorState.createEmpty() );
    const [editorComentarioState, setEditorComentarioState] = useState( () => EditorState.createEmpty() );
    const onNotaEditorStateChange = (eState) => {
        setEditorNotaState(eState);
    };
    const onComentarioEditorStateChange = (eState) => {
        setEditorComentarioState(eState);
    };
    
    // Filtro
    const toggleFilterTool = (evt)  => {
        evt.preventDefault();
        evt.stopPropagation();
        document.getElementById('filtro-tool-mobile').style.display = document.getElementById('filtro-tool-mobile').style.display==='block' ? 'none' : 'block';
    }
    const clearFilters = (evt) => {
        evt.preventDefault();
        evt.stopPropagation();
        setEstadonotaFilterSelectedValue('');
        query['estadonota'] = '';
        setPrioridadFilterSelectedValue('');
        query['prioridad'] = '';
        setAreanotaFilterSelectedValue('');
        query['areanota'] = '';
        setTipoNotaFilterSelectedValue('');
        query['tiponota'] = '';
        setCreadaporFilterSelectedValue('');
        query['creadapor'] = '';
        setRelacionconFilterSelectedValue('');
        // setRelacionconFilterSelectedValue(undefined);
        document.getElementById('notas-fundacion-selectRelacionCon').defaultValue='';
        document.getElementById('notas-fundacion-selectRelacionCon').value='';
        query['relacioncon'] = '';

        dispatch(fetchNotificacionesDatabyQuery({token: users.user.claveAPI, query: query} ));

        setTimeout(() => {
            // console.log('Limpiando de nuevo');
            document.getElementById('notas-fundacion-selectRelacionCon').defaultValue='';
            document.getElementById('notas-fundacion-selectRelacionCon').value='';
            setRelacionconFilterSelectedValue('');
        }, 1300);

    };
    const makeFilter = (q) => {
        q['page'] = '1';
        q['nota'] = searchText.trim() || '';
        dispatch(fetchNotificacionesDatabyQuery({token: users.user.claveAPI, query: q} ));
    }
    // Filtro por Estado
    const [estadonotaFilterSelectedValue, setEstadonotaFilterSelectedValue] = useState('');
    const handleEstadonotaFilterSelectChange = (value, item) => {
        setEstadonotaFilterSelectedValue(value);
        query['estadonota'] = value;
        makeFilter(query);
    };
    const handleEstadonotaFilteronKeyDown  = (evt) => {
        const keyCode = evt.keyCode || evt.which
        if (keyCode === 8 || keyCode === 46) {
            setEstadonotaFilterSelectedValue('');
            query['estadonota'] = '';
            makeFilter(query);
            evt.returnValue = false
            if (evt.preventDefault) evt.preventDefault()
        }
    }
    // Filtro por Prioridad
    const [prioridadFilterSelectedValue, setPrioridadFilterSelectedValue] = useState('');
    const handlePrioridadFilterSelectChange = (value, item) => {
        setPrioridadFilterSelectedValue(value);
        query['prioridad'] = value;
        makeFilter(query);
    };
    const handlePrioridadFilteronKeyDown  = (evt) => {
        const keyCode = evt.keyCode || evt.which
        if (keyCode === 8 || keyCode === 46) {
            setPrioridadFilterSelectedValue('');
            query['prioridad'] = '';
            makeFilter(query);
            evt.returnValue = false
            if (evt.preventDefault) evt.preventDefault()
        }
    }
    // Filtro por Area de Trabajo
    const [areanotaFilterSelectedValue, setAreanotaFilterSelectedValue] = useState('');
    const handleAreanotaFilterSelectChange = (value, item) => {
        setAreanotaFilterSelectedValue(value);
        query['areanota'] = value;
        makeFilter(query);
    };
    const handleAreanotaFilteronKeyDown  = (evt) => {
        const keyCode = evt.keyCode || evt.which
        if (keyCode === 8 || keyCode === 46) {
            setAreanotaFilterSelectedValue('');
            query['areanota'] = '';
            makeFilter(query);
            evt.returnValue = false
            if (evt.preventDefault) evt.preventDefault()
        }
    }
    // Filtro por Tipo Nota
    const [tiponotaFilterSelectedValue, setTipoNotaFilterSelectedValue] = useState('');
    const handleTipoNotaFilterSelectChange = (value, item) => {
        setTipoNotaFilterSelectedValue(value);
        query['tiponota'] = value;
        makeFilter(query);
    };
    const handleTipoNotaFilteronKeyDown  = (evt) => {
        const keyCode = evt.keyCode || evt.which
        if (keyCode === 8 || keyCode === 46) {
            setTipoNotaFilterSelectedValue('');
            query['tiponota'] = '';
            makeFilter(query);
            evt.returnValue = false
            if (evt.preventDefault) evt.preventDefault()
        }
    }
    // Filtro por Creada por Usuario
    const [creadaporFilterSelectedValue, setCreadaporFilterSelectedValue] = useState('');
    const handleCreadaporFilterSelectChange = (value, item) => {
        // console.log(value, item.props.children);
        setCreadaporFilterSelectedValue(item.props.children);
        query['creadapor'] = value;
        makeFilter(query);
    };
    const handleCreadaporFilteronKeyDown  = (evt) => {
        const keyCode = evt.keyCode || evt.which
        if (keyCode === 8 || keyCode === 46) {
            setCreadaporFilterSelectedValue('');
            query['creadapor'] = '';
            makeFilter(query);
            evt.returnValue = false
            if (evt.preventDefault) evt.preventDefault()
        }
    }
    // Filtro por Relacionada con
    const [relacionconFilterSelectedValue, setRelacionconFilterSelectedValue] = useState('');
    const handleRelacionconFilterSelectChange = (value, item) => {
        setRelacionconFilterSelectedValue(item.props.children);
        query['relacioncon'] = value;
        makeFilter(query);
    };
    const handleRelacionconFilteronBlur = (evt) => {
        const value = evt.target.value;
        // console.log(document.getElementById('notas-fundacion-selectRelacionCon'));
        // console.log(evt, evt.target.value);
        if (value===null || value===undefined || value===''){
            // console.log(relacionconFilterSelectedValue);
            // setRelacionconFilterSelectedValue('');
            document.getElementById('notas-fundacion-selectRelacionCon').defaultValue='';
            document.getElementById('notas-fundacion-selectRelacionCon').value='';
            query['relacioncon'] = '';
            makeFilter(query);
            evt.returnValue = false
            if (evt.preventDefault) evt.preventDefault()
            if (evt.stopPropagation) evt.stopPropagation()
            
        }
         
    }
    // Esto no funciona como se espera y sigue teniendo valores nulos, generando error interno
    // const handleRelacionconFilteronKeyDown  = (evt) => {
    //     const value = evt.target.getAttribute("value");
    //     // console.log("handleRelacionconFilteronKeyDown => ",value);
    //     const keyCode = evt.keyCode || evt.which
    //     if (keyCode === 8 || keyCode === 46) { //keyCode === 8 || //backspace or Delete
    //         setRelacionconFilterSelectedValue(undefined); //undefined
    //         query['relacioncon'] = '';
    //         makeFilter(query);
    //         evt.returnValue = false
    //         if (evt.preventDefault) evt.preventDefault()
    //     } 
    // }

    if (resultQueryNotas && listUsers ) 
    return (
        <Fragment>
            <ToastContainer />
            {isDisplayAgregarNota && <AgregarNota>
                    <div className="nota-editform-title">Crear Nueva Nota</div>
                    <div className="nota-editform-editar">
                        {errorenAgregar && <div style={{color: 'red', width: '100%'}}>
                                <Alert red showIcon style={{minWidth: '100%', marginBottom: '10px'}}
                                    showCloseLabel 
                                    onClose={() => setErrorenAgregar(null)}>
                                    <AlertMessage>
                                        Error:{' '}
                                        <strong>{errorenAgregar}</strong>
                                    </AlertMessage>
                                </Alert>
                            </div> 
                        }
                        {/* ITEMS */}
                        
                        { itemsNota && 
                            itemsNota.map((itm, index) => {
                                return (<div key={index} className="itemContainer">
                                    <div className="itemContainer-attr" 
                                        style={{backgroundColor:(index % 2 === 0) ? '#f8f8f8': '#fff'}}
                                    >{itm.label}</div>
                                    <div className="itemContainer-value" 
                                        style={{backgroundColor:(index % 2 === 0) ? '#f8f8f8': '#fff'}}
                                    >
                                    {   
                                        (itm.key==='id' || itm.key==='fechacreacion' || itm.key==='creadapor')
                                        ?
										<ContentEditable
                                            id={'nota_'+itm.key}
                                            style={{color:'#ccc'}}
											html={itm.value || ''} // innerHTML of the editable div
											className='itemContainer-value-long'
											disabled={true}      // use true to disable editing
											tagName='div' // Use a custom HTML tag (uses a div by default)
										/>
                                        :
                                        itm.key==='tiponota' 
                                        ?
                                            <Select filterable positionFixed 
                                                // disabled={!isEditable} 
                                                placeholder={'Seleccione...'}
                                                onChange={handleTipoNotaSelectChange}
                                                onKeyDown={handleTipoNotaonKeyDown}
                                                value={tiponotaSelectedValue || ''}
                                                selectedValue={tiponotaSelectedValue || ''}
                                            >
                                            {tipodeNota.tipo.map(e => {
                                                return (
                                                <MenuItem key={e.id} value={e.name}>
                                                    {e.name}
                                                </MenuItem>
                                                )
                                            })}
                                            </Select>
                                        :
                                        itm.key==='prioridad' 
                                        ?
                                            <Select filterable positionFixed 
                                                // disabled={!isEditable} 
                                                placeholder={'Seleccione...'}
                                                onChange={handlePrioridadSelectChange}
                                                onKeyDown={handlePrioridadonKeyDown}
                                                value={prioridadSelectedValue || ''}
                                                selectedValue={prioridadSelectedValue || ''}
                                            >
                                            {prioridadNota.tipo.map(e => {
                                                return (
                                                <MenuItem key={e.id} value={e.name}>
                                                    {e.name}
                                                </MenuItem>
                                                )
                                            })}
                                            </Select>
                                        :
                                        itm.key==='areanota' 
                                        ?
                                            <Select filterable positionFixed 
                                                // disabled={!isEditable} 
                                                placeholder={'Seleccione...'}
                                                onChange={handleAreanotaSelectChange}
                                                onKeyDown={handleAreanotaonKeyDown}
                                                value={areanotaSelectedValue || ''}
                                                selectedValue={areanotaSelectedValue || ''}
                                            >
                                            {areadeTrabajoNotas.tipo.map(e => {
                                                return (
                                                <MenuItem key={e.id} value={e.name}>
                                                    {e.name}
                                                </MenuItem>
                                                )
                                            })}
                                            </Select>
                                        :
                                        itm.key==='estadonota' 
                                        ?
                                            <Select filterable positionFixed 
                                                // disabled={!isEditable} 
                                                placeholder={'Seleccione...'}
                                                onChange={handleEstadonotaSelectChange}
                                                onKeyDown={handleEstadonotaonKeyDown}
                                                value={estadonotaSelectedValue || ''}
                                                selectedValue={estadonotaSelectedValue || ''}
                                            >
                                            {estadoNotas.tipo.map(e => {
                                                return (
                                                <MenuItem key={e.id} value={e.name}>
                                                    {e.name}
                                                </MenuItem>
                                                )
                                            })}
                                            </Select>
                                        :
                                        itm.key==='fechaplazo' 
                                        ?
                                            <DatePicker 
                                                placeholder="Fecha límite"
                                                id="fechaplazo"
                                                // style={{backgroundColor:(index % 2 === 0) ? '#f8f8f8 !important': '#fff !important'}}
                                                date={fechaPlazo}
                                                focused={fechaPlazoPickerFocused}
                                                onDateChange={onFechaPlazoChange}
                                                onFocusChange={onFechaPlazoFocusChange}
                                                numberOfMonths={1}
                                                // disabled={!isEditable}
                                                readOnly={true}
                                                hideInputIcon
                                                disableScroll={false}
                                                displayFormat={'YYYY-MM-DD'} // 'MMM DD YYYY'
                                                // isOutsideRange={() => {}}
                                                isOutsideRange={fechaPlazo => fechaPlazo.isBefore(minDate) || fechaPlazo.isAfter(maxDate)}
                                                monthYearSelectionMode="MONTH_YEAR"
                                                yearSelectDates={{"startYear" : 2020, "endYear" : new moment().year() + 1}}
                                                // displayFormat={moment.localeData().longDateFormat('L')}
                                                // appendToBody={true}
                                                // withPortal={true}
                                            />
                                        :
                                        itm.key==='relacioncon'
                                        ?
                                        <Search positionFixed className="val-search-benef-input"
                                            virtualized
                                            virtualizedMenuWidth={250}
                                            virtualizedRowHeight={75}
                                            placeholder={'Buscar Beneficiario...'}
                                            searchIcon={false}
                                            inputValue={inputBeneficiarioValue || itm.value}
                                            selectedItem={selectedBeneficiarioItem}
                                            onChange={searchBeneficiarioChanged}
                                            onUserAction={onBeneficiarioUserAction}
                                            onRequestClear={clearBeneficiarioSearch}
                                            >
                                            {listBenef.map((item,ix) => {
                                                const fotoSelBenef = (item.foto==='' || item.foto===null) ? process.env.PUBLIC_URL + '/imgs/team/usuario.jpg' : config.ECASE_URL +'user_images/'+ item.foto; 
                                                return ( 
                                                <ListItem
                                                    key={item.docId}
                                                    value={item.docId}
                                                    label={capitalize(item.nombres +' '+ item.apellidos, true)}
                                                    // leftNode={<UserIcon size={32} />}
                                                    leftNode={fotoSelBenef ? <img src={fotoSelBenef} width={32} alt=" " /> : <UserIcon size={32} />}
                                                >
                                                    <ListItemTitle>{capitalize(item.nombres +' '+ item.apellidos, true)}</ListItemTitle>
                                                    <ListItemSubtitle>{item.actividad +' '+ item.categoria}</ListItemSubtitle>
                                                </ListItem>
                                                )
                                            })}
                                        </Search>
                                        :
                                        itm.key==='responsables'
                                        ?
                                        <MultiSelect positionFixed 
                                            // disabled={!isEditable} 
                                            // renderValue={renderResponsablesValue}
                                            onChange={handleResponsablesMultiSelectChange}
                                            selectedValues={selectedResponsablesValues || []}
                                            closeOnSelect={true}
                                        >
                                            {listUsers.map((f,i) => {
                                                const fotoUser = (f.foto==='' ||f.foto===null) ? process.env.PUBLIC_URL + '/imgs/team/usuario.jpg' : process.env.PUBLIC_URL +'/imgs/team/'+ f.foto; 
                                                return ( f.activo==='1' &&
                                                    <ListItem
                                                        key={f.id}
                                                        value={f.docId}
                                                        label={f.usuario.toLowerCase()}
                                                        leftNode={fotoUser ? <img src={fotoUser} width={32} alt=" " /> : <UserIcon size={32} />}
                                                    >
                                                        <ListItemTitle>{capitalize(f.nombre, true)}</ListItemTitle>
                                                        <ListItemSubtitle>{f.areatrabajo}</ListItemSubtitle>
                                                    </ListItem>
                                                // return <MenuItem key={f.id} value={f.docId}>{capitalize(f.nombre, true)}</MenuItem>
                                                )
                                            })}
                                        </MultiSelect>
                                        :
                                        <ContentEditable
											id={'nota_'+itm.key}
											html={itm.value || ''} // innerHTML of the editable div
											className='itemContainer-value-long'
											// disabled={!isEditable}      // use true to disable editing
											onPaste={pastePlainText} // En caso se copie y pegue de otra pagina de internet
											onChange={handleChange} // handle innerHTML change
											onKeyPress={disableNewlines}  
											onFocus={highlightAll}
											onBlur={handleBlur}
											tagName='div' // Use a custom HTML tag (uses a div by default)
										/>
                                    }

                                    </div>
                                </div>)
                            })
                        }

                        <div className="nota-observaciones-comentarios">
                            <div className="nota-texto-observaciones-title">Notificación u observación:</div>
                            <div className="nota-texto-observaciones"> 
                                <Editor 
                                    placeholder={'Escriba la notificación...'}
                                    editorState={editorNotaState}
                                    // wrapperClassName="wrapper-class"
                                    editorClassName="nota-comntario-editor"
                                    // toolbarClassName="toolbar-class"
                                    localization={{ locale: 'es', }}
                                    onEditorStateChange={onNotaEditorStateChange}
                                />
                                {/* <ContentEditable placeholder={'Escriba la notificación...'}
                                    id={'notaagregar_nota'}
                                    // html={nota.current}
                                    html={notaSelected.nota || ''} // innerHTML of the editable div
                                    // disabled={!isEditable}        // use true to disable editing
                                    onPaste={pastePlainText}
                                    onChange={handleChange} // handle innerHTML change
                                    onFocus={highlightAll}
                                    onBlur={handleBlur}
                                    tagName='div' // Use a custom HTML tag (uses a div by default)
                                    style={{ "minHeight": "100px", "border": "1px dashed", backgroundColor:'#fdfdca', padding:'0px 8px' }}
                                /> */}
                            </div>
                        </div>
                        
                        <div className="nota-observaciones-comentarios">
                            <div className="nota-texto-observaciones-title">Comentario:</div>
                            <div className="nota-texto-observaciones"> 
                                <Editor 
                                    placeholder={'Escriba un comentario...'}
                                    editorState={editorComentarioState}
                                    // wrapperClassName="wrapper-class"
                                    // toolbarClassName="toolbar-class"
                                    editorClassName="nota-comntario-editor"
                                    localization={{ locale: 'es', }}
                                    onEditorStateChange={onComentarioEditorStateChange}
                                />
                                {/* <ContentEditable
                                    id={'notaagregar_comentarios'}
                                    html={notaSelected.comentarios || ''} // innerHTML of the editable div
                                    // disabled={!isEditable}        // use true to disable editing
                                    onPaste={pastePlainText}
                                    onChange={handleChange} // handle innerHTML change
                                    onFocus={highlightAll}
                                    onBlur={handleBlur}
                                    tagName='div' // Use a custom HTML tag (uses a div by default)
                                    style={{ "minHeight": "40px", "border": "1px dashed", padding:'0px 8px', marginBottom:'5px'  }}
                                /> */}
                            </div>
                        </div>

                    </div>

                    <div className="nota-edit-botones">
                        <div className="nota-edit-botones-btn"> 
                            <Button iconPosition="before" onClick={saveNota} icon={<SaveIcon size={16} />}
                            >Guardar</Button>
                        </div>
                        <div className="nota-edit-botones-btn"> 
                            <Button iconPosition="before" onClick={enviarNota} icon={<WhatsappIcon size={16} />}
                            >Enviar</Button>
                        </div>
                        <div className="nota-edit-botones-btn"> 
                            <Button iconPosition="before" onClick={(evt) => cancelNota(notaSelected.id)} icon={<ResetIcon size={16} />}
                            >Cancelar</Button>
                        </div>
                    </div>
                </AgregarNota>
            }
            {/* EDIT NOTE */}
            {isDisplayEditNota && <EditNota>
                    <div className="nota-editform-title">Editar Nota</div>
                    <div className="nota-editform-editar">
                        {error && <div style={{color: 'red', width: '100%'}}>
                                <Alert red showIcon style={{minWidth: '100%', marginBottom: '10px'}}
                                    showCloseLabel 
                                    onClose={() => setError(null)}>
                                    <AlertMessage>
                                        Error:{' '}
                                        <strong>{error}</strong>
                                    </AlertMessage>
                                </Alert>
                            </div> 
                        }
                        {/* ITEMS */}
                        { itemsNota && 
                            itemsNota.map((itm, index) => {
                                return (<div key={index} className="itemContainer">
                                    <div className="itemContainer-attr" 
                                        style={{backgroundColor:(index % 2 === 0) ? '#f8f8f8': '#fff'}}
                                    >{itm.label}</div>
                                    <div className="itemContainer-value" 
                                        style={{backgroundColor:(index % 2 === 0) ? '#f8f8f8': '#fff'}}
                                    >
                                    {   
                                        (itm.key==='id' || itm.key==='fechacreacion' || itm.key==='creadapor')
                                        ?
										<ContentEditable
                                            id={'nota_'+itm.key}
                                            style={{color:'#ccc'}}
											html={itm.value || ''} // innerHTML of the editable div
											className='itemContainer-value-long'
											disabled={true}      // use true to disable editing
											tagName='div' // Use a custom HTML tag (uses a div by default)
										/>
                                        :
                                        itm.key==='tiponota' 
                                        ?
                                            <Select filterable positionFixed 
                                                disabled={!isEditable} 
                                                placeholder={'Seleccione...'}
                                                onChange={handleTipoNotaSelectChange}
                                                onKeyDown={handleTipoNotaonKeyDown}
                                                value={tiponotaSelectedValue || ''}
                                                selectedValue={tiponotaSelectedValue || ''}
                                            >
                                            {tipodeNota.tipo.map(e => {
                                                return (
                                                <MenuItem key={e.id} value={e.name}>
                                                    {e.name}
                                                </MenuItem>
                                                )
                                            })}
                                            </Select>
                                        :
                                        itm.key==='prioridad' 
                                        ?
                                            <Select filterable positionFixed 
                                                disabled={!isEditable} 
                                                placeholder={'Seleccione...'}
                                                onChange={handlePrioridadSelectChange}
                                                onKeyDown={handlePrioridadonKeyDown}
                                                value={prioridadSelectedValue || ''}
                                                selectedValue={prioridadSelectedValue || ''}
                                            >
                                            {prioridadNota.tipo.map(e => {
                                                return (
                                                <MenuItem key={e.id} value={e.name}>
                                                    {e.name}
                                                </MenuItem>
                                                )
                                            })}
                                            </Select>
                                        :
                                        itm.key==='areanota' 
                                        ?
                                            <Select filterable positionFixed 
                                                disabled={!isEditable} 
                                                placeholder={'Seleccione...'}
                                                onChange={handleAreanotaSelectChange}
                                                onKeyDown={handleAreanotaonKeyDown}
                                                value={areanotaSelectedValue || ''}
                                                selectedValue={areanotaSelectedValue || ''}
                                            >
                                            {areadeTrabajoNotas.tipo.map(e => {
                                                return (
                                                <MenuItem key={e.id} value={e.name}>
                                                    {e.name}
                                                </MenuItem>
                                                )
                                            })}
                                            </Select>
                                        :
                                        itm.key==='estadonota' 
                                        ?
                                            <Select filterable positionFixed 
                                                disabled={!isEditable} 
                                                placeholder={'Seleccione...'}
                                                onChange={handleEstadonotaSelectChange}
                                                onKeyDown={handleEstadonotaonKeyDown}
                                                value={estadonotaSelectedValue || ''}
                                                selectedValue={estadonotaSelectedValue || ''}
                                            >
                                            {estadoNotas.tipo.map(e => {
                                                return (
                                                <MenuItem key={e.id} value={e.name}>
                                                    {e.name}
                                                </MenuItem>
                                                )
                                            })}
                                            </Select>
                                        :
                                        itm.key==='fechaplazo' 
                                        ?
                                            <DatePicker 
                                                placeholder="Fecha límite"
                                                id="fechaplazo"
                                                // style={{backgroundColor:(index % 2 === 0) ? '#f8f8f8 !important': '#fff !important'}}
                                                date={fechaPlazo}
                                                focused={fechaPlazoPickerFocused}
                                                onDateChange={onFechaPlazoChange}
                                                onFocusChange={onFechaPlazoFocusChange}
                                                numberOfMonths={1}
                                                disabled={!isEditable}
                                                readOnly={true}
                                                hideInputIcon
                                                disableScroll={false}
                                                displayFormat={'YYYY-MM-DD'} // 'MMM DD YYYY'
                                                // isOutsideRange={() => {}}
                                                isOutsideRange={fechaPlazo => fechaPlazo.isBefore(minDate) || fechaPlazo.isAfter(maxDate)}
                                                monthYearSelectionMode="MONTH_YEAR"
                                                yearSelectDates={{"startYear" : 2000, "endYear" : new moment().year() + 1}}
                                                // displayFormat={moment.localeData().longDateFormat('L')}
                                                // appendToBody={true}
                                                // withPortal={true}
                                            />
                                        :
                                        itm.key==='relacioncon'
                                        ?
                                        <Search positionFixed className="val-search-benef-input"
                                            disabled={!isEditable} 
                                            virtualized
                                            virtualizedMenuWidth={250}
                                            virtualizedRowHeight={75}
                                            placeholder={'Buscar Beneficiario...'}
                                            searchIcon={false}
                                            inputValue={inputBeneficiarioValue || itm.value}
                                            selectedItem={selectedBeneficiarioItem}
                                            onChange={searchBeneficiarioChanged}
                                            onUserAction={onBeneficiarioUserAction}
                                            onRequestClear={clearBeneficiarioSearch}
                                            >
                                            {listBenef.map((item,ix) => {
                                                const fotoSelBenef = (item.foto==='' || item.foto===null) ? process.env.PUBLIC_URL + '/imgs/team/usuario.jpg' : config.ECASE_URL +'user_images/'+ item.foto; 
                                                return ( 
                                                <ListItem
                                                    key={item.docId}
                                                    value={item.docId}
                                                    label={capitalize(item.nombres +' '+ item.apellidos, true)}
                                                    // leftNode={<UserIcon size={32} />}
                                                    leftNode={fotoSelBenef ? <img src={fotoSelBenef} width={32} alt=" " /> : <UserIcon size={32} />}
                                                >
                                                    <ListItemTitle>{capitalize(item.nombres +' '+ item.apellidos, true)}</ListItemTitle>
                                                    <ListItemSubtitle>{item.actividad +' '+ item.categoria}</ListItemSubtitle>
                                                </ListItem>
                                                )
                                            })}
                                        </Search>
                                        :
                                        itm.key==='responsables'
                                        ?
                                        <MultiSelect positionFixed 
                                            disabled={!isEditable} 
                                            // renderValue={renderResponsablesValue}
                                            onChange={handleResponsablesMultiSelectChange}
                                            selectedValues={selectedResponsablesValues || []}
                                            closeOnSelect={true}
                                        >
                                            {listUsers.map((f,i) => {
                                                const fotoUser = (f.foto==='' ||f.foto===null) ? process.env.PUBLIC_URL + '/imgs/team/usuario.jpg' : process.env.PUBLIC_URL +'/imgs/team/'+ f.foto; 
                                                return ( f.activo==='1' &&
                                                    <ListItem
                                                        key={f.id}
                                                        value={f.docId}
                                                        label={f.usuario.toLowerCase()}
                                                        leftNode={fotoUser ? <img src={fotoUser} width={32} alt=" " /> : <UserIcon size={32} />}
                                                    >
                                                        <ListItemTitle>{capitalize(f.nombre, true)}</ListItemTitle>
                                                        <ListItemSubtitle>{f.areatrabajo}</ListItemSubtitle>
                                                    </ListItem>
                                                // return <MenuItem key={f.id} value={f.docId}>{capitalize(f.nombre, true)}</MenuItem>
                                                )
                                            })}
                                        </MultiSelect>
                                        :
                                        <ContentEditable
											id={'nota_'+itm.key}
											html={itm.value || ''} // innerHTML of the editable div
											className='itemContainer-value-long'
											disabled={!isEditable}      // use true to disable editing
											onPaste={pastePlainText} // En caso se copie y pegue de otra pagina de internet
											onChange={handleChange} // handle innerHTML change
											onKeyPress={disableNewlines}  
											onFocus={highlightAll}
											onBlur={handleBlur}
											tagName='div' // Use a custom HTML tag (uses a div by default)
										/>
                                    }

                                    </div>
                                </div>)
                            })
                        }

                        <div className="nota-observaciones-comentarios">
                            <div className="nota-texto-observaciones-title">Notificación u observación:</div>
                            <div className="nota-texto-observaciones"> 
                            { isEditable ?
                                <Editor placeholder={'Escriba la notificación...'}
                                    editorState={editorNotaState}
                                    // wrapperClassName="wrapper-class"
                                    editorClassName="nota-comntario-editor"
                                    // toolbarClassName="toolbar-class"
                                    localization={{ locale: 'es', }}
                                    onEditorStateChange={onNotaEditorStateChange}
                                    // mention={{
                                    //     separator: ' ',
                                    //     trigger: '@',
                                    //     suggestions: [
                                    //       { text: 'FUNDACION', value: 'FUNDACION', url: 'http://fundacionclubfarallones.org/portal/' },
                                    //       { text: 'GRB', value: 'GRB', url: 'http://grb/fundacionclubfarallones.org/' },
                                    //       { text: 'ECASE', value: 'ECASE', url: 'http://fundacionclubfarallones.org/investigacion/ECASE/beta/' },
                                    //       { text: 'EGIRON', value: 'egiron', url: 'egiron' },
                                    //     ],
                                    // }}
                                />
                                :
                                    <ContentEditable
                                        id={'notaedit_nota'}
                                        // innerRef={nota}
                                        // html={nota.current}
                                        html={notaSelected.nota || ''} // innerHTML of the editable div
                                        // disabled={users.user.docId===notaSelected.creadapor ? false : true}        // use true to disable editing
                                        disabled={true} 
                                        onPaste={pastePlainText}
                                        onChange={handleChange} // handle innerHTML change
                                        onFocus={highlightAll}
                                        onBlur={handleBlur}
                                        tagName='div' // Use a custom HTML tag (uses a div by default)
                                        style={{ "minHeight": "100px", "border": "1px dashed", backgroundColor:'#fdfdca', padding:'0px 8px' }}
                                    />
                                }
                            </div>
                        </div>
                        
                        <div className="nota-observaciones-comentarios">
                            <div className="nota-texto-observaciones-title">Comentario:</div>
                            <div className="nota-texto-observaciones"> 
                                {/* {isEditable ? */}
                                    <Editor placeholder={'Escriba un comentario...'}
                                        // toolbarHidden
                                        // toolbarOnFocus
                                        editorState={editorComentarioState}
                                        // wrapperClassName="wrapper-class"
                                        editorClassName="nota-comntario-editor"
                                        // toolbarClassName="toolbar-class"
                                        toolbar={{
                                            inline: { inDropdown: true },
                                            list: { inDropdown: true },
                                            textAlign: { inDropdown: true },
                                            link: { inDropdown: true },
                                            embedded: { inDropdown: true },
                                            emoji: { inDropdown: true },
                                            image: { inDropdown: true },
                                            remove: { inDropdown: true },
                                            history: { inDropdown: true },
                                        }}
                                        localization={{ locale: 'es', }}
                                        onEditorStateChange={onComentarioEditorStateChange}
                                    />
                                    {/* :
                                    <ContentEditable
                                        id={'notaedit_comentarios'}
                                        html={notaSelected.comentarios || ''} // innerHTML of the editable div
                                        disabled={true}        // use true to disable editing
                                        // onPaste={pastePlainText}
                                        // onChange={handleChange} // handle innerHTML change
                                        // onFocus={highlightAll}
                                        // onBlur={handleBlur}
                                        tagName='div' // Use a custom HTML tag (uses a div by default)
                                        style={{ "minHeight": "40px", "border": "1px dashed", padding:'0px 8px', marginBottom:'5px'  }}
                                    />
                                } */}
                            </div>
                        </div>

                    </div>
                    <div className="nota-edit-botones">
                        <div className="nota-edit-botones-btn"> 
                            <Button iconPosition="before" onClick={saveNota} icon={<SaveIcon size={16} />}
                            >Guardar cambios</Button>
                        </div>
                        {isEditable && <div className="nota-edit-botones-btn"> 
                            <Button iconPosition="before" onClick={(evt) => eliminarNota(evt, notaSelected.id)} icon={<TrashIcon size={16} />}
                            >Eliminar</Button>
                        </div>}
                        {/* <div className="nota-edit-botones-btn"> 
                            <Button iconPosition="before" onClick={(evt) => cancelNota(notaSelected.id)} icon={<ResetIcon size={16} />}
                            >Cancelar</Button>
                        </div> */}
                    </div>
                </EditNota>
            }

            {resultQueryNotas && resultQueryNotas.datos.length<0 && <Loader color="#009937" text="Cargando Notifiaciones..."/>}


            {!isDisplayEditNota && !isDisplayAgregarNota && 
                <div className='notas-fundacion-lista'>
                    <div className="notas-fundacion-toolbar-container">
                        <div className="notas-fundacion-toolbar">
                            <div className="notas-fundacion-toolbar-left">
                                <div className="notas-fundacion-toolbarLeft-tools">
                                    <div className="notas-fundacion-toolbarLeft-btn">
                                        <Button inline small iconPosition="before" 
                                            onClick={onBtnAgregarNota}
                                            icon={<PlusIcon size={16} />}
                                        >
                                            Agregar
                                        </Button>
                                    </div>
                                    <div className="notas-fundacion-toolbarLeft-btn">
                                        <Button inline small iconPosition="before" 
                                            onClick={onBtnFiltrarNota}
                                            icon={<FilterIcon size={16} />}
                                        >
                                            Filtrar
                                        </Button>
                                    </div>
                                </div>
                            </div>
                            <div className="notas-fundacion-toolbar-right">
                                <div className="notas-fundacion-toolbarRight-tools">
                                    {/* <div className="notas-fundacion-toolbarLeft-btn">
                                        <Button inline small iconPosition="before" 
                                            onClick={onBtnAgregarNota}
                                            icon={<CalendarIcon size={16} />}
                                        >
                                            Fecha
                                        </Button>
                                    </div> */}
                                    <div className="notas-fundacion-toolbarLeft-btn">
                                        <div className="notas-fundacion-toolbarLeft-btn-Search">
                                            <TextField style={{display:'inline-flex'}}
                                                value={searchText}
                                                onChange={e => onSearchChange(e.target.value)}
                                                onRequestClear={onSearchRequestClear}
                                                // onBlur={(e) => handleSearchBlur(e.target.value)}
                                                onKeyPress={handleSearchKeyPress}
                                                search
                                                placeholder="Buscar..."
                                            />
                                            <div className="notas-fundacion-toolbarLeft-btnBuscar"
                                                onClick={buscarTexto}
                                                >Buscar</div>
                                        </div>
                                        <div className="notas-fundacion-toolbarLeft-btn-SearchSmall">
                                            <Button inline small iconPosition="before" 
                                                onClick={toggleBuscarTool}
                                                icon={<SearchIcon size={16} />}
                                            >
                                                Buscar
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Espacio para el Filtro y opciones para Mobiles */}
                    <div className="notas-fundacion-options-container">
                        <div id='buscar-tool-mobile' className="notas-fundacion-toolbarLeft-btn-Search-mobile">
                            <TextField style={{display:'inline-flex'}}
                                value={searchText}
                                onChange={e => onSearchChange(e.target.value)}
                                onRequestClear={onSearchRequestClear}
                                onKeyPress={handleSearchKeyPress}
                                search
                                placeholder="Buscar..."
                            />
                            <div className="notas-fundacion-toolbarLeft-btnBuscar"
                                onClick={buscarTexto}
                                >Buscar</div>
                        </div>
                        
                        {/* FILTROS */}
                        <div id='filtro-tool-mobile' className="notas-fundacion-lista-filtroMobile">
                            <div className="notas-fundacion-lista-filtro">
                                <div className="notas-fundacion-lista-optfiltros">
                                    <div className="notas-fundacion-lista-optfiltros-toolContainer">
                                        <div className="notas-fundacion-lista-optfiltros-tool">
                                            <div className="notas-fundacion-lista-optfiltros-toolname">
                                                Estado:
                                            </div>
                                            <Select filterable positionFixed style={{width:'120px'}}
                                                // disabled={!isEditable} 
                                                placeholder={'Estado...'}
                                                onChange={handleEstadonotaFilterSelectChange}
                                                onKeyDown={handleEstadonotaFilteronKeyDown}
                                                value={estadonotaFilterSelectedValue || ''}
                                                selectedValue={estadonotaFilterSelectedValue || ''}
                                            >
                                            {estadoNotas.tipo.map(e => {
                                                return (
                                                <MenuItem key={e.id} value={e.name}>
                                                    {e.name}
                                                </MenuItem>
                                                )
                                            })}
                                            </Select>
                                        </div>
                                    </div>
                                    <div className="notas-fundacion-lista-optfiltros-toolContainer">
                                        <div className="notas-fundacion-lista-optfiltros-tool">
                                            <div className="notas-fundacion-lista-optfiltros-toolname">
                                                Prioridad:
                                            </div>
                                            <Select filterable positionFixed style={{width:'120px'}}
                                                // disabled={!isEditable} 
                                                placeholder={'Prioridad...'}
                                                onChange={handlePrioridadFilterSelectChange}
                                                onKeyDown={handlePrioridadFilteronKeyDown}
                                                value={prioridadFilterSelectedValue || ''}
                                                selectedValue={prioridadFilterSelectedValue || ''}
                                            >
                                            {prioridadNota.tipo.map(e => {
                                                return (
                                                <MenuItem key={e.id} value={e.name}>
                                                    {e.name}
                                                </MenuItem>
                                                )
                                            })}
                                            </Select>
                                        </div>
                                    </div>
                                    <div className="notas-fundacion-lista-optfiltros-toolContainer">
                                        <div className="notas-fundacion-lista-optfiltros-tool">
                                            <div className="notas-fundacion-lista-optfiltros-toolname">
                                                Area de Trabajo:
                                            </div>
                                            <Select filterable positionFixed 
                                                // disabled={!isEditable} 
                                                placeholder={'Area...'}
                                                onChange={handleAreanotaFilterSelectChange}
                                                onKeyDown={handleAreanotaFilteronKeyDown}
                                                value={areanotaFilterSelectedValue || ''}
                                                selectedValue={areanotaFilterSelectedValue || ''}
                                            >
                                            {areadeTrabajoNotas.tipo.map(e => {
                                                return (
                                                <MenuItem key={e.id} value={e.name}>
                                                    {e.name}
                                                </MenuItem>
                                                )
                                            })}
                                            </Select>
                                        </div>
                                    </div>
                                    <div className="notas-fundacion-lista-optfiltros-toolContainer">
                                        <div className="notas-fundacion-lista-optfiltros-tool">
                                            <div className="notas-fundacion-lista-optfiltros-toolname">
                                                Tipo de Nota:
                                            </div>
                                            <Select filterable positionFixed 
                                                // disabled={!isEditable} 
                                                placeholder={'Tipo Nota...'}
                                                onChange={handleTipoNotaFilterSelectChange}
                                                onKeyDown={handleTipoNotaFilteronKeyDown}
                                                value={tiponotaFilterSelectedValue || ''}
                                                selectedValue={tiponotaFilterSelectedValue || ''}
                                            >
                                            {tipodeNota.tipo.map(e => {
                                                return (
                                                <MenuItem key={e.id} value={e.name}>
                                                    {e.name}
                                                </MenuItem>
                                                )
                                            })}
                                            </Select>
                                        </div>
                                    </div>
                                    <div className="notas-fundacion-lista-optfiltros-toolContainer">
                                        <div className="notas-fundacion-lista-optfiltros-tool">
                                            <div className="notas-fundacion-lista-optfiltros-toolname">
                                                Creada por:
                                            </div>
                                            <Select filterable positionFixed 
                                                // disabled={!isEditable} 
                                                placeholder={'Creada por...'}
                                                onChange={handleCreadaporFilterSelectChange}
                                                onKeyDown={handleCreadaporFilteronKeyDown}
                                                value={creadaporFilterSelectedValue || ''}
                                                selectedValue={creadaporFilterSelectedValue || ''}
                                            >
                                            {listUsers.map(e => {
                                                return (
                                                <MenuItem key={e.id} value={e.docId}>
                                                    {capitalize(e.nombre, true)}
                                                </MenuItem>
                                                )
                                            })}
                                            </Select>
                                        </div>
                                    </div>
                                    <div className="notas-fundacion-lista-optfiltros-toolContainer">
                                        <div className="notas-fundacion-lista-optfiltros-tool">
                                            <div className="notas-fundacion-lista-optfiltros-toolname">
                                                Relacionada con:
                                            </div>
                                            {/* <div onKeyDown={handleRelacionconFilteronKeyDown} className=""> */}
                                                <Select id='notas-fundacion-selectRelacionCon' filterable positionFixed 
                                                    // autoSelect={true}
                                                    virtualized
                                                    virtualizedMenuWidth={200}
                                                    virtualizedRowHeight={40}
                                                    // disabled={!isEditable} 
                                                    placeholder={'Relacionada con...'}
                                                    onChange={handleRelacionconFilterSelectChange}
                                                    onBlur={handleRelacionconFilteronBlur}
                                                    // onFocus={handleRelacionconFilteronBlur}
                                                    // onKeyDown={handleRelacionconFilteronKeyDown}
                                                    selectedValue={relacionconFilterSelectedValue || ''}
                                                    // selectedItem={relacionconFilterSelectedValue || ''}
                                                    // value={relacionconFilterSelectedValue || ''}
                                                    // value={relacionconFilterSelectedValue===null ? undefined : relacionconFilterSelectedValue}
                                                >
                                                {listBenef.map(e => {
                                                    return (
                                                    <MenuItem key={e.id} value={e.docId}>
                                                        {capitalize(e.nombres+' '+e.apellidos, true)}
                                                    </MenuItem>
                                                    )
                                                })}
                                                </Select>
                                            {/* </div> */}
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="nota-edit-botones-btnRemoveFilter"> 
                                <Button iconPosition="before" onClick={clearFilters} icon={<ResetIcon size={16} />}
                                >Quitar Filtros</Button>
                            </div>
                        </div>
                        
                    </div>


                    {/* <h2>Cantidad de Registros: {resultQueryNotas.stats.count || 0}</h2> */}
                    { resultQueryNotas && resultQueryNotas.datos.length>0 ? 
                        <div className='notas-fundacion-tablaContainer'>
                            <div className='notas-fundacion-tabla'>
                            { resultQueryNotas.datos.map((itm, index) => {
                                // const foto_creador = itm.foto==='' ? process.env.PUBLIC_URL + '/imgs/team/usuario.jpg' : config.ECASE_URL +'user_images/'+ Beneficiario.foto; 
                                const foto_creador = (itm.foto==='' || itm.foto===null) ? process.env.PUBLIC_URL + '/imgs/team/usuario.jpg' : process.env.PUBLIC_URL + '/imgs/team/'+ itm.foto; 
                                let fotobenef = (itm.fotobenef==='' || itm.fotobenef===null) ? process.env.PUBLIC_URL + '/imgs/team/usuario.jpg' : config.ECASE_URL +'user_images/'+ itm.fotobenef;
                                if (itm.relacioncon==='' || itm.relacioncon==='FUNDACIÓN'){
                                    fotobenef = process.env.PUBLIC_URL + '/imgs/team/fundacion.png';
                                }
                                const benefnombre = (itm.benefnombres==='' || itm.benefnombres===null) ? '' : capitalize(itm.benefnombres,true);
                                const benefapellidos = (itm.benefapellidos==='' || itm.benefapellidos===null) ? '' : capitalize(itm.benefapellidos,true);
                                const fullName = (itm.nombre==='' || itm.nombre===null) ? '' : capitalize(itm.nombre, true);
                                const nombre = fullName ? fullName.split(' ')[0] +' '+ fullName.split(' ')[1] : '';
                                // const iniciales = getFirstWords(fullName);
                                const responsables = itm.responsables ? getDatabyResponsableId(itm.responsables) : '';
                                const fechacreacion = itm.fechacreacion ? moment(itm.fechacreacion, 'YYYY-MM-DD').format('YYYY-MMM-DD').split('-') : '';
                                return (<div key={index}  className="notas-fundacion-lista-row" style={{backgroundColor:(index % 2 === 0) ? '#fff': '#fff'}}>
                                    
                                    {/* <div className="notas-fundacion-lista-cell">{itm.id}</div> */}
                                    <div className="notas-fundacion-header" onClick={(evt) => onBtnEditNota(evt, itm.id, index)}>
                                        <div className="notas-fundacion-lista-cell-inline">
                                            <div className="notas-fundacion-lista-cell-inline">
                                                <div className="notas-fundacion-lista-fechacreacion">
                                                    <div className="notas-fundacion-lista-fechacreacion-m">{fechacreacion[1].toUpperCase()}</div>
                                                    <div className="notas-fundacion-lista-fechacreacion-d">{fechacreacion[2]}</div>
                                                    <div className="notas-fundacion-lista-fechacreacion-y">{fechacreacion[0]}</div>
                                                </div>
                                            </div>

                                            <div className="notas-fundacion-lista-cell-inline">
                                                <div className="notas-fundacion-lista-creadorContainer">
                                                    <div className="notas-fundacion-lista-tiponotificacion">
                                                        <div className="notas-fundacion-lista-areanota">{capitalize(itm.areanota, true)}</div>
                                                        <div className="notas-fundacion-lista-tiponota">{itm.tiponota}</div>
                                                        <div className="notas-fundacion-lista-prioridad">
                                                            {itm.prioridad.toLowerCase()==='alta' && <div className="notas-fundacion-lista-prioridad notas-fundacion-lista-prioridad-alta">{itm.prioridad}</div>}
                                                            {itm.prioridad.toLowerCase()==='media' && <div className="notas-fundacion-lista-prioridad notas-fundacion-lista-prioridad-media">{itm.prioridad}</div>}
                                                            {itm.prioridad.toLowerCase()==='baja' && <div className="notas-fundacion-lista-prioridad notas-fundacion-lista-prioridad-baja">{itm.prioridad}</div>}
                                                        </div>
                                                    </div>
                                                    
                                                    <div className="notas-fundacion-lista-creador">
                                                        <div className="notas-fundacion-lista-creadapor-foto"><img src={foto_creador} alt=' ' /></div>
                                                        <div className="notas-fundacion-lista-creadapor">{nombre}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="notas-fundacion-lista-header-right">
                                            <div className="notas-fundacion-lista-dirigidoa">
                                                <div className="notas-fundacion-lista-relacioncon-nombre"><strong>{benefnombre}</strong> {benefapellidos}</div>
                                                <div className="notas-fundacion-lista-relacioncon-foto"><img src={fotobenef} alt=' ' /></div>
                                            </div>
                                            <div className="notas-fundacion-lista-relacioncon">{itm.relacioncon}</div>
                                            { itm.estadonota.toLowerCase()==='finalizada' && <div className="notas-fundacion-lista-estadonota estadonota-finalizada">{itm.estadonota}</div>}
                                            { itm.estadonota.toLowerCase()==='pendiente' && <div className="notas-fundacion-lista-estadonota estadonota-pendiente">{itm.estadonota}</div>}
                                            { itm.estadonota.toLowerCase()==='cancelada' && <div className="notas-fundacion-lista-estadonota estadonota-cancelada">{itm.estadonota}</div>}
                                            { itm.estadonota.toLowerCase()==='aceptada' && <div className="notas-fundacion-lista-estadonota estadonota-aceptada">{itm.estadonota}</div>}
                                            { itm.estadonota.toLowerCase()==='revisada' && <div className="notas-fundacion-lista-estadonota estadonota-revisada">{itm.estadonota}</div>}
                                            { itm.estadonota.toLowerCase()==='caducada' && <div className="notas-fundacion-lista-estadonota estadonota-caducada">{itm.estadonota}</div>}
                                        </div>
                                    </div>
                                    
                                    <div className="notas-fundacion-lista-cell">
                                        <div id={'nota_'+index} className="notas-fundacion-lista-cell-nota multi-line-ellipsis" aria-haspopup="true"
                                            onClick={() => showNota(index)} >
                                                <ContentEditable
                                                    id={'notificacion_'+index}
                                                    html={itm.nota || ''} // innerHTML of the editable div
                                                    disabled={true}        // use true to disable editing
                                                    tagName='div' // Use a custom HTML tag (uses a div by default)
                                                    // style={{ "minHeight": "40px", "border": "1px dashed", padding:'0px 8px'  }}
                                                />
                                            </div>
                                    </div>
                                    
                                    <div className="notas-fundacion-lista-notafooter">
                                        <div className="notas-fundacion-lista-responsables">
                                            {responsables && <div className="notas-fundacion-lista-responsables-avatars">
                                                { responsables.map((r,idx) =>{
                                                    const iniciales = getFirstWords(r.nombre.toUpperCase());
                                                    const fotoresponsable = (r.foto==='' || r.foto===null) ? process.env.PUBLIC_URL + '/imgs/team/usuario.jpg' : process.env.PUBLIC_URL + '/imgs/team/'+ r.foto; 
                                                    return (<div key={idx} title={r.nombre} className="notas-fundacion-lista-resp-avatar">
                                                            <div className="notas-fundacion-lista-responsables-foto"><img src={fotoresponsable} alt=' '/></div>
                                                            <div className="notas-fundacion-lista-responsables-iniciales">{iniciales}</div>
                                                        </div>
                                                    )
                                                })
                                                }
                                            </div>}
                                        </div>
                                        <div className="notas-fundacion-lista-fechaplazo">Plazo: {itm.fechaplazo.split(' ')[0]}</div>
                                    </div>

                                    {itm.comentarios!=='' && <div className="notas-fundacion-lista-cell">
                                        <div className="notas-fundacion-lista-comentarios">
                                            <div>Comentarios:</div>
                                            <div id={'comment_'+index} className="multi-line-ellipsis" onClick={() => showComentarios(index)}>
                                                {/* {itm.comentarios} */}
                                                <ContentEditable
                                                    id={'notificacion_'+index}
                                                    html={itm.comentarios || ''} // innerHTML of the editable div
                                                    disabled={true}        // use true to disable editing
                                                    tagName='div' // Use a custom HTML tag (uses a div by default)
                                                    // style={{ "minHeight": "40px", "border": "1px dashed", padding:'0px 8px'  }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    }
                                    
                                    {itm.etiquetas!=='' && <div className="notas-fundacion-lista-cell">  
                                        <div className="notas-fundacion-lista-etiquetas">
                                            {itm.etiquetas.split(',').map((e, idx) =>{
                                                return (
                                                    <div key={idx} className="notas-fundacion-lista-etiquetas-item">{e}</div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                    }

                                    <div className="notas-fundacion-lista-footer">
                                        <div className="notas-fundacion-lista-actualizacion">Última actualización: {itm.actualizacion} por <span>{itm.modificadopor}</span></div>
                                    </div>
                                    
                                </div>)
                                })
                            }
                            </div>
                        
            

            </div>
                    :
                        <div>No hay registros</div>
                    }
                   
                </div>
 

            }
            
            {/* Pagenation */}
            {!isDisplayEditNota && !isDisplayAgregarNota &&  resultQueryNotas && resultQueryNotas.datos.length>0 && 
                <div className="notas-fundacion-tabla-footerContainer">
                    <div className="notas-fundacion-tabla-footer">
                        <div className="notas-fundacion-cantidad">{resultQueryNotas.stats.count || 0} {resultQueryNotas.stats.count>1 ? 'notas' : 'nota'}</div>
                        <div className="notas-fundacion-pagina">Página {currentPage+1} de {totalPages}</div>
                    </div>
                    
                    <Pagination
                        className="pagination pagination-notificaciones"
                        totalPages={totalPages}
                        currentPage={currentPage}
                        showPages={showPages}
                        onChangePage={onPageNumberChange}
                    />
                </div>
            }
            

        </Fragment>
    );
    return (<></>)
}
  
class TableNotificaciones extends React.Component {
    constructor(props) {
      super(props);
      this.state = {};
    }
    
    render() {
    //   var {dataList} = this.state;
      const {containerHeight, containerWidth} = this.props; //{height, width, ...props}
      return (
        <div style={{ width:containerWidth, height:containerHeight }}>
            <ModuleNotificaciones />
        </div>
      );
    }
}

// See react-dimensions for the best way to configure
// https://github.com/digidem/react-dimensions
export default Dimensions({
    elementResize: true,
  
    getHeight: function(element) {
      // return window.innerHeight - 300;
    //   console.log(element.parentElement.clientHeight);
      let heightOffset = 50;
      let heightParent = element.clientHeight;
      return heightParent - heightOffset;
    },
    getWidth: function(element) {
      // var widthOffset = window.innerWidth < 680 ? 52 : 412;
      // return window.innerWidth - widthOffset;
      let widthOffset = 5;
      return element.clientWidth - widthOffset;
    }
})(TableNotificaciones);