// Copyright 2020 Ernesto Girón E.
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//     http://www.apache.org/licenses/LICENSE-2.0
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.​

import { call, put, takeLatest } from "redux-saga/effects";
import { types as configTypes } from "../reducers/prestamos";
import { 
  getListPrestamos,
  getListPrestamosbyQuery,
  actualizarPrestamo,
  crearPrestamo,
  eliminarPrestamo,
  getListHistoPrestamosbyQuery,
  actualizarHistoPrestamo,
  crearHistoPrestamo,
  eliminarHistoPrestamo,
} from "../../utils/prestamos_req";

// WORKERS //
function* fetchPrestamosData(action) {
  try {
    const listPrestamos = yield call(getListPrestamos, action.payload.token);
    if (listPrestamos)
      yield put({
        type: configTypes.PRESTAMO_LIST_LOADED,
        payload: {listPrestamos: listPrestamos}
      });
  } catch (e) {
    console.error("SAGA ERROR: prestamos_req/fetchPrestamosData, ", e);
  }
}
function* fetchPrestamosDatabyQuery(action) {
  try {
    const resultQueryPrestamos = yield call(getListPrestamosbyQuery, action.payload);
    if (resultQueryPrestamos)
      yield put({
        type: configTypes.PRESTAMO_STATS_LOADED,
        payload: {resultQueryPrestamos: resultQueryPrestamos}
      });
  } catch (e) {
    console.error("SAGA ERROR: prestamos_req/fetchPrestamosData, ", e);
  }
}
function* updatePrestamo(action) {
  try {
    const resp = yield call(actualizarPrestamo, action.payload);
    if (resp && resp!=='')
      yield put({
        type: configTypes.PRESTAMO_SERVER_MESSAGE,
        payload: {mensaje: {"prestamos": resp}}
      });
  } catch (e) {
    console.error("SAGA ERROR: prestamos_req/updatePrestamo, ", e);
  }
}
function* insertPrestamo(action) {
  try {
    const resp = yield call(crearPrestamo, action.payload);
    if (resp && resp!=='')
      yield put({
        type: configTypes.PRESTAMO_SERVER_MESSAGE,
        payload: {mensaje: {"prestamos": resp}}
      });
  } catch (e) {
    console.error("SAGA ERROR: prestamos_req/insertPrestamo, ", e);
  }
}
function* deletePrestamo(action) {
  try {
    const resp = yield call(eliminarPrestamo, action.payload);
    if (resp && resp!=='')
      yield put({
        type: configTypes.PRESTAMO_SERVER_MESSAGE,
        payload: {mensaje: {"prestamos": resp}}
      });
  } catch (e) {
    console.error("SAGA ERROR: prestamos_req/deletePrestamo, ", e);
  }
}

// Histórico
function* fetchHistoPrestamosDatabyQuery(action) {
  try {
    const resultQueryHistoPrestamos = yield call(getListHistoPrestamosbyQuery, action.payload);
    if (resultQueryHistoPrestamos)
      yield put({
        type: configTypes.PRESTAMO_HISTOSTATS_LOADED,
        payload: {resultQueryHistoPrestamos: resultQueryHistoPrestamos}
      });
  } catch (e) {
    console.error("SAGA ERROR: prestamos_req/fetchHistoPrestamosDatabyQuery, ", e);
  }
}
function* updateHistoPrestamo(action) {
  try {
    const resp = yield call(actualizarHistoPrestamo, action.payload);
    if (resp && resp!=='')
      yield put({
        type: configTypes.PRESTAMO_SERVER_MESSAGE,
        payload: {mensaje: {"histoprestamos": resp}}
      });
  } catch (e) {
    console.error("SAGA ERROR: prestamos_req/updateHistoPrestamo, ", e);
  }
}
function* insertHistoPrestamo(action) {
  try {
    const resp = yield call(crearHistoPrestamo, action.payload);
    if (resp && resp!=='')
      yield put({
        type: configTypes.PRESTAMO_SERVER_MESSAGE,
        payload: {mensaje: {"histoprestamos": resp}}
      });
  } catch (e) {
    console.error("SAGA ERROR: prestamos_req/insertHistoPrestamo, ", e);
  }
}
function* deleteHistoPrestamo(action) {
  try {
    const resp = yield call(eliminarHistoPrestamo, action.payload);
    if (resp && resp!=='')
      yield put({
        type: configTypes.PRESTAMO_SERVER_MESSAGE,
        payload: {mensaje: {"histoprestamos": resp}}
      });
  } catch (e) {
    console.error("SAGA ERROR: prestamos_req/deleteHistoPrestamo, ", e);
  }
}


// WATCHER //
export function* watchFetchPrestamosData() {
  yield takeLatest(configTypes.PRESTAMO_DATA_LOADED, fetchPrestamosData);
  yield takeLatest(configTypes.PRESTAMO_DATABYQUERY_LOADED, fetchPrestamosDatabyQuery);
  yield takeLatest(configTypes.PRESTAMO_DATA_UPDATED, updatePrestamo);
  yield takeLatest(configTypes.PRESTAMO_DATA_CREATED, insertPrestamo);
  yield takeLatest(configTypes.PRESTAMO_DATA_DELETED, deletePrestamo);

  yield takeLatest(configTypes.PRESTAMO_HISTODATABYQUERY_LOADED, fetchHistoPrestamosDatabyQuery);
  yield takeLatest(configTypes.PRESTAMO_HISTODATA_UPDATED, updateHistoPrestamo);
  yield takeLatest(configTypes.PRESTAMO_HISTODATA_CREATED, insertHistoPrestamo);
  yield takeLatest(configTypes.PRESTAMO_HISTODATA_DELETED, deleteHistoPrestamo);

}
