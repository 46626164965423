// React
import React from "react";
// Styled & Motion Components
import styled from "styled-components";

const HeaderDiv = styled.div`
  position: relative;
  bottom: 0;
  display: flex;
  align-items: center;
  // justify-content: flex-end;
  width: 100%;
  padding: 0.2em;
  text-align: center;
  color: gray;
  border-top: 1px solid #dfdfdf;
  // background-color: #2e868d;
`;

const LabelFooter = styled.p`
  padding: 0px 3px;
  margin: 0px;
  font-size: 0.65em;
  color: #444;
  text-shadow: -2px 2px 8px rgba(0, 0, 0, 0.25);
  text-align: left;
  
`;

const LabelFooterVersion = styled.p`
  float:right;
  padding: 0px 3px;
  margin: 0px;
  font-size: 0.65em;
  color: #444;
  text-shadow: -2px 2px 8px rgba(0, 0, 0, 0.25);
  text-align: right;
  position: absolute;
  right: 20px;
`;

// const FooterGRB = props => {
    class FooterGRB extends React.Component {
      constructor(props) {
        super(props)
        this.state = { }
      }
  
      render() {
        return (
            <HeaderDiv>
              <LabelFooter>© 2020 Fundación Club Farallones. Desarrollado por 
              <a href="https://egiron.github.io/" target="_new"> egiron</a></LabelFooter>
              <LabelFooterVersion>Versión 1.3.5</LabelFooterVersion>
            </HeaderDiv>

        )
      }
    }
  
//     return <FooterGRB />
// }

export default FooterGRB;