// React
import React, {Fragment, useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";

import {
    // serverMensaje,
    fetchTarifasCaddieData, 
    resetTarifasCaddie
} from '../../redux/reducers/cumpli';

import CriterioTarifasServicio from './TarifasServicio';
// Styled & Motion Components
// import styled from "styled-components";

const ModuleTarifasCaddie = props => {
    const dispatch = useDispatch();
    const users = useSelector(state => state.users);
    const listTarifasCaddie = useSelector(state => state.cumpli.listTarifasCaddie);

    useEffect(() => {
        if (listTarifasCaddie===null || listTarifasCaddie.length<=0){
            dispatch(fetchTarifasCaddieData({token: users.user.claveAPI}));
            return
        }
        return () => {
            // executed when unmount
            dispatch(resetTarifasCaddie());
        }
    }, [users, listTarifasCaddie, dispatch]);

    // useEffect(() => {
    //     if (listTarifasCaddie && listTarifasCaddie.length > 0){
    //         // console.log(listTarifasCaddie);
    //     }

    // }, [listTarifasCaddie, dispatch]);

    if (listTarifasCaddie)
        return (
        <Fragment>
            {/* <h3>Tarifas {users.user.permisos}</h3> */}
            <CriterioTarifasServicio criterios={listTarifasCaddie}/>
        </Fragment>
    );

    return (<></>)
}
  
export default ModuleTarifasCaddie;