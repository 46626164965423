// React
import React, {Fragment} from "react";
import { useSelector } from "react-redux";
// import {fetchUsersData} from '../../redux/reducers/users';

// Styled & Motion Components
// import styled from "styled-components";

const ModuleReportes = props => {
    const users = useSelector(state => state.users);

    // useEffect(() => {
    //     dispatch(fetchUsersData({token: users.user.claveAPI}));
    // }, [users.user.claveAPI, dispatch]);

    if (users)
        return (
        <Fragment>
            <h3>Reportes {users.user.permisos}</h3>
        </Fragment>
    );

return (<></>)
}
  
export default ModuleReportes;