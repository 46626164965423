// React
import React, {useState} from "react";
// Redux imports
import { useSelector, useDispatch } from "react-redux";
import { setProyecto } from "../../../redux/reducers/proyectos";

import ActionBar, {
  Action,
  ActionGroup,
  // BottomActionGroup
} from 'calcite-react/ActionBar';

// Styled Components
import styled from "styled-components";

const ActionBarLeft = styled.div`
  // float:left;
  // display: inline-flex;
  // position: relative;
  // width: auto;
  // height: 100%;
  // text-align: center;
  // z-index: 2;
  // left: 0px;
  overflow: hidden;
  // background-color: #4c4c4c;
  // color: #fff;
`;

const ItemMenuOptsIcon = styled.div`
  width: 16px;
  height: 16px;
  background: transpararent;
  font-size:0.95rem;
  font-weight:700;
  text-align: center;
`;

const MenuLeftReconocimientos = props => {
    // const isCollapsed = useSelector(state => state.proyectos.collapsed);
    const [isCollapsed, setIsCollapsed] = useState(false);
    const activeAction = useSelector(state => state.proyectos.activeProyecto);
    const dispatch = useDispatch();

    const proyectosNames = {
      "":'Reconocimiento',
      "COTIZACIONES_RECONOCIMIENTO": "Cotizaciones",
      "BONO_EXCELENCIA_RECONOCIMIENTO": "Bono excelencia",
      "PATROCINIO_RECONOCIMIENTO": "Patrocinio",
      "PREMIO_RECONOCIMIENTO": "Premio",
      "CENA_RECONOCIMIENTO": "Cena",
      "CONFIGURACION": "Configuración del GRB", 
    };

    // useEffect(() => {
    //   dispatch(moduleLEFTPANELLoaded({activeModule:activeAction, headerTitle:proyectosNames[activeAction]}));
    // }, [dispatch]);

    const onToggleCollapse = (e) => {
      setIsCollapsed(!isCollapsed);
    };

    const turnOffModules = () => {
      // dispatch(moduleUSERSLoaded({loaded:false})); // Hide Users
      // dispatch(moduleBENEFICIARIOSLoaded({loaded:false}));
    }

    const actionSelected = (activeAction) => {
      dispatch(setProyecto({activeProyecto:activeAction, collapsed:isCollapsed, headerTitle:proyectosNames[activeAction]}));
      turnOffModules();
      switch (activeAction) {
        case 'COTIZACIONES_RECONOCIMIENTO':
          console.log('COTIZACIONES_RECONOCIMIENTO');
          break;
        case 'BONO_EXCELENCIA_RECONOCIMIENTO':
          console.log('BONO_EXCELENCIA_RECONOCIMIENTO');
          break;
        case 'PATROCINIO_RECONOCIMIENTO':
          console.log('PATROCINIO_RECONOCIMIENTO');
          break;
        case 'PREMIO_RECONOCIMIENTO':
          console.log('PREMIO_RECONOCIMIENTO');
          break;
        case 'CENA_RECONOCIMIENTO':
          console.log('CENA_RECONOCIMIENTO');
          break;
        default:
          break;
      }
    };

    return (
      <ActionBarLeft>
      <ActionBar 
        // showCollapser={false}
        collapseLabel='Ocultar' 
        expandLabel='Expandir' 
        collapsed={isCollapsed}
        onToggleCollapse={onToggleCollapse}
      >
        <ActionGroup>
          <Action 
              onClick={() => actionSelected('COTIZACIONES_RECONOCIMIENTO')}
              active={activeAction === 'COTIZACIONES_RECONOCIMIENTO'}
              icon={<ItemMenuOptsIcon>Co</ItemMenuOptsIcon>}
            >
              Cotizaciones
          </Action>
          <Action
            onClick={() => actionSelected('BONO_EXCELENCIA_RECONOCIMIENTO')}
            active={activeAction === 'BONO_EXCELENCIA_RECONOCIMIENTO'}
            icon={<ItemMenuOptsIcon>Bo</ItemMenuOptsIcon>}
          >
            Bono excelencia
          </Action>
          
          <Action
            onClick={() => actionSelected('PATROCINIO_RECONOCIMIENTO')}
            active={activeAction === 'PATROCINIO_RECONOCIMIENTO'}
            icon={<ItemMenuOptsIcon>Pa</ItemMenuOptsIcon>}
            >Patrocinio
          </Action>
          <Action
            onClick={() => actionSelected('PREMIO_RECONOCIMIENTO')}
            active={activeAction === 'PREMIO_RECONOCIMIENTO'}
            icon={<ItemMenuOptsIcon>Pr</ItemMenuOptsIcon>}
          >
            Premio
          </Action>
          <Action
            onClick={() => actionSelected('CENA_RECONOCIMIENTO')}
            active={activeAction === 'CENA_RECONOCIMIENTO'}
            icon={<ItemMenuOptsIcon>Ce</ItemMenuOptsIcon>}
          >
            Cena
          </Action>
        
        </ActionGroup>
        {/* <BottomActionGroup>
        <Action icon={<GearIcon />}>Configuración</Action>
      </BottomActionGroup> */}
      </ActionBar>
      </ActionBarLeft>
    )

}

export default MenuLeftReconocimientos;