// React
import React, {useState} from "react";

// Redux imports
import { useSelector, useDispatch } from "react-redux";
import { 
  moduleCumplimientoBenefLoaded, 
  resetBeneficiarioGolf,
  resetBeneficiarioTenis,
  resetTarifasCaddie
} from "../../redux/reducers/cumpli";

// import { setModulo } from "../../redux/reducers/modulos";
import { setProyecto } from "../../redux/reducers/proyectos";

// Styled & Motion Components

// import BeneficiarioIcon from 'calcite-ui-icons-react/PersonIcon';
import GearIcon from 'calcite-ui-icons-react/GearIcon';
import CalendarIcon from 'calcite-ui-icons-react/CalendarIcon';
import ReportIcon from 'calcite-ui-icons-react/FileReportIcon';
import UsersIcon from 'calcite-ui-icons-react/UsersIcon';
import UsuarioIcon from 'calcite-ui-icons-react/UserIcon';
import TarifasIcon from 'calcite-ui-icons-react/ListNumberIcon';

import ActionBar, {
  Action,
  ActionGroup,
  BottomActionGroup
} from 'calcite-react/ActionBar';

// Styled Components
import styled from "styled-components";

const ActionBarLeft = styled.div`
  // float:left;
  // display: inline-flex;
  // position: relative;
  // width: auto;
  // height: 100%;
  // text-align: center;
  // z-index: 2;
  // left: 0px;
  overflow: hidden;
  // background-color: #4c4c4c;
  // color: #fff;
`;

const StarterPanelLeft = props => {
    const [isCollapsed, setIsCollapsed] = useState(false);
    const activeAction = useSelector(state => state.proyectos.activeProyecto);
    // const activeAction = useSelector(state => state.modulos.activeModule);
    const listGolf = useSelector(state => state.cumpli.listGolf);
    const listTenis = useSelector(state => state.cumpli.listTenis);
    const listTarifasCaddie = useSelector(state => state.cumpli.listTarifasCaddie);
    const dispatch = useDispatch();

    const projectNames = {
      "":'Caddie Master',
      "CADDIES": "Caddies Activos",
      "RESERVAS": "Reservas",
      "ASIGNACION": "Asignación",
      "VALIDACION": "Validación Cumplimiento Reserva",
      "REPORTES": "Reportes",
      "TARIFASCADDIES": "Tarifas Caddie",
      "TORNEOS": "Torneos", 
      "CONFIGURACION": "Configuración del GRB", 
    };

    // useEffect(() => {
    //   dispatch(setModulo({activeModule:activeAction, headerTitle:projectNames[activeAction]}));
    // }, [dispatch]);

    const onToggleCollapse = (e) => {
      setIsCollapsed(!isCollapsed);
    };

    const turnOffModules = () => {
        dispatch(moduleCumplimientoBenefLoaded({benefloaded:false}));
    }

    // const clearVariables = () => {
    //   dispatch(displayCovidFilter({isFilterShow:false}));
    // }

    const actionSelected = (activeAction) => {
      // dispatch(setModulo({activeModule:activeAction, collapsed:isCollapsed, headerTitle:projectNames[activeAction]}));
      dispatch(setProyecto({activeProyecto:activeAction, collapsed:isCollapsed, headerTitle:projectNames[activeAction]}));
      turnOffModules();
      switch (activeAction) {
        case 'RESERVAS':
            console.log('RESERVAS');        
          break;
        case 'ASIGNACION':
            console.log('ASIGNACION');
            break;
        case 'VALIDACION':
          console.log('VALIDACION');
          // if (listGolf && listGolf.length>0){
          //   dispatch(resetBeneficiarioGolf({listGolf:[]}));
          // }
          break;
        case 'CADDIES':
            // console.log('CADDIES');
            if (listGolf && listGolf.length>0){
              dispatch(resetBeneficiarioGolf({listGolf:[]}));
            }
            if (listTenis && listTenis.length>0){
              dispatch(resetBeneficiarioTenis({listTenis:[]}));
            }
            dispatch(moduleCumplimientoBenefLoaded({benefloaded:true}));
            break;
        case 'REPORTES':
            console.log('REPORTES');
            break;
        case 'TARIFASCADDIES':
            // console.log('TARIFASCADDIES');
            if (listTarifasCaddie && listTarifasCaddie.length>0){
              dispatch(resetTarifasCaddie({listTarifasCaddie:null}));
            }
          break;
        default:
          break;
      }
    };

    return (
      <ActionBarLeft>
        <ActionBar
          collapseLabel='Ocultar' 
          expandLabel='Expandir' 
          collapsed={isCollapsed}
          onToggleCollapse={onToggleCollapse}
        >
          <ActionGroup>
            <Action icon={<CalendarIcon style={{color:'green'}}/>}
              onClick={() => actionSelected('RESERVAS')}
              active={activeAction === 'RESERVAS'}
            >
              Reservas</Action>
            {/* <Action
              onClick={() => actionSelected('ASIGNACION')}
              active={activeAction === 'ASIGNACION'}
              icon={<UsuarioIcon style={{color:'green'}}/>}
            >
              Asignación
            </Action> */}
            <Action
              onClick={() => actionSelected('VALIDACION')}
              active={activeAction === 'VALIDACION'}
              icon={<UsuarioIcon style={{color:'green'}}/>}
            >
              Validación
            </Action>
          </ActionGroup>
          <ActionGroup>
            <Action
                onClick={() => actionSelected('CADDIES')}
                active={activeAction === 'CADDIES'}
                icon={<UsersIcon style={{color:'green'}} />}
            >
              Caddies
            </Action>
            <Action
              onClick={() => actionSelected('TARIFASCADDIES')}
              active={activeAction === 'TARIFASCADDIES'}
              icon={<TarifasIcon style={{color:'green'}} />}
            >
              Tarifas
            </Action>
          </ActionGroup>
          <ActionGroup>
          {/* <Action
            onClick={() => actionSelected('TORNEOS')}
            active={activeAction === 'TORNEOS'}
            icon={<TeamIcon />}
            >Torneos</Action> */}
          <Action
            onClick={() => actionSelected('REPORTES')}
            active={activeAction === 'REPORTES'}
            icon={<ReportIcon style={{color:'green'}} />}
          >
            Reportes
          </Action>
          
          </ActionGroup>
          <BottomActionGroup>
          <Action icon={<GearIcon />}>Configuración</Action>
        </BottomActionGroup>
        </ActionBar>

      </ActionBarLeft>
    )

}

export default StarterPanelLeft;