// Copyright 2020 Ernesto Giron E.
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//     http://www.apache.org/licenses/LICENSE-2.0
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.​

// React
import React, {useState, useEffect} from "react";
import { Redirect} from "react-router-dom";
// Redux imports
import { useSelector, useDispatch } from "react-redux";
import { login } from "../redux/reducers/auth";
// import API from '../utils/ecaseAPI';
// import { setGRBConfig } from "../redux/reducers/config";
import { startUserAuth, startBeneficiarioAuth } from "../redux/reducers/users";

// Components
import { Formik, Field } from 'formik';
import TextField from 'calcite-react/TextField';
import Button from 'calcite-react/Button';
import Select from 'calcite-react/Select';
import { MenuItem } from 'calcite-react/Menu';

import Form, {
    FormControl,
    FormControlLabel,
    FormHelperText
  } from 'calcite-react/Form';

import background from "../assets/bg_login.jpg";
import logo from "../assets/logo_solo.svg"; //"../assets/logo-solo.png";
import "./App.css";

// Styled & Motion Components
import styled from "styled-components";

const Container = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  background: #fff;
  background-blend-mode: screen;
  text-align: center;
  z-index:1000;
  overflow: hidden auto;
  // background: rgba(255, 255, 255, 0.1) url(${background}) no-repeat center/cover;
`;

const VideoContainer = styled.div`
  margin:0;
  padding:0;
  position:absolute;
  width: 100%;
  height:100%;
  // display: flex;
  // align-items: center;
  // justify-items: center;
  overflow:hidden;
`;


const Wrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 20px;
  text-align:left;
  background: #ffffffa6;
  z-index: 2;
`;

const Copyright = styled.div`
  width: auto;
  height: 20px;
  position: absolute;
  left: 70px;
  // transform: translate(-50%);
  background-blend-mode: screen;
  bottom: -35px;
  display: flex;
  padding: 0.8em;
  text-align: center;
  color: #fff;
  font-size: 0.8em;
  text-shadow: -2px 2px 8px rgba(0, 0, 0, 0.45);
`;

const LogoName = styled.div`
  width: 50px;
  position: relative;
  display: inline-flex;
  padding: 0;
  text-align: center;
  color: #000;
  font-size: 1.01em;
  text-shadow: -2px 2px 8px rgba(0,0,0,0.25);
  font-weight: 600;
  top: -37px;
  left: 40px;
  margin: 0;
  line-height: 16px;
  letter-spacing: 1.5px;
`;
const Logo = styled.img`
  // width: 5em;
  // height: 100%;
  width: auto;
  height: 55px;
  margin-right: 1em;
  text-align:left;
  display: inline-flex;
`;

const ErrorAPIServer = styled.div`
  color:red;
`;
const LabelLogin = styled.div`
  color:#012d08;
  font-size:1.0em;
  font-weight:600;
`;

const LoginScreen = props => {
    const config = useSelector(state => state.config);
    const users = useSelector(state => state.users);
    const loggedIn = useSelector(state => state.auth.loggedIn);
    const dispatch = useDispatch();

    const [headerTitleLogin] = useState(config.headerTitleLogin);
    const [initVals] = useState({ tipo: 'Beneficiario', email: '', password: '', docId: '', password2: '' });
    // const [initVals] = useState({ tipo: 'Beneficiario', email: 'e.giron.e@gmail.com', password: 'Erncad98', docId: '943723721', password2: '' });
    // const [initVals] = useState({ tipo: 'Beneficiario', email: 'caddie.master@clubfarallones.org', password: '16846918', docId: '943723721', password2: '' });
    // const [initVals] = useState({ tipo: 'Beneficiario', email: 'coordinacion@fundacionclubfarallones.org', password: 'carlos77', docId: '943723721', password2: '' });
    // const [initVals] = useState({ tipo: 'Beneficiario', email: 'trabajosocial@fundacionclubfarallones.org', password: 'Univalle10', docId: '38886234', password2: '' });
    // const [initVals] = useState({ tipo: 'Beneficiario', email: 'e_giron_e@msn.com', password: '111111', docId: '943723721', password2: '' });
    const [tipoUser] = useState({"tipos":config.tipoUser});
    const [errorServer, setErrorServer] = useState(null);
    const [values,setValues] = useState({ tipo: 'Beneficiario', email: "", password: "", password2: "", docId: ""});

    useEffect(() => {
      if (values.email!=='' && values.password!=='') 
      {
        dispatch(startUserAuth({user:{email: values.email, password: values.password}}));
      } else if (values.docId!=='' && values.password2!=='') 
      {
        dispatch(startBeneficiarioAuth({user:{docId: values.docId, password: values.password2}}));
      }

    }, [values, dispatch]);

    useEffect(() => {
      if (users.error)
        setErrorServer(users.error);
      // if the config isn't yet loaded then skip this effect
      if (!users.loggedIn) {
        return;
      }
      const { user, token, loggedIn } = users;
      dispatch(login({loggedIn: loggedIn, token: token, user:user.usuario}));
      // setErrorServer("Usuario Válido");
    }, [users, dispatch]);

  // onSubmit
  const validateUseronServer = (values, setSubmitting) => {
    setValues(values);
    setSubmitting(false);
  }
    
  // Render
  if (!loggedIn) {
    return (
      <Container>
        <VideoContainer>
          <div className="video_color_overlay"></div>
          <video autoPlay loop muted className="video_ff">
              <source src="video_20seg_640p.mp4" type="video/mp4" />
          </video>
        </VideoContainer>
        <Wrapper>
            <div style={{height:'65px'}}>
              <Logo src={logo} className="App-logo" />
              <LogoName>{headerTitleLogin}</LogoName>
            </div>
            <hr style={{ color: '#d1ffd1' }}/>

            <Formik
                initialValues={initVals}
                validate={values => {
                    const errors = {};

                    if (values.tipo !== 'Beneficiario') {
                      // errors.tipo = 'Debe ser un Beneficiario 😉'
                      if (!values.email) {
                          errors.email = 'Requerido';
                      } else if (
                          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                      ) {
                          errors.email = 'Correo no válido';
                      } 
                      if (!values.password) {
                        errors.password = 'Requerido';
                      } else if (values.password===" " || values.password.length < 4) {
                        errors.password = 'Contraseña no válida';
                      }
                    }
                    if (values.tipo === 'Beneficiario') {
                      if (!values.docId) {
                        errors.docId = 'Requerido';
                      } else if ( !/^[0-9]*$/i.test(values.docId) ) {
                        errors.docId = 'Número de documento no válido';
                      } else if (values.docId===" " || values.docId.length < 6) {
                        errors.docId = 'Número de documento no válido';
                      }
                      if (!values.password2) {
                        errors.password2 = 'Requerido';
                      } else if ( !/^[0-9]*$/i.test(values.password2) ) {
                        errors.password2 = 'Número no válido';
                      } else if (values.password2===" " || values.password2.length < 4 || values.password2.length > 4) {
                        errors.password2 = 'Número no válido';
                      }
                    }

                    return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                  validateUseronServer(values, setSubmitting);
                }}
            >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
            <Form onSubmit={handleSubmit} style={{ minWidth: '280px' }}>
                <FormControl
                    success={touched.tipo && !errors.tipo ? true : false}
                    error={touched.tipo && errors.tipo ? true : false}
                >
                    <FormControlLabel htmlFor="tipo" style={{ minWidth: '120px', textAlign:'left' }}>
                      <LabelLogin>Tipo de usuario</LabelLogin>
                    </FormControlLabel>
                <Field fullWidth component={Select} name="tipo">
                  {tipoUser.tipos && tipoUser.tipos.map(tipo => {
                    return (
                      <MenuItem key={tipo} value={tipo}>
                        {tipo}
                      </MenuItem>
                    )
                  })}
                </Field>
                    
                    <FormHelperText>
                    {(touched.tipo && errors.tipo) || null}
                   </FormHelperText>
                </FormControl>

                {(values.tipo !== 'Beneficiario')
                  ?
                  <FormControl 
                    success={touched.email && !errors.email ? true : false}
                    error={touched.email && errors.email ? true : false}
                  >
                      <FormControlLabel style={{ minWidth: '120px', textAlign:'left' }}>
                          <LabelLogin>Correo eléctronico</LabelLogin>
                      </FormControlLabel>
                      <TextField fullWidth 
                        type="email" name="email" placeholder="Dígite el correo eléctronico"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email || ''}
                      />
                      <FormHelperText>
                      {(touched.email && errors.email) || null}
                    </FormHelperText>
                  </FormControl>
                  :
                  <FormControl 
                  success={touched.docId && !errors.docId ? true : false}
                  error={touched.docId && errors.docId ? true : false}
                >
                    <FormControlLabel style={{ minWidth: '120px', textAlign:'left' }}>
                        <LabelLogin>Número de documento</LabelLogin>
                    </FormControlLabel>
                    <TextField fullWidth 
                      type="text" name="docId" placeholder="Escriba el número de documento"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.docId || ''}
                    />
                    <FormHelperText>
                    {(touched.docId && errors.docId) || null}
                   </FormHelperText>
                </FormControl>
                }
                {(values.tipo !== 'Beneficiario')
                  ?
                <FormControl
                  success={touched.password && !errors.password ? true : false}
                  error={touched.password && errors.password ? true : false}
                >
                    <FormControlLabel style={{ minWidth: '120px', textAlign:'left' }}>
                    <LabelLogin>Contraseña</LabelLogin>
                    </FormControlLabel>
                    <TextField fullWidth
                      type="password" name="password" placeholder="Dígite su clave"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password || ''}
                    />
                    <FormHelperText>
                    {(touched.password && errors.password) || null}
                   </FormHelperText>
                </FormControl>
                :
                <FormControl
                success={touched.password2 && !errors.password2 ? true : false}
                error={touched.password2 && errors.password2 ? true : false}
              >
                  <FormControlLabel style={{ minWidth: '120px', textAlign:'left' }}>
                  <LabelLogin>Año de nacimiento o últimos 4 de tu celular</LabelLogin>
                  </FormControlLabel>
                  <TextField fullWidth
                    type="password" name="password2" placeholder="Ingresa los 4 números"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password2 || ''}
                  />
                  <FormHelperText>
                  {(touched.password2 && errors.password2) || null}
                </FormHelperText>
              </FormControl>
            }
                
                <FormControl>
                <Button fullWidth type="submit" 
                  disabled={isSubmitting} 
                  style={{ backgroundColor: 'green' }}
                >
                {isSubmitting ? 'Validando.......' : 'Entrar'}
                </Button>

                <ErrorAPIServer>
                  {errorServer}
                </ErrorAPIServer>
              </FormControl>
              
              </Form>
          )}
        </Formik>
        
        <Copyright>© 2020 Fundación Club Farallones</Copyright>
        </Wrapper>
        
      </Container>
    );
  }
  // Beneficiario
  if (loggedIn && values.tipo ==='Beneficiario') {
    return (
      <>
        {/* <Route path="/GRB/Beneficiario" component={MainBeneficiario} /> */}
        {/* <Route path={"/GRB/Beneficiario"} component={(loggedIn) => <MainBeneficiario loggedIn={loggedIn}/>} />  */}
        <Redirect to="/GRB/Beneficiario" />
      </>
    );
  }
  // Externo
  // Si es caddie master o cualqueir otro usuario que deba actualizar parte de la BD
  if (loggedIn && users.user.role ==='S') {
    return (
      <>
        <Redirect to="/GRB/Master" />
      </>
    );
  }

  // 
  // Normal
  if (loggedIn && values.tipo !=='Beneficiario' && users.user.role !=='A') { 
    return (
      <>
        <Redirect to="/GRB" />
      </>
    );
  }

  // Admin
  if (loggedIn && values.tipo !=='Beneficiario' && users.user.role ==='A') { 
    return (
      <>
        <Redirect to="/GRB/Admin" />
      </>
    );
  }

  return (
    <>
    </>
  );
};

export default LoginScreen;
