// // Copyright 2020 Ernesto Girón E.
// // Licensed under the Apache License, Version 2.0 (the "License");
// // you may not use this file except in compliance with the License.
// // You may obtain a copy of the License at
// //     http://www.apache.org/licenses/LICENSE-2.0
// // Unless required by applicable law or agreed to in writing, software
// // distributed under the License is distributed on an "AS IS" BASIS,
// // WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// // See the License for the specific language governing permissions and
// // limitations under the License.​

import API from '../utils/ecaseAPI';
import {calculate_age} from '../utils/utils';

/********************************
// BENEFICIARIOS
*********************************/

 /* 
 * Make a request for list of Beneficiarios
 * @return Array of Beneficiarios
 */
export async function getBeneficiariosDataxCovid(action) {
  let listBeneficiarios = null;
    try {
      await API('/covidayudas/data', { //covidayudas //beneficiarios
          method: 'POST',
          data: JSON.stringify({"api":action.token})
      }).then(response => response.data).then((data) => {
          // console.log(data);
          listBeneficiarios = data.datos.map(d => {
            d.edad = d.fechanacimiento ? calculate_age(d.fechanacimiento) : 0;
            d.edadclub = d.fecha ? calculate_age(d.fecha) : 0;
            d.fecha = d.fecha ? new Date(d.fecha) : '';
            d.fechanacimiento = d.fechanacimiento ? new Date(d.fechanacimiento) : '';
            d.prestarservicio = d.prestarservicio ? d.prestarservicio : '0';
            d.jefehogar = d.jefehogar ? d.jefehogar : ''; // Bug no todos los registros tienen
            d.valor = d.valor ? parseInt(d.valor) : 0;
            d.personasacargo = d.personasacargo ? parseInt(d.personasacargo) : 0;
            // d.fechacaducidad = d.fechacaducidad ? new Date(d.fechacaducidad) : '';
            d.actualizacion = new Date(d.actualizacion);
            return d
          });
          listBeneficiarios = data.datos
      }).catch(function (error) {
          // handle error
          console.log(`😱 problemas obteniendo los datos de Beneficiarios del GRB.`);
          console.log(error);
      })

  } catch (e) {
      console.log(`😱 Axios request failed: ${e}`);
  }
    return listBeneficiarios;
}

/* 
 * Make a request for list of Beneficiarios
 * @return Array of Beneficiarios
 */
export async function getBeneficiariosDataxCovidAcomp(action) {
    let listBeneficiarios = null;
      try {
        await API('/covidayudas/dataacomp', { //data //covidayudas //beneficiarios
            method: 'POST',
            data: JSON.stringify({"api":action.token})
        }).then(response => response.data).then((data) => {
            listBeneficiarios = data.datos.map(d => {
              d.edad = d.fechanacimiento ? calculate_age(d.fechanacimiento) : 0;
              d.edadclub = d.fecha ? calculate_age(d.fecha) : 0;
              d.fecha = d.fecha ? new Date(d.fecha) : '';
              d.fechanacimiento = d.fechanacimiento ? new Date(d.fechanacimiento) : '';
            //   d.jefehogar = d.jefehogar ? d.jefehogar : ''; // Bug no todos los registros tienen
            //   d.valor = d.valor ? parseInt(d.valor) : 0;
            //   d.personasacargo = d.personasacargo ? parseInt(d.personasacargo) : 0;
              // d.fechacaducidad = d.fechacaducidad ? new Date(d.fechacaducidad) : '';
              d.actualizacion = new Date(d.actualizacion);
              return d
            });
            listBeneficiarios = data.datos
        }).catch(function (error) {
            // handle error
            console.log(`😱 problemas obteniendo los datos de Beneficiarios del GRB.`);
            console.log(error);
        })
  
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
      return listBeneficiarios;
}

/* 
 * Make a request for list of Criterios
 * @return Array of Tablas de Criterios
 */
export async function getTablasCriteriosxCovid(action) {
    let listCriterios = null;
      try {
        await API('/covidayudas/criterios', { 
            method: 'POST',
            data: JSON.stringify({"api":action.token})
        }).then(response => response.data).then((data) => {
            listCriterios = data.datos
        }).catch(function (error) {
            // handle error
            console.log(`😱 problemas obteniendo los datos de Beneficiarios del GRB.`);
            console.log(error);
        })
  
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
      return listCriterios;
}

/* 
 * Make a request for Beneficiarios whole data
 * @return Array of Beneficiarios
 */
export async function getBeneficiarioPerfilDataxCovid(action) {
    let datosBenef = null;
      try {
        await API('/beneficiarios/perfil', {
            method: 'POST',
            data: JSON.stringify({"api":action.token, "docId":action.docId, "req":action.req})
        }).then(response => response.data).then((data) => {
            datosBenef = data.datos
        }).catch(function (error) {
            console.log(`😱 problemas obteniendo los datos de Beneficiarios del GRB.`);
            console.log(error);
        })
  
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
      return datosBenef;
}

/* 
 * Make a request for list of Bonos
 * @return Array of Bonos
 */
export async function getAyudasData(action) {
    let listBeneficiarios = null;
      try {
        await API('/covidayudas', {
            method: 'POST',
            data: JSON.stringify({"api":action.token})
        }).then(response => response.data).then((data) => {
            console.log(data);
            // listBeneficiarios = data.datos.map(d => {
            //   d.edad = calculate_age(d.fechanacimiento);
            //   d.edadclub = calculate_age(d.fecha);
            //   d.fecha = new Date(d.fecha);
            //   d.edad = calculate_age(d.fechanacimiento);
            //   d.fechanacimiento = new Date(d.fechanacimiento);
            //   d.actualizacion = new Date(d.actualizacion);
            //   return d
            // });
            listBeneficiarios = data.datos
        }).catch(function (error) {
            // handle error
            console.log(`😱 problemas obteniendo los datos de Beneficiarios del GRB.`);
            console.log(error);
        })
  
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
      return listBeneficiarios;
}

/* 
 * Actualiza los datos de covidayudas del Beneficiario
 * @return 
 */
export async function actualizarAyudasCovid(action) {
    let resp = null;
    try {
        await API('/covidayudas/update', {
            method: 'POST',
            data: JSON.stringify({"api":action.token, "covidayudas":action.covidayudas})
        }).then(response => response.data).then((data) => {
            resp = data.mensaje;
        }).catch((error) => {
            resp = '😱 Error ('+error.response.status+'): Problema guardando los datos de covidayudas en el servidor. ' + error.response.data.mensaje;
            console.log(`😱 problemas actualizando la tabla de covidayudas del Beneficiario en GRB.`, error.response.data.mensaje);
            return resp;
        })
  
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
    return resp;
}
/* 
 * Registra nuevos datos de covidayudas del Beneficiario
 * @return 
 */
export async function crearAyudasCovid(action) {
    let resp = null;
    try {
        await API('/covidayudas/insert', {
            method: 'POST',
            data: JSON.stringify({"api":action.token, "covidayudas":action.covidayudas})
        }).then(response => response.data).then((data) => {
            resp = data.mensaje;
        }).catch((error) => {
            resp = '😱 Error ('+error.response.status+'): Problema registrando los datos de covidayudas en el servidor. ' + error.response.data.mensaje;
            console.log(`😱 problemas registrando la tabla de covidayudas del Beneficiario en GRB.`, error.response.data.mensaje);
            return resp;
        })
  
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
    return resp;
}
/* 
 * Actualiza los datos de covidayudas del Beneficiario
 * @return 
 */
export async function eliminarAyudasCovid(action) {
    let resp = null;
    try {
        await API('/covidayudas/delete', {
            method: 'POST',
            data: JSON.stringify({"api":action.token, "covidayudas":action.covidayudas})
        }).then(response => response.data).then((data) => {
            resp = data.mensaje;
        }).catch((error) => {
            resp = '😱 Error ('+error.response.status+'): Problema eliminando los datos de covidayudas en el servidor. ' + error.response.data.mensaje;
            console.log(`😱 problemas eliminando la tabla de covidayudas del Beneficiario en GRB.`, error.response.data.mensaje);
            return resp;
        })
  
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
    return resp;
}

// TABLAS CRITERIOS PRESTA SERVICIO
/* 
 * Actualiza los datos de criterios del Beneficiario
 * @return 
 */
export async function actualizarCriteriosPrestaServicio(action) {
    let resp = null;
    try {
        await API('/prestaservicio/update', {
            method: 'POST',
            data: JSON.stringify({"api":action.token, "criterios":action.criterios})
        }).then(response => response.data).then((data) => {
            resp = data.mensaje;
        }).catch((error) => {
            resp = '😱 Error ('+error.response.status+'): Problema guardando los datos de prestaservicio en el servidor. ' + error.response.data.mensaje;
            console.log(`😱 problemas actualizando la tabla de prestaservicio del Beneficiario en GRB.`, error.response.data.mensaje);
            return resp;
        })
  
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
    return resp;
}
/* 
 * Registra nuevos datos de criterios del Beneficiario
 * @return 
 */
export async function crearCriteriosPrestaServicio(action) {
    let resp = null;
    try {
        await API('/prestaservicio/insert', {
            method: 'POST',
            data: JSON.stringify({"api":action.token, "criterios":action.criterios})
        }).then(response => response.data).then((data) => {
            resp = data.mensaje;
        }).catch((error) => {
            resp = '😱 Error ('+error.response.status+'): Problema registrando los datos de prestaservicio en el servidor. ' + error.response.data.mensaje;
            console.log(`😱 problemas registrando la tabla de prestaservicio del Beneficiario en GRB.`, error.response.data.mensaje);
            return resp;
        })
  
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
    return resp;
}
/* 
 * Actualiza los datos de criterios del Beneficiario
 * @return 
 */
export async function eliminarCriteriosPrestaServicio(action) {
    let resp = null;
    try {
        await API('/prestaservicio/delete', {
            method: 'POST',
            data: JSON.stringify({"api":action.token, "criterios":action.criterios})
        }).then(response => response.data).then((data) => {
            resp = data.mensaje;
        }).catch((error) => {
            resp = '😱 Error ('+error.response.status+'): Problema eliminando los datos de prestaservicio en el servidor. ' + error.response.data.mensaje;
            console.log(`😱 problemas eliminando la tabla de prestaservicio del Beneficiario en GRB.`, error.response.data.mensaje);
            return resp;
        })
  
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
    return resp;
}


// TABLAS CRITERIOS FAMILIARES EN EL CLUB
/* 
 * Actualiza los datos de criterios del Beneficiario
 * @return 
 */
export async function actualizarCriteriosFamiliaClub(action) {
    let resp = null;
    try {
        await API('/familiaclub/update', {
            method: 'POST',
            data: JSON.stringify({"api":action.token, "criterios":action.criterios})
        }).then(response => response.data).then((data) => {
            resp = data.mensaje;
        }).catch((error) => {
            resp = '😱 Error ('+error.response.status+'): Problema guardando los datos de tienefmliaclub en el servidor. ' + error.response.data.mensaje;
            console.log(`😱 problemas actualizando la tabla de tienefmliaclub del Beneficiario en GRB.`, error.response.data.mensaje);
            return resp;
        })
  
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
    return resp;
}
/* 
 * Registra nuevos datos de criterios del Beneficiario
 * @return 
 */
export async function crearCriteriosFamiliaClub(action) {
    let resp = null;
    try {
        await API('/familiaclub/insert', {
            method: 'POST',
            data: JSON.stringify({"api":action.token, "criterios":action.criterios})
        }).then(response => response.data).then((data) => {
            resp = data.mensaje;
        }).catch((error) => {
            resp = '😱 Error ('+error.response.status+'): Problema registrando los datos de tienefmliaclub en el servidor. ' + error.response.data.mensaje;
            console.log(`😱 problemas registrando la tabla de tienefmliaclub del Beneficiario en GRB.`, error.response.data.mensaje);
            return resp;
        })
  
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
    return resp;
}
/* 
 * Actualiza los datos de criterios del Beneficiario
 * @return 
 */
export async function eliminarCriteriosFamiliaClub(action) {
    let resp = null;
    try {
        await API('/familiaclub/delete', {
            method: 'POST',
            data: JSON.stringify({"api":action.token, "criterios":action.criterios})
        }).then(response => response.data).then((data) => {
            resp = data.mensaje;
        }).catch((error) => {
            resp = '😱 Error ('+error.response.status+'): Problema eliminando los datos de tienefmliaclub en el servidor. ' + error.response.data.mensaje;
            console.log(`😱 problemas eliminando la tabla de tienefmliaclub del Beneficiario en GRB.`, error.response.data.mensaje);
            return resp;
        })
  
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
    return resp;
}

// TABLAS CRITERIOS CABEZA HOGAR
/* 
 * Actualiza los datos de criterios del Beneficiario
 * @return 
 */
export async function actualizarCriteriosCabezaFamilia(action) {
    let resp = null;
    try {
        await API('/cabezahogar/update', {
            method: 'POST',
            data: JSON.stringify({"api":action.token, "criterios":action.criterios})
        }).then(response => response.data).then((data) => {
            resp = data.mensaje;
        }).catch((error) => {
            resp = '😱 Error ('+error.response.status+'): Problema guardando los datos de cabezahogar en el servidor. ' + error.response.data.mensaje;
            console.log(`😱 problemas actualizando la tabla de cabezahogar del Beneficiario en GRB.`, error.response.data.mensaje);
            return resp;
        })
  
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
    return resp;
}
/* 
 * Registra nuevos datos de criterios del Beneficiario
 * @return 
 */
export async function crearCriteriosCabezaFamilia(action) {
    let resp = null;
    try {
        await API('/cabezahogar/insert', {
            method: 'POST',
            data: JSON.stringify({"api":action.token, "criterios":action.criterios})
        }).then(response => response.data).then((data) => {
            resp = data.mensaje;
        }).catch((error) => {
            resp = '😱 Error ('+error.response.status+'): Problema registrando los datos de cabezahogar en el servidor. ' + error.response.data.mensaje;
            console.log(`😱 problemas registrando la tabla de cabezahogar del Beneficiario en GRB.`, error.response.data.mensaje);
            return resp;
        })
  
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
    return resp;
}
/* 
 * Actualiza los datos de criterios del Beneficiario
 * @return 
 */
export async function eliminarCriteriosCabezaFamilia(action) {
    let resp = null;
    try {
        await API('/cabezahogar/delete', {
            method: 'POST',
            data: JSON.stringify({"api":action.token, "criterios":action.criterios})
        }).then(response => response.data).then((data) => {
            resp = data.mensaje;
        }).catch((error) => {
            resp = '😱 Error ('+error.response.status+'): Problema eliminando los datos de cabezahogar en el servidor. ' + error.response.data.mensaje;
            console.log(`😱 problemas eliminando la tabla de cabezahogar del Beneficiario en GRB.`, error.response.data.mensaje);
            return resp;
        })
  
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
    return resp;
}


// TABLAS CRITERIOS PERSONAS A CARGO
/* 
 * Actualiza los datos de criterios del Beneficiario
 * @return 
 */
export async function actualizarCriteriosPersonasaCargo(action) {
    let resp = null;
    try {
        await API('/personasacargo/update', {
            method: 'POST',
            data: JSON.stringify({"api":action.token, "criterios":action.criterios})
        }).then(response => response.data).then((data) => {
            resp = data.mensaje;
        }).catch((error) => {
            resp = '😱 Error ('+error.response.status+'): Problema guardando los datos de personasacargo en el servidor. ' + error.response.data.mensaje;
            console.log(`😱 problemas actualizando la tabla de personasacargo del Beneficiario en GRB.`, error.response.data.mensaje);
            return resp;
        })
  
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
    return resp;
}
/* 
 * Registra nuevos datos de criterios del Beneficiario
 * @return 
 */
export async function crearCriteriosPersonasaCargo(action) {
    let resp = null;
    try {
        await API('/personasacargo/insert', {
            method: 'POST',
            data: JSON.stringify({"api":action.token, "criterios":action.criterios})
        }).then(response => response.data).then((data) => {
            resp = data.mensaje;
        }).catch((error) => {
            resp = '😱 Error ('+error.response.status+'): Problema registrando los datos de personasacargo en el servidor. ' + error.response.data.mensaje;
            console.log(`😱 problemas registrando la tabla de personasacargo del Beneficiario en GRB.`, error.response.data.mensaje);
            return resp;
        })
  
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
    return resp;
}
/* 
 * Actualiza los datos de criterios del Beneficiario
 * @return 
 */
export async function eliminarCriteriosPersonasaCargo(action) {
    let resp = null;
    try {
        await API('/personasacargo/delete', {
            method: 'POST',
            data: JSON.stringify({"api":action.token, "criterios":action.criterios})
        }).then(response => response.data).then((data) => {
            resp = data.mensaje;
        }).catch((error) => {
            resp = '😱 Error ('+error.response.status+'): Problema eliminando los datos de personasacargo en el servidor. ' + error.response.data.mensaje;
            console.log(`😱 problemas eliminando la tabla de personasacargo del Beneficiario en GRB.`, error.response.data.mensaje);
            return resp;
        })
  
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
    return resp;
}


// TABLAS CRITERIOS NUMERO DE HIJOS
/* 
 * Actualiza los datos de criterios del Beneficiario
 * @return 
 */
export async function actualizarCriteriosNumeroHijos(action) {
    let resp = null;
    try {
        await API('/numerohijos/update', {
            method: 'POST',
            data: JSON.stringify({"api":action.token, "criterios":action.criterios})
        }).then(response => response.data).then((data) => {
            resp = data.mensaje;
        }).catch((error) => {
            resp = '😱 Error ('+error.response.status+'): Problema guardando los datos de numerohijos en el servidor. ' + error.response.data.mensaje;
            console.log(`😱 problemas actualizando la tabla de numerohijos del Beneficiario en GRB.`, error.response.data.mensaje);
            return resp;
        })
  
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
    return resp;
}
/* 
 * Registra nuevos datos de criterios del Beneficiario
 * @return 
 */
export async function crearCriteriosNumeroHijos(action) {
    let resp = null;
    try {
        await API('/numerohijos/insert', {
            method: 'POST',
            data: JSON.stringify({"api":action.token, "criterios":action.criterios})
        }).then(response => response.data).then((data) => {
            resp = data.mensaje;
        }).catch((error) => {
            resp = '😱 Error ('+error.response.status+'): Problema registrando los datos de numerohijos en el servidor. ' + error.response.data.mensaje;
            console.log(`😱 problemas registrando la tabla de numerohijos del Beneficiario en GRB.`, error.response.data.mensaje);
            return resp;
        })
  
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
    return resp;
}
/* 
 * Actualiza los datos de criterios del Beneficiario
 * @return 
 */
export async function eliminarCriteriosNumeroHijos(action) {
    let resp = null;
    try {
        await API('/numerohijos/delete', {
            method: 'POST',
            data: JSON.stringify({"api":action.token, "criterios":action.criterios})
        }).then(response => response.data).then((data) => {
            resp = data.mensaje;
        }).catch((error) => {
            resp = '😱 Error ('+error.response.status+'): Problema eliminando los datos de numerohijos en el servidor. ' + error.response.data.mensaje;
            console.log(`😱 problemas eliminando la tabla de numerohijos del Beneficiario en GRB.`, error.response.data.mensaje);
            return resp;
        })
  
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
    return resp;
}

// TABLAS CRITERIOS ASISTENCIA
/* 
 * Actualiza los datos de criterios del Beneficiario
 * @return 
 */
export async function actualizarCriteriosAsistencia(action) {
    let resp = null;
    try {
        await API('/asistencia/update', {
            method: 'POST',
            data: JSON.stringify({"api":action.token, "criterios":action.criterios})
        }).then(response => response.data).then((data) => {
            resp = data.mensaje;
        }).catch((error) => {
            resp = '😱 Error ('+error.response.status+'): Problema guardando los datos de asistencia en el servidor. ' + error.response.data.mensaje;
            console.log(`😱 problemas actualizando la tabla de asistencia del Beneficiario en GRB.`, error.response.data.mensaje);
            return resp;
        })
  
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
    return resp;
}
/* 
 * Registra nuevos datos de criterios del Beneficiario
 * @return 
 */
export async function crearCriteriosAsistencia(action) {
    let resp = null;
    try {
        await API('/asistencia/insert', {
            method: 'POST',
            data: JSON.stringify({"api":action.token, "criterios":action.criterios})
        }).then(response => response.data).then((data) => {
            resp = data.mensaje;
        }).catch((error) => {
            resp = '😱 Error ('+error.response.status+'): Problema registrando los datos de asistencia en el servidor. ' + error.response.data.mensaje;
            console.log(`😱 problemas registrando la tabla de asistencia del Beneficiario en GRB.`, error.response.data.mensaje);
            return resp;
        })
  
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
    return resp;
}
/* 
 * Actualiza los datos de criterios del Beneficiario
 * @return 
 */
export async function eliminarCriteriosAsistencia(action) {
    let resp = null;
    try {
        await API('/asistencia/delete', {
            method: 'POST',
            data: JSON.stringify({"api":action.token, "criterios":action.criterios})
        }).then(response => response.data).then((data) => {
            resp = data.mensaje;
        }).catch((error) => {
            resp = '😱 Error ('+error.response.status+'): Problema eliminando los datos de asistencia en el servidor. ' + error.response.data.mensaje;
            console.log(`😱 problemas eliminando la tabla de asistencia del Beneficiario en GRB.`, error.response.data.mensaje);
            return resp;
        })
  
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
    return resp;
}

// TABLAS CRITERIOS ACTIVIDAD
/* 
 * Actualiza los datos de criterios del Beneficiario
 * @return 
 */
export async function actualizarCriteriosActividad(action) {
    let resp = null;
    try {
        await API('/actividades/update', {
            method: 'POST',
            data: JSON.stringify({"api":action.token, "criterios":action.criterios})
        }).then(response => response.data).then((data) => {
            resp = data.mensaje;
        }).catch((error) => {
            resp = '😱 Error ('+error.response.status+'): Problema guardando los datos de actividades en el servidor. ' + error.response.data.mensaje;
            console.log(`😱 problemas actualizando la tabla de actividades del Beneficiario en GRB.`, error.response.data.mensaje);
            return resp;
        })
  
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
    return resp;
}
/* 
 * Registra nuevos datos de criterios del Beneficiario
 * @return 
 */
export async function crearCriteriosActividad(action) {
    let resp = null;
    try {
        await API('/actividades/insert', {
            method: 'POST',
            data: JSON.stringify({"api":action.token, "criterios":action.criterios})
        }).then(response => response.data).then((data) => {
            resp = data.mensaje;
        }).catch((error) => {
            resp = '😱 Error ('+error.response.status+'): Problema registrando los datos de actividades en el servidor. ' + error.response.data.mensaje;
            console.log(`😱 problemas registrando la tabla de actividades del Beneficiario en GRB.`, error.response.data.mensaje);
            return resp;
        })
  
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
    return resp;
}
/* 
 * Actualiza los datos de criterios del Beneficiario
 * @return 
 */
export async function eliminarCriteriosActividad(action) {
    let resp = null;
    try {
        await API('/actividades/delete', {
            method: 'POST',
            data: JSON.stringify({"api":action.token, "criterios":action.criterios})
        }).then(response => response.data).then((data) => {
            resp = data.mensaje;
        }).catch((error) => {
            resp = '😱 Error ('+error.response.status+'): Problema eliminando los datos de actividades en el servidor. ' + error.response.data.mensaje;
            console.log(`😱 problemas eliminando la tabla de actividades del Beneficiario en GRB.`, error.response.data.mensaje);
            return resp;
        })
  
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
    return resp;
}

// TABLAS CRITERIOS CATEGORIA
/* 
 * Actualiza los datos de criterios del Beneficiario
 * @return 
 */
export async function actualizarCriteriosCategoria(action) {
    let resp = null;
    try {
        await API('/categorias/update', {
            method: 'POST',
            data: JSON.stringify({"api":action.token, "criterios":action.criterios})
        }).then(response => response.data).then((data) => {
            resp = data.mensaje;
        }).catch((error) => {
            resp = '😱 Error ('+error.response.status+'): Problema guardando los datos de categorias en el servidor. ' + error.response.data.mensaje;
            console.log(`😱 problemas actualizando la tabla de categorias del Beneficiario en GRB.`, error.response.data.mensaje);
            return resp;
        })
  
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
    return resp;
}
/* 
 * Registra nuevos datos de criterios del Beneficiario
 * @return 
 */
export async function crearCriteriosCategoria(action) {
    let resp = null;
    try {
        await API('/categorias/insert', {
            method: 'POST',
            data: JSON.stringify({"api":action.token, "criterios":action.criterios})
        }).then(response => response.data).then((data) => {
            resp = data.mensaje;
        }).catch((error) => {
            resp = '😱 Error ('+error.response.status+'): Problema registrando los datos de categorias en el servidor. ' + error.response.data.mensaje;
            console.log(`😱 problemas registrando la tabla de categorias del Beneficiario en GRB.`, error.response.data.mensaje);
            return resp;
        })
  
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
    return resp;
}
/* 
 * Actualiza los datos de criterios del Beneficiario
 * @return 
 */
export async function eliminarCriteriosCategoria(action) {
    let resp = null;
    try {
        await API('/categorias/delete', {
            method: 'POST',
            data: JSON.stringify({"api":action.token, "criterios":action.criterios})
        }).then(response => response.data).then((data) => {
            resp = data.mensaje;
        }).catch((error) => {
            resp = '😱 Error ('+error.response.status+'): Problema eliminando los datos de categorias en el servidor. ' + error.response.data.mensaje;
            console.log(`😱 problemas eliminando la tabla de categorias del Beneficiario en GRB.`, error.response.data.mensaje);
            return resp;
        })
  
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
    return resp;
}

// TABLAS CRITERIOS EDAD DEL BENEFICIARIO
/* 
 * Actualiza los datos de criterios del Beneficiario
 * @return 
 */
export async function actualizarCriteriosEdad(action) {
    let resp = null;
    try {
        await API('/criterioedad/update', {
            method: 'POST',
            data: JSON.stringify({"api":action.token, "criterios":action.criterios})
        }).then(response => response.data).then((data) => {
            resp = data.mensaje;
        }).catch((error) => {
            resp = '😱 Error ('+error.response.status+'): Problema guardando los datos de criterioedad en el servidor. ' + error.response.data.mensaje;
            console.log(`😱 problemas actualizando la tabla de criterioedad del Beneficiario en GRB.`, error.response.data.mensaje);
            return resp;
        })
  
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
    return resp;
}
/* 
 * Registra nuevos datos de criterios del Beneficiario
 * @return 
 */
export async function crearCriteriosEdad(action) {
    let resp = null;
    try {
        await API('/criterioedad/insert', {
            method: 'POST',
            data: JSON.stringify({"api":action.token, "criterios":action.criterios})
        }).then(response => response.data).then((data) => {
            resp = data.mensaje;
        }).catch((error) => {
            resp = '😱 Error ('+error.response.status+'): Problema registrando los datos de criterioedad en el servidor. ' + error.response.data.mensaje;
            console.log(`😱 problemas registrando la tabla de criterioedad del Beneficiario en GRB.`, error.response.data.mensaje);
            return resp;
        })
  
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
    return resp;
}
/* 
 * Actualiza los datos de criterios del Beneficiario
 * @return 
 */
export async function eliminarCriteriosEdad(action) {
    let resp = null;
    try {
        await API('/criterioedad/delete', {
            method: 'POST',
            data: JSON.stringify({"api":action.token, "criterios":action.criterios})
        }).then(response => response.data).then((data) => {
            resp = data.mensaje;
        }).catch((error) => {
            resp = '😱 Error ('+error.response.status+'): Problema eliminando los datos de criterioedad en el servidor. ' + error.response.data.mensaje;
            console.log(`😱 problemas eliminando la tabla de criterioedad del Beneficiario en GRB.`, error.response.data.mensaje);
            return resp;
        })
  
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
    return resp;
}

// TABLAS CRITERIOS TIEMPO EN EL CLUB
/* 
 * Actualiza los datos de criterios del Beneficiario
 * @return 
 */
export async function actualizarCriteriosEdadClub(action) {
    let resp = null;
    try {
        await API('/criterioedadclub/update', {
            method: 'POST',
            data: JSON.stringify({"api":action.token, "criterios":action.criterios})
        }).then(response => response.data).then((data) => {
            resp = data.mensaje;
        }).catch((error) => {
            resp = '😱 Error ('+error.response.status+'): Problema guardando los datos de criterioedadclub en el servidor. ' + error.response.data.mensaje;
            console.log(`😱 problemas actualizando la tabla de criterioedadclub del Beneficiario en GRB.`, error.response.data.mensaje);
            return resp;
        })
  
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
    return resp;
}
/* 
 * Registra nuevos datos de criterios del Beneficiario
 * @return 
 */
export async function crearCriteriosEdadClub(action) {
    let resp = null;
    try {
        await API('/criterioedadclub/insert', {
            method: 'POST',
            data: JSON.stringify({"api":action.token, "criterios":action.criterios})
        }).then(response => response.data).then((data) => {
            resp = data.mensaje;
        }).catch((error) => {
            resp = '😱 Error ('+error.response.status+'): Problema registrando los datos de criterioedadclub en el servidor. ' + error.response.data.mensaje;
            console.log(`😱 problemas registrando la tabla de criterioedadclub del Beneficiario en GRB.`, error.response.data.mensaje);
            return resp;
        })
  
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
    return resp;
}
/* 
 * Actualiza los datos de criterios del Beneficiario
 * @return 
 */
export async function eliminarCriteriosEdadClub(action) {
    let resp = null;
    try {
        await API('/criterioedadclub/delete', {
            method: 'POST',
            data: JSON.stringify({"api":action.token, "criterios":action.criterios})
        }).then(response => response.data).then((data) => {
            resp = data.mensaje;
        }).catch((error) => {
            resp = '😱 Error ('+error.response.status+'): Problema eliminando los datos de criterioedadclub en el servidor. ' + error.response.data.mensaje;
            console.log(`😱 problemas eliminando la tabla de criterioedadclub del Beneficiario en GRB.`, error.response.data.mensaje);
            return resp;
        })
  
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
    return resp;
}

// TABLAS CRITERIOS CUPO POR AFECTACION
/* 
 * Actualiza los datos de criterios del Beneficiario
 * @return 
 */
export async function actualizarCriteriosCupo(action) {
    let resp = null;
    try {
        await API('/criteriocupo/update', {
            method: 'POST',
            data: JSON.stringify({"api":action.token, "criterios":action.criterios})
        }).then(response => response.data).then((data) => {
            resp = data.mensaje;
        }).catch((error) => {
            resp = '😱 Error ('+error.response.status+'): Problema guardando los datos de criteriocupo en el servidor. ' + error.response.data.mensaje;
            console.log(`😱 problemas actualizando la tabla de criteriocupo del Beneficiario en GRB.`, error.response.data.mensaje);
            return resp;
        })
  
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
    return resp;
}
/* 
 * Registra nuevos datos de criterios del Beneficiario
 * @return 
 */
export async function crearCriteriosCupo(action) {
    let resp = null;
    try {
        await API('/criteriocupo/insert', {
            method: 'POST',
            data: JSON.stringify({"api":action.token, "criterios":action.criterios})
        }).then(response => response.data).then((data) => {
            resp = data.mensaje;
        }).catch((error) => {
            resp = '😱 Error ('+error.response.status+'): Problema registrando los datos de criteriocupo en el servidor. ' + error.response.data.mensaje;
            console.log(`😱 problemas registrando la tabla de criteriocupo del Beneficiario en GRB.`, error.response.data.mensaje);
            return resp;
        })
  
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
    return resp;
}
/* 
 * Actualiza los datos de criterios del Beneficiario
 * @return 
 */
export async function eliminarCriteriosCupo(action) {
    let resp = null;
    try {
        await API('/criteriocupo/delete', {
            method: 'POST',
            data: JSON.stringify({"api":action.token, "criterios":action.criterios})
        }).then(response => response.data).then((data) => {
            resp = data.mensaje;
        }).catch((error) => {
            resp = '😱 Error ('+error.response.status+'): Problema eliminando los datos de criteriocupo en el servidor. ' + error.response.data.mensaje;
            console.log(`😱 problemas eliminando la tabla de criteriocupo del Beneficiario en GRB.`, error.response.data.mensaje);
            return resp;
        })
  
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
    return resp;
}

/************************/ 
// LISTA DE ENTREGA
/************************/ 

/* 
 * Make a request for list of delivers
 * @return Array of Tabla de Lista de Entregas
 */
export async function getListEntregaDataxCovid(action) {
    let listEntregas = null;
      try {
        await API('/covidlistaentregas/', { 
            method: 'POST',
            data: JSON.stringify({"api":action.token})
        }).then(response => response.data).then((data) => {
            listEntregas = data.datos
        }).catch(function (error) {
            // handle error
            console.log(`😱 problemas obteniendo los datos de lista de entregas del GRB.`);
            console.log(error);
        })
  
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
      return listEntregas;
}

/* 
 * Registra nuevos datos de lista de entrega del plan solidario
 * @return 
 */
export async function crearListaEntregaCovid(action) {
    let resp = null;
    try {
        await API('/covidlistaentregas/insert', {
            method: 'POST',
            data: JSON.stringify({"api":action.token, "listaentregas":action.listaentregas})
        }).then(response => response.data).then((data) => {
            resp = data.mensaje;
        }).catch((error) => {
            resp = '😱 Error ('+error.response.status+'): Problema registrando los datos de listaentregas en el servidor. ' + error.response.data.mensaje;
            console.log(`😱 problemas registrando la tabla de listaentregas del Beneficiario en GRB.`, error.response.data.mensaje);
            return resp;
        })
  
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
    return resp;
}
/* 
 * Actualiza los datos de lista de entrega del plan solidario
 * @return 
 */
export async function actualizarListaEntregaCovid(action) {
    let resp = null;
    try {
        await API('/covidlistaentregas/update', {
            method: 'POST',
            data: JSON.stringify({"api":action.token, "listaentregas":action.listaentregas})
        }).then(response => response.data).then((data) => {
            resp = data.mensaje;
        }).catch((error) => {
            resp = '😱 Error ('+error.response.status+'): Problema guardando los datos de listaentregas en el servidor. ' + error.response.data.mensaje;
            console.log(`😱 problemas actualizando la tabla de listaentregas del Beneficiario en GRB.`, error.response.data.mensaje);
            return resp;
        })
        
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
    return resp;
}
/* 
 * Elimina datos de lista de entrega del plan solidario
 * @return 
 */
export async function eliminarListaEntregaCovid(action) {
    let resp = null;
    try {
        await API('/covidlistaentregas/delete', {
            method: 'POST',
            data: JSON.stringify({"api":action.token, "listaentregas":action.listaentregas})
        }).then(response => response.data).then((data) => {
            resp = data.mensaje;
        }).catch((error) => {
            resp = '😱 Error ('+error.response.status+'): Problema eliminando los datos de listaentregas en el servidor. ' + error.response.data.mensaje;
            console.log(`😱 problemas eliminando la tabla de listaentregas del Beneficiario en GRB.`, error.response.data.mensaje);
            return resp;
        })
  
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
    return resp;
}
/* 
 * Elimina todos los datos de lista de entrega del plan solidario
 * @return 
 */
export async function limpiarListaEntregaCovid(action) {
    let resp = null;
    try {
        await API('/covidlistaentregas/clean', {
            method: 'POST',
            data: JSON.stringify({"api":action.token.token}) //, "listaentregas":action.listaentregas
        }).then(response => response.data).then((data) => {
            resp = data.mensaje;
        }).catch((error) => {
            resp = '😱 Error ('+error.response.status+'): Problema eliminando los datos de listaentregas en el servidor. ' + error.response.data.mensaje;
            console.log(`😱 problemas eliminando la tabla de listaentregas del Beneficiario en GRB.`, error.response.data.mensaje);
            return resp;
        })
  
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
    return resp;
}
/* 
 * Registra datos en batch de lista de entrega del plan solidario
 * @return 
 */
export async function crearBatchListaEntregaCovid(action) {
    let resp = null;
    try {
        await API('/covidlistaentregas/create', {
            method: 'POST',
            data: JSON.stringify({"api":action.token, "listaentregas":action.listaentregas})
        }).then(response => response.data).then((data) => {
            resp = data.mensaje;
            // console.log(resp);
        }).catch((error) => {
            resp = '😱 Error ('+error.response.status+'): Problema ejecutando en batch la lista de entregas en el servidor. ' + error.response.data.mensaje;
            console.log(`😱 problemas batch la lista de entregas en GRB.`, error.response.data.mensaje);
            return resp;
        })
  
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
    return resp;
}

/* 
 * Registra datos en batch de la nueva lista de entrega del plan solidario
 * Esta opción guarda datos válidados, enviados al Exito
 * @return 
 */
export async function validarNuevaEntregaCovid(action) {
    let resp = null;
    try {
        await API('/covidlistaentregas/validar', {
            method: 'POST',
            data: JSON.stringify({"api":action.token, "listaentregas":action.listaentregas})
        }).then(response => response.data).then((data) => {
            resp = data.mensaje;
            // console.log(resp);
        }).catch((error) => {
            resp = '😱 Error ('+error.response.status+'): Problema ejecutando en batch la lista de nuevas entregas en el servidor. ' + error.response.data.mensaje;
            console.log(`😱 problemas batch nueva lista de entregas en GRB.`, error.response.data.mensaje);
            return resp;
        })
  
    } catch (e) {
        console.log(`😱 Axios request failed: ${e}`);
    }
    return resp;
}

