import React, {Fragment,useState, useEffect} from "react";
import ListBeneficiariosWrapper from "./ListBeneficiariosWrapper";

const DisplayListBeneficiarios = ({listBenef, isFiltered}) => {
  const [hasNextPage,setHasNextPage] = useState(true);
  const [isNextPageLoading,setIsNextPageLoading] = useState(false);
  const [items,setItems] = useState(listBenef);

  const _loadNextPage = (...args) => {
    setIsNextPageLoading(true);
    setHasNextPage(items.length < listBenef.length );
    setIsNextPageLoading(false);
   
    if (isFiltered){
      setItems([]); //Object.assign({}, listBenef[10])
    } else {
      setItems( [...items].concat(listBenef[args[0]]) );
    }
    
  };

  useEffect(() => {
    if (isFiltered){
      setHasNextPage(false );
      setIsNextPageLoading(false);
      setItems(listBenef);
    } else {
      setItems([]);
      setIsNextPageLoading(true);
      setHasNextPage(true );
      setIsNextPageLoading(false);
      setItems(listBenef);
    }
      
  }, [isFiltered, listBenef]);


if (items)
  return (
    <Fragment>
      <ListBeneficiariosWrapper 
          hasNextPage={hasNextPage}
          isNextPageLoading={isNextPageLoading}
          items={items}
          loadNextPage={_loadNextPage}
        />

    </Fragment>
  );
  return (
    <></>
  )
}

export default DisplayListBeneficiarios;
