// React
import React, {Fragment, useState, useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";
import ContentEditable from 'react-contenteditable';
import {capitalize, highlightAll, 
    disableNewlines, 
    pastePlainText
} from '../../utils/utils';
import {
    serverMensaje,
    // fetchListReservasHistoricoGolfData,
    fetchTarifasCaddieData,
    fetchListReservasHistoricoGolfDataxFecha,
    updateListaReservasHistoricoGolf,
    insertListaReservasHistoricoGolf,
    deleteListaReservasHistoricoGolf,
    fetchBenefGolfData,
    resetBeneficiarioGolf,
    // setListReservas
} from '../../redux/reducers/cumpli';
import * as XLSX from 'xlsx';
import DatePicker from 'calcite-react/DatePicker';
import moment from 'moment';
import 'moment/locale/es-us';

import {showMsgToaster} from '../ShowMesg';
import {ToastContainer} from 'calcite-react/Toaster'; //notify
import Alert, {AlertMessage} from 'calcite-react/Alert';
import Switch from 'calcite-react/Switch';
import Button, { ButtonGroup } from 'calcite-react/Button';
import Menu, {MenuItem} from 'calcite-react/Menu'; //MenuTitle, 
import Popover from 'calcite-react/Popover';
import Search from 'calcite-react/Search';
import {
    ListItem,
    // ListHeader,
    ListItemTitle,
    ListItemSubtitle
} from 'calcite-react/List';

import SaveIcon from 'calcite-ui-icons-react/SaveIcon';
import UserPlusIcon from 'calcite-ui-icons-react/UserPlusIcon';
import EliminarIcon from 'calcite-ui-icons-react/TrashIcon';
import SiguienteIcon from 'calcite-ui-icons-react/CaretSquareRightIcon';
import AnteriorIcon from 'calcite-ui-icons-react/CaretSquareLeftIcon';
import UserIcon from 'calcite-ui-icons-react/UserIcon';
// import ArticleIcon from 'calcite-ui-icons-react/ArticleIcon';
import PencilIcon from 'calcite-ui-icons-react/PencilIcon';
import DownloadIcon from 'calcite-ui-icons-react/DownloadIcon';
import CheckIcon from 'calcite-ui-icons-react/CheckIcon';
import ClockIcon from 'calcite-ui-icons-react/ClockIcon';
// import CircleDisallowedIcon from 'calcite-ui-icons-react/CircleDisallowedIcon';
import CheckCircleIcon from 'calcite-ui-icons-react/CheckCircleIcon';
import CheckSquareIcon from 'calcite-ui-icons-react/CheckSquareIcon';
// import PhoneIcon from 'calcite-ui-icons-react/PhoneIcon';
import MobileIcon from 'calcite-ui-icons-react/MobileIcon';
// import WidgetsTabsIcon from 'calcite-ui-icons-react/WidgetsTabsIcon';
import CardIcon from 'calcite-ui-icons-react/ConsoleIcon';

import smartphoneIcon from '../../assets/icons/smartphone_icon.png';
import whatsappIcon from '../../assets/icons/whatsapp.png';
import golfhoyosIcon from '../../assets/icons/golf-hoyo.png';

// Styled & Motion Components
import styled from "styled-components";

const SmartphoneIcon = styled.div`
  width: 20px;
  height: 20px;
  position: relative;
  display: inline-flex;
  vertical-align: bottom;
  opacity: 0.4;
  margin-right: 10px;
  background: rgba(255, 255, 255, 0.1) url(${smartphoneIcon}) no-repeat center/cover;
`;

const WhatsappIcon = styled.div`
  width: 20px;
  height: 20px;
  position: relative;
  display: inline-flex;
  vertical-align: bottom;
  opacity: 0.4;
  margin-right: 10px;
  background: rgba(255, 255, 255, 0.1) url(${whatsappIcon}) no-repeat center/cover;
`;

const GolfhoyosIcon = styled.div`
  width: 20px;
  height: 20px;
  position: relative;
  display: inline-flex;
  vertical-align: bottom;
//   opacity: 0.4;
  margin-right: 2px;
  background: rgba(255, 255, 255, 0.1) url(${golfhoyosIcon}) no-repeat center/cover;
`;

const EditReserva = styled.div`
    width: 100%;
    height: 100vh; // calc(100% - 10px);
    position: relative;
    background-color: #fff;
    z-index: 2;
`;
const AgregarReserva = styled.div`
    width: 100%;
    height: 100vh; // calc(100% - 10px);
    position: relative;
    background-color: #fff;
    z-index: 2;
`;

const ReservaEditContent = styled.div`
    width: 100%;
`;

const ModuleValidacion = props => {
    const dispatch = useDispatch();
    const config = useSelector(state => state.config);
    const users = useSelector(state => state.users);
    const listGolf = useSelector(state => state.cumpli.listGolf);
    const listReservas = useSelector(state => state.cumpli.listReservas);
    const listTarifasCaddie = useSelector(state => state.cumpli.listTarifasCaddie);
    const mensaje = useSelector(state => state.cumpli.mensaje);
    const [error, setError] = useState(null);
    const [errorenAgregar, setErrorenAgregar] = useState(null);
    const [R, updateReserva] = useState(null);
    const [fechaReserva, setFechaReserva] = useState(moment(new Date(),'YYYY-MM-DD'));
    const [fechaReservaPickerFocused, setFechaReservaPickerFocused] = useState(false);
    const [celularOpenPopoverIds, setCelularOpenPopoverIds] = useState({});
    const [isDisplayEditReserva, setIsDisplayEditReserva] = useState(false);
    const [isDisplayAgregarReserva, setIsDisplayAgregarReserva] = useState(false);
    const minDate = moment('2017-05-14'); //moment('2020-07-15'); //moment().subtract(2, 'months').startOf('month');
    const maxDate= moment().add(2, 'days'); //moment().add(2, 'months').endOf('month');
    
    const [reservaSelected, setReservaSelected] = useState({
        "id": '-1', //int
        "fechareserva": '', //0000-00-00 00:00:00
        "tiposocio": '', //
        "socioId": '', //
        "socio": '', 
        "numaccion": '', //
        "jugadores": '', 
        "beneficiarioId": '', //
        "beneficiario": '', //
        "categoria": '', //
        "celular": '',
        "cumplida": '0', //
        "sancionbenef": '0',
        "doble": '0', //
        "valorcargada": '0', //
        "valordoble": '0', //
        "prestarservicio": '',
        "campo": '',
        "comentarios": '', //
        "foto": '',
        "actualizacion": '', //
        "version": '' //
    });
    const [itemsReserva, setItemsReserva] = useState([
        {"key":"id", "label": "Id", "value": reservaSelected['id']},
        {"key":"fechareserva", "label": "Hora Reserva", "value": reservaSelected['fechareserva']},
        {"key":"tiposocio", "label": "Tipo de Socio", "value": reservaSelected['tiposocio']},
        {"key":"socioId", "label": "Número de Identificación del Socio", "value": reservaSelected['socioId']},
        {"key":"socio", "label": "Nombre del Socio", "value": reservaSelected['socio']},
        {"key":"numaccion", "label": "Número de Acción", "value": reservaSelected['numaccion']},
        {"key":"jugadores", "label": "Nombre de los Invitados o Jugadores adicionales", "value": reservaSelected['jugadores']},
        {"key":"beneficiarioId", "label": "Número de Identificación del Beneficiario", "value": reservaSelected['beneficiarioId']},
        {"key":"beneficiario", "label": "Nombre del Beneficiario", "value": reservaSelected['beneficiario']},
        {"key":"categoria", "label": "Categoria del Caddie", "value": reservaSelected['categoria']},
        {"key":"celular", "label": "Número de Celular", "value": reservaSelected['celular']},
        {"key":"cumplida", "label": "Servicio o Reserva cumplida (Una cargada)", "value": reservaSelected['cumplida']},
        {"key":"doble", "label": "Beneficiario realizó doble cargada", "value": reservaSelected['doble']},
        {"key":"valorcargada", "label": "Valor una cargada", "value": reservaSelected['valorcargada']},
        {"key":"valordoble", "label": "Valor doble cargada", "value": reservaSelected['valordoble']},
        {"key":"campo", "label": "Campo o número de hoyos", "value": reservaSelected['campo']},
        {"key":"sancionbenef", "label": "Beneficiario Inhabilitado?", "value": reservaSelected['sancionbenef']},
        {"key":"prestarservicio", "label": "Puede prestar el Servicio?", "value": reservaSelected['prestarservicio']},
        // {"key":"comentarios", "label": "Comentarios", "value": reservaSelected['comentarios']},
        // {"key":"actualizacion", "label": "Actualizacion", "value": reservaSelected['actualizacion']},
        // {"key":"version", "label": "Version", "value": reservaSelected['version']},
    ]);

    useEffect(() => {
        if (listTarifasCaddie===null){
            dispatch(fetchTarifasCaddieData({token: users.user.claveAPI}));
            return
        }
        if (listGolf===null){
            dispatch(fetchBenefGolfData({token: users.user.claveAPI}));
            return
        }
        if (listReservas===null){
            dispatch(fetchListReservasHistoricoGolfDataxFecha({token: users.user.claveAPI, fechareserva:moment(fechaReserva).format("YYYY-MM-DD")}));
            return
        }
        if (listGolf===null || listReservas===null){
            return
        }
        // if (listTarifasCaddie && listTarifasCaddie.length > 0){
        //     console.log(listTarifasCaddie);
        // }

        if (listReservas && listReservas.length > 0){
            let openIds = {}; //[...listReservas].map((itm,idx) => {return false});
            listReservas.forEach((itm,idx) => {
                openIds[idx] = false;
            });
            setCelularOpenPopoverIds(openIds);
        }
        if (listGolf && listGolf.length > 0 && listReservas && listReservas.length >= 0){
            // updateReserva([...listReservas]);
            // Agregar algunos campos necesarios para edición de la reserva 
            // y actualización de datos básicos del beneficiario
            // if (R===null || R.length<=0)
            updateReserva([...listReservas].map((itm, ix) => {
                const benef = listGolf.filter(b => b.docId===itm.beneficiarioId);
                if (benef && benef.length>0){
                    const dataBenef =  benef[0];
                    itm['foto'] = dataBenef.foto ? dataBenef.foto : '';
                    itm['celular'] = dataBenef.celular ? dataBenef.celular : '';
                    itm['categoria'] = dataBenef.categoria ? dataBenef.categoria : '';
                    itm['prestarservicio'] = dataBenef.prestarservicio ? dataBenef.prestarservicio : '';
                    // itm['sancionbenef'] = dataBenef.sancionbenef ? dataBenef.sancionbenef : '';
                }
                return itm;
            }));
                
        }

        if (mensaje.listareservas && mensaje.listareservas!=='') {

            const updateListaReservasxFecha = (date) => {
                dispatch(serverMensaje({listareservas:''}));
                dispatch(fetchListReservasHistoricoGolfDataxFecha({token: users.user.claveAPI, fechareserva:moment(fechaReserva).format("YYYY-MM-DD")}));
            }

            if (typeof mensaje.listareservas === 'string' && mensaje.listareservas.toLowerCase().includes('error')){
                showMsgToaster('error', 'Ocurrió un fallo en el servidor', mensaje.listareservas);
                mensaje.listareservas='';
            } 
            else {
                // showMsgToaster('success', 'Reservas', 'Lista de Reserva guardada con exito!');
                showMsgToaster('success',"Reservas", mensaje.listareservas.split('.')[0]);
                // Update Id for edition
                // Cambiar el id por el que devuelve el servidor o transacción de MySQL
                // if (isDisplayAgregarReserva) insertReserva('-1', 'id', String(mensaje.listareservas.split('.')[1]));
                mensaje.listareservas='';
                dispatch(serverMensaje({listareservas:''}));
                // setFechaReserva(fechaReserva);
                // Si los datos del Beneficiario fueron actualizados es necesario cargarlos de nuevo una vez 
                // guardados en el servidor
                dispatch(resetBeneficiarioGolf({listGolf: null})); // Necesario para reflejar los cambios
                updateListaReservasxFecha(fechaReserva);
            }
            
        }

    }, [users.user.claveAPI, mensaje.listareservas, listTarifasCaddie, 
        listReservas, fechaReserva, listGolf, dispatch]);

    const updateDataonServer = (action, reserva) => {
        // console.log(reserva);
        switch (action) {
            case 'insert':
                dispatch(insertListaReservasHistoricoGolf( { token: users.user.claveAPI, reservas:reserva}));
                break;
            case 'remove':
                dispatch(deleteListaReservasHistoricoGolf( { token: users.user.claveAPI, reservas:reserva}));
                break;
            case 'update':
                // Actualizar solo el registro necesario, para no enviar toda la tabla
                dispatch(updateListaReservasHistoricoGolf( { token: users.user.claveAPI, reservas:reserva}));
                break;
            default: // update
                console.log('Acción no disponible');
                break;
        }
        
    }

    const updateItemsReserva = (f, v) => {
        const newitemsReserva = itemsReserva.map((itm, index) => {
            if (itm.key===f){
                itm.value = v;
            }
            return itm;
        });
        setItemsReserva(newitemsReserva);
    };

    const updateReservas = (idx, f, v) => {
        if (R){
            updateReserva(R.map((itm, ix) => {
                if (ix===idx){
                    itm[f] = v;
                }
                return itm;
            }));
        }
    };
    const getBeneficiario = (docid) =>{
        const benef = listGolf.filter(b => b.docId===docid);
        if (benef && benef.length>0) return benef[0]
    }

    const updateListaReservasxFecha = (date) => {
        dispatch(fetchListReservasHistoricoGolfDataxFecha({token: users.user.claveAPI, fechareserva:moment(date).format("YYYY-MM-DD")}));
    }
    // DatePicker 
    const onFechaReservaChange = (date) =>{
        if (date.preventDefault) date.preventDefault()
        if (date){
            setFechaReserva(date);
            updateListaReservasxFecha(date);
        }
    }
    const onFechaReservaFocusChange = ({ focused }) =>{
        setFechaReservaPickerFocused(focused)
    }
    const onBtnAnterior = (evt) =>{
        evt.preventDefault();
        evt.stopPropagation();
        const Beforedate = moment(fechaReserva).subtract(1,'days'); //.format("YYYY-MM-DD");
        setFechaReserva(Beforedate);
        updateListaReservasxFecha(Beforedate);
    }
    const onBtnSiguiente = (evt) =>{
        evt.preventDefault();
        evt.stopPropagation();
        const nextdate = moment(fechaReserva).add(1,'days'); //.format("YYYY-MM-DD");
        setFechaReserva(nextdate);
        updateListaReservasxFecha(nextdate);
    }
    const onBtnValidarReservas = (evt) =>{
        evt.preventDefault();
        evt.stopPropagation();
        if (R){
            updateReserva(R.map((itm, ix) => {
                itm['cumplida'] = '1';
                return itm;
            }));
        }
    }
    const onBtnExportaraExcel = (evt) =>{
        evt.preventDefault();
        evt.stopPropagation();
        // Remover algunos campos no necesarios para el Caddie Master
        let data2excel = [];
        R.forEach((itm, ix) => {
            data2excel.push({
                // id: itm.id, 
                "NUMERO DE ACCION": itm.numaccion, 
                "TIPO SOCIO": itm.tiposocio,
                "SOCIO": itm.socio,
                "USUARIO": itm.socioId,
                // beneficiarioId: itm.beneficiarioId,
                "BENEFICIARIO": itm.beneficiario,
                "HORA": itm.fechareserva ? itm.fechareserva.split(' ')[1] : '',
                "INVITADOS": itm.jugadores,
                "CAMPO": itm.campo,
                "CUMPLIDA": itm.cumplida==='1' ? 'SI' : 'NO',
                "CARGADA": (itm.cumplida==='1' && itm.beneficiario!=='') ? 'SI' : 'NO',
                "DOBLE": (itm.doble==='1' && itm.beneficiario!=='') ? 'SI' : 'NO',
                "SANCIONADO": (itm.sancionbenef==='1' && itm.beneficiario!=='') ? 'SI' : 'NO',
            });
        });
        /* make the worksheet */
        var ws = XLSX.utils.json_to_sheet(data2excel);
        /* add to workbook */
        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Reservas");
        /* generate an XLSX file */
        XLSX.writeFile(wb, "Reservas_"+moment(fechaReserva).format("YYYY-MM-DD")+".xlsx");

    }
    // Manejo de Reservas
    const onBtnAgregarReserva = (evt) =>{
        evt.preventDefault();
        evt.stopPropagation();
        // console.log('onBtnAgregarReserva');
        const newitemsReserva = itemsReserva.map((itm, index) => {
            itm.value = '';
            return itm;
        });
        // Agregar un nuevo registro
        const nuevoRegistro = {
            "id": '-1', //int
            "fechareserva": '', //0000-00-00 00:00:00
            "tiposocio": '', //
            "socioId": '', //
            "socio": '', 
            "numaccion": '', //
            "jugadores": '', 
            "beneficiarioId": '', //
            "beneficiario": '', //
            "categoria": '', //
            "celular": '',
            "cumplida": '0', //
            "sancionbenef": '0',
            "doble": '0', //
            "valorcargada": '0', //
            "valordoble": '0', //
            "campo": '', //
            "prestarservicio": '0',
            "comentarios": '', //
            "foto": '', 
            "actualizacion": '', //
            "version": '' //
        };
        updateReserva([...R].concat([nuevoRegistro]));
        setReservaSelected(nuevoRegistro);
        setItemsReserva(newitemsReserva);
        setIsDisplayAgregarReserva(true);
    }
    const onBtnEditar = (evt, idx) =>{
        evt.preventDefault();
        evt.stopPropagation();
        // console.log('onBtnEditar',R[idx]);
        setReservaSelected(R[idx]);
        const newitemsReserva = itemsReserva.map((itm, index) => {
            itm.value = R[idx][itm.key];
            if (itm.key==='fechareserva') itm.value = R[idx][itm.key].split(' ')[1];
            return itm;
        });
        setItemsReserva(newitemsReserva);
        setIsDisplayEditReserva(true);
    }
    const onBtnSancionar = (evt, idx) =>{
        evt.preventDefault();
        evt.stopPropagation();
        // Toggle
        // updateReservas(idx, 'sancionbenef', R[idx].sancionbenef==='0' ? '1' : '0');
        updateReservas(idx, 'sancionbenef', '1');
        updateDataonServer('update', R[idx]);
    }
    const onBtnEliminar = (evt, idx) =>{
        evt.preventDefault();
        evt.stopPropagation();
        updateDataonServer('remove', R[idx]);
    }
    const onBtnValidar = (evt, idx) =>{
        evt.preventDefault();
        evt.stopPropagation();
        // Revisar si ya cargó él caddie
        // const benefdatos = getBeneficiario(R[idx].beneficiarioId);
        const benefDobles = R.filter(b => b.beneficiarioId===R[idx].beneficiarioId);
        if (benefDobles && benefDobles.length>0) {
            if (benefDobles.length===1) {
                // console.log('Una cargada');
                updateReservas(idx, 'cumplida', R[idx].cumplida==='0' ? '1' : '0');
            } else if (benefDobles.length>=2) {
                // console.log('La Doble');
                // console.log(R[idx].cumplida, benefDobles[0].cumplida, R[idx].doble);
                if (R[idx].cumplida==='0' && benefDobles[0].cumplida==='1'){
                    updateReservas(idx, 'cumplida', '1');
                    updateReservas(idx, 'doble', '1');
                } else if (benefDobles[0].doble==='1' && benefDobles[1].doble==='1'){
                    updateReservas(idx, 'cumplida', '0');
                    updateReservas(idx, 'doble', '0');
                } else if (R[idx].doble==='1'){
                    updateReservas(idx, 'cumplida', '0');
                    updateReservas(idx, 'doble', '0');
                } else if (benefDobles[0].cumplida==='0' && benefDobles[1].cumplida==='0'){
                    // TODO: Identificar cual salida es? primera o segunda y enviar mensaje
                    // console.log('Debe válidar la primera salida');
                    updateReservas(idx, 'cumplida', '1');
                    // updateReservas(idx, 'doble', '1');
                } else if (R[idx].cumplida==='1' || benefDobles[0].cumplida==='1'){
                    updateReservas(idx, 'doble', '1');
                } else if (R[idx].cumplida==='0'){
                    updateReservas(idx, 'cumplida', '1');
                } else {
                    console.log('Ninguna...');
                }
                
            }
        }
        
        updateDataonServer('update', R[idx]);
    }
    const toggleCelularPopover = (idx) =>{
        let openIds = Object.assign({}, celularOpenPopoverIds);
        openIds[idx] = celularOpenPopoverIds[idx]===false ? true : false;
        setCelularOpenPopoverIds(openIds);
    }
    const celularclosePopover = (idx) =>{
        let openIds = Object.assign({}, celularOpenPopoverIds);
        openIds[idx] = false;
        setCelularOpenPopoverIds(openIds);
    }
    
    const onBtnGuardarReserva = () => {
        setIsDisplayEditReserva(false);
        // console.log(reservaSelected);
        updateDataonServer('update', reservaSelected);
    }
    const onBtnCrearReserva = () => {
        setIsDisplayAgregarReserva(false);
        updateDataonServer('insert', reservaSelected);
    }

    const replaceSomeText = (s) =>{
        return s
            .replace(/&nbsp;/g, ' ')
            .replace(/<br>/g, '')
            .replace(/&amp;/g, '&')
            .replace(/&gt;/g, '>')
            .replace(/&lt;/g, '<').trim()
    }
    const validateInput = (f, v, final) => {
        // const regex_onlynum = /^[0-9]*$/i; ///[0-9,]|\./;
        const regex_onlynum = /[0-9,]|\./;
        // const regex_onlyfloat = /^[0-9,]|\./; // /^[0-9.]*$/i;
        const regex_nombres = /^['`´a-zA-ZÀ-ÿ\u00C0-\u017F\s]+$/;
        const regex_gasto = /^[0-9]*(\.[0-9]+)?$/;
        let new_val = null;
        let err = null;
        switch (f) {
            case 'id':
                new_val = replaceSomeText(v);
                if (!regex_onlynum.test(new_val)) {
                    err = 'Dígite solo números válidos';
                    new_val = null;
                }
                if (new_val===null) new_val = '';
                break;
            case 'fechareserva': //0000-00-00 00:00:00
                new_val = replaceSomeText(v).substr(0,30);
                if (final && new_val===''){
                    new_val = replaceSomeText(v).substr(0,30);
                    err = 'Dígite una fecha de reserva válida';
                    new_val = null;
                }
                break;
            case 'tiposocio':
                new_val = v.substr(0,32);
                if (final)
                    new_val = replaceSomeText(v).substr(0,32);
                break;
            case 'socioId':
                // new_val = v.substr(0,15);
                // if (final)
                //     new_val = replaceSomeText(v).substr(0,15);
                new_val = replaceSomeText(v).substr(0,15); //.toUpperCase();
                if (!regex_onlynum.test(new_val)) {
                    err = 'Dígite solo carácteres válidos';
                    new_val = null;
                } else if(new_val.length>15){
                    err = 'Dígite un número válido';
                    new_val = null;
                }
                break;
            case 'socio':
                new_val = replaceSomeText(v).substr(0,120); //.toUpperCase();
                if (!regex_nombres.test(new_val)) {
                    err = 'Dígite carácteres válidos';
                    new_val = null;
                }
                break;
            case 'numaccion':
                // new_val = v.substr(0,32);
                // if (final)
                //     new_val = replaceSomeText(v).substr(0,32);
                new_val = replaceSomeText(v).substr(0,32); //.toUpperCase();
                if (!regex_onlynum.test(new_val)) {
                    err = 'Dígite solo carácteres válidos';
                    new_val = null;
                } else if(new_val.length>10){
                    err = 'Dígite un número válido';
                    new_val = null;
                }
                break;
            case 'jugadores':
                new_val = v.substr(0,600);
                if (final)
                    new_val = v.substr(0,600).replace(/<br>/g, ' ').trim();
                break;
            case 'beneficiarioId':
                // new_val = replaceSomeText(v).substr(0,15); //.toUpperCase();
                // if (!regex_onlynum.test(new_val)) {
                //     err = 'Dígite solo carácteres válidos';
                //     new_val = null;
                // } else if(new_val.length>15){
                //     err = 'Dígite un número válido';
                //     new_val = null;
                // }
                new_val = null;
                break;
            case 'beneficiario':
                new_val = v.substr(0,120); //.toUpperCase();
                // if (!regex_nombres.test(new_val)) {
                //     err = 'Dígite carácteres válidos';
                //     new_val = null;
                // }
                if (final)
                    new_val = replaceSomeText(v).substr(0,120); 
                break;
            case 'categoria':
                new_val = v.substr(0,32);
                if (final)
                    new_val = replaceSomeText(v).substr(0,32);
                break;
            case 'celular':
                new_val = replaceSomeText(v).substr(0,15);
                if (!regex_onlynum.test(v)) {
                    err = 'Dígite solo números válidos de celular';
                    new_val = null;
                } else if(v.length>15){
                    err = 'Dígite un número de celular válido';
                    new_val = null;
                }
                if (new_val===null) new_val = '';
                break;
            case 'prestarservicio': //true,false
                new_val = replaceSomeText(v).substr(0,1);
                if (final && new_val===''){
                    err = 'Seleccione una opción válida en la prestación del servicio';
                    new_val = null;
                }
                break;
            case 'cumplida':
                new_val = replaceSomeText(v).substr(0,1); //1
                if (!regex_onlynum.test(new_val)) {
                    err = 'Dígite solo números válidos';
                    new_val = null;
                } else if(parseInt(new_val)>1){
                    err = 'Dígite un número válido';
                    new_val = null;
                }
                break;
            case 'sancionbenef':
                new_val = replaceSomeText(v).substr(0,1); //1
                if (!regex_onlynum.test(new_val)) {
                    err = 'Dígite solo números válidos';
                    new_val = null;
                } else if(parseInt(new_val)>1){
                    err = 'Dígite un número válido';
                    new_val = null;
                }
                break;
            case 'doble':
                new_val = replaceSomeText(v).substr(0,1); //1
                if (!regex_onlynum.test(new_val)) {
                    err = 'Dígite solo números válidos';
                    new_val = null;
                } else if(parseInt(new_val)>1){
                    err = 'Dígite un número válido';
                    new_val = null;
                }
                break;
            case 'valorcargada':
                new_val = replaceSomeText(v);
                if (!regex_gasto.test(new_val)) {
                    err = 'Dígite solo números válidos';
                    new_val = null;
                }
                if (new_val===null) new_val = '';
                break;
            case 'valordoble':
                new_val = replaceSomeText(v);
                if (!regex_gasto.test(new_val)) {
                    err = 'Dígite solo números válidos';
                    new_val = null;
                }
                if (new_val===null) new_val = '';
                break;
            case 'campo':
                new_val = v.substr(0,32);
                if (final)
                    new_val = replaceSomeText(v).substr(0,32);
                break;
            // case 'comentarios':
            //     new_val = replaceSomeText(v); //.substr(0,500);
            //     break;
            case 'actualizacion': //0000-00-00 00:00:00
                new_val = v.substr(0,30);
                if (final && new_val===''){
                    new_val = replaceSomeText(v).substr(0,30);
                    err = 'Dígite una fecha de actualización válida';
                    new_val = null;
                }
                break;
            case 'version': //0000-00-00 00:00:00
                new_val = v.substr(0,30);
                if (final && new_val===''){
                    new_val = replaceSomeText(v).substr(0,30);
                    err = 'Dígite una fecha de version válida';
                    new_val = null;
                }
                break;
            
            default:
                break;
        }

        if (isDisplayEditReserva) setError(err);
        if (isDisplayAgregarReserva) setErrorenAgregar(err);
        return new_val
    }
    const handleChange = evt => {
        const field = evt.currentTarget.id.split('_')[1];
        const new_val = validateInput(field, evt.target.value, false);
        if(new_val){
            reservaSelected[field] = new_val
        };
    };
    const handleBlur = evt => {
        const val = evt.currentTarget.innerHTML;
        const field = evt.target.id.split('_')[1];
        let new_val = validateInput(field, val, true);
        // const regex_email = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        // if (field==='email' && !regex_email.test(new_val)){
        //     setError('Correo eléctronico o email no válido');
        //     new_val = null;
        // }
        if(new_val || new_val===''){
            if (field==='fechareserva') {
                new_val = moment(fechaReserva).format("YYYY-MM-DD HH:mm:ss").split(' ')[0] + ' '+ new_val;
                reservaSelected[field] = new_val;
                updateItemsReserva(field, new_val.split(' ')[1]);
            } else {
                if (Object.keys(reservaSelected).includes(field)){
                    reservaSelected[field] = new_val;
                }
                updateItemsReserva(field, new_val);
            }
        };
    };

    const onChangeCumplida = (evt, id) => {
        reservaSelected.cumplida =  evt.target.checked ? '1' : '0';
        updateItemsReserva('cumplida', reservaSelected.cumplida );
    }
    const onChangeDobleCargada = (evt, id) => {
        reservaSelected.doble =  evt.target.checked ? '1' : '0';
        updateItemsReserva('doble', reservaSelected.doble );
    }
    const sancionbenefSelectButton = (selected) => {
        reservaSelected.sancionbenef = selected; // === 1 ? '1' : '0';
        updateItemsReserva('sancionbenef', reservaSelected.sancionbenef );
    };
    const prestarservicioSelectButton = (selected) => {
        reservaSelected.prestarservicio = selected;
        updateItemsReserva('prestarservicio', reservaSelected.prestarservicio );
    };

    const [selectedBeneficiarioItem, setSelectedBeneficiarioItem] = useState('');
    const [inputBeneficiarioValue, setInputBeneficiarioValue] = useState('');
    const searchBeneficiarioChanged = (e) => {
        setSelectedBeneficiarioItem(e);
        updateBeneficiarioSelected(e);
    }
    const clearBeneficiarioSearch = () => {
        setInputBeneficiarioValue('');
        setSelectedBeneficiarioItem('');
        updateBeneficiarioSelected('');
    }
    const onBeneficiarioUserAction = (inputValue, selectedItemVal) => {
        setInputBeneficiarioValue(inputValue);
        setSelectedBeneficiarioItem(selectedItemVal);
        // updateBeneficiarioSelected(inputValue);
    }
    const updateBeneficiarioSelected = (selBenef) => {
        if (selBenef){
            if (typeof selBenef === 'string' && selBenef!==''){
                // console.log("Tipo String",selBenef);
                reservaSelected.beneficiario = selBenef;
                updateItemsReserva('beneficiario', reservaSelected.beneficiario );
            } else if (typeof selBenef === 'object' && selBenef!=={}){
                // console.log("Tipo Object",selBenef);
                const benef = getBeneficiario(selBenef.value);
                const tarifa = getTarifaCaddie(benef.categoria, reservaSelected.campo);
                // console.log("tarifa=>", tarifa);
                const vlrcargada = !tarifa ? '0' : tarifa.toString();
                const vlrdoble = !tarifa ? '0' : (parseInt(tarifa) * 2).toString();
                // setReservaSelected(benef);
                reservaSelected.beneficiario = capitalize(benef.nombres +' '+ benef.apellidos, true); //selBenef.value;
                reservaSelected.beneficiarioId = benef.docId;
                reservaSelected.categoria = benef.categoria;
                reservaSelected.celular = benef.celular;
                reservaSelected.foto = benef.foto;
                reservaSelected.cumplida = '0';
                reservaSelected.valorcargada = vlrcargada;
                reservaSelected.valordoble = vlrdoble;
                reservaSelected.sancionbenef = '0'; //benef.sancionbenef;
                reservaSelected.prestarservicio = benef.prestarservicio;
                updateItemsReserva('beneficiario', capitalize(benef.nombres +' '+ benef.apellidos, true) );
                updateItemsReserva('beneficiarioId', benef.docId );
                updateItemsReserva('categoria', benef.categoria );
                updateItemsReserva('celular', benef.celular );
                updateItemsReserva('foto', benef.foto );
                updateItemsReserva('cumplida', '0' );
                updateItemsReserva('valorcargada', vlrcargada );
                updateItemsReserva('valordoble', vlrdoble) ;
                updateItemsReserva('sancionbenef', '0' ); //benef.sancionbenef
                updateItemsReserva('prestarservicio', benef.prestarservicio );
            } else {
                console.error("Tipo de objeto no válido", selBenef);
            }
        } else {
            reservaSelected.beneficiario = '';
            reservaSelected.beneficiarioId = '';
            reservaSelected.categoria = '';
            reservaSelected.celular = '';
            reservaSelected.foto = '';
            reservaSelected.cumplida = '0';
            // reservaSelected.campo = '9 hoyos';
            reservaSelected.valorcargada = '0';
            reservaSelected.valordoble = '0';
            reservaSelected.sancionbenef = '0';
            reservaSelected.prestarservicio = '1';
            updateItemsReserva('beneficiario', '' );
            updateItemsReserva('beneficiarioId', '' );
            updateItemsReserva('categoria', '' );
            updateItemsReserva('celular', '' );
            updateItemsReserva('foto', '' );
            updateItemsReserva('cumplida', '0' );
            updateItemsReserva('valorcargada', '0' );
            updateItemsReserva('valordoble', '0' );
            updateItemsReserva('sancionbenef', '0' );
            updateItemsReserva('prestarservicio', '1' );
        }
        
    }

    const getTarifaCaddie = (ctg, campo) => {
        let tarifa = null;
        const vlr_campo = parseInt(campo.replace(/[^0-9]/g, "")); //campo.replace( /^\D+/g, '');
        // console.log(ctg, vlr_campo);
        const valores_tarifas = [...listTarifasCaddie].filter(t => t.categoria.toLowerCase()===ctg.toLowerCase());
        if (valores_tarifas && valores_tarifas.length>0){
            if (vlr_campo===9){
                // console.log('9 hoyos', campo);
                console.log(valores_tarifas[0]['valor9hoyos']);
                tarifa = parseInt(valores_tarifas[0]['valor9hoyos']);
            } else if (vlr_campo===18){
                // console.log('18 hoyos', campo);
                console.log(valores_tarifas[0]['valor18hoyos']);
                tarifa = parseInt(valores_tarifas[0]['valor18hoyos']);
            } else {
                console.error('Campo u opción no existe, por favor verifique el número de hoyos');
            }
        }
        
        return tarifa;
    }

    // 
    if (listGolf && listReservas)
        return (
        <Fragment>
            <ToastContainer />
            {isDisplayAgregarReserva && <AgregarReserva>
                <div className="val-editform-title">Crear Nueva Reserva</div>
                <ReservaEditContent style={{marginBottom: '10px'}}>
                    {errorenAgregar && <div style={{color: 'red', width: '100%'}}>
                            <Alert red showIcon style={{minWidth: '100%', marginBottom: '10px'}}
                                showCloseLabel 
                                onClose={() => setErrorenAgregar(null)}>
                                <AlertMessage>
                                    Error:{' '}
                                    <strong>{errorenAgregar}</strong>
                                </AlertMessage>
                            </Alert>
                        </div> 
                    }
                    {/* ITEMS */}
                    {
                            itemsReserva.map((itm, index) => {
                                return (<div key={index} className="itemContainer">
                                    <div className="itemContainer-attr" 
                                        style={{backgroundColor:(index % 2 === 0) ? '#f8f8f8': '#fff'}}
                                    >{itm.label}</div>
                                    <div className="itemContainer-value" 
                                        style={{backgroundColor:(index % 2 === 0) ? '#f8f8f8': '#fff'}}
                                    >
                                    {   
                                        itm.key==='cumplida' 
                                        ?
                                            <Switch 
                                                labelPosition={"before" || '' }
                                                style={{margin: '0 0 0.5rem 0'}}
                                                name={ 'cumplida_' + (itemsReserva.id || '999') } 
                                                checked={false} 
                                                // disabled={!isEditable} 
                                                onChange={onChangeCumplida}
                                            />
                                        :
                                        itm.key==='doble' 
                                        ?
                                            <Switch 
                                                labelPosition={"before" || '' }
                                                style={{margin: '0 0 0.5rem 0'}}
                                                name={ 'doble_' + (itemsReserva.id || '999') } 
                                                checked={false} 
                                                // disabled={!isEditable} 
                                                onChange={onChangeDobleCargada}
                                            />
                                        :
                                        itm.key==='sancionbenef'
                                        ?
                                        <ButtonGroup isToggle 
                                            // disabled={!isEditable} 
                                        >
                                            <Button  clear={itm.value !== '1'}
                                                onClick={() => {  sancionbenefSelectButton('1') }}
                                            >Si</Button>
                                            <Button clear={itm.value !== '0'}
                                                onClick={() => { sancionbenefSelectButton('0') }}
                                            >No</Button>
                                        </ButtonGroup>
                                        :
                                        itm.key==='prestarservicio'
                                        ?
                                        <ButtonGroup isToggle 
                                            // disabled={!isEditable} 
                                        >
                                            <Button  clear={itm.value !== '1'}
                                                onClick={() => {  prestarservicioSelectButton('1') }}
                                            >Si</Button>
                                            <Button clear={itm.value !== '0'}
                                                onClick={() => { prestarservicioSelectButton('0') }}
                                            >No</Button>
                                        </ButtonGroup>
                                        :
                                        itm.key==='beneficiario'
                                        ?
                                        <Search positionFixed className="val-search-benef-input"
                                            virtualized
                                            virtualizedMenuWidth={250}
                                            virtualizedRowHeight={75}
                                            placeholder={'Buscar Beneficiario...'}
                                            searchIcon={false}
                                            inputValue={inputBeneficiarioValue || itm.value}
                                            selectedItem={selectedBeneficiarioItem}
                                            onChange={searchBeneficiarioChanged}
                                            onUserAction={onBeneficiarioUserAction}
                                            onRequestClear={clearBeneficiarioSearch}
                                            >
                                            {listGolf.map((item,ix) => {
                                                // const fotoSelBenef = item.foto==='' ? require('../../assets/team/usuario.jpg') : config.ECASE_URL +'user_images/'+ item.foto; 
                                                const fotoSelBenef = item.foto==='' ? process.env.PUBLIC_URL + '/imgs/team/usuario.jpg' : config.ECASE_URL +'user_images/'+ item.foto; 
                                                return ( item.prestarservicio==='1' && 
                                                <ListItem
                                                    key={item.ix}
                                                    value={item.docId}
                                                    label={capitalize(item.nombres +' '+ item.apellidos, true)}
                                                    leftNode={fotoSelBenef ? <img src={fotoSelBenef} width={32} alt=" " /> : <UserIcon size={32} />}
                                                >
                                                    <ListItemTitle>{capitalize(item.nombres +' '+ item.apellidos, true)}</ListItemTitle>
                                                    <ListItemSubtitle>{item.actividad +' '+ item.categoria}</ListItemSubtitle>
                                                </ListItem>
                                                )
                                            })}
                                        </Search>
                                        :
                                        (itm.key==='id' || itm.key==='beneficiarioId')
                                        ?
										<ContentEditable
                                            id={'addreserva_'+itm.key}
                                            style={{color:'#ccc'}}
											html={itm.value || ''} // innerHTML of the editable div
											className='itemContainer-value-long'
											disabled={true}      // use true to disable editing
											tagName='div' // Use a custom HTML tag (uses a div by default)
										/>
                                        :
                                        <ContentEditable
											id={'addreserva_'+itm.key}
											html={itm.value || ''} // innerHTML of the editable div
											className='itemContainer-value-long'
											// disabled={!isEditable}      // use true to disable editing
											onPaste={pastePlainText} // En caso se copie y pegue de otra pagina de internet
											onChange={handleChange} // handle innerHTML change
											onKeyPress={disableNewlines}  
											onFocus={highlightAll}
											onBlur={handleBlur}
											tagName='div' // Use a custom HTML tag (uses a div by default)
										/>
                                    }

                                    </div>
                                </div>)
                            })
                        }

                        <div className="Observaciones-Comentarios">
                            <div className="benef-texto-observaciones-title">Comentarios en general:</div>
                            <div className="benef-texto-observaciones"> 
                                <ContentEditable
                                    id={'addreserva_comentarios'}
                                    html={reservaSelected.comentarios || ''} // innerHTML of the editable div
                                    onPaste={pastePlainText}
                                    onChange={handleChange} // handle innerHTML change
                                    onFocus={highlightAll}
                                    onBlur={handleBlur}
                                    tagName='div' // Use a custom HTML tag (uses a div by default)
                                    style={{ "minHeight": "40px", "border": "1px dashed" }}
                                />
                            </div>
                        </div>
                        <div className="val-editform-btnSave">
                            <Button iconPosition="before" 
                                onClick={onBtnCrearReserva}
                                icon={<SaveIcon size={16} />}
                            >
                                Crear Reserva
                            </Button>
                        </div>
                    </ReservaEditContent>

                </AgregarReserva>
            }

            {isDisplayEditReserva && <EditReserva>
                <div className="val-editform-title">Editar Reserva</div>
                <ReservaEditContent style={{marginBottom: '10px'}}>
                    {error && <div style={{color: 'red', width: '100%'}}>
                            <Alert red showIcon style={{minWidth: '100%', marginBottom: '10px'}}
                                showCloseLabel 
                                onClose={() => setError(null)}>
                                <AlertMessage>
                                    Error:{' '}
                                    <strong>{error}</strong>
                                </AlertMessage>
                            </Alert>
                        </div> 
                    }
                    {/* ITEMS */}
                        {
                            itemsReserva.map((itm, index) => {
                                return (<div key={index} className="itemContainer">
                                    <div className="itemContainer-attr" 
                                        style={{backgroundColor:(index % 2 === 0) ? '#f8f8f8': '#fff'}}
                                    >{itm.label}</div>
                                    <div className="itemContainer-value" 
                                        style={{backgroundColor:(index % 2 === 0) ? '#f8f8f8': '#fff'}}
                                    >
                                    {   
                                        itm.key==='cumplida' 
                                        ?
                                            <Switch 
                                                labelPosition={"before" || '' }
                                                style={{margin: '0 0 0.5rem 0'}}
                                                name={ 'cumplida_' + itemsReserva.id } 
                                                checked={ itm.value==='0' ? false : true} 
                                                // disabled={!isEditable} 
                                                onChange={onChangeCumplida}
                                            />
                                        :
                                        itm.key==='doble' 
                                        ?
                                            <Switch 
                                                labelPosition={"before" || '' }
                                                style={{margin: '0 0 0.5rem 0'}}
                                                name={ 'doble_' + (itemsReserva.id) } 
                                                checked={ itm.value==='0' ? false : true} 
                                                // disabled={!isEditable} 
                                                onChange={onChangeDobleCargada}
                                            />
                                        :
                                        itm.key==='sancionbenef'
                                        ?
                                        <ButtonGroup isToggle 
                                            // disabled={!isEditable} 
                                        >
                                            <Button  clear={itm.value !== '1'}
                                                onClick={() => {  sancionbenefSelectButton('1') }}
                                            >Si</Button>
                                            <Button clear={itm.value !== '0'}
                                                onClick={() => { sancionbenefSelectButton('0') }}
                                            >No</Button>
                                        </ButtonGroup>
                                        :
                                        itm.key==='prestarservicio'
                                        ?
                                        <ButtonGroup isToggle 
                                            // disabled={!isEditable} 
                                        >
                                            <Button  clear={itm.value !== '1'}
                                                onClick={() => {  prestarservicioSelectButton('1') }}
                                            >Si</Button>
                                            <Button clear={itm.value !== '0'}
                                                onClick={() => { prestarservicioSelectButton('0') }}
                                            >No</Button>
                                        </ButtonGroup>
                                        :
                                        itm.key==='beneficiario'
                                        ?
                                        <Search positionFixed className="val-search-benef-input"
                                            virtualized
                                            virtualizedMenuWidth={250}
                                            virtualizedRowHeight={75}
                                            placeholder={'Buscar Beneficiario...'}
                                            searchIcon={false}
                                            inputValue={inputBeneficiarioValue || itm.value}
                                            selectedItem={selectedBeneficiarioItem}
                                            onChange={searchBeneficiarioChanged}
                                            onUserAction={onBeneficiarioUserAction}
                                            onRequestClear={clearBeneficiarioSearch}
                                            >
                                            {listGolf.map((item,ix) => {
                                                // const fotoSelBenef = item.foto==='' ? require('../../assets/team/usuario.jpg') : config.ECASE_URL +'user_images/'+ item.foto; 
                                                const fotoSelBenef = item.foto==='' ? process.env.PUBLIC_URL + '/imgs/team/usuario.jpg' : config.ECASE_URL +'user_images/'+ item.foto; 
                                                return ( item.prestarservicio==='1' && 
                                                <ListItem
                                                    key={item.ix}
                                                    value={item.docId}
                                                    label={capitalize(item.nombres +' '+ item.apellidos, true)}
                                                    // leftNode={<UserIcon size={32} />}
                                                    leftNode={fotoSelBenef ? <img src={fotoSelBenef} width={32} alt=" " /> : <UserIcon size={32} />}
                                                >
                                                    <ListItemTitle>{capitalize(item.nombres +' '+ item.apellidos, true)}</ListItemTitle>
                                                    <ListItemSubtitle>{item.actividad +' '+ item.categoria}</ListItemSubtitle>
                                                </ListItem>
                                                )
                                            })}
                                        </Search>
                                        :
                                        (itm.key==='id' || itm.key==='beneficiarioId')
                                        ?
										<ContentEditable
                                            id={'reserva_'+itm.key}
                                            style={{color:'#ccc'}}
											html={itm.value || ''} // innerHTML of the editable div
											className='itemContainer-value-long'
											disabled={true}      // use true to disable editing
											tagName='div' // Use a custom HTML tag (uses a div by default)
										/>
                                        :
                                        <ContentEditable
											id={'reserva_'+itm.key}
											html={itm.value || ''} // innerHTML of the editable div
											className='itemContainer-value-long'
											// disabled={!isEditable}      // use true to disable editing
											onPaste={pastePlainText} // En caso se copie y pegue de otra pagina de internet
											onChange={handleChange} // handle innerHTML change
											onKeyPress={disableNewlines}  
											onFocus={highlightAll}
											onBlur={handleBlur}
											tagName='div' // Use a custom HTML tag (uses a div by default)
										/>
                                    }

                                    </div>
                                </div>)
                            })
                        }

                        <div className="Observaciones-Comentarios">
                            <div className="benef-texto-observaciones-title">Comentarios en general:</div>
                            <div className="benef-texto-observaciones"> 
                                <ContentEditable
                                    id={'reserva_comentarios'}
                                    html={reservaSelected.comentarios || ''} // innerHTML of the editable div
                                    // disabled={!isEditable}        // use true to disable editing
                                    onPaste={pastePlainText}
                                    onChange={handleChange} // handle innerHTML change
                                    onFocus={highlightAll}
                                    onBlur={handleBlur}
                                    tagName='div' // Use a custom HTML tag (uses a div by default)
                                    style={{ "minHeight": "40px", "border": "1px dashed" }}
                                />
                            </div>
                        </div>
                        {/* <div className="val-editform-texto-actualizacion">Última actualización: {reservaSelected['actualizacion']}</div> */}
                        <div className="val-editform-btnSave">
                            <Button iconPosition="before" 
                                onClick={onBtnGuardarReserva}
                                icon={<SaveIcon size={16} />}
                            >
                                Guardar Cambios
                            </Button>
                        </div>
                    </ReservaEditContent>
                
            </EditReserva>
            }

            {/* <h3>Validación {users.user.permisos}</h3> */}
            {!isDisplayEditReserva && !isDisplayAgregarReserva &&
            <div className="validacion-container">   
                <div className="validacion-tabla-reservas">
                    <div className="val-tablareservas-header">
                        <div className="val-tablareservas-header-opts">
                            <div className="val-tablareservas-header-opts-agregar">
                                <Button inline small iconPosition="before" 
                                    onClick={onBtnAgregarReserva}
                                    icon={<UserPlusIcon size={16} />}
                                >
                                    Agregar Reserva
                                </Button>
                            </div>
                            <div className="val-tablareservas-header-opts-validar">
                                <Button inline small iconPosition="before" 
                                    onClick={onBtnValidarReservas}
                                    icon={<CheckSquareIcon size={16} />}
                                >
                                    Validar Reservas
                                </Button>
                            </div>
                            <div className="val-tablareservas-header-opts-exportar">
                                <Button inline small iconPosition="before" 
                                    onClick={onBtnExportaraExcel}
                                    icon={<DownloadIcon size={16} />}
                                >
                                    Exportar a Excel
                                </Button>
                            </div>
                        </div>
                        <div className="val-tablareservas-header-fechareserva">
                                <div>
                                    <Button iconButton
                                        onClick={onBtnAnterior}
                                        icon={<AnteriorIcon/>}
                                    ></Button>
                                </div>
                                <div className="validacion-fechareserva">
                                    <DatePicker 
                                        placeholder="Fecha Reserva"
                                        id="fechaReserva"
                                        date={fechaReserva}
                                        focused={fechaReservaPickerFocused}
                                        onDateChange={onFechaReservaChange}
                                        onFocusChange={onFechaReservaFocusChange}
                                        numberOfMonths={1}
                                        // enableOutsideDays={true}
                                        // disabled={!isEditable}
                                        readOnly={true}
                                        hideInputIcon
                                        disableScroll={false}
                                        displayFormat={'MMM DD, YYYY'} // 'MMM DD YYYY' //'DD-MM-YYYY'
                                        // renderMonthElement={renderMonthElement}
                                        isOutsideRange={fechaReserva => fechaReserva.isBefore(minDate) || fechaReserva.isAfter(maxDate)}
                                        monthYearSelectionMode="MONTH_YEAR"
                                        // yearSelectDates={{"startYear" : new moment().subtract(new Date().getFullYear(), 100).year(), "endYear" : new moment().year()}}
                                        yearSelectDates={{"startYear" : 2017, "endYear" : new moment().year() }}
                                        
                                        // appendToBody={true}
                                    />
                                </div>
                                <div>
                                    <Button iconButton
                                            onClick={onBtnSiguiente}
                                            icon={<SiguienteIcon/>}
                                    ></Button>
                                </div>
                        </div>
                    </div>
                    <div className="val-tablareservas-contenido">
                        <div className="val-tablareservas-lista">
                            {
                                R && R.length>0 
                                ?
                                R.map((lr, idx) => {
                                    const numreserva = idx;
                                    const hora = moment(lr.fechareserva).format("YYYY-MM-DD HH:mm").split(' ')[1];
                                    // let benefdatos = getBeneficiario(lr.beneficiarioId);
                                    // if (benefdatos===null || benefdatos===undefined) {
                                    //     benefdatos = {}
                                    // }
                                    // const fotoBenef = lr.foto==='' ? require('../../assets/team/usuario.jpg') : config.ECASE_URL +'user_images/'+ lr.foto; 
                                    const fotoBenef = lr.foto==='' ? process.env.PUBLIC_URL + '/imgs/team/usuario.jpg' : config.ECASE_URL +'user_images/'+ lr.foto; 
                
                                    return (
                                        <div key={idx} className="val-tablareservas-lista-row" 
                                            style={{backgroundColor:(idx % 2 === 0) ? '#eaffec57': '#fff'}}
                                            >
                                            <div className="val-tablareservas-listabenef-hora-container">
                                                <div className="val-tablareservas-listarow-id">{idx+1}</div>
                                                <div className="val-tablareservas-listabenef-hora">{hora}</div>
                                                { lr.cumplida==='0' ?
                                                    <div className="val-tablareservas-cumplio"><ClockIcon size={16}/></div>
                                                    :
                                                    <div className="val-tablareservas-cumplio"><CheckIcon size={32}/></div>
                                                }
                                                <div className="val-tablareservas-listabenef-campo"><GolfhoyosIcon size={20}/>{lr.campo.replace(' hoyos','')}</div>
                                            </div>
                                            <div className="val-tablareservas-lista-socio">
                                                <div className="val-tablareservas-lista-socio-foto">Foto</div>
                                                <div>
                                                    <div className="val-tablareservas-lista-tiposocio">{lr.tiposocio} - <span>{lr.numaccion}</span></div>
                                                    <div className="val-tablareservas-lista-nombre">{capitalize(lr.socio,true)}</div>
                                                    <div className="val-tablareservas-lista-docId"><CardIcon size={16} style={{marginRight:'5px',position:'relative',top:'1px'}}/> {lr.socioId}
                                                    <Button inline small iconPosition="before" 
                                                        onClick={(evt) => onBtnValidar(evt, idx)}
                                                        icon={<CheckCircleIcon size={16} />}
                                                    >
                                                        Validar
                                                    </Button></div>
                                                </div>
                                                <div className="val-tablareservas-lista-jugadores">{capitalize(lr.jugadores,true)}</div>
                                            </div>
                                            <div className="val-tablareservas-lista-benef">
                                                {
                                                    lr.beneficiario!=='' ?
                                                    <div className="val-tablareservas-listabenef-datos">
                                                        <div className="val-tablareservas-lista-foto-container">
                                                            <div className="val-tablareservas-lista-benef-foto"><img src={fotoBenef} alt=" "/></div>
                                                            {lr.categoria==='' && <div className="val-tablareservas-lista-benef-categoria">{lr.categoria}</div>}
                                                            {lr.categoria==='1' && <div className="val-tablareservas-lista-benef-categoria c1">{lr.categoria}</div>}
                                                            {lr.categoria==='2' && <div className="val-tablareservas-lista-benef-categoria c2">{lr.categoria}</div>}
                                                            {lr.categoria==='3' && <div className="val-tablareservas-lista-benef-categoria c3">{lr.categoria}</div>}
                                                            {lr.categoria==='PE1' && <div className="val-tablareservas-lista-benef-categoria pe1">{lr.categoria}</div>}
                                                            {lr.categoria==='PE2' && <div className="val-tablareservas-lista-benef-categoria pe2">{lr.categoria}</div>}
                                                            {lr.categoria==='PE3' && <div className="val-tablareservas-lista-benef-categoria pe3">{lr.categoria}</div>}
                                                            {lr.categoria==='PP3' && <div className="val-tablareservas-lista-benef-categoria pp3">{lr.categoria}</div>}
                                                            {lr.categoria==='PP' && <div className="val-tablareservas-lista-benef-categoria pp3">{lr.categoria}</div>}
                                                            {lr.categoria==='EF1' && <div className="val-tablareservas-lista-benef-categoria ef1">{lr.categoria}</div>}
                                                        </div>
                                                        <div>
                                                            <div className="val-tablareservas-lista-nombre">{capitalize(lr.beneficiario,true)}</div>
                                                            <div className="val-tablareservas-lista-docId">
                                                                <CardIcon size={16} style={{marginRight:'5px',position:'relative',top:'1px'}}/> {lr.beneficiarioId} 
                                                                <MobileIcon size={16} style={{marginRight:'4px'}}/>
                                                                { lr.celular ? 
                                                                    <Popover
                                                                        targetEl={<Button transparent extraSmall style={{padding:'0px',top:'-3px',color:'#a2a2a2'}}
                                                                                onClick={() => toggleCelularPopover(numreserva)}>{lr.celular}</Button>}
                                                                            open={celularOpenPopoverIds[numreserva]}
                                                                            onRequestClose={() => celularclosePopover(numreserva)}
                                                                            closeOnSelect
                                                                            positionFixed
                                                                            // appendToBody
                                                                        >
                                                                        <Menu style={{ maxWidth: '150px' }}>
                                                                            {/* <MenuTitle>Contactar Caddie</MenuTitle> */}
                                                                            <MenuItem href={"tel:+57" + lr.celular} target="_new">
                                                                                <SmartphoneIcon/>Llamar al {lr.celular}
                                                                            </MenuItem>
                                                                            <MenuItem href={"https://api.whatsapp.com/send?phone=57" + lr.celular + "&text=Hola "+ capitalize(lr.beneficiario,true) +", soy de la Fundación Club Farallones..."}  target="_new">
                                                                                <WhatsappIcon/> Escribir mensaje
                                                                            </MenuItem>
                                                                            {/* <MenuTitle>Más opciones</MenuTitle>
                                                                            <MenuItem>Correo electrónico</MenuItem>
                                                                            <MenuItem>Llamar al Acudiente</MenuItem> */}
                                                                        </Menu>
                                                                    </Popover>
                                                                    : ''
                                                                } 
                                                            </div>
                                                            <div className="val-tablareservas-lista-cumplida">
                                                                { lr.cumplida==='0' ?
                                                                    <div className="val-tablareservas-lista-cumplida-no" tilte='Carga Pendiente'>P</div>
                                                                    :
                                                                    lr.doble==='0' ?
                                                                    <div className="val-tablareservas-lista-cumplida-si" tilte='Carga Cumplida'>C</div>
                                                                    :
                                                                    <div className="val-tablareservas-lista-cumplida-si-doble" tilte='Carga Doble'>D</div>
                                                                }
                                                                {/* <div className="val-tablareservas-listabenef-btncambiar">Cambiar</div> */}
                                                                
                                                                { lr.sancionbenef==='1' ?
                                                                    <div className="val-tablareservas-lista-sancionado">Inhabilitado</div>
                                                                    :
                                                                    <Button inline small iconPosition="before" 
                                                                        onClick={(evt) => onBtnSancionar(evt, idx)}
                                                                        icon={<UserIcon size={16} />}
                                                                    >
                                                                        Inhabilitar
                                                                    </Button>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className="val-tablareservas-listabenef-sinasignar">
                                                        {/* <div className="val-tablareservas-listabenef-btnasignar">Asignar Caddie</div> */}
                                                        {/* <div style={{textAlign:'left'}}>
                                                            <Button clear small fullWidth iconPosition="before" 
                                                                onClick={(evt) => onBtnAsignarCaddie(evt, idx)}
                                                                icon={<SaveIcon size={16} />}
                                                            >
                                                                Asignar Caddie
                                                            </Button>
                                                        </div>*/}
                                                    </div> 
                                                }
                                            </div>
                                            <div className="val-tablareservas-lista-acciones">
                                                <div className="val-tablareservas-lista-acciones-botones">
                                                    <Button clear small fullWidth iconPosition="before" 
                                                        onClick={(evt) => onBtnEditar(evt, idx)}
                                                        icon={<PencilIcon size={16} />}
                                                    >
                                                        Editar
                                                    </Button>
                                                </div>
                                                <div className="val-tablareservas-lista-acciones-botones">
                                                    <Button clear small fullWidth iconPosition="before" 
                                                        onClick={(evt) => onBtnEliminar(evt, idx)}
                                                        icon={<EliminarIcon size={16} />}
                                                    >
                                                        Eliminar
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                                :
                                    <div><h2>No hay registros para la fecha seleccionada</h2></div>
                            }

                        </div>
                    </div>
                </div>
            </div>
            }
        </Fragment>
    );

return (<></>)
}
  
export default ModuleValidacion;