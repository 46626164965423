// // Copyright 2020 Ernesto Girón E.
// // Licensed under the Apache License, Version 2.0 (the "License");
// // you may not use this file except in compliance with the License.
// // You may obtain a copy of the License at
// //     http://www.apache.org/licenses/LICENSE-2.0
// // Unless required by applicable law or agreed to in writing, software
// // distributed under the License is distributed on an "AS IS" BASIS,
// // WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// // See the License for the specific language governing permissions and
// // limitations under the License.​

export const capitalize = (str, lower = false) => (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, match => match.toUpperCase());

export const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

export const getFirstWords = (str) => {
    var firstWords = [];
    let words = str.split(' ');
    for (let i = 0; i < words.length; i++) {
        const w = words[i];
        //const  firstWord = w.charAt(0).toUpperCase();
        firstWords.push(w.charAt(0).toUpperCase());
    } 
    // for (var i=0;i<str.length;i++) {
    //     var codeLine = str[i];
    //     var firstWord = codeLine.substr(0, codeLine.indexOf(" "));
    //     firstWords.push(firstWord);
    // }
    return firstWords.join('');
}

export const removeHTMLTags = (html) =>{
    return html.replace(/(<([^>]+)>)/gi, "");
}

export function isEven(n) {
    return n % 2 === 0;
};
 
export function isOdd(n) {
    return Math.abs(n % 2) === 1;
};
    
export const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

export function _formatMoney(number) {
    return number.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
}

export function formatMoney(amount, decimalCount = 2, decimal = ".", thousands = ",") {
    try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
  
      const negativeSign = amount < 0 ? "-" : "";
  
      let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
      let j = (i.length > 3) ? i.length % 3 : 0;
  
      return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
    } catch (e) {
      console.log(e)
    }
};

export function calculate_age (f) {
    const fn = f.split('-');
    const birth_year = fn[0];
    const birth_month = fn[1];
    const birth_day = fn[2];
    const today_date = new Date();
    const today_year = today_date.getFullYear();
    const today_month = today_date.getMonth();
    const today_day = today_date.getDate();
    let age = today_year - birth_year;
    
    if (today_month < (birth_month - 1)) {
        age--;
    }
    if (((birth_month - 1) === today_month) && (today_day < birth_day)) {
        age--;
    }
    return age;
}

export const formatCelular = (cel) => {
    const f_val = cel.replace(/\D[^.]/g, "");
    const f_value = f_val.slice(0,3)+"-"+f_val.slice(3,6)+"-"+f_val.slice(6);
    return f_value;
};

export const disableNewlines = evt => {
    const keyCode = evt.keyCode || evt.which
    if (keyCode === 13) {
        evt.returnValue = false
        if (evt.preventDefault) evt.preventDefault()
    }
};

export const highlightAll = () => {
    setTimeout(() => {
        document.execCommand('selectAll', false, null)
    }, 0)
};

export const pastePlainText = evt => {
    evt.preventDefault()
    const text = evt.clipboardData.getData('text/plain')
    document.execCommand('insertHTML', false, text)
};

export const trimSpaces = string => {
    return string
        .replace(/&nbsp;/g, '')
        .replace(/&amp;/g, '&')
        .replace(/&gt;/g, '>')
        .replace(/&lt;/g, '<')
};

export const validateNum = evt => {
    const keyCode = evt.keyCode || evt.which
    const string = String.fromCharCode(keyCode)
    const regex = /[0-9,]|\./
    
    if (!regex.test(string)) {
        evt.returnValue = false
        if (evt.preventDefault) evt.preventDefault()
    }
};

// Remove Duplicates functions
// returns the union of two arrays where duplicate objects with the same 'prop' are removed
export const removeDuplicatesWith = (a, b, prop) => a.filter(x => !b.find(y => x[prop] === y[prop]));
export const uniqByKeepLast = (data, key) => {
    return [
        ...new Map(
            data.map(x => [key(x), x])
        ).values()
    ]
};

export const RemoveDuplicates = (array, key) => {
    return array.reduce((arr, item) => {
        const removed = arr.filter(i => i[key] !== item[key]);
        return [...removed, item];
    }, []);
};
// Remover duplicados de entregas
export const RemoveEntregasDuplicadas = (array, key, key2) => {
    return array.reduce((arr, item) => {
        let removed = [];
        if (key && key2 && key!=='' && key2!==''){
            removed = arr.filter(i => ( (i[key] !== item[key]) && (i[key2] === item[key2]) ));
        } else {
            removed = arr.filter(i => i[key] !== item[key]);
        }
        
        return [...removed, item];
    }, []);
};

// Returns true/false based on a duplicate object being found   
// Complexity: O(N^2) -- the bigger the array, the slower it may become
export function duplicatesOnly(v1, i1, self) {
    let ndx = self.findIndex(function(v2, i2) {
      // make sure not looking at the same object (using index to verify)
      // use JSON.stringify for object comparison
      return (i1 !== i2 && JSON.stringify(v1) === JSON.stringify(v2))
    })
    return i1 !== ndx && ndx !== -1
}

export const getDuplicates = (array, key) => {
    let duplicates = Object.values(array.reduce((c, v) => {
        let k = v[key];
        c[k] = c[k] || [];
        c[k].push(v);
        return c;
    }, {})).reduce((c, v) => v.length > 1 ? c.concat(v) : c, []);

    return duplicates
}

export const uniq = (a, param) => {
    return a.filter(function(item, pos, array){
        return array.map(function(mapItem){ return mapItem[param]; }).indexOf(item[param]) === pos;
    })
};

export const getUniqueListBy = (arr, key) => { //ES6+
    return [...new Map(arr.map(item => [item[key], item])).values()]
}

/**
 * Returns a scaled number within its source bounds to the desired target bounds.
 * @param {number} n - Unscaled number
 * @param {number} tMin - Minimum (target) bound to scale to
 * @param {number} tMax - Maximum (target) bound to scale to
 * @param {number} sMin - Minimum (source) bound to scale from
 * @param {number} sMax - Maximum (source) bound to scale from
 * @returns {number} The scaled number within the target bounds.
 */
export const scaleBetween = (n, tMin, tMax, sMin, sMax) => {
    return (tMax - tMin) * (n - sMin) / (sMax - sMin) + tMin;
}

// Cálculo de Afectación
export const calcularAfectacion = (params, listCriterios) => {
    const NUM_CRITERIOS = 30; //30
    const MAX_ESCALA = 3; // 1 -> Min ... 3 -> Max
    let vlr_prestarservicio = 1;
    if (params.prestarservicio && params.prestarservicio!=='' && listCriterios['grb_criterios_prestarservicio']){
        let obj = listCriterios['grb_criterios_prestarservicio'].filter(c => c.clase.toLowerCase()===params.prestarservicio.toLowerCase());
        if (obj && obj.length>0){
            vlr_prestarservicio = parseInt(obj[0].valor);
        }
    }
    let vlr_jefehogar = 1;
    if (params.jefehogar && params.jefehogar!=='' && listCriterios['grb_criterios_cabezahogar']){
        let obj = listCriterios['grb_criterios_cabezahogar'].filter(c => c.clase.toLowerCase()===params.jefehogar.toLowerCase());
        if (obj && obj.length>0){
            vlr_jefehogar = parseInt(obj[0].valor);
        }
    }
    let vlr_personasacargo = 1;
    if (params.personasacargo && params.personasacargo!=='' && listCriterios['grb_criterios_personacargo']){
        let obj = listCriterios['grb_criterios_personacargo'].filter(c => parseInt(c.clase)===parseInt(params.personasacargo));
        if (obj && obj.length>0){
            vlr_personasacargo = parseInt(obj[0].valor);
        }
    }
    let vlr_numhijos = 1;
    if (params.numhijos && params.numhijos!=='' && listCriterios['grb_criterios_numhijos']){
        let obj = listCriterios['grb_criterios_numhijos'].filter(c => parseInt(c.clase)===parseInt(params.numhijos));
        if (obj && obj.length>0){
            vlr_numhijos = parseInt(obj[0].valor);
        }
    }
    // TODO: Consignar información actualizada de si tiene o no familia en el Club el Beneficiario
    let vlr_tienefmliaclub = 1;
    if (params.tienefmliaclub && params.tienefmliaclub!=='' && listCriterios['grb_criterios_tienefmliaclub']){
        let obj = listCriterios['grb_criterios_tienefmliaclub'].filter(c => c.clase.toLowerCase()===params.tienefmliaclub.toLowerCase());
        if (obj && obj.length>0){
            vlr_tienefmliaclub = parseInt(obj[0].valor);
        }
    }

    let vlr_actividad = 1;
    if (params.actividad && params.actividad!=='' && listCriterios['grb_criterios_actividad']){
        let obj = listCriterios['grb_criterios_actividad'].filter(c => c.clase===params.actividad);
        if (obj && obj.length>0){
            vlr_actividad = parseInt(obj[0].valor);
        }
    }
    let vlr_categoria = 1;
    if (params.categoria && params.categoria!=='' && listCriterios['grb_criterios_categoria']){
        let obj = listCriterios['grb_criterios_categoria'].filter(c => c.clase===params.categoria);
        if (obj && obj.length>0){
            vlr_categoria = parseInt(obj[0].valor);
        }
    }
    let vlr_asistencia = 1;
    if (params.asistencia && params.asistencia!=='' && listCriterios['grb_criterios_asistencia']){
        let obj = null;
        listCriterios['grb_criterios_asistencia'].forEach((c) => {
            const rangos = c.clase.split('-');
            const vlr1 = parseInt(rangos[0].trim()); // perc x 100 vienen en enteros los rangos
            const vlr2 = (rangos.length>1) ? parseInt(rangos[1].trim()) : null;
            if (Math.ceil(params.asistencia*100)>=vlr1 && Math.ceil(params.asistencia*100)<=vlr2){
                obj = c;
            }
        });
        if (obj){
            vlr_asistencia = parseInt(obj.valor);
            // console.log(vlr_asistencia , parseInt(params.asistencia*100));
        }
    }

    let vlr_edad = 1;
    if (params.edad && params.edad!=='' && listCriterios['grb_criterios_edad']){
        // 0 - 17
        // 18 - 20
        // 21 - 25
        // 26 - 30
        // 31 - 35
        // 36 - 40
        // 41 - 45
        // 45 - 100
        // Extrae valores de rangos
        let obj = null;
        listCriterios['grb_criterios_edad'].forEach((c) => {
            const rangos = c.clase.split('-');
            const vlr1 = parseInt(rangos[0].trim());
            const vlr2 = (rangos.length>1) ? parseInt(rangos[1].trim()) : null;
            if (parseInt(params.edad)>=vlr1 && parseInt(params.edad)<=vlr2){
                obj = c;
            }
        });
        if (obj){
            vlr_edad = parseInt(obj.valor);
        }
    }

    let vlr_edadclub = 1;
    if (params.edadclub && params.edadclub!=='' && listCriterios['grb_criterios_edadclub']){
        let obj = null;
        listCriterios['grb_criterios_edadclub'].forEach((c) => {
            const rangos = c.clase.split('-');
            const vlr1 = parseInt(rangos[0].trim());
            const vlr2 = (rangos.length>1) ? parseInt(rangos[1].trim()) : null;
            if (parseInt(params.edadclub)>=vlr1 && parseInt(params.edadclub)<=vlr2){
                obj = c;
            }
        });
        // console.log(obj);
        if (obj){
            vlr_edadclub = parseInt(obj.valor);
        }
    }
    
    // Criterios Iniciales
    let vlr_avg = vlr_jefehogar + vlr_numhijos + vlr_asistencia;
    let vlr_avg_perc = (vlr_jefehogar + vlr_numhijos + vlr_asistencia) / (3*MAX_ESCALA); //(NUM_CRITERIOS*MAX_ESCALA)
    
    let afectacion_final = 'Baja';
    if (vlr_avg && parseInt(vlr_avg)<=3) afectacion_final = 'Baja';
    if (vlr_avg && (parseInt(vlr_avg)>3 && parseInt(vlr_avg)<=6)) afectacion_final = 'Media';
    if (vlr_avg && (parseInt(vlr_avg)>6)) afectacion_final = 'Alta';
    // Calcular valor
    let vlr_cupo = 0; //params.cupo;
    if (params.cupo && params.cupo!=='' && listCriterios['grb_criterios_cupo']){
        let obj = listCriterios['grb_criterios_cupo'].filter(c => c.clase.toLowerCase()===afectacion_final.toLowerCase());
        if (obj && obj.length>0){
            vlr_cupo = obj[0].valor;
        }
    }
    // Suma ponderada teniendo en cuenta el cupo de la última entrega
    // let vlr_cupo_2 = ((parseFloat(params.cupo) * vlr_jefehogar) 
    //                 + (parseFloat(params.cupo) * vlr_numhijos) 
    //                 + (parseFloat(params.cupo) * vlr_asistencia)) / vlr_avg;
    
    // Suma ponderada con los valores de la tabla de criterio
    let vlr_cupo_2 = ((parseFloat(vlr_cupo) * vlr_jefehogar) 
                    + (parseFloat(vlr_cupo) * vlr_numhijos) 
                    + (parseFloat(vlr_cupo) * vlr_asistencia)) / vlr_avg;
    
    // CRITERIOS Ernesto
    // Criterios propuestos - Suma ponderada 2
    let vlr_avg_2 = (vlr_prestarservicio + vlr_jefehogar + vlr_personasacargo + vlr_numhijos + vlr_tienefmliaclub 
        + vlr_actividad + vlr_categoria + vlr_asistencia + vlr_edad + vlr_edadclub);
    
    // Escalar o normalizar el rango de valores que desean entre 1 a 9.
    const tMin = 0;
    const tMax = 9;
    const sMin = 0;
    const sMax = NUM_CRITERIOS; //27;
    let vlr_escalado = Math.ceil(scaleBetween(vlr_avg_2, tMin, tMax, sMin, sMax));
    
    // Calcular valor por peso
    let vlr_avg_perc_2 = (vlr_prestarservicio + vlr_jefehogar + vlr_personasacargo + vlr_numhijos + vlr_tienefmliaclub 
        + vlr_actividad + vlr_categoria + vlr_asistencia + vlr_edad + vlr_edadclub) / (NUM_CRITERIOS*MAX_ESCALA);
    
    let afectacion_final_2 = 'Baja';
    if (vlr_escalado && parseInt(vlr_escalado)<=3) afectacion_final_2 = 'Baja';
    if (vlr_escalado && (parseInt(vlr_escalado)>3 && parseInt(vlr_escalado)<=6)) afectacion_final_2 = 'Media';
    if (vlr_escalado && (parseInt(vlr_escalado)>6)) afectacion_final_2 = 'Alta';
    
    // Calcular valor
    let vlr_final = 0; //params.cupo;
    if (params.cupo && params.cupo!=='' && listCriterios['grb_criterios_cupo']){
        let obj = listCriterios['grb_criterios_cupo'].filter(c => c.clase.toLowerCase()===afectacion_final_2.toLowerCase());
        if (obj && obj.length>0){
            vlr_final = obj[0].valor;
        }
    }

    return {
            vlr_prestarservicio:vlr_prestarservicio,
            vlr_jefehogar:vlr_jefehogar,
            vlr_personasacargo:vlr_personasacargo,
            vlr_numhijos:vlr_numhijos,
            vlr_tienefmliaclub:vlr_tienefmliaclub,
            vlr_actividad:vlr_actividad,
            vlr_categoria:vlr_categoria,
            vlr_asistencia:vlr_asistencia,
            vlr_edad:vlr_edad,
            vlr_edadclub:vlr_edadclub,
            tipoafectacion: vlr_avg, 
            tipoafectacion2: vlr_avg_2, 
            tipoafectacionescalado: vlr_escalado, 
            afectacionfinal: afectacion_final, 
            afectacionfinal2: afectacion_final_2, 
            perc_afect: Math.ceil(vlr_avg_perc*100),
            perc_afect2: Math.ceil(vlr_avg_perc_2*100),
            // cupoxperc: Math.ceil(vlr_avg_perc * parseFloat(params.cupo)), 
            cupoxperc: Math.ceil(vlr_cupo_2), 
            valorfinal: vlr_final
        };
};