import React from "react"; //useEffect
import { compose, withHandlers } from "recompose"; //withProps, lifecycle, withState
import {
    withGoogleMap,
    GoogleMap,
    StreetViewPanorama,
    OverlayView,
    // Marker,
    withScriptjs,
    // DirectionsRenderer
  } from "react-google-maps";
import Geocode from "react-geocode";

// const HomeIcon = 'home.png';

// GEOCODE
const initGeocoder = (mapkey) =>{
    // set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
    Geocode.setApiKey(mapkey);
    // set response language. Defaults to english.
    Geocode.setLanguage("es");
    // set response region. Its optional.
    // A Geocoding request with region=co (Colombia) will return the Colombian city.
    Geocode.setRegion("co");
    // Enable or disable logs. Its optional.
    // Geocode.enableDebug(); 
}

export default class StreetView extends React.Component { //React.PureComponent
    constructor(props) {
        super(props);
        this.state = {
            configMap: props.configMap,
            visible: props.visible,
            zoom:props.zoom,
            center: props.center,
            updated: props.center.updated,
            pov: props.pov,
            onPanoramaChange: props.onPanoramaChange,
            onPanoramaPovChange: props.onPanoramaPovChange
        }

    }
  
    componentDidMount() {
        // console.log(this.state.mapURL);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.center!==this.state.center) {
            console.log(this.props.center, this.state.center);
            console.log('deberia actualizar el streetview');
        }
        return false
    }

    render() {
        // Nota: Este componente de StreetView debe estar en un div visible de lo contrario no se mostrará, 
        // aparecerá un cuadro de color negro y solo se removerá utilizando el evento resize() del contenedor
        // En este caso como esta dentro de un Accordion, este debería estar desplegado o incluir este componenete dentro del render del parent
        
        initGeocoder(this.props.configMap.key);

        const getPixelPositionOffset = (width, height) => ({
            x: -(width / 2),
            y: -(height / 2),
        });
        
        const StreetViewPanormaWithAnOverlayView = compose(
            withHandlers(() => {
                const refs = {
                  map: undefined,
                  panorama: undefined,
                }
                return {
                    onMapMounted: () => ref => {
                        refs.map = ref;
                        refs.panorama = refs.map ? refs.map.getStreetView() : undefined;
                        // console.log(refs.map, refs.panorama);
                    },
                    // onZoomChanged: ({ onZoomChange }) => () => {
                    //     // console.log('Map Zoom', refs.map.getZoom());
                    //     // console.log('Panorama Zoom', refs.panorama.getZoom());
                    // },
                    onPositionChanged: ({ onPanoramaChange }) => () => {
                        if (refs.panorama){
                            const pos = refs.panorama.getPosition();
                            onPanoramaChange({lat:pos.lat(), lng:pos.lng()});
                        }
                        
                    },
                    onPovChanged: ({ onPanoramaPovChange }) => () => {
                        if (refs.panorama){
                            // const pov = refs.panorama.getPov();
                            onPanoramaPovChange(refs.panorama.getPov());
                        }
                    },
                    // onPanoChanged: ({ onPanoChange }) => () => {
                    //     console.log('onPanoChanged', refs.panorama.getPano());
                    // }
                }
            }),
            withScriptjs,
            withGoogleMap)(props =>
            <GoogleMap ref={props.onMapMounted} defaultZoom={props.pov.zoom} defaultCenter={props.center}
              defaultOptions={{scrollwheel: false, streetViewControl:false, fullscreenControl:false }}
            >
              <StreetViewPanorama defaultPosition={props.center} pov={props.pov}
                defaultOptions={{
                    visible:props.visible,
                    fullscreenControl:false,
                    linksControl:false,
                    panControl:false,
                    enableCloseButton:false,
                    addressControl:false,
                    // mode: "html5",
                    // clickToGo:false,
                }}
                onPositionChanged={props.onPositionChanged}
                onPovChanged={props.onPovChanged}
                // onZoomChanged={props.onZoomChanged}
                // onPanoChanged={props.onPanoChanged}
              >
                {props.visible && <OverlayView
                  position={props.center}
                    mapPaneName={OverlayView.OVERLAY_LAYER}
                    getPixelPositionOffset={getPixelPositionOffset}
                >
                  <div style={{ background: `red`, color: `white`, padding: 5, borderRadius: `50%` }}>
                    Beneficiario
                  </div>
                </OverlayView>}
              </StreetViewPanorama>
            </GoogleMap>
        );

        return (
            <StreetViewPanormaWithAnOverlayView 
                googleMapURL={this.props.configMap.mapURL}
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: `400px` }} />}
                mapElement={<div style={{ height: `100%` }} />}
                // 
                visible={this.props.visible}
                zoom={this.props.zoom} 
                center={ this.props.center }
                pov={ this.props.pov }
                
                // Events
                onPanoramaChange = {this.props.onPanoramaChange}
                onPanoramaPovChange = {this.props.onPanoramaPovChange}
                
            />
        )
    }
}


