// React
import React, {useState} from "react";
// Redux imports
import { useSelector, useDispatch } from "react-redux";
import { setProyecto } from "../../../redux/reducers/proyectos";

// import BooleanIcon from 'calcite-ui-icons-react/BooleanIcon';

import ActionBar, {
  Action,
  ActionGroup,
  // BottomActionGroup
} from 'calcite-react/ActionBar';

// Styled Components
import styled from "styled-components";

const ActionBarLeft = styled.div`
  // float:left;
  // display: inline-flex;
  // position: relative;
  // width: auto;
  // height: 100%;
  // text-align: center;
  // z-index: 2;
  // left: 0px;
  overflow: hidden;
  // background-color: #4c4c4c;
  // color: #fff;
`;

const ItemMenuOptsIcon = styled.div`
  width: 16px;
  height: 16px;
  background: transpararent;
  font-size:0.95rem;
  font-weight:700;
  text-align: center;
`;

const MenuLeftAlimentacion = props => {
    // const isCollapsed = useSelector(state => state.proyectos.collapsed);
    const [isCollapsed, setIsCollapsed] = useState(false);
    const activeAction = useSelector(state => state.proyectos.activeProyecto);
    const dispatch = useDispatch();

    const proyectosNames = {
      "":'Alimentación',
      "VALES_ALIMENTICIOS": "Vales alimenticios",
      "SUBVENCION": "Subvención",
      "CONFIGURACION": "Configuración del GRB", 
    };

    // useEffect(() => {
    //   dispatch(moduleLEFTPANELLoaded({activeModule:activeAction, headerTitle:proyectosNames[activeAction]}));
    // }, [dispatch]);

    const onToggleCollapse = (e) => {
      setIsCollapsed(!isCollapsed);
    };

    const turnOffModules = () => {
      // dispatch(moduleUSERSLoaded({loaded:false})); // Hide Users
      // dispatch(moduleBENEFICIARIOSLoaded({loaded:false}));
    }

    const actionSelected = (activeAction) => {
      dispatch(setProyecto({activeProyecto:activeAction, collapsed:isCollapsed, headerTitle:proyectosNames[activeAction]}));
      turnOffModules();
      switch (activeAction) {
        case 'VALES_ALIMENTICIOS':
          console.log('VALES_ALIMENTICIOS');
          break;
        case 'SUBVENCION':
          console.log('SUBVENCION');
          break;
        default:
          break;
      }
    };

    return (
      <ActionBarLeft>
      <ActionBar 
        // showCollapser={false}
        collapseLabel='Ocultar' 
        expandLabel='Expandir' 
        collapsed={isCollapsed}
        onToggleCollapse={onToggleCollapse}
      >
        <ActionGroup>
          <Action 
              onClick={() => actionSelected('VALES_ALIMENTICIOS')}
              active={activeAction === 'VALES_ALIMENTICIOS'}
              icon={<ItemMenuOptsIcon>V</ItemMenuOptsIcon>}
            >
              Vales alimenticios
          </Action>
          <Action
            onClick={() => actionSelected('SUBVENCION')}
            active={activeAction === 'SUBVENCION'}
            icon={<ItemMenuOptsIcon>S</ItemMenuOptsIcon>}
          >
            Subvención
          </Action>
        </ActionGroup>
        {/* <BottomActionGroup>
        <Action icon={<GearIcon />}>Configuración</Action>
      </BottomActionGroup> */}
      </ActionBar>
      </ActionBarLeft>
    )

}

export default MenuLeftAlimentacion;