import React, {useState, useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";
import ContentEditable from 'react-contenteditable';
import { updateBeneficiarioRecreacion, serverMensaje } from '../../redux/reducers/beneficiarios';
import {highlightAll, disableNewlines, pastePlainText } from '../../utils/utils';
import Alert, {AlertMessage} from 'calcite-react/Alert';
import {MenuItem} from 'calcite-react/Menu';
import MultiSelect from 'calcite-react/MultiSelect';
import Accordion, {
    AccordionSection,
    AccordionTitle,
    AccordionContent
} from 'calcite-react/Accordion';
import {showToaster} from './BenefShowMesg';

import styled from "styled-components";

import rec_actividades from '../../data/recreacion_actividades.json';
import frecuencias from '../../data/frecuencia_object.json';

const BeneficiarioModulo = styled.div`
    height: auto;
    background: #fff;
    border: 0;
    padding: 0px;
    margin: 4px;
    overflow:hidden;
`;

const AccordionModulo = styled(Accordion)`
    padding:0;
`;
const AccordionTitleModule = styled(AccordionTitle)`
    background-color: #e6e6e6;
    color: #4d4d4d;
    font-size: 1.25em;
    font-weight: 700;
    // border-bottom: 1px solid #d9d9d9;
    // box-shadow: 0px 2px 6px #e1e1e1;
`;


const BenefRecreacion = ({recreacion}) => {
    const dispatch = useDispatch();
    const mensaje = useSelector(state => state.beneficiarios.mensaje);
    const users = useSelector(state => state.users);
    // const isEditable = users.user.tipo==='ADMINISTRADOR' ? true : false;
    // const isEditable = (users.user.tipo==='ADMINISTRADOR' && users.user.role==='A' && users.user.permisos==='A') ? true : false;
    const isEditable = (users.user.areatrabajo==='ADMINISTRATIVA' || users.user.areatrabajo==='TRABAJO SOCIAL' || users.user.areatrabajo==='COORDINACIÓN' || users.user.areatrabajo==='PSICOLOGÍA' ) ? true : false;
    // const canView = (users.user.areatrabajo==='ADMINISTRATIVA' || users.user.areatrabajo==='TECNOLOGÍA' ) ? true : false;
    const [activeSectionIndexes, setActiveSectionIndexes] = useState([]);
    const [selectedActividadesValues, setSelectedActividadesValues] = useState(recreacion.actividades.split('|').map(x=>+x));
    const [selectedFrecuenciaValues, setSelectedFrecuenciaValues] = useState(recreacion.fecuencia.split('|').map(x=>+x));

    const [error, setError] = useState(null);
    const onAccordionChange = (evt, index) => {
        activeSectionIndexes.includes(index)
        ? setActiveSectionIndexes(activeSectionIndexes.filter(
            item => index !== item,
          ))
        : setActiveSectionIndexes([...activeSectionIndexes, index]);
    }

    const [R] = useState({
        "id": recreacion.id, //int
        "beneficiarioId": recreacion.beneficiarioId, //15
        "actividades": recreacion.actividades || '', //150  //"1|15|157|162|196",
        "fecuencia": recreacion.fecuencia || '', //32  //"5|7"
        "eventos": recreacion.eventos || '', //text 
        "gasto": recreacion.gasto, //bigint
        "comentarios": recreacion.comentarios, //text
        "actualizacion": recreacion.actualizacion, //0000-00-00 00:00:00
        "version": recreacion.version,
    });

    const [itemsRecreacion, setItemsRecreacion] = useState([
        // {"key":"id", "label": "Identificador Único", "value": R['id']},
        // {"key":"beneficiarioId", "label": "Documento de Identificación", "value": R['beneficiarioId']},
        {"key":"actividades", "label": "En familia, qué actividades realizan en el tiempo libre?", "value": R['actividades']},
        {"key":"fecuencia", "label": "Con qué frecuencia?", "value": R['fecuencia']},
        {"key":"gasto", "label": "Cuánto gasta la familia en recreación?", "value": R['gasto']},
        {"key":"eventos", "label": "Cuáles son los eventos culturales o recreativos en los que ustedes participan con mayor frecuencia?", "value": R['eventos']},
        // {"key":"comentarios", "label": "Comentarios en general recreación", "value": R['comentarios']},
        // {"key":"actualizacion", "label": "Última actualización", "value": R['actualizacion']},
        // {"key":"version", "label": "Versión", "value": R['version']}
    ]);

    useEffect(() => {
        // Guardar los resultados al salir, puede ser contraproducente al fallar la conexión con el servidor u otro evento no deseado
        // se podrián perder los datos modificafos
        
        return () => {
            // if (isUpdated){
            //     console.log('Saving appointment', isUpdated);
            // }
            dispatch(serverMensaje({}));
        }
    }, [dispatch]);

    useEffect(() => {
        if (itemsRecreacion){
            // console.log(itemsRecreacion);
        }
        if (mensaje.recreacion && mensaje.recreacion!=='') {
            if (typeof mensaje.recreacion === 'string' && mensaje.recreacion.toLowerCase().includes('error')){
                showToaster('error', 'Ocurrió un fallo en el servidor', mensaje.recreacion);
            } 
            // else {
            //     showToaster('success',"Recreación del Beneficiario", mensaje.recreacion); //"🧭 " + mensaje
            // }
        }

    }, [mensaje.recreacion, itemsRecreacion, dispatch]);

    const updateItemsRecreacion = (f, v) => {
        const newitemsRecreacion = itemsRecreacion.map((itm, index) => {
            if (itm.key===f){
                itm.value = v;
            }
            return itm;
        });
        setItemsRecreacion(newitemsRecreacion);
    };

    const updateDataonServer = () =>{
        dispatch(serverMensaje({recreacion:''}));
        dispatch(updateBeneficiarioRecreacion( { token: users.user.claveAPI, rec:R}));
    }

    const replaceSomeText = (s) =>{
        return s
            .replace(/&nbsp;/g, ' ')
            .replace(/<br>/g, '')
            .replace(/&amp;/g, '&')
            .replace(/&gt;/g, '>')
            .replace(/&lt;/g, '<').trim()
    }

    const validateInput = (f, v, final) => {
        // const regex_onlynum = /[0-9,]|\./;
        // const regex_nombres = /^[a-zA-Z ]+$/;
        // const regex_nombres = /^['`´a-zA-ZÀ-ÿ\u00C0-\u017F\s]+$/;
        // const regex_onlyletters = /#[a-zA-Z\xE1\xE9\xED\xF3\xFA\xC1\xC9\xCD\xD3\xDA\xF1\xD1]+#/i;
        const regex_gasto = /^[0-9]*(\.[0-9]+)?$/;
        let new_val = null;
        let err = null;
        switch (f) {
            case 'actividades':
                new_val = v.substr(0,150);
                if (final)
                    new_val = replaceSomeText(v).substr(0,150);
                break;
            case 'fecuencia':
                new_val = v.substr(0,32);
                if (final)
                    new_val = replaceSomeText(v).substr(0,32);
                break;
            case 'eventos':
                new_val = v.trim();
                if (final)
                    new_val = replaceSomeText(v);
                break;
            case 'gasto':
                new_val = v.trim(); //.replace(/$/g, '').replace(/./g,'').replace(/,/g,'').replace(/^[ ]/g,'');
                if (!regex_gasto.test(new_val)) {
                    err = 'Dígite solo números de gasto válidos';
                    new_val = null;
                }
                break;
            case 'comentarios':
                new_val = v.trim(); 
                if (final)
                    new_val = replaceSomeText(v);
                break;
            default:
                break;
        }
        setError(err);
        return new_val
    }
    const handleChange = evt => {
        const field = evt.currentTarget.id.split('_')[1];
        const new_val = validateInput(field, evt.target.value, false)
        if(new_val){
            R[field] = new_val
        };
    };
    const handleBlur = evt => {
        const val = evt.currentTarget.innerHTML;
        const field = evt.target.id.split('_')[1];
        const new_val = validateInput(field, val, true)
        if(new_val || new_val===''){
            R[field] = new_val
            // if (field==='gasto'){
            //     updateItemsRecreacion(field, "$ " + numberWithCommas(R[field]));
            // } 
            if (field!=='comentarios')
                updateItemsRecreacion(field, R[field]);
            updateDataonServer();
        };
        
    };

    const handleActividadesMultiSelectChange = (values, items) => {
        let activ_str = "";
        if(values && values.length>0){
            values.map(v =>{
                return activ_str += v + '|';
            });
            // activ_str.replace(/|+$/,'');
            activ_str = activ_str.slice(0,activ_str.length-1);
        }
        setSelectedActividadesValues(values);
        R['actividades'] = activ_str;
        updateDataonServer();
    };
    const handleFrecuenciaMultiSelectChange = (values, items) => {
        let fecuencia_str = "";
        if(values && values.length>0){
            values.map(v =>{
                return fecuencia_str += v + '|';
            });
            // fecuencia_str.replace(/|+$/,'');
            fecuencia_str = fecuencia_str.slice(0,fecuencia_str.length-1);
        }
        setSelectedFrecuenciaValues(values);
        R['fecuencia'] = fecuencia_str;
        updateDataonServer();
    };
    const renderActividadesValue = (items) => {
        if (!items || items.length === 0) {
          return 'Seleccione una opción'
        } else if (items.length > 2) {
          // Template literals currently are not supported by MDX:
          // https://github.com/mdx-js/mdx/issues/218
          return items.length + ' actividades'
        } else {
          return items.map(item => item.props.children).join(', ')
        }
      }


    return (
        <BeneficiarioModulo>
            <AccordionModulo
                onAccordionChange={onAccordionChange}
                activeSectionIndexes={activeSectionIndexes}
            >
                <AccordionSection>
                    <AccordionTitleModule>Recreación</AccordionTitleModule>
                    {activeSectionIndexes.length>0 &&  <AccordionContent style={{marginBottom: '10px'}}>
                    {error && <div style={{color: 'red', width: '100%'}}>
                            <Alert red showIcon style={{minWidth: '100%', marginBottom: '10px'}}
                                showCloseLabel 
                                onClose={() => setError(null)}>
                                <AlertMessage>
                                    Error:{' '}
                                    <strong>{error}</strong>
                                </AlertMessage>
                            </Alert>
                        </div> 
                    }
                    {/* ITEMS */}
                        {
                            itemsRecreacion.map((itm, index) => {
                                // if (itm.value!==null && itm.value!=="" ){
                                    return (<div key={index} className="itemContainer">
                                        <div className="itemContainer-attr-long" 
                                            style={{backgroundColor:(index % 2 === 0) ? '#f8f8f8': '#fff'}}
                                        >{itm.label}</div>
                                        <div className="itemContainer-value" 
                                            style={{backgroundColor:(index % 2 === 0) ? '#f8f8f8': '#fff'}}
                                        >
                                        {itm.key==='actividades'
                                            ?
                                            <div>
                                                <MultiSelect positionFixed disabled={!isEditable} 
                                                    renderValue={renderActividadesValue}
                                                    onChange={handleActividadesMultiSelectChange}
                                                    selectedValues={selectedActividadesValues}
                                                    closeOnSelect={false}
                                                >
                                                    {rec_actividades.actividades.map((f,i) => {
                                                        return <MenuItem key={f.id} value={f.id}>{f.nombre}</MenuItem>
                                                    })}
                                                    
                                                </MultiSelect>
                                            </div>
                                            :
                                            itm.key==='fecuencia'
                                            ?
                                                <MultiSelect positionFixed disabled={!isEditable} 
                                                    onChange={handleFrecuenciaMultiSelectChange}
                                                    selectedValues={selectedFrecuenciaValues}
                                                    closeOnSelect={false}
                                                >
                                                    {frecuencias.frecuencias.map((f,i) => {
                                                        return <MenuItem key={f.id} value={f.id}>{f.name}</MenuItem>
                                                    })}
                                                    {/* <MenuItem value={1}>Diario</MenuItem>
                                                    <MenuItem value={2}>Semanal</MenuItem>
                                                    <MenuItem value={3}>Quincenal</MenuItem>
                                                    <MenuItem value={4}>Mensual</MenuItem>
                                                    <MenuItem value={5}>Bimestral</MenuItem>
                                                    <MenuItem value={6}>Trimestral</MenuItem>
                                                    <MenuItem value={7}>Semestral</MenuItem>
                                                    <MenuItem value={8}>Anual</MenuItem>
                                                    <MenuItem value={9}>Cada 2 años</MenuItem>
                                                    <MenuItem value={10}>Cada 5 años</MenuItem>
                                                    <MenuItem value={11}>Cada que se puede</MenuItem>
                                                    <MenuItem value={12}>No Aplica</MenuItem> */}
                                                </MultiSelect>
                                            :
                                            <ContentEditable
                                                id={'recreacion_'+itm.key}
                                                html={itm.value || ''} // innerHTML of the editable div
                                                className='itemContainer-value-long'
                                                disabled={!isEditable}      // use true to disable editing
                                                onPaste={pastePlainText} // En caso se copie y pegue de otra pagina de internet
                                                onChange={handleChange} // handle innerHTML change
                                                onKeyPress={disableNewlines}  
                                                onFocus={highlightAll}
                                                onBlur={handleBlur}
                                                tagName='div' // Use a custom HTML tag (uses a div by default)
                                            />
                                        }
                                        </div>
                                    </div>)
                                // } 
                            })
                        }

                        <div className="Observaciones-Comentarios">
                            <div className="benef-texto-observaciones-title">Comentarios en general de recreación:</div>
                            <div className="benef-texto-observaciones"> 
                                <ContentEditable
                                    id={'recreacion_comentarios'}
                                    html={R.comentarios || ''} // innerHTML of the editable div
                                    disabled={!isEditable}        // use true to disable editing
                                    onPaste={pastePlainText}
                                    onChange={handleChange} // handle innerHTML change
                                    onFocus={highlightAll}
                                    onBlur={handleBlur}
                                    tagName='div' // Use a custom HTML tag (uses a div by default)
                                    style={{ "minHeight": "40px", "border": "1px dashed" }}
                                />
                            </div>
                        </div>
                        <div className="benef-texto-actualizacion">Última actualización: {R['actualizacion']}</div>

                    </AccordionContent>
                }
                </AccordionSection>
            </AccordionModulo>
        </BeneficiarioModulo>
    );
}

export default BenefRecreacion;

