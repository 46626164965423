import React, {Fragment,useState, useEffect} from "react";
import ListBeneficiariosWrapperAcomp from "./ListBeneficiariosWrapperAcomp";

const DisplayListBeneficiarios = ({listBenefAcomp, isFiltered}) => {
  const [hasNextPage,setHasNextPage] = useState(true);
  const [isNextPageLoading,setIsNextPageLoading] = useState(false);
  const [items,setItems] = useState(listBenefAcomp);

  const _loadNextPage = (...args) => {
    setIsNextPageLoading(true);
    setHasNextPage(items.length < listBenefAcomp.length );
    setIsNextPageLoading(false);
   
    if (isFiltered){
      setItems([]); //Object.assign({}, listBenefAcomp[10])
    } else {
      setItems( [...items].concat(listBenefAcomp[args[0]]) );
    }
    
  };

  useEffect(() => {
    if (isFiltered){
      setHasNextPage(false );
      setIsNextPageLoading(false);
      setItems(listBenefAcomp);
    } else {
      setItems([]);
      setIsNextPageLoading(true);
      setHasNextPage(true );
      setIsNextPageLoading(false);
      setItems(listBenefAcomp);
    }
      
  }, [isFiltered, listBenefAcomp]);


if (items)
  return (
    <Fragment>
      <ListBeneficiariosWrapperAcomp 
          hasNextPage={hasNextPage}
          isNextPageLoading={isNextPageLoading}
          items={items}
          loadNextPage={_loadNextPage}
        />

    </Fragment>
  );
  return (
    <></>
  )
}

export default DisplayListBeneficiarios;
