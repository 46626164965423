import React, {useState, useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectBeneficiarioForPerfilxVestuarioTallaje,
    resetDataVestuarioTallaje
} from '../../../redux/reducers/vestuario';

import BenefHeader from '../../beneficiario/BenefHeader';
import BenefVestuarioTallaje from '../../beneficiario/BenefVestuarioTallaje';

import styled from "styled-components";

import closecircleIcon from '../../../assets/icons/closecircle_icon.png';


const BeneficiarioDetailsContainer = styled.div`
    // width: calc(100% - 45px); // Cuando hay actionbar
    width: 100%;
    height: 100vh;
    background: #fff;
    // padding-right:45px; // Cuando hay actionbar
    padding-right:5px;
`;

const CardBenefDetailsContainer = styled.div`
    // position: relative;
    // display: flex;
    // border: 1px solid #ccc;
    // padding: 0;
    // margin: 5px;
    // height: 100%;
    // flex-wrap: wrap;
    // width: auto;
`;

const CardBenefContainerClose = styled.div`
    position: absolute;
    width: 24px;
    height: 24px;
    // right: 60px; // Cuando hay actionbar
    right: 5px;
    top: 5px;
    font-size: 24px;
    cursor: pointer;
    z-index: 101;
    background: rgba(255, 255, 255, 0.1) url(${closecircleIcon}) no-repeat center/cover;
    opacity: 0.4;

    &:hover {
        opacity: 1;
    }
`;

const TallajeDetails = props => {
    const users = useSelector(state => state.users);
    const canView = ( users.user.role==='L' || users.user.role==='A' ) ? true : false;
    // const canView = (users.user.areatrabajo==='ADMINISTRATIVA' || users.user.areatrabajo==='CONTABILIDAD' || users.user.areatrabajo==='TRABAJO SOCIAL' ) ? true : false;
    const config = useSelector(state => state.config);
    const datosSelBenefTallaje = useSelector(state => state.vestuario.datosSelBenefTallaje);
    const selectedProfile = useSelector(state => state.vestuario.selectedProfileTallaje);
    const [Beneficiario,setBeneficiario] = useState(null);
    const listBenefTallaje = useSelector(state => state.vestuario.listBenefTallaje);
    const dispatch = useDispatch();

    useEffect(() => {
        const getBeneficiarioByDocId = (docId) => {
            return listBenefTallaje.filter((benef, i) => benef.docId === docId);
        };

        if (selectedProfile && selectedProfile.docId!==''){
            setBeneficiario(getBeneficiarioByDocId(selectedProfile.docId)[0]);
        }
    }, [selectedProfile, listBenefTallaje, dispatch]);

    const onCloseBenefDetailsWin = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setBeneficiario(null);
        dispatch(selectBeneficiarioForPerfilxVestuarioTallaje({selectedProfileTallaje:null}));
        dispatch(resetDataVestuarioTallaje({listBenefTallaje:[]}));
    }

    if (Beneficiario){
        let beneficiarios_raw = null; 
        // let perfilbeneficiario = null;
        let tallaje = null;

        if (datosSelBenefTallaje){
            if (datosSelBenefTallaje.length<=0) return (
                <></>
            );
            // console.log("datosSelBenefTallaje==>", datosSelBenefTallaje);
            // 0: "beneficiarios" -> Estos ya se tienen de antemano.
            if ( datosSelBenefTallaje['beneficiarios'] && datosSelBenefTallaje['beneficiarios'].length>0){
                beneficiarios_raw = datosSelBenefTallaje['beneficiarios'][0];
            }
            // 2: "perfilbeneficiario"
            // if ( datosSelBenefTallaje['perfilbeneficiario'] && datosSelBenefTallaje['perfilbeneficiario'].length>0){
            //     perfilbeneficiario = datosSelBenefTallaje['perfilbeneficiario'][0];
            // }
            
            // grb_vestuario_tallaje
            if ( datosSelBenefTallaje['grb_vestuario_tallaje'] && datosSelBenefTallaje['grb_vestuario_tallaje'].length>0){
                tallaje = datosSelBenefTallaje['grb_vestuario_tallaje'][0];
            }

        return (
            <BeneficiarioDetailsContainer>
                <CardBenefContainerClose onClick={onCloseBenefDetailsWin} title="Cerrar Detalle Beneficiario"/>
                <CardBenefDetailsContainer>
                    <div className="cardBenefDetailsContainer-header-first">
                        <BenefHeader Beneficiario={beneficiarios_raw} ECASE_URL={config.ECASE_URL}/>
                        {/* <BenefCumplimiento cumplimiento={cumplimiento} docId={Beneficiario.docId}/> */}
                    </div>
                    {canView && tallaje && <BenefVestuarioTallaje tallaje={tallaje} basicos={beneficiarios_raw} /> }
                    {/* {canView && perfilbeneficiario && <BenefPerfilBeneficiario perfilbeneficiario={perfilbeneficiario} basicos={beneficiarios_raw} /> } */}
                </CardBenefDetailsContainer>
            </BeneficiarioDetailsContainer>
            );

        } else { 
            return (
                <></>
            );
        }
    }
    return (
        <></>
    );
}

export default TallajeDetails;

