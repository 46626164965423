// Copyright 2020 Ernesto Girón E.
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//     http://www.apache.org/licenses/LICENSE-2.0
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.​

import { call, put, takeLatest } from "redux-saga/effects";
import { types as configTypes } from "../reducers/auxilios";
import { 
  getListAuxilios,
  getListAuxiliosbyQuery,
  actualizarAuxilio,
  crearAuxilio,
  eliminarAuxilio,
} from "../../utils/auxilios_req";

// WORKERS //
function* fetchAuxiliosData(action) {
  try {
    const listAuxilios = yield call(getListAuxilios, action.payload.token);
    if (listAuxilios)
      yield put({
        type: configTypes.AUXILIO_LIST_LOADED,
        payload: {listAuxilios: listAuxilios}
      });
  } catch (e) {
    console.error("SAGA ERROR: auxilios_req/fetchAuxiliosData, ", e);
  }
}
function* fetchAuxiliosDatabyQuery(action) {
  try {
    const resultQueryAuxilios = yield call(getListAuxiliosbyQuery, action.payload);
    if (resultQueryAuxilios)
      yield put({
        type: configTypes.AUXILIO_STATS_LOADED,
        payload: {resultQueryAuxilios: resultQueryAuxilios}
      });
  } catch (e) {
    console.error("SAGA ERROR: auxilios_req/fetchAuxiliosData, ", e);
  }
}
function* updateAuxilio(action) {
  try {
    const resp = yield call(actualizarAuxilio, action.payload);
    if (resp && resp!=='')
      yield put({
        type: configTypes.AUXILIO_SERVER_MESSAGE,
        payload: {mensaje: {"notificaciones": resp}}
      });
  } catch (e) {
    console.error("SAGA ERROR: auxilios_req/updateAuxilio, ", e);
  }
}
function* insertAuxilio(action) {
  try {
    const resp = yield call(crearAuxilio, action.payload);
    if (resp && resp!=='')
      yield put({
        type: configTypes.AUXILIO_SERVER_MESSAGE,
        payload: {mensaje: {"notificaciones": resp}}
      });
  } catch (e) {
    console.error("SAGA ERROR: auxilios_req/insertAuxilio, ", e);
  }
}
function* deleteAuxilio(action) {
  try {
    const resp = yield call(eliminarAuxilio, action.payload);
    if (resp && resp!=='')
      yield put({
        type: configTypes.AUXILIO_SERVER_MESSAGE,
        payload: {mensaje: {"notificaciones": resp}}
      });
  } catch (e) {
    console.error("SAGA ERROR: auxilios_req/deleteAuxilio, ", e);
  }
}



// WATCHER //
export function* watchFetchAuxiliosData() {
  yield takeLatest(configTypes.AUXILIO_DATA_LOADED, fetchAuxiliosData);
  yield takeLatest(configTypes.AUXILIO_DATABYQUERY_LOADED, fetchAuxiliosDatabyQuery);
  yield takeLatest(configTypes.AUXILIO_DATA_UPDATED, updateAuxilio);
  yield takeLatest(configTypes.AUXILIO_DATA_CREATED, insertAuxilio);
  yield takeLatest(configTypes.AUXILIO_DATA_DELETED, deleteAuxilio);

}
