// Copyright 2019 Ernesto Giron E.
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//     http://www.apache.org/licenses/LICENSE-2.0
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.​

// ACTION TYPES //
export const types = {
  MODULE_CUMPLIMIENTO_LOADED: "MODULE_CUMPLIMIENTO_LOADED",
  MODULE_CUMPLIMIENTO_BENEF_LOADED: "MODULE_CUMPLIMIENTO_BENEF_LOADED",
  MODULE_CUMPLIMIENTO_TENIS_LOADED: "MODULE_CUMPLIMIENTO_TENIS_LOADED",
  CUMPLIMIENTO_RESERVAS_LIST_LOADED: "CUMPLIMIENTO_RESERVAS_LIST_LOADED",
  CUMPLIMIENTO_SOCIOSGOLF_LIST_LOADED: "CUMPLIMIENTO_SOCIOSGOLF_LIST_LOADED",
  CUMPLIMIENTO_SOCIOSGOLF_DATA_LOADED: "CUMPLIMIENTO_SOCIOSGOLF_DATA_LOADED",
  CUMPLIMIENTO_BENEFGOLF_LIST_LOADED: "CUMPLIMIENTO_BENEFGOLF_LIST_LOADED",
  CUMPLIMIENTO_BENEFGOLF_DATA_LOADED: "CUMPLIMIENTO_BENEFGOLF_DATA_LOADED",
  CUMPLIMIENTO_BENEFTENIS_LIST_LOADED: "CUMPLIMIENTO_BENEFTENIS_LIST_LOADED",
  CUMPLIMIENTO_BENEFTENIS_DATA_LOADED: "CUMPLIMIENTO_BENEFTENIS_DATA_LOADED",
  MODULE_CUMPLIMIENTO_BENEFICIARIO_DATA_SELECTED: "MODULE_CUMPLIMIENTO_BENEFICIARIO_DATA_SELECTED",
  MODULE_CUMPLIMIENTO_BENEFICIARIO_PROFILE_SELECTED: "MODULE_CUMPLIMIENTO_BENEFICIARIO_PROFILE_SELECTED",
  MODULE_CUMPLIMIENTO_BENEFICIARIOS_LIST_FILTERED: "MODULE_CUMPLIMIENTO_BENEFICIARIOS_LIST_FILTERED",
  MODULE_CUMPLIMIENTO_ISFILTER_SHOW: "MODULE_CUMPLIMIENTO_ISFILTER_SHOW",
  MODULE_CUMPLIMIENTO_BENEFICIARIO_SELECTED_DATA_REQUEST: "MODULE_CUMPLIMIENTO_BENEFICIARIO_SELECTED_DATA_REQUEST",

  MODULE_CUMPLIMIENTO_LISTRESERVASGOLF_LOADED: "MODULE_CUMPLIMIENTO_LISTRESERVASGOLF_LOADED",
  MODULE_CUMPLIMIENTO_LISTRESERVASGOLF_UPDATED: "MODULE_CUMPLIMIENTO_LISTRESERVASGOLF_UPDATED",
  MODULE_CUMPLIMIENTO_LISTRESERVASGOLF_CREATED: "MODULE_CUMPLIMIENTO_LISTRESERVASGOLF_CREATED",
  MODULE_CUMPLIMIENTO_LISTRESERVASGOLF_DELETED: "MODULE_CUMPLIMIENTO_LISTRESERVASGOLF_DELETED",
  MODULE_CUMPLIMIENTO_LISTRESERVASGOLF_BATCH: "MODULE_CUMPLIMIENTO_LISTRESERVASGOLF_BATCH",

  MODULE_CUMPLIMIENTO_LISTRESERVASHISTORICOGOLFXFECHA_LOADED: "MODULE_CUMPLIMIENTO_LISTRESERVASHISTORICOGOLFXFECHA_LOADED",
  MODULE_CUMPLIMIENTO_LISTRESERVASHISTORICOGOLF_LOADED: "MODULE_CUMPLIMIENTO_LISTRESERVASHISTORICOGOLF_LOADED",
  MODULE_CUMPLIMIENTO_LISTRESERVASHISTORICOGOLF_UPDATED: "MODULE_CUMPLIMIENTO_LISTRESERVASHISTORICOGOLF_UPDATED",
  MODULE_CUMPLIMIENTO_LISTRESERVASHISTORICOGOLF_CREATED: "MODULE_CUMPLIMIENTO_LISTRESERVASHISTORICOGOLF_CREATED",
  MODULE_CUMPLIMIENTO_LISTRESERVASHISTORICOGOLF_DELETED: "MODULE_CUMPLIMIENTO_LISTRESERVASHISTORICOGOLF_DELETED",
  MODULE_CUMPLIMIENTO_LISTRESERVASHISTORICOGOLF_BATCH: "MODULE_CUMPLIMIENTO_LISTRESERVASHISTORICOGOLF_BATCH",

  CUMPLIMIENTO_TARIFASCADDIE_LIST_LOADED: "CUMPLIMIENTO_TARIFASCADDIE_LIST_LOADED",
  MODULE_CUMPLIMIENTO_TARIFASCADDIE_LOADED: "MODULE_CUMPLIMIENTO_TARIFASCADDIE_LOADED",
  MODULE_CUMPLIMIENTO_TARIFASCADDIE_UPDATED: "MODULE_CUMPLIMIENTO_TARIFASCADDIE_UPDATED",
  MODULE_CUMPLIMIENTO_TARIFASCADDIE_CREATED: "MODULE_CUMPLIMIENTO_TARIFASCADDIE_CREATED",
  MODULE_CUMPLIMIENTO_TARIFASCADDIE_DELETED: "MODULE_CUMPLIMIENTO_TARIFASCADDIE_DELETED",

  MODULE_CUMPLIMIENTO_STATSCADDIE_LOADED: "MODULE_CUMPLIMIENTO_STATSCADDIE_LOADED",
  CUMPLIMIENTO_STATSCADDIE_LIST_LOADED: "CUMPLIMIENTO_STATSCADDIE_LIST_LOADED",

  MODULE_CUMPLIMIENTO_SERVER_MESSAGE: "MODULE_CUMPLIMIENTO_SERVER_MESSAGE"

};

// REDUCERS //
export const initialState = {
  loaded: false,
  benefloaded: false,
  isFilterShow: false,
  token: null,
  listTarifasCaddie: null,
  listReservas: null,
  listSocios: [],
  listBenef: [],
  listGolf: null,
  listTenis: [],
  listStatsCaddie: null,
  selectedProfile: null,
  datosSelBenef: [],
  filter: [],
  mensaje: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    
    case types.MODULE_CUMPLIMIENTO_LOADED:
      const { loaded } = action.payload;
      return {
        ...state,
        loaded
      };
    case types.MODULE_CUMPLIMIENTO_BENEF_LOADED:
      const { benefloaded } = action.payload;
      return {
        ...state,
        benefloaded
      };
    case types.CUMPLIMIENTO_BENEFGOLF_LIST_LOADED:
      const { listGolf } = action.payload;
      return {
        ...state,
        listGolf
      };
    case types.CUMPLIMIENTO_BENEFTENIS_LIST_LOADED:
      const { listTenis } = action.payload;
      return {
        ...state,
        listTenis
      };
    case types.MODULE_CUMPLIMIENTO_BENEFICIARIO_PROFILE_SELECTED:
      return {
        ...state,
        selectedProfile:action.payload.selectedProfile
      };
    case types.MODULE_CUMPLIMIENTO_BENEFICIARIO_DATA_SELECTED:
      const { datosSelBenef, selectedProfile } = action.payload;
      return {
        ...state,
        datosSelBenef,
        selectedProfile
      };
    case types.MODULE_CUMPLIMIENTO_BENEFICIARIOS_LIST_FILTERED:
      const { filter } = action.payload;
      return {
        ...state,
        filter
      };
    case types.MODULE_CUMPLIMIENTO_ISFILTER_SHOW:
      const { isFilterShow } = action.payload;
      return {
        ...state,
        isFilterShow
      };
    case types.CUMPLIMIENTO_SOCIOSGOLF_LIST_LOADED:
      const { listSocios } = action.payload;
      return {
        ...state,
        listSocios
      };
    case types.CUMPLIMIENTO_RESERVAS_LIST_LOADED:
      const { listReservas } = action.payload;
      return {
        ...state,
        listReservas
      };
    case types.CUMPLIMIENTO_TARIFASCADDIE_LIST_LOADED:
      const { listTarifasCaddie } = action.payload;
      return {
        ...state,
        listTarifasCaddie
      };
    case types.CUMPLIMIENTO_STATSCADDIE_LIST_LOADED:
      const { listStatsCaddie } = action.payload;
      return {
        ...state,
        listStatsCaddie
      };
    case types.MODULE_CUMPLIMIENTO_SERVER_MESSAGE:
      const {mensaje} = action.payload;
      return {
        ...state,
        mensaje
      };
    default:
      return state;
  }
};

// ACTIONS //
export const moduleCumplimientoLoaded = options => ({ type: types.MODULE_CUMPLIMIENTO_LOADED, payload: options });
export const moduleCumplimientoBenefLoaded = options => ({ type: types.MODULE_CUMPLIMIENTO_BENEF_LOADED, payload: options });

export const displayCumplimientoFilter = options => ({ type: types.MODULE_CUMPLIMIENTO_ISFILTER_SHOW, payload: options });

export const fetchListReservasGolfData = token => ({ 
  type: types.MODULE_CUMPLIMIENTO_LISTRESERVASGOLF_LOADED, 
  payload: {
    token: token
  }
});

export const fetchListReservasHistoricoGolfData = token => ({ 
  type: types.MODULE_CUMPLIMIENTO_LISTRESERVASHISTORICOGOLF_LOADED, 
  payload: {
    token: token
  }
});
export const fetchListReservasHistoricoGolfDataxFecha = data => ({ 
  type: types.MODULE_CUMPLIMIENTO_LISTRESERVASHISTORICOGOLFXFECHA_LOADED, payload: data
});

export const fetchBenefGolfData = token => ({ 
  type: types.CUMPLIMIENTO_BENEFGOLF_DATA_LOADED, 
  payload: {
    token: token
  }
});

export const fetchBenefSociosData = token => ({ 
  type: types.CUMPLIMIENTO_SOCIOSGOLF_DATA_LOADED, 
  payload: {
    token: token
  }
});

export const fetchBenefTenisData = token => ({ 
  type: types.CUMPLIMIENTO_BENEFTENIS_DATA_LOADED, 
  payload: {
    token: token
  }
});

export const setListSociosGolf = listSocios => ({ 
  type: types.CUMPLIMIENTO_SOCIOSGOLF_LIST_LOADED, 
  payload: listSocios
});

export const setListReservas = listReservas => ({ 
  type: types.CUMPLIMIENTO_RESERVAS_LIST_LOADED, 
  payload: listReservas
});

export const resetBeneficiarioGolf = listGolf => ({ 
  type: types.CUMPLIMIENTO_BENEFGOLF_LIST_LOADED, 
  payload: listGolf
});

export const resetBeneficiarioTenis = listTenis => ({ 
  type: types.CUMPLIMIENTO_BENEFTENIS_LIST_LOADED, 
  payload: listTenis
});

export const resetTarifasCaddie = () => ({ 
  type: types.CUMPLIMIENTO_TARIFASCADDIE_LIST_LOADED, 
  payload: {listTarifasCaddie: null}
});

export const fetchBeneficiarioPerfilDataxCumpli = datosSelBenef => ({ 
  type: types.MODULE_CUMPLIMIENTO_BENEFICIARIO_SELECTED_DATA_REQUEST, 
  payload: datosSelBenef
});

export const filterBeneficiariosDataxCumpli = filter => ({ 
  type: types.MODULE_CUMPLIMIENTO_BENEFICIARIOS_LIST_FILTERED, 
  payload: {
    filter: filter
  }
});
export const resetFilterBeneficiariosDataxCumpli = () => ({ 
  type: types.MODULE_CUMPLIMIENTO_BENEFICIARIOS_LIST_FILTERED, 
  payload: {
    filter: []
  }
});

export const selectBeneficiarioForPerfilxCumpli = selectedProfile => ({ 
  type: types.MODULE_CUMPLIMIENTO_BENEFICIARIO_PROFILE_SELECTED, 
  payload: selectedProfile
});
export const resetSelectBeneficiarioForPerfilxCumpli = () => ({ 
  type: types.MODULE_CUMPLIMIENTO_BENEFICIARIO_PROFILE_SELECTED, 
  payload: {selectedProfile:null}
});

// Lista de Reservas Golf
export const updateListaReservasGolf = data => ({ 
  type: types.MODULE_CUMPLIMIENTO_LISTRESERVASGOLF_UPDATED,  payload: data
});
export const insertListaReservasGolf = data => ({ 
  type: types.MODULE_CUMPLIMIENTO_LISTRESERVASGOLF_CREATED,  payload: data
});
export const deleteListaReservasGolf = data => ({ 
  type: types.MODULE_CUMPLIMIENTO_LISTRESERVASGOLF_DELETED,  payload: data
});
export const insertBatchListaReservasGolf = payload => ({ 
  type: types.MODULE_CUMPLIMIENTO_LISTRESERVASGOLF_BATCH,  
  payload: payload
});

// Lista de Reservas Historico Golf
export const updateListaReservasHistoricoGolf = data => ({ 
  type: types.MODULE_CUMPLIMIENTO_LISTRESERVASHISTORICOGOLF_UPDATED,  payload: data
});
export const insertListaReservasHistoricoGolf = data => ({ 
  type: types.MODULE_CUMPLIMIENTO_LISTRESERVASHISTORICOGOLF_CREATED,  payload: data
});
export const deleteListaReservasHistoricoGolf = data => ({ 
  type: types.MODULE_CUMPLIMIENTO_LISTRESERVASHISTORICOGOLF_DELETED,  payload: data
});
export const insertBatchListaReservasHistoricoGolf = payload => ({ 
  type: types.MODULE_CUMPLIMIENTO_LISTRESERVASHISTORICOGOLF_BATCH,  
  payload: payload
});

// Lista de Reservas Historico Golf
export const fetchTarifasCaddieData = token => ({ 
  type: types.MODULE_CUMPLIMIENTO_TARIFASCADDIE_LOADED, 
  payload: {
    token: token
  }
});
export const updateTarifasCaddie = data => ({ 
  type: types.MODULE_CUMPLIMIENTO_TARIFASCADDIE_UPDATED,  payload: data
});
export const insertTarifasCaddie = data => ({ 
  type: types.MODULE_CUMPLIMIENTO_TARIFASCADDIE_CREATED,  payload: data
});
export const deleteTarifasCaddie = data => ({ 
  type: types.MODULE_CUMPLIMIENTO_TARIFASCADDIE_DELETED,  payload: data
});

// Estádisticas
export const getStatsxCaddie = token => ({ 
  type: types.MODULE_CUMPLIMIENTO_STATSCADDIE_LOADED, 
  payload: {
    token: token
  }
});

export const serverMensaje = mensaje => ({ 
  type: types.MODULE_CUMPLIMIENTO_SERVER_MESSAGE, 
  payload: {
    mensaje: mensaje
  }
});