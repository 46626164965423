// Copyright 2019 Ernesto Giron E.
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//     http://www.apache.org/licenses/LICENSE-2.0
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.​

// ACTION TYPES //
export const types = {
  MODULE_NOTIFICACION_LOADED: "MODULE_NOTIFICACION_LOADED",
  MODULE_NOTIFICACION_FILTER_OPEN: "MODULE_NOTIFICACION_FILTER_OPEN",
  MODULE_NOTIFICACION_EDIT: "MODULE_NOTIFICACION_EDIT",
  NOTIFICACION_DATA_LOADED: "NOTIFICACION_DATA_LOADED",
  NOTIFICACION_RESETDATA_LOADED: "NOTIFICACION_RESETDATA_LOADED",
  NOTIFICACION_DATABYQUERY_LOADED: "NOTIFICACION_DATABYQUERY_LOADED",
  NOTIFICACION_STATS_LOADED: "NOTIFICACION_STATS_LOADED",
  NOTIFICACION_LIST_LOADED: "NOTIFICACION_LIST_LOADED",
  NOTIFICACION_LIST_FILTERED: "NOTIFICACION_LIST_FILTERED",
  NOTIFICACION_ISFILTER_SHOW: "NOTIFICACION_ISFILTER_SHOW",
  NOTIFICACION_SELECTED: "NOTIFICACION_SELECTED",
  NOTIFICACION_NOTA_SELECTED: "NOTIFICACION_NOTA_SELECTED",
  NOTIFICACION_DATA_SELECTED: "NOTIFICACION_DATA_SELECTED",
  NOTIFICACION_SELECTED_DATA_REQUEST: "NOTIFICACION_SELECTED_DATA_REQUEST",
  // NOTIFICACION_SELECTED_DATA_EDITED: "NOTIFICACION_SELECTED_DATA_EDITED",

  NOTIFICACION_DATA_UPDATED: "NOTIFICACION_DATA_UPDATED",
  NOTIFICACION_DATA_CREATED: "NOTIFICACION_DATA_CREATED",
  NOTIFICACION_DATA_DELETED: "NOTIFICACION_DATA_DELETED",
  
  NOTIFICACION_SERVER_MESSAGE: "NOTIFICACION_SERVER_MESSAGE"
};

// REDUCERS //
export const initialState = {
  loaded: false,
  isFilterShow: false,
  edit: false,
  openfilter: false,
  filtername: '',
  listNotas: null,
  resultQueryNotas: null,
  selected: null,
  selectedNota: null,
  datosSelNota: [],
  filter: [],
  query: null,
  mensaje: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.MODULE_NOTIFICACION_LOADED:
      const { loaded } = action.payload;
      return {
        ...state,
        loaded
      };
    case types.MODULE_NOTIFICACION_EDIT:
      const { edit } = action.payload;
      return {
        ...state,
        edit
      };
    case types.MODULE_NOTIFICACION_FILTER_OPEN:
      const { openfilter, filtername } = action.payload;
      return {
        ...state,
        openfilter, 
        filtername
      };
    case types.NOTIFICACION_LIST_LOADED:
      const { listNotas } = action.payload;
      return {
        ...state,
        listNotas
      };
    case types.NOTIFICACION_STATS_LOADED:
      const { resultQueryNotas } = action.payload;
      return {
        ...state,
        resultQueryNotas
      };
    case types.NOTIFICACION_LIST_FILTERED:
      const { filter } = action.payload;
      return {
        ...state,
        filter
      };
    case types.NOTIFICACION_ISFILTER_SHOW:
      const { isFilterShow } = action.payload;
      return {
        ...state,
        isFilterShow
      };
    case types.NOTIFICACION_SELECTED:
      const { selected } = action.payload;
      return {
        ...state,
        selected
      };
    // case types.NOTIFICACION_NOTA_SELECTED:
    //   return {
    //     ...state,
    //     selectedNota:action.payload.selectedNota
    //   };
    // case types.NOTIFICACION_DATA_SELECTED:
    //   const { datosSelNota, selectedNota } = action.payload;
    //   return {
    //     ...state,
    //     datosSelNota,
    //     selectedNota
    //   };
    case types.NOTIFICACION_SERVER_MESSAGE:
      const {mensaje} = action.payload;
      return {
        ...state,
        mensaje
      };
    default:
      return state;
  }
};

// ACTIONS //
export const moduleNotificacionLoaded = options => ({ type: types.MODULE_NOTIFICACION_LOADED, payload: options });
export const displayNotificacionFilter = options => ({ type: types.NOTIFICACION_ISFILTER_SHOW, payload: options });

export const editNotificacion = options => ({ type: types.MODULE_NOTIFICACION_EDIT, payload: options });
export const changeFilterNotificacion = options => ({ type: types.MODULE_NOTIFICACION_FILTER_OPEN, payload: options });

export const fetchNotificacionesData = token => ({ 
  type: types.NOTIFICACION_DATA_LOADED, 
  payload: {
    token: token
  }
});
export const fetchNotificacionesDatabyQuery = options => ({ 
  type: types.NOTIFICACION_DATABYQUERY_LOADED, 
  payload: options
});

export const resetNotificacionesDatabyQuery = () => ({ 
  type: types.NOTIFICACION_RESETDATA_LOADED, 
  payload: {resultQueryNotas: null}
});

export const updateNotificacion = data => ({ 
  type: types.NOTIFICACION_DATA_UPDATED,  payload: data
});
export const insertNotificacion = data => ({ 
  type: types.NOTIFICACION_DATA_CREATED,  payload: data
});
export const deleteNotificacion = data => ({ 
  type: types.NOTIFICACION_DATA_DELETED,  payload: data
});

export const updateEditedNotificacionData = resultQueryNotas => ({ 
  type: types.NOTIFICACION_STATS_LOADED, 
  payload: resultQueryNotas
});

export const filterNotificacionData = filter => ({ 
  type: types.NOTIFICACION_LIST_FILTERED, 
  payload: {
    filter: filter
  }
});
export const resetfilterNotificacionData = () => ({ 
  type: types.NOTIFICACION_LIST_FILTERED, 
  payload: {filter: []}
});

export const selectNotificacion = docId => ({ 
  type: types.NOTIFICACION_SELECTED, 
  payload: {
    selected: docId
  }
});



export const serverMensaje = mensaje => ({ 
  type: types.NOTIFICACION_SERVER_MESSAGE, 
  payload: {
    mensaje: mensaje
  }
});