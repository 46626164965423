// React
import React, {useState} from "react";
// Redux imports
import { useSelector, useDispatch } from "react-redux";
import { setProyecto } from "../../../redux/reducers/proyectos";
import { updateEditedBeneficiarioDataxCovidAyudas, 
  updateEditedBeneficiarioDataxCovidAcomp, displayCovidFilter, 
  resetfilterBeneficiariosData, resetSelectBeneficiarioForPerfilxCovid,
  displayCovidAcompFilter, resetfilterBeneficiariosDataxCovidAcomp, 
  resetSelectBeneficiarioForPerfilxCovidAcomp
} from "../../../redux/reducers/covid19";

import ActionBar, {
  Action,
  ActionGroup,
  // BottomActionGroup
} from 'calcite-react/ActionBar';

// Styled Components
import styled from "styled-components";

const ActionBarLeft = styled.div`
  // float:left;
  // display: inline-flex;
  // position: relative;
  // width: auto;
  // height: 100%;
  // text-align: center;
  // z-index: 2;
  // left: 0px;
  overflow: hidden;
  // background-color: #4c4c4c;
  // color: #fff;
`;

const ItemMenuOptsIcon = styled.div`
  width: 16px;
  height: 16px;
  background: transpararent;
  font-size:0.95rem;
  font-weight:700;
  text-align: center;
`;

const MenuLeftCovid = props => {
    // const isCollapsed = useSelector(state => state.proyectos.collapsed);
    const [isCollapsed, setIsCollapsed] = useState(false);
    const activeAction = useSelector(state => state.proyectos.activeProyecto);
    const listBenef = useSelector(state => state.covid19.listBenef);
    const listBenefAcomp = useSelector(state => state.covid19.listBenefAcomp);
    const dispatch = useDispatch();

    const proyectosNames = {
      "":'Caracterización Social y Económica',
      "BONOS": "Bonos",
      "MERCADOS": "Mercados",
      "AYUDAS_COVID": "Ayudas",
      "ACOMPAÑAMIENTO_COVID": "Acompañamiento individual y familiar",
      "INGRESOS_COVID": "generación de ingresos",
      "CONFIGURACION": "Configuración del GRB", 
    };

    // useEffect(() => {
    //   dispatch(moduleLEFTPANELLoaded({activeModule:activeAction, headerTitle:proyectosNames[activeAction]}));
    // }, [dispatch]);

    const onToggleCollapse = (e) => {
      setIsCollapsed(!isCollapsed);
    };

    // const turnOffModules = () => {
    //   // dispatch(moduleUSERSLoaded({loaded:false})); // Hide Users
    //   // dispatch(moduleBENEFICIARIOSLoaded({loaded:false}));
    // }

    const clearVariables = () => {
      dispatch(displayCovidFilter({isFilterShow:false}));
      dispatch(resetfilterBeneficiariosData());
      dispatch(resetSelectBeneficiarioForPerfilxCovid());
      // Acomp
      dispatch(displayCovidAcompFilter({isFilterShow:false}));
      dispatch(resetfilterBeneficiariosDataxCovidAcomp());
      dispatch(resetSelectBeneficiarioForPerfilxCovidAcomp());
    }

    const actionSelected = (activeAction) => {
      clearVariables();
      dispatch(setProyecto({activeProyecto:activeAction, collapsed:isCollapsed, headerTitle:proyectosNames[activeAction]}));
      // turnOffModules();
      switch (activeAction) {
        // case 'MERCADOS':
        //   console.log('MERCADOS');
        //   break;
        // case 'BONOS':
        //   console.log('BONOS');
        //   break;
        case 'AYUDAS_COVID':
          // console.log('AYUDAS_COVID');
          if (listBenef && listBenef.length>0){
            dispatch(updateEditedBeneficiarioDataxCovidAyudas({listBenef:[]}));
          }
          break;
        case 'ACOMPAÑAMIENTO_COVID':
          // console.log('ACOMPAÑAMIENTO_COVID');
          if (listBenefAcomp && listBenefAcomp.length>0){
            dispatch(updateEditedBeneficiarioDataxCovidAcomp({listBenefAcomp:[]}));
          }
          break;
        case 'INGRESOS_COVID':
          console.log('INGRESOS_COVID');
          break;
        default:
          break;
      }
    };

    return (
      <ActionBarLeft>
      <ActionBar 
        // showCollapser={false}
        collapseLabel='Ocultar' 
        expandLabel='Expandir' 
        collapsed={isCollapsed}
        onToggleCollapse={onToggleCollapse}
      >
        <ActionGroup>
          {/* <Action
            onClick={() => actionSelected('MERCADOS')}
            active={activeAction === 'MERCADOS'}
            icon={<ItemMenuOptsIcon>M</ItemMenuOptsIcon>}
          >
            Mercados
          </Action>
          <Action 
              onClick={() => actionSelected('BONOS')}
              active={activeAction === 'BONOS'}
              icon={<ItemMenuOptsIcon>B</ItemMenuOptsIcon>}
            >
              Bonos
          </Action> */}
          
          <Action
            onClick={() => actionSelected('AYUDAS_COVID')}
            active={activeAction === 'AYUDAS_COVID'}
            icon={<ItemMenuOptsIcon>Ay</ItemMenuOptsIcon>}
          >
            Ayudas
          </Action>
          <Action
            onClick={() => actionSelected('INGRESOS_COVID')}
            active={activeAction === 'INGRESOS_COVID'}
            icon={<ItemMenuOptsIcon>In</ItemMenuOptsIcon>}
          >
            Ingresos
          </Action>
        <Action
          onClick={() => actionSelected('ACOMPAÑAMIENTO_COVID')}
          active={activeAction === 'ACOMPAÑAMIENTO_COVID'}
          icon={<ItemMenuOptsIcon>Ac</ItemMenuOptsIcon>}
          >Acompañamiento</Action>
        </ActionGroup>
        {/* <BottomActionGroup>
        <Action icon={<GearIcon />}>Configuración</Action>
      </BottomActionGroup> */}
      </ActionBar>
      </ActionBarLeft>
    )

}

export default MenuLeftCovid;