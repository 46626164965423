import React, {useState, useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";
import ContentEditable from 'react-contenteditable';
import { updateBeneficiarioEducacion, serverMensaje } from '../../redux/reducers/beneficiarios';
import {highlightAll, disableNewlines, pastePlainText } from '../../utils/utils';
import Alert, {AlertMessage} from 'calcite-react/Alert';
import Button, { ButtonGroup } from 'calcite-react/Button';
import Accordion, {
    AccordionSection,
    AccordionTitle,
    AccordionContent
} from 'calcite-react/Accordion';
// import Table, {
//     TableHeader,
//     TableHeaderRow,
//     TableHeaderCell,
//     TableBody,
//     TableRow,
//     TableCell
//   } from 'calcite-react/Table';


// import Select from 'calcite-react/Select';
// import {MenuItem} from 'calcite-react/Menu';

import {showToaster} from './BenefShowMesg';
import EduHogar from './BenefEduHogar';
import Capacitacion from './BenefCapacitacion';
import Habilidades from './BenefHabilidades';

import styled from "styled-components";

const BeneficiarioModulo = styled.div`
    height: auto;
    background: #fff;
    border: 0;
    padding: 0px;
    margin: 4px;
    overflow:hidden;
`;
const AccordionModulo = styled(Accordion)`
    padding:0;
`;
const AccordionTitleModule = styled(AccordionTitle)`
    background-color: #e6e6e6;
    color: #4d4d4d;
    font-size: 1.25em;
    font-weight: 700;
    // border-bottom: 1px solid #d9d9d9;
    // box-shadow: 0px 2px 6px #e1e1e1;
`;


const BenefEducacion = ({educacion}) => {
    const dispatch = useDispatch();
    const mensaje = useSelector(state => state.beneficiarios.mensaje);
    const users = useSelector(state => state.users);
    // const isEditable = users.user.tipo==='ADMINISTRADOR' ? true : false;
    const isEditable = (users.user.areatrabajo==='ADMINISTRATIVA' || users.user.areatrabajo==='TRABAJO SOCIAL' || users.user.areatrabajo==='COORDINACIÓN' || users.user.areatrabajo==='PSICOLOGÍA' ) ? true : false;
    // const canView = (users.user.areatrabajo==='ADMINISTRATIVA' || users.user.areatrabajo==='TECNOLOGÍA' ) ? true : false;
    
    const [activeSectionIndexes, setActiveSectionIndexes] = useState([]);
    const [haymenoresSelected, setHaymenoresSelected] = useState(parseInt(educacion.haymenores));
    const [estudianmenoresSelected, setEstudianmenoresSelected] = useState(parseInt(educacion.estudianmenores));
    
    const [recibeauxilioSelected, setRecibeauxilioSelected] = useState(parseInt(educacion.recibeauxilio));
    const [tienencapacitacionSelected, setTienencapacitacionSelected] = useState(parseInt(educacion.tienencapacitacion));
    const [quierencapacitarseSelected, setQuierencapacitarseSelected] = useState(parseInt(educacion.quierencapacitarse));
    
    const [error, setError] = useState(null);
    const onAccordionChange = (evt, index) => {
        activeSectionIndexes.includes(index)
        ? setActiveSectionIndexes(activeSectionIndexes.filter(
            item => index !== item,
          ))
        : setActiveSectionIndexes([...activeSectionIndexes, index]);
    }

    const [E] = useState({
        "id": educacion.id, //int
        "beneficiarioId": educacion.beneficiarioId, //15
        "haymenores": educacion.haymenores || '', //1
        "estudianmenores": educacion.estudianmenores || '', //
        "porquenoestudian": educacion.porquenoestudian || '', // 255
        "cuantosmenores": educacion.cuantosmenores, // 32
        "cuantosmayores": educacion.cuantosmayores, //255
        "matricula": educacion.matricula, //
        "mensualidad": educacion.mensualidad, //
        "pagouniformes": educacion.pagouniformes, //
        "pagoutilesescolares": educacion.pagoutilesescolares, //
        "recibeauxilio": educacion.recibeauxilio, //
        "montoauxilio": educacion.montoauxilio, //
        "entidadauxilio": educacion.entidadauxilio, //
        "porquenoauxilio": educacion.porquenoauxilio, //
        "tienencapacitacion": educacion.tienencapacitacion, //
        "quierencapacitarse": educacion.quierencapacitarse, //
        "enquecapacitacion": educacion.enquecapacitacion, //
        // "XXXXXXXX": educacion.XXXXXX, //
        "comentarios": educacion.comentarios, //text
        "actualizacion": educacion.actualizacion, //0000-00-00 00:00:00
        "version": educacion.version,
    });

    const [itemsEducacion, setItemsEducacion] = useState([
        // {"key":"id", "label": "Identificador Único", "value": E['id']},
        // {"key":"beneficiarioId", "label": "Documento de Identificación", "value": E['beneficiarioId']},
        {"key":"haymenores", "label": "Hay menores de edad en la familia?", "value": E['haymenores']},
        {"key":"estudianmenores", "label": "Los menores de edad asisten actualemente al colegio?", "value": E['estudianmenores']},
        {"key":"porquenoestudian", "label": "Porque no estudian?", "value": E['porquenoestudian']},
        {"key":"cuantosmenores", "label": "Cuántos menores de edad en la familia estudian?", "value": E['cuantosmenores']},
        {"key":"cuantosmayores", "label": "Cuántos mayores de edad del hogar estudian?", "value": E['cuantosmayores']},
        {"key":"matricula", "label": "Cuánto paga en matrículas de la familia?", "value": E['matricula']},
        {"key":"mensualidad", "label": "Cuánto paga en mensualidad de la familia?", "value": E['mensualidad']},
        {"key":"pagouniformes", "label": "Cuánto paga en uniformes y zapatos escolares?", "value": E['pagouniformes']},
        {"key":"pagoutilesescolares", "label": "Cuánto paga en útiles escolares?", "value": E['pagoutilesescolares']},
        {"key":"recibeauxilio", "label": "Recibe auxilio económico?", "value": E['recibeauxilio']},
        {"key":"montoauxilio", "label": "Cuál es el monto del auxilio?", "value": E['montoauxilio']},
        {"key":"entidadauxilio", "label": "Qué entidad le da el auxilio económico?", "value": E['entidadauxilio']},
        {"key":"porquenoauxilio", "label": "Razones por las cuales no recibe auxilio", "value": E['porquenoauxilio']},
        {"key":"tienencapacitacion", "label": "Han recibido alguna vez capacitación?", "value": E['tienencapacitacion']},
        {"key":"quierencapacitarse", "label": "Le gustaría a usted y su familia recibir capacitación?", "value": E['quierencapacitarse']},
        {"key":"enquecapacitacion", "label": "En que le gustaría a usted y su familia recibir capacitación?", "value": E['enquecapacitacion']},

        // {"key":"XXXXXX", "label": "XXXXXX", "value": E['XXXXX']},
        // {"key":"XXXXXX", "label": "XXXXXX", "value": E['XXXXX']},

        // {"key":"comentarios", "label": "Comentarios en general información sobre educación", "value": E['comentarios']},
        // {"key":"observaciones", "label": "Observaciones", "value": E['observaciones']},
        // {"key":"actualizacion", "label": "Última actualización", "value": E['actualizacion']},
        // {"key":"version", "label": "Versión", "value": E['version']}
    ]);

    useEffect(() => {
        // Guardar los resultados al salir, puede ser contraproducente al fallar la conexión con el servidor u otro evento no deseado
        // se podrián perder los datos modificafos
        
        return () => {
            // if (isUpdated){
            //     console.log('Saving appointment', isUpdated);
            // }
            dispatch(serverMensaje({}));
        }
    }, [dispatch]);

    useEffect(() => {
        if (itemsEducacion){
            // console.log(itemsEducacion);
        }
        if (mensaje.educacion!=='' && mensaje.educacion) {
            if (typeof mensaje.educacion === 'string' && mensaje.educacion.toLowerCase().includes('error')){
                showToaster('error', 'Ocurrió un fallo en el servidor', mensaje.educacion);
            } 
            // else {
            //     showToaster('success',"Educación del Beneficiario", mensaje.educacion); //"🧭 " + mensaje
            // }
        }

    }, [mensaje.educacion, itemsEducacion, dispatch]);

    const updateItemsEducacion = (f, v) => {
        const newitemseducacion = itemsEducacion.map((itm, index) => {
            if (itm.key===f){
                itm.value = v;
            }
            return itm;
        });
        setItemsEducacion(newitemseducacion);
    };

    const updateDataonServer = () =>{
        dispatch(serverMensaje({educacion:''}));
        dispatch(updateBeneficiarioEducacion( { token: users.user.claveAPI, edu:E}));
    }

    const replaceSomeText = (s) =>{
        return s
            .replace(/&nbsp;/g, ' ')
            .replace(/<br>/g, '')
            .replace(/&amp;/g, '&')
            .replace(/&gt;/g, '>')
            .replace(/&lt;/g, '<').trim()
    }

    const validateInput = (f, v, final) => {
        const regex_onlynum = /^[0-9]*$/i; ///[0-9,]|\./;
        // const regex_nombres = /^['`´a-zA-ZÀ-ÿ\u00C0-\u017F\s]+$/;
        // const regex_email = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        let new_val = null;
        let err = null;
        switch (f) {
            case 'haymenores':
                new_val = replaceSomeText(v); //1
                if (!regex_onlynum.test(new_val)) {
                    err = 'Dígite un solo número válido';
                    new_val = null;
                } else if(parseInt(new_val)>1){
                    err = 'Dígite un número válido';
                    new_val = null;
                }
                break;
            case 'estudianmenores':
                new_val = replaceSomeText(v); //1
                if (!regex_onlynum.test(new_val)) {
                    err = 'Dígite un solo número válido';
                    new_val = null;
                } else if(parseInt(new_val)>1){
                    err = 'Dígite un número válido';
                    new_val = null;
                }
                break;
            case 'porquenoestudian':
                new_val = replaceSomeText(v);
                break;
            case 'cuantosmenores':
                new_val = replaceSomeText(v).substr(0,3);
                if (!regex_onlynum.test(new_val)) {
                    err = 'Dígite solo números válidos';
                    new_val = null;
                } else if(new_val.length>2){
                    err = 'Dígite un número de menores válido';
                    new_val = null;
                }
                if (new_val===null) new_val = '';
                break;
            case 'cuantosmayores':
                new_val = replaceSomeText(v).substr(0,3);
                if (!regex_onlynum.test(new_val)) {
                    err = 'Dígite solo números válidos';
                    new_val = null;
                } else if(new_val.length>2){
                    err = 'Dígite un número de menores válido';
                    new_val = null;
                }
                if (new_val===null) new_val = '';
                break;
            case 'matricula':
                new_val = replaceSomeText(v); //
                if (!regex_onlynum.test(new_val)) {
                    err = 'Dígite un solo número válido';
                    new_val = null;
                }
                if (new_val===null) new_val = '';
                break;
            case 'mensualidad':
                new_val = replaceSomeText(v); //
                if (!regex_onlynum.test(new_val)) {
                    err = 'Dígite un solo número válido';
                    new_val = null;
                }
                if (new_val===null) new_val = '';
                break;
            case 'pagouniformes':
                new_val = replaceSomeText(v); //
                if (!regex_onlynum.test(new_val)) {
                    err = 'Dígite un solo número válido';
                    new_val = null;
                }
                if (new_val===null) new_val = '';
                break;
            case 'pagoutilesescolares':
                new_val = replaceSomeText(v); //
                if (!regex_onlynum.test(new_val)) {
                    err = 'Dígite un solo número válido';
                    new_val = null;
                }
                if (new_val===null) new_val = '';
                break;
            case 'recibeauxilio':
                new_val = replaceSomeText(v); //1
                if (!regex_onlynum.test(new_val)) {
                    err = 'Dígite un solo número válido';
                    new_val = null;
                } else if(parseInt(new_val)>1){
                    err = 'Dígite un número válido';
                    new_val = null;
                }
                break;
            case 'porquenoauxilio':
                new_val = v.substr(0,255);
                if (final)
                    new_val = replaceSomeText(v).substr(0,255);
                break;
            case 'montoauxilio':
                new_val = replaceSomeText(v); //
                if (!regex_onlynum.test(new_val)) {
                    err = 'Dígite un solo número válido';
                    new_val = null;
                }
                if (new_val===null) new_val = '';
                break;
            case 'entidadauxilio':
                new_val = v.substr(0,120);
                if (final)
                    new_val = replaceSomeText(v).substr(0,120);
                break;
            case 'tienencapacitacion':
                new_val = replaceSomeText(v); //1
                if (!regex_onlynum.test(new_val)) {
                    err = 'Dígite un solo número válido';
                    new_val = null;
                } else if(parseInt(new_val)>1){
                    err = 'Dígite un número válido';
                    new_val = null;
                }
                break;
            case 'quierencapacitarse':
                new_val = replaceSomeText(v); //1
                if (!regex_onlynum.test(new_val)) {
                    err = 'Dígite un solo número válido';
                    new_val = null;
                } else if(parseInt(new_val)>1){
                    err = 'Dígite un número válido';
                    new_val = null;
                }
                break;
            case 'enquecapacitacion':
                new_val = v.trim().substr(0,255);
                if (final)
                    new_val = replaceSomeText(v).substr(0,255);
                break;
            case 'comentarios':
                new_val = v.trim(); //text
                break;
            case 'observaciones':
                new_val = v.trim(); //text
                break;
            default:
                break;
        }
        setError(err);
        return new_val
    }
    const handleChange = evt => {
        const field = evt.currentTarget.id.split('_')[1];
        const new_val = validateInput(field, evt.target.value, false)
        if(new_val){
            E[field] = new_val
        };
    };
    const handleBlur = evt => {
        const val = evt.currentTarget.innerHTML;
        const field = evt.target.id.split('_')[1];
        const new_val = validateInput(field, val, true)
        if(new_val || new_val===''){
            E[field] = new_val;
            if (field!=='comentarios' || field!=='observaciones')
                updateItemsEducacion(field, E[field]);
            updateDataonServer();
        };
    };

    const haymenoresSelectButton = (selected) => {
        setHaymenoresSelected(selected);
        E['haymenores'] = selected === 1 ? '1' : '0';
        updateDataonServer();
    }
    const estudianmenoresSelectButton = (selected) => {
        setEstudianmenoresSelected(selected);
        E['estudianmenores'] = selected === 1 ? '1' : '0';
        updateDataonServer();
    }
    const recibeauxilioSelectButton = (selected) => {
        setRecibeauxilioSelected(selected);
        E['recibeauxilio'] = selected === 1 ? '1' : '0';
        updateDataonServer();
    }
    const tienencapacitacionSelectButton = (selected) => {
        setTienencapacitacionSelected(selected);
        E['tienencapacitacion'] = selected === 1 ? '1' : '0';
        updateDataonServer();
    }
    const quierencapacitarseSelectButton = (selected) => {
        setQuierencapacitarseSelected(selected);
        E['quierencapacitarse'] = selected === 1 ? '1' : '0';
        updateDataonServer();
    }
    

    return (
        <BeneficiarioModulo>
            <AccordionModulo
                onAccordionChange={onAccordionChange}
                activeSectionIndexes={activeSectionIndexes}
            >
                <AccordionSection>
                    <AccordionTitleModule>Educación</AccordionTitleModule>
                    {activeSectionIndexes.length>0 &&  <AccordionContent style={{marginBottom: '10px'}}>
                    {error && <div style={{color: 'red', width: '100%'}}>
                            <Alert red showIcon style={{minWidth: '100%', marginBottom: '10px'}}
                                showCloseLabel 
                                onClose={() => setError(null)}>
                                <AlertMessage>
                                    Error:{' '}
                                    <strong>{error}</strong>
                                </AlertMessage>
                            </Alert>
                        </div> 
                    }
                    {/* ITEMS */}
                    {
                        itemsEducacion.map((itm, index) => {
                            return (<div key={index} className="itemContainer">
                                <div className="itemContainer-attr-long" 
                                    style={{backgroundColor:(index % 2 === 0) ? '#f8f8f8': '#fff'}}
                                >{itm.label}</div>
                                <div className="itemContainer-value" 
                                    style={{backgroundColor:(index % 2 === 0) ? '#f8f8f8': '#fff'}}
                                >
                                    { 
                                        itm.key==='haymenores' 
                                        ?
                                            <ButtonGroup isToggle>
                                                <Button  clear={haymenoresSelected !== 1} disabled={!isEditable} 
                                                    onClick={() => {  haymenoresSelectButton(1) }}
                                                >Si</Button>
                                                <Button clear={haymenoresSelected !== 0} disabled={!isEditable} 
                                                    onClick={() => { haymenoresSelectButton(0) }}
                                                >No</Button>
                                            </ButtonGroup>
                                        :
                                        itm.key==='estudianmenores' 
                                        ?
                                            <ButtonGroup isToggle>
                                                <Button  clear={estudianmenoresSelected !== 1} disabled={!isEditable} 
                                                    onClick={() => {  estudianmenoresSelectButton(1) }}
                                                >Si</Button>
                                                <Button clear={estudianmenoresSelected !== 0} disabled={!isEditable} 
                                                    onClick={() => { estudianmenoresSelectButton(0) }}
                                                >No</Button>
                                            </ButtonGroup>
                                        :
                                        itm.key==='recibeauxilio' 
                                        ?
                                            <ButtonGroup isToggle>
                                                <Button  clear={recibeauxilioSelected !== 1} disabled={!isEditable} 
                                                    onClick={() => {  recibeauxilioSelectButton(1) }}
                                                >Si</Button>
                                                <Button clear={recibeauxilioSelected !== 0} disabled={!isEditable} 
                                                    onClick={() => { recibeauxilioSelectButton(0) }}
                                                >No</Button>
                                            </ButtonGroup>
                                        :
                                        itm.key==='tienencapacitacion' 
                                        ?
                                            <ButtonGroup isToggle>
                                                <Button  clear={tienencapacitacionSelected !== 1} disabled={!isEditable} 
                                                    onClick={() => {  tienencapacitacionSelectButton(1) }}
                                                >Si</Button>
                                                <Button clear={tienencapacitacionSelected !== 0} disabled={!isEditable} 
                                                    onClick={() => { tienencapacitacionSelectButton(0) }}
                                                >No</Button>
                                            </ButtonGroup>
                                        :
                                        itm.key==='quierencapacitarse' 
                                        ?
                                            <ButtonGroup isToggle>
                                                <Button  clear={quierencapacitarseSelected !== 1} disabled={!isEditable} 
                                                    onClick={() => {  quierencapacitarseSelectButton(1) }}
                                                >Si</Button>
                                                <Button clear={quierencapacitarseSelected !== 0} disabled={!isEditable} 
                                                    onClick={() => { quierencapacitarseSelectButton(0) }}
                                                >No</Button>
                                            </ButtonGroup>
                                        :
                                            <ContentEditable
                                                id={'educacion_'+itm.key}
                                                html={itm.value || ''} // innerHTML of the editable div
                                                className='itemContainer-value-long'
                                                disabled={!isEditable}      // use true to disable editing
                                                onPaste={pastePlainText} // En caso se copie y pegue de otra pagina de internet
                                                onChange={handleChange} // handle innerHTML change
                                                onKeyPress={disableNewlines}  
                                                onFocus={highlightAll}
                                                onBlur={handleBlur}
                                                tagName='div' // Use a custom HTML tag (uses a div by default)
                                            />
                                        }

                                </div>
                            </div>)
                        })
                    }

                    {/* Tabla Educación en el Hogar */}
                    {(E.estudianmenores || E.estudianmenores==='1') && educacion.educacionenhogar && 
                        <div className="itemContainer-table">
                            <div className="tablas-Beneficiario-detalles">
                                <div className="benef-texto-observaciones-title">Quienes estudian en la familia</div>
                                <EduHogar educacionenhogar={educacion.educacionenhogar} beneficiarioId={educacion.beneficiarioId}/>
                            </div>
                        </div>
                    }

                    {/* Tabla Capacitacion en el Hogar */}
                    {(E.tienencapacitacion || E.tienencapacitacion==='1') && educacion.capacitacion && 
                        <div className="itemContainer-table">
                            <div className="tablas-Beneficiario-detalles">
                                <div className="benef-texto-observaciones-title">Quienes capacitados en la familia</div>
                                <Capacitacion capacitacion={educacion.capacitacion} beneficiarioId={educacion.beneficiarioId}/>
                            </div>
                        </div>
                    }

                    {/* Tabla Habilidades en el Hogar */}
                    {educacion.habilidades && <div className="itemContainer-table">
                        <div className="tablas-Beneficiario-detalles">
                            <div className="benef-texto-observaciones-title">Habilidades de los integrantes de la familia</div>
                            <Habilidades habilidades={educacion.habilidades} beneficiarioId={educacion.beneficiarioId}/>
                        </div>
                    </div>}

                    <div className="Observaciones-Comentarios">
                        <div className="benef-texto-observaciones-title">Comentarios en general:</div>
                        <div className="benef-texto-observaciones"> 
                            <ContentEditable
                                id={'educacion_comentarios'}
                                html={E.comentarios || ''} // innerHTML of the editable div
                                disabled={!isEditable}        // use true to disable editing
                                onPaste={pastePlainText}
                                onChange={handleChange} // handle innerHTML change
                                onFocus={highlightAll}
                                onBlur={handleBlur}
                                tagName='div' // Use a custom HTML tag (uses a div by default)
                                style={{ "minHeight": "40px", "border": "1px dashed" }}
                            />
                        </div>
                    </div>
                    <div className="Observaciones-Comentarios">
                        <div className="benef-texto-observaciones-title">Observaciones:</div>
                        <div className="benef-texto-observaciones"> 
                            <ContentEditable
                                id={'educacion_observaciones'}
                                html={E.observaciones || ''} // innerHTML of the editable div
                                disabled={!isEditable}        // use true to disable editing
                                onPaste={pastePlainText}
                                onChange={handleChange} // handle innerHTML change
                                onFocus={highlightAll}
                                onBlur={handleBlur}
                                tagName='div' // Use a custom HTML tag (uses a div by default)
                                style={{ "minHeight": "40px", "border": "1px dashed" }}
                            />
                        </div>
                    </div>

                    <div className="benef-texto-actualizacion">Última actualización: {E['actualizacion']}</div>

                    </AccordionContent>
                }
                </AccordionSection>
            </AccordionModulo>
        </BeneficiarioModulo>
    );
}

export default BenefEducacion;

