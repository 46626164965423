import Axios from 'axios';
// import config from '../config';
// connfig  process.env.PUBLIC_URL + "/config_grb.json";
// http://fundacionclubfarallones.org/investigacion/ECASE/beta/api/v2/
// http://192.168.0.5/~ernestogiron/FunFarallones/ECASE/api/v2/
export default Axios.create({
    baseURL: 'https://fundacionclubfarallones.org/investigacion/ECASE/beta/api/v2/', //apiGateway.URL,
    responseType: 'json',
    responseEncoding: 'utf8',
    headers: {
        // 'X-Requested-With': 'XMLHttpRequest'
        'Content-Type':'application/x-www-form-urlencoded', 
        'Accept':'*/*',
    },
    
});

// Axios.defaults.baseURL = useSelector(state => state.config.config).apiGateway.URL;
// Axios.defaults.headers.common['Cache-Control'] = 'no-cache';
// Axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// Axios.defaults.headers.common["Authorization"] = `Bearer${token}`;
// Axios.defaults.headers.common["Content-Type"]="application/json";
// Axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
