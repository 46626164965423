// Copyright 2020 Ernesto Giron E.
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//     http://www.apache.org/licenses/LICENSE-2.0
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.​

// React imports
import React, { useEffect } from "react";

// Redux imports
import { useSelector, useDispatch } from "react-redux";
import { fetchConfig } from "../redux/reducers/config";
// import { checkAuth, startAuth, completeAuth } from "../redux/reducers/auth";

// Component imports
import LoadScreen from "./LoadScreen";
import LoginScreen from "./LoginScreen";
// import Main from "./Main";
// import MainBeneficiario from "./MainBeneficiario";

// Component definition
const App = props => {
  
  // we'll use the url to determin sign-in state
  // const { pathname } = props.location;
  
  // redux store state
  // const loggedIn = useSelector(state => state.auth.loggedIn);
  const config = useSelector(state => state.config);
  const dispatch = useDispatch();
  
  // Define config file
  // when the component mounts request the config and load it into the Redux state
  useEffect(() => {
    dispatch(fetchConfig());
  }, [dispatch]);

  
  // once the component mounts and the config loads, check if we have a saved session
  // useEffect(() => {
  //   // if the config isn't yet loaded then skip this effect
  //   if (!config.loaded) {
  //     return;
  //   }
  //   const { portalUrl, clientId, sessionId } = config;
  //   dispatch(checkAuth({ portalUrl, clientId, sessionId }));
  // }, [config, dispatch]);

  // if there's no stored session, we'll watch the url path to see if we need to kick off an authentication
  // this can happen automatically with a portalUrl property in the config
  // or if the user requests a login through an event
  // useEffect(() => {
  //   // if the config isn't yet loaded then skip this effect
  //   if (!config.loaded) {
  //     return;
  //   }

  //   const { portalUrl, clientId, sessionId } = config;

  //   // we'll start the authentication here and it will return here to complete
  //   // if (portalUrl && !user && pathname !== "/auth") {
  //   //   dispatch(startAuth({ portalUrl, clientId, sessionId }));
  //   // } else if (pathname === "/auth" && !user) {
  //   //   dispatch(completeAuth({ portalUrl, clientId, sessionId }));
  //   // }
  // }, [config, pathname, dispatch]);

  // set a halt state to allow the authentication process to complete before
  // we redirect to the main component
  // let signInRequested = false;
  // if (pathname === "/auth") {
  //   signInRequested = true;
  // }

  // RENDER RETURN
  // app is initializing for the following reasons, show the load screen
  // 1. config is not yet loaded
  // 2. authentication is required but there is no user information
  // 3. authentication is not required but user has requested to sign-in
  if ( !config.loaded ) { //|| (config.portalUrl)
    return <LoadScreen isLoaded={false} />;
  }
  // LOGIN
  // Siempre retorne Login Screen
  // if ( config.loaded && (!loggedIn && config.user.claveAPI=="")) {
    return <LoginScreen isLoaded={false}/>;
  // }

  // if (loggedIn && config.user.tipo ==='Beneficiario') {
  //   console.log('Debería pasar por aquí');
  //   return (
  //     <>
  //       <Route path="/GRB/Beneficiario" component={MainBeneficiario} />
  //       <Redirect to="/GRB/Beneficiario" />
  //     </>
  //   );
  // }
  // App is initialized and user is authenticated if needed, route to main component
  // return (
  //   <>
  //     <Route path="/GRB" component={Main} />
  //     <Redirect to="/GRB" />
  //   </>
  // );
};

export default App;
