// Copyright 2020 Ernesto Giron E.
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//     http://www.apache.org/licenses/LICENSE-2.0
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.​

// React imports
import React from "react";
import { Redirect } from "react-router-dom";
// Redux imports
import { useSelector } from "react-redux";

import background from "../assets/logo-solo.png";

// Component imports
import Loader from 'calcite-react/Loader';
import {ToastContainer} from 'calcite-react/Toaster';
import HeaderGRB from './ui_header';
import FooterGRB from './ui_footer';
import SubNavGRB from './ui_subNav';
import PanelLeftGRB from './ui_panelLeft';
import PanelLeftFormacion from './formacion/ui_panelFormacion';
import PanelLeftBienestar from './bienestar/ui_panelBienestar';
import PanelLeftVivienda from './vivienda/ui_panelVivienda';

import SideNavlLeftGRB from './ui_sideNavLeft';
// import PanelRightGRB from './ui_panelRight';
// import ModuleUsersGRB from './ModuleUsers'; // old version
import ModuleUsersGRB from './users/ModuleUsers';
import ModuleBeneficiariosGRB from './ModuleBeneficiarios';
import TableNotificaciones from './notificaciones/ModuleNotificaciones'; //ModuleNotificaciones
import TablePrestamos from './prestamos/ModulePrestamos';
import TableAuxilios from './auxilios/ModuleAuxilios';
// import EditUserGRB from './EditUser';

// Menus secundarios o proyectos
// Formación
import MenuLeftVinculacionFormacion from './formacion/VinculacionFormacion/menuVinculacionFormacion';
import MenuLeftEmprendimientoFormacion from './formacion/EmprendimientoFormacion/menuEmprendimientoFormacion';
import MenuLeftCompraEmpleabilidadFormacion from './formacion/CompraEmpleabilidadFormacion/menuCompraEmpleabilidadFormacion';
import MenuLeftFormacionHabVida from './formacion/FormacionHabVida/menuFormacionHabVida';

// Vivienda
import MenuLeftCompraVivienda from './vivienda/CompraVivienda/menuCompraVivienda';
import MenuLeftMejoramientoVivienda from './vivienda/MejoramientoVivienda/menuMejoramientoVivienda';

// Bienestar
import MenuLeftFondoAsisSocial from './bienestar/FondoAsisSocial/menuFondoAsisSocial';
import MenuLeftJornadasSalud from './bienestar/JornadasSalud/menuJornadasSalud';
import MenuLeftDeporteRecreCultura from './bienestar/DeporteRecreCultura/menuDeporteRecreCultura';
import MenuLeftVestuario from './bienestar/Vestuario/menuVestuario';
import MenuLeftAlimentacion from './bienestar/Alimentacion/menuAlimentacion';
import MenuLeftSeguridadSocial from './bienestar/SeguridadSocial/menuSeguridadSocial';
import MenuLeftReconocimientos from './bienestar/Reconocimientos/menuReconocimientos';
import MenuLeftCovid from './bienestar/Covid19/menuCovid19';

// Proyectos - VESTUARIO - Tallaje
import ModuleVestuarioTallaje from './bienestar/Vestuario/moduleVestuarioTallaje';

// Proyectos - BIENESTAR - COVID19
// import ModuleCovid19Mercados from './bienestar/Covid19/moduleCovid19_Mercados';
// import ModuleCovid19Bonos from './bienestar/Covid19/moduleCovid19_Bonos';
import ModuleCovid19Ayudas from './bienestar/Covid19/moduleCovid19_Ayudas';
import ModuleCovid19Acomp from './bienestar/Covid19/moduleCovid19_Acomp';
// import ModuleCovid19Inregsos from './bienestar/Covid19/moduleCovid19_Ingresos';
import ActionBarRight from './bienestar/Covid19/ActionBarRight';

// Styled Components
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  overflow: hidden;
`;

const GRBWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 0;
  overflow: hidden;
  background-color: #fff;
  text-align: left;
`;

const GRBPanelLeft = styled.div`
  float:left;
  display: inline-flex;
  position: relative;
  width: auto;
  height: 100%;
  text-align: center;
  z-index: 2;
  left: 0px;
  overflow: auto;
  // background-color: #4c4c4c;
  color: #fff;
`;

const GRBPanelRight = styled.div`
  position: absolute;
  width: auto;
  height: 100%;
  text-align: center;
  z-index: 2;
  // background-color: #fff;
  right: 0;
  float: right;
  top: 0;
  border: 1px solid #ececec;
`;

const GRBPanelCentral = styled.div`
  display: block;
  position: relative;
  height: 100%;
  text-align: center;
  z-index: 1;
  background-color: #f8f8f8;
  left: auto;
  overflow: auto;
  // padding-right: 55px;
`;

const GRBPanelCentralBground = styled.div`
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  z-index: -1;
  left: auto;
  background: rgba(255, 255, 255, 0.1) url(${background}) no-repeat center;
  opacity:0.2;  
`;

// Component definition
const Main = props => {
  // const config = useSelector(state => state.config);
  // const user = useSelector(state => state.auth.user);
  const loggedIn = useSelector(state => state.auth.loggedIn);
  const activeProgram = useSelector(state => state.programas.activeProgram);
  const activeModule = useSelector(state => state.modulos.activeModule);
  const activeProyecto = useSelector(state => state.proyectos.activeProyecto);
  const isBeneficiariosLoaded = useSelector(state => state.beneficiarios.loaded);
  // const isBienestarCovidLoaded = useSelector(state => state.bienestar.loaded);
  const isCovidAyudasLoaded = useSelector(state => state.covid19.loaded);
  const isNotificacionesLoaded = useSelector(state => state.notas.loaded);
  const isPrestamosLoaded = useSelector(state => state.prestamos.loaded);
  const isAuxiliosLoaded = useSelector(state => state.auxilios.loaded);

  // Filtros
  const isFilterBeneficiariosShow = useSelector(state => state.beneficiarios.isFilterShow);
  const isFilterCovidAyudasShow = useSelector(state => state.covid19.isFilterShow);
  const isFilterCovidAcompShow = useSelector(state => state.covid19.isFilterAcompShow);

  // const isEditBeneficiario = useSelector(state => state.beneficiarios.edit);
  const isUsersLoaded = useSelector(state => state.users.loaded);
  const isEditUser = useSelector(state => state.users.edit);
  
  if (loggedIn) {
    return (
      <Container>
        <ToastContainer position="bottom-right" />
        <HeaderGRB>
        </HeaderGRB>
        <SubNavGRB/>
        <GRBWrapper>
          {!isEditUser && <GRBPanelLeft>
              {activeProgram==='' && <PanelLeftGRB/>}
              {activeProgram==='FORMACIÓN' && <PanelLeftFormacion />}
              {activeProgram==='VIVIENDA' && <PanelLeftVivienda />}
              {activeProgram==='BIENESTAR' && <PanelLeftBienestar />}

              {/* Menus FORMACIÓN */}
              {activeModule==='VINCULACION' && activeProgram==='FORMACIÓN' && <MenuLeftVinculacionFormacion />}
              {activeModule==='EMPRENDIMIENTO' && activeProgram==='FORMACIÓN' && <MenuLeftEmprendimientoFormacion />}
              {activeModule==='EMPLEABILIDAD' && activeProgram==='FORMACIÓN' && <MenuLeftCompraEmpleabilidadFormacion />}
              {activeModule==='FORMACIONHABVIDA'  && activeProgram==='FORMACIÓN' && <MenuLeftFormacionHabVida />}
              {/* {activeModule==='CONFIGURACION' && activeProgram==='FORMACIÓN' && <MenuLeftConfigFormacion />} */}
             
              {/* Menus VIVIENDA */}
              {activeModule==='COMPRA' && activeProgram==='VIVIENDA' && <MenuLeftCompraVivienda />}
              {activeModule==='MEJORAMIENTO' && activeProgram==='VIVIENDA' && <MenuLeftMejoramientoVivienda />}
              {/* {activeModule==='CONFIGURACION' && activeProgram==='VIVIENDA' && <MenuLeftConfigVivienda />} */}

              {/* Menus BIENESTAR */}
              {activeModule==='FONDOASISSOCIAL'  && activeProgram==='BIENESTAR' && <MenuLeftFondoAsisSocial />}
              {activeModule==='JORNADASSALUD'  && activeProgram==='BIENESTAR' && <MenuLeftJornadasSalud />}
              {activeModule==='DEPORTERECRECULTURA'  && activeProgram==='BIENESTAR' && <MenuLeftDeporteRecreCultura />}
              {activeModule==='VESTUARIO'  && activeProgram==='BIENESTAR' && <MenuLeftVestuario />}
              {activeModule==='ALIMENTACION' && activeProgram==='BIENESTAR' && <MenuLeftAlimentacion />}
              {activeModule==='SEGURIDADSOCIAL' && activeProgram==='BIENESTAR' && <MenuLeftSeguridadSocial />}
              {activeModule==='RECONOCIMIENTOS' && activeProgram==='BIENESTAR' && <MenuLeftReconocimientos />}
              {activeModule==='COVID19' && activeProgram==='BIENESTAR' && <MenuLeftCovid />}
              {/* {activeModule==='CONFIGURACION' && activeProgram==='BIENESTAR' && <MenuLeftConfigBienestar />}  */}

              {/* Filtro Beneficiarios */}
              {isFilterBeneficiariosShow && isBeneficiariosLoaded && activeProgram==='' && <SideNavlLeftGRB isFilterBeneficiariosShow={isFilterBeneficiariosShow}/>}
              {isFilterCovidAyudasShow && activeProgram==='BIENESTAR' && activeProyecto==='AYUDAS_COVID' && <SideNavlLeftGRB isFilterCovidAyudasShow={isFilterCovidAyudasShow}/>}
              {isFilterCovidAcompShow && activeProgram==='BIENESTAR' && activeProyecto==='ACOMPAÑAMIENTO_COVID' && <SideNavlLeftGRB isFilterCovidAcompShow={isFilterCovidAcompShow}/>}

            </GRBPanelLeft>
          }
          <GRBPanelCentral>
            <GRBPanelCentralBground/>
            
            {isUsersLoaded && activeProgram==='' && <ModuleUsersGRB/>}

            {!isBeneficiariosLoaded && activeModule==='BENEFICIARIOS' && <Loader color="#009937" text="Cargando Beneficiarios..."/>}
            {activeModule==='BENEFICIARIOS' && activeProgram==='' && <ModuleBeneficiariosGRB/>}

            {/* NOTIFICACIONES */}
            {!isNotificacionesLoaded && activeModule==='NOTIFICACIONES' && <Loader color="#009937" text="Cargando Notificaciones..."/>}
            {activeModule==='NOTIFICACIONES' && activeProgram==='' && <TableNotificaciones/>}
            
            {/* PRESTAMOS */}
            {!isPrestamosLoaded && activeModule==='PRESTAMOS' && <Loader color="#009937" text="Cargando Prestamos..."/>}
            {activeModule==='PRESTAMOS' && activeProgram==='' && <TablePrestamos/>}
            
            {/* AUXILIOS */}
            {!isAuxiliosLoaded && activeModule==='AUXILIOS' && <Loader color="#009937" text="Cargando Auxilios..."/>}
            {activeModule==='AUXILIOS' && activeProgram==='' && <TableAuxilios/>}
            
            
            {/* {isEditUser && <EditUserGRB/>} */}
            {/* {isEditBeneficiario && <EditBeneficiarioGRB/>} */}

            {/* VESTUARIO */}
            {activeProyecto==='TALLAJE_VESTUARIO' && activeModule==='VESTUARIO' && activeProgram==='BIENESTAR' && <ModuleVestuarioTallaje/>}
            
            {/* BIENESTAR - COVID19 */}
            {!isCovidAyudasLoaded && activeProyecto==='AYUDAS_COVID' && <Loader color="#009937" text="Cargando ayudas..."/>}
            {/* {activeProyecto==='MERCADOS' && activeModule==='COVID19' && activeProgram==='BIENESTAR' && <ModuleCovid19Mercados/>} */}
            {/* {activeProyecto==='BONOS' && activeModule==='COVID19' && activeProgram==='BIENESTAR' && <ModuleCovid19Bonos/>} */}
            {activeProyecto==='AYUDAS_COVID' && activeModule==='COVID19' && activeProgram==='BIENESTAR' && <ModuleCovid19Ayudas/>}
            {activeProyecto==='ACOMPAÑAMIENTO_COVID' && activeModule==='COVID19' && activeProgram==='BIENESTAR' && <ModuleCovid19Acomp/>}
            {/* {activeProyecto==='INGRESOS_COVID' && activeModule==='COVID19' && activeProgram==='BIENESTAR' && <ModuleCovid19Inregsos/>} */}

          </GRBPanelCentral>
          <GRBPanelRight>
              {/* {isBeneficiariosLoaded && <PanelRightGRB/>} */}
              { activeModule==='COVID19' && activeProgram==='BIENESTAR' && activeProyecto==='AYUDAS_COVID' && <ActionBarRight/>}

          </GRBPanelRight>
        </GRBWrapper>
        <FooterGRB/>
      </Container>


    );
  } 

  return (
    <>
        <Redirect to="/" />
    </>
    );
  
};


export default Main;
