// Copyright 2020 Ernesto Giron E.
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//     http://www.apache.org/licenses/LICENSE-2.0
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.​

// ACTION TYPES //
export const types = {
  MODULE_COVID19_LOADED: "MODULE_COVID19_LOADED",
  MODULE_COVID19_AYUDAS_LIST_LOADED: "MODULE_COVID19_AYUDAS_LIST_LOADED",
  MODULE_COVID19_TABLASCRITERIOS_LIST_LOADED: "MODULE_COVID19_TABLASCRITERIOS_LIST_LOADED",
  MODULE_COVID19_BENEFDATA_LOADED: "MODULE_COVID19_BENEFDATA_LOADED",
  MODULE_COVID19_LISTAENTREGASDATA_LOADED: "MODULE_COVID19_LISTAENTREGASDATA_LOADED",
  MODULE_COVID19_AYUDAS_DATA_LOADED: "MODULE_COVID19_AYUDAS_DATA_LOADED",
  MODULE_COVID19_BENEFICIARIOS_LIST_LOADED: "MODULE_COVID19_BENEFICIARIOS_LIST_LOADED",
  MODULE_COVID19_BENEFICIARIO_DATA_SELECTED: "MODULE_COVID19_BENEFICIARIO_DATA_SELECTED",
  MODULE_COVID19_BENEFICIARIO_SELECTED_DATA_REQUEST: "MODULE_COVID19_BENEFICIARIO_SELECTED_DATA_REQUEST",
  MODULE_COVID19_BENEFICIARIO_PROFILE_SELECTED: "MODULE_COVID19_BENEFICIARIO_PROFILE_SELECTED",
  MODULE_COVID19_BENEFICIARIOS_LIST_FILTERED: "MODULE_COVID19_BENEFICIARIOS_LIST_FILTERED",
  MODULE_COVID19_ISFILTER_SHOW: "MODULE_COVID19_ISFILTER_SHOW",
  MODULE_COVID19_ISADDBENEFALISTENTREGA_SHOW: "MODULE_COVID19_ISADDBENEFALISTENTREGA_SHOW",
  MODULE_COVID19_ISFILTER_ACOMP_SHOW: "MODULE_COVID19_ISFILTER_ACOMP_SHOW",
  MODULE_COVID19_COSTOTOTAL_AYUDAS: "MODULE_COVID19_COSTOTOTAL_AYUDAS",
  COVID19_SELECTED_AYUDAS_UPDATED: "COVID19_SELECTED_AYUDAS_UPDATED",
  COVID19_SELECTED_AYUDAS_CREATED: "COVID19_SELECTED_AYUDAS_CREATED",
  COVID19_SELECTED_AYUDAS_DELETED: "COVID19_SELECTED_AYUDAS_DELETED",

  COVID19_LISTENTREGA_UPDATED: "COVID19_LISTENTREGA_UPDATED",
  COVID19_LISTENTREGA_CREATED: "COVID19_LISTENTREGA_CREATED",
  COVID19_LISTENTREGA_DELETED: "COVID19_LISTENTREGA_DELETED",
  COVID19_LISTENTREGA_CLEAN: "COVID19_LISTENTREGA_CLEAN",
  COVID19_LISTENTREGA_BATCH: "COVID19_LISTENTREGA_BATCH",
  COVID19_LISTENTREGA_VALIDAR: "COVID19_LISTENTREGA_VALIDAR",

  MODULE_COVID19_LISTENTREGA_SHOW: "MODULE_COVID19_LISTENTREGA_SHOW",
  MODULE_COVID19_ENTREGA_LIST_LOADED: "MODULE_COVID19_ENTREGA_LIST_LOADED",
  MODULE_COVID19_TABLASCRITERIOS_LOADED: "MODULE_COVID19_TABLASCRITERIOS_LOADED",

  COVID19_CRITERIOS_PRESTASERVICIO_UPDATED: "COVID19_CRITERIOS_PRESTASERVICIO_UPDATED",
  COVID19_CRITERIOS_PRESTASERVICIO_CREATED: "COVID19_CRITERIOS_PRESTASERVICIO_CREATED",
  COVID19_CRITERIOS_PRESTASERVICIO_DELETED: "COVID19_CRITERIOS_PRESTASERVICIO_DELETED",

  COVID19_CRITERIOS_FAMILIACLUB_UPDATED: "COVID19_CRITERIOS_FAMILIACLUB_UPDATED",
  COVID19_CRITERIOS_FAMILIACLUB_CREATED: "COVID19_CRITERIOS_FAMILIACLUB_CREATED",
  COVID19_CRITERIOS_FAMILIACLUB_DELETED: "COVID19_CRITERIOS_FAMILIACLUB_DELETED",

  COVID19_CRITERIOS_CABEZAHOGAR_UPDATED: "COVID19_CRITERIOS_CABEZAHOGAR_UPDATED",
  COVID19_CRITERIOS_CABEZAHOGAR_CREATED: "COVID19_CRITERIOS_CABEZAHOGAR_CREATED",
  COVID19_CRITERIOS_CABEZAHOGAR_DELETED: "COVID19_CRITERIOS_CABEZAHOGAR_DELETED",

  COVID19_CRITERIOS_PERSONASACARGO_UPDATED: "COVID19_CRITERIOS_PERSONASACARGO_UPDATED",
  COVID19_CRITERIOS_PERSONASACARGO_CREATED: "COVID19_CRITERIOS_PERSONASACARGO_CREATED",
  COVID19_CRITERIOS_PERSONASACARGO_DELETED: "COVID19_CRITERIOS_PERSONASACARGO_DELETED",

  COVID19_CRITERIOS_NUMEROHIJOS_UPDATED: "COVID19_CRITERIOS_NUMEROHIJOS_UPDATED",
  COVID19_CRITERIOS_NUMEROHIJOS_CREATED: "COVID19_CRITERIOS_NUMEROHIJOS_CREATED",
  COVID19_CRITERIOS_NUMEROHIJOS_DELETED: "COVID19_CRITERIOS_NUMEROHIJOS_DELETED",

  COVID19_CRITERIOS_ASISTENCIA_UPDATED: "COVID19_CRITERIOS_ASISTENCIA_UPDATED",
  COVID19_CRITERIOS_ASISTENCIA_CREATED: "COVID19_CRITERIOS_ASISTENCIA_CREATED",
  COVID19_CRITERIOS_ASISTENCIA_DELETED: "COVID19_CRITERIOS_ASISTENCIA_DELETED",

  COVID19_CRITERIOS_ACTIVIDAD_UPDATED: "COVID19_CRITERIOS_ACTIVIDAD_UPDATED",
  COVID19_CRITERIOS_ACTIVIDAD_CREATED: "COVID19_CRITERIOS_ACTIVIDAD_CREATED",
  COVID19_CRITERIOS_ACTIVIDAD_DELETED: "COVID19_CRITERIOS_ACTIVIDAD_DELETED",

  COVID19_CRITERIOS_CATEGORIA_UPDATED: "COVID19_CRITERIOS_CATEGORIA_UPDATED",
  COVID19_CRITERIOS_CATEGORIA_CREATED: "COVID19_CRITERIOS_CATEGORIA_CREATED",
  COVID19_CRITERIOS_CATEGORIA_DELETED: "COVID19_CRITERIOS_CATEGORIA_DELETED",

  COVID19_CRITERIOS_EDAD_UPDATED: "COVID19_CRITERIOS_EDAD_UPDATED",
  COVID19_CRITERIOS_EDAD_CREATED: "COVID19_CRITERIOS_EDAD_CREATED",
  COVID19_CRITERIOS_EDAD_DELETED: "COVID19_CRITERIOS_EDAD_DELETED",

  COVID19_CRITERIOS_EDADCLUB_UPDATED: "COVID19_CRITERIOS_EDADCLUB_UPDATED",
  COVID19_CRITERIOS_EDADCLUB_CREATED: "COVID19_CRITERIOS_EDADCLUB_CREATED",
  COVID19_CRITERIOS_EDADCLUB_DELETED: "COVID19_CRITERIOS_EDADCLUB_DELETED",

  COVID19_CRITERIOS_CUPO_UPDATED: "COVID19_CRITERIOS_CUPO_UPDATED",
  COVID19_CRITERIOS_CUPO_CREATED: "COVID19_CRITERIOS_CUPO_CREATED",
  COVID19_CRITERIOS_CUPO_DELETED: "COVID19_CRITERIOS_CUPO_DELETED",

  COVID19_ACOMP_BENEFDATA_LOADED: "COVID19_ACOMP_BENEFDATA_LOADED",
  COVID19_ACOMP_BENEFICIARIOS_LIST_LOADED: "COVID19_ACOMP_BENEFICIARIOS_LIST_LOADED",
  COVID19_ACOMP_BENEFICIARIOS_LIST_FILTERED: "COVID19_ACOMP_BENEFICIARIOS_LIST_FILTERED",
  COVID19_ACOMP_BENEFICIARIO_PROFILE_SELECTED: "COVID19_ACOMP_BENEFICIARIO_PROFILE_SELECTED",
  COVID19_ACOMP_BENEFICIARIO_DATA_SELECTED: "COVID19_ACOMP_BENEFICIARIO_DATA_SELECTED",
  COVID19_ACOMP_BENEFICIARIO_SELECTED_DATA_REQUEST: "COVID19_ACOMP_BENEFICIARIO_SELECTED_DATA_REQUEST",

  MODULE_COVID19_SERVER_MESSAGE: "MODULE_COVID19_SERVER_MESSAGE",
};

// REDUCERS //
export const initialState = {
  loaded: false,
  isFilterShow: false,
  isAddBenefaListEntrega: false,
  isFilterAcompShow: false,
  isListEntrega: false,
  listBenef: [],
  listBenefAcomp: [],
  listAyudas: [],
  listEntrega: null,
  listCriterios: [],
  selectedProfile: null,
  selectedProfileAcomp: null,
  datosSelBenef: [],
  datosSelBenefAcomp: [],
  filter: [],
  filterAcomp: [],
  costoTotalAyudas: 0,
  mensaje: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.MODULE_COVID19_LOADED:
      const { loaded } = action.payload;
      return {
        ...state,
        loaded
      };
    case types.MODULE_COVID19_AYUDAS_LIST_LOADED:
      const { listAyudas } = action.payload;
      return {
        ...state,
        listAyudas
      };
    case types.MODULE_COVID19_TABLASCRITERIOS_LIST_LOADED:
      const { listCriterios } = action.payload;
      return {
        ...state,
        listCriterios
      };
    case types.MODULE_COVID19_BENEFICIARIOS_LIST_LOADED:
      const { listBenef } = action.payload;
      return {
        ...state,
        listBenef
      };
    case types.COVID19_ACOMP_BENEFICIARIOS_LIST_LOADED:
      const { listBenefAcomp } = action.payload;
      return {
        ...state,
        listBenefAcomp
      };
    case types.MODULE_COVID19_ENTREGA_LIST_LOADED:
      const { listEntrega } = action.payload;
      return {
        ...state,
        listEntrega
      };
    case types.MODULE_COVID19_BENEFICIARIO_DATA_SELECTED:
      const { datosSelBenef, selectedProfile } = action.payload;
      return {
        ...state,
        datosSelBenef,
        selectedProfile
      };
    case types.COVID19_ACOMP_BENEFICIARIO_DATA_SELECTED:
      const { datosSelBenefAcomp, selectedProfileAcomp } = action.payload;
      return {
        ...state,
        datosSelBenefAcomp,
        selectedProfileAcomp
      };
    case types.MODULE_COVID19_BENEFICIARIO_PROFILE_SELECTED:
      return {
        ...state,
        selectedProfile:action.payload.selectedProfile
      };
    case types.COVID19_ACOMP_BENEFICIARIO_PROFILE_SELECTED:
      return {
        ...state,
        selectedProfileAcomp:action.payload.selectedProfileAcomp
      };
    case types.MODULE_COVID19_BENEFICIARIOS_LIST_FILTERED:
      const { filter } = action.payload;
      return {
        ...state,
        filter
      };
    case types.COVID19_ACOMP_BENEFICIARIOS_LIST_FILTERED:
      const { filterAcomp } = action.payload;
      return {
        ...state,
        filterAcomp
      };
    case types.MODULE_COVID19_ISFILTER_SHOW:
      const { isFilterShow } = action.payload;
      return {
        ...state,
        isFilterShow
      };
    case types.MODULE_COVID19_ISADDBENEFALISTENTREGA_SHOW:
      const { isAddBenefaListEntrega } = action.payload;
      return {
        ...state,
        isAddBenefaListEntrega
      };
      
    case types.MODULE_COVID19_ISFILTER_ACOMP_SHOW:
      const { isFilterAcompShow } = action.payload;
      return {
        ...state,
        isFilterAcompShow
      };
    case types.MODULE_COVID19_LISTENTREGA_SHOW:
      const { isListEntrega } = action.payload;
      return {
        ...state,
        isListEntrega
      };
    case types.MODULE_COVID19_COSTOTOTAL_AYUDAS:
      const { costoTotalAyudas } = action.payload;
      return {
        ...state,
        costoTotalAyudas
      };
    case types.MODULE_COVID19_SERVER_MESSAGE:
      const {mensaje} = action.payload;
      return {
        ...state,
        mensaje
      };
    default:
      return state;
  }
};

// ACTIONS //
export const moduleCOVID19Loaded = options => ({ type: types.MODULE_COVID19_LOADED, payload: options });
export const displayCovidFilter = options => ({ type: types.MODULE_COVID19_ISFILTER_SHOW, payload: options });
export const displayCovidAcompFilter = options => ({ type: types.MODULE_COVID19_ISFILTER_ACOMP_SHOW, payload: options });
export const displayCovidListEntrega = options => ({ type: types.MODULE_COVID19_LISTENTREGA_SHOW, payload: options });
export const displayAddBenefListEntrega = options => ({ type: types.MODULE_COVID19_ISADDBENEFALISTENTREGA_SHOW, payload: options });


export const setCovidAyudasCostoTotal = options => ({ type: types.MODULE_COVID19_COSTOTOTAL_AYUDAS, payload: options });

export const setCovidListEntrega = listEntrega => ({ 
  type: types.MODULE_COVID19_ENTREGA_LIST_LOADED, 
  payload: listEntrega
});
export const resetCovidListEntrega = listEntrega => ({ 
  type: types.MODULE_COVID19_ENTREGA_LIST_LOADED, 
  payload: listEntrega
});

export const fetchBeneficiariosDataxCovid = token => ({ 
  type: types.MODULE_COVID19_BENEFDATA_LOADED, 
  payload: {
    token: token
  }
});

export const fetchListEntregaDataxCovid = token => ({ 
  type: types.MODULE_COVID19_LISTAENTREGASDATA_LOADED, 
  payload: {
    token: token
  }
});


export const fetchBeneficiariosDataxCovidAcomp = token => ({ 
  type: types.COVID19_ACOMP_BENEFDATA_LOADED, 
  payload: {
    token: token
  }
});

export const fetchBeneficiarioPerfilDataxCovid = datosSelBenef => ({ 
  type: types.MODULE_COVID19_BENEFICIARIO_SELECTED_DATA_REQUEST, 
  payload: datosSelBenef
});

export const updateEditedBeneficiarioDataxCovidAyudas = listBenef => ({ 
  type: types.MODULE_COVID19_BENEFICIARIOS_LIST_LOADED, 
  payload: listBenef
});
export const updateEditedBeneficiarioDataxCovidAcomp = listBenefAcomp => ({ 
  type: types.COVID19_ACOMP_BENEFICIARIOS_LIST_LOADED, 
  payload: listBenefAcomp
});


export const fetchBeneficiarioPerfilDataxCovidAcomp = datosSelBenefAcomp => ({ 
  type: types.COVID19_ACOMP_BENEFICIARIO_SELECTED_DATA_REQUEST, 
  payload: datosSelBenefAcomp
});

export const filterBeneficiariosDataxCovid = filter => ({ 
  type: types.MODULE_COVID19_BENEFICIARIOS_LIST_FILTERED, 
  payload: {
    filter: filter
  }
});

export const filterBeneficiariosDataxCovidAcomp = filter => ({ 
  type: types.COVID19_ACOMP_BENEFICIARIOS_LIST_FILTERED, 
  payload: {
    filterAcomp: filter
  }
});

export const resetfilterBeneficiariosData = () => ({ 
  type: types.MODULE_COVID19_BENEFICIARIOS_LIST_FILTERED, 
  payload: {filter: []}
});
export const resetfilterBeneficiariosDataxCovidAcomp = () => ({ 
  type: types.COVID19_ACOMP_BENEFICIARIOS_LIST_FILTERED, 
  payload: {filterAcomp: []}
});

export const fetchTablasCriteriosxCovid = token => ({ 
  type: types.MODULE_COVID19_TABLASCRITERIOS_LOADED, 
  payload: {
    token: token
  }
});

export const fetchAyudasDataCovid = token => ({ 
  type: types.MODULE_COVID19_AYUDAS_DATA_LOADED, 
  payload: {
    token: token
  }
});

export const resetSelectBeneficiarioForPerfilxCovid = () => ({ 
  type: types.MODULE_COVID19_BENEFICIARIO_PROFILE_SELECTED, 
  payload: {selectedProfile: null}
});
export const resetSelectBeneficiarioForPerfilxCovidAcomp = () => ({ 
  type: types.COVID19_ACOMP_BENEFICIARIO_PROFILE_SELECTED, 
  payload: {selectedProfileAcomp: null}
});

export const selectBeneficiarioForPerfilxCovid = selectedProfile => ({ 
  type: types.MODULE_COVID19_BENEFICIARIO_PROFILE_SELECTED, 
  payload: selectedProfile
});
export const selectBeneficiarioForPerfilxCovidAcomp = selectedProfileAcomp => ({ 
  type: types.COVID19_ACOMP_BENEFICIARIO_PROFILE_SELECTED, 
  payload: selectedProfileAcomp
});

export const updateAyudasCovid = data => ({ 
  type: types.COVID19_SELECTED_AYUDAS_UPDATED, 
  payload: data
});
export const insertAyudasCovid = data => ({ 
  type: types.COVID19_SELECTED_AYUDAS_CREATED, 
  payload: data
});
export const deleteAyudasCovid = data => ({ 
  type: types.COVID19_SELECTED_AYUDAS_DELETED, 
  payload: data
});

// Lista de Entregas
export const updateListaEntregaCovid = data => ({ 
  type: types.COVID19_LISTENTREGA_UPDATED,  payload: data
});
export const insertListaEntregaCovid = data => ({ 
  type: types.COVID19_LISTENTREGA_CREATED,  payload: data
});
export const deleteListaEntregaCovid = data => ({ 
  type: types.COVID19_LISTENTREGA_DELETED,  payload: data
});
export const cleanListaEntregaCovid = token => ({ 
  type: types.COVID19_LISTENTREGA_CLEAN, 
  payload: {
    token: token
  }
});
export const insertBatchListaEntregaCovid = payload => ({ 
  type: types.COVID19_LISTENTREGA_BATCH,  
  payload: payload
});
export const validarListEntrega = payload => ({ 
  type: types.COVID19_LISTENTREGA_VALIDAR,  
  payload: payload
});



// Criterios
export const updateCriteriosPrestaServicio = data => ({ 
  type: types.COVID19_CRITERIOS_PRESTASERVICIO_UPDATED,  payload: data
});
export const insertCriteriosPrestaServicio = data => ({ 
  type: types.COVID19_CRITERIOS_PRESTASERVICIO_CREATED,  payload: data
});
export const deleteCriteriosPrestaServicio = data => ({ 
  type: types.COVID19_CRITERIOS_PRESTASERVICIO_DELETED,  payload: data
});

export const updateCriteriosFamiliaClub = data => ({ 
  type: types.COVID19_CRITERIOS_FAMILIACLUB_UPDATED,  payload: data
});
export const insertCriteriosFamiliaClub = data => ({ 
  type: types.COVID19_CRITERIOS_FAMILIACLUB_CREATED,  payload: data
});
export const deleteCriteriosFamiliaClub = data => ({ 
  type: types.COVID19_CRITERIOS_FAMILIACLUB_DELETED,  payload: data
});

export const updateCriteriosCabezaFamilia = data => ({ 
  type: types.COVID19_CRITERIOS_CABEZAHOGAR_UPDATED,  payload: data
});
export const insertCriteriosCabezaFamilia = data => ({ 
  type: types.COVID19_CRITERIOS_CABEZAHOGAR_CREATED,  payload: data
});
export const deleteCriteriosCabezaFamilia = data => ({ 
  type: types.COVID19_CRITERIOS_CABEZAHOGAR_DELETED,  payload: data
});

export const updateCriteriosPersonasaCargo = data => ({ 
  type: types.COVID19_CRITERIOS_PERSONASACARGO_UPDATED,  payload: data
});
export const insertCriteriosPersonasaCargo = data => ({ 
  type: types.COVID19_CRITERIOS_PERSONASACARGO_CREATED,  payload: data
});
export const deleteCriteriosPersonasaCargo = data => ({ 
  type: types.COVID19_CRITERIOS_PERSONASACARGO_DELETED,  payload: data
});


export const updateCriteriosNumeroHijos = data => ({ 
  type: types.COVID19_CRITERIOS_NUMEROHIJOS_UPDATED,  payload: data
});
export const insertCriteriosNumeroHijos = data => ({ 
  type: types.COVID19_CRITERIOS_NUMEROHIJOS_CREATED,  payload: data
});
export const deleteCriteriosNumeroHijos = data => ({ 
  type: types.COVID19_CRITERIOS_NUMEROHIJOS_DELETED,  payload: data
});

export const updateCriteriosAsistencia = data => ({ 
  type: types.COVID19_CRITERIOS_ASISTENCIA_UPDATED,  payload: data
});
export const insertCriteriosAsistencia = data => ({ 
  type: types.COVID19_CRITERIOS_ASISTENCIA_CREATED,  payload: data
});
export const deleteCriteriosAsistencia = data => ({ 
  type: types.COVID19_CRITERIOS_ASISTENCIA_DELETED,  payload: data
});

export const updateCriteriosActividad = data => ({ 
  type: types.COVID19_CRITERIOS_ACTIVIDAD_UPDATED,  payload: data
});
export const insertCriteriosActividad = data => ({ 
  type: types.COVID19_CRITERIOS_ACTIVIDAD_CREATED,  payload: data
});
export const deleteCriteriosActividad = data => ({ 
  type: types.COVID19_CRITERIOS_ACTIVIDAD_DELETED,  payload: data
});

export const updateCriteriosCategoria = data => ({ 
  type: types.COVID19_CRITERIOS_CATEGORIA_UPDATED,  payload: data
});
export const insertCriteriosCategoria = data => ({ 
  type: types.COVID19_CRITERIOS_CATEGORIA_CREATED,  payload: data
});
export const deleteCriteriosCategoria = data => ({ 
  type: types.COVID19_CRITERIOS_CATEGORIA_DELETED,  payload: data
});

export const updateCriteriosEdad = data => ({ 
  type: types.COVID19_CRITERIOS_EDAD_UPDATED,  payload: data
});
export const insertCriteriosEdad = data => ({ 
  type: types.COVID19_CRITERIOS_EDAD_CREATED,  payload: data
});
export const deleteCriteriosEdad = data => ({ 
  type: types.COVID19_CRITERIOS_EDAD_DELETED,  payload: data
});

export const updateCriteriosEdadClub = data => ({ 
  type: types.COVID19_CRITERIOS_EDADCLUB_UPDATED,  payload: data
});
export const insertCriteriosEdadClub = data => ({ 
  type: types.COVID19_CRITERIOS_EDADCLUB_CREATED,  payload: data
});
export const deleteCriteriosEdadClub = data => ({ 
  type: types.COVID19_CRITERIOS_EDADCLUB_DELETED,  payload: data
});

export const updateCriteriosCupo = data => ({ 
  type: types.COVID19_CRITERIOS_CUPO_UPDATED,  payload: data
});
export const insertCriteriosCupo = data => ({ 
  type: types.COVID19_CRITERIOS_CUPO_CREATED,  payload: data
});
export const deleteCriteriosCupo = data => ({ 
  type: types.COVID19_CRITERIOS_CUPO_DELETED,  payload: data
});


export const serverMensaje = mensaje => ({ 
  type: types.MODULE_COVID19_SERVER_MESSAGE, 
  payload: {
    mensaje: mensaje
  }
});