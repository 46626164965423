// React
import React, {useState} from "react";
// Redux imports
import { useSelector, useDispatch } from "react-redux";
import { setProyecto } from "../../../redux/reducers/proyectos";

import ActionBar, {
  Action,
  ActionGroup,
  // BottomActionGroup
} from 'calcite-react/ActionBar';

// Styled Components
import styled from "styled-components";

const ActionBarLeft = styled.div`
  // float:left;
  // display: inline-flex;
  // position: relative;
  // width: auto;
  // height: 100%;
  // text-align: center;
  // z-index: 2;
  // left: 0px;
  overflow: hidden;
  // background-color: #4c4c4c;
  // color: #fff;
`;

const ItemMenuOptsIcon = styled.div`
  width: 16px;
  height: 16px;
  background: transpararent;
  font-size:0.95rem;
  font-weight:700;
  text-align: center;
`;

const MenuLeftEmprendimientoFormacion = props => {
    // const isCollapsed = useSelector(state => state.proyectos.collapsed);
    const [isCollapsed, setIsCollapsed] = useState(false);
    const activeAction = useSelector(state => state.proyectos.activeProyecto);
    const dispatch = useDispatch();

    const proyectosNames = {
      "":'Caracterización Social y Económica',
      "FORMACION_EMPRENDIMIENTO": "Formación en emprendimiento",
      "CAPACITACION_EMPRENDIMIENTO": "Capacitación",
      "ACOMPANAMIENTO_PUESTA_MARCHA": "Acompañamiento y puesta en marcha",
      "CAPITAL_SEMILLA": "Capital semilla",
      "CIERRE_PROCESO_CLUB_FUNDACION": "Cierre del proceso adelantado en el Club y la Fundación",
      "SEGUIMIENTO": "Seguimiento",
      "CONFIGURACION": "Configuración del GRB", 
    };

    // useEffect(() => {
    //   dispatch(moduleLEFTPANELLoaded({activeModule:activeAction, headerTitle:proyectosNames[activeAction]}));
    // }, [dispatch]);

    const onToggleCollapse = (e) => {
      setIsCollapsed(!isCollapsed);
    };

    const turnOffModules = () => {
      // dispatch(moduleUSERSLoaded({loaded:false})); // Hide Users
      // dispatch(moduleBENEFICIARIOSLoaded({loaded:false}));
    }

    const actionSelected = (activeAction) => {
      dispatch(setProyecto({activeProyecto:activeAction, collapsed:isCollapsed, headerTitle:proyectosNames[activeAction]}));
      turnOffModules();
      switch (activeAction) {
        case 'FORMACION_EMPRENDIMIENTO':
          console.log('FORMACION_EMPRENDIMIENTO');
          break;
        case 'CAPACITACION_EMPRENDIMIENTO':
          console.log('CAPACITACION_EMPRENDIMIENTO');
          break;
        case 'ACOMPANAMIENTO_PUESTA_MARCHA':
          console.log('ACOMPANAMIENTO_PUESTA_MARCHA');
          break;
        case 'CAPITAL_SEMILLA':
          console.log('CAPITAL_SEMILLA');
          break;
        case 'CIERRE_PROCESO_CLUB_FUNDACION':
          console.log('CIERRE_PROCESO_CLUB_FUNDACION');
          break;
        case 'SEGUIMIENTO':
          console.log('SEGUIMIENTO');
          break;
        default:
          break;
      }
    };

    return (
      <ActionBarLeft>
      <ActionBar 
        // showCollapser={false}
        collapseLabel='Ocultar' 
        expandLabel='Expandir' 
        collapsed={isCollapsed}
        onToggleCollapse={onToggleCollapse}
      >
        <ActionGroup>
          <Action 
              onClick={() => actionSelected('CAPACITACION_EMPRENDIMIENTO')}
              active={activeAction === 'CAPACITACION_EMPRENDIMIENTO'}
              icon={<ItemMenuOptsIcon>C</ItemMenuOptsIcon>}
            >
              Capacitación
          </Action>
          <Action
            onClick={() => actionSelected('ACOMPANAMIENTO_PUESTA_MARCHA')}
            active={activeAction === 'ACOMPANAMIENTO_PUESTA_MARCHA'}
            icon={<ItemMenuOptsIcon>AP</ItemMenuOptsIcon>}
          >
            Acompañamiento y puesta en marcha
          </Action>
          <Action
            onClick={() => actionSelected('CAPITAL_SEMILLA')}
            active={activeAction === 'CAPITAL_SEMILLA'}
            icon={<ItemMenuOptsIcon>CS</ItemMenuOptsIcon>}
          >
            Capital semilla
          </Action>
          <Action
            onClick={() => actionSelected('CIERRE_PROCESO_CLUB_FUNDACION')}
            active={activeAction === 'CIERRE_PROCESO_CLUB_FUNDACION'}
            icon={<ItemMenuOptsIcon>CP</ItemMenuOptsIcon>}
            >Cierre del proceso adelantado en el Club y la Fundación
          </Action>
          <Action
            onClick={() => actionSelected('SEGUIMIENTO')}
            active={activeAction === 'SEGUIMIENTO'}
            icon={<ItemMenuOptsIcon>S</ItemMenuOptsIcon>}
          >
            Seguimiento
          </Action>
        </ActionGroup>
        {/* <BottomActionGroup>
        <Action icon={<GearIcon />}>Configuración</Action>
      </BottomActionGroup> */}
      </ActionBar>
      </ActionBarLeft>
    )

}

export default MenuLeftEmprendimientoFormacion;