// Copyright 2019 Ernesto Giron E.
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//     http://www.apache.org/licenses/LICENSE-2.0
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.​

// Lineas de Acción o Programas
// FORMACION - VIVIENDA - BIENESTAR

// ACTION TYPES //
export const types = {
  PROGRAMA_LOADED: "PROGRAMA_LOADED"
};

// REDUCERS //
export const initialState = {
  display: false,
  activeProgram: '',
  headerTitle:''
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.PROGRAMA_LOADED:
      const { display, activeProgram, headerTitle } = action.payload;
      return {
        ...state,
        display,
        activeProgram,
        headerTitle
      };
    default:
      return state;
  }
};

// ACTIONS //
export const setPrograma = options => ({ type: types.PROGRAMA_LOADED, payload: options });
