// React
import React, {useState} from "react";

// Redux imports
import { useSelector, useDispatch } from "react-redux";
import { setModulo } from "../../redux/reducers/modulos";
// Styled Components
import styled from "styled-components";
import GearIcon from 'calcite-ui-icons-react/GearIcon';
import vinculacion_Icon from '../../assets/icons/vinculacion_Icon.png';
import emprendimiento_Icon from '../../assets/icons/emprendimiento5_icon.png';
import empleabilidad_Icon from '../../assets/icons/empleabilidad1_Icon.png';
import formacionvidaIcon from '../../assets/icons/formacion_icon.png';

import ActionBar, {
  Action,
  ActionGroup,
  BottomActionGroup
} from 'calcite-react/ActionBar';


const VinculacionIcon = styled.div`
  width: 24px;
  height: 24px;
  background: rgba(255, 255, 255, 0.1) url(${vinculacion_Icon}) no-repeat center/cover;
`;
const EmprendimientoIcon = styled.div`
  width: 24px;
  height: 24px;
  background: rgba(255, 255, 255, 0.1) url(${emprendimiento_Icon}) no-repeat center/cover;
`; 
const EmpleabilidadIcon = styled.div`
  width: 24px;
  height: 24px;
  background: rgba(255, 255, 255, 0.1) url(${empleabilidad_Icon}) no-repeat center/cover;
`;
const FormacionvidaIcon = styled.div`
  width: 24px;
  height: 24px;
  background: rgba(255, 255, 255, 0.1) url(${formacionvidaIcon}) no-repeat center/cover;
`;

const PanelLeftFormacion = props => {
    // const isUsersLoaded = useSelector(state => state.users.loaded);
    // const isCollapsed = useSelector(state => state.modulos.collapsed);
    const [isCollapsed, setIsCollapsed] = useState(false);
    const activeAction = useSelector(state => state.modulos.activeModule);
    const dispatch = useDispatch();

    const proyectosNames = {
      "":'Formación',
      "VINCULACION": "Vinculación y Permanencia educativa",
      "EMPRENDIMIENTO": "Emprendimiento Empresarial",
      "EMPLEABILIDAD": "Empleabilidad",
      "FORMACIONHABVIDA":"Formación en habilidades para la vida",
      "CONFIGURACION": "Configuración del GRB", 
    };

    const onToggleCollapse = (e) => {
      // dispatch(setModulo({collapsed:!isCollapsed}));
      setIsCollapsed(!isCollapsed);
    };

    const turnOffModules = () => {
      // dispatch(moduleUSERSLoaded({loaded:false})); // Hide Users
      // dispatch(moduleBENEFICIARIOSLoaded({loaded:false}));
    }

    const actionSelected = (activeAction) => {
      dispatch(setModulo({activeModule:activeAction, collapsed:isCollapsed, headerTitle:proyectosNames[activeAction]}));
      turnOffModules();
      switch (activeAction) {
        case 'VINCULACION':
          console.log('VINCULACION');
          // dispatch(moduleBENEFICIARIOSLoaded({loaded:true}));
          break;
        case 'EMPRENDIMIENTO':
          console.log('EMPRENDIMIENTO');
          break;
        case 'EMPLEABILIDAD':
          console.log('EMPLEABILIDAD');
          break;
        case 'FORMACIONHABVIDA':
          console.log('FORMACIONHABVIDA');
          // dispatch(moduleBENEFICIARIOSLoaded({loaded:true}));
          break;
        default:
          break;
      }
    };

    return (
      <ActionBar
        collapseLabel='Ocultar' 
        expandLabel='Expandir' 
        collapsed={isCollapsed}
        onToggleCollapse={onToggleCollapse}
      >
        <ActionGroup>
        <Action
          onClick={() => actionSelected('VINCULACION')}
          active={activeAction === 'VINCULACION'}
          icon={<VinculacionIcon/>}
        >
          Vinculación
        </Action>
          <Action
            onClick={() => actionSelected('EMPRENDIMIENTO')}
            active={activeAction === 'EMPRENDIMIENTO'}
            icon={<EmprendimientoIcon />}
          >
            Emprendimiento
          </Action>
          <Action
            onClick={() => actionSelected('EMPLEABILIDAD')}
            active={activeAction === 'EMPLEABILIDAD'}
            icon={<EmpleabilidadIcon />}
          >
            Empleabilidad
          </Action>
          <Action 
            onClick={() => actionSelected('FORMACIONHABVIDA')}
            active={activeAction === 'FORMACIONHABVIDA'}
            icon={<FormacionvidaIcon/>}
          >
            Formación Vida
          </Action>
        </ActionGroup>
        <BottomActionGroup>
          <Action icon={<GearIcon />}>Configuración</Action>
        </BottomActionGroup>
      </ActionBar>
    )

}

export default PanelLeftFormacion;