// React
import React, { Fragment,useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { 
    // moduleCumplimientoBenefLoaded,
    displayCumplimientoFilter,
    fetchBenefGolfData,
    resetFilterBeneficiariosDataxCumpli,
    resetSelectBeneficiarioForPerfilxCumpli
} from '../../redux/reducers/cumpli';

// load lazy data
import DisplayListBeneficiarios from './displayListBeneficiarios';
import BenefDetailsContainer from './BeneficiarioDetails';

import ListButtonIcon from 'calcite-ui-icons-react/ListButtonIcon';
import FilterIcon from 'calcite-ui-icons-react/FilterIcon';
import Loader from 'calcite-react/Loader';
import Search from 'calcite-react/Search';

import Tabs, {
    TabNav,
    TabTitle,
    TabContents,
    TabSection
} from 'calcite-react/Tabs';

// Styled & Motion Components
import styled from "styled-components";

const SearchBeneficiario = styled.div`
    position: relative;
    float: right;
    // margin-right: 51px;
    top: -4px;
    height:1.9rem;

    /* Extra large devices (large laptops and desktops, 1200px and up) */
    @media only screen and (max-width: 1200px) {
        
    } 

    /* Large devices (laptops/desktops, 992px and up) */
    @media only screen and (max-width: 992px) {
        
    }

    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (max-width: 768px) {
        max-width: 150px;
    }

    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (max-width: 600px) {
        max-width: 150px;
    }

    /* Extra small devices (phones, 600px and down) */
    @media only screen and (max-width: 600px) {
        max-width: 150px;
    }

`;

const BeneficiarioDetalleContainer = styled.div`
    z-index:100;
    display: flex;
    position: absolute;
    left: 0px;
    top: 0px;
    width:100%;
    height:100%;
    padding:0px;
    margin:0px;
    background-color: #ffffffa3;
    //padding-right: 50px;
`;

const FiltroButton = styled.div`
    display: none; // Temporalmente deshabilitado
    // float: left;
    position: relative;
    margin-bottom: 2px;
    top: 0px;
    background: #fff;
    width: 41px;
    height: 40px;
    border: 1px solid #ccc;
    cursor: pointer;

    &:hover{
        background: #f8f8f8;
    }

    /* Extra large devices (large laptops and desktops, 1200px and up) */
    @media only screen and (max-width: 1200px) {
        
    } 

    /* Large devices (laptops/desktops, 992px and up) */
    @media only screen and (max-width: 992px) {
        
    }

    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (max-width: 768px) {
        
    }

    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (max-width: 600px) {
        max-width: 150px;
    }

    /* Extra small devices (phones, 400px and down) */
    @media only screen and (max-width: 400px) {
        
    }

`;
const FiltroIcon = <FilterIcon style={{ width: '20px', height: '20px', marginTop: '10px', color:'green' }} />

const ModuleBeneficiarios = props => {
    const users = useSelector(state => state.users);
    const listGolf = useSelector(state => state.cumpli.listGolf);
    // const listBenef = useSelector(state => state.cumpli.listBenef);
    const selectedProfile = useSelector(state => state.cumpli.selectedProfile);
    const filter = useSelector(state => state.cumpli.filter);
    const isFilterShow = useSelector(state => state.cumpli.isFilterShow);
    const [filtroBeneficiario,setFiltroBeneficiario] = useState([]);
    const [isFiltered,setIsFiltered] = useState(false);
    const dispatch = useDispatch();
    const [activeTabIndex,setTabIndex] = useState(0);
    
    const [inputValue,setInputValue] = useState('');
    const [selectedItem,setSelectedItem] = useState('');
    const [searchItems,setSearchItems] = useState([]);
    
    const onTabChange = (index) => {
        setTabIndex(index);
    }

    useEffect(() => {
        if (listGolf===null || listGolf.length<=0){
            dispatch(fetchBenefGolfData({token: users.user.claveAPI}));
            return
        }

        return () => {
            // executed when unmount
            // console.log('Saliendo del Modulo Beneficiarios');
            setIsFiltered(false);
            setFiltroBeneficiario([]);
            dispatch(displayCumplimientoFilter({isFilterShow:false}));
            dispatch(resetFilterBeneficiariosDataxCumpli());
            dispatch(resetSelectBeneficiarioForPerfilxCumpli());
        }
        
    }, [users.user.claveAPI, listGolf, dispatch]);

    useEffect(() => {
        if (listGolf===null || listGolf.length<=0){
            return
        }
        let datos = [];
        if (filter!==null && filter.length>0){
            setIsFiltered(true);
            datos = [...filter];
            setFiltroBeneficiario(filter);
        } else {
            // Filtrar Beneficiario por los que solo pueden prestar el servicio
            if (listGolf) datos = [...listGolf].filter(b => b.prestarservicio==='1');
            if (!isFiltered)
                setFiltroBeneficiario(datos);
        }
        if (datos.length>0){
            let cantidad = datos.length;
            let items_0 = datos.map((b, idx) => {
                return (
                {
                    "id": idx + "-" + b.docId,
                    "label": b.apellidos.trim() +' '+ b.nombres.trim()
                }
                );
            })
            let items_1 = datos.map((b, idx) => {
                return (
                {
                    "id": (cantidad+idx+1) + "-" + b.docId,
                    "label": b.docId.trim(),
                }
                );
            })
            let items_2 = datos.map((b, idx) => {
                return (
                    {
                        "id": (cantidad*2+idx+1) + "-" + b.docId,
                        "label": b.alias.trim()
                    }
                );
            })
            items_0 = items_0.filter(itm => (itm.id!==undefined || itm.label!==undefined));
            items_1 = items_1.filter(itm => (itm.id!==undefined || itm.label!==undefined));
            items_2 = items_2.filter(itm => (itm.id!==undefined || itm.label!==undefined));
            const items = [...items_0.concat(items_1, items_2)]; //items_0.filter(itm => itm!==undefined);
            setSearchItems(items);
        }
        
        
    }, [listGolf, filter, isFiltered]);

    // Search 
    const searchChanged = (e) => {
        setSelectedItem(e)
    }
    const clearSearch = () => {
        setInputValue('');
        setSelectedItem('');
        setIsFiltered(false);
        setFiltroBeneficiario([...listGolf].filter(b => b.prestarservicio==='1')); //listGolf
    }

    const getBeneficiarioByDocId = (docId) => {
        if (docId && docId!==null && docId!==''){
            setIsFiltered(true);
            setFiltroBeneficiario(listGolf.filter(b => b.docId === docId));
        } 
    };
    
    const onUserAction = (inputValue, selectedItemVal) => {
        setInputValue(inputValue);
        setSelectedItem(selectedItemVal);
        if (selectedItemVal && selectedItemVal.id){
            const doc = selectedItemVal['id'].split('-')[1];
            getBeneficiarioByDocId(doc)
        }
    }

    const onClickToggleFilter = () => {
        dispatch(displayCumplimientoFilter({isFilterShow:!isFilterShow}));
    }
    
    // if (listGolf && listGolf.length>0)
    if (filtroBeneficiario)
    return (
        <Fragment>
            {filtroBeneficiario.length<0 && <Loader color="#009937" text="Cargando Beneficiarios..."/>}
            
            { selectedProfile && 
                    <BeneficiarioDetalleContainer>
                        <BenefDetailsContainer />
                    </BeneficiarioDetalleContainer>
            }

            <FiltroButton onClick={onClickToggleFilter}>
                {FiltroIcon}
            </FiltroButton>

            <SearchBeneficiario>
                <Search
                    virtualized
                    virtualizedMenuWidth={250}
                    placeholder='Buscar...' 
                    inputValue={inputValue}
                    selectedItem={selectedItem}
                    items={searchItems}
                    dataSourceConfig={{ label: 'label', value: 'id' }}
                    minimal
                    appendToBody
                    onChange={searchChanged}
                    onUserAction={onUserAction}
                    onRequestClear={clearSearch}
                />
            </SearchBeneficiario>
            
            <Tabs
                onTabChange={onTabChange}
                activeTabIndex={activeTabIndex}
            >
                <TabNav>
                <TabTitle><ListButtonIcon size={16} color={'green'} /></TabTitle>
                </TabNav>
                <TabContents>
                    <TabSection>
                        { filtroBeneficiario.length>0 && 
                            <DisplayListBeneficiarios isFiltered={isFiltered} listBenef={filtroBeneficiario} />
                        }
                    </TabSection>
                </TabContents>
            </Tabs>

        </Fragment>
    );
    return (<></>)
}
  
export default ModuleBeneficiarios;