// React
import React, {useState} from "react";
// Redux imports
import { useSelector, useDispatch } from "react-redux";
import { setPrograma } from "../redux/reducers/programas";
import { displayCovidFilter } from '../redux/reducers/covid19';

// Styled & Motion Components
import styled from "styled-components";
import greenBlueBg from '../assets/bgImage.png';

import SubNav, {
  SubNavTitle,
  SubNavList,
  SubNavLink
} from 'calcite-react/SubNav'; //SubNavActions

import HomeIcon from 'calcite-ui-icons-react/HomeIcon';


const SubNavTitleExt = styled(SubNavTitle)`
  color: #fff;

  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (max-width: 1200px) {
    // color: #fff;
    // font-size: 1em;
    // line-height: 1.25;
    // font-weight: 500;
  } 

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (max-width: 992px) {
    // color: #fff;
    // font-size: 1em;
    // line-height: 1.25;
    // font-weight: 500;
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (max-width: 768px) {
    // font-size: 1em;
    line-height: 1.25;
    // color: #fff;
    font-weight: 500;
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 600px) {
    font-size: 1em;
    line-height: 1.25;
    // color: #fff;
    font-weight: 500;
  }

  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 600px) {
    font-size: 1em;
    line-height: 1.25;
    // color: #fff;
    font-weight: 500;
  }

`;

const SubNavLinkExt = styled(SubNavLink)`

/* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (max-width: 768px) {
    font-size: 14px;
  }
`;

const SubNavGRB = props => {
  const dispatch = useDispatch();
  const headerTitle = useSelector(state => state.modulos.headerTitle);
  const [aHome, setHome] = useState(true);
  const [aFormacion, setFormacion] = useState(false);
  const [aVivienda, setVivienda] = useState(false);
  const [aBienestar, setBienestar] = useState(false);

  const turnOffSelects = () => {
    setHome(false);
    setFormacion(false);
    setVivienda(false);
    setBienestar(false);
    // Quitar o Ocultar Filtros
    dispatch(displayCovidFilter({isFilterShow:false}));
  };

  const onLoadProgram = (e) => {
    turnOffSelects();
    const aProgram = e.target.innerText || '';
    dispatch(setPrograma({display: true, activeProgram: aProgram, headerTitle:aProgram}));
    switch (e.target.innerText) {
      case 'FORMACIÓN':
        setFormacion(true);
        break;
      case 'VIVIENDA':
        setVivienda(true);
        break;
      case 'BIENESTAR':
        setBienestar(true);
        break;
      default:
        setHome(true);
        break;
    }
  }
  return (
    <>
        <SubNav id='subnav-fundacion'
          backgroundImage={greenBlueBg} gradientFromColor="#70be49" gradientToColor="#1688aa"
          overlayGradient={true} 
        >
          <SubNavTitleExt>{headerTitle}</SubNavTitleExt>
          <SubNavList>
          <SubNavLink active={aHome || false} onClick={onLoadProgram}><HomeIcon/></SubNavLink>
            <SubNavLinkExt active={aFormacion || false} onClick={onLoadProgram}>FORMACIÓN</SubNavLinkExt>
            <SubNavLinkExt active={aVivienda || false} onClick={onLoadProgram}>VIVIENDA</SubNavLinkExt>
            <SubNavLinkExt active={aBienestar || false} onClick={onLoadProgram}>BIENESTAR</SubNavLinkExt>
          </SubNavList>
        </SubNav>
    </>
  )
}

export default SubNavGRB;