// React
import React, {useState } from "react"; //useRef
import { useSelector, useDispatch } from "react-redux";
// import { CSVLink } from "react-csv";
import * as XLSX from 'xlsx';
import { displayCovidListEntrega, displayAddBenefListEntrega,
  cleanListaEntregaCovid, resetCovidListEntrega,
  insertBatchListaEntregaCovid, validarListEntrega
} from "../../../redux/reducers/covid19";

// Styled Components
import styled from "styled-components";
// import GearIcon from 'calcite-ui-icons-react/GearIcon';
import PlusIcon from 'calcite-ui-icons-react/PlusIcon';
import SaveIcon from 'calcite-ui-icons-react/SaveIcon';
import TrashIcon from 'calcite-ui-icons-react/TrashIcon';
import CheckIcon from 'calcite-ui-icons-react/DataCheckIcon';

import ActionBar, {
  Action,
  ActionGroup,
  // BottomActionGroup
} from 'calcite-react/ActionBar';


// import exportarCSVIcon from '../../../assets/icons/csv_icon.png';
import exportarExcelIcon from '../../../assets/icons/excel_icon_black.png';
import tablaEntregaIcon from '../../../assets/icons/tabla_entrega_icon.png';


// const ActionBarRightContainer = styled.div`
//   // float:left;
//   // display: inline-flex;
//   // position: relative;
//   // width: auto;
//   // height: 100%;
//   // text-align: center;
//   // z-index: 2;
//   // left: 0px;
//   overflow: auto;
//   // background-color: #4c4c4c;
//   // color: #fff;
// `;

// const ExtendedActionBar = styled(ActionBar)`
//   overflow: auto;
// `;


const ExportarExcelIcon = styled.div`
  width: 20px;
  height: 20px;
  background: rgba(255, 255, 255, 0.1) url(${exportarExcelIcon}) no-repeat center/cover;
`;
// const ExportarCSVIcon = styled.div`
//   width: 20px;
//   height: 20px;
//   background: rgba(255, 255, 255, 0.1) url(${exportarCSVIcon}) no-repeat center/cover;
// `;
const TablaEntregaIcon = styled.div`
  width: 20px;
  height: 20px;
  background: rgba(255, 255, 255, 0.1) url(${tablaEntregaIcon}) no-repeat center/cover;
`;

const ActionBarRight = props => {
    const users = useSelector(state => state.users);
    const listEntrega = useSelector(state => state.covid19.listEntrega);
    // const isListEntrega = useSelector(state => state.covid19.isListEntrega);
    const [isCollapsed, setIsCollapsed] = useState(true);
    const [activeAction, setActiveAction] = useState('');
    const [lastactiveAction, setlastActiveAction] = useState('');
    // const activeAction = useSelector(state => state.modulos.activeModule);
    // let csvlinkbtn = useRef();
    const dispatch = useDispatch();
    
    // const modulosNames = {
    //   "":"Bienestar",
    //   "ADD_BENEFICIARIO":"Agregar Beneficiario",
    //   "SAVE_ENTREGA":"Guardar Entrega",
    //   "DELETE_ENTREGA": "Eliminar Entrega",
    //   "DEPORTERECRECULTURA":"Deporte, recreación y cultura",
    //   "VESTUARIO":"Vestuario",
    //   "ALIMENTACION": "Alimentación",
    //   "SEGURIDADSOCIAL":"Seguridad Social",
    //   "RECONOCIMIENTOS":"Reconocimientos",
    //   "COVID19":"Covid-19",
    //   "CONFIGURACION": "Configuración del GRB", 
    // };

    // Para CSVLink
    // const headerstoExcel = [
    //   { label: "SERIAL", key: "serial" },
    //   { label: "N° CONVENIO", key: "convenio" },
    //   { label: "NIT", key: "nit" },
    //   { label: "TIPO DE IDENTIFICACIÓN", key: "tipodocId" },
    //   { label: "NÚMERO DE IDENTIFICACION", key: "docId" },
    //   { label: "NOMBRE", key: "nombre" },
    //   { label: "A", key: "a" },
    //   { label: "OTROS", key: "otros" },
    //   { label: "FECHA INICIAL", key: "fechainicio" },
    //   { label: "FECHA DE VIGENCIA", key: "fechavigencia" },
    //   { label: "FECHA DE CADUCIDAD", key: "fechacaducidad" },
    //   { label: "CUPO", key: "cupo" },
    // ];
    let newListaEntregaparaEnviar = [];
    if (listEntrega && listEntrega.length>0){
      try {
          // Definir solo los campos a exportar
          listEntrega.forEach((itm, idx) => {
            const serial = itm.numentrega + itm.fechainicio.split(' ')[0].replace(/-/g,'') + (idx + 1); //itm.serial; //
            const convenio = itm.convenio;
            const nit = '';
            const tipodocId = 'CC';
            const docId = itm.docIdquienreclama;
            const nombre = itm.nombrequienreclama.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase(); // remover acentos
            const a = 'A';
            const otros = 'FUNDACION CLUB FARALLONES';
            const fechainicio = itm.fechainicio;
            const fechavigencia = itm.fechavigencia;
            const fechacaducidad = itm.fechacaducidad;
            const cupo = itm.cupo;
            const valorfinal = itm.valorfinal;

            // Corregir algunos campos para exportar o Validar la lista para guardar en la lista de Entregas
            // Solución temporal para avitar crear mas campos en la BD, y para corregir los Beneficiarios nuevos en entregas
            // que no tengan todos los datos diligenciados correctamente
            itm.serial = serial; // Corregido para evitar error al validar o exportar a la BD
            itm.afectacion = itm.afectacionfinal2;  // Se coloca la afectación creada con los 10 criterios


            // Para CSVLink
            // newListaEntregaparaEnviar.push({
            //   "serial": serial,
            //   "convenio": convenio,
            //   "nit": nit,
            //   "tipodocId": tipodocId,
            //   "docId": docId,
            //   "nombre": nombre,
            //   "a": a,
            //   "otros": otros,
            //   "fechainicio": fechainicio,
            //   "fechavigencia": fechavigencia,
            //   "fechacaducidad": fechacaducidad,
            //   "cupo":cupo
            // });
            newListaEntregaparaEnviar.push({
              "SERIAL": serial,
              "N° CONVENIO": convenio,
              "NIT": nit,
              "TIPO DE IDENTIFICACIÓN": tipodocId,
              "NÚMERO DE IDENTIFICACION": docId,
              "NOMBRE": nombre,
              "A": a,
              "OTROS": otros,
              "FECHA INICIAL": fechainicio,
              "FECHA DE VIGENCIA": fechavigencia,
              "FECHA DE CADUCIDAD": fechacaducidad,
              "CUPO":valorfinal,
              "CUPO ANTERIOR":cupo
            });
            
          });
        } catch (e) {
            console.error(e);
        }
    }
    
    const onToggleCollapse = (e) => {
      // dispatch(setModulo({collapsed:!isCollapsed, headerTitle:modulosNames[activeAction]}));
      setIsCollapsed(!isCollapsed);
    };

    const turnOffModules = () => {
      // dispatch(moduleUSERSLoaded({loaded:false})); // Hide Users
      // dispatch(moduleBENEFICIARIOSLoaded({loaded:false}));
    }

    const actionSelected = (activeAction) => {
      // dispatch(setModulo({activeModule:activeAction, collapsed:isCollapsed, headerTitle:modulosNames[activeAction]}));
      turnOffModules();
      switch (activeAction) {
        case 'ADD_BENEFICIARIO':
          // console.log('ADD_BENEFICIARIO');
          dispatch(displayAddBenefListEntrega({isAddBenefaListEntrega:true}));
          break;
        case 'SAVE_ENTREGA':
          // console.log('SAVE_ENTREGA');
          if (listEntrega && listEntrega.length>0){
            dispatch(insertBatchListaEntregaCovid({"token": users.user.claveAPI, "listaentregas":listEntrega}));
          }
          break;
        case 'DELETE_ENTREGA':
          // console.log('DELETE_ENTREGA');
          if (listEntrega && listEntrega.length>0){
            dispatch(cleanListaEntregaCovid({"token": users.user.claveAPI}));
            dispatch(resetCovidListEntrega({"token": users.user.claveAPI, "listaentregas":[]}));
          }
          
          break;
        case 'CREAR_ENTREGA':
          if (lastactiveAction==='CREAR_ENTREGA') {
            activeAction = '';
            dispatch(displayCovidListEntrega({isListEntrega:false}));
          } else {
            dispatch(displayCovidListEntrega({isListEntrega:true}));
          }
          break;
        case 'EXPORTAR_ENTREGA':
          // console.log('EXPORTAR_ENTREGA');
          // click the CSVLink component to trigger the CSV download
          // csvlinkbtn.link.click();

          // Exportar en formato Excel .xlsx
          var ws = XLSX.utils.json_to_sheet(newListaEntregaparaEnviar); //make the worksheet
          var wb = XLSX.utils.book_new(); //add to workbook
          XLSX.utils.book_append_sheet(wb, ws, "Reservas");
          /* generate an XLSX file */
          // "Reservas_"+moment(fechaReserva).format("YYYY-MM-DD")+".xlsx"
          const fname = "ListaEntrega-"+ new Date().toLocaleString().split(',')[0].replace(',','').replace(/\//g,'-') +".xlsx";
          XLSX.writeFile(wb, fname);
          
          break;
        case 'VALIDAR_ENTREGA':
          // console.log('VALIDAR_ENTREGA');
          if (listEntrega && listEntrega.length>0){
            dispatch(validarListEntrega({"token": users.user.claveAPI, "listaentregas":listEntrega}));
          }
          break;
        default:
          activeAction = '';
          break;
      }
      setActiveAction(activeAction);
      setlastActiveAction(activeAction);
    };

    return (
      // <ActionBarRightContainer>
        <ActionBar position='end' 
          collapseLabel='Ocultar' 
          expandLabel='Expandir' 
          collapsed={isCollapsed}
          onToggleCollapse={onToggleCollapse}
        >
        <ActionGroup>
        <Action 
          onClick={() => actionSelected('ADD_BENEFICIARIO')}
          active={activeAction === 'ADD_BENEFICIARIO'}
          disabled={(listEntrega && listEntrega.length>=0) ? false : true}
          icon={<PlusIcon/>}
        >
          Agregar Beneficiario
        </Action>
          <Action
            onClick={() => actionSelected('SAVE_ENTREGA')}
            active={activeAction === 'SAVE_ENTREGA'}
            disabled={(listEntrega && listEntrega.length>0) ? false : true}
            icon={<SaveIcon />}
          >
            Guardar Lista Entrega
          </Action>
        <Action
          onClick={() => actionSelected('DELETE_ENTREGA')}
          active={activeAction === 'DELETE_ENTREGA'}
          disabled={(listEntrega && listEntrega.length>0) ? false : true}
          icon={<TrashIcon />}
        >
          Borrar Lista Entrega
        </Action>
        </ActionGroup>
        <ActionGroup>
          <Action 
            onClick={() => actionSelected('CREAR_ENTREGA')}
            active={activeAction === 'CREAR_ENTREGA'}
            icon={<TablaEntregaIcon />}
          >
            Crear Lista Entrega
          </Action>
          <Action 
            onClick={() => actionSelected('EXPORTAR_ENTREGA')}
            active={activeAction === 'EXPORTAR_ENTREGA'}
            disabled={newListaEntregaparaEnviar.length>0 ? false : true}
            icon={<ExportarExcelIcon />}>
            Exportar Lista Entrega
            {/* <CSVLink 
              data={newListaEntregaparaEnviar}
              headers={headerstoExcel}
              filename={"listaEntrega-"+ new Date().toLocaleString().split(',')[0].replace(',','').replace(/\//g,'-') +".csv"}
              // target="_blank"
              ref={(r) => csvlinkbtn = r}
            /> */}
          </Action>
          <Action
            onClick={() => actionSelected('VALIDAR_ENTREGA')}
            active={activeAction === 'VALIDAR_ENTREGA'}
            disabled={(listEntrega && listEntrega.length>0) ? false : true}
            icon={<CheckIcon size={32} />}
          >
            Validar Entrega Final
          </Action>
        </ActionGroup>

        {/* <BottomActionGroup>
          <Action icon={<GearIcon />}>Configuración</Action>
        </BottomActionGroup> */}
      </ActionBar>
      // </ActionBarRightContainer>
    )

}

export default ActionBarRight;