// Copyright 2020 Ernesto Giron E.
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//     http://www.apache.org/licenses/LICENSE-2.0
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.​

// ACTION TYPES //
export const types = {
  MODULE_VESTUARIO_LOADED: "MODULE_VESTUARIO_LOADED",
  MODULE_VESTUARIO_BENEFICIARIO_PROFILE_SELECTED: "MODULE_VESTUARIO_BENEFICIARIO_PROFILE_SELECTED",
  MODULE_VESTUARIO_ISFILTER_TALLAJE_SHOW: "MODULE_VESTUARIO_ISFILTER_TALLAJE_SHOW",

  VESTUARIO_TALLAJE_BENEFDATA_LOADED: "VESTUARIO_TALLAJE_BENEFDATA_LOADED",
  VESTUARIO_TALLAJE_BENEFICIARIOS_LIST_LOADED: "VESTUARIO_TALLAJE_BENEFICIARIOS_LIST_LOADED",
  VESTUARIO_TALLAJE_BENEFICIARIO_DATA_SELECTED: "VESTUARIO_TALLAJE_BENEFICIARIO_DATA_SELECTED",
  VESTUARIO_TALLAJE_BENEFICIARIO_PROFILE_SELECTED: "VESTUARIO_TALLAJE_BENEFICIARIO_PROFILE_SELECTED",
  VESTUARIO_TALLAJE_BENEFICIARIOS_LIST_FILTERED: "VESTUARIO_TALLAJE_BENEFICIARIOS_LIST_FILTERED",
  VESTUARIO_TALLAJE_BENEFICIARIO_SELECTED_DATA_REQUEST: "VESTUARIO_TALLAJE_BENEFICIARIO_SELECTED_DATA_REQUEST",

  VESTUARIO_TALLAJE_UPDATED: "VESTUARIO_TALLAJE_UPDATED",
  VESTUARIO_TALLAJE_CREATED: "VESTUARIO_TALLAJE_CREATED",
  VESTUARIO_TALLAJE_DELETED: "VESTUARIO_TALLAJE_DELETED",
  // VESTUARIO_TALLAJE_CLEAN: "VESTUARIO_TALLAJE_CLEAN",

  MODULE_VESTUARIO_SERVER_MESSAGE: "MODULE_VESTUARIO_SERVER_MESSAGE",
};

// REDUCERS //
export const initialState = {
  loaded: false,
  isFilterTallajeShow: false,
  listBenefTallaje: [],
  selectedProfileTallaje: null,
  datosSelBenefTallaje: [],
  filterTallaje: [],
  mensaje: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.MODULE_VESTUARIO_LOADED:
      const { loaded } = action.payload;
      return {
        ...state,
        loaded
      };
    case types.VESTUARIO_TALLAJE_BENEFICIARIOS_LIST_LOADED:
      const { listBenefTallaje } = action.payload;
      return {
        ...state,
        listBenefTallaje
      };
    case types.VESTUARIO_TALLAJE_BENEFICIARIO_DATA_SELECTED:
      const { datosSelBenefTallaje, selectedProfileTallaje } = action.payload;
      return {
        ...state,
        datosSelBenefTallaje,
        selectedProfileTallaje
      };
    case types.MODULE_VESTUARIO_BENEFICIARIO_PROFILE_SELECTED:
      return {
        ...state,
        selectedProfile:action.payload.selectedProfile
      };
    case types.VESTUARIO_TALLAJE_BENEFICIARIO_PROFILE_SELECTED:
      return {
        ...state,
        selectedProfileTallaje:action.payload.selectedProfileTallaje
      };
    case types.VESTUARIO_TALLAJE_BENEFICIARIOS_LIST_FILTERED:
      const { filterTallaje } = action.payload;
      return {
        ...state,
        filterTallaje
      };
    case types.MODULE_VESTUARIO_ISFILTER_TALLAJE_SHOW:
      const { isFilterTallajeShow } = action.payload;
      return {
        ...state,
        isFilterTallajeShow
      };
    case types.MODULE_VESTUARIO_SERVER_MESSAGE:
      const {mensaje} = action.payload;
      return {
        ...state,
        mensaje
      };
    default:
      return state;
  }
};

// ACTIONS //
// export const moduleVESTUARIOLoaded = options => ({ type: types.MODULE_VESTUARIO_LOADED, payload: options });
// export const displayVesturaioFilter = options => ({ type: types.MODULE_VESTUARIO_ISFILTER_SHOW, payload: options });
export const displayVestuarioTallajeFilter = options => ({ type: types.MODULE_VESTUARIO_ISFILTER_TALLAJE_SHOW, payload: options });

export const fetchBeneficiariosDataxVestuarioTallaje = token => ({ 
  type: types.VESTUARIO_TALLAJE_BENEFDATA_LOADED, 
  payload: {
    token: token
  }
});
export const resetDataVestuarioTallaje = listBenefTallaje => ({ 
  type: types.VESTUARIO_TALLAJE_BENEFICIARIOS_LIST_LOADED, 
  payload: listBenefTallaje
});

export const updateEditedBeneficiarioDataxVestuarioTallaje = listBenefTallaje => ({ 
  type: types.VESTUARIO_TALLAJE_BENEFICIARIOS_LIST_LOADED, 
  payload: listBenefTallaje
});

export const fetchBeneficiarioPerfilDataxVestuarioTallaje = datosSelBenefTallaje => ({ 
  type: types.VESTUARIO_TALLAJE_BENEFICIARIO_SELECTED_DATA_REQUEST, 
  payload: datosSelBenefTallaje
});

// Filter
export const filterBeneficiariosDataxVestuarioTallaje = filter => ({ 
  type: types.VESTUARIO_TALLAJE_BENEFICIARIOS_LIST_FILTERED, 
  payload: {
    filterTallaje: filter
  }
});
export const resetfilterBeneficiariosDataxVestuarioTallaje = () => ({ 
  type: types.VESTUARIO_TALLAJE_BENEFICIARIOS_LIST_FILTERED, 
  payload: {filterTallaje: []}
});
// Benef Selected
export const resetSelectBeneficiarioForPerfilxVestuarioTallaje = () => ({ 
  type: types.VESTUARIO_TALLAJE_BENEFICIARIO_PROFILE_SELECTED, 
  payload: {selectedProfileTallaje: null}
});
export const selectBeneficiarioForPerfilxVestuarioTallaje = selectedProfileTallaje => ({ 
  type: types.VESTUARIO_TALLAJE_BENEFICIARIO_PROFILE_SELECTED, 
  payload: selectedProfileTallaje
});

// Tallaje
export const updateVestuarioTallaje = data => ({ 
  type: types.VESTUARIO_TALLAJE_UPDATED,  payload: data
});
export const insertVestuarioTallaje = data => ({ 
  type: types.VESTUARIO_TALLAJE_CREATED,  payload: data
});
export const deleteVestuarioTallaje = data => ({ 
  type: types.VESTUARIO_TALLAJE_DELETED,  payload: data
});
// export const cleanVestuarioTallaje = token => ({ 
//   type: types.VESTUARIO_TALLAJE_CLEAN, 
//   payload: {
//     token: token
//   }
// });
// export const insertBatchListaEntregaVesturaio = payload => ({ 
//   type: types.VESTUARIO_TALLAJE_BATCH,  
//   payload: payload
// });
// export const validarListEntrega = payload => ({ 
//   type: types.VESTUARIO_TALLAJE_VALIDAR,  
//   payload: payload
// });


export const serverMensaje = mensaje => ({ 
  type: types.MODULE_VESTUARIO_SERVER_MESSAGE, 
  payload: {
    mensaje: mensaje
  }
});