import React, {useState, useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";
import ContentEditable from 'react-contenteditable';
import { updateBeneficiarioVivienda, serverMensaje } from '../../redux/reducers/beneficiarios';
import {highlightAll, disableNewlines, pastePlainText } from '../../utils/utils';
import {showToaster} from './BenefShowMesg';
import Alert, {AlertMessage} from 'calcite-react/Alert'
import Button, { ButtonGroup } from 'calcite-react/Button';
import MultiSelect from 'calcite-react/MultiSelect';
import Select from 'calcite-react/Select';
import {MenuItem} from 'calcite-react/Menu';
import Switch from 'calcite-react/Switch';
import Accordion, {
    AccordionSection,
    AccordionTitle,
    AccordionContent
} from 'calcite-react/Accordion';

import styled from "styled-components";

import Animales from './BenefAnimales';

import viviendaTipo from '../../data/vivienda_tipo.json';
import viviendaPropiedad from '../../data/vivienda_propiedad.json';
import viviendaMateriales from '../../data/vivienda_materiales.json';
// import viviendaServicioPublico from '../../data/vivienda_serviciopublico.json';
import viviendaManejoBasura from '../../data/vivienda_manejobasura.json';
import viviendaEquipamento from '../../data/vivienda_equipamento.json';
// import viviendaAnimales from '../../data/vivienda_animales.json';

const BeneficiarioModulo = styled.div`
    height: auto;
    background: #fff;
    border: 0;
    padding: 0px;
    margin: 4px;
    overflow:hidden;
`;
const AccordionModulo = styled(Accordion)`
    padding:0;
`;
const AccordionTitleModule = styled(AccordionTitle)`
    background-color: #e6e6e6;
    color: #4d4d4d;
    font-size: 1.25em;
    font-weight: 700;
    // border-bottom: 1px solid #d9d9d9;
    // box-shadow: 0px 2px 6px #e1e1e1;
`;

const BenefVivienda = ({vivienda}) => {
    const dispatch = useDispatch();
    const mensaje = useSelector(state => state.beneficiarios.mensaje);
    const users = useSelector(state => state.users);
    // const isEditable = (users.user.tipo==='ADMINISTRADOR' && users.user.role==='A' && users.user.permisos==='A') ? true : false;
    const isEditable = (users.user.areatrabajo==='ADMINISTRATIVA' || users.user.areatrabajo==='TRABAJO SOCIAL' || users.user.areatrabajo==='COORDINACIÓN' || users.user.areatrabajo==='PSICOLOGÍA' ) ? true : false;
    // const canView = (users.user.areatrabajo==='ADMINISTRATIVA' || users.user.areatrabajo==='TECNOLOGÍA' ) ? true : false;
    
    const [activeSectionIndexes, setActiveSectionIndexes] = useState([]);
    
    const [docPropiedadSelected, setDocPropiedadSelected] = useState(parseInt(vivienda.docPropiedad));
    const [miembrobienesSelected, setMiembrobienesSelected] = useState(parseInt(vivienda.miembrobienes));
    const [prestamoSelected, setPrestasmoSelected] = useState(parseInt(vivienda.prestasmo));
    const [deudaSelected, setDeudaSelected] = useState(parseInt(vivienda.deuda));
    const [animalesSelected, setAnimalesSelected] = useState(parseInt(vivienda.animales));
    const [tipoviviendaSelectedValue, setTipoviviendaSelectedValue] = useState(vivienda.tipovivienda);
    const [propiedadSelectedValue, setPropiedadSelectedValue] = useState(vivienda.propiedad);
    
    const [materialPiso, setMaterialPiso] = useState([]);
    const [selectedMaterialpisoValues, setSelectedMaterialpisoValues] = useState(vivienda.materialpiso.split('|').map(x=>+x));
    const [materialPared, setMaterialPared] = useState([]);
    const [selectedMaterialparedValues, setSelectedMaterialparedValues] = useState(vivienda.materialpared.split('|').map(x=>+x));
    const [materialTecho, setMaterialTecho] = useState([]);
    const [selectedMaterialtechoValues, setSelectedMaterialtechoValues] = useState(vivienda.materialtecho.split('|').map(x=>+x));
    const [materialSanitario, setMaterialSanitario] = useState([]);
    const [sanitarioSelectedValue, setSanitarioSelectedValue] = useState(vivienda.sanitario);

    // const [manejobasura, setManejobasura] = useState([]);
    // const [equipamento, setEquipamento] = useState([]);
    const [selectedManejobasuraValues, setSelectedManejobasuraValues] = useState(vivienda.manejobasura.split('|').map(x=>+x));
    const [selectedEquipamentoValues, setSelectedEquipamentoValues] = useState(vivienda.equipamento.split(',').map(x=>+x));
    
    
    const [error, setError] = useState(null);
    const onAccordionChange = (evt, index) => {
        activeSectionIndexes.includes(index)
        ? setActiveSectionIndexes(activeSectionIndexes.filter(
            item => index !== item,
          ))
        : setActiveSectionIndexes([...activeSectionIndexes, index]);
    }
    
    const [V] = useState({
        "id": vivienda.id, //int
        "beneficiarioId": vivienda.beneficiarioId, //15
        "obsacceso": vivienda.obsacceso,
        "tipovivienda": vivienda.tipovivienda,
        "tipoviviendaOtro": vivienda.tipoviviendaOtro,
        "propiedad": vivienda.propiedad,
        "propiedadOtra": vivienda.propiedadOtra,
        "docPropiedad": vivienda.docPropiedad,
        "tipodoc": vivienda.tipodoc,
        "prestasmo": vivienda.prestasmo,
        "deuda": vivienda.deuda,
        "valordeuda": vivienda.valordeuda,
        "valorvivienda": vivienda.valorvivienda,
        "miembrobienes": vivienda.miembrobienes,
        "numfamilias": vivienda.numfamilias,
        "numhabitaciones": vivienda.numhabitaciones,
        "valorarriendo": vivienda.valorarriendo,
        "materialpiso": vivienda.materialpiso,
        "materialpared": vivienda.materialpared,
        "materialtecho": vivienda.materialtecho,
        "sanitario": vivienda.sanitario,
        "acueducto": vivienda.acueducto === '1' ? true : false,
        "alcantarillado": vivienda.alcantarillado === '1' ? true : false,
        "electricidad": vivienda.electricidad === '1' ? true : false,
        "gas": vivienda.gas === '1' ? true : false,
        "telefonia": vivienda.telefonia === '1' ? true : false,
        "aseo": vivienda.aseo === '1' ? true : false,
        "internet": vivienda.internet === '1' ? true : false,
        "obsserviciopublico": vivienda.obsserviciopublico,
        "manejobasura": vivienda.manejobasura,
        "equipamento": vivienda.equipamento,
        "animales": vivienda.animales,
        "comentarios": vivienda.comentarios, //text
        "actualizacion": vivienda.actualizacion, //0000-00-00 00:00:00
        "version": vivienda.version,
    });

    const [itemsVivienda, setItemsVivienda] = useState([
        // {"key":"id", "label": "Identificador Único", "value": V['id']},
        // {"key":"beneficiarioId", "label": "Documento de Identificación", "value": V['beneficiarioId']},
        {"key":"obsacceso", "label": "Describa el estado de las vías de acceso al barrio", "value": V['obsacceso']},
        {"key":"tipovivienda", "label": "Tipo de Vivienda", "value": V['tipovivienda']},
        {"key":"tipoviviendaOtro", "label": "Otro, Cuál?", "value": V['tipoviviendaOtro']},
        {"key":"propiedad", "label": "Propiedad vivienda", "value": V['propiedad']},
        {"key":"propiedadOtra", "label": "Otra, Cuál?", "value": V['propiedadOtra']},
        {"key":"docPropiedad", "label": "Tiene algún documento que demuestre la propiedad de la vivienda?", "value": V['docPropiedad']},
        {"key":"tipodoc", "label": "Qué tipo de documento?", "value": V['tipodoc']},
        {"key":"prestasmo", "label": "Utilizó préstamos de alguna(s) entidad(es), para la compra del lote o construcción de su vivienda?", "value": V['prestasmo']},
        {"key":"deuda", "label": "Tiene deuda?", "value": V['deuda']},
        {"key":"valordeuda", "label": "Cuánto debe por ese concepto?", "value": V['valordeuda']},
        {"key":"valorarriendo", "label": "Cuánto paga de arriendo mensualmente?", "value": V['valorarriendo']},
        {"key":"valorvivienda", "label": "Cuánto considera que vale esta vivienda, incluyendo el valor del lote?", "value": V['valorvivienda']},
        {"key":"miembrobienes", "label": "Aparte de usted, algún miembro del hogar es propietario de otros bienes inmuebles, como parcelas, fincas, casa, etc?", "value": V['miembrobienes']},
        {"key":"numfamilias", "label": "Número de familias en la vivienda", "value": V['numfamilias']},
        {"key":"numhabitaciones", "label": "Número de habitaciones de la vivienda", "value": V['numhabitaciones']},
        {"key":"materialpiso", "label": "Materiales predominantes de los pisos", "value": V['materialpiso']},
        {"key":"materialpared", "label": "Materiales predominantes en las paredes exteriores", "value": V['materialpared']},
        {"key":"materialtecho", "label": "Materiales predominantes en techos", "value": V['materialtecho']},
        {"key":"sanitario", "label": "Con qué tipo de servicio sanitario cuenta el hogar?", "value": V['sanitario']},
        // <div>Con cuál de los siguientes servicios públicos, privados o comunales cuenta la vivienda:</div>
        {"key":"acueducto", "label": "Acueducto", "value": V['acueducto']},
        {"key":"alcantarillado", "label": "Alcantarillado", "value": V['alcantarillado']},
        {"key":"electricidad", "label": "Eléctricidad", "value": V['electricidad']},
        {"key":"gas", "label": "Gas", "value": V['gas']},
        {"key":"telefonia", "label": "Telefonía", "value": V['telefonia']},
        {"key":"aseo", "label": "Aseo", "value": V['aseo']},
        {"key":"internet", "label": "Internet", "value": V['internet']},
        {"key":"obsserviciopublico", "label": "Describir o digitar cualquier observación de los servicios públicos anteriormente mencionados", "value": V['obsserviciopublico']},
        {"key":"manejobasura", "label": "Cómo eliminan principalmente la basura de este hogar?", "value": V['manejobasura']},
        {"key":"equipamento", "label": "Lista de chequeo equipamento del hogar", "value": V['equipamento']},
        {"key":"animales", "label": "Posee animales en la vivienda?", "value": V['animales']},
        // {"key":"comentarios", "label": "Comentarios en general de las condiciones habitacionales", "value": V['comentarios']},
        // {"key":"actualizacion", "label": "Última actualización", "value": V['actualizacion']},
        // {"key":"version", "label": "Versión", "value": V['version']}
    ]);

    useEffect(() => {
        // Guardar los resultados al salir, puede ser contraproducente al fallar la conexión con el servidor u otro evento no deseado
        // se podrián perder los datos modificafos

        if (viviendaMateriales){
            // setMaterialPiso( viviendaMateriales.tipo.filter((itm, index) => {
            //     if (itm.tipo.toLocaleLowerCase()==='piso') return itm;
            // }));
            setMaterialPiso( viviendaMateriales.tipo.filter(itm => itm.tipo.toLocaleLowerCase()==='piso'));
            setMaterialPared( viviendaMateriales.tipo.filter(itm => itm.tipo.toLocaleLowerCase()==='pared'));
            setMaterialTecho( viviendaMateriales.tipo.filter(itm => itm.tipo.toLocaleLowerCase()==='techo'));
            setMaterialSanitario(viviendaMateriales.tipo.filter(itm => itm.tipo.toLocaleLowerCase()==='sanitario'));
        }
        
        return () => {
            // if (isUpdated){
            //     console.log('Saving appointment', isUpdated);
            // }
            dispatch(serverMensaje({}));
        }
    }, [dispatch]);

    useEffect(() => {
        // if (itemsVivienda){
        //     // console.log(itemsVivienda);
        // }
        if (mensaje.vivienda && mensaje.vivienda!=='') {
            if (typeof mensaje.vivienda === 'string' && mensaje.vivienda.toLowerCase().includes('error')){
                showToaster('error', 'Ocurrió un fallo en el servidor', mensaje.vivienda);
            } 
            // else {
            //     showToaster('success',"Vivienda del Beneficiario", mensaje.vivienda); //"🧭 " + mensaje
            // }
        }

    }, [mensaje.vivienda, dispatch]);

    const updateItemsVivienda = (f, v) => {
        const newitemsVivienda = itemsVivienda.map((itm, index) => {
            if (itm.key===f){
                itm.value = v;
            }
            return itm;
        });
        setItemsVivienda(newitemsVivienda);
    };

    const updateDataonServer = () =>{
        dispatch(serverMensaje({vivienda:''}));
        // Corregir valores antes de enviar
        V["acueducto"] =  V.acueducto === true ? '1' : '0';
        V["alcantarillado"] = V.alcantarillado === true ? '1' : '0';
        V["electricidad"] = V.electricidad === true ? '1' : '0';
        V["gas"] = V.gas === true ? '1' : '0';
        V["telefonia"] = V.telefonia === true ? '1' : '0';
        V["aseo"] = V.aseo === true ? '1' : '0';
        V["internet"] = V.internet === true ? '1' : '0';

        dispatch(updateBeneficiarioVivienda( { token: users.user.claveAPI, vivienda:V}));
    }

    const replaceSomeText = (s) =>{
        return s
            .replace(/&nbsp;/g, '')
            .replace(/<br>/g, '')
            .replace(/&amp;/g, '&')
            .replace(/&gt;/g, '>')
            .replace(/&lt;/g, '<').trim()
    }
    
    const validateInput = (f, v, final) => {
        const regex_onlynum = /[0-9,]|\./;
        // const regex_nombres = /#[a-zA-Z\xE1\xE9\xED\xF3\xFA\xC1\xC9\xCD\xD3\xDA\xF1\xD1]+#/i; // /^[a-zA-Z ]+$/;
        // const regex_nombres = /^['`´a-zA-ZÀ-ÿ\u00C0-\u017F\s]+$/;
        // const regex_onlyletters = /#[a-zA-Z\xE1\xE9\xED\xF3\xFA\xC1\xC9\xCD\xD3\xDA\xF1\xD1]+#/i;
        const regex_gasto = /^[0-9]*(\.[0-9]+)?$/;
        let new_val = null;
        let err = null;
        switch (f) {
            case 'obsacceso':
                new_val = v.substr(0,255);
                if (final)
                    new_val = replaceSomeText(v).substr(0,255);
                break;
            case 'tipovivienda':
                new_val = v.substr(0,32);
                if (final)
                    new_val = replaceSomeText(v).substr(0,32);
                break;
            case 'tipoviviendaOtro':
                new_val = v.substr(0,50);
                if (final)
                    new_val = replaceSomeText(v).substr(0,50);
                break;
            case 'propiedad':
                new_val = v.substr(0,32);
                if (final)
                    new_val = replaceSomeText(v).substr(0,32);
                break;
            case 'propiedadOtra':
                new_val = v.substr(0,50);
                if (final)
                    new_val = replaceSomeText(v).substr(0,50);
                break;
            case 'docPropiedad':
                new_val = replaceSomeText(v).substr(0,1); //1
                if (!regex_onlynum.test(new_val)) {
                    err = 'Dígite solo números válidos';
                    new_val = null;
                } else if(parseInt(new_val)>1){
                    err = 'Dígite un número válido';
                    new_val = null;
                }
                break;
            case 'tipodoc':
                new_val = v.substr(0,32);
                if (final)
                    new_val = replaceSomeText(v).substr(0,32);
                break;
            case 'prestasmo':
                new_val = replaceSomeText(v).substr(0,1); //1
                if (!regex_onlynum.test(new_val)) {
                    err = 'Dígite solo números válidos';
                    new_val = null;
                } else if(parseInt(new_val)>1){
                    err = 'Dígite un número válido';
                    new_val = null;
                }
                break;
            case 'deuda':
                new_val = replaceSomeText(v).substr(0,1); //1
                if (!regex_onlynum.test(new_val)) {
                    err = 'Dígite solo números válidos';
                    new_val = null;
                } else if(parseInt(new_val)>1){
                    err = 'Dígite un número válido';
                    new_val = null;
                }
                break;
            case 'valordeuda':
                new_val = replaceSomeText(v);
                if (!regex_gasto.test(new_val)) {
                    err = 'Dígite solo números válidos';
                    new_val = null;
                }
                if (new_val===null) new_val = '';
                break;
            case 'valorvivienda':
                new_val = replaceSomeText(v);
                if (!regex_gasto.test(new_val)) {
                    err = 'Dígite solo números válidos';
                    new_val = null;
                }
                if (new_val===null) new_val = '';
                break;
            case 'miembrobienes':
                new_val = replaceSomeText(v).substr(0,1); //1
                if (!regex_onlynum.test(new_val)) {
                    err = 'Dígite solo números válidos';
                    new_val = null;
                } else if(parseInt(new_val)>1){
                    err = 'Dígite un número válido';
                    new_val = null;
                }
                break;
            case 'numfamilias':
                new_val = replaceSomeText(v).substr(0,2); //int
                if (!regex_onlynum.test(new_val)) {
                    err = 'Dígite solo números válidos';
                    new_val = null;
                } else if(parseInt(new_val)>10){
                    err = 'Dígite un número de familias válido';
                    new_val = null;
                }
                break;
            case 'numhabitaciones':
                new_val = replaceSomeText(v).substr(0,2); //int
                if (!regex_onlynum.test(new_val)) {
                    err = 'Dígite solo números válidos';
                    new_val = null;
                } else if(parseInt(new_val)>20){
                    err = 'Dígite un número válido';
                    new_val = null;
                }
                break;
            case 'valorarriendo':
                new_val = replaceSomeText(v);
                if (!regex_gasto.test(new_val)) {
                    err = 'Dígite solo números válidos';
                    new_val = null;
                }
                if (new_val===null) new_val = '';
                break;
            case 'materialpiso':
                new_val = v.substr(0,50);
                if (final)
                    new_val = replaceSomeText(v).substr(0,50);
                break;
            case 'materialpared':
                new_val = v.substr(0,50);
                if (final)
                    new_val = replaceSomeText(v).substr(0,50);
                break;
            case 'materialtecho':
                new_val = v.substr(0,50);
                if (final)
                    new_val = replaceSomeText(v).substr(0,50);
                break;
            case 'sanitario':
                new_val = v.substr(0,255);
                if (final)
                    new_val = replaceSomeText(v).substr(0,255);
                break;
            case 'acueducto':
                new_val = replaceSomeText(v).substr(0,1); //1
                if (!regex_onlynum.test(new_val)) {
                    err = 'Dígite solo números válidos';
                    new_val = null;
                } else if(parseInt(new_val)>1){
                    err = 'Dígite un número válido';
                    new_val = null;
                }
                break;
            case 'alcantarillado':
                new_val = replaceSomeText(v).substr(0,1); //1
                if (!regex_onlynum.test(new_val)) {
                    err = 'Dígite solo números válidos';
                    new_val = null;
                } else if(parseInt(new_val)>1){
                    err = 'Dígite un número válido';
                    new_val = null;
                }
                break;
            case 'electricidad':
                new_val = replaceSomeText(v).substr(0,1); //1
                if (!regex_onlynum.test(new_val)) {
                    err = 'Dígite solo números válidos';
                    new_val = null;
                } else if(parseInt(new_val)>1){
                    err = 'Dígite un número válido';
                    new_val = null;
                }
                break;
            case 'gas':
                new_val = replaceSomeText(v).substr(0,1); //1
                if (!regex_onlynum.test(new_val)) {
                    err = 'Dígite solo números válidos';
                    new_val = null;
                } else if(parseInt(new_val)>1){
                    err = 'Dígite un número válido';
                    new_val = null;
                }
                break;
            case 'telefonia':
                new_val = replaceSomeText(v).substr(0,1); //1
                if (!regex_onlynum.test(new_val)) {
                    err = 'Dígite solo números válidos';
                    new_val = null;
                } else if(parseInt(new_val)>1){
                    err = 'Dígite un número válido';
                    new_val = null;
                }
                break;
            case 'aseo':
                new_val = replaceSomeText(v).substr(0,1); //1
                if (!regex_onlynum.test(new_val)) {
                    err = 'Dígite solo números válidos';
                    new_val = null;
                } else if(parseInt(new_val)>1){
                    err = 'Dígite un número válido';
                    new_val = null;
                }
                break;
            case 'internet':
                new_val = replaceSomeText(v).substr(0,1); //1
                if (!regex_onlynum.test(new_val)) {
                    err = 'Dígite solo números válidos';
                    new_val = null;
                } else if(parseInt(new_val)>1){
                    err = 'Dígite un número válido';
                    new_val = null;
                }
                break;
            case 'obsserviciopublico':
                new_val = v.substr(0,255);
                if (final)
                    new_val = replaceSomeText(v).substr(0,255);
                break;
            case 'manejobasura':
                new_val = v.substr(0,50);
                if (final)
                    new_val = replaceSomeText(v).substr(0,50);
                break;
            case 'equipamento':
                new_val = v.substr(0,50);
                if (final)
                    new_val = replaceSomeText(v).substr(0,50);
                break;
            case 'animales':
                new_val = replaceSomeText(v).substr(0,1); //1
                if (!regex_onlynum.test(new_val)) {
                    err = 'Dígite solo números válidos';
                    new_val = null;
                } else if(parseInt(new_val)>1){
                    err = 'Dígite un número válido';
                    new_val = null;
                }
                break;
            case 'comentarios':
                new_val = v.trim();
                if (final)
                    new_val = replaceSomeText(v);
                break;
            default:
                break;
        }
        setError(err);
        return new_val
    }
    const handleChange = evt => {
        const field = evt.currentTarget.id.split('_')[1];
        const new_val = validateInput(field, evt.target.value, false)
        if(new_val){
            V[field] = new_val
        };
    };
    const handleBlur = evt => {
        const val = evt.currentTarget.innerHTML;
        const field = evt.target.id.split('_')[1];
        const new_val = validateInput(field, val, true)
        if(new_val || new_val===''){
            V[field] = new_val;
            if (field!=='comentarios')
                updateItemsVivienda(field, V[field]);
            updateDataonServer();
        };
        
    };

    const handleMaterialpisoMultiSelectChange = (values, items) => {
        let mat_str = "";
        if(values && values.length>0){
            values.map(v =>{
                return mat_str += v + '|';
            });
            mat_str = mat_str.slice(0,mat_str.length-1);
        }
        setSelectedMaterialpisoValues(values);
        V['materialpiso'] = mat_str;
        updateDataonServer();
    };
    const renderMaterialpisoValue = (items) => {
        if (!items || items.length === 0) {
          return 'Seleccione una opción'
        } else if (items.length > 2) {
          // Template literals currently are not supported by MDX:
          // https://github.com/mdx-js/mdx/issues/218
          return items.length + ' materiales'
        } else {
          return items.map(item => item.props.children).join(', ')
        }
    };

    const handleMaterialparedMultiSelectChange = (values, items) => {
        let mat_str = "";
        if(values && values.length>0){
            values.map(v =>{
                return mat_str += v + '|';
            });
            mat_str = mat_str.slice(0,mat_str.length-1);
        }
        setSelectedMaterialparedValues(values);
        V['materialpared'] = mat_str;
        updateDataonServer();
    };
    const renderMaterialparedValue = (items) => {
        if (!items || items.length === 0) {
          return 'Seleccione una opción'
        } else if (items.length > 1) {
          // Template literals currently are not supported by MDX:
          // https://github.com/mdx-js/mdx/issues/218
          return items.length + ' materiales'
        } else {
          return items.map(item => item.props.children).join(', ')
        }
    };

    const handleMaterialtechoMultiSelectChange = (values, items) => {
        let mat_str = "";
        if(values && values.length>0){
            values.map(v =>{
                return mat_str += v + '|';
            });
            mat_str = mat_str.slice(0,mat_str.length-1);
        }
        setSelectedMaterialtechoValues(values);
        V['materialtecho'] = mat_str;
        updateDataonServer();
    };
    const renderMaterialtechoValue = (items) => {
        if (!items || items.length === 0) {
          return 'Seleccione una opción'
        } else if (items.length > 1) {
          // Template literals currently are not supported by MDX:
          // https://github.com/mdx-js/mdx/issues/218
          return items.length + ' materiales'
        } else {
          return items.map(item => item.props.children).join(', ')
        }
    };
    
    const handleManejobasuraMultiSelectChange = (values, items) => {
        let mat_str = "";
        if(values && values.length>0){
            values.map(v =>{
                return mat_str += v + '|';
            });
            mat_str = mat_str.slice(0,mat_str.length-1);
        }
        setSelectedManejobasuraValues(values);
        V['manejobasura'] = mat_str;
        updateDataonServer();
    };
    const renderManejobasuraValue = (items) => {
        if (!items || items.length === 0) {
          return 'Seleccione una opción'
        } else if (items.length > 2) {
          // Template literals currently are not supported by MDX:
          // https://github.com/mdx-js/mdx/issues/218
          return items.length + '  manejos'
        } else {
          return items.map(item => item.props.children).join(', ')
        }
    };

    const handleEquipamentoMultiSelectChange = (values, items) => {
        let mat_str = "";
        if(values && values.length>0){
            values.map(v =>{
                return mat_str += v + ',';
            });
            mat_str = mat_str.slice(0,mat_str.length-1);
        }
        setSelectedEquipamentoValues(values);
        V['equipamento'] = mat_str;
        updateDataonServer();
    };
    const renderEquipamentoValue = (items) => {
        if (!items || items.length === 0) {
          return 'Seleccione una opción'
        } else if (items.length > 2) {
          // Template literals currently are not supported by MDX:
          // https://github.com/mdx-js/mdx/issues/218
          return items.length + '  equipamentos'
        } else {
          return items.map(item => item.props.children).join(', ')
        }
    };

    const miembrobienesSelectButton = (selected) => {
        setMiembrobienesSelected(selected);
        V['miembrobienes'] = selected; // === 1 ? '1' : '0';
        updateDataonServer();
    }
    
    const docPropiedadSelectButton = (selected) => {
        setDocPropiedadSelected(selected);
        V['docPropiedad'] = selected; // === 1 ? '1' : '0';
        updateDataonServer();
    }
    const prestamoSelectButton = (selected) => {
        setPrestasmoSelected(selected);
        V['prestamo'] = selected; // === 1 ? '1' : '0';
        updateDataonServer();
    }
    const deudaSelectButton = (selected) => {
        setDeudaSelected(selected);
        V['deuda'] = selected; // === 1 ? '1' : '0';
        updateDataonServer();
    }
    const animalesSelectButton = (selected) => {
        setAnimalesSelected(selected);
        V['animales'] = selected; // === 1 ? '1' : '0';
        updateDataonServer();
    }
    const handleTipoviviendaSelectChange = (value, item) => {
        setTipoviviendaSelectedValue(value);
        V['tipovivienda'] = value;
        updateDataonServer();
    };
    const handlePropiedadSelectChange = (value, item) => {
        setPropiedadSelectedValue(value);
        V['propiedad'] = value;
        updateDataonServer();
    };
    const handleSanitarioSelectChange = (value, item) => {
        setSanitarioSelectedValue(value);
        V['sanitario'] = value;
        updateDataonServer();
    };

    // Servicios Públicos
    const onChangeAcueducto = (evt) => {
        V.acueducto =  evt.target.checked ? '1' : '0';
        updateItemsVivienda('acueducto', evt.target.checked);
        updateDataonServer();
    }
    const onChangeAlcantarillado = (evt) => {
        V.alcantarillado =  evt.target.checked ? '1' : '0';
        updateItemsVivienda('alcantarillado', evt.target.checked);
        updateDataonServer();
    }
    const onChangeElectricidad = (evt) => {
        V.electricidad =  evt.target.checked ? '1' : '0';
        updateItemsVivienda('electricidad', evt.target.checked);
        updateDataonServer();
    }
    const onChangeGas = (evt) => {
        V.gas =  evt.target.checked ? '1' : '0';
        updateItemsVivienda('gas', evt.target.checked);
        updateDataonServer();
    }
    const onChangeTelefonia = (evt) => {
        V.telefonia =  evt.target.checked ? '1' : '0';
        updateItemsVivienda('telefonia', evt.target.checked);
        updateDataonServer();
    }
    const onChangeAseo = (evt) => {
        V.aseo =  evt.target.checked ? '1' : '0';
        updateItemsVivienda('aseo', evt.target.checked);
        updateDataonServer();
    }
    const onChangeInternet = (evt) => {
        V.internet =  evt.target.checked ? '1' : '0';
        updateItemsVivienda('internet', evt.target.checked);
        updateDataonServer();
    }


    return (
        <BeneficiarioModulo>
            <AccordionModulo
                onAccordionChange={onAccordionChange}
                activeSectionIndexes={activeSectionIndexes}
            >
                <AccordionSection>
                    <AccordionTitleModule>Condiciones Habitacionales</AccordionTitleModule>
                    {activeSectionIndexes.length>0 &&  <AccordionContent style={{marginBottom: '10px'}}>
                    {error && <div style={{color: 'red', width: '100%'}}>
                            <Alert red showIcon style={{minWidth: '100%', marginBottom: '10px'}}
                                showCloseLabel 
                                onClose={() => setError(null)}>
                                <AlertMessage>
                                    Error:{' '}
                                    <strong>{error}</strong>
                                </AlertMessage>
                            </Alert>
                        </div> 
                    }
                    {/* ITEMS */}
                    {
                        itemsVivienda.map((itm, index) => {
                            return (<div key={index} className="itemContainer">
                                <div className="itemContainer-attr-long" 
                                    style={{backgroundColor:(index % 2 === 0) ? '#f8f8f8': '#fff'}}
                                >{itm.label}</div>
                                <div className="itemContainer-value" 
                                    style={{backgroundColor:(index % 2 === 0) ? '#f8f8f8': '#fff'}}
                                >

                                {   itm.key==='docPropiedad'
                                    ?
                                    <ButtonGroup isToggle>
                                        <Button  clear={docPropiedadSelected !== 1} disabled={!isEditable} 
                                            onClick={() => {  docPropiedadSelectButton(1) }}
                                        >Si</Button>
                                        <Button clear={docPropiedadSelected !== 0} disabled={!isEditable} 
                                            onClick={() => { docPropiedadSelectButton(0) }}
                                        >No</Button>
                                    </ButtonGroup>
                                    :
                                    itm.key==='miembrobienes'
                                    ?
                                    <ButtonGroup isToggle>
                                        <Button  clear={miembrobienesSelected !== 1} disabled={!isEditable} 
                                            onClick={() => {  miembrobienesSelectButton(1) }}
                                        >Si</Button>
                                        <Button clear={miembrobienesSelected !== 0} disabled={!isEditable} 
                                            onClick={() => { miembrobienesSelectButton(0) }}
                                        >No</Button>
                                    </ButtonGroup>
                                    :
                                    itm.key==='prestasmo'
                                    ?
                                    <ButtonGroup isToggle>
                                        <Button  clear={prestamoSelected !== 1} disabled={!isEditable} 
                                            onClick={() => {  prestamoSelectButton(1) }}
                                        >Si</Button>
                                        <Button clear={prestamoSelected !== 0} disabled={!isEditable} 
                                            onClick={() => { prestamoSelectButton(0) }}
                                        >No</Button>
                                    </ButtonGroup>
                                    :
                                    itm.key==='deuda'
                                    ?
                                    <ButtonGroup isToggle>
                                        <Button  clear={deudaSelected !== 1} disabled={!isEditable} 
                                            onClick={() => {  deudaSelectButton(1) }}
                                        >Si</Button>
                                        <Button clear={deudaSelected !== 0} disabled={!isEditable} 
                                            onClick={() => { deudaSelectButton(0) }}
                                        >No</Button>
                                    </ButtonGroup>
                                    :
                                    itm.key==='animales'
                                    ?
                                    <ButtonGroup isToggle>
                                        <Button  clear={animalesSelected !== 1} disabled={!isEditable} 
                                            onClick={() => {  animalesSelectButton(1) }}
                                        >Si</Button>
                                        <Button clear={animalesSelected !== 0} disabled={!isEditable} 
                                            onClick={() => { animalesSelectButton(0) }}
                                        >No</Button>
                                    </ButtonGroup>
                                    :
                                    itm.key==='tipovivienda'
                                    ?
                                    <Select positionFixed disabled={!isEditable} 
                                            onChange={handleTipoviviendaSelectChange}
                                            selectedValue={tipoviviendaSelectedValue.trim()}
                                        >
                                        {viviendaTipo.tipo.map(v => {
                                            return (
                                            <MenuItem key={v.id} value={v.name}>
                                                {v.name}
                                            </MenuItem>
                                            )
                                        })}
                                        </Select>
                                    :
                                    itm.key==='propiedad' 
                                    ?
                                    <Select positionFixed disabled={!isEditable} 
                                        onChange={handlePropiedadSelectChange}
                                        selectedValue={propiedadSelectedValue.trim()}
                                    >
                                    {viviendaPropiedad.tipo.map(v => {
                                        return (
                                        <MenuItem key={v.id} value={v.name}>
                                            {v.name}
                                        </MenuItem>
                                        )
                                    })}
                                    </Select>
                                    :
                                    itm.key==='sanitario' && materialSanitario
                                    ?
                                    <Select filterable positionFixed disabled={!isEditable} 
                                            onChange={handleSanitarioSelectChange}
                                            selectedValue={sanitarioSelectedValue.trim()}
                                        >
                                        {materialSanitario.map(s => {
                                            return (
                                                <MenuItem key={s.id} value={s.nombre}>
                                                    {s.nombre}
                                                </MenuItem>
                                            )
                                        })}
                                        </Select>
                                    :
                                    itm.key==='materialpiso'
                                    ?
                                        <MultiSelect disabled={!isEditable} 
                                            renderValue={renderMaterialpisoValue}
                                            onChange={handleMaterialpisoMultiSelectChange}
                                            selectedValues={selectedMaterialpisoValues}
                                            closeOnSelect={true}
                                        >
                                            {materialPiso.map((m,i) => {
                                                return <MenuItem key={m.id} value={m.id}>{m.nombre}</MenuItem>
                                            })}
                                        </MultiSelect>
                                    :
                                    itm.key==='materialpared'
                                    ?
                                        <MultiSelect disabled={!isEditable} 
                                            renderValue={renderMaterialparedValue}
                                            onChange={handleMaterialparedMultiSelectChange}
                                            selectedValues={selectedMaterialparedValues}
                                            closeOnSelect={true}
                                        >
                                            {materialPared.map((m,i) => {
                                                return <MenuItem key={m.id} value={m.id}>{m.nombre}</MenuItem>
                                            })}
                                        </MultiSelect>
                                    :
                                    itm.key==='materialtecho'
                                    ?
                                        <MultiSelect fullWidth positionFixed disabled={!isEditable} 
                                            renderValue={renderMaterialtechoValue}
                                            onChange={handleMaterialtechoMultiSelectChange}
                                            selectedValues={selectedMaterialtechoValues}
                                            closeOnSelect={true}
                                        >
                                            {materialTecho.map((m,i) => {
                                                return <MenuItem key={m.id} value={m.id}>{m.nombre}</MenuItem>
                                            })}
                                        </MultiSelect>
                                    :
                                    itm.key==='manejobasura'
                                    ?
                                        <MultiSelect positionFixed disabled={!isEditable} 
                                            renderValue={renderManejobasuraValue}
                                            onChange={handleManejobasuraMultiSelectChange}
                                            selectedValues={selectedManejobasuraValues}
                                            closeOnSelect={true}
                                        >
                                            {viviendaManejoBasura.tipo.map((m,i) => {
                                                return <MenuItem key={m.id} value={m.id}>{m.name}</MenuItem>
                                            })}
                                        </MultiSelect>
                                    :
                                    itm.key==='equipamento'
                                    ?
                                        <MultiSelect positionFixed disabled={!isEditable} 
                                            renderValue={renderEquipamentoValue}
                                            onChange={handleEquipamentoMultiSelectChange}
                                            selectedValues={selectedEquipamentoValues}
                                            closeOnSelect={true}
                                        >
                                            {viviendaEquipamento.tipo.map((m,i) => {
                                                return <MenuItem key={m.id} value={m.id}>{m.name}</MenuItem>
                                            })}
                                        </MultiSelect>
                                    :
                                    itm.key==='acueducto' 
                                    ?
                                        <Switch 
                                            labelPosition={"before" || '' }
                                            style={{margin: '0 0 0.5rem 0'}}
                                            name={ 'vivienda_' + V.id } 
                                            checked={ itm.value} 
                                            disabled={!isEditable} 
                                            onChange={onChangeAcueducto} />
                                    :
                                    itm.key==='alcantarillado' 
                                    ?
                                        <Switch 
                                            labelPosition={"before" || '' }
                                            style={{margin: '0 0 0.5rem 0'}}
                                            name={ 'vivienda_' + V.id } 
                                            checked={ itm.value} 
                                            disabled={!isEditable} 
                                            onChange={onChangeAlcantarillado} />
                                    :
                                    itm.key==='electricidad' 
                                    ?
                                        <Switch 
                                            labelPosition={"before" || '' }
                                            style={{margin: '0 0 0.5rem 0'}}
                                            name={ 'vivienda_' + V.id } 
                                            checked={ itm.value} 
                                            disabled={!isEditable} 
                                            onChange={onChangeElectricidad} />
                                    :
                                    itm.key==='gas' 
                                    ?
                                        <Switch 
                                            labelPosition={"before" || '' }
                                            style={{margin: '0 0 0.5rem 0'}}
                                            name={ 'vivienda_' + V.id } 
                                            checked={ itm.value} 
                                            disabled={!isEditable} 
                                            onChange={onChangeGas} />
                                    :
                                    itm.key==='telefonia' 
                                    ?
                                        <Switch 
                                            labelPosition={"before" || '' }
                                            style={{margin: '0 0 0.5rem 0'}}
                                            name={ 'vivienda_' + V.id } 
                                            checked={ itm.value} 
                                            disabled={!isEditable} 
                                            onChange={onChangeTelefonia} />
                                    :
                                    itm.key==='aseo' 
                                    ?
                                        <Switch 
                                            labelPosition={"before" || '' }
                                            style={{margin: '0 0 0.5rem 0'}}
                                            name={ 'vivienda_' + V.id } 
                                            checked={ itm.value} 
                                            disabled={!isEditable} 
                                            onChange={onChangeAseo} />
                                    :
                                    itm.key==='internet' 
                                    ?
                                        <Switch 
                                            labelPosition={"before" || '' }
                                            style={{margin: '0 0 0.5rem 0'}}
                                            name={ 'vivienda_' + V.id } 
                                            checked={ itm.value} 
                                            disabled={!isEditable} 
                                            onChange={onChangeInternet} />
                                    :
                                    <ContentEditable
                                        id={'vivienda_'+itm.key}
                                        html={itm.value || ''} // innerHTML of the editable div
                                        className='itemContainer-value-long'
                                        disabled={!isEditable}      // use true to disable editing
                                        onPaste={pastePlainText} // En caso se copie y pegue de otra pagina de internet
                                        onChange={handleChange} // handle innerHTML change
                                        onKeyPress={disableNewlines}  
                                        onFocus={highlightAll}
                                        onBlur={handleBlur}
                                        tagName='div' // Use a custom HTML tag (uses a div by default)
                                    />
                                }
                                </div>
                            </div>)
                        })
                    }

                    {/* Tabla Animales al Hogar */}
                    {(V.animales || V.animales==='1') && vivienda.animaleshogar && <div className="itemContainer-table">
                            <div className="tablas-Beneficiario-detalles">
                                <div className="benef-texto-observaciones-title">Cuáles de los siguientes animales posee?:</div>
                                <Animales animales={vivienda.animaleshogar} beneficiarioId={vivienda.beneficiarioId} />
                            </div>
                        </div>
                    }

                    <div className="Observaciones-Comentarios">
                        <div className="benef-texto-observaciones-title">Comentarios en general:</div>
                        <div className="benef-texto-observaciones"> 
                            <ContentEditable
                                id={'vivienda_comentarios'}
                                html={V.comentarios || ''} // innerHTML of the editable div
                                disabled={!isEditable}        // use true to disable editing
                                onPaste={pastePlainText}
                                onChange={handleChange} // handle innerHTML change
                                onFocus={highlightAll}
                                onBlur={handleBlur}
                                tagName='div' // Use a custom HTML tag (uses a div by default)
                                style={{ "minHeight": "40px", "border": "1px dashed" }}
                            />
                        </div>
                    </div>
                        
                        <div className="benef-texto-actualizacion">Última actualización: {V['actualizacion']}</div>

                    </AccordionContent>
                }
                </AccordionSection>
            </AccordionModulo>
        </BeneficiarioModulo>
    );
}

export default BenefVivienda;

