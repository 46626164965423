import React, {useState, useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";
import { 
    updateCriteriosCupo, 
    insertCriteriosCupo,
    deleteCriteriosCupo,
    serverMensaje } from '../../../redux/reducers/covid19';
import {highlightAll, disableNewlines, pastePlainText } from '../../../utils/utils';
import {showMsgToaster} from '../../ShowMesg';
import ContentEditable from 'react-contenteditable';
import Alert, {AlertMessage} from 'calcite-react/Alert';
import Table, {
    TableHeader,
    TableHeaderRow,
    TableHeaderCell,
    TableBody,
    TableRow,
    TableCell
} from 'calcite-react/Table';
import Button from 'calcite-react/Button';

import PlusIcon from 'calcite-ui-icons-react/PlusIcon';
import SaveIcon from 'calcite-ui-icons-react/SaveIcon';
import TrashIcon from 'calcite-ui-icons-react/TrashIcon';

const CriterioCupo = ({criterios}) => {
    const dispatch = useDispatch();
    const mensaje = useSelector(state => state.covid19.mensaje);
    const users = useSelector(state => state.users);
    // const isEditable = users.user.tipo==='ADMINISTRADOR' ? true : false;
    const isEditable = (users.user.areatrabajo==='ADMINISTRATIVA' || users.user.areatrabajo==='COORDINACIÓN' || users.user.areatrabajo==='TECNOLOGÍA') ? true : false;
    // const canView = (users.user.areatrabajo==='ADMINISTRATIVA' || users.user.areatrabajo==='TECNOLOGÍA' ) ? true : false;
    
    const [isBtnAddEnable, setIsBtnAddEnable] = useState(false);
    const [error, setError] = useState(null);
    const [C, updateCriterio] = useState([...criterios]); //Object.assign({}, criterios); // Arreglos no Objects

    useEffect(() => {

        if (mensaje.criterioscupo && mensaje.criterioscupo!=='') {
            const insertCriterios = (id, f, v) => {
                if (C){
                    updateCriterio(C.map((itm, index) => {
                        if (itm.id===id){
                            itm[f] = v;
                        }
                        return itm;
                    }));
                    mensaje.criterioscupo='';
                }
            };

            if (typeof mensaje.criterioscupo === 'string' && mensaje.criterioscupo.toLowerCase().includes('error')){
                showMsgToaster('error', 'Ocurrió un fallo en el servidor', mensaje.criterioscupo);
            } 
            else {
                showMsgToaster('success',"Criterios Plan Solidario", mensaje.criterioscupo.split('.')[0]);
                // Update Id for edition
                // Cambiar el id por el que devuelve el servidor o transacción de MySQL
                insertCriterios('-1', 'id', String(mensaje.criterioscupo.split('.')[1]));
            }
        }

    }, [mensaje.criterioscupo, updateCriterio, C, dispatch]);
    
    const updateCriterios = (id, f, v) => {
        if (C){
            updateCriterio(C.map((itm, index) => {
                if (itm.id===id){
                    itm[f] = v;
                }
                return itm;
            }));
        }
    };

    const updateDataonServer = (id, action, newItem) =>{
        dispatch(serverMensaje({criterios:''}));
        switch (action) {
            case 'insert':
                if (parseInt(id)< 0 && newItem){
                    // console.log('insertando nuevo registro: ', newItem);
                    setIsBtnAddEnable(true);
                }
                break;
            case 'save':
                if (id){
                    const reg = C.filter(itm => itm.id===id);
                    if (reg && reg.length>0){
                        dispatch(insertCriteriosCupo( { token: users.user.claveAPI, criterios:reg[0]}));
                        setIsBtnAddEnable(false);
                    }
                }
                break;
            case 'remove':
                if (id){
                    const reg = C.filter(itm => itm.id===id);
                    if (reg && reg.length>0){
                        dispatch(deleteCriteriosCupo( { token: users.user.claveAPI, criterios:reg[0]}));
                    }
                }
                break;
            default: // update
                // Actualizar solo el registro necesario, para no enviar toda la tabla
                if (id && parseInt(id) > 0){
                    const reg = C.filter(itm => itm.id===id);
                    if (reg && reg.length>0){
                        dispatch(updateCriteriosCupo( { token: users.user.claveAPI, criterios:reg[0]}));
                    }
                }
                break;
        }
    }

    const replaceSomeText = (s) =>{
        return s
            .replace(/&nbsp;/g, ' ')
            .replace(/<br>/g, '')
            .replace(/&amp;/g, '&')
            .replace(/&gt;/g, '>')
            .replace(/&lt;/g, '<').trim()
    }

    const validateInput = (f, v, final) => {
        const regex_onlynum = /^[0-9]*$/i; ///[0-9,]|\./;
        // const regex_nombres = /^['`´a-zA-ZÀ-ÿ\u00C0-\u017F\s]+$/;
        let new_val = null;
        let err = null;
        switch (f) {
            case 'clase':
                new_val = v.substr(0,32);
                if (final)
                    new_val = replaceSomeText(v).substr(0,32);
                break;
            case 'valor':
                new_val = replaceSomeText(v);
                if (!regex_onlynum.test(new_val)) {
                    err = 'Dígite solo números válidos';
                    new_val = null;
                }
                if (new_val===null) new_val = '';
                break;
            default:
                break;
        }
        setError(err);
        return new_val
    }

    const handleChange = evt => {
        const [, field, id] = evt.currentTarget.id.split('_');
        const new_val = validateInput(field, evt.target.value, false);
        if(new_val){
            updateCriterios(id,field, new_val );
        };

    };
    const handleBlur = evt => {
        const val = evt.currentTarget.innerHTML;
        const [,field, id] = evt.target.id.split('_');
        const new_val = validateInput(field, val, true)
        if(new_val || new_val===''){
            updateCriterios(id,field, new_val );
            updateDataonServer(id);
        };
    };

    const onClickAddCriterios = () => {
        const newId = -1;
        const nuevo_criterio = {
            "id": String(newId),
            "clase": "",
            "valor": ""
        }
        updateCriterio([...C].concat(nuevo_criterio) );
        updateDataonServer(newId, 'insert', nuevo_criterio);
    }
    const onClickSaveCriterios = (id) => {
        updateDataonServer(id, 'save');
    }
    const onClickRemoveCriterios = (id) => {
        updateCriterio([...C].filter(itm => itm.id!==id));
        updateDataonServer(id, 'remove');
    }

    return (
        <div>
            { error && <div style={{color: 'red', width: '100%'}}>
                <Alert red showIcon style={{minWidth: '100%', marginBottom: '10px'}}
                    showCloseLabel 
                    onClose={() => setError(null)}>
                    <AlertMessage>
                        Error:{' '}
                        <strong>{error}</strong>
                    </AlertMessage>
                </Alert>
                </div>
            }
            <div className="itemContainer-table-criterios">
            <Table striped>
                <TableHeader>
                    <TableHeaderRow>
                        <TableHeaderCell style={{textAlign:'center'}}>Clase</TableHeaderCell>
                        <TableHeaderCell style={{textAlign:'center'}}>Valor</TableHeaderCell>
                        <TableHeaderCell style={{textAlign:'center'}}>Acción</TableHeaderCell>
                    </TableHeaderRow>
                </TableHeader>
                <TableBody>
                    {
                        C.map((ah, index) => (
                            <TableRow key={ah.id}>
                                <TableCell style={{minWidth:'140px'}}>
                                    <ContentEditable
                                        id={'criterios_clase_'+ah.id}
                                        html={ah.clase || ''} // innerHTML of the editable div
                                        className='itemContainer-table-criterios-value'
                                        disabled={!isEditable}      // use true to disable editing
                                        onPaste={pastePlainText} // En caso se copie y pegue de otra pagina de internet
                                        onChange={handleChange} // handle innerHTML change
                                        onKeyPress={disableNewlines}  
                                        onFocus={highlightAll}
                                        onBlur={handleBlur}
                                        tagName='div' // Use a custom HTML tag (uses a div by default)
                                    />
                                </TableCell>
                                <TableCell style={{minWidth:'80px'}}>
                                    <ContentEditable
                                        id={'criterios_valor_'+ah.id}
                                        html={ah.valor || ''} // innerHTML of the editable div
                                        className='itemContainer-table-criterios-value'
                                        disabled={!isEditable}      // use true to disable editing
                                        onPaste={pastePlainText} // En caso se copie y pegue de otra pagina de internet
                                        onChange={handleChange} // handle innerHTML change
                                        onKeyPress={disableNewlines}  
                                        onFocus={highlightAll}
                                        onBlur={handleBlur}
                                        tagName='div' // Use a custom HTML tag (uses a div by default)
                                    />
                                </TableCell>
                                <TableCell style={{maxWidth:'140px', textAlign:'center'}}>
                                    {
                                        (parseInt(ah.id)< 0)
                                        ?
                                        <Button extraSmall clear iconPosition="before"
                                            disabled={!isEditable} 
                                            id={'criterios_guardar_'+ah.id}
                                            onClick={(evt) => onClickSaveCriterios(ah.id)}
                                            icon={<SaveIcon size={16} />}
                                        >
                                            Guardar
                                        </Button>
                                        :
                                        <Button extraSmall clear iconPosition="before"
                                            disabled={!isEditable} 
                                            id={'criterios_eliminar_'+ah.id}
                                            onClick={(evt) => onClickRemoveCriterios(ah.id)}
                                            icon={<TrashIcon size={16} />}
                                        >
                                            Eliminar
                                        </Button>
                                    }
                                </TableCell>
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>
            </div>

            <div className="itemContainer-addbtn-criterios">
                <Button iconPosition="before" 
                    disabled={!isEditable || isBtnAddEnable} 
                    id={'criterios_agregar'}
                    onClick={onClickAddCriterios}
                    icon={<PlusIcon size={16} />}
                >
                    Agregar nuevo criterio
                </Button>
            </div>

        </div> 
    );
}
   
export default CriterioCupo;

