import React from "react";

import Accordion, {
    AccordionSection,
    AccordionTitle,
    AccordionContent
} from 'calcite-react/Accordion';

import styled from "styled-components";

const BeneficiarioModulo = styled.div`
    height: auto;
    background: #fff;
    border: 0;
    padding: 0px;
    margin: 4px;
    overflow:hidden;
`;

const AccordionModulo = styled(Accordion)`
    padding:0;
`;
const AccordionTitleModule = styled(AccordionTitle)`
    background-color: #e6e6e6;
    color: #4d4d4d;
    font-size: 1.25em;
    font-weight: 700;
    // border-bottom: 1px solid #d9d9d9;
    // box-shadow: 0px 2px 6px #e1e1e1;
`;


// const BenefFotos = props => {
    class BenefFotos extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
              activeSectionIndexes: []
            }

            this.onAccordionChange = this.onAccordionChange.bind(this);
        }

        onAccordionChange(evt, index) {
            this.state.activeSectionIndexes.includes(index)
            ? this.setState({
                activeSectionIndexes: this.state.activeSectionIndexes.filter(
                item => index !== item,
                ),
            })
            : this.setState({
                activeSectionIndexes: [...this.state.activeSectionIndexes, index],
            })
        }
        
        render() {
            // const { fotos } = this.props;
            return (
                <BeneficiarioModulo>
                    <AccordionModulo
                        onAccordionChange={this.onAccordionChange}
                        activeSectionIndexes={this.state.activeSectionIndexes}
                    >
                        <AccordionSection>
                            <AccordionTitleModule>Fotos</AccordionTitleModule>
                            <AccordionContent>
                                Fotos
                            </AccordionContent>
                        </AccordionSection>
                    </AccordionModulo>
                </BeneficiarioModulo>
            );
        }
    }
//     return <BenefFotos />
// }
export default BenefFotos;

