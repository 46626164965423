import React, {useState, useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateBeneficiarioEducacionenHogar, 
    insertBeneficiarioEducacionenHogar,
    deleteBeneficiarioEducacionenHogar,
    serverMensaje } from '../../redux/reducers/beneficiarios';
import {highlightAll, disableNewlines, pastePlainText } from '../../utils/utils';
import {showToaster} from './BenefShowMesg';
import ContentEditable from 'react-contenteditable';
import Alert, {AlertMessage} from 'calcite-react/Alert';
import Switch from 'calcite-react/Switch';
import Table, {
    TableHeader,
    TableHeaderRow,
    TableHeaderCell,
    TableBody,
    TableRow,
    TableCell
} from 'calcite-react/Table';
import Select from 'calcite-react/Select';
import {MenuItem} from 'calcite-react/Menu';
import Button from 'calcite-react/Button';

import SaveIcon from 'calcite-ui-icons-react/SaveIcon';
import TrashIcon from 'calcite-ui-icons-react/TrashIcon';
import UserPlusIcon from 'calcite-ui-icons-react/UserPlusIcon';

import tipoInstitucion from '../../data/tipoinstitucion.json';
import tipoEducacion from '../../data/tipoeducacion.json';
import tipoJornadaEscolar from '../../data/jornadaescolar.json';


const BenefEduHogar = ({educacionenhogar, beneficiarioId}) => {
    const dispatch = useDispatch();
    const mensaje = useSelector(state => state.beneficiarios.mensaje);
    const users = useSelector(state => state.users);
    // const isEditable = users.user.tipo==='ADMINISTRADOR' ? true : false;
    // const isEditable = (users.user.tipo==='ADMINISTRADOR' && users.user.role==='A' && users.user.permisos==='A') ? true : false;
    const isEditable = (users.user.areatrabajo==='ADMINISTRATIVA' || users.user.areatrabajo==='TRABAJO SOCIAL' || users.user.areatrabajo==='COORDINACIÓN' || users.user.areatrabajo==='PSICOLOGÍA' ) ? true : false;
    // const canView = (users.user.areatrabajo==='ADMINISTRATIVA' || users.user.areatrabajo==='TECNOLOGÍA' ) ? true : false;
    
    const [isBtnAddEnable, setIsBtnAddEnable] = useState(false);
    const [error, setError] = useState(null);
    const [EH, updateEH] = useState([...educacionenhogar]); //Object.assign({}, educacionenhogar); // Arreglos no Objects

    useEffect(() => {

        if (mensaje.educacionenhogar && mensaje.educacionenhogar!=='') {
            const insertEducacionenHogar = (id, f, v) => {
                if (EH){
                    updateEH(EH.map((itm, index) => {
                        if (itm.id===id){
                            itm[f] = v;
                        }
                        return itm;
                    }));
                    mensaje.educacionenhogar='';
                    // dispatch(serverMensaje({educacionenhogar:''}));
                }
            };

            if (typeof mensaje.educacionenhogar === 'string' && mensaje.educacionenhogar.toLowerCase().includes('error')){
                showToaster('error', 'Ocurrió un fallo en el servidor', mensaje.educacionenhogar);
            } 
            else {
                showToaster('success',"Educacion en el Hogar del Beneficiario", mensaje.educacionenhogar.split('.')[0]);
                // Update Id for edition
                // Cambiar el id por el que devuelve el servidor o transacción de MySQL
                insertEducacionenHogar('-1', 'id', String(mensaje.educacionenhogar.split('.')[1]));
            }
        }

    }, [mensaje.educacionenhogar, updateEH, EH, dispatch]);
    
    const updateEducacionenHogar = (id, f, v) => {
        if (EH){
            updateEH(EH.map((itm, index) => {
                if (itm.id===id){
                    itm[f] = v;
                }
                return itm;
            }));
        }
    };

    const updateDataonServer = (id, action, newItem) =>{
        dispatch(serverMensaje({educacionenhogar:''}));
        switch (action) {
            case 'insert':
                if (parseInt(id)< 0 && newItem){
                    // console.log('insertando nuevo registro: ', newItem);
                    setIsBtnAddEnable(true);
                }
                break;
            case 'save':
                if (id){
                    const reg = EH.filter(itm => itm.id===id);
                    if (reg && reg.length>0){
                        dispatch(insertBeneficiarioEducacionenHogar( { token: users.user.claveAPI, eduhogar:reg[0]}));
                        setIsBtnAddEnable(false);
                    }
                }
                break;
            case 'remove':
                if (id){
                    const reg = EH.filter(itm => itm.id===id);
                    if (reg && reg.length>0){
                        dispatch(deleteBeneficiarioEducacionenHogar( { token: users.user.claveAPI, eduhogar:reg[0]}));
                    }
                }
                break;
            default: // update
                // Actualizar solo el registro necesario, para no enviar toda la tabla
                if (id && parseInt(id) > 0){
                    const reg = EH.filter(itm => itm.id===id);
                    if (reg && reg.length>0){
                        dispatch(updateBeneficiarioEducacionenHogar( { token: users.user.claveAPI, eduhogar:reg[0]}));
                    }
                }
                break;
        }
    }


    const replaceSomeText = (s) =>{
        return s
            .replace(/&nbsp;/g, ' ')
            .replace(/<br>/g, '')
            .replace(/&amp;/g, '&')
            .replace(/&gt;/g, '>')
            .replace(/&lt;/g, '<').trim()
    }

    const validateInput = (f, v, final) => {
        const regex_onlynum = /^[0-9]*$/i; ///[0-9,]|\./;
        // const regex_nombres = /^['`´a-zA-ZÀ-ÿ\u00C0-\u017F\s]+$/;
        let new_val = null;
        let err = null;
        switch (f) {
            case 'nombre':
                new_val = v.substr(0,120);
                if (final)
                    new_val = replaceSomeText(v).substr(0,120);
                break;
            case 'tipo':
                new_val = v.substr(0,32);
                if (final)
                    new_val = replaceSomeText(v).substr(0,32);
                break;
            case 'grado':
                new_val = replaceSomeText(v);
                if (!regex_onlynum.test(new_val)) {
                    err = 'Dígite solo números válidos';
                    new_val = null;
                }
                if (new_val===null) new_val = '';
                break;
            case 'nivel':
                new_val = v.substr(0,32);
                if (final)
                    new_val = replaceSomeText(v).substr(0,32);
                break;
            case 'jornada':
                new_val = v.substr(0,32);
                if (final)
                    new_val = replaceSomeText(v).substr(0,32);
                break;
            case 'menordeedad': // Valida cuando el campo es de texto y no un componente Switch
                new_val = v;
                if (!regex_onlynum.test(v)) {
                    err = 'Dígite un solo número válido';
                    new_val = null;
                } else if(parseInt(v)>1){
                    err = 'Dígite un número válido';
                    new_val = null;
                }
                break;
            // case 'comentarios':
            //     new_val = replaceSomeText(v); //.substr(0,255);
            //     break;
            default:
                break;
        }
        setError(err);
        return new_val
    }

    const handleTipoSelectChange = (value, id) => {
        // EH['tipo'] = value;
        updateEducacionenHogar(id,'tipo', value );
        updateDataonServer(id);
    };
    const handleNivelSelectChange = (value, id) => {
        // EH['nivel'] = value;
        updateEducacionenHogar(id,'nivel', value );
        updateDataonServer(id);
    };
    const handleJornadaSelectChange = (value, id) => {
        // EH['jornada'] = value;
        updateEducacionenHogar(id,'jornada', value );
        updateDataonServer(id);
    };
    const onChangeMenorEdad = (value, id) => {
        const new_val =  value ? '1' : '0';
        EH['menordeedad'] =  new_val;
        updateEducacionenHogar(id,'menordeedad', new_val);
        updateDataonServer(id);
    }
    
    const handleChange = evt => {
        const [, field, id] = evt.currentTarget.id.split('_');
        const new_val = validateInput(field, evt.target.value, false);
        if(new_val){
            updateEducacionenHogar(id,field, new_val );
            // console.log("tabla, field, id", tabla, field, id, new_val);
        };

    };
    const handleBlur = evt => {
        const val = evt.currentTarget.innerHTML;
        const [,field, id] = evt.target.id.split('_');
        const new_val = validateInput(field, val, true)
        if(new_val || new_val===''){
            // console.log("tabla, field, id", tabla, field, id, val);
            updateEducacionenHogar(id,field, new_val );
            updateDataonServer(id);
        };
    };

    const onClickAddEducacionenHogar = () => {
        const newId = -1;
        const nuevo_eduhogar = {
            "id": String(newId),
            "beneficiarioId": beneficiarioId,
            "nombre": "",
            "tipo": "",
            "grado": "0",
            "nivel": "",
            "menordeedad": "0",
            // "version": "2020-06-03 10:03:03"
        }
        updateEH([...EH].concat(nuevo_eduhogar) );
        updateDataonServer(newId, 'insert', nuevo_eduhogar);
    }
    const onClickSaveEducacionenHogar = (id) => {
        updateDataonServer(id, 'save');
    }
    const onClickRemoveEducacionenHogar = (id) => {
        updateEH([...EH].filter(itm => itm.id!==id));
        updateDataonServer(id, 'remove');
    }
    

    return (
        <div>
            { error && <div style={{color: 'red', width: '100%'}}>
                <Alert red showIcon style={{minWidth: '100%', marginBottom: '10px'}}
                    showCloseLabel 
                    onClose={() => setError(null)}>
                    <AlertMessage>
                        Error:{' '}
                        <strong>{error}</strong>
                    </AlertMessage>
                </Alert>
                </div>
            }
        <div className="itemContainer-table-educacionenhogar">
        <Table striped>
            <TableHeader>
                <TableHeaderRow>
                    <TableHeaderCell style={{textAlign:'center'}}>Tipo Institución</TableHeaderCell>
                    <TableHeaderCell style={{textAlign:'center'}}>Grado</TableHeaderCell>
                    <TableHeaderCell style={{textAlign:'center'}}>Nivel</TableHeaderCell>
                    <TableHeaderCell style={{textAlign:'center'}}>Jornada</TableHeaderCell>
                    <TableHeaderCell style={{textAlign:'center'}}>Menor</TableHeaderCell>
                    <TableHeaderCell style={{textAlign:'center'}}>Acción</TableHeaderCell>
                </TableHeaderRow>
            </TableHeader>
            <TableBody>
                {
                    EH.map((eh, index) => (
                        <TableRow key={eh.id}>
                            <TableCell style={{maxWidth:'150px'}}>
                                <Select filterable positionFixed disabled={!isEditable}
                                    id={'educacionenhogar_tipo_'+eh.id}
                                    className={'itemContainer-table-selects'}
                                    onChange={(value, item) => handleTipoSelectChange(value, eh.id)}
                                    selectedValue={eh.tipo.trim()}
                                >
                                {tipoInstitucion.tipo.map(t => {
                                    return (
                                    <MenuItem key={t.id} value={t.name}>
                                        {t.name}
                                    </MenuItem>
                                    )
                                })}
                                </Select>
                            </TableCell>
                            <TableCell style={{minWidth:'60px'}}>
                                <ContentEditable
                                    id={'educacionenhogar_grado_'+eh.id}
                                    html={eh.grado || ''} // innerHTML of the editable div
                                    className='itemContainer-value-table'
                                    disabled={!isEditable}      // use true to disable editing
                                    onPaste={pastePlainText} // En caso se copie y pegue de otra pagina de internet
                                    onChange={handleChange} // handle innerHTML change
                                    onKeyPress={disableNewlines}  
                                    onFocus={highlightAll}
                                    onBlur={handleBlur}
                                    tagName='div' // Use a custom HTML tag (uses a div by default)
                                />
                            </TableCell>
                            <TableCell style={{maxWidth:'120px'}}>
                                <Select filterable positionFixed disabled={!isEditable}
                                    id={'educacionenhogar_nivel_'+eh.id}
                                    className={'itemContainer-table-selects'}
                                    onChange={(value, item) => handleNivelSelectChange(value, eh.id)}
                                    selectedValue={eh.nivel.trim()}
                                >
                                {tipoEducacion.tipoeducacion.map(t => {
                                    return (
                                    <MenuItem key={t.id} value={t.name}>
                                        {t.name}
                                    </MenuItem>
                                    )
                                })}
                                </Select>
                            </TableCell>
                            <TableCell style={{maxWidth:'120px'}}>
                                <Select filterable positionFixed disabled={!isEditable}
                                    id={'educacionenhogar_jornada_'+eh.id}
                                    className={'itemContainer-table-selects'}
                                    onChange={(value, item) => handleJornadaSelectChange(value, eh.id)}
                                    selectedValue={eh.jornada.trim()}
                                >
                                {tipoJornadaEscolar.jornada.map(j => {
                                    return (
                                    <MenuItem key={j.id} value={j.name}>
                                        {j.name}
                                    </MenuItem>
                                    )
                                })}
                                </Select>
                            </TableCell>
                            <TableCell style={{minWidth:'140px'}}>
                                {/* <ContentEditable
                                    id={'educacionenhogar_menordeedad_'+eh.id}
                                    html={eh.menordeedad || ''} // innerHTML of the editable div
                                    className='itemContainer-value-table'
                                    disabled={!isEditable}      // use true to disable editing
                                    onPaste={pastePlainText} // En caso se copie y pegue de otra pagina de internet
                                    onChange={handleChange} // handle innerHTML change
                                    onKeyPress={disableNewlines}  
                                    onFocus={highlightAll}
                                    onBlur={handleBlur}
                                    tagName='div' // Use a custom HTML tag (uses a div by default)
                                /> */}

                                <Switch 
                                    labelPosition={"before" || '' }
                                    style={{margin: '0 0 0.5rem 0'}}
                                    name={ 'educacionenhogar_menordeedad_' + eh.id } 
                                    checked={ eh.menordeedad==='1' ? true : false} 
                                    disabled={!isEditable} 
                                    onChange={(evt) => onChangeMenorEdad(evt.target.checked, eh.id)} />
                            </TableCell>
                            <TableCell style={{maxWidth:'140px'}}>
                                {
                                    (parseInt(eh.id)< 0)
                                    ?
                                    <Button extraSmall clear iconPosition="before"
                                        disabled={!isEditable} 
                                        id={'educacionenhogar_guardar_'+eh.id}
                                        // style={{margin: '0 0 0.5rem 0'}}
                                        onClick={(evt) => onClickSaveEducacionenHogar(eh.id)}
                                        icon={<SaveIcon size={16} />}
                                    >
                                        Guardar
                                    </Button>
                                    :
                                    <Button extraSmall clear iconPosition="before"
                                        disabled={!isEditable} 
                                        id={'educacionenhogar_eliminar_'+eh.id}
                                        onClick={(evt) => onClickRemoveEducacionenHogar(eh.id)}
                                        icon={<TrashIcon size={16} />}
                                    >
                                        Eliminar
                                    </Button>
                                }
                            </TableCell>
                        </TableRow>
                    ))
                }
            </TableBody>
        </Table>
        </div>

            <div className="">
                <Button iconPosition="before" 
                    disabled={!isEditable || isBtnAddEnable} 
                    id={'educacionenhogar_agregar'}
                    onClick={onClickAddEducacionenHogar}
                    icon={<UserPlusIcon size={16} />}
                >
                    Agregar nuevo estudiante
                </Button>
            </div>
            
        </div> 
    );
}
   
export default BenefEduHogar;

