import React, {useState, useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateBeneficiarioAhorradores, 
    insertBeneficiarioAhorradores,
    deleteBeneficiarioAhorradores,
    serverMensaje } from '../../redux/reducers/beneficiarios';
import {highlightAll, disableNewlines, pastePlainText } from '../../utils/utils';
import {showToaster} from './BenefShowMesg';
import ContentEditable from 'react-contenteditable';
import Alert, {AlertMessage} from 'calcite-react/Alert';
import Table, {
    TableHeader,
    TableHeaderRow,
    TableHeaderCell,
    TableBody,
    TableRow,
    TableCell
} from 'calcite-react/Table';
import Select from 'calcite-react/Select';
import {MenuItem} from 'calcite-react/Menu';
import Button from 'calcite-react/Button';

import SaveIcon from 'calcite-ui-icons-react/SaveIcon';
import TrashIcon from 'calcite-ui-icons-react/TrashIcon';
import UserPlusIcon from 'calcite-ui-icons-react/UserPlusIcon';

import tipoParentescos from '../../data/parentesco.json';
import tipoFrecuencias from '../../data/frecuencia_object.json';


const BenefAhorradores = ({ahorradores, beneficiarioId}) => {
    const dispatch = useDispatch();
    const mensaje = useSelector(state => state.beneficiarios.mensaje);
    const users = useSelector(state => state.users);
    // const isEditable = users.user.tipo==='ADMINISTRADOR' ? true : false;
    const isEditable = (users.user.areatrabajo==='ADMINISTRATIVA' || users.user.areatrabajo==='TRABAJO SOCIAL' || users.user.areatrabajo==='COORDINACIÓN' || users.user.areatrabajo==='PSICOLOGÍA' ) ? true : false;
    const [isBtnAddEnable, setIsBtnAddEnable] = useState(false);
    const [error, setError] = useState(null);
    const [A, updateA] = useState([...ahorradores]); //Object.assign({}, ahorradores); // Arreglos no Objects

    useEffect(() => {

        if (mensaje.ahorradores && mensaje.ahorradores!=='') {
            const insertAhorradores = (id, f, v) => {
                if (A){
                    updateA(A.map((itm, index) => {
                        if (itm.id===id){
                            itm[f] = v;
                        }
                        return itm;
                    }));
                    mensaje.ahorradores='';
                    // dispatch(serverMensaje({ahorradores:''}));
                }
            };

            if (typeof mensaje.ahorradores === 'string' && mensaje.ahorradores.toLowerCase().includes('error')){
                showToaster('error', 'Ocurrió un fallo en el servidor', mensaje.ahorradores);
            } 
            else {
                showToaster('success',"Ahorradores del Beneficiario", mensaje.ahorradores.split('.')[0]);
                // Update Id for edition
                // Cambiar el id por el que devuelve el servidor o transacción de MySQL
                insertAhorradores('-1', 'id', String(mensaje.ahorradores.split('.')[1]));
            }
        }

    }, [mensaje.ahorradores, updateA, A, dispatch]);
    
    const updateAhorradores = (id, f, v) => {
        if (A){
            updateA(A.map((itm, index) => {
                if (itm.id===id){
                    itm[f] = v;
                }
                return itm;
            }));
        }
    };

    const updateDataonServer = (id, action, newItem) =>{
        dispatch(serverMensaje({ahorradores:''}));
        switch (action) {
            case 'insert':
                if (parseInt(id)< 0 && newItem){
                    // console.log('insertando nuevo registro: ', newItem);
                    setIsBtnAddEnable(true);
                }
                break;
            case 'save':
                if (id){
                    const reg = A.filter(itm => itm.id===id);
                    if (reg && reg.length>0){
                        dispatch(insertBeneficiarioAhorradores( { token: users.user.claveAPI, ahorradores:reg[0]}));
                        setIsBtnAddEnable(false);
                    }
                }
                break;
            case 'remove':
                if (id){
                    const reg = A.filter(itm => itm.id===id);
                    if (reg && reg.length>0){
                        dispatch(deleteBeneficiarioAhorradores( { token: users.user.claveAPI, ahorradores:reg[0]}));
                    }
                }
                break;
            default: // update
                // Actualizar solo el registro necesario, para no enviar toda la tabla
                if (id && parseInt(id) > 0){
                    const reg = A.filter(itm => itm.id===id);
                    if (reg && reg.length>0){
                        dispatch(updateBeneficiarioAhorradores( { token: users.user.claveAPI, ahorradores:reg[0]}));
                    }
                }
                break;
        }
    }

    const replaceSomeText = (s) =>{
        return s
            .replace(/&nbsp;/g, ' ')
            .replace(/<br>/g, '')
            .replace(/&amp;/g, '&')
            .replace(/&gt;/g, '>')
            .replace(/&lt;/g, '<').trim()
    }

    const validateInput = (f, v, final) => {
        const regex_onlynum = /^[0-9]*$/i; ///[0-9,]|\./;
        // const regex_nombres = /^['`´a-zA-ZÀ-ÿ\u00C0-\u017F\s]+$/;
        let new_val = null;
        let err = null;
        switch (f) {
            case 'nombre':
                new_val = v.substr(0,120);
                if (final)
                    new_val = replaceSomeText(v).substr(0,120);
                break;
            case 'parentesco':
                new_val = v.substr(0,32);
                if (final)
                    new_val = replaceSomeText(v).substr(0,32);
                break;
            case 'frecuencia':
                new_val = v.substr(0,32);
                if (final)
                    new_val = replaceSomeText(v).substr(0,32);
                break;
            case 'dondeguardan':
                new_val = v.substr(0,32);
                if (final)
                    new_val = replaceSomeText(v).substr(0,32);
                break;
            case 'valor':
                new_val = replaceSomeText(v);
                if (!regex_onlynum.test(new_val)) {
                    err = 'Dígite solo números válidos';
                    new_val = null;
                }
                if (new_val===null) new_val = '';
                break;
            // case 'comentarios':
            //     new_val = replaceSomeText(v); //.substr(0,255);
            //     break;
            default:
                break;
        }
        setError(err);
        return new_val
    }

    const handleParentescoSelectChange = (value, id) => {
        A['parentesco'] = value;
        updateAhorradores(id,'parentesco', value );
        updateDataonServer(id);
    };

    const handleFrecuenciaSelectChange = (value, id) => {
        A['frecuencia'] = value;
        updateAhorradores(id,'frecuencia', value );
        updateDataonServer(id);
    };

    const handleChange = evt => {
        const [, field, id] = evt.currentTarget.id.split('_');
        const new_val = validateInput(field, evt.target.value, false);
        if(new_val){
            updateAhorradores(id,field, new_val );
            // console.log("tabla, field, id", tabla, field, id, new_val);
        };

    };
    const handleBlur = evt => {
        const val = evt.currentTarget.innerHTML;
        const [,field, id] = evt.target.id.split('_');
        const new_val = validateInput(field, val, true)
        if(new_val || new_val===''){
            // console.log("tabla, field, id", tabla, field, id, val);
            updateAhorradores(id,field, new_val );
            updateDataonServer(id);
        };
    };

    const onClickAddAhorradores = () => {
        const newId = -1;
        const nuevo_ahorrador = {
            "id": String(newId),
            "beneficiarioId": beneficiarioId,
            "nombre": "",
            "parentesco": "",
            "valor": "0",
            "frecuencia": "",
            "dondeguardan": "",
            // "version": "2020-06-03 10:03:03"
        }
        updateA([...A].concat(nuevo_ahorrador) );
        updateDataonServer(newId, 'insert', nuevo_ahorrador);
    }
    const onClickSaveAhorradores = (id) => {
        updateDataonServer(id, 'save');
    }
    const onClickRemoveAhorradores = (id) => {
        updateA([...A].filter(itm => itm.id!==id));
        updateDataonServer(id, 'remove');
    }

    return (
        <div>
            { error && <div style={{color: 'red', width: '100%'}}>
                <Alert red showIcon style={{minWidth: '100%', marginBottom: '10px'}}
                    showCloseLabel 
                    onClose={() => setError(null)}>
                    <AlertMessage>
                        Error:{' '}
                        <strong>{error}</strong>
                    </AlertMessage>
                </Alert>
                </div>
            }
         <div className="itemContainer-table-ahorradores">
        <Table striped>
            <TableHeader>
                <TableHeaderRow>
                    <TableHeaderCell style={{textAlign:'center'}}>Persona</TableHeaderCell>
                    <TableHeaderCell style={{textAlign:'center'}}>Valor</TableHeaderCell>
                    <TableHeaderCell style={{textAlign:'center'}}>Frecuencia</TableHeaderCell>
                    <TableHeaderCell style={{textAlign:'center'}}>Lugar</TableHeaderCell>
                    <TableHeaderCell style={{textAlign:'center'}}>Acción</TableHeaderCell>
                </TableHeaderRow>
            </TableHeader>
            <TableBody>
                {
                    A.map((ah, index) => (
                        <TableRow key={ah.id}>
                            {/* <TableCell>{ah.parentesco}</TableCell> */}
                            <TableCell style={{maxWidth:'120px'}}>
                                <Select filterable positionFixed disabled={!isEditable} 
                                    id={'ahorradores_parentesco_'+ah.id}
                                    className={'itemContainer-table-selects'}
                                    onChange={(value, item) => handleParentescoSelectChange(value, ah.id)}
                                    selectedValue={ah.parentesco.trim()}
                                >
                                {tipoParentescos.tipo.map(p => {
                                    return (
                                    <MenuItem key={p.id} value={p.name}>
                                        {p.name}
                                    </MenuItem>
                                    )
                                })}
                                </Select>
                            </TableCell>
                            <TableCell style={{minWidth:'80px'}}>
                                {/* {ah.valor} */}
                                <ContentEditable
                                    id={'ahorradores_valor_'+ah.id}
                                    // innerRef={agendoEditable}
                                    html={ah.valor || ''} // innerHTML of the editable div
                                    // style={{textAlign:'center'}}
                                    className='itemContainer-value-table'
                                    disabled={!isEditable}      // use true to disable editing
                                    onPaste={pastePlainText} // En caso se copie y pegue de otra pagina de internet
                                    onChange={handleChange} // handle innerHTML change
                                    onKeyPress={disableNewlines}  
                                    onFocus={highlightAll}
                                    onBlur={handleBlur}
                                    tagName='div' // Use a custom HTML tag (uses a div by default)
                                />
                            </TableCell>
                            <TableCell style={{maxWidth:'120px'}}>
                                {/* {ah.frecuencia} */}
                                {/* <ContentEditable
                                    id={'ahorradores_frecuencia_'+ah.id}
                                    html={ah.frecuencia || ''} // innerHTML of the editable div
                                    className='itemContainer-value-table'
                                    disabled={!isEditable}      // use true to disable editing
                                    onPaste={pastePlainText} // En caso se copie y pegue de otra pagina de internet
                                    onChange={handleChange} // handle innerHTML change
                                    onKeyPress={disableNewlines}  
                                    onFocus={highlightAll}
                                    onBlur={handleBlur}
                                    tagName='div' // Use a custom HTML tag (uses a div by default)
                                /> */}

                                <Select filterable positionFixed disabled={!isEditable} 
                                    id={'ahorradores_frecuencia_'+ah.id}
                                    className={'itemContainer-table-selects'}
                                    onChange={(value, item) => handleFrecuenciaSelectChange(value, ah.id)}
                                    selectedValue={ah.frecuencia.trim()}
                                >
                                {tipoFrecuencias.frecuencias.map(f => {
                                    return (
                                    <MenuItem key={f.id} value={f.name}>
                                        {f.name}
                                    </MenuItem>
                                    )
                                })}
                                </Select>
                            </TableCell>
                            <TableCell style={{minWidth:'140px'}}>
                                <ContentEditable
                                    id={'ahorradores_dondeguardan_'+ah.id}
                                    html={ah.dondeguardan || ''} // innerHTML of the editable div
                                    // style={{textAlign:'center'}}
                                    className='itemContainer-value-table'
                                    disabled={!isEditable}      // use true to disable editing
                                    onPaste={pastePlainText} // En caso se copie y pegue de otra pagina de internet
                                    onChange={handleChange} // handle innerHTML change
                                    onKeyPress={disableNewlines}  
                                    onFocus={highlightAll}
                                    onBlur={handleBlur}
                                    tagName='div' // Use a custom HTML tag (uses a div by default)
                                />
                            </TableCell>
                            <TableCell style={{maxWidth:'140px'}}>
                                    {
                                        (parseInt(ah.id)< 0)
                                        ?
                                        <Button extraSmall clear iconPosition="before"
                                            disabled={!isEditable} 
                                            id={'ahorradores_guardar_'+ah.id}
                                            onClick={(evt) => onClickSaveAhorradores(ah.id)}
                                            icon={<SaveIcon size={16} />}
                                        >
                                            Guardar
                                        </Button>
                                        :
                                        <Button extraSmall clear iconPosition="before"
                                            disabled={!isEditable} 
                                            id={'ahorradores_eliminar_'+ah.id}
                                            onClick={(evt) => onClickRemoveAhorradores(ah.id)}
                                            icon={<TrashIcon size={16} />}
                                        >
                                            Eliminar
                                        </Button>
                                    }
                                </TableCell>
                        </TableRow>
                    ))
                }
            </TableBody>
        </Table>
        </div>

            <div className="">
                <Button iconPosition="before" 
                    disabled={!isEditable || isBtnAddEnable} 
                    id={'ahorradores_agregar'}
                    onClick={onClickAddAhorradores}
                    icon={<UserPlusIcon size={16} />}
                >
                    Agregar nuevo aportante
                </Button>
            </div>

        </div> 
    );
}
   
export default BenefAhorradores;

