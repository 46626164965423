import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { filterBeneficiariosDataxCovid } from '../redux/reducers/covid19';
import {RemoveDuplicates, getDuplicates} from '../utils/utils';
import moment from 'moment';
import 'moment/locale/es-us';

import List, {
  ListItem,
  ListHeader,
  ListItemTitle,
  ListItemSubtitle
} from 'calcite-react/List';

// Styled & Motion Components
import styled from "styled-components";

import covidbonos_Icon from '../assets/icons/bonos.png';
import covidmercados_Icon from '../assets/icons/carrocompras.png';
// import benefactivos_Icon from '../assets/icons/benef_activos_green.png';
// import benefnoactivos_Icon from '../assets/icons/benef_noactivos_red.png';
import limpiarfiltro_Icon from '../assets/icons/filter_clear.png'; 

// Actividad
import caddieIcon from './beneficiario/imgs/caddie_icon_b.png';
import boleroIcon from './beneficiario/imgs/bolero_icon_b.png';
import boleadorIcon from './beneficiario/imgs/boleador_icon_b.png';
import monitorIcon from './beneficiario/imgs/monitor_icon_b.png';

import ChevronDownIcon from 'calcite-ui-icons-react/ChevronDownIcon';
import ChevronRightIcon from 'calcite-ui-icons-react/ChevronRightIcon';
import UsersIcon from 'calcite-ui-icons-react/UsersIcon';
import UIcon from 'calcite-ui-icons-react/UserIcon';

const AyudaBonosIcon = styled.div`
  width: 16px;
  height: 16px;
  background: rgba(255, 255, 255, 0.1) url(${covidbonos_Icon}) no-repeat center/cover;
`;
const AyudaMercadoIcon = styled.div`
  width: 16px;
  height: 16px;
  background: rgba(255, 255, 255, 0.1) url(${covidmercados_Icon}) no-repeat center/cover;
`;

// const BenefActivosIcon = styled.div`
//   width: 16px;
//   height: 16px;
//   background: rgba(255, 255, 255, 0.1) url(${benefactivos_Icon}) no-repeat center/cover;
// `;
// const BenefNoActivosIcon = styled.div`
//   width: 16px;
//   height: 16px;
//   background: rgba(255, 255, 255, 0.1) url(${benefnoactivos_Icon}) no-repeat center/cover;
// `;
const CaddieIcon = styled.div`
  width: 20px;
  height: 20px;
  background: rgba(255, 255, 255, 0.1) url(${caddieIcon}) no-repeat center/cover;
  opacity: 0.7;
  // border-radius: 50%;
  // background-color: #249507;
`;
const BoleroIcon = styled.div`
  width: 20px;
  height: 20px;
  background: rgba(255, 255, 255, 0.1) url(${boleroIcon}) no-repeat center/cover;
  opacity: 0.7;
  // border-radius: 50%;
  // background-color: #249507;
`;
const BoleadorIcon = styled.div`
  width: 20px;
  height: 20px;
  background: rgba(255, 255, 255, 0.1) url(${boleadorIcon}) no-repeat center/cover;
  opacity: 0.7;
  // border-radius: 50%;
  // background-color: #249507;
`;
const MonitorIcon = styled.div`
  width: 20px;
  height: 20px;
  background: rgba(255, 255, 255, 0.1) url(${monitorIcon}) no-repeat center/cover;
  opacity: 0.7;
  // border-radius: 50%;
  // background-color: #249507;
`;
const UsuarioIcon = <UIcon style={{ width: '20px', height: '20px' }} />
const FiltroButton = styled.div`
  width: 16px;
  height: 16px;
  background: rgba(255, 255, 255, 0.1) url(${limpiarfiltro_Icon}) no-repeat center/cover;
  opacity: 0.4;

  &:hover {
    opacity: 1.0;
  }
`;
const FiltroIcon = <FiltroButton />

const FilterCovidAyudas = props => {
  const dispatch = useDispatch();
  const listBenef = useSelector(state => state.covid19.listBenef);
  const [listBenefDates, setListBenefDates] = useState([]);
  const filter = useSelector(state => state.covid19.filter);
  const [, setLastFilter] = useState([]);
  // const isCollapsed = useSelector(state => state.modulos.collapsed); // ActionBar Left
  // const activeAction = useSelector(state => state.modulos.activeModule); // Module name
  const [statsBeneficiario, setStatsBeneficiario] = useState({});
  // const [openTotal, setOpenTotal] = useState(false);
  const [openTipoAyuda, setOpenTipoAyuda] = useState(false);
  const [openEstadoAyuda, setOpenEstadoAyuda] = useState(false);
  const [openAfectacion, setOpenAfectacion] = useState(false);
  const [openValorAyuda, setOpenValorAyuda] = useState(false);
  const [openPrestarServicio, setOpenPrestarServicio] = useState(false);
  const [openAcuerdoCorresp, setOpenAcuerdoCorresp] = useState(false);
  const [openJefeHogar, setOpenJefeHogar] = useState(false);
  const [openPersonasaCargo, setOpenPersonasaCargo] = useState(false);
  const [openEntrega, setOpenEntrega] = useState(false);
  const [openEstado, setOpenEstado] = useState(false);
  const [openActividad, setOpenActividad] = useState(false);
  const [openCategoria, setOpenCategoria] = useState(false);
  const [openActualizado, setOpenActualizado] = useState(false);
  const [openEdad, setOpenEdad] = useState(false);
  const [openEdadClub, setOpenEdadClub] = useState(false);
  
  // let arrowLeftIconTotal = (openTotal) ? <ChevronDownIcon size={16} /> : <ChevronRightIcon size={16} />;
  let arrowLeftIconTipoAyuda = (openTipoAyuda) ? <ChevronDownIcon size={16} /> : <ChevronRightIcon size={16} />;
  let arrowLeftIconEstadoAyuda = (openEstadoAyuda) ? <ChevronDownIcon size={16} /> : <ChevronRightIcon size={16} />;
  let arrowLeftIconAfectacion = (openAfectacion) ? <ChevronDownIcon size={16} /> : <ChevronRightIcon size={16} />;
  let arrowLeftIconPrestarServicio = (openPrestarServicio) ? <ChevronDownIcon size={16} /> : <ChevronRightIcon size={16} />;
  let arrowLeftIconAcuerdoCorresp = (openAcuerdoCorresp) ? <ChevronDownIcon size={16} /> : <ChevronRightIcon size={16} />;
  let arrowLeftIconJefeHogar = (openJefeHogar) ? <ChevronDownIcon size={16} /> : <ChevronRightIcon size={16} />;
  let arrowLeftIconPersonasaCargo = (openPersonasaCargo) ? <ChevronDownIcon size={16} /> : <ChevronRightIcon size={16} />;
  let arrowLeftIconEntrega = (openEntrega) ? <ChevronDownIcon size={16} /> : <ChevronRightIcon size={16} />;
  let arrowLeftIconEstado = (openEstado) ? <ChevronDownIcon size={16} /> : <ChevronRightIcon size={16} />;
  let arrowLeftIconActividad =  (openActividad) ? <ChevronDownIcon size={16} /> : <ChevronRightIcon size={16} />;
  let arrowLeftIconCategoria =  (openCategoria) ? <ChevronDownIcon size={16} /> : <ChevronRightIcon size={16} />;
  let arrowLeftIconActualizado = (openActualizado) ? <ChevronDownIcon size={16} /> : <ChevronRightIcon size={16} />;
  let arrowLeftIconEdad = (openEdad) ? <ChevronDownIcon size={16} /> : <ChevronRightIcon size={16} />;
  let arrowLeftIconEdadClub = (openEdadClub) ? <ChevronDownIcon size={16} /> : <ChevronRightIcon size={16} />;
  let arrowLeftIconValorAyuda = (openValorAyuda) ? <ChevronDownIcon size={16} /> : <ChevronRightIcon size={16} />;
  
  // Stats
  useEffect(() => {
    if (listBenef && listBenef.length<=0){
      return
    }
    
    if (listBenefDates.length<=0){
      // setListBenefDates([...listBenef].map(d => {
      //   // d.actualizacion = new Date(d.actualizacion);
      //   return d
      // }));
      setListBenefDates([...listBenef].filter(itm => itm.docId!==null));
    }

  }, [listBenef, listBenefDates]);
  // Stats
  useEffect(() => {
    if (listBenefDates && listBenefDates.length<=0){
      return
    }
    
    let datos = [];
    if (filter && filter.length>0){
        datos = [...filter];
    } else {
        datos = [...listBenefDates];
    }
    datos = [...datos].filter(itm => itm.docId!==null);
    // General
    const total = datos.length;
    const activos = datos.filter(b => b.activo==='1');
    const noactivos = datos.filter(b => b.activo==='0');
    // Tipo Ayuda
    const bonos = datos.filter(b => b.tipoayuda.toLowerCase()==='bono');
    const mercados = datos.filter(b => b.tipoayuda.toLowerCase()==='mercado');
    // Estado Ayuda
    const redimido = datos.filter(b => b.estadoayuda.toLowerCase()==='redimido');
    const caducado = datos.filter(b => b.estadoayuda.toLowerCase()==='caducado');
    
    // Afectación
    const afectacion_alta = datos.filter(b => b.afectacion.toLowerCase()==='alta');
    const afectacion_media = datos.filter(b => b.afectacion.toLowerCase()==='media');
    const afectacion_baja = datos.filter(b => b.afectacion.toLowerCase()==='baja');
    const afectacion_noaplica = datos.filter(b => b.afectacion.toLowerCase()==='no aplica');
    
    // Entregas
    const entrega_1 = datos.filter(b => b.numentrega==='1');
    const entrega_2 = datos.filter(b => b.numentrega==='2');
    const entrega_3 = datos.filter(b => b.numentrega==='3');
    const entrega_4 = datos.filter(b => b.numentrega==='4');
    const entrega_5 = datos.filter(b => b.numentrega==='5');
    const entrega_6 = datos.filter(b => b.numentrega==='6');
    const entrega_7 = datos.filter(b => b.numentrega==='7');
    const entrega_8 = datos.filter(b => b.numentrega==='8');
    const entrega_9 = datos.filter(b => b.numentrega==='9');
    const entrega_10 = datos.filter(b => b.numentrega==='10');

     // Valor Ayuda (Bono o Mercado)
     const valorayuda1 = datos.filter(b => b.valor>=0 && b.valor<=25000);
     const valorayuda2 = datos.filter(b => b.valor>25000 && b.valor<=50000);
     const valorayuda3 = datos.filter(b => b.valor>50000 && b.valor<=75000);
     const valorayuda4 = datos.filter(b => b.valor>75000 && b.valor<=100000);
     const valorayuda5 = datos.filter(b => b.valor>100000 && b.valor<=150000);
     const valorayuda6 = datos.filter(b => b.valor>150000 && b.valor<=200000);
     const valorayuda7 = datos.filter(b => b.valor>200000 && b.valor<=300000);
     const valorayuda8 = datos.filter(b => b.valor>300000 && b.valor<=400000);
     const valorayuda9 = datos.filter(b => b.valor>400000 && b.valor<=500000);
     const valorayuda10 = datos.filter(b => b.valor>500000);

    // Para manejar las estadisticas
    const datos_unique = RemoveDuplicates(datos, 'docId');
    // const datos_unique = RemoveEntregasDuplicadas(datos, 'docId', 'numentrega'); 
    
    // Prestar Servicio
    const prestarservicio = datos_unique.filter(b => b.prestarservicio==='1');
    const noprestarservicio = datos_unique.filter(b => b.prestarservicio==='0');

    // En Acuerdo de corresponsabilidad
    const acuerdocorresp = datos_unique.filter(b => b.acuerdocorresp==='1');
    const noacuerdocorresp = datos_unique.filter(b => b.acuerdocorresp==='0');

    // Jefe hogar
    const jefehogar = datos_unique.filter(b => b.jefehogar.toLowerCase()==='si');
    const nojefehogar = datos_unique.filter(b => b.jefehogar.toLowerCase()==='no');
    const jefehogarcomp = datos_unique.filter(b => b.jefehogar.toLowerCase()==='compartido');

    // Personas a Cargo
    const personasacargo_0 = datos_unique.filter(b => b.personasacargo===0);
    const personasacargo_1 = datos_unique.filter(b => b.personasacargo===1);
    const personasacargo_2 = datos_unique.filter(b => b.personasacargo===2);
    const personasacargo_3 = datos_unique.filter(b => b.personasacargo===3);
    const personasacargo_4 = datos_unique.filter(b => b.personasacargo===4);
    const personasacargo_5 = datos_unique.filter(b => b.personasacargo===5);
    const personasacargo_5plus = datos_unique.filter(b => b.personasacargo>5);
    const personasacargo_6 = datos_unique.filter(b => b.personasacargo===6);
    const personasacargo_7 = datos_unique.filter(b => b.personasacargo===7);
    const personasacargo_8 = datos_unique.filter(b => b.personasacargo===8);
    const personasacargo_9 = datos_unique.filter(b => b.personasacargo===9);
    const personasacargo_10 = datos_unique.filter(b => b.personasacargo>=10);

    // Estado
    const actualizado = datos_unique.filter(b => b.estado.toLowerCase()==='actualizado');
    const pendiente = datos_unique.filter(b => b.estado.toLowerCase()==='pendiente');
    const visitar = datos_unique.filter(b => b.estado.toLowerCase()==='visitar');
    const revisar = datos_unique.filter(b => b.estado.toLowerCase()==='revisar');
    const prueba = datos_unique.filter(b => b.estado.toLowerCase()==='prueba');
    const retirado = datos_unique.filter(b => b.estado.toLowerCase()==='retirado');
    const noapto = datos_unique.filter(b => b.estado.toLowerCase()==='no apto');
    const suspendido = datos_unique.filter(b => b.estado.toLowerCase()==='suspendido');
    // Actividad
    const caddie = datos_unique.filter(b => b.actividad.toLowerCase()==='caddie');
    const bolero = datos_unique.filter(b => b.actividad.toLowerCase()==='bolero');
    const boleador = datos_unique.filter(b => b.actividad.toLowerCase()==='boleador');
    const monitor = datos_unique.filter(b => b.actividad.toLowerCase()==='monitor');
    const entrenador = datos_unique.filter(b => b.actividad.toLowerCase()==='entrenador');
    const externo = datos_unique.filter(b => b.actividad.toLowerCase()==='externo');
    const empleadoclub = datos_unique.filter(b => b.actividad.toLowerCase()==='empleado club');
    const empleadofundacion = datos_unique.filter(b => b.actividad.toLowerCase()==='empleado fundación');
    const contratista = datos_unique.filter(b => b.actividad.toLowerCase()==='contratista');
    const agenciadeempleo = datos_unique.filter(b => b.actividad.toLowerCase()==='agencia de empleo');
    // Categoría
    const primera = datos_unique.filter(b => b.categoria.toLowerCase()==='1');
    const segunda = datos_unique.filter(b => b.categoria.toLowerCase()==='2');
    const tercera = datos_unique.filter(b => b.categoria.toLowerCase()==='3');
    const pe1 = datos_unique.filter(b => b.categoria.toLowerCase()==='pe1');
    const pe2 = datos_unique.filter(b => b.categoria.toLowerCase()==='pe2');
    const pe3 = datos_unique.filter(b => b.categoria.toLowerCase()==='pe3');
    const pp3 = datos_unique.filter(b => b.categoria.toLowerCase()==='pp3');
    const serviciosgenerales = datos_unique.filter(b => b.categoria.toLowerCase()==='servicios generales');
    const campogolf = datos_unique.filter(b => b.categoria.toLowerCase()==='campo golf');
    const tiendagolf = datos_unique.filter(b => b.categoria.toLowerCase()==='tienda golf');
    const lavadero = datos_unique.filter(b => b.categoria.toLowerCase()==='lavadero');
    const cocina = datos_unique.filter(b => b.categoria.toLowerCase()==='cocina');
    const masajes = datos_unique.filter(b => b.categoria.toLowerCase()==='masajes');
    const cholados = datos_unique.filter(b => b.categoria.toLowerCase()==='cholados');
    const peluqueria = datos_unique.filter(b => b.categoria.toLowerCase()==='peluquería');

    // Edad
    const edadmenor = datos_unique.filter(b => b.edad<18);
    const edad18 = datos_unique.filter(b => b.edad>=18 && b.edad<21);
    const edad21 = datos_unique.filter(b => b.edad>=21 && b.edad<26);
    const edad26 = datos_unique.filter(b => b.edad>=26 && b.edad<31);
    const edad31 = datos_unique.filter(b => b.edad>=31 && b.edad<36);
    const edad36 = datos_unique.filter(b => b.edad>=36 && b.edad<41);
    const edad41 = datos_unique.filter(b => b.edad>=41 && b.edad<45);
    const edad45 = datos_unique.filter(b => b.edad>=45);

    // EdadClub
    const edadclub1 = datos_unique.filter(b => b.edadclub<1);
    const edadclub2 = datos_unique.filter(b => b.edadclub>=1 && b.edadclub<2);
    const edadclub3 = datos_unique.filter(b => b.edadclub>=2 && b.edadclub<5);
    const edadclub4 = datos_unique.filter(b => b.edadclub>=5 && b.edadclub<10);
    const edadclub5 = datos_unique.filter(b => b.edadclub>=10 && b.edadclub<15);
    const edadclub6 = datos_unique.filter(b => b.edadclub>=15 && b.edadclub<20);
    const edadclub7 = datos_unique.filter(b => b.edadclub>=20 && b.edadclub<25);
    const edadclub8 = datos_unique.filter(b => b.edadclub>=25 && b.edadclub<30);
    const edadclub9 = datos_unique.filter(b => b.edadclub>=30 && b.edadclub<35);
    const edadclub10 = datos_unique.filter(b => b.edadclub>=35 && b.edadclub<40);
    const edadclub11 = datos_unique.filter(b => b.edadclub>=40 && b.edadclub<45);
    const edadclub12 = datos_unique.filter(b => b.edadclub>=45 && b.edadclub<50);
    const edadclub13 = datos_unique.filter(b => b.edadclub>=50);
    
    //  Fecha Actualizacion
    const d = moment(new Date(), "YYYY-MM-DD"); //.format("YYYY-MM-DD HH:mm:ss")
    const yesterday = moment(d).subtract(1 , 'day'); //d.setDate(d.getDate()-1);
    const l7days = moment(d).subtract(7 , 'day'); //d.setDate(d.getDate()-7);
    const l30days = moment(d).subtract(30 , 'day'); //d.setDate(d.getDate()-30);
    // 
    const hoy = datos.filter(b => moment(b.actualizacion).format("YYYY-MM-DD")===d.format("YYYY-MM-DD"));
    const ayer = datos.filter(b => moment(b.actualizacion).format("YYYY-MM-DD")===yesterday.format("YYYY-MM-DD"));
    const last7days = datos.filter(b => b.actualizacion>=l7days);
    const last30days = datos.filter(b => b.actualizacion>=l30days);
    // const rangofechas = datos.filter(b => b.actualizacion==='2020-06-07');

    // Categorias Seguimiento (Covid-19) - tabla perfilbeneficiario
    // const seg_empleado = datos.filter(b => b.categoria.toLowerCase()==='empleado');
    // const seg_independiente = datos.filter(b => b.categoria.toLowerCase()==='independiente');
    // const seg_enproceso = datos.filter(b => b.categoria.toLowerCase()==='en proceso');
    // const seg_dependiente = datos.filter(b => b.categoria.toLowerCase()==='dependiente');

    setStatsBeneficiario({
        total:total,
        activos:activos.length,
        noactivos:noactivos.length,
        bonos: bonos.length,
        mercados: mercados.length,
        redimido: redimido.length,
        caducado: caducado.length,
        prestarservicio: prestarservicio.length,
        noprestarservicio: noprestarservicio.length,
        acuerdocorresp: acuerdocorresp.length,
        noacuerdocorresp: noacuerdocorresp.length,
        jefehogar: jefehogar.length,
        nojefehogar: nojefehogar.length,
        jefehogarcomp: jefehogarcomp.length,
        actualizado: actualizado.length,
        pendiente: pendiente.length,
        visitar: visitar.length,
        revisar: revisar.length,
        prueba: prueba.length,
        retirado: retirado.length,
        noapto: noapto.length,
        suspendido: suspendido.length,
        caddie: caddie.length,
        bolero: bolero.length,
        boleador: boleador.length,
        monitor: monitor.length,
        entrenador: entrenador.length,
        externo: externo.length,
        empleadoclub: empleadoclub.length,
        empleadofundacion: empleadofundacion.length,
        contratista: contratista.length,
        agenciadeempleo: agenciadeempleo.length,
        primera: primera.length,
        segunda: segunda.length,
        tercera: tercera.length,
        pe1: pe1.length,
        pe2: pe2.length,
        pe3: pe3.length,
        pp3: pp3.length,
        serviciosgenerales: serviciosgenerales.length,
        campogolf: campogolf.length,
        tiendagolf: tiendagolf.length,
        lavadero: lavadero.length,
        cocina: cocina.length,
        masajes: masajes.length,
        cholados: cholados.length,
        peluqueria: peluqueria.length,
        edadmenor: edadmenor.length,
        edad18: edad18.length,
        edad21: edad21.length,
        edad26: edad26.length,
        edad31: edad31.length,
        edad36: edad36.length,
        edad41: edad41.length,
        edad45: edad45.length,
        edadclub1: edadclub1.length,
        edadclub2: edadclub2.length,
        edadclub3: edadclub3.length,
        edadclub4: edadclub4.length,
        edadclub5: edadclub5.length,
        edadclub6: edadclub6.length,
        edadclub7: edadclub7.length,
        edadclub8: edadclub8.length,
        edadclub9: edadclub9.length,
        edadclub10: edadclub10.length,
        edadclub11: edadclub11.length,
        edadclub12: edadclub12.length,
        edadclub13: edadclub13.length,
        hoy: hoy.length,
        ayer: ayer.length,
        last7days: last7days.length,
        last30days: last30days.length,
        entrega_1: entrega_1.length,
        entrega_2: entrega_2.length,
        entrega_3: entrega_3.length,
        entrega_4: entrega_4.length,
        entrega_5: entrega_5.length,
        entrega_6: entrega_6.length,
        entrega_7: entrega_7.length,
        entrega_8: entrega_8.length,
        entrega_9: entrega_9.length,
        entrega_10: entrega_10.length,
        valorayuda1: valorayuda1.length,
        valorayuda2: valorayuda2.length,
        valorayuda3: valorayuda3.length,
        valorayuda4: valorayuda4.length,
        valorayuda5: valorayuda5.length,
        valorayuda6: valorayuda6.length,
        valorayuda7: valorayuda7.length,
        valorayuda8: valorayuda8.length,
        valorayuda9: valorayuda9.length,
        valorayuda10: valorayuda10.length,
        personasacargo_0:personasacargo_0.length,
        personasacargo_1:personasacargo_1.length,
        personasacargo_2:personasacargo_2.length,
        personasacargo_3:personasacargo_3.length,
        personasacargo_4:personasacargo_4.length,
        personasacargo_5:personasacargo_5.length,
        personasacargo_5plus:personasacargo_5plus.length,
        personasacargo_6:personasacargo_6.length,
        personasacargo_7:personasacargo_7.length,
        personasacargo_8:personasacargo_8.length,
        personasacargo_9:personasacargo_9.length,
        personasacargo_10:personasacargo_10.length,
        afectacion_alta: afectacion_alta.length,
        afectacion_media: afectacion_media.length,
        afectacion_baja: afectacion_baja.length,
        afectacion_noaplica: afectacion_noaplica.length

        // seg_empleado: seg_empleado.length,
        // seg_independiente: seg_independiente.length,
        // seg_enproceso: seg_enproceso.length,
        // seg_dependiente: seg_dependiente.length
    });

  }, [filter, listBenefDates]);

  // const toggleSublistTotal = () => { setOpenTotal(!openTotal); };
  const toggleSublistTipoAyuda = () => { setOpenTipoAyuda(!openTipoAyuda); };
  const toggleSublistEstadoAyuda = () => { setOpenEstadoAyuda(!openEstadoAyuda); };
  const toggleSublistAfectacion = () => { setOpenAfectacion(!openAfectacion); };
  const toggleSublistValorAyuda = () => { setOpenValorAyuda(!openValorAyuda); };
  const toggleSublistPrestarServicio = () => { setOpenPrestarServicio(!openPrestarServicio); };
  const toggleSublistAcuerdoCorresp = () => { setOpenAcuerdoCorresp(!openAcuerdoCorresp); };
  const toggleSublistJefeHogar = () => { setOpenJefeHogar(!openJefeHogar); };
  const toggleSublistPersonasaCargo = () => { setOpenPersonasaCargo(!openPersonasaCargo); };
  const toggleSublistEntrega = () => { setOpenEntrega(!openEntrega); };
  const toggleSublistEstado = () => { setOpenEstado(!openEstado); };
  const toggleSublistActividad = () => { setOpenActividad(!openActividad); };
  const toggleSublistCategoria = () => { setOpenCategoria(!openCategoria); };
  const toggleSublistActualizado = () => { setOpenActualizado(!openActualizado); };
  const toggleSublistEdad = () => { setOpenEdad(!openEdad); };
  const toggleSublistEdadClub = () => { setOpenEdadClub(!openEdadClub); };

  const filtroGlobal = (filtro, arr) => {
    // console.log(filtro, arr);
    setLastFilter(arr);
    dispatch(filterBeneficiariosDataxCovid(arr));
    // if (listadeFiltros && listadeFiltros.length>0){
    //   dispatch(filterBeneficiariosDataxCovid(arr));
    // } else {
    //   dispatch(filterBeneficiariosDataxCovid([]));
    // }
  };
  const [listadeFiltros, setListadeFiltros] = useState([]);
  const [, setHasTipoAyudaFiltro] = useState(false); //hasTipoAyudaFiltro
  const [hasEstadoAyudaFiltro, setHasEstadoAyudaFiltro] = useState(false);
  const [hasAfectacionFiltro, setHasAfectacionFiltro] = useState(false);
  const [hasPrestarServicioFiltro, setHasPrestarServicioFiltro] = useState(false);
  const [hasAcuerdoCorrespFiltro, setHasAcuerdoCorrespFiltro] = useState(false);
  const [hasJefeHogarFiltro, setHasJefeHogarFiltro] = useState(false);
  const [hasPersonasaCargoFiltro, setHasPersonasaCargoFiltro] = useState(false);
  const [hasEntregaFiltro, setHasEntregaFiltro] = useState(false);
  const [hasValorAyudaFiltro, setHasValorAyudaFiltro] = useState(false);
  const [hasEstadoFiltro, setHasEstadoFiltro] = useState(false);
  const [hasActividadFiltro, setHasActividadFiltro] = useState(false);
  const [hasCategoriaFiltro, setHasCategoriaFiltro] = useState(false);
  const [hasEdadFiltro, setHasEdadFiltro] = useState(false);
  const [hasEdadClubFiltro, setHasEdadClubFiltro] = useState(false);
  const [hasActualizadoFiltro, setHasActualizadoFiltro] = useState(false);
  const filtersname = {
    "activos": "1",
    "noactivos": "0",
    "primera": "1",
    "segunda": "2",
    "tercera": "3",
    "prestarservicio": "1",
    "noprestarservicio": "0",
    "acuerdocorresp": "1",
    "noacuerdocorresp": "0",
    "jefehogar": "si",
    "nojefehogar": "no",
    "jefehogarcomp": "compartido",
    "entrega_1": "1",
    "entrega_2": "2",
    "entrega_3": "3",
    "entrega_4": "4",
    "entrega_5": "5",
    "entrega_6": "6",
    "entrega_7": "7",
    "entrega_8": "8",
    "entrega_9": "9",
    "entrega_10": "10",
  }
  
  // Procesa el filtro para Agregar, Remover o Limpiar todos las selecciones
  const procesarFiltro = (filtro, ultimoFiltro, isAddingFilter) => {
    const filtrosUnicos = [ 'activos', 'noactivos', 'bono', 'mercado', 'redimido', 'caducado', 'alta', 'media', 'baja', 'noaplica',
    'prestarservicio', 'noprestarservicio', 'acuerdocorresp', 'noacuerdocorresp', 'jefehogar', 'nojefehogar', 'jefehogarcomp', 
    'hoy', 'ayer', 'last7days', 'last30days', 
      'edadmenor', 'edad18', 'edad21', 'edad26', 'edad31', 'edad36', 'edad41', 'edad45', 'edadclub1', 'edadclub2', 
      'edadclub3', 'edadclub4', 'edadclub5', 'edadclub6', 'edadclub7', 'edadclub8', 'edadclub9', 
      'edadclub10', 'edadclub11', 'edadclub12', 'edadclub13','entrega_1', 'entrega_2', 'entrega_3', 'entrega_4', 'entrega_5',
      'entrega_6', 'entrega_7', 'entrega_8', 'entrega_9', 'entrega_10', 'valorayuda1', 'valorayuda2', 'valorayuda3', 'valorayuda4', 'valorayuda5',
      'valorayuda6', 'valorayuda7', 'valorayuda8', 'valorayuda9', 'valorayuda10', 'personasacargo_0', 'personasacargo_1', 'personasacargo_2', 'personasacargo_3', 
      'personasacargo_4', 'personasacargo_5', 'personasacargo_5plus', 'personasacargo_6', 'personasacargo_7', 'personasacargo_8', 'personasacargo_9', 'personasacargo_10'
    ];
    const filtrosActivos = [ 'activos', 'noactivos' ];
    const filtrosTipoAyuda = [ 'bono', 'mercado' ];
    const filtrosEstadoAyuda = [ 'redimido', 'caducado' ];
    const filtrosAfectacion = [ 'alta', 'media', 'baja', 'noaplica' ];
    const filtrosPrestarServicio = [ 'prestarservicio', 'noprestarservicio' ];
    const filtrosAcuerdoCorresp = [ 'acuerdocorresp', 'noacuerdocorresp' ];
    const filtrosJefeHogar = [ 'jefehogar', 'nojefehogar', 'jefehogarcomp' ];
    const filtrosEntrega = [ 'entrega_1', 'entrega_2', 'entrega_3', 'entrega_4', 'entrega_5',
      'entrega_6', 'entrega_7', 'entrega_8', 'entrega_9', 'entrega_10'
    ];
    const filtrosValorAyuda = [ 'valorayuda1', 'valorayuda2', 'valorayuda3', 'valorayuda4', 'valorayuda5',
      'valorayuda6', 'valorayuda7', 'valorayuda8', 'valorayuda9', 'valorayuda10'
    ];
    const filtrosPersonasaCargo = [ 'personasacargo_0', 'personasacargo_1', 'personasacargo_2', 'personasacargo_3', 'personasacargo_4', 'personasacargo_5', 'personasacargo_5plus',
      'personasacargo_6', 'personasacargo_7', 'personasacargo_8', 'personasacargo_9', 'personasacargo_10'
    ];
    const filtrosEdad = [ 'edadmenor', 'edad18', 'edad21', 'edad26', 'edad31', 'edad36', 'edad41', 'edad45' ]; 
    const filtrosEdadClub = [ 'edadclub1', 'edadclub2', 'edadclub3', 'edadclub4', 'edadclub5', 'edadclub6', 
      'edadclub7', 'edadclub8', 'edadclub9', 'edadclub10', 'edadclub11', 'edadclub12', 'edadclub13' 
    ]; 
    const filtrosActualizacion = [ 'hoy', 'ayer', 'last7days', 'last30days' ]; //'rangofechas'
    const filtrosEstado = ['actualizado', 'pendiente', 'visitar', 'revisar', 
      'prueba', 'retirado', 'no apto', 'suspendido' ];
    const filtrosActividad = ['caddie', 'bolero', 'boleador', 'monitor', 'entrenador', 
      'externo', 'empleado club', 'empleado fundación', 'contratista', 'agencia de empleo' ];
    const filtrosCategoria = ['primera','segunda','tercera','pe1','pe2','pe3','pp3','servicios generales',
      'campo golf','tienda golf','lavadero','cocina','masajes','cholados','peluquería',
    ];
    let filteredArray = [];
    let hasActivoFilter = false;
    let hasTipoAyudaFilter = false;
    let hasEstadoAyudaFilter = false;
    let hasAfectacionFilter = false;
    let hasPrestarServicioFilter = false;
    let hasAcuerdoCorrespFilter = false;
    let hasJefeHogarFilter = false;
    let hasPersonasaCargoFilter = false;
    let hasEntregaFilter = false;
    let hasValorAyudaFilter = false;
    let hasEstadoFilter = false;
    let hasActividadFilter = false;
    let hasCategoriaFilter = false;
    let hasEdadFilter = false;
    let hasEdadClubFilter = false;
    let hasActualizacionFilter = false;
    let lastActivoFilterSelected = null;
    let lastTipoAyudaFilterSelected = null;
    let lastPrestarServicioFilterSelected = null;
    let lastAcuerdoCorrespFilterSelected = null;
    let lastJefeHogarFilterSelected = null;
    let lastEntregaFilterSelected = null;
    let lastPersonasaCargoFilterSelected = null;
    // let lastEstadoFilterSelected = null;
    // let lastActividadFilterSelected = null;
    // let lastCategoriaFilterSelected = null;
    let lastAfectacionFilterSelected = null;
    let lastEstadoAyudaFilterSelected = null;
    let lastValorAyudaFilterSelected = null;
    let lastEdadFilterSelected = null;
    let lastEdadClubFilterSelected = null;
    let lastFechaFilterSelected = null;
    ultimoFiltro.forEach((uf, idx) => {
      if (filtrosActivos.includes(uf.filtro)) {
        lastActivoFilterSelected = uf.filtro;
        hasActivoFilter = true;
      }
      if (filtrosTipoAyuda.includes(uf.filtro)) {
        lastTipoAyudaFilterSelected = uf.filtro;
        hasTipoAyudaFilter = true;
      }
      if (filtrosEstadoAyuda.includes(uf.filtro)) {
        lastEstadoAyudaFilterSelected = uf.filtro;
        hasEstadoAyudaFilter = true;
      }
      if (filtrosAfectacion.includes(uf.filtro)) {
        lastAfectacionFilterSelected = uf.filtro;
        hasAfectacionFilter = true;
      }
      if (filtrosPrestarServicio.includes(uf.filtro)) {
        lastPrestarServicioFilterSelected = uf.filtro;
        hasPrestarServicioFilter = true;
      }
      if (filtrosAcuerdoCorresp.includes(uf.filtro)) {
        lastAcuerdoCorrespFilterSelected = uf.filtro;
        hasAcuerdoCorrespFilter = true;
      }
      if (filtrosJefeHogar.includes(uf.filtro)) {
        lastJefeHogarFilterSelected = uf.filtro;
        hasJefeHogarFilter = true;
      }
      if (filtrosValorAyuda.includes(uf.filtro)) {
        lastValorAyudaFilterSelected = uf.filtro;
        hasValorAyudaFilter = true;
      }
      if (filtrosPersonasaCargo.includes(uf.filtro)) {
        lastPersonasaCargoFilterSelected = uf.filtro;
        hasPersonasaCargoFilter = true;
      }
      if (filtrosEdad.includes(uf.filtro)) {
        lastEdadFilterSelected = uf.filtro;
        hasEdadFilter = true;
      }
      if (filtrosEdadClub.includes(uf.filtro)) {
        lastEdadClubFilterSelected = uf.filtro;
        hasEdadClubFilter = true;
      }
      if (filtrosActualizacion.includes(uf.filtro)) {
        lastFechaFilterSelected = uf.filtro;
        hasActualizacionFilter = true;
      }
      if (filtrosEntrega.includes(uf.filtro)) {
        lastEntregaFilterSelected = uf.filtro;
        hasEntregaFilter = true;
      }
      if (filtrosEstado.includes(uf.filtro)) {
        // lastEstadoFilterSelected = uf.filtro;
        hasEstadoFilter = true;
      }
      if (filtrosActividad.includes(uf.filtro)) {
        // lastActividadFilterSelected = uf.filtro;
        hasActividadFilter = true;
      }
      if (filtrosCategoria.includes(uf.filtro)) {
        // lastCategoriaFilterSelected = uf.filtro;
        hasCategoriaFilter = true;
      }
    });

    // Remuevo todos los filtros de fecha y activo/noactivo seleccionados
    // Debido a que la selección por fechas no es multiple
    filtrosUnicos.forEach((f, idx) => {
      ultimoFiltro = [...ultimoFiltro].filter(itm => itm.filtro!==f)
    });
    setListadeFiltros(ultimoFiltro);
    // Concat Filter Estado, Actividad, Categoria, etc
    // Agregando los filtros seleccionados
    ultimoFiltro.forEach((uf, idx) => {
      let filtername = filtersname[uf.filtro] || uf.filtro;
      filteredArray = [...filteredArray].concat([...listBenefDates].filter(b => b[uf.field].toLowerCase()===filtername));
    });

    if (filteredArray.length<=0){
      if (isAddingFilter) filteredArray = [...listBenefDates]; //AddFilter
    } else {
      // Get Duplicates
      let duplicates = getDuplicates(filteredArray, 'id'); //docId
      if (duplicates.length>0) filteredArray = [...duplicates];
      // Remove duplicates (Esta opción no es válida aquí debido a que los datos originales que se manejan no son únicos)
      // filteredArray = RemoveDuplicates(filteredArray, 'beneficiarioId'); // Get Unique list of Beneficiarios
      // filteredArray = RemoveEntregasDuplicadas(filteredArray, 'docId', 'numentrega');  
      // Sort
      // filteredArray.sort(((a, b) => a.docId < b.docId));
    }

    if (isAddingFilter){ //AddFilter
      if (filtrosActivos.includes(filtro)) lastActivoFilterSelected = filtro;
      if (filtrosTipoAyuda.includes(filtro)) lastTipoAyudaFilterSelected = filtro;
      if (filtrosEstadoAyuda.includes(filtro)) lastEstadoAyudaFilterSelected = filtro;
      if (filtrosAfectacion.includes(filtro)) lastAfectacionFilterSelected = filtro;
      if (filtrosEntrega.includes(filtro)) lastEntregaFilterSelected = filtro;
      if (filtrosPrestarServicio.includes(filtro)) lastPrestarServicioFilterSelected = filtro;
      if (filtrosAcuerdoCorresp.includes(filtro)) lastAcuerdoCorrespFilterSelected = filtro;
      if (filtrosJefeHogar.includes(filtro)) lastJefeHogarFilterSelected = filtro;
      if (filtrosValorAyuda.includes(filtro)) lastValorAyudaFilterSelected = filtro;
      if (filtrosPersonasaCargo.includes(filtro)) lastPersonasaCargoFilterSelected = filtro;
      if (filtrosEdad.includes(filtro)) lastEdadFilterSelected = filtro;
      if (filtrosEdadClub.includes(filtro)) lastEdadClubFilterSelected = filtro;
      if (filtrosActualizacion.includes(filtro)) lastFechaFilterSelected = filtro;
    }

    if (hasEstadoFilter) {
      setHasEstadoFiltro(true);
    } else {
      setHasEstadoFiltro(false);
    }
    if (hasActividadFilter) {
      setHasActividadFiltro(true);
    } else {
      setHasActividadFiltro(false);
    }
    if (hasCategoriaFilter) {
      setHasCategoriaFiltro(true);
    } else {
      setHasCategoriaFiltro(false);
    }
    
    // Último filtro selección única
    if (hasActivoFilter){
      filteredArray = updateActivosFiltro(lastActivoFilterSelected, filteredArray, isAddingFilter);
      ultimoFiltro = [...ultimoFiltro].concat([{filtro:lastActivoFilterSelected, field:'activo'}]);
    }
    if (hasTipoAyudaFilter){
      setHasTipoAyudaFiltro(true);
      filteredArray = updateTipoAyudaFiltro(lastTipoAyudaFilterSelected, filteredArray, isAddingFilter);
      ultimoFiltro = [...ultimoFiltro].concat([{filtro:lastTipoAyudaFilterSelected, field:'tipoayuda'}]);
    } else {
      setHasTipoAyudaFiltro(false);
    }
    if (hasEstadoAyudaFilter){
      setHasEstadoAyudaFiltro(true);
      filteredArray = updateEstadoAyudaFiltro(lastEstadoAyudaFilterSelected, filteredArray, isAddingFilter);
      ultimoFiltro = [...ultimoFiltro].concat([{filtro:lastEstadoAyudaFilterSelected, field:'estadoayuda'}]);
    } else {
      setHasEstadoAyudaFiltro(false);
    }
    if (hasAfectacionFilter){
      setHasAfectacionFiltro(true);
      filteredArray = updateAfectacionFiltro(lastAfectacionFilterSelected, filteredArray, isAddingFilter);
      ultimoFiltro = [...ultimoFiltro].concat([{filtro:lastAfectacionFilterSelected, field:'afectacion'}]);
    } else {
      setHasAfectacionFiltro(false);
    }
    if (hasPrestarServicioFilter){
      setHasPrestarServicioFiltro(true);
      filteredArray = updatePrestarServicioFiltro(lastPrestarServicioFilterSelected, filteredArray, isAddingFilter);
      ultimoFiltro = [...ultimoFiltro].concat([{filtro:lastPrestarServicioFilterSelected, field:'prestarservicio'}]);
    } else {
      setHasPrestarServicioFiltro(false);
    }
    if (hasAcuerdoCorrespFilter){
      setHasAcuerdoCorrespFiltro(true);
      filteredArray = updateAcuerdoCorrespFiltro(lastAcuerdoCorrespFilterSelected, filteredArray, isAddingFilter);
      ultimoFiltro = [...ultimoFiltro].concat([{filtro:lastAcuerdoCorrespFilterSelected, field:'acuerdocorresp'}]);
    } else {
      setHasAcuerdoCorrespFiltro(false);
    }
    if (hasJefeHogarFilter){
      setHasJefeHogarFiltro(true);
      filteredArray = updateJefeHogarFiltro(lastJefeHogarFilterSelected, filteredArray, isAddingFilter);
      ultimoFiltro = [...ultimoFiltro].concat([{filtro:lastJefeHogarFilterSelected, field:'jefehogar'}]);
    } else {
      setHasJefeHogarFiltro(false);
    }
    if (hasPersonasaCargoFilter){
      setHasPersonasaCargoFiltro(true);
      filteredArray = updatePersonasaCargoFiltro(lastPersonasaCargoFilterSelected, filteredArray, isAddingFilter);
      ultimoFiltro = [...ultimoFiltro].concat([{filtro:lastPersonasaCargoFilterSelected, field:'personasacargo'}]);
    } else {
      setHasPersonasaCargoFiltro(false);
    }
    if (hasEntregaFilter){
      setHasEntregaFiltro(true);
      filteredArray = updateEntregaFiltro(lastEntregaFilterSelected, filteredArray, isAddingFilter);
      ultimoFiltro = [...ultimoFiltro].concat([{filtro:lastEntregaFilterSelected, field:'numentrega'}]);
    } else {
      setHasEntregaFiltro(false);
    }
    if (hasValorAyudaFilter){
      setHasValorAyudaFiltro(true);
      filteredArray = updateValorAyudaFiltro(lastValorAyudaFilterSelected, filteredArray, isAddingFilter);
      ultimoFiltro = [...ultimoFiltro].concat([{filtro:lastValorAyudaFilterSelected, field:'valor'}]);
    } else {
      setHasValorAyudaFiltro(false);
    }
    if (hasEdadFilter){
      setHasEdadFiltro(true);
      filteredArray = updateEdadFiltro(lastEdadFilterSelected, filteredArray, isAddingFilter);
      ultimoFiltro = [...ultimoFiltro].concat([{filtro:lastEdadFilterSelected, field:'edad'}]);
    } else {
      setHasEdadFiltro(false);
    }
    if (hasEdadClubFilter){
      setHasEdadClubFiltro(true);
      filteredArray = updateEdadClubFiltro(lastEdadClubFilterSelected, filteredArray, isAddingFilter);
      ultimoFiltro = [...ultimoFiltro].concat([{filtro:lastEdadClubFilterSelected, field:'edadclub'}]);
    } else {
      setHasEdadClubFiltro(false);
    }
    if (hasActualizacionFilter){
      setHasActualizadoFiltro(true);
      filteredArray = updateActualizadoFiltro(lastFechaFilterSelected, filteredArray, isAddingFilter);
      ultimoFiltro = [...ultimoFiltro].concat([{filtro:lastFechaFilterSelected, field:'actualizacion'}]);
    } else {
      setHasActualizadoFiltro(false);
    }
    setListadeFiltros(ultimoFiltro);

    if (filteredArray.length<=0 && !isAddingFilter){
      filteredArray = [...listBenefDates];
    } 

    // Test reduce filter
    // if (hasEstadoFilter && hasActividadFilter){
    //   // filteredArray = RemoveEntregasDuplicadas(filteredArray, 'docId', 'numentrega'); 
    //   // filteredArray = RemoveDuplicates(filteredArray, 'beneficiarioId');
    
    //   filteredArray = [...filteredArray].reduce((arr, item) => {
    //         const removed = arr.filter(i => (
    //           (i['docId'] !== item['docId']) && 
    //           (i['numentrega'] !== item['numentrega']) && 
    //           (i['tipoayuda'] !== item['tipoayuda']) && 
    //           (i['estadoayuda'] !== item['estadoayuda']) && 
    //           (i['afectacion'] !== item['afectacion']) && 
    //           (i['estado'] !== item['estado']) && 
    //           (i['actividad'] !== item['actividad']) && 
    //           (i['categoria'] !== item['categoria'])

    //         ));
    //         return [...removed, item];
    //     }, []);
    // }
    
    return filteredArray
  }

  // Filtros de Selección Única
  const updateActivosFiltro = (lastActivoFilterSelected, filteredArray, isAddingFilter) => {
    if (isAddingFilter || filteredArray.length>0){
      if (lastActivoFilterSelected==='activos') filteredArray = [...filteredArray].filter(b => b['activo'].toLowerCase()==='1');
      if (lastActivoFilterSelected==='noactivos')  filteredArray = [...filteredArray].filter(b => b['activo'].toLowerCase()==='0');
    } else {
      if (lastActivoFilterSelected==='activos') filteredArray = [...listBenefDates].filter(b => b['activo'].toLowerCase()==='1');
      if (lastActivoFilterSelected==='noactivos')  filteredArray = [...listBenefDates].filter(b => b['activo'].toLowerCase()==='0');
    }

    return filteredArray
  }
  // Tipo Ayuda
  const updateTipoAyudaFiltro = (lastTipoAyudaFilterSelected, filteredArray, isAddingFilter) => {
    if (isAddingFilter || filteredArray.length>0){
      if (lastTipoAyudaFilterSelected==='bono') filteredArray = [...filteredArray].filter(b => b['tipoayuda'].toLowerCase()==='bono');
      if (lastTipoAyudaFilterSelected==='mercado')  filteredArray = [...filteredArray].filter(b => b['tipoayuda'].toLowerCase()==='mercado');
    } else {
      if (lastTipoAyudaFilterSelected==='bono') filteredArray = [...listBenefDates].filter(b => b['tipoayuda'].toLowerCase()==='bono');
      if (lastTipoAyudaFilterSelected==='mercado')  filteredArray = [...listBenefDates].filter(b => b['tipoayuda'].toLowerCase()==='mercado');
    }

    return filteredArray
  }
  // Filtros de Estado Ayuda
  const updateEstadoAyudaFiltro = (lastEstadoAyudaFilterSelected, filteredArray, isAddingFilter) => {
    if (isAddingFilter || filteredArray.length>0){
      if (lastEstadoAyudaFilterSelected==='redimido') filteredArray = [...filteredArray].filter(b => b['estadoayuda'].toLowerCase()==='redimido');
      if (lastEstadoAyudaFilterSelected==='caducado')  filteredArray = [...filteredArray].filter(b => b['estadoayuda'].toLowerCase()==='caducado');
    } else {
      if (lastEstadoAyudaFilterSelected==='redimido') filteredArray = [...listBenefDates].filter(b => b['estadoayuda'].toLowerCase()==='redimido');
      if (lastEstadoAyudaFilterSelected==='caducado')  filteredArray = [...listBenefDates].filter(b => b['estadoayuda'].toLowerCase()==='caducado');
    }

    return filteredArray
  }

  // Filtros de Afectacion
  const updateAfectacionFiltro = (lastAfectacionFilterSelected, filteredArray, isAddingFilter) => {
    if (isAddingFilter || filteredArray.length>0){
      if (lastAfectacionFilterSelected==='alta') filteredArray = [...filteredArray].filter(b => b['afectacion'].toLowerCase()==='alta');
      if (lastAfectacionFilterSelected==='media')  filteredArray = [...filteredArray].filter(b => b['afectacion'].toLowerCase()==='media');
      if (lastAfectacionFilterSelected==='baja') filteredArray = [...filteredArray].filter(b => b['afectacion'].toLowerCase()==='baja');
      if (lastAfectacionFilterSelected==='noaplica')  filteredArray = [...filteredArray].filter(b => b['afectacion'].toLowerCase()==='no aplica');
    } else {
      if (lastAfectacionFilterSelected==='alta') filteredArray = [...listBenefDates].filter(b => b['afectacion'].toLowerCase()==='alta');
      if (lastAfectacionFilterSelected==='media')  filteredArray = [...listBenefDates].filter(b => b['afectacion'].toLowerCase()==='media');
      if (lastAfectacionFilterSelected==='baja') filteredArray = [...listBenefDates].filter(b => b['afectacion'].toLowerCase()==='baja');
      if (lastAfectacionFilterSelected==='noaplica')  filteredArray = [...listBenefDates].filter(b => b['afectacion'].toLowerCase()==='no aplica');
    }

    return filteredArray
  }
  
  // Filtros de Jefe Hogar
  const updatePrestarServicioFiltro = (lastPrestarServicioFilterSelected, filteredArray, isAddingFilter) => {
    if (isAddingFilter || filteredArray.length>0){
      if (lastPrestarServicioFilterSelected==='prestarservicio') filteredArray = [...filteredArray].filter(b => b['prestarservicio'].toLowerCase()==='1');
      if (lastPrestarServicioFilterSelected==='noprestarservicio')  filteredArray = [...filteredArray].filter(b => b['prestarservicio'].toLowerCase()==='0');
    } else {
      if (lastPrestarServicioFilterSelected==='prestarservicio') filteredArray = [...listBenefDates].filter(b => b['prestarservicio'].toLowerCase()==='1');
      if (lastPrestarServicioFilterSelected==='noprestarservicio')  filteredArray = [...listBenefDates].filter(b => b['prestarservicio'].toLowerCase()==='0');
    }

    return filteredArray
  }
  // Filtros Acuerdo de Corresponsabilidad
  const updateAcuerdoCorrespFiltro = (lastAcuerdoCorrespFilterSelected, filteredArray, isAddingFilter) => {
    if (isAddingFilter || filteredArray.length>0){
      if (lastAcuerdoCorrespFilterSelected==='acuerdocorresp') filteredArray = [...filteredArray].filter(b => b['acuerdocorresp'].toLowerCase()==='1');
      if (lastAcuerdoCorrespFilterSelected==='noacuerdocorresp')  filteredArray = [...filteredArray].filter(b => b['acuerdocorresp'].toLowerCase()==='0');
    } else {
      if (lastAcuerdoCorrespFilterSelected==='acuerdocorresp') filteredArray = [...listBenefDates].filter(b => b['acuerdocorresp'].toLowerCase()==='1');
      if (lastAcuerdoCorrespFilterSelected==='noacuerdocorresp')  filteredArray = [...listBenefDates].filter(b => b['acuerdocorresp'].toLowerCase()==='0');
    }

    return filteredArray
  }
  // Filtros de Jefe Hogar
  const updateJefeHogarFiltro = (lastJefeHogarFilterSelected, filteredArray, isAddingFilter) => {
    if (isAddingFilter || filteredArray.length>0){
      if (lastJefeHogarFilterSelected==='jefehogar') filteredArray = [...filteredArray].filter(b => b['jefehogar'].toLowerCase()==='si');
      if (lastJefeHogarFilterSelected==='nojefehogar')  filteredArray = [...filteredArray].filter(b => b['jefehogar'].toLowerCase()==='no');
      if (lastJefeHogarFilterSelected==='jefehogarcomp')  filteredArray = [...filteredArray].filter(b => b['jefehogar'].toLowerCase()==='compartido');
    } else {
      if (lastJefeHogarFilterSelected==='jefehogar') filteredArray = [...listBenefDates].filter(b => b['jefehogar'].toLowerCase()==='si');
      if (lastJefeHogarFilterSelected==='nojefehogar')  filteredArray = [...listBenefDates].filter(b => b['jefehogar'].toLowerCase()==='no');
      if (lastJefeHogarFilterSelected==='jefehogarcomp')  filteredArray = [...listBenefDates].filter(b => b['jefehogar'].toLowerCase()==='compartido');
    }

    return filteredArray
  }
  // Filtros de Entrega
  const updateEntregaFiltro = (lastEntregaFilterSelected, filteredArray, isAddingFilter) => {
    if (isAddingFilter || filteredArray.length>0){
      if (lastEntregaFilterSelected==='entrega_1') filteredArray = [...filteredArray].filter(b => b.numentrega==='1');
      if (lastEntregaFilterSelected==='entrega_2') filteredArray = [...filteredArray].filter(b => b.numentrega==='2');
      if (lastEntregaFilterSelected==='entrega_3') filteredArray = [...filteredArray].filter(b => b.numentrega==='3');
      if (lastEntregaFilterSelected==='entrega_4') filteredArray = [...filteredArray].filter(b => b.numentrega==='4');
      if (lastEntregaFilterSelected==='entrega_5') filteredArray = [...filteredArray].filter(b => b.numentrega==='5');
      if (lastEntregaFilterSelected==='entrega_6') filteredArray = [...filteredArray].filter(b => b.numentrega==='6');
      if (lastEntregaFilterSelected==='entrega_7') filteredArray = [...filteredArray].filter(b => b.numentrega==='7');
      if (lastEntregaFilterSelected==='entrega_8') filteredArray = [...filteredArray].filter(b => b.numentrega==='8');
      if (lastEntregaFilterSelected==='entrega_9') filteredArray = [...filteredArray].filter(b => b.numentrega==='9');
      if (lastEntregaFilterSelected==='entrega_10') filteredArray = [...filteredArray].filter(b => b.numentrega==='10');
    } else {
      if (lastEntregaFilterSelected==='entrega_1') filteredArray = [...listBenefDates].filter(b => b.numentrega==='1');
      if (lastEntregaFilterSelected==='entrega_2') filteredArray = [...listBenefDates].filter(b => b.numentrega==='2');
      if (lastEntregaFilterSelected==='entrega_3') filteredArray = [...listBenefDates].filter(b => b.numentrega==='3');
      if (lastEntregaFilterSelected==='entrega_4') filteredArray = [...listBenefDates].filter(b => b.numentrega==='4');
      if (lastEntregaFilterSelected==='entrega_5') filteredArray = [...listBenefDates].filter(b => b.numentrega==='5');
      if (lastEntregaFilterSelected==='entrega_6') filteredArray = [...listBenefDates].filter(b => b.numentrega==='6');
      if (lastEntregaFilterSelected==='entrega_7') filteredArray = [...listBenefDates].filter(b => b.numentrega==='7');
      if (lastEntregaFilterSelected==='entrega_8') filteredArray = [...listBenefDates].filter(b => b.numentrega==='8');
      if (lastEntregaFilterSelected==='entrega_9') filteredArray = [...listBenefDates].filter(b => b.numentrega==='9');
      if (lastEntregaFilterSelected==='entrega_10') filteredArray = [...listBenefDates].filter(b => b.numentrega==='10');
    }

    return filteredArray
  }
  // Filtros de Valor Ayuda
  const updateValorAyudaFiltro = (lastValorAyudaFilterSelected, filteredArray, isAddingFilter) => {
    if (isAddingFilter || filteredArray.length>0){
      if (lastValorAyudaFilterSelected==='valorayuda1') filteredArray = [...filteredArray].filter(b => b.valor>=0 && b.valor<=25000);
      if (lastValorAyudaFilterSelected==='valorayuda2')  filteredArray = [...filteredArray].filter(b => b.valor>25000 && b.valor<=50000);
      if (lastValorAyudaFilterSelected==='valorayuda3') filteredArray = [...filteredArray].filter(b => b.valor>50000 && b.valor<=75000);
      if (lastValorAyudaFilterSelected==='valorayuda4')  filteredArray = [...filteredArray].filter(b => b.valor>75000 && b.valor<=100000);
      if (lastValorAyudaFilterSelected==='valorayuda5') filteredArray = [...filteredArray].filter(b => b.valor>100000 && b.valor<=150000);
      if (lastValorAyudaFilterSelected==='valorayuda6')  filteredArray = [...filteredArray].filter(b => b.valor>150000 && b.valor<=200000);
      if (lastValorAyudaFilterSelected==='valorayuda7') filteredArray = [...filteredArray].filter(b => b.valor>200000 && b.valor<=300000);
      if (lastValorAyudaFilterSelected==='valorayuda8')  filteredArray = [...filteredArray].filter(b => b.valor>300000 && b.valor<=400000);
      if (lastValorAyudaFilterSelected==='valorayuda9') filteredArray = [...filteredArray].filter(b => b.valor>400000 && b.valor<=500000);
      if (lastValorAyudaFilterSelected==='valorayuda10')  filteredArray = [...filteredArray].filter(b => b.valor>500000);
    } else {
      if (lastValorAyudaFilterSelected==='valorayuda1') filteredArray = [...listBenefDates].filter(b => b.valor>=0 && b.valor<=25000);
      if (lastValorAyudaFilterSelected==='valorayuda2')  filteredArray = [...listBenefDates].filter(b => b.valor>25000 && b.valor<=50000);
      if (lastValorAyudaFilterSelected==='valorayuda3') filteredArray = [...listBenefDates].filter(b => b.valor>50000 && b.valor<=75000);
      if (lastValorAyudaFilterSelected==='valorayuda4')  filteredArray = [...listBenefDates].filter(b => b.valor>75000 && b.valor<=100000);
      if (lastValorAyudaFilterSelected==='valorayuda5') filteredArray = [...listBenefDates].filter(b => b.valor>100000 && b.valor<=150000);
      if (lastValorAyudaFilterSelected==='valorayuda6')  filteredArray = [...listBenefDates].filter(b => b.valor>150000 && b.valor<=200000);
      if (lastValorAyudaFilterSelected==='valorayuda7') filteredArray = [...listBenefDates].filter(b => b.valor>200000 && b.valor<=300000);
      if (lastValorAyudaFilterSelected==='valorayuda8')  filteredArray = [...listBenefDates].filter(b => b.valor>300000 && b.valor<=400000);
      if (lastValorAyudaFilterSelected==='valorayuda9') filteredArray = [...listBenefDates].filter(b => b.valor>400000 && b.valor<=500000);
      if (lastValorAyudaFilterSelected==='valorayuda10')  filteredArray = [...listBenefDates].filter(b => b.valor>500000);
    }

    return filteredArray
  }

  // Filtros de Personas a Cargo
  const updatePersonasaCargoFiltro = (lastPersonasaCargoFilterSelected, filteredArray, isAddingFilter) => {
    if (isAddingFilter || filteredArray.length>0){
      if (lastPersonasaCargoFilterSelected==='personasacargo_0') filteredArray = [...filteredArray].filter(b => b.personasacargo===0);
      if (lastPersonasaCargoFilterSelected==='personasacargo_1') filteredArray = [...filteredArray].filter(b => b.personasacargo===1);
      if (lastPersonasaCargoFilterSelected==='personasacargo_2') filteredArray = [...filteredArray].filter(b => b.personasacargo===2);
      if (lastPersonasaCargoFilterSelected==='personasacargo_3') filteredArray = [...filteredArray].filter(b => b.personasacargo===3);
      if (lastPersonasaCargoFilterSelected==='personasacargo_4') filteredArray = [...filteredArray].filter(b => b.personasacargo===4);
      if (lastPersonasaCargoFilterSelected==='personasacargo_5') filteredArray = [...filteredArray].filter(b => b.personasacargo===5);
      if (lastPersonasaCargoFilterSelected==='personasacargo_5plus') filteredArray = [...filteredArray].filter(b => b.personasacargo>5);
      if (lastPersonasaCargoFilterSelected==='personasacargo_6') filteredArray = [...filteredArray].filter(b => b.personasacargo===6);
      if (lastPersonasaCargoFilterSelected==='personasacargo_7') filteredArray = [...filteredArray].filter(b => b.personasacargo===7);
      if (lastPersonasaCargoFilterSelected==='personasacargo_8') filteredArray = [...filteredArray].filter(b => b.personasacargo===8);
      if (lastPersonasaCargoFilterSelected==='personasacargo_9') filteredArray = [...filteredArray].filter(b => b.personasacargo===9);
      if (lastPersonasaCargoFilterSelected==='personasacargo_10') filteredArray = [...filteredArray].filter(b => b.personasacargo>=10);
    } else {
      if (lastPersonasaCargoFilterSelected==='personasacargo_0') filteredArray = [...listBenefDates].filter(b => b.personasacargo===0);
      if (lastPersonasaCargoFilterSelected==='personasacargo_1') filteredArray = [...listBenefDates].filter(b => b.personasacargo===1);
      if (lastPersonasaCargoFilterSelected==='personasacargo_2') filteredArray = [...listBenefDates].filter(b => b.personasacargo===2);
      if (lastPersonasaCargoFilterSelected==='personasacargo_3') filteredArray = [...listBenefDates].filter(b => b.personasacargo===3);
      if (lastPersonasaCargoFilterSelected==='personasacargo_4') filteredArray = [...listBenefDates].filter(b => b.personasacargo===4);
      if (lastPersonasaCargoFilterSelected==='personasacargo_5') filteredArray = [...listBenefDates].filter(b => b.personasacargo===5);
      if (lastPersonasaCargoFilterSelected==='personasacargo_5plus') filteredArray = [...listBenefDates].filter(b => b.personasacargo>5);
      if (lastPersonasaCargoFilterSelected==='personasacargo_6') filteredArray = [...listBenefDates].filter(b => b.personasacargo===6);
      if (lastPersonasaCargoFilterSelected==='personasacargo_7') filteredArray = [...listBenefDates].filter(b => b.personasacargo===7);
      if (lastPersonasaCargoFilterSelected==='personasacargo_8') filteredArray = [...listBenefDates].filter(b => b.personasacargo===8);
      if (lastPersonasaCargoFilterSelected==='personasacargo_9') filteredArray = [...listBenefDates].filter(b => b.personasacargo===9);
      if (lastPersonasaCargoFilterSelected==='personasacargo_10') filteredArray = [...listBenefDates].filter(b => b.personasacargo>=10);
    }

    return filteredArray
  }
  const updateActualizadoFiltro = (lastFechaFilterSelected, filteredArray, isAddingFilter) => {
    const d = moment(new Date(), "YYYY-MM-DD"); //.format("YYYY-MM-DD HH:mm:ss")
    const yesterday = moment(d).subtract(1 , 'day'); //d.setDate(d.getDate()-1);
    const l7days = moment(d).subtract(7 , 'day'); //d.setDate(d.getDate()-7);
    const l30days = moment(d).subtract(30 , 'day'); //d.setDate(d.getDate()-30);
    
    if (isAddingFilter || filteredArray.length>0){
      if (lastFechaFilterSelected==='last30days') filteredArray = [...filteredArray].filter(b => b.actualizacion>=l30days);
      if (lastFechaFilterSelected==='last7days') filteredArray = [...filteredArray].filter(b => b.actualizacion>=l7days);
      if (lastFechaFilterSelected==='ayer') filteredArray = [...filteredArray].filter(b => moment(b.actualizacion).format("YYYY-MM-DD")===yesterday.format("YYYY-MM-DD"));
      if (lastFechaFilterSelected==='hoy') filteredArray = [...filteredArray].filter(b => moment(b.actualizacion).format("YYYY-MM-DD")===d.format("YYYY-MM-DD"));
    } else {
      if (lastFechaFilterSelected==='last30days') filteredArray = [...listBenefDates].filter(b => b.actualizacion>=l30days);
      if (lastFechaFilterSelected==='last7days') filteredArray = [...listBenefDates].filter(b => b.actualizacion>=l7days);
      if (lastFechaFilterSelected==='ayer') filteredArray = [...listBenefDates].filter(b => moment(b.actualizacion).format("YYYY-MM-DD")===yesterday.format("YYYY-MM-DD"));
      if (lastFechaFilterSelected==='hoy') filteredArray = [...listBenefDates].filter(b => moment(b.actualizacion).format("YYYY-MM-DD")===d.format("YYYY-MM-DD"));
    }
    return filteredArray
  }
  const updateEdadFiltro = (lastEdadFilterSelected, filteredArray, isAddingFilter) => {
    if (isAddingFilter || filteredArray.length>0){
      if (lastEdadFilterSelected==='edadmenor') filteredArray = [...filteredArray].filter(b => b.edad<18);
      if (lastEdadFilterSelected==='edad18') filteredArray = [...filteredArray].filter(b => b.edad>=18 && b.edad<21);
      if (lastEdadFilterSelected==='edad21') filteredArray = [...filteredArray].filter(b => b.edad>=21 && b.edad<26);
      if (lastEdadFilterSelected==='edad26') filteredArray = [...filteredArray].filter(b => b.edad>=26 && b.edad<31);
      if (lastEdadFilterSelected==='edad31') filteredArray = [...filteredArray].filter(b => b.edad>=31 && b.edad<36);
      if (lastEdadFilterSelected==='edad36') filteredArray = [...filteredArray].filter(b => b.edad>=36 && b.edad<41);
      if (lastEdadFilterSelected==='edad41') filteredArray = [...filteredArray].filter(b => b.edad>=41 && b.edad<45);
      if (lastEdadFilterSelected==='edad45') filteredArray = [...filteredArray].filter(b => b.edad>=45);
    } else {
      if (lastEdadFilterSelected==='edadmenor') filteredArray = [...listBenefDates].filter(b => b.edad<18);
      if (lastEdadFilterSelected==='edad18') filteredArray = [...listBenefDates].filter(b => b.edad>=18 && b.edad<21);
      if (lastEdadFilterSelected==='edad21') filteredArray = [...listBenefDates].filter(b => b.edad>=21 && b.edad<26);
      if (lastEdadFilterSelected==='edad26') filteredArray = [...listBenefDates].filter(b => b.edad>=26 && b.edad<31);
      if (lastEdadFilterSelected==='edad31') filteredArray = [...listBenefDates].filter(b => b.edad>=31 && b.edad<36);
      if (lastEdadFilterSelected==='edad36') filteredArray = [...listBenefDates].filter(b => b.edad>=36 && b.edad<41);
      if (lastEdadFilterSelected==='edad41') filteredArray = [...listBenefDates].filter(b => b.edad>=41 && b.edad<45);
      if (lastEdadFilterSelected==='edad45') filteredArray = [...listBenefDates].filter(b => b.edad>=45);
    }
    
    return filteredArray
  }
  const updateEdadClubFiltro = (lastEdadClubFilterSelected, filteredArray, isAddingFilter) => {
    if (isAddingFilter || filteredArray.length>0){
      if (lastEdadClubFilterSelected==='edadclub1') filteredArray = [...filteredArray].filter(b => b.edadclub<1);
      if (lastEdadClubFilterSelected==='edadclub2') filteredArray = [...filteredArray].filter(b => b.edadclub>=1 && b.edadclub<2);
      if (lastEdadClubFilterSelected==='edadclub3') filteredArray = [...filteredArray].filter(b => b.edadclub>=2 && b.edadclub<5);
      if (lastEdadClubFilterSelected==='edadclub4') filteredArray = [...filteredArray].filter(b => b.edadclub>=5 && b.edadclub<10);
      if (lastEdadClubFilterSelected==='edadclub5') filteredArray = [...filteredArray].filter(b => b.edadclub>=10 && b.edadclub<15);
      if (lastEdadClubFilterSelected==='edadclub6') filteredArray = [...filteredArray].filter(b => b.edadclub>=15 && b.edadclub<20);
      if (lastEdadClubFilterSelected==='edadclub7') filteredArray = [...filteredArray].filter(b => b.edadclub>=20 && b.edadclub<25);
      if (lastEdadClubFilterSelected==='edadclub8') filteredArray = [...filteredArray].filter(b => b.edadclub>=25 && b.edadclub<30);
      if (lastEdadClubFilterSelected==='edadclub9') filteredArray = [...filteredArray].filter(b => b.edadclub>=30 && b.edadclub<35);
      if (lastEdadClubFilterSelected==='edadclub10') filteredArray = [...filteredArray].filter(b => b.edadclub>=35 && b.edadclub<40);
      if (lastEdadClubFilterSelected==='edadclub11') filteredArray = [...filteredArray].filter(b => b.edadclub>=40 && b.edadclub<45);
      if (lastEdadClubFilterSelected==='edadclub12') filteredArray = [...filteredArray].filter(b => b.edadclub>=45 && b.edadclub<50);
      if (lastEdadClubFilterSelected==='edadclub13') filteredArray = [...filteredArray].filter(b => b.edadclub>=50);
    } else {
      if (lastEdadClubFilterSelected==='edadclub1') filteredArray = [...listBenefDates].filter(b => b.edadclub<1);
      if (lastEdadClubFilterSelected==='edadclub2') filteredArray = [...listBenefDates].filter(b => b.edadclub>=1 && b.edadclub<2);
      if (lastEdadClubFilterSelected==='edadclub3') filteredArray = [...listBenefDates].filter(b => b.edadclub>=2 && b.edadclub<5);
      if (lastEdadClubFilterSelected==='edadclub4') filteredArray = [...listBenefDates].filter(b => b.edadclub>=5 && b.edadclub<10);
      if (lastEdadClubFilterSelected==='edadclub5') filteredArray = [...listBenefDates].filter(b => b.edadclub>=10 && b.edadclub<15);
      if (lastEdadClubFilterSelected==='edadclub6') filteredArray = [...listBenefDates].filter(b => b.edadclub>=15 && b.edadclub<20);
      if (lastEdadClubFilterSelected==='edadclub7') filteredArray = [...listBenefDates].filter(b => b.edadclub>=20 && b.edadclub<25);
      if (lastEdadClubFilterSelected==='edadclub8') filteredArray = [...listBenefDates].filter(b => b.edadclub>=25 && b.edadclub<30);
      if (lastEdadClubFilterSelected==='edadclub9') filteredArray = [...listBenefDates].filter(b => b.edadclub>=30 && b.edadclub<35);
      if (lastEdadClubFilterSelected==='edadclub10') filteredArray = [...listBenefDates].filter(b => b.edadclub>=35 && b.edadclub<40);
      if (lastEdadClubFilterSelected==='edadclub11') filteredArray = [...listBenefDates].filter(b => b.edadclub>=40 && b.edadclub<45);
      if (lastEdadClubFilterSelected==='edadclub12') filteredArray = [...listBenefDates].filter(b => b.edadclub>=45 && b.edadclub<50);
      if (lastEdadClubFilterSelected==='edadclub13') filteredArray = [...listBenefDates].filter(b => b.edadclub>=50);
    }
    return filteredArray
  }

  // Agrega filtro a la lista de beneficiarios
  const addFilter = (filtro, field) => { 
    let filtername = filtersname[filtro] || filtro;
    let filteredArray = [];
    let ultimoFiltro = [...listadeFiltros];
    const isFiltered = ultimoFiltro.filter(lf => lf.filtro===filtro);
    // Actualizo la lista de filtros agregando solo nuevos y/o únicos
    if (isFiltered && isFiltered.length===0){
      ultimoFiltro = [...ultimoFiltro].concat([{filtro:filtro, field:field}]);
      setListadeFiltros(ultimoFiltro);
    }
      
    if (ultimoFiltro.length===1){
      if (field!=='actualizacion' && field!=='edad' && field!=='edadclub' 
        && field!=='numentrega' && field!=='valor' 
        && field!=='personasacargo' ){
        filteredArray = listBenefDates.filter(b => b[field].toLowerCase()===filtername);
        // Tipo texto
        if (field==='tipoayuda') setHasTipoAyudaFiltro(true);
        if (field==='estadoayuda') setHasEstadoAyudaFiltro(true);
        if (field==='afectacion') setHasAfectacionFiltro(true);
        if (field==='prestarservicio') setHasPrestarServicioFiltro(true);
        if (field==='acuerdocorresp') setHasAcuerdoCorrespFiltro(true);
        if (field==='jefehogar') setHasJefeHogarFiltro(true);
        if (field==='estado') setHasEstadoFiltro(true);
        if (field==='actividad') setHasActividadFiltro(true);
        if (field==='categoria') setHasCategoriaFiltro(true);
        
      } else if (field==='personasacargo'){
        setHasPersonasaCargoFiltro(true);
        filteredArray = updatePersonasaCargoFiltro(filtro, filteredArray, false);
      } else if (field==='numentrega'){
        setHasEntregaFiltro(true);
        filteredArray = updateEntregaFiltro(filtro, filteredArray, false);
      } else if (field==='valor'){
        setHasValorAyudaFiltro(true);
        filteredArray = updateValorAyudaFiltro(filtro, filteredArray, false);
      } else if (field==='edad'){
        setHasEdadFiltro(true);
        filteredArray = updateEdadFiltro(filtro, filteredArray, false);
      } else if (field==='edadclub'){
        setHasEdadClubFiltro(true);
        filteredArray = updateEdadClubFiltro(filtro, filteredArray, false);
      } else if (field==='actualizacion') {
        setHasActualizadoFiltro(true);
        filteredArray = updateActualizadoFiltro(filtro, filteredArray, false);
      }
    } else {
      filteredArray = procesarFiltro(filtro, ultimoFiltro, true);
    }
    
    filtroGlobal(filtro, filteredArray);
  };

  const removeFilter = (filtro, field) => { 
    let filteredArray = [];
    let ultimoFiltro = [...listadeFiltros].filter(l => l.filtro!==filtro);
    setListadeFiltros(ultimoFiltro);
    
    if (ultimoFiltro.length<1){
      if (field==='tipoayuda') setHasTipoAyudaFiltro(false);
      if (field==='estadoayuda') setHasEstadoAyudaFiltro(false);
      if (field==='afectacion') setHasAfectacionFiltro(false);
      if (field==='prestarservicio') setHasPrestarServicioFiltro(false);
      if (field==='acuerdocorresp') setHasAcuerdoCorrespFiltro(false);
      if (field==='jefehogar') setHasJefeHogarFiltro(false);
      if (field==='estado') setHasEstadoFiltro(false);
      if (field==='actividad') setHasActividadFiltro(false);
      if (field==='categoria') setHasCategoriaFiltro(false);
      if (field==='personasacargo') setHasPersonasaCargoFiltro(false);
      if (field==='numentrega') setHasEntregaFiltro(false);
      if (field==='valor') setHasValorAyudaFiltro(false);
      if (field==='edad') setHasEdadFiltro(false);
      if (field==='edadclub') setHasEdadClubFiltro(false);
      if (field==='actualizacion') setHasActualizadoFiltro(false);

      filteredArray = [...listBenefDates];
    } else {
      filteredArray = procesarFiltro(filtro, ultimoFiltro, false);
    }
    
    filtroGlobal(filtro, filteredArray);
  };

  const clearFilter = (filtros) => {
    let ultimoFiltro = [...listadeFiltros];
    // Remueve todos los filtros seleccionados
    filtros.forEach((f, idx) => {
      ultimoFiltro = [...ultimoFiltro].filter(itm => itm.filtro!==f)
    });

    let filteredArray = procesarFiltro(null, ultimoFiltro, false);
    
    filtroGlobal(null, filteredArray);
  }

  // Filtro por Activo / No Activos
  // const [selectedActivos, setSelectedActivos] = useState(false);
  // const [selectedNoActivos, setSelectedNoActivos] = useState(false);
  // const filterSublistTotal = (filtro) => { 
  //   if (filtro==='activos'){
  //     (!selectedActivos) ? addFilter(filtro, 'activo') : removeFilter(filtro, 'activo');
  //     setSelectedActivos(!selectedActivos);
  //     setSelectedNoActivos(false);
  //   }
  //   if (filtro==='noactivos'){
  //     (!selectedNoActivos) ? addFilter(filtro, 'activo') : removeFilter(filtro, 'activo');
  //     setSelectedNoActivos(!selectedNoActivos);
  //     setSelectedActivos(false);
  //   }
  // };

  // Filtro por Bonos / Mercados
  const [selectedBonos, setSelectedBonos] = useState(false);
  const [selectedMercados, setSelectedMercados] = useState(false);
  const filterSublistTipoAyuda = (filtro) => { 
    if (filtro==='bono'){
      (!selectedBonos) ? addFilter(filtro, 'tipoayuda') : removeFilter(filtro, 'tipoayuda');
      setSelectedBonos(!selectedBonos);
      setSelectedMercados(false);
    }
    if (filtro==='mercado'){
      (!selectedMercados) ? addFilter(filtro, 'tipoayuda') : removeFilter(filtro, 'tipoayuda');
      setSelectedMercados(!selectedMercados);
      setSelectedBonos(false);
    }
  };

  // Filtro por Estado Ayuda
  const [selectedRedimido, setSelectedRedimido] = useState(false);
  const [selectedCaducado, setSelectedCaducado] = useState(false);
  const filterSublistEstadoAyuda = (filtro) => { 
    if (filtro==='redimido'){
      (!selectedRedimido) ? addFilter(filtro, 'estadoayuda') : removeFilter(filtro, 'estadoayuda');
      setSelectedRedimido(!selectedRedimido);
      setSelectedCaducado(false);
    }
    if (filtro==='caducado'){
      (!selectedCaducado) ? addFilter(filtro, 'estadoayuda') : removeFilter(filtro, 'estadoayuda');
      setSelectedCaducado(!selectedCaducado);
      setSelectedRedimido(false);
    }
  };

  // Filtro por Afectación
  const [selectedAfectacionAlta, setSelectedAfectacionAlta] = useState(false);
  const [selectedAfectacionMedia, setSelectedAfectacionMedia] = useState(false);
  const [selectedAfectacionBaja, setSelectedAfectacionBaja] = useState(false);
  const [selectedAfectacionNoAplica, setSelectedAfectacionNoAplica] = useState(false);
  const clearFilterSublistAfectacion = () => {
    setSelectedAfectacionAlta(false);
    setSelectedAfectacionMedia(false);
    setSelectedAfectacionBaja(false);
    setSelectedAfectacionNoAplica(false);
  }
  const filterSublistAfectacion = (filtro) => { 
    clearFilterSublistAfectacion();
    if (filtro==='alta'){
      (!selectedAfectacionAlta) ? addFilter(filtro, 'afectacion') : removeFilter(filtro, 'afectacion');
      setSelectedAfectacionAlta(!selectedAfectacionAlta);
    }
    if (filtro==='media'){
      (!selectedAfectacionMedia) ? addFilter(filtro, 'afectacion') : removeFilter(filtro, 'afectacion');
      setSelectedAfectacionMedia(!selectedAfectacionMedia);
    }
    if (filtro==='baja'){
      (!selectedAfectacionBaja) ? addFilter(filtro, 'afectacion') : removeFilter(filtro, 'afectacion');
      setSelectedAfectacionBaja(!selectedAfectacionBaja);
    }
    if (filtro==='noaplica'){
      (!selectedAfectacionNoAplica) ? addFilter(filtro, 'afectacion') : removeFilter(filtro, 'afectacion');
      setSelectedAfectacionNoAplica(!selectedAfectacionNoAplica);
    }
    
  };

  // Filtro por Prestar Servicio
  const [selectedPrestarServicio, setSelectedPrestarServicio] = useState(false);
  const [selectedNoPrestarServicio, setSelectedNoPrestarServicio] = useState(false);
  const filterSublistPrestarServicio = (filtro) => { 
    if (filtro==='prestarservicio'){
      (!selectedPrestarServicio) ? addFilter(filtro, 'prestarservicio') : removeFilter(filtro, 'prestarservicio');
      setSelectedPrestarServicio(!selectedPrestarServicio);
      setSelectedNoPrestarServicio(false);
    }
    if (filtro==='noprestarservicio'){
      (!selectedNoPrestarServicio) ? addFilter(filtro, 'prestarservicio') : removeFilter(filtro, 'prestarservicio');
      setSelectedNoPrestarServicio(!selectedNoPrestarServicio);
      setSelectedPrestarServicio(false);
    }
  };

  // Filtro Acuerdo corresponsabilidad
  const [selectedAcuerdoCorresp, setSelectedAcuerdoCorresp] = useState(false);
  const [selectedNoAcuerdoCorresp, setSelectedNoAcuerdoCorresp] = useState(false);
  const filterSublistAcuerdoCorresp = (filtro) => { 
    if (filtro==='acuerdocorresp'){
      (!selectedAcuerdoCorresp) ? addFilter(filtro, 'acuerdocorresp') : removeFilter(filtro, 'acuerdocorresp');
      setSelectedAcuerdoCorresp(!selectedAcuerdoCorresp);
      setSelectedNoAcuerdoCorresp(false);
    }
    if (filtro==='noacuerdocorresp'){
      (!selectedNoAcuerdoCorresp) ? addFilter(filtro, 'acuerdocorresp') : removeFilter(filtro, 'acuerdocorresp');
      setSelectedNoAcuerdoCorresp(!selectedNoAcuerdoCorresp);
      setSelectedAcuerdoCorresp(false);
    }
  };

  // Filtro por Jefe Hogar
  const [selectedJefeHogar, setSelectedJefeHogar] = useState(false);
  const [selectedNoJefeHogar, setSelectedNoJefeHogar] = useState(false);
  const [selectedJefehogarComp, setSelectedJefehogarComp] = useState(false);
  const filterSublistJefeHogar = (filtro) => { 
    if (filtro==='jefehogar'){
      (!selectedJefeHogar) ? addFilter(filtro, 'jefehogar') : removeFilter(filtro, 'jefehogar');
      setSelectedJefeHogar(!selectedJefeHogar);
      setSelectedNoJefeHogar(false);
      setSelectedJefehogarComp(false);
    }
    if (filtro==='nojefehogar'){
      (!selectedNoJefeHogar) ? addFilter(filtro, 'jefehogar') : removeFilter(filtro, 'jefehogar');
      setSelectedNoJefeHogar(!selectedNoJefeHogar);
      setSelectedJefeHogar(false);
      setSelectedJefehogarComp(false);
    }
    if (filtro==='jefehogarcomp'){
      (!selectedJefehogarComp) ? addFilter(filtro, 'jefehogar') : removeFilter(filtro, 'jefehogar');
      setSelectedJefehogarComp(!selectedJefehogarComp);
      setSelectedNoJefeHogar(false);
      setSelectedJefeHogar(false);
    }
    
  };

  // Filtro por Entrega
  const [selectedEntrega1, setSelectedEntrega1] = useState(false);
  const [selectedEntrega2, setSelectedEntrega2] = useState(false);
  const [selectedEntrega3, setSelectedEntrega3] = useState(false);
  const [selectedEntrega4, setSelectedEntrega4] = useState(false);
  const [selectedEntrega5, setSelectedEntrega5] = useState(false);
  const [selectedEntrega6, setSelectedEntrega6] = useState(false);
  const [selectedEntrega7, setSelectedEntrega7] = useState(false);
  const [selectedEntrega8, setSelectedEntrega8] = useState(false);
  const [selectedEntrega9, setSelectedEntrega9] = useState(false);
  const [selectedEntrega10, setSelectedEntrega10] = useState(false);
  const clearFilterSublistEntrega = () => {
    setSelectedEntrega1(false);
    setSelectedEntrega2(false);
    setSelectedEntrega3(false);
    setSelectedEntrega4(false);
    setSelectedEntrega5(false);
    setSelectedEntrega6(false);
    setSelectedEntrega7(false);
    setSelectedEntrega8(false);
    setSelectedEntrega9(false);
    setSelectedEntrega10(false);
  }
  const filterSublistEntrega = (filtro) => { 
    clearFilterSublistEntrega();
    if (filtro==='entrega_1'){
      (!selectedEntrega1) ? addFilter(filtro, 'numentrega') : removeFilter(filtro, 'numentrega');
      setSelectedEntrega1(!selectedEntrega1);
    }
    if (filtro==='entrega_2'){
      (!selectedEntrega2) ? addFilter(filtro, 'numentrega') : removeFilter(filtro, 'numentrega');
      setSelectedEntrega2(!selectedEntrega2);
    }
    if (filtro==='entrega_3'){
      (!selectedEntrega3) ? addFilter(filtro, 'numentrega') : removeFilter(filtro, 'numentrega');
      setSelectedEntrega3(!selectedEntrega3);
    }
    if (filtro==='entrega_4'){
      (!selectedEntrega4) ? addFilter(filtro, 'numentrega') : removeFilter(filtro, 'numentrega');
      setSelectedEntrega4(!selectedEntrega4);
    }
    if (filtro==='entrega_5'){
      (!selectedEntrega5) ? addFilter(filtro, 'numentrega') : removeFilter(filtro, 'numentrega');
      setSelectedEntrega5(!selectedEntrega5);
    }
    if (filtro==='entrega_6'){
      (!selectedEntrega6) ? addFilter(filtro, 'numentrega') : removeFilter(filtro, 'numentrega');
      setSelectedEntrega6(!selectedEntrega6);
    }
    if (filtro==='entrega_7'){
      (!selectedEntrega7) ? addFilter(filtro, 'numentrega') : removeFilter(filtro, 'numentrega');
      setSelectedEntrega7(!selectedEntrega7);
    }
    if (filtro==='entrega_8'){
      (!selectedEntrega8) ? addFilter(filtro, 'numentrega') : removeFilter(filtro, 'numentrega');
      setSelectedEntrega8(!selectedEntrega8);
    }
    if (filtro==='entrega_9'){
      (!selectedEntrega9) ? addFilter(filtro, 'numentrega') : removeFilter(filtro, 'numentrega');
      setSelectedEntrega9(!selectedEntrega9);
    }
    if (filtro==='entrega_10'){
      (!selectedEntrega10) ? addFilter(filtro, 'numentrega') : removeFilter(filtro, 'numentrega');
      setSelectedEntrega10(!selectedEntrega10);
    }
    
  };
  
  // Filtro Valor Ayuda
  const [selectedValorAyuda1, setSelectedValorAyuda1] = useState(false);
  const [selectedValorAyuda2, setSelectedValorAyuda2] = useState(false);
  const [selectedValorAyuda3, setSelectedValorAyuda3] = useState(false);
  const [selectedValorAyuda4, setSelectedValorAyuda4] = useState(false);
  const [selectedValorAyuda5, setSelectedValorAyuda5] = useState(false);
  const [selectedValorAyuda6, setSelectedValorAyuda6] = useState(false);
  const [selectedValorAyuda7, setSelectedValorAyuda7] = useState(false);
  const [selectedValorAyuda8, setSelectedValorAyuda8] = useState(false);
  const [selectedValorAyuda9, setSelectedValorAyuda9] = useState(false);
  const [selectedValorAyuda10, setSelectedValorAyuda10] = useState(false);
  const clearFilterSublistValorAyuda = () => {
    setSelectedValorAyuda1(false);
    setSelectedValorAyuda2(false);
    setSelectedValorAyuda3(false);
    setSelectedValorAyuda4(false);
    setSelectedValorAyuda5(false);
    setSelectedValorAyuda6(false);
    setSelectedValorAyuda7(false);
    setSelectedValorAyuda8(false);
    setSelectedValorAyuda9(false);
    setSelectedValorAyuda10(false);
  }
  const filterSublistValorAyuda = (filtro) => { 
    clearFilterSublistValorAyuda();
    if (filtro==='valorayuda1'){
      (!selectedValorAyuda1) ? addFilter(filtro, 'valor') : removeFilter(filtro, 'valor');
      setSelectedValorAyuda1(!selectedValorAyuda1);
    }
    if (filtro==='valorayuda2'){
      (!selectedValorAyuda2) ? addFilter(filtro, 'valor') : removeFilter(filtro, 'valor');
      setSelectedValorAyuda2(!selectedValorAyuda2);
    }
    if (filtro==='valorayuda3'){
      (!selectedValorAyuda3) ? addFilter(filtro, 'valor') : removeFilter(filtro, 'valor');
      setSelectedValorAyuda3(!selectedValorAyuda3);
    }
    if (filtro==='valorayuda4'){
      (!selectedValorAyuda4) ? addFilter(filtro, 'valor') : removeFilter(filtro, 'valor');
      setSelectedValorAyuda4(!selectedValorAyuda4);
    }
    if (filtro==='valorayuda5'){
      (!selectedValorAyuda5) ? addFilter(filtro, 'valor') : removeFilter(filtro, 'valor');
      setSelectedValorAyuda5(!selectedValorAyuda5);
    }
    if (filtro==='valorayuda6'){
      (!selectedValorAyuda6) ? addFilter(filtro, 'valor') : removeFilter(filtro, 'valor');
      setSelectedValorAyuda6(!selectedValorAyuda6);
    }
    if (filtro==='valorayuda7'){
      (!selectedValorAyuda7) ? addFilter(filtro, 'valor') : removeFilter(filtro, 'valor');
      setSelectedValorAyuda7(!selectedValorAyuda7);
    }
    if (filtro==='valorayuda8'){
      (!selectedValorAyuda8) ? addFilter(filtro, 'valor') : removeFilter(filtro, 'valor');
      setSelectedValorAyuda8(!selectedValorAyuda8);
    }
    if (filtro==='valorayuda9'){
      (!selectedValorAyuda9) ? addFilter(filtro, 'valor') : removeFilter(filtro, 'valor');
      setSelectedValorAyuda9(!selectedValorAyuda9);
    }
    if (filtro==='valorayuda10'){
      (!selectedValorAyuda10) ? addFilter(filtro, 'valor') : removeFilter(filtro, 'valor');
      setSelectedValorAyuda10(!selectedValorAyuda10);
    }
    
  };

  // Filtro Personas a Cargo
  const [selectedPersonasaCargo0, setSelectedPersonasaCargo0] = useState(false);
  const [selectedPersonasaCargo1, setSelectedPersonasaCargo1] = useState(false);
  const [selectedPersonasaCargo2, setSelectedPersonasaCargo2] = useState(false);
  const [selectedPersonasaCargo3, setSelectedPersonasaCargo3] = useState(false);
  const [selectedPersonasaCargo4, setSelectedPersonasaCargo4] = useState(false);
  const [selectedPersonasaCargo5, setSelectedPersonasaCargo5] = useState(false);
  const [selectedPersonasaCargo5plus, setSelectedPersonasaCargo5plus] = useState(false);
  const [selectedPersonasaCargo6, setSelectedPersonasaCargo6] = useState(false);
  const [selectedPersonasaCargo7, setSelectedPersonasaCargo7] = useState(false);
  const [selectedPersonasaCargo8, setSelectedPersonasaCargo8] = useState(false);
  const [selectedPersonasaCargo9, setSelectedPersonasaCargo9] = useState(false);
  const [selectedPersonasaCargo10, setSelectedPersonasaCargo10] = useState(false);
  const clearFilterSublistPersonasaCargo = () => {
    setSelectedPersonasaCargo0(false);
    setSelectedPersonasaCargo1(false);
    setSelectedPersonasaCargo2(false);
    setSelectedPersonasaCargo3(false);
    setSelectedPersonasaCargo4(false);
    setSelectedPersonasaCargo5(false);
    setSelectedPersonasaCargo5plus(false);
    setSelectedPersonasaCargo6(false);
    setSelectedPersonasaCargo7(false);
    setSelectedPersonasaCargo8(false);
    setSelectedPersonasaCargo9(false);
    setSelectedPersonasaCargo10(false);
  }
  const filterSublistPersonasaCargo = (filtro) => { 
    clearFilterSublistPersonasaCargo();
    if (filtro==='personasacargo_0'){
      (!selectedPersonasaCargo0) ? addFilter(filtro, 'personasacargo') : removeFilter(filtro, 'personasacargo');
      setSelectedPersonasaCargo0(!selectedPersonasaCargo0);
    }
    if (filtro==='personasacargo_1'){
      (!selectedPersonasaCargo1) ? addFilter(filtro, 'personasacargo') : removeFilter(filtro, 'personasacargo');
      setSelectedPersonasaCargo1(!selectedPersonasaCargo1);
    }
    if (filtro==='personasacargo_2'){
      (!selectedPersonasaCargo2) ? addFilter(filtro, 'personasacargo') : removeFilter(filtro, 'personasacargo');
      setSelectedPersonasaCargo2(!selectedPersonasaCargo2);
    }
    if (filtro==='personasacargo_3'){
      (!selectedPersonasaCargo3) ? addFilter(filtro, 'personasacargo') : removeFilter(filtro, 'personasacargo');
      setSelectedPersonasaCargo3(!selectedPersonasaCargo3);
    }
    if (filtro==='personasacargo_4'){
      (!selectedPersonasaCargo4) ? addFilter(filtro, 'personasacargo') : removeFilter(filtro, 'personasacargo');
      setSelectedPersonasaCargo4(!selectedPersonasaCargo4);
    }
    if (filtro==='personasacargo_5'){
      (!selectedPersonasaCargo5) ? addFilter(filtro, 'personasacargo') : removeFilter(filtro, 'personasacargo');
      setSelectedPersonasaCargo5(!selectedPersonasaCargo5);
    }
    if (filtro==='personasacargo_5plus'){
      (!selectedPersonasaCargo5plus) ? addFilter(filtro, 'personasacargo') : removeFilter(filtro, 'personasacargo');
      setSelectedPersonasaCargo5plus(!selectedPersonasaCargo5plus);
    }
    if (filtro==='personasacargo_6'){
      (!selectedPersonasaCargo6) ? addFilter(filtro, 'personasacargo') : removeFilter(filtro, 'personasacargo');
      setSelectedPersonasaCargo6(!selectedPersonasaCargo6);
    }
    if (filtro==='personasacargo_7'){
      (!selectedPersonasaCargo7) ? addFilter(filtro, 'personasacargo') : removeFilter(filtro, 'personasacargo');
      setSelectedPersonasaCargo7(!selectedPersonasaCargo7);
    }
    if (filtro==='personasacargo_8'){
      (!selectedPersonasaCargo8) ? addFilter(filtro, 'personasacargo') : removeFilter(filtro, 'personasacargo');
      setSelectedPersonasaCargo8(!selectedPersonasaCargo8);
    }
    if (filtro==='personasacargo_9'){
      (!selectedPersonasaCargo9) ? addFilter(filtro, 'personasacargo') : removeFilter(filtro, 'personasacargo');
      setSelectedPersonasaCargo9(!selectedPersonasaCargo9);
    }
    if (filtro==='personasacargo_10'){
      (!selectedPersonasaCargo10) ? addFilter(filtro, 'personasacargo') : removeFilter(filtro, 'personasacargo');
      setSelectedPersonasaCargo10(!selectedPersonasaCargo10);
    }
    
  };

  // Filtro por Estado
  const [selectedActualizado, setSelectedActualizado] = useState(false);
  const [selectedPendiente, setSelectedPendiente] = useState(false);
  const [selectedVisitar, setSelectedVisitar] = useState(false);
  const [selectedRevisar, setSelectedRevisar] = useState(false);
  const [selectedPrueba, setSelectedPrueba] = useState(false);
  const [selectedRetirado, setSelectedRetirado] = useState(false);
  const [selectedNoApto, setSelectedNoApto] = useState(false);
  const [selectedSuspendido, setSelectedSuspendido] = useState(false);
  const filterSublistEstado = (filtro) => { 
    if (filtro==='actualizado'){
      (!selectedActualizado) ? addFilter(filtro, 'estado') : removeFilter(filtro, 'estado');
      setSelectedActualizado(!selectedActualizado);
    }
    if (filtro==='pendiente'){
      (!selectedPendiente) ? addFilter(filtro, 'estado') : removeFilter(filtro, 'estado');
      setSelectedPendiente(!selectedPendiente);
    }
    if (filtro==='visitar'){
      (!selectedVisitar) ? addFilter(filtro, 'estado') : removeFilter(filtro, 'estado');
      setSelectedVisitar(!selectedVisitar);
    }
    if (filtro==='revisar'){
      (!selectedRevisar) ? addFilter(filtro, 'estado') : removeFilter(filtro, 'estado');
      setSelectedRevisar(!selectedRevisar);
    }
    if (filtro==='prueba'){
      (!selectedPrueba) ? addFilter(filtro, 'estado') : removeFilter(filtro, 'estado');
      setSelectedPrueba(!selectedPrueba);
    }
    if (filtro==='retirado'){
      (!selectedRetirado) ? addFilter(filtro, 'estado') : removeFilter(filtro, 'estado');
      setSelectedRetirado(!selectedRetirado);
    }
    if (filtro==='no apto'){
      (!selectedNoApto) ? addFilter(filtro, 'estado') : removeFilter(filtro, 'estado');
      setSelectedNoApto(!selectedNoApto);
    }
    if (filtro==='suspendido'){
      (!selectedSuspendido) ? addFilter(filtro, 'estado') : removeFilter(filtro, 'estado');
      setSelectedSuspendido(!selectedSuspendido);
    }
  };
  
  // Filtro por Actividad
  const [selectedCaddie, setSelectedCaddie] = useState(false);
  const [selectedBolero, setSelectedBolero] = useState(false);
  const [selectedBoleador, setSelectedBoleador] = useState(false);
  const [selectedMonitor, setSelectedMonitor] = useState(false);
  const [selectedEntrenador, setSelectedEntrenador] = useState(false);
  const [selectedExterno, setSelectedExterno] = useState(false);
  const [selectedEmpleadoClub, setSelectedEmpleadoClub] = useState(false);
  const [selectedEmpleadoFundacion, setSelectedEmpleadoFundacion] = useState(false);
  const [selectedContratista, setSelectedContratista] = useState(false);
  const [selectedAgencia, setSelectedAgencia] = useState(false);
  const filterSublistActividad = (filtro) => { 
    if (filtro==='caddie'){
      (!selectedCaddie) ? addFilter(filtro, 'actividad') : removeFilter(filtro, 'actividad');
      setSelectedCaddie(!selectedCaddie);
    }
    if (filtro==='bolero'){
      (!selectedBolero) ? addFilter(filtro, 'actividad') : removeFilter(filtro, 'actividad');
      setSelectedBolero(!selectedBolero);
    }
    if (filtro==='boleador'){
      (!selectedBoleador) ? addFilter(filtro, 'actividad') : removeFilter(filtro, 'actividad');
      setSelectedBoleador(!selectedBoleador);
    }
    if (filtro==='monitor'){
      (!selectedMonitor) ? addFilter(filtro, 'actividad') : removeFilter(filtro, 'actividad');
      setSelectedMonitor(!selectedMonitor);
    }
    if (filtro==='entrenador'){
      (!selectedEntrenador) ? addFilter(filtro, 'actividad') : removeFilter(filtro, 'actividad');
      setSelectedEntrenador(!selectedEntrenador);
    }
    if (filtro==='externo'){
      (!selectedExterno) ? addFilter(filtro, 'actividad') : removeFilter(filtro, 'actividad');
      setSelectedExterno(!selectedExterno);
    }
    if (filtro==='empleado club'){
      (!selectedEmpleadoClub) ? addFilter(filtro, 'actividad') : removeFilter(filtro, 'actividad');
      setSelectedEmpleadoClub(!selectedEmpleadoClub);
    }
    if (filtro==='empleado fundación'){
      (!selectedEmpleadoFundacion) ? addFilter(filtro, 'actividad') : removeFilter(filtro, 'actividad');
      setSelectedEmpleadoFundacion(!selectedEmpleadoFundacion);
    }
    if (filtro==='contratista'){
      (!selectedContratista) ? addFilter(filtro, 'actividad') : removeFilter(filtro, 'actividad');
      setSelectedContratista(!selectedContratista);
    }
    if (filtro==='agencia de empleo'){
      (!selectedAgencia) ? addFilter(filtro, 'actividad') : removeFilter(filtro, 'actividad');
      setSelectedAgencia(!selectedAgencia);
    }
  };

  // Filtro por Actividad
  const [selectedPrimera, setSelectedPrimera] = useState(false);
  const [selectedSegunda, setSelectedSegunda] = useState(false);
  const [selectedTercera, setSelectedTercera] = useState(false);
  const [selectedPE1, setSelectedPE1] = useState(false);
  const [selectedPE2, setSelectedPE2] = useState(false);
  const [selectedPE3, setSelectedPE3] = useState(false);
  const [selectedPP3, setSelectedPP3] = useState(false);
  const [selectedServiciosgenerales, setSelectedServiciosgenerales] = useState(false);
  const [selectedCampogolf, setSelectedCampogolf] = useState(false);
  const [selectedTiendagolf, setSelectedTiendagolf] = useState(false);
  const [selectedLavadero, setSelectedLavadero] = useState(false);
  const [selectedCocina, setSelectedCocina] = useState(false);
  const [selectedMasajes, setSelectedMasajes] = useState(false);
  const [selectedCholados, setSelectedCholados] = useState(false);
  const [selectedPeluqueria, setSelectedPeluqueria] = useState(false);
  const filterSublistCategoria = (filtro) => { 
    if (filtro==='primera'){
      (!selectedPrimera) ? addFilter(filtro, 'categoria') : removeFilter(filtro, 'categoria');
      setSelectedPrimera(!selectedPrimera);
    }
    if (filtro==='segunda'){
      (!selectedSegunda) ? addFilter(filtro, 'categoria') : removeFilter(filtro, 'categoria');
      setSelectedSegunda(!selectedSegunda);
    }
    if (filtro==='tercera'){
      (!selectedTercera) ? addFilter(filtro, 'categoria') : removeFilter(filtro, 'categoria');
      setSelectedTercera(!selectedTercera);
    }
    if (filtro==='pe1'){
      (!selectedPE1) ? addFilter(filtro, 'categoria') : removeFilter(filtro, 'categoria');
      setSelectedPE1(!selectedPE1);
    }
    if (filtro==='pe2'){
      (!selectedPE2) ? addFilter(filtro, 'categoria') : removeFilter(filtro, 'categoria');
      setSelectedPE2(!selectedPE2);
    }
    if (filtro==='pe3'){
      (!selectedPE3) ? addFilter(filtro, 'categoria') : removeFilter(filtro, 'categoria');
      setSelectedPE3(!selectedPE3);
    }
    if (filtro==='pp3'){
      (!selectedPP3) ? addFilter(filtro, 'categoria') : removeFilter(filtro, 'categoria');
      setSelectedPP3(!selectedPP3);
    }
    if (filtro==='servicios generales'){
      (!selectedServiciosgenerales) ? addFilter(filtro, 'categoria') : removeFilter(filtro, 'categoria');
      setSelectedServiciosgenerales(!selectedServiciosgenerales);
    }
    if (filtro==='campo golf'){
      (!selectedCampogolf) ? addFilter(filtro, 'categoria') : removeFilter(filtro, 'categoria');
      setSelectedCampogolf(!selectedCampogolf);
    }
    if (filtro==='tienda golf'){
      (!selectedTiendagolf) ? addFilter(filtro, 'categoria') : removeFilter(filtro, 'categoria');
      setSelectedTiendagolf(!selectedTiendagolf);
    }
    if (filtro==='lavadero'){
      (!selectedLavadero) ? addFilter(filtro, 'categoria') : removeFilter(filtro, 'categoria');
      setSelectedLavadero(!selectedLavadero);
    }
    if (filtro==='cocina'){
      (!selectedCocina) ? addFilter(filtro, 'categoria') : removeFilter(filtro, 'categoria');
      setSelectedCocina(!selectedCocina);
    }
    if (filtro==='masajes'){
      (!selectedMasajes) ? addFilter(filtro, 'categoria') : removeFilter(filtro, 'categoria');
      setSelectedMasajes(!selectedMasajes);
    }
    if (filtro==='cholados'){
      (!selectedCholados) ? addFilter(filtro, 'categoria') : removeFilter(filtro, 'categoria');
      setSelectedCholados(!selectedCholados);
    }
    if (filtro==='peluquería'){
      (!selectedPeluqueria) ? addFilter(filtro, 'categoria') : removeFilter(filtro, 'categoria');
      setSelectedPeluqueria(!selectedPeluqueria);
    }
  };

  // Filtro Actualizado
  const [selectedHoy, setSelectedHoy] = useState(false);
  const [selectedAyer, setSelectedAyer] = useState(false);
  const [selectedLast7days, setSelectedLast7days] = useState(false);
  const [selectedLast30days, setSelectedLast30days] = useState(false);
  const [selectedRangoFechas, setSelectedRangoFechas] = useState(false);
  const clearFilterSublistActualizado = () => {
    setSelectedHoy(false);
    setSelectedAyer(false);
    setSelectedLast7days(false);
    setSelectedLast30days(false);
    setSelectedRangoFechas(false);
  }
  const filterSublistActualizado = (filtro) => { 
    clearFilterSublistActualizado();
    if (filtro==='hoy'){
      (!selectedHoy) ? addFilter(filtro, 'actualizacion') : removeFilter(filtro, 'actualizacion');
      setSelectedHoy(!selectedHoy);
    }
    if (filtro==='ayer'){
      (!selectedAyer) ? addFilter(filtro, 'actualizacion') : removeFilter(filtro, 'actualizacion');
      setSelectedAyer(!selectedAyer);
    }
    if (filtro==='last7days'){
      (!selectedLast7days) ? addFilter(filtro, 'actualizacion') : removeFilter(filtro, 'actualizacion');
      setSelectedLast7days(!selectedLast7days);
    }
    if (filtro==='last30days'){
      (!selectedLast30days) ? addFilter(filtro, 'actualizacion') : removeFilter(filtro, 'actualizacion');
      setSelectedLast30days(!selectedLast30days);
    }
    if (filtro==='rangofechas'){
      // (!selectedRangoFechas) ? addFilter(filtro, 'actualizacion') : removeFilter(filtro, 'actualizacion');
      setSelectedRangoFechas(!selectedRangoFechas);
    }
    
  };

  // Filtro Edad
  const [selectedEdadmenor, setSelectedEdadmenor] = useState(false);
  const [selectedEdad18, setSelectedEdad18] = useState(false);
  const [selectedEdad21, setSelectedEdad21] = useState(false);
  const [selectedEdad26, setSelectedEdad26] = useState(false);
  const [selectedEdad31, setSelectedEdad31] = useState(false);
  const [selectedEdad36, setSelectedEdad36] = useState(false);
  const [selectedEdad41, setSelectedEdad41] = useState(false);
  const [selectedEdad45, setSelectedEdad45] = useState(false);
  const clearFilterSublistEdad = () => {
    setSelectedEdadmenor(false);
    setSelectedEdad18(false);
    setSelectedEdad21(false);
    setSelectedEdad26(false);
    setSelectedEdad31(false);
    setSelectedEdad36(false);
    setSelectedEdad41(false);
    setSelectedEdad45(false);
  }
  const filterSublistEdad = (filtro) => { 
    clearFilterSublistEdad();
    if (filtro==='edadmenor'){
      (!selectedEdadmenor) ? addFilter(filtro, 'edad') : removeFilter(filtro, 'edad');
      setSelectedEdadmenor(!selectedEdadmenor);
    }
    if (filtro==='edad18'){
      (!selectedEdad18) ? addFilter(filtro, 'edad') : removeFilter(filtro, 'edad');
      setSelectedEdad18(!selectedEdad18);
    }
    if (filtro==='edad21'){
      (!selectedEdad21) ? addFilter(filtro, 'edad') : removeFilter(filtro, 'edad');
      setSelectedEdad21(!selectedEdad21);
    }
    if (filtro==='edad26'){
      (!selectedEdad26) ? addFilter(filtro, 'edad') : removeFilter(filtro, 'edad');
      setSelectedEdad26(!selectedEdad26);
    }
    if (filtro==='edad31'){
      (!selectedEdad31) ? addFilter(filtro, 'edad') : removeFilter(filtro, 'edad');
      setSelectedEdad31(!selectedEdad31);
    }
    if (filtro==='edad36'){
      (!selectedEdad36) ? addFilter(filtro, 'edad') : removeFilter(filtro, 'edad');
      setSelectedEdad36(!selectedEdad36);
    }
    if (filtro==='edad41'){
      (!selectedEdad41) ? addFilter(filtro, 'edad') : removeFilter(filtro, 'edad');
      setSelectedEdad41(!selectedEdad41);
    }
    if (filtro==='edad45'){
      (!selectedEdad45) ? addFilter(filtro, 'edad') : removeFilter(filtro, 'edad');
      setSelectedEdad45(!selectedEdad45);
    }
    
  };

  // Filtro EdadClub
  const [selectedEdadClub1, setSelectedEdadClub1] = useState(false);
  const [selectedEdadClub2, setSelectedEdadClub2] = useState(false);
  const [selectedEdadClub3, setSelectedEdadClub3] = useState(false);
  const [selectedEdadClub4, setSelectedEdadClub4] = useState(false);
  const [selectedEdadClub5, setSelectedEdadClub5] = useState(false);
  const [selectedEdadClub6, setSelectedEdadClub6] = useState(false);
  const [selectedEdadClub7, setSelectedEdadClub7] = useState(false);
  const [selectedEdadClub8, setSelectedEdadClub8] = useState(false);
  const [selectedEdadClub9, setSelectedEdadClub9] = useState(false);
  const [selectedEdadClub10, setSelectedEdadClub10] = useState(false);
  const [selectedEdadClub11, setSelectedEdadClub11] = useState(false);
  const [selectedEdadClub12, setSelectedEdadClub12] = useState(false);
  const [selectedEdadClub13, setSelectedEdadClub13] = useState(false);
  const clearFilterSublistEdadClub = () => {
    setSelectedEdadClub1(false);
    setSelectedEdadClub2(false);
    setSelectedEdadClub3(false);
    setSelectedEdadClub4(false);
    setSelectedEdadClub5(false);
    setSelectedEdadClub6(false);
    setSelectedEdadClub7(false);
    setSelectedEdadClub8(false);
    setSelectedEdadClub9(false);
    setSelectedEdadClub10(false);
    setSelectedEdadClub11(false);
    setSelectedEdadClub12(false);
    setSelectedEdadClub13(false);
  }
  const filterSublistEdadClub = (filtro) => { 
    clearFilterSublistEdadClub();
    if (filtro==='edadclub1'){
      (!selectedEdadClub1) ? addFilter(filtro, 'edadclub') : removeFilter(filtro, 'edadclub');
      setSelectedEdadClub1(!selectedEdadClub1);
    }
    if (filtro==='edadclub2'){
      (!selectedEdadClub2) ? addFilter(filtro, 'edadclub') : removeFilter(filtro, 'edadclub');
      setSelectedEdadClub2(!selectedEdadClub2);
    }
    if (filtro==='edadclub3'){
      (!selectedEdadClub3) ? addFilter(filtro, 'edadclub') : removeFilter(filtro, 'edadclub');
      setSelectedEdadClub3(!selectedEdadClub3);
    }
    if (filtro==='edadclub4'){
      (!selectedEdadClub4) ? addFilter(filtro, 'edadclub') : removeFilter(filtro, 'edadclub');
      setSelectedEdadClub4(!selectedEdadClub4);
    }
    if (filtro==='edadclub5'){
      (!selectedEdadClub5) ? addFilter(filtro, 'edadclub') : removeFilter(filtro, 'edadclub');
      setSelectedEdadClub5(!selectedEdadClub5);
    }
    if (filtro==='edadclub6'){
      (!selectedEdadClub6) ? addFilter(filtro, 'edadclub') : removeFilter(filtro, 'edadclub');
      setSelectedEdadClub6(!selectedEdadClub6);
    }
    if (filtro==='edadclub7'){
      (!selectedEdadClub7) ? addFilter(filtro, 'edadclub') : removeFilter(filtro, 'edadclub');
      setSelectedEdadClub7(!selectedEdadClub7);
    }
    if (filtro==='edadclub8'){
      (!selectedEdadClub8) ? addFilter(filtro, 'edadclub') : removeFilter(filtro, 'edadclub');
      setSelectedEdadClub8(!selectedEdadClub8);
    }
    if (filtro==='edadclub9'){
      (!selectedEdadClub9) ? addFilter(filtro, 'edadclub') : removeFilter(filtro, 'edadclub');
      setSelectedEdadClub9(!selectedEdadClub9);
    }
    if (filtro==='edadclub10'){
      (!selectedEdadClub10) ? addFilter(filtro, 'edadclub') : removeFilter(filtro, 'edadclub');
      setSelectedEdadClub10(!selectedEdadClub10);
    }
    if (filtro==='edadclub11'){
      (!selectedEdadClub11) ? addFilter(filtro, 'edadclub') : removeFilter(filtro, 'edadclub');
      setSelectedEdadClub11(!selectedEdadClub11);
    }
    if (filtro==='edadclub12'){
      (!selectedEdadClub12) ? addFilter(filtro, 'edadclub') : removeFilter(filtro, 'edadclub');
      setSelectedEdadClub12(!selectedEdadClub12);
    }
    if (filtro==='edadclub13'){
      (!selectedEdadClub13) ? addFilter(filtro, 'edadclub') : removeFilter(filtro, 'edadclub');
      setSelectedEdadClub13(!selectedEdadClub13);
    }
  };

  // Lista de Beneficiarios Activos / No Activos
  // const ListItemActivo = ({statsBeneficiario}) => {
  //   if (statsBeneficiario)
  //     return (
  //       <>
  //         <ListItem leftNode={<UsersIcon />} rightNode={arrowLeftIconTotal}
  //           onClick={toggleSublistTotal}
  //         >
  //           <ListItemTitle style={{fontWeight:500, color:'#000'}}>Total</ListItemTitle>
  //           <ListItemSubtitle>{statsBeneficiario.total} registros</ListItemSubtitle>
  //         </ListItem>
  //         <List minimal nested open={openTotal}>
  //           <ListItem active={selectedActivos} onClick={() => { filterSublistTotal('activos') }}
  //             leftNode={<BenefActivosIcon />} rightNode={statsBeneficiario.activos}>
  //             <ListItemTitle style={{fontSize: '0.875rem'}}>Activos</ListItemTitle>
  //           </ListItem>
  //           <ListItem active={selectedNoActivos} onClick={() => { filterSublistTotal('noactivos') }}
  //             leftNode={<BenefNoActivosIcon />} rightNode={statsBeneficiario.noactivos}>
  //             <ListItemTitle style={{fontSize: '0.875rem'}}>No Activos</ListItemTitle>
  //           </ListItem>
  //         </List>
  //       </>
  //     );
  //   return ( <></>);
  // }

  const clearSublistPrestarServicio = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    const filtros = ['prestarservicio', 'noprestarservicio' ];
    setSelectedPrestarServicio(false);
    setSelectedNoPrestarServicio(false);
    clearFilter(filtros);
  }
  const clearSublistAcuerdoCorresp = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    const filtros = ['acuerdocorresp', 'noacuerdocorresp' ];
    setSelectedAcuerdoCorresp(false);
    setSelectedNoAcuerdoCorresp(false);
    clearFilter(filtros);
  }

  const clearSublistJefeHogar = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    const filtros = ['jefehogar', 'nojefehogar', 'jefehogarcomp' ];
    setSelectedJefeHogar(false);
    setSelectedNoJefeHogar(false);
    setSelectedJefehogarComp(false);
    clearFilter(filtros);
  }
  const clearSublistEstadoAyuda = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    const filtros = ['redimido', 'caducado' ];
    setSelectedRedimido(false);
    setSelectedCaducado(false);
    clearFilter(filtros);
  }
  const clearSublistAfectacion = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    const filtros = ['alta', 'media', 'baja', 'noaplica'  ];
    setSelectedAfectacionAlta(false);
    setSelectedAfectacionMedia(false);
    setSelectedAfectacionBaja(false);
    setSelectedAfectacionNoAplica(false);
    clearFilter(filtros);
  }
  const clearSublistEntrega = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    const filtros = ['entrega_1', 'entrega_2', 'entrega_3', 'entrega_4', 
      'entrega_5', 'entrega_6', 'entrega_7', 'entrega_8', 'entrega_9', 'entrega_10' ];
    setSelectedEntrega1(false);
    setSelectedEntrega2(false);
    setSelectedEntrega3(false);
    setSelectedEntrega4(false);
    setSelectedEntrega5(false);
    setSelectedEntrega6(false);
    setSelectedEntrega7(false);
    setSelectedEntrega8(false);
    setSelectedEntrega9(false);
    setSelectedEntrega10(false);
    clearFilter(filtros);
  }
  const clearSublistValorAyuda = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    const filtros = [ 'valorayuda1', 'valorayuda2', 'valorayuda3', 'valorayuda4', 'valorayuda5', 
      'valorayuda6', 'valorayuda7', 'valorayuda8', 'valorayuda9', 'valorayuda10' ]; 
    setSelectedValorAyuda1(false);
    setSelectedValorAyuda2(false);
    setSelectedValorAyuda3(false);
    setSelectedValorAyuda4(false);
    setSelectedValorAyuda5(false);
    setSelectedValorAyuda6(false);
    setSelectedValorAyuda7(false);
    setSelectedValorAyuda8(false);
    setSelectedValorAyuda9(false);
    setSelectedValorAyuda10(false);
    clearFilter(filtros);
  }
  const clearSublistPersonasaCargo = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    const filtros = [ 'personasacargo_0', 'personasacargo_1', 'personasacargo_2', 'personasacargo_3', 'personasacargo_4', 'personasacargo_5', 'personasacargo_5plus',
      'personasacargo_6', 'personasacargo_7', 'personasacargo_8', 'personasacargo_9', 'personasacargo_10' ]; 
    setSelectedPersonasaCargo1(false);
    setSelectedPersonasaCargo0(false);
    setSelectedPersonasaCargo2(false);
    setSelectedPersonasaCargo3(false);
    setSelectedPersonasaCargo4(false);
    setSelectedPersonasaCargo5(false);
    setSelectedPersonasaCargo5plus(false);
    setSelectedPersonasaCargo6(false);
    setSelectedPersonasaCargo7(false);
    setSelectedPersonasaCargo8(false);
    setSelectedPersonasaCargo9(false);
    setSelectedPersonasaCargo10(false);
    clearFilter(filtros);
  }
  const clearSublistEstado = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    const filtros = ['actualizado', 'pendiente', 'visitar', 'revisar', 
      'prueba', 'retirado', 'no apto', 'suspendido' ];
    setSelectedActualizado(false);
    setSelectedPendiente(false);
    setSelectedVisitar(false);
    setSelectedRevisar(false);
    setSelectedPrueba(false);
    setSelectedRetirado(false);
    setSelectedNoApto(false);
    setSelectedSuspendido(false);
    clearFilter(filtros);
  }
  const clearSublistActividad = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    const filtros = ['caddie', 'bolero', 'boleador', 'monitor', 'entrenador', 
      'externo', 'empleado club', 'empleado fundación', 'contratista', 'agencia de empleo' ];
    setSelectedCaddie(false);
    setSelectedBolero(false);
    setSelectedBoleador(false);
    setSelectedMonitor(false);
    setSelectedEntrenador(false);
    setSelectedExterno(false);
    setSelectedEmpleadoClub(false);
    setSelectedEmpleadoFundacion(false);
    setSelectedContratista(false);
    setSelectedAgencia(false);
    clearFilter(filtros);
  }
  const clearSublistCategoria = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    const filtros = ['primera','segunda','tercera','pe1','pe2','pe3','pp3','servicios generales',
      'campo golf','tienda golf','lavadero','cocina','masajes','cholados','peluquería',
    ];
    setSelectedPrimera(false);
    setSelectedSegunda(false);
    setSelectedTercera(false);
    setSelectedPE1(false);
    setSelectedPE2(false);
    setSelectedPE3(false);
    setSelectedPP3(false);
    setSelectedServiciosgenerales(false);
    setSelectedCampogolf(false);
    setSelectedTiendagolf(false);
    setSelectedLavadero(false);
    setSelectedCocina(false);
    setSelectedMasajes(false);
    setSelectedCholados(false);
    setSelectedPeluqueria(false);
    clearFilter(filtros);
  }
  const clearSublistActualizado = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    const filtros = [ 'hoy', 'ayer', 'last7days', 'last30days' ]; //'rangofechas'
    setSelectedHoy(false);
    setSelectedAyer(false);
    setSelectedLast7days(false);
    setSelectedLast30days(false);
    setSelectedRangoFechas(false);
    clearFilter(filtros);
    // clearFilterSublistActualizado();
  }
  const clearSublistEdad = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    const filtros = [ 'edadmenor', 'edad18', 'edad21', 'edad26', 'edad31', 'edad36', 'edad41', 'edad45' ]; 
    setSelectedEdadmenor(false);
    setSelectedEdad18(false);
    setSelectedEdad21(false);
    setSelectedEdad26(false);
    setSelectedEdad31(false);
    setSelectedEdad36(false);
    setSelectedEdad41(false);
    setSelectedEdad45(false);
    clearFilter(filtros);
  }
  const clearSublistEdadClub = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    const filtros = [ 'edadclub1', 'edadclub2', 'edadclub3', 'edadclub4', 'edadclub5', 'edadclub6', 
    'edadclub7', 'edadclub8', 'edadclub9', 'edadclub10', 'edadclub11', 'edadclub12', 'edadclub13' ]; 
    setSelectedEdadClub1(false);
    setSelectedEdadClub2(false);
    setSelectedEdadClub3(false);
    setSelectedEdadClub4(false);
    setSelectedEdadClub5(false);
    setSelectedEdadClub6(false);
    setSelectedEdadClub7(false);
    setSelectedEdadClub8(false);
    setSelectedEdadClub9(false);
    setSelectedEdadClub10(false);
    setSelectedEdadClub11(false);
    setSelectedEdadClub12(false);
    setSelectedEdadClub13(false);
    clearFilter(filtros);
  }

  // Lista de Ayudas Bonos / Mercados
  const ListItemAyudas = ({statsBeneficiario}) => {
    if (statsBeneficiario)
      return (
        <>
          <ListItem leftNode={<UsersIcon />} rightNode={arrowLeftIconTipoAyuda}
            onClick={toggleSublistTipoAyuda}
          >
            <ListItemTitle style={{fontWeight:500, color:'#000'}}>Total</ListItemTitle>
            <ListItemSubtitle>{statsBeneficiario.total} registros</ListItemSubtitle>
          </ListItem>
          <List minimal nested open={openTipoAyuda}>
            <ListItem active={selectedBonos} onClick={() => { filterSublistTipoAyuda('bono') }}
              leftNode={<AyudaBonosIcon />} rightNode={statsBeneficiario.bonos}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>Bonos</ListItemTitle>
            </ListItem>
            <ListItem active={selectedMercados} onClick={() => { filterSublistTipoAyuda('mercado') }}
              leftNode={<AyudaMercadoIcon />} rightNode={statsBeneficiario.mercados}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>Mercados</ListItemTitle>
            </ListItem>
            {/* <ListItem active={selectedOtros} onClick={() => { filterSublistTipoAyuda('otros') }}
              leftNode={<BenefNoActivosIcon />} rightNode={statsBeneficiario.otros}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>Otros</ListItemTitle>
            </ListItem> */}
          </List>
        </>
      );
    return ( <></>);
  }
  // Lista de Estado Ayuda
  const ListItemEstadoAyuda = ({statsBeneficiario}) => {
    if (statsBeneficiario)
      return (
        <>
          <ListItem 
            leftNode={arrowLeftIconEstadoAyuda}
            rightNode={hasEstadoAyudaFiltro && <div className={'bienestar-limpiar'} onClick={clearSublistEstadoAyuda} >{FiltroIcon}</div>}
            onClick={toggleSublistEstadoAyuda}>
            <ListItemTitle style={{fontWeight:500, color:'#000'}}>Estado Ayuda</ListItemTitle>
          </ListItem>
          <List minimal nested open={openEstadoAyuda}>
            <ListItem active={selectedRedimido} onClick={() => { filterSublistEstadoAyuda('redimido') }}
              rightNode={statsBeneficiario.redimido}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>Redimido</ListItemTitle>
            </ListItem>
            <ListItem active={selectedCaducado} onClick={() => { filterSublistEstadoAyuda('caducado') }}
              rightNode={statsBeneficiario.caducado}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>Caducado</ListItemTitle>
            </ListItem>
          </List>
        </>
      );
    return ( <></>);
  }

  // Lista de Afectación
  const ListItemAfectacion = ({statsBeneficiario}) => {
    if (statsBeneficiario)
      return (
        <>
          <ListItem 
            leftNode={arrowLeftIconAfectacion}
            rightNode={hasAfectacionFiltro && <div className={'bienestar-limpiar'} onClick={clearSublistAfectacion} >{FiltroIcon}</div>}
            onClick={toggleSublistAfectacion}>
            <ListItemTitle style={{fontWeight:500, color:'#000'}}>Afectación</ListItemTitle>
          </ListItem>
          <List minimal nested open={openAfectacion}>
            <ListItem active={selectedAfectacionAlta} onClick={() => { filterSublistAfectacion('alta') }}
              rightNode={statsBeneficiario.afectacion_alta}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>Alta</ListItemTitle>
            </ListItem>
            <ListItem active={selectedAfectacionMedia} onClick={() => { filterSublistAfectacion('media') }}
              rightNode={statsBeneficiario.afectacion_media}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>Media</ListItemTitle>
            </ListItem>
            <ListItem active={selectedAfectacionBaja} onClick={() => { filterSublistAfectacion('baja') }}
              rightNode={statsBeneficiario.afectacion_baja}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>Baja</ListItemTitle>
            </ListItem>
            {statsBeneficiario.afectacion_noaplica>0 && 
              <ListItem active={selectedAfectacionNoAplica} onClick={() => { filterSublistAfectacion('noaplica') }}
                rightNode={statsBeneficiario.afectacion_noaplica}>
                <ListItemTitle style={{fontSize: '0.875rem'}}>No Aplica</ListItemTitle>
              </ListItem>
            }
          </List>
        </>
      );
    return ( <></>);
  }

  // Presta Servicio actualmente
  const ListItemPrestaServicio = ({statsBeneficiario}) => {
    if (statsBeneficiario)
      return (
        <>
          <ListItem 
            leftNode={arrowLeftIconPrestarServicio}
            rightNode={hasPrestarServicioFiltro && <div className={'bienestar-limpiar'} onClick={clearSublistPrestarServicio} >{FiltroIcon}</div>}
            onClick={toggleSublistPrestarServicio}>
            <ListItemTitle style={{fontWeight:500, color:'#000'}}>Prestando Servicio</ListItemTitle>
          </ListItem>
          <List minimal nested open={openPrestarServicio}>
            <ListItem active={selectedPrestarServicio} onClick={() => { filterSublistPrestarServicio('prestarservicio') }}
              rightNode={statsBeneficiario.prestarservicio}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>Si</ListItemTitle>
            </ListItem>
            <ListItem active={selectedNoPrestarServicio} onClick={() => { filterSublistPrestarServicio('noprestarservicio') }}
              rightNode={statsBeneficiario.noprestarservicio}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>No</ListItemTitle>
            </ListItem>
          </List>
        </>
      );
    return ( <></>);
  }

  // Acuerdo de Corresponsabilidad
  const ListItemAcuerdoCorresp = ({statsBeneficiario}) => {
    if (statsBeneficiario)
      return (
        <>
          <ListItem 
            leftNode={arrowLeftIconAcuerdoCorresp}
            rightNode={hasAcuerdoCorrespFiltro && <div className={'bienestar-limpiar'} onClick={clearSublistAcuerdoCorresp} >{FiltroIcon}</div>}
            onClick={toggleSublistAcuerdoCorresp}>
            <ListItemTitle style={{fontWeight:500, color:'#000'}}>Firmó Acuerdo</ListItemTitle>
          </ListItem>
          <List minimal nested open={openAcuerdoCorresp}>
            <ListItem active={selectedAcuerdoCorresp} onClick={() => { filterSublistAcuerdoCorresp('acuerdocorresp') }}
              rightNode={statsBeneficiario.acuerdocorresp}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>Si</ListItemTitle>
            </ListItem>
            <ListItem active={selectedNoAcuerdoCorresp} onClick={() => { filterSublistAcuerdoCorresp('noacuerdocorresp') }}
              rightNode={statsBeneficiario.noacuerdocorresp}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>No</ListItemTitle>
            </ListItem>
          </List>
        </>
      );
    return ( <></>);
  }

  // Lista de Jefe Hogar
  const ListItemJefeHogar = ({statsBeneficiario}) => {
    if (statsBeneficiario)
      return (
        <>
          <ListItem 
            leftNode={arrowLeftIconJefeHogar}
            rightNode={hasJefeHogarFiltro && <div className={'bienestar-limpiar'} onClick={clearSublistJefeHogar} >{FiltroIcon}</div>}
            onClick={toggleSublistJefeHogar}>
            <ListItemTitle style={{fontWeight:500, color:'#000'}}>Jefe Hogar</ListItemTitle>
          </ListItem>
          <List minimal nested open={openJefeHogar}>
            <ListItem active={selectedJefeHogar} onClick={() => { filterSublistJefeHogar('jefehogar') }}
              rightNode={statsBeneficiario.jefehogar}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>Si</ListItemTitle>
            </ListItem>
            <ListItem active={selectedNoJefeHogar} onClick={() => { filterSublistJefeHogar('nojefehogar') }}
              rightNode={statsBeneficiario.nojefehogar}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>No</ListItemTitle>
            </ListItem>
            <ListItem active={selectedJefehogarComp} onClick={() => { filterSublistJefeHogar('jefehogarcomp') }}
              rightNode={statsBeneficiario.jefehogarcomp}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>Compartido</ListItemTitle>
            </ListItem>
            
          </List>
        </>
      );
    return ( <></>);
  }

  // Lista de Beneficiarios por Entrega
  const ListItemEntrega = ({statsBeneficiario}) => {
    if (statsBeneficiario)
      return (
        <>
          <ListItem 
            leftNode={arrowLeftIconEntrega}
            rightNode={hasEntregaFiltro && <div className={'bienestar-limpiar'} onClick={clearSublistEntrega} >{FiltroIcon}</div>}
            onClick={toggleSublistEntrega}>
            <ListItemTitle style={{fontWeight:500, color:'#000'}}>Entregas</ListItemTitle>
          </ListItem>
          <List minimal multiSelect nested open={openEntrega}>
            <ListItem 
              active={selectedEntrega1} onClick={() => { filterSublistEntrega('entrega_1') }}
              rightNode={statsBeneficiario.entrega_1}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>Entrega 1</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedEntrega2} onClick={() => { filterSublistEntrega('entrega_2') }}
              rightNode={statsBeneficiario.entrega_2}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>Entrega 2</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedEntrega3} onClick={() => { filterSublistEntrega('entrega_3') }}
              rightNode={statsBeneficiario.entrega_3}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>Entrega 3</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedEntrega4} onClick={() => { filterSublistEntrega('entrega_4') }}
              rightNode={statsBeneficiario.entrega_4}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>Entrega 4</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedEntrega5} onClick={() => { filterSublistEntrega('entrega_5') }}
              rightNode={statsBeneficiario.entrega_5}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>Entrega 5</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedEntrega6} onClick={() => { filterSublistEntrega('entrega_6') }}
              rightNode={statsBeneficiario.entrega_6}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>Entrega 6</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedEntrega7} onClick={() => { filterSublistEntrega('entrega_7') }}
              rightNode={statsBeneficiario.entrega_7}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>Entrega 7</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedEntrega8} onClick={() => { filterSublistEntrega('entrega_8') }}
              rightNode={statsBeneficiario.entrega_8}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>Entrega 8</ListItemTitle>
            </ListItem>
            {statsBeneficiario.entrega_9>0 && <ListItem 
              active={selectedEntrega9} onClick={() => { filterSublistEntrega('entrega_9') }}
              rightNode={statsBeneficiario.entrega_9}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>Entrega 9</ListItemTitle>
            </ListItem>}
            {statsBeneficiario.entrega_10>0 && <ListItem 
              active={selectedEntrega10} onClick={() => { filterSublistEntrega('entrega_10') }}
              rightNode={statsBeneficiario.entrega_10}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>Entrega 10</ListItemTitle>
            </ListItem>}
            
          </List>
        </>
      );
    
    return ( <></>);      
  }

  // Lista de Beneficiarios Valor Ayuda
  const ListItemValorAyuda = ({statsBeneficiario}) => {
    if (statsBeneficiario)
      return (
        <>
          <ListItem 
              leftNode={arrowLeftIconValorAyuda}
              rightNode={hasValorAyudaFiltro && <div className={'filtro-bienestar-limpiar'} onClick={clearSublistValorAyuda} >{FiltroIcon}</div>}
              onClick={toggleSublistValorAyuda}>
              <ListItemTitle style={{fontWeight:500, color:'#000'}}>Valor Ayuda</ListItemTitle>
          </ListItem>
          <List minimal multiSelect nested open={openValorAyuda}>
            <ListItem 
              active={selectedValorAyuda1} onClick={() => { filterSublistValorAyuda('valorayuda1') }}
              rightNode={statsBeneficiario.valorayuda1}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>$0 - $25,000</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedValorAyuda2} onClick={() => { filterSublistValorAyuda('valorayuda2') }}
              rightNode={statsBeneficiario.valorayuda2}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>$25,001 - $50,000</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedValorAyuda3} onClick={() => { filterSublistValorAyuda('valorayuda3') }}
              rightNode={statsBeneficiario.valorayuda3}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>$50,001 - $75,000</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedValorAyuda4} onClick={() => { filterSublistValorAyuda('valorayuda4') }}
              rightNode={statsBeneficiario.valorayuda4}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>$75,001 - $100,000</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedValorAyuda5} onClick={() => { filterSublistValorAyuda('valorayuda5') }}
              rightNode={statsBeneficiario.valorayuda5}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>$100,001 - $150,000</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedValorAyuda6} onClick={() => { filterSublistValorAyuda('valorayuda6') }}
              rightNode={statsBeneficiario.valorayuda6}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>$150,001 - $200,000</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedValorAyuda7} onClick={() => { filterSublistValorAyuda('valorayuda7') }}
              rightNode={statsBeneficiario.valorayuda7}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>$200,001 - $300,000</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedValorAyuda8} onClick={() => { filterSublistValorAyuda('valorayuda8') }}
              rightNode={statsBeneficiario.valorayuda8}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>$300,001 - $400,000</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedValorAyuda9} onClick={() => { filterSublistValorAyuda('valorayuda9') }}
              rightNode={statsBeneficiario.valorayuda9}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>$400,001 - $500,000</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedValorAyuda10} onClick={() => { filterSublistValorAyuda('valorayuda10') }}
              rightNode={statsBeneficiario.valorayuda10}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>Más de $500,001</ListItemTitle>
            </ListItem>
            
          </List>

        </>
      );
    
    return ( <></>);
       
  }

  // Lista de Beneficiarios Personas a Cargo
  const ListItemPersonasaCargo = ({statsBeneficiario}) => {
    if (statsBeneficiario)
      return (
        <>
          <ListItem 
              leftNode={arrowLeftIconPersonasaCargo}
              rightNode={hasPersonasaCargoFiltro && <div className={'filtro-bienestar-limpiar'} onClick={clearSublistPersonasaCargo} >{FiltroIcon}</div>}
              onClick={toggleSublistPersonasaCargo}>
              <ListItemTitle style={{fontWeight:500, color:'#000'}}>Personas a Cargo</ListItemTitle>
          </ListItem>
          <List minimal multiSelect nested open={openPersonasaCargo}>
          <ListItem 
              active={selectedPersonasaCargo0} onClick={() => { filterSublistPersonasaCargo('personasacargo_0') }}
              rightNode={statsBeneficiario.personasacargo_0}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>0 personas</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedPersonasaCargo1} onClick={() => { filterSublistPersonasaCargo('personasacargo_1') }}
              rightNode={statsBeneficiario.personasacargo_1}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>1 persona</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedPersonasaCargo2} onClick={() => { filterSublistPersonasaCargo('personasacargo_2') }}
              rightNode={statsBeneficiario.personasacargo_2}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>2 personas</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedPersonasaCargo3} onClick={() => { filterSublistPersonasaCargo('personasacargo_3') }}
              rightNode={statsBeneficiario.personasacargo_3}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>3 personas</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedPersonasaCargo4} onClick={() => { filterSublistPersonasaCargo('personasacargo_4') }}
              rightNode={statsBeneficiario.personasacargo_4}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>4 personas</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedPersonasaCargo5} onClick={() => { filterSublistPersonasaCargo('personasacargo_5') }}
              rightNode={statsBeneficiario.personasacargo_5}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>5 personas</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedPersonasaCargo5plus} onClick={() => { filterSublistPersonasaCargo('personasacargo_5plus') }}
              rightNode={statsBeneficiario.personasacargo_5plus}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>Más de 5 pers.</ListItemTitle>
            </ListItem>
            {/* <ListItem 
              active={selectedPersonasaCargo6} onClick={() => { filterSublistPersonasaCargo('personasacargo_6') }}
              rightNode={statsBeneficiario.personasacargo_6}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>6 personas</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedPersonasaCargo7} onClick={() => { filterSublistPersonasaCargo('personasacargo_7') }}
              rightNode={statsBeneficiario.personasacargo_7}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>7 personas</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedPersonasaCargo8} onClick={() => { filterSublistPersonasaCargo('personasacargo_8') }}
              rightNode={statsBeneficiario.personasacargo_8}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>8 personas</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedPersonasaCargo9} onClick={() => { filterSublistPersonasaCargo('personasacargo_9') }}
              rightNode={statsBeneficiario.personasacargo_9}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>9 personas</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedPersonasaCargo10} onClick={() => { filterSublistPersonasaCargo('personasacargo_10') }}
              rightNode={statsBeneficiario.personasacargo_10}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>Más de 10 pers.</ListItemTitle>
            </ListItem> */}
            
          </List>

        </>
      );
    
    return ( <></>);
       
  }

  // Lista de Beneficiarios por Estado
  const ListItemEstado = ({statsBeneficiario}) => {
      if (statsBeneficiario)
        return (
          <>
            <ListItem 
              leftNode={arrowLeftIconEstado}
              rightNode={hasEstadoFiltro && <div className={'bienestar-limpiar'} onClick={clearSublistEstado} >{FiltroIcon}</div>}
              onClick={toggleSublistEstado}>
              <ListItemTitle style={{fontWeight:500, color:'#000'}}>Estado</ListItemTitle>
            </ListItem>
            <List minimal multiSelect nested open={openEstado}>
              <ListItem 
                active={selectedActualizado} onClick={() => { filterSublistEstado('actualizado') }}
                leftNode={<span className="cardBenefItem-estado-circle cardBenefItem-estado-actualizado" />} 
                rightNode={statsBeneficiario.actualizado}>
                <ListItemTitle style={{fontSize: '0.875rem'}}>Actualizado</ListItemTitle>
              </ListItem>
              <ListItem 
                active={selectedPendiente} onClick={() => { filterSublistEstado('pendiente') }}
                leftNode={<span className="cardBenefItem-estado-circle cardBenefItem-estado-pendiente" />} 
                rightNode={statsBeneficiario.pendiente}>
                <ListItemTitle style={{fontSize: '0.875rem'}}>Pendiente</ListItemTitle>
              </ListItem>
              <ListItem 
                active={selectedVisitar} onClick={() => { filterSublistEstado('visitar') }}
                leftNode={<span className="cardBenefItem-estado-circle cardBenefItem-estado-visitar" />} 
                rightNode={statsBeneficiario.visitar}>
                <ListItemTitle style={{fontSize: '0.875rem'}}>Visitar</ListItemTitle>
              </ListItem>
              <ListItem 
                active={selectedRevisar} onClick={() => { filterSublistEstado('revisar') }}
                leftNode={<span className="cardBenefItem-estado-circle cardBenefItem-estado-revisar" />} 
                rightNode={statsBeneficiario.revisar}>
                <ListItemTitle style={{fontSize: '0.875rem'}}>Revisar</ListItemTitle>
              </ListItem>
              <ListItem 
                active={selectedPrueba} onClick={() => { filterSublistEstado('prueba') }}
                leftNode={<span className="cardBenefItem-estado-circle cardBenefItem-estado-prueba" />} 
                rightNode={statsBeneficiario.prueba}>
                <ListItemTitle style={{fontSize: '0.875rem'}}>Prueba</ListItemTitle>
              </ListItem>
              <ListItem 
                active={selectedRetirado} onClick={() => { filterSublistEstado('retirado') }}
                leftNode={<span className="cardBenefItem-estado-circle cardBenefItem-estado-retirado" />} 
                rightNode={statsBeneficiario.retirado}>
                <ListItemTitle style={{fontSize: '0.875rem'}}>Retirado</ListItemTitle>
              </ListItem>
              <ListItem 
                active={selectedNoApto} onClick={() => { filterSublistEstado('no apto') }}
                leftNode={<span className="cardBenefItem-estado-circle cardBenefItem-estado-noapto" />} 
                rightNode={statsBeneficiario.noapto}>
                <ListItemTitle style={{fontSize: '0.875rem'}}>No apto</ListItemTitle>
              </ListItem>
              <ListItem 
                active={selectedSuspendido} onClick={() => { filterSublistEstado('suspendido') }}
                leftNode={<span className="cardBenefItem-estado-circle cardBenefItem-estado-suspendido" />} 
                rightNode={statsBeneficiario.suspendido}>
                <ListItemTitle style={{fontSize: '0.875rem'}}>Suspendido</ListItemTitle>
              </ListItem>
            </List>
          </>
        );
      
      return ( <></>);      
  }

  // Lista de Beneficiarios por Actividad
  const ListItemActividad = ({statsBeneficiario}) => {
    if (statsBeneficiario)
      return (
        <>
          <ListItem 
              leftNode={arrowLeftIconActividad}
              rightNode={hasActividadFiltro && <div className={'bienestar-limpiar'} onClick={clearSublistActividad} >{FiltroIcon}</div>}
              onClick={toggleSublistActividad}>
              <ListItemTitle  style={{fontWeight:500, color:'#000'}}>Actividad</ListItemTitle>
          </ListItem>
          <List minimal multiSelect nested open={openActividad}>
            <ListItem 
              active={selectedCaddie} onClick={() => { filterSublistActividad('caddie') }}
              leftNode={<CaddieIcon />} rightNode={statsBeneficiario.caddie}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>Caddie</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedBolero} onClick={() => { filterSublistActividad('bolero') }}
              leftNode={<BoleroIcon />} rightNode={statsBeneficiario.bolero}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>Bolero</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedBoleador} onClick={() => { filterSublistActividad('boleador') }}
              leftNode={<BoleadorIcon />} rightNode={statsBeneficiario.boleador}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>Boleador</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedMonitor} onClick={() => { filterSublistActividad('monitor') }}
              leftNode={<MonitorIcon />} rightNode={statsBeneficiario.monitor}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>Monitor</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedEntrenador} onClick={() => { filterSublistActividad('entrenador') }}
              leftNode={UsuarioIcon} rightNode={statsBeneficiario.entrenador}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>Entrenador</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedExterno} onClick={() => { filterSublistActividad('externo') }}
              leftNode={UsuarioIcon} rightNode={statsBeneficiario.externo}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>Externo</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedEmpleadoClub} onClick={() => { filterSublistActividad('empleado club') }}
              leftNode={UsuarioIcon} rightNode={statsBeneficiario.empleadoclub}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>Emp. Club</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedEmpleadoFundacion} onClick={() => { filterSublistActividad('empleado fundación') }}
              leftNode={UsuarioIcon} rightNode={statsBeneficiario.empleadofundacion}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>Emp. Fundación</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedContratista} onClick={() => { filterSublistActividad('contratista') }}
              leftNode={UsuarioIcon} rightNode={statsBeneficiario.contratista}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>Contratista</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedAgencia} onClick={() => { filterSublistActividad('agencia de empleo') }}
              leftNode={UsuarioIcon} rightNode={statsBeneficiario.agenciadeempleo}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>Agencia</ListItemTitle>
            </ListItem>
          </List>

        </>
      );
    
    return ( <></>);
       
  }
  
  // Lista de Beneficiarios Categorias
  const ListItemCategoria = ({statsBeneficiario}) => {
    if (statsBeneficiario)
      return (
        <>
          <ListItem 
              leftNode={arrowLeftIconCategoria}
              rightNode={hasCategoriaFiltro && <div className={'filtro-bienestar-limpiar'} onClick={clearSublistCategoria} >{FiltroIcon}</div>}
              onClick={toggleSublistCategoria}>
              <ListItemTitle style={{fontWeight:500, color:'#000'}}>Categoría</ListItemTitle>
          </ListItem>
          <List minimal multiSelect nested open={openCategoria}>
            <ListItem 
              active={selectedPrimera} onClick={() => { filterSublistCategoria('primera') }}
              rightNode={statsBeneficiario.primera}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>Primera</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedSegunda} onClick={() => { filterSublistCategoria('segunda') }}
              rightNode={statsBeneficiario.segunda}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>Segunda</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedTercera} onClick={() => { filterSublistCategoria('tercera') }}
              rightNode={statsBeneficiario.tercera}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>Tercera</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedPE1} onClick={() => { filterSublistCategoria('pe1') }}
              rightNode={statsBeneficiario.pe1}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>PE1</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedPE2} onClick={() => { filterSublistCategoria('pe2') }}
              rightNode={statsBeneficiario.pe2}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>PE2</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedPE3} onClick={() => { filterSublistCategoria('pe3') }}
              rightNode={statsBeneficiario.pe3}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>PE3</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedPP3} onClick={() => { filterSublistCategoria('pp3') }}
              rightNode={statsBeneficiario.pp3}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>PP3</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedServiciosgenerales} onClick={() => { filterSublistCategoria('servicios generales') }}
              rightNode={statsBeneficiario.serviciosgenerales}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>Serv. Generales</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedCampogolf} onClick={() => { filterSublistCategoria('campo golf') }}
              rightNode={statsBeneficiario.campogolf}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>Campo golf</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedTiendagolf} onClick={() => { filterSublistCategoria('tienda golf') }}
              rightNode={statsBeneficiario.tiendagolf}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>Tienda golf</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedLavadero} onClick={() => { filterSublistCategoria('lavadero') }}
              rightNode={statsBeneficiario.lavadero}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>Lavadero</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedCocina} onClick={() => { filterSublistCategoria('cocina') }}
              rightNode={statsBeneficiario.cocina}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>Cocina</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedMasajes} onClick={() => { filterSublistCategoria('masajes') }}
              rightNode={statsBeneficiario.masajes}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>Masajes</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedCholados} onClick={() => { filterSublistCategoria('cholados') }}
              rightNode={statsBeneficiario.cholados}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>Cholados</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedPeluqueria} onClick={() => { filterSublistCategoria('peluquería') }}
              rightNode={statsBeneficiario.peluqueria}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>Peluquería</ListItemTitle>
            </ListItem>
          </List>

        </>
      );
    
    return ( <></>);
       
  }

  // Lista de Beneficiarios Fecha Modificación
  const ListItemFechaActualizado = ({statsBeneficiario}) => {
    if (statsBeneficiario)
      return (
        <>
          <ListItem 
              leftNode={arrowLeftIconActualizado}
              rightNode={hasActualizadoFiltro && <div className={'filtro-bienestar-limpiar'} onClick={clearSublistActualizado} >{FiltroIcon}</div>}
              onClick={toggleSublistActualizado}>
              <ListItemTitle style={{fontWeight:500, color:'#000'}}>Actualizado</ListItemTitle>
          </ListItem>
          <List minimal nested open={openActualizado}>
            <ListItem 
              active={selectedHoy} onClick={() => { filterSublistActualizado('hoy') }}
              rightNode={statsBeneficiario.hoy}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>Hoy</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedAyer} onClick={() => { filterSublistActualizado('ayer') }}
              rightNode={statsBeneficiario.ayer}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>Ayer</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedLast7days} onClick={() => { filterSublistActualizado('last7days') }}
              rightNode={statsBeneficiario.last7days}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>Últimos 7 días</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedLast30days} onClick={() => { filterSublistActualizado('last30days') }}
              rightNode={statsBeneficiario.last30days}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>Últimos 30 días</ListItemTitle>
            </ListItem>
            {/* <ListItem 
              active={selectedRangoFechas}
              // rightNode={statsBeneficiario.RangoFechas}
            >
              <ListItemTitle style={{fontSize: '0.875rem'}}>Rango fechas...</ListItemTitle>
            </ListItem> */}
            
          </List>

        </>
      );
    
    return ( <></>);
       
  }

  // Lista de Beneficiarios Edad
  const ListItemEdad = ({statsBeneficiario}) => {
    if (statsBeneficiario)
      return (
        <>
          <ListItem 
              leftNode={arrowLeftIconEdad}
              rightNode={hasEdadFiltro && <div className={'filtro-bienestar-limpiar'} onClick={clearSublistEdad} >{FiltroIcon}</div>}
              onClick={toggleSublistEdad}>
              <ListItemTitle style={{fontWeight:500, color:'#000'}}>Edad</ListItemTitle>
          </ListItem>
          <List minimal multiSelect nested open={openEdad}>
            <ListItem 
              active={selectedEdadmenor} onClick={() => { filterSublistEdad('edadmenor') }}
              rightNode={statsBeneficiario.edadmenor}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>Menor de 18 años</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedEdad18} onClick={() => { filterSublistEdad('edad18') }}
              rightNode={statsBeneficiario.edad18}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>18 - 20 años</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedEdad21} onClick={() => { filterSublistEdad('edad21') }}
              rightNode={statsBeneficiario.edad21}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>21 - 25 años</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedEdad26} onClick={() => { filterSublistEdad('edad26') }}
              rightNode={statsBeneficiario.edad26}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>26 - 30 años</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedEdad31} onClick={() => { filterSublistEdad('edad31') }}
              rightNode={statsBeneficiario.edad31}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>31 - 35 años</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedEdad36} onClick={() => { filterSublistEdad('edad36') }}
              rightNode={statsBeneficiario.edad36}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>36 - 40 años</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedEdad41} onClick={() => { filterSublistEdad('edad41') }}
              rightNode={statsBeneficiario.edad41}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>41 y 45 años</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedEdad45} onClick={() => { filterSublistEdad('edad45') }}
              rightNode={statsBeneficiario.edad45}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>Más de 45 años</ListItemTitle>
            </ListItem>
            
          </List>

        </>
      );
    
    return ( <></>);
       
  }

  // Lista de Beneficiarios Antiguedad - EdadClub con la FechaIngreso
  const ListItemEdadClub = ({statsBeneficiario}) => {
    if (statsBeneficiario)
      return (
        <>
          <ListItem 
              leftNode={arrowLeftIconEdadClub}
              rightNode={hasEdadClubFiltro && <div className={'filtro-bienestar-limpiar'} onClick={clearSublistEdadClub} >{FiltroIcon}</div>}
              onClick={toggleSublistEdadClub}>
              <ListItemTitle style={{fontWeight:500, color:'#000'}}>Años Club</ListItemTitle>
          </ListItem>
          <List minimal multiSelect nested open={openEdadClub}>
            <ListItem 
              active={selectedEdadClub1} onClick={() => { filterSublistEdadClub('edadclub1') }}
              rightNode={statsBeneficiario.edadclub1}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>Menos de 1 año</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedEdadClub2} onClick={() => { filterSublistEdadClub('edadclub2') }}
              rightNode={statsBeneficiario.edadclub2}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>1 - 2 años</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedEdadClub3} onClick={() => { filterSublistEdadClub('edadclub3') }}
              rightNode={statsBeneficiario.edadclub3}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>2 - 5 años</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedEdadClub4} onClick={() => { filterSublistEdadClub('edadclub4') }}
              rightNode={statsBeneficiario.edadclub4}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>5 - 10 años</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedEdadClub5} onClick={() => { filterSublistEdadClub('edadclub5') }}
              rightNode={statsBeneficiario.edadclub5}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>10 - 15 años</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedEdadClub6} onClick={() => { filterSublistEdadClub('edadclub6') }}
              rightNode={statsBeneficiario.edadclub6}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>15 y 20 años</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedEdadClub7} onClick={() => { filterSublistEdadClub('edadclub7') }}
              rightNode={statsBeneficiario.edadclub7}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>20 y 25 años</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedEdadClub8} onClick={() => { filterSublistEdadClub('edadclub8') }}
              rightNode={statsBeneficiario.edadclub8}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>25 y 30 años</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedEdadClub9} onClick={() => { filterSublistEdadClub('edadclub9') }}
              rightNode={statsBeneficiario.edadclub9}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>30 y 35 años</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedEdadClub10} onClick={() => { filterSublistEdadClub('edadclub10') }}
              rightNode={statsBeneficiario.edadclub10}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>35 y 40 años</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedEdadClub11} onClick={() => { filterSublistEdadClub('edadclub11') }}
              rightNode={statsBeneficiario.edadclub11}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>40 y 45 años</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedEdadClub12} onClick={() => { filterSublistEdadClub('edadclub12') }}
              rightNode={statsBeneficiario.edadclub12}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>45 y 50 años</ListItemTitle>
            </ListItem>
            <ListItem 
              active={selectedEdadClub13} onClick={() => { filterSublistEdadClub('edadclub13') }}
              rightNode={statsBeneficiario.edadclub13}>
              <ListItemTitle style={{fontSize: '0.875rem'}}>Más de 50 años</ListItemTitle>
            </ListItem>
            
          </List>

        </>
      );
    
    return ( <></>);
       
  }

  return (
      <div className="filtro-bienestar">
        <List minimal>
          <ListHeader>Filtro</ListHeader>
          {/* Mostrar los filtros seleccionados*/}
          {/* {listadeFiltros && listadeFiltros.length>0 && <ListItem>
            {listadeFiltros.map((lf, idx) => {
              return <div key={idx}>{lf.filtro}</div>
            })}
          </ListItem>}  */}
          {/* <ListItemActivo statsBeneficiario={statsBeneficiario}/> */}
          <ListItemAyudas statsBeneficiario={statsBeneficiario}/>
          <ListItemEstadoAyuda statsBeneficiario={statsBeneficiario}/>
          <ListItemEntrega statsBeneficiario={statsBeneficiario}/>
          <ListItemAfectacion statsBeneficiario={statsBeneficiario}/>
          <ListItemValorAyuda statsBeneficiario={statsBeneficiario}/>
          <ListItemPrestaServicio statsBeneficiario={statsBeneficiario}/>
          <ListItemAcuerdoCorresp statsBeneficiario={statsBeneficiario}/>
          <ListItemJefeHogar statsBeneficiario={statsBeneficiario}/>
          <ListItemPersonasaCargo statsBeneficiario={statsBeneficiario}/>
          <ListItemEstado statsBeneficiario={statsBeneficiario}/>
          <ListItemActividad statsBeneficiario={statsBeneficiario}/>
          <ListItemCategoria statsBeneficiario={statsBeneficiario}/>
          <ListItemEdad statsBeneficiario={statsBeneficiario}/>
          <ListItemEdadClub statsBeneficiario={statsBeneficiario}/>
          <ListItemFechaActualizado statsBeneficiario={statsBeneficiario}/>
          
        </List>

      </div>
    )

}

export default FilterCovidAyudas;