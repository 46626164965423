// Copyright 2019 Esri
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//     http://www.apache.org/licenses/LICENSE-2.0
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.​

import { call, put, takeLatest } from "redux-saga/effects";
import { types as configTypes } from "../reducers/vestuario";
import {
  getBeneficiariosDataxVestuarioTallaje,
  getBeneficiarioPerfilDataxTallaje,
  actualizarVestuarioTallaje,
  crearVestuarioTallaje,
  eliminarVestuarioTallaje
} from "../../utils/vestuario_req";
// WORKER //
function* fetchBeneficiariosDataxVestuarioTallaje(action) {
  try {
    // fetch datos del Beneficiario seleccionado
    const listBenefTallaje = yield call(getBeneficiariosDataxVestuarioTallaje, action.payload.token);
    // load users into Redux store
    if (listBenefTallaje)
      yield put({
        type: configTypes.VESTUARIO_TALLAJE_BENEFICIARIOS_LIST_LOADED,
        payload: {listBenefTallaje: listBenefTallaje}
      });
  } catch (e) {
    console.error("SAGA ERROR: _vestuario/fetchBeneficiariosDataxVestuarioTallaje, ", e);
  }
}

function* fetchBeneficiarioPerfilDataxVestuarioTallaje(action) {
  try {
    // fetch datos del Beneficiario seleccionado
    const datosSelBenefTallaje = yield call(getBeneficiarioPerfilDataxTallaje, action.payload);
    // load users into Redux store
    if (datosSelBenefTallaje)
      yield put({
        type: configTypes.VESTUARIO_TALLAJE_BENEFICIARIO_DATA_SELECTED,
        payload: {datosSelBenefTallaje: datosSelBenefTallaje, selectedProfileTallaje:{docId:datosSelBenefTallaje.beneficiarios[0].docId}}
      });
  } catch (e) {
    console.error("SAGA ERROR: _vestuario/fetchBeneficiarioPerfilDataxVestuarioTallaje, ", e);
  }
}

function* updateVestuarioTallaje(action) {
  try {
    const resp = yield call(actualizarVestuarioTallaje, action.payload);
    if (resp && resp!=='')
      yield put({
        type: configTypes.MODULE_VESTUARIO_SERVER_MESSAGE,
        payload: {mensaje: {"tallaje": resp}}
      });
  } catch (e) {
    console.error("SAGA ERROR: _vestuario/updateVestuarioTallaje, ", e);
  }
}
function* insertVestuarioTallaje(action) {
  try {
    const resp = yield call(crearVestuarioTallaje, action.payload);
    if (resp && resp!=='')
      yield put({
        type: configTypes.MODULE_VESTUARIO_SERVER_MESSAGE,
        payload: {mensaje: {"tallaje": resp}}
      });
  } catch (e) {
    console.error("SAGA ERROR: _vestuario/insertVestuarioTallaje, ", e);
  }
}
function* deleteVestuarioTallaje(action) {
  try {
    const resp = yield call(eliminarVestuarioTallaje, action.payload);
    if (resp && resp!=='')
      yield put({
        type: configTypes.MODULE_VESTUARIO_SERVER_MESSAGE,
        payload: {mensaje: {"tallaje": resp}}
      });
  } catch (e) {
    console.error("SAGA ERROR: _vestuario/deleteVestuarioTallaje, ", e);
  }
}

// WATCHER //
export function* watchFetchConfig() {
  yield takeLatest(configTypes.VESTUARIO_TALLAJE_BENEFDATA_LOADED, fetchBeneficiariosDataxVestuarioTallaje);
  yield takeLatest(configTypes.VESTUARIO_TALLAJE_BENEFICIARIO_SELECTED_DATA_REQUEST, fetchBeneficiarioPerfilDataxVestuarioTallaje);
  yield takeLatest(configTypes.VESTUARIO_TALLAJE_UPDATED, updateVestuarioTallaje);
  yield takeLatest(configTypes.VESTUARIO_TALLAJE_CREATED, insertVestuarioTallaje);
  yield takeLatest(configTypes.VESTUARIO_TALLAJE_DELETED, deleteVestuarioTallaje);
}
