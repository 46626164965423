// React
import React, { useState, useEffect } from "react";
// Redux imports
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../redux/reducers/auth";
// import { setGRBConfig } from "../redux/reducers/config";
import { editUser, authLogout } from '../redux/reducers/users';

import Button from 'calcite-react/Button';
import Popover from 'calcite-react/Popover';
import Avatar from 'calcite-react/Avatar';
import styled from "styled-components";
// import user_1 from "../assets/team/usuario.jpg";
import { editBeneficiario } from "../redux/reducers/beneficiarios";

const LoginAccountContainer = styled.div`
    justify-content: space-between;
    display: flex;
    box-sizing: border-box;
    flex-direction: row;
    background-color: #fff;
    min-width: 0;
    align-items: center;
    width: 120px;
    maxWidth: 150px;
    border-left: 1px solid #ccc;

    /* Extra small devices (phones, 600px and down) */
    @media only screen and (max-width: 600px) {
        // position: relative;
        // right: 22px;
        padding: 0 5px;
        // top: 5px;
        // width: auto;
    }
    
    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (min-width: 600px) {
        // position: relative;
        // right: 22px;
        padding: 0 5px;
        // top: 5px;
        // width: auto;
    }
    
    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (min-width: 768px) {
        // position: relative;
        // right: 22px;
        // padding: 0 5px;
        // top: 5px;
        // width: auto;
    }
    
    /* Large devices (laptops/desktops, 992px and up) */
    @media only screen and (min-width: 992px) {
        
    }
    
    /* Extra large devices (large laptops and desktops, 1200px and up) */
    @media only screen and (min-width: 1200px) {
        
    } 
    

    &:hover {
        // border: 1px solid #f00;
        border-bottom: 3px solid green;
    }
`;
const LoginLabelName = styled.div`
    position: relative;
    left: 5px;
    color: #444;
    font-size: 0.95em;
    font-weight: 600;
    user-select: none;

    /* Extra small devices (phones, 600px and down) */
    @media only screen and (max-width: 600px) {
        // left: 14px;
        display:none;
    }
    
    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (min-width: 600px) {
        
    }
    
    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (min-width: 768px) {
       
    }
    
    /* Large devices (laptops/desktops, 992px and up) */
    @media only screen and (min-width: 992px) {
        
    }
    
    /* Extra large devices (large laptops and desktops, 1200px and up) */
    @media only screen and (min-width: 1200px) {
        
    } 
`;
const AvatarExtendedContent = styled(Avatar)`
    // position: relative;
    background-color: #6baf64;
`;

const UserInfoContainer = styled.div`
    width: auto;
    display: block;
    background-color: #fff;
    text-align: center;
    padding: 0;
    margin:0;
    border: 1px solid #d5d5d5;
    box-shadow: 0px 2px 5px #9d9d9d;
`;

const UserInfoContainerAvatar = styled.div`
    margin: 0;
    margin-top: 1.2em;
    padding: 0.25em;
    border: 3px solid #1dc64f;
    display: inline-block;
    background-color: #fff;
    user-select: none;
    border-radius: 50%;
    justify-content: center;
`;

const UserInfoLabelName = styled.div`
    font-weight: 600;
    padding: 5px;
    font-family: Montserrat;
`;

const UserInfoLabelTipo = styled.div`
    font-weight: 300;
    padding: 5px;
    margin-top: 0;
    margin-bottom: 14px;
    position: relative;
    top: -12px;
    text-decoration: underline;
`;

const ButtonClearGreen = styled(Button)`
    width:120px;
    color: green;
    background: #fff;
    border: 1px solid green;

    &:hover {
        border: 1px solid #fff;
        color: #fff;
        background: green;
    }
`;

const LoginAccount = (props) => {
    const config = useSelector(state => state.config);
    const users = useSelector(state => state.users);
    // const token = useSelector(state => state.auth.token);
    const loggedIn = useSelector(state => state.auth.loggedIn);
    const dispatch = useDispatch();

    // const [salir, setLogout] = useState(false);
    // const [user, setUser] = useState({});
    const [shortname,setUserName] = useState('');
    const [fullname,setUserFullName] = useState('');
    const [popperopen, setPopperOpen] = useState(false);
    // const [avatar, setAvatar] = useState(user_1);
    const [avatar, setAvatar] = useState(process.env.PUBLIC_URL + '/imgs/team/usuario.jpg');

    const capitalize = (str, lower = false) => (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, match => match.toUpperCase());
   
    useEffect(() => {
        if (loggedIn && users.user.foto!==""){
            if (users.user.tipo && users.user.tipo!==''){
                // const foto = users.user.foto==='' ? require('../assets/team/usuario.jpg') : require('../assets/team/' + users.user.foto);
                const foto = users.user.foto==='' ? process.env.PUBLIC_URL + '/imgs/team/usuario.jpg' : process.env.PUBLIC_URL + '/imgs/team/' + users.user.foto;
                setAvatar(foto);
                setUserFullName(capitalize(users.user.nombre, true));
                setUserName(capitalize(users.user.nombre.split(' ')[0], true));
            } else if (users.user.actividad && users.user.actividad!==''){
                // const ECASE_URL = config.ECASE_URL +'user_images/'+ config.user.foto;
                // const foto = users.user.foto==='' ? require('../assets/team/usuario.jpg') : config.ECASE_URL +'user_images/'+ users.user.foto; //require('/~ernestogiron/FunFarallones/ECASE/user_images/beneficiario_287199.jpg'); // + 'user_images/' + config.user.foto);
                const foto = users.user.foto==='' ? process.env.PUBLIC_URL + '/imgs/team/usuario.jpg' : config.ECASE_URL +'user_images/'+ users.user.foto; //require('/~ernestogiron/FunFarallones/ECASE/user_images/beneficiario_287199.jpg'); // + 'user_images/' + config.user.foto);
                setAvatar(foto);
                setUserFullName(capitalize((users.user.nombres + ' ' + users.user.apellidos), true));
                setUserName(capitalize(users.user.nombres.split(' ')[0], true));
            }
        }
    }, [config, loggedIn, users, dispatch]);
    

    const togglePopover = () => {
        setPopperOpen(!popperopen);
    }

    const closePopover = () => {
        setPopperOpen(false);
    }

    const onClickEditarUser = () => {
        // if (users.user.tipo && users.user.tipo!==''){
        //     dispatch(editUser({edit:true}));
        // } else {
        //     dispatch(editBeneficiario({edit:true}));
        // }
        // closePopover();
    }

    // Sign out button click event
    const onClickSignOut = () => {
    //   setLogout(true);
      if (users.user.tipo && users.user.tipo!==''){
        dispatch(editUser({edit:false}));
      } else {
        dispatch(editBeneficiario({edit:false}));
      }
      
     
      dispatch(logout({}));
      dispatch(authLogout());
      
    }
    
    if (loggedIn)
        return (
            <>
            <Popover
                targetEl={
                    <LoginAccountContainer onClick={togglePopover}>
                        <LoginLabelName>{shortname || ''}</LoginLabelName>
                        <AvatarExtendedContent style={{ margin: '2px', width: 40, height: 40 }} src={avatar || ''}  />
                    </LoginAccountContainer>
                }
                open={popperopen}
                onRequestClose={closePopover}
            >
                <UserInfoContainer>
                    <UserInfoContainerAvatar>
                        <Avatar style={{ margin: '1px', width: 140, height: 140, border: '1px solid green' }} src={avatar || ''}  />
                    </UserInfoContainerAvatar>
                    <UserInfoLabelName>{fullname || ''}</UserInfoLabelName>
                    <UserInfoLabelTipo>{users.user ? users.user.actividad || users.user.areatrabajo : ''}</UserInfoLabelTipo>
                    <Button green style={{width:'120px'}} onClick={onClickEditarUser}>Editar</Button>
                    <ButtonClearGreen clear onClick={onClickSignOut}>Salir</ButtonClearGreen>
                </UserInfoContainer>
            </Popover>
            </>
        );
    
    
    return (
        <>
        </>
        );

    

}

export default LoginAccount;

