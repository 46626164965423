// React
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { removeHTMLTags,
  // getFirstWords,capitalize,
} from '../utils/utils';

// import {
//   ListItem,
//   // ListHeader,
//   ListItemTitle,
//   ListItemSubtitle
// } from 'calcite-react/List';
import {MenuItem} from 'calcite-react/Menu';
import Popover from 'calcite-react/Popover'
import Button from "calcite-react/Button";
import TopNav, {
    // TopNavBrand,
    TopNavTitle,
    TopNavList,
    // TopNavLink,
    TopNavActionsList
  } from 'calcite-react/TopNav';

// import { NavLink } from 'react-router-dom'
import logo from "../assets/logo_Fundacion.png";
import LoginAccount from './LoginAccount';
import { fetchNotificacionesUserbyQuery } from '../redux/reducers/users';

import SwitchIcon from 'calcite-ui-icons-react/SwitchIcon';
import NotificationIcon from 'calcite-ui-icons-react/BellIcon';
// import UserIcon from 'calcite-ui-icons-react/UserIcon';

import styled from "styled-components";
import './App.css';

const TopNavTitleExt = styled(TopNavTitle)`
  padding-bottom: 0.25rem;
`;

const TopNavActionsListExt = styled(TopNavActionsList)`
  // padding-bottom: 0.25rem;

  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 600px) {
    padding: 0px 2px;
  }
  
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
    padding: 0px 2px;
  }
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
    
  }
  
  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
      
  }
  
  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
      
  } 
`;

const LogoFundacion = styled.a`
  padding: 0 1.55rem;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  text-decoration: none;
  img {
    height:55px;
  }

  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (max-width: 1200px) {
    // padding: 10px;
    // img {
    //   height:auto;
    //   // width:50px;
    //   max-width:100%;
    // }
  } 

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (max-width: 992px) {
    // padding: 10px;
    // img {
    //   height:auto;
    //   // width:50px;
    //   max-width:100%;
    // }
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (max-width: 768px) {
    padding: 10px;
    img {
      height:50px;
      width:50px;
      max-width:50px;
    }
  }

`;
const AppAbrevName = styled.div`
  text-align: left;
  font-size: 2.1em;
  font-weight: 700;
  font-family: Times;
  letter-spacing: 1.2px;
`;

const AppName = styled.div`
  text-align: left;
  font-size: 0.7em;
  font-weight: 300;
  letter-spacing: 1.5px;

  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (max-width: 1200px) {
    font-size: 0.7em;
    font-weight: 300;
    letter-spacing: 1.5px;
  } 

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (max-width: 992px) {
    font-size: 0.7em;
    font-weight: 300;
    letter-spacing: 1.5px;
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (max-width: 768px) {
    // display: none;
    line-height: 15px;
    font-size: 0.6em;
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 600px) {
    font-size: 0.5em;
    line-height: 15px;
    width: 150px;
  }

  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 600px) {
    font-size: 0.5em;
    line-height: 15px;
    width: 150px;
  }

`;

const ToolBarRight = styled.div`
  justify-content: space-between;
  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  background-color: #fff;

  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 600px) {

  }
  
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {

  }
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
     
  }
  
  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
      
  }
  
  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
      
  } 
  

`;

const Bagde = styled.div`
  z-index: 10;
  position: relative;
  right: 8px;
  top: 0px;
  font-size: 0.72em;
  border-radius: 50%;
  background-color: #f00;
  width: 20px;
  height: 20px;
  color: #fff;
  line-height: 20px;
  font-weight: 600;
  // border: 2px solid #f00;

  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 600px) {
    top: 2px;
    right: 10px;
  }
  
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
    
  }
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
      
  }
  
  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
      
  }
  
  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
      
  } 
  
  
`;

const ButtonTopRight = styled(Button)`
  position: relative;
  color: green;
  top:4px;
  padding:5px 2px;

  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 600px) {
      
  }
  
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
      
  }
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
     
  }
  
  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
      
  }
  
  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
      
  } 
  

  &:hover {
      // border: 1px solid #f00;
      border-bottom: 3px solid green;
  }
`;

const ButtonNotas = styled.div`
  position: relative;
  color: green;
  top:4px;
  padding:5px 2px;

  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 600px) {
      
  }
  
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
       
  }
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
      
  }
  
  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
      
  }
  
  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
      
  } 
  
`;

const NotasContainer = styled.div`
    // justify-content: space-between;
    display: inline-flex;
    box-sizing: border-box;
    // flex-direction: row;
    background-color: #fff;
    // min-width: 0;
    // align-items: center;
    width: 50px;
    maxWidth: 50px;
    // border-left: 1px solid #ccc;

    /* Extra small devices (phones, 600px and down) */
    @media only screen and (max-width: 600px) {
        padding: 0 2px;
    }
    
    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (min-width: 600px) {
        padding: 0 2px;
    }
    
    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (min-width: 768px) {
        
    }
    
    /* Large devices (laptops/desktops, 992px and up) */
    @media only screen and (min-width: 992px) {
        
    }
    
    /* Extra large devices (large laptops and desktops, 1200px and up) */
    @media only screen and (min-width: 1200px) {
        
    } 
    

    &:hover {
        // border: 1px solid #f00;
        border-bottom: 3px solid green;
    }
`;

const PopoverNotasContainer = styled.div`
  box-sizing: border-box;
  background-color: #fff;
  max-height: 250px;
  // width: 50px;
  // maxWidth: 50px;
  // border: 1px solid #ccc;

  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 600px) {
  }
  
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
  }
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
      
  }
  
  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
      
  }
  
  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
      
  } 
  
`;

const ListaPendientesContainer = styled.div`
  text-align: left;
  max-width: 200px;
  max-height: 250px;
  width: 200px;
  height: 250px;
  overflow: hidden auto;
  border: 1px solid #ccc;
  box-shadow: 1px 2px 2px #ccc;
`;


const HeaderGRB = props => {
  const dispatch = useDispatch();
  const config = useSelector(state => state.config);
  const users = useSelector(state => state.users);
  // const notificaciones = useSelector(state => state.notas.notificaciones);
  const notificaciones = useSelector(state => state.users.notificaciones);
  // // const token = useSelector(state => state.auth.token);
  const loggedIn = useSelector(state => state.auth.loggedIn);
  const [cantNotas,setCantNotas] = useState(null);
  const [popperopen, setPopperOpen] = useState(false);
  // let notification = null;

  const [query,] = useState({
    "page": "1",
    "item_per_page": "50",
    "searchText":"",
    "id":"",
    "tiponota":"",
    "nota":"",
    "areanota":"",
    "relacioncon":"",
    "responsables": users.user.docId,
    "prioridad":"",
    "etiquetas":"",
    "estadonota":"Pendiente",
    "comentarios":"",
    "fechacreacion":"",
    "fechaplazo":"",
    "creadapor":"",
    "actualizacion":"",
    "version":"",
    "modificadopor":""
  });

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(fetchNotificacionesUserbyQuery({token: users.user.claveAPI, query: query} ));
    }, 300000); // Cada 5 minutos
    return () => {
      clearInterval(interval);
    };
  }, [users.user.claveAPI, query, dispatch]);

  useEffect(() => {

    if (!("Notification" in window)) {
      console.log("This browser does not support desktop notification");
    } else {
      Notification.requestPermission();
    }

    if (!notificaciones || notificaciones===null){
        dispatch(fetchNotificacionesUserbyQuery({token: users.user.claveAPI, query: query} ));
        return
    }

    return () => {
        // executed when unmount
        // console.log('Saliendo del Modulo Beneficiarios');
    }
    
  }, [users.user.claveAPI, notificaciones, query, dispatch]);

  useEffect(() => {
    
    if (!notificaciones || notificaciones===null || notificaciones===undefined){
        return
    }
    if (!notificaciones || notificaciones===null || notificaciones===undefined || notificaciones.datos.length<=0){
        console.log('No hay registros para mostrar...');
    } else {
      setCantNotas(notificaciones.datos.length);
      // console.log("Cantidad de Notifiaciones",notificaciones.datos.length, cantNotas);
      if (cantNotas && notificaciones.datos.length > cantNotas){
        new Notification("GRB Notification", {
          body: "Nueva notificacion...",
          image: "http://fundacionclubfarallones.org/portal/images/logo-small.png",
          icon: "http://fundacionclubfarallones.org/portal/images/logo-small.png",
          dir: "ltr"
        });
      }
      
    }

    
  }, [notificaciones, cantNotas, dispatch]);

  const toggleSubNav = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    document.getElementById('subnav-fundacion').style.display = (document.getElementById('subnav-fundacion').style.display==='' || document.getElementById('subnav-fundacion').style.display==='block') ? 'none' : 'block';
  }

  // const showNotification = () => {
  //   var options = {
  //     body: "Hola Bienvenido al GRB",
  //     image: "http://fundacionclubfarallones.org/portal/images/logo-small.png",
  //     icon: "http://fundacionclubfarallones.org/portal/images/logo-small.png",
  //     dir: "ltr"
  //   };
  //   notification = new Notification("GRB Notification", options);
  // }
  
  // const showNotificaciones = (evt) => {
  //   evt.preventDefault();
  //   evt.stopPropagation();
  // }

  const togglePopover = () => {
      setPopperOpen(!popperopen);
  }
  const closePopover = () => {
      setPopperOpen(false);
  }

  return (
      <TopNav>
        <LogoFundacion href="http://fundacionclubfarallones.org/" target="_new"><img src={logo} alt='Logo'/></LogoFundacion>
        <TopNavTitleExt>
          <AppAbrevName>GRB</AppAbrevName>
          <AppName className="header-AppName">{config.AppName || ''}</AppName>
        </TopNavTitleExt>
        <TopNavList>
            {/* <TopNavLink href="#" active>Plans</TopNavLink>
            <TopNavLink href="#">Community</TopNavLink>
            <TopNavLink href="#">Docs</TopNavLink> */}
        </TopNavList>
        <TopNavActionsListExt>
          <ToolBarRight>
            <div title='Mostrar/Ocultar Menú Superior'>
              <ButtonTopRight transparent buttonIcon 
                  onClick={toggleSubNav}
                  icon={<SwitchIcon size={24} />}
              ></ButtonTopRight>
            </div>
            <div title='Mostrar/Ocultar Notificaciones'>
                {/* <ButtonTopRight transparent buttonIcon 
                    onClick={showNotificaciones}
                    icon={<NotificationIcon size={24} />}
                ></ButtonTopRight>
                {cantNotas && cantNotas>0 && <Bagde>{cantNotas}</Bagde>} */}
                <Popover
                  targetEl={
                      <NotasContainer onClick={togglePopover}>
                          <ButtonNotas>{<NotificationIcon size={24} />}</ButtonNotas>
                          {cantNotas && cantNotas>0 && <Bagde>{cantNotas}</Bagde>}
                      </NotasContainer>
                  }
                  open={popperopen}
                  onRequestClose={closePopover}
                >
                  <PopoverNotasContainer>
                    { notificaciones && notificaciones.datos.length>0 ? 
                        <ListaPendientesContainer>
                            { notificaciones.datos.map((itm, index) => {
                                const foto_creador = (itm.foto==='' || itm.foto===null) ? process.env.PUBLIC_URL + '/imgs/team/usuario.jpg' : process.env.PUBLIC_URL + '/imgs/team/'+ itm.foto; 

                                return (
                                  <MenuItem key={itm.id} value={itm.id} style={{padding:'0px 2px'}}>
                                      <div className="uiheader-notifiaciones-left">
                                        <div className="uiheader-notifiacionesLeft-id">{itm.id}</div>
                                        <div className="uiheader-notifiacionesLeft-creadapor"><img src={foto_creador} width={32} alt=" " /></div>
                                        {itm.prioridad.toLowerCase()==='alta' && <div className="uiheader-notifiacionesLeft-prioridad uiheader-notifiacionesLeft-prioridad-alta">{itm.prioridad}</div>}
                                        {itm.prioridad.toLowerCase()==='media' && <div className="uiheader-notifiacionesLeft-prioridad uiheader-notifiacionesLeft-prioridad-media">{itm.prioridad}</div>}
                                        {itm.prioridad.toLowerCase()==='baja' && <div className="uiheader-notifiacionesLeft-prioridad uiheader-notifiacionesLeft-prioridad-baja">{itm.prioridad}</div>}
                                      </div>
                                      <div className="uiheader-notifiaciones-right">
                                        <div className="uiheader-notifiacionesRight-tiponota">{itm.areanota} - {itm.tiponota}</div>
                                        <div className="uiheader-notifiacionesRight-nota">{removeHTMLTags(itm.nota).substr(0,150)}</div>
                                      </div>
                                  </MenuItem>
                                )

                                }
                              )
                            }
                        </ListaPendientesContainer>
                        :
                        <div>No hay notas pendientes</div>
                    }
                  </PopoverNotasContainer>
                </Popover>

            </div>
            <div>{loggedIn && <LoginAccount/>}</div>
          </ToolBarRight>
          
        </TopNavActionsListExt>
      </TopNav>
  )
}

export default HeaderGRB;