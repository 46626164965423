// Copyright 2019 Ernesto Giron E.
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//     http://www.apache.org/licenses/LICENSE-2.0
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.​

// ACTION TYPES //
export const types = {
  MODULE_AUXILIO_LOADED: "MODULE_AUXILIO_LOADED",
  MODULE_AUXILIO_FILTER_OPEN: "MODULE_AUXILIO_FILTER_OPEN",
  MODULE_AUXILIO_EDIT: "MODULE_AUXILIO_EDIT",
  AUXILIO_DATA_LOADED: "AUXILIO_DATA_LOADED",
  AUXILIO_RESETDATA_LOADED: "AUXILIO_RESETDATA_LOADED",
  AUXILIO_DATABYQUERY_LOADED: "AUXILIO_DATABYQUERY_LOADED",
  AUXILIO_STATS_LOADED: "AUXILIO_STATS_LOADED",
  AUXILIO_LIST_LOADED: "AUXILIO_LIST_LOADED",
  AUXILIO_LIST_FILTERED: "AUXILIO_LIST_FILTERED",
  AUXILIO_ISFILTER_SHOW: "AUXILIO_ISFILTER_SHOW",
  AUXILIO_SELECTED: "AUXILIO_SELECTED",
  AUXILIO_NOTA_SELECTED: "AUXILIO_NOTA_SELECTED",
  AUXILIO_DATA_SELECTED: "AUXILIO_DATA_SELECTED",
  AUXILIO_SELECTED_DATA_REQUEST: "AUXILIO_SELECTED_DATA_REQUEST",
  // AUXILIO_SELECTED_DATA_EDITED: "AUXILIO_SELECTED_DATA_EDITED",

  AUXILIO_DATA_UPDATED: "AUXILIO_DATA_UPDATED",
  AUXILIO_DATA_CREATED: "AUXILIO_DATA_CREATED",
  AUXILIO_DATA_DELETED: "AUXILIO_DATA_DELETED",
  
  AUXILIO_SERVER_MESSAGE: "AUXILIO_SERVER_MESSAGE"
};

// REDUCERS //
export const initialState = {
  loaded: false,
  isFilterShow: false,
  edit: false,
  openfilter: false,
  filtername: '',
  listAuxilios: null,
  resultQueryAuxilios: null,
  selected: null,
  selectedAuxilio: null,
  datosSelAuxilio: [],
  filter: [],
  query: null,
  mensaje: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.MODULE_AUXILIO_LOADED:
      const { loaded } = action.payload;
      return {
        ...state,
        loaded
      };
    case types.MODULE_AUXILIO_EDIT:
      const { edit } = action.payload;
      return {
        ...state,
        edit
      };
    case types.MODULE_AUXILIO_FILTER_OPEN:
      const { openfilter, filtername } = action.payload;
      return {
        ...state,
        openfilter, 
        filtername
      };
    case types.AUXILIO_LIST_LOADED:
      const { listAuxilios } = action.payload;
      return {
        ...state,
        listAuxilios
      };
    case types.AUXILIO_STATS_LOADED:
      const { resultQueryAuxilios } = action.payload;
      return {
        ...state,
        resultQueryAuxilios
      };
    case types.AUXILIO_LIST_FILTERED:
      const { filter } = action.payload;
      return {
        ...state,
        filter
      };
    case types.AUXILIO_ISFILTER_SHOW:
      const { isFilterShow } = action.payload;
      return {
        ...state,
        isFilterShow
      };
    case types.AUXILIO_SELECTED:
      const { selected } = action.payload;
      return {
        ...state,
        selected
      };
    // case types.AUXILIO_NOTA_SELECTED:
    //   return {
    //     ...state,
    //     selectedAuxilio:action.payload.selectedAuxilio
    //   };
    // case types.AUXILIO_DATA_SELECTED:
    //   const { datosSelAuxilio, selectedAuxilio } = action.payload;
    //   return {
    //     ...state,
    //     datosSelAuxilio,
    //     selectedAuxilio
    //   };
    case types.AUXILIO_SERVER_MESSAGE:
      const {mensaje} = action.payload;
      return {
        ...state,
        mensaje
      };
    default:
      return state;
  }
};

// ACTIONS //
export const moduleAuxilioLoaded = options => ({ type: types.MODULE_AUXILIO_LOADED, payload: options });
export const displayAuxilioFilter = options => ({ type: types.AUXILIO_ISFILTER_SHOW, payload: options });

export const editAuxilio = options => ({ type: types.MODULE_AUXILIO_EDIT, payload: options });
export const changeFilterAuxilio = options => ({ type: types.MODULE_AUXILIO_FILTER_OPEN, payload: options });

export const fetchAuxiliosData = token => ({ 
  type: types.AUXILIO_DATA_LOADED, 
  payload: {
    token: token
  }
});
export const fetchAuxiliosDatabyQuery = options => ({ 
  type: types.AUXILIO_DATABYQUERY_LOADED, 
  payload: options
});

export const resetAuxiliosDatabyQuery = () => ({ 
  type: types.AUXILIO_RESETDATA_LOADED, 
  payload: {resultQueryAuxilios: null}
});

export const updateAuxilio = data => ({ 
  type: types.AUXILIO_DATA_UPDATED,  payload: data
});
export const insertAuxilio = data => ({ 
  type: types.AUXILIO_DATA_CREATED,  payload: data
});
export const deleteAuxilio = data => ({ 
  type: types.AUXILIO_DATA_DELETED,  payload: data
});

export const updateEditedAuxilioData = resultQueryAuxilios => ({ 
  type: types.AUXILIO_STATS_LOADED, 
  payload: resultQueryAuxilios
});

export const filterAuxilioData = filter => ({ 
  type: types.AUXILIO_LIST_FILTERED, 
  payload: {
    filter: filter
  }
});
export const resetfilterAuxilioData = () => ({ 
  type: types.AUXILIO_LIST_FILTERED, 
  payload: {filter: []}
});

export const selectAuxilio = docId => ({ 
  type: types.AUXILIO_SELECTED, 
  payload: {
    selected: docId
  }
});



export const serverMensaje = mensaje => ({ 
  type: types.AUXILIO_SERVER_MESSAGE, 
  payload: {
    mensaje: mensaje
  }
});